import { getTheme, Theme } from '@gaming1/g1-style';
import { getSearchParam, readFrom } from '@gaming1/g1-utils';

import {
  getSavedTheme,
  getSavedThemes,
} from './components/CustomThemesProvider/helpers';

export enum ThemeName {
  dark = 'dark',
  light = 'light',
  rainbow = 'rainbow',
}

type Themes = {
  [T in ThemeName]: Theme;
};

const darkTheme: Theme = getTheme(
  {
    colors: {
      activeLink: '#1c7590',
      backgrounds: ['#12202b', '#1e2b35', '#24313b', '#333f48', '#35414a'],
      backgroundDark: '#1c1c1c',
      backgroundLight: '#fefefe',
      clubStatus: '#92E1FF',
      danger: '#F33D41',
      deposit: '#ffaf40',
      neutrals: ['#fefefe', '#eee', '#ccc', '#7c7c7c', '#555', '#333'],
      info: '#44a8e5',
      inputActive: '#4885f8',
      link: '#169dc5',
      muted: '#8f9ea6',
      tertiary: '#c3c3c3',
      portal: '#57DB98',
      primary: [
        '#ff4040',
        '#cf393b',
        '#a03237',
        '#702d33',
        '#41262f',
        '#1E212B',
      ],
      secondary: [
        '#afbec5',
        '#8f9ea6',
        '#707e87',
        '#505f68',
        '#313f49',
        '#1a2832',
      ],
      success: '#5ac732',
      text: '#eeeeee',
      textDark: '#333333',
      textLight: '#ffffff',
      warning: '#ec5d00',
    },
    dark: true,
    name: 'dark',
    shadows: [
      'none',
      'none',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
    ],
  },
  () => ({
    colors: {
      bettingActivityStatusOnlineWinning: '#6EE76C',
      bettingActivityStatusLive: '#CC0E00',
      bettingBettingSlipMenuBackground: '#455a64',
      bettingBoostus: '#FCB12B',
      bettingBoostusPrimary: '#FCB12B',
      bettingBoostusSecondary: '#E87C24',
      bettingCashout: '#03A834',
      bettingDailyOffers: '#10A8E6',
      bettingDailyOffersPrimary: '#10A8E6',
      bettingDailyOffersSecondary: '#24E87A',
      bettingFreebet: '#16B9BC',
      bettingFreebetPrimary: '#16B9BC',
      bettingFreebetSecondary: '#1280A8',
      bettingNewPromotion: '#FECB4D',
      bettingOptimalBet: '#E87C24',
      bettingOptiOddsLabel: '#5FA3D4',
      bettingOutcome: '#58788A',
      bettingOutcomeBorder: '#58788A',
      bettingOutcomeActive: '#2D996F',
      bettingOutcomeActiveHovered: '#3AC48D',
      bettingOutcomeActiveBorder: '#03A834',
      bettingOutcomeActiveBorderHovered: '#01BF37',
      bettingOutcomeBorderActive: '#03A834',
      bettingOutcomeHovered: '#79A5BD',
      bettingOutcomeLocked: '#505f68',
      bettingOutcomeLockedBorder: '#58788A',
      bettingPromotions: '#D3637B',
      bettingPromotionsPrimary: '#C44A4A',
      bettingPromotionsSecondary: '#B33A6B',
      bettingSlipKey: '#717E87',
      bettingSuperOdds: '#B078F6',
      bettingSuperOddsPrimary: '#2F3098',
      bettingSuperOddsSecondary: '#2C4E9B',
      bettingUsedGiftPrimary: '#5F5E5B',
      bettingUsedGiftSecondary: '#2F2F2F',
      // biggestWinningsBorder: theme.colors.primary,
      // bottomNavActiveText: theme.colors.primary,
      bottomNavBackground: '#24313B',
      // bottomNavHighlightedBackground: theme.colors.primary,
      bottomNavHighlightedText: '#ffffff',
      bottomNavText: '#ffffff',
      // buttonPrimaryBackground: '#ff4040',
      // buttonPrimaryBackgroundActive: darken(0.1, '#ff4040'),
      // buttonPrimaryBorder: '#ff4040',
      // buttonPrimaryBorderActive: darken(0.1, '#ff4040'),
      // buttonPrimaryText: '#ffffff',
      // buttonPrimaryTextActive: '#ffffff',
      // buttonSecondaryBackground: 'transparent',
      // buttonSecondaryBackgroundActive: '#F9EFF0',
      // buttonSecondaryBorder: '#ff4040',
      // buttonSecondaryBorderActive: darken(0.1, '#ff4040'),
      // buttonSecondaryText: '#ff4040',
      // buttonSecondaryTextActive: darken(0.1, '#ff4040'),
      // buttonTertiaryBackground: 'transparent',
      // buttonTertiaryBackgroundActive: '#F9EFF0',
      // buttonTertiaryBorder: 'transparent',
      // buttonTertiaryBorderActive: 'transparent',
      // buttonTertiaryText: '#ff4040',
      //  buttonTertiaryTextActive: darken(0.1, '#ff4040'),
      // backgroundDark: '#1c1c1c',
      // backgroundLight: '#fefefe',
      chips: '#263d51',
      chipsActive: '#3e6585',
      chipsHover: '#3e6585',
      // danger: '#b90004',
      // deposit: '#ffaf40',
      drawerNavActiveText: '#ff4040',
      drawerNavBackground: '#12202b',
      drawerNavText: '#ffffff',
      favouriteIconColor: '#f33d41',
      // footerAltText: '#ccc',
      footerBackground: ['#1f2c3a', '#12202b', '#12202b'],
      // footerBorder: '#313f49',
      // footerLink: theme.colors.link,
      // footerActiveLink: theme.colors.activeLink,
      // footerText: '#ffffff',
      // neutrals: ['fefefe', '#eee', '#ccc', '#7c7c7c', '#555', '#333'],
      headerBackground: '#24313b',
      // heading1: '#ff4040',
      heading2: '#ffffff',
      heading3: '#ffffff',
      heading4: '#eeeeee',
      info: '#44a8e5',
      // inputActive: '#4885f8',
      inputBackground: '#354552',
      inputBorder: '#cccccc',
      jackpot: '#ff00ff',
      // jackpotBronze: '#e06f16',
      // jackpotCopper: '#e33b10',
      // jackpotGold: '#fbc400',
      // jackpotSilver: '#b8b8ba',
      label: '#b8b8b8',
      mainBackground: '#0c161e',
      // profileBorder: '#c3c3c3',
      profileIconBackgroundColor: '#2c3842',
      // profileIconBorderColor: '#fefefe',
      scrollNavBackground: '#12202b',
      scrollNavBorder: '#38434d',
      scrollNavButtonBackground: '#afbec5',
      scrollNavButtonForeground: '#2e3b44',
      scrollNavItemBorderActive: '#ff4040',
      scrollNavItemBorderHovered: '#ff4040',
      scrollNavItemForeground: '#8f9ea6',
      scrollNavItemForegroundActive: '#ffffff',
      scrollNavItemForegroundHighlighted: '#57DB98',
      scrollNavTileBackground: '#24313b',
      scrollNavTileBackgroundActive: '#333f48',
      scrollNavFlexTileBackground: '#24313b',
      scrollNavFlexTileBackgroundActive: '#333f48',
      // separator: '#555',
      // topBarActiveText: '#ff4040',
      topBarBackground: '#1e2b35',
      topBarBorder: '#38434d',
      topBarText: '#cccccc',
      topNavBackground: '#1e2b35',
      topNavText: '#ffffff',
      tournamentCardBorder: '#1e2b35',
      tournamentCardSecondaryText: '#c0c0c0',
      tournamentCategoryTag: '#088EE8',
      tournamentCategoryTagBestOdd: '#ec5d00',
      // tournamentLabelBackground: '#7c7c7c',
      // warning: '#ec5d00',
    },
  }),
);

const rainbowTheme: Theme = getTheme(
  {
    colors: {
      activeLink: '#1c7590',
      backgrounds: ['#12202b', '#1e2b35', '#24313b', '#333f48', '#35414a'],
      info: '#44a8e5',
      link: '#169dc5',
      muted: '#8f9ea6',
      primary: [
        '#ff4040',
        '#cf393b',
        '#a03237',
        '#702d33',
        '#41262f',
        '#1E212B',
      ],
      secondary: [
        '#afbec5',
        '#8f9ea6',
        '#707e87',
        '#505f68',
        '#313f49',
        '#1a2832',
      ],
      success: '#5ac732',
      text: '#eeeeee',
      textDark: '#333333',
      textLight: '#ffffff',
      // warning: '#ec5d00',
    },
    dark: true,
    name: 'rainbow',
    shadows: [
      'none',
      'none',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
    ],
  },
  (theme) => ({
    colors: {
      bettingBettingSlipMenuBackground: '#455a64',
      bettingOutcome: '#455a64',
      bettingOutcomeActive: '#472632',
      bettingOutcomeBorderActive: '#ff1d31',
      bettingOutcomeLocked: '#505f68',
      bettingSlipKey: '#717E87',
      bottomNavText: theme.colors.textLight,
      chips: '#263d51',
      chipsActive: '#3e6585',
      chipsHover: '#3e6585',
      drawerNavActiveText: theme.colors.primary[0],
      drawerNavBackground: theme.colors.backgrounds[0],
      drawerNavText: theme.colors.textLight,
      footerBackground: [
        '#1f2c3a',
        theme.colors.backgrounds[0],
        theme.colors.backgrounds[0],
      ],
      heading2: theme.colors.textLight,
      heading3: theme.colors.textLight,
      heading4: theme.colors.text,
      inputBackground: '#354552',
      inputBorder: theme.colors.neutrals[2],
      jackpot: '#ff4040',
      label: '#b8b8b8',
      mainBackground: 'purple',
      scrollNavBorder: theme.colors.backgrounds[1],
      scrollNavItemBorderHovered: theme.colors.textLight,
      scrollNavItemForegroundActive: theme.colors.textLight,
      scrollNavItemForegroundHighlighted: theme.colors.portal,
      topBarBackground: theme.colors.backgrounds[1],
      topBarBorder: theme.colors.backgrounds[1],
      topBarText: theme.colors.neutrals[2],
      topNavBackground: theme.colors.backgrounds[1],
      topNavText: theme.colors.textLight,
      tournamentCardBorder: theme.colors.backgrounds[1],
    },
  }),
);

const lightTheme = getTheme(
  {
    name: 'light',
    colors: {
      activeLink: '#1c7590',
      backgrounds: ['#f9f9fa', '#fafafb', '#f9f9fa', '#f9f9fa', '#f9f9fa'],
      backgroundDark: '#1c1c1c',
      backgroundLight: '#fefefe',
      clubStatus: '#92E1FF',
      danger: '#F33D41',
      deposit: '#ffaf40',
      neutrals: ['#fefefe', '#eee', '#ccc', '#7c7c7c', '#555', '#333'],
      info: '#2b7aaa',
      inputActive: '#4885f8',
      link: '#169dc5',
      muted: '#6c757d',
      portal: '#57DB98',
      primary: [
        '#ff4040',
        '#cf393b',
        '#a03237',
        '#702d33',
        '#41262f',
        '#1E212B',
      ],
      secondary: [
        '#afbec5',
        '#8f9ea6',
        '#707e87',
        '#505f68',
        '#313f49',
        '#1a2832',
      ],
      success: '#43a31f',
      text: '#111111',
      textDark: '#111111',
      textLight: '#ffffff',
      warning: '#ec5d00',
    },
    options: {
      elevationBorderLevel: 2,
    },
  },
  () => ({
    colors: {
      mainBackground: '#eeeeee',
      buttonSecondaryBackgroundActive: 'rgba(255,64,64,0.07)',
      buttonTertiaryBackgroundActive: '#F9EFF0',
      buttonTertiaryBorderActive: '#F9EFF0',
    },
  }),
);

export const themes: Themes = {
  [ThemeName.dark]: darkTheme,
  [ThemeName.light]: lightTheme,
  [ThemeName.rainbow]: rainbowTheme,
};

export const LOCAL_STORAGE_THEME_KEY = 'defaultTheme';
export const LOCAL_STORAGE_CUSTOM_THEME_KEY = 'customTheme';

const isValidThemeName = (name: unknown): name is ThemeName =>
  typeof name === 'string' && Object.keys(ThemeName).includes(name);

export const getDefaultThemeName = () => {
  const paramName = getSearchParam('theme');
  const storedName = readFrom(localStorage, LOCAL_STORAGE_THEME_KEY);
  if (isValidThemeName(paramName)) {
    return paramName;
  }
  if (!isValidThemeName(storedName)) {
    return ThemeName.dark;
  }
  return ThemeName[storedName];
};

export const getSavedCustomTheme = () => {
  const storeCustomThemeName = readFrom(
    localStorage,
    LOCAL_STORAGE_CUSTOM_THEME_KEY,
  );

  if (storeCustomThemeName) {
    const customThemes = getSavedThemes();

    if (customThemes && customThemes.length) {
      // Since this is a dev feature, type casting is safe here
      const currentCustomTheme = getSavedTheme(storeCustomThemeName as string);

      if (currentCustomTheme) {
        return currentCustomTheme.name;
      }
    }
  }

  return null;
};
