import { createContext } from 'react';

import { OddsUnitsType } from '@gaming1/g1-config';
import { MenuList } from '@gaming1/g1-core-web';
import { readFrom } from '@gaming1/g1-utils';

export type BettingMenuContextMenus = {
  main: { firstItems: MenuList; lastItems: MenuList };
  mainAuthenticated: { firstItems: MenuList; lastItems: MenuList };
  services: MenuList;
};

export const bettingMenuContextMenusDefaultValues: BettingMenuContextMenus = {
  main: { firstItems: [], lastItems: [] },
  mainAuthenticated: { firstItems: [], lastItems: [] },
  services: [],
};

type BettingMenuContextType = {
  menus: BettingMenuContextMenus;
  oddUnit: OddsUnitsType;
  outcomeValue: string;
  setOddUnit: (unit: OddsUnitsType) => void;
};

export const ODD_UNIT_STORAGE_KEY = 'oddUnit';

const oddUnitStorage = readFrom(
  localStorage,
  ODD_UNIT_STORAGE_KEY,
) as OddsUnitsType;

export const bettingMenuContextDefaultValues: BettingMenuContextType = {
  menus: bettingMenuContextMenusDefaultValues,
  oddUnit: oddUnitStorage || 'decimal',
  outcomeValue: '',
  setOddUnit: () => null,
};

/** A context to overwrite some default behaviours of the betting menu */
export const BettingMenuContext = createContext<BettingMenuContextType>(
  bettingMenuContextDefaultValues,
);
