import React, { VFC } from 'react';

import { Gifts } from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { OpacityContainer } from '../styles';

type HistoryStakeProps = {
  /** The stake of the history bet */
  stake: number;
  /** Compact version (mobile or inside ticket) */
  isCompact?: boolean;
  /** The type of the gift, used to display different thing according to the type */
  giftType: Gifts | null;
};

/** Render a history stake with different layouts (if we're on desktop or not).
 *  If the stake is a freebet, we will have a different render with an icon +
 *  a little text that will display to the user that the bet was done with a freebet.
 */
export const HistoryStake: VFC<HistoryStakeProps> = ({
  stake,
  isCompact = false,
  giftType,
}) => {
  const formatMoney = useFormatMoney();

  const { t } = useTranslation('betting');

  if (giftType === 'freebet') {
    return !isCompact ? (
      <Box data-testid="history-freebet-stake-desktop">
        <Box flexDirection="row" alignItems="center">
          <Text mt="xxs" fontWeight="bold">
            {formatMoney(stake)}
          </Text>
        </Box>
        <OpacityContainer>
          {t('history.freebetType.stake.description')}
        </OpacityContainer>
      </Box>
    ) : (
      <Box data-testid="history-freebet-stake-mobile">
        <OpacityContainer>
          {t('history.freebetType.stake.description')}
        </OpacityContainer>
        <Box flexDirection="row" alignItems="center">
          <Text mt="xxs" fontWeight="bold">
            {formatMoney(stake)}
          </Text>
        </Box>
      </Box>
    );
  }

  return !isCompact ? (
    <Box
      data-testid="history-money-stake-desktop"
      flexDirection="row"
      alignItems="center"
      fontWeight="bold"
    >
      {formatMoney(stake)}
    </Box>
  ) : (
    <Box data-testid="history-money-stake-mobile">
      <OpacityContainer>{t('history.mobileStake.text')}</OpacityContainer>
      <Text fontWeight="bold" mt="xxs">
        {formatMoney(stake)}
      </Text>
    </Box>
  );
};
