import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  eventNameSelector,
  getRegionNameSelector,
  leagueNameSelector,
  sportNameSelector,
} from '@gaming1/g1-betting';
import { ConfigContext } from '@gaming1/g1-config';
import { useDateLocale } from '@gaming1/g1-core';
import { useNotifications, useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { useMediaBreakPoint } from '@gaming1/g1-ui';
import {
  latinizeString,
  RemoteData,
  RouteList,
  RouteObject,
  RouteParams,
} from '@gaming1/g1-utils';

import { BettingMenuContext } from './common/BettingMenuContext';
import { cleanParam, formatOdd } from './helpers';
import { bettingRoutes } from './routes';

export * from './common/hooks/icons';

export const useFormatOdd = (odd?: number) => {
  const { oddUnit } = useContext(BettingMenuContext);

  return odd ? formatOdd(odd, oddUnit) : null;
};

export const useIsDesktop = () => useMediaBreakPoint({ min: 'lg' });

export const useDateFormat = (validityDate?: string) => {
  const { i18n } = useContext(ConfigContext);
  const { t } = useTranslation('betting');
  const dateLocale = useDateLocale();

  if (!validityDate) {
    return null;
  }

  const daysFromToday = differenceInCalendarDays(
    new Date(validityDate),
    new Date(),
  );

  if (daysFromToday === 0) {
    return t('gifts.card.dateValidity.today');
  }

  if (daysFromToday === 1) {
    return t('gifts.card.dateValidity.tomorrow');
  }

  if (daysFromToday > 1) {
    return t('gifts.card.dateValidity.date', {
      formattedDate: format(new Date(validityDate), i18n.dateShortFormat, {
        locale: dateLocale,
      }),
    });
  }

  return null;
};

/**
 * Will check the given {requestState} and send a success notification if the
 * state is in success too. It'll display the given {notificationMessage} in the
 * displayed notification.
 */
export const useSendSuccessNotificationOnSuccessState = (
  requestState: RemoteData,
  notificationMessage: string,
) => {
  const { addNotification } = useNotifications();

  const { t } = useTranslation('betting');

  useEffect(() => {
    if (requestState === RemoteData.Success) {
      addNotification({
        message: t(notificationMessage),
        type: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotification, requestState]);
};

/**
 * Will check the given {requestState} and send an error notification if the
 * state is in error too. It'll display the given {notificationMessage} in the
 * displayed notification.
 */
export const useSendErrorNotificationOnErrorState = (
  requestState: RemoteData,
  notificationMessage: string,
) => {
  const { addNotification } = useNotifications();
  const { t } = useTranslation('betting');

  useEffect(() => {
    if (requestState === RemoteData.Error) {
      addNotification({
        message: t(notificationMessage),
        type: 'warning',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotification, requestState]);
};

export const useManageNotificationOnRequestState = (
  requestState: RemoteData,
  successNotificationMessage: string,
  errorNotificationMessage: string,
) => {
  useSendSuccessNotificationOnSuccessState(
    requestState,
    successNotificationMessage,
  );
  useSendErrorNotificationOnErrorState(requestState, errorNotificationMessage);
};

/** Returns all params of current URL with only the ids (without the names added for SEO) */
export const useCleanedParams = <T extends { [name: string]: string }>(): T => {
  const params = useParams<T>();
  const keys = Object.keys(params);

  return keys.reduce((acc, key) => {
    const value = params?.[key] ? `${params[key]}` : null;
    if (value === null) {
      return acc;
    }

    return { ...acc, [key]: cleanParam(value) };
  }, {}) as T;
};

type BettingRoutes = typeof bettingRoutes;
type BettingRouteNames = keyof BettingRoutes;
type CommonParams = {
  sportId: string;
  regionId: string;
  leagueId: string;
  eventId: string;
};
// Defined in packages/g1-core-web/src/routing/helpers.ts
type SearchParams = { [k: string]: string };

type ExtractRouteWithoutParams<Route> = Route extends RouteObject<null>
  ? Route
  : never;
type ExtractRouteNameWithoutParams<
  Routes extends RouteList,
  RouteName extends keyof Routes = keyof Routes,
> = Routes[RouteName] extends RouteObject<null> ? RouteName : never;

type ExtractRouteNameWithParams<
  Routes extends RouteList,
  RouteName extends keyof Routes = keyof Routes,
> = Routes[RouteName] extends RouteObject<null> ? never : RouteName;

const isRouteWithoutParams = <
  Key extends keyof BettingRoutes,
  Route extends BettingRoutes[Key],
>(
  route: Route,
): route is ExtractRouteWithoutParams<Route> => route.parameters === undefined;

const isRouteNameWithoutParams = <Name extends BettingRouteNames>(
  routeName: Name,
): routeName is ExtractRouteNameWithoutParams<BettingRoutes> =>
  isRouteWithoutParams(bettingRoutes[routeName]);

/** Returns an URL with names and ids in params (for SEO) */
export const useBettingRoutePath = () => {
  const getBettingRoutePath = useRoutePath(bettingRoutes);

  const getSportName = useSelector(sportNameSelector);
  const getRegionName = useSelector(getRegionNameSelector);
  const getLeagueName = useSelector(leagueNameSelector);
  const getEventName = useSelector(eventNameSelector);

  const getBettingRoutePathWithNamedParams = useCallback(
    <RouteName extends BettingRouteNames>(
      routeName: RouteName,
      ...parameters: RouteParams<BettingRoutes[RouteName]> extends undefined
        ? [undefined?, SearchParams?]
        : [RouteParams<BettingRoutes[RouteName]>, SearchParams?]
    ): string => {
      const [params, searchQuery] = parameters;
      //
      if (isRouteNameWithoutParams(routeName)) {
        return getBettingRoutePath(routeName, undefined, searchQuery);
      }
      const routeNameWithparams =
        routeName as ExtractRouteNameWithParams<BettingRoutes>;

      // Type casting needed because typeof params is an union type of
      // object without discriminating properties and the only other way was
      // to use a lot of custom type guards
      const possibleParams = params as Partial<CommonParams>;
      const sportId = possibleParams?.sportId || '';
      const regionId = possibleParams?.regionId || '';
      const leagueId = possibleParams?.leagueId || '';
      const eventId = possibleParams?.eventId || '';

      const sportName = getSportName(+sportId);
      const regionName = getRegionName(+sportId, +regionId);
      const leagueName = getLeagueName(+leagueId);
      const eventName = getEventName(+eventId);

      const names: CommonParams = {
        sportId: sportName || '',
        regionId: regionName || '',
        leagueId: leagueName || '',
        eventId: eventName,
      };

      // Type casting needed because Object.entries type all keys as string
      const paramEntries = Object.entries(params || {}) as [
        keyof RouteParams<BettingRoutes[RouteName]>,
        string,
      ][];

      const mergedParams: RouteParams<BettingRoutes[RouteName]> =
        paramEntries.reduce<RouteParams<BettingRoutes[RouteName]>>(
          (acc, [paramKey, paramValue]) => {
            const paramName =
              paramKey in names
                ? names[paramKey as keyof typeof names]
                : undefined;
            const concatenatedName = paramName
              ? `${paramName}-${paramValue}`
              : paramValue;
            const cleanName = latinizeString(concatenatedName)
              .replace(/(\s|:|,|\/)/g, '-') // Remove unwanted chars
              .replace(/-+/g, '-'); // Replace several '-' by 1 '-'

            return { ...acc, [paramKey]: cleanName };
          },
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          { ...params } as RouteParams<BettingRoutes[RouteName]>,
        );

      const url = getBettingRoutePath(
        routeNameWithparams,
        mergedParams,
        searchQuery,
      );

      return url;
    },
    [
      getBettingRoutePath,
      getEventName,
      getLeagueName,
      getRegionName,
      getSportName,
    ],
  );
  return getBettingRoutePathWithNamedParams;
};

export const useReplaceHistory = () => {
  const history = useHistory();
  const location = useLocation();

  const { pathname, search, hash } = location;
  const currentUrl = `${pathname}${search}${hash}`;

  return useCallback(
    (newUrl: string) => {
      if (currentUrl !== newUrl) {
        history.replace(newUrl);
      }
    },
    [currentUrl, history],
  );
};
