import { animated, useTransition } from '@react-spring/web';
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import ResizeObserver from 'resize-observer-polyfill';

import { useWindowSize } from '@gaming1/g1-utils';

import {
  HorizontalPosition,
  POPOVER_MARGIN_IN_PX,
  PopoverAside,
  PopoverContainer,
  PopoverContent,
  VerticalPosition,
} from './styles';

const TRANSITION_DURATION_IN_MS = 200;

export type PopoverProps = {
  /** Ref of the component where the popover should be contained. */
  containerRef: MutableRefObject<HTMLElement | null>;
  /** Wether the global layout has shifted or not */
  hasLayoutShifted?: boolean;
  /** Test id linked to the popover */
  testId: string;
  /** Where to put the popover relatively to its container */
  horizontalPosition?: HorizontalPosition;
  /** Where to put the popover relatively to its container */
  verticalPosition?: VerticalPosition;
  /** Wether to show the popover or not */
  visible: boolean;
};

type PopoverCompoundComponents = {
  Content: typeof PopoverContent;
};

const getSizeAndCoordinates = (elem?: HTMLElement) => {
  if (!elem) {
    return { top: 0, left: 0, width: 0, height: 0 };
  }
  // Cordinates
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = Math.round(box.top + scrollTop - clientTop);
  const left = Math.round(box.left + scrollLeft - clientLeft);

  // Size
  const { width: elWidth, height: elHeight } = getComputedStyle(elem);
  // Commented code is only useful when using another border-box model
  const height = parseFloat(elHeight) || 0;
  /* +
    parseFloat(paddingTop) +
    parseFloat(paddingBottom) +
    parseFloat(borderTopWidth) +
    parseFloat(borderBottomWidth);
    */
  const width = parseFloat(elWidth) || 0;
  /* +
    parseFloat(paddingLeft) +
    parseFloat(paddingRight) +
    parseFloat(borderLeftWidth) +
    parseFloat(borderRightWidth); */

  return { top, left, height, width };
};

export const Popover: FC<PopoverProps> & PopoverCompoundComponents = ({
  containerRef,
  testId,
  children,
  horizontalPosition = 'center',
  verticalPosition = 'top',
  visible,
  hasLayoutShifted = false,
}) => {
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const isObserving = useRef(false);

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  useEffect(() => {
    setContainerSize(getSizeAndCoordinates(containerRef.current || undefined));
  }, [containerRef, windowHeight, windowWidth, hasLayoutShifted]);

  const ro = useMemo(
    () =>
      new ResizeObserver(() => {
        // We don't actually use the data sent in the callback since there
        // doesn't seem a way to get the complete size of the element (including
        // borders/paddings)
        setContainerSize(
          getSizeAndCoordinates(containerRef.current || undefined),
        );
      }),
    [containerRef],
  );

  useEffect(() => {
    if (containerRef.current && !isObserving.current) {
      // Observe one or multiple elements
      ro.observe(containerRef.current);
      isObserving.current = true;
    }
  }, [containerRef, visible, ro]);

  useEffect(
    () => () => {
      if (containerRef.current && isObserving) {
        ro.disconnect();
      }
    },
    [containerRef, ro],
  );

  const shouldBeVisible = visible && !!containerRef.current;

  const transitions = useTransition(shouldBeVisible, {
    config: {
      clamp: false,
      duration: TRANSITION_DURATION_IN_MS,
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const { width, height, top, left } = containerSize;

  // Ensure the popup bottom isn't below the visible viewport
  const maxHeight = (windowHeight || 100) - top - height;

  return transitions(
    (style, show) =>
      show &&
      createPortal(
        <PopoverAside
          data-testid={testId}
          aria-hidden={shouldBeVisible ? undefined : 'true'}
          role="tooltip"
          tabIndex={-1}
          style={{
            top,
            left,
            width,
            height,
          }}
        >
          <PopoverContainer
            horizontalPosition={horizontalPosition}
            verticalPosition={verticalPosition}
            style={{
              maxHeight,
            }}
          >
            <animated.div
              style={{ ...style, maxHeight: maxHeight - POPOVER_MARGIN_IN_PX }}
            >
              {children}
            </animated.div>
          </PopoverContainer>
        </PopoverAside>,
        document.body,
      ),
  );
};

Popover.Content = PopoverContent;
