import { GetShopHistoryRequestDTO } from '@gaming1/g1-requests';
import { PaginationList } from '@gaming1/g1-utils';

import {
  FreeSpin,
  GetGameFeatureHistoryResponse,
  GetShopHistoryResponse,
  GetSponsorshipHistoryResponse,
  ScratchCardHistoryElement,
  ScratchcardHistoryResponse,
  ShopOrder,
  Sponsorship,
} from './types';

export const formatGameFeatureHistory = (
  response: GetGameFeatureHistoryResponse,
): PaginationList<FreeSpin> => {
  const { HistoricGameFeature, PageNumber, PageSize, Total } = response;

  return {
    list: HistoricGameFeature,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};

export const formatSponsorshipHistory = (
  response: GetSponsorshipHistoryResponse,
): PaginationList<Sponsorship> => {
  const { List, PageNumber, PageSize, Total } = response;

  return {
    list: List,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};

export const formatShopHistory = (
  request: GetShopHistoryRequestDTO,
  response: GetShopHistoryResponse,
): PaginationList<ShopOrder> => {
  const { PageCount, PageIndex } = request;
  const { Orders, Total } = response;

  return {
    list: Orders || [],
    pagination: {
      PageNumber: PageIndex,
      PageSize: PageCount,
      Total,
    },
  };
};

export const formatScratchcardHistory = (
  response: ScratchcardHistoryResponse,
): PaginationList<ScratchCardHistoryElement> => {
  const { Elements, PageNumber, PageSize, TotalElements } = response;

  return {
    list: Elements || [],
    pagination: {
      PageNumber,
      PageSize,
      Total: TotalElements,
    },
  };
};
