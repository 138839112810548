import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { EFrontErrorType } from '../../../../notification/mapper';
import { getPromotionsCorrespondingToIdsGettorSelector } from '../../../../promotions/selectors';
import {
  bettingSlipGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  promotionSelectedForBSGetterSelector,
} from '../common';
import { getFinalOddsOfBettingSlipItemGetterSelector } from '../elements/bettingslipItem';
import { bettingSlipFinalOddsGettorSelector } from '../odds';
import {
  bettingSlipFinalWinningsGettorSelector,
  bettingSlipWinningsGettorSelector,
} from '../winnings';

import { isAvailableByRangeCondition } from './helpers';

/**
 * Get the promotionConditions slice of the store from the given bettingSlip
 */
export const getPromotionsIdsGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingSlip) => (bettingSlipId: BettingSlipIdentifierType) =>
    memoize(() => bettingSlip(bettingSlipId)?.promotionsIds),
);

export const isValidPromotionForBSGetterSelector = createSelector(
  promotionSelectedForBSGetterSelector,
  getPromotionsCorrespondingToIdsGettorSelector,
  bettingSlipGetterSelector,
  bettingSlipFinalOddsGettorSelector,
  bettingSlipWinningsGettorSelector,
  bettingSlipFinalWinningsGettorSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  (
    getPromotionSelected,
    getPromotionCorrespondingToIds,
    bettingSlip,
    bettingSlipFinalOdds,
    bettingSlipWinnings,
    bettingSlipFinalWinnings,
    bettingSlipItemFinalOdds,
    bettingSlipStakeInFloat,
    roundingConfig,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const promotionSelected = getPromotionSelected(bettingSlipId);

      if (!promotionSelected) {
        return {
          Status: EFrontErrorType.None,
          Level: NotificationLevel.Information,
          Code: NotificationCode.Unknown,
        };
      }
      const promotion = getPromotionCorrespondingToIds([
        promotionSelected.id,
      ])[0];

      if (!promotion || !promotion.Conditions) {
        return {
          Status: EFrontErrorType.None,
          Level: NotificationLevel.Information,
          Code: NotificationCode.Unknown,
        };
      }

      const bs = bettingSlip(bettingSlipId);
      return isAvailableByRangeCondition(
        promotion.Conditions.RangeConditions?.filter(isNonNullable),
        {
          items:
            bs?.items.map((item) => ({
              finalOdd: bettingSlipItemFinalOdds(bettingSlipId, item.id) || 0,
            })) || [],
          totalFinalOdd: bettingSlipFinalOdds(bettingSlipId),
          winnings: {
            base: bettingSlipWinnings(bettingSlipId) || 0,
            final: bettingSlipFinalWinnings(bettingSlipId) || 0,
          },
          stake: bettingSlipStakeInFloat(bettingSlipId) || 0,
        },
        {
          roundingConfig: roundingConfig(bettingSlipId),
        },
      );
    }),
);
