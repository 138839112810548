import {
  EPlayerAcceptTermsAndConditionsResponseStatus,
  ETermsAndConditionsResponseStatus,
} from '@gaming1/g1-requests';

export const getTermsAndConditionsErrorMessages = {
  [ETermsAndConditionsResponseStatus.InternalError]:
    'core:error.internalServer', // 0
  [ETermsAndConditionsResponseStatus.FileNotFound]:
    'user:termsAndConditions.error.fileNotFound', // 2
};

export const acceptTermsAndConditionsErrorMessages = {
  [EPlayerAcceptTermsAndConditionsResponseStatus.InternalError]:
    'core:error.internalServer', // 0
};
