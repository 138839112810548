/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:47:56 01-12-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetAvailablePromoRequest,
  GetAvailablePromoResponse,
  GetPromoForBettingSlipRequest,
  GetPromoForBettingSlipResponse,
  HasAvailableFeatureRequest,
  HasAvailablePromoResponse,
} from '../entities';

export const hasAvailablePromo = (options: HasAvailableFeatureRequest) =>
  createRequest<HasAvailablePromoResponse>(202, 'HasAvailablePromo', options);

export const getAvailablePromo = (options: GetAvailablePromoRequest) =>
  createRequest<GetAvailablePromoResponse>(202, 'GetAvailablePromo', options);

export const getPromoForBettingSlip = (
  options: GetPromoForBettingSlipRequest,
) =>
  createRequest<GetPromoForBettingSlipResponse>(
    202,
    'GetPromoForBettingSlip',
    options,
  );
