import React, { useEffect, useMemo, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  connectionToGeoComplyRequestStateSelector,
  currentGeolocationSelector,
  decryptGeolocationRequestStateSelector,
  geoComplyLicenseKeyRequestStateSelector,
  GeoComplyStatusCode,
  GeolocationCustomErrorCode,
  geolocationRequestStateSelector,
  isFirstGeolocationErrorsDetectionSelector,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { useRequestState } from '@gaming1/g1-store';
import { Banner } from '@gaming1/g1-ui';
import { isOnMobileBrowser } from '@gaming1/g1-utils';

import { logger } from '../../../logger';
import { coreRoutes } from '../../../routes';
import { useRoutePath } from '../../../routing/hooks';
import { GeolocationModal, GeolocationModalType } from '../GeolocationModal';

import {
  GeolocationBannerButton,
  GeolocationBannerContent,
  GeolocationBannerLink,
  GeolocationBannerWrapperContent,
} from './styles';

/**
 * Banner displayed when the user is not well geolocated
 */
export const GeolocationBanner: VFC = () => {
  const { pathname: currentUrl } = useLocation();
  const getCoreRoutePath = useRoutePath(coreRoutes);

  const currentGeolocation = useSelector(currentGeolocationSelector);
  const isFirstTimeGeolocationErrorsOccured = useSelector(
    isFirstGeolocationErrorsDetectionSelector,
  );

  /* Requests state */
  const {
    isSuccessful: isGeolocationDecryptionRequestStateSuccessful,
    isFailing: isGeolocationDecryptionRequestStateFailing,
    wasFailed: wasGeolocationDecryptionRequestStateFailing,
  } = useRequestState(decryptGeolocationRequestStateSelector);
  const {
    isFailing: hasConnectionToGeoComplyFailed,
    errorCode: geocomplyConnectionErrorCode,
  } = useRequestState(connectionToGeoComplyRequestStateSelector);
  const {
    errorCode: geolocationRequestErrorCode,
    isSuccessful: isGeolocationRequestSuccessful,
    isFailing: isGeolocationRequestFailing,
    wasFailed: wasGeolocationRequestFailing,
    isLoading: isGeolocationRequestLoading,
  } = useRequestState(geolocationRequestStateSelector);
  const {
    isFailing: isTheGeocomplyLicenseFetchRequestFailing,
    wasFailed: wasTheGeocomplyLicenseFetchRequestFailing,
    isSuccessful: isTheGeocomplyLicenseFetchRequestSuccessful,
  } = useRequestState(geoComplyLicenseKeyRequestStateSelector);

  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const isUserOnAppDownloadPage = useMemo(
    () => currentUrl.includes(coreRoutes.downloadApp.path),
    [currentUrl],
  );

  const { t } = useTranslation('core');
  const [shownGeolocationType, setShownGeolocationType] = useState<
    'none' | GeolocationModalType
  >('none');

  const onModalClose = () => {
    setShownGeolocationType('none');
  };

  let bannerMessage: JSX.Element | null = null;

  /**
   * Show the geolocation modal errors when the
   * troubleshooters are displayed for the first time
   */
  useEffect(() => {
    if (isFirstTimeGeolocationErrorsOccured) {
      logger.info(
        '[Geolocation] First geolocation errors spotted : show the GeolocationModal automatically',
      );
      setShownGeolocationType('troubleshooters');
    }
  }, [isFirstTimeGeolocationErrorsOccured]);
  let modalAvailable: GeolocationModalType | null = null;

  if (!isUserLoggedIn) {
    return null;
  }

  if (
    hasConnectionToGeoComplyFailed &&
    geocomplyConnectionErrorCode ===
      GeoComplyStatusCode.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION
  ) {
    /** The connection to the Geocomply PLC failed */
    modalAvailable = 'pluginConnectionIssue';
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {t('geocomply.technicalIssue')}
        </GeolocationBannerContent>
        <GeolocationBannerButton
          data-testid="geocomply-banner-issue-resolve-button"
          onClick={() => setShownGeolocationType('pluginConnectionIssue')}
        >
          {t('geocomply.resolveTechnicalIssue')}
        </GeolocationBannerButton>
      </GeolocationBannerWrapperContent>
    );
  } else if (hasConnectionToGeoComplyFailed && !isUserOnAppDownloadPage) {
    /** The connection to the Geocomply PLC couldn't be made because it is
     * likely not installed/started */
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {isOnMobileBrowser()
            ? t('geocomply.connectionNotEstablishedOnMobile')
            : t('geocomply.connectionNotEstablished')}
        </GeolocationBannerContent>
        <GeolocationBannerLink
          data-testid="geocomply-banner-connection-read-more-button"
          to={getCoreRoutePath('downloadApp')}
        >
          {t('geocomply.readMore.connection')}
        </GeolocationBannerLink>
      </GeolocationBannerWrapperContent>
    );
  } /** The license couldn't be fetched from our backend */ else if (
    isTheGeocomplyLicenseFetchRequestFailing ||
    (wasTheGeocomplyLicenseFetchRequestFailing &&
      !isTheGeocomplyLicenseFetchRequestSuccessful)
  ) {
    modalAvailable = 'noLicense';
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {t('geocomply.technicalIssue')}
        </GeolocationBannerContent>
        <GeolocationBannerButton
          data-testid="geocomply-banner-issue-resolve-button"
          onClick={() => setShownGeolocationType('noLicense')}
        >
          {t('geocomply.resolveTechnicalIssue')}
        </GeolocationBannerButton>
      </GeolocationBannerWrapperContent>
    );
  } else if (
    getWrapperEnv() === 'rn' &&
    isGeolocationRequestFailing &&
    geolocationRequestErrorCode ===
      GeoComplyStatusCode.CLNT_ERROR_APPROXIMATE_GEOLOCATION
  ) {
    /**
     * The geolocation setting is set to approximate in user's device settings
     */
    modalAvailable = 'approximateGeolocation';
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {t('geocomply.approximateGeolocation')}
        </GeolocationBannerContent>
        <GeolocationBannerButton
          data-testid="geocomply-banner-approximate-geolocation-read-more-button"
          onClick={() => setShownGeolocationType('approximateGeolocation')}
        >
          {t('geocomply.readMore.approximate-geolocation')}
        </GeolocationBannerButton>
      </GeolocationBannerWrapperContent>
    );
  } else if (
    getWrapperEnv() === 'rn' &&
    isGeolocationRequestFailing &&
    geolocationRequestErrorCode ===
      GeolocationCustomErrorCode.LOCATION_PERMISSION_DENIED
  ) {
    /**
     * The user device geolocation setting is completely disabled
     */
    modalAvailable = 'locationPermissionDenied';
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {t('geocomply.locationPermissionDenied')}
        </GeolocationBannerContent>
        <GeolocationBannerButton
          data-testid="geocomply-banner-location-permission-denied-read-more-button"
          onClick={() => setShownGeolocationType('locationPermissionDenied')}
        >
          {t('geocomply.readMore.location-permission-denied')}
        </GeolocationBannerButton>
      </GeolocationBannerWrapperContent>
    );
  } else if (
    isGeolocationRequestFailing ||
    (wasGeolocationRequestFailing && !isGeolocationRequestSuccessful) ||
    isGeolocationDecryptionRequestStateFailing ||
    (wasGeolocationDecryptionRequestStateFailing &&
      !isGeolocationDecryptionRequestStateSuccessful)
  ) {
    modalAvailable = 'technicalIssue';
    /** There was an error making the geolocation request or decrypting its
     * result  */
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {t('geocomply.technicalIssue')}
        </GeolocationBannerContent>
        <GeolocationBannerButton
          data-testid="geocomply-banner-issue-resolve-button"
          onClick={() => setShownGeolocationType('technicalIssue')}
        >
          {t('geocomply.resolveTechnicalIssue')}
        </GeolocationBannerButton>
      </GeolocationBannerWrapperContent>
    );
  } else if (
    /**
     * The geolocation request was successful but its result states that the user
     * geolocation is invalid
     */
    currentGeolocation &&
    !currentGeolocation.IsValid &&
    isGeolocationDecryptionRequestStateSuccessful &&
    isGeolocationRequestSuccessful
  ) {
    modalAvailable = 'troubleshooters';
    bannerMessage = (
      <GeolocationBannerWrapperContent>
        <GeolocationBannerContent>
          {t('geocomply.locationNotVerified')}
        </GeolocationBannerContent>
        <GeolocationBannerButton
          data-testid="geocomply-banner-location-read-more-button"
          onClick={() => setShownGeolocationType('troubleshooters')}
        >
          {t('geocomply.readMore.location')}
        </GeolocationBannerButton>
      </GeolocationBannerWrapperContent>
    );
  }

  return bannerMessage && !isGeolocationRequestLoading ? (
    <>
      <Banner
        type="warning"
        p="xs"
        icon="LocationPinError"
        message={bannerMessage}
        testId="geolocation-banner-container"
      />

      {modalAvailable === 'troubleshooters' && (
        <GeolocationModal
          isVisible={shownGeolocationType === 'troubleshooters'}
          onClose={onModalClose}
          type="troubleshooters"
        />
      )}
      {modalAvailable === 'technicalIssue' && (
        <GeolocationModal
          isVisible={shownGeolocationType === 'technicalIssue'}
          onClose={onModalClose}
          type="technicalIssue"
        />
      )}
      {modalAvailable === 'noLicense' && (
        <GeolocationModal
          isVisible={shownGeolocationType === 'noLicense'}
          onClose={onModalClose}
          type="noLicense"
        />
      )}
      {modalAvailable === 'pluginConnectionIssue' && (
        <GeolocationModal
          isVisible={shownGeolocationType === 'pluginConnectionIssue'}
          onClose={onModalClose}
          type="pluginConnectionIssue"
        />
      )}
      {modalAvailable === 'approximateGeolocation' && (
        <GeolocationModal
          isVisible={shownGeolocationType === 'approximateGeolocation'}
          onClose={onModalClose}
          type="approximateGeolocation"
        />
      )}
      {modalAvailable === 'locationPermissionDenied' && (
        <GeolocationModal
          isVisible={shownGeolocationType === 'locationPermissionDenied'}
          onClose={onModalClose}
          type="locationPermissionDenied"
        />
      )}
    </>
  ) : null;
};
