import React, { FC } from 'react';

import { PrismicTabsSlice as PrismicTabsType } from '@gaming1/g1-cms';
import { Box, Tabs } from '@gaming1/g1-ui';

import { getItemKey } from '../../helpers';
import { PrismicRichText } from '../PrismicRichText';
import { PrismicWrapper } from '../styles';

const { Tab, TabContent, TabContents, TabList } = Tabs;

export type PrismicTabsProps = {
  /** Prismic 'Tabs' slice */
  slice: PrismicTabsType;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Tabs` slice
 * A block with tabs, each having its own content
 */
export const PrismicTabs: FC<PrismicTabsProps> = ({
  slice,
  testId = 'prismic-tabs',
  parentIds = [],
}) => (
  <PrismicWrapper>
    <Tabs defaultActiveTab="0" data-testid={testId}>
      <TabList>
        {slice.items.map((tab, index) => (
          <Tab
            key={`tabs-${getItemKey(tab.header) || index}`}
            id={index.toString()}
          >
            {tab.header}
          </Tab>
        ))}
      </TabList>
      <TabContents>
        {slice.items.map((tab, index) => (
          <TabContent
            key={`tab-content-${getItemKey(tab.header) || index}`}
            id={index.toString()}
            data-testid="prismic-tab-content"
          >
            <Box p="sm">
              <PrismicRichText
                content={tab.content}
                sliceId={slice.primary.id}
                parentIds={
                  slice.primary.id
                    ? [...parentIds, slice.primary.id]
                    : parentIds
                }
              />
            </Box>
          </TabContent>
        ))}
      </TabContents>
    </Tabs>
  </PrismicWrapper>
);
