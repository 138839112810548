// This was a duplicate from g1-utils but the usage of Url make RN app crash

import { conditionalWindow } from './conditionalWindow';

/**
 * Get a search parameter from an url or the current one
 */
export const getSearchParam = (queryName: string, href?: string) => {
  if (
    typeof conditionalWindow !== 'object' ||
    conditionalWindow.location === undefined
  ) {
    return null;
  }
  const url = href || conditionalWindow.location.href;
  if (url.indexOf('?') === -1) {
    return null;
  }
  return url
    .slice(url.indexOf('?') + 1)
    .split('&')
    .reduce<string | null>((acc, hash) => {
      const [key, value] = hash.split('=');
      return key === queryName ? value : acc;
    }, null);
};
