import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space, typography, TypographyProps } from 'styled-system';

import {
  ColorName,
  colors,
  fontSizes,
  media,
  spaces,
  zIndex,
} from '@gaming1/g1-style';
import {
  Box,
  FluidContentContainer,
  horizontalScrollWithoutScrollbarCss,
  SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX,
  SimpleButton,
} from '@gaming1/g1-ui';

import { Layout } from './types';

/* TODO: remove the backgrounds[index] when an element is in a special state
  for the tabs layout version of the menu. Instead a new color should be
  created or there could be a lighten/darken of the scrollNavBackground

  the tabs layout could also be possibly removed as it isn't used anywhere
*/

/* Constants */

const SCROLL_NAV_BORDER_HEIGHT_IN_PX = 2;
export const ICON_SIZE_FLEXTILE_IN_PX = 28;
export const MOBILE_ICON_SIZE_FLEXTILE_IN_PX = 21;
export const ICON_SIZE_IN_PX = 16;
export const ICON_SIZE_TILE_IN_PX = 18;
const FLEX_TILE_HEIGHT_IN_PX = 105;
const FLEX_TILE_BOTTOM_INDICATOR_HEIGHT_IN_PX = 4;
const FLEX_TILE_MIN_WIDTH_IN_PX = 150;
const MOBILE_FLEX_TILE_HEIGHT_IN_PX = 96;
const TILE_SIZE_IN_PX = 64;
const TILE_BOTTOM_INDICATOR_HEIGHT_IN_PX = 2;
const TILE_BOTTOM_INDICATOR_WIDTH_IN_PX = 8;
const TILE_BOTTOM_INDICATOR_BOTTOM_SPACE_IN_PX = 4;

const ACTIVE_CLASS_NAME = 'active';

/* Properties */

type WithHighlightedProps = {
  highlighted: boolean;
};
type WithLayoutProps = {
  layout: Layout;
};
type WithUnderlineProps = {
  hasUnderline: boolean;
};
type WithDisabledProps = {
  disabled?: boolean;
};

type ScrollNavContainerProps = {
  /** should the nav be sticky */
  shouldStick?: boolean;
  /** type of layout displayed */
  layout: Layout;
} & WithLayoutProps;

/* Helpers */

type ElementState = 'default' | 'active' | 'hover' | 'hightlight';

const borderBottomColorsByState: Record<ElementState, ColorName | null> = {
  default: null,
  hover: 'scrollNavItemBorderHovered',
  active: 'scrollNavItemBorderActive',
  hightlight: 'scrollNavItemForegroundHighlighted',
};

const getBorderBottomCss = (state: ElementState) => css<WithLayoutProps>`
  ${({ layout }) => {
    if (layout !== 'flexTiles' && layout !== 'tiles') {
      const borderColor = borderBottomColorsByState[state];
      return css`
        border-bottom: ${SCROLL_NAV_BORDER_HEIGHT_IN_PX}px solid
          ${borderColor ? colors(borderColor) : 'transparent'};
      `;
    }
    return '';
  }}
`;

/* Containers */

export const ScrollNavStickyContainer = styled(Box)<ScrollNavContainerProps>`
  /* TODO: decide whether to keep this or not since it create bugs in the layout  */
  /* getWrapperEnv() === 'rn' */
  background-color: ${colors('scrollNavBackground')};
  display: flex;
  justify-content: center;
  position: ${({ shouldStick }) => (shouldStick ? 'sticky' : 'relative')};
  top: 0;
  z-index: ${zIndex(2)};

  ${({ layout }) =>
    layout === 'altMenu' &&
    css`
      border-color: transparent;
      background-color: ${colors('backgrounds', { index: 2 })};
    `};

  ${({ layout }) =>
    layout === 'tabs' &&
    css`
      border-color: transparent;
    `}

  ${({ layout }) =>
    layout !== 'flexTiles' &&
    layout !== 'tiles' &&
    css`
      border-bottom: ${SCROLL_NAV_BORDER_HEIGHT_IN_PX}px solid
        ${colors('scrollNavBorder')};
    `}

  ${space}
`;

export const ScrollNavFluidContentContainer = styled(
  FluidContentContainer,
)<WithLayoutProps>`
  flex-direction: row;
  width: 100%;

  ${({ layout }) =>
    layout === 'flexTiles' &&
    css`
      padding: ${spaces('sm')} 0;
    `}
  ${({ layout }) =>
    layout === 'altMenu' &&
    css`
      padding: 0;
    `}
`;

export const ScrollNavContentContainer = styled(
  FluidContentContainer,
)<WithLayoutProps>`
  flex: 1;
  margin: ${SCROLL_NAV_BORDER_HEIGHT_IN_PX}px;
  overflow-x: hidden;
  padding: 0 0 !important;
  top: ${SCROLL_NAV_BORDER_HEIGHT_IN_PX * 2}px;
  width: unset;

  ${({ layout }) =>
    layout === 'altMenu' &&
    css`
      top: ${SCROLL_NAV_BORDER_HEIGHT_IN_PX}px;
      margin: 0;
    `}
`;

export const ScrollNavScrollableContainer = styled.nav`
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;
  display: flex;
  justify-content: stretch;

  ${horizontalScrollWithoutScrollbarCss};
`;

export const ScrollNavContent = styled.div<WithLayoutProps>`
  height: 100%;
  display: flex;
  flex: 1;
  white-space: nowrap;

  ${({ layout }) =>
    layout === 'menu'
      ? css`
          padding: 0 ${SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX}px;
        `
      : css`
          padding: 0;
        `}

  ${({ layout }) =>
    layout === 'flexTiles' &&
    css`
      padding-top: ${spaces('xxs')};
    `}
`;

/* Items */

const scrollNavItemContentTileCss = css<WithLayoutProps>`
  align-items: center;
  background-color: ${colors('scrollNavTileBackground')};
  border-radius: ${spaces('xs')};
  display: flex;
  flex-direction: column;
  height: ${TILE_SIZE_IN_PX}px;
  justify-content: center;
  position: relative;
  transition: none;
  width: ${TILE_SIZE_IN_PX}px;

  svg {
    margin: 0;
  }

  &:after {
    background-color: ${colors('backgrounds', { index: 0 })};
    bottom: ${TILE_BOTTOM_INDICATOR_BOTTOM_SPACE_IN_PX}px;
    content: '';
    height: ${TILE_BOTTOM_INDICATOR_HEIGHT_IN_PX}px;
    position: absolute;
    width: ${TILE_BOTTOM_INDICATOR_WIDTH_IN_PX}px;
  }
`;

const scrollNavItemContentFlexTileCss = css<WithLayoutProps>`
  ${scrollNavItemContentTileCss}

  background-color: ${colors('scrollNavFlexTileBackground')};
  height: ${MOBILE_FLEX_TILE_HEIGHT_IN_PX}px;
  min-width: ${MOBILE_FLEX_TILE_HEIGHT_IN_PX}px;
  overflow: hidden;
  width: 100%;

  ${media.lg} {
    height: ${FLEX_TILE_HEIGHT_IN_PX}px;
    min-width: ${FLEX_TILE_MIN_WIDTH_IN_PX}px;
  }

  &:after {
    background-color: ${colors('primary')};
    bottom: 0;
    content: none;
    height: ${FLEX_TILE_BOTTOM_INDICATOR_HEIGHT_IN_PX}px;
    position: absolute;
    width: 100%;
  }
`;

export const ScrollNavItemContent = styled.div<WithLayoutProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  position: relative;

  ${({ layout }) => layout === 'tiles' && scrollNavItemContentTileCss}
  ${({ layout }) => layout === 'flexTiles' && scrollNavItemContentFlexTileCss}
`;

const scrollNavItemBaseCss = css<WithLayoutProps & WithHighlightedProps>`
  cursor: pointer;
  font-size: ${fontSizes('sm')};
  height: 100%;
  padding: ${spaces('xs')} ${spaces('sm')};
  text-align: center;
  text-decoration: none;
  transition: border-bottom 0.2s, color 0.2s;

  /* Normal state */
  /* Label color */
  color: ${colors('scrollNavItemForeground')};
  /* Icon color */
  svg path {
    fill: ${colors('scrollNavItemForeground')};
  }
  /* Border color */
  ${getBorderBottomCss('default')}

  /* Hover/focus state */
  &:focus,
  &:hover {
    ${getBorderBottomCss('hover')}
  }

  /* Active state */
  &.${ACTIVE_CLASS_NAME}, &:active {
    /* Border color */
    ${getBorderBottomCss('active')}
    ${({ layout }) =>
      layout === 'flexTiles' &&
      css`
        svg path {
          fill: ${colors('scrollNavItemForegroundActive')};
        }
      `}
  }

  /* Highlight state */
  ${({ highlighted }) =>
    highlighted &&
    css`
      color: ${colors('scrollNavItemForegroundHighlighted')};
      svg path {
        fill: ${colors('scrollNavItemForegroundHighlighted')};
      }
    `}

  ${({ layout }) =>
    layout === 'flexTiles' &&
    css`
      flex: 1;
      font-size: ${fontSizes('sm')};
      padding: 0;
      margin-right: ${spaces('xs')};
      transition: transform 0.3s;

      ${media.lg} {
        font-size: ${fontSizes('lg')};
        margin-right: ${spaces('lg')};
      }

      &:last-child {
        margin-right: 0;
      }

      &:focus,
      &:hover {
        transform: translateY(-${spaces('xxs')});
      }

      & ${ScrollNavItemContent} {
        transition: background-color 0.3s;
      }
      &:hover ${ScrollNavItemContent} {
        background-color: ${colors('scrollNavTileBackgroundActive')};
      }
    `}

  ${({ layout }) =>
    layout === 'tiles' &&
    css`
      align-items: flex-start;
      border: initial;
      display: inline-flex;
      flex-direction: column;
      margin-right: ${spaces('xs')};
      padding: 0;

      &:hover ${ScrollNavItemContent} {
        background-color: ${colors('scrollNavTileBackgroundActive')};
      }

      /* Text color */
      &.${ACTIVE_CLASS_NAME}, &:active {
        color: ${colors('scrollNavItemForegroundActive')};
      }
    `}
`;

const scrollNavItemTabCss = css`
  background-color: ${colors('backgrounds', { index: 2 })};
  margin: 0 1px;
  min-width: 70px;

  &:hover {
    background-color: ${colors('backgrounds', { index: 1 })};
  }

  &.${ACTIVE_CLASS_NAME}, &:active {
    background-color: ${colors('backgrounds', { index: 1 })};
  }
`;

type ScrollNavItemLinkProps = TypographyProps &
  WithHighlightedProps &
  WithLayoutProps &
  WithUnderlineProps &
  WithDisabledProps;
export const ScrollNavItemLink = styled(NavLink)
  .withConfig({
    shouldForwardProp: (prop) =>
      !['highlighted', 'hasUnderline'].includes(prop),
  })
  .attrs({
    activeClassName: ACTIVE_CLASS_NAME,
  })<ScrollNavItemLinkProps>`
  ${scrollNavItemBaseCss}

  ${({ highlighted, layout }) =>
    layout === 'tabs' && highlighted === false && scrollNavItemTabCss}

  ${({ layout }) =>
    (layout === 'flexTiles' || layout === 'tiles') &&
    css`
      &.${ACTIVE_CLASS_NAME}, &:active {
        border: initial;

        ${ScrollNavItemContent} {
          background-color: ${layout === 'flexTiles'
            ? colors('scrollNavFlexTileBackgroundActive')
            : colors('scrollNavTileBackgroundActive')};

          &:after {
            background-color: ${colors('primary')};
            content: '';
          }
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
    `}
  
  ${typography}
`;

type ScrollNavItemAnchorProps = TypographyProps &
  WithHighlightedProps &
  WithLayoutProps &
  WithDisabledProps;
export const ScrollNavItemAnchor = styled.a<ScrollNavItemAnchorProps>`
  ${scrollNavItemBaseCss}

  /* An anchor should never be active */
  /* ...but has a different focus style */
  &:focus {
    border-bottom-style: dotted;
  }

  ${({ highlighted, layout }) =>
    layout === 'tabs' && highlighted === false && scrollNavItemTabCss}

  ${({ layout }) =>
    layout === 'tiles' &&
    css`
      &:hover {
        border-bottom: initial;
      }
      &:focus {
        outline: none;
        border-bottom: initial;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
    `}

  ${typography}
`;

type ScrollNavItemButtonProps = TypographyProps &
  WithHighlightedProps &
  WithLayoutProps &
  WithDisabledProps;
export const ScrollNavItemButton = styled(
  SimpleButton,
)<ScrollNavItemButtonProps>`
  ${scrollNavItemBaseCss}

  ${({ highlighted, layout }) =>
    highlighted === false && layout === 'tabs' && scrollNavItemTabCss}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
    `}

  ${typography}
`;

/* Labels */

type ScrollNavItemContentLabelProps = WithHighlightedProps & WithLayoutProps;

export const ScrollNavItemContentLabel = styled.div<ScrollNavItemContentLabelProps>`
  margin-top: ${spaces('xxs')};

  ${({ layout }) =>
    layout === 'tiles' &&
    css`
      margin: ${spaces('xxs')} auto 0;
      /* Force the items to keep the same width */
      max-width: calc(${TILE_SIZE_IN_PX}px - ${spaces('xs')});
      white-space: pre-line;
      overflow: hidden;
      /* Prevent long labels to break the layout */
      text-overflow: ellipsis;
    `}

  ${({ layout }) =>
    layout === 'flexTiles' &&
    css`
      margin: ${spaces('sm')} auto 0;
      /* Force the items to keep the same width */
      max-width: 90%;
      white-space: pre-line;
      overflow: hidden;
      /* Prevent long labels to break the layout */
      text-overflow: ellipsis;
    `}
`;

export const ScrollNavItemExtraLabel = styled.span`
  font-size: ${fontSizes(0)};
  color: ${colors('scrollNavButtonBackground')};
  position: absolute;
  top: ${spaces('xxs')};
  left: ${spaces('xxs')};
  padding: ${spaces('xxxs')};
`;

export const VerticalSeparator = styled.span`
  width: 2px;
  height: 60%;
  background-color: ${colors('scrollNavBorder')};
  margin: auto ${spaces('xs')};
  display: inline-block;
`;
