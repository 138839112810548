import { useContext } from 'react';

import { PrismicPageContext } from './components/PrismicPageContext';
import { parsePrismicInternalLink } from './helpers';
import { PrismicInternalLinkResult } from './types';

export const usePrismicInternalLink = (
  link?: string | null,
): PrismicInternalLinkResult => {
  const context = useContext(PrismicPageContext);

  return parsePrismicInternalLink(link, context?.customFunctions);
};
