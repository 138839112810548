import { ContextType } from 'react';

import { BettingAssetsContext } from '../../../assetsManagement/BettingAssetsContext';
import { ScoreBoardSportId } from '../../types';

/** Scoreboard background assets by sport id */
export const SCOREBOARD_BACKGROUNDS: {
  [k in ScoreBoardSportId]?: keyof ContextType<typeof BettingAssetsContext>;
} = {
  [ScoreBoardSportId.AmericanFootball]: 'americanFootballScoreBackground',
  [ScoreBoardSportId.Baseball]: 'baseballScoreBackground',
  [ScoreBoardSportId.Basketball]: 'basketballScoreBackground',
  [ScoreBoardSportId.Bowls]: 'bowlsScoreBackground',
  [ScoreBoardSportId.Boxing]: 'boxingScoreBackground',
  [ScoreBoardSportId.Cricket]: 'cricketScoreBackground',
  [ScoreBoardSportId.Cycling]: 'cyclingScoreBackground',
  [ScoreBoardSportId.Darts]: 'dartsScoreBackground',
  [ScoreBoardSportId.Football]: 'footballScoreBackground',
  [ScoreBoardSportId.Golf]: 'golfScoreBackground',
  [ScoreBoardSportId.Handball]: 'handballScoreBackground',
  [ScoreBoardSportId.IceHockey]: 'iceHockeyScoreBackground',
  [ScoreBoardSportId.MartialArts]: 'martialArtsScoreBackground',
  [ScoreBoardSportId.MixedMartialArts]: 'mixedMartialArtsScoreBackground',
  [ScoreBoardSportId.MotorCycleRacing]: 'motorCycleRacingScoreBackground',
  [ScoreBoardSportId.MotorSport]: 'motorSportScoreBackground',
  [ScoreBoardSportId.Rugby]: 'rugbyScoreBackground',
  [ScoreBoardSportId.Snooker]: 'snookerScoreBackground',
  [ScoreBoardSportId.StockCarRacing]: 'stockCarRacingScoreBackground',
  [ScoreBoardSportId.TableTennis]: 'tableTennisScoreBackground',
  [ScoreBoardSportId.Tennis]: 'tennisScoreBackground',
  [ScoreBoardSportId.Volleyball]: 'volleyballScoreBackground',
};
