import styled from 'styled-components';

import {
  fontSizes,
  fontWeight,
  media,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { AppButtonLink, Box, Text } from '@gaming1/g1-ui';

const GO_BACK_HOME_BUTTON_MIN_WIDTH = 332;
const GO_BACK_HOME_BUTTON_MIN_WIDTHV2 = 332;

export const FallbackWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin: ${spaces('md')} ${spaces('sm')};

  ${media.md} {
    margin: ${spaces('xl')} ${spaces('sm')};
  }
`;

export const PrimaryMessage = styled(Text)`
  font-size: ${fontSizes('xl')};
  margin-top: 0;
  margin-bottom: ${spaces('sm')};
  opacity: ${opacity('semi')};
  letter-spacing: 0.6px;
  font-weight: ${fontWeight('bold')};
  line-height: 30px;
  text-align: center;
`;

export const SecondaryMessage = styled(Text)`
  margin: 0;
  font-size: ${fontSizes('lg')};
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: ${spaces('xl')};
`;

export const StyledAppButtonLink = styled(AppButtonLink)`
  width: 100%;
  min-width: ${GO_BACK_HOME_BUTTON_MIN_WIDTH}px;
  ${media.sm} {
    width: auto;
  }
`;

export const StyledAppButtonLinkV2 = styled(AppButtonLink)`
  width: 100%;
  max-width: ${GO_BACK_HOME_BUTTON_MIN_WIDTHV2}px;
`;
