import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { actions, currentSectionSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { DrawerType, Icon, LayoutContext } from '@gaming1/g1-ui';
import { useDebounce } from '@gaming1/g1-utils';

import { useTracking } from '../../../tracking/hooks/useTracking';

import { ClearIconWrapper, SearchBarWrapper, SearchInput } from './styles';

type SearchBarProps = {
  /** Setter used to change the term value clicked on in the history */
  onSearchValueUpdate: (searchValue: string) => void;
  /** The term value to put in the search bar value */
  searchValue: string;
  /** Placerholder of the input */
  inputPlaceHolder?: string | null;
  /** Function to call to dispatch the new searched value (otherwise, dispatch actions.userSearch) */
  dispatchFct?: (value: string) => void;
  /** If specified, set the margin top of the component */
  mt?: number;
  /** If true, means that we are in drawer and must clean the term every time the drawer opens (default true) */
  isInDrawer?: boolean;
};

export const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      mt,
      onSearchValueUpdate,
      searchValue,
      inputPlaceHolder,
      dispatchFct,
      isInDrawer = false,
    },
    ref,
  ) => {
    const { t } = useTranslation('core');
    const dispatch = useDispatch();

    const defaultDispatchFct = useCallback(
      (value: string): void => {
        dispatch(actions.userSearch({ searchTerm: value }));
      },
      [dispatch],
    );

    const { pushEvent } = useTracking();
    const currentSection = useSelector(currentSectionSelector);
    const doDispatch = useCallback(
      (value: string): void => {
        (dispatchFct || defaultDispatchFct)(value);

        if (value.length > 1) {
          // Tracking: search request
          pushEvent({
            name: 'search',
            action: 'request',
            category: currentSection,
            label: isInDrawer ? 'drawer' : 'page',
            value,
          });
        }
      },
      [currentSection, defaultDispatchFct, dispatchFct, isInDrawer, pushEvent],
    );

    const { visibleDrawer } = useContext(LayoutContext);

    const searchDrawerVisibility = visibleDrawer === DrawerType.search;

    const { colors, space, opacity } = useContext(ThemeContext);

    const [isClearIconVisible, setIsClearIconVisible] = useState(false);

    const debouncedSearch = useDebounce(searchValue, 500);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onSearchValueUpdate(e.target.value);
    };

    const clearInputValue = useCallback(() => {
      onSearchValueUpdate('');
      doDispatch('');
    }, [onSearchValueUpdate, doDispatch]);

    useEffect(() => {
      if (!searchDrawerVisibility && isInDrawer) {
        clearInputValue();
      }
    }, [doDispatch, isInDrawer, clearInputValue, searchDrawerVisibility]);

    useEffect(() => {
      doDispatch(debouncedSearch);
    }, [doDispatch, debouncedSearch]);

    useEffect(() => {
      if (searchValue && searchValue.length > 0) {
        setIsClearIconVisible(true);
      } else if (searchValue.length === 0) {
        setIsClearIconVisible(false);
      }
    }, [searchValue, searchValue.length, setIsClearIconVisible]);

    return (
      <SearchBarWrapper
        alignItems="center"
        flexDirection="row"
        data-testid="search-bar-wrapper"
        mt={mt}
      >
        <Icon
          id="search-bar"
          type="MagnifyingGlass"
          fill={colors.backgrounds[2]}
          marginLeft={space.xxs}
        />
        <SearchInput
          id="user-search"
          onChange={handleChange}
          placeholder={inputPlaceHolder || t('search.placeholder')}
          simple
          type="text"
          value={searchValue}
          ref={ref}
          aria-label={inputPlaceHolder || t('search.placeholder')}
          autoComplete="off"
        />
        {isClearIconVisible ? (
          <ClearIconWrapper
            data-testid="clear-search-input"
            onClick={clearInputValue}
          >
            <Icon
              id="clear-search"
              type="DiscCross"
              fill={colors.backgrounds[2]}
              opacity={opacity.translucent}
              marginRight={space.xxs}
            />
          </ClearIconWrapper>
        ) : null}
      </SearchBarWrapper>
    );
  },
);
