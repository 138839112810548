import { FC } from 'react';

import {
  ScoreBoardPeriodType,
  ScoreBoardSportType,
} from '@gaming1/g1-requests-betting';

import { ScoreBoardSportId, ScoreBoardType } from '../../types';
import * as compact from '../ScoreBoardCompactTemplates';
import * as full from '../ScoreBoardFullTemplates';
import * as prematch from '../ScoreBoardPrematchTemplates';
import * as summary from '../ScoreBoardSummaryTemplates';

import { ScoreBoardTemplateProps } from './types';

/** List of sport templates */
export const SPORT_TEMPLATES: {
  [k in ScoreBoardSportId]?: {
    [kk in ScoreBoardType]?: FC<ScoreBoardTemplateProps>;
  };
} = {
  [ScoreBoardSportId.Badminton]: {
    compact: compact.ScoreBoardCompactPeriodTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardSportId.MotorSport]: {
    compact: compact.ScoreBoardCompactOneNameOnlyTemplate,
    full: full.ScoreBoardFullOneNameOnlyTemplate,
    summary: summary.ScoreBoardSummaryOneNameOnlyTemplate,
  },
  [ScoreBoardSportId.Snooker]: {
    compact: compact.ScoreBoardCompactPeriodTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardSportId.Squash]: {
    compact: compact.ScoreBoardCompactPeriodTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardSportId.TableTennis]: {
    compact: compact.ScoreBoardCompactPeriodTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardSportId.Volleyball]: {
    compact: compact.ScoreBoardCompactPeriodTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
};

/** List of parser templates */
export const PARSER_TEMPLATES: {
  [k in ScoreBoardSportType]?: {
    [kk in ScoreBoardType]?: FC<ScoreBoardTemplateProps>;
  };
} = {
  [ScoreBoardSportType.DefaultWithPoint]: {
    compact: compact.ScoreBoardCompactPointTemplate,
    full: full.ScoreBoardFullPointTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardSportType.DefaultWithPeriod]: {
    compact: compact.ScoreBoardCompactPointTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardSportType.Cricket]: {
    compact: compact.ScoreBoardCompactCricketTemplate,
    full: full.ScoreBoardFullCricketTemplate,
    summary: summary.ScoreBoardSummaryCricketTemplate,
  },
  [ScoreBoardSportType.Darts]: {
    compact: compact.ScoreBoardCompactDartsTemplate,
    full: full.ScoreBoardFullDartsTemplate,
    summary: summary.ScoreBoardSummaryDartsTemplate,
  },
  [ScoreBoardSportType.Tennis]: {
    compact: compact.ScoreBoardCompactTennisTemplate,
    full: full.ScoreBoardFullTennisTemplate,
    summary: summary.ScoreBoardSummaryTennisTemplate,
  },
};

/** List of fallback templates */
export const FALLBACK_TEMPLATES: {
  [k in ScoreBoardPeriodType]: {
    [kk in ScoreBoardType]: FC<ScoreBoardTemplateProps>;
  };
} = {
  [ScoreBoardPeriodType.Unknown]: {
    compact: compact.ScoreBoardCompactPointTemplate,
    full: full.ScoreBoardFullPointTemplate,
    prematch: prematch.ScoreBoardPrematchDefaultTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardPeriodType.PeriodBasedOnPoint]: {
    compact: compact.ScoreBoardCompactPointTemplate,
    full: full.ScoreBoardFullPeriodTemplate,
    prematch: prematch.ScoreBoardPrematchDefaultTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
  [ScoreBoardPeriodType.PeriodBasedOnTime]: {
    compact: compact.ScoreBoardCompactPointTemplate,
    full: full.ScoreBoardFullPointTemplate,
    prematch: prematch.ScoreBoardPrematchDefaultTemplate,
    summary: summary.ScoreBoardSummaryDefaultTemplate,
  },
};
