import React, {
  ButtonHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  RefCallback,
  RefObject,
  useCallback,
  useState,
} from 'react';
import MaskedInput, { Mask } from 'react-text-mask';

import { Theme } from '@gaming1/g1-style';

import { Icon } from '../Icon';
import { InputWrapper } from '../InputWrapper';
import { CommonInputProps } from '../types';

import {
  AppTextInput,
  BaseTextInput,
  InputSpan,
  SideIconButton,
  TextInputLabel,
  TextInputLabelSpan,
} from './styles';

/*
  Unforunately, react-text-mask is typing its ref as HTMLElement.
  TODO: change this lib to use something else that hasn't been abandonned
*/
type FixedMaskInputRenderRef =
  | RefCallback<HTMLInputElement>
  | RefObject<HTMLInputElement>
  | null
  | undefined;

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> &
  CommonInputProps & {
    /** Show label only when placeholder is hidden, true by default */
    autoHideLabel?: boolean;
    /**
     * Whether the input should take the whole width available
     * default: false
     */
    block?: boolean;
    /**
     * If we need to have a background
     * default: false
     */
    hasBackground?: boolean;
    /** If we need to add a full border to each sides of the input */
    hasBorder?: boolean;
    /** Specifies the color of the highlight, danger by default */
    highlightColor?: keyof Theme['colors'];
    /**
     * Optional property to decide where the sideIcon should be located. Right by default.
     */
    iconSide?: 'left' | 'right';
    /** input mask for formatting the value */
    mask?: Mask | ((value: string) => Mask);
    /** display mask '__-____-__', optional & true by default */
    maskVisibility?: boolean;
    /** Change handler */
    onValueChange?: (value: string) => void;
    /**
     * Optional input placeholder
     * Warning: the placeholder will be replaced by the label if a label is
     * provided and autoHideLabel is true
     */
    placeholder?: string;
    /**
     * An optional component that will be shown on the side of the input
     * Will not be displayed if toggleIcon is set
     */
    sideIcon?: ReactNode;
    /** Should the input content be hidden ? */
    secret?: boolean;
    /**
     * A side button allowing to trigger actions. Use 'secret' to include a
     * button which will toggle visibility on hidden fields. You can also use
     * a totally custom component to interact outside this component (useful
     * with info tooltips or custom dropdown lists for example).
     */
    toggleIcon?: ReactNode | 'secret';
    /**
     * Optional props for the button that will be displayed if toggleIcon is
     * set
     */
    toggleButtonProps?: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;
  };

/** Custom text input */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      autoHideLabel = true,
      className,
      error,
      disabled,
      errors = [],
      hasBorder = false,
      hasBackground = false,
      highlightColor = 'danger',
      maskVisibility = true,
      onBlur,
      onChange,
      onFocus,
      onValueChange,
      iconSide = 'right',
      id,
      info,
      label,
      mask,
      placeholder,
      sideIcon,
      simple,
      secret = false,
      testId = 'text-input',
      toggleButtonProps = {},
      toggleIcon,
      type = 'text',
      value = '',
      wrapperProps,
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);
    const [passwordInput, setPasswordInput] = useState(secret);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (onValueChange) {
          onValueChange(event.currentTarget.value);
        }
        if (onChange) {
          onChange(event);
        }
      },
      [onChange, onValueChange],
    );

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(event);
        }
        setFocused(true);
      },
      [onFocus],
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(event);
        }
        setFocused(false);
      },
      [onBlur],
    );

    if (simple) {
      return (
        <AppTextInput
          {...rest}
          aria-invalid={!!error || !!errors.length}
          block
          className={className}
          data-testid={testId}
          error={!!error || !!errors.length}
          hasBackground={hasBackground}
          disabled={disabled}
          hasBorder={hasBorder}
          highlightColor={highlightColor}
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          ref={ref}
          simple={simple}
          type={passwordInput ? 'password' : type}
          value={value}
        />
      );
    }

    const textInput = mask ? (
      <MaskedInput
        className={className}
        guide={maskVisibility}
        id={id}
        mask={mask}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={!autoHideLabel || !label ? placeholder : undefined}
        placeholderChar="_"
        render={(mref, props) => (
          <AppTextInput
            ref={mref as FixedMaskInputRenderRef}
            {...props}
            {...rest}
            type={type}
            block
            error={!!error || !!errors.length}
            aria-invalid={!!error || !!errors.length}
            data-testid={testId}
            hasBorder={hasBorder}
            hasBackground={hasBackground}
            highlightColor={highlightColor}
          />
        )}
        showMask={false}
        value={value}
      />
    ) : (
      <AppTextInput
        {...rest}
        aria-invalid={!!error || !!errors.length}
        block
        button={!!toggleIcon}
        className={className}
        data-testid={testId}
        error={!!error || !!errors.length}
        hasBackground={hasBackground}
        hasBorder={hasBorder}
        highlightColor={highlightColor}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={!autoHideLabel || !label ? placeholder : undefined}
        ref={ref}
        simple={simple}
        type={passwordInput ? 'password' : type}
        value={value}
        disabled={disabled}
      />
    );

    let sideContent: undefined | ReactNode = null;
    if (toggleIcon === 'secret') {
      sideContent = (
        <SideIconButton
          {...toggleButtonProps}
          error={!!error || !!errors.length}
          highlightColor={highlightColor}
          data-testid={`${testId}-toggle`}
          disabled={disabled}
          side={iconSide}
          onClick={(e) => {
            setPasswordInput(!passwordInput);
            if (toggleButtonProps.onClick) {
              toggleButtonProps.onClick(e);
            }
          }}
        >
          {passwordInput ? (
            <Icon type="EyeOpen" id="password-input-open" />
          ) : (
            <Icon type="EyeClose" id="password-input-close" />
          )}
        </SideIconButton>
      );
    } else if (toggleIcon) {
      sideContent = (
        <SideIconButton
          {...toggleButtonProps}
          disabled={disabled}
          error={!!error || !!errors.length}
          highlightColor={highlightColor}
          side={iconSide}
        >
          {toggleIcon}
        </SideIconButton>
      );
    } else if (sideIcon) {
      sideContent = (
        <SideIconButton
          removePaddingTop
          error={!!error || !!errors.length}
          highlightColor={highlightColor}
          side={iconSide}
        >
          {sideIcon}
        </SideIconButton>
      );
    }

    if (!label) {
      return (
        <InputWrapper
          errors={errors}
          focused={focused}
          hasBackground={hasBackground}
          highlightColor={highlightColor}
          info={info}
          testId={testId}
          mt={hasBorder ? 'none' : null}
          {...wrapperProps}
        >
          <InputSpan>
            {textInput}
            {sideContent}
          </InputSpan>
        </InputWrapper>
      );
    }

    return (
      <InputWrapper
        errors={errors}
        focused={focused}
        hasBackground={hasBackground}
        highlightColor={highlightColor}
        info={info}
        testId={testId}
        {...wrapperProps}
      >
        <TextInputLabel>
          <TextInputLabelSpan
            as="span"
            asPlaceholder={
              autoHideLabel && (value === '' || value === undefined) && !focused
            }
            error={!!error || !!errors.length}
            focused={focused}
            highlightColor={highlightColor}
          >
            {label}
          </TextInputLabelSpan>
          <InputSpan>
            {textInput}
            {sideContent}
          </InputSpan>
        </TextInputLabel>
      </InputWrapper>
    );
  },
);

export { AppTextInput, BaseTextInput, TextInputLabelSpan };
