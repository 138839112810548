import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { GetRegionsResponseType, GetTrendsResponseType } from './types';

/** Ask sports navigation (dispatch a sport request) */
export const askSportsNavigation = createAction(
  'betting/ask_sports_navigation',
)();

/** Ask region navigation if no request currently pending */
export const askRegionsNavigation = createAction(
  'betting/ask_regions_navigation',
)<{ sportId: number }>();

/** Ask trends navigation if no request currently pending */
export const askTrendsNavigation = createAction(
  'betting/ask_trends_navigation',
)<void>();

/** Get regions navigation */
export const getRegionsNavigation = createAsyncAction(
  'betting/get_regions_navigation_request',
  'betting/get_regions_navigation_success',
  'betting/get_regions_navigation_failure',
)<
  { sportId: number },
  { data: GetRegionsResponseType; request: { sportId: number } },
  { data: FailurePayload; request: { sportId: number } }
>();

/** Get trends navigation */
export const getTrendsNavigation = createAsyncAction(
  'betting/get_trends_navigation_request',
  'betting/get_trends_navigation_success',
  'betting/get_trends_navigation_failure',
)<void, GetTrendsResponseType, FailurePayload>();
