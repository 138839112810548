/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  CanPlayerResetVipStatusRequestDTO,
  CanPlayerResetVipStatusResponseDTO,
  FeatureEnabledRequestDTO,
  FeatureEnabledResponseDTO,
  GetMinimalVipRankToAllowResetRequestDTO,
  GetMinimalVipRankToAllowResetResponseDTO,
  GetVipInitializationRequestDTO,
  GetVipInitializationResponseDTO,
  PlayMonthlyBoxRequestDTO,
  PlayMonthlyBoxResponseDTO,
  PlayVipWheelRequestDTO,
  PlayVipWheelResponseDTO,
  ResetVipStatusRequestDTO,
  ResetVipStatusResponseDTO,
} from '../entities';

export const getVipInitialization = (options: GetVipInitializationRequestDTO) =>
  createRequest<GetVipInitializationResponseDTO>(
    104,
    'GetVipInitialization',
    options,
  );

export const playVipWheel = (options: PlayVipWheelRequestDTO) =>
  createRequest<PlayVipWheelResponseDTO>(104, 'PlayVipWheel', options);

export const playMonthlyBox = (options: PlayMonthlyBoxRequestDTO) =>
  createRequest<PlayMonthlyBoxResponseDTO>(104, 'PlayMonthlyBox', options);

export const resetVipStatus = (options: ResetVipStatusRequestDTO) =>
  createRequest<ResetVipStatusResponseDTO>(104, 'ResetVipStatus', options);

export const getMinimalRankToAllowStatusReset = (
  options: GetMinimalVipRankToAllowResetRequestDTO,
) =>
  createRequest<GetMinimalVipRankToAllowResetResponseDTO>(
    104,
    'GetMinimalRankToAllowStatusReset',
    options,
  );

export const canPlayerResetVipStatus = (
  options: CanPlayerResetVipStatusRequestDTO,
) =>
  createRequest<CanPlayerResetVipStatusResponseDTO>(
    104,
    'CanPlayerResetVipStatus',
    options,
  );

export const featureIsEnabled = (options: FeatureEnabledRequestDTO) =>
  createRequest<FeatureEnabledResponseDTO>(104, 'FeatureIsEnabled', options);
