import React, { FC, memo, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { Box } from '@gaming1/g1-ui';

import { BettingSlipKeyboard } from './BettingSlipKeyBoard';
import { BettingSlipSuggestion } from './BettingSlipSuggestion';
import { KeyboardKey } from './type';

type BettingSlipNumpadProps = {
  /** Need to show suggestion */
  readonly showSuggestion?: boolean;
  /** Need to show keyboard */
  readonly showKeyBoard: boolean;
  /** actual stake */
  readonly stake: string;
  /** max stake available */
  readonly stakeMax?: number;
  /** update the stake */
  updateStake: (stake: string) => void;
  /** need to close numpad */
  askClose: () => void;
};

export const BettingSlipNumpadComponent: FC<BettingSlipNumpadProps> = ({
  showSuggestion = false,
  showKeyBoard,
  stake,
  stakeMax,
  updateStake,
  askClose,
}) => {
  const [resetStake, setResetStake] = useState(true);

  const suggestionSelected = (selected: string) => {
    updateStake(selected);
    setResetStake(true);
  };

  const keyPress = (key: KeyboardKey) => {
    if (key === 'del') {
      updateStake(stake.substring(0, stake.length - 1));
      setResetStake(false);
    } else if (key === 'ok') {
      askClose();
      setResetStake(true);
    } else if (key === 'delimiter') {
      const alreadyHaveDelimiter = stake.indexOf('.') > -1;
      if (!alreadyHaveDelimiter) {
        updateStake(`${stake}.`);
      }
    } else {
      updateStake(resetStake ? key : stake + key);
      setResetStake(false);
    }
  };
  return (
    <Box alignItems="center" mb="xs" width="100%">
      {showSuggestion ? (
        <BettingSlipSuggestion
          suggestionSelected={suggestionSelected}
          stakeMax={stakeMax}
        />
      ) : undefined}
      {showKeyBoard ? <BettingSlipKeyboard keyPress={keyPress} /> : undefined}
    </Box>
  );
};

export const BettingSlipNumpad = memo(BettingSlipNumpadComponent, shallowEqual);
