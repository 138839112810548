import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as pipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const promotionReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.addPromotionToBS): {
        const { payload } = action;
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].selectedPromotion =
            payload;
        }
        break;
      }
      case getType(actions.removePromotionToBS):
      case getType(pipeActions.resetBettingSlip.request):
      case getType(pipeActions.launchPlaceBet.success): {
        const { payload } = action;

        if (!payload.bettingSlipId) {
          break;
        }

        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].selectedPromotion =
            null;
        }
        break;
      }
      default: // Immer will automatically return the state
    }
  });
