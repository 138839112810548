import { Location } from 'history';
import { createContext } from 'react';

export type HistoryContextValue = {
  exitFullscreenLocation: Location;
  isBottomNavVisibleInFullscreen: boolean;
  isFullscreenLayout: boolean;
  previousLocation: Location | null;
  setFullscreenLayout: (
    isFullScreen: boolean,
    isBottomNavVisible?: boolean,
  ) => void;
};

export const historyContextDefaultValue: HistoryContextValue = {
  exitFullscreenLocation: { hash: '', pathname: '/', search: '', state: null },
  isFullscreenLayout: false,
  isBottomNavVisibleInFullscreen: false,
  previousLocation: null,
  setFullscreenLayout: () => null,
};

export const HistoryContext = createContext<HistoryContextValue>(
  historyContextDefaultValue,
);
