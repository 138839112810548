import * as t from 'io-ts';

import { EResponseStatus } from '@gaming1/g1-requests-betting';

import { filterCodec, leagueCodec, nullable } from './entities';

export const getLiveCountResponseCodec = t.type(
  {
    LiveCount: t.number,
    Status: t.literal(EResponseStatus.Successfull),
  },
  'GetLiveCountResponseCodec',
);

export const getLiveCountBySportResponseCodec = t.type(
  {
    CountBySportId: t.array(t.type({ Count: t.number, Id: t.number })),
    Status: t.literal(EResponseStatus.Successfull),
  },
  'GetLiveCountBySportResponseCodec',
);

export const getFilterResponseResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
      Filters: t.array(filterCodec),
    }),
    t.partial({}),
  ],
  'GetLiveCountBySportResponseCodec',
);

/**
 * Works for getLives, getTopEvents, getLiveSport and getPrematchSport
 */
export const baseSportbookResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      Leagues: t.array(leagueCodec),
    }),
  ],
  'BaseSportbookResponseCodec',
);

export const getLeagueResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      League: nullable(leagueCodec),
    }),
  ],
  'GetLeagueResponseCodec',
);

/**
 * Event Response
 */
export const eventResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      League: t.union([t.null, leagueCodec]),
    }),
  ],
  'EventResponseCodec',
);
