import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { CoreActions } from '../types';

import * as actions from './actions';
import { UserCoreState } from './types';

export const initialState: UserCoreState = {
  requests: {
    userCredit: generateInitialRequestState(),
    userProfile: generateInitialRequestState(),
  },
  userProfile: null,
  userCredit: null,
  userLoggedIn: null,
};

export const userCoreReducer = (
  state: UserCoreState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.loggedInUser):
        draftState.userLoggedIn = true;
        break;

      // Reset the whole store slice
      case getType(actions.loggedOutUser):
        return {
          ...initialState,
          userLoggedIn: false,
        };

      case getType(actions.getUserProfile.request):
        produceLoadingState(draftState.requests.userProfile);
        break;

      case getType(actions.getUserProfile.failure):
        produceFailureState(draftState.requests.userProfile, action.payload);
        break;

      case getType(actions.getUserProfile.success):
        produceSuccessState(draftState.requests.userProfile);
        draftState.userProfile = action.payload;
        break;

      case getType(actions.getUserCredit.request):
        produceLoadingState(draftState.requests.userCredit);
        break;

      case getType(actions.getUserCredit.failure):
        produceFailureState(draftState.requests.userCredit, action.payload);
        break;

      case getType(actions.getUserCredit.success):
        produceSuccessState(draftState.requests.userCredit);
        draftState.userCredit = action.payload;
        break;

      case getType(actions.updateUserCredit):
        draftState.userCredit = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
