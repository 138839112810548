import React, { forwardRef, SelectHTMLAttributes, useState } from 'react';

import { InputWrapper } from '../InputWrapper';
import { TextInputLabel, TextInputLabelSpan } from '../TextInput/styles';
import { CommonInputProps } from '../types';

import { AppSelect, SelectArrowIcon, SelectContainerDiv } from './styles';
import { ArrowType, OptionValue } from './types';

// TODO: find a way to accept and correctly type select with number/string options
// See https://mariusschulz.com/blog/passing-generics-to-jsx-elements-in-typescript
// The issue is that we are using forwardRef making the use of generic difficult

export type Option<V extends OptionValue> = {
  // Value of the <option /> element
  value: V;
  // Text inside the <option> element
  label: string;
  // Key that will overwrite the default one
  key?: string;
};

export type SelectProps<V extends OptionValue = string> = Omit<
  SelectHTMLAttributes<HTMLSelectElement> & CommonInputProps,
  'value'
> & {
  /** Style of the arrow */
  arrowType?: ArrowType;
  /** ClassName of the outer div */
  containerClass?: string;
  /** Shows an empty value by default (field label) */
  emptyByDefault?: boolean;
  /** Text to display for the empty option */
  emptyOptionLabel?: string;
  /** Change handler */
  onValueChange?: (value: string) => void;
  /** List of options */
  options: Option<V>[];
  /** Current value */
  value?: V;
};

/** Custom select input */
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      arrowType = 'default',
      containerClass,
      emptyByDefault = false,
      emptyOptionLabel,
      errors,
      id,
      info,
      label,
      onBlur,
      onChange,
      onFocus,
      onValueChange,
      options,
      simple,
      testId = 'select',
      value,
      wrapperProps,
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);
    const select = (
      <SelectContainerDiv className={containerClass} arrowType={arrowType}>
        <AppSelect
          data-testid={testId}
          error={!!errors && !!errors.length}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
            setFocused(false);
          }}
          onFocus={(e) => {
            if (onFocus) {
              onFocus(e);
            }
            setFocused(true);
          }}
          onChange={(event) => {
            if (onValueChange) {
              onValueChange(
                options[
                  emptyByDefault
                    ? event.currentTarget.selectedIndex - 1
                    : event.currentTarget.selectedIndex
                ].value,
              );
            }
            if (onChange) {
              onChange(event);
            }
          }}
          ref={ref}
          value={value}
          {...rest}
        >
          {[
            emptyByDefault ? (
              <option disabled hidden key="option-default" value="">
                {emptyOptionLabel || emptyOptionLabel === ''
                  ? emptyOptionLabel
                  : label}
              </option>
            ) : null,
            ...options.map((option) => (
              <option key={option.key || option.value} value={option.value}>
                {option.label}
              </option>
            )),
          ]}
        </AppSelect>
        {arrowType === 'default' && (
          <SelectArrowIcon type="AngleDown" id="select-arrow" />
        )}
      </SelectContainerDiv>
    );
    if (simple) {
      return select;
    }
    return (
      <InputWrapper
        errors={errors}
        info={info}
        testId={testId}
        {...wrapperProps}
      >
        {!!label && (
          <TextInputLabel id={id}>
            <TextInputLabelSpan
              as="span"
              error={!!errors && !!errors.length}
              focused={focused}
            >
              {label}
            </TextInputLabelSpan>
          </TextInputLabel>
        )}
        {select}
      </InputWrapper>
    );
  },
);
