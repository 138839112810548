import styled from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const Container = styled(Box)`
  flex-direction: row;
  > * + * {
    margin-left: ${spaces('sm')};
  }
`;
