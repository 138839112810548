import styled, { css } from 'styled-components';

import { colors, fontWeight, spaces } from '@gaming1/g1-style';
import { Heading } from '@gaming1/g1-ui';

// dupplicate from packages/g1-user-web/src/history/components/styles.tsx
// TODO: create a generic History component that accept any data and uses those
// styles to be able to create history modules easily from any packages without
// duplication

export const MoreInformationModalTitle = styled(Heading).attrs(() => ({
  level: 'h2',
}))`
  ${fontWeight('bold')};
  margin-top: 0;
`;

export const MoreInformationModalWrapper = styled.div`
  margin-top: ${spaces('sm')};
`;

export const MoreInformationModalRow = styled.div`
  display: flex;
  margin-top: ${spaces('xs')};
`;

export const MoreInformationModalLabel = styled.div`
  ${fontWeight('bold')};
  flex-basis: 50%;
`;

export const MoreInformationModalValue = styled.div`
  flex-basis: 50%;
`;

type FreeSpinsHistoryWinningsSpanProps = {
  winner?: boolean;
};

export const FreeSpinsHistoryWinningsSpan = styled.span<FreeSpinsHistoryWinningsSpanProps>`
  ${({ winner }) =>
    winner &&
    css`
      ${fontWeight('bold')};
      color: ${colors('success')};
    `}
`;
