import React, { FC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  bettingHistoryByIdGetterSelector,
  BettingHistoryElementType,
  getCombinationLabel,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { CombinationLabel } from './styles';
import { TypeViewStyle } from './types';

type LabelInfoProps = {
  info: BettingHistoryElementType;
  bettingHistoryId: string;
} & TypeViewStyle;
export const LabelInfo: FC<LabelInfoProps> = ({
  info,
  bettingHistoryId,
  view,
}) => {
  const history = useBettingGetterSelector({
    getterSelector: bettingHistoryByIdGetterSelector,
    args: [bettingHistoryId],
    equalityFn: shallowEqual,
  });

  const elements = history?.Elements?.filter(isNonNullable);

  if (!elements) {
    return null;
  }
  return (
    <CombinationLabel
      view={view}
      data-testid="history-element-combinasion-label"
    >
      {getCombinationLabel(elements || [], [info])}.
    </CombinationLabel>
  );
};
