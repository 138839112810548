import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { outcomeListSelector } from '../../../sportbook/store/selectors';
import { calcOddsMultiplying, calcOddsWithBoostus } from '../../helpers/calc';

import {
  bettingSlipItemsGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  boostusSelectedForBSGetterSelector,
} from './common';
import { getFinalOddsOfBettingSlipItemGetterSelector } from './elements/bettingslipItem';
import { extractOddsFromOutcomes, extractOutcomeListId } from './helpers';

/**
 * Get the base odds of a betting slip.
 *
 * Note that if an odd is negative or undefined, it'll always be rounded up to 1.
 */
export const bettingSlipBaseOddsGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  outcomeListSelector,
  bettingSlipRoundingConfigGetterSelector,
  (bettingSlipItems, outcomeList, roundingConfig) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const ids = extractOutcomeListId(bettingSlipItems(bettingSlipId) || []);
      const outcomes = outcomeList(ids);

      return calcOddsMultiplying(roundingConfig(bettingSlipId))(
        extractOddsFromOutcomes(outcomes),
      );
    }),
);

export const bettingSlipFinalOddsBasedOnElementsGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  (itemsFromBettingSlip, itemFinalOdds, roundingConfig) =>
    memoize((bettingSlipId) => {
      const items = itemsFromBettingSlip(bettingSlipId);

      if (!items || items.length < 1) {
        return 1;
      }

      const odds = items.map((item) =>
        itemFinalOdds(bettingSlipId, item.outcome.id),
      );

      return calcOddsMultiplying(roundingConfig(bettingSlipId))(odds);
    }),
);

/**
 * Get the odd of bettingslip after applying boostus
 */
export const bettingSlipFinalOddsGettorSelector = createSelector(
  boostusSelectedForBSGetterSelector,
  bettingSlipFinalOddsBasedOnElementsGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  (selectedBoostus, finalOddsSelector, roundingConfig) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const selectedBoostusValue =
        selectedBoostus(bettingSlipId)?.boostValue || 0;

      // TODO : Don't put boostus value when using a opti odds
      const finalOdds = finalOddsSelector(bettingSlipId);

      return calcOddsWithBoostus(roundingConfig(bettingSlipId))(
        finalOdds,
        selectedBoostusValue,
      );
    }),
);
