import React, { useCallback, VFC } from 'react';

import {
  SearchCompetitionType,
  sportAliasSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import {
  SearchLayout,
  SearchResultDisplay,
  useTracking,
} from '@gaming1/g1-core-web';

import { useBettingRoutePath } from '../../../hooks';
import { getIconType } from '../../../sportbook/helpers';
import { BettingLink } from '../BettingSearchTrends/styles';

import { SearchResultWrapper } from './styles';

type SearchCompetitionItemProps = {
  competition: SearchCompetitionType;
  searchTerms: string[];
  context: 'page' | 'drawer';
  layout?: SearchLayout;
};

/** Render the result title, description and will highlight the matching part of the result title  */
export const SearchCompetitionItem: VFC<SearchCompetitionItemProps> = ({
  competition,
  searchTerms,
  context,
  layout = 'normal',
}) => {
  const sportAlias = useBettingGetterSelector({
    getterSelector: sportAliasSelector,
    args: [competition.SportId],
  });

  const getBettingRoutePath = useBettingRoutePath();
  const url = getBettingRoutePath('league', {
    sportId: `${competition.SportId || ''}`,
    regionId: `${competition.RegionId || ''}`,
    leagueId: `${competition.LeagueId || ''}`,
  });

  const { pushEvent } = useTracking();

  const onClick = useCallback(() => {
    // Tracking: search item clicked
    pushEvent({
      name: 'search',
      action: 'competition clicked',
      category: 'betting',
      label: context,
      value: competition.LeagueName || '',
      searchTerm: searchTerms.join(' '),
    });
  }, [competition.LeagueName, context, pushEvent, searchTerms]);

  return (
    <BettingLink
      to={url}
      data-testid="search-competition-item-link"
      isNormalLayout={layout === 'normal'}
    >
      <SearchResultWrapper
        width="100%"
        data-testid="search-competition-item-wrapper"
        onClick={onClick}
      >
        <SearchResultDisplay
          key={competition.LeagueId}
          icon={getIconType(sportAlias)}
          title={competition.LeagueName || ''}
          layout={layout}
          matchingTerms={searchTerms}
        />
      </SearchResultWrapper>
    </BettingLink>
  );
};
