import { createContext } from 'react';

import { generateInitialRequestState, RequestState } from '@gaming1/g1-utils';

type BettingUserSettings = {
  bSendRegistrationMail: boolean;
  bSendResultMail: boolean;
  bCleanBettingSlips: boolean;
  // Keys from EBettingSlipOddChangeSettings
  OddChangeSettings: 'None' | 'OnlyHigherOdds' | 'AllOdds';
};

const noop = () => null;

export const BettingUserSettingsContext = createContext<{
  /** Fetch the user preferences regarding the betting */
  getBettingUserSettings: () => void;
  /** Update the user preferences regarding the betting */
  updateBettingUserSettings: (settings: Partial<BettingUserSettings>) => void;
  /** User preferences for the betting */
  bettingUserSettings: BettingUserSettings | null;
  /** State of the request fetching the user betting preferences */
  getBettingUserSettingsRequestState: RequestState;
  /** State of the request updating the user betting preferences */
  updateBettingUserSettingsRequestState: RequestState;
}>({
  getBettingUserSettings: noop,
  updateBettingUserSettings: noop,
  getBettingUserSettingsRequestState: generateInitialRequestState(),
  updateBettingUserSettingsRequestState: generateInitialRequestState(),
  bettingUserSettings: null,
});
