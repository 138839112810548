import { Reducer } from 'redux';

import { FluxStandardAction } from '@gaming1/g1-utils';

// From https://github.com/redux-utilities/reduce-reducers/pull/27/files
/**
 * Combine reducers at the root level, calling one after the other
 * (unlinke combineReducers from redux)
 */
export const reduceReducers = <
  State,
  Actions extends { type: string } = FluxStandardAction,
>(
  initialState: State,
  ...reducers: Reducer<State, Actions>[]
): Reducer<State, Actions> => {
  if (!reducers.length) {
    throw new Error('No reducer was provided');
  }
  const rootReducer: Reducer<State, Actions> = (
    prevState: State = initialState,
    action: Actions | undefined,
  ): State => {
    if (typeof action === 'undefined') {
      return prevState;
    }

    return reducers.reduce(
      (newState, reducer) => reducer(newState, action),
      prevState,
    );
  };
  return rootReducer;
};
