import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import {
  useFormatMoney,
  userCreditSelector,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';

import { SuggestionBox, SuggestionList } from './styles';

type BettingSlipSuggestionProps = {
  /** Maximum stake available */
  stakeMax?: number;
  /** Suggestion selected */
  suggestionSelected: (selection: string) => void;
};

export const BettingSlipSuggestionComponent: FC<BettingSlipSuggestionProps> = ({
  stakeMax,
  suggestionSelected,
}) => {
  const { t } = useTranslation('betting');
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const userCredit = useSelector(userCreditSelector);
  const formatMoney = useFormatMoney();

  const config = useConfig();

  const { suggestions } = config.betting.bettingslip;

  const selection = (selectedValue: number | string) => {
    suggestionSelected(selectedValue.toString());
  };
  const selectMax = () => {
    if (!isUserLoggedIn) {
      return;
    }

    if (stakeMax && userCredit) {
      if (userCredit.AvailableAmout >= stakeMax) {
        if (stakeMax) {
          suggestionSelected(stakeMax.toString());
        }
      } else {
        suggestionSelected(userCredit.AvailableAmout.toString());
      }
    }
  };
  return (
    <SuggestionList>
      {suggestions.map((suggestion) => (
        <SuggestionBox
          onClick={() => selection(suggestion)}
          key={suggestion}
          data-testid="stake-box"
        >
          {formatMoney(suggestion, true)}
        </SuggestionBox>
      ))}
      <SuggestionBox
        disabled={!isUserLoggedIn}
        onClick={selectMax}
        data-testid="stake-box-max"
      >
        {t('bettingslip.suggestion.max')}
      </SuggestionBox>
    </SuggestionList>
  );
};

export const BettingSlipSuggestion = memo(BettingSlipSuggestionComponent);
