/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:17:08 17-01-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  ScratchCardDetailsRequest,
  ScratchCardDetailsResponse,
} from '../entities';

export const scratchCardDetails = (options: ScratchCardDetailsRequest) =>
  createRequest<ScratchCardDetailsResponse>(100, 'ScratchCardDetails', options);
