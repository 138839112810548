import { DidomiSDK } from '@didomi/react';
import React, { createContext, FC, memo, useContext, useState } from 'react';

import { ConfigContext } from '@gaming1/g1-config';

import { logger } from '../../../logger';

type PrivacySettingsContextValue = {
  /** Get the consent status for the given purpose ID */
  getUserConsentStatusForPurpose?: (purpose: string) => boolean | undefined;
  /** Get the consent status for the given vendor ID */
  getUserConsentStatusForVendor?: (vendor: string) => boolean | undefined;
  /** Opens the privacy settings modal */
  openPrivacySettings?: () => void;
};

/**
 * This context allows a third party app (e.g. Didomi) to initialize before
 * making a privacy settings link available. So when initializing the third
 * party app, this context should be updated with said function.
 */
export const PrivacySettingsContext =
  createContext<PrivacySettingsContextValue>({});

/**
 * This provider automatically includes the DidomiSDK which handles the privacy
 * settings modal on its own and provides the context to handle anything about
 * privacy settings and user consents.
 */
export const PrivacySettingsProvider: FC = memo(({ children }) => {
  const [privacyContextValue, setPrivacyContextValue] =
    useState<PrivacySettingsContextValue>({});
  const {
    core: { privacySettings },
  } = useContext(ConfigContext);
  const hydrateContext: DidomiReact.OnReadyFunction = (didomi) => {
    setPrivacyContextValue({
      getUserConsentStatusForPurpose: didomi.getUserConsentStatusForPurpose,
      getUserConsentStatusForVendor: didomi.getUserConsentStatusForVendor,
      openPrivacySettings: didomi.preferences.show,
    });
    logger.debug('[Privacy Settings] Didomi SDK loaded');
  };

  return (
    <PrivacySettingsContext.Provider value={privacyContextValue}>
      {privacySettings.type === 'Didomi' && privacySettings.didomiApiKey && (
        <DidomiSDK
          apiKey={privacySettings.didomiApiKey}
          /*
           * See https://developers.didomi.io/cmp/web-sdk/consent-notice/bots
           * for more information about bots behaviors configuration.
           */
          config={{ user: { bots: { consentRequired: false } } }}
          onReady={hydrateContext}
        />
      )}
      {children}
    </PrivacySettingsContext.Provider>
  );
});
