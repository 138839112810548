/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { TopEventsRequest, TopEventsResponse } from '../entities';

export const getTopEvents = (options: TopEventsRequest) =>
  createRequest<TopEventsResponse>(201, 'GetTopEvents', options);
