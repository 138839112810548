import { memo, VFC } from 'react';

import { Box, BoxProps, Loader } from '@gaming1/g1-ui';

type WrappedLoaderProps = {
  testId?: string;
} & BoxProps;

/**
 * Render the loader with good and common margin by default
 */
const WrappedLoaderComponent: VFC<WrappedLoaderProps> = ({
  testId = 'wrapped-loader',
  ...props
}) => (
  <Box
    mt={props.mt || 'xxl'}
    mb={props.mb || 'xxl'}
    data-testid={testId}
    {...props}
  >
    <Loader />
  </Box>
);

export const WrappedLoader = memo(WrappedLoaderComponent);
