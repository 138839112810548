import { createAsyncAction } from 'typesafe-actions';

import { EActivitySource } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetConnectionLimitRemainingTimeResponse,
  GetDepositLimitsResponse,
  GetLimitsResponse,
  GetRemainingTimeToSpendResponse,
  LimitsActivities,
  SetLimitsRequest,
  SetLimitsResult,
} from './types';

/* Async actions */

/** Set limits */
export const setLimits = createAsyncAction(
  'user/set_limits_request',
  'user/set_limits_success',
  'user/set_limits_failure',
)<
  { data: SetLimitsRequest; params: LimitsActivities },
  { data: SetLimitsResult; params: LimitsActivities },
  { data: FailurePayload; params: LimitsActivities }
>();

/** Get limits */
export const getLimits = createAsyncAction(
  'user/get_limits_request',
  'user/get_limits_success',
  'user/get_limits_failure',
)<
  LimitsActivities,
  {
    data: GetDepositLimitsResponse | GetLimitsResponse;
    params: LimitsActivities;
  },
  { data: FailurePayload; params: LimitsActivities }
>();

/** Get remaining connection time */
export const getRemainingConnectionTime = createAsyncAction(
  'user/get_remaining_connection_time_request',
  'user/get_remaining_connection_time_success',
  'user/get_remaining_connection_time_failure',
)<void, GetConnectionLimitRemainingTimeResponse, FailurePayload>();

export const getRemainingTimeToSpend = createAsyncAction(
  'user/get_remaining_time_to_spend_request',
  'user/get_remaining_time_to_spend_success',
  'user/get_remaining_time_to_spend_failure',
)<
  { params: { source: EActivitySource } },
  {
    data: GetRemainingTimeToSpendResponse;
    params: { source: EActivitySource };
  },
  { data: FailurePayload; params: { source: EActivitySource } }
>();
