import { normalize, schema } from 'normalizr';

import { GetShopProductsRequestDTO } from '@gaming1/g1-requests';

import {
  GetShopProductsResponse,
  PaginatedProductsData,
  ProductsData,
} from './types';

const products = new schema.Entity('products', {}, { idAttribute: 'Id' });
const productItems = new schema.Array(products);

export const formatGetShopProducts = (
  request: GetShopProductsRequestDTO,
  response: GetShopProductsResponse,
): PaginatedProductsData => {
  const normalizedData = normalize(response.Products, productItems);
  const entities = normalizedData.entities as PaginatedProductsData['entities'];
  const { result } = normalizedData;

  return {
    entities,
    result,
    pagination: {
      PageNumber: request.PageIndex,
      PageSize: request.PageCount,
      Total: response.Count,
    },
  };
};

export const formatGetShopProductsById = (
  response: GetShopProductsResponse,
): ProductsData => {
  const normalizedData = normalize(response.Products, productItems);
  const entities = normalizedData.entities as ProductsData['entities'];
  const { result } = normalizedData;

  return { entities, result };
};
