import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';
import { Box, CollapsiblePanel, Text } from '@gaming1/g1-ui';

const MAX_LINES = 2;

type CardTitleProps = {
  color: string;
  maxLines?: number;
};

export const CardTitle = styled(Text)<CardTitleProps>`
  ${fontWeight('bold')};
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /** We need to override the white-space from the g1-ui Text component */
  white-space: normal;
  font-size: ${fontSizes('xl')};
  line-height: 23px;

  ${({ maxLines }) =>
    maxLines
      ? css`
          -webkit-line-clamp: ${maxLines};
        `
      : css`
          -webkit-line-clamp: ${MAX_LINES};
        `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

type ContentContainerProps = {
  toBottom?: boolean;
};

export const ContentContainer = styled(Box)<ContentContainerProps>`
  justify-content: space-between;
  height: 100%;
  color: ${colors('neutrals', { index: 2 })};

  ${({ toBottom }) =>
    toBottom
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: space-between;
        `}
`;

type CardDescriptionProps = {
  enableMaxLines?: boolean;
};

export const CardDescription = styled(Text)<CardDescriptionProps>`
  ${fontWeight('bold')};
  font-size: ${fontSizes('md')};
  color: ${colors('neutrals', { index: 2 })};
  margin-top: ${spaces('sm')};

  ${space}

  ${({ enableMaxLines }) =>
    enableMaxLines &&
    css`
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${MAX_LINES};
      /** We need to override the white-space from the g1-ui Text component */
      white-space: normal;
    `}
`;

export const CardContent = styled(Box)``;

export const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  white-space: pre-line;
`;
