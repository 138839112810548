import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { SearchGamesData, SearchGamesRequest } from './types';

export const searchGames = createAsyncAction(
  'gaming/search_games_by_term_request',
  'gaming/search_games_by_term_success',
  'gaming/search_games_by_term_failure',
)<
  SearchGamesRequest,
  { data: SearchGamesData; request: SearchGamesRequest },
  { data: FailurePayload; request: SearchGamesRequest }
>();
