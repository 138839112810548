import React, { FC, memo, useContext } from 'react';

import {
  actions,
  bettingSlipStakeGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  isHighlightStakeByError,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
  useBettingSlipCombiErrorFrontType,
  useBettingSlipGetCombiMaxStake,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipCombiError } from '../BettingSlipError/BettingSlipCombiError';
import { BettingSlipNumpad } from '../BettingSlipNumpad';
import { useNumpadActionManage } from '../BettingSlipNumpad/hooks';

import { BettingSlipStakeBox } from './styles';

import { BettingSlipStake } from '.';

/**
 * Display the stake for an item
 * show and manage stake
 */
export const BettingSlipCombiStakeComponent: FC = () => {
  const { t } = useTranslation('betting');
  const { bettingSlipId } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const {
    hasKeyBoardCapabilities,
    showSuggestion,
    showKeyBoard,
    showNumpad,
    focusChanged,
    closeKeyboard,
  } = useNumpadActionManage(bettingSlipId);

  const stake = useBettingGetterSelector({
    getterSelector: bettingSlipStakeGetterSelector,
    args: [bettingSlipId],
  });

  const stakeInFloat = useBettingGetterSelector({
    getterSelector: bettingSlipStakeInFloatGetterSelector,
    args: [bettingSlipId],
  });

  const isErrorStake = isHighlightStakeByError(
    useBettingSlipCombiErrorFrontType(bettingSlipId)(),
  );

  const stakeMax = useBettingSlipGetCombiMaxStake(bettingSlipId)();

  const updateStake = (newStake: string) => {
    allowBSActions(actions.updateStake({ stake: newStake, bettingSlipId }));
  };

  return (
    <>
      <BettingSlipStakeBox data-testid="bettingslip-input-stake-combi-container">
        {t('bettingslip.combi.stake')}
        <BettingSlipStake
          testId="bettingslip-combi-stake-input"
          inError={isErrorStake}
          stake={stake}
          stakeInFloat={stakeInFloat}
          updateStake={updateStake}
          focusChanged={focusChanged}
          hasKeyBoardCapabilities={hasKeyBoardCapabilities}
          isKeyboardDisplayed={showKeyBoard}
        />
      </BettingSlipStakeBox>

      <BettingSlipCombiError stake={stake || ''} />
      {showNumpad ? (
        <BettingSlipNumpad
          showSuggestion={showSuggestion}
          showKeyBoard={showKeyBoard}
          stake={stake || ''}
          stakeMax={stakeMax}
          updateStake={updateStake}
          askClose={closeKeyboard}
        />
      ) : undefined}
    </>
  );
};

export const BettingSlipCombiStake = memo(BettingSlipCombiStakeComponent);
