import * as t from 'io-ts';

import { CashoutAvailability } from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { notificationCodec } from '../notification/codecs';

/* Object data */

export const cashoutInformationCodec = t.intersection(
  [
    t.type({
      BettingSlipId: t.string,
      ErrorIsInProgress: t.boolean,
      CashoutAvailability:
        createEnumType<CashoutAvailability>(CashoutAvailability),

      // Properties for Partial Cashout which will be developed in the future
      LimitCashoutToStake: t.boolean,
      PartialCashoutIsEnabled: t.boolean,
      PartialCashoutMinStakeRemainingAllowed: t.number,
      PartialCashoutMinStake: t.number,
    }),
    t.partial({
      FullCashoutAmount: t.number,
      Notification: notificationCodec,
    }),
  ],
  'CashoutInformationCodec',
);

export const cashedOutDataCodec = t.type(
  {
    BettingSlipId: t.string,
    Date: t.string,
    Amount: t.number,
  },
  'CashedOutDataCodec',
);

export const cashoutDataCodec = t.partial(
  {
    CashoutInformation: cashoutInformationCodec,
    CashedOutData: cashedOutDataCodec,
  },
  'CashoutDataCodec',
);

/* Response data */

export const cashoutResponseCodec = t.partial(
  {
    Notification: notificationCodec,
  },
  'CashoutResponseCodec',
);

export const getCashoutInfoResponseCodec = t.partial(
  {
    CashoutInformation: cashoutInformationCodec,
    Notification: notificationCodec,
  },
  'GetCashoutInfoResponseCodec',
);
