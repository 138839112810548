import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';

import { GamingActions } from '../../../store/types';
import * as actions from '../actions';
import { GameListEntitiesState } from '../types';

export const initalState: GameListEntitiesState = {
  attributeCategories: {},
  attributes: {},
  gameCategories: {},
};

export const gameListEntitiesReducer = (
  state: GameListEntitiesState = initalState,
  action: GamingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchGames.success): {
        draftState.attributeCategories = {
          ...draftState.attributeCategories,
          ...action.payload.data.entities.attributeCategories,
        };

        draftState.attributes = {
          ...draftState.attributes,
          ...action.payload.data.entities.attributes,
        };
        break;
      }

      case getType(actions.fetchGameCategories.success): {
        draftState.gameCategories = action.payload.entities.gamesCategories;
        break;
      }

      default: // Immer will automatically return the state
    }
  });
