import * as t from 'io-ts';

import { PrismicCodecs } from '../../common/codecs';

const navigationItem = t.type({
  content: PrismicCodecs.linkCodec,
  label: t.string,
});

export const navigationData = t.type({
  items: t.array(navigationItem),
  text_identifier: t.string,
  title: t.string,
});

export const navigationDocument = t.type({
  id: t.string,
  data: navigationData,
  lang: t.string,
  type: t.literal('navigation'),
});

export const navigationQueryResponse = t.type({
  results: t.array(navigationDocument),
});
