import { darken } from 'polished';
import styled from 'styled-components';
import { color } from 'styled-system';

import {
  colors,
  device,
  elevation,
  fontSizes,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { AppLink, Box, Text } from '@gaming1/g1-ui';

export const EXTRA_ICON_WIDTH_IN_PX = '20';
export const EXTRA_ICON_HEIGHT_IN_PX = '20';

export const BetIdText = styled(Text)`
  opacity: ${opacity('semi')};
  padding-right: ${spaces('xs')};
`;

export const BetIdContainer = styled(Box)`
  height: 32px;
  flex-direction: row;
  font-size: ${fontSizes('sm')};
  padding-right: ${spaces('xs')};
  margin: ${spaces('xs')} ${spaces('none')};
  justify-content: flex-end;
  align-items: center;
`;

export const CombinationCallToAction = styled(Box)`
  text-align: center;
  color: ${colors('primary')};
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

export const HistoryExpandedGiftContainer = styled(Box)`
  ${elevation(1, { noShadow: true })};
  padding: ${spaces('xs')} ${spaces('xs')};
  min-height: 32px;
  margin-top: ${spaces('xs')};
  border-radius: ${spaces('xxxs')};
  flex-direction: row;
  align-items: center;
`;

export const CallToActionLink = styled(AppLink)`
  color: ${colors('primary')};
  display: contents;
  ${device.canHover} {
    &:hover {
      color: ${colors('primary', { transform: darken(0.1) })};
    }
  }
`;

export const ExtraText = styled(Text)`
  margin-left: ${spaces('xxs')};
  margin-top: 0px;
  margin-right: ${spaces('md')};
  font-size: ${fontSizes('md')};
  ${color}
`;
