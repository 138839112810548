import { createContext, VFC } from 'react';

import { DeclarationOfHonorModalProps, PinCodeFormModalProps } from '../types';

const EmptyComponent: VFC = () => null;

/** Context containing public user components
 * - PinCodeFormModal: A modal asking for the user pin code
 */
export const UserPublicComponentsContext = createContext<{
  PinCodeFormModal: VFC<PinCodeFormModalProps>;
  DeclarationOfHonorModal: VFC<DeclarationOfHonorModalProps>;
}>({
  PinCodeFormModal: EmptyComponent,
  DeclarationOfHonorModal: EmptyComponent,
});
