import { createAsyncAction } from 'typesafe-actions';

import {
  GetShopProductsRequestDTO,
  PurchaseScratchCardRequestDTO,
  ScratchCardIdentifierRequest,
  ShopPurchaseRequestDTO,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetAvailableTicketsResponse,
  GetScratchcardIdentifierResponse,
  GetUnavailableProductsResponse,
  PaginatedProductsData,
  ProductsData,
  PurchaseResponse,
  ShopCategoriesResponse,
  ShopPurchaseResponse,
} from './types';

/** Get shop products */
export const getShopProducts = createAsyncAction(
  'loyalty/get_shop_products_request',
  'loyalty/get_shop_products_success',
  'loyalty/get_shop_products_failure',
)<GetShopProductsRequestDTO, PaginatedProductsData, FailurePayload>();

/** Get shop products by id */
export const getShopProductsById = createAsyncAction(
  'loyalty/get_shop_products_by_id_request',
  'loyalty/get_shop_products_by_id_success',
  'loyalty/get_shop_products_by_id_failure',
)<string[], ProductsData, FailurePayload>();

/** Purchase shop product */
export const purchaseShopProduct = createAsyncAction(
  'loyalty/purchase_shop_product_request',
  'loyalty/purchase_shop_product_success',
  'loyalty/purchase_shop_product_failure',
)<ShopPurchaseRequestDTO, ShopPurchaseResponse, FailurePayload>();

/** Get shop categories */
export const getShopCategories = createAsyncAction(
  'loyalty/get_shop_categories_request',
  'loyalty/get_shop_categories_success',
  'loyalty/get_shop_categories_failure',
)<void, ShopCategoriesResponse, FailurePayload>();

/** Get user ticket list */
export const getAvailableTickets = createAsyncAction(
  'loyalty/get_available_ticket_request',
  'loyalty/get_available_ticket_success',
  'loyalty/get_available_ticket_failure',
)<void, GetAvailableTicketsResponse, FailurePayload>();

/** Get unavailable products list */
export const getUnavailableProducts = createAsyncAction(
  'loyalty/get_unavailable_products_request',
  'loyalty/get_unavailable_products_success',
  'loyalty/get_unavailable_products_failure',
)<void, GetUnavailableProductsResponse, FailurePayload>();

/** Purchase product */
export const purchase = createAsyncAction(
  'loyalty/purchase_request',
  'loyalty/purchase_success',
  'loyalty/purchase_failure',
  'loyalty/purchase_cancel',
)<PurchaseScratchCardRequestDTO, PurchaseResponse, FailurePayload>();

/** Get scratchcard identifier */
export const getScratchcardIdentifier = createAsyncAction(
  'loyalty/get_scratchcard_identifier_request',
  'loyalty/get_scratchcard_identifier_success',
  'loyalty/get_scratchcard_identifier_failure',
)<
  ScratchCardIdentifierRequest,
  GetScratchcardIdentifierResponse,
  FailurePayload
>();
