import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import {
  getTermsAndConditions,
  playerAcceptTermsAndConditions,
  showTermsAndConditions,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { UserEpic } from '../../store/types';

import * as actions from './actions';
import {
  acceptTermsAndConditionsResponse,
  getTermsAndConditionsResponse,
  showTermsAndConditionsResponse,
} from './codecs';
import {
  acceptTermsAndConditionsErrorMessages,
  getTermsAndConditionsErrorMessages,
} from './errorMessages';

/** Accept Terms & Conditions */
export const acceptTermsAndConditionsEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.acceptTermsAndConditions.request)),
    switchMap(() =>
      wsAdapter.request(playerAcceptTermsAndConditions({})).pipe(
        mapGuard(acceptTermsAndConditionsResponse),
        map(actions.acceptTermsAndConditions.success),
        catchError((err) =>
          of(
            actions.acceptTermsAndConditions.failure(
              createFailurePayload(err, acceptTermsAndConditionsErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get Terms & Conditions */
export const getTermsAndConditionsEpic: UserEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTermsAndConditions.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      wsAdapter
        .request(
          getTermsAndConditions({
            Language: currentWebSocketLocaleSelector(state),
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(getTermsAndConditionsResponse),
          map(actions.getTermsAndConditions.success),
          catchError((err) =>
            of(
              actions.getTermsAndConditions.failure(
                createFailurePayload(err, getTermsAndConditionsErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Show Terms & Conditions */
export const showTermsAndConditionsEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.showTermsAndConditions.request)),
    switchMap(() =>
      wsAdapter.request(showTermsAndConditions({})).pipe(
        mapGuard(showTermsAndConditionsResponse),
        map(actions.showTermsAndConditions.success),
        catchError((err) =>
          of(actions.showTermsAndConditions.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );
