import React, { FC } from 'react';

import { usePrismicInternalLink } from '../../hooks';
import { logger } from '../../logger';

import { PrismicExternalLink, PrismicInternalLink } from './styles';

export type PrismicLinkProps = {
  /** Link url */
  link?: string;
  /** Link target */
  target?: string;
  /** Content width (in percent) */
  width?: string | null;
  /** Return only children if no link specified */
  renderChildrenIfEmpty?: boolean;
  /** Test ID */
  testId?: string;
};

export const PrismicLink: FC<PrismicLinkProps> = ({
  children,
  link,
  target,
  width,
  testId = 'prismic-link',
  renderChildrenIfEmpty,
}) => {
  const { onClick, path, error } = usePrismicInternalLink(link);
  if (error && (!!link || (!link && !renderChildrenIfEmpty))) {
    logger.error('[Prismic][Link]', error);
  }

  if (onClick) {
    return (
      <PrismicInternalLink onClick={onClick} data-testid={testId} width={width}>
        {children}
      </PrismicInternalLink>
    );
  }

  if (path) {
    return (
      <PrismicExternalLink
        href={path || ''}
        target={target}
        rel={target === '_blank' ? 'noopener' : undefined}
        data-testid={testId}
        width={width}
      >
        {children}
      </PrismicExternalLink>
    );
  }

  if (!link && renderChildrenIfEmpty === true) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};
