import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getGameFeatureHistoric } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../actions';
import { LoyaltyEpic } from '../../types';
import { getGameFeatureHistoryResponse } from '../codecs';
import { getGameFeatureHistoryErrorMessages } from '../errorMessages';
import { formatGameFeatureHistory } from '../format';

export const freeSpinsHistoryEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getFreeSpinsHistory.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getGameFeatureHistoric(payload)).pipe(
        mapGuard(getGameFeatureHistoryResponse),
        map(formatGameFeatureHistory),
        map(actions.getFreeSpinsHistory.success),
        catchError((err) =>
          of(
            actions.getFreeSpinsHistory.failure(
              createFailurePayload(err, getGameFeatureHistoryErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
