import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { SCROLL_NAV_HEIGHT, usePageMinHeight } from '@gaming1/g1-core-web';
import { Box, RenderOnMedia, ScrollContext } from '@gaming1/g1-ui';

import { SubDrawerProvider } from '../../../bettingSlip/components/SubDrawer/SubDrawerProvider';
import { BettingSlipMain } from '../../../bettingSlip/components/Templates/BettingSlipMain';
import { breakPointMobileTicket } from '../../../bettingSlip/hooks/render';
import { SportBookContainer } from '../../../routers/styles';
import { CollapsibleMenuContainer } from '../../../sportbook/components/CollapsibleMenuContainer';
import { FooterContext } from '../../FooterContext';

import {
  CollapsibleChildrenSubContainer,
  StyledBettingSlipContainer,
} from './styles';

type MultiColumnsProps = {
  /** Shows the live navigation if true */
  numberOfColumns: number;
  isLivePage?: boolean;
};

export const MultiColumns: FC<MultiColumnsProps> = ({
  children,
  numberOfColumns,
  isLivePage = false,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const Footer = useContext(FooterContext);
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = useCallback((shouldOpen: boolean) => {
    setShowMenu(shouldOpen);
  }, []);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [contentRef, location]);

  const { minHeight } = usePageMinHeight(true);
  const { minHeight: minHeightFooterNotVisible } = usePageMinHeight(false);

  const containerHeight = minHeightFooterNotVisible - SCROLL_NAV_HEIGHT;
  const contentMinHeight = minHeight - SCROLL_NAV_HEIGHT;

  const collapsibleChildren = useMemo(
    () => (
      <Box display="block" flex={1} overflowY="auto" ref={contentRef}>
        <ScrollContext.Provider value={contentRef}>
          <CollapsibleChildrenSubContainer
            numberOfColumns={numberOfColumns}
            minHeight={contentMinHeight}
          >
            {children}
          </CollapsibleChildrenSubContainer>
          <Footer />
        </ScrollContext.Provider>
      </Box>
    ),
    [Footer, children, contentMinHeight, numberOfColumns],
  );

  const content = useMemo(
    () => (
      <SubDrawerProvider>
        <SportBookContainer
          flexDirection="row"
          height={containerHeight}
          data-testid={`${numberOfColumns}-col-layout`}
        >
          {numberOfColumns === 2 && collapsibleChildren}
          {numberOfColumns === 3 && (
            <CollapsibleMenuContainer
              isMenuVisible={showMenu}
              isLivePage={isLivePage}
              toggleMenu={toggleMenu}
            >
              {collapsibleChildren}
            </CollapsibleMenuContainer>
          )}

          <RenderOnMedia min={breakPointMobileTicket}>
            <StyledBettingSlipContainer>
              <BettingSlipMain />
            </StyledBettingSlipContainer>
          </RenderOnMedia>
        </SportBookContainer>
      </SubDrawerProvider>
    ),
    [
      collapsibleChildren,
      containerHeight,
      isLivePage,
      numberOfColumns,
      showMenu,
      toggleMenu,
    ],
  );

  return content;
};
