import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { FilteredIds } from '../types';

import { componentsSelector } from './components';
import { eventsSelector, leaguesSelector } from './entities';

/**
 * Return a function that takes a list of event id and returns a list of event
 * Don't forget to use shallowEqual with this selector
 */
export const eventListSelector = createSelector(eventsSelector, (events) =>
  memoize(
    (
      eventIds: number[],
      filterDeleted: boolean | null = true,
      sortedType: string[] = ['IsWinner', 'StartDate', 'HomeName'],
    ) =>
      sortBy(
        eventIds?.map((eventId) =>
          events[eventId]?.deleted && filterDeleted
            ? undefined
            : events[eventId],
        ) || [],
        sortedType,
      ),
    // Cache key resolver
    (
      eventIds: number[],
      filterDeleted = true,
      sortedType = ['IsWinner', 'StartDate', 'HomeName'],
    ) => `${eventIds?.join('-')}${!!filterDeleted}${sortedType.join('-')}`,
  ),
);

export const componentLeagueIdsSelector = createSelector(
  componentsSelector,
  (components) => memoize((key: string) => components[key]?.leagueIds || []),
);

/**
 * Return a function that takes a league id and returns a league
 */
export const leagueSelector = createSelector(leaguesSelector, (leagues) =>
  memoize(
    (leagueId: number, filterDeleted: boolean | null = true) =>
      leagues[leagueId]?.deleted && filterDeleted
        ? undefined
        : leagues[leagueId],
    // Cache key resolver
    (leagueId: number, filterDeleted = true) => `${leagueId}${!!filterDeleted}`,
  ),
);
/**
 * Return a function that takes a list of league id and returns a list of leagues
 */
export const leaguesListSelector = createSelector(leaguesSelector, (leagues) =>
  memoize(
    (leagueIds: number[], filterDeleted: boolean | null = true) =>
      leagueIds?.map((leagueId) =>
        leagues[leagueId]?.deleted && filterDeleted
          ? undefined
          : leagues[leagueId],
      ),
    // Cache key resolver
    (leagueIds: number[], filterDeleted = true) =>
      `${leagueIds?.join('-')}${!!filterDeleted}`,
  ),
);
/**
 * Returns all ids ([sportId/leagueId/eventIds]) without filtering
 * Don't forget to use shallowEqual with this selector
 */
export const allIdsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  (getLeagueIds, getLeagues) =>
    memoize((key: string): FilteredIds => {
      const leagues = getLeagues(getLeagueIds(key)).sort(
        (a, b) => (a?.Order || 0) - (b?.Order || 0),
      );

      return leagues.map((league) => ({
        sportId: league?.SportId || 0,
        leagueId: league?.LeagueId || 0,
        eventIds: league?.Events || [],
      }));
    }),
);

/**
 * Returns true if component contains at least 1 winner event
 */
export const hasWinnerEventsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize((key: string): boolean =>
      getLeagues(getLeagueIds(key)).some((league) =>
        getEvents(league?.Events || []).some((event) => event?.IsWinner),
      ),
    ),
);

/**
 * Returns true if component contains at least 1 NON winner event
 */
export const hasNonWinnerEventsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize((key: string): boolean =>
      getLeagues(getLeagueIds(key)).some((league) =>
        getEvents(league?.Events || []).some((event) => !event?.IsWinner),
      ),
    ),
);
