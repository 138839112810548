import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  getTrendsNavigationSelector,
  getTrendsNavigationStatusSelector,
  sportAliasSelector,
} from '@gaming1/g1-betting';
import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import {
  SearchLayout,
  SearchResultDisplay,
  SearchResultDisplayContainer,
  SearchResultTitle,
} from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Separator } from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

import { useBettingRoutePath } from '../../../hooks';
import { getIconType } from '../../../sportbook/helpers';

import { BettingLink, SearchTrendsContainer } from './styles';

type BettingSearchTrendsProps = {
  /** Layout used. Normal layout is one column, grid up to three with darker style */
  layout?: SearchLayout;
};

/** Return a list of betting trends */
export const BettingSearchTrends: FC<BettingSearchTrendsProps> = ({
  layout = 'normal',
}) => {
  const { t } = useTranslation('betting');

  const dispatch = useDispatch();
  const getBettingRoutePath = useBettingRoutePath();
  const getSportAlias = useSelector(sportAliasSelector);

  const trends = useSelector(getTrendsNavigationSelector);
  const locale = useSelector(currentWebSocketLocaleSelector);
  const trendsRequestStatus = useSelector(getTrendsNavigationStatusSelector);

  useEffect(() => {
    if (trendsRequestStatus === RemoteData.NotAsked && locale) {
      dispatch(actions.askTrendsNavigation());
    }
  }, [dispatch, trendsRequestStatus, locale]);

  return trends.length ? (
    <SearchTrendsContainer>
      {layout === 'normal' ? (
        <>
          <Box
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            data-testid="betting-search-trends"
          >
            {t('allSports.trends')}
          </Box>

          <Box mb="sm" width="100%">
            <Separator data-testid="trends-separator" />
          </Box>
        </>
      ) : (
        <SearchResultTitle data-testid="betting-search-trends">
          {t('allSports.trends')}
        </SearchResultTitle>
      )}
      <SearchResultDisplayContainer>
        {trends.map((trend) => (
          <BettingLink
            data-testid="betting-search-trending-league-link"
            key={trend.Id}
            to={getBettingRoutePath('league', {
              sportId: trend.SportId?.toString() || '',
              regionId: trend.RegionId?.toString() || '',
              leagueId: trend.Id?.toString() || '',
            })}
            isNormalLayout={layout === 'normal'}
          >
            <SearchResultDisplay
              key={trend.Id}
              icon={getIconType(getSportAlias(trend.SportId))}
              title={trend.Name}
              layout={layout}
            />
          </BettingLink>
        ))}
      </SearchResultDisplayContainer>
    </SearchTrendsContainer>
  ) : null;
};
