import * as t from 'io-ts';

import { BettingActivity } from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import {
  lightEventCodec,
  lightMarketCodec,
  lightOutcomeCodec,
} from '../../../sportbook/store/codecs/entities';

import { riskManagementLimitsCodec } from './riskManagement';

/* Object data */

export const importBettingSlipItemExtraStateCodec = t.partial(
  {
    baseManualBetOdd: t.number,
  },
  'ImportBettingSlipItemExtraStateCodec',
);

export const importBettingSlipExtraStateCodec = t.partial(
  {
    title: t.string,
    limits: riskManagementLimitsCodec,
    manualBetConfigId: t.string,
    firstDepositDone: t.boolean,
  },
  'ImportBettingSlipExtraStateCodec',
);

export const importBettingSlipStateCodec = t.intersection(
  [
    t.type({
      items: t.array(
        t.intersection([
          t.type({
            id: t.number,
            event: t.number,
            market: t.number,
            outcome: t.number,
          }),
          t.partial({
            extra: importBettingSlipItemExtraStateCodec,
          }),
        ]),
      ),
      stake: t.string,
      type: t.union([
        t.literal('single'),
        t.literal('combi'),
        t.literal('system'),
      ]),
    }),
    t.partial({
      extra: importBettingSlipExtraStateCodec,
      configuration: t.partial({
        activity: createEnumType<BettingActivity>(BettingActivity),
      }),
    }),
  ],
  'ImportBettingSlipStateCodec',
);

export const importBettingSlipCodec = t.type(
  {
    bettingSlip: importBettingSlipStateCodec,
    events: t.array(lightEventCodec),
    markets: t.array(lightMarketCodec),
    outcomes: t.array(lightOutcomeCodec),
  },
  'ImportBettingSlipCodec',
);
