import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions, coreActions } from '@gaming1/g1-core';

import * as betPipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import {
  getInitialBettingSlipState,
  getInitialBettingSlipStateByImport,
  getInitialRootBettingSlipState,
} from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

/**
 * Ensure the bettingSlipState is initialized for any action having a bettingSlipId
 */
export const bettingSlipInitReducer = (
  state = initialState,
  action: BettingActions | CoreActions,
): BettingSlipsState =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(coreActions.setConfig): {
        const {
          payload: {
            betting: {
              bettingslip: { defaultStake },
            },
          },
        } = action;
        draftState.config = { defaultStake };
        break;
      }
      case getType(actions.initBettingSlipBySession):
      case getType(actions.initBettingSlipFast): {
        const {
          payload: {
            bettingSlip: { bettingSlipId, bettingSlip },
          },
        } = action;

        draftState.bettingSlips[bettingSlipId] =
          getInitialBettingSlipStateByImport(bettingSlip, draftState.config);

        break;
      }
      case getType(actions.changeBettingSlipType):
      case getType(actions.addBettingSlipOutcome):
      case getType(actions.removeBettingSlipOutcome):
      case getType(actions.toggleBettingSlipOutcome):
      case getType(actions.toggleCheckItem):
      case getType(actions.updateStake):
      case getType(actions.updateOddChangeForItem):
      case getType(actions.resetOddChange):
      case getType(actions.updateStakeForRank):
      case getType(betPipeActions.resetBettingSlip.request):
      case getType(betPipeActions.getRiskManagement.request):
      case getType(betPipeActions.getSystemRiskManagement.request):
      case getType(betPipeActions.freebetConditionsForBettingSlip.request):
      case getType(betPipeActions.boostusConditionsForBettingSlip.request):
      case getType(betPipeActions.getOptimalBet.request):
      case getType(betPipeActions.placeBet.request):
      case getType(betPipeActions.placeSystemBet.request): {
        if (!draftState.getInitData) {
          draftState = getInitialRootBettingSlipState();
        }
        const { bettingSlipId } = action.payload;
        if (bettingSlipId && !draftState.bettingSlips[bettingSlipId]) {
          draftState.bettingSlips[bettingSlipId] = getInitialBettingSlipState(
            draftState.config,
          );
        }
        break;
      }

      default: // Immer will automatically return the state
    }
  });
