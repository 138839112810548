import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

import { isNonNullable } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../../store/types';
import { getSortedSports } from '../helpers';
import { FilteredIds, SportType } from '../types';

import {
  componentLeagueIdsSelector,
  eventListSelector,
  leaguesListSelector,
} from './common';
import { sportsSelector } from './entities';

/* Sports */
export const getSportsStatusSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.requests.getSports.status;

export const sportSelector = createSelector(sportsSelector, (getSports) =>
  memoize((sportId: number): SportType | undefined => getSports[sportId]),
);

export const sportsByIdSelector = createSelector(sportsSelector, (getSports) =>
  memoize(
    (sportIds: number[]): Array<SportType | undefined> =>
      sportIds.map((id) => getSports[id]),
  ),
);

export const sportAliasSelector = createSelector(sportSelector, (getSport) =>
  memoize((sportId?: number) =>
    sportId !== undefined ? getSport(sportId)?.SportAlias : undefined,
  ),
);
export const sportNameSelector = createSelector(sportSelector, (getSport) =>
  memoize((sportId?: number) =>
    sportId !== undefined ? getSport(sportId)?.SportName : undefined,
  ),
);

/* Live count by sport  */
export const liveCountBySportSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.liveCountBySport;

export const getLiveCountBySportStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.requests.getLiveCountBySport.status;

export const getLiveCountBySportErrorMessageSelector = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.requests.getLiveCountBySport.errorMessage;

export const liveCountBySportGetterSelector = createSelector(
  liveCountBySportSelector,
  (counts) =>
    memoize(
      (sportId: number) => counts.find(({ Id }) => Id === sportId)?.Count || 0,
    ),
);

/**
 * Returns all sports (id) sorted by liveOrder currently available
 * Don't forget to use shallowEqual with this selector
 */
export const allSportsByLiveOrderSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  sportsSelector,
  (getLeagueIds, getLeagues, getSport) =>
    memoize((key: string) =>
      uniq(
        getLeagues(getLeagueIds(key))
          .filter((league) => league?.SportId && league?.Events?.length)
          .map((league) => league?.SportId)
          .sort((sportIdA, sportIdB) => {
            const sportA = getSport[sportIdA || -1];
            const sportB = getSport[sportIdB || -1];

            const liveOrderA = sportA?.LiveOrder || -1;
            const liveOrderB = sportB?.LiveOrder || -1;

            if (liveOrderA !== liveOrderB) {
              return liveOrderA - liveOrderB;
            }

            return (sportA?.SportId || 0) - (sportB?.SportId || 0);
          }),
      ),
    ),
);

/**
 * Returns all ids ([sportId/leagueId/eventIds]) for a specified sport and region id
 * Don't forget to use isEqual with this selector
 */
export const idsBySportRegionSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize(
      (
        key: string,
        sportId: number,
        regionId: number | null,
        sortedType?: string[],
      ): FilteredIds => {
        const leagues = getLeagues(getLeagueIds(key))
          .filter(
            (league) =>
              (!sportId || league?.SportId === sportId) &&
              (!regionId || league?.RegionId === regionId) &&
              league?.Events?.length,
          )
          .sort((a, b) => (a?.Order || 0) - (b?.Order || 0));

        return leagues.map((league) => ({
          sportId: league?.SportId || 0,
          leagueId: league?.LeagueId || 0,
          eventIds: getEvents(league?.Events || [], true, sortedType)
            .filter(isNonNullable)
            .map((e) => e?.EventId),
        }));
      },

      // Cache key resolver
      (
        key: string,
        sportId: number,
        regionId: number | null,
        sortedType?: string[],
      ) => `${key}${sportId}${regionId}${sortedType?.join('-')}`,
    ),
);

/**
 * Returns all sports (id/name) currently available
 * Don't forget to use isEqual with this selector
 */
export const allSportsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  sportsSelector,
  idsBySportRegionSelector,
  (getLeagueIds, getLeagues, getSport, getIds) =>
    memoize(
      (key: string, regionId: number | null) =>
        getSortedSports(
          uniqBy(
            getLeagues(getLeagueIds(key))
              .filter((league) => league?.SportId && league?.Events?.length)
              .map((league) => {
                const sport = getSport[league?.SportId || -1];

                return {
                  sportId: league?.SportId,
                  sportName: sport?.SportName || `${league?.SportId}`,
                  sportAlias: sport?.SportAlias || `${league?.SportId}`,
                  count: league?.SportId
                    ? getIds(key, league.SportId, regionId).reduce(
                        (acc, cur) => acc + cur.eventIds.length,
                        0,
                      )
                    : undefined,
                  liveOrder: sport?.LiveOrder || 0,
                };
              }),

            ({ sportId }) => sportId,
          ),
        ),

      // Cache key resolver
      (key: string, regionId: number | null) => `${key}${regionId}`,
    ),
);
