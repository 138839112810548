/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:52:53 16-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { EventsForRegionRequest, EventsForRegionResponse } from '../entities';

export const getEventsForRegion = (options: EventsForRegionRequest) =>
  createRequest<EventsForRegionResponse>(201, 'GetEventsForRegion', options);
