import orderBy from 'lodash/orderBy';

import { curriedHasNonNullableProperty } from '@gaming1/g1-utils';

import { ManualBetType } from '../types';

export const removeUsedDailyOfferAndOrderByStartDateOrOdds = (
  dailyOffers: ManualBetType[],
) =>
  orderBy(
    dailyOffers
      .filter((dailyOffer) => !dailyOffer.UsedDate)
      .filter(curriedHasNonNullableProperty('ElementInfo')),
    [
      (dailyOffer) => new Date(dailyOffer.ElementInfo.StartDate),
      (dailyOffer) => dailyOffer.ElementInfo?.FinalOdds,
    ],
    ['asc', 'desc'],
  );

export const keepUsedDailyOffersAndOrderByUsedDateOrOdds = (
  dailyOffers: ManualBetType[],
) =>
  orderBy(
    dailyOffers.filter(curriedHasNonNullableProperty('UsedDate')),
    [
      (dailyOffer) => new Date(dailyOffer.UsedDate),
      (dailyOffer) => dailyOffer.ElementInfo?.FinalOdds,
    ],
    ['desc', 'desc'],
  );

export const removeUsedDailyOfferAndOrderByOdds = (
  dailyOffers: ManualBetType[],
) =>
  orderBy(
    dailyOffers
      .filter((dailyOffer) => !dailyOffer.UsedDate)
      .filter(curriedHasNonNullableProperty('ElementInfo')),
    [(dailyOffer) => dailyOffer.ElementInfo?.FinalOdds],
    ['desc'],
  );
