import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const WRAPPER_MAX_HEIGHT = '67px';

export const CompetitionLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0;
  background-color: ${colors('backgrounds', {
    index: 2,
  })};
  padding-bottom: 0;
`;

export const SearchResultWrapper = styled(Box)`
  max-height: ${WRAPPER_MAX_HEIGHT};
  cursor: pointer;
`;

export const SearchResultRow = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
