import { AppConfig } from '../types';

import { defaultBettingConfig } from './betting';
import { defaultCoreConfig } from './core';
import { defaultGamingConfig } from './gaming';
import { defaultI18nConfig } from './i18n';
import { defaultLoyaltyConfig } from './loyalty';
import { defaultNetworkConfig } from './network';
import { defaultRoomConfig } from './room';
import { defaultSeoConfig } from './seo';
import { defaultUserConfig } from './user';

export const defaultConfig: AppConfig = {
  betting: defaultBettingConfig,
  core: defaultCoreConfig,
  gaming: defaultGamingConfig,
  i18n: defaultI18nConfig,
  loyalty: defaultLoyaltyConfig,
  network: defaultNetworkConfig,
  room: defaultRoomConfig,
  seo: defaultSeoConfig,
  user: defaultUserConfig,
};
