import { createContext } from 'react';

import { NetworkConfig, OverwriteConfig } from '@gaming1/g1-config';

import {
  ConfigName,
  configs,
  defaultConfigName,
  getOverwrittenNetworkConfigValues,
} from '../../configs';
import {
  getDefaultThemeName,
  getSavedCustomTheme,
  ThemeName,
} from '../../themes';
import { editableNetworkConfigFieldsList } from '../DevToolMenu/constants';

const getMatchingNetworkConfigFields = (
  networkConfig: NetworkConfig,
): OverwriteConfig =>
  Object.fromEntries(
    editableNetworkConfigFieldsList.map((f) => [f, networkConfig[f]]),
  );

export const initialSwitchState: SwitchState = {
  config: defaultConfigName,
  customTheme: getSavedCustomTheme(),
  networkConfig: Object.keys(getOverwrittenNetworkConfigValues()).length
    ? getOverwrittenNetworkConfigValues()
    : getMatchingNetworkConfigFields(configs[defaultConfigName].network),
  theme: getDefaultThemeName(),
};

export type SwitchState = {
  config: ConfigName;
  customTheme: string | null;
  networkConfig: OverwriteConfig | null;
  theme: ThemeName;
};

type ConfigSwitchAction = {
  type: 'switch/config';
  payload: ConfigName;
};

type NetworkConfigSwitchAction = {
  type: 'switch/network_config';
  payload: OverwriteConfig | null;
};

type ThemeSwitchAction = {
  type: 'switch/theme';
  payload: ThemeName;
};

type CustomSwitchAction = {
  type: 'switch/custom_theme';
  payload: string | null;
};

export type SwitchAction =
  | ConfigSwitchAction
  | CustomSwitchAction
  | NetworkConfigSwitchAction
  | ThemeSwitchAction;

export const SwitchContext = createContext([
  initialSwitchState,
  // we use the argument for type inference
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (action: SwitchAction): void => undefined,
] as const);
