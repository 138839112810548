import React, { FC } from 'react';

import { BottomElementContainer } from './styles';
import { AnimationType } from './types';

type BottomNavItemProps = {
  /** Type of animation on scroll */
  animationType: AnimationType;
  /** Special background color */
  isHighLighted?: boolean;
  /** Is the BottomNav is minimized? */
  isMinimized: boolean;
  /** Testid of the container */
  testId: string;
};

/**
 * Menu item link displayed in the bottom nav
 */
export const BottomNavItem: FC<BottomNavItemProps> = ({
  animationType,
  children,
  isHighLighted = false,
  isMinimized,
  testId,
}) => {
  const isPoppedUp = isMinimized && animationType === 'hide' && isHighLighted;

  return (
    <BottomElementContainer
      data-testid={testId}
      isPoppedUp={isPoppedUp}
      isHighlighted={isHighLighted}
    >
      {children}
    </BottomElementContainer>
  );
};
