import styled from 'styled-components';

import { colors, getReadableColor, media } from '@gaming1/g1-style';

export const Pellet = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  background-color: ${colors('primary')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: ${getReadableColor('primary')};
    }
  }

  ${media.md} {
    width: 28px;
    height: 28px;
  }
`;
