import styled, { css } from 'styled-components';

import { Image, ResponsiveImage } from '@gaming1/g1-core-web';
import {
  colors,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

export const GAME_CARD_BORDER_RADIUS_IN_PX = '8px';

type GameCardOverlayDivProps = {
  /**
   * When the game card is too small to display the title of the game its style
   * and content changes.
   */
  $isTiny?: boolean;
};
export const GameCardOverlayDiv = styled.div<GameCardOverlayDivProps>`
  align-items: center;
  background-color: rgba(0, 0, 0, 66%);
  bottom: 0;
  color: ${colors('textLight')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  opacity: ${opacity('transparent')};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;

  ${({ $isTiny }) =>
    $isTiny &&
    css`
      background-color: rgba(0, 0, 0, 80%);
      color: ${colors('primary')};
      font-size: ${fontSizes('sm')};
      text-transform: uppercase;
      ${fontWeight('bold')};
      svg {
        margin-left: ${spaces('xs')};
        path {
          fill: ${colors('primary')};
        }
      }
    `}
`;

type GameCardButtonProps = {
  ratio: number;
  /** if the game is only visible for some users / developers) */
  shouldHighlightRestrictedGame: boolean;
};

export const GameCardButton = styled(SimpleButton).attrs({
  type: 'button',
})<GameCardButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: ${({ ratio }) => 100 / ratio}% 0 0;
  overflow: hidden;
  border-radius: ${({ theme }) =>
    theme.options.gameCardRoundBorder ? GAME_CARD_BORDER_RADIUS_IN_PX : '0'};

  /* fix the pseudo bottom margin induced by inline-block display */
  vertical-align: middle;

  ${({ shouldHighlightRestrictedGame }) =>
    shouldHighlightRestrictedGame &&
    css`
      &:after {
        content: '';
        border: 3px solid red;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    `}

  &:hover {
    ${GameCardOverlayDiv} {
      opacity: ${opacity('opaque')};
    }
  }
  &:focus {
    outline: none;
  }
`;

export const GameCardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const GameCardImg = styled(ResponsiveImage)`
  display: block;
  height: 100%;
  width: 100%;
`;

export type GameCardLabelImageProps = {
  $gameCardWidth: number;
};

export const GameCardLabelImage = styled(Image)<GameCardLabelImageProps>`
  position: absolute;
  left: 0;

  ${({ $gameCardWidth }) =>
    $gameCardWidth > 100
      ? css`
          top: 4%;
          height: 15%;
        `
      : css`
          top: 8%;
          height: 24%;
        `};
`;
