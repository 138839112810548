import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../store/types';
import { ManualBetType } from '../types';

import {
  keepUsedDailyOffersAndOrderByUsedDateOrOdds,
  removeUsedDailyOfferAndOrderByOdds,
  removeUsedDailyOfferAndOrderByStartDateOrOdds,
} from './helpers';

const getDailyOffersStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.dailyOffers;

/** hasAvailableDailyOffers selectors */
export const getHasDailyOffersSelector = (state: ApplicationWithBettingState) =>
  state.betting.dailyOffers.result.hasDailyOffers;

export const getDailyOffersRelatedEventIdsSelector = createSelector(
  getDailyOffersStateSelector,
  (dailyOffers) =>
    memoize(() => dailyOffers.result.relatedEventIds.filter(isNonNullable)),
);

export const getHasDailyOffersStatusSelector = (
  state: ApplicationWithBettingState,
) => getDailyOffersStateSelector(state).requests.hasDailyOffers.status;

export const getHasDailyOffersErrorMessageSelector = (
  state: ApplicationWithBettingState,
) => getDailyOffersStateSelector(state).requests.hasDailyOffers.errorMessage;

/** getAvailableDailyOffers selectors */
export const getDailyOffersRequestStateSelector = (
  state: ApplicationWithBettingState,
) => getDailyOffersStateSelector(state).requests.getAvailableDailyOffers;

export const getDailyOffersRequestStatusSelector = (
  state: ApplicationWithBettingState,
) => getDailyOffersRequestStateSelector(state).status;

export const getDailyOffersGetterSelector = createSelector(
  getDailyOffersStateSelector,
  (dailyOffers) =>
    memoize(
      () =>
        dailyOffers?.result.availableDailyOffers.ManualBets?.filter(
          isNonNullable,
        ) || [],
    ),
);

export const getAvailableDailyOffersGetterSelector = createSelector(
  getDailyOffersGetterSelector,
  (dailyOffers) =>
    memoize(() => removeUsedDailyOfferAndOrderByStartDateOrOdds(dailyOffers())),
);

export const getUsedDailyOffersGetterSelector = createSelector(
  getDailyOffersGetterSelector,
  (dailyOffers) =>
    memoize(() => keepUsedDailyOffersAndOrderByUsedDateOrOdds(dailyOffers())),
);

export const getDailyOfferGetterSelector = createSelector(
  getDailyOffersGetterSelector,
  (getDailyOffers) =>
    memoize(
      (id: string) =>
        id && getDailyOffers().find((item: ManualBetType) => item?.Id === id),
    ),
);

export const getDailyOffersForEventGetterSelector = createSelector(
  getDailyOffersStateSelector,
  (dailyOffers) =>
    memoize(
      (eventId: number) =>
        dailyOffers?.result.availableDailyOffersForEvent?.ManualBets?.filter(
          isNonNullable,
        ).filter((mb) => mb.RelatedEventId === Number(eventId)) || [],
    ),
);

export const getAvailableDailyOffersForEventGetterSelector = createSelector(
  getDailyOffersForEventGetterSelector,
  (getEventDailyOffers) =>
    memoize((eventId: number) =>
      removeUsedDailyOfferAndOrderByOdds(getEventDailyOffers(eventId)),
    ),
);
