import { createContext } from 'react';

import { ManualBetType } from '@gaming1/g1-betting';
import { ManualBetType as EManualBetType } from '@gaming1/g1-requests-betting';

const dummyFunction = () => null;

export const FastBettingSlipContext = createContext<{
  openFastTicket: (id: ManualBetType, type: EManualBetType) => void;
  closeFastTicket: () => void;
  isPlaceBetDone: boolean;
  setIsPlaceBetDone: (value: boolean) => void;
  type: EManualBetType;
}>({
  openFastTicket: dummyFunction,
  closeFastTicket: dummyFunction,
  isPlaceBetDone: false,
  setIsPlaceBetDone: dummyFunction,
  type: EManualBetType.Unknown,
});
