import isEqual from 'lodash/isEqual';
import React, { FC, memo, useContext } from 'react';

import {
  bettingSlipItemsGetterSelector,
  EBettingSlipIdentifier,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';

import { BettingSlipContext } from '../../../BettingSlipContext';

import { BettingSlipTitleContainer } from './styles';

type BettingSlipTitleProps = {
  /** If we need to give directly the bettingSlipId to the component.
   *  When in the drawer, we are rendering a BettingSlipMain that is a component for the bettingSlip.Main.
   *  Since the BettingSlipTitle in the drawer is OUTSIDE the BettingSlipMain, the bettingSlipId retrieved from the context is "missing",
   * in that case we need to provide him the bettingSlipId he needs.
   */
  bettingSlipIdFallback?: EBettingSlipIdentifier;
};

export const BettingSlipTitleComponent: FC<BettingSlipTitleProps> = ({
  bettingSlipIdFallback,
}) => {
  const { t } = useTranslation('betting');
  const { bettingSlipId } = useContext(BettingSlipContext);

  const selectedElementCount =
    useBettingGetterSelector({
      getterSelector: bettingSlipItemsGetterSelector,
      args: [bettingSlipIdFallback || bettingSlipId],
      equalityFn: isEqual,
    })?.length || 0;

  return (
    <BettingSlipTitleContainer data-testid="bettingSlip-title">
      {t('bettingslip.title', { nb: selectedElementCount })}
    </BettingSlipTitleContainer>
  );
};

export const BettingSlipTitle = memo(BettingSlipTitleComponent);
