import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { SportbookState } from '../types';

const liveCountBySportInitialState: SportbookState['liveCountBySport'] = [];

export const liveCountBySportReducer = (
  state = liveCountBySportInitialState,
  action: BettingActions,
) =>
  produce(state, () => {
    switch (action.type) {
      case getType(actions.getLiveCountBySport.success):
        return action.payload;

      default: // Immer will automatically return the state
    }
  });
