import React, { VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';

import { useLocaleSwitch } from '../../../i18n/hooks';

import { LocaleButton, LocaleListContainer, LocaleListLabelP } from './styles';

export const LocaleList: VFC = () => {
  const { t } = useTranslation('core');
  const { localeList, switchLocale } = useLocaleSwitch();

  return (
    <div role="radiogroup" aria-labelledby="drawernav-locale-title">
      <LocaleListLabelP id="drawernav-locale-title">
        {t('drawerNav.languages')}
      </LocaleListLabelP>
      <LocaleListContainer data-testid="drawernav-locale-list">
        {localeList.map((locale) => (
          <LocaleButton
            type="button"
            role="radio"
            key={locale.label}
            aria-checked={locale.selected}
            active={locale.selected}
            onClick={() => switchLocale(locale.value)}
            data-testid={`drawernav-locale-${locale.value}`}
          >
            {locale.label}
          </LocaleButton>
        ))}
      </LocaleListContainer>
    </div>
  );
};
