import { PaginationList } from '@gaming1/g1-utils';

import {
  GetTournamentListResponse,
  GetTournamentPlayerRankingResponse,
  GetTournamentRankingResponse,
  Tournament,
  TournamentRanking,
} from './types';

export const formatTournamentList = (
  response: GetTournamentListResponse,
): PaginationList<Tournament> => {
  const { List, PageNumber, PageSize, Total } = response;

  return {
    list: List,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};

export const formatTournamentRanking = (
  response: GetTournamentRankingResponse,
): PaginationList<TournamentRanking> => {
  const { List, PageNumber, PageSize, Total } = response;

  return {
    list: List,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};

export const formatPlayerRanking = (
  response: GetTournamentPlayerRankingResponse,
): TournamentRanking[] => response.List;
