import * as t from 'io-ts';

import {
  EChangeSecurityPinCodeStatus,
  EProtectedFeature,
  EResetSecurityPinCodeStatus,
  GetSecurityPinCodeInfoStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const getSecurityPinCodeInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(GetSecurityPinCodeInfoStatus.Successful),
      IsSecurityPinCodeEnabled: t.boolean,
      ShouldResetSecurityPinCode: t.boolean,
    }),
    t.partial({
      ProtectedFeatures: t.array(
        createEnumType<EProtectedFeature>(
          EProtectedFeature,
          'EProtectedFeature',
        ),
      ),
    }),
  ],
  'GetSecurityPinCodeInfoResponse',
);

export const resetSecurityPinCodeResponse = t.type(
  {
    Status: t.literal(EResetSecurityPinCodeStatus.Successful),
  },
  'ResetSecurityPinCodeResponse',
);

export const changeSecurityPinCodeResponse = t.type(
  {
    Status: t.literal(EChangeSecurityPinCodeStatus.Successful),
  },
  'ChangeSecurityPinCode',
);
