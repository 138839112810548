import React, { FC } from 'react';

import { Gifts } from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Icon, Text } from '@gaming1/g1-ui';

import { multiplyBy100 } from '../../../boostus/helpers';
import { BettingIcon } from '../../../common/components/BettingIcon';
import { useDateFormat, useIsDesktop } from '../../../hooks';

import {
  AmountAvailableBorder,
  AmountAvailableBox,
  CardContainer,
  OpenDetailsText,
  ValidityDate,
} from './styles';

type CardProps = {
  /**
   * The amount of gifts OR the amount that the gift provides
   * Example : If freebet, the amount of freebet of 5€ is 3.
   *           If boostus, the amount that the boostus provides is +25%.
   *           If promotion, none.
   */
  amount?: number;
  /** The type of gift that the Card will represents */
  cardType: Gifts;
  /** If the gift is locked */
  isLocked?: boolean;
  /** The function to use when the user clicks on the "open details" or "selected" button" */
  onClick?: () => void;
  /** If we need to show the "select" label instead of the details one in the CTA */
  showSelectLabel?: boolean;
  testId?: string;
  /**
   * The title of the card (ex: Freebet of 5,00€ || BetXtra Combi 4+ )
   * If it's a Freebet, we have to do a formatMoney with the correct translation key.
   * We have to do the formatMoney in this component so we don't copy the code when this component is used.
   *
   */
  title: string;
  /** The validityDate of the gift (if freebet and no validityDate, it's locked) */
  validityDate?: string;
};

const EXPAND_BUTTON_ICON_SIZE = 14;

/**
 * Render a Card that could be a "Freebet" or a "Boostus".
 *
 * The Card will contains an icon that will change depending of the {cardType},
 * a title, a string with the {validityDate}, a CTA to select or open the details
 * of the Freebet || Boostus and a container with the amount of Freebets in the group
 * OR the boostus percentage.
 */
export const Card: FC<CardProps> = ({
  amount,
  cardType,
  isLocked = false,
  onClick,
  showSelectLabel = false,
  testId = 'gift-card-container',
  title,
  validityDate,
}) => {
  const { t } = useTranslation('betting');

  const isDesktop = useIsDesktop();

  const formatMoney = useFormatMoney();

  /**
   * This function will returns the right translation key depending
   * of the days remaining before the given validityDate.
   */
  const formatDateCorrectly = useDateFormat(validityDate);

  return (
    <CardContainer
      data-testid={testId}
      width={isDesktop ? null : '100%'}
      onClick={onClick}
      isLocked={isLocked}
    >
      <Box
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Box flexDirection="row" alignItems="center">
          {cardType === 'freebet' && (
            <Box minWidth="32" minHeight="32">
              <BettingIcon
                id="FreebetSquare"
                type="FreebetSquare"
                width="32"
                height="32"
              />
            </Box>
          )}
          {cardType === 'boostus' && (
            <Box minWidth="32" minHeight="32">
              <BettingIcon
                id="BoostusGradient"
                type="BoostusGradient"
                width="32"
                height="32"
              />
            </Box>
          )}
          {cardType === 'promotion' && (
            <Box minWidth="32" minHeight="32">
              <BettingIcon
                id="PromotionsGradient"
                type="PromotionsGradient"
                width="32"
                height="32"
              />
            </Box>
          )}
          <Box ml="sm" mr="lg">
            <Text mt="none" fontWeight="bold" fontSize="lg">
              {cardType === 'freebet'
                ? t('freebets.card.amount', {
                    amount: formatMoney(Number(title)),
                  })
                : title}
            </Text>
            {validityDate && (
              <ValidityDate mt="xxs" fontSize="md">
                {formatDateCorrectly}
              </ValidityDate>
            )}
            <OpenDetailsText>
              {t(
                !showSelectLabel
                  ? 'gifts.card.details.text'
                  : 'gifts.card.select.text',
              )}
            </OpenDetailsText>
          </Box>
        </Box>
        <AmountAvailableBorder type={cardType}>
          <AmountAvailableBox type={cardType}>
            {isLocked && (
              <Icon
                id="LockClosed"
                type="LockClosed"
                height={EXPAND_BUTTON_ICON_SIZE}
                mr="xxxs"
              />
            )}
            {cardType === 'freebet' &&
              t('freebets.card.nbFreebetsAvailable.multiplier', {
                nb: amount,
              })}
            {cardType === 'boostus' &&
              t('boostus.card.amount.text', {
                amount: multiplyBy100(amount || 0),
              })}
            {cardType === 'promotion' && (
              <Icon id="information" type="CircleInfo" width="16" height="16" />
            )}
          </AmountAvailableBox>
        </AmountAvailableBorder>
      </Box>
    </CardContainer>
  );
};
