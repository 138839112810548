import * as t from 'io-ts';

import {
  GroupConditionType as EGroupConditionType,
  RangeConditionType as ERangeConditionType,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

export const GroupConditionItemCodec = t.type(
  {
    Identifier: t.string,
    DisplayName: t.string,
  },
  'GroupConditionItem',
);

export const groupConditionCodec = t.intersection(
  [
    t.type({
      Type: createEnumType<EGroupConditionType>(EGroupConditionType),
    }),
    t.partial({
      Items: t.array(GroupConditionItemCodec),
    }),
  ],
  'GroupCondition',
);

export const rangeConditionCodec = t.intersection(
  [
    t.type({
      Type: createEnumType<ERangeConditionType>(ERangeConditionType),
    }),
    t.partial({
      Min: t.number,
      Max: t.number,
    }),
  ],
  'RangeCondition',
);

export const conditionListCodec = t.intersection(
  [
    t.type({
      Id: t.string,
    }),
    t.partial({
      RangeConditions: t.array(rangeConditionCodec),
      GroupConditions: t.array(groupConditionCodec),
    }),
  ],
  'ConditionList',
);
