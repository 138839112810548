import {
  EGetFavoriteGameListStatus,
  ESetFavoriteGameListStatus,
} from '@gaming1/g1-requests-gaming';

export const getFavoriteRecentGamesErrorMessages = {
  [EGetFavoriteGameListStatus.InternalError]: 'core:error.internalServer',
  [EGetFavoriteGameListStatus.InvalidRoom]: 'core:error.invalidRoom',
  [EGetFavoriteGameListStatus.InvalidPlayer]: 'core:error.invalidPlayer',
  [EGetFavoriteGameListStatus.NoFavoriteGames]:
    'gaming:favoriteRecentGames.message.noFavoriteGames',
};

export const setFavoriteGameListErrorMesssages = {
  [ESetFavoriteGameListStatus.InternalError]: 'core:error.internalServer', // 0
  [ESetFavoriteGameListStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
  [ESetFavoriteGameListStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 3
};
