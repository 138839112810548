/* istanbul ignore file */
import { gamingPublicRoutes } from '@gaming1/g1-gaming-api';
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitely
// typed as it, otherwise this break the RouteParams mapped type!
export const gamingRoutes = {
  ...gamingPublicRoutes,
  gameListMyGames: createRoute('/games/my-games'),
  gameListCategory: createParamRoute('/games/:category', {
    category: '',
  }),
  gameListCasino: createRoute('/casino-games'),
  gameListCasinoMyGames: createRoute('/casino-games/my-games'),
  gameListCasinoCategory: createParamRoute('/casino-games/:category', {
    category: '',
  }),
  gameListDice: createRoute('/dice-games'),
  gameListDiceMyGames: createRoute('/dice-games/my-games'),
  gameListDiceCategory: createParamRoute('/dice-games/:category', {
    category: '',
  }),
  searchResults: createRoute('/gaming-search-results'),
  searchCasinoResults: createRoute('/gaming-search-casino-results'),
  searchDiceResults: createRoute('/gaming-search-dice-results'),
  playTournament: createParamRoute('/tournament/play/:tournamentId', {
    tournamentId: '',
  }),
  tournaments: createRoute('/tournaments'),
  finishedTournaments: createRoute('/tournaments/finished'),
  tournamentDetails: createParamRoute('/tournaments/details/:tournamentId', {
    tournamentId: '',
  }),
};
