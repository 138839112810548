/* eslint-disable react/no-array-index-key */
import isEqual from 'lodash/isEqual';
import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardScorePerPeriodSelector,
  getScoreBoardTeamNamesSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useMediaBreakPoint } from '@gaming1/g1-ui';
import { useEdgesVisibility } from '@gaming1/g1-utils';

import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardPlayTime } from '../ScoreBoardPlayTime';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import { useAutomaticRightScroll } from './hooks';
import {
  ScoreBoardFullTemplateColumn as TemplateColumn,
  ScoreBoardFullTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardFullTemplateEllipsis as TemplateEllipsis,
  ScoreBoardFullTemplateItem as TemplateItem,
  ScoreBoardFullTemplateRow as TemplateRow,
  ScoreBoardFullTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the full scoreboard with a period template */
export const ScoreBoardFullPeriodTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
  sportId,
}) => {
  const isDesktop = useMediaBreakPoint({ min: 'lg' });
  const { team1: name1, team2: name2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const scorePerPeriod = useBettingGetterSelector({
    getterSelector: getScoreBoardScorePerPeriodSelector,
    args: [eventId],
    equalityFn: isEqual,
  });

  const automaticRightScrollRef = useAutomaticRightScroll();
  const [edges, edgesVisibilityRef] = useEdgesVisibility();
  const hasScrollablePeriods = !edges.isLeftVisible || !edges.isRightVisible;

  const isMuted = (i: number) => i !== scorePerPeriod.length - 1;

  return (
    <TemplateWrapper data-testid="scoreboard-full-period-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper
          flexDirection="row"
          justifyContent="space-between"
        >
          <TemplateColumn gap="xxxs" pb="xxs" ellipsis>
            <TemplateRow pt="xxxs" pb="xxs">
              <TemplateEllipsis>
                <ScoreBoardPlayTime
                  eventId={eventId}
                  fontSize="md"
                  textAlign="left"
                />
              </TemplateEllipsis>
            </TemplateRow>
            <TemplateRow>
              <TemplateItem>
                <TemplateEllipsis>{name1}</TemplateEllipsis>
              </TemplateItem>
            </TemplateRow>
            <TemplateRow>
              <TemplateItem>
                <TemplateEllipsis>{name2}</TemplateEllipsis>
              </TemplateItem>
            </TemplateRow>
          </TemplateColumn>
          <TemplateColumn maxWidth="50%">
            <TemplateRow justifyContent="flex-end">
              <TemplateColumn
                gap="xxxs"
                pb="xs"
                pl="xs"
                pr="xxs"
                shadow={hasScrollablePeriods ? 'right' : undefined}
              >
                <TemplateItem />
                <TemplateItem fontSize={isDesktop ? 'xl' : 'lg'}>
                  {score1}
                </TemplateItem>
                <TemplateItem fontSize={isDesktop ? 'xl' : 'lg'}>
                  {score2}
                </TemplateItem>
              </TemplateColumn>
              <TemplateColumn
                overflowX="scroll"
                pb="xxs"
                pl="xxs"
                ref={(element) => {
                  edgesVisibilityRef(element);
                  automaticRightScrollRef(element);
                }}
              >
                <TemplateRow gap="xxxs">
                  {scorePerPeriod.map((period, i) => (
                    <TemplateColumn key={i} gap="xxxs">
                      <TemplateItem isMuted={isMuted(i)}>{i + 1}</TemplateItem>
                      <TemplateItem hasBackground isMuted={isMuted(i)}>
                        {period.team1}
                      </TemplateItem>
                      <TemplateItem hasBackground isMuted={isMuted(i)}>
                        {period.team2}
                      </TemplateItem>
                    </TemplateColumn>
                  ))}
                </TemplateRow>
              </TemplateColumn>
            </TemplateRow>
          </TemplateColumn>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
