/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:52:31 23-08-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  PlayerBettingProfileRequest,
  PlayerBettingProfileResponse,
} from '../entities';

export const getPlayerBettingProfile = (options: PlayerBettingProfileRequest) =>
  createRequest<PlayerBettingProfileResponse>(
    212,
    'GetPlayerBettingProfile',
    options,
  );
