import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardLastPeriodScoreSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import { useScoreBoardCompactTranslations } from './hooks';
import {
  ScoreBoardCompactBackgroundWrapper as BackgroundWrapper,
  ScoreBoardCompactTemplateColumn as TemplateColumn,
  ScoreBoardCompactTemplateItem as TemplateItem,
  ScoreBoardCompactTemplateItemTitle as TemplateItemTitle,
  ScoreBoardCompactTemplateRow as TemplateRow,
  ScoreBoardCompactTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the compact scoreboard with a period template */
export const ScoreBoardCompactPeriodTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
}) => {
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: lastPeriod1, team2: lastPeriod2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardLastPeriodScoreSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  const translations = useScoreBoardCompactTranslations();

  return (
    <TemplateWrapper data-testid="scoreboard-compact-period-template">
      <TemplateRow>
        <TemplateColumn>
          <BackgroundWrapper backgroundRadius="left">
            <TemplateItem>{lastPeriod1}</TemplateItem>
            <TemplateItem>{lastPeriod2}</TemplateItem>
          </BackgroundWrapper>
        </TemplateColumn>
        <TemplateColumn>
          <TemplateItemTitle title={translations.score} isMuted>
            {translations.score.charAt(0).toUpperCase()}
          </TemplateItemTitle>
          <BackgroundWrapper backgroundRadius="right">
            <TemplateItem isHighlighted>{score1}</TemplateItem>
            <TemplateItem isHighlighted>{score2}</TemplateItem>
          </BackgroundWrapper>
        </TemplateColumn>
      </TemplateRow>
    </TemplateWrapper>
  );
};
