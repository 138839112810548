/* Entities */

import { createSelector } from 'reselect';

import { ApplicationWithGamingState } from '../../store/types';

import { Game } from './types';

/**
 * Given a game guid, this selector returns the corresponding game details or
 * undefined if not found.
 *
 * ### Example usage
 * ```tsx
 *
 * const amazonFierceGame =
 *   useSelector(gameSelector('032a8106-9e3f-4e92-9910-c5bd7b0bcdb1'));
 * ```
 * @param gameId
 */
export const gameSelector =
  (gameId: string) =>
  (state: ApplicationWithGamingState): Game | undefined =>
    state.gaming.common.entities.games[gameId];

const commonEntitiesSelector = (state: ApplicationWithGamingState) =>
  state.gaming.common.entities;

export const gamesEntitiesSelector = createSelector(
  commonEntitiesSelector,
  (entities) => entities.games,
);
