import { FC } from 'react';

import { PrismicSlice } from '@gaming1/g1-cms';

import { useRenderPrismicSlice } from './hooks';

type PrismicChildProps = {
  parentIds: string[];
  slice: PrismicSlice;
  testId?: string;
};

export const PrismicChild: FC<PrismicChildProps> = ({
  parentIds,
  slice,
  testId,
}) => useRenderPrismicSlice(parentIds, slice, testId);
