/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:21:10 10-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetRiskManagementRequest,
  GetRiskManagementResponse,
  GetSystemRiskManagementRequest,
  GetSystemRiskManagementResponse,
} from '../entities';

export const getRiskManagement = (options: GetRiskManagementRequest) =>
  createRequest<GetRiskManagementResponse>(202, 'GetRiskManagement', options);

export const getSystemRiskManagement = (
  options: GetSystemRiskManagementRequest,
) =>
  createRequest<GetSystemRiskManagementResponse>(
    202,
    'GetSystemRiskManagement',
    options,
  );
