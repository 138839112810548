import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { COMMON_PRISMIC_AJAX_CONFIG } from '../../common/constants';
import { CMSEpic } from '../../store/types';

import * as actions from './actions';
import { getPrismicReferencesResponse } from './codecs';

export const prismicReferenceEpic: CMSEpic = (
  actions$,
  _,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchPrismicReference.request)),
    withLatestFrom(config$),
    switchMap(([, config]) =>
      ajaxFetch(
        `${config.network.prismicUrl}`,
        COMMON_PRISMIC_AJAX_CONFIG,
      ).pipe(
        mapGuard(getPrismicReferencesResponse),
        map((response) => response.refs[0].ref),
        map(actions.fetchPrismicReference.success),
        catchError((err) =>
          of(
            actions.fetchPrismicReference.failure(
              createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicReference',
              ),
            ),
          ),
        ),
      ),
    ),
  );
