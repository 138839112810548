import React, { useContext, useState, VFC } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-core';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { AppAnchor, Button, Icon, RenderOnMedia } from '@gaming1/g1-ui';
import { persistIn, readFrom } from '@gaming1/g1-utils';

import {
  CookieMessageButtonWrapper,
  CookieMessageContent,
  CookieMessageWrapper,
  IconWrapper,
  PrivacyPolicyLinkWrapper,
} from './styles';

export const COOKIES_ACCEPTED_LOCAL_STORAGE_KEY = 'areCookiesAccepted';

export const CookieMessage: VFC = () => {
  const { t } = useTranslation();

  const config = useContext(ConfigContext);
  const { currentLocale: locale, values } = useI18n();

  const [areCookiesAlreadyAccepted, setAreCookiesAlreadyAccepted] = useState(
    !!readFrom(localStorage, COOKIES_ACCEPTED_LOCAL_STORAGE_KEY),
  );

  const onClickAcceptButton = () => {
    persistIn(localStorage, COOKIES_ACCEPTED_LOCAL_STORAGE_KEY, true);
    setAreCookiesAlreadyAccepted(true);
  };

  if (!areCookiesAlreadyAccepted) {
    return (
      <CookieMessageWrapper data-testid="cookie-banner-wrapper">
        <CookieMessageContent data-testid="cookie-banner-content">
          <IconWrapper>
            <Icon id="cookie" type="Cookie" />
          </IconWrapper>
          <PrivacyPolicyLinkWrapper>
            <Trans
              i18nKey="cookie.banner.description"
              ns="core"
              values={values.siteName}
              components={{
                AppAnchor: (
                  <AppAnchor
                    href={`${config.network.mediaUrl}/${config.core.cguPrivacyFileNamePrefix}${locale}.pdf`}
                  />
                ),
              }}
            />
          </PrivacyPolicyLinkWrapper>
          <RenderOnMedia min="sm">
            <CookieMessageButtonWrapper>
              <Button type="primary" onClick={onClickAcceptButton} py="xxs">
                {t('cookie.button.text')}
              </Button>
            </CookieMessageButtonWrapper>
          </RenderOnMedia>
        </CookieMessageContent>
        <RenderOnMedia max="xs">
          <CookieMessageButtonWrapper>
            <Button type="primary" onClick={onClickAcceptButton} py="xxs">
              {t('cookie.button.text')}
            </Button>
          </CookieMessageButtonWrapper>
        </RenderOnMedia>
      </CookieMessageWrapper>
    );
  }
  return null;
};
