import { useSelector } from 'react-redux';

import {
  bettingSlipGetterSelector,
  bettingSlipKeysGetterSelector,
} from '@gaming1/g1-betting';
import { Table } from '@gaming1/g1-ui';

import { BettingSlipIemDebugging } from './item';
import { BettingSlipRMDebugging } from './rm';

export const BettingSlipDebugging = () => {
  const bsKeys = useSelector(bettingSlipKeysGetterSelector)();

  const bsSelector = useSelector(bettingSlipGetterSelector);

  return (
    <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Events</th>
          <th>RM</th>
        </tr>
      </thead>
      <tbody>
        {bsKeys.map((key) => {
          const bs = bsSelector(key);

          return (
            <tr key={key}>
              <td>{key}</td>
              <td>
                {bs?.items.map((item) => {
                  if (!item) {
                    return undefined;
                  }
                  return (
                    <>
                      <BettingSlipIemDebugging
                        key={item.id}
                        item={item}
                        bsId={key}
                      />
                      <hr />
                    </>
                  );
                })}
              </td>
              <td>
                <BettingSlipRMDebugging bsId={key} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
