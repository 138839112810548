export enum License {
  A = 'A',
  B = 'B',
  F = 'F',
}

export type CoreConfig = Readonly<{
  /** CGU file name prefix */
  cguFileNamePrefix: string;
  /** CGU Privacy file name prefix */
  cguPrivacyFileNamePrefix: string;
  geoComply: {
    /** Enable GeoComply */
    activated: boolean;
    /** GeoComply's environment to be used */
    environment: 'production' | 'staging';
    /** Installer key used to install GeoComply's PLC */
    installerKey: string;
    /** A number in seconds after which the geolocation request will timeout */
    timeoutSeconds: number;
    /** UMS server url used to download GeoComply's PLC */
    umsServerUrl: string;
    /** GeoComply's version to be used */
    version: string;
  };
  /**
   * Google API key
   *
   * Each DTA environment has its own key shared by any partners.
   *
   * Leave default value empty to ensure a wrong key is never used
   *
   * Dev, will only work from the office IP
   * AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks
   *
   * Test, will only work from these urls https://*.gaming1.com/*
   * AIzaSyCpmjqsM8Msln_EqLRZsNIgQIy4pPpcuLw
   *
   * Acc, will only work from these urls https://*.gaming1.com/*
   * AIzaSyD5JiTG0g30qzw7_rOKLflxcL2Rzm7OUDM
   *
   * For the production environment each partner has its own key which is restricted to its urls
   *
   * */
  googleAPIKey: string;
  /** Array of allowed country ISO code, leave the array empty for no restriction */
  ibanAllowedCountries: string[];
  /** Iovation configuration */
  iovation: {
    /** Enable Iovation tracking */
    enabled: boolean;
    /** Iovation's version to be used */
    version: 'snare' | 'loader';
  };
  /** Activated licenses */
  licenses: License[] | null;
  /** Mobile app apk path for download */
  mobileAppApkPath: string | null;
  /** Mobile app identifiers */
  mobileAppIdentifiers: {
    /** Android application identifier (example: be.gaming1.blanco)  */
    android: string | null;
    /** iOS application identifier (example: 1560187768) */
    ios: string | null;
  };
  /** Precision used when money is displayed */
  moneyPrecision: number;
  /** All keys related to privacy settings and cookies/storages complicance */
  privacySettings: {
    /** Privacy content consent handler (`null` while DidomiSDK is loading) */
    didomiApiKey: string | null;
    /** All purpose IDs related to the partner */
    purposeIDs: {
      /** Personnal Advertizing */
      advertizing: string;
      /** Unavoidable user data usage */
      essential: string;
      /** Analytics and performance tracking */
      performanceMeasure: string;
      /** UX analytics tracking */
      uxImprovement: string;
    };
    type: 'Didomi' | 'message' | 'none';
  };
  /** Whether the registration steps should be skipped (and thus, display the form directly) or not */
  skipRegistrationSteps: boolean;
  /** Zendesk widget Key */
  zendeskWidgetKey: string | null;
}>;
