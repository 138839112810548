import React, { FC, useContext } from 'react';

import { PrismicMediasTextBlockSlice } from '@gaming1/g1-cms';
import { Box, isMediaBreakPointBelowOrEqual, useMedia } from '@gaming1/g1-ui';
import { isNonNullable } from '@gaming1/g1-utils';

import { PrismicChildrenContext } from '../PrismicChildren/PrismicChildrenContext';
import { PrismicRichText } from '../PrismicRichText';

import { PrismicMediasTextContainer, PrismicMediasTextGrid } from './styles';

export type PrismicMediasTextBlockProps = {
  /** Prismic 'Media card' slice */
  slice: PrismicMediasTextBlockSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Media card` slice
 * A component having a 'card' elevation style, with a media (image/map/video) at the top,
 * and some text content at the bottom
 */
export const PrismicMediasTextBlock: FC<PrismicMediasTextBlockProps> = ({
  slice,
  testId = 'prismic-medias-text-block',
  parentIds = [],
}) => {
  const { component: PrismicChildren } = useContext(PrismicChildrenContext);
  const { mediaBP } = useMedia();
  const isSmallBP = isMediaBreakPointBelowOrEqual(mediaBP, 'sm');

  const mainMedia = slice.primary.main_media && (
    <PrismicChildren
      childrenIds={slice.primary.main_media}
      parentIds={
        slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
      }
    />
  );

  const otherMedias = (
    <PrismicMediasTextGrid
      itemsCount={isSmallBP ? 2 : slice.items.length}
      data-testid="prismic-other-medias"
    >
      <PrismicChildren
        childrenIds={slice.items
          .map((item) => item.media)
          .filter(isNonNullable)}
        parentIds={
          slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
        }
      />
    </PrismicMediasTextGrid>
  );

  const width = slice.primary.media_width || '33%';
  return (
    <Box data-testid={testId}>
      <PrismicMediasTextContainer
        alignment={
          slice.primary.main_media_alignment?.toLowerCase() === 'right'
            ? 'right'
            : 'left'
        }
      >
        <Box width={isSmallBP ? '100%' : width}>
          {mainMedia}
          {slice.items.length === 2 && !isSmallBP && otherMedias}
        </Box>
        <Box
          className="content-wrapper"
          width={isSmallBP ? '100%' : `calc(100% - ${width})`}
        >
          <PrismicRichText content={slice.primary.content} />
        </Box>
      </PrismicMediasTextContainer>
      {(isSmallBP || slice.items.length === 4) && otherMedias}
    </Box>
  );
};
