import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';

const ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT = 10;

export const RedCircle = styled.div`
  border-radius: 5px;
  background: ${colors('danger')};
  width: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
  height: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
`;
