import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { OddsUnit } from './actions';

export type EventState = 'live' | 'prematch';
export type BettingSlipStringType = 'single' | 'combi' | 'system';
export type BettingSlipContentType = 'ticket' | 'setting' | 'gift';
export type BettingSlipRankType = 'single' | 'normal' | 'trixie';

export type CommonState = {
  oddsUnit: OddsUnit;
};

export type CommonActions = ActionType<typeof actions>;

export enum EBettingSlipIdentifier {
  Main = 'Main',
  Fast = 'Fast',
  Missing = 'missing',
  Mock_id = 'mock_id',
}

export type BettingSlipIdentifierType = EBettingSlipIdentifier | string;

export type BettingSlipIdType = { bettingSlipId: BettingSlipIdentifierType };
