import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { CmsActions } from '../../store/types';

import * as actions from './actions';
import { PrismicReferenceState } from './types';

export const initialState: PrismicReferenceState = {
  requests: {
    getPrismicReference: generateInitialRequestState(null),
  },
  prismicReference: '',
};

export const prismicReferenceReducer = (
  state: PrismicReferenceState = initialState,
  action: CmsActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchPrismicReference.request):
        produceLoadingState(draftState.requests.getPrismicReference);
        break;

      case getType(actions.fetchPrismicReference.failure):
        produceFailureState(
          draftState.requests.getPrismicReference,
          action.payload,
        );
        break;

      case getType(actions.fetchPrismicReference.success):
        produceSuccessState(draftState.requests.getPrismicReference);
        draftState.prismicReference = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
