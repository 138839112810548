import styled, { css } from 'styled-components';
import { color, fontSize, space, width } from 'styled-system';

import { colors, fontSizes, fontWeight } from '@gaming1/g1-style';

import { StyledSystemProps } from '../types';

export type AppLabelProps = StyledSystemProps & {
  /**
   * Display the label as a block
   * Default: false
   */
  block?: boolean;
  /**
   * Use a bolder font
   * Default: false;
   */
  bold?: boolean;
};

export const AppLabel = styled.label<AppLabelProps>`
  font-size: ${fontSizes('md')};
  color: ${colors('label')};

  ${({ block }) =>
    block &&
    css`
      display: block;
    `}

  ${({ bold }) =>
    bold &&
    css`
      ${fontWeight('bold')}
    `}

${space}
${width}
${fontSize}
${color}
`;
