import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import { haveRanks } from '../../helpers';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import {
  getInitialRootBettingSlipState,
  getRankById,
  getRanks,
} from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const bettingSlipRanksReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.updateStakeForRank): {
        const {
          payload: { bettingSlipId, stake, rankId },
        } = action;

        const bettingslip = draftState.bettingSlips[bettingSlipId];

        if (!bettingslip || !haveRanks(bettingslip)) {
          return;
        }
        const rank = getRankById(bettingslip.ranks, rankId);
        if (!rank) {
          return;
        }

        if (rank?.type === 'trixie') {
          bettingslip.ranks
            .filter((rankItem) => rankItem.type !== 'trixie')
            .forEach((rankItem) => {
              rankItem.stake = stake;
              rankItem.available = !stake;
            });
        }

        rank.stake = stake;

        break;
      }
      case getType(actions.initBettingSlipBySession): {
        const {
          payload: {
            bettingSlip: { bettingSlipId },
          },
        } = action;

        if (
          draftState.bettingSlips[bettingSlipId].configuration.type === 'system'
        ) {
          draftState.bettingSlips[bettingSlipId].ranks = getRanks(
            draftState.bettingSlips[bettingSlipId].items
              .filter((bsItem) => bsItem.checked)
              .map((bsItem) => bsItem.id),
          );
        }

        break;
      }
      case getType(actions.addBettingSlipOutcome):
      case getType(actions.removeBettingSlipOutcome):
      case getType(actions.toggleBettingSlipOutcome):
      case getType(actions.toggleCheckItem):
      case getType(actions.changeBettingSlipType): {
        const {
          payload: { bettingSlipId },
        } = action;

        if (
          draftState.bettingSlips[bettingSlipId].configuration.type === 'system'
        ) {
          draftState.bettingSlips[bettingSlipId].ranks = getRanks(
            draftState.bettingSlips[bettingSlipId].items
              .filter((bsItem) => bsItem.checked)
              .map((bsItem) => bsItem.id),
          );
        }

        break;
      }
      default: // Immer will automatically return the state
    }
  });
