import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../store/types';
import * as actions from '../actions';
import { extractBetPipeIdFromAction } from '../helpers';
import { BetPipeStateSlice } from '../types';

const initialState: BetPipeStateSlice = {};

/**
 * Ensure the bettingSlipState is initialized for any action having a betPipeId
 */
export const betPipeStatusReducer = (
  state = initialState,
  action: BettingActions,
): BetPipeStateSlice =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getBettingSlipInitializationData.failure):
      case getType(actions.getInfoFromOutcomes.failure):
      case getType(actions.launchRiskManagement.failure):
      case getType(actions.freebetConditionsForBettingSlip.failure):
      case getType(actions.boostusConditionsForBettingSlip.failure):
      case getType(actions.promotionsForBettingSlip.failure):
      case getType(actions.launchPlaceBet.failure): {
        // Cancel the pipe if one of the steps has failed
        const betPipeId = extractBetPipeIdFromAction(action);
        if (betPipeId) {
          draftState[betPipeId].status = 'cancelled';
        }
        break;
      }
      case getType(actions.cancelBetPipe): {
        const {
          payload: { betPipeId },
        } = action;
        // Don't set to "cancelled" a pipe that has complete
        if (draftState[betPipeId].status === 'started') {
          draftState[betPipeId].status = 'cancelled';
        }
        break;
      }
      case getType(actions.completedPipe): {
        const {
          payload: { betPipeId },
        } = action;
        draftState[betPipeId].status = 'completed';
        break;
      }
      default: // Immer will automatically return the state
    }
  });
