/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:21:25 04-05-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  AutoExcludeRequestDTO,
  AutoExcludeResponseDTO,
  BlacklistPlayerRequestDTO,
  BlacklistPlayerResponseDTO,
  GetAutoExcludeInfoRequestDTO,
  GetAutoExcludeInfoResponseDTO,
  GetConnectionLimitRemainingTimeRequestDTO,
  GetConnectionLimitRemainingTimeResponseDTO,
  GetLimitsRequestDTO,
  GetLimitsResponseDTO,
  GetSessionDurationRequestDTO,
  GetSessionDurationResponseDTO,
  GetSpentTimeLimitRemainingTimeRequestDTO,
  GetSpentTimeLimitRemainingTimeResponseDTO,
  ResponsibleGamingDTO,
  SessionFinishedDisplayedRequestDTO,
  SessionFinishedDTO,
  SetLimitsRequestDTO,
  SetLimitsResponseDTO,
  SetSessionDurationRequestDTO,
  SetSessionDurationResponseDTO,
  UnExcludeRequestDTO,
  UnExcludeResponseDTO,
  Void,
} from '../entities';

export const autoExclude = (options: AutoExcludeRequestDTO) =>
  createRequest<AutoExcludeResponseDTO>(100, 'AutoExclude', options);

export const unExclude = (options: UnExcludeRequestDTO) =>
  createRequest<UnExcludeResponseDTO>(100, 'UnExclude', options);

export const getAutoExcludeInfo = (options: GetAutoExcludeInfoRequestDTO) =>
  createRequest<GetAutoExcludeInfoResponseDTO>(
    100,
    'GetAutoExcludeInfo',
    options,
  );

export const setLossLimits = (options: SetLimitsRequestDTO) =>
  createRequest<SetLimitsResponseDTO>(100, 'SetLossLimits', options);

export const getLossLimits = (options: GetLimitsRequestDTO) =>
  createRequest<GetLimitsResponseDTO>(100, 'GetLossLimits', options);

export const setWageLimits = (options: SetLimitsRequestDTO) =>
  createRequest<SetLimitsResponseDTO>(100, 'SetWageLimits', options);

export const getWageLimits = (options: GetLimitsRequestDTO) =>
  createRequest<GetLimitsResponseDTO>(100, 'GetWageLimits', options);

export const setConnectionTimeLimits = (options: SetLimitsRequestDTO) =>
  createRequest<SetLimitsResponseDTO>(100, 'SetConnectionTimeLimits', options);

export const getSpentTimeOnActivityLimit = (options: GetLimitsRequestDTO) =>
  createRequest<GetLimitsResponseDTO>(
    100,
    'GetSpentTimeOnActivityLimit',
    options,
  );

export const getConnectionLimitRemainingTime = (
  options: GetConnectionLimitRemainingTimeRequestDTO,
) =>
  createRequest<GetConnectionLimitRemainingTimeResponseDTO>(
    100,
    'GetConnectionLimitRemainingTime',
    options,
  );

export const getSpentTimeLimitRemainingTime = (
  options: GetSpentTimeLimitRemainingTimeRequestDTO,
) =>
  createRequest<GetSpentTimeLimitRemainingTimeResponseDTO>(
    100,
    'GetSpentTimeLimitRemainingTime',
    options,
  );

export const setSpentTimeOnActivityLimit = (options: SetLimitsRequestDTO) =>
  createRequest<SetLimitsResponseDTO>(
    100,
    'SetSpentTimeOnActivityLimit',
    options,
  );

export const sessionFinished = (options: string) =>
  createRequest<SessionFinishedDTO>(100, 'SessionFinished', options);

export const responsibleGaming = (options: string) =>
  createRequest<ResponsibleGamingDTO>(100, 'ResponsibleGaming', options);

export const sessionFinishedDisplayed = (
  options: SessionFinishedDisplayedRequestDTO,
) => createRequest<Void>(100, 'SessionFinishedDisplayed', options);

export const blacklistPlayer = (options: BlacklistPlayerRequestDTO) =>
  createRequest<BlacklistPlayerResponseDTO>(100, 'BlacklistPlayer', options);

export const setDepositLimits = (options: SetLimitsRequestDTO) =>
  createRequest<SetLimitsResponseDTO>(100, 'SetDepositLimits', options);

export const getConnectionLimits = (options: GetLimitsRequestDTO) =>
  createRequest<GetLimitsResponseDTO>(100, 'GetConnectionLimits', options);

export const setConnectionLimits = (options: SetLimitsRequestDTO) =>
  createRequest<SetLimitsResponseDTO>(100, 'SetConnectionLimits', options);

export const setSessionDuration = (options: SetSessionDurationRequestDTO) =>
  createRequest<SetSessionDurationResponseDTO>(
    100,
    'SetSessionDuration',
    options,
  );

export const getSessionDuration = (options: GetSessionDurationRequestDTO) =>
  createRequest<GetSessionDurationResponseDTO>(
    100,
    'GetSessionDuration',
    options,
  );

export const getConnectionTimeLimits = (options: GetLimitsRequestDTO) =>
  createRequest<GetLimitsResponseDTO>(100, 'GetConnectionTimeLimits', options);

export const setMinimumTimeBetweenConnections = (
  options: SetLimitsRequestDTO,
) =>
  createRequest<SetLimitsResponseDTO>(
    100,
    'SetMinimumTimeBetweenConnections',
    options,
  );

export const getMinimumTimeBetweenConnections = (
  options: GetLimitsRequestDTO,
) =>
  createRequest<GetLimitsResponseDTO>(
    100,
    'GetMinimumTimeBetweenConnections',
    options,
  );
