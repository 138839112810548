import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userLoggedInSelector } from '@gaming1/g1-core';
import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  EActivitySource,
  EGetSpentTimeLimitRemainingTimeStatus,
} from '@gaming1/g1-requests';
import { useRequestState } from '@gaming1/g1-store';
import { Banner } from '@gaming1/g1-ui';
import {
  actions,
  getRemainingConnectionTimeRequestStateSelector,
  getRemainingTimeToSpendRequestStateSelector,
  remainingConnectionTimeSelector,
  remainingTimeToSpendSelector,
} from '@gaming1/g1-user';
import {
  slugifyPayload,
  useGetIsMounted,
  useRequestCallback,
} from '@gaming1/g1-utils';

import { userRoutes } from '../../../routes';

/**
 * This component will display a banner to warn the user his connection time
 * limits is about to be reached. It will also warn the user his times spent
 * betting and/or gaming are reached or about to be reached.
 */
export const RemainingTimeLimitBanner: FC = () => {
  const { t } = useTranslation('user');

  const getUserRoutePath = useRoutePath(userRoutes);

  const { status: remainingConnectionTimeStatus } = useRequestState(
    getRemainingConnectionTimeRequestStateSelector,
  );

  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const getIsMounted = useGetIsMounted();

  // Remaining connection time
  const remainingConnectionTimeResponse = useSelector(
    remainingConnectionTimeSelector,
  );

  const [remainingConnectionTime, setRemainingConnectionTime] = useState<
    number | null
  >(null);

  const [
    shouldDisplayConnectionTimeBanner,
    setShouldDisplayConnectionTimeBanner,
  ] = useState(false);

  const connectionTimeIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useRequestCallback(remainingConnectionTimeStatus, () => {
    // clear previous interval, it's needed if the player changes his limit
    if (connectionTimeIntervalRef.current) {
      clearInterval(connectionTimeIntervalRef.current);
    }

    if (
      remainingConnectionTimeResponse &&
      remainingConnectionTimeResponse.RemainingTimeInSeconds != null
    ) {
      setRemainingConnectionTime(
        remainingConnectionTimeResponse.RemainingTimeInSeconds,
      );

      connectionTimeIntervalRef.current = setInterval(() => {
        if (getIsMounted()) {
          setRemainingConnectionTime((previous) =>
            previous !== null ? previous - 1 : null,
          );
        }
      }, 1000);
    }
  });

  useEffect(() => {
    if (isUserLoggedIn && remainingConnectionTime) {
      const warnTime =
        remainingConnectionTimeResponse?.WarnPlayerBeforeLimitReached;
      if (warnTime && remainingConnectionTime <= warnTime * 60) {
        setShouldDisplayConnectionTimeBanner(true);

        // once the message has been displayed, there is no need to keep decreasing the remaining time
        if (connectionTimeIntervalRef.current) {
          clearInterval(connectionTimeIntervalRef.current);
        }
      }
    }

    return () => {
      if (!isUserLoggedIn && connectionTimeIntervalRef.current) {
        clearInterval(connectionTimeIntervalRef.current);
      }
    };
  }, [
    isUserLoggedIn,
    remainingConnectionTime,
    remainingConnectionTimeResponse,
  ]);

  // Remaining time to spend
  const remainingTimeToSpendResponse = useSelector(
    remainingTimeToSpendSelector,
  );

  const getRemainingTimeToSpendBettingRequestState = useSelector(
    getRemainingTimeToSpendRequestStateSelector,
  )({ source: EActivitySource.Betting });

  const [remainingTimeToSpendBetting, setRemainingTimeToSpendBetting] =
    useState<number | null>(null);

  const [
    shouldDisplayRemainingTimeToSpendBettingBanner,
    setShouldDisplayRemainingTimeToSpendBettingBanner,
  ] = useState(false);

  const [
    isRemainingTimeToSpendBettingBannerManualyClosed,
    setIsRemainingTimeToSpendBettingBannerManualyClosed,
  ] = useState(false);

  const [
    shouldDisplayRemainingTimeToSpendBettingReachedBanner,
    setShouldDisplayRemainingTimeToSpendBettingReachedBanner,
  ] = useState(false);

  const timeToSpendBettingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useRequestCallback(
    getRemainingTimeToSpendBettingRequestState.status,
    () => {
      // clear previous interval, it's needed if the player changes his limit
      if (timeToSpendBettingIntervalRef.current) {
        clearInterval(timeToSpendBettingIntervalRef.current);
      }

      const getRemainingTimeToSpendBetting =
        remainingTimeToSpendResponse[
          slugifyPayload({ source: EActivitySource.Betting })
        ];
      if (
        getRemainingTimeToSpendBetting &&
        getRemainingTimeToSpendBetting.RemainingTimeInSeconds != null
      ) {
        setRemainingTimeToSpendBetting(
          getRemainingTimeToSpendBetting.RemainingTimeInSeconds,
        );

        timeToSpendBettingIntervalRef.current = setInterval(() => {
          if (getIsMounted()) {
            setRemainingTimeToSpendBetting((previous) =>
              previous !== null ? previous - 1 : null,
            );
          }
        }, 1000);
      }
    },
    () => {
      // clear previous interval, it's needed if the player changes his limit
      if (timeToSpendBettingIntervalRef.current) {
        clearInterval(timeToSpendBettingIntervalRef.current);
      }

      if (
        getRemainingTimeToSpendBettingRequestState.errorCode ===
        EGetSpentTimeLimitRemainingTimeStatus.SpentTimeLimitReached
      ) {
        setShouldDisplayRemainingTimeToSpendBettingBanner(false);
        setShouldDisplayRemainingTimeToSpendBettingReachedBanner(true);
      }
    },
  );

  useEffect(() => {
    if (isUserLoggedIn && remainingTimeToSpendBetting !== null) {
      const warnTime =
        remainingTimeToSpendResponse[
          slugifyPayload({ source: EActivitySource.Betting })
        ]?.WarnPlayerBeforeLimitReached;

      if (
        warnTime &&
        remainingTimeToSpendBetting <= warnTime * 60 &&
        !isRemainingTimeToSpendBettingBannerManualyClosed
      ) {
        setShouldDisplayRemainingTimeToSpendBettingReachedBanner(false);
        setShouldDisplayRemainingTimeToSpendBettingBanner(true);
      }

      if (warnTime && remainingTimeToSpendBetting <= 0) {
        setShouldDisplayRemainingTimeToSpendBettingBanner(false);
        setShouldDisplayRemainingTimeToSpendBettingReachedBanner(true);

        // once the message has been displayed, there is no need to keep decreasing the remaining time
        if (timeToSpendBettingIntervalRef.current) {
          clearInterval(timeToSpendBettingIntervalRef.current);
        }
      }
    }

    return () => {
      if (!isUserLoggedIn && timeToSpendBettingIntervalRef.current) {
        clearInterval(timeToSpendBettingIntervalRef.current);
      }
    };
  }, [
    isRemainingTimeToSpendBettingBannerManualyClosed,
    isUserLoggedIn,
    remainingTimeToSpendBetting,
    remainingTimeToSpendResponse,
  ]);

  // request remaining time limits
  useEffect(() => {
    if (isUserLoggedIn) {
      // request remaining connection time
      dispatch(actions.getRemainingConnectionTime.request());

      // request remaining betting time
      dispatch(
        actions.getRemainingTimeToSpend.request({
          params: { source: EActivitySource.Betting },
        }),
      );
    } else {
      setShouldDisplayConnectionTimeBanner(false);
      setShouldDisplayRemainingTimeToSpendBettingBanner(false);
      setShouldDisplayRemainingTimeToSpendBettingReachedBanner(false);
    }
  }, [dispatch, isUserLoggedIn]);

  return (
    <>
      {shouldDisplayConnectionTimeBanner && (
        <Banner
          link={{
            label: t(
              'account.responsibleGaming.sessionLimits.connectionTime.notification.almostReached.linkLabel',
            ),
            route: getUserRoutePath('accountSessionLimits'),
          }}
          message={t(
            'account.responsibleGaming.sessionLimits.connectionTime.notification.almostReached.message',
          )}
          onCloseButtonClick={() => {
            setShouldDisplayConnectionTimeBanner(false);
          }}
          paddingY="xs"
          testId="connection-time-almost-reached"
        />
      )}

      {shouldDisplayRemainingTimeToSpendBettingBanner && (
        <Banner
          link={{
            label: t(
              'account.responsibleGaming.sessionLimits.timeToSpendBetting.notification.almostReached.linkLabel',
            ),
            route: getUserRoutePath('accountSessionLimits'),
          }}
          message={t(
            'account.responsibleGaming.sessionLimits.timeToSpendBetting.notification.almostReached.message',
          )}
          onCloseButtonClick={() => {
            setShouldDisplayRemainingTimeToSpendBettingBanner(false);
            setIsRemainingTimeToSpendBettingBannerManualyClosed(true);
          }}
          paddingY="xs"
          testId="time-spent-betting-almost-reached"
        />
      )}

      {shouldDisplayRemainingTimeToSpendBettingReachedBanner && (
        <Banner
          link={{
            label: t(
              'account.responsibleGaming.sessionLimits.timeToSpendBetting.notification.reached.linkLabel',
            ),
            route: getUserRoutePath('accountSessionLimits'),
          }}
          message={t(
            'account.responsibleGaming.sessionLimits.timeToSpendBetting.notification.reached.message',
          )}
          paddingY="xs"
          type="warning"
          testId="time-spent-betting-reached"
        />
      )}
    </>
  );
};
