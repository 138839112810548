/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:21:10 10-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetConditionsForBettingSlipRequest,
  GetConditionsForBettingSlipResponse,
  GetFreebetsRequest,
  GetFreebetsResponse,
} from '../entities';

export const getFreebets = (options: GetFreebetsRequest) =>
  createRequest<GetFreebetsResponse>(202, 'GetFreebets', options);

export const getFreebetConditionsForBettingSlip = (
  options: GetConditionsForBettingSlipRequest,
) =>
  createRequest<GetConditionsForBettingSlipResponse>(
    202,
    'GetFreebetConditionsForBettingSlip',
    options,
  );
