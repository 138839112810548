import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getInfoFromOutcomes } from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { bettingSlipAllItemsGetterSelector } from '../../bettingSlip/store/selectors';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingSlipIdentifierType } from '../../common/store/types';
import { getInfoFromOutcomesCodec } from '../../sportbook/store/codecs/getInfoFromOutcomes';
import { getOutcomeAndEventIdsFromItems } from '../../sportbook/store/helpers';
import { OutcomeEventAndMarketIds } from '../../sportbook/store/types';
import { ApplicationWithBettingState, BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

const extractIdsFromBS = (
  state: ApplicationWithBettingState,
  bettingSlipId?: BettingSlipIdentifierType,
): OutcomeEventAndMarketIds[] =>
  bettingSlipId
    ? getOutcomeAndEventIdsFromItems(
        bettingSlipAllItemsGetterSelector(state)(bettingSlipId),
      )
    : [];

export const getOutcomeInfoEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getInfoFromOutcomes.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      const ids =
        (!payload.ids || payload.ids.length === 0
          ? extractIdsFromBS(state, bettingSlipId)
          : payload.ids) || [];

      const OutcomeIds = ids.map((outcome) => outcome.outcomeId);
      const EventIds = ids.map((outcome) => outcome.eventId);

      if (OutcomeIds.length === 0) {
        return of(
          actions.getInfoFromOutcomes.failure(
            formatActionsData({
              data: {
                errorMessage: 'no outcome list',
                status: DEFAULT_SERVER_ERROR_STATUS,
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }
      return wsAdapter
        .request(
          getInfoFromOutcomes({
            OutcomeIds,
            EventIds,
          }),
          { retryOnTimeout: true, timeoutAfterMs: 5000 },
        )
        .pipe(
          takeUntilPipeIsCancelled(betPipeId, actions$),
          mapGuard(getInfoFromOutcomesCodec),
          map((data) =>
            actions.getInfoFromOutcomes.success(
              formatActionsData({
                data: {
                  ...data,
                  ...{ ids },
                },
                bettingSlipId,
                betPipeId,
              }),
            ),
          ),
          catchError((err) =>
            of(
              actions.getInfoFromOutcomes.failure(
                formatActionsData({
                  data: createFailurePayload(err, commonBettingErrorMessages),
                  bettingSlipId,
                  betPipeId,
                }),
              ),
            ),
          ),
        );
    }),
  );
