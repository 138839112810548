import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  currentGeolocationSelector,
  geolocationRequestStateSelector,
  isCurrentGeolocationDataValid,
  shouldShowGeolocationCheckIndicationSelector,
} from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import { useToast } from '@gaming1/g1-ui';
import { useDebounce, usePrevious } from '@gaming1/g1-utils';

import { GeoComplyToast } from '../GeoComplyToast';

export const GEOLOCATION_LOADING_TOAST_ID = 'geocomply-verifying-toast';
export const GEOLOCATION_VERIFIED_TOAST_ID = 'geocomply-validated-toast';

export const useGeolocationToasts = () => {
  const shouldShowGeolocationCheckIndication = useSelector(
    shouldShowGeolocationCheckIndicationSelector,
  );
  const isCurrentGeolocationValid = useSelector(isCurrentGeolocationDataValid);
  const currentGeolocation = useSelector(currentGeolocationSelector);
  const { isLoading: isRequestGeolocationRequestLoading } = useRequestState(
    geolocationRequestStateSelector,
  );
  const previousGeolocationValidity = usePrevious(currentGeolocation?.IsValid);
  const { addToast, closeToast } = useToast();
  const { t } = useTranslation('core');
  const loaderVisibilityDebounced = useDebounce(
    shouldShowGeolocationCheckIndication,
    100,
  );
  const shouldShowGeolocationVerifiedIndication = !!(
    isCurrentGeolocationValid && !previousGeolocationValidity
  );

  // "Loading geolocation" toast
  useEffect(() => {
    if (loaderVisibilityDebounced) {
      closeToast(GEOLOCATION_VERIFIED_TOAST_ID);
      addToast({
        id: GEOLOCATION_LOADING_TOAST_ID,
        render: () => (
          <GeoComplyToast icon="LocationPin">
            {t('geocomply.verifyingLocation')}
          </GeoComplyToast>
        ),
        variant: 'info',
        containerStyle: 'chips',
      });
    } else {
      closeToast(GEOLOCATION_LOADING_TOAST_ID);
    }
  }, [addToast, closeToast, loaderVisibilityDebounced, t]);

  // "Verified geolocation" toast
  useEffect(() => {
    if (shouldShowGeolocationVerifiedIndication) {
      closeToast(GEOLOCATION_LOADING_TOAST_ID);
      addToast({
        id: GEOLOCATION_VERIFIED_TOAST_ID,
        render: () => (
          <GeoComplyToast icon="CircleCheck">
            {t('geocomply.locationValidated')}
          </GeoComplyToast>
        ),
        variant: 'success',
        containerStyle: 'chips',
        hideAfterInMs: 5000,
      });
    }
  }, [addToast, closeToast, t, shouldShowGeolocationVerifiedIndication]);

  // Force close the "verified geolocation" toast when a geolocation request is running.
  useEffect(() => {
    if (isRequestGeolocationRequestLoading) {
      closeToast(GEOLOCATION_VERIFIED_TOAST_ID);
    }
  }, [closeToast, isRequestGeolocationRequestLoading]);
};
