import styled, { css } from 'styled-components';

import { colors, media, spaces } from '@gaming1/g1-style';
import { Heading } from '@gaming1/g1-ui';

import { PrismicPageContentWrapper } from './components/styles';

export const PrismicRouterWrapper = styled.div<{ minHeight?: number }>`
  background-color: ${colors('backgrounds', { index: 0 })};

  ${({ minHeight }) =>
    minHeight !== undefined &&
    css`
      min-height: ${minHeight}px;
    `}
`;

export const BlogPageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;

  ${media.lg} {
    width: 75%;
  }

  ${media.xl} {
    width: 50%;
  }
`;

export const PrismicPageWrapper = styled.div`
  background-color: ${colors('backgrounds', { index: 0 })};

  & ${PrismicPageContentWrapper} {
    margin: 0 auto;
    width: 100%;

    ${media.lg} {
      width: 75%;
    }

    ${media.xl} {
      width: 50%;
    }
  }
`;

export const PrismicTitleH1 = styled(Heading).attrs(() => ({ level: 'h1' }))`
  background-color: ${colors('backgrounds', { index: 0 })};
  padding: ${spaces('sm')};
`;
