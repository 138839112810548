import React, { FC } from 'react';

import { useConfig } from '@gaming1/g1-config';
import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';

import { bettingRoutes } from '../../../routes';

import {
  FallbackWrapper,
  PrimaryMessage,
  SecondaryMessage,
  StyledAppButtonLink,
  StyledAppButtonLinkV2,
} from './styles';

type FallbackProps = {
  primaryMessage: string;
  secondaryMessage: string;
};

export const Fallback: FC<FallbackProps> = ({
  primaryMessage,
  secondaryMessage,
}) => {
  const { t } = useTranslation('betting');
  const config = useConfig();
  const uniformizationGiftsDesignActive =
    config.betting.uniformizationGiftsDesign;

  const getBettingRoutePath = useRoutePath(bettingRoutes);

  return (
    <FallbackWrapper>
      <PrimaryMessage data-testid="fallback-primary-message">
        {t(primaryMessage)}
      </PrimaryMessage>
      <SecondaryMessage data-testid="fallback-secondary-message">
        {t(secondaryMessage)}
      </SecondaryMessage>
      {uniformizationGiftsDesignActive ? (
        <StyledAppButtonLinkV2
          colorType="primary"
          data-testid="fallback-button"
          to={getBettingRoutePath('bettingHome')}
        >
          {t('superOdds.contentPage.button.backToHomepage')}
        </StyledAppButtonLinkV2>
      ) : (
        <StyledAppButtonLink
          colorType="primary"
          data-testid="fallback-button"
          to={getBettingRoutePath('bettingHome')}
        >
          {t('superOdds.contentPage.button.backToHomepage')}
        </StyledAppButtonLink>
      )}
    </FallbackWrapper>
  );
};
