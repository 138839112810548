import React, { FC, ReactNode } from 'react';

import { Box } from '@gaming1/g1-ui';

import { Ribbon } from '../../../gifts/components/RibbonV2';
import {
  CardTitle,
  // ValidityDateText,
} from '../../../gifts/components/styles';
import { ValidityDateText } from '../../../gifts/components/ValidityDateText';

import { BasicCardContainer } from './styles';

type BasicCardProps = {
  color: string;
  // The icon that will be used in the ribbon
  ribbonIcon: ReactNode;
  // The text that will be put inside the ribbon
  ribbonText: string;
  title: string;
  // If we want to display an icon next to the title (on its left)
  titleIcon?: ReactNode;
  validityDate?: string;
  onClick?: () => void;
  testId?: string;
};

/**
 * This BasicCard will render a rounded card with a ribbon containing an icon
 * and a text, the validity date of the offer (if given), a main title that will
 * matches with the color given and a content as ReactNode.
 */
export const BettingSlipCard: FC<BasicCardProps> = ({
  color,
  ribbonIcon,
  ribbonText,
  title,
  titleIcon,
  validityDate,
  onClick,
  testId = 'gift-card-container',
}) => (
  <BasicCardContainer data-testid={testId} onClick={onClick}>
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
      min-height="13px"
    >
      <Ribbon color={color} icon={ribbonIcon} text={ribbonText} />
      <ValidityDateText validityDate={validityDate || ''} />
    </Box>
    <Box flexDirection="row" alignItems="center" mt="sm">
      {titleIcon}
      <CardTitle color={color} ellipsis mt="0" ml={titleIcon ? 'xs' : 'none'}>
        {title}
      </CardTitle>
    </Box>
  </BasicCardContainer>
);
