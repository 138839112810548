import * as t from 'io-ts';

import { EChangeStateLicences, EGetAllLicence } from '@gaming1/g1-requests';

export const getLicencesResponse = t.type(
  {
    Status: t.literal(EGetAllLicence.Successful),
    List: t.array(
      t.type({
        Name: t.string,
        Checked: t.boolean,
      }),
    ),
  },
  'GetLicencesResponse',
);

export const changeLicencesResponse = t.type(
  {
    Status: t.literal(EChangeStateLicences.Successful),
  },
  'ChangeLicencesResponse',
);
