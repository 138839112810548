import * as t from 'io-ts';

import {
  EGetConnectionLimitRemainingTimeStatus,
  EGetDepositLimitationResponseStatus,
  EGetLimitsResponseStatus,
  EGetSpentTimeLimitRemainingTimeStatus,
  ESetLimitsResponseStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const setLimitsResponse = t.type(
  {
    Status: t.literal(ESetLimitsResponseStatus.Successful),
    DailyLimitChangeStatus: createEnumType<ESetLimitsResponseStatus>(
      ESetLimitsResponseStatus,
      'ESetLimitsResponseStatus',
    ),
    WeeklyLimitChangeStatus: createEnumType<ESetLimitsResponseStatus>(
      ESetLimitsResponseStatus,
      'ESetLimitsResponseStatus',
    ),
    MonthlyLimitChangeStatus: createEnumType<ESetLimitsResponseStatus>(
      ESetLimitsResponseStatus,
      'ESetLimitsResponseStatus',
    ),
  },
  'SetLimitsResponse',
);

export const setLimitsResult = t.type(
  {
    Status: t.literal(ESetLimitsResponseStatus.Successful),
    DailyLimitChangeMessage: t.string,
    WeeklyLimitChangeMessage: t.string,
    MonthlyLimitChangeMessage: t.string,
  },
  'SetLimitsResult',
);

export const getLimitsResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetLimitsResponseStatus.Successful),
      DailyLimited: t.boolean,
      DailyLimit: t.number,
      DailyNextLimit: t.number,
      WeeklyLimited: t.boolean,
      WeeklyLimit: t.number,
      WeeklyNextLimit: t.number,
      MonthlyLimited: t.boolean,
      MonthlyLimit: t.number,
      MonthlyNextLimit: t.number,

      // HourlyLimited: t.boolean,
      // HourlyLimit: t.number,
      // CurrentHourlyAmount: t.number,
      // HourlyPeriodEnd: t.string,
      // HourlyNextLimit: t.number,
      // CurrentDailyAmount: t.number,
      // DailyPeriodEnd: t.string,
      // CurrentWeeklyAmount: t.number,
      // WeeklyPeriodEnd: t.string,
      // CurrentMonthlyAmount: t.number,
      // MonthlyPeriodEnd: t.string,
      // YearlyLimited: t.boolean,
      // YearlyLimit: t.number,
      // YearlyNextLimit: t.number,
      // CurrentYearlyAmount: t.number,
      // YearlyPeriodEnd: t.string,
    }),
    t.partial({
      DailyNextRaise: t.string,
      WeeklyNextRaise: t.string,
      MonthlyNextRaise: t.string,
      YearlyNextRaise: t.string,

      // HourlyNextRaise: t.string,
    }),
  ],
  'GetLimitsResponse',
);

export const getDepositLimitsResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetDepositLimitationResponseStatus.Successful),
      DailyLimited: t.boolean,
      DailyLimit: t.number,
      DailyNextLimit: t.number,
      WeeklyLimited: t.boolean,
      WeeklyLimit: t.number,
      WeeklyNextLimit: t.number,
      MonthlyLimited: t.boolean,
      MonthlyLimit: t.number,
      MonthlyNextLimit: t.number,

      // CurrentDailyLimit: t.number,
      // CurrentWeeklyLimit: t.number,
      // CurrentMonthlyLimit: t.number,
      // YearlyLimited: t.boolean,
      // YearlyLimit: t.number,
      // YearlyNextLimit: t.number,
      // CurrentYearlyLimit: t.number,
    }),
    t.partial({
      DailyNextRaise: t.string,
      WeeklyNextRaise: t.string,
      MonthlyNextRaise: t.string,

      // YearlyNextRaise: t.string,
    }),
  ],
  'GetDepositLimitsResponse',
);

export const getConnectionLimitRemainingTimeResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetConnectionLimitRemainingTimeStatus.Successful),
      WarnPlayerBeforeLimitReached: t.number,
    }),
    t.partial({
      RemainingTimeInSeconds: t.number,
    }),
  ],
  'GetConnectionLimitRemainingTimeResponse',
);

export const getRemainingTimeToSpendResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetSpentTimeLimitRemainingTimeStatus.Successful),
      WarnPlayerBeforeLimitReached: t.number,
    }),
    t.partial({
      RemainingTimeInSeconds: t.number,
    }),
  ],
  'GetRemainingTimeToSpendResponse',
);
