import * as t from 'io-ts';

import {
  EGetVipInitializationStatus,
  EPlayMonthlyBoxStatus,
  EPlayVipStatus,
  EPrizeType,
  EScratchCardConsumeResponseStatus,
  GetPlayerMonthlyBoxInfoStatus,
  GetPlayerVipRankInfoStatus,
  GetPlayerWheelInfoStatus,
  GetPrizeInfoStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const prizeInfo = t.intersection(
  [
    t.type({
      Amount: t.number,
      Description: t.string,
      Id: t.string,
      IsOnlyFirstDeposit: t.boolean,
      Order: t.number,
      PrizeConfigurationId: t.string,
      PrizeType: createEnumType<EPrizeType>(EPrizeType),
      Used: t.boolean,
      Values: t.array(t.string),
    }),
    t.partial({
      ExtraInfo: t.string,
      Name: t.string,
      PictureUrl: t.string,
      Title: t.string,
    }),
  ],
  'PrizeInfo',
);

export const wheelInfo = t.intersection(
  [
    t.type({
      Played: t.boolean,
      Threshold: t.number,
      Unlocked: t.boolean,
      WheelId: t.string,
    }),
    t.partial({
      PrizeWon: prizeInfo,
      Prizes: t.array(prizeInfo),
      RankName: t.string,
    }),
  ],
  'WheelInfo',
);

export const getVipInitializationResponse = t.intersection(
  [
    t.type({
      Coins: t.number,
      HasGift: t.boolean,
      Points: t.number,
      RefuseBonus: t.boolean,
      Status: t.literal(EGetVipInitializationStatus.Successful),
    }),
    t.partial({
      CurrentRank: t.string,
      Pseudo: t.string,
      RanksInfos: t.array(wheelInfo),
    }),
  ],
  'GetVipInitializationResponse',
);

export const playVipWheelResponse = t.type(
  {
    Status: t.literal(EPlayVipStatus.Successful),
    Prize: t.string,
  },
  'PlayVipWheelResponse',
);

export const playMonthlyBoxResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EPlayMonthlyBoxStatus.Successful),
    }),
    t.partial({
      PrizeWon: prizeInfo,
      Prizes: t.array(t.string),
    }),
  ],
  'PlayMonthlyBoxResponse',
);

export const getPlayerWheelInfoResponse = t.type(
  {
    Status: t.literal(GetPlayerWheelInfoStatus.Successful),
    WheelAvailable: t.boolean,
    IsWheelEnabled: t.boolean,
    CanPlayWheel: t.boolean,
  },
  'GetPlayerWheelInfoResponse',
);

export const getPlayerMonthlyBoxInfoResponse = t.type(
  {
    Status: t.literal(GetPlayerMonthlyBoxInfoStatus.Successful),
    MonthlyBoxAvailable: t.boolean,
    IsMonthlyBoxEnabled: t.boolean,
    CanPlayMonthlyBox: t.boolean,
  },
  'GetPlayerMonthlyBoxInfoResponse',
);

export const rankInfo = t.intersection([
  t.type({ Status: t.number, Threshold: t.number }),
  t.partial({ Name: t.string }),
]);

export const getPlayerVipRankInfoResponse = t.intersection(
  [
    t.type({
      Points: t.number,
      Coins: t.number,
      Status: t.literal(GetPlayerVipRankInfoStatus.Successful),
    }),
    t.partial({
      CurrentStatus: rankInfo,
      NextStatus: rankInfo,
    }),
  ],
  'GetPlayerVipRankInfoResponse',
);

export const scrollPayload = t.type(
  {
    deltaY: t.number,
  },
  'ScrollPayload',
);

export const gameSimpleEventCodec = t.type(
  {
    type: t.string,
  },
  'GameSimpleEventCodec',
);

export const gameScrollIntentCodec = t.type(
  {
    type: t.string,
    payload: scrollPayload,
  },
  'GameScrollIntentCodec',
);

export const detailSymbolResponse = t.type(
  {
    Identifier: t.number,
    Order: t.number,
  },
  'DetailSymbolResponse',
);

export const getScratchcardDetailsResponse = t.intersection(
  [
    t.type({
      Id: t.string,
      IsScratched: t.boolean,
      Amount: t.number,
      MaxAmountToStake: t.number,
    }),
    t.partial({
      Matrix: t.array(detailSymbolResponse),
      PrizeId: t.string,
    }),
  ],
  'GetScratchcardDetailsResponse',
);

export const scratchCardConsumeResponse = t.type(
  {
    Status: t.literal(EScratchCardConsumeResponseStatus.Ok),
  },
  'ScratchCardConsumeResponse',
);

export const getScratchCardPrizeInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(GetPrizeInfoStatus.Successful),
    }),
    t.partial({
      PrizeAmount: t.number,
    }),
  ],
  'GetScratchCardPrizeInfoResponse',
);
