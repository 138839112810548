import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getBettingHomeModuleOrder } from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';

import * as actions from './actions';
import { getBettingHomeModuleOrderResponse } from './codecs';

/** Get home module order */
export const getHomeModuleOrderEpic: BettingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getHomeModuleOrder.request)),
    switchMap(() => {
      const request = {};
      return wsAdapter.request(getBettingHomeModuleOrder(request)).pipe(
        mapGuard(getBettingHomeModuleOrderResponse),
        map(actions.getHomeModuleOrder.success),
        catchError((err) =>
          of(
            actions.getHomeModuleOrder.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      );
    }),
  );
