import React, { FC, memo, useContext, useState } from 'react';
import { shallowEqual } from 'react-redux';

import {
  bettingSlipRanksGetterSelector,
  useBettingGetterSelector,
  useBettingSlipIsInvalidRanks,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipSystemError } from '../BettingSlipError/BettingSlipSystemError';
import { BettingSlipNumpadProvider } from '../BettingSlipNumpad/BettingSlipNumpadProvider';
import { BettingSlipSystemPotWinning } from '../BettingSlipPotWinning/BettingSlipSystemPotWinning';
import { BettingSlipSystemStake } from '../BettingSlipStake/BettingSlipSystemStake';
import { BettingSlipValidation } from '../BettingSlipValidation';
import { BettingSlipRank } from '../Templates/BettingSlipMain/BettingSlipRank';

import { BettingSlipFooterLineContainer } from './styles';

/**
 * Display the ticket item footer
 *
 */
const BettingSlipFooterSystemComposent: FC = () => {
  const [hasSystemTooManyElements, setHasSystemTooManyElements] = useState(
    () => false,
  );

  const { t } = useTranslation('betting');

  const { bettingSlipId } = useContext(BettingSlipContext);

  const ranksId = useBettingGetterSelector({
    getterSelector: bettingSlipRanksGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  })?.map((rank) => rank.id);

  const isInvalidRanks = useBettingSlipIsInvalidRanks(bettingSlipId);

  if (!ranksId) {
    return null;
  }

  return (
    <BettingSlipNumpadProvider>
      {!hasSystemTooManyElements &&
        ranksId.map((id) => <BettingSlipRank id={id} key={id} />)}
      <BettingSlipFooterLineContainer data-testid="bettingslip-system-stake-container">
        {t('bettingslip.system.stake')}
        <BettingSlipSystemStake />
      </BettingSlipFooterLineContainer>
      <BettingSlipFooterLineContainer data-testid="bettingslip-system-potwinning-container">
        {t('bettingslip.system.potWinning')}
        <BettingSlipSystemPotWinning />
      </BettingSlipFooterLineContainer>
      <BettingSlipFooterLineContainer data-testid="bettingslip-system-error-front-container">
        {!isInvalidRanks && (
          <BettingSlipSystemError
            setHasSystemTooManyElements={setHasSystemTooManyElements}
          />
        )}
      </BettingSlipFooterLineContainer>
      <Box mt="sm" width="100%">
        <BettingSlipValidation />
      </Box>
    </BettingSlipNumpadProvider>
  );
};
export const BettingSlipFooterSystem = memo(BettingSlipFooterSystemComposent);
