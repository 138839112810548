/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:59:25 31-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  ActivateAccountRequestDTO,
  ActivateAccountResponseDTO,
  ChangePasswordRequestDTO,
  ChangePasswordResponseDTO,
  ChangePlayerProfileRequestDTO,
  ChangePlayerProfileResponseDTO,
  ChangePlayerSettingsRequestDTO,
  ChangePlayerSettingsResponseDTO,
  ChangeStateLicencesRequestDTO,
  ChangeStateLicencesResponseDTO,
  CheckPlayerRealMoneyAllowedRequestDTO,
  CheckPlayerRealMoneyAllowedResponseDTO,
  GetAllCountriesRequestDTO,
  GetAllCountriesResponseDTO,
  GetAllLicenceRequestDTO,
  GetAllLicenceResponseDTO,
  GetAllProvincesRequestDTO,
  GetAllProvincesResponseDTO,
  GetMunicipalitiesRequestDTO,
  GetMunicipalitiesResponseDTO,
  GetPlayerConnectionHistoryRequestDTO,
  GetPlayerConnectionHistoryResponseDTO,
  GetPlayerProfileRequestDTO,
  GetPlayerProfileResponseDTO,
  GetPlayerSettingsRequestDTO,
  GetPlayerSettingsResponseDTO,
  GetPlayerShortInfoRequestDTO,
  GetPlayerShortInfoResponseDTO,
  GetRegistrationFormInfoRequestDTO,
  GetRegistrationFormInfoResponseDTO,
  GetRegistrationInfoRequestDTO,
  GetRegistrationInfoResponseDTO,
  GetRequiredDocTypesRequestDTO,
  GetRequiredDocTypesResponseDTO,
  GetStatesRequestDTO,
  GetStatesResponseDTO,
  GetUploadDocumentStatusRequestDTO,
  GetUploadDocumentStatusResponseDTO,
  PasswordRecoveryRequestDTO,
  PasswordRecoveryResponseDTO,
  PlayerRegistrationRequestDTO,
  PlayerRegistrationResponseDTO,
  RequestFace2FaceValidationRequestDTO,
  RequestFace2FaceValidationResponseDTO,
  ResetPasswordRequestDTO,
  ResetPasswordResponseDTO,
  SendRegistrationMailRequestDTO,
  SendRegistrationMailResponseDTO,
  ValidateEmailRequestDTO,
  ValidateEmailResponseDTO,
  ValidatePasswordRecoveryRequestDTO,
  ValidatePasswordRecoveryResponseDTO,
  ValidatePseudoRequestDTO,
  ValidatePseudoResponseDTO,
} from '../entities';

export const requestFace2FaceValidation = (
  options: RequestFace2FaceValidationRequestDTO,
) =>
  createRequest<RequestFace2FaceValidationResponseDTO>(
    100,
    'RequestFace2FaceValidation',
    options,
  );

export const getPlayerShortInfo = (options: GetPlayerShortInfoRequestDTO) =>
  createRequest<GetPlayerShortInfoResponseDTO>(
    100,
    'GetPlayerShortInfo',
    options,
  );

export const getRegistrationInfo = (options: GetRegistrationInfoRequestDTO) =>
  createRequest<GetRegistrationInfoResponseDTO>(
    100,
    'GetRegistrationInfo',
    options,
  );

export const validateRegistrationFields = (
  options: PlayerRegistrationRequestDTO,
) =>
  createRequest<PlayerRegistrationResponseDTO>(
    100,
    'ValidateRegistrationFields',
    options,
  );

export const registerPlayer = (options: PlayerRegistrationRequestDTO) =>
  createRequest<PlayerRegistrationResponseDTO>(100, 'RegisterPlayer', options);

export const validatePseudo = (options: ValidatePseudoRequestDTO) =>
  createRequest<ValidatePseudoResponseDTO>(100, 'ValidatePseudo', options);

export const validateEmail = (options: ValidateEmailRequestDTO) =>
  createRequest<ValidateEmailResponseDTO>(100, 'ValidateEmail', options);

export const sendRegistrationMail = (options: SendRegistrationMailRequestDTO) =>
  createRequest<SendRegistrationMailResponseDTO>(
    100,
    'SendRegistrationMail',
    options,
  );

export const activateAccount = (options: ActivateAccountRequestDTO) =>
  createRequest<ActivateAccountResponseDTO>(100, 'ActivateAccount', options);

export const passwordRecovery = (options: PasswordRecoveryRequestDTO) =>
  createRequest<PasswordRecoveryResponseDTO>(100, 'PasswordRecovery', options);

export const validatePasswordRecovery = (
  options: ValidatePasswordRecoveryRequestDTO,
) =>
  createRequest<ValidatePasswordRecoveryResponseDTO>(
    100,
    'ValidatePasswordRecovery',
    options,
  );

export const resetPassword = (options: ResetPasswordRequestDTO) =>
  createRequest<ResetPasswordResponseDTO>(100, 'ResetPassword', options);

export const changePassword = (options: ChangePasswordRequestDTO) =>
  createRequest<ChangePasswordResponseDTO>(100, 'ChangePassword', options);

export const getPlayerProfile = (options: GetPlayerProfileRequestDTO) =>
  createRequest<GetPlayerProfileResponseDTO>(100, 'GetPlayerProfile', options);

export const changePlayerProfile = (options: ChangePlayerProfileRequestDTO) =>
  createRequest<ChangePlayerProfileResponseDTO>(
    100,
    'ChangePlayerProfile',
    options,
  );

export const getPlayerSettings = (options: GetPlayerSettingsRequestDTO) =>
  createRequest<GetPlayerSettingsResponseDTO>(
    100,
    'GetPlayerSettings',
    options,
  );

export const changePlayerSettings = (options: ChangePlayerSettingsRequestDTO) =>
  createRequest<ChangePlayerSettingsResponseDTO>(
    100,
    'ChangePlayerSettings',
    options,
  );

export const getAllCountriesForResidenceCountry = (
  options: GetAllCountriesRequestDTO,
) =>
  createRequest<GetAllCountriesResponseDTO>(
    100,
    'GetAllCountriesForResidenceCountry',
    options,
  );

export const getAllLicencesAndStatesByPlayer = (
  options: GetAllLicenceRequestDTO,
) =>
  createRequest<GetAllLicenceResponseDTO>(
    100,
    'GetAllLicencesAndStatesByPlayer',
    options,
  );

export const changeStateLicences = (options: ChangeStateLicencesRequestDTO) =>
  createRequest<ChangeStateLicencesResponseDTO>(
    100,
    'ChangeStateLicences',
    options,
  );

export const getAllCountries = (options: GetAllCountriesRequestDTO) =>
  createRequest<GetAllCountriesResponseDTO>(100, 'GetAllCountries', options);

export const getAllProvinces = (options: GetAllProvincesRequestDTO) =>
  createRequest<GetAllProvincesResponseDTO>(100, 'GetAllProvinces', options);

export const getMunicipalities = (options: GetMunicipalitiesRequestDTO) =>
  createRequest<GetMunicipalitiesResponseDTO>(
    100,
    'GetMunicipalities',
    options,
  );

export const getStates = (options: GetStatesRequestDTO) =>
  createRequest<GetStatesResponseDTO>(100, 'GetStates', options);

export const getUploadDocumentStatus = (
  options: GetUploadDocumentStatusRequestDTO,
) =>
  createRequest<GetUploadDocumentStatusResponseDTO>(
    100,
    'GetUploadDocumentStatus',
    options,
  );

export const checkPlayerRealMoneyAllowed = (
  options: CheckPlayerRealMoneyAllowedRequestDTO,
) =>
  createRequest<CheckPlayerRealMoneyAllowedResponseDTO>(
    100,
    'CheckPlayerRealMoneyAllowed',
    options,
  );

export const getPlayerConnectionHistory = (
  options: GetPlayerConnectionHistoryRequestDTO,
) =>
  createRequest<GetPlayerConnectionHistoryResponseDTO>(
    100,
    'GetPlayerConnectionHistory',
    options,
  );

export const getRegistrationFormInfo = (
  options: GetRegistrationFormInfoRequestDTO,
) =>
  createRequest<GetRegistrationFormInfoResponseDTO>(
    100,
    'GetRegistrationFormInfo',
    options,
  );

export const getRequiredDocTypes = (options: GetRequiredDocTypesRequestDTO) =>
  createRequest<GetRequiredDocTypesResponseDTO>(
    100,
    'GetRequiredDocTypes',
    options,
  );
