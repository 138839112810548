import { animated, useTransition } from '@react-spring/web';
import { transparentize } from 'polished';
import React, { useContext, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { BACKDROP_ANIMATION_DURATION_IN_MS } from '../constants';
import { LayoutContext } from '../LayoutProvider/LayoutContext';

/**
 * Black overlay that hides the content of the website when showing a modal or the mobile menu
 */
export const Backdrop: VFC = () => {
  const { backdropClickHandler, visibleModal, visibleDrawer } =
    useContext(LayoutContext);
  const backDropVisibility = visibleModal !== null || visibleDrawer !== null;

  const theme = useContext(ThemeContext);

  const transitions = useTransition(backDropVisibility, {
    config: { clamp: true, duration: BACKDROP_ANIMATION_DURATION_IN_MS },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const backdropButtonStyles = {
    border: 'none',
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: transparentize(theme.opacity.translucent)('#000'),
    zIndex: theme.zIndices.backdrop,
    transform: 'translateZ(0)',
  };
  return transitions(
    (style, show) =>
      show && (
        <animated.button
          aria-label="Close modal or drawer"
          aria-hidden={backDropVisibility ? undefined : 'true'}
          type="button"
          data-testid="backdrop"
          onClick={backdropClickHandler}
          style={{ ...backdropButtonStyles, ...style }}
        />
      ),
  );
};
