import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, elevation, fontSizes, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT = 5;

export const ListItem = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding: ${spaces('xs')} ${spaces('sm')};
  border: 1px solid ${colors('separator')};
  ${elevation(1)};
  & + & {
    margin-top: ${spaces('xs')};
  }
`;

export const MessageTitle = styled(Box)<{ hasBeenRead: boolean }>`
  ${({ hasBeenRead }) =>
    hasBeenRead
      ? css`
          color: ${colors('muted')};
          font-size: ${fontSizes('lg')};
        `
      : css`
          font-weight: bold;
          font-size: ${fontSizes('lg')};
        `}
`;

export const MessageTime = styled(Box)<{ hasBeenRead: boolean }>`
  ${({ hasBeenRead }) =>
    hasBeenRead
      ? css`
          color: ${colors('muted')};
          font-size: ${fontSizes('md')};
        `
      : css`
          color: ${colors('danger')};
          font-size: ${fontSizes('md')};
        `}
`;

export const RedCircle = styled.div`
  border-radius: 5px;
  background: ${colors('danger')};
  width: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
  height: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
  margin-right: ${spaces('xs')};
`;

export const CircleContainer = styled.div`
  width: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT * 2}px;
  height: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
`;
