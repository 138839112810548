import get from 'lodash/fp/get';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { coreActions } from '@gaming1/g1-core';
import { useIsUserLoggedIn } from '@gaming1/g1-core-web';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import { AppAnchor, Button, Heading, Modal } from '@gaming1/g1-ui';
import {
  acceptLegalModalRequestStateSelector,
  actions,
  shouldShowLegalModalSelector,
} from '@gaming1/g1-user';
import { usePrevious, useRequestCallback } from '@gaming1/g1-utils';

import { logger } from '../../../logger';

import { LegalModalContent } from './styles';

/**
 * The LegalModal is for the belgian regulation where user should be blocked
 * every 30 minutes (or so) and be shown a message.
 *
 * This modal can't be closed by any other mean than to accept or refuse.
 *
 * Refusing should log the user out
 */
export const LegalModal: FC = () => {
  const { t } = useTranslation(['user', 'core']);

  const { user: userConfig } = useContext(ConfigContext);

  const dispatch = useDispatch();

  // This generates a random number between 1 and `userConfig.legalModalMaxMessages`
  // used to display the title and content randomly
  const randomOrder = useMemo(
    () =>
      new Array(userConfig.legalModalMaxMessages)
        .fill(0)
        .map((_, i) => ({ sort: Math.random(), val: i + 1 }))
        .sort((a, b) => a.sort - b.sort)
        .map(get('val')),
    [userConfig.legalModalMaxMessages],
  );
  const [contentIndex, setContentIndex] = useState(0);

  const isUserLoggedIn = useIsUserLoggedIn();
  const previousLoggedInState = usePrevious(isUserLoggedIn);

  const shouldShowLegalModal = useSelector(shouldShowLegalModalSelector);
  const { isLoading, status: acceptLegalModalStatus } = useRequestState(
    acceptLegalModalRequestStateSelector,
  );

  const handleAcceptLegal = () => {
    dispatch(actions.acceptLegalModal.request());
  };

  const handleRefuseLegal = () => {
    logger.info('[Legal Modal] Logout due to modal refusal');
    dispatch(coreActions.logout.request());
  };

  /** Ask if LegalModal has to be accepted */
  useEffect(() => {
    if (!previousLoggedInState && isUserLoggedIn) {
      dispatch(actions.showLegalModal.request());
    }
  }, [dispatch, isUserLoggedIn, previousLoggedInState]);

  /** Change modal message after acceptance */
  useRequestCallback(acceptLegalModalStatus, () => {
    setContentIndex((prev) => (prev === 4 ? 0 : prev + 1));
  });

  return (
    <Modal
      modalId="legalModal"
      onClose={() => null}
      isClosableByUser={false}
      showCloseButton={false}
      visible={shouldShowLegalModal}
    >
      <Heading level="h2" mt="0" data-testid="legalHeading">
        {t(`legalPopup.modal.title${randomOrder[contentIndex]}`)}
      </Heading>

      <LegalModalContent data-testid="legalContent">
        <Trans
          i18nKey={`legalPopup.modal.content${randomOrder[contentIndex]}`}
          ns="user"
          components={{
            //  The href is set directly inside the translation
            AppAnchor: <AppAnchor target="_blank" rel="noopener" />,
            Paragraph: <p />,
          }}
        />
      </LegalModalContent>

      <Button
        block
        loading={isLoading}
        mt="lg"
        onClick={handleAcceptLegal}
        testId="legalAcceptBtn"
        type="primary"
      >
        {t('core:button.confirm')}
      </Button>
      <Button
        block
        noBorder
        onClick={handleRefuseLegal}
        testId="legalRefuseBtn"
        type="secondary"
      >
        {t('core:button.cancel')}
      </Button>
    </Modal>
  );
};
