import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useNotifications } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import {
  actions,
  getSecurityPinCodeInfoRequestStateSelector,
  getSecurityPinCodeInfoSelector,
  resetSecurityPinCodeRequestStateSelector,
} from '@gaming1/g1-user';
import { useRequestCallback } from '@gaming1/g1-utils';

import { PinCodeFormModal } from '../PinCodeFormModal';

/**
 * Multifactor authentication reset pin code watcher. This component will wait
 * for a successful response of GetSecurityPinCodeInfo. If the response specifies
 * that the user should reset the PIN code it will display a modal.
 */
export const PinCodeResetWatcher: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('user');
  const { addNotification } = useNotifications();

  const pinCodeInfo = useSelector(getSecurityPinCodeInfoSelector);
  const { status: pinCodeInfoStatus } = useRequestState(
    getSecurityPinCodeInfoRequestStateSelector,
  );
  const {
    errorMessage: resetPinCodeError,
    isLoading,
    status: resetPinCodeStatus,
  } = useRequestState(resetSecurityPinCodeRequestStateSelector);

  const [error, setError] = useState<string | undefined>(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  useRequestCallback(pinCodeInfoStatus, () => {
    if (pinCodeInfo?.ShouldResetSecurityPinCode) {
      setModalVisible(true);
    }
  });

  useRequestCallback(
    resetPinCodeStatus,
    () => {
      setModalVisible(false);
      addNotification({
        message: t('multifactorAuthentication.pinCode.reset'),
        type: 'success',
        urlToNotify: history.location.pathname,
      });
    },
    () => {
      setError(resetPinCodeError);
    },
  );

  return (
    <PinCodeFormModal
      errorMessage={error ? t(error) : undefined}
      isClosableByUser={false}
      loading={isLoading}
      modalId="reset-pin-code-form-modal"
      onClose={() => setModalVisible(false)}
      onFocus={() => setError(undefined)}
      visible={modalVisible}
      onSubmit={(pinCode, newPinCode) =>
        dispatch(
          actions.resetSecurityPinCode.request({
            CurrentSecurityPinCode: pinCode,
            NewSecurityPinCode: newPinCode,
          }),
        )
      }
      resetMode
    />
  );
};
