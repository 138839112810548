import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { GetHorizontalBannerResponse } from './types';

/** Get  */
export const getHorizontalBanner = createAsyncAction(
  'betting/get_horizontal_banner_request',
  'betting/get_horizontal_banner_success',
  'betting/get_horizontal_banner_failure',
)<
  { ClientVersion: string; MaxBannerCount?: number },
  GetHorizontalBannerResponse,
  FailurePayload
>();
