import { createAsyncAction } from 'typesafe-actions';

import { ChangePasswordRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import { ChangePasswordResponse } from './types';

/* Async actions */

/** Change password */
export const changePassword = createAsyncAction(
  'user/change_password_request',
  'user/change_password_success',
  'user/change_password_failure',
)<ChangePasswordRequestDTO, ChangePasswordResponse, FailurePayload>();
