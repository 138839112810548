import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  throttleTime,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { THROTTLE_TIME_IN_MS } from '@gaming1/g1-core';
import { getGamesCategories } from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { GamingEpic } from '../../../store/types';
import * as actions from '../actions';
import { gamesCategoriesData, getGamesCategoriesReponse } from '../codecs';
import { formatgamesCategoriesData } from '../formats';

export const fetchGameCategoriesEpic: GamingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchGameCategories.request)),
    throttleTime(THROTTLE_TIME_IN_MS),
    mergeMap((action) => {
      const params = {
        GameTechnology: 0,
        GroupName: '',
        ...action.payload,
      };
      return wsAdapter.request(getGamesCategories(params)).pipe(
        mapGuard(getGamesCategoriesReponse),
        map(formatgamesCategoriesData),
        mapGuard(gamesCategoriesData),
        map(actions.fetchGameCategories.success),
        catchError((err) =>
          of(actions.fetchGameCategories.failure(createFailurePayload(err))),
        ),
      );
    }),
  );
