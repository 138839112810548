import {
  FetchPromotionsRequestPayload,
  FetchPromotionsSuccessPayload,
  PromotionsGroupState,
  PromotionsResponse,
} from './types';

/**
 * Generates an empty state for a new promotions group
 */
export const generatePromotionsGroupState = (): PromotionsGroupState => ({
  pagination: {
    totalItems: 0,
    totalPages: 0,
    pageNum: 0,
  },
  promotionsUids: [],
});

/**
 * Parse a raw response from Prismic into a FetchPromotionsSuccessPayload object
 */
export const formatFetchPromotionsResponse = (
  response: PromotionsResponse,
  request: FetchPromotionsRequestPayload,
): FetchPromotionsSuccessPayload => ({
  tag: request.tag.name,
  pagination: {
    totalPages: response.total_pages,
    totalItems: response.total_results_size,
    pageNum: request.pageNum,
  },
  promotionDocuments: response.results,
});
