import { Context } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';
import { ContextType, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { DeployEnv, getDeployEnv } from '@gaming1/g1-logger';

import { logger } from '../../../logger';
import { usePrivacySettings } from '../../hooks/usePrivacySettings';
import { TrackingContext } from '../../TrackingContext';

type DataDogEnv = 'dev' | 'tst' | 'acc' | 'prd';

type DataDogContextValue = Omit<
  ContextType<typeof TrackingContext>,
  'isGtmEnabled' | 'pushEvent' | 'pushRawData'
>;

const DATADOG_ENVS: { [k in DeployEnv]: DataDogEnv } = {
  local: 'dev',
  development: 'dev',
  testing: 'tst',
  acceptance: 'acc',
  production: 'prd',
};

/**
 * Implements DataDog functionalities :
 *   - sending context informations to DataDog
 *   - sending custom actions to DataDog
 *   - sending errors to DataDog
 */
export const useDataDog = (): DataDogContextValue => {
  const {
    core: { privacySettings },
    seo: { dataDog },
  } = useConfig();

  const { getUserConsentStatusForPurpose } = usePrivacySettings();

  const isDataDogEnabled =
    !!getUserConsentStatusForPurpose(
      privacySettings.purposeIDs.performanceMeasure,
    ) &&
    !!dataDog.applicationId &&
    !!dataDog.clientToken &&
    !!dataDog.site;

  const pushDataDogAction = useCallback(
    (name: string, context?: Context | undefined) => {
      if (isDataDogEnabled) {
        logger.debug('[Tracking] Sending DataDog action :', name, context);
        datadogRum.addAction(name, context);
      }
    },
    [isDataDogEnabled],
  );

  const pushDataDogContext = useCallback(
    (key: string, value?: Context | string | undefined) => {
      if (isDataDogEnabled) {
        logger.debug('[Tracking] Sending DataDog context :', key, value);
        datadogRum.addRumGlobalContext(key, value);
      }
    },
    [isDataDogEnabled],
  );

  const pushDataDogError = useCallback(
    (error: unknown, context?: Context | undefined) => {
      if (isDataDogEnabled) {
        logger.debug('[Tracking] Sending DataDog error :', error, context);
        datadogRum.addError(error, context);
      }
    },
    [isDataDogEnabled],
  );

  /* Init datadog */
  useEffect(() => {
    if (isDataDogEnabled) {
      const datadogEnv = DATADOG_ENVS[getDeployEnv()];

      const initData: Parameters<typeof datadogRum.init>[0] = {
        applicationId: dataDog.applicationId || '',
        clientToken: dataDog.clientToken || '',
        env: datadogEnv,
        sampleRate: dataDog.sampleRate || 100,
        service: 'web-app',
        site: dataDog.site || '',
        trackInteractions: true,
        version: process.env.REACT_APP_VERSION || '',
      };
      logger.debug(
        '[Tracking] Initializing DataDog with parameters :',
        initData,
      );
      datadogRum.init(initData);
      pushDataDogContext('partner', dataDog.partnerTag || '');
    }
  }, [
    dataDog.applicationId,
    dataDog.clientToken,
    dataDog.partnerTag,
    dataDog.sampleRate,
    dataDog.site,
    isDataDogEnabled,
    pushDataDogContext,
  ]);

  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const user = useSelector(userSelector);

  /* Setup datadog user context */
  useEffect(() => {
    if (isDataDogEnabled && isUserLoggedIn && user) {
      pushDataDogContext('usr', {
        id: user.Pseudo || null,
        logged: !!isUserLoggedIn,
      });
    }
  }, [isDataDogEnabled, isUserLoggedIn, pushDataDogContext, user]);

  const ddData: DataDogContextValue = useMemo(
    () => ({
      isDataDogEnabled,
      pushDataDogAction,
      pushDataDogContext,
      pushDataDogError,
    }),
    [isDataDogEnabled, pushDataDogAction, pushDataDogContext, pushDataDogError],
  );

  return ddData;
};
