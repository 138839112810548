import React, { VFC } from 'react';

import {
  BettingHistoryTicketType,
  OptimalBetHistoryTicketInformationType,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../../common/components/BettingIcon';
import { OpacityContainer } from '../../styles';
import { UpdatedOdds } from '../HistoryCardParts/UpdatedOdds';

import { HistoryExpandedGiftContainer } from './styles';

type ExpandedExtraInfoOptimalBetProps = {
  optimalBet: OptimalBetHistoryTicketInformationType;
  history: BettingHistoryTicketType;
};

export const ExpandedExtraInfoOptimalBet: VFC<
  ExpandedExtraInfoOptimalBetProps
> = ({ optimalBet, history }) => {
  const { t } = useTranslation('betting');

  return optimalBet ? (
    <HistoryExpandedGiftContainer>
      {optimalBet.OptimalBetApplied ? (
        <BettingIcon id="optiodds" type="OptiOddsTag" width={20} height={20} />
      ) : (
        <OpacityContainer data-testid="invalid-icon-optiodds-container">
          <BettingIcon id="optiodds" type="OptiOddsTag" />
        </OpacityContainer>
      )}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text ml="xxs" mt={0} fontSize="md" mr="md">
          {t('optiodds.history.nrOfEvents', {
            count: optimalBet.NumberOfOutcomes,
          })}
        </Text>
        {optimalBet.OptimalBetApplied ? (
          <UpdatedOdds
            baseOdds={history.BaseOdds}
            finalOdds={history.FinalOdds}
            updateType="odds"
            id="optiodds"
          />
        ) : (
          <Text mt={0} fontSize="md">
            {t('optiodds.history.notValid')}
          </Text>
        )}
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
