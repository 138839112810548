import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getDepositHistory, getWithdrawtHistory } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as paymentActions from '../actions';
import { PaymentEpic } from '../types';

import {
  getDepositHistoryResponse,
  getWithdrawalHistoryResponse,
} from './codecs';
import {
  getDepositHistoryErrorMessages,
  getWithdrawalHistoryErrorMessages,
} from './errorMessages';
import { formatDepositHistory, formatWithdrawalHistory } from './format';

export const getDepositHistoryEpic: PaymentEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(paymentActions.getDepositHistory.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getDepositHistory(payload)).pipe(
        mapGuard(getDepositHistoryResponse),
        map(formatDepositHistory),
        map(paymentActions.getDepositHistory.success),
        catchError((err) =>
          of(
            paymentActions.getDepositHistory.failure(
              createFailurePayload(err, getDepositHistoryErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const getWithdrawalHistoryEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(paymentActions.getWithdrawalHistory.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getWithdrawtHistory(payload)).pipe(
        mapGuard(getWithdrawalHistoryResponse),
        map(formatWithdrawalHistory),
        map(paymentActions.getWithdrawalHistory.success),
        catchError((err) =>
          of(
            paymentActions.getWithdrawalHistory.failure(
              createFailurePayload(err, getWithdrawalHistoryErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
