import { combineReducers } from 'redux';
import { Reducer } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';

import { GamingActions } from '../../../store/types';
import { CommonState } from '../types';

import { commonEntitiesReducer } from './entities';

export const commonReducer: Reducer<CommonState, GamingActions | CoreActions> =
  combineReducers({
    entities: commonEntitiesReducer,
  });
