import { Context, ContextType, useContext } from 'react';

import { useConfig } from '@gaming1/g1-config';

import { logger } from '../logger';

import { CoreAssetsContext } from './CoreAssetsContext';

/** Keep a list of warning thrown to avoid spamming logs if a missing asset is
 * used multiple times in the lifecycle of the app */
const warningLoggedList: string[] = [];

export const useGetAssetPath = <
  ContextValues extends Record<string, string | null>,
>(
  context: Context<ContextValues>,
) => {
  const contextValues = useContext(context);
  return (key: keyof ContextType<typeof context>) => {
    const path = contextValues[key];
    if (path === '' && !warningLoggedList.includes(String(key))) {
      warningLoggedList.push(String(key));
      logger.error(
        `[Assets] The path for the asset "${key}" was retrieved but it is empty. It looks like the app is missing the proper asset context`,
      );
    }
    return path;
  };
};

export const useGetCoreAssetPath = () => useGetAssetPath(CoreAssetsContext);

/**
 * Returns a path for medias coming from the back
 */
export const useMediaUrl = () => {
  const config = useConfig();

  return (resource?: string) => `${config.network.mediaUrl}/${resource}`;
};
