import styled from 'styled-components';

import { fontSizes, spaces } from '@gaming1/g1-style';
import { Col } from '@gaming1/g1-ui';

export const BettingSlipTitleContainer = styled.div`
  padding: ${spaces('sm')};
  font-size: ${fontSizes('lg')};
`;

export const PotWinningCol = styled(Col)`
  align-self: flex-end;
`;
