import { batchActions } from 'redux-batched-actions';
import { concat, EMPTY, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  actions as coreActions,
  currentWebSocketLocaleSelector,
} from '@gaming1/g1-core';
import {
  EventType as EEventType,
  fullSearch,
  getEvent,
  getEventsForLeague,
  getEventsForRegion,
  getFilters,
  getLiveCount,
  getLiveCountBySport,
  getLives,
  getLiveSport,
  getPrematchSport,
  getSports,
  getTopEvents,
  lightSearch,
} from '@gaming1/g1-requests-betting';
import {
  codecGuard,
  createFailurePayload,
  mapGuard,
  RemoteData,
} from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { generateBettingMulticastGroupName } from '../../common/store/helpers';
import { logger } from '../../logger';
import { BettingEpic } from '../../store/types';

// import { normalizedFullSearchMockResponse } from './__mocks__/getFullSearch';
// import { getLightSearchMockResponse } from './__mocks__/getLightSearch';
import * as actions from './actions';
import {
  fullSearchResponseCodec,
  getSportsResponseCodec,
  lightSearchResponseCodec,
} from './codecs/entities';
import {
  baseSportbookResponseCodec,
  eventResponseCodec,
  getFilterResponseResponseCodec,
  getLeagueResponseCodec,
  getLiveCountBySportResponseCodec,
  getLiveCountResponseCodec,
} from './codecs/responses';
import {
  getLiveCountUpdateCodec,
  sportbookUpdatesResponseCodec,
} from './codecs/updates';
import { MIN_SEARCH_TERM_LENGTH } from './constants';
import {
  formatBaseSportBook,
  formatFullSearch,
  formatGetEvent,
  formatGetLeagueResponse,
  formatSport,
} from './format';
import { sportbookUpdatesToActions } from './helpers';
import { checkIncoherence, getRefreshPayload } from './helpers/pushHelpers';
import { getSportsStatusSelector } from './selectors';
import { SportbookUpdateType } from './types';

const requestOptions = { retryOnTimeout: true, timeoutAfterMs: 10000 };

export const getLiveEventsCountEpic: BettingEpic = (
  actions$,
  _,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getLiveEventsCount.request)),
    switchMap(({ payload }) => {
      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('countAllLive', null),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(getLiveCountUpdateCodec, logger)),
              // filter out empty updates array
              filter((update) => !!update.EntityUpdates.length),
              // Return only the last count
              map(
                (update) =>
                  update.EntityUpdates[update.EntityUpdates.length - 1]
                    .LiveCountUpdate,
              ),
              // Send update action
              map((count) => actions.updateLiveEventsCount(count)),
            );
      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getLiveCount({}), requestOptions)
        .pipe(
          mapGuard(getLiveCountResponseCodec),
          map((response) =>
            actions.getLiveEventsCount.success(response.LiveCount),
          ),
          catchError((err) =>
            of(
              actions.getLiveEventsCount.failure(
                createFailurePayload(err, commonBettingErrorMessages),
              ),
            ),
          ),
        );
      /* 
        The concat ensure that the success action will be dispatched before the
        updates, even if they arrive before the fetch data
      */
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelLiveEventsCountSubscriptionEpic: BettingEpic = (
  actions$,
  _,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getLiveEventsCount.cancel)),
    // Unsubscribe to group
    tap(() =>
      wsAdapter.unsubscribeToGroup(
        generateBettingMulticastGroupName('countAllLive', null),
      ),
    ),
    // Don't send any action
    ignoreElements(),
  );

export const subscribeToOutcomeInfoEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.subscribeToOutcome)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) =>
      wsAdapter
        .subscribeToGroup(
          generateBettingMulticastGroupName('outcome', {
            outcomeId: payload.outcomeId,
            locale: currentWebSocketLocaleSelector(state),
          }),
        )
        .pipe(
          // Filter out malformed data
          filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
          // get the content of the EntityUpdates array
          pluck('Updates'),
          // Convert the array to an array of updates
          map((updates) => sportbookUpdatesToActions(updates, true)),
          // Convert the array to an observable
          map((acts) => batchActions(acts)),
          // mergeAll(),
        ),
    ),
  );

export const unsubscribeToOutcomeInfoEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.unsubscribeToOutcome)),
    withLatestFrom(state$),
    tap(([{ payload }, state]) =>
      wsAdapter.unsubscribeToGroup(
        generateBettingMulticastGroupName('outcome', {
          outcomeId: payload.outcomeId,
          locale: currentWebSocketLocaleSelector(state),
        }),
      ),
    ),
    // Don't send any action
    ignoreElements(),
  );

export const getLiveCountBySportEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getLiveCountBySport.request)),
    switchMap(({ payload }) => {
      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('countSportLive', null),
            )
            .pipe(
              mapGuard(getLiveCountBySportResponseCodec),
              map((response) =>
                actions.getLiveCountBySport.success(response.CountBySportId),
              ),
              catchError((err) =>
                of(
                  actions.getLiveCountBySport.failure(
                    createFailurePayload(err, commonBettingErrorMessages),
                  ),
                ),
              ),
            );

      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getLiveCountBySport({}), requestOptions)
        .pipe(
          mapGuard(getLiveCountBySportResponseCodec),
          map((response) =>
            actions.getLiveCountBySport.success(response.CountBySportId),
          ),
          catchError((err) =>
            of(
              actions.getLiveCountBySport.failure(
                createFailurePayload(err, commonBettingErrorMessages),
              ),
            ),
          ),
        );
      /* 
        The concat ensure that the success action will be dispatched before the
        updates, even if they arrive before the fetch data
      */
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelLiveCountBySportSubscriptionEpic: BettingEpic = (
  actions$,
  _,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getLiveCountBySport.cancel)),
    // Unsubscribe to group
    tap(() =>
      wsAdapter.unsubscribeToGroup(
        generateBettingMulticastGroupName('countSportLive', null),
      ),
    ),
    // Don't send any action
    ignoreElements(),
  );

export const askSportsEpic: BettingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.askSports)),
    delay(1),
    withLatestFrom(state$),
    filter(
      ([, state]) => getSportsStatusSelector(state) === RemoteData.NotAsked,
    ),
    mapTo(actions.getSports.request()),
  );

export const getSportsEpic: BettingEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getSports.request)),
    switchMap(() =>
      wsAdapter.request(getSports({}), requestOptions).pipe(
        mapGuard(getSportsResponseCodec),
        map(formatSport),
        map(actions.getSports.success),
        catchError((err) =>
          of(
            actions.getSports.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const getTopEventsEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getTopEvents.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { key, EventType } = payload;
      const type = EventType === EEventType.Live ? 'live' : 'prematch';
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('topEvent', {
                locale: currentWebSocketLocaleSelector(state),
                type,
              }),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
              // get the content of the EntityUpdates array
              pluck('Updates'),
              // Store updates to use later
              tap((updates) => {
                receivedUpdates = updates;
              }),
              // Convert the array to an array of updates
              map((updates) => sportbookUpdatesToActions(updates, false, key)),
              // Convert the array to an observable
              map((acts) => batchActions(acts)),
              // mergeAll(),
              // Resend the request if incoherence detected
              map((acts) => {
                if (
                  checkIncoherence(receivedUpdates, state$.value, lastReqSent)
                ) {
                  lastReqSent = new Date().getTime();
                  return actions.getTopEvents.request(
                    getRefreshPayload(payload),
                  );
                }

                return acts;
              }),
            );

      const fetchAction$ = wsAdapter
        .request(getTopEvents(payload), requestOptions)
        .pipe(
          mapGuard(baseSportbookResponseCodec),
          map(formatBaseSportBook),
          map((response) => actions.getTopEvents.success({ ...response, key })),
          catchError((err) =>
            of(
              actions.getTopEvents.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                key,
              }),
            ),
          ),
        );

      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelTopEventsSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getTopEvents.cancel)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { EventType, locale },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('topEvent', {
            locale,
            type: EventType === EEventType.Live ? 'live' : 'prematch',
          }),
        ),
    ),
    // Don't send any action
    ignoreElements(),
  );

export const getLivesEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getLives.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { key } = payload;
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('lives', {
                locale: currentWebSocketLocaleSelector(state),
              }),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
              // get the content of the EntityUpdates array
              pluck('Updates'),
              // Store updates to use later
              tap((updates) => {
                receivedUpdates = updates;
              }),
              // Convert the array to an array of updates
              map((updates) => sportbookUpdatesToActions(updates, false, key)),
              // Convert the array to an observable
              map((acts) => batchActions(acts)),
              // mergeAll(),
              // Resend the request if incoherence detected
              map((acts) => {
                if (
                  checkIncoherence(receivedUpdates, state$.value, lastReqSent)
                ) {
                  lastReqSent = new Date().getTime();
                  return actions.getLives.request(getRefreshPayload(payload));
                }

                return acts;
              }),
            );

      // The regular request logic
      const fetchAction$ = wsAdapter.request(getLives({}), requestOptions).pipe(
        mapGuard(baseSportbookResponseCodec),
        map(formatBaseSportBook),
        map((response) => actions.getLives.success({ ...response, key })),
        catchError((err) =>
          of(
            actions.getLives.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              key,
            }),
          ),
        ),
      );
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelGetLivesSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getLives.cancel)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { locale },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('lives', { locale }),
        ),
    ),

    // Don't send any action
    ignoreElements(),
  );

export const getPrematchSportEpic: BettingEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getPrematchSport.request)),
    mergeMap(({ payload }) => {
      const { key } = payload;

      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getPrematchSport(payload), requestOptions)
        .pipe(
          mapGuard(baseSportbookResponseCodec),
          map(formatBaseSportBook),
          map((response) =>
            actions.getPrematchSport.success({ ...response, key }),
          ),
          catchError((err) =>
            of(
              actions.getPrematchSport.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                key,
              }),
            ),
          ),
        );

      return fetchAction$;
    }),
  );

export const subscribeToPrematchSportUpdatesEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.subscribeToPrematchSportUpdates)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      return wsAdapter
        .subscribeToGroup(
          generateBettingMulticastGroupName('prematchSport', {
            sportId: payload.SportId,
            locale: currentWebSocketLocaleSelector(state),
          }),
        )
        .pipe(
          // Filter out malformed data
          filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
          // get the content of the EntityUpdates array
          pluck('Updates'),
          // Store updates to use later
          tap((updates) => {
            receivedUpdates = updates;
          }),
          // Convert the array to an array of updates
          map((updates) =>
            sportbookUpdatesToActions(updates, false, payload.key),
          ),
          // Convert the array to an observable
          map((acts) => batchActions(acts)),
          // Resend the request if incoherence detected
          map((acts) => {
            if (checkIncoherence(receivedUpdates, state$.value, lastReqSent)) {
              lastReqSent = new Date().getTime();
              return actions.getPrematchSport.request(
                getRefreshPayload(payload),
              );
            }

            return acts;
          }),
        );
    }),
  );

export const unsubscribeToPrematchSportUpdatesEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.unsubscribeToPrematchSportUpdates)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { locale, sportId },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('prematchSport', {
            sportId,
            locale,
          }),
        ),
    ),
    // Don't send any action
    ignoreElements(),
  );

export const getFiltersEpic: BettingEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getFilters.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getFilters(payload), requestOptions).pipe(
        mapGuard(getFilterResponseResponseCodec),
        map((response) =>
          actions.getFilters.success({
            filters: response.Filters,
            sportId: payload.SportId,
          }),
        ),
        catchError((err) =>
          of(
            actions.getFilters.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const getLiveSportEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getLiveSport.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { key } = payload;
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('liveSport', {
                sportId: payload.SportId,
                locale: currentWebSocketLocaleSelector(state),
              }),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
              // get the content of the EntityUpdates array
              pluck('Updates'),
              // Store updates to use later
              tap((updates) => {
                receivedUpdates = updates;
              }),
              // Convert the array to an array of updates
              map((updates) => sportbookUpdatesToActions(updates, false, key)),
              // Convert the array to an observable
              map((acts) => batchActions(acts)),
              // Resend the request if incoherence detected
              map((acts) => {
                if (
                  checkIncoherence(receivedUpdates, state$.value, lastReqSent)
                ) {
                  lastReqSent = new Date().getTime();
                  return actions.getLiveSport.request(
                    getRefreshPayload(payload),
                  );
                }

                return acts;
              }),
            );

      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getLiveSport(payload), requestOptions)
        .pipe(
          mapGuard(baseSportbookResponseCodec),
          map(formatBaseSportBook),
          map((response) => actions.getLiveSport.success({ ...response, key })),
          catchError((err) =>
            of(
              actions.getLiveSport.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                key,
              }),
            ),
          ),
        );

      /* 
        The concat ensure that the success action will be dispatched before the
        updates, even if they arrive before the fetch data
      */
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelLiveSportSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getLiveSport.cancel)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { locale, sportId },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('liveSport', { locale, sportId }),
        ),
    ),
    // Don't send any action
    ignoreElements(),
  );

/** Get league */
export const getLeagueEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getLeague.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { key } = payload;
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('league', {
                leagueId: payload.LeagueId,
                locale: currentWebSocketLocaleSelector(state),
              }),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
              // get the content of the EntityUpdates array
              pluck('Updates'),
              // Store updates to use later
              tap((updates) => {
                receivedUpdates = updates;
              }),
              // Convert the array to an array of updates
              map((updates) => sportbookUpdatesToActions(updates, false, key)),
              // Convert the array to an observable
              map((acts) => batchActions(acts)),
              // Resend the request if incoherence detected
              map((acts) => {
                if (
                  checkIncoherence(receivedUpdates, state$.value, lastReqSent)
                ) {
                  lastReqSent = new Date().getTime();
                  return actions.getLeague.request(getRefreshPayload(payload));
                }

                return acts;
              }),
            );

      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getEventsForLeague(payload), requestOptions)
        .pipe(
          mapGuard(getLeagueResponseCodec),
          map(formatGetLeagueResponse),
          map((response) => actions.getLeague.success({ ...response, key })),
          catchError((err) =>
            of(
              actions.getLeague.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                key,
              }),
            ),
          ),
        );

      /* 
        The concat ensure that the success action will be dispatched before the
        updates, even if they arrive before the fetch data
      */
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelLeagueSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getLeague.cancel)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { locale, leagueId },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('league', { leagueId, locale }),
        ),
    ),
    // Don't send any action
    ignoreElements(),
  );

/** Get Region */
export const getRegionEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getRegion.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { key } = payload;
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('region', {
                sportId: payload.SportId,
                regionId: payload.RegionId,
                locale: currentWebSocketLocaleSelector(state),
              }),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
              // get the content of the EntityUpdates array
              pluck('Updates'),
              // Store updates to use later
              tap((updates) => {
                receivedUpdates = updates;
              }),
              // Convert the array to an array of updates
              map((updates) => sportbookUpdatesToActions(updates, false, key)),
              // Convert the array to an observable
              map((acts) => batchActions(acts)),
              // Resend the request if incoherence detected
              map((acts) => {
                if (
                  checkIncoherence(receivedUpdates, state$.value, lastReqSent)
                ) {
                  lastReqSent = new Date().getTime();
                  return actions.getRegion.request(getRefreshPayload(payload));
                }

                return acts;
              }),
            );

      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getEventsForRegion(payload), requestOptions)
        .pipe(
          mapGuard(baseSportbookResponseCodec),
          map(formatBaseSportBook),
          map((response) => actions.getRegion.success({ ...response, key })),
          catchError((err) =>
            of(
              actions.getRegion.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                key,
              }),
            ),
          ),
        );

      /* 
        The concat ensure that the success action will be dispatched before the
        updates, even if they arrive before the fetch data
      */
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelRegionSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getRegion.cancel)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { sportId, regionId, locale },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('region', {
            sportId,
            regionId,
            locale,
          }),
        ),
    ),
    // Don't send any action
    ignoreElements(),
  );

export const getEventEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getEvent.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { key } = payload;
      let receivedUpdates: SportbookUpdateType[];
      let lastReqSent: number = new Date().getTime();

      // If asked, we first subscribe to updates
      const updateActions$ = !payload.subscribeToUpdates
        ? EMPTY
        : wsAdapter
            .subscribeToGroup(
              generateBettingMulticastGroupName('event', {
                eventId: payload.EventId,
                locale: currentWebSocketLocaleSelector(state),
              }),
            )
            .pipe(
              // Filter out malformed data
              filter(codecGuard(sportbookUpdatesResponseCodec, logger)),
              // get the content of the EntityUpdates array
              pluck('Updates'),
              // Store updates to use later
              tap((updates) => {
                receivedUpdates = updates;
              }),
              // Convert the array to an array of updates
              map((updates) => sportbookUpdatesToActions(updates, true, key)),
              // Convert the array to an observable
              map((acts) => batchActions(acts)),
              // Resend the request if incoherence detected
              map((acts) => {
                if (
                  checkIncoherence(receivedUpdates, state$.value, lastReqSent)
                ) {
                  lastReqSent = new Date().getTime();
                  return actions.getEvent.request(getRefreshPayload(payload));
                }

                return acts;
              }),
            );

      // The regular request logic
      const fetchAction$ = wsAdapter
        .request(getEvent(payload), requestOptions)
        .pipe(
          mapGuard(eventResponseCodec),
          map(formatGetEvent),
          map((response) => actions.getEvent.success({ ...response, key })),
          catchError((err) =>
            of(
              actions.getEvent.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                key,
              }),
            ),
          ),
        );

      /* 
        The concat ensure that the success action will be dispatched before the
        updates, even if they arrive before the fetch data
      */
      return concat(fetchAction$, updateActions$);
    }),
  );

export const cancelEventSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getEvent.cancel)),
    withLatestFrom(state$),
    // Unsubscribe to group
    tap(
      ([
        {
          payload: { eventId, locale },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('event', { eventId, locale }),
        ),
    ),
    // Don't send any action
    ignoreElements(),
  );

/** Get light search */
export const lightSearchEpic: BettingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.lightSearch.request)),
    filter(
      ({ payload }) => !!payload.SearchTerm && payload.SearchTerm?.length > 2,
    ),
    switchMap(({ payload }) =>
      wsAdapter.request(lightSearch(payload)).pipe(
        mapGuard(lightSearchResponseCodec),
        map(actions.lightSearch.success),
        catchError((err) =>
          of(
            actions.lightSearch.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
            }),
          ),
        ),
      ),
    ),
  );

/** Get full search */
export const fullSearchEpic: BettingEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.fullSearch.request)),
    filter(
      ({ payload }) => !!payload.SearchTerm && payload.SearchTerm?.length > 2,
    ),
    switchMap(({ payload }) => {
      const { key } = payload;

      return wsAdapter.request(fullSearch(payload)).pipe(
        mapGuard(fullSearchResponseCodec),
        map(formatFullSearch),
        map((response) => actions.fullSearch.success({ ...response, key })),
        // map(() =>
        //   actions.fullSearch.success({
        //     ...normalizedFullSearchMockResponse,
        //     key,
        //     SearchTokens: ['rin', 'lig'],
        //   }),
        // ),
        catchError((err) =>
          of(
            actions.fullSearch.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              key,
            }),
          ),
        ),
      );
    }),
  );

export const reactToLightSearchBettingEpic: BettingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(coreActions.userSearch)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      request: {
        SearchTerm: payload.searchTerm,
        MaxEventsCount: 5,
        MaxCompetitionsCount: 5,
      },
      payload,
      state,
    })),
    map(({ payload, request }) =>
      payload.searchTerm.length >= MIN_SEARCH_TERM_LENGTH
        ? actions.lightSearch.request(request)
        : actions.clearLightSearch(),
    ),
  );

export const reactToFullSearchBettingEpic: BettingEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.betSearch)),
    filter(
      ({ payload }) => payload.SearchTerm.length >= MIN_SEARCH_TERM_LENGTH,
    ),
    map(({ payload }) => actions.fullSearch.request(payload)),
  );
