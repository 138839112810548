import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { useConfig } from '@gaming1/g1-config';

type MovedPermanentlyProps = {
  /** The pathname with optional search params (e.g. "/path/to/resource") */
  redirectTo: string;
};

export const MovedPermanentlyPage: FC<MovedPermanentlyProps> = ({
  redirectTo,
}) => {
  const {
    network: { siteUrl },
  } = useConfig();

  // see https://docs.prerender.io/article/7-faq
  const isPrerenderBot = !!navigator.userAgent.match(/Prerender/m);

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="301" />
        <meta
          name="prerender-header"
          content={`Location: ${siteUrl}${redirectTo}`}
        />
      </Helmet>
      {!isPrerenderBot && <Redirect to={redirectTo} />}
    </>
  );
};
