import React, { FC, useContext } from 'react';
import { shallowEqual } from 'react-redux';

import {
  bettingSlipFirstItemGetterSelector,
  bettingSlipIsCashableGetterSelector,
  hasCashoutAvailableForPlayerSelector,
  useBettingGetterSelector,
  useIsOptimalBetIsAplyOnBettingSlip,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../common/components/BettingIcon';
import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipOdds } from '../BettingSlipOdds/BettingSlipOdds';
import { BettingSlipSinglePotWinning } from '../BettingSlipPotWinning/BettingSlipSinglePotWinning';
import { BettingSlipSingleStake } from '../BettingSlipStake/BettingSlipSingleStake';
import { BettingSlipValidation } from '../BettingSlipValidation';

import { BettingSlipFooterLineContainer } from './styles';

/** Render a Single BettingSlip (ticket) with a stake, an odd, a potWinning and a CTA to validate the BS */
export const BettingSlipFooterSingle: FC = () => {
  const { t } = useTranslation('betting');

  const { bettingSlipId } = useContext(BettingSlipContext);

  const id = useBettingGetterSelector({
    getterSelector: bettingSlipFirstItemGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  })?.id;

  const hasCashoutAvailable = useBettingGetterSelector({
    getterSelector: hasCashoutAvailableForPlayerSelector,
    args: [],
  });
  const isCashable = useBettingGetterSelector({
    getterSelector: bettingSlipIsCashableGetterSelector,
    args: [bettingSlipId],
  });

  const showOptiOddsIcons = useIsOptimalBetIsAplyOnBettingSlip(bettingSlipId);

  if (!id) {
    return null;
  }

  return (
    <>
      <BettingSlipSingleStake id={id} />
      <BettingSlipFooterLineContainer data-testid="bettingslip-single-odd-container">
        <Box flexDirection="row" alignItems="center">
          {t('bettingslip.single.odd')}
          {/* TODO: create unit test but need to wait for API */}
          {showOptiOddsIcons && (
            <BettingIcon
              id="optiOdds"
              type="OptiOddsTag"
              title="Optiodds"
              ml="xxs"
              width={16}
              height={16}
            />
          )}
        </Box>
        <BettingSlipOdds />
      </BettingSlipFooterLineContainer>
      <BettingSlipFooterLineContainer data-testid="bettingslip-single-potwinning-container">
        <Box flexDirection="row" alignItems="center">
          {t('bettingslip.single.potWinning')}
          {/* TODO: create unit test but need to wait for API */}
          {hasCashoutAvailable && isCashable && (
            <BettingIcon
              id="cashout"
              type="CashoutTag"
              title="Cashout"
              ml="xxs"
              width={16}
              height={16}
            />
          )}
        </Box>
        <BettingSlipSinglePotWinning />
      </BettingSlipFooterLineContainer>
      <Box mt="sm" width="100%">
        <BettingSlipValidation />
      </Box>
    </>
  );
};
