import Big from 'big.js';

import { ERoundingMethod, ERoundingTarget } from '@gaming1/g1-requests-betting';
import { ceilNumber, roundNumber } from '@gaming1/g1-utils';

import { RoundingConfig } from '../store/types/common';

const defaultConfig: RoundingConfig = {
  bettingSlipType: 'single',
  rounding: [],
};

/*
 * Rounding
 */
export const getConfigScope = (
  target: ERoundingTarget,
  config: RoundingConfig,
) => config.rounding.find((item) => item.Target === target);
export const getRoundingMethod = (value: ERoundingMethod) => {
  switch (value) {
    case ERoundingMethod.MathematicalRounding:
      return roundNumber;
    case ERoundingMethod.UpRounding:
      return ceilNumber;
    default:
      return roundNumber;
  }
};

export const roundNumberByConfig = (
  value: number,
  target: ERoundingTarget,
  config: RoundingConfig,
) => {
  const configScope = getConfigScope(target, config);
  if (!configScope) {
    return value;
  }
  const roundingMethode = getRoundingMethod(configScope?.Method);

  return roundingMethode(value, configScope.Precision);
};

/*
 * For winning
 */
export const calcWinning =
  (config?: RoundingConfig) =>
  (stake: number, odd: number): number =>
    roundNumberByConfig(
      Big(stake).times(odd).toNumber(),
      ERoundingTarget.CurrencyRounding,
      config || defaultConfig,
    );
export const calcProfitWinning =
  (config?: RoundingConfig) =>
  (base: number, final: number): number =>
    roundNumberByConfig(
      Big(final).minus(base).toNumber(),
      ERoundingTarget.CurrencyRounding,
      config || defaultConfig,
    );

/*
 * For odd
 */
export const calcOddsMultiplying =
  (config?: RoundingConfig) =>
  (list: number[]): number =>
    roundNumberByConfig(
      list.reduce(
        (sum, currentOdd) => Big(sum).times(currentOdd).toNumber(),
        1,
      ),
      ERoundingTarget.BettingSlipOddRounding,
      config || defaultConfig,
    );
export const calcOddsWithBoostus =
  (config?: RoundingConfig) =>
  (oddValue: number, boostValue: number): number =>
    roundNumberByConfig(
      Big(Big(oddValue).minus(1))
        .times(Big(1).plus(boostValue))
        .plus(1)
        .toNumber(),
      ERoundingTarget.BoostusOddRounding,
      config || defaultConfig,
    );

export const calcOddsWithOptimalBets =
  (config?: RoundingConfig) =>
  (oddValue: number, coefficient: number, offset: number): number =>
    roundNumberByConfig(
      Big(Big(oddValue)).plus(offset).times(coefficient).toNumber(),
      ERoundingTarget.BettingSlipElementOddRounding,
      config || defaultConfig,
    );
