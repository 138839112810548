import styled from 'styled-components';

import { Box } from '@gaming1/g1-ui';

import { PrismicWrapper } from '../styles';

type PrismicVideoWrapperProps = {
  width: string | null;
};

const RATIO_16_9_PADDING = '56.25%';

export const PrismicVideoWrapper = styled(
  PrismicWrapper,
)<PrismicVideoWrapperProps>`
  width: ${({ width }) => width || '100%'};
  position: relative;
`;

export const PrismicVideoContainer = styled(Box)`
  position: relative;
  padding-bottom: ${RATIO_16_9_PADDING};

  iframe,
  video {
    position: absolute;
    border: 0;
  }
`;
