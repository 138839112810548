import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { BetPipeIdentifier } from '../betPipe/types';

import {
  GetAvailablePromotionsResponseType,
  GetPromotionsParamsType,
  HasAvailablePromotionsResponseType,
} from './types';

/**
 * hasAvailablePromotions action
 */

export const hasAvailablePromotions = createAsyncAction(
  'betting/has_available_promotions_request',
  'betting/has_available_promotions_success',
  'betting/has_available_promotions_failure',
)<void, HasAvailablePromotionsResponseType, FailurePayload>();

export const getAvailablePromotions = createAsyncAction(
  'betting/get_available_promotions_request',
  'betting/get_available_promotions_success',
  'betting/get_available_promotions_failure',
  'betting/get_available_promotions_cancel',
)<
  BetPipeIdentifier & GetPromotionsParamsType,
  GetAvailablePromotionsResponseType &
    BetPipeIdentifier &
    GetPromotionsParamsType,
  FailurePayload & BetPipeIdentifier & GetPromotionsParamsType,
  BetPipeIdentifier
>();

export const getAvailablePromotionsWithCache = createAction(
  'betting/get_promotions_with_cache',
)<BetPipeIdentifier & GetPromotionsParamsType>();
