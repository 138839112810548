import React, { FC, memo } from 'react';

import { Col, Row } from '@gaming1/g1-ui';

import { BettingSlipItemError } from '../../BettingSlipError/BettingSlipItemError';

type BettingSlipItemStakeInfoProps = {
  /** id to find ticket item */
  id: number;
};

/**
 * Display the ticket item stake and pot winning information
 * a item (base on outcome) select by user and save into store
 */
const BettingSlipStakeInfoComponent: FC<BettingSlipItemStakeInfoProps> = ({
  id,
}) => (
  <Row>
    {/* <PotWinningCol data-testid={`bettingslip-pot-winning-${id}`} xs={7}>
      <BettingSlipItemPotWinning id={id} />
    </PotWinningCol> */}
    <Col xs={5}>{/* <BettingSlipSingleStake id={id} /> */}</Col>
    <Col xs={12}>
      <BettingSlipItemError id={id} />
    </Col>
  </Row>
);

export const BettingSlipStakeInfo = memo(BettingSlipStakeInfoComponent);
