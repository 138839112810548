/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:39:53 26-02-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GeoComplyCheckLocationRequestDTO,
  GeoComplyCheckLocationResponseDTO,
  IsGeoComplyLocationValidRequestDTO,
  IsGeoComplyLocationValidResponseDTO,
} from '../entities';

export const geoComplyCheckLocation = (
  options: GeoComplyCheckLocationRequestDTO,
) =>
  createRequest<GeoComplyCheckLocationResponseDTO>(
    100,
    'GeoComplyCheckLocation',
    options,
  );

export const isGeoComplyLocationValid = (
  options: IsGeoComplyLocationValidRequestDTO,
) =>
  createRequest<IsGeoComplyLocationValidResponseDTO>(
    100,
    'IsGeoComplyLocationValid',
    options,
  );
