import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { Text } from '@gaming1/g1-ui';

import { TypeViewStyle } from './types';

export type CombinasionsStatusProps = {
  isLost: boolean;
  isWin: boolean;
};
export const OutcomeLabelStatus = styled.span`
  font-size: ${fontSizes('md')};
  opacity: ${opacity('seeThrough')};
`;

export const OutcomeStatus = styled.span<CombinasionsStatusProps>`
  display: flex;
  align-items: center;
  margin-right: ${spaces('xxs')};
  svg path {
    ${({ isLost }) =>
      isLost &&
      css`
        fill: ${colors('neutrals', { index: 3 })};
      `}
    ${({ isWin }) =>
      isWin &&
      css`
        fill: ${colors('success')};
      `}
  }
`;

export const CombinationLabel = styled.span<TypeViewStyle>`
  color: ${colors('primary')};
  margin-right: ${spaces('xxs')};
  font-size: ${fontSizes('md')};
`;

export const OutcomeName = styled(Text)<TypeViewStyle>`
  ${fontWeight('bold')}
  color: ${colors('text')};
  margin-right: ${spaces('xxs')};
  font-size: ${fontSizes('md')};
`;
export const MarketName = styled(Text)<TypeViewStyle>`
  color: ${colors('text')};
  opacity: ${opacity('seeThrough')};
  margin-right: ${spaces('xxs')};
  font-size: ${fontSizes('md')};
`;
export const PlayerMarketName = styled(MarketName)`
  ${fontWeight('light')};
  opacity: ${opacity('semi')};
`;
export const EventName = styled(Text)<TypeViewStyle>`
  color: ${colors('text')};
  opacity: ${opacity('seeThrough')};
  font-size: ${fontSizes('md')};
  margin-top: ${spaces('xxs')};
`;
export const EventFullName = styled(Text)<TypeViewStyle>`
  color: ${colors('text')};
  opacity: ${opacity('seeThrough')};
  font-size: ${fontSizes('md')};
  margin-top: ${spaces('xxs')};
  /* stylelint-disable no-descending-specificity */
  & > div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Date = styled.span<TypeViewStyle>`
  color: ${colors('text')};
  opacity: ${opacity('seeThrough')};
  font-size: ${fontSizes('md')};
`;

export const DateContainer = styled(Text)`
  font-size: ${fontSizes('sm')};
  opacity: ${opacity('seeThrough')};
`;

export const OddContainer = styled.span`
  ${fontWeight('bold')};
  font-size: ${fontSizes('md')};
`;
