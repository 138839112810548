import { ApplicationWithLoyaltyState } from '../types';

import { ShopHistoryElement } from './types';

/* Game feature */

export const freeSpinsHistorySelector = (state: ApplicationWithLoyaltyState) =>
  state.loyalty.history.freeSpins || [];

export const getGameFeatureHistoryRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.history.requests.getGameFeatureHistory;

/* Scratchcard */

export const scratchCardHistorySelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.history.scratchcards || [];

export const getScratchCardHistoryRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.history.requests.getScratchCardHistory;

/* Shop */

export const shopHistorySelector = (state: ApplicationWithLoyaltyState) =>
  state.loyalty.history.shopOrders || [];

export const shopHistoryElementsSelector = (
  state: ApplicationWithLoyaltyState,
) =>
  state.loyalty.history.shopOrders?.reduce(
    (acc, order) => [
      ...acc,
      ...(order?.Items?.map((item) => ({
        ...item,
        Id: order.Id,
        OrderDate: order.OrderDate,
        State: order.State,
      })) || []),
    ],
    [] as ShopHistoryElement[],
  ) || [];

export const getShopHistoryRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.history.requests.getShopHistory;

/* Sponsorship */

export const sponsorshipHistorySelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.history.sponsorships || [];

export const getSponsorshipHistoryRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.history.requests.getSponsorshipHistory;
