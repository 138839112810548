import styled, { css } from 'styled-components';

import { colors } from '@gaming1/g1-style';
import { Button } from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

export const JsonDisplay = styled.pre`
  white-space: pre-wrap;
`;

export const TriggerButton = styled(Button)`
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 6px;
  & + & {
    margin-top: 4px;
  }
`;

export const RequestStep = styled.span<{ state: RemoteData }>`
  display: inline-block;
  padding: 4px;
  position: relative;
  background-color: ${colors('muted')};
  ${({ state }) => {
    if (state === RemoteData.Error) {
      return css`
  background-color ${colors('danger')};`;
    }
    if (state === RemoteData.Loading) {
      return css`
  background-color ${colors('warning')};`;
    }
    if (state === RemoteData.Success) {
      return css`
  background-color ${colors('success')};`;
    }
    return '';
  }}

  & + & {
    padding-left: 14px;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; /* 40px height (20+20) */
    border-left: 10px solid #333;
    position: absolute;
    right: -10px;
    z-index: 2;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`;
