/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:56:17 16-02-2021                       */
/*                                                                 */
/* *************************************************************** */

/**
 * @description Status of the external token authentication
 */
export enum EExternalTokenAuthenticationStatus {
  /**
   * @description Unexpected error occurred
   */
  InternalError = 0,
  /**
   * @description Authentication successful
   */
  Successful = 1,
  /**
   * @description Actor not found with provided information
   */
  ActorNotFound = 2,
  /**
   * @description Room parameter empty or unknown room
   */
  InvalidRoom = 3,
}
export enum EGetDepositInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  PlayerBlackListed = 3,
  PlayerHasNotMinimumLegalAge = 4,
  PlayerDepositLimitReached = 5,
  PlayerHasPendingWithdraw = 6,
  RealMoneyDisabled = 7,
  AccountActivationRequired = 8,
  PlayerAutoExcluded = 9,
  ErrorMajor = 10,
  PlayerWaitingForIdentification = 11,
  Disabled = 12,
}

export enum ECreditCardWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  WaitingForIdentity = 3,
  AccountNotActivated = 4,
  InvalidAmount = 5,
  InsufficientCredit = 6,
  TooFrequentRequest = 7,
  BonusGameRunning = 8,
  HasRunningGame = 9,
  IovationDenied = 10,
  AccountNotAllowed = 11,
  PendingWithdraw = 12,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
  WalletInformationNotFound = 23,
}

/**
 * @description Enumeration for response status
 */
export enum GetCreditCardWithdrawInfosStatus {
  /**
   * @description Failure: an internal error occured
   */
  InternalError = 0,
  /**
   * @description Success
   */
  Successful = 1,
  /**
   * @description Failure: the provided player is invalid
   */
  InvalidPlayer = 2,
}

export enum ERegistrationStatus {
  None = 0,
  Pending = 1,
  Registered = 2,
  Any = 3,
}
/**
 * @description The type of value that is expected to be returned by the front-end.
 *
 * As of today, could be an amount of money, or the code of a prepaid card.
 */
export enum EGetDepositInfoExpectedValueType {
  Amount = 0,
  Code = 1,
}
export enum ExtraFieldType {
  Mail = 0,
  Password = 1,
  Money = 2,
  Date = 3,
  Code = 4,
  Text = 5,
  Options = 6,
  MultiSelectOptions = 7,
  Integer = 8,
  Element = 9,
  SelectText = 10,
  Checkbox = 11,
  Hidden = 12,
  PostExtraFormButton = 13,
  PostExtraFormLink = 14,
  LabelValue = 15,
  RadioButtons = 16,
  Label = 17,
}
export enum EDepositType {
  Unknown = 0,
  Classic = 1,
  Voucher = 2,
}
export enum EPrizeType {
  Ticket = 0,
  Bonus = 1,
  DepositBonus = 2,
  RealMoney = 3,
  VipPoint = 4,
  FreeBet = 5,
  FreeSpin = 6,
  Item = 7,
}
export enum EGamingRoomsBonusType {
  Unknown = 0,
  Inscription = 100,
  InscriptionDirectCash = 101,
  FirstDeposit = 200,
  Deposit = 201,
  DepositRange = 210,
  Sponsorship = 300,
  /**
   * @description Bonus given by the casino for a event : new game, ...
   */
  Event = 400,
  /**
   * @description Gift
   */
  Gift = 500,
  /**
   * @description Bonus given by back office, for compensation
   */
  Manual = 600,
  /**
   * @description Bonus bought in the club
   */
  Club = 700,
  /**
   * @description Dynamic bonus
   */
  Crm = 800,
  CrmConnectionDirectCash = 801,
  CrmLoss = 802,
  CrmBirthday = 809,
  TournamentPrize = 810,
  /**
   * @description Direct cash bonus, without deposit
   */
  DirectCash = 900,
  /**
   * @description Connection bonus
   */
  ConnectionDirectCash = 901,
  /**
   * @description Connection from mobile bonus
   */
  ConnectionMobileDirectCash = 902,
  InRoom = 1000,
  BettingFirstBet = 1100,
  BettingBet = 1101,
  VirtualBettingBet = 1201,
}
export enum EDepositLimitation {
  None = 0,
  PersonalLimitation = 1,
  LegalLimitation = 2,
}
export enum EMeansOfLimitCancellation {
  None = 0,
  Settings = 1,
  DeclarationOfHonor = 2,
}
export enum EDepositLimitationDetail {
  None = 0,
  DailyDepositLimit = 1,
  WeeklyDepositLimit = 2,
  MonthlyDepositLimit = 3,
  YearlyDepositLimit = 4,
}
export enum EDepositBonusChoice {
  Default = 0,
  None = 1,
  Code = 2,
  Specific = 3,
  Package = 4,
}
export enum EMakeDepositStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  PlayerBlackListed = 3,
  PlayerHasNotMinimumLegalAge = 4,
  PlayerDepositLimitReached = 5,
  PlayerHasPendingWithdraw = 6,
  RealMoneyDisabled = 7,
  InvalidWallet = 8,
  WalletDisabled = 9,
  InvalidAmount = 10,
  InvalidMinimalAmount = 11,
  InvalidMaximalAmount = 12,
  InitializationFailed = 14,
  InvalidBonusCode = 15,
  AccountActivationRequired = 16,
  PlayerAutoExcluded = 17,
  InvalidCardCode = 18,
  PlayerWaitingForIdentification = 19,
  ErrorMajor = 20,
  InvalidCredential = 21,
  NewDespositNotAllowedForTheEmail = 22,
  ProviderError = 23,
  MissingParam = 24,
  PlayerNotAllowed = 25,
  InvalidTimeLimitForPayment = 26,
  None = 27,
  CurrencyNotSupported = 28,
  CountryCodeNotSupported = 29,
  TransactionAlreadyProcessed = 30,
  WrongUrl = 31,
  InvalidCardNumber = 32,
  InvalidPayment = 33,
  InsufficientFunds = 34,
  BadTrackData = 35,
  RestrictedCard = 36,
  SecurityViolation = 37,
  ResponseTimeout = 38,
  CardNot3DSEnabled = 39,
  ThreeDSMalfunction = 40,
  ThreeDSAuthRequired = 41,
  ExpiredCard = 42,
  RiskBlockedPayment = 43,
  PostCodeFailed = 44,
  InvalidOperation = 45,
  InvalidCard = 46,
  Error3DS = 47,
  InvalidParam = 48,
  InvalidPhoneNumber = 49,
  InvalidEmail = 50,
  SendNotImplemented = 51,
  GetNotImplemented = 52,
  FinalizeNotImplemented = 53,
  InvalidOrderId = 54,
  DeviceNotLinkedToAMobileWallet = 55,
  InvalidSignatureForMobileWallet = 56,
  SDKVersionOutdated = 57,
  SuspendedDevice = 58,
  ExpiredPasswordOrPIN = 59,
  ApplicationInstanceVerificationRequired = 60,
  SyntacticalError = 61,
  LoginNeeded = 62,
  NotFound = 63,
  UnexpectedResponse = 64,
  FraudulentTransaction = 65,
  NotRedeemable = 66,
  CanceledTransaction = 67,
  InvalidToken = 68,
  TippingNotSupported = 69,
  TippingCalculationError = 70,
  TerminalError = 71,
  TransactionBeingReviewed = 72,
  InvalidAccount = 73,
  InvalidBillingAddress = 74,
  TransactionRequestNotSupported = 75,
  RefundDataMismatch = 76,
  RefundAmountExceedsOriginal = 77,
  AlreadyRefunded = 78,
  MultipleDepositsNotAllowed = 79,
}
export enum EDepositStatusResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidDepositId = 2,
}
export enum EDepositStatusResponseDepositStatus {
  Pending = 0,
  Canceled = 1,
  Refused = 2,
  Accepted = 3,
  Rejected = 4,
}
export enum EDepositHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EGetDepositLimitationResponseStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ESetDepositLimitationResponseStatus {
  InternalError = 0,
  Successful = 1,
  AlreadyLower = 2,
  Unchanged = 3,
  LimitTooLow = 4,
  LowerLimitationPending = 5,
  RaiseNotAllowed = 6,
}
/**
 * @description Represents the response status.
 */
export enum EFindDepositsStatus {
  /**
   * @description Internal Error: 0
   */
  InternalError = 0,
  /**
   * @description Successful: 1
   */
  Successful = 1,
  /**
   * @description Invalid Room: 2
   */
  InvalidRoom = 2,
}
export enum EGetWithdrawInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  WaitingForIdentification = 3,
  PendingDocuments = 4,
  PendingWithdraw = 5,
  HasRunningGame = 6,
  AccountAutoExclude = 7,
  IovationDenied = 8,
  ValidationError = 9,
  MaximumWithdrawPerDayReached = 10,
  MaximumRunningWithdrawReached = 11,
}
export enum EWithdrawType {
  Unknown = 0,
  InRoom = 1,
  BankTransfer = 2,
  Anytime = 3,
  EWallet = 4,
  CreditCard = 5,
  MoviiRed = 7,
  EBank = 8,
  Efecty = 9,
}
export enum EWithdrawMethodInfoStatus {
  Enabled = 0,
  MinimumDepositAmountNotReached = 1,
  MaxPendingWithdrawReached = 2,
  InRoomWithdrawDisabled = 3,
  PlayerTooYoung = 4,
  NoPreviousInRoomDeposit = 5,
  TooMuchInRoomWithdraws = 6,
}
export enum EMakeTransferWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  WaitingForIdentity = 3,
  AccountNotActivated = 4,
  InvalidAmount = 5,
  InsufficientCredit = 6,
  TooFrequentRequest = 7,
  BonusGameRunning = 8,
  HasRunningGame = 9,
  NoIban = 10,
  NoBic = 11,
  InvalidIban = 12,
  IbanSuspicious = 13,
  IbanUsedTooManyTimes = 14,
  ForbiddenIban = 15,
  IovationDenied = 16,
  IbanWaitingTrust = 17,
  WithdrawForbidden = 18,
  PendingWithdraw = 19,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
  InvalidSecurityPinCode = 22,
}
export enum EMakeInRoomWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  WaitingForIdentity = 3,
  AccountNotActivated = 4,
  InvalidAmount = 5,
  InsufficientCredit = 6,
  TooFrequentRequest = 7,
  BonusGameRunning = 8,
  HasRunningGame = 9,
  InRoomWithdrawDisabled = 10,
  TooMuchInRoomWithdraws = 11,
  PlayerTooYoung = 12,
  IovationDenied = 13,
  BadCodeFormat = 14,
  InRoomWithdrawVirtualRoomSelectionMandatory = 15,
  UnknownVirtualRoom = 16,
  NoPreviousInRoomDeposit = 17,
  PendingWithdraw = 18,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
  MinimumDepositAmountNotReached = 22,
}
export enum EMakeAnytimeWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  WaitingForIdentity = 3,
  AccountNotActivated = 4,
  InvalidAmount = 5,
  InsufficientCredit = 6,
  TooFrequentRequest = 7,
  BonusGameRunning = 8,
  HasRunningGame = 9,
  AnytimeWithdrawDisabled = 10,
  AnytimeCardLost = 11,
  AnytimeCardStolen = 12,
  AnytimeCardNotActivated = 13,
  AnytimeCardNotFound = 14,
  IovationDenied = 15,
  PendingWithdraw = 16,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
}
export enum EWalletWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  WaitingForIdentity = 3,
  AccountNotActivated = 4,
  InvalidAmount = 5,
  InsufficientCredit = 6,
  TooFrequentRequest = 7,
  BonusGameRunning = 8,
  HasRunningGame = 9,
  IovationDenied = 10,
  AccountNotAllowed = 11,
  PendingWithdraw = 12,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
}
export enum EMakeMoviiRedWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  VirtualRoomNotFound = 2,
  PendingWithdraw = 3,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
}
export enum EBankWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  PendingWithdraw = 2,
  MaximumWithdrawPerDayReached = 20,
  MaximumRunningWithdrawReached = 21,
}
export enum GetTransferWithdrawInfosStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum ESetWithdrawLimitationResponseStatus {
  InternalError = 0,
  Successful = 1,
  Failed = 2,
}
export enum EWithdrawLimitationStatus {
  Successful = 0,
  Unchanged = 1,
  LimitTooLow = 2,
  LimitMandatory = 3,
}
export enum EGetWithdrawLimitationResponseStatus {
  InternalError = 0,
  Successful = 1,
  NoLimitation = 2,
}
export enum GetBicForIbanResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidIban = 2,
}
export enum EGetAnytimeCardStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  AnytimeDisabled = 3,
  RedirectToOrder = 4,
}
export enum GetEWalletWithdrawInfosStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum GetEBankWithdrawInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum ECancellableWithdrawsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EWithdrawtHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum ECancelWithdrawStatus {
  InternalError = 0,
  Successful = 1,
  InvalidWithdrawId = 2,
  CancelDenied = 3,
}
/**
 * @description Contains the PushTestResponse status values
 */
export enum EPushTestResponseStatus {
  /**
   * @description The Internal Error status (0)
   */
  InternalError = 0,
  /**
   * @description The Successful status (1)
   */
  Successful = 1,
  /**
   * @description The Player not reachable status (2)
   */
  PlayerNotReachable = 2,
}
/**
 * @description Contains the InjectResponseTest status values
 */
export enum EInjectResponseTestStatus {
  /**
   * @description The Internal Error status (0)
   */
  InternalError = 0,
  /**
   * @description The Successful status (1)
   */
  Successful = 1,
}
export enum EAcceptLegalPopupResponse {
  None = 0,
  Successful = 1,
  InternalError = 2,
  InvalidPlayer = 3,
  InvalidRoom = 4,
  InvalidBusinessSession = 5,
  LegalPopupDisabled = 6,
}
export enum EExclusionType {
  Unknown = 0,
  AutoExclude = 10,
  AutoExcludeDefinitive = 11,
  CloseAccount = 20,
  GamePause = 30,
}
export enum EAutoExcludeResponseStatus {
  Unknown = 0,
  InternalError = 1,
  Successful = 10,
  AlreadyExcluded = 20,
  InvalidType = 21,
  AutoExclusionDisable = 22,
  InvalidAutoExcludeStartDate = 23,
  InvalidDurationForType = 24,
}
export enum EUnExcludeResponseStatus {
  Unknown = 0,
  Successful = 1,
  ImpossibleUnExclude = 2,
  InternalError = 3,
}
export enum EGetAutoExcludeinfoResponse {
  Unknown = 0,
  Successful = 1,
  InternalError = 2,
  AutoExclusionDisabled = 3,
}
export enum EActivitySource {
  Gaming = 0,
  Betting = 1,
  VirtualBetting = 2,
  FantasyBet = 3,
  HorseBetting = 4,
  Poker = 5,
}
export enum EActivityType {
  Deposit = 0,
  Wage = 1,
  Loss = 2,
  Connection = 3,
  ConnectionTime = 4,
  MinimumTimeBetweenConnections = 5,
  SpentTime = 6,
}
export enum ESetLimitsResponseStatus {
  InternalError = 0,
  Successful = 1,
  AlreadyLower = 2,
  Unchanged = 3,
  LimitTooLow = 4,
  LowerLimitationPending = 5,
  RaiseNotAllowed = 6,
  LowerNotAllowed = 7,
  HigherLimitationPending = 8,
  LimitTooHigh = 9,
  InvalidRoom = 10,
}
export enum EGetLimitsResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGetConnectionLimitRemainingTimeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  ConnectionTimeReached = 3,
}
export enum EGetSpentTimeLimitRemainingTimeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  SpentTimeLimitReached = 3,
}
/**
 * @description Enum of response status
 */
export enum BlacklistPlayerStatus {
  /**
   * @description InternalError
   */
  InternalError = 0,
  /**
   * @description Successful
   */
  Successful = 1,
  /**
   * @description InvalidPlayer
   */
  InvalidPlayer = 2,
}
export enum ESetSessionDurationResponseStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetSessionDurationResponseStatus {
  InternalError = 0,
  Successful = 1,
}
/**
 * @description enum of status of legal limit
 */
export enum EGetLegalLimitStatus {
  /**
   * @description None
   */
  None = 0,
  /**
   * @description AskedRemove (old system)
   */
  AskedRemove = 1,
  /**
   * @description AlreadySendDocumentsButNotValidate (old system)
   */
  AlreadySendDocumentsButNotValidate = 2,
  /**
   * @description Success
   */
  Success = 3,
  /**
   * @description Request can exceed limit exists
   */
  RequestCanExceedLimitExists = 4,
  /**
   * @description When the request was refused by the CJH
   */
  RequestCanExceedLimitRefused = 5,
}
export enum ResponseStatus {
  Unknown = 0,
  Success = 1,
  InternalError = 2,
  MissingField = 3,
}
export enum ESetLegalLimitStatus {
  None = 0,
  Success = 1,
  AskedRemove = 2,
  AlreadySendDocumentsButNotValidate = 3,
  RequestCanExceedLimitExists = 4,
  CurrentRequestIsRefused = 5,
}
export enum EShowLegalPopupResponse {
  None = 0,
  Successful = 1,
  InternalError = 2,
  InvalidPlayer = 3,
  InvalidRoom = 4,
  InvalidBusinessSession = 5,
  LegalPopupDisabled = 6,
}
/**
 * @description Represents the feature values protected by the Security Pin Code.
 */
export enum EProtectedFeature {
  /**
   * @description No feature protected (0)
   */
  None = 0,
  /**
   * @description The ChangePassword feature (1)
   */
  ChangePassword = 1,
  /**
   * @description The ResetPassword feature (2)
   */
  ResetPassword = 2,
  /**
   * @description The update of the player's bank account information (3)
   */
  UpdatePlayerBankInfo = 3,
}
/**
 * @description Represents the SecurityPinCodeInfoStatus values.
 */
export enum GetSecurityPinCodeInfoStatus {
  /**
   * @description An internal server error occured (0)
   */
  InternalError = 0,
  /**
   * @description The request is success (1)
   */
  Successful = 1,
  /**
   * @description The specified room is not valid (2)
   */
  InvalidRoom = 2,
  /**
   * @description The specified player is not found (3)
   */
  InvalidPlayer = 3,
}
/**
 * @description Represents the values of the ResetSecurityPinCode response's status
 */
export enum EResetSecurityPinCodeStatus {
  /**
   * @description An unmanaged error occured (0)
   */
  InternalError = 0,
  /**
   * @description The Security Pin Code has been reset (1).
   */
  Successful = 1,
  /**
   * @description The specified player is not valid (2).
   */
  InvalidPlayer = 2,
  /**
   * @description The specified room is not valid (3).
   */
  InvalidRoom = 3,
  /**
   * @description The specified current Security Pin Code is not valid (4).
   */
  InvalidCurrentSecurityPinCode = 4,
  /**
   * @description The specified new Security Pin Code is missing and mandatory (5).
   */
  NewSecurityPinCodeMissing = 5,
  /**
   * @description The specified new Security Pin Code is too long (6).
   */
  NewSecurityPinCodeTooLong = 6,
  /**
   * @description The specified new Security Pin Code is too short (7).
   */
  NewSecurityPinCodeTooShort = 7,
  /**
   * @description The specified new Security Pin Code is not valid (8).
   */
  InvalidNewSecurityPinCode = 8,
}
export enum EChangeSecurityPinCodeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidRoom = 3,
  InvalidCurrentSecurityPinCode = 4,
  NewSecurityPinCodeMissing = 5,
  NewSecurityPinCodeTooLong = 6,
  NewSecurityPinCodeTooShort = 7,
  InvalidNewSecurityPinCode = 8,
}
export enum EDepositNextStepResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidDepositId = 2,
  NotImplemented = 3,
}
export enum EDepositStepActionType {
  Pending = 1,
  Script = 2,
  Done = 3,
}
export enum EProductType {
  None = 0,
  Bonus = 1,
  CasinoPackage = 2,
  Credit = 3,
  DepositPrize = 4,
  Freebet = 5,
  GameFeature = 6,
  Ptg = 7,
  PtgColombia = 8,
  Voucher = 9,
  InHouse = 10,
  ScratchCard = 11,
}

export enum EShopProductAvailableStatus {
  /**
   * @description An unmanaged error occurred (0)
   */
  InternalError = 0,
  /**
   * @description The Security Pin Code has been reset (1).
   */
  Successful = 1,
  /**
   * @description The specified room is not valid (3).
   */
  InvalidRoom = 2,
}

/**
 * @description Represents the GetPlayerTicketsStatus values.
 */
export enum GetAvailableTicketsStatus {
  /**
   * @description An internal server error occured (0)
   */
  InternalError = 0,
  /**
   * @description The request is success (1)
   */
  Successful = 1,
  /**
   * @description The specified room is not valid (2)
   */
  InvalidRoom = 2,
  /**
   * @description The specified player is not found (3)
   */
  InvalidPlayer = 3,
}
/**
 * @description Represents the GetPlayerTicketsStatus values.
 */
export enum GetPlayerTicketsStatus {
  /**
   * @description An internal server error occured (0)
   */
  InternalError = 0,
  /**
   * @description The request is success (1)
   */
  Successful = 1,
  /**
   * @description The specified room is not valid (2)
   */
  InvalidRoom = 2,
  /**
   * @description The specified player is not found (3)
   */
  InvalidPlayer = 3,
}
/**
 * @description Represents the GetPlayerVipRankInfo response status.
 */
export enum GetPlayerVipRankInfoStatus {
  /**
   * @description An unmanaged error occured (0)
   */
  InternalError = 0,
  /**
   * @description The Security Pin Code has been reset (1).
   */
  Successful = 1,
  /**
   * @description The specified player is not valid (2).
   */
  InvalidPlayer = 2,
  /**
   * @description The specified room is not valid (3).
   */
  InvalidRoom = 3,
}
/**
 * @description Represents the GetPlayerMonthlyBoxInfo response status.
 */
export enum GetPlayerMonthlyBoxInfoStatus {
  /**
   * @description An unmanaged error occured (0)
   */
  InternalError = 0,
  /**
   * @description The Security Pin Code has been reset (1).
   */
  Successful = 1,
  /**
   * @description The specified player is not valid (2).
   */
  InvalidPlayer = 2,
  /**
   * @description The specified room is not valid (3).
   */
  InvalidRoom = 3,
}
/**
 * @description Represents the GetPlayerWheelInfo response status.
 */
export enum GetPlayerWheelInfoStatus {
  /**
   * @description An unmanaged error occured (0)
   */
  InternalError = 0,
  /**
   * @description The Security Pin Code has been reset (1).
   */
  Successful = 1,
  /**
   * @description The specified player is not valid (2).
   */
  InvalidPlayer = 2,
  /**
   * @description The specified room is not valid (3).
   */
  InvalidRoom = 3,
}
export enum ETokenAuthenticationStatus {
  /**
   * @description Unexpected error occurred
   */
  InternalError = 0,
  /**
   * @description Authentication successful
   */
  Successful = 1,
  /**
   * @description Invalid Token : invalid parameter, not found, ...
   */
  InvalidToken = 2,
  /**
   * @description Token has expired
   */
  TokenExpired = 3,
  /**
   * @description Blocked By Iovation
   */
  BlockedByIovation = 4,
}
export enum EActorAuthenticationStatus {
  /**
   * @description Unexpected error occurred
   */
  InternalError = 0,
  /**
   * @description Authentication successful
   */
  Successful = 1,
  /**
   * @description Actor not found with provided informations
   */
  ActorNotFound = 2,
  /**
   * @description Actor found, but provided password is incorrect
   */
  IncorrectPassword = 3,
  /**
   * @description Actor is suspended
   */
  ActorSuspended = 4,
  /**
   * @description Actor account deleted
   */
  ActorDeleted = 5,
  /**
   * @description Authentication flood detected, receiver should temporize calls for provided actor
   */
  TooMuchAttempts = 6,
  /**
   * @description Login can not be null or empty
   */
  LoginMandatory = 8,
  /**
   * @description Password can not be null or empty
   */
  PasswordMandatory = 9,
  /**
   * @description Room parameter empty
   */
  InvalidRoom = 10,
  /**
   * @description Credentials correspond to pre-registration
   */
  RedirectToRegistration = 11,
  /**
   * @description Can be registred only in a room, not in site
   */
  RegistrationOnlyInRoom = 12,
  /**
   * @description The player has reached the connection number limit on the month (Circus CZ)
   */
  ConnectionNumberLimitReached = 13,
  /**
   * @description The player has reached their maximum connection time
   */
  ConnectionTimeLimitReached = 14,
  /**
   * @description The birthdate is mandatory
   */
  BadBirthDate = 15,
  /**
   * @description The player cannot be authenticated because it has been black listed.
   */
  Blacklisted = 16,
  /**
   * @description The player has a excludeUntil date
   */
  PlayerAutoExclude = 17,
  /**
   * @description the player has too many deposit refused
   */
  TooManyFailedDeposit = 18,
  /**
   * @description Player is suspended because located on the frozen assets list
   */
  FrozenAssetsExclude = 19,
  /**
   * @description Player close his account
   */
  CloseAccount = 20,
  /**
   * @description Player reactivated his account and is now in cool off
   */
  IsInCoolOffAfterAutoReactivation = 21,
  /**
   * @description Player has almost reached the number of allowed failed logins
   */
  AlmostReachedTooManyFailedLogin = 22,
}
export enum EAfterLoginStatus {
  PasswordContainsLogin = 0,
}
export enum EGetCreditStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ERequestFace2FaceValidationStatus {
  InternalError = 0,
  Successful = 1,
  AlreadyRequested = 2,
}
export enum EGetPlayerShortInfoStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EPlayerIdentityStatus {
  None = 0,
  IdentityTrusted = 10,
  WaitingForIdentification = 20,
  PendingDocuments = 21,
}
export enum EPlayerGovernmentIdentityStatus {
  None = 0,
  IdentityTrusted = 10,
}
export enum EPlayerAccountState {
  /**
   * @description Normal status for all accounts
   */
  Enabled = 0,
  /**
   * @description Player has requested the deletion of his/her account
   */
  Deleted = 1,
  /**
   * @description Account has been suspended by an administrator (message should be transmitted to the player )
   */
  Suspended = 2,
  None = 3,
}
export enum EPlayerAddressStatus {
  None = 0,
  WaitingValidation = 1,
  Trusted = 2,
  MissingData = 3,
  MissingAddress = 4,
}
export enum EDocumentStatus {
  NotApplicable = 0,
  Pending = 1,
  Ongoing = 2,
  Validated = 3,
  Expired = 4,
}
export enum EDocumentType {
  None = 0,
  IdentityCardRecto = 1,
  IdentityCardVerso = 2,
  Address = 4,
  BankStatement = 8,
  DepositMethod = 16,
  Passport = 32,
  LegalLimit = 64,
}
export enum EIdentityFieldDocument {
  None = 0,
  FullName = 1,
  DateOfBirth = 2,
  Ssn = 4,
  Address = 8,
}
export enum ERegistrationInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ECivility {
  Unknown = 0,
  Mister = 1,
  Mrs = 2,
  Miss = 3,
}
export enum ECreationSource {
  Unknown = 0,
  Gaming = 1,
  Betting = 2,
  ApplicationRegister = 3,
}
export enum ERegisterPlayerProperty {
  None = 0,
  EmployeePinCode = 1,
  Login = 2,
  Pseudo = 3,
  Password = 4,
  LastName = 5,
  FirstName = 6,
  Birthday = 7,
  BirthCountry = 8,
  BirthCity = 9,
  TaxIdNumber = 10,
  Profession = 11,
  AccountNumberIBAN = 12,
  FullName = 13,
  NationalIdentityNumber = 14,
  Address1 = 15,
  ZipCode = 16,
  City = 17,
  Country = 18,
  Province = 19,
  PhoneNumber = 20,
  Email = 21,
  RegistrationPromoCode = 22,
  Municipality = 23,
  IdentificationDocumentInformation = 24,
  AccountNumberBIC = 25,
  Address2 = 26,
  Civility = 27,
  Company = 28,
  PhoneNumber2 = 29,
  SecondLastName = 30,
  SecondFirstName = 31,
  ConditionalIdentificationDocumentInformation = 32,
  DataBoxIdentificationNumber = 33,
  LanguageIsoCode = 34,
  LegalDepositLimit = 35,
  PhoneNumberOrPhoneNumber2 = 36,
  Account = 37,
  HasHouseNumber = 38,
  SsnOrTin = 39,
  SecurityPinCode = 40,
  States = 41,
}
export enum EPlayerRegistratioStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  FieldError = 3,
  DuplicateAccount = 4,
  RegistrationBlocked = 5,
  GovernmentIdentityUnknown = 6,
  InvalidEventId = 7,
  InvalidLimitations = 8,
  InvalidAutomaticWidthdrawThreshold = 9,
}
export enum EFieldRegisterPlayerProperty {
  Valid = 0,
  Missing = 1,
  TooLong = 2,
  TooShort = 3,
  AlreadyUsed = 4,
  Denied = 5,
  Invalid = 6,
}
export enum EValidatePseudoStatus {
  InternalError = 0,
  InvalidRoom = 1,
  Ok = 2,
  AlreadyUsed = 3,
  TooShort = 4,
  TooLong = 5,
}
export enum EValidateEmailStatus {
  InternalError = 0,
  InvalidRoom = 1,
  Ok = 2,
  AlreadyUsed = 3,
  Forbidden = 4,
  Malformed = 5,
}
export enum ESendRegistrationMailStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  TooMuchAttempts = 3,
}
export enum EActivateAccountStatus {
  InternalError = 0,
  Successful = 1,
  InvalidActivationId = 2,
  AccountAlreadyActivated = 3,
}
/**
 * @description Value of status of response
 */
export enum EPasswordRecoveryStatus {
  /**
   * @description Internal Error
   */
  InternalError = 0,
  /**
   * @description Successful
   */
  Successful = 1,
  /**
   * @description Invalid Email
   */
  InvalidEmail = 2,
  /**
   * @description Invalid Room
   */
  InvalidRoom = 3,
  /**
   * @description Email null or empty
   */
  EmptyEmail = 4,
  /**
   * @description Email no exists
   */
  EmailNoExists = 5,
  /**
   * @description Player account is suspended
   */
  AccountSuspended = 6,
}
export enum EValidatePasswordRecoveryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRequest = 2,
}
export enum EResetPasswordStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRequest = 2,
  PasswordMismatch = 3,
  InvalidPassword = 4,
  InvalidSecurityPinCode = 5,
}
export enum EChangePasswordStatus {
  InternalError = 0,
  Successful = 1,
  BadOldPassword = 2,
  PasswordMismatch = 3,
  InvalidPassword = 4,
  InvalidSecurityPinCode = 5,
}
export enum EGetPlayerProfileStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidRoom = 3,
}
export enum EPhoneValidationStatus {
  NeverValidated = 0,
  DoNotValidate = 1,
  Validated = 1,
  NotValidated = 2,
}
export enum EPlayerProfileIdentityStatus {
  None = 0,
  IdentityTrusted = 10,
  WaitingForIdentification = 20,
  PendingDocuments = 21,
}
export enum EPlayerProfileGovernmentIdentityStatus {
  None = 0,
  IdentityTrusted = 10,
}
export enum EPlayerProfileProperty {
  None = 0,
  Email = 1,
  Civility = 2,
  LastName = 3,
  SecondLastName = 4,
  FirstName = 5,
  SecondFirstName = 6,
  FullName = 7,
  Birthday = 8,
  BirthCountry = 9,
  BirthCity = 10,
  Address = 11,
  CountryAddress = 12,
  PhoneNumber = 13,
  PhoneNumber2 = 14,
  Profession = 15,
  TaxIdNumber = 16,
  AccountNumberIBAN = 17,
  AccountNumberBIC = 18,
}
export enum EChangePlayerProfileStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  FieldError = 3,
  InvalidSecurityPinCode = 4,
}
export enum EGetPlayerSettingsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum PlayerSettingsProperties {
  LanguageIsoCode = 0,
  ReceiveEmailNotification = 1,
  ReceivePostNotification = 2,
  ReceivePhoneNotification = 3,
  ReceiveSMSNotification = 4,
  RefuseBonus = 5,
  AllowRemarketing = 6,
}
export enum EChagePlayerSettingsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  FieldError = 3,
}
export enum EGetAllCountriesStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetAllLicence {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidPlayer = 3,
}
export enum EChangeStateLicences {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidPlayer = 3,
  NotLegalAge = 4,
}
export enum EGetAllProvincesStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetMunicipalitiesStatus {
  InternalError = 0,
  Successful = 1,
}
/**
 * @description Value of Status
 */
export enum EGetStatesStatus {
  /**
   * @description Internal Error
   */
  InternalError = 0,
  /**
   * @description Successful
   */
  Successful = 1,
  /**
   * @description Feature State disable
   */
  StatesDisabled = 2,
  /**
   * @description InvalidRoom
   */
  InvalidRoom = 3,
}
export enum EReasonToUploadDocument {
  FullName = 1,
  DateOfBirth = 2,
  Ssn = 3,
  Address = 4,
}
export enum EDocumentToUpload {
  DriverLicense = 1,
  StateIdentificationCard = 2,
  MilitaryIdentificationCard = 3,
  Passport = 4,
  PropertyTaxReceipt = 5,
  UtilityBill = 6,
  AutoInsuranceCard = 7,
  FinancialDocument = 8,
  SsnCard = 9,
  W2Form = 10,
  Form1099 = 11,
}
export enum EUploadDocumentStatus {
  InternalError = 0,
  Successful = 1,
  UploadDocumentDenied = 2,
  InvalidPlayer = 3,
  TooMuchAttempts = 4,
}
export enum ECheckPlayerRealMoneyAllowedStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  BlackListed = 3,
  IncompleteRegistration = 4,
  RealMoneyForbiddenInCountry = 6,
  PlayerHasNotIdentityNumber = 7,
  TooYoungPlayer = 8,
  UnknownReason = 9,
  PlayerSelfExcluded = 10,
  RunningGame = 11,
  DailyWageLimitExceeded = 12,
  WeeklyWageLimitExceeded = 13,
  MonthlyWageLimitExceeded = 14,
  YearlyWageLimitExceeded = 15,
  DailyLossLimitExceeded = 16,
  WeeklyLossLimitExceeded = 17,
  MonthlyLossLimitExceeded = 18,
  YearlyLossLimitExceeded = 19,
  NotAuthenticatedPlayer = 20,
  PlayerHasNotAcceptedLicense = 21,
}
export enum EPlayerConnectionHistory {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EGetRegistrationFormInfo {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  RegistrationForbiddenCountry = 3,
}
export enum EGetRequiredDocTypesStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EAuthenticationDocument {
  IdCard = 0,
  CzechPoint = 1,
}
export enum EFrenchPlayerPendingAcksStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EFrenchPlayerRegisterAckStatus {
  InternalError = 0,
  PartiallySuccessful = 1,
  Successful = 2,
}
export enum EStatus {
  Successful = 0,
  WrongActorId = 1,
  InternalError = 2,
}
export enum SendPhoneValidationCodeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidRoom = 3,
  ServiceError = 4,
  NoPhone = 5,
  PhoneAlreadyValidated = 6,
  InvalidPhoneNumber = 7,
  PhoneValidationNotActivated = 8,
  PhoneNumberMultiAccount = 9,
  WaitAgain = 10,
}
export enum EValidatePhoneCodeResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidRoom = 3,
  BadValidationCode = 4,
  SuccessfulWithBonus = 5,
  PhoneValidationNotActivated = 6,
  PhoneNumberMultiAccount = 7,
}
export enum EPlayerPreregistrationStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  FieldError = 3,
  DuplicateAccount = 4,
}
export enum EGetPreregistrationStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  NotFound = 3,
}
export enum EGetHipayTppDepositInfoStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EMakeHipayTppDepositStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EBonusHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum ERedeemBonusCodeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidBonusCode = 3,
  CodeAlreadyRedeemed = 4,
}
export enum ECancelBonusMoneyStatus {
  Success = 0,
  InternalError = 1,
  RunningGame = 2,
}
export enum EActiveBonusStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EActiveBonusForPlayerStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EActiveBonusForPlayerOrRoomStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidRoom = 3,
  AuthenticationRequired = 4,
}
export enum ELiveNewsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ESponsorshipHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EGetSponsorshipInfosStatus {
  InternalError = 0,
  Allow = 1,
  Deny = 2,
}
export enum ENewSponsorshipStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  TooMuchAttempts = 3,
  AccountNotActivated = 4,
  Deny = 5,
}
export enum ENewSponsorshInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidEmailAddress = 2,
  EmailMatchExistingAccount = 3,
  EmailMatchExistingSponsorshipRequest = 4,
}
export enum EGetAllLanguagesStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ESiteType {
  MainSite = 0,
  AffiliatedSite = 1,
  Extranet = 2,
  Api = 3,
}
export enum EScope {
  Any = 0,
  UnsecuredZone = 1,
  SecuredZone = 2,
}
export enum EFaqStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ESlideShowStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ESlideShowScope {
  Any = 0,
  UnsecuredZone = 1,
  SecuredZone = 2,
}
export enum EScreenSize {
  Default = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
  XLarge = 4,
  XXLarge = 5,
}
export enum ESliderScreenSize {
  Default = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
  XLarge = 4,
  XXLarge = 5,
}
export enum ESliderStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ENewsScope {
  Any = 0,
  UnsecuredZone = 1,
  SecuredZone = 2,
  ForNonDepositor = 4,
  ForDepositor = 8,
}
export enum ESlideActionType {
  InternalURL = 0,
  GameLaunch = 1,
  OpenModal = 2,
  ExternalURL = 3,
  LoginAndRedirect = 4,
}
export enum EVirtualRoomListStatus {
  InternalError = 0,
  Successful = 1,
  VirtualRoomsNotFound = 2,
}
export enum EVirtualRoomGroupListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum GetSupportNodeResponseStatus {
  Success = 0,
  InternalError = 1,
}
export enum GetContactDataResponseStatus {
  Success = 0,
  InternalError = 1,
}
export enum ESendContactResponseStatus {
  Success = 0,
  InternalError = 1,
  MissingFields = 2,
}
export enum ENewsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EMail5YearsCircusStatus {
  InternalError = 0,
  FieldError = 1,
  Successful = 2,
}
export enum EMailBowlingStatus {
  InternalError = 0,
  FieldError = 1,
  Successful = 2,
}
export enum ESendMailPackageReservationStatus {
  InternalError = 0,
  Successful = 1,
}
export enum MailAgendaEventRegistrationStatus {
  InternalError = 0,
  FieldError = 1,
  Successful = 2,
}
export enum EMailStatus {
  InternalError = 0,
  FieldError = 1,
  Successful = 2,
}
export enum EGoogleTagManagerStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGetAnytimeStatusStatus {
  InternalError = 0,
  Successful = 1,
  Unauthorized = 2,
  AlreadyOrdered = 3,
  Authorized = 4,
}
export enum OrderAnytimeCardResponseStatus {
  InternalError = 0,
  Successful = 1,
  Unauthorized = 2,
  AlreadyOrdered = 3,
}
export enum GetPromotionsResponseStatus {
  Success = 0,
  InternalError = 1,
  BonusTypeNotSupported = 2,
}
export enum EPromotionType {
  Bonus = 0,
  MarketingAction = 1,
  Tournament = 2,
  Link = 3,
}
export enum EPromotionCategory {
  None = 0,
  Gaming = 1,
  Betting = 2,
  General = 3,
}
export enum EBonusType {
  Inscription = 100,
  InscriptionDirectCash = 101,
  FirstDeposit = 200,
  Deposit = 201,
  DepositRange = 210,
  Sponsorship = 300,
  Event = 400,
  Gift = 500,
  Manual = 600,
  Club = 700,
  Crm = 800,
  CrmConnectionDirectCash = 801,
  CrmLoss = 802,
  CrmBirthday = 809,
  TournamentPrize = 810,
  DirectCash = 900,
  ConnectionDirectCash = 901,
  ConnectionMobileDirectCash = 902,
  InRoom = 1000,
  BettingFirstBet = 1100,
  BettingBet = 1101,
  VirtualBettingBet = 1201,
}
export enum EAdjustmentHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EAdjustmentType {
  None = 0,
  GameProblem = 1,
  TransferRefused = 2,
  ManualDeposit = 3,
  TestCredit = 4,
  Other = 5,
}
export enum EMakeNetellerDepositStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InvalidWallet = 3,
  Declined = 6,
  ErrorOnDeposit = 7,
}
export enum EGetMarketingActionInfosStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  MarketingActionNotFound = 3,
  MarketingActionNotRunning = 4,
  OnlyForNewAccounts = 5,
  MarketingActionNotAvailable = 6,
}
export enum EMarketingActionParticipateStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  MarketingActionNotFound = 3,
  MarketingActionNotRunning = 4,
  AlreadyParticipated = 5,
  OnlyNewAccounts = 6,
  MarketingActionNotAvailable = 7,
}
export enum EMarketingActionScratchCardParticipateStatus {
  InternalError = 0,
  Won = 1,
  Lost = 2,
  MonthlyVouchersLimitReached = 3,
}
export enum EScratchCardPrizeType {
  None = 0,
  Unknow = 1,
  Voucher = 2,
}
export enum EGetAllMarketingActionProspectsStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetMarketingActionPrizesStatus {
  InternalError = 0,
  Successful = 1,
  InvalidEventId = 2,
  InvalidRoom = 3,
}
export enum ETermsAndConditionsResponseStatus {
  InternalError = 0,
  Successful = 1,
  FileNotFound = 2,
}
export enum EPlayerAcceptTermsAndConditionsResponseStatus {
  InternalError = 0,
  Successful = 1,
  MissingField = 2,
}
export enum EPrivacyConditionsResponseStatus {
  InternalError = 0,
  Successful = 1,
  FileNotFound = 2,
}
export enum EShowPrivacyConditions {
  None = 0,
  Successful = 1,
  InternalError = 2,
  PlayerNotFound = 3,
}
export enum EPlayerAcceptPrivacyConditionsResponseStatus {
  InternalError = 0,
  Successful = 1,
}
export enum TicketTypeCode {
  InternalError = 0,
  Success = 1,
}
export enum EUnlockedTicketStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  NoTicketFound = 3,
}
export enum ETicketListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EPlayerTicketStatus {
  /**
   * @description The ticket has a pending condition to be unlocked (for instance, stake progression)
   */
  Locked = 0,
  /**
   * @description The ticket has been unlocked and can be used
   */
  Unlocked = 1,
  /**
   * @description The ticket has been used
   */
  Used = 2,
  /**
   * @description The ticket validity has expired
   */
  Obsolete = 3,
}
/**
 * @description Represents a set of response codes sent by the metadata service.
 */
export enum MetaResponseCode {
  ServerError = 0,
  Success = 1,
  Unathorized = 2,
  BadRequest = 3,
  NotImplemented = 4,
}
export enum EGetURLRedirectExternalActivityResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidPlayer = 3,
  ExternalActivityNotFoundOrDisabled = 4,
  SessionNotCreated = 5,
  WebsiteURLParameterNotFound = 6,
}
export enum EGetNotificationCountStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetNotificationsStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetUnseenNotificationCountStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetUnseenNotificationsStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ESetNotificationsSeenStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ESetAllNotificationsSeenStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EOnNewNotificationStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EGetNotificationCategoriesStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ESetNotificationCategoryStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ENotificationRegistrationStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EParticipateQuizResponseStatus {
  Success = 0,
  AlreadyParticipated = 1,
  InternalError = 2,
}
export enum ECanParticipateQuizResponseStatus {
  Can = 0,
  Cannot = 1,
  InternalError = 2,
}
export enum EGetVouchersStatus {
  InternalError = 0,
  Successfull = 1,
}
export enum EVoucherStatus {
  /**
   * @description The voucher is valid and can be redeemed by the player at any time
   */
  Valid = 0,
  /**
   * @description The voucher has already been redeemed by the player. It cannot be redeemed anymore
   */
  Redeemed = 1,
  /**
   * @description The voucher is outdated, and cannot be redeemed anymore
   */
  Expired = 2,
  /**
   * @description The voucher has been cancelled by the player or anyone else, and cannot be redeemed anymore
   */
  Cancelled = 3,
}
export enum EGetVouchersLimitationStatus {
  InternalError = 0,
  Successful = 1,
}
export enum ERedeemCodeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidCode = 2,
  AlreadyUsed = 3,
  AlreadyUsedByPlayer = 4,
}
export enum AgendaEventState {
  CanSubscribe = 0,
  CanNotSubscibe = 1,
  CanNoLongerSubscribe = 2,
}
export enum TypeSubscriptionForm {
  None = 0,
  NormalForm = 1,
  BilletWebForm = 2,
}
export enum GetAgendaEventsResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidQuery = 2,
}
export enum GetLandbasedJackpotsStatus {
  InternalError = 0,
  Successful = 2,
  InvalidVirtualRoom = 3,
  NoServiceName = 4,
}
export enum ESynchronizeInformationStatus {
  Exist = 0,
  NotExist = 1,
  InvalidRoom = 2,
  DataMissmatch = 3,
  InternalError = 4,
  Suspended = 5,
  Deleted = 6,
}
export enum EContactHelpdeskStatus {
  Successful = 0,
  InternalError = 1,
  InvalidRoom = 2,
  InvalidLogin = 3,
  InvalidMail = 4,
  InvalidPhone = 5,
  InvalidNationalIdentityNumber = 6,
  InvalidFirstName = 7,
  InvalidLastName = 8,
  InvalidBirthday = 9,
  InvalidBirthCity = 10,
  InvalidMailEmploye = 11,
}
export enum EUpdateInformationResponseStatus {
  Successful = 0,
  InternalError = 1,
  InvalidRoom = 2,
  InvalidLogin = 3,
  InvalidMail = 4,
  MailAlreadyUsed = 5,
  MailDenied = 6,
  InvalidAccountNumber = 7,
  InvalidPhone = 8,
  InvalidPhone2 = 9,
  InvalidBirthCity = 10,
  InvalidAddress = 11,
  Suspended = 12,
  Deleted = 13,
  InvalidCountry = 14,
}
export enum EAuthenticateStatus {
  Successful = 0,
  InternalError = 1,
  InvalidRoom = 2,
  InvalidLogin = 3,
  InvalidMail = 4,
  InvalidPhone = 5,
  InvalidNationalIdentityNumber = 6,
  InvalidFirstName = 7,
  InvalidLastName = 8,
  InvalidBirthday = 9,
  InvalidBirthCity = 10,
  InvalidAddress = 11,
  InvalidPhoto = 12,
}
export enum EGetSponsorshipCodesStatus {
  Successful = 0,
  InternalError = 1,
  InvalidRoom = 2,
}
export enum EGetBlogNewsStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EDocumentStatusResponse {
  None = 0,
  Successful = 1,
  InternalError = 2,
  InvalidRoom = 3,
}
/**
 * @description Please sync this enum with  enum.
 */
export enum GetTokenRegistrationStatutes {
  None = 0,
  InternalError = 1,
  Successful = 2,
  ProviderError = 3,
  RegistrationProviderNotFound = 4,
  InvalidRoom = 5,
}
/**
 * @description Please sync this enum with  enum.
 */
export enum GetPlayerInfoStatutes {
  None = 0,
  InternalError = 1,
  Successful = 2,
  InvalidRoom = 3,
  TransactionNotFound = 4,
  RegistrationProviderNotFound = 5,
  ProviderError = 6,
}
/**
 * @description Enumeration for response status
 */
export enum EThirdPartyPrizeRetributionHistoryStatus {
  /**
   * @description InternalError
   */
  InternalError = 0,
  /**
   * @description Successful
   */
  Successful = 1,
  /**
   * @description InvalidPlayer
   */
  InvalidPlayer = 2,
}
export enum EVipHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EVipPrizesResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  BadConfiguration = 3,
}
export enum EBuyVipPrizeResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InsufficientPoints = 3,
  BadConfiguration = 4,
}
export enum EGetVipInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EGetVipRanksStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EBuyVipBonusStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  InsufficientPoints = 3,
}
export enum ECoinsHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EGetVipInitializationStatus {
  InternalError = 0,
  Successful = 1,
  RoomNotFound = 2,
  PlayerNotFound = 3,
  WrongRoomForPlayer = 4,
}
export enum EPlayVipStatus {
  InternalError = 0,
  Successful = 1,
  AlreadyPlayed = 2,
  InvalidWheelId = 3,
  PlayerNoFound = 4,
}
export enum EPlayMonthlyBoxStatus {
  InternalError = 0,
  Successful = 1,
  AlreadyPlayed = 2,
  NoMarketingAction = 3,
  RoomNotFound = 4,
  PlayerNotFound = 5,
  WrongRoomForPlayer = 6,
}
/**
 * @description Origin of a status reset request
 */
export enum EResetStatusOrigin {
  /**
   * @description Unknwon origin
   */
  Unknown = 0,
  /**
   * @description Request sent from the Extranet
   */
  Extranet = 1,
  /**
   * @description Request sent from the website
   */
  Website = 2,
}
/**
 * @description Status of a VIP Rank Reset Response
 */
export enum EVipRankResetStatus {
  Ongoing = 0,
  Done = 1,
  NotDoneRankNotHighEnough = 2,
  NotDoneWheelsPending = 3,
  PlayerUnknown = 4,
  ErrorWithWheelsHistorization = 5,
  RoomNotFound = 6,
  MarketingActionsNotFound = 7,
  ParticipationsNotFound = 8,
  ErrorWithStatusHistorization = 9,
  InternalError = 10,
  NotEnabled = 999,
}
/**
 * @description Status of a VIP Rank Reset Response
 */
export enum EVipStatusResetAllowed {
  Allowed = 0,
  NotAllowedInRoom = 10,
  NotAllowedRankNotHighEnough = 20,
  NotAllowedWheelsPending = 30,
  NotAllowedByConfiguration = 40,
  NotAllowedInternalError = 100,
}
/**
 * @description Enum with all possible VIP-related features
 */
export enum EVipFeatures {
  None = 0,
  ResetStatus = 1,
}
export enum GetContestGameResponseStatus {
  Unknown = 0,
  Soon = 1,
  InProgress = 2,
  Completed = 3,
  InvalidField = 97,
  NotFound = 98,
  InternalError = 99,
}
export enum SetParticipationResponseStatus {
  Ok = 0,
  AlreadyParticipated = 1,
  ContestGameCompleted = 2,
  InvalidField = 98,
  InternalError = 99,
}
export enum EGetPlayerGameFeaturesStatus {
  Unknown = 0,
  InternalError = 1,
  Successful = 2,
  GameNotFound = 3,
  PlayerNotAuthenticate = 4,
  PlayerNotFound = 5,
}
export enum EDeleteGameFeature {
  Unknown = 0,
  InternalError = 1,
  Successful = 2,
  GameFeatureNotFound = 3,
}
export enum EGetGameFeatureHistory {
  Unknown = 0,
  InternalError = 1,
  Successful = 2,
  PlayerNotAuthenticate = 3,
  PlayerNotFound = 4,
}
export enum EGameFeaturePlayerHistory {
  Created = 0,
  Validated = 1,
  Finished = 2,
  Canceled = 3,
}
/**
 * @description Represents a set of response codes sent by the shop.
 */
export enum ShopResponseCode {
  ServerError = 0,
  Success = 1,
  Unathorized = 2,
  BadRequest = 3,
  NotImplemented = 4,
  CannotAfford = 5,
  InvalidShop = 6,
  InvalidUser = 7,
  InvalidPrice = 8,
  InvalidQuery = 9,
  InvalidVipRank = 10,
  InvalidUserTrust = 11,
  OutOfStockRefunded = 12,
  InvalidAddress = 13,
  InvalidZipCode = 14,
  InvalidCity = 15,
  InvalidCountryIsoCode = 16,
  InvalidMunicipality = 17,
  InvalidDepartment = 18,
  InvalidVirtualRoom = 19,
  PlayerRefuseBonus = 20,
  PlayerBonusNotAllowed = 21,
  PlayerLimitedBetting = 22,
}
export enum EProductCategory {
  Default = 0,
  VoucherCode = 1,
  Freebet = 2,
  GameFeature = 3,
  Bonus = 4,
  Item = 5,
  Credit = 6,
  DepositPrize = 7,
  CasinoPackage = 8,
  ScratchCard = 9,
}
/**
 * @description The order state, mirrorred from the DAL with some changes.
 */
export enum OrderStatus {
  /**
   * @description The order have failed
   */
  Failed = 0,
  /**
   * @description The order is created, but not yet paid.
   */
  PendingPayment = 1,
  /**
   * @description The payment was completed, but the order was not fully delivered yet.
   */
  PendingDelivery = 2,
  /**
   * @description The order is completed.
   */
  Delivered = 3,
  /**
   * @description The order was rejected
   */
  Rejected = 4,
}
/**
 * @deprecated Use NewShopProductType instead
 * @description Represents the product type
 */
export enum ShopProductType {
  /**
   * @description Unknown product type.
   */
  Unknown = 0,
  /**
   * @description The product is tangible and should be shipped.
   */
  Tangible = 1,
  /**
   * @description The product is virtual.
   */
  Virtual = 2,
}
export enum NewShopProductType {
  /**
   * @description Unknown product type.
   */
  Unknown = 0,
  /**
   * @description The product is tangible and should be shipped.
   */
  Physical = 1,
  /**
   * @description The product is virtual, but internal (Bonus, Freebet).
   */
  VirtualInternal = 2,
  /**
   * @description The product is virtual, but external and need be shipped by mail
   */
  VirtualExternal = 3,
}
export enum ShopSortField {
  Popular = 0,
  Name = 1,
  Price = 2,
  Date = 3,
}
export enum ShopSortDirection {
  Ascending = 0,
  Descending = 1,
}
/**
 * @description Represents the price type.
 */
export enum PriceType {
  Invalid = 0,
  RealMoney = 1,
  VipMoney = 2,
  VipTicket = 4,
  Dynamic = 5,
}

export enum EScriptInjectionType {
  FraudnetPaypal = 0,
  TrustlyWidget = 1,
  TrustlyWidgetInit = 2,
}

export enum EGetDepositWalletsListInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  PlayerBlackListed = 3,
  PlayerHasNotMinimumLegalAge = 4,
  PlayerDepositLimitReached = 5,
  PlayerHasPendingWithdraw = 6,
  RealMoneyDisabled = 7,
  AccountActivationRequired = 8,
  PlayerAutoExcluded = 9,
  ErrorMajor = 10,
  PlayerWaitingForIdentification = 11,
  Disabled = 12,
  FrontNotCompatible = 13,
}

export enum EGetDepositWalletInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
  PlayerBlackListed = 3,
  PlayerHasNotMinimumLegalAge = 4,
  PlayerDepositLimitReached = 5,
  PlayerHasPendingWithdraw = 6,
  RealMoneyDisabled = 7,
  AccountActivationRequired = 8,
  PlayerAutoExcluded = 9,
  ErrorMajor = 10,
  PlayerWaitingForIdentification = 11,
  Disabled = 12,
  NotFound = 13,
  Restricted = 14,
  UserAgentNotCompatible = 15,
}

/**
 * @description Represents the shop purchase response statuses.
 */
export enum EShopPurchaseResponseStatus {
  /**
   * @description The Internal Error status - An unexpected internal error occured (0)
   */
  InternalError = 0,
  /**
   * @description The success status - The request was successful (1)
   */
  Success = 1,
  /**
   * @description The invalid player status (2)
   */
  InvalidPlayer = 2,
  /**
   * @description The invalid room status -The specified room is not a valid one (3)
   */
  InvalidRoom = 3,
  /**
   * @description The invalid shop status - The specified shop is not a valid one (4)
   */
  InvalidShop = 4,
  /**
   * @description The invalid delivery information (5) -
   *
   * The specified delivery information is not valid.
   *
   * See EmailError or AddressError or PhoneNumberError or property for details.
   */
  InvalidDeliveryInformation = 5,
  /**
   * @description The player can not use the specified delivery (6)
   */
  CanNotUseDelivery = 6,
  /**
   * @description The shop payment error (7) -
   *
   * An error linked to the shop payment occured.
   *
   * See PaymentError property for details.
   */
  PaymentError = 7,
  /**
   * @description The delivery error - This error is due to a provider error (8)
   */
  DeliveryError = 8,
}

/**
 * @description Represents the address delivery information errors.
 */
export enum AddressDeliveryInformationErrors {
  /**
   * @description None - No error (0)
   */
  None = 0,
  /**
   * @description The Missing error - Address is missing but mandatory (1)
   */
  Missing = 1,
}

/**
 * @description Represents the InRoom delivery information errors.
 */
export enum InRoomDeliveryInformationErrors {
  /**
   * @description None - No error (0)
   */
  None = 0,
  /**
   * @description The Missing error - InRoom identifier is missing but mandatory (1)
   */
  Missing = 1,
  /**
   * @description The invalid InRoom error - Specified InRoom is invalid (2)
   */
  InvalidInRoom = 2,
}
/**
 * @description List of player segments
 */
export enum PlayerSegments {
  /**
   * @description The new segment id dedicated to a player who has just sign up
   */
  New = 0,
  /**
   * @description The active segment is dedicated to a player who has 'Enabled' as account state and has never been suspended in the past
   */
  Active = 1,
  /**
   * @description The inactive segment is dedicated to a player who has an account state different of 'Enabled
   */
  Inactive = 2,
  /**
   * @description The reactivated segment is dedicated to a player who has 'Enabled' as account state and has been suspended in the past
   */
  Reactivated = 3,
}
/**
 * @description Represents the shop payment errors.
 */
export enum ShopPaymentError {
  /**
   * @description There is no error (0)
   */
  None = 0,
  /**
   * @description Player Cannot Afford (1)
   */
  PlayerCannotAfford = 1,
  /**
   * @description Player does not reached the needed VIP rank (2)
   */
  InvalidVipRank = 2,
  /**
   * @description An unexpected error occurred (3)
   */
  Unexpected = 3,
}

/**
 * @description Represents the phone number delivery information errors.
 */
export enum PhoneNumberDeliveryInformationErrors {
  /**
   * @description None - No error (0)
   */
  None = 0,
  /**
   * @description The Missing error - Phone number is missing but mandatory (1)
   */
  Missing = 1,
  /**
   * @description The invalid format error - Specified phone number has invalid format (2)
   */
  InvalidFormat = 2,
}

/**
 * @description Represents the email delivery information errors.
 */
export enum EmailDeliveryInformationErrors {
  /**
   * @description None - No error (0)
   */
  None = 0,
  /**
   * @description The Missing error - Email is missing but mandatory (1)
   */
  Missing = 1,
  /**
   * @description The invalid format error - Specified email has invalid format (2)
   */
  InvalidFormat = 2,
}

/**
 * @description Represents the GetPrizeInfo response status.
 */
export enum GetPrizeInfoStatus {
  /**
   * @description An unmanaged error occured (0)
   */
  InternalError = 0,
  /**
   * @description The Security Pin Code has been reset (1).
   */
  Successful = 1,
  /**
   * @description The specified player is not valid (2).
   */
  InvalidPlayer = 2,
  /**
   * @description The specified room is not valid (3).
   */
  InvalidRoom = 3,
}

export enum EScratchCardConsumeResponseStatus {
  Ok = 0,
}

/**
 * @description Represents the shop purchase response statuses.
 */
export enum EShopScratchCardPurchaseResponseStatus {
  /**
   * @description The Internal Error status - An unexpected internal error occured (0)
   */
  InternalError = 0,
  /**
   * @description The success status - The request was successful (1)
   */
  Success = 1,
  /**
   * @description The shop payment error (7) -
   *
   * An error linked to the shop payment occured.
   *
   * See PaymentError property for details.
   */
  PaymentError = 7,
  /**
   * @description The delivery error - This error is due to a provider error (8)
   */
  DeliveryError = 8,
  NotAllowed = 9,
}

export enum ENotAllowedReason {
  Exclusion = 0,
  Untrusted = 1,
}

/**
 * @description Represents the needed personal info to order values.
 */
export enum ENeededPersonalInfoToOrder {
  /**
   * @description The email address is needed to order product.
   */
  Email = 1,
  /**
   * @description Postal address is needed to order product.
   */
  Address = 2,
  /**
   * @description Phone number is needed to order product.
   */
  PhoneNumber = 3,
}
