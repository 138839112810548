import { ApplicationState } from '../types';

export const blackBoxesSelector = (state: ApplicationState) =>
  state.core.tracking.blackBoxes;

export const getGoogleTagManagerRequestStateSelector = (
  state: ApplicationState,
) => state.core.tracking.requests.getGoogleTagManager;

export const googleTagManagerTrackingPlacedRequestStateSelector = (
  state: ApplicationState,
) => state.core.tracking.requests.googleTagManagerTrackingPlaced;

export const gtmEventsSelector = (state: ApplicationState) =>
  state.core.tracking.gtmEvents;
