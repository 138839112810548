import React, { FC, memo, useContext } from 'react';
import { shallowEqual } from 'react-redux';

import {
  placeBettingSlipNotificationGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

const BettingSlipPlaceBettingSlipErrorComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const notification = useBettingGetterSelector({
    getterSelector: placeBettingSlipNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  if (!notification) {
    return null;
  }

  return (
    <Box mb="sm" data-testid="bettingslip-placebet-error-box">
      <BettingSlipMessage
        testId={`bettingslip-placebettingslip-message-${notification.Code}`}
        notification={notification}
      />
    </Box>
  );
};

export const BettingSlipPlaceBettingSlipError = memo(
  BettingSlipPlaceBettingSlipErrorComponent,
);
