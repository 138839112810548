import React, { FC, useContext } from 'react';

import { AppLink, Heading, RadioInput, Select } from '@gaming1/g1-ui';

import { ThemeName } from '../../themes';
import { CustomThemesContext } from '../CustomThemesProvider/CustomThemesContext';
import { SwitchContext } from '../SwitchProvider/contexts';

export const ThemeSwitch: FC = () => {
  const [switchState, switchDispatch] = useContext(SwitchContext);
  const { themes: customThemes } = useContext(CustomThemesContext);

  const handleThemeSwitch = (themeName: ThemeName) => {
    switchDispatch({
      type: 'switch/theme',
      payload: themeName,
    });

    switchDispatch({
      type: 'switch/custom_theme',
      payload: null,
    });
  };

  const handleCustomThemeSwitch = (themeName: string) =>
    switchDispatch({
      type: 'switch/custom_theme',
      payload: themeName,
    });

  return (
    <>
      <Heading level="h4">Theme</Heading>
      {Object.values(ThemeName).map((name) => (
        <RadioInput
          checked={!switchState.customTheme && name === switchState.theme}
          key={name}
          label={name}
          onCheck={() => handleThemeSwitch(name)}
          testId="devtoolmenu-theme-radio"
        />
      ))}
      {!!customThemes.length && !!customThemes[0].name && (
        <RadioInput
          checked={!!switchState.customTheme}
          label="Custom"
          onCheck={() => handleCustomThemeSwitch(customThemes[0].name)}
        />
      )}
      <p>
        <AppLink to="/theme">Edit themes</AppLink>
      </p>
      {!!switchState.customTheme && (
        <Select
          options={customThemes.map(({ name }) => ({
            value: name,
            label: name,
          }))}
          simple
          value={switchState.customTheme}
          onValueChange={(themeName) => handleCustomThemeSwitch(themeName)}
        />
      )}
    </>
  );
};
