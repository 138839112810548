import React, { VFC } from 'react';

import { PendingGamesBanner } from '../../../pendingGames/components/PendingGamesBanner';
import { GameLauncherRequestForwarder } from '../../../startGame/components/GameLauncherRequestForwarder/GameLauncherRequestForwarder';

/**
 * This component is supposed to be rendered in the "AppLayout" of the partner
 * app
 *
 * It will render (depending on the configuration)
 *
 * - The PendingGamesBanner that warns the user that he has games that should be
 *   finished
 */
export const GamingWatchers: VFC = () => (
  <>
    <PendingGamesBanner />
    <GameLauncherRequestForwarder />
  </>
);
