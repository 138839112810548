import React, { FC, memo, useCallback } from 'react';

import { InferStyledTypes } from '@gaming1/g1-core-web';
import { Icon } from '@gaming1/g1-ui';

import { LateralLiveMenu } from '../LateralLiveMenu';
import { LateralMenu } from '../LateralMenu';

import { CollapsibleContainer, CollapsibleContainerIcon } from './styles';

type CollapsibleMenuProps = InferStyledTypes<typeof CollapsibleContainer>;

export const COLLAPSIBLE_MENU_WIDTH = 260;

/**
 * This component renders a menu that could be collapsed to only show icons
 */
const CollapsibleMenuComponent: FC<
  CollapsibleMenuProps & {
    isMenuVisible: boolean;
    toggleMenu: (shouldOpen: boolean) => void;
    showLivesOnly?: boolean;
  }
> = ({
  isCollapsed,
  hasTouchCapabilities,
  showLivesOnly = false,
  toggleMenu,
  isMenuVisible,
  ...props
}) => {
  const onClick = useCallback(
    () => toggleMenu(!isMenuVisible),
    [toggleMenu, isMenuVisible],
  );

  const onMouseEnter = useCallback(() => {
    if (!hasTouchCapabilities) {
      toggleMenu(true);
    }
  }, [toggleMenu, hasTouchCapabilities]);

  const onMouseLeave = useCallback(() => toggleMenu(false), [toggleMenu]);

  const onLateralMenuClick = useCallback(() => {
    if (isCollapsed) {
      toggleMenu(true);
    }
  }, [isCollapsed, toggleMenu]);

  return (
    <CollapsibleContainer
      width={isCollapsed && !isMenuVisible ? 'auto' : COLLAPSIBLE_MENU_WIDTH}
      height="100%"
      overflowY="auto"
      data-testid="collapsible-menu"
      isCollapsed={isCollapsed}
      hasTouchCapabilities={hasTouchCapabilities}
      onClick={onLateralMenuClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {isMenuVisible && !props.isDesktop && hasTouchCapabilities && (
        <CollapsibleContainerIcon onClick={onClick}>
          <Icon
            id="collapsible-menu-icon"
            testId="collapsible-menu-icon"
            type="PanelOpen"
          />
        </CollapsibleContainerIcon>
      )}
      {showLivesOnly ? (
        <LateralLiveMenu isCollapsed={isCollapsed} />
      ) : (
        <LateralMenu isCollapsed={isCollapsed} />
      )}
    </CollapsibleContainer>
  );
};

export const CollapsibleMenu = memo(CollapsibleMenuComponent);
