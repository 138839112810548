import React, { VFC } from 'react';
import { useTheme } from 'styled-components';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { Box, useMediaBreakPoint } from '@gaming1/g1-ui';

import { BOTTOM_NAV_HEIGHT } from '../../../styles';

import { FooterBackgroundLevels } from './styles';

type SafeAreaProps = {
  /** Backgrounds with three different colors options (default: 0) */
  backgroundLevel?: FooterBackgroundLevels;
  /** Safe area height in px (default: BOTTOM_NAV_HEIGHT * 2) */
  height?: number;
  /** Allow override of default display conditions */
  shouldRenderSafeArea?: boolean;
};

/**
 * This component will define a space based on the height of the
 * bottom navigation. When positioned at the bottom of the footer, it will
 * prevent floating objects from overlapping content that should never be hidden
 * (legal text content...).
 */
export const SafeArea: VFC<SafeAreaProps> = ({
  backgroundLevel = 0,
  height = BOTTOM_NAV_HEIGHT * 2,
  shouldRenderSafeArea,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaBreakPoint({ min: 'lg' });

  const defaultShouldRenderSafeArea = getWrapperEnv() !== 'rn' && !isDesktop;

  const isRendered =
    shouldRenderSafeArea !== undefined
      ? shouldRenderSafeArea
      : defaultShouldRenderSafeArea;

  return isRendered ? (
    <Box
      backgroundColor={theme.colors.footerBackground[backgroundLevel]}
      data-testid="footer-safe-area"
      height={`${height}px`}
    />
  ) : null;
};
