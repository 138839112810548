import { produce } from 'immer';
import uniq from 'lodash/uniq';
import { getType } from 'typesafe-actions';

import { actions as coreActions, CoreActions } from '@gaming1/g1-core';

import * as actions from '../../../store/actions';
import { BettingActions } from '../../../store/types';
import {
  initializeEvent,
  initializeMarket,
  initializeOutcome,
} from '../helpers';
import { NormalizedEvent, NormalizedLeague, SportbookState } from '../types';

export const initialEntitiesState: SportbookState['entities'] = {
  events: {},
  leagues: {},
  markets: {},
  outcomes: {},
  playerProps: {},
  sports: {},
};

type EventEntity = { [eventId: string]: NormalizedEvent };
type LeagueEntity = { [leagueId: string]: NormalizedLeague };

/** Set the received markets from the back in the appropriate array of markets */
const getMergedEvents = (
  store: EventEntity = {},
  newEntities: EventEntity = {},
  isFullEvent: boolean,
) => ({
  ...store,
  ...Object.entries(newEntities).reduce(
    (acc, [id, event]) => ({
      ...acc,
      [id]: {
        ...event,
        Markets: isFullEvent ? store[id]?.Markets : event?.Markets,
        FullMarkets: !isFullEvent ? store[id]?.FullMarkets : event?.Markets,
      },
    }),
    {},
  ),
});

/** Add the received event id to the league if not yet present */
const getMergedLeagues = (
  store: LeagueEntity = {},
  newEntities: LeagueEntity = {},
) => ({
  ...store,
  ...Object.entries(newEntities).reduce((acc, [id, league]) => {
    // Prevents Order to be reset to zero when already set
    if (store[id]?.Order !== undefined) {
      league.Order = store[id].Order;
    }

    return {
      ...acc,
      [id]: {
        ...league,
        Events: uniq([...(store[id]?.Events || []), ...(league?.Events || [])]),
      },
    };
  }, {}),
});

export const sportbookEntitiesReducer = (
  state = initialEntitiesState,
  action: BettingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getLives.success):
      case getType(actions.getPrematchSport.success):
      case getType(actions.getLiveSport.success):
      case getType(actions.getTopEvents.success):
      case getType(actions.fullSearch.success):
      case getType(actions.getLeague.success):
      case getType(actions.getRegion.success): {
        const { entities } = action.payload;

        draftState.events = getMergedEvents(
          draftState.events,
          entities.events,
          false,
        );
        draftState.leagues = {
          ...draftState.leagues,
          ...entities.leagues,
        };
        draftState.markets = {
          ...draftState.markets,
          ...entities.markets,
        };
        draftState.outcomes = {
          ...draftState.outcomes,
          ...entities.outcomes,
        };
        draftState.playerProps = {
          ...draftState.playerProps,
          ...entities.playerProps,
        };
        break;
      }

      case getType(actions.getEvent.success): {
        const { entities } = action.payload;

        draftState.leagues = getMergedLeagues(
          draftState.leagues,
          entities.leagues,
        );
        draftState.events = getMergedEvents(
          draftState.events,
          entities.events,
          true,
        );
        draftState.markets = {
          ...draftState.markets,
          ...entities.markets,
        };
        draftState.outcomes = {
          ...draftState.outcomes,
          ...entities.outcomes,
        };
        draftState.playerProps = {
          ...draftState.playerProps,
          ...entities.playerProps,
        };

        break;
      }

      case getType(actions.getSports.success): {
        const { entities } = action.payload;

        draftState.sports = {
          ...draftState.sports,
          ...entities.sports,
        };

        break;
      }

      /* PUSH UPDATES */

      /* REMOVALS */

      case getType(actions.removeLeague): {
        const { leagueId } = action.payload;
        if (draftState.leagues[leagueId]) {
          draftState.leagues[leagueId].deleted = true;
        }
        break;
      }

      case getType(actions.removeEvent): {
        const { eventId, leagueId } = action.payload;
        if (draftState.events[eventId]) {
          draftState.events[eventId].deleted = true;
        }

        const league = draftState.leagues[leagueId];
        if (league?.Events) {
          league.Events = league.Events?.filter((id) => id !== eventId);
        }
        break;
      }

      case getType(actions.removePlayerProp): {
        const { playerPropId, eventId } = action.payload;

        const event = draftState.events[eventId];
        const pProps = event?.PlayerProps;
        if (pProps?.length) {
          event.PlayerProps = pProps?.filter((id) => id !== playerPropId);
        }
        break;
      }

      case getType(actions.removeMarket): {
        const { marketId, eventId, isFullEvent } = action.payload;

        // Because of showcase markets, trust only removals on full events
        if (isFullEvent && draftState.markets[marketId]) {
          draftState.markets[marketId].deleted = true;
        }

        const event = draftState.events[eventId];
        if (!isFullEvent && event?.Markets) {
          event.Markets = event.Markets?.filter((id) => id !== marketId);
        }
        if (isFullEvent && event?.FullMarkets) {
          event.FullMarkets = event.FullMarkets?.filter(
            (id) => id !== marketId,
          );
        }
        break;
      }

      case getType(actions.removePlayerMarket): {
        const { marketId, playerPropId } = action.payload;

        draftState.markets[marketId].deleted = true;

        const pProp = draftState.playerProps?.[playerPropId];
        if (pProp?.Markets) {
          pProp.Markets = pProp.Markets?.filter((id) => id !== marketId);
        }

        break;
      }

      case getType(actions.removeOutcome): {
        const { outcomeId, marketId } = action.payload;
        if (draftState.outcomes[outcomeId]) {
          draftState.outcomes[outcomeId].deleted = true;
        }

        const market = draftState.markets[marketId];
        if (market?.Outcomes) {
          market.Outcomes = market.Outcomes?.filter((id) => id !== outcomeId);
        }
        break;
      }

      /* ADDS */

      case getType(actions.addOutcome): {
        draftState.outcomes[action.payload.outcome.OutcomeId] =
          action.payload.outcome;

        const { outcome: newOutcome, marketId } = action.payload;
        draftState.outcomes[newOutcome.OutcomeId] = newOutcome;
        const { Outcomes } = draftState.markets[marketId] || {};
        if (Outcomes && !Outcomes.includes(newOutcome.OutcomeId)) {
          Outcomes.push(newOutcome.OutcomeId);
        }
        break;
      }

      case getType(actions.addMarket): {
        const { entities, isFullEvent } = action.payload;
        const newMarket = Object.values(entities.markets)[0];
        if (newMarket) {
          const event = draftState.events[newMarket.EventId] || {};
          const markets = isFullEvent ? event.FullMarkets : event.Markets;
          if (markets && !markets.includes(newMarket.MarketId)) {
            markets.push(newMarket.MarketId);
          }
        }
        break;
      }

      case getType(actions.addPlayerMarket): {
        const { entities, playerPropId } = action.payload;
        const newMarket = Object.values(entities.markets)[0];
        if (newMarket) {
          const pProp = draftState.playerProps?.[playerPropId];
          const markets = pProp?.Markets;
          if (markets && !markets.includes(newMarket.MarketId)) {
            markets.push(newMarket.MarketId);
          }
        }
        break;
      }

      case getType(actions.addPlayerProp): {
        const { entities } = action.payload;
        const newPProp = Object.values(entities.playerProps)[0];
        if (newPProp) {
          const event = draftState.events[newPProp.EventId] || {};
          const pProps = event.PlayerProps;
          if (pProps && !pProps.includes(newPProp.PlayerPropId)) {
            pProps.push(newPProp.PlayerPropId);
          }
        }
        break;
      }

      case getType(actions.addEvent): {
        const { entities } = action.payload;
        const newEvent = Object.values(entities.events)[0];
        if (newEvent) {
          const { Events } = draftState.leagues[newEvent.LeagueId] || {};
          if (Events && !Events.includes(newEvent.EventId)) {
            Events.push(newEvent.EventId);
          }
        }
        break;
      }

      /* UPDATES */

      case getType(actions.updateEvent): {
        const { IsBlocked, NbTotalMarkets, StartDate, IdToUpdate } =
          action.payload;
        if (draftState.events[IdToUpdate]) {
          if (IsBlocked != null) {
            draftState.events[IdToUpdate].IsBlocked = IsBlocked;
          }
          if (StartDate != null) {
            draftState.events[IdToUpdate].StartDate = StartDate;
          }
          if (NbTotalMarkets != null) {
            draftState.events[IdToUpdate].NbTotalMarkets = NbTotalMarkets;
          }
        }
        break;
      }

      case getType(actions.updatePlayerProp): {
        // The only prop is RowCount but it'isnt useful for front at the moment
        break;
      }

      case getType(actions.updateMarket): {
        const { StateUpdate, IdToUpdate } = action.payload;
        if (draftState.markets[IdToUpdate]) {
          if (StateUpdate != null) {
            draftState.markets[IdToUpdate].State = StateUpdate;
          }
        }
        break;
      }

      case getType(actions.updateOutcome): {
        const { NewOdd, NewProviderProbabilities, IdToUpdate } = action.payload;
        const outcome = draftState.outcomes[IdToUpdate];
        if (outcome) {
          if (NewOdd != null) {
            outcome.Odd = NewOdd;
          }
          if (NewProviderProbabilities !== null) {
            outcome.ProviderProbabilities = NewProviderProbabilities;
          }
        }
        break;
      }

      /* Update by get outcomeInfos */
      case getType(actions.getInfoFromOutcomes.success): {
        const { ids, Infos } = action.payload;

        ids.forEach(({ eventId, marketId, outcomeId }) => {
          const info = Infos?.find(
            (item) =>
              item?.EventInfo?.EventId === eventId &&
              item?.MarketInfo?.MarketId === marketId &&
              item?.OutcomeInfo?.OutcomeId === outcomeId,
          );

          const outcome = draftState.outcomes[outcomeId];

          if (!info) {
            // outcome not found on SBF -> lock outcome
            if (outcome) {
              outcome.Odd = -1;
            }
          } else {
            const { EventInfo, MarketInfo, OutcomeInfo } = info;
            // outcome found on SBF -> update outcome/market/event
            const event = draftState.events[eventId];
            if (event && EventInfo) {
              event.HomeName = EventInfo.HomeName;
              event.AwayName = EventInfo.AwayName;
              event.EventType = EventInfo.EventType;
              event.StartDate = EventInfo.StartDate;
              event.LeagueId = info?.LeagueInfo?.LeagueId || -1;
              event.IsBlocked = EventInfo.IsBlocked;
            } else {
              draftState.events[eventId] = initializeEvent({
                eventInformation: EventInfo,
                outcomeInfo: info,
              });
            }

            const market = draftState.markets[marketId];
            if (market && MarketInfo) {
              market.MarketName = MarketInfo.MarketName;
              market.TeamName = MarketInfo.TeamName;
              market.PlayerName = MarketInfo.PlayerName;
              market.Provider = MarketInfo.Provider;
              market.State = MarketInfo.State;
              market.IsCashable = MarketInfo.IsCashable;
              market.HasOptiOdds = MarketInfo.HasOptiOdds;
              market.EventId = EventInfo?.EventId || -1;
            } else {
              draftState.markets[marketId] = initializeMarket({
                marketInformation: MarketInfo,
                eventInformation: EventInfo,
              });
            }

            if (outcome && OutcomeInfo) {
              outcome.Name = OutcomeInfo.OutcomeName;
              outcome.Base = OutcomeInfo.Base;
              outcome.Odd = OutcomeInfo.Odd;
              outcome.MarketId = MarketInfo?.MarketId || -1;
            } else {
              draftState.outcomes[outcomeId] = initializeOutcome({
                outcomeInformation: OutcomeInfo,
                marketInformation: MarketInfo,
                eventInformation: EventInfo,
              });
            }
          }
        });

        break;
      }

      /* INIT Session */
      case getType(actions.initBettingSlipBySession):
      case getType(actions.initBettingSlipFast): {
        const { events, markets, outcomes } = action.payload.sportbook;

        events.forEach((event) => {
          if (!draftState.events[event.EventId]) {
            draftState.events[event.EventId] = initializeEvent({
              lightEvent: event,
            });
          }
        });

        markets.forEach((market) => {
          if (!draftState.markets[market.MarketId]) {
            draftState.markets[market.MarketId] = initializeMarket({
              lightMarket: market,
            });
          }
        });

        outcomes.forEach((outcome) => {
          if (!draftState.outcomes[outcome.OutcomeId]) {
            draftState.outcomes[outcome.OutcomeId] = initializeOutcome({
              lightOutcome: outcome,
            });
          }
        });

        break;
      }

      default: // Immer will automatically return the state
    }

    // Second switch to allow regrouping all updates
    switch (action.type) {
      case getType(actions.addLeague):
      case getType(actions.addEvent):
      case getType(actions.addPlayerProp):
      case getType(actions.addPlayerMarket):
      case getType(actions.addMarket): {
        const { payload } = action;
        const { entities } = payload;

        if ('leagues' in entities) {
          draftState.leagues = {
            ...draftState.leagues,
            ...entities.leagues,
          };
        }
        if ('events' in entities) {
          draftState.events = getMergedEvents(
            draftState.events,
            entities.events,
            'isFullEvent' in payload ? payload.isFullEvent : false,
          );
        }
        if ('markets' in entities) {
          draftState.markets = {
            ...draftState.markets,
            ...entities.markets,
          };
        }
        if ('playerProps' in entities) {
          draftState.playerProps = {
            ...(draftState.playerProps || {}),
            ...(entities.playerProps || {}),
          };
        }
        if ('outcomes' in entities) {
          draftState.outcomes = {
            ...draftState.outcomes,
            ...entities.outcomes,
          };
        }

        break;
      }

      case getType(coreActions.switchLocale.success):
        draftState.sports = {};
        break;

      default: // Immer will automatically return the state
    }
  });
