import { getWorkingEnv, getWrapperEnv } from '@gaming1/g1-logger';

import * as allCoreEpics from '../epics';

type CoreEpics = typeof allCoreEpics;
type CoreEpicNames = keyof typeof allCoreEpics;

/**
 * All core epics that deals with HTTP requests, that should be done in the
 * RN code to avoid cookie and CORS issues (since the files are served via
 * localhost and making request to another domain)
 */
export const httpEpicList: CoreEpicNames[] = [
  'getAuthTokenEpic',
  'getGeoComplyLicenseKeyEpic',
  'getShellParametersEpic',
  'impersonateUserEpic',
  'keepSessionAliveEpic',
  'loginEpic',
  'logoutAjaxEpic',
];

/** Core epics that should not be enabled when running the components into
 * another (legacy) app */
export const modernAuthEpicList: CoreEpicNames[] = [
  // We want to init the wsAdapter outside because we might need the existing WS
  // factory that is passed asynchronously (we have to wait for the host app to
  // pass it)
  'appInitEpic',
  // We don't want to auth the user on the host WebSocket because it will
  // already be handled by the host app
  'authReconnectEpic',
  // We don't need to display the user credit for now
  'userCreditPollingEpic',
  // We only want to try to login the user when the host app decide it
  'getTokenOnInitEpic',
];

const filterCoreEpicsByName = (
  coreEpics: CoreEpics,
  {
    names,
    mode,
  }: {
    names: CoreEpicNames[];
    mode: 'blacklist' | 'whitelist';
  },
) =>
  Object.entries(coreEpics).reduce<{ [k in CoreEpicNames]?: CoreEpics[k] }>(
    (acc, [epicKey, epic]) => {
      const epicName = epicKey as CoreEpicNames;
      if (
        mode === 'blacklist'
          ? !names.includes(epicName)
          : names.includes(epicName)
      ) {
        acc[epicName] = epic;
      }
      return acc;
    },
    {},
  );

type WrapperEnv = ReturnType<typeof getWrapperEnv>;
/** Filter out the core epics depending on the wrapper env.
 * This will remove the epics that make http requests from the webapp
 * This is because when the webapp is served through a webview in the iOS native
 * app, the cookies to our http api domain are not used, due to the fact that it
 * is different than the one used to serve the app (localhost).
 * RN has no such issue, so transfering all the HTTP requests to it make it
 * possible to use cookie auth.
 */
export const filterCoreEpicsByEnv = (
  coreEpics: CoreEpics,
  forcedEnv?: WrapperEnv,
) => {
  const wrapperEnv = forcedEnv || getWrapperEnv();
  const isInLegacyApp = wrapperEnv === 'interopwebapp';
  const isInRNWebview = wrapperEnv === 'rn';
  const isInReactNative = getWorkingEnv() === 'react-native';
  if (isInReactNative) {
    return filterCoreEpicsByName(coreEpics, {
      names: httpEpicList,
      mode: 'whitelist',
    });
  }
  if (isInRNWebview) {
    return filterCoreEpicsByName(coreEpics, {
      names: httpEpicList,
      mode: 'blacklist',
    });
  }
  if (isInLegacyApp) {
    return filterCoreEpicsByName(coreEpics, {
      names: modernAuthEpicList,
      mode: 'blacklist',
    });
  }
  return coreEpics;
};
