import { EResponseStatus } from '@gaming1/g1-requests-betting';

/**
 * Error messages used in most betting requests since they almost all use
 * the EResponseStatus enum
 */
export const commonBettingErrorMessages = {
  [EResponseStatus.Error]: 'core:error.internalServer',
  [EResponseStatus.RequestParametersError]: 'core:error.invalidParameters',
};
