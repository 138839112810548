import React, { VFC } from 'react';

import { BettingHistoryTicketType } from '@gaming1/g1-betting';
import { useNotifications } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { BettingSlipType } from '@gaming1/g1-requests-betting';
import { Box, Icon, SimpleButton } from '@gaming1/g1-ui';

import { extractCashoutId } from '../../helpers';

import { ExpandedExtraInfoBoostus } from './ExpandedExtraInfoBoostus';
import { ExpandedExtraInfoCashout } from './ExpandedExtraInfoCashout';
import { ExpandedExtraInfoOptimalBet } from './ExpandedExtraInfoOptimalBet';
import { ExpandedExtraInfoPromotion } from './ExpandedExtraInfoPromotion';
import { BetIdContainer, BetIdText, CombinationCallToAction } from './styles';

type ExpandedExtraInfoProps = {
  betId: string;
  isExpanded: boolean;
  activeTab: string;
  history: BettingHistoryTicketType;
  openSystemDetails?: (betId: string) => void;
};
export const ExpandedExtraInfo: VFC<ExpandedExtraInfoProps> = ({
  betId,
  isExpanded,
  activeTab,
  history,
  openSystemDetails,
}) => {
  const { t } = useTranslation('betting');
  const { addNotification } = useNotifications();

  const cashoutId = extractCashoutId(history);

  const handleCombinationSystemOpened = () => {
    if (!openSystemDetails) {
      return;
    }
    openSystemDetails(betId);
  };
  const handleCopyToClipboard = () => {
    if (history && history.Name) {
      navigator.clipboard.writeText(history.Name);
      addNotification({
        message: t('history.clipboard.copied'),
        type: 'success',
      });
    }
  };

  if (!isExpanded) {
    return null;
  }
  return (
    <div data-testid="expanded-extra-info-content">
      {history.AssociatedPromotion?.Promotion && (
        <ExpandedExtraInfoPromotion
          promotion={history.AssociatedPromotion.Promotion}
        />
      )}
      {history.AssociatedPromotion?.Boostus && (
        <ExpandedExtraInfoBoostus
          boostus={history.AssociatedPromotion.Boostus}
          history={history}
        />
      )}
      {history.OptimalBetInformation && (
        <ExpandedExtraInfoOptimalBet
          optimalBet={history.OptimalBetInformation}
          history={history}
        />
      )}
      {cashoutId && <ExpandedExtraInfoCashout cashoutId={cashoutId} />}
      {isExpanded &&
        history.Type === BettingSlipType.System &&
        activeTab !== 'fromTicket' && (
          <Box mt="md">
            <CombinationCallToAction
              onClick={handleCombinationSystemOpened}
              data-testid="open-system-details-cta"
            >
              {t('historyTabContent.combinasionCallToAction')}
            </CombinationCallToAction>
          </Box>
        )}
      {isExpanded && (
        <BetIdContainer>
          <BetIdText mt={0} ml="xxs">
            {t('historyTabContent.betId')}: {history.Name}
          </BetIdText>
          <SimpleButton
            onClick={handleCopyToClipboard}
            data-testid="copy-bet-id-button"
          >
            {/** TODO : change to the clipboard Icon when it's available in the zeroheight */}
            <Icon type="BettingSlip" id="BettingSlip" opacity="semi" />
          </SimpleButton>
        </BetIdContainer>
      )}
    </div>
  );
};
