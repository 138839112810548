import React, { useContext } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import {
  Box,
  Button,
  DrawerType,
  LayoutContext,
  RenderOnMedia,
} from '@gaming1/g1-ui';

import { useGetEmptyMenu } from '../../hooks';
import { breakPointMobileTicket } from '../../hooks/render';
import { BettingSlipMenu } from '../BettingSlipMenu';
import { BettingSlipMobileLinks } from '../BettingSlipMobileLinks';

import { BettingSlipEmptyStateContainer } from './styles';
/**
 * Display when bettingslip is empty
 *
 */
export const BettingSlipEmptyState = () => {
  const { t } = useTranslation('betting');

  const menu = useGetEmptyMenu();

  const { hideDrawer, visibleDrawer } = useContext(LayoutContext);

  const bettingSlipDrawerVisibility = visibleDrawer === DrawerType.bettingSlip;

  const closeDrawerButton = () => {
    hideDrawer();
  };

  return (
    <BettingSlipEmptyStateContainer data-testid="bettingslip-empty-state-container">
      <div>
        <BettingSlipMenu />
      </div>
      <RenderOnMedia max={breakPointMobileTicket}>
        <Box>
          <BettingSlipMobileLinks menu={menu} />
          {bettingSlipDrawerVisibility && (
            <Box mx="sm" mt="lg" mb="sm">
              <Button onClick={closeDrawerButton} type="primary">
                {t('bettingslip.drawer.close.button')}
              </Button>
            </Box>
          )}
        </Box>
      </RenderOnMedia>
    </BettingSlipEmptyStateContainer>
  );
};
