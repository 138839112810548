import React, { FC } from 'react';

import { ResettingWrapper } from '../../../layout/components/ResettingWrapper';
import { MultiLanguageRouter } from '../MultiLanguageRouter';

export const RouterWrapper: FC = ({ children }) => (
  <MultiLanguageRouter>
    <ResettingWrapper>{children}</ResettingWrapper>
  </MultiLanguageRouter>
);
