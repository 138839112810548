import { of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { DEFAULT_SERVER_ERROR_STATUS } from '@gaming1/g1-utils';

import { bettingSlipGetterSelector } from '../../bettingSlip/store/selectors';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

/** Reset the bettingslip external data (RM, PB, freebet, ...) */
export const resetBettingSlipEpic: BettingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.resetBettingSlip.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );
      if (bettingSlipId) {
        const bettingSlipSlice =
          bettingSlipGetterSelector(state)(bettingSlipId);

        if (!bettingSlipSlice) {
          return of(
            actions.resetBettingSlip.failure(
              formatActionsData({
                data: {
                  errorMessage:
                    'betting:resetBettingSlip.noBettingSlipSlice.error',
                  status: DEFAULT_SERVER_ERROR_STATUS,
                },
                bettingSlipId,
                betPipeId,
              }),
            ),
          );
        }
      }

      return of(
        actions.resetBettingSlip.success(
          formatActionsData({
            data: {},
            bettingSlipId,
            betPipeId,
          }),
        ),
      );
    }),
  );
