import React, { ForwardedRef, forwardRef } from 'react';
import { TypographyProps } from 'styled-system';

import { Icon, useMediaBreakPoint } from '@gaming1/g1-ui';

import { MenuItem } from '../types';

import {
  ICON_SIZE_FLEXTILE_IN_PX,
  ICON_SIZE_IN_PX,
  ICON_SIZE_TILE_IN_PX,
  MOBILE_ICON_SIZE_FLEXTILE_IN_PX,
  ScrollNavItemAnchor,
  ScrollNavItemButton,
  ScrollNavItemContent,
  ScrollNavItemContentLabel,
  ScrollNavItemExtraLabel,
  ScrollNavItemLink,
} from './styles';
import { Layout } from './types';

type ScrollNavItemProps = MenuItem &
  TypographyProps & {
    /** Style of the layout */
    layout?: Layout;
  };

export const ScrollNavItem = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ScrollNavItemProps
>(
  (
    {
      extraLabel,
      highlighted,
      icon = null,
      label,
      testId,
      layout = 'menu',
      ...props
    },
    ref,
  ) => {
    const isLarge = useMediaBreakPoint({ min: 'lg' });
    const iconSizesInPx: Record<Layout, number> = {
      altMenu: ICON_SIZE_IN_PX,
      flexTiles: isLarge
        ? ICON_SIZE_FLEXTILE_IN_PX
        : MOBILE_ICON_SIZE_FLEXTILE_IN_PX,
      menu: ICON_SIZE_IN_PX,
      tabs: ICON_SIZE_IN_PX,
      tiles: ICON_SIZE_TILE_IN_PX,
    };
    const iconUI =
      typeof icon === 'string' ? (
        <Icon
          height={`${iconSizesInPx[layout]}px`}
          id={`scrollnav-${icon.toLowerCase()}`}
          mb="xs"
          type={icon}
          width={`${iconSizesInPx[layout]}px`}
        />
      ) : (
        icon
      );
    const labelUI = (
      <ScrollNavItemContentLabel
        highlighted={highlighted === true}
        layout={layout}
      >
        {label}
      </ScrollNavItemContentLabel>
    );
    const content =
      layout === 'tiles' ? (
        <>
          <ScrollNavItemContent layout={layout}>
            {extraLabel && (
              <ScrollNavItemExtraLabel>{extraLabel}</ScrollNavItemExtraLabel>
            )}
            {iconUI}
          </ScrollNavItemContent>

          {labelUI}
        </>
      ) : (
        <ScrollNavItemContent layout={layout}>
          {/* No extra label for other layouts */}
          {iconUI}
          {labelUI}
        </ScrollNavItemContent>
      );

    const { disabled = false } = props;

    if ('onClick' in props) {
      return (
        <ScrollNavItemButton
          data-testid={testId}
          layout={layout}
          highlighted={highlighted === true}
          disabled={disabled}
          ref={ref as ForwardedRef<HTMLButtonElement>}
          {...props}
        >
          {content}
        </ScrollNavItemButton>
      );
    }

    const { externalLink, exact, path } = props;

    return externalLink ? (
      <ScrollNavItemAnchor
        data-testid={testId}
        layout={layout}
        highlighted={highlighted === true}
        href={path}
        disabled={disabled}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...props}
      >
        {content}
      </ScrollNavItemAnchor>
    ) : (
      <ScrollNavItemLink
        data-testid={testId}
        layout={layout}
        exact={exact}
        hasUnderline
        highlighted={highlighted === true}
        to={path}
        disabled={disabled}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...props}
      >
        {content}
      </ScrollNavItemLink>
    );
  },
);
