import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { COMMON_PRISMIC_AJAX_CONFIG } from '../../common/constants';
import { CMSEpic } from '../../store/types';

import * as actions from './actions';
import { pageContentQueryResponse } from './codecs';

const buildUrl = (url: string, query: string) =>
  `${url}/documents/search?${query}`;

export const pageContentQueryEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchPageContentDocument.request)),
    withLatestFrom(config$, state$),
    switchMap(([action, config]) =>
      ajaxFetch(
        buildUrl(config.network.prismicUrl, action.payload),
        COMMON_PRISMIC_AJAX_CONFIG,
      ).pipe(
        mapGuard(pageContentQueryResponse),
        map((data) =>
          actions.fetchPageContentDocument.success({
            data,
            request: action.payload,
          }),
        ),
        catchError((err) =>
          of(
            actions.fetchPageContentDocument.failure({
              request: action.payload,
              error: createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicDocument',
              ),
            }),
          ),
        ),
      ),
    ),
  );

export const singlePageContentQueryEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchSinglePageContentDocument.request)),
    withLatestFrom(config$, state$),
    mergeMap(([action, config]) =>
      ajaxFetch(
        buildUrl(config.network.prismicUrl, action.payload),
        COMMON_PRISMIC_AJAX_CONFIG,
      ).pipe(
        mapGuard(pageContentQueryResponse),
        map((data) =>
          actions.fetchSinglePageContentDocument.success({
            data,
            request: action.payload,
          }),
        ),
        catchError((err) =>
          of(
            actions.fetchSinglePageContentDocument.failure({
              request: action.payload,
              error: createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicDocument',
              ),
            }),
          ),
        ),
      ),
    ),
  );
