import gte from 'lodash/gte';
import lte from 'lodash/lte';

import { MediaBreakPointNames, mediaBreakPoints } from '@gaming1/g1-style';

const createMediaBreakPointComparison =
  (compareFn: (a: number, b: number) => boolean) =>
  (
    mediaBreakPoint: MediaBreakPointNames,
    mediaBreakPointToCompare: MediaBreakPointNames,
  ): boolean => {
    const mediaBreakPointNames = mediaBreakPoints.map((bp) => bp.name);
    return compareFn(
      mediaBreakPointNames.indexOf(mediaBreakPoint),
      mediaBreakPointNames.indexOf(mediaBreakPointToCompare),
    );
  };

export const isMediaBreakPointAboveOrEqual =
  createMediaBreakPointComparison(gte);
export const isMediaBreakPointBelowOrEqual =
  createMediaBreakPointComparison(lte);
