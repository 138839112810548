/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
import { isNonNullable } from '@gaming1/g1-utils';

import { NormalizedOutcome } from '../sportbook/store/types';

import { calcOddsMultiplying } from './helpers/calc';
import { extractOddsFromOutcomes } from './store/selectors/helpers';
import { RoundingConfig } from './store/types/common';
import {
  BettingSlipRankState,
  BettingSlipUnitState,
} from './store/types/store';

export const haveItems = (bettingslip?: BettingSlipUnitState): boolean =>
  isNonNullable(bettingslip) &&
  bettingslip &&
  bettingslip.items &&
  bettingslip.items.length > 0;

export const haveRanks = (bettingslip?: BettingSlipUnitState): boolean =>
  isNonNullable(bettingslip) &&
  bettingslip &&
  bettingslip.ranks &&
  bettingslip.ranks.length > 0;

export const extractOddFromRankCombination = (
  rank: BettingSlipRankState,
  outcomesSelector: (
    outcomeIds: number[],
    filterDeleted?: boolean | null,
  ) => (NormalizedOutcome | undefined)[],
  roundingConfig: RoundingConfig,
) =>
  rank.itemsCombination.reduce((oldValue, itemsId) => {
    const outcomes = outcomesSelector(itemsId);

    return (
      oldValue +
      calcOddsMultiplying(roundingConfig)(extractOddsFromOutcomes(outcomes))
    );
  }, 0);

/**
 * @todo add unit test
 */
/**
 * Generate combination of rank for system betting slip
 * @param itemsId list of item Id
 * @param rank nb of group item
 * @description inspiration de https://www.w3resource.com/javascript-exercises/javascript-function-exercise-21.php
 */
export const combination = (itemsId: number[], rank: number): number[][] => {
  const resultSet = [];

  for (let x = 0; x < 2 ** itemsId.length; x++) {
    const result = [];
    let i = itemsId.length - 1;
    do {
      if ((x & (1 << i)) !== 0) {
        result.push(itemsId[i]);
      }
    } while (i--);

    if (result.length === rank) {
      resultSet.push(result);
    }
  }

  return resultSet;
};
