import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { SettingsState } from './types';

export const initialState: SettingsState = {
  requests: {
    changePlayerSettings: generateInitialRequestState(null),
    getPlayerSettings: generateInitialRequestState(null),
  },
  userSettings: null,
};

export const settingsReducer = (
  state: SettingsState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.changeUserSettings.request):
        produceLoadingState(draftState.requests.changePlayerSettings);
        break;

      case getType(actions.changeUserSettings.failure):
        produceFailureState(
          draftState.requests.changePlayerSettings,
          action.payload,
        );
        break;

      case getType(actions.changeUserSettings.success):
        produceSuccessState(draftState.requests.changePlayerSettings);
        break;

      case getType(actions.getUserSettings.request):
        produceLoadingState(draftState.requests.getPlayerSettings);
        break;

      case getType(actions.getUserSettings.failure):
        produceFailureState(
          draftState.requests.getPlayerSettings,
          action.payload,
        );
        break;

      case getType(actions.getUserSettings.success):
        produceSuccessState(draftState.requests.getPlayerSettings);
        draftState.userSettings = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
