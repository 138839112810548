import * as t from 'io-ts';

import { EGoogleTagManagerStatus } from '@gaming1/g1-requests';

const gtmKeyValueEventCodec = t.partial(
  {
    Key: t.string,
    Value: t.string,
  },
  'KeyValueEvent',
);

export type RawGTMEvent = t.TypeOf<typeof gtmKeyValueEventCodec>;

const dataBlockCodec = t.intersection(
  [
    t.type({
      Id: t.string,
      List: t.array(gtmKeyValueEventCodec),
    }),
    t.partial({
      IsGoogleAnalytics4Event: t.boolean,
    }),
  ],
  'DataBlock',
);

export type RawDataBlock = t.TypeOf<typeof dataBlockCodec>;

export const getGoogleTagManagerSuccessResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGoogleTagManagerStatus.Successful),
      DataBlocks: t.array(dataBlockCodec),
    }),
    t.partial({
      IsGoogleAnalytics4Event: t.boolean,
    }),
  ],
  'GoogleTagManagerSuccessResponse',
);
