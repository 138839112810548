import styled from 'styled-components';

import { colors, media, spaces } from '@gaming1/g1-style';
import { Text } from '@gaming1/g1-ui';

/* 4:2 ratio on mobile */
const BANNER_MOBILE_RATIO_IN_PERCENT = 50;
/* 4:1 ratio on desktop and tablet */
const BANNER_DESKTOP_RATIO_IN_PERCENT = 25;

type ModalBannerProps = {
  /** URL of the image */
  url?: string;
};

export const ModalBanner = styled.div<ModalBannerProps>`
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  width: 100%;
  height: 0;
  padding-bottom: ${BANNER_MOBILE_RATIO_IN_PERCENT}%;

  ${media.sm} {
    padding-bottom: ${BANNER_DESKTOP_RATIO_IN_PERCENT}%;
  }
  ${media.md} {
    padding-bottom: ${BANNER_MOBILE_RATIO_IN_PERCENT}%;
  }
  ${media.xl} {
    padding-bottom: ${BANNER_DESKTOP_RATIO_IN_PERCENT}%;
  }
`;

export const ModalType = styled(Text)`
  margin-top: ${spaces('xs')};
  color: ${colors('muted')};
`;
