import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  device,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { Box, TextInput } from '@gaming1/g1-ui';

const BORDER_RADIUS_OF_BETTINGSLIP_INPUT_IN_PX = 2;

const BETTING_SLIP_INPUT_HEIGHT_IN_PX = 32;
const BETTING_SLIP_INPUT_MIN_WIDTH = '110px';

type BettingSlipInputProps = {
  disabled?: boolean;
};

export const BettingSlipInputContainer = styled.div`
  width: 33%;
  min-width: ${BETTING_SLIP_INPUT_MIN_WIDTH};
`;

export const BettingSlipNbCombinationsContainer = styled.div<BettingSlipInputProps>`
  margin-right: ${spaces('xs')};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      color: ${colors('text', { transform: darken(0.2) })};
    `}
`;

export const BettingSlipInput = styled(TextInput)<BettingSlipInputProps>`
  ${fontWeight('bold')};
  border: none;
  cursor: pointer;
  text-align: right;
  padding-right: ${spaces('xs')};
  font-size: ${fontSizes('md')};
  border-radius: ${BORDER_RADIUS_OF_BETTINGSLIP_INPUT_IN_PX}px;
  background-color: ${colors('backgrounds', { index: 1 })};
  height: ${BETTING_SLIP_INPUT_HEIGHT_IN_PX}px;
  ${device.canHover} {
    &:hover,
    &:focus {
      background-color: ${colors('backgrounds', {
        index: 1,
        transform: lighten(0.15),
      })};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      color: ${colors('text', { transform: darken(0.2) })};
    `}
`;

export const BettingSlipStakeBox = styled(Box)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: ${fontSizes('md')};
  margin-bottom: ${spaces('xs')};
`;
