import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { userCreditSelector } from '@gaming1/g1-core';
import {
  KeyValueType,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import {
  BettingSlipIdentifierType,
  EBettingSlipIdentifier,
} from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import {
  BettingSlipNotification,
  EFrontErrorType,
} from '../../../notification/mapper/types';
import { MAX_NUMBER_OF_ELEMENTS_IN_SYSTEM_BETTINGSLIP } from '../../const';
import { hasSystemTooManyElements } from '../../helpers/validators';
import {
  bettingSlipItemsSportBookIdsGetterSelector,
  bettingSlipRanksGetterSelector,
} from '../../store/selectors';

import {
  useBettingSlipRankPotWinning,
  useBettingSlipRankStakeWithCombinations,
} from './rank';

export const useBettingSlipSystemStake = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const bettingSlipRankStakeWithCombinations =
    useBettingSlipRankStakeWithCombinations(bettingSlipId);
  const ranks = useBettingGetterSelector({
    getterSelector: bettingSlipRanksGetterSelector,
    args: [bettingSlipId],
  });

  if (!ranks) {
    return undefined;
  }

  return ranks
    .filter((rank) => rank.available)
    .reduce((prev, rank) => {
      const stake = bettingSlipRankStakeWithCombinations(rank.id) || 0;

      return stake ? prev + stake : prev;
    }, 0);
};

export const useBettingSlipSystemPotWinning = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const bettingSlipRankPotWinningGetterSelector =
    useBettingSlipRankPotWinning(bettingSlipId);
  const ranks = useBettingGetterSelector({
    getterSelector: bettingSlipRanksGetterSelector,
    args: [bettingSlipId],
  });
  if (!ranks) {
    return undefined;
  }

  return ranks
    .filter((rank) => rank.available)
    .reduce((prev, rank) => {
      const potWinning = bettingSlipRankPotWinningGetterSelector(rank.id);
      if (!potWinning) {
        return prev;
      }
      return potWinning ? prev + potWinning : potWinning;
    }, 0);
};

export const useBettingSlipSystemErrorFrontType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const stake = useBettingSlipSystemStake(bettingSlipId);

  const userCredit = useSelector(userCreditSelector);

  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsSportBookIdsGetterSelector,
    args: [EBettingSlipIdentifier.Main],
    equalityFn: isEqual,
  });

  const hasTooManyElements = hasSystemTooManyElements(items);

  return (): BettingSlipNotification => {
    if (hasTooManyElements) {
      return {
        Status: EFrontErrorType.TooManyElementsInSystem,
        Level: NotificationLevel.Error,
        Code: NotificationCode.RiskManagement_TooManyElements,
        NotificationParameters: [
          {
            Key: 'MaxElements',
            Value: MAX_NUMBER_OF_ELEMENTS_IN_SYSTEM_BETTINGSLIP.toString(),
            Type: KeyValueType.String,
          },
        ],
      };
    }
    if (userCredit && stake && stake > userCredit.AvailableAmout) {
      return {
        Status: EFrontErrorType.NotEnoughCredit,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }
    return {
      Status: EFrontErrorType.None,
      Level: NotificationLevel.Information,
      Code: NotificationCode.Unknown,
    };
  };
};
