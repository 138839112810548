import {
  EChagePlayerSettingsStatus,
  EGetPlayerSettingsStatus,
} from '@gaming1/g1-requests';

export const changePlayerSettingsErrorMessages = {
  [EChagePlayerSettingsStatus.InternalError]: 'core:error.internalServer', // 0
  [EChagePlayerSettingsStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EChagePlayerSettingsStatus.FieldError]: 'user:account.message.formInvalid', // 3
};

export const getPlayerSettingsErrorMessages = {
  [EGetPlayerSettingsStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetPlayerSettingsStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};
