import { NavigationDataType } from '@gaming1/g1-betting';
import { NavigationLinkType } from '@gaming1/g1-requests-betting';

import { useBettingRoutePath } from '../../../hooks';

export const useCreateRouteFromFormattedRoute = (
  formattedRoute: NavigationDataType | null,
  navigationType: NavigationLinkType | undefined,
) => {
  const getBettingRoutePath = useBettingRoutePath();

  if (!formattedRoute) {
    return null;
  }

  if (!navigationType) {
    return null;
  }

  const { SportId, LeagueId, EventId, RegionId } = formattedRoute;

  const sportId = SportId.toString();
  const regionId = RegionId?.toString();
  const leagueId = LeagueId?.toString();
  const eventId = EventId?.toString();

  switch (navigationType) {
    case NavigationLinkType.Sport:
      return getBettingRoutePath('sport', { sportId });
      break;
    case NavigationLinkType.League:
      return getBettingRoutePath('league', {
        sportId,
        regionId: regionId || '0',
        leagueId: leagueId || '0',
      });
      break;
    case NavigationLinkType.Event:
      return getBettingRoutePath('eventPrematch', {
        sportId,
        regionId: regionId || '0',
        leagueId: leagueId || '0',
        eventId: eventId || '0',
      });
      break;
    default:
      return null;
  }
};
