import React, { VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon } from '@gaming1/g1-ui';

import { MenuGroups } from '../types';

import {
  TopBarMenuAnchor,
  TopBarMenuButton,
  TopBarMenuContainer,
  TopBarMenuGroup,
  TopBarMenuLink,
} from './styles';

export type TopBarMenuProps = {
  /** The menu groups */
  menu: MenuGroups;
};

/** The menu display on xl screens in the TopBar */
export const Menu: VFC<TopBarMenuProps> = ({ menu }) => {
  const { t } = useTranslation('core');

  return (
    <TopBarMenuContainer>
      {menu.map((menuList, groupIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TopBarMenuGroup key={groupIndex}>
          {menuList.map(({ icon, label, testId, ...props }) => {
            if ('onClick' in props) {
              return (
                <TopBarMenuButton
                  data-testid={testId}
                  key={`${testId}${label}`}
                  onClick={props.onClick}
                >
                  {t(label)}
                </TopBarMenuButton>
              );
            }

            return props.externalLink ? (
              <TopBarMenuAnchor
                data-testid={testId}
                key={`${props.path}${label}`}
                href={props.path}
                rel="noopener"
                target="_blank"
                groupIndex={groupIndex}
              >
                {!!icon && typeof icon === 'string' ? (
                  <Icon type={icon} id={`topbar-${icon}`} />
                ) : (
                  icon
                )}
                <span>{t(label)}</span>
              </TopBarMenuAnchor>
            ) : (
              <TopBarMenuLink
                data-testid={testId}
                exact={props.exact}
                key={`${props.path}${label}`}
                to={props.path}
                groupIndex={groupIndex}
              >
                {!!icon && typeof icon === 'string' ? (
                  <Icon type={icon} id={`topbar-${icon}`} />
                ) : (
                  icon
                )}
                <span>{t(label)}</span>
              </TopBarMenuLink>
            );
          })}
        </TopBarMenuGroup>
      ))}
    </TopBarMenuContainer>
  );
};
