import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from '@gaming1/g1-utils';

import { DrawerType, LayoutContext } from '../LayoutProvider';

/** This hook holds the logic for closing a Drawer on user navigation.
 *  The Drawer will be closed in two cases:
 *    - when the user navigates to a different path
 *    - when the user navigates to the same path (the user clicks on the active link, updating the location but the path is not changed)
 *  When the user changes only the locale the Drawer will not be closed.
 */
export const useCloseDrawerOnNavigation = (drawerType: DrawerType) => {
  const { visibleDrawer, hideDrawer } = useContext(LayoutContext);

  const location = useLocation();
  const previousLocation = usePrevious(location);

  const isSpecificDrawerVisible = visibleDrawer === drawerType;
  const wasSpecificDrawerVisible = usePrevious(isSpecificDrawerVisible);

  const currentLocale = location.pathname.substr(0, 3);
  const previousLocale = usePrevious(currentLocale);

  useEffect(() => {
    if (
      location.key !== previousLocation?.key &&
      isSpecificDrawerVisible &&
      wasSpecificDrawerVisible &&
      previousLocale === currentLocale
    ) {
      hideDrawer();
    }
  }, [
    location,
    isSpecificDrawerVisible,
    wasSpecificDrawerVisible,
    hideDrawer,
    previousLocale,
    currentLocale,
    previousLocation?.key,
  ]);
};
