import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getWrapperEnv } from '@gaming1/g1-logger';
import {
  geoComplyCheckLocation,
  isGeoComplyLocationValid,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard, RemoteData } from '@gaming1/g1-utils';

import * as actions from '../actions';
import { logger } from '../logger';
import { CoreEpic } from '../types';

import {
  checkGeoLocationResponseCodec,
  geoComplyLicenseKeyResponseCodec,
  isGeoComplyLocationValidResponseCodec,
} from './codecs';

const BACKEND_DECRYPT_EXCEPTION_RULE = 'app-backend-fail-to-check-location';

/** Fetch GeoComply's license key */
export const getGeoComplyLicenseKeyEpic: CoreEpic = (
  actions$,
  _,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getGeoComplyLicenseKey.request)),
    withLatestFrom(config$),
    mergeMap(([, config]) =>
      ajaxFetch(`${config.network.apiUrl}/Ajax/GeoComplyLicense`).pipe(
        mapGuard(geoComplyLicenseKeyResponseCodec),
        map(actions.getGeoComplyLicenseKey.success),
        catchError((err) =>
          of(actions.getGeoComplyLicenseKey.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/**
 * After successfully loading the current user profile, we check if the cached geolocation is valid if :
 * - GeoComply is activated
 * - The user profile is filled
 * - The GeoComply License Key is set
 * - the app is connected to GeoComply SDK OR the web app is running in a native wrapper
 * - no current geolocation data exists => allows the call not to be made when the GeoComply License key is renewed
 */
export const successGetProfileToCheckGeolocationValidEpic: CoreEpic = (
  actions$,
  state$,
  { config$ },
) =>
  actions$.pipe(
    filter(
      isActionOf([
        actions.getUserProfile.success,
        actions.getGeoComplyLicenseKey.success,
        actions.connectToGeoComply.success,
      ]),
    ),
    withLatestFrom(config$, state$),
    filter(
      ([, config, state]) =>
        config.core.geoComply.activated &&
        !!state.core.user.userProfile &&
        !!state.core.geolocation.geoComplyLicenseKey &&
        (state.core.geolocation.requests.connectToGeoComply.status ===
          RemoteData.Success ||
          getWrapperEnv() === 'rn') &&
        state.core.geolocation.currentGeolocation === null,
    ),
    map(() => actions.checkIsCachedGeolocationValid.request()),
  );

/** Checks whether or not the cached geolocation is valid */
export const isGeolocationValidEpic: CoreEpic = (actions$, _, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.checkIsCachedGeolocationValid.request)),
    mergeMap(() =>
      wsAdapter.request(isGeoComplyLocationValid({})).pipe(
        mapGuard(isGeoComplyLocationValidResponseCodec),
        map(actions.checkIsCachedGeolocationValid.success),
        catchError((err) =>
          of(
            actions.checkIsCachedGeolocationValid.failure(
              createFailurePayload(err),
            ),
          ),
        ),
      ),
    ),
  );

/**
 * After a successful geolocation request,
 * Send an action that will decrypt the GeoComply packet received from the PLC
 */
export const successRequestGeolocationToDecryptGeolocationEpic: CoreEpic = (
  actions$,
) =>
  actions$.pipe(
    filter(isActionOf(actions.requestGeolocation.success)),
    map(({ payload }) => actions.decryptGeolocation.request(payload)),
  );

/** Decrypts GeoComply's packet to readable data */
export const decryptGeolocationEpic: CoreEpic = (actions$, _, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.decryptGeolocation.request)),
    mergeMap(({ payload }) =>
      wsAdapter
        .request(geoComplyCheckLocation({ GeoComplyPacket: payload }), {
          timeoutAfterMs: 30 * 1000,
        })
        .pipe(
          mapGuard(checkGeoLocationResponseCodec),
          tap((decryptedPayload) => {
            /* Log an error if there is a specific rule in the troubleshooters,
             indicating that the backend failed to decryp the GeoComply payload
             */
            if (
              decryptedPayload.Troubleshooters?.some(
                (troubleshooter) =>
                  troubleshooter.Rule === BACKEND_DECRYPT_EXCEPTION_RULE,
              )
            ) {
              logger.error(
                '[Geolocation] The backend could not decrypt the geolocation',
              );
            }
          }),
          map(actions.decryptGeolocation.success),
          catchError((err) =>
            of(actions.decryptGeolocation.failure(createFailurePayload(err))),
          ),
        ),
    ),
  );

// At this point, the PLC is not installed.  If a requestGeolocation action is running
// while the connection failed, it means we have to change its status as well.
export const connectoToGeoComplyFailureToRequestGeolocationFailureEpic: CoreEpic =
  (actions$, state$) =>
    actions$.pipe(
      filter(isActionOf(actions.connectToGeoComply.failure)),
      withLatestFrom(state$),
      filter(
        ([, state]) =>
          state.core.geolocation.requests.requestGeolocation.status ===
          RemoteData.Loading,
      ),
      map(([{ payload }]) =>
        actions.requestGeolocation.failure({
          status: payload.status,
          errorMessage: payload.errorMessage,
        }),
      ),
    );
