import { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  BettingSlipItemState,
  eventNameSelector,
  eventSelector,
  marketSelector,
  outcomeSelector,
  useBettingGetterSelector,
  useBettingSlipItemBaseOdds,
} from '@gaming1/g1-betting';

export const BettingSlipIemDebugging: VFC<{
  item: BettingSlipItemState;
  bsId: string;
}> = ({ item, bsId }) => {
  const event = useBettingGetterSelector({
    getterSelector: eventSelector,
    // eslint-disable-next-line react/destructuring-assignment
    args: [item.event.id, false],
    equalityFn: shallowEqual,
  });
  const eventName = useBettingGetterSelector({
    getterSelector: eventNameSelector,
    // eslint-disable-next-line react/destructuring-assignment
    args: [item.event.id],
  });

  const market = useBettingGetterSelector({
    getterSelector: marketSelector,
    // eslint-disable-next-line react/destructuring-assignment
    args: [item.market.id, false],
    equalityFn: shallowEqual,
  });
  const playerMarketName = market?.PlayerName
    ? `${market?.PlayerName} / ${market?.TeamName}`
    : '';

  const outcome = useBettingGetterSelector({
    getterSelector: outcomeSelector,
    // eslint-disable-next-line react/destructuring-assignment
    args: [item.outcome.id, false],
    equalityFn: shallowEqual,
  });
  const outcomeName = outcome?.Name;

  const oddValue = useBettingSlipItemBaseOdds(bsId)(item.id);

  return (
    <div>
      <div>
        {event?.EventId} - {eventName}
      </div>
      <div>
        {market?.MarketId} - {market?.MarketName} - {playerMarketName}
      </div>
      <div>
        {outcome?.OutcomeId} - {outcomeName} - {oddValue}
      </div>
    </div>
  );
};
