import { useEffect, useState } from 'react';

import { useNetwork } from '@gaming1/g1-core';
import { Heading, SimpleButton, Table } from '@gaming1/g1-ui';

import { stringifyRequestContent } from '../../helpers';
import { RequestContent } from '../styles';

import { ActionsDispatcherForm } from './ActionsDispatcherForm';
import { ResponseMockForm } from './ResponseMockForm';
import { TableData } from './styles';

/** Allow to set mock responses to be sent by wsAdapter instead of the real one
 * sent back from the backend for debug purpose */
export const MockResponseDebugger = () => {
  const { wsAdapter } = useNetwork();
  const [mockResponses, setMockResponses] = useState<Record<string, unknown>>(
    {},
  );
  const [highlightedRequests, setHighlightedRequests] = useState<string[]>([]);

  useEffect(() => {
    const subscription = wsAdapter.overwrittenResponses$.subscribe({
      next: setMockResponses,
    });
    return () => subscription.unsubscribe();
  }, [wsAdapter.overwrittenResponses$]);

  return (
    <>
      <Heading level="h1" mb="md">
        Mock Responses debug tool
      </Heading>
      <ActionsDispatcherForm />
      <ResponseMockForm
        onSubmit={({ content, name }) =>
          wsAdapter.setOverwrittenResponse(name, content)
        }
      />
      <Table>
        <thead>
          <tr>
            <th>Request name</th>
            <th>&nbsp;</th>
            <th>Content</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(mockResponses).map(([name, content]) => {
            const isHighLighted = highlightedRequests.includes(name);
            return (
              <tr key={name}>
                <TableData>{name}</TableData>
                <TableData>
                  <SimpleButton
                    type="button"
                    onClick={() =>
                      setHighlightedRequests((previousValue) =>
                        isHighLighted
                          ? previousValue.filter(
                              (requestName) => requestName !== name,
                            )
                          : [...previousValue, name],
                      )
                    }
                  >
                    {isHighLighted ? '🔼' : '🔽'}
                  </SimpleButton>
                </TableData>
                <TableData>
                  <RequestContent shouldBeTruncated={!isHighLighted}>
                    {stringifyRequestContent(content, isHighLighted)}
                  </RequestContent>
                </TableData>
                <TableData>
                  <SimpleButton
                    onClick={() => wsAdapter.setOverwrittenResponse(name)}
                  >
                    ❌
                  </SimpleButton>
                </TableData>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
