import { useState, VFC } from 'react';
import { Form } from 'react-final-form';

import { Button, Col, Label, Message, Row } from '@gaming1/g1-ui';

import { TextInputField } from '../../../form/components/TextInputField';
import { logger } from '../../../logger';

import { AppFieldset, AppLegend } from './styles';
import { TextAreaInputField } from './TextareaInputField';

type MockResponseForm = {
  name: string;
  content: string;
};

type ResponseMockFormProps = {
  onSubmit: (formData: MockResponseForm) => void;
};

export const ResponseMockForm: VFC<ResponseMockFormProps> = ({ onSubmit }) => {
  const [formError, setFormError] = useState('');

  const handleFormSubmit = (formData: MockResponseForm) => {
    try {
      const parsedContent = JSON.parse(formData.content);
      setFormError('');
      onSubmit({ name: formData.name, content: parsedContent });
    } catch (e) {
      logger.error('[Debug panel] Could not parse mock response content', e);
      setFormError(
        'Could not parse content. Ensure you are inputing a valid json object (that means every property should be wrapped into quotes)',
      );
    }
  };

  return (
    <Form<MockResponseForm>
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, form }) => (
        <AppFieldset>
          <AppLegend>Responses mocking</AppLegend>
          {!!formError && (
            <Message mb="sm" type="danger">
              {formError}
            </Message>
          )}
          <Row>
            <Col md={8}>
              <Label htmlFor="content">JSON Content</Label>
              <TextAreaInputField name="content" required />
            </Col>
            <Col md={4}>
              <TextInputField
                autoHideLabel={false}
                name="name"
                label="Request name"
                required
                wrapperProps={{ mt: 0 }}
              />
              <Row>
                <Col sm={6}>
                  <Button
                    type="tertiary"
                    onClick={() => form.restart()}
                    mt="sm"
                  >
                    Clear
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button type="submit" onClick={handleSubmit} mt="sm">
                    Add mock
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </AppFieldset>
      )}
    />
  );
};
