import { createAction, createAsyncAction } from 'typesafe-actions';

import { ChangeStateLicencesRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import { ChangeLicencesResponse, GetLicencesResponse } from './types';

/* Async actions */

/** Get user licenses */
export const getLicenses = createAsyncAction(
  'user/get_licenses_request',
  'user/get_licenses_success',
  'user/get_licenses_failure',
)<void, GetLicencesResponse, FailurePayload>();

/** Change user licenses */
export const changeLicenses = createAsyncAction(
  'user/change_licenses_request',
  'user/change_licenses_success',
  'user/change_licenses_failure',
)<ChangeStateLicencesRequestDTO, ChangeLicencesResponse, FailurePayload>();

/**
 * The user clicked on a license checkbox
 */
export const clickUserLicense =
  createAction('user/license_click')<ChangeStateLicencesRequestDTO>();
