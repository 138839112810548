import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getPlayerMonthlyBoxInfo,
  getPlayerVipRankInfo,
  getPlayerWheelInfo,
  getScratchCardPrizeInfo,
  getVipInitialization,
  playMonthlyBox,
  playVipWheel,
  scratchCardConsume,
  scratchCardDetails,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../actions';
import { LoyaltyEpic } from '../types';

import {
  getPlayerMonthlyBoxInfoResponse,
  getPlayerVipRankInfoResponse,
  getPlayerWheelInfoResponse,
  getScratchcardDetailsResponse,
  getScratchCardPrizeInfoResponse,
  getVipInitializationResponse,
  playMonthlyBoxResponse,
  playVipWheelResponse,
  scratchCardConsumeResponse,
} from './codecs';
import {
  getPlayerMonthlyBoxInfoErrorMessages,
  getPlayerVipRankInfoErrorMessages,
  getPlayerWheelInfoErrorMessages,
  getScratchCardPrizeInfoErrorMessages,
  playMonthlyBoxErrorMessages,
  playVipWheelErrorMessages,
  vipInitializeErrorMessages,
} from './errorMessages';

/** Get Vip Initialization */
export const getVipInitializationEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getVipInitialization.request)),
    switchMap(() =>
      wsAdapter.request(getVipInitialization({})).pipe(
        mapGuard(getVipInitializationResponse),
        map(actions.getVipInitialization.success),
        catchError((err) =>
          of(
            actions.getVipInitialization.failure(
              createFailurePayload(err, vipInitializeErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Play Vip Wheel */
export const playVipWheelEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.playVipWheel.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(playVipWheel(payload)).pipe(
        mapGuard(playVipWheelResponse),
        map(actions.playVipWheel.success),
        catchError((err) =>
          of(
            actions.playVipWheel.failure(
              createFailurePayload(err, playVipWheelErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Play Monthly Box */
export const playMonthlyBoxEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.playMonthlyBox.request)),
    switchMap(() =>
      wsAdapter.request(playMonthlyBox({})).pipe(
        mapGuard(playMonthlyBoxResponse),
        map(actions.playMonthlyBox.success),
        catchError((err) =>
          of(
            actions.playMonthlyBox.failure(
              createFailurePayload(err, playMonthlyBoxErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get Player Wheel Info */
export const getPlayerWheelInfoEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPlayerWheelInfo.request)),
    switchMap(() =>
      wsAdapter.request(getPlayerWheelInfo({})).pipe(
        mapGuard(getPlayerWheelInfoResponse),
        map(actions.getPlayerWheelInfo.success),
        catchError((err) =>
          of(
            actions.getPlayerWheelInfo.failure(
              createFailurePayload(err, getPlayerWheelInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get Player Monthly Box Info */
export const getPlayerMonthlyBoxInfoEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPlayerMonthlyBoxInfo.request)),
    switchMap(() =>
      wsAdapter.request(getPlayerMonthlyBoxInfo({})).pipe(
        mapGuard(getPlayerMonthlyBoxInfoResponse),
        map(actions.getPlayerMonthlyBoxInfo.success),
        catchError((err) =>
          of(
            actions.getPlayerMonthlyBoxInfo.failure(
              createFailurePayload(err, getPlayerMonthlyBoxInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get player VIP rank info */
export const getPlayerVipRankInfoEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPlayerVipRankInfo.request)),
    switchMap(() =>
      wsAdapter.request(getPlayerVipRankInfo({})).pipe(
        mapGuard(getPlayerVipRankInfoResponse),
        map(actions.getPlayerVipRankInfo.success),
        catchError((err) =>
          of(
            actions.getPlayerVipRankInfo.failure(
              createFailurePayload(err, getPlayerVipRankInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get scratchcard details */
export const getScratchcardDetailsEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getScratchcardDetails.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(scratchCardDetails(payload)).pipe(
        mapGuard(getScratchcardDetailsResponse),
        map(actions.getScratchcardDetails.success),
        catchError((err) =>
          of(actions.getScratchcardDetails.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/** Consume scratchcard */
export const consumeScratchcardEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.consumeScratchcard.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(scratchCardConsume(payload)).pipe(
        mapGuard(scratchCardConsumeResponse),
        map(actions.consumeScratchcard.success),
        catchError((err) =>
          of(actions.consumeScratchcard.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/** Get scratchcard prize info */
export const getScratchCardPrizeInfoEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getScratchCardPrizeInfo.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getScratchCardPrizeInfo(payload)).pipe(
        mapGuard(getScratchCardPrizeInfoResponse),
        map(actions.getScratchCardPrizeInfo.success),
        catchError((err) =>
          of(
            actions.getScratchCardPrizeInfo.failure(
              createFailurePayload(err, getScratchCardPrizeInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
