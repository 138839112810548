import React, { VFC } from 'react';

import { PromotionType } from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { PictureSize } from '@gaming1/g1-requests-betting';

import {
  DetailsButton,
  PromotionInfo,
  PromotionPicture,
  StyledPromotionCard,
  Summary,
  SummaryTitle,
} from './styles';

type PromotionCardProps = {
  promotion: PromotionType;
  onClick: () => void;
};

export const PromotionCard: VFC<PromotionCardProps> = ({
  promotion,
  onClick,
}) => {
  const { t } = useTranslation('betting');

  const promotionSmallPictureUrl = promotion.Pictures?.find(
    (picture) => picture?.Size === PictureSize.Small,
  )?.Path;

  return (
    <StyledPromotionCard onClick={onClick} data-testid="promotion-card">
      <PromotionPicture>
        <img
          data-testid="promotion-picture"
          alt="promotion.img.alternativeText"
          src={promotionSmallPictureUrl}
        />
      </PromotionPicture>
      <PromotionInfo>
        <SummaryTitle>{promotion.SummaryTitle}</SummaryTitle>
        <Summary>{promotion.Summary}</Summary>
        <DetailsButton>{t('promotion.button.seeMore')}</DetailsButton>
      </PromotionInfo>
    </StyledPromotionCard>
  );
};
