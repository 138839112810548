import {
  EAcceptLegalPopupResponse,
  EShowLegalPopupResponse,
} from '@gaming1/g1-requests';

export const acceptLegalModalErrorMessages = {
  [EAcceptLegalPopupResponse.None]: 'core:error.internalServer', // 0
  [EAcceptLegalPopupResponse.InternalError]: 'core:error.internalServer', // 2
  [EAcceptLegalPopupResponse.InvalidPlayer]: 'core:error.invalidPlayer', // 3
  [EAcceptLegalPopupResponse.InvalidRoom]: 'core:error.invalidRoom', // 4
  [EAcceptLegalPopupResponse.InvalidBusinessSession]:
    'core:error.invalidSessionId', // 5
  [EAcceptLegalPopupResponse.LegalPopupDisabled]:
    'user.legalPopup.error.disabled', // 6
};

export const showLegalModalErrorMessages = {
  [EShowLegalPopupResponse.None]: 'core:error.internalServer', // 0
  [EShowLegalPopupResponse.InternalError]: 'core:error.internalServer', // 2
  [EShowLegalPopupResponse.InvalidPlayer]: 'core:error.invalidPlayer', // 3
  [EShowLegalPopupResponse.InvalidRoom]: 'core:error.invalidRoom', // 4
  [EShowLegalPopupResponse.InvalidBusinessSession]:
    'core:error.invalidSessionId', // 5
  [EShowLegalPopupResponse.LegalPopupDisabled]:
    'user.legalPopup.error.disabled', // 6
};
