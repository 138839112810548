import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { coreActions, userLoggedInSelector } from '@gaming1/g1-core';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import {
  AppAnchor,
  Button,
  CheckboxInput,
  Heading,
  Modal,
} from '@gaming1/g1-ui';
import {
  acceptPrivacyConditionsRequestStateSelector,
  actions,
  showPrivacyConditionsSelector,
} from '@gaming1/g1-user';
import { usePrevious, useRequestCallback } from '@gaming1/g1-utils';

import { PrivacyModalContent } from './styles';

/**
 * The PrivacyConditionsModal is mainly used by belgian partners to warn the user a
 * deposit limit has been applied to his account and he has to approve it.
 *
 * This modal can't be closed by any other mean than to accept or refuse.
 *
 * Refusing should log the user out
 */
export const PrivacyConditionsModal: FC = () => {
  const { t } = useTranslation(['user', 'core']);

  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasAcceptedConditions, setHasAcceptedConditions] = useState(false);

  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const previousLoggedInState = usePrevious(isUserLoggedIn);

  const shouldShowConditions = useSelector(showPrivacyConditionsSelector);

  const { isLoading, status: acceptPrivacyConditionsStatus } = useRequestState(
    acceptPrivacyConditionsRequestStateSelector,
  );

  const handleAcceptConditions = () => {
    dispatch(actions.acceptPrivacyConditions.request());
  };

  const handleRefuseConditions = () => {
    setHasAcceptedConditions(false);
    setIsModalVisible(false);

    dispatch(coreActions.logout.request());
  };

  /** Ask if Privacy Conditions have to be accepted */
  useEffect(() => {
    if (!previousLoggedInState && isUserLoggedIn) {
      dispatch(actions.showPrivacyConditions.request());
    }
  }, [dispatch, isUserLoggedIn, previousLoggedInState]);

  /** Display modal when ready */
  useEffect(() => {
    if (shouldShowConditions) {
      setIsModalVisible(true);
    }
  }, [shouldShowConditions]);

  /** Close modal on accept Privacy Conditions request success */
  useRequestCallback(acceptPrivacyConditionsStatus, () => {
    setIsModalVisible(false);
  });

  return (
    <Modal
      modalId="privacyModal"
      onClose={() => null}
      isClosableByUser={false}
      showCloseButton={false}
      visible={isModalVisible}
    >
      <Heading level="h2" mt="0" data-testid="privacyHeading">
        {t('privacyConditions.modal.heading')}
      </Heading>

      <PrivacyModalContent data-testid="privacyContent">
        <Trans
          i18nKey="privacyConditions.modal.content"
          ns="user"
          components={{
            //  The href is set directly inside the translation
            AppAnchor: <AppAnchor target="_blank" rel="noopener" />,
            Emphasis: <em />,
            List: <ul />,
            ListItem: <li />,
            Paragraph: <p />,
          }}
        />
      </PrivacyModalContent>

      <CheckboxInput
        checked={hasAcceptedConditions}
        data-testid="privacyCheckbox"
        label={t('privacyConditions.modal.checkbox')}
        onChange={() => {
          setHasAcceptedConditions(!hasAcceptedConditions);
        }}
      />
      <Button
        block
        disabled={!hasAcceptedConditions}
        loading={isLoading}
        mt="lg"
        onClick={handleAcceptConditions}
        testId="privacyAcceptBtn"
        type="primary"
      >
        {t('core:button.confirm')}
      </Button>
      <Button
        block
        noBorder
        onClick={handleRefuseConditions}
        testId="privacyRefuseBtn"
        type="secondary"
      >
        {t('core:button.cancel')}
      </Button>
    </Modal>
  );
};
