import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  autoExclude,
  blacklistPlayer,
  EExclusionType,
  getAutoExcludeInfo,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  autoExcludeResponse,
  blacklistPlayerResponse,
  getAutoExcludeInfoResponse,
} from './codecs';
import {
  blacklistPlayerErrorMessages,
  selfExclusionErrorMessages,
} from './errorMessages';

/** Trigger a request for a gaming pause  */
export const setGamePauseEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.setGamePause.request)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(
          autoExclude({
            Duration: payload.durationInMinutes,
            ExcludeType: EExclusionType.GamePause,
          }),
        )
        .pipe(
          mapGuard(autoExcludeResponse),
          mergeMap(() =>
            wsAdapter
              .request(getAutoExcludeInfo({}))
              .pipe(mapGuard(getAutoExcludeInfoResponse)),
          ),
          map(actions.setGamePause.success),
          catchError((err) =>
            of(
              actions.setGamePause.failure(
                createFailurePayload(err, selfExclusionErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
/** Self exclude the user */
export const selfExclusionEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.setSelfExclusion.request)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(
          autoExclude({
            Duration: payload.Duration,
            ExcludeType: EExclusionType.AutoExclude,
          }),
        )
        .pipe(
          mapGuard(autoExcludeResponse),
          map(() => actions.setSelfExclusion.success()),
          catchError((err) =>
            of(
              actions.setSelfExclusion.failure(
                createFailurePayload(err, selfExclusionErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Get user self exclusion info */
export const getSelfExclusionInfoEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getSelfExclusionInfo.request)),
    switchMap(() =>
      wsAdapter.request(getAutoExcludeInfo({})).pipe(
        mapGuard(getAutoExcludeInfoResponse),
        map((response) => actions.getSelfExclusionInfo.success(response)),
        catchError((err) =>
          of(
            actions.getSelfExclusionInfo.failure(
              createFailurePayload(err, selfExclusionErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Close account of the user */

export const closeAccountEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.closeAccount.request)),
    switchMap(() =>
      wsAdapter
        .request(
          autoExclude({
            Duration: -1,
            ExcludeType: EExclusionType.CloseAccount,
          }),
        )
        .pipe(
          mapGuard(autoExcludeResponse),
          map(() => actions.closeAccount.success()),
          catchError((err) =>
            of(
              actions.closeAccount.failure(
                createFailurePayload(err, selfExclusionErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Blacklist the player */
export const blacklistPlayerEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.blacklistPlayer.request)),
    switchMap(() =>
      wsAdapter.request(blacklistPlayer({})).pipe(
        mapGuard(blacklistPlayerResponse),
        map(actions.blacklistPlayer.success),
        catchError((err) =>
          of(
            actions.blacklistPlayer.failure(
              createFailurePayload(err, blacklistPlayerErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
