import { NotificationCode } from '@gaming1/g1-requests-betting';

import { EFrontErrorType } from './types';

export type NotificationLevel = 'danger' | 'warning' | 'success' | undefined;

/*
 * chose/map the right level for an error
 */
/** Map for front error (by status) */
export const notificationStatusLevelMapping: Record<number, NotificationLevel> =
  {
    [EFrontErrorType.InvalidOdd]: 'danger',
    [EFrontErrorType.NotEnoughCredit]: 'danger',
    [EFrontErrorType.NoStake]: 'danger',
    [EFrontErrorType.MinMax]: 'danger',
    [EFrontErrorType.Min]: 'danger',
    [EFrontErrorType.Max]: 'danger',
    [EFrontErrorType.InvalidBoostus]: 'danger',
    [EFrontErrorType.BoostusMaxProfit]: 'danger',
    [EFrontErrorType.NoStakeWithMin]: 'danger',
    [EFrontErrorType.FirstDepositRequired]: 'danger',
  };

/** Map for interaction error (by code) */
export const notificationCodeLevelMapping: Record<number, NotificationLevel> = {
  [NotificationCode.RiskManagement_Success]: 'success',
  [NotificationCode.RiskManagement_InvalidBettingSlipType]: 'danger',
  [NotificationCode.RiskManagement_InternalError]: 'danger',
  [NotificationCode.RiskManagement_UnspecifiedError]: 'danger',
  [NotificationCode.RiskManagement_TooFewElements]: 'danger',
  [NotificationCode.RiskManagement_InvalidOutcomeCombination]: 'danger',
  [NotificationCode.RiskManagement_LiveSystemNotAllowed]: 'danger',
  [NotificationCode.RiskManagement_StakeTooHigh]: 'danger',
  [NotificationCode.RiskManagement_StakeTooLow]: 'danger',
  [NotificationCode.RiskManagement_TimingFreeze]: 'danger',

  [NotificationCode.PlaceBet_Success]: 'success',
  [NotificationCode.PlaceBet_InternalError]: 'danger',
  [NotificationCode.PlaceBet_TimeSpentLimitReached]: 'danger',
  [NotificationCode.PlaceBet_PlayerCheckFailed]: 'danger',
  [NotificationCode.PlaceBet_RunningGame]: 'danger',
  [NotificationCode.PlaceBet_ValidationError]: 'danger',
  [NotificationCode.PlaceBet_CreditTransferError]: 'danger',
  [NotificationCode.PlaceBet_InvalidPlayer]: 'danger',
  [NotificationCode.PlaceBet_FreebetNotFound]: 'danger',
  [NotificationCode.PlaceBet_LossLimitExceeded]: 'danger',
  [NotificationCode.PlaceBet_AmountAboveLimit]: 'danger',
  [NotificationCode.PlaceBet_NoFreebetGrantedBeforeMinElementStartDate]:
    'danger',
};
