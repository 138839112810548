import * as t from 'io-ts';

import { NavigationLinkType } from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { conditionListCodec } from '../common/gifts/codecs';
import { navigationDataCodec } from '../navigation/store/codecs';
import { notificationCodec } from '../notification/codecs';

export const boostusCodec = t.intersection([
  t.type({
    BoostusId: t.string,
    BoostValue: t.number,
    StartDate: t.string,
    EndDate: t.string,
    CreatedDate: t.string,
  }),
  t.partial({
    /**
     * @deprecated
     */
    LinkType: createEnumType<NavigationLinkType>(NavigationLinkType),
    FriendlyName: t.string,
    Title: t.string,
    Description: t.string,
    UsedDate: t.string,
    ConditionId: t.string,
    NavigationData: navigationDataCodec,
  }),
]);

export const hasBoostusResponseCodec = t.type(
  {
    IsAvailable: t.boolean,
    Notification: notificationCodec,
  },
  'GetHasBoostus',
);

export const getAvailableBoostusCodec = t.intersection([
  t.type({
    Boostuses: t.array(boostusCodec),
    Notification: notificationCodec,
  }),
  t.partial({
    Conditions: t.array(conditionListCodec),
  }),
]);
