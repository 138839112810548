import { shallowEqual } from 'react-redux';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import { isValidBoostusForBSGetterSelector } from '../../store/selectors/gifts/boostusConditions';

/**
 *  This hook will fetch the Notification to send (valid or not valid).
 *  It's a very simple hook that could have been removed BUT as we always get
 *  the validity of a freebet, item, etc... via a hook, we wanted to keep this
 *  logic and not break it by having only one component that would use directly
 *  a selector instead of passing through a hook.
 */
export const useBettingSlipBoostusErrorType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const isValid = useBettingGetterSelector({
    getterSelector: isValidBoostusForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  return () => isValid;
};
