import * as t from 'io-ts';

import {
  ManualBetPlayerStatus,
  PlayerCashoutPolicy,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { notificationCodec } from '../notification/codecs';

export const playerCashoutProfileCodec = t.type(
  {
    CashoutPolicy: createEnumType<PlayerCashoutPolicy>(PlayerCashoutPolicy),
  },
  'PlayerCashoutProfileCodec',
);

export const playerBettingProfileResponseCodec = t.partial(
  {
    Notification: notificationCodec,
    CashoutProfile: playerCashoutProfileCodec,
    FirstDepositDone: t.boolean,
    SuperOddsStatus: createEnumType<ManualBetPlayerStatus>(
      ManualBetPlayerStatus,
    ),
  },
  'PlayerBettingProfileResponseCodec',
);
