import styled, { css } from 'styled-components';
import { height, maxWidth, space, width } from 'styled-system';

import {
  ColorName,
  colors,
  elevation,
  media,
  spaces,
  zIndex,
} from '@gaming1/g1-style';

import { SCROLL_BAR_BORDER_SIZE } from '../constants';
import { SimpleButton } from '../styles';

const CLOSE_BUTTON_HEIGHT_IN_PX = 32;
const CLOSE_BUTTON_ICON_HEIGHT_IN_PX = 18;

const MODAL_BORDER_RADIUS = 24;

type ModalContainerProps = {
  elevationLevel?: 1 | 2 | 3 | 4;
  hasIcon: boolean;
  fullscreen?: boolean;
};

export const ModalContainer = styled.div<ModalContainerProps>`
  ${({ elevationLevel }) => elevation(elevationLevel || 4)};
  border-radius: ${MODAL_BORDER_RADIUS}px;
  position: fixed;
  width: 90%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: ${colors('text')};
  transition: transform 0.5s;
  -webkit-overflow-scrolling: touch;
  z-index: ${zIndex('modal')};

  ${({ hasIcon }) =>
    css`
      overflow: ${hasIcon ? 'visible' : 'hidden'};
    `};

  ${height}
  ${width}
  ${space}

  /* Removes the outline created by the tabindex attr */
  &:focus {
    outline: none;
  }

  ${media.md} {
    ${({ fullscreen }) =>
      css`
        max-width: ${fullscreen ? 100 : 50}%;
        ${maxWidth}
      `};
  }

  ${media.lg} {
    ${({ fullscreen }) =>
      css`
        max-width: ${fullscreen ? 100 : 33.333}%;
        ${maxWidth}
      `};
  }

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      height: 100%;
      width: 100%;
      max-width: 100%;
      top: 0;
      left: 0;
      border-radius: 0;
      transform: none;
    `}
`;

export const CloseButton = styled(SimpleButton)`
  position: absolute;
  top: ${spaces('xxs')};
  right: ${SCROLL_BAR_BORDER_SIZE}px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2; /* Ensure the CloseButton is above the ModalContentDiv */

  width: ${CLOSE_BUTTON_HEIGHT_IN_PX}px;
  height: ${CLOSE_BUTTON_HEIGHT_IN_PX}px;

  svg {
    height: ${CLOSE_BUTTON_ICON_HEIGHT_IN_PX}px;

    path {
      fill: ${colors('text')};
    }
  }
`;

type ModalContentDivProps = {
  fullscreen?: boolean;
  noPadding?: boolean;
  noCloseButton?: boolean;
};

export const ModalContentDiv = styled.div<ModalContentDivProps>`
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-bottom: ${MODAL_BORDER_RADIUS}px;
  margin-left: 0;
  margin-right: ${SCROLL_BAR_BORDER_SIZE}px;
  margin-top: ${({ theme, noCloseButton }) =>
    !noCloseButton
      ? CLOSE_BUTTON_HEIGHT_IN_PX + theme.space.xxs
      : MODAL_BORDER_RADIUS}px;
  padding-bottom: 0;
  padding-left: ${spaces('sm')};
  padding-right: ${({ theme }) => theme.space.sm - SCROLL_BAR_BORDER_SIZE}px;
  padding-top: 0;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
      margin: 0;
    `}

  overflow-y: auto;
  max-height: 80vh;
  > * {
    flex-shrink: 0;
  }

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      height: 100%;
      width: 100%;
      max-height: 100%;
    `}
`;

type ModalIconWrapperProps = {
  bgColor: ColorName;
  wrapperHeight: string;
  wrapperWidth: string;
};
export const ModalIconWrapper = styled.div<ModalIconWrapperProps>`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  top: -${spaces('sm')};
  left: 50%;
  transform: translateX(-50%);

  ${({ bgColor, wrapperHeight, wrapperWidth }) =>
    css`
      background-color: ${colors(bgColor)};
      height: ${wrapperHeight};
      width: ${wrapperWidth};
    `}
`;
