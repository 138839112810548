import styled, { css } from 'styled-components';

import { colors, elevation, spaces } from '@gaming1/g1-style';
import { Collapsible, Popover } from '@gaming1/g1-ui';

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

type TableRowProps = {
  direction: number;
  isSelected: boolean;
};

export const TableRow = styled.tr<TableRowProps>`
  ${({ direction, isSelected }) => css`
    background-color: ${colors('backgrounds', {
      index: isSelected ? 3 : direction + 1,
    })};
  `}
`;

export const TableData = styled.td`
  vertical-align: top;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: ${spaces('xs')};
`;

export const FilterContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: bottom;
  flex-direction: row;
  align-items: center;
  & + & {
    margin-top: ${spaces('sm')};
  }
  & > label {
    margin-right: ${spaces('sm')};
  }
`;

export const FiltersContainerCollapsible = styled(Collapsible)`
  margin-top: ${spaces('sm')};
`;

export const HelpPopoverContent = styled(Popover.Content)`
  ${elevation(2)};
  color: ${colors('text')};
  padding: ${spaces('md')};
`;

type RequestNameToggleProps = {
  isSelected: boolean;
};

export const RequestNameToggle = styled.div<RequestNameToggleProps>`
  border: 1px solid ${colors('contentBorder')};
  margin: ${spaces('xxs')} 0;
  padding: ${spaces('xs')};

  & + & {
    margin-left: ${spaces('xs')};
  }

  ${({ isSelected }) => css`
    background-color: ${colors('backgrounds', { index: isSelected ? 2 : 1 })};
  `};
`;
