/* istanbul ignore file */
import { paymentPublicRoutes } from '@gaming1/g1-payment-api';
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

import { WithdrawalWalletType } from './withdrawal/types';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!
export const paymentRoutes = {
  ...paymentPublicRoutes,
  depositWallets: createRoute('/deposit/wallets'),
  depositWalletConfiguration: createRoute('/deposit/wallet-configuration'),
  depositProcessing: createRoute('/deposit/processing'),
  depositStatus: createRoute('/deposit/status'),
  depositSpecificWallet: createParamRoute<{ walletId: string }>(
    '/deposit/select-wallet/:walletId',
    {
      walletId: '',
    },
  ),
  withdrawalWalletConfiguration: createParamRoute<{
    walletType: WithdrawalWalletType | '';
  }>('/withdrawal/:walletType', { walletType: '' }),
  withdrawalSuccess: createRoute('/withdrawal/success'),
};
