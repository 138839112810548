import addMinutes from 'date-fns/addMinutes';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';

import {
  EventType as EEventType,
  EventInformation,
  FilterType,
  MarketInformation,
  OutcomeInformation,
  Provider,
  TradingState,
} from '@gaming1/g1-requests-betting';

import { BettingSlipItemState } from '../../../bettingSlip/store/types';
import { EventState } from '../../../common/store/types';
import { logger } from '../../../logger';
import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import {
  formatEvent,
  formatLeague,
  formatMarket,
  formatPlayerProp,
} from '../format';
import {
  LightEventType,
  LightMarketType,
  LightOutcomeType,
  NormalizedEvent,
  NormalizedMarket,
  NormalizedOutcome,
  OutcomeEventAndMarketIds,
  OutcomeInfoType,
  SportbookUpdateType,
  SportIds,
} from '../types';

const hasIdToUpdate = <
  T extends {
    IdToUpdate?: number | null;
  },
>(
  updateData: T,
): updateData is T & { IdToUpdate: number } =>
  typeof updateData.IdToUpdate === 'number';

/**
 * Return an array of update actions to be sent to the reducers based on a list
 * of entity updates sent by the backend as multicast
 * @param updates the EntityUpdates array from the push content
 */
export const sportbookUpdatesToActions = (
  updates: SportbookUpdateType[],
  isFullEvent = false,
  key = '',
): BettingActions[] =>
  updates.reduce<BettingActions[]>((acc, update) => {
    const {
      LeagueUpdate,
      EventUpdate,
      MarketUpdate,
      OutcomeUpdate,
      PlayerPropUpdate,
      PlayerMarketUpdate,
      PlayerOutcomeUpdate,
      LiveCount,
    } = update;

    if (typeof LiveCount === 'number') {
      acc.push(actions.updateLiveEventsCount(LiveCount));
    }

    if (LeagueUpdate) {
      if (LeagueUpdate.NewEntity) {
        acc.push(
          actions.addLeague({
            key,
            isFullEvent,
            ...formatLeague(LeagueUpdate.NewEntity),
          }),
        );
      } else if (LeagueUpdate.IdToRemove) {
        acc.push(
          actions.removeLeague({
            key,
            leagueId: LeagueUpdate.IdToRemove,
          }),
        );
      } else {
        logger.debug('could not treat league update', LeagueUpdate);
      }
    }

    if (EventUpdate) {
      if (EventUpdate.NewEntity) {
        acc.push(
          actions.addEvent({
            key,
            isFullEvent,
            ...formatEvent(EventUpdate.NewEntity),
          }),
        );
      } else if (EventUpdate.IdToRemove && EventUpdate.LeagueId) {
        acc.push(
          actions.removeEvent({
            key,
            eventId: EventUpdate.IdToRemove,
            leagueId: EventUpdate.LeagueId,
          }),
        );
      } else if (hasIdToUpdate(EventUpdate)) {
        acc.push(actions.updateEvent(EventUpdate));
      } else {
        logger.debug('could not treat event update', EventUpdate);
      }
    }

    if (MarketUpdate) {
      if (MarketUpdate.NewEntity) {
        acc.push(
          actions.addMarket({
            isFullEvent,
            ...formatMarket(MarketUpdate.NewEntity),
          }),
        );
      } else if (MarketUpdate.IdToRemove && MarketUpdate.EventId) {
        acc.push(
          actions.removeMarket({
            isFullEvent,
            marketId: MarketUpdate.IdToRemove,
            eventId: MarketUpdate.EventId,
          }),
        );
      } else if (hasIdToUpdate(MarketUpdate)) {
        acc.push(actions.updateMarket(MarketUpdate));
      } else {
        logger.debug('could not treat market update', MarketUpdate);
      }
    }

    if (OutcomeUpdate) {
      if (OutcomeUpdate.NewEntity && OutcomeUpdate.MarketId) {
        acc.push(
          actions.addOutcome({
            outcome: OutcomeUpdate.NewEntity,
            marketId: OutcomeUpdate.MarketId,
          }),
        );
      } else if (OutcomeUpdate.IdToRemove && OutcomeUpdate.MarketId) {
        acc.push(
          actions.removeOutcome({
            outcomeId: OutcomeUpdate.IdToRemove,
            marketId: OutcomeUpdate.MarketId,
          }),
        );
      } else if (hasIdToUpdate(OutcomeUpdate)) {
        acc.push(actions.updateOutcome(OutcomeUpdate));
      } else {
        logger.debug('could not treat outcome update', OutcomeUpdate);
      }
    }

    if (PlayerPropUpdate) {
      if (PlayerPropUpdate.NewEntity) {
        acc.push(
          actions.addPlayerProp(formatPlayerProp(PlayerPropUpdate.NewEntity)),
        );
      } else if (PlayerPropUpdate.IdToRemove && PlayerPropUpdate.EventId) {
        acc.push(
          actions.removePlayerProp({
            playerPropId: PlayerPropUpdate.IdToRemove,
            eventId: PlayerPropUpdate.EventId,
          }),
        );
      } else if (hasIdToUpdate(PlayerPropUpdate)) {
        acc.push(actions.updatePlayerProp(PlayerPropUpdate));
      } else {
        logger.debug('could not treat playerProp update', PlayerPropUpdate);
      }
    }

    if (PlayerMarketUpdate) {
      if (PlayerMarketUpdate.NewEntity) {
        acc.push(
          actions.addPlayerMarket({
            playerPropId: PlayerMarketUpdate.PlayerPropId,
            ...formatMarket(PlayerMarketUpdate.NewEntity),
          }),
        );
      } else if (
        PlayerMarketUpdate.IdToRemove &&
        PlayerMarketUpdate.PlayerPropId
      ) {
        acc.push(
          actions.removePlayerMarket({
            playerPropId: PlayerMarketUpdate.PlayerPropId,
            marketId: PlayerMarketUpdate.IdToRemove,
          }),
        );
      } else if (hasIdToUpdate(PlayerMarketUpdate)) {
        acc.push(actions.updateMarket(PlayerMarketUpdate));
      } else {
        logger.debug(
          'could not treat player market update',
          PlayerMarketUpdate,
        );
      }
    }

    if (PlayerOutcomeUpdate) {
      if (PlayerOutcomeUpdate.NewEntity && PlayerOutcomeUpdate.MarketId) {
        acc.push(
          actions.addOutcome({
            outcome: PlayerOutcomeUpdate.NewEntity,
            marketId: PlayerOutcomeUpdate.MarketId,
          }),
        );
      } else if (
        PlayerOutcomeUpdate.IdToRemove &&
        PlayerOutcomeUpdate.MarketId
      ) {
        acc.push(
          actions.removeOutcome({
            outcomeId: PlayerOutcomeUpdate.IdToRemove,
            marketId: PlayerOutcomeUpdate.MarketId,
          }),
        );
      } else if (hasIdToUpdate(PlayerOutcomeUpdate)) {
        acc.push(actions.updateOutcome(PlayerOutcomeUpdate));
      } else {
        logger.debug(
          'could not treat player outcome update',
          PlayerOutcomeUpdate,
        );
      }
    }

    return acc;
  }, []);

export const temporalFilterEvent = (
  eventDate: Date | string,
  filterDate: Date | string,
  filterType: FilterType,
) => {
  const eventDateLocale = new Date(eventDate);

  const filterDateLocale = addMinutes(
    new Date(filterDate),
    new Date(filterDate).getTimezoneOffset(),
  );

  const isEventSameDay = filterDate
    ? isSameDay(eventDateLocale, filterDateLocale)
    : true;

  const isEventAfter =
    filterType === FilterType.Later
      ? isAfter(eventDateLocale, filterDateLocale)
      : true;

  return filterType === FilterType.Later ? isEventAfter : isEventSameDay;
};

/**
 * Return an object containing arrays which has the outcome, event and market identifiers of each items.
 * @param items the items from a bettingSlip that we need to split in outcome, event and market ids.
 */
export const getOutcomeAndEventIdsFromItems = (
  items: BettingSlipItemState[] | undefined,
): OutcomeEventAndMarketIds[] => {
  const ids = items?.map((item) => ({
    outcomeId: item.outcome.id,
    eventId: item.event.id,
    marketId: item.market.id,
  }));

  return ids || [];
};

export const checkEventType = (type: EventState, event?: NormalizedEvent) =>
  type === 'live'
    ? event?.EventType === EEventType.Live
    : event?.EventType === EEventType.Prematch;

/**
 *
 * @param sports sports displayed in navigation
 * @returns sports sorted by liveOrder then by sportId
 */
export const getSortedSports = (sports: SportIds[]) =>
  sports.sort((sportA, sportB) => {
    if (sportA.liveOrder !== sportB.liveOrder) {
      return sportA.liveOrder - sportB.liveOrder;
    }

    return (sportA.sportId || 0) - (sportB.sportId || 0);
  });

export const initializeEvent = (data: {
  eventInformation?: EventInformation;
  outcomeInfo?: OutcomeInfoType;
  lightEvent?: LightEventType;
}): NormalizedEvent => {
  if (data.eventInformation) {
    const { eventInformation, outcomeInfo } = data;
    return {
      EventId: eventInformation.EventId,
      HomeName: eventInformation.HomeName || '',
      AwayName: eventInformation.AwayName || '',
      EventType: eventInformation.EventType,
      IsBlocked: eventInformation.IsBlocked,
      IsCashable: outcomeInfo?.MarketInfo?.IsCashable ?? false,
      IsWinner: false,
      LeagueId: outcomeInfo?.LeagueInfo?.LeagueId || -1,
      NbTotalMarkets: 0,
      StartDate: outcomeInfo?.EventInfo?.StartDate || '',
    };
  }

  const { lightEvent } = data;
  return {
    EventId: lightEvent?.EventId || 0,
    HomeName: lightEvent?.HomeName || '',
    AwayName: lightEvent?.AwayName || '',
    EventType: EEventType.Prematch,
    IsBlocked: false,
    IsCashable: false,
    IsWinner: false,
    LeagueId: 0,
    NbTotalMarkets: 0,
    StartDate: '',
  };
};

export const initializeMarket = (data: {
  marketInformation?: MarketInformation;
  eventInformation?: EventInformation;
  lightMarket?: LightMarketType;
}): NormalizedMarket => {
  if (data.eventInformation && data.marketInformation) {
    const { eventInformation, marketInformation } = data;

    return {
      MarketId: marketInformation.MarketId,
      MarketName: marketInformation.MarketName || '',
      PlayerName: marketInformation.PlayerName,
      TeamName: marketInformation.TeamName,
      EventId: eventInformation.EventId,
      IsCashable: marketInformation.IsCashable,
      State: marketInformation.State,
      Provider: marketInformation.Provider,
    };
  }

  const { lightMarket } = data;
  return {
    MarketId: lightMarket?.MarketId || 0,
    MarketName: lightMarket?.MarketName || '',
    ColCount: 0,
    EventId: lightMarket?.EventId || 0,
    Order: 0,
    IsCashable: false,
    State:
      lightMarket?.State === undefined
        ? TradingState.Closed
        : lightMarket.State,
    Provider: lightMarket?.Provider || Provider.Unknown,
    ColumnReorder: 0,
  };
};

export const initializeOutcome = (data: {
  outcomeInformation?: OutcomeInformation;
  marketInformation?: MarketInformation;
  eventInformation?: EventInformation;
  lightOutcome?: LightOutcomeType;
}): NormalizedOutcome => {
  if (
    data.outcomeInformation &&
    data.marketInformation &&
    data.eventInformation
  ) {
    const { eventInformation, outcomeInformation, marketInformation } = data;

    return {
      Name: outcomeInformation.OutcomeName,
      Base: outcomeInformation.Base,
      Odd: outcomeInformation.Odd,
      MarketId: marketInformation.MarketId,
      OutcomeId: outcomeInformation.OutcomeId,
      EventId: eventInformation.EventId,
    };
  }

  const { lightOutcome } = data;
  return {
    OutcomeId: lightOutcome?.OutcomeId || 0,
    EventId: lightOutcome?.EventId || 0,
    MarketId: lightOutcome?.MarketId || 0,
    Name: lightOutcome?.Name || '',
    Odd: lightOutcome?.Odd || -1,
    Base: lightOutcome?.Base,
    GroupOrder: 0,
  };
};
