import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { LoyaltyActions } from '../types';

import * as actions from './actions';
import { ClubState } from './types';

export const initialState: ClubState = {
  consumeScratchcard: null,
  playerMonthlyBoxInfo: null,
  playerVipRankInfo: null,
  playerWheelInfo: null,
  playMonthlyBoxData: null,
  scratchcardDetails: null,
  scratchCardPrizeInfo: null,
  vipWheelWonPrizeId: null,
  vipInitData: null,
  requests: {
    consumeScratchcard: generateInitialRequestState(null),
    getPlayerMonthlyBoxInfo: generateInitialRequestState(null),
    getPlayerVipRankInfo: generateInitialRequestState(null),
    getPlayerWheelInfo: generateInitialRequestState(null),
    getScratchcardDetails: generateInitialRequestState(null),
    getScratchCardPrizeInfo: generateInitialRequestState(null),
    getVipInitialization: generateInitialRequestState(null),
    playMonthlyBox: generateInitialRequestState(null),
    playVipWheel: generateInitialRequestState(null),
  },
};

export const clubReducer = (
  state: ClubState = initialState,
  action: LoyaltyActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getVipInitialization.request):
        produceLoadingState(draftState.requests.getVipInitialization);
        break;

      case getType(actions.getVipInitialization.failure):
        produceFailureState(
          draftState.requests.getVipInitialization,
          action.payload,
        );
        break;

      case getType(actions.getVipInitialization.success):
        produceSuccessState(draftState.requests.getVipInitialization);
        draftState.vipInitData = action.payload;
        break;

      case getType(actions.playVipWheel.request):
        produceLoadingState(draftState.requests.playVipWheel);
        break;

      case getType(actions.playVipWheel.failure):
        produceFailureState(draftState.requests.playVipWheel, action.payload);
        break;

      case getType(actions.playVipWheel.success):
        produceSuccessState(draftState.requests.playVipWheel);
        draftState.vipWheelWonPrizeId = action.payload.Prize;
        break;

      case getType(actions.playMonthlyBox.request):
        produceLoadingState(draftState.requests.playMonthlyBox);
        break;

      case getType(actions.playMonthlyBox.failure):
        produceFailureState(draftState.requests.playMonthlyBox, action.payload);
        break;

      case getType(actions.playMonthlyBox.success):
        produceSuccessState(draftState.requests.playMonthlyBox);
        draftState.playMonthlyBoxData = action.payload;
        break;

      case getType(actions.getPlayerMonthlyBoxInfo.request):
        produceLoadingState(draftState.requests.getPlayerMonthlyBoxInfo);
        break;

      case getType(actions.getPlayerMonthlyBoxInfo.failure):
        produceFailureState(
          draftState.requests.getPlayerMonthlyBoxInfo,
          action.payload,
        );
        break;

      case getType(actions.getPlayerMonthlyBoxInfo.success):
        produceSuccessState(draftState.requests.getPlayerMonthlyBoxInfo);
        draftState.playerMonthlyBoxInfo = action.payload;
        break;

      case getType(actions.getPlayerWheelInfo.request):
        produceLoadingState(draftState.requests.getPlayerWheelInfo);
        break;

      case getType(actions.getPlayerWheelInfo.failure):
        produceFailureState(
          draftState.requests.getPlayerWheelInfo,
          action.payload,
        );
        break;

      case getType(actions.getPlayerWheelInfo.success):
        produceSuccessState(draftState.requests.getPlayerWheelInfo);
        draftState.playerWheelInfo = action.payload;
        break;

      case getType(actions.getPlayerVipRankInfo.request):
        produceLoadingState(draftState.requests.getPlayerVipRankInfo);
        break;

      case getType(actions.getPlayerVipRankInfo.failure):
        produceFailureState(
          draftState.requests.getPlayerVipRankInfo,
          action.payload,
        );
        break;

      case getType(actions.getPlayerVipRankInfo.success):
        produceSuccessState(draftState.requests.getPlayerVipRankInfo);
        draftState.playerVipRankInfo = action.payload;
        break;

      case getType(actions.resetVipData):
        return initialState;

      case getType(actions.getScratchcardDetails.request):
        produceLoadingState(draftState.requests.getScratchcardDetails);
        break;

      case getType(actions.getScratchcardDetails.failure):
        produceFailureState(
          draftState.requests.getScratchcardDetails,
          action.payload,
        );
        break;

      case getType(actions.getScratchcardDetails.success):
        produceSuccessState(draftState.requests.getScratchcardDetails);
        draftState.scratchcardDetails = action.payload;
        break;

      case getType(actions.consumeScratchcard.request):
        produceLoadingState(draftState.requests.consumeScratchcard);
        break;

      case getType(actions.consumeScratchcard.failure):
        produceFailureState(
          draftState.requests.consumeScratchcard,
          action.payload,
        );
        break;

      case getType(actions.consumeScratchcard.success):
        produceSuccessState(draftState.requests.consumeScratchcard);
        break;

      case getType(actions.getScratchCardPrizeInfo.request):
        produceLoadingState(draftState.requests.getScratchCardPrizeInfo);
        break;

      case getType(actions.getScratchCardPrizeInfo.failure):
        produceFailureState(
          draftState.requests.getScratchCardPrizeInfo,
          action.payload,
        );
        break;

      case getType(actions.getScratchCardPrizeInfo.success):
        produceSuccessState(draftState.requests.getScratchCardPrizeInfo);
        draftState.scratchCardPrizeInfo = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
