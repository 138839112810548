import { EChangeSecurityPinCodeStatus } from '@gaming1/g1-requests';

export const changeSecurityPinCodeErrorMessages = {
  [EChangeSecurityPinCodeStatus.InternalError]: 'core:error.internalServer', // 0
  [EChangeSecurityPinCodeStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EChangeSecurityPinCodeStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
  [EChangeSecurityPinCodeStatus.InvalidCurrentSecurityPinCode]:
    'user:account.message.invalidCurrentSecurityPinCode', // 4
  [EChangeSecurityPinCodeStatus.NewSecurityPinCodeMissing]:
    'user:account.message.newSecurityPinCodeMissing', // 5
  [EChangeSecurityPinCodeStatus.NewSecurityPinCodeTooLong]:
    'user:account.message.newSecurityPinCodeTooLong', // 6
  [EChangeSecurityPinCodeStatus.NewSecurityPinCodeTooShort]:
    'user:account.message.newSecurityPinCodeTooShort', // 7
  [EChangeSecurityPinCodeStatus.InvalidNewSecurityPinCode]:
    'user:account.message.invalidNewSecurityPinCode', // 8
};
