import {
  EGetConnectionLimitRemainingTimeStatus,
  EGetDepositLimitationResponseStatus,
  EGetLimitsResponseStatus,
  EGetSpentTimeLimitRemainingTimeStatus,
  ESetLimitsResponseStatus,
} from '@gaming1/g1-requests';

export const setLimitsErrorMessages = {
  [ESetLimitsResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [ESetLimitsResponseStatus.AlreadyLower]:
    'user:account.message.limitAlreadyLower', // 2
  [ESetLimitsResponseStatus.Unchanged]: 'user:account.message.limitUnchanged', // 3
  [ESetLimitsResponseStatus.LimitTooLow]: 'user:account.message.limitTooLow', // 4
  [ESetLimitsResponseStatus.LowerLimitationPending]:
    'user:account.message.lowerLimitationPending', // 5
  [ESetLimitsResponseStatus.RaiseNotAllowed]:
    'user:account.message.raiseLimitNotAllowed', // 6
  [ESetLimitsResponseStatus.LowerNotAllowed]:
    'user:account.message.lowerLimitNotAllowed', // 7
  [ESetLimitsResponseStatus.HigherLimitationPending]:
    'user:account.message.higherLimitationPending', // 8
  [ESetLimitsResponseStatus.LimitTooHigh]: 'user:account.message.limitTooHigh', // 9
  [ESetLimitsResponseStatus.InvalidRoom]: 'core:error.invalidRoom', // 10
};

export const getDepositLimitsErrorMessages = {
  [EGetDepositLimitationResponseStatus.InternalError]:
    'core:error.internalServer', // 0
};

export const getLimitsErrorMessages = {
  [EGetLimitsResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetLimitsResponseStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
};

export const getRemainingConnectionTimeErrorMessages = {
  [EGetConnectionLimitRemainingTimeStatus.InternalError]:
    'core:error.internalServer', // 0
  [EGetConnectionLimitRemainingTimeStatus.InvalidRoom]:
    'core:error.invalidRoom', // 2
  [EGetConnectionLimitRemainingTimeStatus.ConnectionTimeReached]:
    'user:account.message.connectionTimeReached', // 3
};

export const getRemainingTimeToSpendErrorMessages = {
  [EGetSpentTimeLimitRemainingTimeStatus.InternalError]:
    'core:error.internalServer', // 0
  [EGetSpentTimeLimitRemainingTimeStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
  [EGetSpentTimeLimitRemainingTimeStatus.SpentTimeLimitReached]:
    'user:account.message.spentTimeReached', // 3
};
