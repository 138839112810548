import { createContext, Dispatch, RefObject, SetStateAction } from 'react';

import {
  BettingSlipIdentifierType,
  BettingSlipStringType,
  EBettingSlipIdentifier,
} from '@gaming1/g1-betting';

const dummyFunction = () => null;

export const BettingSlipContext = createContext<{
  type: BettingSlipStringType;
  switchType: (type: BettingSlipStringType) => void;
  bettingSlipId: BettingSlipIdentifierType;
  setRefToScrollTo: (ref: RefObject<HTMLDivElement>) => void;
  refToScrollTo: RefObject<HTMLDivElement> | null;
  isInputStakeFocused: boolean;
  setIsInputStakeFocused: Dispatch<SetStateAction<boolean>>;
}>({
  type: 'combi',
  switchType: dummyFunction,
  bettingSlipId: EBettingSlipIdentifier.Missing,
  setRefToScrollTo: dummyFunction,
  refToScrollTo: null,
  isInputStakeFocused: false,
  setIsInputStakeFocused: dummyFunction,
});
