import { ApplicationWithUserState } from '../../store/types';

/** Accept Terms & Conditions */

export const acceptTermsAndConditionsRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.termsAndCondition.requests.acceptTermsAndConditions;

/** Get Terms & Conditions */

export const getTermsAndConditionsPathSelector = (
  state: ApplicationWithUserState,
) => state.user.termsAndCondition.path;

export const getTermsAndConditionsPathRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.termsAndCondition.requests.getTermsAndConditions;

/** Show Terms & Conditions */

export const showTermsAndConditionsSelector = (
  state: ApplicationWithUserState,
) => state.user.termsAndCondition.show;

export const showTermsAndConditionsRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.termsAndCondition.requests.showTermsAndConditions;
