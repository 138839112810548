import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  GetCreditCardWithdrawInfosRequestDTO,
  GetEWalletWithdrawInfosRequestDTO,
  GetTransferWithdrawInfosRequestDTO,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  CancelWithdrawalResponse,
  CancelWithdrawRequest,
  CreditCardWithdrawal,
  EWalletWithdrawal,
  GetCreditCardWithdrawInfoResponse,
  GetEWalletWithdrawInfoResponse,
  GetTransferWithdrawInfoResponse,
  GetWithdrawInfoResponse,
  InRoomWithdrawal,
  MakeCreditCardWithdrawResponse,
  MakeEWalletWithdrawResponse,
  MakeInRoomWithdrawResponse,
  MakeTransferWithdrawResponse,
  TransferWithdrawal,
} from './types';

/* Async actions */

export const getWithdrawalInfo = createAsyncAction(
  'payment/get_withdrawal_info_request',
  'payment/get_withdrawal_info_success',
  'payment/get_withdrawal_info_failure',
)<void, GetWithdrawInfoResponse, FailurePayload>();

export const getTransferWithdrawalInfo = createAsyncAction(
  'payment/get_transfer_withdrawal_info_request',
  'payment/get_transfer_withdrawal_info_success',
  'payment/get_transfer_withdrawal_info_failure',
)<
  GetTransferWithdrawInfosRequestDTO,
  GetTransferWithdrawInfoResponse,
  FailurePayload
>();

export const getEWalletWithdrawalInfo = createAsyncAction(
  'payment/get_EWallet_withdrawal_info_request',
  'payment/get_EWallet_withdrawal_info_success',
  'payment/get_EWallet_withdrawal_info_failure',
)<
  GetEWalletWithdrawInfosRequestDTO,
  GetEWalletWithdrawInfoResponse,
  FailurePayload
>();

export const getCreditCardWithdrawalInfo = createAsyncAction(
  'payment/get_creditcard_withdrawal_info_request',
  'payment/get_creditcard_withdrawal_info_success',
  'payment/get_creditcard_withdrawal_info_failure',
)<
  GetCreditCardWithdrawInfosRequestDTO,
  GetCreditCardWithdrawInfoResponse,
  FailurePayload
>();

export const makeInRoomWithdrawal = createAsyncAction(
  'payment/make_inRoom_withdrawal_request',
  'payment/make_inRoom_withdrawal_success',
  'payment/make_inRoom_withdrawal_failure',
)<InRoomWithdrawal, MakeInRoomWithdrawResponse, FailurePayload>();

export const cleanMakeInRoomWithdrawal = createAction(
  'payment/clean_make_inroom_withdrawal',
)();

export const makeTransferWithdrawal = createAsyncAction(
  'payment/make_transfer_withdrawal_request',
  'payment/make_transfer_withdrawal_success',
  'payment/make_transfer_withdrawal_failure',
)<TransferWithdrawal, MakeTransferWithdrawResponse, FailurePayload>();

export const cleanMakeTransferWithdrawal = createAction(
  'payment/clean_make_transfer_withdrawal',
)();

export const makeEWalletWithdrawal = createAsyncAction(
  'payment/make_ewallet_withdrawal_request',
  'payment/make_ewallet_withdrawal_success',
  'payment/make_ewallet_withdrawal_failure',
)<EWalletWithdrawal, MakeEWalletWithdrawResponse, FailurePayload>();

export const makeCreditCardWithdrawal = createAsyncAction(
  'payment/make_creditcard_withdrawal_request',
  'payment/make_creditcard_withdrawal_success',
  'payment/make_creditcard_withdrawal_failure',
)<CreditCardWithdrawal, MakeCreditCardWithdrawResponse, FailurePayload>();

export const cleanMakeEWalletWithdrawal = createAction(
  'payment/clean_make_ewallet_withdrawal',
)();

export const cleanMakeCreditCardWithdrawal = createAction(
  'payment/clean_make_creditcard_withdrawal',
)();

export const cancelWithdrawal = createAsyncAction(
  'payment/cancel_withdrawal_request',
  'payment/cancel_withdrawal_success',
  'payment/cancel_withdrawal_failure',
)<CancelWithdrawRequest, CancelWithdrawalResponse, FailurePayload>();
