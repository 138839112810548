import React, { createContext, FC, memo, useEffect, useState } from 'react';

import { logger } from '../../../logger';
import { useIframeMessages } from '../../hooks/useIframeMessages';

export const BlacklistedRoutesContext = createContext<RegExp[] | null>(null);

/** Pattern used to extract regexp delimiters from a stringified regexp
 * For example if we stringify /^test?/i it will be "/\/^test?\/i/"
 * If this string is given to the RegExp constructor, it will create the
 * //^test?/i/ pattern. So we need to remove the first and last slash +
 * extract the optional flags
 */
const stringifiedRegexPattern = /^\/(.+)\/([a-z])?$/;

/**
 * This provider manage the state of blacklist regex received from ReactNativeWebView
 */
export const BlacklistedRoutesProvider: FC = memo(({ children }) => {
  const [blacklistedRoutesRegexps, setBlacklistedRoutesRegexps] = useState<
    RegExp[] | null
  >(null);
  const { blackListedRoute$ } = useIframeMessages();

  useEffect(() => {
    const subscription = blackListedRoute$.subscribe(({ payload }) => {
      logger.info(
        '[BlacklistedRoutesProvider] New blacklisted routes received',
        payload,
      );
      setBlacklistedRoutesRegexps(
        payload.map((regexStr) => {
          const matches = stringifiedRegexPattern.exec(regexStr);
          if (matches && matches.length) {
            return new RegExp(matches[1], matches[2]);
          }
          return new RegExp(regexStr);
        }),
      );
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [blackListedRoute$, setBlacklistedRoutesRegexps]);

  return (
    <BlacklistedRoutesContext.Provider value={blacklistedRoutesRegexps}>
      {children}
    </BlacklistedRoutesContext.Provider>
  );
});
