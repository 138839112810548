import React, {
  ContextType,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet, HelmetTags } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { ConfigContext, LocaleCode } from '@gaming1/g1-config';
import { useI18n, userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { media, spaces, zIndex } from '@gaming1/g1-style';
import { useGetIsMounted, usePrevious } from '@gaming1/g1-utils';

import { BOTTOM_NAV_HEIGHT } from '../../../styles';

import { ZendeskWidgetContext } from './ZendeskWidgetContext';

type WidgetPrefillValue = { value: string; readOnly: boolean };

type WidgetAction =
  | 'clear'
  | 'helpCenter:setSuggestions'
  | 'hide'
  | 'identify'
  | 'logout'
  | 'open'
  | 'prefill'
  | 'reset'
  | 'setLocale'
  | 'show';

type WidgetUserData = {
  name?: string | WidgetPrefillValue;
  email?: string | WidgetPrefillValue;
  phone?: string | WidgetPrefillValue;
};

type WidgetSuggestionData = {
  labels: ('label1' | 'label2' | 'label3' | 'label4' | 'label5')[];
};

type WidgetTarget = 'webWidget' | 'webWidget:on';

type WidgetCallback = () => void;
type WidgetEventCallback = (userEvent?: Record<string, unknown>) => void;

interface WidgetFunction {
  (
    target: WidgetTarget,
    action: WidgetAction,
    data?: LocaleCode | WidgetUserData | WidgetSuggestionData | WidgetCallback,
  ): void;
  (target: 'webWidget', action: 'setLocale', data: LocaleCode): void;
  (
    target: 'webWidget',
    action: 'identify' | 'prefill',
    data: WidgetUserData,
  ): void;
  (target: 'webWidget:on', action: 'open', data: WidgetCallback): void;
  (
    target: 'webWidget:on',
    action: 'userEvent',
    data: WidgetEventCallback,
  ): void;
}

// For more information on the widget API see documentation :
// https://developer.zendesk.com/embeddables/docs/widget/core#commands
type WindowWithZendeskSDK = Window & {
  zE?: WidgetFunction;
};
const windowWithZendeskSDK = window as WindowWithZendeskSDK;

const ZendeskWidgetStyle = createGlobalStyle`
  iframe#launcher, iframe#webWidget {
    z-index: ${zIndex('helpWidget')} !important;
    margin-bottom: 0px !important;
    left: 0px !important;
    bottom : ${({ theme }) => BOTTOM_NAV_HEIGHT + theme.space.xs}px !important;

    ${media.xl} {
      bottom: ${spaces('xs')} !important;
    }
}`;

const getUserData = ({
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  PrefixPhoneNumber: Prefix,
}: NonNullable<ReturnType<typeof userSelector>>) => ({
  name: FirstName && LastName ? `${FirstName} ${LastName}` : '',
  email: Email || '',
  phone: Prefix && PhoneNumber ? `+${Prefix}${PhoneNumber}` : '',
});

export const ZE_SCRIPT_ID = 'ze-snippet';

export const WIDGET_OPENING_TIMEOUT_IN_MS = 100;

/**
 * Add the zendesk widget
 */
export const ZendeskWidgetProvider: FC = memo(({ children }) => {
  const config = useContext(ConfigContext);

  const { currentLocale: locale } = useI18n();
  const [shouldLoadWidget, setShouldLoadWidget] = useState(false);
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const [shouldWidgetBeVisible, setShouldWidgetBeVisible] = useState(false);

  const user = useSelector(userSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const location = useLocation();
  const currentLocation = location.pathname;
  const previousLocation = usePrevious(currentLocation);
  const getIsMounted = useGetIsMounted();

  const zendeskMethod = windowWithZendeskSDK?.zE;

  /**
   * Dirty hack to force the widget to fully open. Calling the 'open' action
   * when the widget is hidden is the same as calling the 'show' action, which
   * only shows the tiny widget (not very visible). Calling a second time the
   * 'open' action after the widget has been shown has the intended effect.
   * Unfortunately there is no event handler for the 'show' event available,
   * thus we use a simple timeout.
   */
  const forceOpenWidget = useCallback(() => {
    if (isWidgetLoaded && zendeskMethod) {
      zendeskMethod('webWidget', 'show');
      setTimeout(
        () =>
          getIsMounted() && zendeskMethod && zendeskMethod('webWidget', 'open'),
        WIDGET_OPENING_TIMEOUT_IN_MS,
      );
    }
  }, [getIsMounted, isWidgetLoaded, zendeskMethod]);

  /** Show and open the widget */
  const showWidget = useCallback(() => {
    setShouldWidgetBeVisible(true);
    if (!shouldLoadWidget) {
      setShouldLoadWidget(true);
    } else {
      forceOpenWidget();
    }
  }, [forceOpenWidget, shouldLoadWidget]);

  const loadWidget = useCallback(() => {
    setShouldLoadWidget(true);
  }, []);

  /** Close and hide the widget */
  const hideWidget = useCallback(() => {
    setShouldWidgetBeVisible(false);
  }, []);

  /** Hides and shows the widget */
  useEffect(() => {
    if (shouldWidgetBeVisible) {
      forceOpenWidget();
    } else if (isWidgetLoaded && zendeskMethod) {
      zendeskMethod('webWidget', 'hide');
    }
  }, [forceOpenWidget, isWidgetLoaded, shouldWidgetBeVisible, zendeskMethod]);

  /** Set the current language for the widget */
  useEffect(() => {
    if (isWidgetLoaded && zendeskMethod) {
      zendeskMethod('webWidget', 'setLocale', locale);
    }
  }, [locale, isWidgetLoaded, zendeskMethod]);

  /** Identifies or logout the user when a login or logout event is triggered */
  useEffect(() => {
    if (isWidgetLoaded && zendeskMethod) {
      if (isUserLoggedIn === true && user) {
        const { name, email, phone } = getUserData(user);
        zendeskMethod('webWidget', 'identify', { name, email });
        zendeskMethod('webWidget', 'prefill', {
          name: { value: name, readOnly: !!name },
          email: { value: email, readOnly: !!email },
          phone: { value: phone, readOnly: !!phone },
        });
      } else if (isUserLoggedIn === false) {
        zendeskMethod('webWidget', 'logout');
        zendeskMethod('webWidget', 'prefill', {
          name: { value: '', readOnly: false },
          email: { value: '', readOnly: false },
          phone: { value: '', readOnly: false },
        });
        zendeskMethod('webWidget', 'clear');
      }
    }
  }, [isUserLoggedIn, user, isWidgetLoaded, zendeskMethod]);

  /**
   * Populates the widget search suggestions with default entries.
   * Entries will be linked to topics by the customer support in zendesk.
   * If an entry is not found nothing is displayed.
   * Example : "label1" can be linked to "How to make a deposit ?"
   */
  useEffect(() => {
    if (isWidgetLoaded && zendeskMethod) {
      zendeskMethod('webWidget', 'helpCenter:setSuggestions', {
        labels: ['label1', 'label2', 'label3', 'label4', 'label5'],
      });
    }
  }, [isWidgetLoaded, zendeskMethod]);

  /* Hide the widget when the url changes */
  useEffect(() => {
    if (currentLocation !== previousLocation) {
      hideWidget();
    }
  }, [currentLocation, hideWidget, previousLocation]);

  /** Handle the <script> load event */
  const handleScriptInject = ({ scriptTags }: HelmetTags) => {
    if (scriptTags && scriptTags.length) {
      const zdTag = scriptTags.find(
        (scriptTag) => scriptTag.id === ZE_SCRIPT_ID,
      );

      if (zdTag) {
        zdTag.onload = () => {
          setIsWidgetLoaded(true);
        };
      }
    }
  };

  const zendeskWidgetContextValue: ContextType<typeof ZendeskWidgetContext> =
    useMemo(
      () => ({ showWidget, hideWidget, loadWidget, isWidgetLoaded }),
      [hideWidget, isWidgetLoaded, loadWidget, showWidget],
    );

  return (
    <>
      {config.core.zendeskWidgetKey && shouldLoadWidget && (
        <>
          <ZendeskWidgetStyle />
          <Helmet
            onChangeClientState={(_, addedTags) =>
              handleScriptInject(addedTags)
            }
          >
            <script
              id="ze-snippet"
              type="text/javascript"
              data-testid="ze-snippet"
              src={`https://static.zdassets.com/ekr/snippet.js?key=${config.core.zendeskWidgetKey}`}
              defer
            />
          </Helmet>
        </>
      )}
      <ZendeskWidgetContext.Provider value={zendeskWidgetContextValue}>
        {children}
      </ZendeskWidgetContext.Provider>
    </>
  );
});
