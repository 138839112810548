import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getHorizontalBanner,
  HorizontalBannerRequest,
} from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';

import * as actions from './actions';
import { getHorizontalBannerResponse } from './codecs';

/** Get  */
export const getHorizontalBannerEpic: BettingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getHorizontalBanner.request)),
    switchMap(({ payload }) => {
      const request: HorizontalBannerRequest = {
        ...payload,
        MaxBannerCount: payload.MaxBannerCount ?? 0,
      };
      return wsAdapter.request(getHorizontalBanner(request)).pipe(
        mapGuard(getHorizontalBannerResponse),
        map(actions.getHorizontalBanner.success),
        catchError((err) =>
          of(
            actions.getHorizontalBanner.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      );
    }),
  );
