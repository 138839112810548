import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const SCOREBOARD_BACKGROUND_BLUR_IN_PX = 2;

export const ScoreBoardBackgroundImage = styled(Box)<{ imagePath: string }>`
  align-items: center;
  color: ${colors('textLight')};
  height: 100%;
  overflow: hidden;
  padding: 0 ${spaces('xs')};
  position: relative;
  width: 100%;

  &:before {
    content: '';
    background-image: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      ${({ imagePath }) => `url(${imagePath})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(0.7) blur(${SCOREBOARD_BACKGROUND_BLUR_IN_PX}px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
