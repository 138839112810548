/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { GetSliderRequestDTO, GetSliderResponseDTO } from '../entities';

export const getSlider = (options: GetSliderRequestDTO) =>
  createRequest<GetSliderResponseDTO>(100, 'GetSlider', options);
