import { TextareaHTMLAttributes, VFC } from 'react';
import { useField } from 'react-final-form';

import { InputWrapper } from '@gaming1/g1-ui';
import { getInputFieldValidator } from '@gaming1/g1-utils';

import { FieldProps } from '../../../form/components/types';
import { useInputFieldError } from '../../../form/hooks';

import { TextareaInput } from './styles';

type TextAreaInputFieldProps = FieldProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>
>;

/** textarea that works with final-form. Since it's the only place where it is
 * needed, it's not located next to other *InputField components but if it
 * changes in the future, it should be moved there (and cleaned up a bit) */
export const TextAreaInputField: VFC<TextAreaInputFieldProps> = ({
  name,
  format,
  parse,
  i18nNamespace = 'core',
  required = false,
  subscription = {
    dirtySinceLastSubmit: true,
    error: true,
    touched: true,
    submitError: true,
    value: true,
  },
  validate = null,
  ...rest
}) => {
  const validator = getInputFieldValidator(validate, required);
  const { input, meta } = useField(name, {
    format,
    parse,
    subscription,
    validate: validator,
  });
  const errorMessage = useInputFieldError(meta, i18nNamespace);
  return (
    <InputWrapper errors={errorMessage ? [errorMessage] : undefined}>
      <TextareaInput
        hasErrors={!!errorMessage}
        id={name}
        {...rest}
        {...input}
      />
    </InputWrapper>
  );
};
