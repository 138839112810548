import {
  getScoreBoardSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  ScoreBoardPeriodType,
  ScoreBoardState,
} from '@gaming1/g1-requests-betting';

import { SCOREBOARD_STATE_KEYS } from '../../constants';
import { ScoreBoardSportId } from '../../types';

import { SCOREBOARD_PLAYTIMES } from './constants';
import { TimeType } from './types';

export const useScoreBoardPlayTimeData = (eventId: number) => {
  const scoreBoard = useBettingGetterSelector({
    getterSelector: getScoreBoardSelector,
    args: [eventId],
  });

  if (scoreBoard) {
    const sportId = scoreBoard.SportId as ScoreBoardSportId;
    const playTime = SCOREBOARD_PLAYTIMES[sportId];

    const timeByTypes: { [k in TimeType]: number | null | undefined } = {
      time: scoreBoard?.Info?.CurrentTime,
      remainingTime: scoreBoard?.Info?.RemainingTime,
    };

    const counter = playTime?.counterType || 'forward';
    const timeType = playTime?.timeType || 'time';

    return {
      counter,
      time: timeByTypes[timeType],
      timeType,
    };
  }

  return undefined;
};

export const useScoreBoardPlayTimeTranslation = (eventId: number) => {
  const { t, i18n } = useTranslation('betting');

  const scoreBoard = useBettingGetterSelector({
    getterSelector: getScoreBoardSelector,
    args: [eventId],
  });

  const periodType = scoreBoard?.PeriodType;
  const state = scoreBoard?.Info?.State || ScoreBoardState.Unknown;

  if (!scoreBoard || state === ScoreBoardState.Unknown) {
    return '';
  }

  const stateKey = SCOREBOARD_STATE_KEYS[state];

  const translationKey = `score.boardPlayTime.${scoreBoard.SportId}.${stateKey}`;
  const fallbackTranslationKey =
    periodType === ScoreBoardPeriodType.PeriodBasedOnTime
      ? `score.boardPlayTime.point.${stateKey}`
      : `score.boardPlayTime.period.${stateKey}`;

  return i18n.exists(`betting:${translationKey}`)
    ? t(translationKey)
    : t(fallbackTranslationKey);
};
