import React from 'react';

import { AppRoute } from '../components/AppRoute';
import { MovedPermanentlyManifest } from '../types';

import { GonePage } from './GonePage';
import { MovedPermanentlyPage } from './MovedPermanentlyPage';

const countSeparators = (url: string) => url.split('/').length;

/**
 * This is a helper which generates a collection of routes based on the provided
 * manifest. Each generated rout renders a `GonePage` component telling
 * prerender to return a 410 HTTP code (gone).
 */
export const catchGoneURL = (manifest: string[]) =>
  manifest.length > 0
    ? manifest
        .sort((a, b) => countSeparators(b) - countSeparators(a))
        .map((route) => (
          <AppRoute
            component={GonePage}
            key={route}
            path={route}
            section="default"
          />
        ))
    : null;

/**
 * This is a helper which generates a collection of routes based on the provided
 * manifest. Each generated rout renders a `MovedPermanentlyPage` component
 * telling prerender to return a 301 HTTP code (gone) and redirecting to the new
 * resource URL.
 */
export const catchMovedURL = (manifest: MovedPermanentlyManifest) =>
  manifest.length > 0
    ? manifest
        .sort(([a], [b]) => countSeparators(b) - countSeparators(a))
        .map(([from, to]) => (
          <AppRoute
            key={from}
            path={from}
            render={() => <MovedPermanentlyPage redirectTo={to} />}
            section="default"
          />
        ))
    : null;
