import { BettingSlipIdentifierType } from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import {
  bettingSlipBaseOddsGetterSelector,
  bettingSlipFinalOddsBasedOnElementsGetterSelector,
} from '../../store/selectors/odds';

export const useIsOptimalBetIsAplyOnBettingSlip = (
  bettingSlipId: BettingSlipIdentifierType,
): boolean => {
  const baseOdds = useBettingGetterSelector({
    getterSelector: bettingSlipBaseOddsGetterSelector,
    args: [bettingSlipId],
  });

  const finalOdds = useBettingGetterSelector({
    getterSelector: bettingSlipFinalOddsBasedOnElementsGetterSelector,
    args: [bettingSlipId],
  });

  return baseOdds !== finalOdds;
};
