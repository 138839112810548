import * as t from 'io-ts';

import { bettingHistoryTicketCodec } from '../../../history/store/codecs';
import {
  elementNotificationCodec,
  notificationCodec,
} from '../../../notification/codecs';

/* Response data */

export const placeBetResponseCodec = t.intersection(
  [
    t.type({
      IsFirstBet: t.boolean,
    }),
    t.partial({
      Notification: notificationCodec,
      ElementNotifications: t.array(elementNotificationCodec),
      TicketSummaryId: t.string,
      Ticket: bettingHistoryTicketCodec,
    }),
  ],
  'PlaceBetResponseCodec',
);

export const systemRankNotificationCodec = t.partial({
  RankType: t.string,
  Notification: notificationCodec,
});

export const placeSystemBetResponseCodec = t.intersection(
  [
    t.type({
      IsFirstBet: t.boolean,
    }),
    t.partial({
      RankNotifications: t.array(systemRankNotificationCodec),
      Notification: notificationCodec,
      ElementNotifications: t.array(elementNotificationCodec),
      TicketSummaryId: t.string,
      Ticket: bettingHistoryTicketCodec,
    }),
  ],
  'PlaceSystemBetResponseCodec',
);
