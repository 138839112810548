import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  DefaultMinStakeType,
  GetAvailableManualBetsForEventRequestType,
  GetAvailableManualBetsResponseType,
  HasAvailableManualBetResponseType,
} from './types';

// DAILY OFFERS

export const hasAvailableDailyOffers = createAsyncAction(
  'betting/has_daily_offers_request',
  'betting/has_daily_offers_success',
  'betting/has_daily_offers_failure',
)<void, HasAvailableManualBetResponseType, FailurePayload>();

export const getAvailableDailyOffers = createAsyncAction(
  'betting/get_available_daily_offers_request',
  'betting/get_available_daily_offers_success',
  'betting/get_available_daily_offers_failure',
)<
  void,
  GetAvailableManualBetsResponseType & DefaultMinStakeType,
  FailurePayload
>();

export const getAvailableDailyOffersForEvent = createAsyncAction(
  'betting/get_available_daily_offers_for_event_request',
  'betting/get_available_daily_offers_for_event_success',
  'betting/get_available_daily_offers_for_event_failure',
)<
  GetAvailableManualBetsForEventRequestType,
  GetAvailableManualBetsResponseType & DefaultMinStakeType,
  FailurePayload
>();

// SUPER ODDS

export const hasAvailableSuperOdds = createAsyncAction(
  'betting/has_super_odds_request',
  'betting/has_super_odds_success',
  'betting/has_super_odds_failure',
)<void, HasAvailableManualBetResponseType, FailurePayload>();

export const getAvailableSuperOdds = createAsyncAction(
  'betting/get_available_super_odds_request',
  'betting/get_available_super_odds_success',
  'betting/get_available_super_odds_failure',
)<
  void,
  GetAvailableManualBetsResponseType & DefaultMinStakeType,
  FailurePayload
>();

export const getAvailableSuperOddsForEvent = createAsyncAction(
  'betting/get_available_super_odds_for_event_request',
  'betting/get_available_super_odds_for_event_success',
  'betting/get_available_super_odds_for_event_failure',
)<
  GetAvailableManualBetsForEventRequestType,
  GetAvailableManualBetsResponseType & DefaultMinStakeType,
  FailurePayload
>();
