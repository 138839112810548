import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { color, flexDirection, layout, space } from 'styled-system';

import { device, elevation, fontSizes, spaces } from '@gaming1/g1-style';
import { Box, BoxProps, SimpleButton } from '@gaming1/g1-ui';

const DEFAULT_ELEVATION_LEVEL = 2;
const MENU_ITEM_MIN_HEIGHT_IN_PX = 40;

type ElevationLevels = 0 | 1 | 2 | 3 | 4;

const getHoverElevationLevel = (level: ElevationLevels): ElevationLevels =>
  (level > 2 ? 4 : level + 1) as ElevationLevels;

export type MenuItemWrapperProps = {
  elevationLevel?: ElevationLevels;
  hasNoHoverBehavior?: boolean;
  hasNoElevation?: boolean;
  hasNoShadow?: boolean;
};

export const MenuItemWrapper = styled(Box)<MenuItemWrapperProps>`
  align-items: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : undefined)};
  display: flex;
  flex-direction: row;

  ${({ hasNoElevation, elevationLevel, hasNoShadow }) =>
    !hasNoElevation &&
    css`
      ${elevation(elevationLevel ?? DEFAULT_ELEVATION_LEVEL, {
        noShadow: !!hasNoShadow,
      })}
    `}

  ${({ elevationLevel, hasNoHoverBehavior }) =>
    !hasNoHoverBehavior &&
    css`
      ${device.canHover} {
        &:hover {
          ${elevation(
            getHoverElevationLevel(elevationLevel ?? DEFAULT_ELEVATION_LEVEL),
            { noShadow: true },
          )}
        }
      }
    `}

  ${color}
  ${layout}
  ${flexDirection}
`;

export const MenuItemContent = styled(Box)<
  BoxProps & {
    elevationLevel?: ElevationLevels;
    hoverElevationLevel?: ElevationLevels;
    hasBorderRadius?: boolean;
  }
>`
  padding-top: ${spaces('xs')};
  padding-bottom: ${spaces('xs')};
  padding-left: ${spaces('sm')};
  padding-right: ${spaces('sm')};
  display: flex;
  font-size: ${fontSizes('md')};
  align-items: center;
  flex-direction: row;
  min-height: ${MENU_ITEM_MIN_HEIGHT_IN_PX}px;
  line-height: 1.5;
  flex: 1;
  text-align: left;

  ${({ elevationLevel }) =>
    elevationLevel !== undefined &&
    elevation(elevationLevel, {
      noShadow: true,
    })}

  ${({ hoverElevationLevel }) =>
    hoverElevationLevel !== undefined &&
    css`
      ${device.canHover} {
        &:hover {
          ${elevation(hoverElevationLevel, { noShadow: true })}
        }
      }
    `}

  ${({ hasBorderRadius }) =>
    hasBorderRadius &&
    css`
      border-radius: ${spaces('xxs')};
    `}

  ${space}
`;

const MenuItemBaseCss = css`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const MenuItemName = styled(Box)`
  border-radius: ${spaces('xs')};
`;

export const MenuItemButton = styled(SimpleButton)`
  ${MenuItemBaseCss}
`;

export const MenuItemLink = styled(Link)<LinkProps & { width?: string }>`
  width: 100%;
  ${MenuItemBaseCss}
`;

export const Left = styled(Box)<BoxProps & { isActive: boolean }>`
  padding-left: ${spaces('sm')};
  opacity: 0.75;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}

  ${space}
`;
export const Right = styled(Box)<BoxProps>`
  padding-right: ${spaces('sm')};

  ${space}
`;
