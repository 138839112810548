import DOMPurify from 'dompurify';
import React, { FC, useContext, useEffect, VFC } from 'react';
import { Redirect } from 'react-router-dom';

import { useRoutePath, useSmartBackRedirect } from '@gaming1/g1-core-web';
import { Box, Button, Icon, SimpleButton } from '@gaming1/g1-ui';

import { inboxRoutes } from '../../../routes';
import { markAsDeletedOL, markAsReadOL } from '../../helpers';
import { InboxContext } from '../../InboxContext';

import { BodyContainer, ButtonLink, WideHeading } from './styles';

const Y_OFFSET_FOR_SCROLL = -90;
const ICON_WIDTH = 24;
const ICON_HEIGHT = 22;

export type LinkButtonProps = {
  /** Link url */
  linkUrl: string;
};

const LinkButton: FC<LinkButtonProps> = ({ linkUrl, children }) => {
  const internalUrlRegex = /https?:\/\/internal\//;

  return linkUrl?.match(internalUrlRegex) ? (
    <ButtonLink to={linkUrl?.replace(internalUrlRegex, `/`)}>
      {children}
    </ButtonLink>
  ) : (
    <ButtonLink to={{ pathname: linkUrl }} target="_blank" rel="noopener">
      {children}
    </ButtonLink>
  );
};

type MessageDetailProps = {
  /** Message id  */
  messageId: string;
};

/** Displays a message details from the user inbox */
export const MessageDetail: VFC<MessageDetailProps> = ({ messageId }) => {
  const getInboxPath = useRoutePath(inboxRoutes);
  const { inboxContent } = useContext(InboxContext);
  const backRedirect = useSmartBackRedirect(getInboxPath('messageList'));

  const message = inboxContent?.messages.find(
    (msg) => msg.id.toString() === messageId,
  );

  const handleScroll = (element: HTMLElement | null) => {
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top +
          window.pageYOffset +
          Y_OFFSET_FOR_SCROLL,
      });
    }
  };

  useEffect(() => {
    const isMessageRead = message && message?.read > 0;
    if (message?.id !== undefined && !isMessageRead) {
      markAsReadOL(message.id);
    }
  }, [message]);

  const onDelete = () => {
    if (message?.id !== undefined) {
      markAsDeletedOL(message.id);
    }
    backRedirect();
  };

  const messageContent = message?.content;

  return message ? (
    <>
      <Box flexDirection="row" ref={handleScroll}>
        <SimpleButton onClick={backRedirect} data-testid="message-button-back">
          <Box>
            <Icon
              type="ArrowLeft"
              id="header-back-button"
              width={ICON_WIDTH}
              height={ICON_HEIGHT}
            />
          </Box>
        </SimpleButton>
        <WideHeading level="h2" mt={0} ml="sm" data-testid="message-title">
          {message?.sline}
        </WideHeading>
        <SimpleButton onClick={onDelete} data-testid="message-button-delete">
          <Box>
            <Icon
              type="Trash"
              id="delete"
              width={ICON_WIDTH}
              height={ICON_HEIGHT}
              ml="md"
            />
          </Box>
        </SimpleButton>
      </Box>
      {messageContent && (
        <BodyContainer
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(messageContent),
          }}
          data-testid="message-content"
        />
      )}
      {message?.burl && (
        <LinkButton linkUrl={message?.burl}>
          <Button type="primary" data-testid="message-button-redirect">
            {message?.blbl}
          </Button>
        </LinkButton>
      )}
    </>
  ) : (
    <Redirect
      exact
      from={getInboxPath('messageDetail', { messageId })}
      to={getInboxPath('messageList')}
    />
  );
};
