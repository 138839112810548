import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as betpipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import { BettingSlipsState } from '../types';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const optimalBetReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(betpipeActions.resetBettingSlip.request): {
        const { payload } = action;
        if (!payload.bettingSlipId) {
          break;
        }
        if (!draftState.bettingSlips[payload.bettingSlipId]) {
          return;
        }
        draftState.bettingSlips[payload.bettingSlipId].requests.getOptimalBet =
          generateInitialRequestState();
        break;
      }

      case getType(betpipeActions.getOptimalBet.request): {
        const { payload } = action;
        if (!draftState.bettingSlips[payload.bettingSlipId]) {
          return;
        }
        produceLoadingState(
          draftState.bettingSlips[payload.bettingSlipId].requests.getOptimalBet,
        );
        break;
      }

      case getType(betpipeActions.getOptimalBet.failure): {
        const { payload } = action;
        if (!draftState.bettingSlips[payload.bettingSlipId]) {
          return;
        }
        produceFailureState(
          draftState.bettingSlips[payload.bettingSlipId].requests.getOptimalBet,
          action.payload,
        );
        break;
      }

      case getType(betpipeActions.getOptimalBet.success): {
        const { payload } = action;

        if (!draftState.bettingSlips[payload.bettingSlipId]) {
          return;
        }

        produceSuccessState(
          draftState.bettingSlips[payload.bettingSlipId].requests.getOptimalBet,
        );
        draftState.bettingSlips[payload.bettingSlipId].optimalBet.result =
          action.payload;
        break;
      }

      default: // Immer will automatically return the state
    }
  });
