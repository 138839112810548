import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { EExclusionType } from '@gaming1/g1-requests';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { SelfExclusionState } from './types';

export const initialState: SelfExclusionState = {
  requests: {
    blacklistPlayer: generateInitialRequestState(null),
    getSelfExclusionInfo: generateInitialRequestState(null),
    closeAccount: generateInitialRequestState(null),
    setGamePause: generateInitialRequestState(null),
    setSelfExclusion: generateInitialRequestState(null),
  },
  selfExclusion: null,
};

export const selfExclusionReducer = (
  state: SelfExclusionState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.setSelfExclusion.request):
        produceLoadingState(draftState.requests.setSelfExclusion);
        break;

      case getType(actions.setSelfExclusion.failure):
        produceFailureState(
          draftState.requests.setSelfExclusion,
          action.payload,
        );
        break;

      case getType(actions.setSelfExclusion.success):
        produceSuccessState(draftState.requests.setSelfExclusion);
        break;

      case getType(actions.setGamePause.request):
        produceLoadingState(draftState.requests.setGamePause);
        break;

      case getType(actions.setGamePause.failure):
        produceFailureState(draftState.requests.setGamePause, action.payload);
        break;

      case getType(actions.setGamePause.success):
        produceSuccessState(draftState.requests.setGamePause);
        draftState.selfExclusion = action.payload;
        break;

      case getType(actions.getSelfExclusionInfo.request):
        produceLoadingState(draftState.requests.getSelfExclusionInfo);
        break;

      case getType(actions.getSelfExclusionInfo.failure):
        produceFailureState(
          draftState.requests.getSelfExclusionInfo,
          action.payload,
        );
        break;

      case getType(actions.getSelfExclusionInfo.success):
        produceSuccessState(draftState.requests.getSelfExclusionInfo);
        draftState.selfExclusion = action.payload;
        break;

      case getType(actions.closeAccount.request):
        produceLoadingState(draftState.requests.closeAccount);
        break;

      case getType(actions.closeAccount.success):
        produceSuccessState(draftState.requests.closeAccount);
        draftState.selfExclusion = {
          ExcludeType: EExclusionType.CloseAccount,
          Excluded: true,
        };
        break;

      case getType(actions.closeAccount.failure):
        produceFailureState(draftState.requests.closeAccount, action.payload);
        break;

      /**
       * Blacklist player
       */
      case getType(actions.blacklistPlayer.request):
        produceLoadingState(draftState.requests.blacklistPlayer);
        break;
      case getType(actions.blacklistPlayer.success):
        produceSuccessState(draftState.requests.blacklistPlayer);
        break;
      case getType(actions.blacklistPlayer.failure):
        produceFailureState(
          draftState.requests.blacklistPlayer,
          action.payload,
        );
        break;

      default: // Immer will automatically return the state
    }
  });
