import { Action, Location } from 'history';
import { fromEvent } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

import {
  blacklistedRoutesMessageCodec,
  changeConnectionTypeMessageCodec,
  exitFullScreenLocationMessageCodec,
  filterByCodec,
  geolocationMessageCodec,
  interopMessages,
  isSimpleIframeMessage,
  navigateMessageCodec,
  navigationDrawerStateChangeMessageCodec,
  networkRequestMessageCodec,
  networkResponseMessageCodec,
  parseInteropMessages,
  reduxActionTwinningMessageCodec,
  trackingEventMessageCodec,
} from '@gaming1/g1-core';
import { TwinAction } from '@gaming1/g1-twinning';

import { sendIframeMessage } from './helpers';

/* Sending messages */

export const updateHistory = (location: Location, action: Action) =>
  sendIframeMessage(interopMessages.locationChanged, {
    ...location,
    action,
  });

export const forwardReduxAction = (action: TwinAction) => {
  sendIframeMessage(interopMessages.reduxActionDispatched, action);
};

/* Receiving messages */

/**
 * Observable emitting all valid messages sent to the iframe
 * Those messages have to be either a valid SimpleIframeMessage (or
 * a stringified object of this type)
 */
const iframeMessages$ = fromEvent<MessageEvent>(window, 'message').pipe(
  // Comment this out for debugging
  // tap((message) => console.log('[Iframe Message] Unfiltered', message.data)),
  // Only keep the data property
  pluck<unknown>('data'),
  // Ensure we received a string or an object
  parseInteropMessages,
  // Comment this out for debugging
  // tap((message) => console.log('[Iframe Message] New message', message)),
  // Filtering out invalid messages
  filter(isSimpleIframeMessage),
);

/** Observable emitting when the user geolocation status has changed */
export const geolocation$ = iframeMessages$.pipe(
  filterByCodec(geolocationMessageCodec),
);

export const reduxActionsTwinningMessages$ = iframeMessages$.pipe(
  filterByCodec(reduxActionTwinningMessageCodec),
);

export const navigateMessages$ = iframeMessages$.pipe(
  filterByCodec(navigateMessageCodec),
);

export const blackListedRoute$ = iframeMessages$.pipe(
  filterByCodec(blacklistedRoutesMessageCodec),
);

/** Observable emitting when the native wants to send tracking/analytics event */
export const trackingEvent$ = iframeMessages$.pipe(
  filterByCodec(trackingEventMessageCodec),
);

/** Observable emitting when the native wants to toggle navigation drawer */
export const navigationDrawerStateChange$ = iframeMessages$.pipe(
  filterByCodec(navigationDrawerStateChangeMessageCodec),
);

/** Observable emitting when the react native app make a request to the web
 * socket */
export const networkRequest$ = iframeMessages$.pipe(
  filterByCodec(networkRequestMessageCodec),
);

/** Observable emitting when the web socket respond to a request made by the
 * react native app */
export const networkResponse$ = iframeMessages$.pipe(
  filterByCodec(networkResponseMessageCodec),
);

export const exitFullScreenLocation$ = iframeMessages$.pipe(
  filterByCodec(exitFullScreenLocationMessageCodec),
);

export const changeConnectionType$ = iframeMessages$.pipe(
  filterByCodec(changeConnectionTypeMessageCodec),
);

// TODO: rename as interopMessageManager
/**
 * Object containing all the methods for sending messages to the parent iframe,
 * and the observables to listen to the message from the parent iframe
 * Useful for testing through dependency injection (components and epics)
 */
export const iframeMessageManager = {
  // Methods
  forwardReduxAction,
  updateHistory,
  // Observables
  blackListedRoute$,
  exitFullScreenLocation$,
  geolocation$,
  navigateMessages$,
  navigationDrawerStateChange$,
  networkRequest$,
  networkResponse$,
  reduxActionsTwinningMessages$,
  changeConnectionType$,
  trackingEvent$,
};

export type IframeMessageManager = typeof iframeMessageManager;
