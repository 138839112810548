import React, { FC, memo, useContext } from 'react';

import {
  useBettingSlipBaseOdds,
  useBettingSlipFinalOdds,
} from '@gaming1/g1-betting';

import { BettingSlipContext } from '../../BettingSlipContext';

import { OddsContainer } from './OddsContainer';

const BettingSlipOddsComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  // before applying boostus
  const baseOdds = useBettingSlipBaseOdds(bettingSlipId)();

  // after applying boostus
  const finalOdds = useBettingSlipFinalOdds(bettingSlipId)();

  if (!baseOdds) {
    return null;
  }

  return <OddsContainer baseOdds={baseOdds} finalOdds={finalOdds} />;
};

export const BettingSlipOdds = memo(BettingSlipOddsComponent);
