import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { userLoggedInSelector } from '@gaming1/g1-core';
import { userPublicRoutes } from '@gaming1/g1-user-api';

import { SuspenseLoader } from '../../../layout/components/SuspenseLoader';
import { coreRoutes } from '../../../routes';
import { concatLocation } from '../../helpers';
import { useRoutePath } from '../../hooks';
import { AppRoute, AppRouteProps } from '../AppRoute';

export type ProtectedRouteProps = AppRouteProps & {
  /** If the user is allowed to go to the protected route */
  allowed?: boolean;
  /** The route is allowed only if logged out */
  loggedOutOnly?: boolean;
  /**
   * Route to redirect the user if not allowed
   * Default: '/login'
   */
  redirectPath?: string;
};

/**
 * Route component from react-router-dom that will redirect the user if he is
 * not allowed to access it.
 */
export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  allowed,
  redirectPath,
  loggedOutOnly = false,
  ...appRouteProps
}) => {
  const loggedIn = useSelector(userLoggedInSelector);
  const location = useLocation<{ from?: string }>();
  const isAllowedToGo =
    loggedOutOnly && loggedIn !== null ? !loggedIn : loggedIn;
  const routeIsAllowed = allowed === undefined ? isAllowedToGo : allowed;

  const getCoreRoutePath = useRoutePath(coreRoutes);
  const getUserRoutePath = useRoutePath(userPublicRoutes);

  const defaultRedirectPath = loggedOutOnly
    ? getCoreRoutePath('home')
    : getUserRoutePath('login');

  const routeToRedirect =
    location.state?.from === 'fullscreen'
      ? getCoreRoutePath('home')
      : redirectPath || defaultRedirectPath;

  // Render a blank page when the user auth status is pending
  if (routeIsAllowed === null) {
    return <SuspenseLoader />;
  }

  return routeIsAllowed ? (
    <AppRoute {...appRouteProps} />
  ) : (
    <Redirect
      to={{
        pathname: routeToRedirect,
        search: !loggedOutOnly
          ? `from=${encodeURIComponent(concatLocation(location))}`
          : '',
      }}
      push={false}
    />
  );
};
