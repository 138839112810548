import * as t from 'io-ts';

import {
  ECostTypeV4,
  EGetTournamentListV4Status,
  EGetTournamentV4Status,
  EPrizeTypeV4,
  ERegisterTournamentV4Status,
  ETournamentActionV4,
  ETournamentDetailsV4Status,
  ETournamentModeV4,
  ETournamentPlayerRankingV4Status,
  ETournamentRankingV4Status,
  ETournamentStateV4,
} from '@gaming1/g1-requests-gaming';
import { createEnumType } from '@gaming1/g1-utils';

import { game } from '../../common/store/codecs';

export const tournamentRanking = t.intersection(
  [
    t.type({
      Position: t.number,
      Prize: t.number,
      PrizeType: createEnumType<EPrizeTypeV4>(EPrizeTypeV4),
      Score: t.number,
      SubPosition: t.number,
    }),
    t.partial({
      Gift: t.string,
      Pseudo: t.string,
    }),
  ],
  'TournamentRanking',
);

export const tournament = t.intersection(
  [
    t.type({
      Action: createEnumType<ETournamentActionV4>(ETournamentActionV4),
      BannerResourceUri: t.string,
      Cost: t.number,
      CostType: createEnumType<ECostTypeV4>(ECostTypeV4),
      Id: t.string,
      From: t.string,
      PictureResourceUri: t.string,
      Players: t.number,
      PrizePool: t.number,
      PrizePoolType: createEnumType<EPrizeTypeV4>(EPrizeTypeV4),
      RegistrationTo: t.string,
      State: createEnumType<ETournamentStateV4>(ETournamentStateV4),
      To: t.string,
      TournamentMode: createEnumType<ETournamentModeV4>(ETournamentModeV4),
    }),
    t.partial({
      Game: game,
      Name: t.string,
      PlayerRanking: tournamentRanking,
    }),
  ],
  'Tournament',
);

export const getTournamentListResponse = t.type(
  {
    List: t.array(tournament),
    PageNumber: t.number,
    PageSize: t.number,
    Status: t.literal(EGetTournamentListV4Status.Successful),
    Total: t.number,
  },
  'GetTournamentListResponse',
);

export const getTournamentDetailsResponse = t.type(
  {
    AddonCost: t.number,
    AddonCostType: createEnumType<ECostTypeV4>(ECostTypeV4),
    AddonCount: t.number,
    AddonCredits: t.number,
    AddonDuration: t.number,
    Credits: t.number,
    Duration: t.number,
    RebuyCost: t.number,
    RebuyCostType: createEnumType<ECostTypeV4>(ECostTypeV4),
    RebuyCount: t.number,
    RebuyCredits: t.number,
    RebuyDuration: t.number,
    Status: t.literal(ETournamentDetailsV4Status.Successful),
  },
  'GetTournamentDetailsResponse',
);

export const getTournamentRankingResponse = t.type(
  {
    List: t.array(tournamentRanking),
    PageNumber: t.number,
    PageSize: t.number,
    Status: t.literal(ETournamentRankingV4Status.Successful),
    Total: t.number,
  },
  'GetTournamentRankingResponse',
);

export const getTournamentPlayerRankingResponse = t.type(
  {
    List: t.array(tournamentRanking),
    Status: t.literal(ETournamentPlayerRankingV4Status.Successful),
  },
  'GetTournamentPlayerRankingResponse',
);

export const registerTournamentFailureResponse = t.intersection(
  [
    t.type({
      Status: createEnumType<ERegisterTournamentV4Status>(
        ERegisterTournamentV4Status,
        'Successful',
      ),
    }),
    t.partial({
      ErrorMessage: t.union([t.null, t.string]),
      Message: t.union([t.null, t.string]),
    }),
  ],
  'RegisterTournamentFailureResponse',
);

export const registerTournamentResponse = t.intersection(
  [
    t.type({
      Status: t.literal(ERegisterTournamentV4Status.Successful),
    }),
    t.partial({
      TournamentId: t.string,
    }),
  ],
  'RegisterTournamentResponse',
);

export const getTournamentResponse = t.type(
  {
    Status: t.literal(EGetTournamentV4Status.Successful),
    Tournament: tournament,
  },

  'GetTournamentResponse',
);
