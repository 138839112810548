import React, { VFC } from 'react';

import { IconProps as CommonIconProps } from '@gaming1/g1-ui';

import { icons, IconType } from './icons';

export type { icons, IconType } from './icons';

export type IconProps = Omit<CommonIconProps, 'type'> & {
  /** the type of icon to displayed (finite list of available icons) */
  type: IconType;
};

export const BettingIcon: VFC<IconProps> = ({ id, testId, type, ...props }) => {
  const dataTestId =
    testId ||
    (id ? `${id}-betting-icon` : `betting-icon-${type.toLowerCase()}`);

  const IconComponent = icons[type];

  return <IconComponent data-testid={dataTestId} {...props} />;
};
