import React, { ContextType, FC } from 'react';

import { LoyaltyPublicComponentsContext } from '@gaming1/g1-loyalty-api';

import { ClubFeatureLinks } from '../../../club/components/ClubFeatureLinks';
import { ProfileRankInfo } from '../../../club/components/ProfileRankInfo';
import { FreeSpinsHistory } from '../../../histories/components/FreeSpinsHistory';
import { ScratchcardHistory } from '../../../histories/components/ScratchcardHistory';
import { ShopHistory } from '../../../histories/components/ShopHistory';
import { SponsorshipHistory } from '../../../histories/components/SponsorshipHistory';

const contextValue: ContextType<typeof LoyaltyPublicComponentsContext> = {
  ClubFeatureLinks,
  FreeSpinsHistory,
  ProfileRankInfo,
  ScratchcardHistory,
  ShopHistory,
  SponsorshipHistory,
};

/** Every context providers needed for the loyalty package:
 * - LoyaltyPublicComponentsContext that shares public components to other
 *   packages
 */
export const LoyaltyProviders: FC = ({ children }) => (
  <LoyaltyPublicComponentsContext.Provider value={contextValue}>
    {children}
  </LoyaltyPublicComponentsContext.Provider>
);
