import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { LoyaltyActions } from '../types';

import * as actions from './actions';
import { SearchState } from './types';

export const initialState: SearchState = {
  requests: {
    searchShopProducts: generateInitialRequestState([]),
  },
};

export const searchReducer = (
  state: SearchState = initialState,
  action: LoyaltyActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.searchShopProducts.request):
        produceLoadingState(draftState.requests.searchShopProducts);
        break;

      case getType(actions.searchShopProducts.failure):
        produceFailureState(
          draftState.requests.searchShopProducts,
          action.payload,
        );
        break;

      case getType(actions.searchShopProducts.success):
        produceSuccessState(
          draftState.requests.searchShopProducts,
          action.payload.result,
        );
        break;

      default: // Immer will automatically return the state
    }
  });
