import React, { VFC } from 'react';
import { useTheme } from 'styled-components';

import { PromotionInfoType } from '@gaming1/g1-betting';
import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { GiftType, PromotionState } from '@gaming1/g1-requests-betting';
import { Box, Icon } from '@gaming1/g1-ui';

import { bettingRoutes } from '../../../../../routes';
import { OpacityContainer } from '../../../styles';
import {
  CallToActionLink,
  EXTRA_ICON_HEIGHT_IN_PX,
  EXTRA_ICON_WIDTH_IN_PX,
  ExtraText,
  HistoryExpandedGiftContainer,
} from '../styles';

type ExpandedExtraInfoPromotionProps = {
  promotion: PromotionInfoType;
};
export const ExpandedExtraInfoPromotionV2: VFC<
  ExpandedExtraInfoPromotionProps
> = ({ promotion }) => {
  const { t } = useTranslation('betting');
  const getBettingPath = useRoutePath(bettingRoutes);

  const { colors } = useTheme();

  const promotionIcon = (
    <Icon
      id="BettingPromotionWithBackground"
      type="BettingPromotionWithBackground"
      color={colors.bettingPromotions}
      height={EXTRA_ICON_HEIGHT_IN_PX}
      width={EXTRA_ICON_WIDTH_IN_PX}
    />
  );
  return promotion ? (
    <HistoryExpandedGiftContainer>
      {promotion.State !== PromotionState.NotValidated ? (
        promotionIcon
      ) : (
        <OpacityContainer data-testid="extra-promotion-invalid-container">
          {promotionIcon}
        </OpacityContainer>
      )}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ExtraText color={colors.bettingPromotions}>
          {promotion?.Name}
        </ExtraText>
        {promotion.State === PromotionState.Validated &&
          promotion.GiftType === GiftType.Freebet && (
            <CallToActionLink
              mt={0}
              fontSize="md"
              to={getBettingPath('myUnlockedGifts')}
            >
              {t('promotion.history.freebetgift')}
            </CallToActionLink>
          )}
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
