import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const bettingSlipConfigurationReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.changeBettingSlipType): {
        const {
          payload: { bettingSlipId, type },
        } = action;

        const bettingslip = draftState.bettingSlips[bettingSlipId];

        if (!bettingslip) {
          return;
        }
        bettingslip.configuration.type = type;

        break;
      }
      case getType(actions.updateStake): {
        const {
          payload: { bettingSlipId, stake },
        } = action;

        if (!draftState.bettingSlips[bettingSlipId]) {
          return;
        }

        draftState.bettingSlips[bettingSlipId].stake = stake;

        break;
      }
      case getType(actions.cleanBettingSlip): {
        const {
          payload: { bettingSlipId },
        } = action;

        draftState.bettingSlips[bettingSlipId].items = [];
        draftState.bettingSlips[bettingSlipId].ranks = [];

        break;
      }
      default: // Immer will automatically return the state
    }
  });
