import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { PrismicNavigationsQueryResponse } from './types';

/**
 * Action to fetch all prismic navigations
 */
export const fetchNavigations = createAsyncAction(
  'prismic/fetch_navigations_request',
  'prismic/fetch_navigations_success',
  'prismic/fetch_navigations_failure',
)<string, PrismicNavigationsQueryResponse, FailurePayload>();
