import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { userCreditSelector } from '@gaming1/g1-core';
import {
  KeyValueType,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import {
  BettingSlipNotification,
  EFrontErrorType,
} from '../../../notification/mapper/types';
import {
  bettingSlipItemIsValidOutcomeGetterSelector,
  bettingSlipItemsGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  riskManagementLimitsGetterSelector,
} from '../../store/selectors';
import { freebetSelectedForBSGetterSelector } from '../../store/selectors/common';
import {
  getLimitStatus,
  getRealMax,
} from '../../store/selectors/elements/helpers';
import {
  bettingSlipBaseOddsGetterSelector,
  bettingSlipFinalOddsGettorSelector,
} from '../../store/selectors/odds';
import {
  bettingSlipFinalWinningsGettorSelector,
  bettingSlipWinningsGettorSelector,
} from '../../store/selectors/winnings';

export const useBettingSlipBaseOdds = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const odds = useBettingGetterSelector({
    getterSelector: bettingSlipBaseOddsGetterSelector,
    args: [bettingSlipId],
  });

  return (): number => odds;
};

export const useBettingSlipFinalOdds = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const odds = useBettingGetterSelector({
    getterSelector: bettingSlipFinalOddsGettorSelector,
    args: [bettingSlipId],
  });

  return (): number => odds;
};

export const useBettingSlipCombiPotWinning = (
  bettingSlipId: BettingSlipIdentifierType,
): number | undefined => {
  const winnings = useBettingGetterSelector({
    getterSelector: bettingSlipWinningsGettorSelector,
    args: [bettingSlipId],
  });

  return winnings;
};

export const useBettingSlipCombiFinalPotWinning = (
  bettingSlipId: BettingSlipIdentifierType,
): number | undefined => {
  const winnings = useBettingGetterSelector({
    getterSelector: bettingSlipFinalWinningsGettorSelector,
    args: [bettingSlipId],
  });

  return winnings;
};

export const useBettingSlipGetCombiMaxStake = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const limitsSelector = useSelector(riskManagementLimitsGetterSelector);

  const oddsValue = useBettingSlipFinalOdds(bettingSlipId)();

  return (): number => {
    const limits = limitsSelector(bettingSlipId);

    if (!limits) {
      return Number.MAX_VALUE;
    }
    if (!oddsValue) {
      return 0;
    }

    const { max, winnable } = limits;

    return (
      getRealMax(max ?? null, winnable ?? null, oddsValue) || Number.MAX_VALUE
    );
  };
};

export const useBettingSlipCombiErrorFrontType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const limitsSelector = useSelector(riskManagementLimitsGetterSelector);
  const stakeSelector = useSelector(bettingSlipStakeInFloatGetterSelector);
  const freebetSelectedSelector = useSelector(
    freebetSelectedForBSGetterSelector,
  );

  const odds = useBettingSlipFinalOdds(bettingSlipId);
  const userCredit = useSelector(userCreditSelector);

  return (): BettingSlipNotification => {
    const limits = limitsSelector(bettingSlipId);

    const stake = stakeSelector(bettingSlipId);

    if (!stake) {
      if (limits && limits.min) {
        return {
          Status: EFrontErrorType.NoStakeWithMin,
          Level: NotificationLevel.Error,
          Code: NotificationCode.Unknown,
          NotificationParameters: [
            {
              Key: 'minCurrency',
              Value: limits?.min?.toString() || '0',
              Type: KeyValueType.Money,
            },
          ],
        };
      }

      return {
        Status: EFrontErrorType.NoStake,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const freebetSelected = freebetSelectedSelector(bettingSlipId);
    if (
      !freebetSelected &&
      userCredit &&
      stake &&
      stake > userCredit.AvailableAmout
    ) {
      return {
        Status: EFrontErrorType.NotEnoughCredit,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    if (!limits) {
      return {
        Status: EFrontErrorType.None,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const oddsValue = odds();
    // We should always have an odd as the selectors will round any negative one
    // or undefined one to "1"
    if (!oddsValue) {
      return {
        Status: EFrontErrorType.InvalidOdd,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const { min, max, winnable } = limits;
    return getLimitStatus({
      stake,
      min: min ?? null,
      max: max ?? null,
      winnable: winnable ?? null,
      odd: oddsValue,
    });
  };
};

export const useBettingSlipCombiIsValid = (
  bettingSlipId: BettingSlipIdentifierType,
): boolean => {
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const bettingSlipItemIsValidOutcome = useSelector(
    bettingSlipItemIsValidOutcomeGetterSelector,
  );

  if (
    useBettingSlipCombiErrorFrontType(bettingSlipId)().Status !==
    EFrontErrorType.None
  ) {
    return false;
  }

  if (!items || items.length === 0) {
    return false;
  }
  return (
    items.find(
      (item) => !bettingSlipItemIsValidOutcome(bettingSlipId, item.id),
    ) === undefined
  );
};
