import {
  EValidatePhoneCodeResponseStatus,
  SendPhoneValidationCodeStatus,
} from '@gaming1/g1-requests';

export const sendPhoneValidationCodeErrorMessages = {
  [SendPhoneValidationCodeStatus.InternalError]: 'core:error.internalServer', // 0
  [SendPhoneValidationCodeStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [SendPhoneValidationCodeStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
  [SendPhoneValidationCodeStatus.ServiceError]:
    'user:phoneValidation.serviceError', // 4
  [SendPhoneValidationCodeStatus.NoPhone]: 'user:phoneValidation.noPhone', // 5
  [SendPhoneValidationCodeStatus.PhoneAlreadyValidated]:
    'user:phoneValidation.phoneAlreadyValidated', // 6
  [SendPhoneValidationCodeStatus.InvalidPhoneNumber]:
    'user:phoneValidation.invalidPhoneNumber', // 7
  [SendPhoneValidationCodeStatus.PhoneValidationNotActivated]:
    'user:phoneValidation.phoneValidationNotActivated', // 8
  [SendPhoneValidationCodeStatus.PhoneNumberMultiAccount]:
    'user:phoneValidation.phoneNumberMultiAccount', // 9
  [SendPhoneValidationCodeStatus.WaitAgain]: 'user:phoneValidation.waitAgain', // 10
};

export const validatePhoneCodeErrorMessages = {
  [EValidatePhoneCodeResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EValidatePhoneCodeResponseStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EValidatePhoneCodeResponseStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
  [EValidatePhoneCodeResponseStatus.BadValidationCode]:
    'user:phoneValidation.badValidationCode', // 4
  [EValidatePhoneCodeResponseStatus.PhoneValidationNotActivated]:
    'user:phoneValidation.phoneValidationNotActivated', // 6
  [EValidatePhoneCodeResponseStatus.PhoneNumberMultiAccount]:
    'user:phoneValidation.phoneNumberMultiAccount', // 7
};
