import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  getReadableColor,
  media,
  spaces,
} from '@gaming1/g1-style';
import {
  AppAnchor,
  AppLink,
  FluidContentContainer,
  Icon,
} from '@gaming1/g1-ui';

import { FULLSCREEN_FOOTER_HEIGHT } from '../../../styles';

export const FullScreenFooterContainer = styled.footer`
  display: flex;
  align-items: center;
  background: ${colors('backgrounds', { index: 1 })};
  color: ${getReadableColor('backgrounds', { index: 1 })};
  height: ${FULLSCREEN_FOOTER_HEIGHT}px;
  font-size: ${fontSizes('md')};
  width: 100%;
  margin: 0 auto;

  ${({ theme }) =>
    !theme.dark &&
    css`
      border-top: 1px solid ${colors('separator')};
    `}
`;

export const FullScreenFooterWrapper = styled(FluidContentContainer)`
  justify-content: space-between;
`;

export const LegalItems = styled.div`
  display: flex;
`;

const itemCss = css`
  display: flex;
  align-items: center;
  color: ${getReadableColor('backgrounds', { index: 1 })};

  ${media.md} {
    margin-left: ${spaces('lg')};
  }
`;

export const LegalAgeItem = styled.div`
  ${itemCss};
`;

export const LegalAgeItemIcon = styled(Icon)`
  height: 24px;
  width: 24px;
  margin-right: ${spaces('xs')};
`;

export const ItemAnchor = styled(AppAnchor)`
  ${media.md} {
    ${fontWeight('bold')};
  }

  ${itemCss};
`;

export const ItemLink = styled(AppLink)`
  ${media.md} {
    ${fontWeight('bold')};
  }

  ${itemCss};
`;

export const ItemSeparator = styled.span`
  width: 1px;
  height: 15px;
  background-color: ${getReadableColor('backgrounds', { index: 1 })};
  margin: auto ${spaces('xs')};
  display: inline-block;
`;
