import * as t from 'io-ts';

import {
  EValidatePhoneCodeResponseStatus,
  SendPhoneValidationCodeStatus,
} from '@gaming1/g1-requests';

export const sendPhoneValidationCodeResponse = t.type(
  { Status: t.literal(SendPhoneValidationCodeStatus.Successful) },
  'SendPhoneValidationCodeResponse',
);

export const validatePhoneCodeResponse = t.type(
  { Status: t.literal(EValidatePhoneCodeResponseStatus.Successful) },
  'ValidatePhoneCodeResponse',
);
