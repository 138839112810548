import React, { VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';

import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';

import { inboxRoutes } from '../../routes';
import { MessageDetail } from '../components/MessageDetail';

/** Inbox message detailed page */
export const MessageDetailPage: VFC = () => {
  const { t } = useTranslation('loyalty');
  const getInboxPath = useRoutePath(inboxRoutes);
  const { messageId } = useParams<{ messageId: string }>();

  return (
    <>
      <Helmet>
        <title>{t('inbox.detail.title')}</title>
      </Helmet>
      {messageId ? (
        <MessageDetail messageId={messageId} />
      ) : (
        <Redirect
          exact
          from={getInboxPath('messageDetail', { messageId })}
          to={getInboxPath('messageList')}
        />
      )}
    </>
  );
};
