import {
  CoreAssetsContext,
  generateAssetsContextValue,
} from '@gaming1/g1-core-web';

import genericError from './errors/generic_error.svg';
import networkError from './errors/network_error.svg';
import backToTheTopArrow from './footer/backtop.svg';
import androidApkEn from './geolocation/android-apk-en.png';
import androidApkFr from './geolocation/android-apk-fr.png';
import appStoreComingSoonEn from './geolocation/app-store-coming-soon-en.png';
import appStoreComingSoonFr from './geolocation/app-store-coming-soon-fr.png';
import appStoreEn from './geolocation/app-store-en.png';
import appStoreFr from './geolocation/app-store-fr.png';
import googlePlayComingSoonEn from './geolocation/google-play-coming-soon-en.png';
import googlePlayComingSoonFr from './geolocation/google-play-coming-soon-fr.png';
import googlePlayEn from './geolocation/google-play-en.png';
import googlePlayFr from './geolocation/google-play-fr.png';
import laptop from './geolocation/laptop.png';
import mobile from './geolocation/mobile.png';
import notFound from './illustrations/error-404.svg';
import failure from './illustrations/failed.svg';
import questioning from './illustrations/questioning.svg';
import repair from './illustrations/repair.svg';
import successCashier from './illustrations/success-cashier.svg';
import successWelcome from './illustrations/success-welcome.svg';
import warning from './illustrations/warning.svg';
import logoSmall from './logos/logo-icon.svg';
import logo from './logos/logo.svg';
import logoDark from './logos/logo_dark.svg';
import logoLight from './logos/logo_light.svg';
import defaultSliderLarge from './sliders/default-slider-large.jpg';
import defaultSliderSmall from './sliders/default-slider-small.jpg';

export const coreAssets = generateAssetsContextValue(CoreAssetsContext)({
  /* UI buttons */
  backToTheTopArrow,
  genericError,
  networkError,
  /* Logos */
  logo,
  logoDark,
  logoLight,
  logoSmall,
  /* Banners */
  defaultSliderLarge,
  defaultSliderSmall,
  /* Illustrations */
  notFound,
  failure,
  questioning,
  noResults: questioning,
  repair,
  successCashier,
  successWelcome,
  warning,
  /* Geolocation */
  androidApkEn,
  androidApkFr,
  appStoreComingSoonEn,
  appStoreComingSoonFr,
  appStoreEn,
  appStoreFr,
  googlePlayComingSoonEn,
  googlePlayComingSoonFr,
  googlePlayEn,
  googlePlayFr,
  laptop,
  mobile,
});
