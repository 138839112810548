import React, { FC } from 'react';

import { GlobalAssetsProvider } from '@gaming1/g1-core-web';

import { bettingAssets } from './betting/bettingAssets';
import { coreAssets } from './core/coreAssets';
import { gamingAssets } from './gaming/gamingAssets';
import { loyaltyAssets } from './loyalty/loyaltyAssets';
import { paymentAssets } from './payment/paymentAssets';
import { userAssets } from './user/userAssets';

const assets = [
  bettingAssets,
  coreAssets,
  gamingAssets,
  paymentAssets,
  loyaltyAssets,
  userAssets,
];

export const AssetsProvider: FC = ({ children }) => (
  <GlobalAssetsProvider contextValues={assets}>{children}</GlobalAssetsProvider>
);
