import React, { FC, useEffect } from 'react';

import { usePageContentByUid } from '@gaming1/g1-cms';
import { PrismicSectionProps } from '@gaming1/g1-cms-api';
import { NotFoundPage, Page, RequestErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Loader } from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

import { PrismicTitleH1 } from '../../styles';
import { PrismicNavigation } from '../PrismicNavigation';
import { PrismicPageContent } from '../PrismicPageContent';

export const PrismicSection: FC<PrismicSectionProps> = ({
  context = 'page',
  currentContentUid,
  navigation,
  tags,
  testId,
  title,
  ...props
}) => {
  const { t } = useTranslation('core');
  const { fetchDocument, isLoading, document, status } = usePageContentByUid(
    currentContentUid,
    tags,
  );
  useEffect(() => {
    if (status === RemoteData.NotAsked) {
      fetchDocument();
    }
  }, [fetchDocument, status]);

  const retryRequest = () => {
    if (status === RemoteData.Error) {
      fetchDocument();
    }
  };

  if (status === RemoteData.Error) {
    return (
      <RequestErrorMessage
        errorMessage={t('error.fetchPrismicDocument')}
        onRetryButtonClick={retryRequest}
      />
    );
  }

  if (status === RemoteData.Success && !document) {
    switch (context) {
      case 'page':
        return <NotFoundPage />;
      case 'fragment':
      case 'seoFragment':
      case 'rawContent':
      default:
        return null;
    }
  }

  const Content = document ? (
    <PrismicPageContent
      context={context}
      document={document}
      testId={`prismic-section-${currentContentUid}`}
      {...props}
    />
  ) : null;

  if (context === 'rawContent') {
    return Content;
  }

  const WrappedContent = (
    <Page.ContentFluid data-testid={testId}>
      {isLoading || !document ? <Loader py="sm" /> : Content}
    </Page.ContentFluid>
  );

  if (context === 'page') {
    return (
      <Page
        title={null}
        description={null}
        testId={`prismic-section-page-${currentContentUid}`}
      >
        {title && <PrismicTitleH1>{title}</PrismicTitleH1>}
        {navigation && <PrismicNavigation nav={navigation} />}
        <Page.Wrapper>{WrappedContent}</Page.Wrapper>
      </Page>
    );
  }

  return WrappedContent;
};
