import * as t from 'io-ts';

import { countryCodec, stateCodec } from '@gaming1/g1-core';
import {
  EChangePlayerProfileStatus,
  EGetAllCountriesStatus,
  EGetStatesStatus,
} from '@gaming1/g1-requests';

export const changePlayerProfileResponse = t.type(
  {
    Status: t.literal(EChangePlayerProfileStatus.Successful),
  },
  'ChangePlayerProfileResponse',
);

export const getCountriesResponseCodec = t.type(
  {
    List: t.array(countryCodec),
    Status: t.literal(EGetAllCountriesStatus.Successful),
  },
  'GetCountriesResponse',
);

export const getStatesResponseCodec = t.type(
  {
    States: t.array(stateCodec),
    Status: t.literal(EGetStatesStatus.Successful),
  },
  'GetStatesResponse',
);
