import { generateAssetsContextValue } from '@gaming1/g1-core-web';
import { LoyaltyAssetsContext } from '@gaming1/g1-loyalty-web';

import scratchcardBadgeCherry from './illustrations/badge-cherry.svg';
import scratchcardBadgeCrown from './illustrations/badge-crown.svg';
import scratchcardBadgeDice from './illustrations/badge-dice.svg';
import scratchcardBadgeGiftbox from './illustrations/badge-giftbox.svg';
import earnedCoins from './illustrations/earned-coins.svg';
import scratchcardModalError from './illustrations/ic-error.svg';
import scratchcardModalLose from './illustrations/ic-lose.svg';
import scratchcardSliderSprite from './illustrations/lucky-sprite.svg';
import scratchcardWinNumbers from './illustrations/scratchcardNumbers.png';

export const loyaltyAssets = generateAssetsContextValue(LoyaltyAssetsContext)({
  /* Shop */
  earnedCoins,
  scratchcardModalError,
  scratchcardModalLose,
  scratchcardBadgeCherry,
  scratchcardBadgeCrown,
  scratchcardBadgeDice,
  scratchcardBadgeGiftbox,
  scratchcardSliderSprite,
  scratchcardWinNumbers,
});
