import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { PlatformJackpot } from './types/entities';

/** Get the list of running platform jackpots */
export const getPlatformJackpots = createAsyncAction(
  'platform_jackpot/get_jackpots_request',
  'platform_jackpot/get_jackpots_success',
  'platform_jackpot/get_jackpots_failure',
)<void, PlatformJackpot[], FailurePayload>();

export const dummyAction = createAction('platform_jackpot/dummy')();
