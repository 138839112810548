import format from 'date-fns/format';
import { useCallback, useEffect, useState } from 'react';

import { Heading, Table } from '@gaming1/g1-ui';

import { logger } from '../../../logger';
import { stringifyRequestContent } from '../../helpers';
import { RequestContent } from '../styles';

type RawMessage = string | Record<string, unknown>;

const stringifyMessageContent = (message: RawMessage) => {
  if (typeof message === 'object') {
    return stringifyRequestContent(message);
  }
  try {
    const parsedMessage = JSON.parse(message);
    return stringifyRequestContent(parsedMessage);
  } catch (e) {
    logger.error('[Debug Panel] Could not parse message', e);
  }
  return '[Failed to parse message]';
};

export const MessagesDebugger = () => {
  const [messages, setMessages] = useState<
    { message: RawMessage; datetime: number }[]
  >([]);

  const handleMessage = useCallback(({ data }: MessageEvent) => {
    if (typeof data === 'string' || (typeof data === 'object' && !!data)) {
      setMessages((oldMessages) => [
        ...oldMessages,
        {
          datetime: new Date().getTime(),
          message: data,
        },
      ]);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <>
      <Heading level="h2">Messages</Heading>
      <Table>
        <thead>
          <tr>
            <th>Datetime</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {messages.map(({ datetime, message }) => (
            <tr key={datetime}>
              <td>{format(datetime, 'HH:mm:ss')}</td>
              <RequestContent shouldBeTruncated={false}>
                {stringifyMessageContent(message)}
              </RequestContent>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
