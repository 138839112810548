import { NormalizedMarket } from '@gaming1/g1-betting';
import { IconType } from '@gaming1/g1-ui';

const FALLBACK_ICON: IconType = 'Special';

export type SportAlias =
  | 'AlpineSkiing'
  | 'AmericanFootball'
  | 'AustralianFootball'
  | 'Badminton'
  | 'Baseball'
  | 'Basketball'
  | 'BeachSoccer'
  | 'BeachVolleyball'
  | 'Biathlon'
  | 'Boxing'
  | 'Cricket'
  | 'CrossCountry'
  | 'Cycling'
  | 'CycloCross'
  | 'Darts'
  | 'ESports'
  | 'Formula1'
  | 'Golf'
  | 'GrassHockey'
  | 'Handball'
  | 'IceHockey'
  | 'MMA'
  | 'MiniSoccer'
  | 'MotorSport'
  | 'Motorbikes'
  | 'Nascar'
  | 'NordicCombined'
  | 'Rugby'
  | 'SkiJump'
  | 'Snooker'
  | 'Soccer'
  | 'Squash'
  | 'TableTennis'
  | 'Tennis'
  | 'Volleyball'
  | 'WaterPolo';

const iconsMatchings: { [k in SportAlias]: IconType } = {
  AlpineSkiing: 'AlpineSkiing',
  AmericanFootball: 'AmericanFootball',
  AustralianFootball: 'AustralianFootball',
  Badminton: 'Badminton',
  Baseball: 'Baseball',
  Basketball: 'Basketball',
  BeachSoccer: 'BeachSoccer',
  BeachVolleyball: 'BeachVolleyball',
  Biathlon: 'Biathlon',
  Boxing: 'Boxing',
  Cricket: 'Cricket',
  CrossCountry: 'CrossCountrySkiing',
  Cycling: 'Cycling',
  CycloCross: 'CycloCross',
  Darts: 'Darts',
  ESports: 'ESports',
  Formula1: 'Formula1',
  Golf: 'Golf',
  GrassHockey: 'GrassHockey',
  Handball: 'Handball',
  IceHockey: 'IceHockey',
  MMA: 'Mma',
  MiniSoccer: 'MiniSoccer',
  MotorSport: 'MotorSport',
  Motorbikes: 'Motorbikes',
  Nascar: 'Nascar',
  NordicCombined: 'NordicCombined',
  Rugby: 'Rugby',
  SkiJump: 'SkiJump',
  Snooker: 'Snooker',
  Soccer: 'Soccer',
  Squash: 'Squash',
  TableTennis: 'TableTennis',
  Tennis: 'Tennis',
  Volleyball: 'Volleyball',
  WaterPolo: 'WaterPolo',
};

const parseValue = (value: string) =>
  value.replace(/\s/g, '').replace(/-/g, '').toLowerCase();

/** Get icon type by sport alias
 * @param alias Sport alias
 */
export const getIconType = (alias = '') =>
  Object.entries(iconsMatchings).find(
    ([key]) => parseValue(key) === parseValue(alias),
  )?.[1] || FALLBACK_ICON;

/** Build testId
 * @param id Base test id
 * @param params optional extra params will be added after id if specified
 */
export const getTestId = (id: string, params?: { [name: string]: string }) => {
  if (!params) {
    return id;
  }

  return Object.entries(params).reduce(
    (acc, [key, value]) => `${acc}-${key}-${value}`,
    id,
  );
};

/**
 *
 * @param market default market defined by the DefaultMarketType
 * @returns an array of outcomes column name for the default market (P1-P2 or 1-X-2)
 */
export const getMainMarketColumnNames = (
  market: NormalizedMarket | undefined,
) => {
  if (market?.BetType === 'P1P2') {
    return ['P1', 'P2'];
  }

  if (market?.BetType === 'P1XP2') {
    return ['1', 'X', '2'];
  }

  return [];
};
