import { EDepositType } from '@gaming1/g1-requests';

import { ApplicationWithPaymentState } from '../types';

import { AvailableBonus, GamingRoomsBonus, Prize } from './types';

/* Find deposit wallets */
export const findDepositsSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.findDeposits;

export const findDepositsRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.findDeposits;

export const walletListSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.depositWalletList;

export const classicWalletListSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.depositWalletList?.Wallets.filter(
    (wallet) => wallet.WalletType === EDepositType.Classic,
  ) || [];

export const voucherWalletListSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.depositWalletList?.Wallets.filter(
    (wallet) => wallet.WalletType === EDepositType.Voucher,
  ) || [];
export const fetchedWalletsSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.fetchedWallets;

export const bonusSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.depositWalletList?.BonusList || [];

export const prizesFromPrizeConfigurationsSelector = (
  state: ApplicationWithPaymentState,
) =>
  state.payment.deposit.depositWalletList?.PrizeConfigurationList.reduce(
    (acc: Prize[], prizeConfiguration) =>
      acc.concat(prizeConfiguration.Prizes || []),
    [],
  ) || [];

export const availableBonusSelector = (
  state: ApplicationWithPaymentState,
): AvailableBonus[] => {
  const bonus = bonusSelector(state);
  const prizes = prizesFromPrizeConfigurationsSelector(state);

  return [
    ...bonus.map((b: GamingRoomsBonus) => ({
      id: b.Id,
      amount: b.Value,
      description: b.Description,
      title: b.Name,
      type: 'bonus' as const,
    })),
    ...prizes.map((p: Prize) => ({
      id: p.Id,
      amount: p.Amount,
      description: p.Description,
      title: p.Title,
      type: 'prize' as const,
    })),
  ];
};

export const hasAvailableBonusSelector = (state: ApplicationWithPaymentState) =>
  availableBonusSelector(state).length > 0;

export const lastWalletSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.depositWalletList?.Wallets.find(
    (wallet) =>
      wallet.Id === state.payment.deposit.depositWalletList?.LastWalletId,
  ) || null;

export const hasLastWalletSelector = (state: ApplicationWithPaymentState) =>
  lastWalletSelector(state) !== null;

export const canUseLastWalletSelector = (
  state: ApplicationWithPaymentState,
) => {
  const lastWallet = lastWalletSelector(state);
  const walletList = walletListSelector(state);

  return (
    lastWallet &&
    walletList &&
    lastWallet.MinimumDeposit <= walletList.MaximumDeposit
  );
};

export const getDepositWalletListRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.getDepositWalletList;

export const getDepositWalletInfoRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.getDepositWalletInfo;

/* Get deposit status */

export const getDepositStatusSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.depositStatus;

export const getDepositStatusRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.getDepositStatus;

export const getDepositStatusErrorMessageParamsSelector = (
  state: ApplicationWithPaymentState,
) =>
  state.payment.deposit.depositStatus?.ErrorMessageParameters?.filter(
    (param) => !!param.Key && !!param.Value,
  ).reduce(
    (obj, param) => ({
      ...(obj && { [param.Key || '']: param.Value }),
    }),
    {},
  );

export const getDepositNextStepSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.depositNextStep;

export const getDepositNextStepRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.getDepositNextStep;

/* Make deposit */

export const makeDepositSelector = (state: ApplicationWithPaymentState) =>
  state.payment.deposit.makeDeposit;

export const makeDepositRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.makeDeposit;

/* Make voucher deposit */

export const makeVoucherDepositSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.makeVoucherDeposit;

export const makeVoucherDepositRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.deposit.requests.makeVoucherDeposit;
