import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  cashoutDebugLogs$,
  CommonDebugLog,
  getCashoutStateSelector,
} from '@gaming1/g1-betting';
import { Table } from '@gaming1/g1-ui';

export const CashoutDebugging = () => {
  const [cashoutDebugLogs, setcashoutDebugLogs] = useState<CommonDebugLog[]>(
    [],
  );

  useEffect(() => {
    const debugStoreSub = cashoutDebugLogs$.subscribe({
      next: (item) => {
        setcashoutDebugLogs((items) => [...items, item]);
      },
    });

    return () => {
      debugStoreSub.unsubscribe();
    };
  }, []);

  const cashoutList = useSelector(getCashoutStateSelector);
  const keys = Object.keys(cashoutList);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Availability</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => {
            const cashout = cashoutList[key];

            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  {cashout.result?.CashoutInformation?.CashoutAvailability}
                </td>
                <td>{cashout.result?.CashoutInformation?.FullCashoutAmount}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <th>Time</th>
            <th>ID</th>
            <th>Informations</th>
          </tr>
        </thead>
        <tbody>
          {cashoutDebugLogs
            .sort((a, b) => b.time - a.time)
            .map(({ id, info, time }) => (
              <tr key={`${time}${id}`}>
                <td>{format(time, 'HH:mm:ss')}</td>
                <td>{id}</td>
                <td>
                  <pre>{info}</pre>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};
