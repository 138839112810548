import format from 'date-fns/format';
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
  VFC,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { useShouldRenderRequestError } from '@gaming1/g1-core';
import { RequestStateErrorMessage, useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  getScratchCardHistoryRequestStateSelector,
  loyaltyActions,
  ScratchCardHistoryElement,
  scratchCardHistorySelector,
} from '@gaming1/g1-loyalty';
import { useRequestState } from '@gaming1/g1-store';
import {
  AppAnchor,
  Button,
  Icon,
  Loader,
  Message,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';
import { HISTORY_PAGE_SIZE } from '@gaming1/g1-user-api';

import { loyaltyRoutes } from '../../../routes';
import { ScratchcardHistoryInformationModal } from '../ScratchcardHistoryInformationModal';

import { getStatus } from './helpers';

export const ScratchcardHistory: VFC = () => {
  const { t } = useTranslation('loyalty');
  const { i18n } = useContext(ConfigContext);
  const getLoyaltyRoutePath = useRoutePath(loyaltyRoutes);
  const dispatch = useDispatch();

  const scratchcardHistory = useSelector(scratchCardHistorySelector);

  const [selectedScratchcardOrderForInfo, setSelectedScratchcardOrderForInfo] =
    useState<ScratchCardHistoryElement | null>();

  const [informationModalVisible, setInformationModalVisible] = useState(false);

  const { isFirstLoading, isLoading } = useRequestState(
    getScratchCardHistoryRequestStateSelector,
  );

  const { pagination: scratchcardHistoryPagination } = useSelector(
    getScratchCardHistoryRequestStateSelector,
  );

  const getScratchCardHistory = useCallback(() => {
    dispatch(
      loyaltyActions.getScratchCardHistory.request({
        PageNumber: 0,
        PageSize: HISTORY_PAGE_SIZE,
      }),
    );
  }, [dispatch]);

  useLayoutEffect(() => {
    getScratchCardHistory();
  }, [getScratchCardHistory]);

  const shouldRenderRequestError = useShouldRenderRequestError(
    getScratchCardHistoryRequestStateSelector,
  );

  if (shouldRenderRequestError) {
    return (
      <RequestStateErrorMessage
        onRetryButtonClick={getScratchCardHistory}
        selector={getScratchCardHistoryRequestStateSelector}
      />
    );
  }

  if (isFirstLoading) {
    return <Loader mt="sm" />;
  }
  return scratchcardHistory.length > 0 ? (
    <>
      <Table testId="scratchcard-history-container">
        <thead>
          <tr>
            <th>{t('history.scratchcard.orderDate')}</th>
            <th>{t('history.scratchcard.amount')}</th>
            <th>{t('history.scratchcard.status')}</th>
          </tr>
        </thead>
        <tbody>
          {scratchcardHistory.map((history, index) => (
            <tr key={history.Id} data-testid="scratchcard-history-row">
              <td data-testid="scratchcard-history-purchase-date">
                {format(
                  new Date(history.PurchaseDate),
                  i18n.dateTimePartialFormat,
                )}
              </td>
              <td data-testid="scratchcard-history-amount">
                {`${history.Amount} ${
                  history.Amount > 1
                    ? t('shopProduct.priceType.vipMoney')
                    : t('shopProduct.priceType.vipMoneySingular')
                }`}
              </td>
              <td data-testid="scratchcard-history-status">
                {!history.IsScratched ? (
                  <AppAnchor
                    href={getLoyaltyRoutePath('scratchcard', {
                      scratchcardId: history.Id,
                    })}
                  >
                    {t(getStatus(history.IsScratched, history?.PrizeId))}
                  </AppAnchor>
                ) : (
                  <>{t(getStatus(history.IsScratched, history?.PrizeId))}</>
                )}
              </td>
              <td>
                <SimpleButton
                  data-testid={`scratchcard-history-information-button-${index}`}
                  onClick={() => {
                    setSelectedScratchcardOrderForInfo(history);
                    setInformationModalVisible(true);
                  }}
                >
                  <Icon
                    id="history-info"
                    type="VerticalTripleDots"
                    height="20px"
                  />
                </SimpleButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {scratchcardHistory.length < scratchcardHistoryPagination.Total && (
        <Button
          block
          loading={isLoading}
          noBorder
          onClick={() => {
            dispatch(
              loyaltyActions.getScratchCardHistory.request({
                PageNumber: scratchcardHistoryPagination.PageNumber + 1,
                PageSize: scratchcardHistoryPagination.PageSize,
              }),
            );
          }}
          data-testid="scratchcard-history-more-button"
          type="secondary"
        >
          {t('history.seeMore')}
        </Button>
      )}
      {selectedScratchcardOrderForInfo && (
        <ScratchcardHistoryInformationModal
          history={selectedScratchcardOrderForInfo}
          onClose={() => {
            setInformationModalVisible(false);
            setSelectedScratchcardOrderForInfo(null);
          }}
          visible={informationModalVisible}
        />
      )}
    </>
  ) : (
    <Message mt="sm" type="warning">
      {t('history.noResults')}
    </Message>
  );
};
