import { useState, VFC } from 'react';

import { EDataPacketDirection } from '@gaming1/g1-network';
import { CheckboxInput, Label } from '@gaming1/g1-ui';

import { packetDirectionNames } from '../helpers';
import { RowFilterFunc, RowFilterHook } from '../types';

const directionList: EDataPacketDirection[] = [
  EDataPacketDirection.Request,
  EDataPacketDirection.Response,
  EDataPacketDirection.Broadcast,
  EDataPacketDirection.Push,
];

const filterByDirection =
  (directions: EDataPacketDirection[]): RowFilterFunc =>
  ({ direction }) =>
    !directions.length || directions.some((direc) => direction === direc);

type DirectionFilterProps = {
  selectedDirections: EDataPacketDirection[];
  onDirectionToggle: (direction: EDataPacketDirection) => void;
};

const DirectionFilter: VFC<DirectionFilterProps> = ({
  selectedDirections,
  onDirectionToggle,
}) => (
  <>
    <Label htmlFor={null}> Filter by directions:</Label>
    {directionList.map((direction) => (
      <CheckboxInput
        key={direction}
        label={packetDirectionNames[direction]}
        checked={selectedDirections.includes(direction)}
        onChange={() => onDirectionToggle(direction)}
        removeMarginTop
        wrapperProps={{ mr: 4, my: 2 }}
      />
    ))}
  </>
);

export const useFilterByDirection: RowFilterHook = () => {
  const [selectedDirections, setSelectedDirections] = useState<
    EDataPacketDirection[]
  >([]);

  const manageDirectionToggle = (direction: EDataPacketDirection) => {
    setSelectedDirections((previousValue) =>
      previousValue.includes(direction)
        ? previousValue.filter((value) => value !== direction)
        : [...previousValue, direction],
    );
  };

  const component = (
    <DirectionFilter
      selectedDirections={selectedDirections}
      onDirectionToggle={manageDirectionToggle}
    />
  );

  const filter = filterByDirection(selectedDirections);

  return {
    filter,
    component,
  };
};
