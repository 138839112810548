import styled, { css } from 'styled-components';
import {
  alignItems,
  textAlign,
  TextAlignProps,
  typography,
} from 'styled-system';

import { colors, fontSizes, fontWeight, shadows } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const SCOREBOARD_SUMMARY_BULLET_SIZE_IN_PX = 6;
const SCOREBOARD_SUMMARY_CONTENT_MAX_WIDTH_IN_PX = 560;
export const SCOREBOARD_SUMMARY_HEIGHT_IN_PX = 80;

export const ScoreBoardSummaryTemplateWrapper = styled(Box)`
  box-shadow: ${shadows(4)};
  height: ${SCOREBOARD_SUMMARY_HEIGHT_IN_PX}px;
  width: 100%;
`;

export const ScoreBoardSummaryTemplateContentWrapper = styled(Box)`
  align-items: center;
  height: 100%;
  justify-content: center;
  max-width: ${SCOREBOARD_SUMMARY_CONTENT_MAX_WIDTH_IN_PX}px;
  position: relative;
  width: 100%;
`;

export const ScoreBoardSummaryTemplateRow = styled(Box)`
  align-items: center;
  flex-direction: row;
`;

export const ScoreBoardSummaryTemplateColumn = styled(Box)`
  flex-direction: column;
  justify-content: center;
`;

export const ScoreBoardSummaryTemplateItem = styled(Box)`
  align-items: center;
  font-size: ${fontSizes('md')};
  font-weight: ${fontWeight('bold')};
  justify-content: center;
  text-align: center;

  ${alignItems}
  ${typography}
`;

export const ScoreBoardSummaryTemplateEllipsis = styled.div<TextAlignProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${textAlign}
`;

export const ScoreBoardSummaryTemplateBullet = styled(Box)<{ color?: string }>`
  background-color: ${colors('textLight')};
  border-radius: 50%;
  height: ${SCOREBOARD_SUMMARY_BULLET_SIZE_IN_PX}px;
  width: ${SCOREBOARD_SUMMARY_BULLET_SIZE_IN_PX}px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `};
`;
