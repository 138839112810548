/* eslint-disable no-console */
/*
 * This is a FORK of the "loglevel" lib (https://github.com/pimterry/loglevel)
 *
 * Improvements:
 * - written in Typescript
 * - new "setShouldAlwaysCallMethod" method
 *
 * Removals:
 * - no more legacy browsers support
 * - no built-in persistence mechanism
 * - default export
 *
 * Other than that, the API is more or less preserved
 */

/* Types */

// The console.log can accept any value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LoggingMethod = (...message: any[]) => void;

/**
 * An enum with all the possible levels a logger can be set, with their
 * corresponding number value. In the original loglevel lib, it was in a plain
 * object "levels" (with the type being LogLevel as well)
 */
export enum LogLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4,
  SILENT = 5,
}

/** Union type of log level names */
export type LogLevelNames =
  | 'TRACE'
  | 'DEBUG'
  | 'INFO'
  | 'WARN'
  | 'ERROR'
  | 'SILENT';

/** Union type of log level numbers */
export type LogLevelNumbers = 0 | 1 | 2 | 3 | 4 | 5;

/** Union type of available log level methods */
type LogMethods = 'trace' | 'debug' | 'info' | 'warn' | 'error';

/**
 * Possible log level descriptors, may be string, lower or upper case, or number.
 */
export type LogLevelDesc =
  | LogLevelNames
  | LogLevelNumbers
  | LogMethods
  | 'silent';

export type MethodFactory = (
  methodName: LogMethods,
  level: LogLevelNumbers,
  loggerName: string | symbol,
) => LoggingMethod;

/* Helpers */

const logMethods = ['trace', 'debug', 'info', 'warn', 'error'] as const;

const noop = () => null;

const maxLogLevelsByMethodName: Record<LogMethods, LogLevel> = {
  trace: LogLevel.TRACE,
  debug: LogLevel.DEBUG,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
};

const loggersByName: Record<string, Logger> = {};

/**
 * This will return you the dictionary of all loggers created with getLogger, keyed off of their names.
 */
export const getLoggers = () => loggersByName;

/** Returns true if the logging method is allowed by the current log level
 * @param methodName the log method name ('debug', 'warn', ....)
 * @param logLevel a value of the LogLevel enum
 */
export const isLoggingAllowed = (methodName: LogMethods, logLevel: LogLevel) =>
  maxLogLevelsByMethodName[methodName] >= logLevel;

/** Mutate the given logger object by replacing all its logging methods
 * with either its method factory or a noop depending on the logger level
 */
const replaceLoggingMethods = (logger: Logger) => {
  logMethods.forEach((methodName) => {
    const method =
      isLoggingAllowed(methodName, logger.getLevel()) ||
      logger.shouldAlwaysCallMethod
        ? logger.getMethodFactory()(methodName, logger.getLevel(), logger.name)
        : noop;
    // We must reassign methods to prevent the loss of the console stacktrace
    // eslint-disable-next-line no-param-reassign
    logger[methodName] = method.bind(logger);
  });
};

/** Build the best logging method possible for this env
 * Wherever possible we want to bind, not wrap, to preserve stack traces
 */
export const defaultMethodFactory: MethodFactory = (methodName: LogMethods) => {
  const trueMethodName = methodName === 'debug' ? 'log' : methodName;
  if (typeof console === undefined) {
    return noop;
  }
  if (console[trueMethodName] !== undefined) {
    const method = console[trueMethodName];
    return method.bind(console);
  }
  if (console.log !== undefined) {
    const fallbackMethod = console.log;
    return fallbackMethod.bind(console);
  }
  return noop;
};

/**
 * Get a log level name (in uppercase) from a number
 * @param levelNumber 0 | 1 | 2 | 3 | 4 | 5
 */
export const getLoglevelNameFromNumber = (
  levelNumber: LogLevelNumbers,
): LogLevelNames =>
  (Object.keys(LogLevel) as LogLevelNames[]).find(
    (key) => LogLevel[key] === levelNumber,
  ) || 'SILENT';

/**
 * Get a log level number  from a name (in uppercase)
 * @param levelNumber 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'SILENT'
 */
export const getLoglevelNumberFromName = (
  logLevelName: string,
): LogLevelNumbers | undefined =>
  logLevelName in LogLevel
    ? LogLevel[logLevelName as keyof typeof LogLevel]
    : undefined;

export class Logger {
  public shouldAlwaysCallMethod = false;

  public name: string;

  private currentLevel: LogLevel = LogLevel.SILENT;

  private methodFactory: MethodFactory = defaultMethodFactory;

  /**
   *
   * @param name the unique name of the logger
   * @param [initialLevel] the initial log level. WARN by default
   * @param [methodFactory] an optional method factory for logging instead of the
   * default one
   */
  constructor(
    name: string,
    initialLevel: LogLevel = LogLevel.WARN,
    methodFactory: MethodFactory = defaultMethodFactory,
  ) {
    this.name = name;

    this.methodFactory = methodFactory;

    this.setLevel(initialLevel);
  }

  /**
   * Returns the current logging level, as a value from LogLevel.
   * It's very unlikely you'll need to use this for normal application logging; it's provided partly to help plugin
   * development, and partly to let you optimize logging code as below, where debug data is only generated if the
   * level is set such that it'll actually be logged. This probably doesn't affect you, unless you've run profiling
   * on your code and you have hard numbers telling you that your log data generation is a real performance problem.
   */
  public getLevel() {
    return this.currentLevel;
  }

  /**
   * This disables all logging below the given level, so that after a log.setLevel("warn") call log.warn("something")
   * or log.error("something") will output messages, but log.info("something") will not.
   *
   * @param level as a string, like 'error' (case-insensitive) or as a number from 0 to 5 (or as log.levels. values)
   */
  public setLevel(level: LogLevel | LogLevelNumbers) {
    this.currentLevel =
      typeof level === 'number'
        ? level
        : getLoglevelNumberFromName(level) ?? LogLevel.SILENT;

    // Must be run each time the level is changed
    replaceLoggingMethods(this);
  }

  /**
   * Set the level of the logger. Since the persistence logic has been removed,
   * it's a duplicate of "setLevel"
   * @deprecated
   */
  public setDefaultLevel = (level: LogLevel | LogLevelNumbers) => {
    this.setLevel(level);
  };

  /**
   * This enables all log messages, and is equivalent to log.setLevel("trace").
   */
  public enableAll() {
    this.setLevel(LogLevel.TRACE);
  }

  /**
   * This disables all log messages, and is equivalent to log.setLevel("silent").
   */
  public disableAll() {
    this.setLevel(LogLevel.SILENT);
  }

  /**
   * Make it possible to overwrite the default behaviour of not calling the
   * method factory if the method used is not permitted by the current log
   * level. Only useful for plugins when using setMethodFactory()
   * @param shouldAlwaysCallMethod if true, the method factory will always
   * be called regardless of the current level
   */
  public setShouldAlwaysCallMethod(shouldAlwaysCallMethod: boolean) {
    this.shouldAlwaysCallMethod = shouldAlwaysCallMethod;
    replaceLoggingMethods(this);
  }

  /**
   * Plugin API entry point. The factory will be called for each enabled method
   * each time the level is set (including initially), and should return a
   * method to be used for the given log method, at the given level, for a
   * logger with the given name. If you'd like to retain all the reliability and
   * features of loglevel, it's recommended that this wraps the initially
   * provided defaultMethodFactory
   *
   * If called without argument, will restore the default factory
   */
  public setMethodFactory(factory: MethodFactory = defaultMethodFactory) {
    this.methodFactory = factory;
    replaceLoggingMethods(this);
  }

  /** Get the current logger method factory */
  public getMethodFactory() {
    return this.methodFactory;
  }

  /**
   * Output trace message to console.
   * This will also include a full stack trace
   *
   * @param msg any data to log to the console
   */
  public trace: LoggingMethod = noop;

  /**
   * Output debug message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  public debug: LoggingMethod = noop;

  /**
   * Output info message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  public info: LoggingMethod = noop;

  /**
   * Output warn message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  public warn: LoggingMethod = noop;

  /**
   * Output error message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  public error: LoggingMethod = noop;
}

/**
 * This gets you a new logger object that  have its level and logging methods
 * set independently. All loggers must have a name (which is a non-empty string)
 * Calling getLogger() multiple times with the same name will return an
 * identical logger object. In large applications, it can be incredibly useful
 * to turn logging on and off for particular modules as you are working with
 * them. Using the getLogger() method lets you create a separate logger for each
 * part of your application with its own logging level. Likewise, for small,
 * independent modules, using a named logger instead of the default root logger
 * allows developers using your module to selectively turn on deep, trace-level
 * logging when trying to debug problems, while logging only errors or silencing
 * logging altogether under normal circumstances.
 * @param name The name of the produced logger
 */
export const getLogger = (name: string) => {
  let logger = loggersByName[name];
  if (!logger) {
    logger = new Logger(name, LogLevel.WARN, defaultMethodFactory);
  }
  loggersByName[name] = logger;
  return logger;
};
