import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';

import { media, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

import { getGridItemWidth } from '../../helpers';

export type PrismicMediasTextContainerProps = {
  alignment: 'left' | 'right';
};

export const PrismicMediasTextContainer = styled(
  Box,
)<PrismicMediasTextContainerProps>`
  display: flex;
  flex-direction: column;

  ${media.md} {
    flex-direction: ${({ alignment }) =>
      alignment === 'right' ? 'row-reverse' : 'row'};

    & > .content-wrapper {
      ${({ alignment }) => css`
        ${alignment === 'right' ? 'padding-right' : 'padding-left'}: ${spaces(
          'sm',
        )};
      `};
    }
  }

  & > .content-wrapper > :first-child {
    margin-top: 0;
  }
`;

export type PrismicMediasTextGridProps = ThemeProps<DefaultTheme> & {
  itemsCount: number;
};

export const PrismicMediasTextGrid = styled(Box)<PrismicMediasTextGridProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    margin: ${spaces('xs')} 0;
    width: ${(props) =>
      getGridItemWidth(props.itemsCount, `${spaces('sm')(props)}`)};
  }
`;
