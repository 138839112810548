import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  actions,
  BettingSlipIdType,
  placeBettingSlipRequestSuccessGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

export const useAutomating = ({ bettingSlipId }: BettingSlipIdType) => {
  const dispatch = useDispatch();

  const isSuccess = useBettingGetterSelector({
    getterSelector: placeBettingSlipRequestSuccessGetterSelector,
    args: [bettingSlipId],
  });

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    dispatch(actions.getFreebets.request({}));

    dispatch(actions.getHasBoostusAvailable.request());
    dispatch(actions.getAvailableBoostus.request({}));

    dispatch(actions.hasAvailablePromotions.request());
    dispatch(actions.getAvailablePromotions.request({}));
  }, [isSuccess, dispatch]);
};
