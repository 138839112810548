import React, { FC, memo, useContext, useEffect, useState } from 'react';

import {
  actions,
  bettingSlipRankAvailableGetterSelector,
  bettingSlipRankNbElementsGetterSelector,
  bettingSlipRankStakeGetterSelector,
  bettingSlipRankStakeInFloatGetterSelector,
  isHighlightStakeByError,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
  useBettingSlipRankErrorFrontType,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { useIsDesktopTicket } from '../../hooks/render';
import { BettingSlipRankError } from '../BettingSlipError/BettingSlipRankError';
import { BettingSlipNumpad } from '../BettingSlipNumpad';
import { BettingSlipNumpadContext } from '../BettingSlipNumpad/BettingSlipNumpadContext';
import { useNumpadActionManage } from '../BettingSlipNumpad/hooks';

import { BettingSlipNbCombinationsContainer } from './styles';

import { BettingSlipStake } from '.';

type BettingSlipItemRankStakeProps = {
  /** Id to find ticket item */
  id: string;
  /** The number of combinations to display near the input */
  nbCombinations: number;
};

/**
 * Display the stake for an item
 * show and manage stake
 */
const BettingSlipRankStakeComponent: FC<BettingSlipItemRankStakeProps> = ({
  id,
  nbCombinations,
}) => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const { t } = useTranslation('betting');
  const isDesktop = useIsDesktopTicket();

  const {
    hasKeyBoardCapabilities,
    showKeyBoard,
    showNumpad,
    focusChanged,
    closeKeyboard,
  } = useNumpadActionManage(bettingSlipId);

  const { setInputFocused, currentInput } = useContext(
    BettingSlipNumpadContext,
  );

  /**
   * To know if we have to let the system inputs blank or not.
   * If the user comes for the first time in the system section, the inputs will be blank instead of showing : '0.00{currency}'.
   */
  const [isSetAtZeroByUser, setIsSetAtZeroByUser] = useState(false);

  const nbElements = useBettingGetterSelector({
    getterSelector: bettingSlipRankNbElementsGetterSelector,
    args: [bettingSlipId, id],
  });

  const stake = useBettingGetterSelector({
    getterSelector: bettingSlipRankStakeGetterSelector,
    args: [bettingSlipId, id],
  });

  const stakeInFloat = useBettingGetterSelector({
    getterSelector: bettingSlipRankStakeInFloatGetterSelector,
    args: [bettingSlipId, id],
  });

  const available = useBettingGetterSelector({
    getterSelector: bettingSlipRankAvailableGetterSelector,
    args: [bettingSlipId, id],
  });

  const isErrorStake = isHighlightStakeByError(
    useBettingSlipRankErrorFrontType(bettingSlipId)(id),
  );

  const updateStake = (newStake: string) => {
    if (!isSetAtZeroByUser && newStake === '0') {
      setIsSetAtZeroByUser(true);
    }
    allowBSActions(
      actions.updateStakeForRank({
        stake: newStake,
        rankId: id,
        bettingSlipId,
      }),
    );
  };

  /**
   * When the rank input is focused, we need to notify the keyboard so it could be displayed.
   * We give the id of the rank input to the BettingSlipNumpadContext so we can display the keyboard ONLY for this input.
   */
  const inputIsFocused = (isFocused: boolean) => {
    if (isFocused) {
      setInputFocused(id);
      focusChanged(true);
    }
  };

  const closeInputKeyboard = () => {
    setInputFocused(null);
  };

  /**
   * When the currentInput (the focused one) is not the same as {id} anymore, it means that we can close the keyboard for the {id} input.
   */
  useEffect(() => {
    if (currentInput !== id) {
      closeKeyboard();
    }
  });

  return (
    <>
      <Box
        flexDirection="row"
        alignItems="center"
        mb="xs"
        width="100%"
        data-testid={`${id}-rank-stake`}
        justifyContent="space-between"
      >
        <Box width="50%">
          {t(
            `bettingslip.${id.toLocaleLowerCase()}${
              id === 'SYSTEM_COMBI' ? `_${nbElements}` : ''
            }`,
          )}
        </Box>
        <Box flexDirection="row" alignItems="center" justifyContent="flex-end">
          <BettingSlipNbCombinationsContainer disabled={!available}>
            {`${nbCombinations}x`}
          </BettingSlipNbCombinationsContainer>
          <BettingSlipStake
            testId={`bettingslip-system-stake-input-${id}`}
            inError={isErrorStake}
            stake={stake}
            stakeInFloat={
              isSetAtZeroByUser && stakeInFloat === 0 ? undefined : stakeInFloat
            }
            updateStake={updateStake}
            focusChanged={inputIsFocused}
            hasKeyBoardCapabilities={hasKeyBoardCapabilities}
            isKeyboardDisplayed={isDesktop ? false : currentInput === id}
            disabled={!available}
            shouldBeBlank={!isSetAtZeroByUser && stakeInFloat === 0}
          />
        </Box>
      </Box>
      {available && <BettingSlipRankError id={id} />}
      {showNumpad ? (
        <BettingSlipNumpad
          showKeyBoard={showKeyBoard}
          stake={stake?.toString() || ''}
          updateStake={updateStake}
          askClose={closeInputKeyboard}
        />
      ) : undefined}
    </>
  );
};
export const BettingSlipRankStake = memo(BettingSlipRankStakeComponent);
