import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { BetPipeIdentifier } from '../betPipe/types';

import {
  GetAvailableBoostusResponseType,
  GetBoostusParamsType,
  HasAvailableFeatureResponseType,
} from './types';

/** Get has boostus available */
export const getHasBoostusAvailable = createAsyncAction(
  'betting/get_has_boostus_available_request',
  'betting/get_has_boostus_available_success',
  'betting/get_has_boostus_available_failure',
)<void, HasAvailableFeatureResponseType, FailurePayload>();

export const getBoostusWithCache = createAction('betting/get_boostuswithcache')<
  BetPipeIdentifier & GetBoostusParamsType
>();

export const getAvailableBoostus = createAsyncAction(
  'betting/get_available_boostus_request',
  'betting/get_available_boostus_success',
  'betting/get_available_boostus_failure',
)<
  BetPipeIdentifier & GetBoostusParamsType,
  GetAvailableBoostusResponseType & BetPipeIdentifier & GetBoostusParamsType,
  FailurePayload & BetPipeIdentifier & GetBoostusParamsType
>();
