import { createAsyncAction } from 'typesafe-actions';

import { ShopSearchRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import { SearchShopProductsData } from './types';

/** Search shop products */
export const searchShopProducts = createAsyncAction(
  'loyalty/search_shop_products_request',
  'loyalty/search_shop_products_success',
  'loyalty/search_shop_products_failure',
)<ShopSearchRequestDTO, SearchShopProductsData, FailurePayload>();
