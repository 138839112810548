import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  CashoutInfoRequest,
  CashoutRequest,
} from '@gaming1/g1-requests-betting';

import {
  CashoutFailureResponseType,
  CashoutIdentificationType,
  CashoutResponseType,
  GetCashoutInfoFailureResponseType,
  GetCashoutInfoResponseType,
  HistoryAndCashoutIdentification,
} from './types';

export const cashout = createAsyncAction(
  'betting/cashout_request',
  'betting/cashout_success',
  'betting/cashout_failure',
)<
  CashoutRequest,
  CashoutResponseType & CashoutIdentificationType,
  CashoutFailureResponseType & CashoutIdentificationType
>();

export const getCashoutInfo = createAsyncAction(
  'betting/get_cashout_info_request',
  'betting/get_cashout_info_success',
  'betting/get_cashout_info_failure',
)<
  CashoutInfoRequest,
  GetCashoutInfoResponseType & CashoutIdentificationType,
  GetCashoutInfoFailureResponseType & CashoutIdentificationType
>();

export const closeHistoryTicket = createAction(
  'betting/close_history_ticket',
)<HistoryAndCashoutIdentification>();
