import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import {
  actions,
  bettingSlipItemGetterSelector,
  bettingSlipItemIsCheckedGetterSelector,
  bettingSlipItemIsValidOutcomeGetterSelector,
  BettingSlipOutcomeSelection,
  eventNameSelector,
  eventSelector,
  getInitialTicketItem,
  hasCashoutAvailableForPlayerSelector,
  marketSelector,
  outcomeSelector,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
  useBettingSlipIsItemInterationError,
} from '@gaming1/g1-betting';
import { ConfigContext } from '@gaming1/g1-config';
import { Box, CheckboxInput, Icon, SimpleButton } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../../common/components/BettingIcon';
import { BettingSlipContext } from '../../../BettingSlipContext';
import { BettingSlipItemOdds } from '../../BettingSlipOdds/BettingSlipItemOdds';
import {
  EventNameContainer,
  OutcomeBaseContainer,
  OutcomeNameContainer,
  TicketItem,
  TicketItemWrapper,
} from '../styles';

import { BettingSlipStakeInfo } from './BettingSlipStakeInfo';

type BettingSlipItemProps = {
  /** id to find ticket item */
  id: number;
};

/**
 * Display the ticket item
 * a item (base on outcome) select by user and save into store
 */
const BettingSlipItemComponent: FC<BettingSlipItemProps> = ({ id }) => {
  const { bettingSlipId, type } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const { betting } = useContext(ConfigContext);

  const item =
    useBettingGetterSelector({
      getterSelector: bettingSlipItemGetterSelector,
      args: [bettingSlipId, id],
      equalityFn: shallowEqual,
    }) || getInitialTicketItem({ outcome: -1, market: -1, event: -1 });

  const isErrorInteraction = useBettingSlipIsItemInterationError(
    bettingSlipId,
    item.id,
  );

  const hasCashoutAvailable = useBettingGetterSelector({
    getterSelector: hasCashoutAvailableForPlayerSelector,
    args: [],
  });

  const event = useBettingGetterSelector({
    getterSelector: eventSelector,
    args: [item?.event.id || 0, false],
    equalityFn: shallowEqual,
  });
  const eventName = useBettingGetterSelector({
    getterSelector: eventNameSelector,
    args: [item?.event.id || 0],
  });

  const market = useBettingGetterSelector({
    getterSelector: marketSelector,
    args: [item?.market.id || 0, false],
    equalityFn: shallowEqual,
  });

  const hasOptiOdds = market?.HasOptiOdds;

  const playerMarketName = market?.PlayerName
    ? `${market?.PlayerName} / ${market?.TeamName}`
    : '';

  const outcome = useBettingGetterSelector({
    getterSelector: outcomeSelector,
    args: [item?.outcome.id || 0, false],
    equalityFn: shallowEqual,
  });
  const outcomeName = outcome?.Name;

  const isChecked = useBettingGetterSelector({
    getterSelector: bettingSlipItemIsCheckedGetterSelector,
    args: [bettingSlipId, id],
  });
  const isLocked = !useBettingGetterSelector({
    getterSelector: bettingSlipItemIsValidOutcomeGetterSelector,
    args: [bettingSlipId, id],
  });

  const toggleCheck = useCallback(() => {
    allowBSActions(actions.toggleCheckItem(item.id, bettingSlipId));
  }, [allowBSActions, item, bettingSlipId]);

  const remove = useCallback(() => {
    const outcomeSelection: BettingSlipOutcomeSelection = {
      ids: {
        outcome: item.outcome.id,
        market: item.market.id,
        event: item.event.id,
      },
      bettingSlipId,
    };
    allowBSActions(actions.removeBettingSlipOutcome(outcomeSelection));
  }, [item, bettingSlipId, allowBSActions]);

  const content = useMemo(
    () => (
      <TicketItem
        data-testid={`bettingslip-item-${item.id}`}
        checked={isChecked}
        lock={isLocked}
        isError={isErrorInteraction}
      >
        <TicketItemWrapper>
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            data-testid="bettingslip-item-outcome"
            mb="xxxs"
          >
            <Box alignItems="center" flexDirection="row">
              {isLocked || isErrorInteraction ? (
                <Icon id="locked-outcome" type="LockClosed" />
              ) : (
                <CheckboxInput
                  checked={isChecked}
                  onChange={toggleCheck}
                  removeMarginTop
                />
              )}
              <OutcomeNameContainer>
                {outcomeName}
                {outcome?.Base != null && (
                  <OutcomeBaseContainer>({outcome?.Base})</OutcomeBaseContainer>
                )}
              </OutcomeNameContainer>
            </Box>
            <Box alignItems="center" flexDirection="row">
              {!isLocked && <BettingSlipItemOdds id={id} />}
              <SimpleButton
                onClick={remove}
                data-testid="bettingslip-remove-item"
              >
                <Icon
                  id="clear-bettingslip"
                  type="Trash"
                  ml="xs"
                  width="12"
                  height="12"
                />
              </SimpleButton>
            </Box>
          </Box>

          {market?.MarketName}

          {!!playerMarketName && (
            <Box
              fontSize="md"
              fontWeight="lighter"
              data-testid="bettingslip-item-player-market"
            >
              {playerMarketName}
            </Box>
          )}

          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            fontSize="md"
            fontWeight="light"
            mt="xs"
            data-testid="bettingslip-item-market"
          >
            <EventNameContainer data-testid="bettingslip-item-event">
              {eventName}
            </EventNameContainer>
            <Box flexDirection="row">
              {/* TODO: create unit test but need to wait for API */}
              {hasOptiOdds && type !== 'system' && (
                <BettingIcon
                  id="optiodds"
                  type="OptiOddsTag"
                  title="Optiodds"
                  mr="xxs"
                  height={16}
                  width={16}
                />
              )}
              {hasCashoutAvailable &&
                market?.IsCashable &&
                type !== 'system' && (
                  <BettingIcon
                    id="cashout"
                    type="CashoutTag"
                    title="Cashout"
                    mr="xxs"
                    height={16}
                    width={16}
                  />
                )}
            </Box>
          </Box>
          {type === 'single' && betting.bettingslip.multiSingle ? (
            <BettingSlipStakeInfo id={item.id} />
          ) : null}
        </TicketItemWrapper>
      </TicketItem>
    ),
    [
      item,
      isChecked,
      isLocked,
      toggleCheck,
      isErrorInteraction,
      outcomeName,
      outcome,
      remove,
      market,
      playerMarketName,
      eventName,
      hasOptiOdds,
      type,
      hasCashoutAvailable,
      id,
      betting.bettingslip.multiSingle,
    ],
  );

  if (item.id === -1 || !event || !market || !outcome) {
    return null;
  }

  return content;
};

export const BettingSlipItem = memo(BettingSlipItemComponent);
