import React, { FC } from 'react';

import { RenderOnMedia } from '@gaming1/g1-ui';

import { useIsFullscreenLayout } from '../../../routing/hooks';
import { desktopLayoutBreakpoint } from '../../constants';

import { Menu } from './Menu';
import {
  TopBarContainer,
  TopBarContentContainer,
  TopBarSeparator,
} from './styles';
import { TopBarClock } from './TopBarClock';
import { TopBarLocaleSwitcher } from './TopBarLocaleSwitcher';

type TopBarCompoundComponents = {
  Clock: typeof TopBarClock;
  Separator: typeof TopBarSeparator;
  Menu: typeof Menu;
  LocaleSwitcher: typeof TopBarLocaleSwitcher;
};

export const TopBar: FC & TopBarCompoundComponents = ({ children }) => {
  const isFullscreenLayout = useIsFullscreenLayout();

  return isFullscreenLayout ? null : (
    <RenderOnMedia min={desktopLayoutBreakpoint}>
      <TopBarContainer>
        <TopBarContentContainer>{children}</TopBarContentContainer>
      </TopBarContainer>
    </RenderOnMedia>
  );
};

TopBar.Clock = TopBarClock;
TopBar.Separator = TopBarSeparator;
TopBar.Menu = Menu;
TopBar.LocaleSwitcher = TopBarLocaleSwitcher;
