/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:44:04 16-07-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  PlaceBetRequest,
  PlaceBetResponse,
  PlaceSystemBetRequest,
  PlaceSystemBetResponse,
} from '../entities';

export const placeBet = (options: PlaceBetRequest) =>
  createRequest<PlaceBetResponse>(202, 'PlaceBet', options);

export const placeSystemBet = (options: PlaceSystemBetRequest) =>
  createRequest<PlaceSystemBetResponse>(202, 'PlaceSystemBet', options);
