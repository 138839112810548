import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { GTMDataBlock } from './types';

/** Set the blackBox information from Iovation */
export const setBlackBoxes = createAction(
  'core/set_blackBoxes',
  (blackBoxes: { ioBlackBox: string; fpBlackBox?: string }) => blackBoxes,
)();

export const getGTMEvents = createAsyncAction(
  'core/get_gtm_events_request',
  'core/get_gtm_events_success',
  'core/get_gtm_events_failure',
)<void, GTMDataBlock[], FailurePayload>();

export const markGTMEventsAsSent = createAsyncAction(
  'core/mark_gtm_events_as_sent_request',
  'core/mark_gtm_events_as_sent_success',
  'core/mark_gtm_events_as_sent_failure',
)<string[], string[], FailurePayload>();
