import React, { useContext, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { useTranslation } from '@gaming1/g1-i18n';

import { MenuItem } from '../types';

import {
  BottomNavIcon,
  BottomNavIconContainer,
  BottomNavIconText,
  BottomNavLabel,
} from './styles';

type BottomNavItemContentProps = Pick<
  MenuItem,
  'icon' | 'label' | 'testId' | 'iconText'
> & {
  /** The component should hide the label while keeping the icon */
  isLabelHidden: boolean;
};

export const BottomNavItemContent: VFC<BottomNavItemContentProps> = ({
  icon,
  iconText,
  label,
  isLabelHidden,
  testId,
}) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <>
      {!!icon && typeof icon === 'string' ? (
        <BottomNavIconContainer>
          <BottomNavIcon
            type={icon}
            id={`bottom-nav-${icon.toLowerCase()}`}
            fill={colors.bottomNavText}
          />
          {iconText && <BottomNavIconText>{iconText}</BottomNavIconText>}
        </BottomNavIconContainer>
      ) : (
        icon
      )}
      {!isLabelHidden && (
        <BottomNavLabel data-testid={`${testId}-label`}>
          {t(label)}
        </BottomNavLabel>
      )}
    </>
  );
};
