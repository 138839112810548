import React, { VFC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { colors } from '@gaming1/g1-style';

const mouseBounce = keyframes`
  0%   { transform: translateY(0); }
  50%  { transform: translateY(2px); }
  100% { transform: translateY(0); }
`;

const arrowBounce = keyframes`
  0%   { transform: translateY(0); }
  50%  { transform: translateY(3px); }
  100% { transform: translateY(0); }
`;

const AnimatedIconSvg = styled.svg<SpaceProps>`
  bottom: 0;
  fill: ${colors('text')};
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  ${space}

  ${({ fill }) =>
    fill &&
    css`
      fill: ${fill};
    `}

  & #mouse path {
    animation: ${mouseBounce};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  & #arrow path {
    animation: ${arrowBounce};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

type ScrollableContentHintProps = {
  /** The color of the hint icon (defaults to text color) */
  fill?: string;
  /** The size of the hint icon (e.g. "150px" or "1em") */
  size: string;
} & SpaceProps;

export const ScrollableContentHint: VFC<ScrollableContentHintProps> = ({
  fill,
  size,
  ...spaceProps
}) => (
  <AnimatedIconSvg
    data-testid="scrollable-hint"
    fill={fill}
    height={size}
    version="1.1"
    viewBox="0 0 16 18"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...spaceProps}
  >
    <title>Please scroll down</title>
    <g id="arrow" stroke="none" fillRule="evenodd">
      <path
        d="M9.16781808,11.7962953 C9.37420946,11.6128363 9.69024565,11.6314267 9.87370466,11.8378181 C10.0367793,12.0212771 10.0402101,12.291369 9.89400391,12.4779737 L9.83218192,12.5437047 L8.33218192,13.877038 C8.16641928,14.0243826 7.92713,14.0428006 7.74298571,13.9322922 L7.66781808,13.877038 L6.16781808,12.5437047 C5.9614267,12.3602457 5.94283633,12.0442095 6.12629534,11.8378181 C6.28937001,11.6543591 6.55719703,11.6192861 6.75965614,11.7426061 L6.83218192,11.7962953 L7.99999464,12.834 L9.16781808,11.7962953 Z"
        id="arrow"
      />
    </g>

    <g id="mouse" stroke="none" fillRule="evenodd">
      <path
        d="M8,1.70530257e-13 C9.68446849,1.70530257e-13 11.05,1.36553151 11.05,3.05 L11.05,3.05 L11.05,6.62 C11.05,8.30446849 9.68446849,9.67 8,9.67 C6.31553151,9.67 4.95,8.30446849 4.95,6.62 L4.95,6.62 L4.95,3.05 C4.95,1.36553151 6.31553151,1.70530257e-13 8,1.70530257e-13 Z M8,1 C6.86781626,1 5.95,1.91781626 5.95,3.05 L5.95,3.05 L5.95,6.62 C5.95,7.75218374 6.86781626,8.67 8,8.67 C9.13218374,8.67 10.05,7.75218374 10.05,6.62 L10.05,6.62 L10.05,3.05 C10.05,1.91781626 9.13218374,1 8,1 Z M8,2.42 C8.24545989,2.42 8.44960837,2.59687516 8.49194433,2.83012437 L8.5,2.92 L8.5,4.17 C8.5,4.44614237 8.27614237,4.67 8,4.67 C7.75454011,4.67 7.55039163,4.49312484 7.50805567,4.25987563 L7.5,4.17 L7.5,2.92 C7.5,2.64385763 7.72385763,2.42 8,2.42 Z"
        id="mouse"
      />
    </g>
  </AnimatedIconSvg>
);
