import React, { FC } from 'react';

import {
  BettingHistoryTicketType,
  BettingSlipHistoryType,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { BettingSlipType } from '@gaming1/g1-requests-betting';
import { Box, Text } from '@gaming1/g1-ui';

import { HistoryStake } from '../../HistoryStake';
import { OpacityContainer } from '../../styles';
import { handleTicketType } from '../helpers';

import { ClickableSummaryContainer, HistoryStakeContainer } from './styles';
import { UpdatedOdds } from './UpdatedOdds';
import { UpdatedWinnings } from './UpdatedWinnings';

type HistoryCardFooterProps = {
  activeTab: BettingSlipHistoryType;
  hasMobileView: boolean;
  historyTicket: BettingHistoryTicketType;
  onClick: () => void;
};

/**
 * It'll render a clickable container displaying the final odds
 * (or boosted odds if it uses a boostus), the total stake and the
 * potential winnings (or winnings if the ticket is closed).
 */
export const HistoryCardFooter: FC<HistoryCardFooterProps> = ({
  activeTab,
  hasMobileView,
  historyTicket,
  onClick,
}) => {
  const { betting } = useConfig();
  const updateOddsOrWinnings = betting.boostus.applyTo;
  const { t } = useTranslation('betting');

  const ticketType = handleTicketType(historyTicket);

  const { BaseOdds, BaseWinnings, FinalOdds, FinalWinnings, Stake, Type } =
    historyTicket;

  return (
    <ClickableSummaryContainer
      onClick={onClick}
      padding="sm"
      data-testid="clickable-summary-container-card"
    >
      <Box>
        <OpacityContainer>
          {ticketType === 'boostus'
            ? t('history.boostusOdd.text')
            : t('history.mobileOdd.text')}
        </OpacityContainer>
        <Text fontWeight="bold" mt="xxs" data-testid="card-odd-text-component">
          {Type !== BettingSlipType.System ? (
            <UpdatedOdds
              baseOdds={BaseOdds}
              finalOdds={FinalOdds}
              updateType={updateOddsOrWinnings}
              id="history-footer"
            />
          ) : (
            '-'
          )}
        </Text>
      </Box>
      <HistoryStakeContainer hasMobileView={hasMobileView}>
        <HistoryStake stake={Stake} giftType={ticketType} isCompact />
      </HistoryStakeContainer>
      <Box>
        <OpacityContainer>
          {activeTab === 'close'
            ? t('history.winnings.header')
            : t('history.potentialWinnings.header')}
        </OpacityContainer>
        <UpdatedWinnings
          baseWinnings={BaseWinnings}
          finalWinnings={FinalWinnings}
          updateType={updateOddsOrWinnings}
        />
      </Box>
    </ClickableSummaryContainer>
  );
};
