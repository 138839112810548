import { OverwriteConfig } from '@gaming1/g1-config';

/**
 * List of Network config properties that are allowed to be
 * edited in separated fields
 */
export const editableNetworkConfigFieldsList: (keyof OverwriteConfig)[] = [
  'apiUrl',
  'wsUrl',
  'prismicUrl',
];
