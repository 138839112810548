import React, { FC, memo, useContext } from 'react';
import { useSelector } from 'react-redux';

import { bettingSlipRankNbCombinationsGetterSelector } from '@gaming1/g1-betting';

import { BettingSlipContext } from '../../../BettingSlipContext';
import { BettingSlipRankStake } from '../../BettingSlipStake/BettingSlipRankStake';

type BettingSlipRankProps = {
  /** id to find ticket item */
  id: string;
};

/**
 * Display the ticket item
 * a item (base on outcome) select by user and save into store
 */
const BettingSlipRankComponent: FC<BettingSlipRankProps> = ({ id }) => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const nbCombinations = useSelector(
    bettingSlipRankNbCombinationsGetterSelector,
  )(bettingSlipId, id);

  return <BettingSlipRankStake id={id} nbCombinations={nbCombinations ?? 0} />;
};

export const BettingSlipRank = memo(BettingSlipRankComponent);
