import styled from 'styled-components';

import {
  colors,
  device,
  fontSizes,
  fontWeight,
  media,
  shadows,
  spaces,
} from '@gaming1/g1-style';
import { Box, Heading, SimpleButton, Text } from '@gaming1/g1-ui';

const CARD_MAX_WIDTH_WHEN_IS_ALONE_ON_THE_ROW_IN_PX = 430;
const AMOUNT_BORDER_RADIUS_IN_PX = 4;
const CARD_IMAGE_HEIGHT_IN_PX = 185;
const DETAILS_BUTTON_LETTER_SPACING_IN_PX = 0.5;
const CARD_HEADING_CUSTOM_LINE_HEIGHT_IN_PX = 30;

export const StyledPromotionCard = styled(Box)`
  background-color: ${colors('backgrounds', {
    index: 3,
  })};
  border-radius: ${AMOUNT_BORDER_RADIUS_IN_PX}px;
  box-shadow: ${shadows(2)};
  height: 100%;
  max-width: ${CARD_MAX_WIDTH_WHEN_IS_ALONE_ON_THE_ROW_IN_PX}px;
  margin-bottom: ${spaces('sm')};
  width: 100%;

  ${media.xl} {
    max-width: none;
  }
  ${device.canHover} {
    :hover {
      cursor: pointer;
    }
  }
`;

export const PromotionInfo = styled(Box)`
  height: 100%;
  padding: 0 ${spaces('sm')} ${spaces('md')};
`;

export const PromotionPicture = styled(Box)`
  img {
    border-top-right-radius: ${AMOUNT_BORDER_RADIUS_IN_PX}px;
    border-top-left-radius: ${AMOUNT_BORDER_RADIUS_IN_PX}px;
    height: ${CARD_IMAGE_HEIGHT_IN_PX}px;
    width: 100%;
  }
`;

export const DetailsButton = styled(SimpleButton)`
  ${fontWeight('bold')};
  color: ${colors('buttonSecondaryText')};
  display: flex;
  font-size: ${fontSizes('md')};
  letter-spacing: ${DETAILS_BUTTON_LETTER_SPACING_IN_PX}px;
  text-transform: uppercase;
`;

export const Summary = styled(Text)`
  font-size: ${spaces('sm')};
  height: 100%;
  margin-bottom: ${spaces('sm')};
  margin-top: ${spaces('xs')};
`;

export const SummaryTitle = styled(Heading).attrs(() => ({
  level: 'h4',
}))`
  line-height: ${CARD_HEADING_CUSTOM_LINE_HEIGHT_IN_PX}px;
`;
