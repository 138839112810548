/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       06:43:33 18-08-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  FindDepositsRequestDTO,
  FindDepositsResponseDTO,
  GetDepositHistoryRequestDTO,
  GetDepositHistoryResponseDTO,
  GetDepositInfoRequestDTO,
  GetDepositInfoResponseDTO,
  GetDepositLimitationRequestDTO,
  GetDepositLimitationResponseDTO,
  GetDepositStatusRequestDTO,
  GetDepositStatusResponseDTO,
  GetDepositWalletInfoRequestDTO,
  GetDepositWalletInfoResponseDTO,
  GetDepositWalletsListRequestDTO,
  GetDepositWalletsListResponseDTO,
  MakeDepositPrepaidCardRequestDTO,
  MakeDepositPrepaidCardResponseDTO,
  MakeDepositRequestDTO,
  MakeDepositResponseDTO,
  MakeVoucherDepositRequestDTO,
  SetDepositLimitationRequestDTO,
  SetDepositLimitationResponseDTO,
} from '../entities';

export const getDepositInfo = (options: GetDepositInfoRequestDTO) =>
  createRequest<GetDepositInfoResponseDTO>(100, 'GetDepositInfo', options);

export const makeDeposit = (options: MakeDepositRequestDTO) =>
  createRequest<MakeDepositResponseDTO>(100, 'MakeDeposit', options);

export const getDepositWalletsList = (
  options: GetDepositWalletsListRequestDTO,
) =>
  createRequest<GetDepositWalletsListResponseDTO>(
    100,
    'GetDepositWalletsList',
    options,
  );

export const getDepositWalletInfo = (options: GetDepositWalletInfoRequestDTO) =>
  createRequest<GetDepositWalletInfoResponseDTO>(
    100,
    'GetDepositWalletInfo',
    options,
  );

export const makeVoucherDeposit = (options: MakeVoucherDepositRequestDTO) =>
  createRequest<MakeDepositResponseDTO>(100, 'MakeVoucherDeposit', options);

export const makeDepositPrepaidCard = (
  options: MakeDepositPrepaidCardRequestDTO,
) =>
  createRequest<MakeDepositPrepaidCardResponseDTO>(
    100,
    'MakeDepositPrepaidCard',
    options,
  );

export const getDepositStatus = (options: GetDepositStatusRequestDTO) =>
  createRequest<GetDepositStatusResponseDTO>(100, 'GetDepositStatus', options);

export const getDepositHistory = (options: GetDepositHistoryRequestDTO) =>
  createRequest<GetDepositHistoryResponseDTO>(
    100,
    'GetDepositHistory',
    options,
  );

export const getDepositLimitation = (options: GetDepositLimitationRequestDTO) =>
  createRequest<GetDepositLimitationResponseDTO>(
    100,
    'GetDepositLimitation',
    options,
  );

export const setDepositLimitation = (options: SetDepositLimitationRequestDTO) =>
  createRequest<SetDepositLimitationResponseDTO>(
    100,
    'SetDepositLimitation',
    options,
  );

export const findDeposits = (options: FindDepositsRequestDTO) =>
  createRequest<FindDepositsResponseDTO>(100, 'FindDeposits', options);
