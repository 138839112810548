import { createContext, VFC } from 'react';

import { PrismicChildrenProps } from './types';

const EmptyComponent = () => null;

export type PrismicChildrenContextValue = {
  component: VFC<PrismicChildrenProps>;
};

export const PrismicChildrenContext =
  createContext<PrismicChildrenContextValue>({ component: EmptyComponent });
