import * as t from 'io-ts';

import {
  EResponseStatus,
  EventDisplayType,
  ScoreBoardPeriodType,
  ScoreBoardSportType,
  ScoreBoardState,
  ScoreBoardTeam,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

/* Helpers */

const nullable = <C extends t.Mixed>(codec: C) =>
  t.union([codec, t.null, t.undefined]);

/* Object data */

export const metadaPerTeamCodec = t.type(
  {
    Home: t.number,
    Away: t.number,
  },
  'MetadaPerTeamCodec',
);

export const scoreBoardMetadataCodec = t.partial(
  {
    Name: nullable(t.string),
    PeriodNumber: nullable(t.number),
    TeamValue: nullable(metadaPerTeamCodec),
    Value: nullable(t.string),
  },
  'ScoreBoardMetadataCodec',
);

export const scoreBoardStatsCodec = t.partial(
  {
    ScorePerPeriod: nullable(t.array(nullable(scoreBoardMetadataCodec))),
    TotalScore: nullable(scoreBoardMetadataCodec),
  },
  'ScoreBoardStatsCodec',
);

export const scoreBoardInfoCodec = t.intersection(
  [
    t.type({
      State: createEnumType<ScoreBoardState>(ScoreBoardState),
    }),
    t.partial({
      HomeTeamName: nullable(t.string),
      AwayTeamName: nullable(t.string),
      PassTeam: nullable(createEnumType<ScoreBoardTeam>(ScoreBoardTeam)),
      CurrentGameTime: nullable(t.number),
      CurrentTime: nullable(t.number),
      RemainingTime: nullable(t.number),
    }),
  ],
  'ScoreBoardInfoCodec',
);

export const scoreBoardCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      EventDisplayType: createEnumType<EventDisplayType>(EventDisplayType),
      IsEventRemoved: t.boolean,
      PeriodType: createEnumType<ScoreBoardPeriodType>(ScoreBoardPeriodType),
      SportId: t.number,
      SportParser: createEnumType<ScoreBoardSportType>(ScoreBoardSportType),
    }),
    t.partial({
      Info: nullable(scoreBoardInfoCodec),
      SportInfo: nullable(t.array(nullable(scoreBoardMetadataCodec))),
      SportStats: nullable(t.array(nullable(scoreBoardMetadataCodec))),
      Stats: nullable(scoreBoardStatsCodec),
    }),
  ],
  'ScoreBoardCodec',
);

/* Response data */

export const getScoreBoardResponseCodec = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    ScoreBoard: scoreBoardCodec,
  },
  'GetScoreBoardResponseCodec',
);

export const getScoreBoardsResponseCodec = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    ScoreBoards: t.array(nullable(scoreBoardCodec)),
  },
  'GetScoreBoardsResponseCodec',
);

/* Normalized data */

export const getScoreBoardNormalizedCodec = t.record(
  t.string,
  scoreBoardCodec,
  'GetScoreBoardNormalizedCodec',
);

export const getScoreBoardsNormalizedCodec = t.type(
  { scoreBoards: t.record(t.string, scoreBoardCodec) },
  'GetScoreBoardsNormalizedCodec',
);

/* Update data */

export const getScoreBoardUpdatesCodec = t.type(
  {
    Updates: t.array(
      t.type({
        ScoreBoardUpdate: t.type({
          ScoreBoard: nullable(scoreBoardCodec),
        }),
      }),
    ),
  },
  'GetScoreBoardUpdatesCodec',
);
