import { GroupConditionType, RangeConditionType } from '@gaming1/g1-betting';
import {
  GroupConditionType as EGroupConditionType,
  RangeConditionType as ERangeConditionType,
} from '@gaming1/g1-requests-betting';

export enum ERangeFormatType {
  Odd = 0,
  Money = 1,
  Number = 2,
}
type ConditionOutput = {
  /** The translation key to use for the header */
  header: string;
  /** The description to return like "Champions League, Europa League" for a GroupCondition OR
   *  the translation key to use for the description for a RangeCondition
   */
  description?: string;
  /** Allows to determine how the Min / Max have to be formatted */
  type?: ERangeFormatType;
};

/**
 * Take a RangeCondition as {rangeGroup} and will check every possible types to get
 * the correct translation key as a header.
 *
 * If in the rangeGroup, we only have a "Min" property initialized, we will return a ConditionOutput
 * with a translation key that is corresponding to a only min property. (Example : element needs an odd of X min)
 *
 * If in the rangeGroup, we only have a "Max" property initialized, we return a translation key for the only max.
 * (Example : element needs an odd of max X)
 *
 * If in the rangeGroup we have a "Min" and a "Max", we return the "minMax" translation key.
 * (Example : element needs an odd between X and Y)
 * @param rangeGroup the rangeGroup needed to take the Type, Min and Max
 */
export const getRangeConditionKey = (
  rangeGroup: RangeConditionType,
): ConditionOutput | null => {
  const { Min, Max, Type } = rangeGroup;

  // ElementOdd condition
  if (Type === ERangeConditionType.ElementOdd) {
    const header = 'gifts.rangeCondition.elementOdd.header';
    if (Min && Max) {
      return {
        header,
        description: 'gifts.rangeCondition.elementOdd.minMax.description',
        type: ERangeFormatType.Odd,
      };
    }

    if (Min) {
      return {
        header,
        description: 'gifts.rangeCondition.elementOdd.onlyMin.description',
        type: ERangeFormatType.Odd,
      };
    }
    if (Max) {
      return {
        header,
        description: 'gifts.rangeCondition.elementOdd.onlyMax.description',
        type: ERangeFormatType.Odd,
      };
    }
  }

  // Stake condition
  if (Type === ERangeConditionType.Stake) {
    const header = 'gifts.rangeCondition.stake.header';
    if (Min && Max) {
      return {
        header,
        description: 'gifts.rangeCondition.stake.minMax.description',
        type: ERangeFormatType.Money,
      };
    }

    if (Min) {
      return {
        header,
        description: 'gifts.rangeCondition.stake.onlyMin.description',
        type: ERangeFormatType.Money,
      };
    }
    if (Max) {
      return {
        header,
        description: 'gifts.rangeCondition.stake.onlyMax.description',
        type: ERangeFormatType.Money,
      };
    }
  }

  // TicketOdd condition
  if (Type === ERangeConditionType.TicketOdd) {
    const header = 'gifts.rangeCondition.ticketOdd.header';
    if (Min && Max) {
      return {
        header,
        description: 'gifts.rangeCondition.ticketOdd.minMax.description',
        type: ERangeFormatType.Odd,
      };
    }
    if (Min) {
      return {
        header,
        description: 'gifts.rangeCondition.ticketOdd.onlyMin.description',
        type: ERangeFormatType.Odd,
      };
    }
    if (Max) {
      return {
        header,
        description: 'gifts.rangeCondition.ticketOdd.onlyMax.description',
        type: ERangeFormatType.Odd,
      };
    }
  }

  // ElementCount condition
  if (Type === ERangeConditionType.ElementCount) {
    const header = 'gifts.rangeCondition.elementCount.header';
    if (Min && Max) {
      return {
        header,
        description: 'gifts.rangeCondition.elementCount.minMax.description',
        type: ERangeFormatType.Number,
      };
    }

    if (Min) {
      return {
        header,
        description: 'gifts.rangeCondition.elementCount.onlyMin.description',
        type: ERangeFormatType.Number,
      };
    }
    if (Max) {
      return {
        header,
        description: 'gifts.rangeCondition.elementCount.onlyMax.description',
        type: ERangeFormatType.Number,
      };
    }
  }

  // BoostusMaxProfit : Should only have a "Max"
  if (Type === ERangeConditionType.BoostusMaxProfit) {
    const header = 'gifts.rangeCondition.boostusMaxProfit.header';
    return {
      header,
      description: 'gifts.rangeCondition.boostusMaxProfit.description',
      type: ERangeFormatType.Money,
    };
  }

  // FreebetNetWinnings condition
  if (Type === ERangeConditionType.FreebetNetWinnings) {
    const header = 'gifts.rangeCondition.freebetNetWinnings.header';
    if (Min && Max) {
      return {
        header,
        description:
          'gifts.rangeCondition.freebetNetWinnings.minMax.description',
        type: ERangeFormatType.Money,
      };
    }

    if (Min) {
      return {
        header,
        description:
          'gifts.rangeCondition.freebetNetWinnings.onlyMin.description',
        type: ERangeFormatType.Money,
      };
    }
    if (Max) {
      return {
        header,
        description:
          'gifts.rangeCondition.freebetNetWinnings.onlyMax.description',
        type: ERangeFormatType.Money,
      };
    }
  }

  return {
    header: 'gifts.rangeCondition.unknown.header',
    description: 'gifts.rangeCondition.unknown.description',
  };
};

/**
 * Take a GroupCondition as {group} and check its Type to know which translation key to return.
 * If the group has its Items property initialized,
 * we create a string wich each strings contained in the Items array.
 * (Example of return for the description : Champions League, Europa League)
 *
 * We need to check every Type possible.
 *
 * @param group the GroupCondition that we need to check every Type and to return the description-
 * -for the translation key.
 */
export const getGroupConditionKey = (
  group: GroupConditionType,
): ConditionOutput | null => {
  const { Type, Items } = group;

  const description = Items?.map((item) => item?.DisplayName)
    .join(', ')
    .toString();

  // League condition
  if (Type === EGroupConditionType.League) {
    return {
      header: 'gifts.groupCondition.league.header',
      description,
    };
  }
  // MarketType condition
  if (Type === EGroupConditionType.MarketType) {
    return {
      header: 'gifts.groupCondition.marketType.header',
      description,
    };
  }

  // BettingSlipType condition
  if (Type === EGroupConditionType.BettingSlipType) {
    return {
      header: 'gifts.groupCondition.bettingSlipType.header',
      description,
    };
  }

  // BettingSlipEventType condition
  if (Type === EGroupConditionType.BettingSlipEventType) {
    return {
      header: 'gifts.groupCondition.bettingSlipEventType.header',
      description,
    };
  }

  // Sport condition
  if (Type === EGroupConditionType.Sport) {
    return {
      header: 'gifts.groupCondition.sport.header',
      description,
    };
  }

  // Event condition
  if (Type === EGroupConditionType.Event) {
    return {
      header: 'gifts.groupCondition.event.header',
      description,
    };
  }

  // AmountInType condition
  if (Type === EGroupConditionType.AmountInType) {
    return {
      header: 'gifts.groupCondition.amountInType.header',
      description,
    };
  }

  return {
    header: 'gifts.groupCondition.unknown.header',
    description: description || '',
  };
};
