import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { coreActions, CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  generatePaginatedInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { LoyaltyActions } from '../types';

import * as actions from './actions';
import { ShopState } from './types';

export const initialState: ShopState = {
  purchaseProduct: null,
  categories: [],
  availableTickets: null,
  unavailableProducts: [],
  purchase: null,
  scratchcardIdentifier: null,
  requests: {
    getProducts: generatePaginatedInitialRequestState([]),
    getProductsById: generateInitialRequestState([]),
    getCategories: generateInitialRequestState(null),
    getAvailableTickets: generateInitialRequestState([]),
    getScratchcardIdentifier: generateInitialRequestState(null),
    getUnavailableProducts: generateInitialRequestState(null),
    purchaseProduct: generateInitialRequestState(null),
    purchase: generateInitialRequestState(null),
  },
};

export const shopReducer = (
  state: ShopState = initialState,
  action: LoyaltyActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getShopProducts.request):
        produceLoadingState(draftState.requests.getProducts);
        break;

      case getType(actions.getShopProducts.success):
        produceSuccessState(
          draftState.requests.getProducts,
          action.payload.pagination.PageNumber > 0
            ? [...state.requests.getProducts.result, ...action.payload.result]
            : action.payload.result,
          action.payload.pagination,
        );
        break;

      case getType(actions.getShopProducts.failure):
        produceFailureState(draftState.requests.getProducts, action.payload);
        break;

      case getType(actions.getShopProductsById.request):
        produceLoadingState(draftState.requests.getProductsById);
        break;

      case getType(actions.getShopProductsById.success):
        produceSuccessState(
          draftState.requests.getProductsById,
          action.payload.result,
        );
        break;

      case getType(actions.getShopProductsById.failure):
        produceFailureState(
          draftState.requests.getProductsById,
          action.payload,
        );
        break;
      case getType(actions.getAvailableTickets.request):
        produceLoadingState(draftState.requests.getAvailableTickets);
        break;

      case getType(actions.getAvailableTickets.success):
        produceSuccessState(draftState.requests.getAvailableTickets);
        draftState.availableTickets = action.payload?.AvailableTickets || null;

        break;

      case getType(actions.getAvailableTickets.failure):
        produceFailureState(
          draftState.requests.getAvailableTickets,
          action.payload,
        );
        break;

      case getType(actions.purchaseShopProduct.request):
        produceLoadingState(draftState.requests.purchaseProduct);
        break;

      case getType(actions.purchaseShopProduct.success):
        produceSuccessState(draftState.requests.purchaseProduct);
        draftState.purchaseProduct = action.payload;
        break;

      case getType(actions.purchaseShopProduct.failure):
        produceFailureState(
          draftState.requests.purchaseProduct,
          action.payload,
        );
        break;

      case getType(actions.purchase.request):
        produceLoadingState(draftState.requests.purchase);
        break;

      case getType(actions.purchase.success):
        produceSuccessState(draftState.requests.purchase);
        draftState.purchase = action.payload;
        break;

      case getType(actions.purchase.failure):
        produceFailureState(draftState.requests.purchase, action.payload);
        break;

      case getType(actions.getShopCategories.request):
        produceLoadingState(draftState.requests.getCategories);
        break;

      case getType(actions.getShopCategories.success):
        produceSuccessState(draftState.requests.getCategories);
        draftState.categories = action.payload?.Categories || [];
        break;

      case getType(actions.getShopCategories.failure):
        produceFailureState(draftState.requests.getCategories, action.payload);
        break;

      case getType(coreActions.switchLocale.success):
        return initialState;

      case getType(actions.getUnavailableProducts.request):
        produceLoadingState(draftState.requests.getUnavailableProducts);
        break;

      case getType(actions.getUnavailableProducts.success):
        produceSuccessState(draftState.requests.getUnavailableProducts);
        draftState.unavailableProducts =
          action.payload?.UnavailableProducts || [];

        break;

      case getType(actions.getUnavailableProducts.failure):
        produceFailureState(
          draftState.requests.getUnavailableProducts,
          action.payload,
        );
        break;

      case getType(actions.getScratchcardIdentifier.request):
        produceLoadingState(draftState.requests.getScratchcardIdentifier);
        break;

      case getType(actions.getScratchcardIdentifier.success):
        produceSuccessState(draftState.requests.getScratchcardIdentifier);
        draftState.scratchcardIdentifier = action.payload;

        break;

      case getType(actions.getScratchcardIdentifier.failure):
        produceFailureState(
          draftState.requests.getScratchcardIdentifier,
          action.payload,
        );
        break;

      default: // Immer will automatically return the state
    }
  });
