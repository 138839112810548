import { createSelector } from 'reselect';

import { generateInitialRequestState, RequestState } from '@gaming1/g1-utils';

import { gamesEntitiesSelector } from '../../common/store/selectors';
import { ApplicationWithGamingState } from '../../store/types';

import { slugifySearchPayload } from './helpers';
import { SearchGamesRequest } from './types';

export const searchGamesRequestsSelector = (
  state: ApplicationWithGamingState,
) => state.gaming.search.requests.searchGames;

export const searchGamesRequestStateSelector = createSelector(
  searchGamesRequestsSelector,
  (requests) =>
    (request: SearchGamesRequest): RequestState =>
      requests[slugifySearchPayload(request)] || generateInitialRequestState(),
);

export const searchGamesSelector = createSelector(
  gamesEntitiesSelector,
  searchGamesRequestsSelector,
  (entities, requests) => (request: SearchGamesRequest) =>
    requests[slugifySearchPayload(request)]?.result.games.map(
      (id) => entities[id],
    ) || [],
);
