import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  fontSize,
  justifyContent,
  JustifyContentProps,
  space,
} from 'styled-system';

import {
  colors,
  fontSizes,
  fontWeight,
  media,
  spaces,
} from '@gaming1/g1-style';
import { AppAnchor, Box, SimpleButton, Text } from '@gaming1/g1-ui';

import { BOTTOM_NAV_HEIGHT, FOOTER_HEADER_HEIGHT } from '../../../styles';
import { Image } from '../Image';

const activeClassName = 'active';

export const FOOTER_LOGO_HEIGHT = 40;
export const FOOTER_HELP_CONTAINER_MARGIN_TOP = 32;
export const FOOTER_LEGAL_AGE_CONTAINER_MARGIN_TOP = 32;
export const FOOTER_WRAPPER_PADDING_TOP_BOTTOM = 32;

/* Background */

export type FooterBackgroundLevels = 0 | 1 | 2;

export type FooterBackgroundProps = {
  /** backgrounds with three different colors options  */
  backgroundLevel: FooterBackgroundLevels;
  /** To add a border top  */
  hasBorder?: boolean;
};

export const FooterBackground = styled.div<FooterBackgroundProps>`
  background-color: ${({ backgroundLevel, theme }) =>
    theme.colors.footerBackground[backgroundLevel]};

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-top: 2px solid ${colors('footerBorder')};
    `}
`;

/* Wrapper */

export const FooterWrapper = styled.div`
  padding: ${FOOTER_WRAPPER_PADDING_TOP_BOTTOM}px 0;

  display: flex;
  width: 100%;
  margin: 0 auto;

  flex-direction: column;

  ${media.md} {
    flex-direction: row;
  }
`;

/* Header */

export const FooterHeaderItemWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterLegalAgeContainer = styled(FooterHeaderItemWrapper)`
  order: 2;
  padding-left: 0;

  & > * {
    width: 100%;
    & + * {
      padding-left: ${spaces('lg')};
    }
  }

  ${media.md} {
    padding-left: ${spaces('lg')};
    flex-wrap: nowrap;
    order: 3;

    & > * {
      width: auto;
    }
  }

  ${media.xl} {
    padding-left: 0;
  }
`;

export const FooterHeaderContainer = styled(FooterWrapper)`
  align-items: center;
  justify-content: space-between;
  padding: ${spaces('xl')} 0;
  overflow: auto;
  flex-wrap: wrap;

  ${media.md} {
    padding: ${spaces('sm')} 0;
    height: auto;
  }

  ${media.lg} {
    justify-content: center;
  }

  ${media.xl} {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0;
    height: ${FOOTER_HEADER_HEIGHT}px;

    & > * {
      & + * {
        padding-left: ${spaces('lg')};
      }
    }
  }
`;

export const FooterLogoImg = styled(Image)`
  height: ${FOOTER_LOGO_HEIGHT}px;
`;

export const FooterLegalAge = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;

  svg {
    margin-right: ${spaces('xs')};
    path {
      fill: ${colors('footerText')};
    }
  }

  span {
    font-size: ${fontSizes('md')};
    white-space: nowrap;
  }
`;

/* Social networks */

export const FooterSocialNetworkContainer = styled.div`
  display: flex;
  margin-top: ${spaces('sm')};
  justify-content: center;

  ${media.md} {
    justify-content: flex-start;
  }
`;

export const FooterSocialIconWrapper = styled.div`
  margin: 0 ${spaces('xs')};
  svg {
    fill: ${colors('footerIconColor')};
  }
`;

/* Images */

export const FooterImagesContainer = styled(FooterWrapper)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 ${spaces('xs')} ${spaces('lg')};

  img {
    max-width: calc(33% - ${spaces('sm')});
    max-height: 50px;
    margin: ${spaces('lg')} ${spaces('xs')} 0;
  }

  ${media.md} {
    padding: 0 ${spaces('xxxl')} ${spaces('lg')};

    img {
      margin: ${spaces('lg')} ${spaces('md')} 0;
    }
  }
`;

/* Raw container */

export const FooterWrapContainer = styled(FooterWrapper)`
  flex-wrap: wrap;
  padding-bottom: ${spaces('xxxl')};
`;

/* Cols container */

export const FooterColsContainer = styled(FooterWrapper)`
  & > div {
    flex: 1 1 0;

    &:not(:first-of-type) {
      margin-top: ${spaces('xl')};
    }
  }

  ${media.md} {
    & > div {
      &:not(:first-of-type) {
        margin-top: 0;
      }
    }
  }
`;

/* Row container */

export type FooterRowContainerProps = {
  /** Change the justify-content when there are several contents  */
  multipleContents?: boolean;
  /** Text-align (left by default) */
  textAlign?: 'right' | 'center';
};

export const FooterRowContainer = styled(
  FooterWrapper,
)<FooterRowContainerProps>`
  display: flex;
  justify-content: ${({ multipleContents }) =>
    multipleContents ? 'space-between' : 'center'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  align-items: center;
`;

/* Boxes */

export type FooterBoxProps = {
  /** Content take all the place in medium  */
  wideInMedium?: boolean;
};

export const FooterBox = styled(Box)<FooterBoxProps>`
  margin-top: ${spaces('xxl')};
  align-items: center;

  ${media.lg} {
    margin-top: ${spaces('none')};
  }

  ${({ wideInMedium }) =>
    wideInMedium
      ? css`
          flex: 1 1 100%;

          ${media.lg} {
            margin-top: ${spaces('none')};
            align-items: flex-start;
            flex: 1 1 0;
          }
        `
      : css`
          flex: 1 1 0;

          ${media.md} {
            align-items: flex-start;
          }
        `};

  ${space}
`;

/* Title */

export type FooterTitleProps = {
  hasNoUppercase?: boolean;
};

export const FooterTitle = styled(Text)<FooterTitleProps>`
  margin-top: ${spaces('none')};
  padding: ${spaces('xs')};
  font-size: ${fontSizes('md')};
  ${fontWeight('bold')};
  text-align: center;
  color: ${colors('footerAltText')};

  ${({ hasNoUppercase }) =>
    !hasNoUppercase &&
    css`
      text-transform: uppercase;
    `};

  ${media.md} {
    text-align: left;
  }

  ${space}
  ${fontSize}
`;

/* Links */

type FooterLinkContainerProps = JustifyContentProps & {
  /** When links are displayed in row */
  row?: boolean;
};

export const FooterLinkContainer = styled.div<FooterLinkContainerProps>`
  align-items: center;

  ${({ row }) =>
    row &&
    css`
      display: flex;
      flex-direction: column;

      ${media.lg} {
        flex-direction: row;
      }
    `};
`;

export const FooterLinkGroup = styled.div<FooterLinkContainerProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  ${media.md} {
    text-align: ${({ row }) => (row ? 'center' : 'left')};
    align-items: ${({ row }) => (row ? 'center' : 'flex-start')};
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
    ${justifyContent}

    & > * {
      padding-left: ${spaces('xs')};
      padding-right: ${spaces('xs')};
    }
  }
`;

type FooterItemProps = {
  /** Index of the current links group */
  group?: number;
  /** Alternative styles of the links
   * title : no underline, bold, uppercase
   * text : no underline, bold, no uppercase, alternative footer text color
   */
  linkStyle?: 'title' | 'text';
};

const FooterLinkItemCSS = css<FooterItemProps>`
  font-size: ${fontSizes('lg')};
  color: ${colors('footerLink')};
  letter-spacing: 0.2px;
  height: 48px;
  text-decoration: underline;
  display: flex;
  align-items: center;

  svg {
    margin-right: ${spaces('xs')};
    path {
      fill: ${colors('footerLink')};
    }
  }

  ${({ linkStyle }) =>
    linkStyle === 'title' &&
    css`
      text-transform: uppercase;
      font-size: ${fontSizes('md')};
      ${fontWeight('bold')};
      color: ${colors('footerText')};
      text-decoration: none;

      svg {
        path {
          fill: ${colors('footerText')};
        }
      }
    `};

  ${({ linkStyle }) =>
    linkStyle === 'text' &&
    css`
      color: ${colors('footerAltText')};
      text-decoration: none;
      font-size: ${fontSizes('lg')};
      ${fontWeight('bold')};

      svg {
        path {
          fill: ${colors('footerAltText')};
        }
      }
    `};
`;

export const FooterLinkLink = styled(NavLink)
  .withConfig({
    shouldForwardProp: (prop) => !['linkStyle', 'groupIndex'].includes(prop),
  })
  .attrs({
    activeClassName,
  })<FooterItemProps>`
  ${FooterLinkItemCSS};

  &.${activeClassName}, &:focus,
  &:active,
  &:hover {
    color: ${colors('footerActiveLink')};
  }
`;

export const FooterLinkAnchor = styled(AppAnchor).withConfig({
  shouldForwardProp: (prop) => !['linkStyle', 'groupIndex'].includes(prop),
})<FooterItemProps>`
  ${FooterLinkItemCSS};
`;

export const FooterLinkButton = styled(SimpleButton)`
  ${FooterLinkItemCSS};
`;

export const FooterHelpButton = styled(SimpleButton)`
  ${FooterLinkItemCSS};

  color: ${colors('footerAltText')};
  text-decoration: none;
  margin: 0 auto;
  font-size: ${fontSizes('lg')};
  ${fontWeight('bold')};
  width: 100%;
  justify-content: center;

  svg {
    margin-right: ${spaces('xs')};
    path {
      fill: ${colors('footerAltText')};
    }
  }

  ${media.md} {
    margin: 0;
    width: auto;
    justify-content: center;
  }
`;

export const FooterHelpSimpleButton = styled(SimpleButton)`
  color: ${colors('footerAltText')};
`;

export const BulletSeparator = styled.span`
  width: 7px;
  height: 7px;
  background-color: ${colors('footerText')};
  border-radius: 50%;
  margin: ${spaces('xs')} calc(${spaces('md')} / 2);
`;

/* Image And Text */

export const FooterImageAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${spaces('xs')};
  justify-content: center;

  ${media.md} {
    justify-content: flex-start;
  }

  svg {
    path {
      fill: ${colors('footerAltText')};
    }
  }
`;

export const FooterImage = styled(Image)`
  margin-right: ${spaces('xs')};
`;

export const FooterText = styled.span`
  font-size: ${fontSizes('lg')};
  color: ${colors('footerAltText')};
`;

/* Scroll top button */
export const SCROLL_TOP_BUTTON_SIZE = 40;
export const SCROLL_TOP_BUTTON_SIZE_SMALL = 40;

type ScrollTopButtonContainerProps = {
  visible?: boolean;
};
export const ScrollTopButtonContainer = styled(
  SimpleButton,
)<ScrollTopButtonContainerProps>`
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 500ms;
  opacity: 0;
  position: fixed;
  border-radius: 50%;
  right: ${spaces('xs')};
  bottom: -${SCROLL_TOP_BUTTON_SIZE_SMALL}px;
  width: ${SCROLL_TOP_BUTTON_SIZE_SMALL}px;
  height: ${SCROLL_TOP_BUTTON_SIZE_SMALL}px;
  ${media.xl} {
    right: ${spaces('lg')};
    bottom: -${SCROLL_TOP_BUTTON_SIZE}px;
    width: ${SCROLL_TOP_BUTTON_SIZE}px;
    height: ${SCROLL_TOP_BUTTON_SIZE}px;
  }

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      bottom: ${({ theme }) => BOTTOM_NAV_HEIGHT + theme.space.xs}px;
      ${media.xl} {
        bottom: ${spaces('xs')};
      }
    `}
`;

/* Container */

export const FooterContainer = styled.footer`
  color: ${colors('footerText')};
  font-size: ${fontSizes('md')};
`;

/* Footer logo wrapper */

export const FooterLogoWrapper = styled(FooterHeaderItemWrapper)`
  width: 100%;
  padding-bottom: ${spaces('sm')};

  ${media.xl} {
    padding-bottom: 0;
    width: auto;
  }
`;

/* Footer Header Help */

export const FooterHelpContainer = styled(FooterHeaderItemWrapper)`
  align-items: center;
  order: 3;
  flex-wrap: wrap;

  ${media.md} {
    flex-wrap: nowrap;

    & > * {
      & + * {
        padding-left: ${spaces('sm')};
      }
    }
  }

  ${media.sm} {
    order: 2;
  }
`;
