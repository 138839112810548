import { darken } from 'polished';
import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';
import { AppLink, Box } from '@gaming1/g1-ui';

export const SearchCategoryResultWrapper = styled(Box)`
  padding-bottom: ${spaces('lg')};
`;

export const SeeAllLink = styled(AppLink)`
  color: ${colors('buttonSecondaryText')};

  &:hover {
    color: ${colors('buttonSecondaryText', { transform: darken(0.1) })};
  }
`;
