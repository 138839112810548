import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import {
  getHasAvailablePromotionsSelector,
  getHasBoostusSelector,
  getHasDailyOffersSelector,
  useDisplaySuperOdds,
} from '@gaming1/g1-betting';
import { MenuItem as MenuItemType, useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';

import { MenuItem } from '../../../common/components/MenuItem';
import { bettingRoutes } from '../../../routes';

import { MenuTitle } from './MenuTitle';
import { MenuIcon } from './styles';

type UnmissableMenuProps = {
  isCollapsed?: boolean;
  onClick: (id: number | string) => void;
  activeId: string | number;
};

const UnmissableMenuComponent: FC<UnmissableMenuProps> = ({
  isCollapsed = false,
  onClick,
  activeId,
}) => {
  const { t } = useTranslation('betting');

  const getBettingRoutePath = useRoutePath(bettingRoutes);

  const hasSuperOdds = useDisplaySuperOdds();
  const hasDailyOffers = useSelector(getHasDailyOffersSelector);
  const hasBoostus = useSelector(getHasBoostusSelector);
  const hasPromotions = useSelector(getHasAvailablePromotionsSelector);

  const items: (MenuItemType & { enabled: boolean })[] = [
    {
      label: t('unmissable.promotions'),
      path: getBettingRoutePath('promotions'),
      icon: 'BettingPromotion',
      testId: 'promotions-menu-link',
      enabled: !!hasPromotions,
    },
    {
      label: t('unmissable.superOdds'),
      path: getBettingRoutePath('superOdds'),
      icon: 'SuperOdds',
      testId: 'super-odds-menu-link',
      enabled: !!hasSuperOdds,
    },
    {
      label: t('unmissable.dailyOffers'),
      path: getBettingRoutePath('dailyOffers'),
      icon: 'DailyOffer',
      testId: 'daily-offers-menu-link',
      enabled: !!hasDailyOffers,
    },
    {
      label: t('unmissable.boostus'),
      path: getBettingRoutePath('boostus'),
      icon: 'Boostus',
      testId: 'boostus-menu-link',
      enabled: !!hasBoostus,
    },
  ];

  return items.some((i) => i.enabled) ? (
    <>
      <MenuTitle isCollapsed={isCollapsed}>{t('unmissable.title')}</MenuTitle>
      {items
        .filter(({ enabled }) => enabled)
        .map(({ icon, label, testId, ...props }) => {
          const menuItemContent = !isCollapsed ? (
            <>
              {icon ? (
                <MenuItem.Left isActive={activeId === label}>
                  {typeof icon === 'string' ? (
                    <MenuIcon type={icon} id={`icon-${label}`} />
                  ) : (
                    icon
                  )}
                </MenuItem.Left>
              ) : null}
              <MenuItem.Content>{label}</MenuItem.Content>
            </>
          ) : (
            <MenuItem.Content justifyContent="center" alignItems="center">
              {!!icon && typeof icon === 'string' ? (
                <MenuIcon type={icon} id={`icon-${label}`} />
              ) : null}
            </MenuItem.Content>
          );

          return 'onClick' in props ? (
            <MenuItem
              key={`${testId}${label}`}
              elevationLevel={2}
              mb="1px"
              onClick={() => onClick(label)}
            >
              <MenuItem.Button onClick={props.onClick}>
                {menuItemContent}
              </MenuItem.Button>
            </MenuItem>
          ) : (
            <MenuItem
              data-testid={testId}
              key={`${testId}${label}`}
              elevationLevel={2}
              mb="1px"
              onClick={() => onClick(label)}
            >
              <MenuItem.Link to={props.path}>{menuItemContent}</MenuItem.Link>
            </MenuItem>
          );
        })}
    </>
  ) : null;
};

export const UnmissableMenu = memo(UnmissableMenuComponent);
