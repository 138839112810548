import styled, { css } from 'styled-components';

import { colors, device, elevation, spaces, zIndex } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

type CollapsibleContainerProps = {
  isCollapsed?: boolean;
  isDesktop: boolean;
  hasTouchCapabilities: boolean;
};

export const CollapsibleContainer = styled(Box)<CollapsibleContainerProps>`
  flex-shrink: 0;
  position: absolute;
  z-index: ${zIndex(4)};
  background-color: ${colors('backgrounds', { index: 0 })};

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      width: ${spaces('xxxxl')};

      ::after {
        content: '';
        display: block;
        inset: 0;
        position: sticky;
        min-height: 100%;
      }
    `}
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      position: relative;
    `}
`;

export const CollapsibleContainerIcon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${spaces('xxs')};
  right: 0;
  width: 32px;
  height: 32px;
  border-radius: ${spaces('xs')} 0 0 ${spaces('xs')};
  ${elevation(1)};
  background-color: ${colors('backgrounds', {
    index: 2,
  })};
`;

type CollapsibleButtonProps = {
  isVisible: boolean;
};

export const CollapsibleButton = styled.div<CollapsibleButtonProps>`
  padding: ${spaces('sm')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    transition: all ease-out 0.15s;
  }
  ${device.canHover} {
    &:hover {
      color: ${colors('primary')};
      svg {
        path {
          fill: ${colors('primary')};
        }
      }
    }
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      justify-content: center;
      color: ${colors('primary')};
      svg {
        transform: rotate(90deg);
        path {
          fill: ${colors('primary')};
        }
      }
    `}
`;
