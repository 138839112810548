import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { actions as coreActions, userLoggedInSelector } from '@gaming1/g1-core';
import {
  changePlayerProfile,
  getAllCountriesForResidenceCountry,
  getStates,
  PlayerSettingsProperties,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  changePlayerProfileResponse,
  getCountriesResponseCodec,
  getStatesResponseCodec,
} from './codecs';
import {
  changePlayerProfileErrorMessages,
  countriesErrorMessages,
  statesErrorMessages,
} from './errorMessages';

/** Change the user profile */
export const changeUserProfileEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.changeUserProfile.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(changePlayerProfile(payload)).pipe(
        mapGuard(changePlayerProfileResponse),
        map(actions.changeUserProfile.success),
        catchError((err) =>
          of(
            actions.changeUserProfile.failure(
              createFailurePayload(err, changePlayerProfileErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** After a successful change user profile/change user settings, we get the user profile */
export const successChangeUserProfileOrChangeUserSettingsToGetUserProfileEpic: UserEpic =
  (action$) =>
    action$.pipe(
      filter(
        isActionOf([
          actions.changeUserProfile.success,
          actions.changeUserSettings.success,
        ]),
      ),
      map(() => coreActions.getUserProfile.request()),
    );

/** Update the user language after a locale switch */
export const changeUserLanguageOnLocaleSwitchOrGetUserProfileEpic: UserEpic = (
  actions$,
  state$,
) =>
  actions$.pipe(
    filter(
      isActionOf([
        coreActions.switchLocale.success,
        coreActions.getUserProfile.success,
      ]),
    ),
    withLatestFrom(state$),
    // The user is logged in AND the back-end locale differs from the front-end one
    filter(
      ([, state]) =>
        !!userLoggedInSelector(state) &&
        !!state.core.user.userProfile &&
        state.core.user.userProfile?.Language !==
          state.core.i18n.webSocketLocale,
    ),
    map(([, state]) =>
      actions.changeUserSettings.request({
        LanguageIsoCode: state.core.i18n.webSocketLocale,
        PropertiesToUpdate: [PlayerSettingsProperties.LanguageIsoCode],
      }),
    ),
  );

/** Get the residence countries from the backend */
export const getResidenceCountriesEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getResidenceCountries.request)),
    switchMap(() =>
      wsAdapter.request(getAllCountriesForResidenceCountry({})).pipe(
        mapGuard(getCountriesResponseCodec),
        map(actions.getResidenceCountries.success),
        catchError((err) =>
          of(
            actions.getResidenceCountries.failure(
              createFailurePayload(err, countriesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the states from the backend */
export const getStatesEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getStates.request)),
    switchMap(() =>
      wsAdapter.request(getStates({})).pipe(
        mapGuard(getStatesResponseCodec),
        map(actions.getStates.success),
        catchError((err) =>
          of(
            actions.getStates.failure(
              createFailurePayload(err, statesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
