import { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  coreActions,
  getGoogleTagManagerRequestStateSelector,
  gtmEventsSelector,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { useRequestState } from '@gaming1/g1-store';

import { isValidDepositGTMEventProps } from '../../helpers';
import { useTracking } from '../../hooks/useTracking';

/**
 * Sends GTM Events fetched from the backend to Datadog
 */
export const DatadogEventsManager: VFC = () => {
  const dispatch = useDispatch();
  const events = useSelector(gtmEventsSelector);
  const { isNotAsked } = useRequestState(
    getGoogleTagManagerRequestStateSelector,
  );
  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const { isDataDogEnabled, isGtmEnabled, pushDataDogAction } = useTracking();

  /** Fetch the events if logged in */
  useEffect(() => {
    if (isDataDogEnabled && isNotAsked && isUserLoggedIn) {
      dispatch(coreActions.getGTMEvents.request());
    }
  }, [dispatch, isDataDogEnabled, isNotAsked, isUserLoggedIn]);

  /** Send all the events in the store */
  useEffect(() => {
    if (isDataDogEnabled && events.length) {
      events.forEach((event) => {
        const { data } = event;

        if (isDataDogEnabled && isValidDepositGTMEventProps(data)) {
          pushDataDogAction('deposit', {
            amount: parseFloat(data.depositAmount.toString()),
            currency: data.transactionCurrency || null,
            transactionId: data.transactionId || null,
            bonusAmount: data.bonusAmount ? parseFloat(data.bonusAmount) : null,
            transactionTotal: data.transactionTotal
              ? parseFloat(data.transactionTotal)
              : null,
          });
        }
        if (!isGtmEnabled) {
          dispatch(coreActions.markGTMEventsAsSent.request([event.id]));
        }
      });
    }
  }, [dispatch, events, isDataDogEnabled, isGtmEnabled, pushDataDogAction]);

  return null;
};
