import React, { useContext, VFC } from 'react';

import { cmsPublicRoutes } from '@gaming1/g1-cms-api';
import { ConfigContext } from '@gaming1/g1-config';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import {
  Box,
  LayoutContext,
  RenderOnMedia,
  SimpleButton,
  Text,
} from '@gaming1/g1-ui';

import { ZendeskWidgetContext } from '../../../help/components/ZendeskWidgetProvider/ZendeskWidgetContext';
import { useIsFullscreenLayout, useRoutePath } from '../../../routing/hooks';

import {
  FullScreenFooterContainer,
  FullScreenFooterWrapper,
  ItemAnchor,
  ItemLink,
  ItemSeparator,
  LegalAgeItem,
  LegalAgeItemIcon,
  LegalItems,
} from './styles';

/** Smaller Footer component intended to be shown inside the Fullscreen layout */
export const FullScreenFooter: VFC = () => {
  const isFullscreenLayout = useIsFullscreenLayout();
  const { t } = useTranslation('core');
  const config = useContext(ConfigContext);
  const { showWidget } = useContext(ZendeskWidgetContext);
  const { hideDrawer } = useContext(LayoutContext);
  const getCmsRoutePath = useRoutePath(cmsPublicRoutes);

  const isBelgian = config.user.defaultCountry === 'be';

  /** Show the zendesk help widget and ensure the drawers are closed to avoid
   * a conflict between their z-indexes
   */
  const showHelpWidget = () => {
    hideDrawer();
    showWidget();
  };

  return isFullscreenLayout ? (
    <FullScreenFooterContainer data-testid="fullscreen-footer">
      <FullScreenFooterWrapper flexDirection="row">
        <RenderOnMedia min="md">
          <SimpleButton onClick={showHelpWidget}>
            {t('fullscreenFooter.needHelp')}
          </SimpleButton>
          <LegalItems>
            {config.betting.enabled && (
              <LegalAgeItem>
                <LegalAgeItemIcon id="footer-betting-age" type="Circle18Plus" />
                <Text mt="none">{t('fullscreenFooter.sports')}</Text>
              </LegalAgeItem>
            )}
            {config.gaming.enabled && (
              <LegalAgeItem>
                <LegalAgeItemIcon id="footer-casino-age" type="Circle21Plus" />
                <Text mt="none">{t('fullscreenFooter.games')}</Text>
              </LegalAgeItem>
            )}
            {isBelgian && (
              <ItemAnchor
                href="https://playsafe.be"
                target="_blank"
                rel="noopener"
                data-testid="footer-playsafe-anchor"
              >
                {t('fullscreenFooter.playsafe')}
              </ItemAnchor>
            )}
            <Trans
              i18nKey="fullscreenFooter.responsibleGaming"
              ns="core"
              components={{
                ItemLinkAboutUsResponsibleGaming: (
                  <ItemLink
                    to={`${getCmsRoutePath('aboutUs', {
                      content: 'responsible-gaming',
                    })}`}
                  />
                ),
                ItemLinkResponsibleGaming: (
                  <ItemLink to={getCmsRoutePath('responsibleGaming')} />
                ),
              }}
            />
          </LegalItems>
        </RenderOnMedia>
        <RenderOnMedia max="sm">
          <Box flexDirection="row">
            {config.betting.enabled && (
              <LegalAgeItem>
                <LegalAgeItemIcon id="footer-betting-age" type="Circle18Plus" />
              </LegalAgeItem>
            )}
            {config.gaming.enabled && (
              <LegalAgeItem>
                <LegalAgeItemIcon id="footer-casino-age" type="Circle21Plus" />
              </LegalAgeItem>
            )}
          </Box>
          <Box flexDirection="row">
            <SimpleButton onClick={showHelpWidget}>
              {t('fullscreenFooter.help')}
            </SimpleButton>
            <ItemSeparator />
            <ItemLink to={getCmsRoutePath('responsibleGaming')}>
              {t('fullscreenFooter.playResponsibly')}
            </ItemLink>
            {isBelgian && (
              <>
                <ItemSeparator />
                <ItemAnchor
                  href="https://www.playsafe.be"
                  target="_blank"
                  rel="noopener"
                  data-testid="footer-playsafe-anchor"
                >
                  {t('fullscreenFooter.playsafe')}
                </ItemAnchor>
              </>
            )}
          </Box>
        </RenderOnMedia>
      </FullScreenFooterWrapper>
    </FullScreenFooterContainer>
  ) : null;
};
