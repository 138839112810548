import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateFailureRequestState,
  generateInitialRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  slugifyPayload,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { LimitsState } from './types';

export const initialState: LimitsState = {
  requests: {
    getLimits: {},
    getRemainingConnectionTime: generateInitialRequestState(null),
    getRemainingTimeToSpend: {},
    setLimits: {},
  },
  limits: {},
  remainingConnectionTime: null,
  remainingTimeToSpend: {},
  setLimits: {},
};

export const limitsReducer = (
  state: LimitsState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /**
       * Set limits
       */
      case getType(actions.setLimits.request): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.setLimits[slug] = generateLoadingRequestState();
        break;
      }

      case getType(actions.setLimits.failure): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.setLimits[slug] = generateFailureRequestState(
          action.payload.data,
        );
        break;
      }

      case getType(actions.setLimits.success): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.setLimits[slug] = generateSuccessRequestState();
        draftState.setLimits[slug] = action.payload.data;
        break;
      }

      /**
       * Get Limits
       */
      case getType(actions.getLimits.request): {
        const slug = slugifyPayload(action.payload);
        draftState.requests.getLimits[slug] = generateLoadingRequestState();
        break;
      }

      case getType(actions.getLimits.failure): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.getLimits[slug] = generateFailureRequestState(
          action.payload.data,
        );
        break;
      }

      case getType(actions.getLimits.success): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.getLimits[slug] = generateSuccessRequestState();
        draftState.limits[slug] = action.payload.data;
        break;
      }

      /**
       * Get remaining connection time
       */
      case getType(actions.getRemainingConnectionTime.request): {
        produceLoadingState(draftState.requests.getRemainingConnectionTime);
        break;
      }

      case getType(actions.getRemainingConnectionTime.failure): {
        produceFailureState(
          draftState.requests.getRemainingConnectionTime,
          action.payload,
        );
        break;
      }

      case getType(actions.getRemainingConnectionTime.success): {
        produceSuccessState(draftState.requests.getRemainingConnectionTime);
        draftState.remainingConnectionTime = action.payload;
        break;
      }

      /**
       * Get remaining time to spend
       */
      case getType(actions.getRemainingTimeToSpend.request): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.getRemainingTimeToSpend[slug] =
          generateLoadingRequestState();
        break;
      }

      case getType(actions.getRemainingTimeToSpend.failure): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.getRemainingTimeToSpend[slug] =
          generateFailureRequestState(action.payload.data);
        break;
      }

      case getType(actions.getRemainingTimeToSpend.success): {
        const slug = slugifyPayload(action.payload.params);
        draftState.requests.getRemainingTimeToSpend[slug] =
          generateSuccessRequestState();
        draftState.remainingTimeToSpend[slug] = action.payload.data;
        break;
      }

      default: // Immer will automatically return the state
    }
  });
