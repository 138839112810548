import format from 'date-fns/format';
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
  VFC,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { useShouldRenderRequestError } from '@gaming1/g1-core';
import { RequestStateErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  getSponsorshipHistoryRequestStateSelector,
  loyaltyActions,
  Sponsorship,
  sponsorshipHistorySelector,
} from '@gaming1/g1-loyalty';
import { useRequestState } from '@gaming1/g1-store';
import {
  Button,
  Icon,
  Loader,
  Message,
  RenderOnMedia,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';
import { HISTORY_PAGE_SIZE } from '@gaming1/g1-user-api';

import { sponsorshipHistoryRequestStateKeys } from '../helpers';
import { SponsorshipHistoryInformationModal } from '../SponsorshipHistoryInformationModal';

const isValidStatus = (
  num: number,
): num is keyof typeof sponsorshipHistoryRequestStateKeys =>
  num in sponsorshipHistoryRequestStateKeys;

export const SponsorshipHistory: VFC = () => {
  const { t } = useTranslation('loyalty');
  const { colors } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { i18n } = useContext(ConfigContext);

  const sponsorshipHistory = useSelector(sponsorshipHistorySelector);
  const { isFirstLoading, isLoading } = useRequestState(
    getSponsorshipHistoryRequestStateSelector,
  );
  const { pagination: sponsorshipHistoryPagination } = useSelector(
    getSponsorshipHistoryRequestStateSelector,
  );

  const [selectedSponsorship, setSelectedSponsorship] = useState<Sponsorship>();
  const [isModalVisible, setModalVisible] = useState(false);

  const getSponsorshipHistory = useCallback(() => {
    dispatch(
      loyaltyActions.getSponsorshipHistory.request({
        PageNumber: 0,
        PageSize: HISTORY_PAGE_SIZE,
        IncludeTotal: true,
      }),
    );
  }, [dispatch]);

  useLayoutEffect(() => {
    getSponsorshipHistory();
  }, [getSponsorshipHistory]);

  const shouldRenderRequestError = useShouldRenderRequestError(
    getSponsorshipHistoryRequestStateSelector,
  );

  if (shouldRenderRequestError) {
    return (
      <RequestStateErrorMessage
        onRetryButtonClick={getSponsorshipHistory}
        selector={getSponsorshipHistoryRequestStateSelector}
      />
    );
  }

  if (isFirstLoading) {
    return <Loader mt="sm" />;
  }

  return sponsorshipHistory.length > 0 ? (
    <>
      <Table testId="sponsorship-history-container">
        <thead>
          <tr>
            <th>{t('history.sponsorship.date')}</th>
            <th>{t('history.sponsorship.sponsored')}</th>

            <RenderOnMedia min="lg">
              <th>{t('history.sponsorship.status')}</th>
              <th>{t('history.sponsorship.deposit')}</th>
              <th>{t('history.sponsorship.progression')}</th>
            </RenderOnMedia>

            <RenderOnMedia max="md">
              <th>&nbsp;</th>
            </RenderOnMedia>
          </tr>
        </thead>

        <tbody>
          {sponsorshipHistory.map((history) => (
            <tr key={history.CreatedDate} data-testid="sponsorship-history-row">
              <td data-testid="sponsorship-history-date-col">
                {format(
                  new Date(history.CreatedDate),
                  i18n.dateTimePartialFormat,
                )}
              </td>
              <td data-testid="sponsorship-history-sponsored-col">
                {history.Godson}
              </td>

              <RenderOnMedia min="lg">
                <td data-testid="sponsorship-history-status-col">
                  {isValidStatus(history.Status) &&
                    t(sponsorshipHistoryRequestStateKeys[history.Status])}
                </td>
                <td data-testid="sponsorship-history-deposit-col">
                  {history.WasDeposited ? (
                    <Icon
                      id="history-completed"
                      type="CircleCheck"
                      fill={colors.success}
                    />
                  ) : (
                    <Icon
                      id="history-notCompleted"
                      type="CircleStop"
                      fill={colors.danger}
                    />
                  )}
                </td>
                <td data-testid="sponsorship-history-progression-col">
                  {history.GodsonBonusProgression}%
                </td>
              </RenderOnMedia>
              <RenderOnMedia max="md">
                <td data-testid="sponsorship-history-details-col">
                  <SimpleButton
                    data-testid="sponsorship-history-details-button"
                    onClick={() => {
                      setSelectedSponsorship(history);
                      setModalVisible(true);
                    }}
                  >
                    <Icon
                      id="history-info"
                      type="VerticalTripleDots"
                      height="20px"
                    />
                  </SimpleButton>
                </td>
              </RenderOnMedia>
            </tr>
          ))}
        </tbody>
      </Table>

      {sponsorshipHistory.length < sponsorshipHistoryPagination.Total && (
        <Button
          block
          loading={isLoading}
          noBorder
          onClick={() => {
            dispatch(
              loyaltyActions.getSponsorshipHistory.request({
                PageNumber: sponsorshipHistoryPagination.PageNumber + 1,
                PageSize: sponsorshipHistoryPagination.PageSize,
                IncludeTotal: true,
              }),
            );
          }}
          data-testid="sponsorship-history-more-button"
          type="secondary"
        >
          {t('history.seeMore')}
        </Button>
      )}
      {selectedSponsorship && (
        <SponsorshipHistoryInformationModal
          history={selectedSponsorship}
          onClose={() => setModalVisible(false)}
          visible={isModalVisible}
        />
      )}
    </>
  ) : (
    <Message mt="sm" type="warning">
      {t('history.noResults')}
    </Message>
  );
};
