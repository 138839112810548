/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:33:19 14-07-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetPlayerMonthlyBoxInfoRequestDTO,
  GetPlayerMonthlyBoxInfoResponseDTO,
} from '../entities';

export const getPlayerMonthlyBoxInfo = (
  options: GetPlayerMonthlyBoxInfoRequestDTO,
) =>
  createRequest<GetPlayerMonthlyBoxInfoResponseDTO>(
    104,
    'GetPlayerMonthlyBoxInfo',
    options,
  );
