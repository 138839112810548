import { createContext } from 'react';

import { License } from '@gaming1/g1-config';
import { MenuList } from '@gaming1/g1-core-web';

export type GameCategoriesMenuContextType = {
  /** Menu items that will be added at the start of the menu (before categories) */
  firstItems?: MenuList | { [k in License]: MenuList };
  /** Menu items that will be added at the end of the menu (after categories) */
  lastItems?: MenuList | { [k in License]: MenuList };
};

/**
 * A context to overwrite some default behaviours of the game categories menu
 */
export const GameCategoriesMenuContext =
  createContext<GameCategoriesMenuContextType>({});
