import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import {
  BettingSlipIdentifierType,
  EBettingSlipIdentifier,
  freebetSelectedForBSGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { extractBettingSlipTypeFromId } from '../../../../helpers';
import { useIsMobileTicket } from '../../../hooks/render';

/**
 * This hook manage all the action and the behavior about to show suggestion and keyboard
 */
export const useNumpadActionManage = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showKeyBoard, setShowKeyBoard] = useState(false);

  const mobileTicket = useIsMobileTicket();

  const selectedFreebet = useBettingGetterSelector({
    getterSelector: freebetSelectedForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const showNumpad = selectedFreebet ? false : showSuggestion || showKeyBoard;

  const bettingSlipIdentifier = extractBettingSlipTypeFromId(bettingSlipId);

  const isBettingSlipAFastTicket =
    bettingSlipIdentifier === EBettingSlipIdentifier.Fast;

  /**
   * Specifies if the custom keyboard can be displayed with the current
   * configuration (breakpoint...).
   */
  const hasKeyBoardCapabilities = mobileTicket;

  /**
   * Input focus link to numpad changed (see if we need to open numpad)
   * @param focus
   */
  const focusChanged = (focus: boolean) => {
    if (mobileTicket && focus) {
      if (!isBettingSlipAFastTicket) {
        setShowSuggestion(true);
      }
      setShowKeyBoard(true);
    }
  };

  /**
   * close the numpad
   */
  const closeKeyboard = () => {
    setShowSuggestion(false);
    setShowKeyBoard(false);
  };

  /**
   * always open for suggestion in desktop except when we're in a fast ticket
   */
  useEffect(() => {
    if (isBettingSlipAFastTicket) {
      setShowSuggestion(false);
      setShowKeyBoard(false);
    } else {
      setShowSuggestion(false);
      if (!mobileTicket) {
        setShowKeyBoard(false);
        setShowSuggestion(true);
      }
    }
  }, [isBettingSlipAFastTicket, mobileTicket]);

  return {
    hasKeyBoardCapabilities,
    showSuggestion,
    showKeyBoard,
    showNumpad,
    focusChanged,
    closeKeyboard,
  };
};
