/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  LivesRequest,
  LivesResponse,
  ScoreBoardRequest,
  ScoreBoardResponse,
  ScoreBoardsRequest,
  ScoreBoardsResponse,
} from '../entities';

export const getLives = (options: LivesRequest) =>
  createRequest<LivesResponse>(201, 'GetLives', options);

export const getScoreBoard = (options: ScoreBoardRequest) =>
  createRequest<ScoreBoardResponse>(201, 'GetScoreBoard', options);

export const getScoreBoards = (options: ScoreBoardsRequest) =>
  createRequest<ScoreBoardsResponse>(201, 'GetScoreBoards', options);
