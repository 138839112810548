import {
  EChagePlayerSettingsStatus,
  EGetDepositLimitationResponseStatus,
  EGetLimitsResponseStatus,
  EGetPlayerSettingsStatus,
  ESetLimitsResponseStatus,
  ResponseStatus,
} from '@gaming1/g1-requests';

export const changePlayerSettingsErrorMessages = {
  [EChagePlayerSettingsStatus.InternalError]: 'core:error.internalServer', // 0
  [EChagePlayerSettingsStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EChagePlayerSettingsStatus.FieldError]: 'user:account.message.formInvalid', // 3
};

export const getPlayerSettingsErrorMessages = {
  [EGetPlayerSettingsStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetPlayerSettingsStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};

export const getLegalDepositLimitErrorMessages = {
  [ResponseStatus.Unknown]: 'core:error.internalServer', // 0
  [ResponseStatus.InternalError]: 'core:error.internalServer', // 2
  [ResponseStatus.MissingField]: 'core:error.internalServer', // 3
};

export const setLegalDepositLimitErrorMessages = {
  [ResponseStatus.Unknown]: 'core:error.internalServer', // 0
  [ResponseStatus.InternalError]: 'core:error.internalServer', // 2
  [ResponseStatus.MissingField]: 'core:error.internalServer', // 3
};

export const setLimitsErrorMessages = {
  [ESetLimitsResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [ESetLimitsResponseStatus.AlreadyLower]:
    'user:account.message.limitAlreadyLower', // 2
  [ESetLimitsResponseStatus.Unchanged]: 'user:account.message.limitUnchanged', // 3
  [ESetLimitsResponseStatus.LimitTooLow]: 'user:account.message.limitTooLow', // 4
  [ESetLimitsResponseStatus.LowerLimitationPending]:
    'user:account.message.lowerLimitationPending', // 5
  [ESetLimitsResponseStatus.RaiseNotAllowed]:
    'user:account.message.raiseLimitNotAllowed', // 6
  [ESetLimitsResponseStatus.LowerNotAllowed]:
    'user:account.message.lowerLimitNotAllowed', // 7
  [ESetLimitsResponseStatus.HigherLimitationPending]:
    'user:account.message.higherLimitationPending', // 8
  [ESetLimitsResponseStatus.LimitTooHigh]: 'user:account.message.limitTooHigh', // 9
  [ESetLimitsResponseStatus.InvalidRoom]: 'core:error.invalidRoom', // 10
};

export const getDepositLimitsErrorMessages = {
  [EGetDepositLimitationResponseStatus.InternalError]:
    'core:error.internalServer', // 0
};

export const getLimitsErrorMessages = {
  [EGetLimitsResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetLimitsResponseStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
};
