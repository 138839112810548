import React, { FC, useMemo, useState } from 'react';

import { CustomThemesContext } from './CustomThemesContext';
import { getSavedThemes } from './helpers';

export const CustomThemesProvider: FC = ({ children }) => {
  const [customThemes, setCustomThemes] = useState(() => getSavedThemes());
  const CustomThemesValue = useMemo(
    () => ({
      refreshThemes: () => setCustomThemes(getSavedThemes()),
      themes: customThemes,
    }),
    [customThemes],
  );
  return (
    <CustomThemesContext.Provider value={CustomThemesValue}>
      {children}
    </CustomThemesContext.Provider>
  );
};
