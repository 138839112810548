import React, { useContext, useEffect, useState, VFC } from 'react';
import useIsInViewport from 'use-is-in-viewport';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, LayoutContext } from '@gaming1/g1-ui';

import { ZendeskWidgetContext } from '../../../help/components/ZendeskWidgetProvider/ZendeskWidgetContext';

import { FooterHelpButton, FooterHelpSimpleButton } from './styles';

export const HELP_BUTTON_ICON_SIZE_IN_PX = 24;

export const TIMEOUT_BEFORE_ALLOWING_THE_AUTO_LOAD_IN_MS = 5000;

export type HelpButtonProps = {
  /** Specifies if the button is displayed a link group */
  hasLinkStyle?: boolean;
  /** Specifies if the button has a icon */
  hasIcon?: boolean;
};

/** Button that will trigger the display of the Zendesk help widget */
export const HelpButton: VFC<HelpButtonProps> = ({ hasIcon, hasLinkStyle }) => {
  const { t } = useTranslation('core');

  const { showWidget, isWidgetLoaded, loadWidget } =
    useContext(ZendeskWidgetContext);

  const { hideDrawer } = useContext(LayoutContext);

  const [isInViewport, childElToWatch] = useIsInViewport();

  const [hasBeenLoadedForSomeTime, setHasBeenLoadedForSomeTime] =
    useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasBeenLoadedForSomeTime(true);
    }, TIMEOUT_BEFORE_ALLOWING_THE_AUTO_LOAD_IN_MS);
    return () => clearTimeout(timeout);
  }, []);

  /** Show the zendesk help widget and ensure the drawers are closed to avoid
   * a conflict between their z-indexes
   */
  const showHelpWidget = () => {
    hideDrawer();
    showWidget();
  };

  /**
   * Loads automatically the widget when the user has scrolled enough to view
   * the footer AND the app has been mounted for at least
   * {TIMEOUT_BEFORE_ALLOWING_THE_AUTO_LOAD_IN_MS} ms
   * This is meant to ensure that the widget isn't too slow to show up when the
   * user clicks the "need help button" since the Zendesk framework is quite
   * heavy (> 300kb) and was only loaded at the first click before
   */
  useEffect(() => {
    if (isInViewport && !isWidgetLoaded && hasBeenLoadedForSomeTime) {
      loadWidget();
    }
  }, [hasBeenLoadedForSomeTime, isInViewport, isWidgetLoaded, loadWidget]);

  return hasLinkStyle ? (
    <FooterHelpButton
      data-testid="footer-help-button"
      onClick={showHelpWidget}
      ref={childElToWatch}
    >
      {hasIcon && (
        <Icon
          id="footer-help"
          type="MessageCopy"
          height={`${HELP_BUTTON_ICON_SIZE_IN_PX}px`}
          width={`${HELP_BUTTON_ICON_SIZE_IN_PX}px`}
        />
      )}
      {t('button.helpCenter')}
    </FooterHelpButton>
  ) : (
    <FooterHelpSimpleButton
      data-testid="footer-help-button"
      onClick={showHelpWidget}
      ref={childElToWatch}
    >
      {hasIcon && <Icon type="MessageCopy" id="footer-help" mr="xs" />}
      {t('button.needHelp')}
    </FooterHelpSimpleButton>
  );
};
