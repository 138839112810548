import styled from 'styled-components';

import { fontSizes, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const BettingSlipFooterLineContainer = styled(Box)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${spaces('xs')};
  font-size: ${fontSizes('md')};
`;
