import React, { VFC } from 'react';

import { Box } from '@gaming1/g1-ui';
import { latinizeString } from '@gaming1/g1-utils';

import { ResultName } from './styles';

type HighlightedTextProps = {
  /** The part of the title that will be highlighted passed as an array in case of multiple terms */
  searchTerms: string[];
  /** The text of the result title */
  text: string;
  /** Specifies if the text will be cropped */
  ellipsis?: boolean;
};

/** Render a text with an highlighted part if the search term does match with the title */
export const HighlightedText: VFC<HighlightedTextProps> = ({
  searchTerms,
  text,
  ellipsis = false,
}) => {
  const getHighlightedTextNode = (
    searchTokens: string[],
    term: string,
    AggregatedTextNode: React.ReactNode = null,
  ): React.ReactNode => {
    const latinizedString = latinizeString(term);
    const regex = new RegExp(`${(searchTokens || []).join('|')}`, 'gi');
    const length = latinizedString.match(regex)?.[0].length || 0;
    const index = latinizedString.search(regex);

    if (index === -1 || !term || !searchTokens?.length) {
      return (
        <>
          {AggregatedTextNode}
          {term}
        </>
      );
    }

    const beginning = <>{term.slice(0, index)}</>;
    const matchingTerm = term.slice(index, index + length);
    const taggedTerm = <b data-testid="highlighted-text">{matchingTerm}</b>;
    const end = term.slice(index + length);

    const AggregatedString = (
      <>
        {AggregatedTextNode || null}
        {beginning}
        {taggedTerm}
      </>
    );

    if (end === term) {
      return AggregatedTextNode;
    }

    return getHighlightedTextNode(searchTokens, end, AggregatedString);
  };

  const HighlightedTextNode = getHighlightedTextNode(searchTerms, text);

  return (
    <Box alignItems="center" flexDirection="row">
      <ResultName ellipsis={ellipsis} mt={0}>
        {searchTerms.join(' ').length > 1 ? HighlightedTextNode : text}
      </ResultName>
    </Box>
  );
};
