/* eslint-disable no-param-reassign */

import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as searchActions from '../../search/actions';
import * as shopActions from '../../shop/actions';
import { LoyaltyActions } from '../../types';
import { CommonEntitiesState } from '../types';

export const initialState: CommonEntitiesState = {
  products: {},
};

export const commonEntitiesReducer = (
  state: CommonEntitiesState = initialState,
  action: LoyaltyActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(searchActions.searchShopProducts.success):
      case getType(shopActions.getShopProducts.success):
      case getType(shopActions.getShopProductsById.success):
        draftState.products = {
          ...draftState.products,
          ...action.payload.entities.products,
        };
        break;

      default: // immer will automatically return the state
    }
  });
