import React, { VFC } from 'react';
import { useTheme } from 'styled-components';

import { BettingHistoryTicketType } from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { CreditType } from '@gaming1/g1-requests-betting';
import { Box, Icon } from '@gaming1/g1-ui';

import {
  EXTRA_ICON_HEIGHT_IN_PX,
  EXTRA_ICON_WIDTH_IN_PX,
  ExtraText,
  HistoryExpandedGiftContainer,
} from '../styles';

type ExpandedExtraInfoFreebetProps = {
  history: BettingHistoryTicketType;
};

export const ExpandedExtraInfoFreebetV2: VFC<ExpandedExtraInfoFreebetProps> = ({
  history,
}) => {
  const { t } = useTranslation('betting');
  const formatMoney = useFormatMoney();
  const { colors } = useTheme();

  return history.CreditType === CreditType.Freebet ? (
    <HistoryExpandedGiftContainer>
      <Icon
        id="FreebetV2"
        type="FreebetV2"
        color={colors.bettingFreebet}
        height={EXTRA_ICON_HEIGHT_IN_PX}
        width={EXTRA_ICON_WIDTH_IN_PX}
      />
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ExtraText color={colors.bettingFreebet}>
          {t('bettingslip.offersSelected.freebet', {
            amount: formatMoney(history.Stake),
          })}
        </ExtraText>
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
