import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { NotificationCode } from '@gaming1/g1-requests-betting';

import { ApplicationWithBettingState } from '../store/types';

import { orderPromotionsByDisplayPriorityOrEndDate } from './helpers';

const getPromotionsStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.promotions;

/**
 * hasAvailablePromotions selectors
 */

export const getHasAvailablePromotionsSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) => getPromotionsState.result.hasAvailablePromotions,
);

export const getHasEventAvailablePromotionsSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) => (eventId: number) =>
    getPromotionsState.result.relatedIds.RelatedEventIds?.includes(eventId),
);

export const getHasLeagueAvailablePromotionsSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) => (leagueId: number) =>
    getPromotionsState.result.relatedIds.RelatedLeagueIds?.includes(leagueId),
);

export const getHasSportAvailablePromotionsSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) => (sportId: number) =>
    getPromotionsState.result.relatedIds.RelatedSportIds?.includes(sportId),
);

/**
 * getAvailablePromotions selectors
 */

export const getAvailablePromotionsRequestStateSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) => getPromotionsState.requests.getAvailablePromotions,
);

export const getAvailablePromotionsRequestStatusSelector = createSelector(
  getAvailablePromotionsRequestStateSelector,
  (getAvailablePromotionsRequestState) =>
    getAvailablePromotionsRequestState.status,
);

export const getAvailablePromotionsResultSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) => getPromotionsState.result.availablePromotions,
);

export const isGetAvailablePromotionsNotificationASuccess = createSelector(
  getAvailablePromotionsResultSelector,
  (getPromotionsResult) =>
    getPromotionsResult.Notification.Code === NotificationCode.Promo_Success,
);

export const getAvailablePromotionsListSelector = createSelector(
  getPromotionsStateSelector,
  (getPromotionsState) =>
    memoize((publicPromotionsOnly = false) => {
      const promotionsList = publicPromotionsOnly
        ? getPromotionsState.result.availablePromotions.Promotions?.filter(
            (promo) => promo.DisplayOnGeneralPage,
          )
        : getPromotionsState.result.availablePromotions.Promotions;
      return orderPromotionsByDisplayPriorityOrEndDate(promotionsList || []);
    }),
);

export const getPromotionsCorrespondingToIdsGettorSelector = createSelector(
  getAvailablePromotionsListSelector,
  (getPromotionsListState) =>
    memoize((ids: string[]) =>
      getPromotionsListState().filter((promo) => ids.includes(promo.Id)),
    ),
);
