import {
  EDocumentStatusResponse,
  EUploadDocumentStatus,
} from '@gaming1/g1-requests';

export const getUploadDocumentStatusErrorMessages = {
  [EUploadDocumentStatus.InternalError]: 'core:error.internalServer', // 0
  [EUploadDocumentStatus.UploadDocumentDenied]:
    'user:authentication.message.uploadDocumentDenied', // 2
  [EUploadDocumentStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 3
  [EUploadDocumentStatus.TooMuchAttempts]:
    'user:authentication.message.tooMuchAttempts', // 4
};

export const getDocumentStatusErrorMessages = {
  [EDocumentStatusResponse.None]: 'core:error.none', // 0
  [EDocumentStatusResponse.InternalError]: 'core:error.internalServer', // 2
  [EDocumentStatusResponse.InvalidRoom]: 'core:error.invalidRoom', // 3
};
