import React, {
  ContextType,
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
} from 'react';

import { useEdgesVisibility, useIsTouchDevice } from '@gaming1/g1-utils';

import { ScrollableListContext } from './context';
import { useListDetails, useScrollableListContext } from './hooks';
import { ListButton, ListButtonIcon, ListWrapper } from './styles';
import { ScrollableButtonProps } from './types';

type ScrollableListButtonProps = Omit<
  ScrollableButtonProps,
  'isVisible' | 'onClick'
>;

const ScrollableListButton: FC<ScrollableListButtonProps> = ({
  side,
  ...props
}) => {
  const hasTouchCapabilities = useIsTouchDevice();

  const { areChildrenOverflowing, scrollHandlers, edges } =
    useScrollableListContext();

  if (!scrollHandlers) {
    return null;
  }

  const isVisible =
    areChildrenOverflowing &&
    !edges[side === 'left' ? 'isLeftVisible' : 'isRightVisible'];
  const onClick =
    side === 'left'
      ? scrollHandlers.handleScrollToLeft
      : scrollHandlers.handleScrollToRight;

  return (
    <ListButton
      {...props}
      data-testid={side === 'right' ? 'right-button' : 'left-button'}
      hasTouchCapabilities={hasTouchCapabilities}
      isVisible={isVisible}
      onClick={onClick}
      side={side}
    >
      <ListButtonIcon
        id={`scroll-nav-button-${side === 'right' ? 'next' : 'previous'}`}
        type={side === 'right' ? 'AngleRight' : 'AngleLeft'}
      />
    </ListButton>
  );
};

type ScrollableListWrapperProps = {
  /** Should the gradient take bottom border out for its height */
  shouldGradientHaveFullHeight?: boolean;
  hasGradient?: boolean;
  layout?: string;
};

const ScrollableListWrapper: FC<ScrollableListWrapperProps> = ({
  children,
  hasGradient,
  shouldGradientHaveFullHeight = false,
  layout = 'normal',
}) => {
  const { edges, areChildrenOverflowing } = useScrollableListContext();

  return (
    <ListWrapper
      edges={edges}
      shouldGradientHaveFullHeight={shouldGradientHaveFullHeight}
      areChildrenOverflowing={areChildrenOverflowing}
      hasGradient={hasGradient || false}
      layout={layout}
    >
      {children}
    </ListWrapper>
  );
};

type ScrollableListProps = {
  children: React.ReactNode;
  containerRef: MutableRefObject<HTMLElement | null>;
  listRef: MutableRefObject<HTMLElement | null>;
};

export const ScrollableList = ({
  children,
  containerRef,
  listRef,
}: ScrollableListProps) => {
  const { areChildrenOverflowing, scrollHandlers } = useListDetails(
    containerRef,
    listRef,
  );

  const [edges, scrollRef] = useEdgesVisibility();

  useEffect(() => {
    scrollRef(containerRef.current);
  }, [containerRef, scrollRef]);

  const scrollableListContextValue: ContextType<typeof ScrollableListContext> =
    useMemo(
      () => ({
        areChildrenOverflowing,
        scrollHandlers,
        edges,
      }),
      [areChildrenOverflowing, edges, scrollHandlers],
    );

  return (
    <ScrollableListContext.Provider value={scrollableListContextValue}>
      {children}
    </ScrollableListContext.Provider>
  );
};

ScrollableList.ScrollableListButton = ScrollableListButton;
ScrollableList.ScrollableListWrapper = ScrollableListWrapper;
