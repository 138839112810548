import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';

const TABLE_LINE_HEIGHT_IN_PX = 24;

export const StyledTableBody = styled.tbody`
  border-radius: 4px;
  border: 1px solid ${colors('separator')};

  td {
    line-height: ${TABLE_LINE_HEIGHT_IN_PX}px;
    width: 50%;

    &:first-of-type {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

export const TableWrapper = styled.div`
  table {
    border-radius: 4px;
    border-collapse: initial;
    border: 1px solid ${colors('separator')};
  }
`;
