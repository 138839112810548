import React, { FC } from 'react';

import { useIsFullscreenLayout } from '../../../routing/hooks';

import { AppLogo } from './AppLogo';
import { AppSection } from './AppSection';
import { Menu } from './Menu';
import { SearchIcon } from './SearchIcon';
import { TopNavContainer, TopNavContentContainer, TopNavGroup } from './styles';

type TopNavCompoundComponents = {
  AppLogo: typeof AppLogo;
  AppSection: typeof AppSection;
  Group: typeof TopNavGroup;
  Menu: typeof Menu;
  SearchIcon: typeof SearchIcon;
};

/**
 * The top navigation bar
 */
export const TopNav: FC & TopNavCompoundComponents = ({ children }) => {
  const isFullscreenLayout = useIsFullscreenLayout();

  return isFullscreenLayout ? null : (
    <TopNavContainer>
      <TopNavContentContainer>{children}</TopNavContentContainer>
    </TopNavContainer>
  );
};

TopNav.AppLogo = AppLogo;
TopNav.AppSection = AppSection;
TopNav.Group = TopNavGroup;
TopNav.Menu = Menu;
TopNav.SearchIcon = SearchIcon;
