import { createContext } from 'react';

import { generateInitialRequestState, RequestState } from '@gaming1/g1-utils';

// Keys from EProtectedFeature from g1-requests
type ProtectedFeature =
  | 'None'
  | 'ChangePassword'
  | 'ResetPassword'
  | 'UpdatePlayerBankInfo';

/** Context containing info about the pincode requirements */
export const PinCodeContext = createContext<{
  isPinCodeEnabled: boolean;
  protectedFeatures: ProtectedFeature[];
  requestState: RequestState;
}>({
  isPinCodeEnabled: false,
  protectedFeatures: [],
  requestState: generateInitialRequestState(),
});
