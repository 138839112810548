import React, { MouseEvent, useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  useFormatMoney,
  userCreditSelector,
  userCreditStatusSelector,
  userDetailsForGASelector,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { paymentPublicRoutes } from '@gaming1/g1-payment-api';
import { useMediaBreakPoint } from '@gaming1/g1-ui';
import { RemoteData, useGetIsMounted } from '@gaming1/g1-utils';

import { useRoutePath } from '../../../routing/hooks';
import { useTracking } from '../../../tracking/hooks/useTracking';

import { IconAdd } from './IconAdd';
import { IconRefresh } from './IconRefresh';
import {
  CashText,
  CashWrapper,
  DepositNavLink,
  RefreshCreditButton,
  RefreshCreditButtonWrapper,
  ROTATING_ANIMATION_TIME_IN_MS,
} from './styles';

type DepositButtonProps = {
  /** Only render the deposit button */
  displayOnlyButton?: boolean;
  /** Force the button height to provided value */
  forcedButtonHeight?: number;
  /**
   * Optional callback called when the user clicks the deposit button
   * By default, it's a link to the deposit page
   */
  onClick?: () => void;
  /** Wether the Text going next to the icon should be hidden or not */
  shouldHideIconAddText?: boolean;
};

/**
 * Displays the amount available in a player's account.
 * This component is only displayed if the user is logged in.
 * A click on the button redirect to the deposit page
 */
export const DepositButton: VFC<DepositButtonProps> = ({
  displayOnlyButton,
  forcedButtonHeight,
  onClick,
  shouldHideIconAddText = false,
}) => {
  const dispatch = useDispatch();
  const formatMoney = useFormatMoney();
  const getIsMounted = useGetIsMounted();
  const { pushEvent } = useTracking();
  const userGAInfos = useSelector(userDetailsForGASelector);
  const userCredit = useSelector(userCreditSelector);
  const userLoggedIn = useSelector(userLoggedInSelector);
  const getPaymentRoutePath = useRoutePath(paymentPublicRoutes);

  const buttonHeight = useMediaBreakPoint({ min: 'xl' }) ? 28 : 22;

  const onDepositButtonClick = (e: MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
    if (userGAInfos?.Id) {
      pushEvent({
        event: 'deposit_start',
        deposit_counter: userGAInfos.DepositCounter || 'unknown',
        player_segment: userGAInfos.PlayerSegment,
        player_age: userGAInfos.Age || 'unknown',
        player_gender: userGAInfos.Civility,
        user_id: userGAInfos.Id,
      });
    }
  };

  const [isRotating, setIsRotating] = useState(false);
  const [isRotationComplete, setIsRotationComplete] = useState(false);
  const userCreditStatus = useSelector(userCreditStatusSelector);
  const isLoading = userCreditStatus === RemoteData.Loading;

  const onRefreshCreditButtonClick = () => {
    if (!isLoading) {
      setIsRotating(true);
      setIsRotationComplete(false);
      dispatch(actions.clickRefreshCredit());
      setTimeout(() => {
        if (getIsMounted()) {
          setIsRotationComplete(true);
        }
      }, ROTATING_ANIMATION_TIME_IN_MS);
    }
  };

  useEffect(() => {
    // This ensures that the icon performs at least one full rotation.
    if (isRotationComplete && !isLoading) {
      setIsRotating(false);
    }
  }, [isLoading, isRotationComplete]);

  if (!userLoggedIn) {
    return null;
  }

  return displayOnlyButton ? (
    <DepositNavLink
      data-testid="deposit-button"
      height={forcedButtonHeight || buttonHeight}
      onClick={onDepositButtonClick}
      to={getPaymentRoutePath('deposit')}
    >
      <IconAdd
        shouldHideText={shouldHideIconAddText}
        wrapperHeight={forcedButtonHeight || buttonHeight}
      />
    </DepositNavLink>
  ) : (
    <CashWrapper>
      <RefreshCreditButtonWrapper>
        <RefreshCreditButton
          data-testid="refresh-credit-button"
          onClick={onRefreshCreditButtonClick}
        >
          <IconRefresh isRotating={isRotating} />
          <CashText>
            {userCredit !== null && userCredit.AvailableAmout !== null
              ? formatMoney(userCredit.AvailableAmout)
              : formatMoney(0).replace(/0/g, '-')}
          </CashText>
        </RefreshCreditButton>
      </RefreshCreditButtonWrapper>
      <DepositNavLink
        data-testid="deposit-button"
        exact
        height={forcedButtonHeight || buttonHeight}
        onClick={onDepositButtonClick}
        to={getPaymentRoutePath('deposit')}
      >
        <IconAdd
          shouldHideText={shouldHideIconAddText}
          wrapperHeight={forcedButtonHeight || buttonHeight}
        />
      </DepositNavLink>
    </CashWrapper>
  );
};
