import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { userLoggedInSelector } from '@gaming1/g1-core';
import {
  getBoostusConditionsForBettingSlip,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { formatForBettingSlipPreparedRequest } from '../../bettingSlip/helpers/forBettingSlip';
import { getConditionsForBettingSlipResponseCodec } from '../../bettingSlip/store/codecs/common';
import { prepareForBSGetterSelector } from '../../bettingSlip/store/selectors/gifts/prepareConditionsForBS';
import * as boostusActions from '../../boostus/actions';
import {
  boostusIsSuccessGettorSelector,
  getBoostusGetterSelector,
} from '../../boostus/selectors';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

const onTimeOut = {
  retryOnTimeout: true,
  timeoutAfterMs: 10000 /* 10 sec */,
};

export const boostusConditionsForBettingSlipEpic: BettingEpic = (
  actions$,
  state$,
  { config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.boostusConditionsForBettingSlip.request)),
    withLatestFrom(state$, config$),
    map(([{ payload }, state, config]) => {
      const { betPipeId, bettingSlipId, bettingSlipType } =
        extractInformationForBettingSlip(payload, state);

      if (!config.betting.boostus.enabled || bettingSlipType === 'system') {
        return actions.boostusConditionsForBettingSlip.success(
          formatActionsData({
            data: {
              Notification: {
                Code: NotificationCode.GetBoostusConditionForBettingSlip_InternalError,
                Level: NotificationLevel.Information,
              },
              ConditionsIds: [],
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }
      const isUserLoggedIn = userLoggedInSelector(state);

      if (!isUserLoggedIn) {
        return actions.boostusConditionsForBettingSlip.failure(
          formatActionsData({
            data: {
              errorMessage:
                'betting:boostusConditions.error.userNotAuthenticated',
              status: DEFAULT_SERVER_ERROR_STATUS,
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }

      const isSuccessBoostusRequest = boostusIsSuccessGettorSelector(state);
      if (isSuccessBoostusRequest) {
        return boostusActions.getBoostusWithCache({
          ...payload,
          ...{ askBoostusConditions: true },
        });
      }

      return boostusActions.getAvailableBoostus.request({
        ...payload,
        ...{ askBoostusConditions: true },
      });
    }),
  );

export const reactToBoostusSuccessActionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(
      isActionOf([
        boostusActions.getAvailableBoostus.success,
        boostusActions.getBoostusWithCache,
      ]),
    ),
    withLatestFrom(state$),
    filter(([{ payload }]) => !!payload.askBoostusConditions),
    map(([{ payload }, state]) => {
      const { bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      const isUserLoggedIn = userLoggedInSelector(state);

      if (!isUserLoggedIn) {
        return {
          payload,
          request: null,
          state,
          isUserLoggedIn,
        };
      }

      const list = getBoostusGetterSelector(state);
      if (list.length > 0) {
        return {
          payload,
          request: formatForBettingSlipPreparedRequest(
            prepareForBSGetterSelector(state)(bettingSlipId),
          ),
          state,
          isUserLoggedIn,
        };
      }

      return {
        payload,
        request: null,
        state,
        isUserLoggedIn,
      };
    }),
    mergeMap(({ payload, request, state, isUserLoggedIn }) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      if (!request) {
        return of(
          actions.boostusConditionsForBettingSlip.success(
            formatActionsData({
              data: {
                Notification: {
                  Code: NotificationCode.GetBoostusConditionForBettingSlip_InternalError,
                  Level: NotificationLevel.Information,
                },
                ConditionsIds: [],
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }

      if (!isUserLoggedIn) {
        return of(
          actions.boostusConditionsForBettingSlip.failure(
            formatActionsData({
              data: {
                errorMessage:
                  'betting:boostusConditions.error.userNotAuthenticated',
                status: DEFAULT_SERVER_ERROR_STATUS,
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }

      return wsAdapter
        .request(getBoostusConditionsForBettingSlip(request), onTimeOut)
        .pipe(
          takeUntilPipeIsCancelled(betPipeId, actions$),
          mapGuard(getConditionsForBettingSlipResponseCodec),
          map((data) => {
            if (
              data.Notification &&
              data.Notification.Code ===
                NotificationCode.GetBoostusConditionForBettingSlip_Success
            ) {
              return actions.boostusConditionsForBettingSlip.success(
                formatActionsData({
                  data,
                  bettingSlipId,
                  betPipeId,
                }),
              );
            }
            const { Notification } = data;
            const errorMessage =
              (Notification && Notification.Message) ??
              'betting:bettingSlip.boostusConditionsForBettingSlip.noNotificationError';
            return actions.boostusConditionsForBettingSlip.failure(
              formatActionsData({
                data: {
                  errorMessage,
                  status: DEFAULT_SERVER_ERROR_STATUS,
                  ...data,
                },
                bettingSlipId,
                betPipeId,
              }),
            );
          }),
          catchError((err) =>
            of(
              actions.boostusConditionsForBettingSlip.failure(
                formatActionsData({
                  data: createFailurePayload(err, commonBettingErrorMessages),
                  bettingSlipId,
                  betPipeId,
                }),
              ),
            ),
          ),
        );
    }),
  );

export const reactToBoostusFailActionEpic: BettingEpic = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(boostusActions.getAvailableBoostus.failure)),
    withLatestFrom(state$),
    filter(([{ payload }]) => !!payload.askBoostusConditions),
    map(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      return actions.boostusConditionsForBettingSlip.failure({
        errorMessage:
          'betting:bettingSlip.boostusConditionsForBettingSlip.boostusListError',
        status: DEFAULT_SERVER_ERROR_STATUS,
        bettingSlipId,
        betPipeId,
      });
    }),
  );
