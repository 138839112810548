import { PrismicQueryInput } from './types';

// TODO: base this helper on the actual partner config to use the right locale
// of the prismic repository (e.g. fr-fr for JOA)
/**
 * Converts an app locale into the prismic format which is based on a language
 * and a region
 *
 * @param locale a locale code from the app
 */
export const getPrismicLocale = (locale: string) => {
  switch (locale) {
    case 'fr':
      return 'fr-be';
    case 'nl':
      return 'nl-be';
    case 'en':
      return 'en-us';
    default:
      return 'en-us';
  }
};

/**
 * Converts a PrismicQueryInput object into a string usable in a Prismic query string
 */
const getPrismicStringForQuery = (query: PrismicQueryInput): string => {
  const { predicate, path, value } = query;

  if (Array.isArray(value)) {
    const sortedValues = [...value].sort();
    return `[${predicate}(${path}, ["${sortedValues.join('","')}"])]`;
  }
  return `[${predicate}(${path}, "${value}")]`;
};

/**
 * This helper create a valid query to fetch data from the Prismic API.
 * The query could have different shapes according to the given predicate or path.
 * A query will have a shape like this : `[predicate(path, value)]`
 *
 * More accurate examples :
 * If we want to query the document.tags with a "at" predicate : `[[at(document.tags, ["promotions"])]]`
 * If we want to query the document with a "at" predicate : `[[at(document.type, "promotions")]]`
 * If we want to query the document with a date : `[[at(document.first_publication_date, 2020)]]`
 *
 * When we want to use a predicate "at" on a path "document.tags", we've to send an array of string,
 * even if its length is only 1.
 *
 * The value could be empty when using some specific predicates.
 *
 * For more details, see : https://prismic.io/docs/rest-api/query-the-api/predicates-reference
 *
 * @param queries an array of objects containing a predicate, path and value
 */
export const prismicQueriesToString = (queries: PrismicQueryInput[]): string =>
  `[${(queries || [])
    .map((query) => getPrismicStringForQuery(query))
    .join('')}]`;

/**
 * This helper create a valid query to fetch data from the Prismic API.
 * The query could have different shapes according to the given predicate or path.
 * A query will have a shape like this : `[predicate(path, value)]`
 *
 * More accurate examples :
 * If we want to query the document.tags with a "at" predicate : `[[at(document.tags, ["promotions"])]]`
 * If we want to query the document with a "at" predicate : `[[at(document.type, "promotions")]]`
 * If we want to query the document with a date : `[[at(document.first_publication_date, 2020)]]`
 *
 * When we want to use a predicate "at" on a path "document.tags", we've to send an array of string,
 * even if its length is only 1.
 *
 * The value could be empty when using some specific predicates.
 *
 * For more details, see : https://prismic.io/docs/rest-api/query-the-api/predicates-reference
 *
 * @param query an object containing the predicate, path and value
 */
export const prismicQueryToString = (query: PrismicQueryInput) =>
  prismicQueriesToString([query]);
