import React, { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  loyaltyActions,
  ScratchCardHistoryElement,
  scratchCardPrizeInfoSelector,
} from '@gaming1/g1-loyalty';
import { AppAnchor, Modal } from '@gaming1/g1-ui';

import { loyaltyRoutes } from '../../../routes';
import {
  MoreInformationModalLabel,
  MoreInformationModalRow,
  MoreInformationModalValue,
  MoreInformationModalWrapper,
} from '../styles';

export type ScratchcardHistoryInformationModalProps = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  history: ScratchCardHistoryElement;
};

/**
 * Information modal for scratchcard history
 */
export const ScratchcardHistoryInformationModal: FC<
  ScratchcardHistoryInformationModalProps
> = ({ onClose, history, ...rest }) => {
  const { t } = useTranslation('loyalty');
  const dispatch = useDispatch();
  const getLoyaltyRoutePath = useRoutePath(loyaltyRoutes);

  const scratchCardPrizeInfo = useSelector(scratchCardPrizeInfoSelector);

  useEffect(() => {
    if (history.PrizeId) {
      dispatch(
        loyaltyActions.getScratchCardPrizeInfo.request({
          PrizeId: history.PrizeId,
        }),
      );
    }
  }, [dispatch, history.PrizeId]);

  return (
    <Modal
      modalId="scratchcard-history-information-modal"
      onClose={onClose}
      {...rest}
    >
      <MoreInformationModalWrapper>
        <MoreInformationModalRow>
          <MoreInformationModalLabel>
            {t('history.scratchcard.winning')}
          </MoreInformationModalLabel>
          <MoreInformationModalValue>
            {!history.IsScratched ? (
              <AppAnchor
                href={getLoyaltyRoutePath('scratchcard', {
                  scratchcardId: history.Id,
                })}
              >
                {t('history.scratchcard.toscratch')}
              </AppAnchor>
            ) : (
              scratchCardPrizeInfo?.PrizeAmount || t('history.scratchcard.lost')
            )}
          </MoreInformationModalValue>
        </MoreInformationModalRow>
        <MoreInformationModalRow>
          <MoreInformationModalLabel>
            {t('history.scratchcard.ticketid')}
          </MoreInformationModalLabel>
          <MoreInformationModalValue>{history.Id}</MoreInformationModalValue>
        </MoreInformationModalRow>
      </MoreInformationModalWrapper>
    </Modal>
  );
};
