/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  SportNavRequest,
  SportNavResponse,
  SportsRequest,
  SportsResponse,
} from '../entities';

export const getSports = (options: SportsRequest) =>
  createRequest<SportsResponse>(201, 'GetSports', options);

export const getSportNav = (options: SportNavRequest) =>
  createRequest<SportNavResponse>(201, 'GetSportNav', options);
