import { MediaBreakPointNames } from '@gaming1/g1-style';

/**
 * From this breakpoint, the BottomNav will be hidden, DrawerNav will not be
 * openable and TopBar and TopNav will be visible
 */
export const desktopLayoutBreakpoint: MediaBreakPointNames = 'lg';

/**
 * Url search parameter that will be used by pages and components. It will
 * specify if a drawer has to be opened after a navigation. The parameter will
 * be used by the useDrawerByUrlSearchParam hook.
 */
export const DRAWER_URL_SEARCH_PARAMETER = 'drawer';
