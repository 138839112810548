import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  FetchPromotionsRequestPayload,
  FetchPromotionsSuccessPayload,
  PromotionDocument,
  PromotionsConfiguration,
} from './types';

/** Action to fetch the 'Prismic Configuration' document in Prismic */
export const fetchPromotionsConfiguration = createAsyncAction(
  'prismic/fetch_promotions_configuration_request',
  'prismic/fetch_promotions_configuration_success',
  'prismic/fetch_promotions_configuration_failure',
)<void, PromotionsConfiguration, FailurePayload>();

/** Action to fetch promotions documents in Prismic */
export const fetchPromotions = createAsyncAction(
  'prismic/fetch_promotions_request',
  'prismic/fetch_promotions_success',
  'prismic/fetch_promotions_failure',
)<
  FetchPromotionsRequestPayload,
  FetchPromotionsSuccessPayload,
  { data: FailurePayload; request: FetchPromotionsRequestPayload }
>();

/** Action to fetch a promotion document in Prismic, by its UID */
export const fetchPromotionByUid = createAsyncAction(
  'prismic/fetch_promotion_request',
  'prismic/fetch_promotion_success',
  'prismic/fetch_promotion_failure',
  'prismic/fetch_promotion_cancel',
)<{ uid: string }, PromotionDocument, FailurePayload, void>();
