import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { NavLeagueType } from '@gaming1/g1-betting';

import { MenuItem } from '../../../common/components/MenuItem';
import { useBettingRoutePath } from '../../../hooks';

type LeagueMenuItemProps = {
  item: NavLeagueType;
  isSelected?: boolean;
};

export const LeagueMenuItem: FC<LeagueMenuItemProps> = ({
  item,
  isSelected = false,
}) => {
  const theme = useTheme();
  const getBettingRoutePath = useBettingRoutePath();

  return (
    <MenuItem
      key={item.Id}
      hasNoShadow
      margin={0}
      pl="xs"
      pr="md"
      py="xxs"
      hasNoElevation
      hasNoHoverBehavior
    >
      <MenuItem.Link
        to={getBettingRoutePath('league', {
          sportId: String(item.SportId),
          regionId: String(item.RegionId),
          leagueId: String(item.Id),
        })}
        key={item.Id}
      >
        <MenuItem.Content
          elevationLevel={isSelected ? 3 : undefined}
          hoverElevationLevel={4}
          hasBorderRadius
          ml="md"
          pl="md"
          py="xxs"
          color={
            isSelected ? theme.colors.neutrals[0] : theme.colors.neutrals[2]
          }
        >
          {item.Name}
        </MenuItem.Content>
      </MenuItem.Link>
    </MenuItem>
  );
};
