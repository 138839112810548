import React, { useState, VFC } from 'react';
import { useLocation } from 'react-router-dom';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { WebSocketAdapter, wsAdapter } from '@gaming1/g1-network';
import { Button, CheckboxInput, Heading } from '@gaming1/g1-ui';
import { persistIn, readFrom } from '@gaming1/g1-utils';

import { ConfigSwitch } from './ConfigSwitch';
import { DebugModeToggle } from './DebugModeToggle';
import { EditableNetworkConfigFields } from './EditableNetworkConfigFields';
import { LogLevelSwitch } from './LogLevelSwitch';
import { NetworkTools } from './NetworkTools';
import {
  DevToolContentDiv,
  DevToolTitleH3,
  DevToolVisibilityButton,
  DevToolWrapperDiv,
} from './styles';
import { ThemeSwitch } from './ThemeSwitch';

const LOCAL_STORAGE_SWITCHER_ANIMATION_KEY = 'switcherAnimation';

const animationEnabledByDefaut = !!readFrom(
  localStorage,
  LOCAL_STORAGE_SWITCHER_ANIMATION_KEY,
);

type DevToolMenuProps = {
  /** An optional WebSocketAdapter instance (useful for DI) */
  webSocketAdapter?: WebSocketAdapter;
};

/**
 * Developer tool (only available for site-default) used to switch and test
 * different environments. It can change on the fly
 * - The config used
 * - The theme used
 * - The log level of the global logger
 */
export const DevToolMenu: VFC<DevToolMenuProps> = ({
  webSocketAdapter = wsAdapter,
}) => {
  const [visibility, setVisibility] = useState(false);
  const onVisibilityToggle = () => setVisibility((visible) => !visible);
  // That way the menu is hidden either if it was closed or the backdrop was clicked
  const [colorAnimation, setColorAnimation] = useState(
    animationEnabledByDefaut,
  );
  const onAnimationSwitch = () => {
    setColorAnimation((animSet) => {
      persistIn(localStorage, LOCAL_STORAGE_SWITCHER_ANIMATION_KEY, !animSet);
      return !animSet;
    });
  };

  const location = useLocation();

  const handlePageReload = () => {
    wsAdapter.prepareDisconnection();
    wsAdapter.triggerDisconnection();
    /*
     * This reload will ensure that the website is
     * refreshed and that all requests are triggered again.
     */
    if (getWrapperEnv() !== 'rn') {
      window.location.reload();
    }
  };

  return (
    <DevToolWrapperDiv data-testid="devtoolmenu" visible={visibility}>
      <DevToolVisibilityButton
        animated={colorAnimation}
        aria-label="Show dev tools"
        onClick={onVisibilityToggle}
        visible={visibility}
      />
      <DevToolTitleH3 as="div">Dev Tool Menu</DevToolTitleH3>
      <DevToolContentDiv>
        <DebugModeToggle />
        <ConfigSwitch onChange={handlePageReload} />
        <EditableNetworkConfigFields onChange={handlePageReload} />
        <ThemeSwitch />
        <LogLevelSwitch />
        <NetworkTools webSocketAdapter={webSocketAdapter} />
        <Heading level="h4">Misc</Heading>
        <CheckboxInput
          checked={colorAnimation}
          label="Fancy switcher animation"
          onClick={onAnimationSwitch}
        />
        <Button
          mt="sm"
          onClick={() => {
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(location, null, 2));
          }}
        >
          Location
        </Button>
      </DevToolContentDiv>
    </DevToolWrapperDiv>
  );
};
