import React, { FC } from 'react';

import {
  BackgroundCircle,
  CircularProgressBarSvg,
  CircularProgressBarWrapper,
  ProgressCircle,
} from './styles';

export type CircularProgressBarProps = {
  /** Progress amount in decimal fraction between 0 and 1 (e.g. 0.4 for 40%) */
  progress: number;
};

export const CircularProgressBar: FC<CircularProgressBarProps> = ({
  children,
  progress,
}) => (
  <CircularProgressBarWrapper>
    <CircularProgressBarSvg
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* As strokes cannot be placed inside or outside of an SVG shape, the
    radius here is slightly smaller to allow it to grow inside the viewBox */}
      <BackgroundCircle cx="100" cy="100" r="90" fill="transparent" />
      <ProgressCircle
        cx="100"
        cy="100"
        r="90"
        fill="transparent"
        strokeDasharray="565.48"
        strokeDashoffset={(1 - progress) * Math.PI * 90 * 2}
        strokeLinecap="round"
      />
    </CircularProgressBarSvg>
    {children}
  </CircularProgressBarWrapper>
);
