import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  colors,
  elevation,
  fontSizes,
  spaces,
  zIndex,
} from '@gaming1/g1-style';
import {
  Heading,
  ResponsiveContentContainer,
  SimpleButton,
} from '@gaming1/g1-ui';

import {
  HEADER_HEIGHT,
  HEADER_LARGE_HEIGHT,
  HEADER_LOGO_HEIGHT,
} from '../../../styles';
import { Image } from '../Image';

export const HeaderPlaceholder = styled.div<{ shouldBeSticky?: boolean }>`
  background-color: ${colors('headerBackground')};
  position: relative;
  z-index: ${zIndex(3, 1)};

  ${({ shouldBeSticky }) =>
    shouldBeSticky &&
    css`
      position: sticky;
      top: 0;
    `}
`;

export const HeaderTitle = styled(Heading).attrs(() => ({
  level: 'h2',
}))`
  margin: 0;
`;

export const MobileHeaderWrapper = styled.div`
  ${elevation(2)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: ${HEADER_HEIGHT}px;
`;

const buttonCss = css`
  color: ${colors('text')};
  text-decoration: none;

  letter-spacing: 0.5px;

  svg {
    font-size: ${({ theme }) => theme.options.buttonsFontSize};

    path {
      fill: ${colors('text')};
    }
  }
`;

const mobileButtonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;
`;

export const BackButton = styled(SimpleButton)`
  ${buttonCss}
`;

export const CloseButton = styled(SimpleButton)`
  ${buttonCss}
`;

export const CloseLink = styled(Link)`
  ${buttonCss}
`;

export const MobileBackButtonWrapper = styled.div`
  flex-basis: ${HEADER_HEIGHT}px;

  & ${BackButton} {
    ${mobileButtonCss}
  }
`;

export const MobileCloseButtonWrapper = styled.div`
  flex-basis: ${HEADER_HEIGHT}px;

  & ${CloseButton} {
    ${mobileButtonCss}
  }

  & ${CloseLink} {
    ${mobileButtonCss}
  }
`;

export const DesktopHeaderWrapper = styled(ResponsiveContentContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: ${HEADER_LARGE_HEIGHT}px;
`;

export const DesktopBackButtonWrapper = styled.div`
  display: flex;
`;

export const DesktopCloseButtonWrapper = styled.div`
  display: flex;

  margin-left: auto;

  & ${CloseButton} {
    svg {
      margin-left: ${spaces('sm')};
    }
  }

  & ${CloseLink} {
    svg {
      margin-left: ${spaces('sm')};
    }
  }
`;

export const CloseText = styled.span`
  font-size: ${fontSizes('xl')};
`;

export const HeaderLogoImg = styled(Image)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: ${HEADER_LOGO_HEIGHT}px;
`;
