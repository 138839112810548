import styled from 'styled-components';

import { Alignement } from '@gaming1/g1-cms';

import { PrismicWrapper } from '../styles';

export type PrismicImageContainerProps = {
  /** Alignment of the image */
  alignment: Alignement | null;
};

export const PrismicImageContainer = styled(
  PrismicWrapper,
)<PrismicImageContainerProps>`
  display: flex;

  justify-content: ${({ alignment }) => {
    if (alignment === 'center') {
      return 'center';
    }
    if (alignment === 'right') {
      return 'flex-end';
    }
    return 'flex-start';
  }};
`;

export type PrismicImgProps = {
  /** Width of the image (in percent) */
  imageWidth?: string | null;
};

export const PrismicImg = styled.img<PrismicImgProps>`
  width: ${({ imageWidth }) => imageWidth || '100%'};
`;
