import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { userLoggedInSelector } from '@gaming1/g1-core';
import {
  getRiskManagement,
  getSystemRiskManagement,
  NotificationCode,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  curriedHasNonNullableProperty,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import {
  formatRiskManagementPreparedRequest,
  formatSystemRiskManagementPreparedRequest,
} from '../../bettingSlip/helpers/riskManagement';
import {
  hasSystemTooManyElements,
  helperBettingSlipIsValid,
} from '../../bettingSlip/helpers/validators';
import {
  getRiskManagementResponseCodec,
  getSystemRiskManagementResponseCodec,
} from '../../bettingSlip/store/codecs/riskManagement';
import { bettingSlipItemsSportBookIdsGetterSelector } from '../../bettingSlip/store/selectors';
import { bettingSlipTypeGetterSelector } from '../../bettingSlip/store/selectors/common';
import { bettingSlipItemsForValidationGetterSelector } from '../../bettingSlip/store/selectors/elements/items';
import {
  riskManagementPrepareCombiGetterSelector,
  riskManagementPrepareSingleGetterSelector,
  riskManagementPrepareSystemGetterSelector,
} from '../../bettingSlip/store/selectors/riskManagement/prepareRiskManagement';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

const onTimeOut = {
  retryOnTimeout: true,
  timeoutAfterMs: 10000 /* 10 sec */,
};

export const systemRiskManagementEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getSystemRiskManagement.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const { bettingSlipType } = extractInformationForBettingSlip(
        payload,
        state,
      );

      const items = bettingSlipItemsSportBookIdsGetterSelector(state)(
        payload.bettingSlipId,
      );

      const hasTooManyElements = hasSystemTooManyElements(items);

      if (bettingSlipType === 'system' && !hasTooManyElements) {
        return {
          payload,
          request: formatSystemRiskManagementPreparedRequest(
            riskManagementPrepareSystemGetterSelector(state)(
              payload.bettingSlipId,
            ),
          ),
        };
      }
      return { payload, request: null };
    }),
    filter(curriedHasNonNullableProperty('request')),
    withLatestFrom(state$),
    mergeMap(([{ payload, request }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );
      return wsAdapter
        .request(getSystemRiskManagement(request), onTimeOut)
        .pipe(
          takeUntilPipeIsCancelled(betPipeId, actions$),
          mapGuard(getSystemRiskManagementResponseCodec),
          map((data) => {
            if (
              data.Notification &&
              data.Notification.Code === NotificationCode.RiskManagement_Success
            ) {
              return actions.getSystemRiskManagement.success(
                formatActionsData({
                  data,
                  bettingSlipId,
                  betPipeId,
                }),
              );
            }
            const { Notification } = data;
            const errorMessage =
              (Notification && Notification.Message) ??
              'betting:bettingSlip.systemRiskManagement.noNotificationError';
            return actions.getSystemRiskManagement.failure(
              formatActionsData({
                data: {
                  errorMessage,
                  status: DEFAULT_SERVER_ERROR_STATUS,
                  ...data,
                },
                bettingSlipId,
                betPipeId,
              }),
            );
          }),
          catchError((err) =>
            of(
              actions.getSystemRiskManagement.failure(
                formatActionsData({
                  data: createFailurePayload(err, commonBettingErrorMessages),
                  bettingSlipId,
                  betPipeId,
                }),
              ),
            ),
          ),
        );
    }),
  );

export const riskManagementEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getRiskManagement.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const bettingSlipType =
        payload.bettingSlipType ??
        bettingSlipTypeGetterSelector(state)(payload.bettingSlipId);

      switch (bettingSlipType) {
        case 'single':
          return {
            payload,
            request: formatRiskManagementPreparedRequest(
              riskManagementPrepareSingleGetterSelector(state)(
                payload.bettingSlipId,
              ),
            ),
          };
        case 'combi':
          return {
            payload,
            request: formatRiskManagementPreparedRequest(
              riskManagementPrepareCombiGetterSelector(state)(
                payload.bettingSlipId,
              ),
            ),
          };
        default:
          return { payload, request: null };
      }
    }),
    filter(curriedHasNonNullableProperty('request')),
    withLatestFrom(state$),
    mergeMap(([{ payload, request }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );
      return wsAdapter.request(getRiskManagement(request), onTimeOut).pipe(
        takeUntilPipeIsCancelled(betPipeId, actions$),
        mapGuard(getRiskManagementResponseCodec),
        map((data) => {
          if (
            data.Notification &&
            data.Notification.Code === NotificationCode.RiskManagement_Success
          ) {
            return actions.getRiskManagement.success(
              formatActionsData({
                data,
                bettingSlipId,
                betPipeId,
              }),
            );
          }
          const { Notification } = data;
          const errorMessage =
            (Notification && Notification.Message) ??
            'betting:bettingSlip.riskManagement.noNotificationError';
          return actions.getRiskManagement.failure(
            formatActionsData({
              data: {
                errorMessage,
                status: DEFAULT_SERVER_ERROR_STATUS,
                ...data,
              },
              bettingSlipId,
              betPipeId,
            }),
          );
        }),
        catchError((err) =>
          of(
            actions.getRiskManagement.failure(
              formatActionsData({
                data: createFailurePayload(err, commonBettingErrorMessages),
                bettingSlipId,
                betPipeId,
              }),
            ),
          ),
        ),
      );
    }),
  );

export const reactToRiskManagementRequestAction: BettingEpic = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.launchRiskManagement.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );
      if (!bettingSlipId) {
        return actions.launchRiskManagement.failure(
          formatActionsData({
            data: {
              errorMessage: 'betting:bettingSlip.riskManagement.noBsId',
              status: DEFAULT_SERVER_ERROR_STATUS,
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }

      const isUserLoggedIn = userLoggedInSelector(state);

      if (!isUserLoggedIn) {
        return actions.launchRiskManagement.failure(
          formatActionsData({
            data: {
              errorMessage:
                'betting:bettingSlip.riskManagement.userNotLoggedIn',
              status: DEFAULT_SERVER_ERROR_STATUS,
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }

      const items =
        bettingSlipItemsForValidationGetterSelector(state)(bettingSlipId);

      const isValid = helperBettingSlipIsValid(items);
      if (!isValid) {
        return actions.launchRiskManagement.failure(
          formatActionsData({
            data: {
              errorMessage: 'betting:bettingSlip.riskManagement.items.notValid',
              status: DEFAULT_SERVER_ERROR_STATUS,
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }

      const bettingSlipType =
        bettingSlipTypeGetterSelector(state)(bettingSlipId);

      const requestPayload = {
        betPipeId,
        bettingSlipId,
      };

      if (bettingSlipType === 'single' || bettingSlipType === 'combi') {
        return actions.getRiskManagement.request(requestPayload);
      }
      if (bettingSlipType === 'system') {
        return actions.getSystemRiskManagement.request(requestPayload);
      }

      return actions.launchRiskManagement.failure(
        formatActionsData({
          data: {
            errorMessage: 'betting:bettingSlip.riskManagement.bsTypeError',
            status: DEFAULT_SERVER_ERROR_STATUS,
          },
          bettingSlipId,
          betPipeId,
        }),
      );
    }),
  );

export const reactToRiskManagementSuccessAction: BettingEpic = (action$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.getRiskManagement.success,
        actions.getSystemRiskManagement.success,
      ]),
    ),
    map(({ payload }) => actions.launchRiskManagement.success(payload)),
  );

export const reactToRiskManagementFailureAction: BettingEpic = (action$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.getRiskManagement.failure,
        actions.getSystemRiskManagement.failure,
      ]),
    ),
    map(({ payload }) => actions.launchRiskManagement.failure(payload)),
  );

export const reactToRiskManagementCancelAction: BettingEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.launchRiskManagement.cancel)),
    map(({ payload }) => {
      if (payload.bettingSlipType === 'system') {
        return actions.getSystemRiskManagement.cancel(payload);
      }
      if (
        payload.bettingSlipType === 'single' ||
        payload.bettingSlipType === 'combi'
      ) {
        return actions.getRiskManagement.cancel(payload);
      }

      return actions.launchRiskManagement.failure({
        errorMessage: 'betting:bettingSlip.riskManagement.bsTypeError',
        status: DEFAULT_SERVER_ERROR_STATUS,
        bettingSlipId: payload.bettingSlipId,
        betPipeId: payload.betPipeId,
      });
    }),
  );
