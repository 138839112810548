import React, { VFC } from 'react';
import { useSelector } from 'react-redux';

import { mobileVersionSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { getWrapperEnv } from '@gaming1/g1-logger';

import { FooterText } from './styles';

/**
 * This component will retrieve the version of the native application
 * and display it. If there is no information in the store when the component
 * is displayed, nothing will be rendered.
 */
export const AppVersion: VFC = () => {
  const { t } = useTranslation('core');

  const { mobileBuildNumber, mobileBuildVersion } = useSelector(
    mobileVersionSelector,
  );

  return getWrapperEnv() === 'rn' && mobileBuildNumber && mobileBuildVersion ? (
    <FooterText>
      {t('footer.version', {
        mobileBuildVersion,
        mobileBuildNumber,
      })}
    </FooterText>
  ) : null;
};
