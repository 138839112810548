import { Icon } from '@gaming1/g1-ui';

import { useUniformizationGiftsDesign } from './config';

export const useBettingIconOverrideList = () =>
  useUniformizationGiftsDesign()
    ? {
        Boostus: () => <Icon type="BoostusV2" id="boostus" />,
        DailyOffer: () => <Icon type="DailyOfferV2" id="DailyOffer" />,
        BettingPromotion: () => (
          <Icon type="BettingPromotionV2" id="BettingPromotion" />
        ),
        SuperOdds: () => <Icon type="SuperOddsV2" id="SuperOdds" />,
      }
    : {};
