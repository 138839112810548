/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:54:31 25-03-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetBettingSlipPlayerPreferences,
  GetBettingSlipPlayerPreferencesResponse,
  GetUserPreferencesRequest,
  UpdatePlayerPreferencesRequest,
  UpdatePlayerPreferencesResponse,
} from '../entities';

export const getBettingSlipPlayerPreferences = (
  options: GetBettingSlipPlayerPreferences,
) =>
  createRequest<GetBettingSlipPlayerPreferencesResponse>(
    202,
    'GetBettingSlipPlayerPreferences',
    options,
  );

export const getUserPreferences = (options: GetUserPreferencesRequest) =>
  createRequest<GetBettingSlipPlayerPreferencesResponse>(
    202,
    'GetUserPreferences',
    options,
  );

export const updateUserPreferences = (
  options: UpdatePlayerPreferencesRequest,
) =>
  createRequest<UpdatePlayerPreferencesResponse>(
    202,
    'UpdateUserPreferences',
    options,
  );
