import React, { useContext, useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  getPlayerMonthlyBoxInfoSelector,
  getPlayerWheelInfoSelector,
  loyaltyActions,
} from '@gaming1/g1-loyalty';
import { Icon, useMediaBreakPoint } from '@gaming1/g1-ui';

import { loyaltyRoutes } from '../../../routes';
import { BREAKPOINT_PROFILE_FOR_MOBILE } from '../../constants';

import {
  IconsWrapper,
  IconWrapper,
  Notification,
  UnstyledLink,
  VerticalSeparator,
} from './styles';

export const ClubFeatureLinksDesktop: VFC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('loyalty');
  const { colors } = useContext(ThemeContext);
  const getLoyaltyPath = useRoutePath(loyaltyRoutes);
  const shouldUseMobileLayout = useMediaBreakPoint({
    max: BREAKPOINT_PROFILE_FOR_MOBILE,
  });
  const { loyalty } = useContext(ConfigContext);

  const playerMonthlyBoxInfo = useSelector(getPlayerMonthlyBoxInfoSelector);
  const playerWheelInfo = useSelector(getPlayerWheelInfoSelector);

  useEffect(() => {
    if (loyalty.shop || loyalty.fullShop) {
      dispatch(loyaltyActions.getPlayerMonthlyBoxInfo.request());
      dispatch(loyaltyActions.getPlayerWheelInfo.request());
    }
  }, [dispatch, loyalty.shop, loyalty.fullShop]);

  if (shouldUseMobileLayout) {
    return null;
  }

  return (
    <IconsWrapper>
      {playerWheelInfo?.IsWheelEnabled && (
        <IconWrapper
          isActive={playerWheelInfo?.CanPlayWheel}
          data-testid="button-wheel"
        >
          <Icon
            id="wheel-button"
            type="WheelClub"
            mr="xs"
            fill={playerWheelInfo?.CanPlayWheel ? colors.text : colors.muted}
          />
          {t('games.icon.wheel')}
          {playerWheelInfo?.WheelAvailable && (
            <Notification data-testid="notification-wheel" />
          )}
        </IconWrapper>
      )}
      {playerMonthlyBoxInfo?.IsMonthlyBoxEnabled &&
        playerWheelInfo?.IsWheelEnabled && <VerticalSeparator />}
      {playerMonthlyBoxInfo?.IsMonthlyBoxEnabled && (
        <UnstyledLink to={getLoyaltyPath('giftbox')}>
          <IconWrapper
            isActive={playerMonthlyBoxInfo?.CanPlayMonthlyBox}
            data-testid="button-giftbox"
          >
            <Icon
              id="gift-button"
              type="Gift"
              mr="xs"
              fill={
                playerMonthlyBoxInfo?.CanPlayMonthlyBox
                  ? colors.text
                  : colors.muted
              }
            />
            {t('games.icon.giftbox')}
            {playerMonthlyBoxInfo?.MonthlyBoxAvailable && (
              <Notification data-testid="notification-giftbox" />
            )}
          </IconWrapper>
        </UnstyledLink>
      )}
    </IconsWrapper>
  );
};
