/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:52:52 18-01-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  ScratchCardConsumeRequest,
  ScratchCardConsumeResponse,
} from '../entities';

export const scratchCardConsume = (options: ScratchCardConsumeRequest) =>
  createRequest<ScratchCardConsumeResponse>(100, 'ScratchCardConsume', options);
