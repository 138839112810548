import { Middleware, MiddlewareAPI } from 'redux';

import {
  startsWithAction as isActionWithTypeStartingWith,
  isExternalAction,
} from './helpers';
import { logger } from './logger';
import type { TwinAction } from './types';

type Observer = (message: TwinAction) => void;

type ActionTypeWhiteList = string[] | null;

type TwinningUtils = {
  emit: (message: TwinAction) => void;
  subscribe: (observer: Observer) => () => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  middleware: Middleware<{}, unknown>;
  setWhitelist: (whitelist: string[] | null) => void;
};

/**
 * Middleware redux function.
 * @param whitelist white list of shared actions.
 * If white list is null, all actions are shared
 * If white list is empty, not actions are shared
 * If action.type start with one of white listed item, the twinningMiddleware emit action to subscriber
 * @param dispatcherId Identity of the twinning middleware. Avoiding loop between twinnings
 * @returns setWhitelist: function that give access to dynamically change the whitelist.
 * @returns middleware: redux middleware to apply to a store
 * @returns subscribe: allow anyone to be notified when an action should be transferred to other store.
 * The subscribe function return an unsubscribe function.
 * @returns emit: function that dispatch (correclty) action to the twinned store.
 */
export const twinning = (
  dispatcherId: string,
  whiteList: ActionTypeWhiteList = null,
): TwinningUtils => {
  logger.info(
    `[Twinning] Initializing twinning middleware for dispatcher "${dispatcherId}" with whitelist:`,
    whiteList,
  );

  let isWhiteListedAction = isActionWithTypeStartingWith(whiteList);

  let currentStore: MiddlewareAPI;
  let observers: Observer[] = [];

  const emit = (action: TwinAction): void => {
    logger.debug(
      `[Twinning] Dispatcher "${dispatcherId}" will emit action to observers..`,
      action,
    );

    observers.forEach((observer) => observer(action));
  };

  return {
    setWhitelist: (whitelist: ActionTypeWhiteList) => {
      logger.debug('[Twinning] Whitelisted actions updated with:', {
        whitelist,
      });
      isWhiteListedAction = isActionWithTypeStartingWith(whitelist);
    },

    subscribe: (observer) => {
      logger.debug('[Twinning] Will subscribe to observer...');
      observers.push(observer);

      return () => {
        logger.debug('[Twinning] Will unsubscribe from observer...');
        observers = observers.filter(
          (otherObserver) => otherObserver !== observer,
        );
      };
    },
    // Note: the loggers have been commented out but can be used to debug the twinning logic
    // They are way too verbose when not needed
    emit: (externalAction: TwinAction) => {
      /*
      logger.debug(
        `[Twinning] Dispatcher "${dispatcherId}" received an action of type`,
        `"${externalAction.type}" from "${externalAction.meta?.dispatcherId}"`,
      );
      */

      // Avoid cycling action
      if (!isExternalAction(dispatcherId, externalAction)) {
        /*
        logger.debug(
          `[Twinning] Skipped action of type "${externalAction.type}"`,
          `because it is an internal action we should not care about.`,
        );
        */
        return;
      }

      if (!currentStore) {
        /*
        logger.debug(
          `[Twinning] Cannot dispatch action of type "${externalAction.type}"`,
          `because associated store for dispatcher "${dispatcherId}" was not properly set.`,
          { currentStore },
        );
        */
        return;
      }

      /*
      logger.debug(
        `[Twinning] Dispatching external action "${externalAction.type}" from "${externalAction.meta?.dispatcherId}" to "${dispatcherId}" store`,
      );
      */
      currentStore.dispatch(externalAction);
    },
    middleware: (store) => {
      if (store != null) {
        currentStore = store;
        logger.debug(`[Twinning][Middleware] Current store was updated`);
      }

      // Note: the loggers have been commented out but can be used to debug the twinning logic
      // They are way too verbose when not needed
      return (next) => (action) => {
        /*
        logger.debug(
          `[Twinning][Middleware] Dispatcher "${dispatcherId}" received an action of type ${action.type}", determining what to do with it...`,
        );
        */

        if (!isWhiteListedAction(action)) {
          /*
          logger.debug(
            `[Twinning][Middleware] Skipped action of type "${action.type}"`,
            `because it is not in the whitelist of allowed actions.`,
            { whiteList },
          );
          */
          return next(action);
        }

        // Avoid cycling actions
        if (isExternalAction(dispatcherId, action)) {
          /*
          logger.debug(
            `[Twinning][Middleware] Skipped action of type "${action.type}"`,
            `because it is an external action we should not care about.`,
          );
          */
          return next(action);
        }

        logger.debug(
          `[Twinning] Emitting internal action "${action.type}" from "${dispatcherId}"`,
        );

        emit({
          ...action,
          meta: {
            dispatcherId,
          },
        });

        return next(action);
      };
    },
  };
};
