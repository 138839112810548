import {
  EPasswordRecoveryStatus,
  EResetPasswordStatus,
  EValidatePasswordRecoveryStatus,
} from '@gaming1/g1-requests';

export const passwordRecoveryErrorMessages = {
  [EPasswordRecoveryStatus.InternalError]: 'core:error.internalServer', // 0
  [EPasswordRecoveryStatus.InvalidEmail]:
    'user:passwordRecovery.message.notFound', // 2
  [EPasswordRecoveryStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
  [EPasswordRecoveryStatus.EmptyEmail]:
    'user:passwordRecovery.message.emailEmpty', // 4
  [EPasswordRecoveryStatus.EmailNoExists]:
    'user:passwordRecovery.message.notFound', // 5 Not used anymore as it's treated as successful
};

export const validatePasswordRecoveryErrorMessages = {
  [EValidatePasswordRecoveryStatus.InternalError]: 'core:error.internalServer', // 0,
  [EValidatePasswordRecoveryStatus.InvalidRequest]:
    'user:passwordRecovery.message.invalidRecoveryId', // 2
};

export const resetPasswordErrorMessages = {
  [EResetPasswordStatus.InternalError]: 'core:error.internalServer', // 0
  [EResetPasswordStatus.InvalidPassword]:
    'user:registration.validation.passwordIncorrectFormat', // 4
  [EResetPasswordStatus.InvalidRequest]: 'core:error.invalidParameters', // 2
  [EResetPasswordStatus.InvalidSecurityPinCode]:
    'user:account.message.invalidPinCode', // 5
  [EResetPasswordStatus.PasswordMismatch]:
    'user:account.message.passwordMismatch', // 3
};
