import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NavLeagueType, sportAliasSelector } from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';

import { MenuItem } from '../../../common/components/MenuItem';
import { useBettingRoutePath, useCleanedParams } from '../../../hooks';
import { getIconType } from '../../helpers';

import { MenuTitle } from './MenuTitle';
import { MenuIcon } from './styles';

type TrendsMenuProps = {
  isCollapsed?: boolean;
  items: NavLeagueType[] | null;
};

const TrendsMenuComponent: FC<TrendsMenuProps> = ({
  isCollapsed = false,
  items,
}) => {
  const { t } = useTranslation('betting');
  const getBettingRoutePath = useBettingRoutePath();

  const { leagueId } = useCleanedParams<{ leagueId: string }>();

  const getSportAlias = useSelector(sportAliasSelector);

  const content = useMemo(
    () =>
      items && items.length > 0 ? (
        <>
          <MenuTitle isCollapsed={isCollapsed}>
            {t('lateralMenu.trends')}
          </MenuTitle>
          {items.map((item) => (
            <MenuItem key={item.Id} elevationLevel={2} hasNoShadow mb="1px">
              <MenuItem.Link
                to={getBettingRoutePath('league', {
                  sportId: item.SportId?.toString() || '',
                  regionId: item.RegionId?.toString() || '',
                  leagueId: item.Id?.toString() || '',
                })}
              >
                {!isCollapsed ? (
                  <>
                    <MenuItem.Left isActive={leagueId === item.Id.toString()}>
                      <MenuIcon
                        id="sport-menu-icon"
                        type={getIconType(getSportAlias(item.SportId))}
                      />
                    </MenuItem.Left>
                    <MenuItem.Content>{item.Name}</MenuItem.Content>
                  </>
                ) : (
                  <MenuItem.Content justifyContent="center" alignItems="center">
                    <MenuIcon
                      id="sport-menu-icon"
                      type={getIconType(getSportAlias(item.SportId))}
                    />
                  </MenuItem.Content>
                )}
              </MenuItem.Link>
            </MenuItem>
          ))}
        </>
      ) : null,
    [items, isCollapsed, leagueId, getBettingRoutePath, getSportAlias, t],
  );

  return content;
};

export const TrendsMenu = memo(TrendsMenuComponent);
