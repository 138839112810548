import { createSelector } from 'reselect';

import { EWithdrawMethodInfoStatus } from '@gaming1/g1-requests';

import { ApplicationWithPaymentState } from '../types';

import { withdrawMethodInfoErrorMessages } from './errorMessages';
import {
  GetCreditCardWithdrawInfoResponse,
  GetEWalletWithdrawInfoResponse,
  GetTransferWithdrawInfoResponse,
  GetWithdrawInfoResponse,
  WithdrawalState,
} from './types';

/** Cancel withdrawal */

export const cancelWithdrawalSelector = (state: ApplicationWithPaymentState) =>
  state.payment.withdrawal.cancelWithdrawal;

export const cancelWithdrawalRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.cancelWithdrawal;

/** Get withdrawal */

export const withdrawalSelector = (
  state: ApplicationWithPaymentState,
): WithdrawalState => state.payment.withdrawal;

/** Get withdrawal info */

export const withdrawalInfoSelector = (
  state: ApplicationWithPaymentState,
): GetWithdrawInfoResponse | null => withdrawalSelector(state).withdrawalInfo;

export const getWithdrawalInfoRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => withdrawalSelector(state).requests.getWithdrawInfo;

/** Get transfer withdrawal info */

export const transferWithdrawalInfoSelector = (
  state: ApplicationWithPaymentState,
): GetTransferWithdrawInfoResponse | null =>
  withdrawalSelector(state).transferWithdrawalInfo;

export const getTransferWithdrawalInfoRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => withdrawalSelector(state).requests.getTransferWithdrawInfo;

/** Get EWallet withdrawal info */

export const eWalletWithdrawalInfoSelector = (
  state: ApplicationWithPaymentState,
): GetEWalletWithdrawInfoResponse | null =>
  withdrawalSelector(state).eWalletWithdrawalInfo;

export const getEWalletWithdrawalInfoRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => withdrawalSelector(state).requests.getEWalletWithdrawInfo;

/** Get CreditCard withdrawal info */

export const creditCardWithdrawalInfoSelector = (
  state: ApplicationWithPaymentState,
): GetCreditCardWithdrawInfoResponse | null =>
  withdrawalSelector(state).creditCardWithdrawalInfo;

export const getCreditCardWithdrawalInfoRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => withdrawalSelector(state).requests.getCreditCardWithdrawInfo;

/** Get withdrawal methods */

const isEnabledSelector = (
  _: ApplicationWithPaymentState,
  isEnabled: boolean,
) => isEnabled;

const withdrawalMethodsSelector = createSelector(
  withdrawalInfoSelector,
  isEnabledSelector,
  (withdrawalInfo, isEnabled) => {
    if (
      withdrawalInfo !== null &&
      withdrawalInfo.WithdrawMethods !== undefined
    ) {
      return withdrawalInfo.WithdrawMethods.filter((method) =>
        isEnabled
          ? method.Status !==
            EWithdrawMethodInfoStatus.MinimumDepositAmountNotReached
          : method.Status ===
            EWithdrawMethodInfoStatus.MinimumDepositAmountNotReached,
      );
    }
    return [];
  },
);

export const withdrawalMethodStatusErrorMessageSelector = (
  status: EWithdrawMethodInfoStatus,
): string | undefined =>
  status === EWithdrawMethodInfoStatus.Enabled
    ? undefined
    : withdrawMethodInfoErrorMessages[status];

export const withdrawalEnabledMethodsSelector = (
  state: ApplicationWithPaymentState,
) => withdrawalMethodsSelector(state, true);

export const withdrawalDisabledMethodsSelector = (
  state: ApplicationWithPaymentState,
) => withdrawalMethodsSelector(state, false);

export const hasOnlyOneMethodAvailableSelector = createSelector(
  withdrawalEnabledMethodsSelector,
  withdrawalDisabledMethodsSelector,
  (enabledMethods, disabledMethods) =>
    enabledMethods.length === 1 && disabledMethods.length === 0,
);

export const onlyMethodAvailable = createSelector(
  withdrawalEnabledMethodsSelector,
  hasOnlyOneMethodAvailableSelector,
  (enabledMethods, hasOnlyOneMethodAvailable) =>
    hasOnlyOneMethodAvailable ? enabledMethods[0] : null,
);

/** Make in room withrawal */

export const inRoomWithdrawalResultSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.inRoomWithdrawResult;

export const makeInRoomWithdrawalRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.makeInRoomWithdraw;

/** Make transfer withrawal */

export const transferWithdrawalResultSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.transferWithdrawResult;

export const makeTransferWithdrawalRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.makeTransferWithdraw;

/** Make EWallet withrawal */

export const eWalletWithdrawalResultSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.eWalletWithdrawResult;

export const makeEWalletWithdrawalRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.makeEWalletWithdraw;

export const eWalletWithdrawalResultErrorSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.makeEWalletWithdraw.errorMessage;

export const eWalletWithdrawalResultStatusSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.makeEWalletWithdraw.status;

/** Make EWallet withrawal */

export const creditCardWithdrawResultSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.creditCardWithdrawResult;

export const makeCreditCardWithdrawalRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.withdrawal.requests.makeCreditCardWithdraw;
