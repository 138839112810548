import React, { FC, Suspense, useContext, useLayoutEffect } from 'react';

import { Icon } from '@gaming1/g1-ui';

import { HistoryContext } from '../../../routing/components/HistoryProvider/HistoryContext';
import { FullScreenFooter } from '../FullScreenFooter';
import { SuspenseLoader } from '../SuspenseLoader';

import {
  CloseButton,
  CloseLink,
  FullScreenContentDiv,
  FullScreenWrapper,
} from './styles';
import { FullScreenProps } from './types';

/**
 * Content that shows on top of the app, coming from a side of the screen
 */
export const FullScreen: FC<FullScreenProps> = ({
  children,
  bottomNavVisible = false,
  closeLocation,
  onCloseButtonClick,
  ...spaceProps
}) => {
  const { setFullscreenLayout } = useContext(HistoryContext);
  useLayoutEffect(
    () => {
      setFullscreenLayout(true, bottomNavVisible);
      return () => {
        setFullscreenLayout(false, false);
      };
    },
    // This should only run on (un)mount
    [bottomNavVisible, setFullscreenLayout],
  );

  return (
    <FullScreenWrapper data-testid="fullscreen">
      {onCloseButtonClick && (
        <CloseButton
          aria-label="Close the full screen content"
          data-testid="fullscreen-closebutton"
          onClick={onCloseButtonClick}
        >
          <Icon id="fullscreen-close" type="Cross" />
        </CloseButton>
      )}
      {!onCloseButtonClick && closeLocation && (
        <CloseLink
          aria-label="Close the full screen content"
          data-testid="fullscreen-closebutton"
          to={closeLocation}
        >
          <Icon id="fullscreen-close" type="Cross" />
        </CloseLink>
      )}
      <FullScreenContentDiv {...spaceProps}>
        <Suspense fallback={<SuspenseLoader />}>{children}</Suspense>
      </FullScreenContentDiv>
      <FullScreenFooter />
    </FullScreenWrapper>
  );
};
