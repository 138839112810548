import { shallowEqual } from 'react-redux';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import { isValidPromotionForBSGetterSelector } from '../../store/selectors/gifts/promotions';

export const useBettingSlipPromotionErrorType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const isValid = useBettingGetterSelector({
    getterSelector: isValidPromotionForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  return () => isValid;
};
