import { ScoreBoardSportId } from '../../types';

import { CounterType, TimeType } from './types';

/** Separator used to separate units of time */
export const TIME_SEPARATOR = ':';

/** Play time settings by sport id */
export const SCOREBOARD_PLAYTIMES: {
  [k in ScoreBoardSportId]?: {
    counterType: CounterType;
    timeType: TimeType;
  };
} = {
  [ScoreBoardSportId.Basketball]: {
    counterType: 'backward',
    timeType: 'remainingTime',
  },
  [ScoreBoardSportId.EBasketball]: {
    counterType: 'backward',
    timeType: 'remainingTime',
  },
  [ScoreBoardSportId.IceHockey]: {
    counterType: 'backward',
    timeType: 'remainingTime',
  },
  [ScoreBoardSportId.EIceHockey]: {
    counterType: 'backward',
    timeType: 'remainingTime',
  },
};
