import React, { useEffect, VFC } from 'react';
import { Helmet } from 'react-helmet-async';

import { useConfig } from '@gaming1/g1-config';

import { usePrivacySettings } from '../../hooks/usePrivacySettings';

// See https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-your-Hotjar-Tracking-Code
// For tracking code setup reference

interface HotjarFunction {
  (...args: unknown[]): void;
  q: unknown[];
}

type WindowWithHotjarSDK = Window & {
  _hjSettings?: {
    hjid: number; // Hotjar ID
    hjsv: number; // Hotjar version
  };
  hj?: HotjarFunction;
};
const windowWithHotjarSDK = window as WindowWithHotjarSDK;

const hotjarFunction: HotjarFunction = (...args) => {
  windowWithHotjarSDK.hj?.q.push(...args);
};

hotjarFunction.q = [];

/** Injects the Hotjar script if the id is set up in the config */
export const HotjarScriptInjector: VFC = () => {
  const {
    core: { privacySettings },
    seo: { hotjar },
  } = useConfig();

  const { getUserConsentStatusForPurpose } = usePrivacySettings();

  const hasUserAcceptedPerformanceMesure = getUserConsentStatusForPurpose(
    privacySettings.purposeIDs.performanceMeasure,
  );

  const isHotjarEnabled = !!hotjar.id && hasUserAcceptedPerformanceMesure;

  useEffect(() => {
    if (hotjar.id) {
      // Not sure this is needed but this is done in the init script so...
      windowWithHotjarSDK._hjSettings = {
        hjid: hotjar.id,
        hjsv: hotjar.version,
      };
      // Injecting the script that add events, like for gtm dataLayer
      windowWithHotjarSDK.hj = windowWithHotjarSDK.hj || hotjarFunction;
    }
  }, [hotjar]);

  const hotjarScriptPath = `https://static.hotjar.com/c/hotjar-${hotjar.id}.js?sv=${hotjar.version}`;

  return isHotjarEnabled ? (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          src: hotjarScriptPath,
          id: 'hotjar',
        },
      ]}
    />
  ) : null;
};
