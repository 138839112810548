/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:12:04 09-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetBiggestWinningsRequestDTO,
  GetBiggestWinningsResponseDTO,
  GetCircusPokerTournamentRequestDTO,
  GetCircusPokerTournamentResponseDTO,
  GetDepositStakeRatioRankingRequestDTO,
  GetDepositStakeRatioRankingResponseDTO,
  GetGameRTPRequestDTO,
  GetGameRTPResponseDTO,
  GetSpinRankingRequestDTO,
  GetSpinRankingResponseDTO,
  GetVipRankingRequestDTO,
  GetVipRankingResponseDTO,
} from '../entities';

export const getBiggestWinnings = (options: GetBiggestWinningsRequestDTO) =>
  createRequest<GetBiggestWinningsResponseDTO>(
    103,
    'GetBiggestWinnings',
    options,
  );

export const getGameRTP = (options: GetGameRTPRequestDTO) =>
  createRequest<GetGameRTPResponseDTO>(103, 'GetGameRTP', options);

export const getSpinRanking = (options: GetSpinRankingRequestDTO) =>
  createRequest<GetSpinRankingResponseDTO>(103, 'GetSpinRanking', options);

export const getDepositStakeRatioRanking = (
  options: GetDepositStakeRatioRankingRequestDTO,
) =>
  createRequest<GetDepositStakeRatioRankingResponseDTO>(
    103,
    'GetDepositStakeRatioRanking',
    options,
  );

export const getCircusPokerTournaments = (
  options: GetCircusPokerTournamentRequestDTO,
) =>
  createRequest<GetCircusPokerTournamentResponseDTO>(
    103,
    'GetCircusPokerTournaments',
    options,
  );

export const getVipRanking = (options: GetVipRankingRequestDTO) =>
  createRequest<GetVipRankingResponseDTO>(103, 'GetVipRanking', options);
