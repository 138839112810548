import styled from 'styled-components';
import { fontSize } from 'styled-system';

import { fontSizes } from '@gaming1/g1-style';
import { Icon } from '@gaming1/g1-ui';

export const ColoredIcon = styled(Icon)<{ color?: string }>`
  font-size: ${fontSizes('sm')};
  path {
    fill: ${(props) => props.color};
  }

  ${fontSize}
`;
