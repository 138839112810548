import { ApplicationWithUserState } from '../../store/types';

/** Get security pin code info */

export const getSecurityPinCodeInfoSelector = (
  state: ApplicationWithUserState,
) => state.user.multifactorAuthentication.getSecurityPinCodeInfo;

export const getSecurityPinCodeInfoRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.multifactorAuthentication.requests.getSecurityPinCodeInfo;

export const getSecurityPinCodeEnabledSelector = (
  state: ApplicationWithUserState,
) =>
  !!state.user.multifactorAuthentication.getSecurityPinCodeInfo
    ?.IsSecurityPinCodeEnabled;

/** Reset security pin code */

export const resetSecurityPinCodeSelector = (state: ApplicationWithUserState) =>
  state.user.multifactorAuthentication.resetSecurityPinCode;

export const resetSecurityPinCodeRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.multifactorAuthentication.requests.resetSecurityPinCode;

/** Change security pin code */

export const changeSecurityPinCodeSelector = (
  state: ApplicationWithUserState,
) => state.user.multifactorAuthentication.changeSecurityPinCode;

export const changeSecurityPinCodeRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.multifactorAuthentication.requests.changeSecurityPinCode;
