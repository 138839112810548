/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:36:05 30-08-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetAvailableManualBetsRequest,
  GetAvailableManualBetsResponse,
  HasAvailableManualBetRequest,
  HasAvailableManualBetResponse,
} from '../entities';

export const hasAvailableManualBet = (options: HasAvailableManualBetRequest) =>
  createRequest<HasAvailableManualBetResponse>(
    211,
    'HasAvailableManualBet',
    options,
  );

export const getAvailableManualBets = (
  options: GetAvailableManualBetsRequest,
) =>
  createRequest<GetAvailableManualBetsResponse>(
    211,
    'GetAvailableManualBets',
    options,
  );
