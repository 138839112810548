import { useSelector } from 'react-redux';

import { mobileVersionSelector } from '@gaming1/g1-core';
import { getDeployEnv, getWorkingEnv, getWrapperEnv } from '@gaming1/g1-logger';
import { Col, Row } from '@gaming1/g1-ui';

import { logger } from '../../../logger';

import { WrappedPre } from './styles';

let envString = '';

try {
  envString = JSON.stringify(process.env, null, 2);
} catch (e) {
  logger.warn('Could not stringify process.env', e);
}

export const EnvDebugger = () => {
  const { mobileBuildVersion, mobileBuildNumber } = useSelector(
    mobileVersionSelector,
  );
  return (
    <Row>
      <Col md={6}>
        Wrapper env: <WrappedPre>{getWrapperEnv()}</WrappedPre>
        Deploy env: <WrappedPre>{getDeployEnv()}</WrappedPre>
        Working env: <WrappedPre>{getWorkingEnv()}</WrappedPre>
        Package name: <WrappedPre>{process.env.REACT_APP_NAME}</WrappedPre>
        Version: <WrappedPre>{process.env.REACT_APP_VERSION}</WrappedPre>
        Git head: <WrappedPre>{process.env.REACT_APP_GIT_HEAD}</WrappedPre>
        Artifact version:{' '}
        <WrappedPre>{process.env.REACT_APP_ARTIFACT_VERSION}</WrappedPre>
        {getWrapperEnv() === 'rn' && (
          <>
            Mobile App version: {mobileBuildVersion} - {mobileBuildNumber}
          </>
        )}
      </Col>
      <Col md={6}>
        <div>
          Process.env: <WrappedPre>{envString}</WrappedPre>
        </div>
      </Col>
    </Row>
  );
};
