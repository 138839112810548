import React, { memo, VFC } from 'react';

import { useFormatOdd } from '../../../hooks';

import {
  OddContainer,
  PrevCurrentOddContainer,
  PrevOddContainer,
} from './styles';

type OddsContainerProps = {
  baseOdds: number;
  finalOdds: number;
};

const OddsContainerComponent: VFC<OddsContainerProps> = ({
  baseOdds,
  finalOdds,
}) => {
  const formattedbaseOdds = useFormatOdd(baseOdds);
  const formattedFinalOdds = useFormatOdd(finalOdds);

  if (!baseOdds) {
    return null;
  }

  const showPrevOdds = baseOdds !== finalOdds;

  return showPrevOdds ? (
    <PrevCurrentOddContainer>
      <PrevOddContainer data-testid="base-odds">
        {formattedbaseOdds}
      </PrevOddContainer>
      <OddContainer data-testid="final-odds">{formattedFinalOdds}</OddContainer>
    </PrevCurrentOddContainer>
  ) : (
    <OddContainer data-testid="final-odds">{formattedFinalOdds}</OddContainer>
  );
};

export const OddsContainer = memo(OddsContainerComponent);
