import {
  EGameFeaturePlayerHistory,
  NewShopProductType,
  OrderStatus,
} from '@gaming1/g1-requests';

import { SponsorshipHistoryRequestState } from './types';

export const freeSpinsHistoryTransactionKeys = {
  [EGameFeaturePlayerHistory.Created]: 'history.freeSpins.state.created',
  [EGameFeaturePlayerHistory.Finished]: 'history.freeSpins.state.finished',
  [EGameFeaturePlayerHistory.Validated]: 'history.freeSpins.state.validated',
  [EGameFeaturePlayerHistory.Canceled]: 'history.freeSpins.state.canceled',
};

export const shopHistoryRequestKeys = {
  [OrderStatus.Failed]: {
    [NewShopProductType.Unknown]: 'history.shop.requestState.failedUnknown',
    [NewShopProductType.Physical]: 'history.shop.requestState.failedPhysical',
    [NewShopProductType.VirtualInternal]:
      'history.shop.requestState.failedVirtualInternal',
    [NewShopProductType.VirtualExternal]:
      'history.shop.requestState.failedVirtualExternal',
  },
  [OrderStatus.PendingPayment]: {
    [NewShopProductType.Unknown]:
      'history.shop.requestState.pendingPaymentUnknown',
    [NewShopProductType.Physical]:
      'history.shop.requestState.pendingPaymentPhysical',
    [NewShopProductType.VirtualInternal]:
      'history.shop.requestState.pendingPaymentVirtualInternal',
    [NewShopProductType.VirtualExternal]:
      'history.shop.requestState.pendingPaymentVirtualExternal',
  },
  [OrderStatus.PendingDelivery]: {
    [NewShopProductType.Unknown]:
      'history.shop.requestState.pendingDeliveryUnknown',
    [NewShopProductType.Physical]:
      'history.shop.requestState.pendingDeliveryPhysical',
    [NewShopProductType.VirtualInternal]:
      'history.shop.requestState.pendingDeliveryVirtualInternal',
    [NewShopProductType.VirtualExternal]:
      'history.shop.requestState.pendingDeliveryVirtualExternal',
  },
  [OrderStatus.Delivered]: {
    [NewShopProductType.Unknown]: 'history.shop.requestState.deliveredUnknown',
    [NewShopProductType.Physical]:
      'history.shop.requestState.deliveredPhysical',
    [NewShopProductType.VirtualInternal]:
      'history.shop.requestState.deliveredVirtualInternal',
    [NewShopProductType.VirtualExternal]:
      'history.shop.requestState.deliveredVirtualExternal',
  },
  [OrderStatus.Rejected]: {
    [NewShopProductType.Unknown]: 'history.shop.requestState.rejectedUnknown',
    [NewShopProductType.Physical]: 'history.shop.requestState.rejectedPhysical',
    [NewShopProductType.VirtualInternal]:
      'history.shop.requestState.rejectedVirtualInternal',
    [NewShopProductType.VirtualExternal]:
      'history.shop.requestState.rejectedVirtualExternal',
  },
};

export const sponsorshipHistoryRequestStateKeys = {
  [SponsorshipHistoryRequestState.Pending]:
    'history.sponsorship.requestState.pending',
  [SponsorshipHistoryRequestState.Accepted]:
    'history.sponsorship.requestState.accepted',
  [SponsorshipHistoryRequestState.Acquired]:
    'history.sponsorship.requestState.acquired',
  [SponsorshipHistoryRequestState.Refused]:
    'history.sponsorship.requestState.refused',
};
