import React, { useContext, VFC } from 'react';

import { InboxContext } from '../../InboxContext';

import { RedCircle } from './styles';

/** Displays an icon when there are unread messages */
export const InboxNotification: VFC = () => {
  const { inboxContent } = useContext(InboxContext);

  return inboxContent?.meta.unread ? (
    <RedCircle data-testid="inbox-notification" />
  ) : null;
};
