import React, { ContextType, FC } from 'react';

import { BettingPublicComponentsContext } from '@gaming1/g1-betting-api';

import { PageContentHandler } from '../../../promotions/components/PageContentHandler';

const contextValue: ContextType<typeof BettingPublicComponentsContext> = {
  Promotions: PageContentHandler,
};

/** Provide the values for the BettingPublicComponentsContext in order to make
 * other feature packages access the betting global components
 */
export const BettingPublicComponentsProvider: FC = ({ children }) => (
  <BettingPublicComponentsContext.Provider value={contextValue}>
    {children}
  </BettingPublicComponentsContext.Provider>
);
