import React, { FC } from 'react';

import { PrismicContainerSlice } from '@gaming1/g1-cms';

import { PrismicRichText } from '../PrismicRichText';

import { PrismicContainerElement } from './styles';

export type PrismicContainerProps = {
  /** Prismic 'Container' slice */
  slice: PrismicContainerSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Container` slice
 * Simple flex container
 */
export const PrismicContainer: FC<PrismicContainerProps> = ({
  slice,
  slice: {
    primary: { alignment, style, width, content },
  },
  testId = 'prismic-container',
  parentIds = [],
}) => (
  <PrismicContainerElement
    alignment={alignment}
    styleName={style}
    width={width}
    data-testid={testId}
  >
    <PrismicRichText
      content={content}
      sliceId={slice.primary.id}
      parentIds={
        slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
      }
    />
  </PrismicContainerElement>
);
