/* istanbul ignore file */
import { createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!

/** Public betting routes */
export const bettingPublicRoutes = {
  betting: createRoute('/betting'),
};
