import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';

export const PotWinningContainer = styled.span`
  ${fontWeight('bold')}
  font-size: ${fontSizes('lg')};
  color: ${colors('success')};
`;

export const PrevPotWinningContainer = styled.span`
  margin-right: ${spaces('xs')};
  text-decoration: line-through;
  opacity: ${opacity('seeThrough')};
`;
