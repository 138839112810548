import { ApplicationWithBettingState } from '../store/types';
/* Get betting user settings */

export const bettingUserSettingsSelector = (
  state: ApplicationWithBettingState,
) => state.betting.settings.userSettings;

export const getBettingUserSettingsRequestStateSelector = (
  state: ApplicationWithBettingState,
) => state.betting.settings.requests.getUserPreferences;

export const updateBettingUserSettingsRequestStateSelector = (
  state: ApplicationWithBettingState,
) => state.betting.settings.requests.updateUserPreferences;
