/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       13:11:48 15-02-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetInitialializationDataRequest,
  GetInitializationDataResponse,
  GetPlayerConfigRequest,
  GetPlayerConfigResponse,
} from '../entities';

export const getInitializationData = (
  options: GetInitialializationDataRequest,
) =>
  createRequest<GetInitializationDataResponse>(
    202,
    'GetInitializationData',
    options,
  );

export const getPlayerConfig = (options: GetPlayerConfigRequest) =>
  createRequest<GetPlayerConfigResponse>(202, 'GetPlayerConfig', options);
