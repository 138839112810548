import * as t from 'io-ts';

import { EGameListStatus } from '@gaming1/g1-requests-gaming';

import { game } from '../../common/store/codecs';

/* Entities */

export const gameAttribute = t.type(
  {
    Id: t.string,
    CategoryAttributeId: t.string,
    Order: t.number,
    FriendlyName: t.string,
    TranslatedName: t.string,
    Enabled: t.boolean,
  },
  'GameAttribute',
);

export const gameAttributeCategoryAPI = t.type(
  {
    Id: t.string,
    FriendlyName: t.string,
    Order: t.number,
    TranslatedName: t.string,
    AttributeList: t.array(gameAttribute),
    Enabled: t.boolean,
  },
  'GameAttributeCategoryAPI',
);

export const gameAttributeCategory = t.type(
  { ...gameAttributeCategoryAPI.props, AttributeList: t.array(t.string) },
  'GameAttributeCategory',
);

const requiredGameCategoryProps = t.type({
  Id: t.string,
  FriendlyName: t.string,
  TranslatedName: t.string,
  Slug: t.string,
  Order: t.number,
  Group: t.string,
  GameRangeName: t.string,
  DisplayDestination: t.string,
});

export const gameCategoryAPI = t.intersection(
  [
    requiredGameCategoryProps,
    t.partial({
      AttributeCategoriesList: t.array(gameAttributeCategoryAPI),
      ChildCategories: t.array(t.any),
      IconImage: t.string,
      SeoId: t.string,
    }),
  ],
  'GameCategoryAPI',
);

export const gameCategory = t.intersection(
  [
    requiredGameCategoryProps,
    t.partial({
      AttributeCategoriesList: t.array(t.string),
      ChildCategories: t.array(t.string),
      IconImage: t.string,
      SeoId: t.string,
    }),
  ],
  'GameCategory',
);

/* Responses */

export const getPaginatedGameListSuccessfulResponseCodec = t.intersection(
  [
    t.type({
      PageNumber: t.number,
      PageSize: t.number,
      Total: t.number,
      Status: t.literal(EGameListStatus.Successful),
      List: t.array(game),
      AttributeCategoriesList: t.array(gameAttributeCategoryAPI),
    }),
    t.partial({
      GameRange: t.string,
    }),
  ],
  'GetPaginatedGameListResponse',
);

export const getGamesCategoriesReponse = t.type(
  {
    Status: t.number,
    List: t.array(gameCategoryAPI),
  },
  'GetGamesCategoriesReponse',
);

/* Normalized data */

export const gamesData = t.type(
  {
    entities: t.type({
      attributes: t.record(t.string, gameAttribute),
      attributeCategories: t.record(t.string, gameAttributeCategory),
      games: t.record(t.string, game),
    }),
    result: t.type({
      attributeCategories: t.array(t.string),
      attributes: t.array(t.string),
      games: t.array(t.string),
    }),
    meta: t.intersection([
      t.type({
        Status: t.number,
      }),
      t.partial({
        GameRange: t.string,
      }),
    ]),
    pagination: t.type({
      PageNumber: t.number,
      PageSize: t.number,
      Total: t.number,
    }),
  },
  'FormattedGamesApiData',
);

export const gamesDataWithOnlyGamesResult = t.type(
  {
    entities: t.type({
      attributes: t.record(t.string, gameAttribute),
      attributeCategories: t.record(t.string, gameAttributeCategory),
      games: t.record(t.string, game),
    }),
    result: t.type({
      games: t.array(t.string),
    }),
    meta: t.intersection([
      t.type({
        Status: t.number,
      }),
      t.partial({
        GameRange: t.string,
      }),
    ]),
    pagination: t.type({
      PageNumber: t.number,
      PageSize: t.number,
      Total: t.number,
    }),
  },
  'FormattedGamesApiData',
);

export const gamesCategoriesData = t.type(
  {
    entities: t.type({
      gamesCategories: t.record(t.string, gameCategory),
    }),
    result: t.array(t.string),
    meta: t.type({
      Status: t.number,
    }),
  },
  'GetGamesCategoriesData',
);
