import React, { FC, memo } from 'react';

import { IconType } from '@gaming1/g1-ui';

import { useOutcomeAnimation } from '../../../sportbook/hooks';

import { IndicatorIcon } from './styles';

type IndicatorIconContainerProps = {
  /** id to find ticket item */
  id: number;
};

export const IndicatorIconContainerComponent: FC<
  IndicatorIconContainerProps
> = ({ id }) => {
  const { animationClass } = useOutcomeAnimation(id);

  if (!animationClass) {
    return null;
  }
  return (
    <IndicatorIcon
      className={animationClass}
      id="oddIndicator"
      type={
        animationClass === 'increase'
          ? ('AngleUp' as IconType)
          : ('AngleDown' as IconType)
      }
    />
  );
};

export const IndicatorIconContainer = memo(IndicatorIconContainerComponent);
