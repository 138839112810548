import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { RegistrationState } from './types';

export const initialState: RegistrationState = {
  accountActivation: null,
  departmentsAndMunicipalities: null,
  provinces: null,
  registeredUsername: null,
  registrationFormFieldsErrors: null,
  registrationFormInfo: null,
  registrationInfo: null,
  requests: {
    accountActivation: generateInitialRequestState(null),
    departmentsAndMunicipalities: generateInitialRequestState(null),
    provinces: generateInitialRequestState(null),
    registration: generateInitialRequestState(null),
    registrationFormInfo: generateInitialRequestState(null),
    registrationInfo: generateInitialRequestState(null),
    validateFields: generateInitialRequestState(null),
  },
};

export const registrationReducer = (
  state = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /**
       * Get registration form info
       */
      case getType(actions.getRegistrationFormInfo.request):
        produceLoadingState(draftState.requests.registrationFormInfo);
        break;

      case getType(actions.getRegistrationFormInfo.failure):
        produceFailureState(
          draftState.requests.registrationFormInfo,
          action.payload,
        );
        break;

      case getType(actions.getRegistrationFormInfo.success):
        produceSuccessState(draftState.requests.registrationFormInfo);
        draftState.registrationFormInfo = action.payload;
        break;

      /**
       * Get provinces
       */
      case getType(actions.getProvinces.request):
        produceLoadingState(draftState.requests.provinces);
        break;

      case getType(actions.getProvinces.failure):
        produceFailureState(draftState.requests.provinces, action.payload);
        break;

      case getType(actions.getProvinces.success):
        produceSuccessState(draftState.requests.provinces);
        draftState.provinces = action.payload.Provinces;
        break;

      /**
       * Get departments and municipalities
       */
      case getType(actions.getDepartmentsAndMunicipalities.request):
        produceLoadingState(draftState.requests.departmentsAndMunicipalities);
        break;

      case getType(actions.getDepartmentsAndMunicipalities.failure):
        produceFailureState(
          draftState.requests.departmentsAndMunicipalities,
          action.payload,
        );
        break;

      case getType(actions.getDepartmentsAndMunicipalities.success):
        produceSuccessState(draftState.requests.departmentsAndMunicipalities);
        draftState.departmentsAndMunicipalities =
          action.payload.DepartmentsAndMunicipalities;
        break;

      /**
       * Validate registration fields
       */
      case getType(actions.validateRegistrationFields.request):
        produceLoadingState(draftState.requests.validateFields);
        // reset registration error
        draftState.requests.registration.errorMessage = undefined;
        draftState.registrationFormFieldsErrors = null;
        break;

      case getType(actions.validateRegistrationFields.failure):
        produceFailureState(draftState.requests.validateFields, action.payload);
        draftState.registrationFormFieldsErrors =
          action.payload.fieldsErrorMessages || null;
        break;

      case getType(actions.validateRegistrationFields.success):
        produceSuccessState(draftState.requests.validateFields);
        draftState.registrationFormFieldsErrors = null;
        break;

      /**
       * Register user
       */
      case getType(actions.registerUser.request):
        produceLoadingState(draftState.requests.registration);
        // reset registration fields validation error
        draftState.requests.validateFields.errorMessage = undefined;
        draftState.registrationFormFieldsErrors = null;
        draftState.registeredUsername = null;
        break;

      case getType(actions.registerUser.failure):
        produceFailureState(draftState.requests.registration, action.payload);
        draftState.registrationFormFieldsErrors =
          action.payload.fieldsErrorMessages || null;
        break;

      case getType(actions.registerUser.success):
        produceSuccessState(draftState.requests.registration);
        draftState.registrationFormFieldsErrors = null;
        draftState.registeredUsername = action.payload.username;
        break;

      /**
       * Get registration info
       */
      case getType(actions.getRegistrationInfo.request):
        produceLoadingState(draftState.requests.registrationInfo);
        break;

      case getType(actions.getRegistrationInfo.failure):
        produceFailureState(
          draftState.requests.registrationInfo,
          action.payload,
        );
        break;

      case getType(actions.getRegistrationInfo.success):
        produceSuccessState(draftState.requests.registrationInfo);
        draftState.registrationInfo = action.payload;
        break;

      /**
       * Activate account
       */
      case getType(actions.activateAccount.request):
        produceLoadingState(draftState.requests.accountActivation);
        break;

      case getType(actions.activateAccount.failure):
        produceFailureState(
          draftState.requests.accountActivation,
          action.payload,
        );
        break;

      case getType(actions.activateAccount.success):
        produceSuccessState(draftState.requests.accountActivation);
        draftState.accountActivation = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
