import { createSelector } from 'reselect';

import { convertWithBettingSlipString } from '../../../../common/store/helpers';
import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { RiskManagementRawData } from '../../types/riskManagement';
import {
  bettingSlipItemsGetterSelector,
  bettingSlipTypeGetterSelector,
} from '../common';
import { prepareItemGetterSelector } from '../prepare';

export const prepareForBSGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  prepareItemGetterSelector,
  bettingSlipTypeGetterSelector,
  (bettingSlipItems, preparedItem, type) =>
    (bettingSlipId: BettingSlipIdentifierType): RiskManagementRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const bettingSlipType = convertWithBettingSlipString(type(bettingSlipId));

      const preparedElements = items
        ? items.map((item) => preparedItem(bettingSlipId, item.id))
        : [];

      return {
        stake: undefined,
        bettingSlipType,
        preparedElements,
      };
    },
);
