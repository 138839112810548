import { VFC } from 'react';

import { ClubFeatureLinksProps } from '@gaming1/g1-loyalty-api';

import { ClubFeatureLinksDesktop } from './ClubFeatureLinksDesktop';
import { ClubFeatureLinksMobile } from './ClubFeatureLinksMobile';

export const ClubFeatureLinks: VFC<ClubFeatureLinksProps> = ({ layout }) =>
  layout === 'mobile' ? (
    <ClubFeatureLinksMobile />
  ) : (
    <ClubFeatureLinksDesktop />
  );
