import React, { FC, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  findDepositsRequestStateSelector,
  findDepositsSelector,
  paymentActions,
} from '@gaming1/g1-payment';
import { useRequestState } from '@gaming1/g1-store';

import { DepositWalletImg } from './styles';

type DepositWalletsImagesProps = {
  /** Is colored or is in mono by default */
  isColored?: boolean;
};

export const DepositWalletsImages: FC<DepositWalletsImagesProps> = ({
  isColored,
}) => {
  const dispatch = useDispatch();
  const findDepositInformation = useSelector(findDepositsSelector);
  const { isNotAsked } = useRequestState(findDepositsRequestStateSelector);

  useEffect(() => {
    if (isNotAsked) {
      dispatch(paymentActions.findDeposits.request());
    }
  }, [dispatch, isNotAsked]);

  const wallets = findDepositInformation?.Wallets;

  if (wallets === undefined || wallets.length === 0) {
    return null;
  }

  return (
    <>
      {wallets.map((wallet) => (
        <Fragment key={wallet.Id}>
          {(wallet.Thumbnail || wallet.MonoThumbnail) && (
            <DepositWalletImg
              src={
                isColored ? `${wallet.Thumbnail}` : `${wallet.MonoThumbnail}`
              }
              alt={wallet.Name}
              data-testid={
                isColored
                  ? `wallet-${wallet.Name}-colored`
                  : `wallet-${wallet.Name}-mono`
              }
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
