import React, { VFC } from 'react';

import { useConfig } from '@gaming1/g1-config';

import { GeolocationBanner } from '../../../geolocation/components/GeolocationBanner';
import { BlackBoxFetcher } from '../../../tracking/components/BlackBoxFetcher';
import { BtagExtractor } from '../../../tracking/components/BtagExtractor';
import { CookieMessage } from '../../../tracking/components/CookieMessage';
import { FullStory } from '../../../tracking/components/FullStory';
import { HotjarScriptInjector } from '../../../tracking/components/HotjarScriptInjector';

/**
 * This component is supposed to be rendered in the "AppLayout" of the partner
 * app
 *
 * It will render (depending on the configuration)
 *
 * - The CookieMessage that shows a message on the bottom of the webapp linked to
 *   the cookie policy and regulation
 * - The GeolocationBanner that warns the user if its geolocation is not valid
 */
export const CoreWatchers: VFC = () => {
  const { core } = useConfig();
  return (
    <>
      <BlackBoxFetcher />
      <BtagExtractor />
      <FullStory />
      <HotjarScriptInjector />
      {core.privacySettings.type === 'message' && <CookieMessage />}
      {core.geoComply.activated && <GeolocationBanner />}
    </>
  );
};
