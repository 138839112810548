import styled from 'styled-components';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';
import { Box, Heading } from '@gaming1/g1-ui';

export const PinCodeTitle = styled(Heading).attrs(() => ({ level: 'h2' }))`
  ${fontWeight('bold')};
  margin-top: 0;
  padding: 0 ${spaces('xs')};
  text-align: center;
`;

export const PinCodeError = styled(Box)`
  color: ${colors('danger')};
  flex-direction: row;
  font-size: ${fontSizes('md')};
  justify-content: center;
  padding: ${spaces('xs')};
  padding-top: ${spaces('sm')};
  text-align: center;

  &::before {
    content: '✗';
    padding-right: ${spaces('xxs')};
  }
`;

export const PinCodeLabel = styled.div`
  color: ${colors('muted')};
  font-size: ${fontSizes('md')};
  line-height: ${fontSizes('lg')};
  padding: 0 ${spaces('xs')};
`;

export const PinCodeText = styled.p`
  color: ${colors('muted')};
  font-size: ${fontSizes('md')};
  line-height: ${fontSizes('lg')};
  padding: 0 ${spaces('xs')};
  text-align: center;
`;
