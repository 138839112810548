import { ExtendedGTMEventProps } from './types';

type RawEvent = Record<string, string | number>;

export const isValidGTMEventProps = (
  event: RawEvent,
): event is ExtendedGTMEventProps =>
  typeof event.category === 'string' &&
  typeof event.action === 'string' &&
  ['undefined', 'string'].includes(typeof event.label) &&
  ['undefined', 'number'].includes(typeof event.value);

type GTMDepositEvent = {
  event: 'deposit_success';
  depositAmount: string;
  transactionCurrency?: string;
  transactionId?: string;
  bonusAmount?: string;
  transactionTotal?: string;
};

const hasStringOrUndefinedProp =
  (event: Record<string, unknown>) => (propName: string) =>
    !(propName in event) || typeof event[propName] === 'string';

export const isValidDepositGTMEventProps = (
  event: Partial<Omit<GTMDepositEvent, 'event'>> & { event?: string },
): event is GTMDepositEvent => {
  if (typeof event !== 'object') {
    return false;
  }
  if (event === null) {
    return false;
  }
  if (!('event' in event) || event.event !== 'deposit_success') {
    return false;
  }
  const hasValidProp = hasStringOrUndefinedProp(event);
  if (
    !hasValidProp('transactionCurrency') ||
    !hasValidProp('transactionId') ||
    !hasValidProp('bonusAmount') ||
    !hasValidProp('transactionTotal')
  ) {
    return false;
  }
  if (
    !hasValidProp('depositAmount') ||
    (!!event.depositAmount && parseFloat(event.depositAmount) <= 0)
  ) {
    return false;
  }
  return true;
};
