import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  sendPhoneValidationCode,
  validatePhoneCode,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { UserEpic } from '../../store/types';

import * as actions from './actions';
import * as codecs from './codecs';
import * as errorMessages from './errorMessages';

/** send Phone Validation Code */
export const sendPhoneValidationCodeEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.sendPhoneValidationCode.request)),
    switchMap(() =>
      wsAdapter.request(sendPhoneValidationCode({})).pipe(
        mapGuard(codecs.sendPhoneValidationCodeResponse),
        map(actions.sendPhoneValidationCode.success),
        catchError((err) =>
          of(
            actions.sendPhoneValidationCode.failure(
              createFailurePayload(
                err,
                errorMessages.sendPhoneValidationCodeErrorMessages,
              ),
            ),
          ),
        ),
      ),
    ),
  );

/** validate phone Code */
export const validatePhoneCodeEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.validatePhoneCode.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(validatePhoneCode(payload)).pipe(
        mapGuard(codecs.validatePhoneCodeResponse),
        map(actions.validatePhoneCode.success),
        catchError((err) =>
          of(
            actions.validatePhoneCode.failure(
              createFailurePayload(
                err,
                errorMessages.validatePhoneCodeErrorMessages,
              ),
            ),
          ),
        ),
      ),
    ),
  );
