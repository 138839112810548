import { createContext } from 'react';

import { CustomTheme } from './types';

export const CustomThemesContext = createContext<{
  refreshThemes: () => void;
  themes: CustomTheme[];
}>({
  refreshThemes: () => undefined,
  themes: [],
});
