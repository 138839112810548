import * as t from 'io-ts';

import {
  AddressDeliveryInformationErrors,
  EmailDeliveryInformationErrors,
  ENotAllowedReason,
  EProductCategory,
  EProductType,
  EShopProductAvailableStatus,
  EShopScratchCardPurchaseResponseStatus,
  GetAvailableTicketsStatus,
  InRoomDeliveryInformationErrors,
  PhoneNumberDeliveryInformationErrors,
  ShopPaymentError,
  ShopResponseCode,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

import { shopProduct } from '../common/codecs';

/* Response data */

export const getShopProductsResponse = t.intersection(
  [
    t.type({
      Status: t.literal(ShopResponseCode.Success),
      Count: t.number,
    }),
    t.partial({
      Products: t.array(shopProduct),
    }),
  ],
  'GetShopProductsResponse',
);

export const ticket = t.intersection(
  [
    t.type({
      TicketId: t.string,
    }),
    t.partial({
      TicketName: t.string,
      TicketImageUrl: t.string,
    }),
  ],
  'Ticket',
);

export const getAvailableTicketsResponse = t.type(
  {
    AvailableTickets: t.array(ticket),
    Status: t.literal(GetAvailableTicketsStatus.Successful),
  },
  'GetAvailableTicketsResponse',
);

export const shopPurchaseResponse = t.intersection(
  [
    t.type({
      Status: t.literal(ShopResponseCode.Success),
      ProductCategory: createEnumType<EProductCategory>(EProductCategory),
    }),
    t.partial({
      ProductCode: t.string,
      RedeemUrl: t.string,
      RedeemUrlWithParameters: t.string,
      ShopOrderId: t.string,
    }),
  ],
  'ShopPurchaseResponse',
);
export const shopCategory = t.intersection(
  [
    t.type({
      Sort: t.number,
      ProductCount: t.number,
    }),
    t.partial({
      Id: t.string,
      Name: t.string,
      Slug: t.string,
    }),
  ],
  'ShopCategory',
);

export const shopCategoriesResponse = t.intersection(
  [
    t.type({ Status: t.literal(ShopResponseCode.Success) }),
    t.partial({ Categories: t.array(shopCategory) }),
  ],
  'ShopPurchaseResponse',
);

/* Normalized data */

export const productsData = t.type(
  {
    entities: t.type({
      products: t.record(t.string, shopProduct),
    }),
    result: t.array(t.string),
  },
  'ProductsData',
);

export const paginatedProductsData = t.type(
  {
    entities: t.partial({
      products: t.record(t.string, shopProduct),
    }),
    result: t.array(t.string),
    pagination: t.type({
      PageNumber: t.number,
      PageSize: t.number,
      Total: t.number,
    }),
  },
  'PaginatedProductsData',
);

export const getUnavailableProductsResponse = t.intersection(
  [
    t.type({ Status: t.literal(EShopProductAvailableStatus.Successful) }),
    t.partial({
      UnavailableProducts: t.array(createEnumType<EProductType>(EProductType)),
    }),
  ],
  'GetUnavailableProductsResponse',
);

export const purchaseScratchCardResponse = t.type(
  {
    TransactionId: t.string,
  },
  'PurchaseScratchCardResponse',
);

export const paymentError = t.type(
  {
    Error: t.array(createEnumType<ShopPaymentError>(ShopPaymentError)),
  },
  'PaymentError',
);

export const notAllowed = t.type(
  {
    Reason: t.array(createEnumType<ENotAllowedReason>(ENotAllowedReason)),
  },
  'PaymentError',
);

export const addressDeliveryInfoError = t.type(
  {
    Error: t.array(
      createEnumType<AddressDeliveryInformationErrors>(
        AddressDeliveryInformationErrors,
      ),
    ),
  },
  'AddressDeliveryInfoError',
);

export const defaultAddressDeliveryInfoError = t.type(
  {
    Error: t.array(
      createEnumType<AddressDeliveryInformationErrors>(
        AddressDeliveryInformationErrors,
      ),
    ),
  },
  'DefaultAddressDeliveryInfoError',
);

export const emailDeliveryInfoError = t.type(
  {
    Error: t.array(
      createEnumType<EmailDeliveryInformationErrors>(
        EmailDeliveryInformationErrors,
      ),
    ),
  },
  'EmailDeliveryInfoError',
);

export const inRoomDeliveryInfoError = t.type(
  {
    Error: t.array(
      createEnumType<InRoomDeliveryInformationErrors>(
        InRoomDeliveryInformationErrors,
      ),
    ),
  },
  'InRoomDeliveryInfoError',
);

export const phoneNumberDeliveryInfoError = t.type(
  {
    Error: t.array(
      createEnumType<PhoneNumberDeliveryInformationErrors>(
        PhoneNumberDeliveryInformationErrors,
      ),
    ),
  },
  'PhoneNumberDeliveryInfoError',
);

export const purchaseResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EShopScratchCardPurchaseResponseStatus.Success),
    }),
    t.partial({
      ScratchCardPurchased: purchaseScratchCardResponse,
      PaymentError: paymentError,
      NotAllowed: notAllowed,
    }),
  ],
  'PurchaseResponse',
);

export const getScratchcardIdentifierResponse = t.type(
  {
    ScratchCardId: t.string,
  },
  'GetScratchcardIdentifierResponse',
);
