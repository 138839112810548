import { GamingConfig } from '../types/gaming';

/**
 * Gaming default configuration
 */
export const defaultGamingConfig: GamingConfig = {
  defaultCategoryTypeForGameCategoriesMenu: 'all',
  enabled: true,
  maxPatternRepetition: 10,
  shouldDisplayFiltersOnGameListByCategory: false,
  shouldDisplayTitleOnGameListByCategory: false,
};
