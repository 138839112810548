import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  GetScratchCardPrizeInfoRequestDTO,
  PlayVipWheelRequestDTO,
  ScratchCardConsumeRequest,
  ScratchCardDetailsRequest,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetPlayerMonthlyBoxInfoResponse,
  GetPlayerVipRankInfoResponse,
  GetPlayerWheelInfoResponse,
  GetScratchcardDetailsResponse,
  GetScratchCardPrizeInfoResponse,
  GetVipInitializationResponse,
  PlayMonthlyBoxResponse,
  PlayVipWheelResponse,
  ScratchCardConsumeResponse,
} from './types';

/** Get vip data to start games */
export const getVipInitialization = createAsyncAction(
  'loyalty/get_vip_initialization_request',
  'loyalty/get_vip_initialization_success',
  'loyalty/get_vip_initialization_failure',
)<void, GetVipInitializationResponse, FailurePayload>();

/** Play the wheel to get the prize */
export const playVipWheel = createAsyncAction(
  'loyalty/play_vip_wheel_request',
  'loyalty/play_vip_wheel_success',
  'loyalty/play_vip_wheel_failure',
)<PlayVipWheelRequestDTO, PlayVipWheelResponse, FailurePayload>();

/** Open the giftbox to get the prize */
export const playMonthlyBox = createAsyncAction(
  'loyalty/play_monthly_box_request',
  'loyalty/play_monthly_box_success',
  'loyalty/play_monthly_box_failure',
)<void, PlayMonthlyBoxResponse, FailurePayload>();

/** Wheels status for buttons */
export const getPlayerWheelInfo = createAsyncAction(
  'loyalty/get_player_wheel_info_request',
  'loyalty/get_player_wheel_info_success',
  'loyalty/get_player_wheel_info_failure',
)<void, GetPlayerWheelInfoResponse, FailurePayload>();

/** Giftbox status for buttons */
export const getPlayerMonthlyBoxInfo = createAsyncAction(
  'loyalty/get_player_monthly_box_info_request',
  'loyalty/get_player_monthly_box_info_success',
  'loyalty/get_player_monthly_box_info_failure',
)<void, GetPlayerMonthlyBoxInfoResponse, FailurePayload>();

/** Get player VIP rank infos */
export const getPlayerVipRankInfo = createAsyncAction(
  'loyalty/get_player_vip_rank_info_request',
  'loyalty/get_player_vip_rank_info_success',
  'loyalty/get_player_vip_rank_info_failure',
)<void, GetPlayerVipRankInfoResponse, FailurePayload>();

/** Clean the stored VIP data */
export const resetVipData = createAction('loyalty/reset_vip_data')();

/** Get scratchcard details */
export const getScratchcardDetails = createAsyncAction(
  'loyalty/get_scratchcard_details_request',
  'loyalty/get_scratchcard_details_success',
  'loyalty/get_scratchcard_details_failure',
)<ScratchCardDetailsRequest, GetScratchcardDetailsResponse, FailurePayload>();

/** Consume scratchcard */
export const consumeScratchcard = createAsyncAction(
  'loyalty/consume_scratchcard_request',
  'loyalty/consume_scratchcard_success',
  'loyalty/consume_scratchcard_failure',
)<ScratchCardConsumeRequest, ScratchCardConsumeResponse, FailurePayload>();

/** Get scratchcard prize info */
export const getScratchCardPrizeInfo = createAsyncAction(
  'loyalty/get_scratchcard_prize_info_request',
  'loyalty/get_scratchcard_prize_info_success',
  'loyalty/get_scratchcard_prize_info_failure',
)<
  GetScratchCardPrizeInfoRequestDTO,
  GetScratchCardPrizeInfoResponse,
  FailurePayload
>();
