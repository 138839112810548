import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { PendingGames } from './types';

/** Get pending games */
export const getPendingGames = createAsyncAction(
  'user/get_pending_games_request',
  'user/get_pending_games_success',
  'user/get_pending_games_failure',
)<void, PendingGames, FailurePayload>();
