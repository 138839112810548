import { MediaBreakPointNames } from '@gaming1/g1-style';

export const widthDrawerByMedia: { [k in MediaBreakPointNames]: string } = {
  xs: '100%',
  sm: '80%',
  md: '60%',
  lg: '50%',
  xl: '50%',
  xxl: '50%',
};

export const widthBettingSlipDrawerByMedia: {
  [k in MediaBreakPointNames]: string;
} = {
  xs: widthDrawerByMedia.xs,
  sm: '360px',
  md: '360px',
  lg: '360px',
  xl: '360px',
  xxl: '360px',
};
