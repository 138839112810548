import React, { FC } from 'react';

import { PrismicRichTextElement } from '@gaming1/g1-cms';

import { PrismicRichText } from '../PrismicRichText';

export type PrismicTitleProps = {
  clearMargins?: boolean;
  title: PrismicRichTextElement[];
  testId?: string;
};

/**
 * This component renders a title
 */

export const PrismicTitle: FC<PrismicTitleProps> = ({
  title,
  testId,
  clearMargins = false,
}) => (
  <div data-testid={testId}>
    <PrismicRichText
      content={title}
      disableInclusion
      clearMargins={clearMargins}
    />
  </div>
);
