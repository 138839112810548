import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { BettingActivity, EventType } from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import {
  eventSelector,
  marketSelector,
} from '../../../sportbook/store/selectors';
import { ApplicationWithBettingState } from '../../../store/types';
import { haveItems } from '../../helpers';
import { RoundingConfig } from '../types/common';
import { BettingSlipUnitState } from '../types/store';

import { getInitDataRequestResultGetterSelector } from './getInitData/getInitData';
import { convertStakeToFloat } from './helpers';

/** The whole state of bettingSlip */
export const bettingSlipsStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.bettingSlip;

export const bettingSlipKeysGetterSelector = createSelector(
  bettingSlipsStateSelector,
  (bettingSlipState) =>
    memoize((): string[] => Object.keys(bettingSlipState.bettingSlips)),
);

/**
 * Get the state of a precise bettingSlip, based on its id
 * Don't forget to use shallowEqual with this selector
 */
export const bettingSlipGetterSelector = createSelector(
  bettingSlipsStateSelector,
  (bettingSlipState) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
      ): BettingSlipUnitState | undefined =>
        bettingSlipState.bettingSlips[bettingSlipId],
    ),
);
export const haveBettingSlipGetterSelector = createSelector(
  bettingSlipsStateSelector,
  (bettingSlipState) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType): boolean =>
        !!bettingSlipState.bettingSlips[bettingSlipId],
    ),
);

/**
 * Get all items (check and uncheck) into bettingSlip
 * Don't forget to use shallowEqual with this selector
 */
export const bettingSlipAllItemsGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingSlip) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const bettingslip = bettingSlip(bettingSlipId);
      if (!haveItems(bettingslip)) {
        return undefined;
      }
      return bettingslip?.items;
    }),
);

/** Get all check items into bettingSlip */
export const bettingSlipItemsGetterSelector = createSelector(
  bettingSlipAllItemsGetterSelector,
  (bettingSlipAllItems) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) =>
      bettingSlipAllItems(bettingSlipId)?.filter((item) => item.checked),
    ),
);

/*
 * Configuration
 */
export const bettingSlipTypeGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingslip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        bettingslip(bettingSlipId)?.configuration.type || 'combi',
    ),
);
export const bettingSlipActivityGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingslip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        bettingslip(bettingSlipId)?.configuration.activity ||
        BettingActivity.Sportbook,
    ),
);

export const bettingSlipExtraInfoGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingslip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        bettingslip(bettingSlipId)?.extra,
    ),
);

export const bettingSlipRoundingConfigGetterSelector = createSelector(
  bettingSlipTypeGetterSelector,
  getInitDataRequestResultGetterSelector,
  (type, getInitDataResult) =>
    memoize((bettingSlipId: BettingSlipIdentifierType): RoundingConfig => {
      const initData = getInitDataResult();

      return {
        bettingSlipType: type(bettingSlipId),
        rounding: initData?.RoundingProcessors || [],
      };
    }),
);

/* selected freebet */
export const freebetSelectedForBSGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingSlip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        bettingSlip(bettingSlipId)?.selectedFreebet,
    ),
);

/* selected boostus */
export const boostusSelectedForBSGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingSlip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        bettingSlip(bettingSlipId)?.selectedBoostus,
    ),
);
/* selected promotion */
export const promotionSelectedForBSGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingSlip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        bettingSlip(bettingSlipId)?.selectedPromotion,
    ),
);

/* is cashable */
/* TODO: create unit test but need to wait for API */
export const bettingSlipIsCashableGetterSelector = createSelector(
  bettingSlipGetterSelector,
  freebetSelectedForBSGetterSelector,
  boostusSelectedForBSGetterSelector,
  promotionSelectedForBSGetterSelector,
  marketSelector,
  (
    bettingSlipsItem,
    freebetSelectedForBS,
    boostusSelectedForBS,
    promotionSelectedForBS,
    marketSelectorS,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const bs = bettingSlipsItem(bettingSlipId);

      if (
        !bs ||
        freebetSelectedForBS(bettingSlipId) ||
        boostusSelectedForBS(bettingSlipId) ||
        promotionSelectedForBS(bettingSlipId)
      ) {
        return false;
      }

      return bs.items.every(
        (item) => !!marketSelectorS(item?.market.id)?.IsCashable,
      );
    }),
);

/* have live */
export const bettingSlipHaveLiveGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  eventSelector,
  (bettingSlipsItem, eventSelectorS) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const items = bettingSlipsItem(bettingSlipId);

      if (!items) {
        return false;
      }
      return items.find(
        (item) => eventSelectorS(item?.event.id)?.EventType === EventType.Live,
      );
    }),
);
/**
 * Get the BS stake
 *
 */
export const bettingSlipStakeGetterSelector = createSelector(
  bettingSlipGetterSelector,
  freebetSelectedForBSGetterSelector,
  (bettingSlip, selectedFreebet) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) =>
      selectedFreebet(bettingSlipId)
        ? selectedFreebet(bettingSlipId)?.amount.toString()
        : bettingSlip(bettingSlipId)?.stake || '',
    ),
);
export const bettingSlipStakeInFloatGetterSelector = createSelector(
  bettingSlipStakeGetterSelector,
  (bettingSlipStake) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const stake = bettingSlipStake(bettingSlipId);
      return convertStakeToFloat(stake);
    }),
);

/**
 * Get the first item inside BS
 *
 */
export const bettingSlipFirstItemGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  (bettingSlipItems) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const bettingslipItemsList = bettingSlipItems(bettingSlipId);

      if (!bettingslipItemsList || bettingslipItemsList.length === 0) {
        return undefined;
      }
      return bettingslipItemsList[0];
    }),
);

export const bettingSlipItemsSportBookIdsGetterSelector = createSelector(
  bettingSlipAllItemsGetterSelector,
  (bettingSlipItems) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const items = bettingSlipItems(bettingSlipId);

      if (!items) {
        return [];
      }
      return items.map((item) => ({
        id: item.id,
        outcomeId: item.outcome.id,
        marketId: item.market.id,
        eventId: item.event.id,
      }));
    }),
);

/** bettingSlip requests state */
export const bettingSlipRequestGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        getBettingSlip(bettingSlipId)?.requests,
    ),
);
