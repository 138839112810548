import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';
import { Box, SimpleButton } from '@gaming1/g1-ui';

export const OffersAvailableDrawerContainer = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: ${colors('backgrounds', { index: 0 })};
`;

export const OffersAvailableDrawerTitleContainer = styled(Box)`
  background-color: ${colors('backgrounds', { index: 1 })};
`;

export const CloseButton = styled(SimpleButton)``;

export const OffersAvailableCardsContainer = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
`;
