import React, { FC } from 'react';

import { Box } from '@gaming1/g1-ui';

import { StyledGiftLabel } from './styles';
import { GiftLabelType } from './types';

type GiftLabelProps = { type: GiftLabelType; name: string };

/**
 * Gift Label component will display a text in a specific design
 * @param type will decide the styling
 * @param name the text that will be styled
 * @returns a new component
 */
export const GiftLabel: FC<GiftLabelProps> = ({ type, name }) => {
  switch (type) {
    case 'optiOdds':
      return (
        <StyledGiftLabel type={type}>
          <Box>{name}</Box>
        </StyledGiftLabel>
      );

    default:
      return null;
  }
};
