import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const riskManagementReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /**
       * getRiskManagement action
       */
      case getType(actions.getRiskManagement.request): {
        const { payload } = action;
        produceLoadingState(
          draftState.bettingSlips[payload.bettingSlipId].requests
            .riskManagement,
        );

        break;
      }

      case getType(actions.getRiskManagement.success): {
        const { payload } = action;
        const { Limits, Notification, ElementNotifications } = payload;
        produceSuccessState(
          draftState.bettingSlips[payload.bettingSlipId].requests
            .riskManagement,
        );

        const { riskManagement } =
          draftState.bettingSlips[payload.bettingSlipId];
        if (riskManagement) {
          riskManagement.result = {
            Limits,
            Notification,
            ElementNotifications,
          };
        }

        break;
      }

      case getType(actions.getRiskManagement.failure): {
        const { payload } = action;
        const { Limits, Notification, ElementNotifications } = payload;
        produceFailureState(
          draftState.bettingSlips[payload.bettingSlipId].requests
            .riskManagement,
          payload,
        );

        const { riskManagement } =
          draftState.bettingSlips[payload.bettingSlipId];
        if (riskManagement) {
          riskManagement.result = {
            Limits,
            Notification,
            ElementNotifications,
          };
        }
        break;
      }

      /**
       * getSystemRiskManagement action
       */
      case getType(actions.getSystemRiskManagement.request): {
        const { payload } = action;
        produceLoadingState(
          draftState.bettingSlips[payload.bettingSlipId].requests
            .riskManagement,
        );

        break;
      }

      case getType(actions.getSystemRiskManagement.success): {
        const { payload } = action;
        const { Ranks, Notification, ElementNotifications } = payload;

        produceSuccessState(
          draftState.bettingSlips[payload.bettingSlipId].requests
            .riskManagement,
        );

        const { riskManagement } =
          draftState.bettingSlips[payload.bettingSlipId];
        if (riskManagement) {
          riskManagement.result = {
            Ranks,
            Notification,
            ElementNotifications,
          };
        }

        break;
      }

      case getType(actions.getSystemRiskManagement.failure): {
        const { payload } = action;
        const { Notification, ElementNotifications } = payload;
        produceFailureState(
          draftState.bettingSlips[payload.bettingSlipId].requests
            .riskManagement,
          payload,
        );

        const { riskManagement } =
          draftState.bettingSlips[payload.bettingSlipId];
        if (riskManagement) {
          riskManagement.result = {
            Notification,
            ElementNotifications,
          };
        }
        break;
      }
      case getType(actions.resetBettingSlip.request): {
        const { payload } = action;

        if (!payload.bettingSlipId) {
          break;
        }

        if (!draftState.bettingSlips[payload.bettingSlipId]) {
          // eslint-disable-next-line no-console
          console.warn(
            'Trying to destructure bettingSlips[bettingSlipId] but is undefined ! The slice for this bettingSlipId is not created yet',
          );
          break;
        }

        const { riskManagement } =
          draftState.bettingSlips[payload.bettingSlipId];
        riskManagement.result = {};

        break;
      }
      default: // Immer will automatically return the state
    }
  });
