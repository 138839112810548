import * as t from 'io-ts';

import { PrismicCodecs, PrismicPartialCodecs } from '../../common/codecs';

export const promotionScopeCodec = t.union(
  [
    t.literal('none'),
    t.literal('connected'),
    t.literal('not connected'),
    t.literal('depositor'),
    t.literal('non depositor'),
  ],
  'PromotionScope',
);

export const promotionCodec = t.type(
  {
    start_date: t.string,
    end_date: t.string,
    scope: promotionScopeCodec,
    image: PrismicPartialCodecs.imageItem,
    banner: PrismicPartialCodecs.responsiveImage(['Mobile']),
    type: t.union([t.string, t.null]),
    title: t.string,
    content: t.array(PrismicCodecs.richText),
    link_text: t.union([t.string, t.null]),
    link: PrismicCodecs.linkCodec,
  },
  'Promotion',
);

export const promotionDocumentCodec = t.type(
  {
    tags: t.array(t.string),
    data: promotionCodec,
    uid: t.string,
  },
  'PromotionDocument',
);

export const promotionDocumentsCodec = t.array(
  promotionDocumentCodec,
  'PromotionDocuments',
);

export const promotionsResponseCodec = t.type(
  {
    results: promotionDocumentsCodec,
    total_pages: t.number,
    total_results_size: t.number,
  },
  'PromotionsResponse',
);

export const promotionTagCodec = t.type(
  {
    enabled: t.boolean,
    name: t.string,
    slug: t.string,
    icon: t.string,
    title: t.string,
  },
  'PromotionTag',
);

export const promotionsConfigurationCodec = t.type(
  {
    tags: t.array(promotionTagCodec),
  },
  'PromotionsConfiguration',
);

export const promotionsConfigurationResponseCodec = t.type(
  {
    results: t.array(
      t.type({
        data: promotionsConfigurationCodec,
      }),
    ),
  },
  'PromotionsConfigurationResponse',
);
