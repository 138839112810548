// The doc says import * but that cause issues with setLevel
// See https://github.com/pimterry/loglevel/issues/127
import { getLogger, LogLevel } from '../loglevel';

import { getDeployEnv } from './getDeployEnv';
import { getWorkingEnv } from './getWorkingEnv';
import { getWrapperEnv } from './getWrapperEnv';

export const createLogger = (
  loggerName: string,
): ReturnType<typeof getLogger> => {
  const env = getDeployEnv();
  const logger = getLogger(loggerName);
  // BYPASS_JEST_CHECK is only useful for unit testing
  if (!process.env.BYPASS_JEST_CHECK && typeof jest !== 'undefined') {
    // Mute logger for jest tests
    logger.setLevel(LogLevel.SILENT);
  } else if (
    (process.env.DEBUG === 'true' || process.env.NODE_ENV === 'development') &&
    (getWrapperEnv() === 'rn' || getWorkingEnv() === 'react-native')
  ) {
    // Show all logs when in the RN app
    logger.setLevel(LogLevel.TRACE);
  } else if (env === 'production') {
    logger.setLevel(LogLevel.WARN);
  } else if (env === 'local') {
    logger.setLevel(LogLevel.DEBUG);
  } else {
    logger.setLevel(LogLevel.INFO);
  }
  return logger;
};
