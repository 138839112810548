import flatten from 'lodash/flatten';
import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import {
  EventType as EEventType,
  EMarketGroupDisplayType,
  EventDisplayType,
  MarketGroup,
  ShowcaseMarketDisplayType,
  TradingState,
} from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { EventState } from '../../../common/store/types';
import { NormalizedMarket, SCMGroup, ShowcaseMarketType } from '../types';

import {
  componentLeagueIdsSelector,
  eventListSelector,
  leagueSelector,
  leaguesListSelector,
} from './common';
import { componentsSelector } from './components';
import {
  eventsSelector,
  leaguesSelector,
  marketsSelector,
  playerPropsSelector,
  sportsSelector,
} from './entities';
import {
  eventDisplayTypeSelector,
  eventSelector,
  isEventAvailableSelector,
} from './events';
import { getSportsStatusSelector, sportSelector } from './sports';

/**
 * Return a function that takes an market id and returns a market
 */
export const marketSelector = createSelector(marketsSelector, (markets) =>
  memoize(
    (marketId: number, filterDeleted: boolean | null = true) =>
      markets[marketId]?.deleted && filterDeleted
        ? undefined
        : markets[marketId],
    // Cache key resolver
    (marketId: number, filterDeleted = true) => `${marketId}${!!filterDeleted}`,
  ),
);

/**
 * Return a function that takes a list of market id and returns a list of market
 * Don't forget to use shallowEqual with this selector
 */
export const marketListSelector = createSelector(marketsSelector, (markets) =>
  memoize(
    (marketIds: number[], filterDeleted: boolean | null = true) =>
      marketIds
        ?.map((marketId) =>
          markets[marketId]?.deleted && filterDeleted
            ? undefined
            : markets[marketId],
        )
        .sort(
          (a, b) =>
            (a?.Order || 0) - (b?.Order || 0) ||
            (a?.Base || 0) - (b?.Base || 0),
        ),
    // Cache key resolver
    (marketIds: number[], filterDeleted = true) =>
      `${marketIds?.join('-')}${!!filterDeleted}`,
  ),
);

/**
 * Get a list of all markets for one event id
 */
export const marketsByEventIdSelector = createSelector(
  eventSelector,
  marketsSelector,
  (getEvent, getMarkets) =>
    memoize(
      (eventId: number, isFullEvent: boolean) => {
        const event = getEvent(eventId);
        const marketIds =
          (isFullEvent ? event?.FullMarkets : event?.Markets) || [];

        return marketIds
          .map((id) => getMarkets[id])
          .filter(isNonNullable)
          .sort(
            (a, b) =>
              (a?.Order || 0) - (b?.Order || 0) ||
              (a.Base || 0) - (b.Base || 0),
          );
      },
      // Cache key resolver
      (eventId: number, isFullEvent: boolean) => `${eventId}${!!isFullEvent}`,
    ),
);

/**
 * Returns markets by scm ids (showcase market) or the default market if asked.
 * Don't forget to use shallowEqual with this selector
 */
export const marketsBySCMIdsSelector = createSelector(
  eventSelector,
  marketsByEventIdSelector,
  (getEvent, getMarkets) =>
    memoize(
      (
        eventId: number,
        scmIds: string[],
        shouldUseDefaultMarket?: boolean,
      ): (NormalizedMarket | null)[] => {
        const markets = getMarkets(eventId, false);
        const event = getEvent(eventId);

        if (shouldUseDefaultMarket) {
          const defaultMarket =
            markets.find((m) => m.BetType === event?.DefaultMarketType) || null;
          return [defaultMarket];
        }

        return scmIds.map(
          (scmId) => markets.find((m) => scmId === m.ShowcaseMarketId) || null,
        );
      },

      // Cache key resolver
      (eventId: number, scmIds: string[], shouldUseDefaultMarket?: boolean) =>
        `${eventId}${scmIds.join('-')}${shouldUseDefaultMarket}`,
    ),
);

/**
 * Get the default market from event by event Id
 */
export const getMainMarketByEventIdSelector = createSelector(
  eventSelector,
  marketsSelector,
  (getEvent, getMarkets) =>
    memoize((eventId: number) => {
      const event = getEvent(eventId);

      const defaultMarketType = event?.DefaultMarketType;
      const marketIds = event?.Markets || [];

      return marketIds
        .map((id) => getMarkets[id])
        .find((m) => m.BetType === defaultMarketType);
    }),
);

/**
 * Takes an event id and market names and returns markets
 * Don't forget to use shallowEqual with this selector
 */
export const marketsByNameSelector = createSelector(
  marketsByEventIdSelector,
  (getMarkets) =>
    memoize(
      (eventId: number, marketName: string) =>
        getMarkets(eventId, true).filter((m) => m?.MarketName === marketName),

      // Cache key resolver
      (eventId: number, marketName: string) => `${eventId}${marketName}`,
    ),
);

/**
 * Takes a player prop id(s) and returns all his player markets
 * Don't forget to use shallowEqual with this selector
 */
export const marketsByPlayerPropIdsSelector = createSelector(
  playerPropsSelector,
  marketsSelector,
  (getPlayerProps, getMarkets) =>
    memoize(
      (playerPropIds: number[]) => {
        const playerProps = playerPropIds.map((ppId) => getPlayerProps[ppId]);
        const pMarketIds = flatten(playerProps.map((pp) => pp?.Markets || []));

        const markets = pMarketIds
          .map((id) => getMarkets[id || 0])
          .filter(isNonNullable);

        return orderBy(markets, 'RowPosition');
      },

      // Cache key resolver
      (playerPropIds: number[]) => `${playerPropIds?.join('-')}`,
    ),
);

/**
 * Returns scm by id (showcase market).
 * Don't forget to use shallowEqual with this selector
 */
export const SCMByIdSelector = createSelector(sportSelector, (getSport) =>
  memoize(
    (
      scmId: string,
      sportId: number,
      eventType: EEventType,
    ): ShowcaseMarketType | null => {
      const sport = getSport(sportId);
      const allGroups =
        (eventType === EEventType.Live
          ? sport?.LiveShowcaseMarketGroups
          : sport?.PrematchShowcaseMarketGroups) || [];

      const scmGroup = allGroups?.find((group) =>
        group?.ShowcaseMarkets?.some((scm) => scm?.Id === scmId),
      );

      return (
        scmGroup?.ShowcaseMarkets?.find((scm) => scm?.Id === scmId) || null
      );
    },

    // Cache key resolver
    (scmId: string, sportId: number, eventType: EEventType) =>
      `${scmId}${sportId}${eventType}`,
  ),
);

/**
 * Returns true if market is of type OverUnder
 */
export const isMarketOverUnder = createSelector(
  SCMByIdSelector,
  eventSelector,
  leagueSelector,
  (getSCM, getEvent, getLeague) =>
    memoize(
      (market?: NormalizedMarket): boolean => {
        const event = market ? getEvent(market.EventId) : undefined;
        const league = getLeague(event?.LeagueId || 0);
        const scm = getSCM(
          market?.ShowcaseMarketId || '',
          league?.SportId || 0,
          event?.EventType || EEventType.Prematch,
        );

        return scm?.DisplayType === ShowcaseMarketDisplayType.OverUnder;
      },

      // Cache key resolver
      (market?: NormalizedMarket) => `${market?.MarketId}`,
    ),
);

/**
 * Takes a market and returns true if outcomes must be reversed
 */
export const isMarketReversibleSelector = createSelector(
  eventDisplayTypeSelector,
  isMarketOverUnder,
  (getDisplayType, isOverUnder) =>
    memoize(
      (market: NormalizedMarket) => {
        // Do not reverse if OverUnder because order is important
        if (isOverUnder(market)) {
          return false;
        }

        const displayType = getDisplayType(market?.EventId);
        return (
          displayType === EventDisplayType.American && market?.IsReversible
        );
      },

      // Cache key resolver
      (market: NormalizedMarket) => `${market?.MarketId}`,
    ),
);

/**
 *
 * Event => array(marketIds)
 * Market => string(ShowcaseMarketId)
 * Market => string(EventId)
 * sports => sportId => prematch/liveShowcasemarketGroup => array => showCaseMarket => array => Id
 */
export const showcaseMarketsSelector = createSelector(
  eventListSelector,
  marketListSelector,
  (getEvents, getMarkets) =>
    memoize(
      (eventsIds: number[]) => {
        const events = getEvents(eventsIds, true);
        const marketIds: number[] = uniq(
          flatten(events.map((e) => e?.Markets || [])),
        );
        const showcaseIds = getMarkets(marketIds).map(
          (m) => m?.ShowcaseMarketId,
        );

        return { scmIds: uniq(showcaseIds), markets: getMarkets(marketIds) };
      },

      // Cache key resolver
      (eventIds: number[]) => `${eventIds.join('-')}`,
    ),
);

export const getMarketsByShowCaseMarketId = createSelector(
  showcaseMarketsSelector,
  (getMarket) =>
    memoize(
      (scmIds: string[], eventIds: number[]) => {
        const { markets } = getMarket(eventIds);

        return markets.filter((market) => {
          const scmId = market?.ShowcaseMarketId || '';
          return scmIds.includes(scmId);
        });
      },

      // Cache key resolver
      (scmIds: string[], eventIds: number[]) =>
        `${scmIds.join('-')}${eventIds.join('-')}`,
    ),
);

/**
 * Returns only winner markets
 * Don't forget to use shallowEqual with this selector
 */
export const allWinnerMarketsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventListSelector,
  marketsByEventIdSelector,
  (getLeagueIds, getLeagues, getEvents, getMarkets) =>
    memoize((key: string): NormalizedMarket[] => {
      const leagues = getLeagues(getLeagueIds(key)).sort(
        (a, b) => (a?.Order || 0) - (b?.Order || 0),
      );

      const eventIds = flatten(
        leagues.map((league) =>
          getEvents(league?.Events || [])
            .filter((event) => event?.IsWinner)
            .map((e) => e?.EventId || 0),
        ),
      );

      const markets = flatten(
        eventIds.map((id) => getMarkets(id, true)),
      ).filter(isNonNullable);

      return markets;
    }),
);

export const isMarketAvailableSelector = createSelector(
  marketsSelector,
  isEventAvailableSelector,
  (marketsS, isEventAvailableS) =>
    memoize((marketId: number): boolean => {
      const market = marketsS[marketId];
      if (!market || market.State !== TradingState.Open || market.deleted) {
        return false;
      }

      return isEventAvailableS(market.EventId);
    }),
);

/** Returns true if at least one market is available */
export const hasMarketsAvailableSelector = createSelector(
  isMarketAvailableSelector,
  (getIsMarketAvailable) =>
    memoize(
      (marketIds: number[]): boolean => marketIds.some(getIsMarketAvailable),

      // Cache key resolver
      (marketIds: number[]) => `${marketIds.join('-')}`,
    ),
);

/**
 * Get one marketgroup for a specified EMarketGroupDisplayType
 */
export const marketGroupsSelector = createSelector(sportsSelector, (sportsS) =>
  memoize((sportId: number): MarketGroup[] => {
    const groups = sportsS[sportId]?.MarketGroups?.filter(isNonNullable);

    return groups?.length
      ? groups
      : ([
          {
            Key: 'Others',
            Name: 'Others',
            Order: 1000,
            DisplayType: EMarketGroupDisplayType.Standard,
          },
        ] as MarketGroup[]);
  }),
);

/**
 * Get one marketgroup for a specified EMarketGroupDisplayType
 */
export const marketGroupByTypeSelector = createSelector(
  marketGroupsSelector,
  (getMarketGroups) =>
    memoize(
      (sportId: number, type: EMarketGroupDisplayType): MarketGroup | null => {
        const groups = getMarketGroups(sportId);
        return groups.find((m) => m?.DisplayType === type) || null;
      },
      // Cache key resolver
      (sportId: number, type: EMarketGroupDisplayType) => `${sportId}${type}`,
    ),
);

/**
 * Get one marketgroup for a specified marketGroupKey
 */
export const marketGroupByKeySelector = createSelector(
  marketGroupsSelector,
  (getMarketGroups) =>
    memoize(
      (sportId: number, key: string): MarketGroup | null => {
        const groups = getMarketGroups(sportId);
        return groups.find((m) => m?.Key === key) || null;
      },
      // Cache key resolver
      (sportId: number, key: string) => `${sportId}${key}`,
    ),
);

/**
 * Get last marketgroup
 */
export const lastMarketGroupSelector = createSelector(
  marketGroupsSelector,
  (getMarketGroups) =>
    memoize((sportId: number): MarketGroup | null => {
      const groups = getMarketGroups(sportId);
      return orderBy(groups, 'Order')[groups.length - 1] || null;
    }),
);

/**
 * Get marketgroup key for a market
 */
export const marketGroupKeyForMarketSelector = createSelector(
  marketsSelector,
  lastMarketGroupSelector,
  eventsSelector,
  leaguesSelector,
  (getMarkets, getLastMarketGroup, getEvent, getLeague) =>
    memoize((marketId: number): string | null => {
      const market = getMarkets[marketId];

      if (market?.MarketGroupKey) {
        return market.MarketGroupKey;
      }

      const event = getEvent[market?.EventId];
      const league = getLeague[event?.LeagueId];
      const sportId = league?.SportId;

      return getLastMarketGroup(sportId)?.Key || null;
    }),
);

/**
 * Get marketgroup key for a player prop
 */
export const marketGroupKeyForPPropSelector = createSelector(
  playerPropsSelector,
  lastMarketGroupSelector,
  eventsSelector,
  leaguesSelector,
  (getPlayerProps, getLastMarketGroup, getEvent, getLeague) =>
    memoize((pPropId: number): string | null => {
      const pProp = getPlayerProps[pPropId];

      if (pProp?.MarketGroupKey) {
        return pProp.MarketGroupKey;
      }

      const event = getEvent[pProp?.EventId];
      const league = getLeague[event?.LeagueId];
      const sportId = league?.SportId;

      return getLastMarketGroup(sportId)?.Key || null;
    }),
);

/**
 * Returns a function that takes an event id and returns market group names list
 * Don't forget to use shallowEqual with this selector
 */
export const marketGroupKeysByEventSelector = createSelector(
  marketsByEventIdSelector,
  eventsSelector,
  leaguesSelector,
  marketGroupKeyForMarketSelector,
  marketGroupKeyForPPropSelector,
  marketGroupByKeySelector,
  getSportsStatusSelector,
  (
    getMarkets,
    getEvent,
    getLeague,
    getMarketGroupKey,
    getPPropMarketGroupKey,
    getMarketGroup,
    sportsStatus,
  ) =>
    memoize((eventId: number): string[] => {
      const event = getEvent[eventId];
      const league = getLeague[event?.LeagueId];

      if (!event || !league || sportsStatus !== 'Success') {
        return [];
      }

      const marketGroupKeys = uniq(
        getMarkets(eventId, true)
          .map((m) => getMarketGroupKey(m?.MarketId))
          .filter(isNonNullable),
      );
      const marketGroupKeyPProps = uniq(
        event?.PlayerProps?.map((ppId) => getPPropMarketGroupKey(ppId)),
      )?.filter(isNonNullable);

      const keys = uniq([...marketGroupKeys, ...(marketGroupKeyPProps || [])]);

      return orderBy(
        keys
          .map((key) => getMarketGroup(league.SportId, key))
          .filter(isNonNullable),
        'Order',
      ).map((group) => group?.Key || '');
    }),
);

/**
 * Returns a function that takes an event id and returns markets by group names
 * Don't use this selector in components
 */
const groupedMarketsByGroupSelector = createSelector(
  marketsByEventIdSelector,
  (getMarkets) =>
    memoize((eventId: number) =>
      getMarkets(eventId, true).reduce<{ [gName: string]: NormalizedMarket[] }>(
        (acc, cur) => {
          if (!cur || !cur.MarketGroupKey) {
            return acc;
          }

          const gName = cur.MarketGroupKey;
          const arr = acc[gName] || [];
          acc[gName] = [...arr, cur];
          return acc;
        },
        {},
      ),
    ),
);

/**
 * Takes an event id and a group name and returns market names
 * Don't forget to use shallowEqual with this selector
 */
export const marketNamesByGroupSelector = createSelector(
  groupedMarketsByGroupSelector,
  (groupsS) =>
    memoize(
      (eventId: number, groupName: string) => {
        const groups = groupsS(eventId)[groupName];

        return uniq(groups?.map((m) => m?.MarketName).filter(isNonNullable));
      },

      // Cache key resolver
      (eventId: number, groupName: string) => `${eventId}${groupName}`,
    ),
);

/**
 * Count markets by group names
 */
export const countMarketsByGroupsSelector = createSelector(
  groupedMarketsByGroupSelector,
  eventsSelector,
  playerPropsSelector,
  marketGroupKeyForPPropSelector,
  (groupsS, getEvents, getPlayerProps, getPPropMarketGroupKey) =>
    memoize(
      (eventId: number, groupNames: string[]) => {
        const playerPropIds = getEvents[eventId]?.PlayerProps || [];
        const playerProps = playerPropIds.map((ppId) => getPlayerProps[ppId]);
        const marketCounts = groupNames.reduce((count, curGroup) => {
          const marketCount = groupsS(eventId)[curGroup]?.length || 0;
          const playerPropsCount = playerProps.reduce((acc, pp) => {
            const isCurGroup =
              getPPropMarketGroupKey(pp?.PlayerPropId) === curGroup;
            const totalCount = isCurGroup
              ? acc + (pp?.Markets?.length || 0)
              : acc;

            return totalCount;
          }, 0);
          return count + marketCount + playerPropsCount;
        }, 0);

        return marketCounts;
      },

      // Cache key resolver
      (eventId: number, groupNames: string[]) =>
        `${eventId}${groupNames.join('-')}`,
    ),
);

/**
 * Return all market names for an event id and a list of market group names
 * Don't forget to use shallowEqual with this selector
 */
export const allMarketNamesSelector = createSelector(
  marketNamesByGroupSelector,
  eventsSelector,
  playerPropsSelector,
  marketGroupKeyForPPropSelector,
  (getMarketNames, getEvents, getPlayerProps, getPPropMarketGroupKey) =>
    memoize(
      (eventId: number, marketGroups: string[]) => {
        const event = getEvents[eventId];
        const pProps = event?.PlayerProps?.map((ppId) => getPlayerProps[ppId]);

        return uniq(
          marketGroups
            .map((groupKey) => {
              const pPropNames =
                pProps
                  ?.filter(
                    (pp) =>
                      getPPropMarketGroupKey(pp?.PlayerPropId) === groupKey,
                  )
                  .map((pp) => pp.PlayerPropName) || [];

              const marketNames = getMarketNames(eventId, groupKey);

              return uniq(
                [...pPropNames, ...marketNames].filter(isNonNullable),
              );
            })
            .reduce((prev, cur) => [...prev, ...cur], []),
        );
      },
      // Cache key resolver
      (eventId: number, marketGroups: string[]) =>
        `${eventId}${marketGroups.join('-')}`,
    ),
);

/**
 * Return a list of market names / ids for an event id and a list of market group names
 * Don't forget to use shallowEqual with this selector
 */
export const allMarketNameIdsSelector = createSelector(
  allMarketNamesSelector,
  marketsByNameSelector,
  eventsSelector,
  playerPropsSelector,
  (getMarketNames, getMarkets, getEvents, getPlayerProps) =>
    memoize(
      (eventId: number, marketGroups: string[]) => {
        const names = getMarketNames(eventId, marketGroups);

        const playerPropIds = getEvents[eventId]?.PlayerProps || [];
        const playerProps = playerPropIds.map((ppId) => getPlayerProps[ppId]);

        return names.reduce<{ name: string; ids: number[] }[]>((acc, name) => {
          // Get player props by name (Cannot use playerPropsByMarketNameSelector from here)
          const pPropIds = playerProps
            .filter((pp) => pp?.PlayerPropName === name)
            .map((p) => p.PlayerPropId);

          const markets = getMarkets(eventId, name);
          const hasBase = markets.some((m) => m.Base != null);
          const ids = [...pPropIds, ...markets.map((m) => m.MarketId)];

          const output = hasBase
            ? [{ name, ids }]
            : ids.map((id) => ({ name, ids: [id] }));

          return [...acc, ...output];
        }, []);
      },

      // Cache key resolver
      (eventId: number, marketGroups: string[]) =>
        `${eventId}${marketGroups.join('-')}`,
    ),
);

/**
 * Returns all scm groups (showcase market groups) for a specified sport and event type
 * Don't forget to use shallowEqual with this selector
 */
export const scmGroupsSelector = createSelector(sportSelector, (getSport) =>
  memoize(
    (sportId: number, eventType: EventState) => {
      const sport = getSport(sportId);
      const allScm =
        (eventType === 'live'
          ? sport?.LiveShowcaseMarketGroups
          : sport?.PrematchShowcaseMarketGroups) || [];

      return allScm.map((scm) => ({
        sportId,
        eventType,
        order: scm?.Order || 0,
        name: scm?.DisplayName || '',
      }));
    }, // Cache key resolver
    (sportId: number, eventType: EventState) => `${sportId}${eventType}`,
  ),
);

/**
 * Returns scm by group (showcase market).
 * Don't forget to use shallowEqual with this selector
 */
export const SCMByGroupSelector = createSelector(sportSelector, (getSport) =>
  memoize(
    (scmGroup: SCMGroup): (ShowcaseMarketType | null | undefined)[] => {
      const sport = getSport(scmGroup?.sportId);
      const allScm =
        (scmGroup?.type === 'live'
          ? sport?.LiveShowcaseMarketGroups
          : sport?.PrematchShowcaseMarketGroups) || [];

      return orderBy(
        allScm.find((item) => item?.DisplayName === scmGroup?.name)
          ?.ShowcaseMarkets || [],
        'Order',
      );
    },

    // Cache key resolver
    (scmGroup: SCMGroup) =>
      `${scmGroup.sportId}${scmGroup.name}${scmGroup.type}`,
  ),
);

/**
 * Returns scm groups filtered if markets are available
 * Sorts scm groups by order and puts them at the end if order is zero
 */
export const filteredScmGroupsSelector = createSelector(
  sportsSelector,
  showcaseMarketsSelector,
  (getSport, getShowcasesMarkets) =>
    memoize(
      (sportId: number, type: EventState, eventIds: number[]) => {
        const showcaseMarketIds = getShowcasesMarkets(eventIds).scmIds;
        const showcaseMarketGroups =
          (type === 'live'
            ? getSport[sportId]?.LiveShowcaseMarketGroups
            : getSport[sportId]?.PrematchShowcaseMarketGroups) || [];

        return showcaseMarketGroups
          .filter((sm) => {
            const isShowcaseAvailable =
              sm?.ShowcaseMarkets &&
              sm.ShowcaseMarkets.some((scm) =>
                showcaseMarketIds.includes(scm?.Id),
              );
            return isShowcaseAvailable;
          })
          .sort((a, b) => {
            if (!a || a?.Order === 0) {
              return 1;
            }
            if (!b || b?.Order === 0) {
              return -1;
            }

            return a.Order - b.Order;
          })
          .map((scm) => ({
            sportId,
            type,
            name: scm?.DisplayName || '',
          }));
      },
      // Cache key resolver
      (sportId: number, type: EventState, eventIds: number[]) =>
        `${sportId}${type}${eventIds.join('-')}`,
    ),
);

export const hasSportShowcaseMarketsSelector = createSelector(
  sportsSelector,
  (getSport) =>
    memoize(
      (sportId: number, type: EventState) => {
        const showcaseMarketGroups =
          (type === 'live'
            ? getSport[sportId]?.LiveShowcaseMarketGroups
            : getSport[sportId]?.PrematchShowcaseMarketGroups) || [];
        return showcaseMarketGroups.length > 0;
      },
      // Cache key resolver
      (sportId: number, type: EventState) => `${sportId}${type}`,
    ),
);

export const componentSelectedSCMGroupSelector = createSelector(
  componentsSelector,
  (components) =>
    memoize(
      (key: string, id: number, type: EventState) =>
        (components[key]?.selectedSCMGroups[type] || {})[id] || [],

      // Cache key resolver
      (key: string, id: number, type: EventState) => `${key}${id}${type}`,
    ),
);

export const hasComponentSelectedSCMGroupsSelector = createSelector(
  componentsSelector,
  (components) =>
    memoize(
      (key: string, type: EventState) =>
        !!Object.keys((components[key]?.selectedSCMGroups || {})[type] || {})
          .length,

      // Cache key resolver
      (key: string, type: EventState) => `${key}${type}`,
    ),
);

export const componentSelectedSCMGroupsSelector = createSelector(
  componentsSelector,
  (components) =>
    memoize(
      (key: string, type: EventState) =>
        (components[key]?.selectedSCMGroups || {})[type] || {},
      // Cache key resolver
      (key: string, type: EventState) => `${key}${type}`,
    ),
);

export const hasComponentUsSCMsSelector = createSelector(
  componentsSelector,
  SCMByGroupSelector,
  (components, getSCMs) =>
    memoize(
      (key: string, type: EventState, leagueId?: number) => {
        const groups = (components[key]?.selectedSCMGroups || {})[type] || {};
        const values = leagueId ? [groups[leagueId]] : Object.values(groups);

        return values.some((groupsArr) =>
          groupsArr?.some((group) => getSCMs(group).length === 3),
        );
      },

      // Cache key resolver
      (key: string, type: EventState, leagueId?: number) =>
        `${key}${type}${leagueId}`,
    ),
);

/**
 * Returns selected scmGroup (showcase market group).
 * Don't forget to use shallowEqual with this selector
 */
export const selectedSCMGroupSelector = createSelector(
  componentSelectedSCMGroupSelector,
  (getSelectedSCMGroup) =>
    memoize(
      (key: string, id: number, count: number, type: EventState) =>
        getSelectedSCMGroup(key, id, type).slice(0, count),

      // Cache key resolver
      (key: string, id: number, count: number, type: EventState) =>
        `${key}${id}${count}${type}`,
    ),
);

/**
 * Returns all current scm (showcase market) related with selected SCMGroups in dropdowns
 * Don't forget to use shallowEqual with this selector
 */
export const SCMsByGroupsSelector = createSelector(
  SCMByGroupSelector,
  selectedSCMGroupSelector,
  (getSCMByGroup, getSCMGroups) =>
    memoize(
      (
        key: string,
        id: number,
        count: number,
        type: EventState,
      ): (ShowcaseMarketType | undefined | null)[][] => {
        const scmGroups = getSCMGroups(key, id, count, type);

        const groups = scmGroups.map((val) => getSCMByGroup(val));

        return [...groups, ...range(count - groups.length).map(() => [])];
      },

      // Cache key resolver
      (key: string, id: number, count: number, type: EventState) =>
        `${key}${id}${count}${type}`,
    ),
);
