import React, { useContext, useEffect, useState, VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { useScroll } from '@gaming1/g1-utils';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';
import { Image } from '../Image';

import { FooterContext } from './FooterContext';
import { ScrollTopButtonContainer } from './styles';

const VERTICAL_SCROLL_THRESHOLD_IN_PX = 500;

export const ScrollTopButton: VFC = () => {
  const { position } = useContext(FooterContext);
  const { t } = useTranslation('core');
  const getAssetPath = useGetCoreAssetPath();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isVisible, setIsVisible] = useState(false);
  const { y: scrollY = window.scrollY } = useScroll({ debounceDelay: 50 });

  useEffect(() => {
    if (scrollY > VERTICAL_SCROLL_THRESHOLD_IN_PX) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [scrollY]);

  return position === 'drawerNav' ? null : (
    <ScrollTopButtonContainer
      data-testid="footer-scroll-top-button"
      onClick={scrollTop}
      visible={isVisible}
    >
      <Image
        url={getAssetPath('backToTheTopArrow')}
        id="footer-scrolltop"
        alt={t('alt.backToTheTop')}
        width="50%"
        height="50%"
      />
    </ScrollTopButtonContainer>
  );
};
