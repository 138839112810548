import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../store/types';

import * as actions from './actions';
import { PromotionsState } from './types';

export const initialState: PromotionsState = {
  result: {
    hasAvailablePromotions: false,
    relatedIds: {},
    availablePromotions: {
      Notification: {
        Level: NotificationLevel.Unknown,
        Code: NotificationCode.Unknown,
      },
    },
  },
  requests: {
    hasAvailablePromotions: generateInitialRequestState(),
    getAvailablePromotions: generateInitialRequestState(),
  },
};

export const promotionsReducer = (
  state: PromotionsState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getAvailablePromotions.request):
        produceLoadingState(draftState.requests.getAvailablePromotions);
        break;

      case getType(actions.getAvailablePromotions.failure):
        produceFailureState(
          draftState.requests.getAvailablePromotions,
          action.payload,
        );
        break;

      case getType(actions.getAvailablePromotions.success):
        produceSuccessState(draftState.requests.getAvailablePromotions);
        draftState.result.availablePromotions = action.payload;
        break;

      case getType(actions.hasAvailablePromotions.request):
        produceLoadingState(draftState.requests.hasAvailablePromotions);
        break;

      case getType(actions.hasAvailablePromotions.failure):
        produceFailureState(
          draftState.requests.hasAvailablePromotions,
          action.payload,
        );
        break;

      case getType(actions.hasAvailablePromotions.success):
        produceSuccessState(draftState.requests.hasAvailablePromotions);
        draftState.result.hasAvailablePromotions = action.payload.IsAvailable;

        draftState.result.relatedIds = {
          RelatedEventIds: action.payload.RelatedIds?.RelatedEventIds || [],
          RelatedLeagueIds: action.payload.RelatedIds?.RelatedLeagueIds || [],
          RelatedSportIds: action.payload.RelatedIds?.RelatedSportIds || [],
        };
        break;

      default: // Immer will automatically return the state
    }
  });
