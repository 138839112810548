import { reduceReducers } from '@gaming1/g1-store';

import { BettingActions } from '../../store/types';
import { BetPipeStateSlice } from '../types';

import { betPipeInitReducer } from './init';
import { betPipeRequestsReducer } from './requests';
import { betPipeStatusReducer } from './status';

// This reducer uses reduceReducers instead of combineReducer, meaning every
// "sub" reducer will be able to work with a valid state for any given betPipeId
export const betPipeReducer = reduceReducers<BetPipeStateSlice, BettingActions>(
  {},
  betPipeInitReducer,
  betPipeStatusReducer,
  betPipeRequestsReducer,
);
