import format from 'date-fns/format';
import { useEffect, useState } from 'react';

import { useNetwork, useNetworkState } from '@gaming1/g1-core';
import {
  EDataPacketDirection,
  WebsocketDebugStoreItem,
} from '@gaming1/g1-network';
import {
  Button,
  Col,
  Heading,
  Icon,
  Row,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';

import { stringifyRequestContent } from '../../helpers';
import { RequestContent } from '../styles';

import { packetDirectionNames } from './helpers';
import { useFilterByDirection } from './hooks/useFilterByDirection';
import { useFilterByPacketType } from './hooks/useFilterByPacketType';
import { useRecurringPacketsFilter } from './hooks/useFilterByRecurringPackets';
import { useRequestNameFilter } from './hooks/useFilterByRequestName';
import {
  FilterContainer,
  FiltersContainer,
  FiltersContainerCollapsible,
  TableData,
  TableRow,
  TableWrapper,
} from './styles';

/** Allow to read every packets sent through the web socket, in both directions
 * */
export const NetworkDebugger = () => {
  const { wsAdapter } = useNetwork();
  const [networkStore, setNetworkStore] = useState<WebsocketDebugStoreItem[]>(
    [],
  );
  const [highlightedPackets, setHighlightedPackets] = useState<string[]>([]);
  const [selectedPacketId, setSelectedPacketId] = useState<string | null>(null);
  const [shouldReverseOrder, setShouldReverseOrder] = useState(false);

  const { component: nameComponent, filter: filterByName } =
    useRequestNameFilter(networkStore);

  const { component: recurringComponent, filter: filterRecurring } =
    useRecurringPacketsFilter(networkStore);

  const { component: packetTypeComponent, filter: filterByType } =
    useFilterByPacketType(networkStore);

  const { component: directionComponent, filter: filterByDirection } =
    useFilterByDirection(networkStore);

  const networkState = useNetworkState();

  useEffect(() => {
    const debugStoreSub = wsAdapter.debugStore$.subscribe({
      next: (item) => {
        setNetworkStore((items) => [...items, item]);
      },
    });

    return () => {
      debugStoreSub.unsubscribe();
    };
  }, [wsAdapter.debugStore$]);

  const getResponseDelay = (packetId: string, time: number) => {
    const request = networkStore.find(
      ({ id, direction }) =>
        id === packetId && direction === EDataPacketDirection.Request,
    );
    return request ? time - request.time : null;
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <Heading level="h1">Network Debugging</Heading>
        </Col>
        <Col md={6} alignItems="flex-end">
          Web Socket state: {networkState.replace('websocket/', '')}
        </Col>
      </Row>
      <FiltersContainerCollapsible
        hoverTitle="Filters"
        mainContent={
          <Heading level="h3" mt="none">
            Filters
          </Heading>
        }
        mainStyle={{ p: 'xxs' }}
        expandedContent={
          <FiltersContainer>
            <Row mb="2">
              <Col md={6} justifyContent="center">
                <FilterContainer>{directionComponent}</FilterContainer>
              </Col>
              <Col md={3} justifyContent="center">
                <FilterContainer>{packetTypeComponent}</FilterContainer>
              </Col>
              <Col md={3} justifyContent="center" flexDirection="row">
                <FilterContainer>{recurringComponent}</FilterContainer>
                <Button ml="sm" py="xxs" onClick={() => setNetworkStore([])}>
                  Clear table
                </Button>
              </Col>
            </Row>
            <FilterContainer>{nameComponent}</FilterContainer>
          </FiltersContainer>
        }
      />
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>
                Time{' '}
                <SimpleButton
                  onClick={() =>
                    setShouldReverseOrder((previousValue) => !previousValue)
                  }
                >
                  <Icon
                    id="network-debugging-order"
                    type={shouldReverseOrder ? 'AngleUp' : 'AngleDown'}
                  />
                </SimpleButton>
              </th>
              <th>ID</th>
              <th>Direction</th>
              <th>Type</th>
              <th>Name</th>
              <th>&nbsp;</th>
              <th>Content</th>
            </tr>
          </thead>
          <tbody>
            {networkStore
              .filter((item) =>
                [
                  filterByName,
                  filterRecurring,
                  filterByType,
                  filterByDirection,
                ].every((filterFunc) => filterFunc(item)),
              )
              .sort((packetA, packetB) =>
                shouldReverseOrder
                  ? packetB.time - packetA.time
                  : packetA.time - packetB.time,
              )
              .map(({ content, direction, name, type, id, injected, time }) => {
                const uniqId = `${id}${direction}${time}`;
                const isHighLighted = highlightedPackets.indexOf(uniqId) !== -1;
                const isEmpty =
                  !content ||
                  (typeof content === 'object' &&
                    content !== null &&
                    Object.keys(content).length === 0);
                const shouldBeTruncated = !isHighLighted && !isEmpty;
                return (
                  <TableRow
                    direction={direction}
                    key={uniqId}
                    isSelected={selectedPacketId === id}
                  >
                    <TableData>
                      {format(time, 'HH:mm:ss')}
                      {direction === EDataPacketDirection.Response &&
                        ` (${getResponseDelay(id, time)}ms)`}
                    </TableData>
                    <TableData>
                      <SimpleButton
                        onClick={() =>
                          setSelectedPacketId((currentlySelectedId) =>
                            currentlySelectedId === id ? null : id,
                          )
                        }
                      >
                        {id}
                      </SimpleButton>
                    </TableData>
                    <TableData>
                      {packetDirectionNames[direction]}
                      {injected ? ' (injected)' : ''}
                    </TableData>
                    <TableData>{type}</TableData>
                    <TableData>{name}</TableData>
                    <TableData>
                      {!isEmpty && (
                        <SimpleButton
                          type="button"
                          onClick={() =>
                            setHighlightedPackets((previousValue) =>
                              isHighLighted
                                ? previousValue.filter((hid) => hid !== uniqId)
                                : [...previousValue, uniqId],
                            )
                          }
                        >
                          {isHighLighted ? '🔼' : '🔽'}
                        </SimpleButton>
                      )}
                    </TableData>
                    <TableData>
                      <RequestContent shouldBeTruncated={shouldBeTruncated}>
                        {stringifyRequestContent(content, !shouldBeTruncated)}
                      </RequestContent>
                    </TableData>
                  </TableRow>
                );
              })}
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};
