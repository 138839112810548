import { createContext } from 'react';

import { AssetsContext } from '@gaming1/g1-core-web';

export const LoyaltyAssetsContext = createContext<AssetsContext>({
  /* Shop */
  earnedCoins: '',
  /* Scratchcards */
  scratchcardModalError: '',
  scratchcardModalLose: '',
  scratchcardBadgeCherry: '',
  scratchcardBadgeCrown: '',
  scratchcardBadgeDice: '',
  scratchcardBadgeGiftbox: '',
  scratchcardSliderSprite: '',
  scratchcardWinNumbers: '',
});
