import React, { FC, useContext } from 'react';
import { Form } from 'react-final-form';

import { NetworkConfig, OverwriteConfig } from '@gaming1/g1-config';
import { TextInputField } from '@gaming1/g1-core-web';

import { SwitchContext } from '../SwitchProvider/contexts';

import { editableNetworkConfigFieldsList } from './constants';
import {
  EditableNetworkConfigButton,
  EditableNetworkConfigButtonWrapper,
} from './styles';

type EditableNetworkConfigFieldsType = {
  onChange: () => void;
};

export const EditableNetworkConfigFields: FC<
  EditableNetworkConfigFieldsType
> = ({ onChange }) => {
  const [switchState, switchDispatch] = useContext(SwitchContext);

  const handleReset = () => {
    switchDispatch({
      type: 'switch/network_config',
      payload: null,
    });

    onChange();
  };

  return (
    <Form<OverwriteConfig>
      initialValues={switchState.networkConfig || {}}
      onSubmit={(values) => {
        switchDispatch({
          type: 'switch/network_config',
          payload: values,
        });

        onChange();
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {editableNetworkConfigFieldsList.map((field: keyof NetworkConfig) => (
            <TextInputField
              key={`editable-network-${field}-field`}
              name={field}
              label={field}
              testId={`editable-network-${field}-input`}
              htmlName={field}
            />
          ))}

          <EditableNetworkConfigButtonWrapper>
            <EditableNetworkConfigButton type="submit">
              Ok
            </EditableNetworkConfigButton>
            <EditableNetworkConfigButton
              fontSize="sm"
              color="white"
              type="tertiary"
              onClick={handleReset}
            >
              Reset
            </EditableNetworkConfigButton>
          </EditableNetworkConfigButtonWrapper>
        </form>
      )}
    />
  );
};
