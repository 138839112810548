import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { PrismicPageContentQueryResponse } from './types';

/**
 * Action to fetch mutiple page content.
 */
export const fetchPageContentDocument = createAsyncAction(
  'prismic/fetch_page_documents_request',
  'prismic/fetch_page_documents_success',
  'prismic/fetch_page_documents_failure',
)<
  string,
  { data: PrismicPageContentQueryResponse; request: string },
  { error: FailurePayload; request: string }
>();

/**
 * Action for fecth only one page content
 */
export const fetchSinglePageContentDocument = createAsyncAction(
  'prismic/fetch_single_page_document_request',
  'prismic/fetch_single_page_document_success',
  'prismic/fetch_single_page_document_failure',
)<
  string,
  { data: PrismicPageContentQueryResponse; request: string },
  { error: FailurePayload; request: string }
>();
