import {
  ContextType,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet, HelmetTags } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import {
  appInitializedSelector,
  checkIsCachedGeolocationValidRequestStateSelector,
  connectionToGeoComplyRequestStateSelector,
  coreActions,
  currentGeolocationSelector,
  decryptGeolocationRequestStateSelector,
  geoComplyLicenseKeyRequestStateSelector,
  GeolocationReason,
  geolocationRequestStateSelector,
  userIdSelector,
  userLoggedInSelector,
  userLoginRequestStateSelector,
} from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { useRequestState } from '@gaming1/g1-store';
import { RemoteData, useRequestCallback } from '@gaming1/g1-utils';

import { logger } from '../../../logger';
import { windowWithGeoComplySDK } from '../../types';

import { GeolocationContext } from './GeolocationContext';
import { useGeoComplyConnection } from './useGeoComplyConnection';
import { useGeolocationToasts } from './useGeolocationToasts';
import { useGetLicense } from './useGetLicense';
import { useRequestGeolocation } from './useRequestGeolocation';

const geoComplyScriptPath = `${process.env.REACT_APP_BASE_NAME}geocomply/geocomply-client.min.js?v=${process.env.REACT_APP_GIT_HEAD}`;
const GC_SCRIPT_ID = 'geocomply-script';

/**
 * Component that manages to fetch GeoComply's license, store it
 * and init the connection to GeoComply's PLC.
 *
 * Player Location Check is an extension installed on one's computer that our application
 * will question in order to get the current user's precise geolocation
 */
export const GeolocationProvider: FC = memo(({ children }) => {
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const userId = useSelector(userIdSelector);
  const currentGeolocation = useSelector(currentGeolocationSelector);
  const { status: geoComplyLicenseRequestStatus } = useRequestState(
    geoComplyLicenseKeyRequestStateSelector,
  );
  const { status: decryptGeolocationRequestStatus } = useRequestState(
    decryptGeolocationRequestStateSelector,
  );
  const { errorCode: geolocationRequestErrorCode } = useRequestState(
    geolocationRequestStateSelector,
  );
  const { status: checkIsCachedGeolocationValidRequestStatus } =
    useRequestState(checkIsCachedGeolocationValidRequestStateSelector);
  const { status: connectionToGeoComplyRequestState } = useRequestState(
    connectionToGeoComplyRequestStateSelector,
  );
  const {
    core: {
      geoComply: { activated: isGeoComplyActivated },
    },
  } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [latestGeolocationReason, setLatestGeolocationReason] =
    useState<GeolocationReason | null>(null);
  const appInitialized = useSelector(appInitializedSelector);
  const { status: loginRequestStatus } = useRequestState(
    userLoginRequestStateSelector,
  );

  // Those refs are used so that callbacks always have the most up-to-date value
  // from the store.
  const userIdRef = useRef(userId);
  userIdRef.current = userId;
  const isUserLoggedInRef = useRef(isUserLoggedIn);
  isUserLoggedInRef.current = isUserLoggedIn;

  /** Will return true if the user is currently logged in and his ID is known. Can be used inside callbacks since it will use the latest information from the store */
  const getIsUserIdentified = useCallback(
    () => !!userIdRef.current && isUserLoggedInRef.current,
    [],
  );

  useGeoComplyConnection(isScriptLoaded);

  useGetLicense(geoComplyLicenseRequestStatus);

  useGeolocationToasts();

  const requestGeolocation = useRequestGeolocation({
    onReasonUpdate: setLatestGeolocationReason,
  });

  const isGeoComplyReady =
    connectionToGeoComplyRequestState === RemoteData.Success;

  const handleScriptInject = ({ scriptTags }: HelmetTags) => {
    if (scriptTags && scriptTags.length) {
      const gcScript = scriptTags.find(
        (scriptTag) => scriptTag.id === GC_SCRIPT_ID,
      );

      if (gcScript) {
        gcScript.onload = () => {
          setIsScriptLoaded(true);
        };
      }
    }
  };

  const geoComplySDK = windowWithGeoComplySDK.GeoComply;

  const retryLatestGeolocationRequest = useCallback(() => {
    if (latestGeolocationReason) {
      logger.info(
        '[Geolocation] Asked to retry latest geolocation with latest geolocation parameters being : ',
        latestGeolocationReason,
      );
      requestGeolocation(latestGeolocationReason);
    } else {
      logger.warn(
        '[Geolocation] Asked to retry latest geolocation but no previous parameters were found.  Asking a new geolocation with "SessionStart" reason',
      );
      requestGeolocation(GeolocationReason.SessionStart);
    }
  }, [latestGeolocationReason, requestGeolocation]);

  /**
   * This hook will be triggered whenever :
   *
   * - GeoComply License key was successfully fetched,
   * - GeoComply request status is set to "License expired",
   * - Mandatory geolocation request parameters are set
   *
   * It means this hook will be triggered if a geolocation request was made and the license has expired.
   */
  useRequestCallback(geoComplyLicenseRequestStatus, () => {
    if (
      geolocationRequestErrorCode ===
        geoComplySDK?.Client.CLNT_ERROR_LICENSE_EXPIRED &&
      latestGeolocationReason
    ) {
      logger.info(
        `[Geolocation] geolocation request failed, GeoComply's License was renewed.  Attempting a new geolocation request`,
      );
      retryLatestGeolocationRequest();
    }
  });

  /**
   * Attempt a new geolocation request every X seconds,
   * whenever the currentGeolocation.ShouldGeoLocateBeforeTime property changes.
   */
  useEffect(() => {
    let requestGeolocationTimeout: NodeJS.Timeout;

    if (
      currentGeolocation &&
      currentGeolocation.IsValid &&
      getIsUserIdentified()
    ) {
      const timeInSeconds =
        currentGeolocation.ShouldGeoLocateInSeconds >= 0
          ? currentGeolocation.ShouldGeoLocateInSeconds
          : 0;
      logger.info(
        `[Geolocation] Should request geolocation in ${timeInSeconds} seconds`,
      );

      requestGeolocationTimeout = setTimeout(() => {
        if (getIsUserIdentified()) {
          requestGeolocation(GeolocationReason.SessionIntervalGeo);
        } else {
          logger.info(
            '[Geolocation] Automatic geolocation after interval was cancelled because the used is logged out',
          );
        }
      }, timeInSeconds * 1000);
    }

    return () => {
      if (requestGeolocationTimeout) {
        clearTimeout(requestGeolocationTimeout);
      }
    };
  }, [currentGeolocation, getIsUserIdentified, requestGeolocation]);

  /**
   * That hook makes sure a new geolocation check is made after
   * the WS connection is restored if the user is logged in.
   *
   * The fact that we check that checkIsCachedGeolocationValidRequestStatus !== RemoteData.NotAsked
   * ensures that the check won't be called on the first login.
   */
  useRequestCallback(loginRequestStatus, () => {
    if (
      appInitialized &&
      getIsUserIdentified() &&
      checkIsCachedGeolocationValidRequestStatus !== RemoteData.NotAsked
    ) {
      dispatch(coreActions.checkIsCachedGeolocationValid.request());
      logger.info(
        '[Geolocation] Check geolocation again after WS connection was restored',
      );
    }
  });

  /**
   * Whenever :
   * - The geolocation check at auth goes from loading to successful;
   * - The currentGeolocation response exists;
   * - The currentGeolocation response is not valid;
   *
   * Then trigger a geolocation request with a 'Session Start' Reason.
   *
   * Immediately trigger a new geolocation request when isGeoComplyLocationValid request failed.
   */
  useRequestCallback(
    checkIsCachedGeolocationValidRequestStatus,
    () => {
      if (
        currentGeolocation &&
        !currentGeolocation.IsValid &&
        getIsUserIdentified()
      ) {
        logger.info(
          '[Geolocation] [Request] because of the checkIsCachedGeolocation returning invalid data',
        );
        requestGeolocation(GeolocationReason.SessionStart);
      }
    },
    () => {
      if (getIsUserIdentified()) {
        logger.info(
          '[Geolocation] [Request] because of the checkIsCachedGeolocation returning an error',
        );
        requestGeolocation(GeolocationReason.SessionStart);
      }
    },
  );

  /**
   * Immediately trigger a new geolocation request when decryptGeolocation request failed.
   */
  useRequestCallback(
    decryptGeolocationRequestStatus,
    () => {},
    () => {
      if (getIsUserIdentified()) {
        requestGeolocation(GeolocationReason.SessionIntervalGeo);
      }
    },
  );

  const geolocationContextValue: ContextType<typeof GeolocationContext> =
    useMemo(
      () => ({
        requestGeolocation,
        isGeoComplyReady,
        retryLatestGeolocationRequest,
      }),
      [isGeoComplyReady, requestGeolocation, retryLatestGeolocationRequest],
    );

  return (
    <>
      {getWrapperEnv() !== 'rn' && isGeoComplyActivated && (
        <Helmet
          onChangeClientState={(_, addedTags) => handleScriptInject(addedTags)}
        >
          <script
            id="geocomply-script"
            data-testid="geocomply-script"
            type="text/javascript"
            src={geoComplyScriptPath}
            defer
          />
        </Helmet>
      )}

      <GeolocationContext.Provider value={geolocationContextValue}>
        {children}
      </GeolocationContext.Provider>
    </>
  );
});
