import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { NotificationCode } from '@gaming1/g1-requests-betting';
import { isNonNullable, RemoteData } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../store/types';

import {
  bettingHistoriesClosedSort,
  bettingHistoriesRunningSort,
} from './helpers';

const bettingHistoryRequestStateSelector = (
  state: ApplicationWithBettingState,
) => state.betting.history.requests;

const bettingHistoryStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.history;

const bettingHistoryFromBettingSlipStateSelector = (
  state: ApplicationWithBettingState,
) => state.betting.history.fromBettingSlip;

/* History requets state */
export const getBettingHistoryRequestStateSelector = (
  state: ApplicationWithBettingState,
) => bettingHistoryRequestStateSelector(state).getBettingHistory;

export const bettingHistoryIsLoadingGetterSelector = createSelector(
  bettingHistoryRequestStateSelector,
  (request) =>
    memoize(() => request.getBettingHistory.status === RemoteData.Loading),
);
export const bettingHistoryOnErrorGetterSelector = createSelector(
  bettingHistoryStateSelector,
  bettingHistoryRequestStateSelector,
  (history, request) =>
    memoize((): boolean => {
      if (request.getBettingHistory.status === RemoteData.Error) {
        return true;
      }
      // TODO: check notification code
      if (
        history.list.histories?.Notification &&
        history.list.histories.Notification.Code !==
          NotificationCode.BettingHistory_Success
      ) {
        return true;
      }
      return false;
    }),
);
export const bettingHistorySuccessGetterSelector = createSelector(
  bettingHistoryStateSelector,
  bettingHistoryRequestStateSelector,
  (history, request) =>
    memoize((): boolean => {
      if (request.getBettingHistory.status !== RemoteData.Success) {
        return false;
      }
      // TODO: check notification code
      if (
        history.list.histories?.Notification &&
        history.list.histories.Notification.Code ===
          NotificationCode.BettingHistory_Success
      ) {
        return true;
      }
      return false;
    }),
);

export const bettingSlipSystemDetailIsLoadingGetterSelector = createSelector(
  bettingHistoryRequestStateSelector,
  (request) =>
    memoize(
      (bettingHistoryId: string) =>
        request.getBettingSlipSystemDetail[bettingHistoryId] &&
        request.getBettingSlipSystemDetail[bettingHistoryId].status ===
          RemoteData.Loading,
    ),
);
export const bettingSlipSystemDetailOnErrorGetterSelector = createSelector(
  bettingHistoryStateSelector,
  bettingHistoryRequestStateSelector,
  (history, request) =>
    memoize((bettingHistoryId: string) => {
      if (
        !request.getBettingSlipSystemDetail[bettingHistoryId] ||
        request.getBettingSlipSystemDetail[bettingHistoryId].status ===
          RemoteData.Error
      ) {
        return true;
      }
      if (
        history.details[bettingHistoryId]?.Notification &&
        history.details[bettingHistoryId]?.Notification?.Code !==
          NotificationCode.BettingHistorySystemDetails_Success
      ) {
        return true;
      }
      return false;
    }),
);
export const bettingSlipSystemDetailSuccessGetterSelector = createSelector(
  bettingHistoryStateSelector,
  bettingHistoryRequestStateSelector,
  (history, request) =>
    memoize((bettingHistoryId: string) => {
      if (
        !request.getBettingSlipSystemDetail[bettingHistoryId] ||
        request.getBettingSlipSystemDetail[bettingHistoryId].status !==
          RemoteData.Success
      ) {
        return false;
      }
      if (
        history.details[bettingHistoryId]?.Notification &&
        history.details[bettingHistoryId]?.Notification?.Code ===
          NotificationCode.BettingHistorySystemDetails_Success
      ) {
        return true;
      }
      return false;
    }),
);

/* History data */
export const bettingHistoryGetterSelector = createSelector(
  bettingHistoryStateSelector,
  bettingHistorySuccessGetterSelector,
  (history, isSuccess) =>
    memoize(() => {
      if (!isSuccess()) {
        return [];
      }
      if (!history.list.histories?.BettingHistory) {
        return [];
      }

      const compFct =
        history.list.type === 'open'
          ? bettingHistoriesRunningSort
          : bettingHistoriesClosedSort;
      return compFct(
        history.list.histories.BettingHistory.map((item) => item) || [],
      );
    }),
);
export const bettingHistoryByIdGetterSelector = createSelector(
  bettingHistoryGetterSelector,
  bettingHistoryFromBettingSlipStateSelector,
  (history, fromBettingSlip) =>
    memoize(
      (id: string) =>
        history()?.find((item) => item?.Id === id) || fromBettingSlip[id],
    ),
);

export const bettingHistoryElementsGetterSelector = createSelector(
  bettingHistoryByIdGetterSelector,
  (history) =>
    memoize((id: string) => history(id)?.Elements?.filter(isNonNullable)),
);

export const bettingHistoryElementGetterSelector = createSelector(
  bettingHistoryByIdGetterSelector,
  (history) =>
    memoize(
      (id: string, outcomeId: number) =>
        history(id)
          ?.Elements?.filter(isNonNullable)
          ?.find((item) => item.OutcomeId === outcomeId),
      (id: string, outcomeId) => `${id}-${outcomeId}`,
    ),
);

export const bettingHistoryFirstElementGetterSelector = createSelector(
  bettingHistoryElementsGetterSelector,
  (list) =>
    memoize((id: string) => {
      const elements = list(id);

      return elements && elements.length > 0 ? elements[0] : null;
    }),
);

export const bettingHistoryGetNumberOfOpenedBetsGetterSelector = memoize(
  createSelector(
    bettingHistoryStateSelector,
    (history) => history.numberOfBetsOpened,
  ),
);

export const bettingHistoryNotificationGetterSelector = createSelector(
  bettingHistoryStateSelector,
  (history) => memoize(() => history.list.histories?.Notification),
);

export const bettingSlipSystemDetailGetterSelector = createSelector(
  bettingHistoryStateSelector,
  bettingSlipSystemDetailSuccessGetterSelector,
  (history, isSuccess) =>
    memoize((bettingHistoryId: string) =>
      isSuccess(bettingHistoryId)
        ? history.details[bettingHistoryId]?.Detail?.filter(isNonNullable)
        : null,
    ),
);
