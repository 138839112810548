import React, { FC, memo } from 'react';

import {
  BettingSlipNotification,
  BettingSlipNotificationCodeCallToAction,
  BettingSlipNotificationStatusCallToAction,
} from '@gaming1/g1-betting';
import { useRoutePath, useZendeskUrl } from '@gaming1/g1-core-web';
import { gamingPublicRoutes } from '@gaming1/g1-gaming-api';
import { paymentPublicRoutes } from '@gaming1/g1-payment-api';
import { userPublicRoutes } from '@gaming1/g1-user-api';
import { isNonNullable } from '@gaming1/g1-utils';

import {
  CallDecoration,
  CallToActionAppAnchor,
  CallToActionButton,
  CallToActionLink,
} from './styles';

type BettingSlipCallToActionComponentProps = {
  notification: BettingSlipNotification;
  callToActionsFunc?: {
    updateStake?: (stake: string) => void;
    resetOdds?: () => void;
  };
};

const BettingSlipCallToActionComponent: FC<
  BettingSlipCallToActionComponentProps
> = ({ notification, callToActionsFunc, children }) => {
  const zendeskUrl = useZendeskUrl();
  const getGamingRoutePath = useRoutePath(gamingPublicRoutes);
  const getPaymentRoutePath = useRoutePath(paymentPublicRoutes);
  const getUserRoutePath = useRoutePath(userPublicRoutes);
  const action =
    notification.Status !== undefined
      ? BettingSlipNotificationStatusCallToAction[notification.Status]
      : BettingSlipNotificationCodeCallToAction[notification.Code];

  const updateStake = () => {
    if (!callToActionsFunc || !callToActionsFunc.updateStake) {
      return;
    }
    const key = action === 'setToMax' ? 'maxCurrency' : 'minCurrency';
    callToActionsFunc.updateStake(
      notification.NotificationParameters?.filter(isNonNullable).find(
        (item) => item.Key === key,
      )?.Value || '',
    );
  };

  if (
    (action === 'setToMax' || action === 'setToMin') &&
    callToActionsFunc &&
    callToActionsFunc.updateStake
  ) {
    return (
      <CallToActionButton onClick={updateStake}>
        <CallDecoration>{children}</CallDecoration>
      </CallToActionButton>
    );
  }
  if (
    action === 'dirtyOdd' &&
    callToActionsFunc &&
    callToActionsFunc.resetOdds
  ) {
    return (
      <CallToActionButton onClick={callToActionsFunc.resetOdds}>
        <CallDecoration>{children}</CallDecoration>
      </CallToActionButton>
    );
  }
  if (action === 'deposit') {
    return (
      <CallToActionLink to={getPaymentRoutePath('deposit')}>
        <CallDecoration>{children}</CallDecoration>
      </CallToActionLink>
    );
  }
  if (action === 'helpdesk') {
    return (
      <CallToActionAppAnchor href={zendeskUrl} target="_blank" rel="noopener">
        <CallDecoration>{children}</CallDecoration>
      </CallToActionAppAnchor>
    );
  }
  if (action === 'settings') {
    return (
      <CallToActionLink to={getUserRoutePath('accountSettings')}>
        <CallDecoration>{children}</CallDecoration>
      </CallToActionLink>
    );
  }
  if (action === 'pendingGames') {
    return (
      <CallToActionLink to={getGamingRoutePath('pendingGames')}>
        <CallDecoration>{children}</CallDecoration>
      </CallToActionLink>
    );
  }
  return null;
};

export const BettingSlipCallToAction = memo(BettingSlipCallToActionComponent);
