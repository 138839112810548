/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as commonActions from './actions';
import { CommonActions, CommonState } from './types';

export const initialState: CommonState = {
  oddsUnit: 'decimal',
};

export const commonReducer = (
  state: CommonState = initialState,
  action: CommonActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(commonActions.setOddsUnit):
        draftState.oddsUnit = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
