import { useEffect } from 'react';
import { filter, map, mergeMap, pluck } from 'rxjs/operators';

import { interopMessages, useNetwork } from '@gaming1/g1-core';
import { interopMessageManager, sendIframeMessage } from '@gaming1/g1-core-web';
import { Request } from '@gaming1/g1-network';

const requestNameWhitelist = ['StartTournament_V4', 'RegisterGame'];

export const GameLauncherRequestForwarder = () => {
  const { wsAdapter } = useNetwork();
  useEffect(() => {
    const subscription = interopMessageManager.networkRequest$
      .pipe(
        pluck('payload'),
        // Only allow those requests to be made
        filter((request) => requestNameWhitelist.includes(request.Identifier)),
        mergeMap((request: Request) => {
          // Copy the request original Id because wsAdapter will mutate it
          const requestId = request.Id;
          return wsAdapter
            .request(request)
            .pipe(
              map<unknown, [string, unknown]>((response) => [
                requestId,
                response,
              ]),
            );
        }),
      )
      .subscribe({
        next: ([requestId, response]) => {
          sendIframeMessage(
            interopMessages.networkResponse,
            {
              requestId,
              content: response,
            },
            {
              destination: 'rn',
            },
          );
        },
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [wsAdapter]);
  return null;
};
