import { UserField } from '@gaming1/g1-core';
import { ERegisterPlayerProperty } from '@gaming1/g1-requests';

const playerPropertyToFieldName = {
  [ERegisterPlayerProperty.AccountNumberBIC]: UserField.bic,
  [ERegisterPlayerProperty.AccountNumberIBAN]: UserField.iban,
  [ERegisterPlayerProperty.Address1]: UserField.address,
  [ERegisterPlayerProperty.Address2]: UserField.address2,
  [ERegisterPlayerProperty.BirthCity]: UserField.birthCity,
  [ERegisterPlayerProperty.BirthCountry]: UserField.birthCountry,
  [ERegisterPlayerProperty.Birthday]: UserField.birthDate,
  [ERegisterPlayerProperty.City]: UserField.city,
  [ERegisterPlayerProperty.Civility]: UserField.gender,
  [ERegisterPlayerProperty.Country]: UserField.country,
  [ERegisterPlayerProperty.Email]: UserField.email,
  [ERegisterPlayerProperty.FirstName]: UserField.firstname,
  [ERegisterPlayerProperty.FullName]: UserField.fullName,
  [ERegisterPlayerProperty.LanguageIsoCode]: UserField.locale,
  [ERegisterPlayerProperty.LastName]: UserField.lastname,
  [ERegisterPlayerProperty.Municipality]: UserField.municipality,
  [ERegisterPlayerProperty.NationalIdentityNumber]: UserField.nationalNumber,
  [ERegisterPlayerProperty.Password]: UserField.password,
  [ERegisterPlayerProperty.PhoneNumber2]: UserField.landlinePhone,
  [ERegisterPlayerProperty.PhoneNumberOrPhoneNumber2]: UserField.mobilePhone,
  [ERegisterPlayerProperty.PhoneNumber]: UserField.mobilePhone,
  [ERegisterPlayerProperty.Profession]: UserField.profession,
  [ERegisterPlayerProperty.Province]: UserField.province,
  [ERegisterPlayerProperty.Pseudo]: UserField.username,
  [ERegisterPlayerProperty.SecondFirstName]: UserField.secondFirstname,
  [ERegisterPlayerProperty.SecondLastName]: UserField.secondLastname,
  [ERegisterPlayerProperty.SsnOrTin]: UserField.ssnOrTin,
  [ERegisterPlayerProperty.States]: UserField.state,
  [ERegisterPlayerProperty.TaxIdNumber]: UserField.taxIdNumber,
  [ERegisterPlayerProperty.ZipCode]: UserField.zipCode,
  // Unused or unknown
  /*
  ERegisterPlayerProperty.None
  */
};
/*
Missing:
Company
ConditionalIdentificationDocumentInformation
DataBoxIdentificationNumber
IdentificationDocumentInformation
LegalDepositLimit
RegistrationPromoCode
Not needed?:
None
EmployeePinCode
Login
*/

const fieldNameToPlayerProperty = Object.entries(
  playerPropertyToFieldName,
).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: Number(key),
  }),
  {},
);

const isPlayerProperty = (
  prop: number,
): prop is keyof typeof playerPropertyToFieldName =>
  prop in playerPropertyToFieldName;

export const convertPlayerPropertyToFieldName = (
  playerPropertyEnum: ERegisterPlayerProperty | number,
): UserField | null =>
  isPlayerProperty(playerPropertyEnum)
    ? playerPropertyToFieldName[playerPropertyEnum]
    : null;

const isFieldName = (
  prop: string,
): prop is keyof typeof fieldNameToPlayerProperty =>
  prop in fieldNameToPlayerProperty;

export const convertFieldNameToPlayerProperty = (
  fieldNameEnum: UserField | string,
): ERegisterPlayerProperty | null =>
  isFieldName(fieldNameEnum) ? fieldNameToPlayerProperty[fieldNameEnum] : null;
