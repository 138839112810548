/* eslint-disable react/require-default-props */
import React, {
  ComponentProps,
  ComponentType,
  LazyExoticComponent,
  useMemo,
  useRef,
} from 'react';

import { lazifyComponent } from '@gaming1/g1-utils';

import { AppRoute, AppRouteProps } from '../AppRoute';
import { ProtectedRoute, ProtectedRouteProps } from '../ProtectedRoute';
import {
  RedirectableRoute,
  RedirectableRouteProps,
} from '../RedirectableRoute';

type RouteWithOmittedProps<T> = Omit<T, 'children' | 'component' | 'render'>;

export type LazyRouteProps =
  | ({ type: 'app' } & RouteWithOmittedProps<AppRouteProps>)
  | ({ type: 'protected' } & RouteWithOmittedProps<ProtectedRouteProps>)
  | ({ type: 'redirectable' } & RouteWithOmittedProps<RedirectableRouteProps>);

/** Returns a route with a lazily loaded component */
export const LazyRoute = <
  Module extends {
    [k in ExportName]: ComponentType<ComponentProps<Module[ExportName]>>;
  },
  ExportName extends keyof Module,
  Props extends ComponentProps<LazyExoticComponent<Module[ExportName]>>,
>({
  componentName,
  componentProps,
  importFactory,
  ...rest
}: LazyRouteProps & {
  /** The export name of the component */
  componentName: ExportName;
  /** The props of the component */
  componentProps?: Props;
  /** A factory returning a dynamic import of the file where the component is */
  importFactory: () => Promise<Module>;
}) => {
  const importFactoryRef = useRef(importFactory);
  importFactoryRef.current = importFactory;
  /**
   * The same lazy component must always be returned between each render. Even
   * when the factory import function changes. If we put importFactory as a
   * dependency of the useMemo, it will return a different component
   * every time the function reference changes.
   */
  const LazyComponent = useMemo(
    () => lazifyComponent(componentName, importFactoryRef.current),
    [componentName],
  );

  const routeProps = {
    render: () => <LazyComponent {...componentProps} />,
  };

  if (rest.type === 'protected') {
    return <ProtectedRoute {...rest} {...routeProps} />;
  }

  if (rest.type === 'redirectable') {
    return <RedirectableRoute {...rest} {...routeProps} />;
  }

  return <AppRoute {...rest} {...routeProps} />;
};
