import React from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { LazyRoute } from '@gaming1/g1-core-web';

import { loyaltyRoutes } from './routes';

export const getLoyaltyRouter = (locale: LocaleCode) => [
  <LazyRoute
    componentName="ShopRouter"
    importFactory={() => import('./shop/ShopRouter')}
    key="shop"
    path={`/${locale}${loyaltyRoutes.shop.path}`}
    section="shop"
    type="app"
  />,
  <LazyRoute
    componentName="GiftBoxPage"
    importFactory={() => import('./club/pages/GiftBoxPage')}
    key="giftbox"
    path={`/${locale}${loyaltyRoutes.giftbox.path}`}
    section="club"
    type="app"
  />,
];
