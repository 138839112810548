import styled, { css } from 'styled-components';

import { fontSizes } from '@gaming1/g1-style';

type RequestContentProps = { shouldBeTruncated: boolean };

export const RequestContent = styled.span<RequestContentProps>`
  font-size: ${fontSizes('sm')};
  ${({ shouldBeTruncated }) =>
    shouldBeTruncated
      ? css`
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px;
          height: 1.2em;
          display: inline-block;
        `
      : css`
          white-space: pre-wrap;
        `}
`;
