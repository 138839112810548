import * as t from 'io-ts';

import {
  ColumnReorder,
  EventType as EEventType,
  FilterType as EFilterType,
  EMarketGroupDisplayType,
  EResponseStatus,
  ESportType,
  EventDisplayType,
  Provider,
  ShowcaseMarketDisplayType,
  StreamingStatus,
  StreamingType,
  TradingState,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { scoreBoardCodec } from '../../../score/codecs';

const keyValuePairCodec = t.type(
  { Key: t.string, Value: t.string },
  'keyValuePair',
);

// We need to used this instead of t.partial because entities with empty properties have 2 different behaviours:
// - on regular requests, empty (null/undefined) propreties are omitted from the object
// - on push, empty properties exists and are set to null
// We need those codecs to works in either case
export const nullable = <C extends t.Mixed>(codec: C) =>
  t.union([codec, t.null, t.undefined]);

/* Entities */
export const lightOutcomeCodec = t.intersection([
  t.type({
    OutcomeId: t.number,
    MarketId: t.number,
    EventId: t.number,
    Name: nullable(t.string),
    Odd: t.number,
  }),
  t.partial({
    Base: nullable(t.number),
  }),
]);

export const lightMarketCodec = t.type({
  MarketId: t.number,
  EventId: t.number,
  MarketName: nullable(t.string),
  Provider: nullable(t.number),
  State: createEnumType<TradingState>(TradingState),
});

export const lightEventCodec = t.type({
  EventId: t.number,
  MarketId: t.number,
  OutcomeId: t.number,
  HomeName: nullable(t.string),
  AwayName: nullable(t.string),
});

const showcaseColumnCodec = t.type(
  {
    Index: t.number,
    Name: t.string,
  },
  'ShowcaseColumnCodec',
);

export const showcaseMarketCodec = t.intersection(
  [
    t.type({
      Id: t.string,
      Order: t.number,
    }),
    t.partial({
      Columns: nullable(t.array(showcaseColumnCodec)),
      ConfigName: nullable(t.string),
      DisplayName: nullable(t.string),
      DisplayType: createEnumType<ShowcaseMarketDisplayType>(
        ShowcaseMarketDisplayType,
      ),
    }),
  ],
  'ShowcaseMarketCodec',
);

export const marketGroupCodec = t.intersection(
  [
    t.type({
      DisplayType: createEnumType<EMarketGroupDisplayType>(
        EMarketGroupDisplayType,
      ),
      Order: t.number,
    }),
    t.partial({
      Key: t.string,
      Name: t.string,
    }),
  ],
  'MarketGroupCodec',
);

export const showcaseMarketGroupCodec = t.intersection([
  t.type({
    Order: t.number,
  }),
  t.partial({
    DisplayName: t.string,
    ShowcaseMarkets: t.array(nullable(showcaseMarketCodec)),
  }),
]);

export const GetSportsResponseCodec = t.intersection(
  [
    t.type({
      SportId: t.number,
      InNav: t.boolean,
      NavOrder: t.number,
      TopLiveOrder: t.number,
      TopPrematchOrder: t.number,
      SportType: createEnumType<ESportType>(ESportType),
    }),
    t.partial({
      SportName: t.string,
      SportAlias: t.string,
      LiveScoreTranslations: t.array(nullable(keyValuePairCodec)),
      MarketGroups: t.array(nullable(marketGroupCodec)),
      PrematchShowcaseMarketGroups: t.array(nullable(showcaseMarketGroupCodec)),
      LiveShowcaseMarketGroups: t.array(nullable(showcaseMarketGroupCodec)),
    }),
  ],
  'GetSportsResponseCodec',
);

export const sportCodec = t.intersection(
  [
    t.type({
      SportId: t.number,
      InNav: t.boolean,
      NavOrder: t.number,
      LiveOrder: t.number,
      TopLiveOrder: t.number,
      TopPrematchOrder: t.number,
      SportType: createEnumType<ESportType>(ESportType),
    }),
    t.partial({
      SportName: t.string,
      SportAlias: t.string,
      LiveScoreTranslations: t.array(nullable(keyValuePairCodec)),
      MarketGroups: t.array(nullable(marketGroupCodec)),
      PrematchShowcaseMarketGroups: t.array(nullable(showcaseMarketGroupCodec)),
      LiveShowcaseMarketGroups: t.array(nullable(showcaseMarketGroupCodec)),
    }),
  ],
  'SportCodec',
);

export const getSportsResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      Sports: t.union([t.null, t.array(sportCodec)]),
    }),
  ],
  'GetSportsResponseCodec',
);

// Also contains props from player outcome
export const outcomeCodec = t.intersection(
  [
    t.type({
      Odd: t.number,
      MarketId: t.number,
      OutcomeId: t.number,
      EventId: t.number,
    }),
    t.partial({
      Base: nullable(t.number),
      GroupOrder: t.number,
      Order: nullable(t.number),
      Name: t.string,
      ProviderProbabilities: t.number,
      ColPosition: t.number, // For "Player Outcome"
    }),
  ],
  'OutcomeCodec',
);

export const StreamingDescriptorCodec = t.intersection(
  [
    t.type({
      StreamingState: createEnumType<StreamingStatus>(StreamingStatus),
      Type: createEnumType<StreamingType>(StreamingType),
    }),
    t.partial({
      StreamId: t.string,
      ProviderName: t.string,
    }),
  ],
  'StreamingDescriptorCodec',
);

// Also contains props from player market
export const marketCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      MarketId: t.number,
      State: createEnumType<TradingState>(TradingState),
      Provider: createEnumType<Provider>(Provider),
    }),
    t.partial({
      Outcomes: t.union([t.null, t.array(outcomeCodec)]),
      PlayerOutcomes: t.union([t.null, t.array(outcomeCodec)]), // For "Player Market"
      Base: nullable(t.number),
      BetType: t.string,
      MarketGroupKey: t.string,
      MarketName: t.string,
      PlayerName: t.string, // For "Player Market"
      TeamName: t.string, // For "Player Market"
      ShowcaseMarketId: nullable(t.string),
      IsReversible: t.boolean,
      RowPosition: t.number, // For "Player Market"
      ColCount: t.number,
      Order: t.number,
      ColumnReorder: createEnumType<ColumnReorder>(ColumnReorder),
      IsCashable: t.boolean,
      HasOptiOdds: t.boolean,
    }),
  ],
  'MarketCodec',
);

export const columnDefinitionCodec = t.intersection(
  [
    t.type({
      Position: t.number,
    }),
    t.partial({
      Name: t.string,
    }),
  ],
  'ColumnDefinitionCodec',
);

export const playerOutcomeCodec = t.intersection(
  [
    t.type({
      ColPosition: t.number,
      EventId: t.number,
      MarketId: t.number,
      Odd: t.number,
      OutcomeId: t.number,
    }),
    t.partial({
      Base: t.number,
      OutcomeName: t.string,
      ProviderProbabilities: t.number,
    }),
  ],
  'PlayerOutcomeCodec',
);

export const playerMarketCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      MarketId: t.number,
      Provider: createEnumType<Provider>(Provider),
      RowPosition: t.number,
      State: createEnumType<TradingState>(TradingState),
    }),
    t.partial({
      PlayerName: t.string,
      PlayerOutcome: nullable(t.array(playerOutcomeCodec)),
      TeamName: t.string,
    }),
  ],
  'PlayerMarketCodec',
);

export const playerPropCodec = t.intersection(
  [
    t.type({
      ColCount: t.number,
      EventId: t.number,
      IsCashable: t.boolean,
      Order: t.number,
      PlayerPropId: t.number,
      Provider: createEnumType<Provider>(Provider),
    }),
    t.partial({
      BetType: t.string,
      Columns: t.union([t.null, t.array(columnDefinitionCodec)]),
      MarketGroupKey: t.string,
      Markets: t.union([t.null, t.array(marketCodec)]),
      PlayerPropName: t.string,
    }),
  ],
  'PlayerPropCodec',
);

export const eventCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      EventType: createEnumType<EEventType>(EEventType),
      IsBlocked: t.boolean,
      IsCashable: t.boolean,
      IsWinner: t.boolean,
      LeagueId: t.number,
      NbTotalMarkets: t.number,
      StartDate: t.string,
      Markets: t.union([t.null, t.array(marketCodec)]),
    }),
    t.partial({
      DefaultMarketType: nullable(t.string),
      StreamingDescriptor: nullable(t.array(StreamingDescriptorCodec)),
      HomeName: t.string,
      AwayName: t.string,
      UrlStats: t.string,
      Order: t.number,
      IsTopEvent: t.boolean,
      PlayerProps: nullable(t.array(playerPropCodec)),
    }),
  ],
  'EventCodec',
);

export const leagueCodec = t.intersection(
  [
    t.type({
      Order: t.number,
      LeagueId: t.number,
      RegionId: t.number,
      SportId: t.number,
      Events: t.union([t.null, t.array(eventCodec)]),
    }),
    t.partial({
      EventDisplayType: createEnumType<EventDisplayType>(EventDisplayType),
      LeagueName: t.string,
      RegionName: t.string,
    }),
  ],
  'LeagueCodec',
);

export const getTopEventsRequestCodec = t.intersection(
  [
    t.type({
      EventType: createEnumType<EEventType>(EEventType),
    }),
    t.partial({
      ProtocolName: t.string,
      Type: t.string,
      Name: t.string,
    }),
  ],
  'GetTopEventsRequestCodec',
);

/** Filters */
export const filterCodec = t.intersection(
  [
    t.type({
      Type: createEnumType<EFilterType>(EFilterType),
    }),
    t.partial({
      LocalDate: t.string,
    }),
  ],
  'filterCodec',
);

export const getFilterRequestCodec = t.intersection(
  [
    t.type({
      SportId: t.number,
      DiffMinUtc: t.number,
    }),
    t.partial({
      ProtocolName: t.string,
      Type: t.string,
      Name: t.string,
    }),
  ],
  'GetFilterRequestCodec',
);

export const getPrematchSportResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      Leagues: t.array(leagueCodec),
    }),
  ],
  'GetPrematchSportResponseCodec',
);

/** Search */
export const searchCompetitionCodec = t.intersection(
  [
    t.type({
      LeagueId: t.number,
      RegionId: t.number,
      SportId: t.number,
    }),
    t.partial({
      LeagueName: t.string,
      RegionName: t.string,
      SportName: t.string,
    }),
  ],
  'SearchCompetitionCodec',
);

export const searchCompetitionResultCodec = t.intersection(
  [
    t.type({
      TotalResults: t.number,
    }),
    t.partial({
      Competitions: t.array(t.union([t.null, searchCompetitionCodec])),
    }),
  ],
  'SearchCompetitionResultCodec',
);

export const searchEventResultCodec = t.intersection(
  [
    t.type({
      TotalResults: t.number,
    }),
    t.partial({
      Leagues: t.array(nullable(leagueCodec)),
    }),
  ],
  'SearchEventResultCodec',
);

export const lightSearchEventCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      EventDisplayType: createEnumType<EventDisplayType>(EventDisplayType),
      RegionId: t.number,
      LeagueId: t.number,
      SportId: t.number,
      StartDate: t.string,
      EventType: createEnumType<EEventType>(EEventType),
    }),
    t.partial({
      HomeName: t.string,
      AwayName: t.string,
      RegionName: t.string,
      LeagueName: t.string,
      SportName: t.string,
      ScoreBoard: scoreBoardCodec,
      IsWinner: t.boolean,
    }),
  ],
  'LightSearchEventCodec',
);

export const lightSearchEventResultCodec = t.intersection(
  [
    t.type({
      TotalResults: t.number,
    }),
    t.partial({
      Events: t.array(nullable(lightSearchEventCodec)),
    }),
  ],
  'LightSearchEventResultCodec',
);

export const getLighSearchRequestCodec = t.partial({
  SearchTerm: t.string,
  ProtocolName: t.string,
  Type: t.string,
  Name: t.string,
});

export const lightSearchResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      SearchTokens: t.array(t.string),
      EventResult: searchEventResultCodec,
      CompetitionResult: searchCompetitionResultCodec,
    }),
  ],
  'LightSearchResponseCodec',
);

export const getFullSearchRequestCodec = t.partial({
  SearchTerm: t.string,
  ProtocolName: t.string,
  Type: t.string,
  Name: t.string,
});

export const fullSearchResponseCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      SearchTokens: t.array(t.string),
      EventResult: searchEventResultCodec,
      CompetitionResult: searchCompetitionResultCodec,
    }),
  ],
  'FullSearchResponseCodec',
);
