import React, { FC, useContext } from 'react';

import { useBettingSlipSystemStake } from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';

import { BettingSlipContext } from '../../BettingSlipContext';

/**
 * Display the stake for an item
 * show and manage stake
 */
export const BettingSlipSystemStake: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const formatMoney = useFormatMoney();

  const stake = useBettingSlipSystemStake(bettingSlipId);

  if (!stake) {
    return null;
  }
  return (
    <span data-testid="format-money-span-system-stake">
      {formatMoney(stake)}
    </span>
  );
};
