import React, { SVGAttributes, VFC } from 'react';

import { AppAnchor } from '@gaming1/g1-ui';

import {
  FooterSocialIconWrapper,
  FooterSocialNetworkContainer,
} from './styles';

export type SocialNetworkProps = {
  /** Blog url */
  blogUrl?: string;
  /** The facebook url */
  facebookUrl?: string;
  /** The instagram url */
  instagramUrl?: string;
  /** The twitter url */
  twitterUrl?: string;
  /** The youtube url */
  youtubeUrl?: string;
};

/** Social icons */

type SVGIconProps = SVGAttributes<HTMLOrSVGElement> & {
  desc?: string;
  title?: string;
};

const BlogSvg: VFC<SVGIconProps> = ({ title, desc, ...props }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" {...props}>
    {title && <title>{title}</title>}
    {desc && <desc>{desc}</desc>}
    <path
      d="M9.5 15.6c-.1-.1-.3-.2-.5-.2-.2-.1-.4-.1-.7-.1H6.6v2.5h1.6c.3 0 .6 0 .8-.1.2-.1.4-.2.5-.3.1-.1.2-.3.3-.4.1-.2.1-.3.1-.5s0-.4-.1-.5c-.1-.2-.2-.3-.3-.4zM23.5 19.4c0 .1 0 .2.1.3.1.1.1.2.3.2.1.1.3.1.5.2.2 0 .4.1.7.1.3 0 .5 0 .7-.1.2 0 .4-.1.5-.2l.3-.3c.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.2s-.3-.1-.4-.1h-1.1c-.2 0-.4 0-.6-.1-.2.1-.3.2-.4.3-.3.2-.3.4-.3.5zM9.2 13.9c.3-.2.4-.5.4-1s-.1-.8-.4-1c-.3-.2-.7-.3-1.2-.3H6.6v2.5h1.3c.6.1 1 
      0 1.3-.2zM18.6 14c-.5 0-.9.2-1.2.5-.2.3-.4.9-.4 1.5 0 .7.1 1.2.4 1.5s.6.5 1.2.5c.5 0 .9-.2 1.1-.5.2-.3.4-.8.4-1.5s-.1-1.2-.4-1.5c-.2-.4-.6-.5-1.1-.5zM24.9 13.9c-.4 0-.6.1-.8.3-.2.2-.3.4-.3.7 0 .1 0 .3.1.4s.1.2.2.3c.1.1.2.2.3.2.1.1.3.1.5.1s.3 0 .5-.1c.1 0 .2-.1.3-.2.1-.1.2-.2.2-.3s.1-.3.1-.4c0-.3-.1-.5-.3-.7-.1-.2-.4-.3-.8-.3z"
      fillRule="evenodd"
    />
    <path
      d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm-4.8 17.6c-.1.3-.3.6-.6.8-.3.2-.6.4-1 .5-.4.1-.8.2-1.3.2H5v-8.7h3c.6 0 1.1.1 
      1.5.2.4.1.7.3 1 .5.3.2.4.4.6.7.1.3.2.6.2 1 0 .2 0 .4-.1.6-.1.2-.2.4-.3.5-.1.2-.3.3-.5.4-.2.1-.4.2-.7.3 1.2.3 1.8.9 1.8 1.9-.1.4-.1.8-.3 1.1zm3.1 1.5h-1.5v-8.9h1.5v8.9zm7.1-1.8c-.1.4-.3.7-.6 1-.3.3-.6.5-1 .6s-.8.2-1.3.2-.9-.1-1.3-.2-.7-.4-1-.6c-.3-.3-.5-.6-.6-1-.1-.4-.2-.8-.2-1.3s.1-.9.2-1.3.4-.7.6-1c.3-.3.6-.5 1-.6s.8-.2 1.3-.2.9.1 1.3.2.7.4 1 .6c.3.3.5.6.6 1 .1.4.2.8.2 1.3s0 .9-.2 1.3zm6.6-3.4c0 .1-.1.1-.2.1l-.6.1c0 .1.1.2.1.3v.4c0 .3-.1.6-.2.8-.1.2-.3.5-.5.6-.2.2-.5.3-.8.4-.3.1-.6.1-1 .1-.2 0-.5 0-.7-.1 0 .3-.1.4-.1.5 0 .1.1.2.2.3.1.1.3.1.5.1H26c.2 0 .5.1.7.1.2 0 .4.1.6.2.2.1.3.3.5.4s.2.4.2.7c0 .3-.1.5-.2.8-.1.3-.3.5-.6.7-.3.2-.6.4-.9.5s-.8.2-1.3.2-.9 0-1.2-.1c-.3-.1-.6-.2-.9-.4s-.4-.3-.5-.5c-.1-.2-.2-.4-.2-.6 0-.3.1-.5.3-.7.2-.2.4-.4.7-.5-.2-.1-.3-.2-.4-.3s-.1-.3-.1-.5v-.3c0-.1.1-.2.1-.3.1-.1.1-.2.2-.3.1-.1.2-.2.4-.2-.3-.2-.6-.4-.7-.7-.2-.3-.3-.6-.3-1 0-.3.1-.6.2-.8.1-.2.3-.5.5-.6.2-.2.5-.3.8-.4s.6-.1 1-.1c.3 0 .5 0 .7.1.2.1.4.1.6.2H28v.6c.1.1.1.2 0 .2z"
      fillRule="evenodd"
    />
  </svg>
);

const FacebookSvg: VFC<SVGIconProps> = ({ title, desc, ...props }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" {...props}>
    {title && <title>{title}</title>}
    {desc && <desc>{desc}</desc>}

    <path
      d="M20.155 10.656l-1.506.001c-1.181 0-1.41.561-1.41 1.384v1.816h2.817l-.367 2.845h-2.45V24h-2.937v-7.298h-2.456v-2.845h2.456V11.76c0-2.435 1.487-3.76 3.658-3.76 1.04 0 1.934.077 2.195.112v2.544zM16 0C7.164 0 0 7.163 0 16c0 8.836 7.164 16 16 16 8.837 0 16-7.164 16-16 0-8.837-7.163-16-16-16z"
      fillRule="evenodd"
    />
  </svg>
);

const InstagramSvg: VFC<SVGIconProps> = ({ title, desc, ...props }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" {...props}>
    {title && <title>{title}</title>}
    {desc && <desc>{desc}</desc>}
    <path
      d="M16 0c8.837 0 16 7.163 16 16 0 8.836-7.163 16-16 16S0 24.836 0 16C0 7.163 7.163 0 16 0zm6.098 8H9.902A1.9 1.9 0 008 9.897v12.205A1.9 1.9 0 
      009.902 24h12.196A1.9 1.9 0 0024 22.102V9.897A1.899 1.899 0 0022.098 8zm-10.773 6.735c-.113.409-.173.84-.173 1.284 0 2.677 2.183 4.856 4.867 4.856s4.867-2.18 4.867-4.856c0-.444-.06-.875-.173-1.284h1.497v6.958c0 .247-.2.447-.448.447H10.275a.447.447 0 01-.447-.447v-6.958zm4.694-1.786a3.073 3.073 0 013.077 3.07 3.073 3.073 0 01-3.077 3.07 3.073 3.073 0 01-3.077-3.07 3.073 3.073 0 013.077-3.07zm5.706-3.126c.247 0 .448.2.448.446v2.16a.447.447 0 01-.448.445h-2.163a.447.447 0 01-.448-.446V10.27c0-.246.2-.446.448-.446z"
      fillRule="evenodd"
    />
  </svg>
);

const TwitterSvg: VFC<SVGIconProps> = ({ title, desc, ...props }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" {...props}>
    {title && <title>{title}</title>}
    {desc && <desc>{desc}</desc>}
    <path
      d="M22.363 12.737c.006.141.01.282.01.425 0 4.337-3.302 9.34-9.34 9.34A9.294 9.294 0 018 21.026c.257.03.518.045.783.045a6.584 6.584 0 004.077-1.405 3.285 3.285 0 01-3.067-2.279 3.312 3.312 0 001.483-.057 3.283 3.283 0 01-2.633-3.218v-.042c.442.246.949.394 1.487.411A3.282 3.282 0 019.115 10.1a9.32 9.32 0 006.766 3.43 3.283 3.283 0 015.593-2.994 6.568 6.568 0 002.085-.796 3.299 3.299 0 01-1.443 1.816A6.587 6.587 0 0024 11.038a6.682 6.682 0 01-1.638 1.7M16 0C7.164 0 0 7.163 0 16c0 8.836 7.163 16 16 16s16-7.164 16-16c0-8.837-7.163-16-16-16"
      fillRule="evenodd"
    />
  </svg>
);

const YoutubeSvg: VFC<SVGIconProps> = ({ title, desc, ...props }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" {...props}>
    {title && <title>{title}</title>}
    {desc && <desc>{desc}</desc>}
    <path
      d="M16 0c8.837 0 16 7.163 16 16 0 8.836-7.163 16-16 16S0 24.836 0 16C0 7.163 7.163 0 16 0zm.003 10.373c-.006 0-3.365 0-5.604.162-.313.037-.995.04-1.603.677-.48.486-.636 1.589-.636 1.589S8 14.095 8 15.39v1.214c0 1.295.16 2.59.16 2.59s.156 1.102.636 1.588c.608.637 1.408.617 1.764.684 1.2.114 4.931.155 5.393.16h.263c.777-.005 3.485-.03 5.385-.167.313-.037.995-.04 1.603-.677.48-.486.636-1.588.636-1.588s.16-1.295.16-2.59V15.39c0-1.295-.16-2.589-.16-2.589s-.156-1.103-.636-1.589c-.608-.637-1.29-.64-1.603-.677-2.239-.162-5.598-.162-5.598-.162zm-1.656 3.206l4.324 2.256-4.323 2.24v-4.496z"
      fillRule="evenodd"
    />
  </svg>
);

/** Footer Header */

export const SocialNetwork: VFC<SocialNetworkProps> = ({
  blogUrl,
  facebookUrl,
  instagramUrl,
  twitterUrl,
  youtubeUrl,
}) => (
  <FooterSocialNetworkContainer>
    {facebookUrl && (
      <AppAnchor href={facebookUrl} target="_blank" rel="noopener">
        <FooterSocialIconWrapper>
          <FacebookSvg data-testid="footer-social-facebook" title="Facebook" />
        </FooterSocialIconWrapper>
      </AppAnchor>
    )}
    {twitterUrl && (
      <AppAnchor href={twitterUrl} target="_blank" rel="noopener">
        <FooterSocialIconWrapper>
          <TwitterSvg data-testid="footer-social-twitter" title="Twitter" />
        </FooterSocialIconWrapper>
      </AppAnchor>
    )}
    {youtubeUrl && (
      <AppAnchor href={youtubeUrl} target="_blank" rel="noopener">
        <FooterSocialIconWrapper>
          <YoutubeSvg data-testid="footer-social-youtube" title="Youtube" />
        </FooterSocialIconWrapper>
      </AppAnchor>
    )}
    {instagramUrl && (
      <AppAnchor href={instagramUrl} target="_blank" rel="noopener">
        <FooterSocialIconWrapper>
          <InstagramSvg
            data-testid="footer-social-instagram"
            title="Instagram"
          />
        </FooterSocialIconWrapper>
      </AppAnchor>
    )}
    {blogUrl && (
      <AppAnchor href={blogUrl} target="_blank" rel="noopener">
        <FooterSocialIconWrapper>
          <BlogSvg data-testid="footer-social-blog" title="Blog" />
        </FooterSocialIconWrapper>
      </AppAnchor>
    )}
  </FooterSocialNetworkContainer>
);
