import { shallowEqual, useSelector } from 'react-redux';

import { userCreditSelector } from '@gaming1/g1-core';
import {
  ManualBetType as EManualBetType,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import {
  BettingSlipNotification,
  EFrontErrorType,
} from '../../../notification/mapper/types';
import {
  bettingSlipExtraInfoGetterSelector,
  bettingSlipFirstItemGetterSelector,
  bettingSlipItemLimitsGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  freebetSelectedForBSGetterSelector,
} from '../../store/selectors';
import { getLimitStatus } from '../../store/selectors/elements/helpers';
import {
  bettingSlipBaseOddsGetterSelector,
  bettingSlipFinalOddsGettorSelector,
} from '../../store/selectors/odds';
import {
  bettingSlipFinalWinningsGettorSelector,
  bettingSlipWinningsGettorSelector,
} from '../../store/selectors/winnings';

import { useBettingSlipItemFinalOdds, useBettingSlipItemIsValid } from './item';

export const useBettingSlipSingleIsValid = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const item = useBettingGetterSelector({
    getterSelector: bettingSlipFirstItemGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const isValid = useBettingSlipItemIsValid(bettingSlipId);

  return (): boolean => {
    if (!item) {
      return false;
    }
    return isValid(item.id);
  };
};

export const useBettingSlipSingleErrorFrontType = (
  bettingSlipId: BettingSlipIdentifierType,
  type?: EManualBetType,
) => {
  const bSExtraInfo = useSelector(bettingSlipExtraInfoGetterSelector)(
    bettingSlipId,
  );
  const limitsSelector = useSelector(bettingSlipItemLimitsGetterSelector);
  const stakeSelector = useSelector(bettingSlipStakeInFloatGetterSelector);
  const freebetSelectedSelector = useSelector(
    freebetSelectedForBSGetterSelector,
  );
  const odds = useBettingSlipItemFinalOdds(bettingSlipId);
  const userCredit = useSelector(userCreditSelector);
  const itemId = useBettingGetterSelector({
    getterSelector: bettingSlipFirstItemGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  })?.id;
  return (): BettingSlipNotification => {
    if (!itemId) {
      return {
        Status: EFrontErrorType.None,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }
    const stake = stakeSelector(bettingSlipId);

    const freebetSelected = freebetSelectedSelector(bettingSlipId);

    // This condition should only pass when we're in a fast betting slip
    if (
      type === EManualBetType.SuperOdds &&
      bSExtraInfo?.firstDepositDone === false
    ) {
      return {
        Status: EFrontErrorType.FirstDepositRequired,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    if (
      !freebetSelected &&
      userCredit &&
      stake &&
      stake > userCredit.AvailableAmout
    ) {
      return {
        Status: EFrontErrorType.NotEnoughCredit,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const limits = limitsSelector(bettingSlipId);
    if (!limits) {
      return {
        Status: EFrontErrorType.None,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const oddsValue = odds(itemId);
    // This should never happen as we always round up the odds to 1
    // when it's undefined or below 1.
    if (!oddsValue) {
      return {
        Status: EFrontErrorType.InvalidOdd,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const { min, max, winnable } = limits;
    return getLimitStatus({
      stake,
      min: min ?? null,
      max: max ?? null,
      winnable: winnable ?? null,
      odd: oddsValue,
    });
  };
};

export const useBettingSlipSingleOdd = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const odd = useBettingGetterSelector({
    getterSelector: bettingSlipBaseOddsGetterSelector,
    args: [bettingSlipId],
  });

  return (): number => odd;
};

export const useBettingSlipSingleFinalOdd = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const odd = useBettingGetterSelector({
    getterSelector: bettingSlipFinalOddsGettorSelector,
    args: [bettingSlipId],
  });

  return (): number => odd;
};

export const useBettingSlipSingleWinning = (
  bettingSlipId: BettingSlipIdentifierType,
): number | undefined => {
  const winnings = useBettingGetterSelector({
    getterSelector: bettingSlipWinningsGettorSelector,
    args: [bettingSlipId],
  });
  return winnings;
};

export const useBettingSlipSingleFinalWinning = (
  bettingSlipId: BettingSlipIdentifierType,
): number | undefined => {
  const winnings = useBettingGetterSelector({
    getterSelector: bettingSlipFinalWinningsGettorSelector,
    args: [bettingSlipId],
  });
  return winnings;
};
