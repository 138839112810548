import {
  BettingHistoryTicketType,
  EGiftsType,
  Gifts,
} from '@gaming1/g1-betting';
import { CreditType } from '@gaming1/g1-requests-betting';

/**
 * Returns the type of the ticket, it could be a Boostus or a Freebet for example.
 * @param ticket The ticket of the history to check
 * @returns The type of the ticket or null (if something wrong happens)
 */
export const handleTicketType = (
  ticket: BettingHistoryTicketType,
): Gifts | null => {
  if (ticket.CreditType === CreditType.Freebet) {
    return EGiftsType.freebet;
  }

  if (ticket?.AssociatedPromotion?.Boostus) {
    return EGiftsType.boostus;
  }
  if (ticket?.AssociatedPromotion?.Promotion) {
    return EGiftsType.promotion;
  }

  return null;
};
