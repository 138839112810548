import { useDispatch, useSelector } from 'react-redux';

import {
  ApplicationState,
  coreActions,
  userLoggedInSelector,
  userLogoutRequestStateSelector,
  userSelector,
} from '@gaming1/g1-core';
import { useRequestState } from '@gaming1/g1-store';
import { Button, Heading, Text } from '@gaming1/g1-ui';

/** Various buttons and data to help debug user sessions */
export const SessionDebugger = () => {
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const userProfile = useSelector(userSelector);
  const dispatch = useDispatch();
  const keepSessionAliveRequestStatus = useSelector(
    (state: ApplicationState) =>
      state.core.login.requests.keepSessionAlive.status,
  );
  const { status: logoutRequestStatus } = useRequestState(
    userLogoutRequestStateSelector,
  );
  const refreshTokenRequestStatus = useSelector(
    (state: ApplicationState) =>
      state.core.login.requests.refreshAuthToken.status,
  );
  const profileRequestStatus = useSelector(
    (state: ApplicationState) => state.core.user.requests.userProfile.status,
  );
  return (
    <>
      <Heading level="h1" mb="sm">
        Session debugging
      </Heading>
      <Button
        onClick={() => dispatch(coreActions.keepSessionAlive.request())}
        mr="xs"
        mb="xs"
      >
        Keepalive request ({keepSessionAliveRequestStatus})
      </Button>
      <Button
        onClick={() => dispatch(coreActions.logout.request())}
        mr="xs"
        mb="xs"
      >
        Logout request ({logoutRequestStatus})
      </Button>
      <Button
        onClick={() => dispatch(coreActions.refreshAuthToken.request())}
        mr="xs"
        mb="xs"
      >
        Refresh token request ({refreshTokenRequestStatus})
      </Button>
      <Button
        onClick={() => dispatch(coreActions.getUserProfile.request())}
        mb="xs"
      >
        Fetch user profile request ({profileRequestStatus})
      </Button>
      <Text>Is user logged in? {String(isUserLoggedIn)}</Text>
      {userProfile?.Pseudo && <Text>Logged in as {userProfile.Pseudo}</Text>}
    </>
  );
};
