import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../store/types';
import * as actions from '../actions';
import { addDefaultMinStakeToManualBetWithoutOne } from '../helpers';

import { SuperOddsState } from './types';

export const initialState: SuperOddsState = {
  result: {
    hasSuperOdds: false,
    relatedEventIds: [],
    availableSuperOdds: {},
    availableSuperOddsForEvent: {},
  },
  requests: {
    hasSuperOdds: generateInitialRequestState(),
    getAvailableSuperOdds: generateInitialRequestState(null),
    getAvailableSuperOddsForEvent: generateInitialRequestState(null),
  },
};

export const superOddsReducer = (
  state: SuperOddsState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.hasAvailableSuperOdds.request):
        produceLoadingState(draftState.requests.hasSuperOdds);
        break;

      case getType(actions.hasAvailableSuperOdds.failure):
        produceFailureState(draftState.requests.hasSuperOdds, action.payload);
        break;

      case getType(actions.hasAvailableSuperOdds.success):
        produceSuccessState(draftState.requests.hasSuperOdds);
        draftState.result.hasSuperOdds = action.payload.IsAvailable;
        draftState.result.relatedEventIds =
          action.payload.RelatedEventIds || [];

        break;

      case getType(actions.getAvailableSuperOdds.request): {
        produceLoadingState(draftState.requests.getAvailableSuperOdds);
        draftState.result.availableSuperOdds = {};
        break;
      }

      case getType(actions.getAvailableSuperOdds.success): {
        produceSuccessState(draftState.requests.getAvailableSuperOdds);
        const { defaultMinStake, ManualBets, Notification } = action.payload;
        const SuperOddsWithMinStake =
          ManualBets &&
          addDefaultMinStakeToManualBetWithoutOne(ManualBets, defaultMinStake);
        draftState.result.availableSuperOdds = {
          Notification,
          ManualBets: SuperOddsWithMinStake,
        };
        break;
      }

      case getType(actions.getAvailableSuperOdds.failure): {
        const { payload } = action;
        produceFailureState(draftState.requests.getAvailableSuperOdds, payload);
        draftState.result.availableSuperOdds = {};
        break;
      }

      case getType(actions.getAvailableSuperOddsForEvent.request): {
        produceLoadingState(draftState.requests.getAvailableSuperOddsForEvent);
        draftState.result.availableSuperOddsForEvent = {};
        break;
      }

      case getType(actions.getAvailableSuperOddsForEvent.success): {
        const { payload } = action;
        produceSuccessState(draftState.requests.getAvailableSuperOddsForEvent);
        const { Notification, ManualBets, defaultMinStake } = payload;
        const superOddsWithMinStake =
          ManualBets &&
          addDefaultMinStakeToManualBetWithoutOne(ManualBets, defaultMinStake);
        draftState.result.availableSuperOddsForEvent = {
          Notification,
          ManualBets: superOddsWithMinStake,
        };
        break;
      }

      case getType(actions.getAvailableSuperOddsForEvent.failure): {
        const { payload } = action;
        produceFailureState(
          draftState.requests.getAvailableSuperOddsForEvent,
          payload,
        );
        draftState.result.availableSuperOddsForEvent = {};
        break;
      }

      default:
    }
  });
