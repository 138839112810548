import * as t from 'io-ts';

import { PictureSize } from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { conditionListCodec } from '../common/gifts/codecs';
import { navigationDataCodec } from '../navigation/store/codecs';
import { notificationCodec } from '../notification/codecs';

/* Object data */

const pictureLinkCodec = t.intersection(
  [
    t.type({
      Size: createEnumType<PictureSize>(PictureSize),
    }),
    t.partial({
      Path: t.string,
    }),
  ],
  'PictureLinkCodec',
);

const promoParticipationCodec = t.type(
  {
    EventId: t.number,
    Date: t.string,
  },
  'PromoParticipationCodec',
);

export const relatedIdsCodec = t.partial(
  {
    RelatedSportIds: t.array(t.number),
    RelatedLeagueIds: t.array(t.number),
    RelatedEventIds: t.array(t.number),
  },
  'RelatedIdsCodec',
);

export const promotionCodec = t.intersection(
  [
    t.type({
      Id: t.string,
      DisplayPriority: t.number,
      DisplayOnGeneralPage: t.boolean,
      EndDate: t.string,
    }),
    t.partial({
      SummaryTitle: t.string,
      Summary: t.string,
      Title: t.string,
      Description: t.string,
      Conditions: conditionListCodec,
      Participations: t.array(t.union([promoParticipationCodec, t.null])),
      Pictures: t.array(t.union([pictureLinkCodec, t.null])),
      RelatedIds: relatedIdsCodec,
      NavigationData: navigationDataCodec,
    }),
  ],
  'PromotionCodec',
);

/* Response data */

export const hasAvailablePromotionsResponseCodec = t.intersection(
  [
    t.type({
      IsAvailable: t.boolean,
      Notification: notificationCodec,
    }),
    t.partial({
      RelatedIds: relatedIdsCodec,
    }),
  ],
  'HasAvailablePromotionsResponseCodec',
);

export const getAvailablePromotionsResponseCodec = t.intersection(
  [
    t.type({
      Notification: notificationCodec,
    }),
    t.partial({
      Promotions: t.array(promotionCodec),
    }),
  ],
  'GetAvailablePromotionsResponseCodec',
);
