import { opacify } from 'polished';
import styled, { css } from 'styled-components';
import {
  alignItems,
  justifyItems,
  textAlign,
  TextAlignProps,
  typography,
} from 'styled-system';

import { fontSizes, fontWeight, media } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const SCOREBOARD_PREMATCH_CONTENT_MAX_WIDTH_IN_PX = 560;
export const SCOREBOARD_PREMATCH_SMALL_HEIGHT_IN_PX = 105;
export const SCOREBOARD_PREMATCH_LARGE_HEIGHT_IN_PX = 180;

export const ScoreBoardPrematchTemplateWrapper = styled(Box)`
  height: ${SCOREBOARD_PREMATCH_SMALL_HEIGHT_IN_PX}px;
  width: 100%;

  ${media.lg} {
    height: ${SCOREBOARD_PREMATCH_LARGE_HEIGHT_IN_PX}px;
  }
`;

export const ScoreBoardPrematchTemplateContentWrapper = styled(Box)`
  align-items: center;
  height: 100%;
  justify-content: center;
  max-width: ${SCOREBOARD_PREMATCH_CONTENT_MAX_WIDTH_IN_PX}px;
  position: relative;
  width: 100%;
`;

export const ScoreBoardPrematchTemplateRow = styled(Box)`
  align-items: center;
  flex-direction: row;

  ${alignItems}
`;

export const ScoreBoardPrematchTemplateColumn = styled(Box)`
  flex-direction: column;
  justify-content: center;

  ${justifyItems}
`;

export const ScoreBoardPrematchTemplateItem = styled(Box)<{
  isMuted?: boolean;
}>`
  align-items: center;
  font-size: ${fontSizes('sm')};
  font-weight: ${fontWeight('bold')};
  justify-content: center;
  text-align: center;

  ${media.lg} {
    font-size: ${fontSizes('md')};
    ${typography}
  }

  ${({ isMuted }) =>
    isMuted &&
    css`
      color: ${({ theme }) => opacify(-0.2)(theme.colors.textLight)};
    `};

  ${alignItems}
  ${typography}
`;

export const ScoreBoardPrematchTemplateEllipsis = styled.div<TextAlignProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${textAlign}
`;
