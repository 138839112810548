import { createContext, VFC } from 'react';

const EmptyComponent: VFC = () => null;

export type ClubFeatureLinksProps = {
  layout: 'desktop' | 'mobile';
};

/** Context containing public loyalty components
 * - ClubFeatureLinks: Links for the club to be added in the profile
 * - ProfileRankInfo:
 */
export const LoyaltyPublicComponentsContext = createContext<{
  ClubFeatureLinks: VFC<ClubFeatureLinksProps>;
  FreeSpinsHistory: VFC;
  ProfileRankInfo: VFC;
  ScratchcardHistory: VFC;
  ShopHistory: VFC;
  SponsorshipHistory: VFC;
}>({
  ClubFeatureLinks: EmptyComponent,
  FreeSpinsHistory: EmptyComponent,
  ProfileRankInfo: EmptyComponent,
  ScratchcardHistory: EmptyComponent,
  ShopHistory: EmptyComponent,
  SponsorshipHistory: EmptyComponent,
});
