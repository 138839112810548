import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-core';
import { RouteList } from '@gaming1/g1-utils';

import { concatLocation } from '../../helpers';
import { AppRoute, AppRouteProps } from '../AppRoute';

export type RedirectableRouteProps = AppRouteProps & {
  /** List of all routes */
  routes: RouteList;
};

/**
 * Route component from react-router-dom that will redirect to the right page if param is found in url
 */
export const RedirectableRoute: FC<RedirectableRouteProps> = ({
  section,
  path,
  routes,
  ...rest
}) => {
  const location = useLocation();
  const { currentLocale: locale } = useI18n();

  const getPageNameFromUrl = (): string | null =>
    new URLSearchParams(location.search).get('pagename');

  const getSearchUrl = (): string => {
    const usp = new URLSearchParams(location.search);
    usp.delete('pagename');
    return usp.toString();
  };

  const findUrlByPageName = (pageName: string): string | null => {
    const route = routes[pageName];
    return route ? route.path : null;
  };

  const getUrl = (): string | null => {
    const pageName = getPageNameFromUrl();
    if (!pageName) {
      return null;
    }

    const url = findUrlByPageName(pageName);
    if (!url || url === path) {
      return null;
    }

    return `/${locale}${url}`;
  };

  const routeToRedirect = getUrl();

  return !routeToRedirect ? (
    <AppRoute path={path} section={section} {...rest} />
  ) : (
    <Redirect
      to={concatLocation({
        pathname: routeToRedirect,
        search: getSearchUrl(),
        hash: location.hash,
      })}
    />
  );
};
