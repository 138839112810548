import * as t from 'io-ts';

import {
  EResponseStatus,
  NavigationLinkType,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

/* Object data */

export const navLeagueCodec = t.type(
  {
    Id: t.number,
    Name: t.string,
    Order: t.number,
    RegionId: t.number,
    SportId: t.number,
  },
  'NavLeagueCodec',
);

export const navRegionCodec = t.type(
  {
    Id: t.number,
    Name: t.string,
    Order: t.number,
    SportId: t.number,
  },
  'NavRegionCodec',
);

export const navRegionWithLeaguesCodec = t.type(
  { ...navRegionCodec.props, Leagues: t.array(navLeagueCodec) },
  'NavRegionWithLeaguesCodec',
);

export const navSportCodec = t.type(
  {
    Id: t.number,
    Alias: t.string,
    Name: t.string,
    Order: t.number,
  },
  'NavSportCodec',
);

export const navigationDataCodec = t.intersection(
  [
    t.type({
      SportId: t.number,
      LinkType: createEnumType<NavigationLinkType>(NavigationLinkType),
    }),
    t.partial({
      RegionId: t.number,
      LeagueId: t.number,
      EventId: t.number,
    }),
  ],
  'NavigationDataCodec',
);

/* Response data */
export const getRegionsResponseCodec = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    Regions: t.array(navRegionWithLeaguesCodec),
  },
  'GetRegionsResponseCodec',
);

export const getTrendsResponseCodec = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    Trends: t.array(navLeagueCodec),
  },
  'GetTrendsResponseCodec',
);
