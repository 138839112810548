/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:33:19 14-07-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetPlayerWheelInfoRequestDTO,
  GetPlayerWheelInfoResponseDTO,
} from '../entities';

export const getPlayerWheelInfo = (options: GetPlayerWheelInfoRequestDTO) =>
  createRequest<GetPlayerWheelInfoResponseDTO>(
    104,
    'GetPlayerWheelInfo',
    options,
  );
