import { useSelector } from 'react-redux';

import { userLoggedInSelector } from '@gaming1/g1-core';

/**
 * Returns the user authentication status:
 * - `null` when no information is available
 * - `true` or `false` when the user is logged in or not
 */
export const useIsUserLoggedIn = () => useSelector(userLoggedInSelector);
