import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { AcceptLegalModalResponse, ShowLegalModalResponse } from './types';

/** Accept Legal Popup */
export const acceptLegalModal = createAsyncAction(
  'user/accept_legal_modal_request',
  'user/accept_legal_modal_success',
  'user/accept_legal_modal_failure',
)<void, AcceptLegalModalResponse, FailurePayload>();

/** Show Legal Popup */
export const showLegalModal = createAsyncAction(
  'user/show_legal_modal_request',
  'user/show_legal_modal_success',
  'user/show_legal_modal_failure',
)<void, ShowLegalModalResponse, FailurePayload>();
