import { produce } from 'immer';
import omit from 'lodash/omit';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  RemoteData,
  slugifyPayload,
} from '@gaming1/g1-utils';

import * as sportbookActions from '../../sportbook/store/actions';
import { BettingActions } from '../../store/types';

import * as actions from './actions';
import { getNavSportsFromSports } from './helpers';
import { NavigationState } from './types';

export const initialState: NavigationState = {
  hasDailyOffers: false,
  sportsRegionsLeagues: { sports: {} },
  trends: {},
  requests: {
    getRegions: {},
    getSports: generateInitialRequestState(),
    getTrends: generateInitialRequestState(),
    hasDailyOffers: generateInitialRequestState(),
  },
};

export const navigationReducer = (
  state: NavigationState = initialState,
  action: BettingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(sportbookActions.getSports.request):
        produceLoadingState(draftState.requests.getSports);
        break;

      case getType(sportbookActions.getSports.failure):
        produceFailureState(draftState.requests.getSports, action.payload);
        break;

      case getType(sportbookActions.getSports.success): {
        const sports = getNavSportsFromSports(action.payload.entities.sports);
        produceSuccessState(draftState.requests.getSports);
        draftState.sportsRegionsLeagues.sports = Object.values(sports).reduce(
          (acc, sport) => ({
            ...acc,
            [sport.Id]: {
              data: sport,
              regions: {
                ...(draftState.sportsRegionsLeagues.sports[sport.Id]?.regions ||
                  {}),
              },
            },
          }),
          {},
        );
        break;
      }

      case getType(actions.getRegionsNavigation.request): {
        const slug = slugifyPayload(action.payload);
        draftState.requests.getRegions[slug] = {
          ...generateInitialRequestState(),
          ...state.requests.getRegions[slug],
          errorCode: undefined,
          errorMessage: undefined,
          status: RemoteData.Loading,
        };
        break;
      }

      case getType(actions.getRegionsNavigation.failure): {
        const slug = slugifyPayload(action.payload.request);
        draftState.requests.getRegions[slug] = {
          ...generateInitialRequestState(),
          ...state.requests.getRegions[slug],
          errorCode: action.payload.data.status,
          errorMessage: action.payload.data.errorMessage,
          status: RemoteData.Error,
        };
        break;
      }

      case getType(actions.getRegionsNavigation.success): {
        const { sportId } = action.payload.request;
        const slug = slugifyPayload(action.payload.request);
        draftState.requests.getRegions[slug] = {
          ...generateInitialRequestState(),
          ...state.requests.getRegions[slug],
          errorCode: undefined,
          errorMessage: undefined,
          status: RemoteData.Success,
        };
        draftState.sportsRegionsLeagues.sports = {
          ...draftState.sportsRegionsLeagues.sports,
          [sportId]: {
            ...draftState.sportsRegionsLeagues.sports[sportId],
            regions: action.payload.data.Regions.reduce(
              (firstAcc, region) => ({
                ...firstAcc,
                [region.Id]: {
                  data: omit(region, 'Leagues'),
                  leagues: region.Leagues.reduce(
                    (secondAcc, league) => ({
                      ...secondAcc,
                      [league.Id]: league,
                    }),
                    {},
                  ),
                },
              }),
              {},
            ),
          },
        };
        break;
      }

      case getType(actions.getTrendsNavigation.request):
        produceLoadingState(draftState.requests.getTrends);
        break;

      case getType(actions.getTrendsNavigation.failure):
        produceFailureState(draftState.requests.getTrends, action.payload);
        break;

      case getType(actions.getTrendsNavigation.success):
        produceSuccessState(draftState.requests.getTrends);
        draftState.trends = {
          ...draftState.trends,
          ...action.payload.Trends.reduce(
            (acc, trend) => ({
              ...acc,
              [trend.Id]: trend,
            }),
            {},
          ),
        };
        break;

      default: // Immer will automatically return the state
    }
  });
