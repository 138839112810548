import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  BettingSlipStatus,
  getBettingHistory,
  getBettingSlipSystemDetail,
} from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';

import * as actions from './actions';
import {
  getBettingHistoryResponseCodec,
  getBettingSlipSystemDetailResponseCodec,
} from './codecs';
import { BettingSlipHistoryType } from './types';

const mappingTypeForStatus: Record<
  BettingSlipHistoryType,
  Array<BettingSlipStatus>
> = {
  open: [BettingSlipStatus.Open],
  close: [
    BettingSlipStatus.Won,
    BettingSlipStatus.Lost,
    BettingSlipStatus.Cashout,
    BettingSlipStatus.Cancel,
    BettingSlipStatus.Refund,
  ],
  fromTicket: [],
};
export const bettingHistoryEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getBettingHistory.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }]) => {
      const { type } = payload;

      return wsAdapter
        .request(getBettingHistory({ Status: mappingTypeForStatus[type] }), {
          retryOnTimeout: true,
          timeoutAfterMs: 15000,
        })
        .pipe(
          mapGuard(getBettingHistoryResponseCodec),
          map((data) =>
            actions.getBettingHistory.success({
              type,
              histories: data,
            }),
          ),
          catchError((err) =>
            of(
              actions.getBettingHistory.failure({
                ...{ type },
                ...createFailurePayload(err, commonBettingErrorMessages),
              }),
            ),
          ),
        );
    }),
  );

export const bettingSlipSystemDetailEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getBettingSlipSystemDetail.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }]) => {
      const { bettingSlipId } = payload;

      return wsAdapter
        .request(getBettingSlipSystemDetail({ BettingSlipId: bettingSlipId }), {
          retryOnTimeout: true,
          timeoutAfterMs: 5000,
        })
        .pipe(
          mapGuard(getBettingSlipSystemDetailResponseCodec),
          map((data) =>
            actions.getBettingSlipSystemDetail.success({
              ...data,
              ...{ bettingSlipId },
            }),
          ),
          catchError((err) =>
            of(
              actions.getBettingSlipSystemDetail.failure({
                ...{ bettingSlipId },
                ...createFailurePayload(err, commonBettingErrorMessages),
              }),
            ),
          ),
        );
    }),
  );
