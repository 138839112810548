import { useCallback, useContext, useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  PrismicImageItem,
  promotionRequestStateSelector,
  promotionSelector,
  usePrismicReference,
} from '@gaming1/g1-cms';
import { RequestErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import {
  Box,
  isMediaBreakPointAboveOrEqual,
  isMediaBreakPointBelowOrEqual,
  LayoutContext,
  Loader,
} from '@gaming1/g1-ui';

import { PromotionPrismicSection } from '../PrismicPromotionDetails/styles';

import { ModalBanner, ModalType } from './styles';

export type PrismicPromotionDetailsModalContentProp = {
  uid: string;
};

/**
 * Component that display the content of a promotion
 * It loads a 'content_v2' Prismic document having the matching UID and a 'Promotion' tag
 */

export const PrismicPromotionDetailsModalContent: VFC<
  PrismicPromotionDetailsModalContentProp
> = ({ uid }) => {
  const { t } = useTranslation('core');
  const dispatch = useDispatch();

  const { media } = useContext(LayoutContext);
  const prismicReference = usePrismicReference();
  const promotion = useSelector(promotionSelector);
  const { isNotAsked, isLoading, isSuccessful, isFailing } = useRequestState(
    promotionRequestStateSelector,
  );

  const retryRequest = useCallback(() => {
    if (uid && prismicReference && isNotAsked) {
      dispatch(actions.fetchPromotionByUid.request({ uid }));
    }
  }, [dispatch, isNotAsked, prismicReference, uid]);

  useEffect(() => {
    retryRequest();
  }, [retryRequest]);
  // If no UID is specified
  if (!uid) {
    return null;
  }
  if (isLoading) {
    return <Loader my="xl" data-testid="prismic-modal-loader" />;
  }
  if (isFailing) {
    return (
      <RequestErrorMessage
        errorMessage={t('error.fetchPrismicPromotion')}
        onRetryButtonClick={retryRequest}
        testId="prismic-modal-error"
      />
    );
  }
  if (isSuccessful) {
    let banner: PrismicImageItem | undefined =
      promotion?.data.banner || undefined;
    if (
      isMediaBreakPointBelowOrEqual(media, 'xs') ||
      (isMediaBreakPointAboveOrEqual(media, 'md') &&
        isMediaBreakPointBelowOrEqual(media, 'lg'))
    ) {
      banner = promotion?.data.banner.Mobile || banner;
    }
    if (promotion) {
      return (
        <>
          <div>
            {banner && banner.url && (
              <ModalBanner url={banner.url} data-testid="prismic-modal-image" />
            )}
          </div>
          <Box m="sm">
            {promotion.data.type && (
              <ModalType>{promotion.data.type}</ModalType>
            )}
            {promotion.data.link.uid && (
              <PromotionPrismicSection
                currentContentUid={promotion.data.link.uid}
                context="rawContent"
                title=""
                testId="prismic-modal-prismic-section"
              />
            )}
          </Box>
        </>
      );
    }
    return (
      <RequestErrorMessage
        errorMessage={t('error.fetchPrismicPromotion')}
        onRetryButtonClick={retryRequest}
        testId="prismic-modal-error"
      />
    );
  }

  return null;
};
