import { ElementInfo, EventType, Provider } from '@gaming1/g1-requests-betting';

import { RoundingConfig } from '../store/types/common';
import { RawItem } from '../store/types/store';

import { calcWinning } from './calc';

/**
 * Take a raw data object to format them into a valid BettingSlipElementDTO.
 *
 * @param stake stake of the BS element
 * @param odd odd of the BS element
 * @param item the item contained in the bettingSlip
 * @param provider the trading provider of the event
 */
export const formatPreparedItem = ({
  baseOdd,
  finalOdd,
  item,
  eventType,
  provider,
}: RawItem): ElementInfo => ({
  EventId: item?.event.id || 0,
  MarketId: item?.market.id || 0,
  OutcomeId: item?.outcome.id || 0,
  BaseOdds: baseOdd || -1,
  FinalOdds: finalOdd || -1,
  EventType: eventType || EventType.Unknown,
  Provider: provider || Provider.Unknown,
});

export const calcPotWinning = (data: {
  stake: number | undefined;
  odd: number | undefined;
  config?: {
    isFreeBetRetributeStakeAmount: boolean | undefined;
    roundingConfig: RoundingConfig;
  };
  options?: { isFreebetSelected: boolean };
}): number => {
  if (!data.stake || !data.odd) {
    return 0;
  }
  const potWinning = calcWinning(data.config?.roundingConfig)(
    data.stake,
    data.odd,
  );

  if (
    data.options &&
    data.options.isFreebetSelected &&
    data.config &&
    data.config.isFreeBetRetributeStakeAmount === false
  ) {
    return potWinning - data.stake;
  }
  return potWinning;
};
