import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { CoreEpic } from '../types';

import * as actions from './actions';

/**
 * When the user change its locale, let the websocket know
 */
export const localeSwitchEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.switchLocale.request)),
    switchMap(({ payload }) =>
      wsAdapter.switchLocale(payload).pipe(
        map(() => actions.switchLocale.success(payload)),
        catchError(() => of(actions.switchLocale.success(payload))),
      ),
    ),
  );
