import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { ScoreBoardTeam } from '@gaming1/g1-requests-betting';
import { RemoteData } from '@gaming1/g1-utils';

import { getEventTeamsValues } from '../common/store/helpers';
import { ApplicationWithBettingState } from '../store/types';

import { slugifyScoreBoardPayload } from './helpers';
import { ScoreBoardType } from './types';

/* Helpers */

type Nullable<T> = T | null | undefined;

type Teams<T> = { team1: T; team2: T };

type Separator = { separator: string };

/* Entities */

export const scoreBoardsSelector = (state: ApplicationWithBettingState) =>
  state.betting.score.entities.scoreBoards;

/* Get score board */
const getScoreBoardRequestsSelector = (state: ApplicationWithBettingState) =>
  state.betting.score.requests.getScoreBoard;

export const getScoreBoardSelector = createSelector(
  scoreBoardsSelector,
  (scoreBoards) =>
    memoize(
      (eventId: number): ScoreBoardType | undefined => scoreBoards[eventId],
    ),
);

export const getScoreBoardIsEventRemovedSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize(
      (eventId: number): boolean => !!getScoreBoard(eventId)?.IsEventRemoved,
    ),
);

export const getScoreBoardTeamNamesSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize((eventId: number): Teams<Nullable<string>> & Separator => {
      const { EventDisplayType, Info } = getScoreBoard(eventId) || {};

      return getEventTeamsValues(
        Info?.HomeTeamName,
        Info?.AwayTeamName,
        EventDisplayType,
      );
    }),
);

export const getScoreBoardPassTeamSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize((eventId: number): Teams<boolean> => {
      const { EventDisplayType, Info } = getScoreBoard(eventId) || {};

      return getEventTeamsValues(
        Info?.PassTeam === ScoreBoardTeam.Home,
        Info?.PassTeam === ScoreBoardTeam.Away,
        EventDisplayType,
      );
    }),
);

export const getScoreBoardTotalScorePerTeamSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize((eventId: number): Teams<number> => {
      const { EventDisplayType, Stats } = getScoreBoard(eventId) || {};
      const { Home, Away } = Stats?.TotalScore?.TeamValue || {};

      return getEventTeamsValues(Home || 0, Away || 0, EventDisplayType);
    }),
);

export const getScoreBoardScorePerPeriodSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize((eventId: number): Teams<number>[] => {
      const { EventDisplayType, Stats } = getScoreBoard(eventId) || {};

      return (Stats?.ScorePerPeriod || []).map((period) => {
        const { Home, Away } = period?.TeamValue || {};

        return getEventTeamsValues(Home || 0, Away || 0, EventDisplayType);
      });
    }),
);

export const getScoreBoardLastPeriodScoreSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize((eventId: number): Teams<number> => {
      const { EventDisplayType, Stats } = getScoreBoard(eventId) || {};
      const lastPeriod = (Stats?.ScorePerPeriod || []).slice(-1)[0];
      const { Home, Away } = lastPeriod?.TeamValue || {};

      return getEventTeamsValues(Home || 0, Away || 0, EventDisplayType);
    }),
);

export const getScoreBoardTotalCountWonPeriodsSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize((eventId: number): Teams<number> => {
      const { EventDisplayType, Stats } = getScoreBoard(eventId) || {};

      const totalScoreForPeriods = (
        Stats?.ScorePerPeriod?.slice(0, -1) || []
      ).reduce(
        (acc, period) => {
          const home = period?.TeamValue?.Home || 0;
          const away = period?.TeamValue?.Away || 0;

          return {
            home: home > away ? acc.home + 1 : acc.home,
            away: away > home ? acc.away + 1 : acc.away,
          };
        },
        { home: 0, away: 0 },
      );

      return getEventTeamsValues(
        totalScoreForPeriods.home,
        totalScoreForPeriods.away,
        EventDisplayType,
      );
    }),
);

export const getScoreBoardSportInfoSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize(
      (eventId: number, metadataName: string): Nullable<string> => {
        const { SportInfo } = getScoreBoard(eventId) || {};

        return SportInfo?.find((info) => info?.Name === metadataName)?.Value;
      },
      (eventId: number, metadataName: string) => `${eventId}${metadataName}`,
    ),
);

export const getScoreBoardSportStatsSelector = createSelector(
  getScoreBoardSelector,
  (getScoreBoard) =>
    memoize(
      (eventId: number, metadataName: string): Teams<number> => {
        const { SportStats, EventDisplayType } = getScoreBoard(eventId) || {};
        const data = SportStats?.find((stats) => stats?.Name === metadataName);
        const { Home, Away } = data?.TeamValue || {};

        return getEventTeamsValues(Home || 0, Away || 0, EventDisplayType);
      },
      (eventId: number, metadataName: string) => `${eventId}${metadataName}`,
    ),
);

export const getScoreBoardStatusSelector = createSelector(
  getScoreBoardRequestsSelector,
  (requests) =>
    memoize(
      (eventId: number) =>
        requests[slugifyScoreBoardPayload({ eventId })]?.status ||
        RemoteData.NotAsked,
    ),
);

export const getScoreBoardErrorMessageSelector = createSelector(
  getScoreBoardRequestsSelector,
  (requests) =>
    memoize(
      (eventId: number) =>
        requests[slugifyScoreBoardPayload({ eventId })]?.errorMessage,
    ),
);

/* Get all score boards */

export const getAllScoreBoardsStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.score.requests.getAllScoreBoards.status;

export const getAllScoreBoardsErrorMessageSelector = (
  state: ApplicationWithBettingState,
) => state.betting.score.requests.getAllScoreBoards.errorMessage;
