import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { actions as coreActions, CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { SportbookState } from '../types';

export const initialRequestsState: SportbookState['requests'] = {
  getLiveCount: generateInitialRequestState(),
  getLiveCountBySport: generateInitialRequestState(),
  getSports: generateInitialRequestState(),
  getFilters: generateInitialRequestState(0),
  getFullSearch: generateInitialRequestState(),
  getLightSearch: generateInitialRequestState(),
};

export const sportbookRequestsReducer = (
  state = initialRequestsState,
  action: BettingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getLiveEventsCount.request):
        produceLoadingState(draftState.getLiveCount);
        break;

      case getType(actions.getLiveEventsCount.failure):
        produceFailureState(draftState.getLiveCount, action.payload);
        break;

      case getType(actions.getLiveEventsCount.success):
        produceSuccessState(draftState.getLiveCount);
        break;

      case getType(actions.getLiveCountBySport.request):
        produceLoadingState(draftState.getLiveCountBySport);
        break;

      case getType(actions.getLiveCountBySport.failure):
        produceFailureState(draftState.getLiveCountBySport, action.payload);
        break;

      case getType(actions.getLiveCountBySport.success):
        produceSuccessState(draftState.getLiveCountBySport);
        break;

      case getType(actions.getSports.request):
        produceLoadingState(draftState.getSports);
        break;

      case getType(actions.getSports.failure):
        produceFailureState(draftState.getSports, action.payload);
        break;

      case getType(actions.getSports.success):
        produceSuccessState(draftState.getSports);
        break;

      case getType(actions.getFilters.request):
        produceLoadingState(draftState.getFilters);
        break;

      case getType(actions.getFilters.failure):
        produceFailureState(draftState.getFilters, action.payload);
        break;

      case getType(actions.getFilters.success):
        produceSuccessState(draftState.getFilters, action.payload.sportId);
        break;

      /** Light search */
      case getType(actions.lightSearch.request):
        produceLoadingState(draftState.getLightSearch);
        break;

      case getType(actions.lightSearch.failure):
        produceFailureState(draftState.getLightSearch, action.payload);
        break;

      case getType(actions.lightSearch.success): {
        produceSuccessState(draftState.getLightSearch);
        break;
      }

      /** Full search */
      case getType(actions.fullSearch.request):
        produceLoadingState(draftState.getFullSearch);
        break;

      case getType(actions.fullSearch.failure):
        produceFailureState(draftState.getFullSearch, action.payload);
        break;

      case getType(actions.fullSearch.success): {
        produceSuccessState(draftState.getFullSearch);
        break;
      }

      case getType(actions.fullSearch.cancel): {
        draftState.getFullSearch = generateInitialRequestState();
        break;
      }

      case getType(coreActions.switchLocale.success):
        draftState.getSports = generateInitialRequestState();
        break;

      default: // Immer will automatically return the state
    }
  });
