/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:57:27 25-03-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { HorizontalBannerRequest, HorizontalBannerResponse } from '../entities';

export const getHorizontalBanner = (options: HorizontalBannerRequest) =>
  createRequest<HorizontalBannerResponse>(201, 'GetHorizontalBanner', options);
