import React, { FC } from 'react';
import { JustifyContentProps, SpaceProps, TextAlignProps } from 'styled-system';

import { Icon } from '../Icon';
import { IconType } from '../Icon/icons';

import { StyledHeading } from './styles';
import { HeadingLevel } from './types';

export type HeadingProps = SpaceProps &
  JustifyContentProps &
  TextAlignProps & {
    /** Level of the heading */
    level: HeadingLevel;
    /** Level of the style, if the style must be different from the level of the heading */
    levelStyle?: HeadingLevel;
    /** Icon of the title */
    icon?: IconType;
  };

export const Heading: FC<HeadingProps> = ({
  level,
  levelStyle,
  icon,
  children,
  ...rest
}) => (
  <StyledHeading as={level} level={levelStyle || level} {...rest}>
    {icon && <Icon type={icon} id={`heading-${icon.toLowerCase()}`} />}
    {children}
  </StyledHeading>
);
