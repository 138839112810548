import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import {
  AppAnchor,
  Button,
  CheckboxInput,
  Heading,
  Modal,
} from '@gaming1/g1-ui';
import {
  actions,
  setLegalDepositLimitRequestStateSelector,
} from '@gaming1/g1-user';
import { DeclarationOfHonorModalProps } from '@gaming1/g1-user-api';
import { RemoteData } from '@gaming1/g1-utils';

/**
 * Declaration of honor modal
 * Displayed when a player with an active legal limit wants to disable it.
 * To disable the limit, the player has to make a declaration of honor, which is handled by this component.
 */
export const DeclarationOfHonorModal: FC<DeclarationOfHonorModalProps> = ({
  onClose,
  ...rest
}) => {
  const [confirmDeclarationOfHonor, setConfirmDeclarationOfHonor] =
    useState(false);
  const { t } = useTranslation('user');
  const { isLoading, status } = useRequestState(
    setLegalDepositLimitRequestStateSelector,
  );
  const dispatch = useDispatch();

  if (status && status === RemoteData.Success) {
    // disabling of the limit asked
    return (
      <Modal
        modalId="account-declaration-honor-modal"
        onClose={onClose}
        {...rest}
      >
        <Heading level="h3" mt="none">
          {t('account.limit.declarationOfHonor.confirmed.title')}
        </Heading>
        <Trans
          i18nKey="account.limit.declarationOfHonor.confirmed.modalText"
          ns="user"
          components={{
            p: <p />,
            Link: <AppAnchor />,
          }}
        />
        <Button
          block
          testId="account-declaration-honor-close-button"
          type="primary"
          mt="lg"
          onClick={onClose}
        >
          {t('core:button.close')}
        </Button>
      </Modal>
    );
  }
  return (
    <Modal
      modalId="account-declaration-honor-modal"
      onClose={onClose}
      {...rest}
    >
      <Heading level="h3" mt="none">
        {t('account.limit.declarationOfHonor.title')}
      </Heading>
      <Trans
        i18nKey="account.limit.declarationOfHonor.modalText"
        ns="user"
        components={{
          p: <p />,
        }}
      />
      <CheckboxInput
        data-testid="account-declaration-honor-confirm-checkbox"
        label={t('account.limit.declarationOfHonor.modalCheckbox')}
        checked={confirmDeclarationOfHonor}
        onChange={() => {
          setConfirmDeclarationOfHonor(!confirmDeclarationOfHonor);
        }}
      />
      <Button
        block
        testId="account-declaration-honor-confirm-button"
        type="primary"
        loading={isLoading}
        mt="lg"
        onClick={() => {
          dispatch(
            actions.setLegalDepositLimit.request({
              AcceptDepositLegalLimit: false,
              LegalDepositLimit: 0,
            }),
          );
        }}
        disabled={!confirmDeclarationOfHonor}
      >
        {t('core:button.confirm')}
      </Button>
      <Button
        block
        testId="account-declaration-honor-cancel-button"
        type="secondary"
        mt="lg"
        onClick={onClose}
      >
        {t('core:button.cancel')}
      </Button>
    </Modal>
  );
};
