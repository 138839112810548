import React, { forwardRef, InputHTMLAttributes, useState } from 'react';

import { InputWrapper } from '../InputWrapper';
import { CommonInputProps } from '../types';

import {
  AppRadioSpan,
  HiddenRadioInput,
  RadioCardContent,
  RadioCardWrapper,
  RadioLabel,
  RadioLabelSpan,
} from './styles';

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;

export type RadioInputProps = Omit<NativeInputProps, 'type'> &
  CommonInputProps & {
    /**
     * Optional callback called when the input is checked
     * (called with the input value)
     */
    onCheck?: (value: NativeInputProps['value']) => void;
    /**
     * allows for input change when clicking on the card
     */
    onClick?: undefined | ((value: string) => void);
    /**
     * default is a simple radio, card is a card block
     */
    mode?: 'default' | 'card';
  };

/**
 * Custom radio input
 */
export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (
    {
      checked,
      disabled,
      errors,
      info,
      label,
      onBlur,
      onChange,
      onCheck,
      onFocus,
      simple,
      testId = 'radio-input',
      value,
      wrapperProps,
      children,
      onClick,
      mode = 'default',
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);
    let radio = (
      <>
        <HiddenRadioInput
          checked={checked}
          data-testid={testId}
          disabled={disabled}
          onBlur={(event) => {
            if (onBlur) {
              onBlur(event);
            }
            setFocused(false);
          }}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }
            if (onCheck && event.target.checked) {
              onCheck(value);
            }
          }}
          onFocus={(event) => {
            if (onFocus) {
              onFocus(event);
            }
            setFocused(true);
          }}
          onClick={(event) => {
            if (onClick) {
              onClick(event);
            }
          }}
          ref={ref}
          value={value}
          {...rest}
        />
        <AppRadioSpan checked={checked} disabled={disabled} focused={focused} />
        {!!label && (
          <RadioLabelSpan checked={checked} disabled={disabled}>
            {label}
          </RadioLabelSpan>
        )}
      </>
    );
    // if it needs to render as a card, we wrap it in a div
    if (mode === 'card') {
      radio = (
        <RadioCardWrapper
          data-testid={`${testId}-divwrapper`}
          onClick={(event) => {
            event.preventDefault();
            if (onClick && value && typeof value === 'string') {
              onClick(value);
            }
          }}
        >
          {radio}
          <RadioCardContent>{children}</RadioCardContent>
        </RadioCardWrapper>
      );
    } else {
      // otherwise we render it in a label
      radio = (
        <RadioLabel data-testid={`${testId}-labelwrapper`}>{radio}</RadioLabel>
      );
    }
    if (simple) {
      return radio;
    }

    return (
      <InputWrapper
        errors={errors}
        info={info}
        testId={testId}
        {...wrapperProps}
      >
        {radio}
      </InputWrapper>
    );
  },
);
