import { createAction, createAsyncAction } from 'typesafe-actions';

import { AppConfig, LocaleCode } from '@gaming1/g1-config';
import { WsAdapterOptions } from '@gaming1/g1-network';
import { RESET_STATES_ACTION_NAME } from '@gaming1/g1-store';
import { FailurePayload } from '@gaming1/g1-utils';

import { ShellParameters } from './codecs';
import { AppSection, MobileVersion } from './types';

/** Run at the launch of the app */
export const initApp = createAction('core/init')<{
  hasUserAlreadyLoggedIn: boolean;
  isInMobileApp: boolean;
  lastVisitTimestamp: number | null;
  locale: LocaleCode;
  wsAdapterOptions?: Partial<WsAdapterOptions>;
}>();

/** The app will quit because the user closed the tab */
export const exitApp = createAction('core/exit')();

export const setLastVisitTimestamp = createAction(
  'core/set_last_visit_timestamp',
)<number>();

/** Switch the section of the application */
export const switchSection = createAction(
  'core/switch_section',
  (section: AppSection) => section,
)();

/** Used for testing */
export const dummyAction = createAction('core/dummy')();

/**
 * Get various value form the http api. None are used at the moment
 */
export const getShellParams = createAsyncAction(
  'core/get_shell_params_request',
  'core/get_shell_params_success',
  'core/get_shell_params_failure',
)<void, ShellParameters, FailurePayload>();

/** Dispatch the current config of the app to the store */
export const setConfig = createAction('core/set_config')<AppConfig>();

/** Set Mobile app version */
export const setMobileVersion = createAction(
  'core/set_mobile_app_version',
)<MobileVersion>();

/** Resets states to their initial states */
export const resetStates = createAction(RESET_STATES_ACTION_NAME)();
