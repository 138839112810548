import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  RemoteData,
} from '@gaming1/g1-utils';

import { CoreActions } from '../types';

import * as actions from './actions';
import { LoginState } from './types';

export const initialState: LoginState = {
  sessionId: null,
  requests: {
    impersonate: generateInitialRequestState(),
    keepSessionAlive: generateInitialRequestState(),
    logout: generateInitialRequestState(),
    login: {
      ...generateInitialRequestState(),
      status: RemoteData.NotAsked,
      errorCode: undefined,
      errorMessage: undefined,
    },
    refreshAuthToken: generateInitialRequestState(),
  },
};

export const loginReducer = (
  state: LoginState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.login.request):
        produceLoadingState(draftState.requests.login);
        break;

      case getType(actions.login.success):
        if ('SessionId' in action.payload && !!action.payload.SessionId) {
          draftState.sessionId = action.payload.SessionId;
        }
        break;

      case getType(actions.login.failure):
        draftState.requests.login = {
          ...action.payload,
          status: RemoteData.Error,
          errorCode: action.payload.status,
        };
        break;

      case getType(actions.auth.request):
        produceLoadingState(draftState.requests.login);
        break;

      case getType(actions.auth.failure):
        produceFailureState(draftState.requests.login, action.payload);
        break;

      case getType(actions.auth.success):
        produceSuccessState(draftState.requests.login);
        break;

      case getType(actions.cleanLogin):
        return initialState;

      case getType(actions.logout.request):
        produceLoadingState(draftState.requests.logout);
        // Reset the keep alive state after a logout
        draftState.requests.keepSessionAlive =
          initialState.requests.keepSessionAlive;
        break;

      case getType(actions.logout.failure):
        produceFailureState(draftState.requests.logout, action.payload);
        break;

      case getType(actions.logout.success):
        produceSuccessState(draftState.requests.logout);
        // Reset the login state after a logout
        draftState.requests.login = initialState.requests.login;
        draftState.sessionId = null;
        break;

      case getType(actions.impersonateUser.request):
        produceLoadingState(draftState.requests.impersonate);
        break;

      case getType(actions.impersonateUser.failure):
        produceFailureState(draftState.requests.impersonate, action.payload);
        break;

      case getType(actions.impersonateUser.success):
        produceSuccessState(draftState.requests.impersonate);
        if (
          'BusinessSessionId' in action.payload &&
          !!action.payload.BusinessSessionId
        ) {
          draftState.sessionId = action.payload.BusinessSessionId;
        }
        break;

      case getType(actions.refreshAuthToken.request):
        produceLoadingState(draftState.requests.refreshAuthToken);
        break;

      case getType(actions.refreshAuthToken.success):
        produceSuccessState(draftState.requests.refreshAuthToken);
        if (
          'BusinessSessionId' in action.payload &&
          !!action.payload.BusinessSessionId
        ) {
          draftState.sessionId = action.payload.BusinessSessionId;
        }
        break;

      case getType(actions.refreshAuthToken.failure):
        produceFailureState(
          draftState.requests.refreshAuthToken,
          action.payload,
        );
        break;

      case getType(actions.keepSessionAlive.request):
        produceLoadingState(draftState.requests.keepSessionAlive);
        break;

      case getType(actions.keepSessionAlive.success):
        produceSuccessState(draftState.requests.keepSessionAlive);
        break;

      case getType(actions.keepSessionAlive.failure):
        produceFailureState(
          draftState.requests.keepSessionAlive,
          action.payload,
        );
        break;

      default: // Immer will automatically return the state
    }
  });
