import React, { useMemo, VFC } from 'react';

import { App as CoreApp, RouterWrapper } from '@gaming1/g1-core-web';
import { WsAdapterOptions } from '@gaming1/g1-network';
import { getSearchParam } from '@gaming1/g1-utils';

import { AppRouter } from './AppRouter';
import { AssetsProvider } from './assets/AssetsProvider';
import { AppLayout } from './components/AppLayout';
import { CustomThemesProvider } from './components/CustomThemesProvider';
import { DevToolMenu } from './components/DevToolMenu';
import { IconsProvider } from './components/IconsProvider';
import { OverwriteStyle } from './components/OverwriteStyle';
import { SwitchProvider } from './components/SwitchProvider';

import './index.css';

export const App: VFC = () => {
  // Allows to disable the ws autoreconnect mecanism for dev purpose
  const wsAdapterOptions: Partial<WsAdapterOptions> = useMemo(() => {
    const attemps = Number(getSearchParam('reconnectattempts'));
    return {
      reconnectAttempts: !Number.isNaN(attemps) && attemps >= 0 ? attemps : 5,
    };
  }, []);

  return (
    <CustomThemesProvider>
      <SwitchProvider>
        <IconsProvider>
          <CoreApp wsAdapterOptions={wsAdapterOptions}>
            <AssetsProvider>
              <AppLayout>
                <DevToolMenu />
                <OverwriteStyle />
                <RouterWrapper>
                  <AppRouter />
                </RouterWrapper>
              </AppLayout>
            </AssetsProvider>
          </CoreApp>
        </IconsProvider>
      </SwitchProvider>
    </CustomThemesProvider>
  );
};
