import React, {
  ContextType,
  FC,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import {
  EBettingSlipIdentifier,
  useDoesBettingSlipAllowActions,
} from '@gaming1/g1-betting';

import { SubDrawerContext } from './SubDrawerContext';

export const SubDrawerProvider: FC = ({ children }) => {
  const isPlaceBetProcessing = useDoesBettingSlipAllowActions(
    EBettingSlipIdentifier.Main,
  );

  const [offersAvailableDrawerVisibility, setOffersAvailableDrawerVisibility] =
    useState(false);

  const openOffersAvailableDrawer = useCallback(() => {
    setOffersAvailableDrawerVisibility(isPlaceBetProcessing);
  }, [isPlaceBetProcessing]);

  const closeOffersAvailableDrawer = useCallback(() => {
    setOffersAvailableDrawerVisibility(false);
  }, []);

  const backdropClickHandler = useCallback(() => {
    setOffersAvailableDrawerVisibility(false);
  }, []);

  const handleEscape = useCallback((e) => {
    if (e.key === 'Escape') {
      setOffersAvailableDrawerVisibility(false);
    }
  }, []);

  const backdropVisibility = useMemo(
    () => offersAvailableDrawerVisibility,
    [offersAvailableDrawerVisibility],
  );

  useLayoutEffect(() => {
    if (offersAvailableDrawerVisibility) {
      // Allow to hide on Escape
      window.addEventListener('keydown', handleEscape);
    } else {
      // Remove the Escape event
      window.removeEventListener('keydown', handleEscape);
    }

    return () => window.removeEventListener('keydown', handleEscape);
  }, [offersAvailableDrawerVisibility, handleEscape]);

  const subDrawerContextValue: ContextType<typeof SubDrawerContext> = useMemo(
    () => ({
      openOffersAvailableDrawer,
      closeOffersAvailableDrawer,
      offersAvailableDrawerVisibility,
      backdropVisibility,
      backdropClickHandler,
    }),
    [
      openOffersAvailableDrawer,
      closeOffersAvailableDrawer,
      offersAvailableDrawerVisibility,
      backdropVisibility,
      backdropClickHandler,
    ],
  );

  return (
    <SubDrawerContext.Provider value={subDrawerContextValue}>
      {children}
    </SubDrawerContext.Provider>
  );
};
