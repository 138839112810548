import addMilliseconds from 'date-fns/addMilliseconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInYears from 'date-fns/differenceInYears';
import formatDate from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import {
  ComponentProps,
  ComponentType,
  lazy,
  LazyExoticComponent,
} from 'react';

import { getWorkingEnv } from '@gaming1/g1-logger';

import { logger } from './logger';
import { Serializable, TimeDistanceUnit } from './types';

// Needed for guid generation
/* eslint-disable no-bitwise */
// From https://stackoverflow.com/a/2117523

// It duplicates DateFormat from g1-config/src/types/i18n.ts to avoid the package dependency.
type DateFormat = 'dd/MM/yyyy' | 'MM/dd/yyyy' | 'yyyy-MM-dd';

/**
 * Merge two objects while keeping the type of the first
 * @param defaultValues base object
 * @param values object that will be merged into te base object
 */
export const mergeWithDefault = <
  T extends Record<string, unknown>,
  O extends Partial<O>,
>(
  defaultValues: T,
  values: O,
): T => merge(defaultValues, pick(values, keys(defaultValues)));

/**
 * Get a search parameter from an url or the current one
 */
export const getSearchParam = (
  queryName: string,
  href?: string,
): string | null => {
  if (!['web', 'jest'].includes(getWorkingEnv())) {
    return null;
  }
  const url = href || window.location.href;
  return new URL(url).searchParams.get(queryName);
};

/**
 * Serializes an object into an URI query string
 *
 * @param params The params to encode
 */
export const encodeQuery = (
  params: Record<string, boolean | number | string>,
) =>
  Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');

/**
 * Create a promisified timeout
 */
export const sleep =
  (delayInMs: number) =>
  <T = never>(value?: T) =>
    new Promise((resolve) => {
      setTimeout(() => resolve(value), delayInMs);
    });

export const parseDate = (value: string, format: string) =>
  parse(value, format, new Date());

/**
 * Returns true if the params correspond to a date, false instead
 * @param {string} value such as 31/12/1999
 * @param {string} format such as dd/MM/yyyy
 * @param {number} startYear The year date should start from
 * @returns {boolean}
 */
export const isDateValid = (
  value: string,
  format: string,
  startYear?: number,
): boolean => {
  const date = parseDate(value, format);
  return startYear
    ? isValid(date) && date.getFullYear() >= startYear
    : isValid(date);
};

/**
 * Create a formatted date like 'yy.MM.dd' to precomplete the national number.
 * @param dateToFormat the date to format, it could be a european or american date
 */
export const parseDateToBelgianNationalNumberPrefix = (
  dateToFormat: string,
  format = 'dd/MM/yyyy',
) =>
  isDateValid(dateToFormat, format)
    ? formatDate(parseDate(dateToFormat, format), 'yy.MM.dd')
    : '';

/**
 * Checks a given birth date corresponds to an age above given limit
 * @param {string} birthDate "dd/MM/yyyy" format
 */
export const getAge = (birthDate: string | Date, format = 'dd/MM/yyyy') => {
  const date =
    typeof birthDate === 'string' ? parseDate(birthDate, format) : birthDate;
  return differenceInYears(new Date(), date);
};

/**
 *
 * @param datestring the date in a string format
 * @param format such as dd/MM/yyyy, yyyy-MM-dd or MM/dd/yyyy
 */
export const parseUTCDate = (
  datestring?: string,
  format: DateFormat = 'dd/MM/yyyy',
) => {
  if (!datestring || !isDateValid(datestring, format)) {
    return null;
  }

  const parsedDate = parse(datestring, format, new Date());

  return new Date(
    Date.UTC(
      parsedDate.getFullYear(),
      parsedDate.getMonth(),
      parsedDate.getDate(),
    ),
  );
};

/**
 * Convert given date into an UTC date
 *
 * @param datestring the date in a string format
 */
export const getUTCDate = (dateString: string): Date | null => {
  const date = new Date(dateString);

  return !Number.isNaN(date.getTime())
    ? new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
          date.getMilliseconds(),
        ),
      )
    : null;
};

/**
 * Formats an ISO date (usually from the back-end) following the specified format
 * @param ISODate an ISO formatted date
 */
export const formatISODate = (
  ISODate: string,
  format: DateFormat = 'dd/MM/yyyy',
) => {
  const parsedDate = parseISO(ISODate);
  return isValid(parsedDate) ? formatDate(parsedDate, format) : '';
};

/**
 * Check if the argument is a date object with a valid date
 * @param date a possible date object
 */
export const checkIfDateisValid = (date: unknown): date is Date =>
  !!date && date instanceof Date && !Number.isNaN(Number(date));

/**
 * Return a formatted duration in the desired language
 * @param durationInMs duration in milliseconds
 * @param locale Locale object from date-fns
 * @param unit Force the unit to be used
 */
export const getDurationFromMs = (
  durationInMs: number,
  // locale: Locale,
  // TODO: change the any back to Locale (from date-fns) when TS is able to make it work
  // eslint-disable-next-line
  locale: any,
  unit?: TimeDistanceUnit,
) => {
  const startDate = new Date();
  const endDate = addMilliseconds(startDate, durationInMs);
  return formatDistanceStrict(endDate, startDate, { locale, unit });
};

/**
 * Return a formatted duration in the desired language
 * @param durationInMs duration in milliseconds
 * @param locale Locale object from date-fns
 * @param unit Force the unit to be used
 */
export const getDurationFromMinutes = (
  durationInMs: number,
  // TODO: change the any back to Locale (from date-fns) when TS is able to make it work
  // eslint-disable-next-line
  locale: any,
  unit?: TimeDistanceUnit,
) => getDurationFromMs(durationInMs * 60 * 1000, locale, unit);

type FormatNumberOptions = {
  fractionDigits: number;
  locale: string;
  removeTrailingZeros: boolean;
  value: number;
};
/**
 * Formats a number according to the given full locale (e.g. "fr-BE")
 */
export const formatNumber = ({
  fractionDigits,
  locale,
  removeTrailingZeros,
  value,
}: FormatNumberOptions) => {
  try {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: removeTrailingZeros ? 0 : fractionDigits,
    }).format(value);
  } catch (e) {
    logger.error(
      `[Core] formatNumber failed to work with given locale "${locale}"!`,
    );
    return value.toString();
  }
};

type FormatMoneyOptions = {
  currency: string;
  fractionDigits: number;
  locale: string;
};
/**
 * Formats a number into a currency display based on the full locale
 * (e.g. "fr-BE") and a given precision specifying how many fraction digits
 * should be displayed.
 *
 * It is a curried function which takes currency, locale and the wanted precision
 * as a first argument and returns a function taking the amount and the optional
 * `removeTrailingZeros` argument which is self explanatory (defaults to `false`).
 */
export const formatMoney =
  ({ currency, fractionDigits, locale }: FormatMoneyOptions) =>
  (amount: number, removeTrailingZeros = false) => {
    try {
      return new Intl.NumberFormat(locale, {
        currency,
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: removeTrailingZeros ? 0 : fractionDigits,
        style: 'currency',
      }).format(amount);
    } catch (e) {
      logger.error(
        `[Core] formatMoney failed to work with given locale "${locale}"!`,
      );
      return currency + amount.toString();
    }
  };

/**
 * Floor a number
 */
export const floorNumber = (value: number, decimal = 0): number => {
  const multiple = 10 ** decimal;
  return (
    Math.floor(parseFloat((value * multiple).toPrecision(10))) / multiple || 0
  );
};

/**
 * Round a number
 */
export const roundNumber = (
  value: number,
  decimal = 0,
  precision = decimal + 1,
): number => {
  const multiple = 10 ** decimal;
  return (
    Math.round(
      parseFloat((floorNumber(value, precision) * multiple).toPrecision(10)),
    ) / multiple || 0
  );
};

/**
 * Ceil a number
 */
export const ceilNumber = (
  value: number,
  decimal = 0,
  precision = decimal + 1,
): number => {
  const multiple = 10 ** decimal;
  return (
    Math.ceil(
      parseFloat((floorNumber(value, precision) * multiple).toPrecision(10)),
    ) / multiple || 0
  );
};

/**
 * Persist data in your chosen storage API with error management safety and
 * automatic data serialization.
 *
 * ## Usage
 * ```ts
 * // saves '{"the":"data","you":"want"}'
 * // in the local storage with the "someKey" key
 * persistIn(localStorage, 'someKey', { the: 'data', you: 'want' });
 * ```
 *
 * You can also provide an error callback to handle errors further:
 * ```ts
 * persistIn(
 *  localStorage,
 *  'someKey',
 *  { the: 'data', you: 'want' },
 *  () => {
 *    // Do something on failure
 *  },
 * );
 * ```
 *
 * @param storage
 * @param key
 * @param data
 */
export const persistIn = (
  storage: Storage,
  key: string,
  data: Serializable,
  onError: (e: unknown) => void = () => null,
) => {
  if (!['web', 'jest'].includes(getWorkingEnv())) {
    logger.error(
      '[Storage] [Persist] persistIn was used in a non browser env, this is a no-op',
    );
    return;
  }
  try {
    storage.setItem(key, JSON.stringify(data));
  } catch (e) {
    const message = e instanceof Error ? e.message : '';
    logger.error(`[Storage] [Persist] Error for key "${key}"`, message);
    onError(e);
  }
};

/**
 * Removes data from your chosen storage API with error management safety.
 *
 * ## Usage
 * ```ts
 * // removes 'aGivenKey'
 * removeFrom(localStorage, 'aGivenKey');
 * ```
 *
 * You can also provide an error callback to handle errors further:
 * ```ts
 * removeFrom(
 *  localStorage,
 *  'aGivenKey',
 *  () => {
 *    // Do something on failure
 *  },
 * );
 * ```
 *
 * @param storage
 * @param key
 */
export const removeFrom = (
  storage: Storage,
  key: string,
  onError: (e: Error | unknown) => void = () => null,
) => {
  if (!['web', 'jest'].includes(getWorkingEnv())) {
    logger.error(
      '[Storage] [Remove] removeFrom was used in a non browser env, this is a no-op',
    );
    return;
  }
  try {
    storage.removeItem(key);
  } catch (e) {
    const message = e instanceof Error ? e.message : '';
    logger.error(`[Storage] [Remove] Error for key "${key}"`, message);
    onError(e);
  }
};

/**
 * Reads data from your chosen storage API with error management safety and
 * automatic data unserialization.
 *
 * ## Usage
 * ```ts
 * // will return { the: 'data', you: 'want' } if the localStorage key "someKey"
 * // contains '{"the":"data","you":"want"}'
 * const data = readFrom(localStorage, 'someKey');
 * ```
 *
 * You can also provide an error callback to handle errors further:
 * ```ts
 * readFrom(
 *  localStorage,
 *  'someKey',
 *  () => {
 *    // Do something on failure
 *  },
 * );
 * ```
 *
 * @param storage
 * @param key
 * @param onError
 */
export const readFrom = (
  storage: Storage,
  key: string,
  onError: (e: unknown) => void = () => null,
): unknown => {
  if (!['web', 'jest'].includes(getWorkingEnv())) {
    logger.error(
      '[Storage] readFrom was use in a non browser env, this will always return null',
    );
    return null;
  }
  const rawData = storage.getItem(key);

  // Nothing was retreived so null is returned
  if (!rawData) {
    return null;
  }

  try {
    return JSON.parse(rawData);
  } catch (e) {
    const message = e instanceof Error ? e.message : '';
    logger.error(`[Storage] [Read] Error for key "${key}"`, message);
    onError(e);
    return null;
  }
};

type NonUndefined<T> = T extends undefined ? never : T;

type WithoutUndefinedKeys<T> = { [K in keyof T]: NonUndefined<T[K]> };

export const removeUndefined = <T extends Record<string, unknown>>(
  object: T,
): WithoutUndefinedKeys<T> =>
  Object.entries(object)
    .filter(([, value]) => value !== undefined)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      {} as WithoutUndefinedKeys<T>,
    );

/**
 * Check if the difference between now and the given date is bigger than the milliseconds given.
 * As an example, if I need to know if the date given is older of at least 5 minutes, I can check it with this function.
 *
 * @param dateToCheck The date to give to check if it's older than {ms} from now
 * @param ms The milliseconds given to check the difference
 */
export const checkIfDifferenceIsBiggerThanMillisecondsGiven = (
  dateToCheck: Date | undefined,
  ms: number,
): boolean => {
  const now = new Date();

  // If the date given is after now (which should not be possible) we return true so we could create a valid date afterward.
  // This should not happen, but better be safe than sorry.
  if (dateToCheck && !isAfter(dateToCheck, now)) {
    const difference = differenceInMilliseconds(now, dateToCheck);
    return difference > ms;
  }

  return true;
};

/**
 * Whether the app is running on mobile browser or not
 *
 * Note: this helper is not meant to be precise : it might not cover all use cases.
 * Its purpose is mainly to quickly detect a mobile browser.
 *
 * Source : https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser#answer-11381730
 *
 * @returns boolean
 */
export const isOnMobileBrowser = (): boolean => {
  if (typeof navigator !== 'undefined' && navigator && navigator.userAgent) {
    const webMobileRegex = [
      /Android/i,
      /BlackBerry/i,
      /iOS/i,
      /iPad/i,
      /iPhone/i,
      /iPod/i,
      /webOS/i,
      /Windows Phone/i,
    ];

    return webMobileRegex.some((toMatchItem) =>
      navigator.userAgent.match(toMatchItem),
    );
  }
  return false;
};

/**
 * Generates a uuid
 *
 * Duplicated from g1-network/src/helpers.ts to avoid extra dependency
 *
 * @returns string
 */
export const generateUuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/** Create a lazily loaded component */
export const lazifyComponent = <
  Module extends {
    [k in ExportName]: ComponentType<ComponentProps<Module[ExportName]>>;
  },
  ExportName extends keyof Module,
>(
  /** The export name of the component */
  componentName: ExportName,
  /** A factory returning a dynamic import of the file where the component is */
  importFactory: () => Promise<Module>,
): LazyExoticComponent<
  ComponentType | ComponentType<ComponentProps<Module[ExportName]>>
> =>
  lazy(() =>
    importFactory().then((moduleContent) => ({
      default: moduleContent[componentName],
    })),
  );

/**
 *
 * @param text string to latinize
 * @returns the string passed as parameter without any special character (letters a to z only) in lowercase
 */
export const latinizeString = (text: string) => {
  const textLowerCase = text.toLowerCase();
  const matchingCharacters: { [key: string]: string } = {
    á: 'a',
    ă: 'a',
    ắ: 'a',
    ặ: 'a',
    ằ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ǎ: 'a',
    â: 'a',
    ấ: 'a',
    ậ: 'a',
    ầ: 'a',
    ẩ: 'a',
    ẫ: 'a',
    ä: 'a',
    ǟ: 'a',
    ȧ: 'a',
    ǡ: 'a',
    ạ: 'a',
    ȁ: 'a',
    à: 'a',
    ả: 'a',
    ȃ: 'a',
    ā: 'a',
    ą: 'a',
    ᶏ: 'a',
    ẚ: 'a',
    å: 'a',
    ǻ: 'a',
    ḁ: 'a',
    ⱥ: 'a',
    ã: 'a',
    // æ: 'ae',
    // ǽ: 'ae',
    // ǣ: 'ae',
    ḃ: 'b',
    ḅ: 'b',
    ɓ: 'b',
    ḇ: 'b',
    ᵬ: 'b',
    ᶀ: 'b',
    ƀ: 'b',
    ƃ: 'b',
    ɵ: 'o',
    ć: 'c',
    č: 'c',
    ç: 'c',
    ḉ: 'c',
    ĉ: 'c',
    ɕ: 'c',
    ċ: 'c',
    ƈ: 'c',
    ȼ: 'c',
    ď: 'd',
    ḑ: 'd',
    ḓ: 'd',
    ȡ: 'd',
    ḋ: 'd',
    ḍ: 'd',
    ɗ: 'd',
    ᶑ: 'd',
    ḏ: 'd',
    ᵭ: 'd',
    ᶁ: 'd',
    đ: 'd',
    ɖ: 'd',
    ƌ: 'd',
    ı: 'i',
    ȷ: 'j',
    ɟ: 'j',
    ʄ: 'j',
    é: 'e',
    ĕ: 'e',
    ě: 'e',
    ȩ: 'e',
    ḝ: 'e',
    ê: 'e',
    ế: 'e',
    ệ: 'e',
    ề: 'e',
    ể: 'e',
    ễ: 'e',
    ḙ: 'e',
    ë: 'e',
    ė: 'e',
    ẹ: 'e',
    ȅ: 'e',
    è: 'e',
    ẻ: 'e',
    ȇ: 'e',
    ē: 'e',
    ḗ: 'e',
    ḕ: 'e',
    ⱸ: 'e',
    ę: 'e',
    ᶒ: 'e',
    ɇ: 'e',
    ẽ: 'e',
    ḛ: 'e',
    ḟ: 'f',
    ƒ: 'f',
    ᵮ: 'f',
    ᶂ: 'f',
    ǵ: 'g',
    ğ: 'g',
    ǧ: 'g',
    ģ: 'g',
    ĝ: 'g',
    ġ: 'g',
    ɠ: 'g',
    ḡ: 'g',
    ᶃ: 'g',
    ǥ: 'g',
    ḫ: 'h',
    ȟ: 'h',
    ḩ: 'h',
    ĥ: 'h',
    ⱨ: 'h',
    ḧ: 'h',
    ḣ: 'h',
    ḥ: 'h',
    ɦ: 'h',
    ẖ: 'h',
    ħ: 'h',
    í: 'i',
    ĭ: 'i',
    ǐ: 'i',
    î: 'i',
    ï: 'i',
    ḯ: 'i',
    ị: 'i',
    ȉ: 'i',
    ì: 'i',
    ỉ: 'i',
    ȋ: 'i',
    ī: 'i',
    į: 'i',
    ᶖ: 'i',
    ɨ: 'i',
    ĩ: 'i',
    ḭ: 'i',
    ꝺ: 'd',
    ꝼ: 'f',
    ᵹ: 'g',
    ꞃ: 'r',
    ꞅ: 's',
    ꞇ: 't',
    ǰ: 'j',
    ĵ: 'j',
    ʝ: 'j',
    ɉ: 'j',
    ḱ: 'k',
    ǩ: 'k',
    ķ: 'k',
    ⱪ: 'k',
    ꝃ: 'k',
    ḳ: 'k',
    ƙ: 'k',
    ḵ: 'k',
    ᶄ: 'k',
    ꝁ: 'k',
    ꝅ: 'k',
    ĺ: 'l',
    ƚ: 'l',
    ɬ: 'l',
    ľ: 'l',
    ļ: 'l',
    ḽ: 'l',
    ȴ: 'l',
    ḷ: 'l',
    ḹ: 'l',
    ⱡ: 'l',
    ꝉ: 'l',
    ḻ: 'l',
    ŀ: 'l',
    ɫ: 'l',
    ᶅ: 'l',
    ɭ: 'l',
    ł: 'l',
    ſ: 's',
    ẜ: 's',
    ẛ: 's',
    ẝ: 's',
    ḿ: 'm',
    ṁ: 'm',
    ṃ: 'm',
    ɱ: 'm',
    ᵯ: 'm',
    ᶆ: 'm',
    ń: 'n',
    ň: 'n',
    ņ: 'n',
    ṋ: 'n',
    ȵ: 'n',
    ṅ: 'n',
    ṇ: 'n',
    ǹ: 'n',
    ɲ: 'n',
    ṉ: 'n',
    ƞ: 'n',
    ᵰ: 'n',
    ᶇ: 'n',
    ɳ: 'n',
    ñ: 'n',
    ó: 'o',
    ŏ: 'o',
    ǒ: 'o',
    ô: 'o',
    ố: 'o',
    ộ: 'o',
    ồ: 'o',
    ổ: 'o',
    ỗ: 'o',
    ö: 'o',
    ȫ: 'o',
    ȯ: 'o',
    ȱ: 'o',
    ọ: 'o',
    ő: 'o',
    ȍ: 'o',
    ò: 'o',
    ỏ: 'o',
    ơ: 'o',
    ớ: 'o',
    ợ: 'o',
    ờ: 'o',
    ở: 'o',
    ỡ: 'o',
    ȏ: 'o',
    ꝋ: 'o',
    ꝍ: 'o',
    ⱺ: 'o',
    ō: 'o',
    ṓ: 'o',
    ṑ: 'o',
    ǫ: 'o',
    ǭ: 'o',
    ø: 'o',
    ǿ: 'o',
    õ: 'o',
    ṍ: 'o',
    ṏ: 'o',
    ȭ: 'o',
    ɛ: 'e',
    ᶓ: 'e',
    ɔ: 'o',
    ᶗ: 'o',
    ṕ: 'p',
    ṗ: 'p',
    ꝓ: 'p',
    ƥ: 'p',
    ᵱ: 'p',
    ᶈ: 'p',
    ꝕ: 'p',
    ᵽ: 'p',
    ꝑ: 'p',
    ꝙ: 'q',
    ʠ: 'q',
    ɋ: 'q',
    ꝗ: 'q',
    ŕ: 'r',
    ř: 'r',
    ŗ: 'r',
    ṙ: 'r',
    ṛ: 'r',
    ṝ: 'r',
    ȑ: 'r',
    ɾ: 'r',
    ᵳ: 'r',
    ȓ: 'r',
    ṟ: 'r',
    ɼ: 'r',
    ᵲ: 'r',
    ᶉ: 'r',
    ɍ: 'r',
    ɽ: 'r',
    ↄ: 'c',
    ꜿ: 'c',
    ɘ: 'e',
    ɿ: 'r',
    ś: 's',
    ṥ: 's',
    š: 's',
    ṧ: 's',
    ş: 's',
    ŝ: 's',
    ș: 's',
    ṡ: 's',
    ṣ: 's',
    ṩ: 's',
    ʂ: 's',
    ᵴ: 's',
    ᶊ: 's',
    ȿ: 's',
    ɡ: 'g',
    ᴑ: 'o',
    ᴓ: 'o',
    ᴝ: 'u',
    ť: 't',
    ţ: 't',
    ṱ: 't',
    ț: 't',
    ȶ: 't',
    ẗ: 't',
    ⱦ: 't',
    ṫ: 't',
    ṭ: 't',
    ƭ: 't',
    ṯ: 't',
    ᵵ: 't',
    ƫ: 't',
    ʈ: 't',
    ŧ: 't',
    ɐ: 'a',
    // ᴂ: 'ae',
    ǝ: 'e',
    ᵷ: 'g',
    ɥ: 'h',
    ʮ: 'h',
    ʯ: 'h',
    ᴉ: 'i',
    ʞ: 'k',
    ꞁ: 'l',
    ɯ: 'm',
    ɰ: 'm',
    ɹ: 'r',
    ɻ: 'r',
    ɺ: 'r',
    ⱹ: 'r',
    ʇ: 't',
    ʌ: 'v',
    ʍ: 'w',
    ʎ: 'y',
    ú: 'u',
    ŭ: 'u',
    ǔ: 'u',
    û: 'u',
    ṷ: 'u',
    ü: 'u',
    ǘ: 'u',
    ǚ: 'u',
    ǜ: 'u',
    ǖ: 'u',
    ṳ: 'u',
    ụ: 'u',
    ű: 'u',
    ȕ: 'u',
    ù: 'u',
    ủ: 'u',
    ư: 'u',
    ứ: 'u',
    ự: 'u',
    ừ: 'u',
    ử: 'u',
    ữ: 'u',
    ȗ: 'u',
    ū: 'u',
    ṻ: 'u',
    ų: 'u',
    ᶙ: 'u',
    ů: 'u',
    ũ: 'u',
    ṹ: 'u',
    ṵ: 'u',
    ⱴ: 'v',
    ꝟ: 'v',
    ṿ: 'v',
    ʋ: 'v',
    ᶌ: 'v',
    ⱱ: 'v',
    ṽ: 'v',
    ẃ: 'w',
    ŵ: 'w',
    ẅ: 'w',
    ẇ: 'w',
    ẉ: 'w',
    ẁ: 'w',
    ⱳ: 'w',
    ẘ: 'w',
    ẍ: 'x',
    ẋ: 'x',
    ᶍ: 'x',
    ý: 'y',
    ŷ: 'y',
    ÿ: 'y',
    ẏ: 'y',
    ỵ: 'y',
    ỳ: 'y',
    ƴ: 'y',
    ỷ: 'y',
    ỿ: 'y',
    ȳ: 'y',
    ẙ: 'y',
    ɏ: 'y',
    ỹ: 'y',
    ź: 'z',
    ž: 'z',
    ẑ: 'z',
    ʑ: 'z',
    ⱬ: 'z',
    ż: 'z',
    ẓ: 'z',
    ȥ: 'z',
    ẕ: 'z',
    ᵶ: 'z',
    ᶎ: 'z',
    ʐ: 'z',
    ƶ: 'z',
    ɀ: 'z',
    // œ: 'oe',
    ₐ: 'a',
    ₑ: 'e',
    ᵢ: 'i',
    ⱼ: 'j',
    ₒ: 'o',
    ᵣ: 'r',
    ᵤ: 'u',
    ᵥ: 'v',
    ₓ: 'x',
  };
  const regex = /([\u0300-\u036f]|[^0-9a-zA-Z\s])/g;
  const matchingIndice = textLowerCase.match(regex);

  if (!matchingIndice) {
    return text;
  }

  return textLowerCase.replace(
    regex,
    (match: string) => matchingCharacters[match] || match,
  );
};
