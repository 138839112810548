import {
  BettingHistoryElementType,
  BettingHistoryTicketType,
  isEventLostStatus,
  isEventPushedStatus,
  isEventWinStatus,
} from '@gaming1/g1-betting';
import {
  AssociatedPromoType,
  BettingSlipStatus,
  BettingSlipType,
} from '@gaming1/g1-requests-betting';
import { IconType } from '@gaming1/g1-ui';

/**
 * Take a ticket from the history to know which translation key to return through its Status.
 * @param ticket The ticket taken from the history
 */
export const getCloseHistoryTicketStatus = (
  ticket: BettingHistoryTicketType,
): string => {
  if (ticket.Status === BettingSlipStatus.Won) {
    return 'history.ticket.won';
  }
  if (ticket.Status === BettingSlipStatus.Lost) {
    return 'history.ticket.lost';
  }
  if (ticket.Status === BettingSlipStatus.Refund) {
    return 'history.ticket.refund';
  }
  if (ticket.Status === BettingSlipStatus.Cancel) {
    return 'history.ticket.cancel';
  }
  if (ticket.Status === BettingSlipStatus.Cashout) {
    return 'history.ticket.cashout';
  }
  return 'history.ticket.unknown';
};

/**
 * Take a bettingSlip type, check is value to return the correct translation key.
 *
 * @param type the bettingSlip type (single, combi, system)
 */
export const giveBettingSlipTypeTranslationKey = (type: BettingSlipType) => {
  if (type === BettingSlipType.Single) {
    return 'historyElement.bettingSlipType.single';
  }
  if (type === BettingSlipType.Combi) {
    return 'historyElement.bettingSlipType.combi';
  }
  if (type === BettingSlipType.System) {
    return 'historyElement.bettingSlipType.system';
  }

  return 'historyElement.bettingSlipType.unknown';
};

export const getIconForStatus = (info: BettingHistoryElementType): IconType => {
  if (isEventLostStatus(info)) {
    return 'DiscCross';
  }
  if (isEventWinStatus(info)) {
    return 'DiscCheck';
  }
  if (isEventPushedStatus(info)) {
    return 'CircleStop';
  }
  return 'Clock';
};

export const extractCashoutId = (
  history: BettingHistoryTicketType | undefined,
) =>
  history && history.CashoutData
    ? history.CashoutData.CashoutInformation?.BettingSlipId ||
      history.CashoutData.CashedOutData?.BettingSlipId
    : undefined;

/**
 * Take the associated promotions array and a bettingSlip type.
 * If there is a daily offer associated promotion return the daily offer translation key for it.
 * If there is a super odds offer associated promotion return the super odds translation key for it.
 * If there is no associated promotion return the correct translation key for the type of the ticket.
 *
 * @param type the bettingSlip type (single, combi, system)
 * @param associatedPromotion the associated promotions array (Unknown, Boostus, DailyOffer, BoostedOdds,)
 */
export const giveClickableContainerTitleTranslationKey = (
  type: BettingSlipType,
  associatedPromotion: Array<AssociatedPromoType | null> | undefined,
) => {
  if (
    associatedPromotion &&
    associatedPromotion.includes(AssociatedPromoType.DailyOffer)
  ) {
    return 'historyElement.associatedPromoType.DailyOffer';
  }
  if (
    associatedPromotion &&
    associatedPromotion.includes(AssociatedPromoType.SuperOdds)
  ) {
    return 'historyElement.associatedPromoType.SuperOdds';
  }
  return giveBettingSlipTypeTranslationKey(type);
};
