import { NotificationType } from '../../../notification/types';

export type ItemValid = {
  isOutcomeValid: boolean;
  odd: number | undefined;
  notification: NotificationType | undefined;
};

export const isItemValid = (item: ItemValid) => {
  if (!item) {
    return false;
  }
  if (!item.odd || item.odd < 1) {
    return false;
  }
  if (item.notification && item.notification.Level) {
    return false;
  }
  return item.isOutcomeValid;
};

export const areItemsValid = (items: ItemValid[]) =>
  items.every((item) => isItemValid(item));
