import React, { ContextType, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EProtectedFeature } from '@gaming1/g1-requests';
import {
  getSecurityPinCodeInfoRequestStateSelector,
  getSecurityPinCodeInfoSelector,
} from '@gaming1/g1-user';
import { PinCodeContext } from '@gaming1/g1-user-api';

export const PinCodeProvider: FC = ({ children }) => {
  const pinCodeRequestState = useSelector(
    getSecurityPinCodeInfoRequestStateSelector,
  );
  const pinCodeInfo = useSelector(getSecurityPinCodeInfoSelector);
  const contextValue: ContextType<typeof PinCodeContext> = useMemo(
    () => ({
      isPinCodeEnabled:
        (pinCodeInfo && pinCodeInfo?.IsSecurityPinCodeEnabled) || false,
      protectedFeatures:
        pinCodeInfo && pinCodeInfo?.ProtectedFeatures
          ? pinCodeInfo.ProtectedFeatures.map(
              (value) =>
                EProtectedFeature[value] as keyof typeof EProtectedFeature,
            )
          : [],
      requestState: pinCodeRequestState,
    }),
    [pinCodeRequestState, pinCodeInfo],
  );
  return (
    <PinCodeContext.Provider value={contextValue}>
      {children}
    </PinCodeContext.Provider>
  );
};
