import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getShopHistory } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../actions';
import { LoyaltyEpic } from '../../types';
import { getShopHistoryResponse } from '../codecs';
import { getShopHistoryErrorMessages } from '../errorMessages';
import { formatShopHistory } from '../format';

export const getShopHistoryEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getShopHistory.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getShopHistory(payload)).pipe(
        mapGuard(getShopHistoryResponse),
        map((response) => formatShopHistory(payload, response)),
        map(actions.getShopHistory.success),
        catchError((err) =>
          of(
            actions.getShopHistory.failure(
              createFailurePayload(err, getShopHistoryErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
