import styled, { css } from 'styled-components';

import { PrismicSpacerSlice } from '@gaming1/g1-cms';

export type PrismicSpacerProps = {
  /** Prismic 'Spacer' slice */
  slice: PrismicSpacerSlice;
  /** Test ID */
  testId?: string;
};

/**
 * This component renders a prismic `Spacer` slice
 * It renders a small element with specified size that acts as a spacer between other components
 */
export const PrismicSpacer = styled.div.attrs<PrismicSpacerProps>((props) => ({
  'data-testid': props.testId || 'prismic-spacer',
}))<PrismicSpacerProps>`
  display: inline-block;

  ${({ slice }) => {
    if (!slice.primary.size) {
      return css`
        flex: 1;
      `;
    }
    return css`
      width: ${slice.primary.size};
      height: ${slice.primary.size};
    `;
  }};
`;
