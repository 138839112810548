import React, { FocusEvent, forwardRef } from 'react';
import { useField } from 'react-final-form';

import { TextInput, TextInputProps } from '@gaming1/g1-ui';
import { getInputFieldValidator } from '@gaming1/g1-utils';

import { useInputFieldError } from '../../hooks';
import { FieldProps } from '../types';

export type TextInputFieldProps = FieldProps<TextInputProps> & {
  /** Blur handler */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  /** Focus handler */
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  /** Change handler */
  onValueChange?: (value: string) => void;
  /** html `name` attribute that will be used for the native input element */
  htmlName?: string;
};

/**
 * Component that bind Field (from final-form) to TextInput (from g1-ui)
 */
export const TextInputField = forwardRef<HTMLInputElement, TextInputFieldProps>(
  (
    {
      disabled = false,
      format,
      i18nNamespace = 'user',
      htmlName,
      name,
      onBlur,
      onFocus,
      onValueChange,
      parse,
      required = false,
      showErrors = true,
      spellCheck = false,
      subscription = {
        dirtySinceLastSubmit: true,
        error: true,
        touched: true,
        submitError: true,
        value: true,
      },
      validate = null,
      ...inputProps
    },
    ref,
  ) => {
    const validator = getInputFieldValidator(validate, required);
    const { input, meta } = useField(name, {
      format,
      parse,
      subscription,
      validate: validator,
    });
    const errorMessage = useInputFieldError(meta, i18nNamespace);
    return (
      <TextInput
        errors={errorMessage && showErrors ? [errorMessage] : undefined}
        id={name}
        name={htmlName}
        ref={ref}
        onValueChange={(
          newValue: Parameters<
            NonNullable<TextInputFieldProps['onValueChange']>
          >[0],
        ) => {
          if (onValueChange) {
            onValueChange(newValue);
          }
          input.onChange(newValue);
        }}
        onBlur={(
          e: Parameters<NonNullable<TextInputFieldProps['onBlur']>>[0],
        ) => {
          if (onBlur) {
            onBlur(e);
          }
          input.onBlur(e);
        }}
        onFocus={(
          e: Parameters<NonNullable<TextInputFieldProps['onFocus']>>[0],
        ) => {
          if (onFocus && !disabled) {
            onFocus(e);
          }
          if (!disabled) {
            input.onFocus(e);
          }
        }}
        value={input.value}
        spellCheck={spellCheck}
        disabled={disabled}
        {...inputProps}
      />
    );
  },
);
