import React, { ContextType, useContext, VFC } from 'react';

import { PrismicPageContext } from '../PrismicPageContext';

import { PrismicChild } from './PrismicChild';
import { PrismicChildrenContext } from './PrismicChildrenContext';
import { PrismicChildrenProps } from './types';

const PrismicChildrenComponent: VFC<PrismicChildrenProps> = ({
  slices,
  childrenIds,
  parentIds = [],
}) => {
  const { slicesWithIds } = useContext(PrismicPageContext);
  const ids =
    typeof childrenIds === 'string' ? childrenIds.split('\n') : childrenIds;
  const children =
    slices ||
    ids?.map((child_id) => slicesWithIds && slicesWithIds[child_id]) ||
    [];
  return (
    <>
      {children
        .filter((slice) => !!slice)
        .map((slice, index) => (
          <PrismicChild
            parentIds={parentIds}
            slice={slice}
            key={`prismic-child-${index + 1}`}
          />
        ))}
    </>
  );
};

const contextValue: ContextType<typeof PrismicChildrenContext> = {
  component: PrismicChildrenComponent,
};

/**
 * Renders Prismic children slices
 * The slices can be specified directly, or by passing their IDs
 */
export const PrismicChildren: VFC<PrismicChildrenProps> = ({ ...props }) => (
  <PrismicChildrenContext.Provider value={contextValue}>
    {/* provide the component reference in a context to allow its children to
    render it without creating circular dependencies */}
    <PrismicChildrenComponent {...props} />
  </PrismicChildrenContext.Provider>
);
