import {
  EShopProductAvailableStatus,
  EShopPurchaseResponseStatus,
  GetAvailableTicketsStatus,
} from '@gaming1/g1-requests';

export const availableTicketsErrorMessages = {
  [GetAvailableTicketsStatus.InternalError]: 'core:error.internalServer', // 0
  [GetAvailableTicketsStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
  [GetAvailableTicketsStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 3
};

export const unavailableProductsErrorMessages = {
  [EShopProductAvailableStatus.InternalError]: 'core:error.internalServer', // 0
  [EShopProductAvailableStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
};

export const purchaseErrorMessages = {
  [EShopPurchaseResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EShopPurchaseResponseStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EShopPurchaseResponseStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
  [EShopPurchaseResponseStatus.InvalidShop]: 'loyalty:error.invalidShop', // 4
  [EShopPurchaseResponseStatus.InvalidDeliveryInformation]:
    'loyalty:error.invalidDeliveryInformation', // 5
  [EShopPurchaseResponseStatus.CanNotUseDelivery]:
    'loyalty:error.canNotUseDelivery', // 6
  [EShopPurchaseResponseStatus.PaymentError]: 'loyalty:error.paymentError', // 7
  [EShopPurchaseResponseStatus.DeliveryError]: 'loyalty:error.deliveryError', // 8
};
