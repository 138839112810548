import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Heading, Separator } from '@gaming1/g1-ui';

import { SearchCategoryResultWrapper, SeeAllLink } from './styles';

type SearchCategoryResultProps = {
  /** The category name to be used in testid */
  testId: string;
  /** The translated category name to display */
  categoryName: string;
  /** Number of results to display */
  numberOfResults: number;
  /** The source of the all results page */
  allResultsPageUrl?: string;
};

/** Render a header with the category title, the number of results and a separator */
export const SearchCategoryResult: FC<SearchCategoryResultProps> = ({
  testId = 'search-category-result-wrapper',
  categoryName,
  children,
  numberOfResults,
  allResultsPageUrl,
}) => {
  const { t } = useTranslation('core');

  const { space } = useTheme();

  return (
    <SearchCategoryResultWrapper data-testid={testId}>
      <Box flexDirection="row" justifyContent="space-between">
        <Box flexDirection="row">
          <Heading mt={0} level="h4">
            {categoryName}
          </Heading>
          <Heading mt={0} level="h4" ml={space.xxxs}>
            ({numberOfResults})
          </Heading>
        </Box>
        {allResultsPageUrl ? (
          <SeeAllLink to={allResultsPageUrl} data-testid="see-all-link">
            {t('search.seeAll')}
          </SeeAllLink>
        ) : null}
      </Box>
      <Box mb={space.sm}>
        <Separator data-testid="category-result-separator" />
      </Box>
      {children}
    </SearchCategoryResultWrapper>
  );
};
