import styled from 'styled-components';

import { elevation, spaces } from '@gaming1/g1-style';

export const HistoryCardContainer = styled.div`
  ${elevation(2)}
  margin-top: ${spaces('sm')};
  border-radius: ${spaces('xxs')};
`;

export const HistoryCardWrapper = styled.div`
  padding: ${spaces('sm')} ${spaces('xs')} 0 ${spaces('xs')};
`;
