import { identity, Observable, pipe } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ApplicationWithBettingState, BettingActions } from '../../store/types';
import * as actions from '../actions';
import { betPipeInfoGetterSelector } from '../selectors';
import { BetPipeIdentifier } from '../types';

export const takeUntilPipeIsCancelled = (
  betPipeId: string | undefined,
  action$: Observable<BettingActions>,
) =>
  pipe(
    betPipeId
      ? takeUntil(
          action$.pipe(
            filter(isActionOf(actions.cancelBetPipe)),
            filter(({ payload }) => payload.betPipeId === betPipeId),
          ),
        )
      : map(identity),
  );

export const extractBettingSlipId = (
  payload: BetPipeIdentifier,
  betPipeId: string,
  state: ApplicationWithBettingState,
) =>
  payload.bettingSlipId ||
  betPipeInfoGetterSelector(state)(betPipeId)?.bettingSlipId;
