import React, { useContext, VFC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { userLoggedInSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { useInboxPublicComponents } from '@gaming1/g1-inbox-api';
import { Icon, useMedia } from '@gaming1/g1-ui';
import { userPublicRoutes } from '@gaming1/g1-user-api';

import { useRoutePath } from '../../../routing/hooks';

import {
  IconProfileWrapper,
  InboxNotificationContainer,
  TopNavLinks,
} from './styles';

/**
 * When logged out, display registration and login links
 * Wheen logged in, display a link to the profile page
 */
export const AccountLinks: VFC = () => {
  const { colors } = useContext(ThemeContext);
  const { mediaBP } = useMedia();
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const { t } = useTranslation('user');
  const { InboxNotification } = useInboxPublicComponents();
  const config = useContext(ConfigContext);
  const getUserRoutePath = useRoutePath(userPublicRoutes);

  const size = ['xl', 'xxl'].includes(mediaBP) ? '16px' : '12px';

  // TODO: add this notification when messages are implemented
  // <NotificationTag />
  return (
    <>
      {/* We should not display anything while 'isUserLoggedIn === null' (pending) */}
      {isUserLoggedIn === true && (
        <Link to={getUserRoutePath('profile')}>
          <IconProfileWrapper data-testid="topnav-profile-button">
            <Icon
              id="profile-link"
              type="Profile"
              height={size}
              width={size}
              fill={colors.topNavText}
              testId="topnav-profile"
            />
            {config.user.inbox && InboxNotification && (
              <InboxNotificationContainer>
                <InboxNotification />
              </InboxNotificationContainer>
            )}
          </IconProfileWrapper>
        </Link>
      )}
      {isUserLoggedIn === false && (
        <>
          <TopNavLinks
            colorType="secondary"
            to={getUserRoutePath('registration')}
            data-testid="topnav-registration"
          >
            {t('topNav.registration')}
          </TopNavLinks>
          <TopNavLinks
            colorType="primary"
            ml="sm"
            to={getUserRoutePath('login')}
            data-testid="topnav-login"
          >
            {t('topNav.login')}
          </TopNavLinks>
        </>
      )}
    </>
  );
};
