import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import {
  actions,
  searchHistorySelector,
  userSearchTermSelector,
} from '@gaming1/g1-core';
import { persistIn, readFrom } from '@gaming1/g1-utils';

import { usePrivacySettings } from '../../../tracking/hooks/usePrivacySettings';

const SEARCH_HISTORY_LOCAL_STORAGE_NAME = 'searchHistory';

/** Manage search history */
export const useSearchHistory = (term?: string) => {
  const defaultTerm = useSelector(userSearchTermSelector);
  const userSearchTerm = term == null ? defaultTerm : term;

  const dispatch = useDispatch();

  const config = useConfig();
  const { getUserConsentStatusForPurpose } = usePrivacySettings();
  const uxDataConsent = !!getUserConsentStatusForPurpose(
    config.core.privacySettings.purposeIDs.uxImprovement,
  );

  const isStringArray = (input: unknown): input is string[] =>
    Array.isArray(input) && input.every((item) => typeof item === 'string');

  const initializeSearchHistory = useCallback(() => {
    // Privacy settings don't allow to store this kind of data
    if (!uxDataConsent) {
      return [];
    }

    const searchHistory = readFrom(
      localStorage,
      SEARCH_HISTORY_LOCAL_STORAGE_NAME,
    );

    // Stored searchHistory is invalid or inexistant. Let's create a fresh one!
    if (!isStringArray(searchHistory)) {
      persistIn(localStorage, SEARCH_HISTORY_LOCAL_STORAGE_NAME, []);
      return [];
    }

    return searchHistory;
  }, [uxDataConsent]);

  const searchHistory = useSelector(searchHistorySelector);

  useEffect(() => {
    const loadedHistory = initializeSearchHistory();
    if (searchHistory === null && loadedHistory !== null) {
      dispatch(actions.setSearchHistory({ history: loadedHistory }));
    }
  }, [dispatch, initializeSearchHistory, searchHistory]);

  const clearHistory = useCallback(() => {
    persistIn(localStorage, SEARCH_HISTORY_LOCAL_STORAGE_NAME, []);
    dispatch(actions.setSearchHistory({ history: [] }));
  }, [dispatch]);

  useEffect(() => {
    if (
      uxDataConsent &&
      userSearchTerm.length > 1 &&
      searchHistory &&
      isStringArray(searchHistory) &&
      !searchHistory.includes(userSearchTerm)
    ) {
      const newSearchHistoryState = [userSearchTerm, ...searchHistory].slice(
        0,
        6,
      );
      persistIn(
        localStorage,
        SEARCH_HISTORY_LOCAL_STORAGE_NAME,
        newSearchHistoryState,
      );
      dispatch(actions.setSearchHistory({ history: newSearchHistoryState }));
    }
  }, [dispatch, searchHistory, userSearchTerm, uxDataConsent]);

  return { searchHistory, clearHistory, isStringArray };
};
