import React, { FC } from 'react';

import { TableContainer } from './styles';

export type TableProps = {
  /** Highlight every alternate line. */
  isAlternate?: boolean;
  /** Adds vertical space inside each cell. */
  isLarger?: boolean;
  /** Displays the borders for each cell */
  hasCellBorders?: boolean;
  /** An optional testId */
  testId?: string;
};

export const Table: FC<TableProps> = ({
  isAlternate = false,
  isLarger = false,
  hasCellBorders = true,
  testId,
  children,
}) => (
  <TableContainer
    isAlternate={isAlternate}
    isLarger={isLarger}
    hasCellBorders={hasCellBorders}
    data-testid={testId}
  >
    {children}
  </TableContainer>
);
