/**
 * Loads the provided URL into a `<script>` tag only once and returns a Promise
 * which resolves when the script is loaded. When said script adds properties to
 * the `window` object, you'll still have to augment types yourself.
 */
export const loadScript = (url: string): Promise<string> => {
  if (typeof window !== 'object') {
    return Promise.reject(
      new Error('Scripts cannot be added in a non browser env'),
    );
  }
  return document.body.querySelector(`script[src="${url}"]`)
    ? Promise.resolve('script was already loaded')
    : new Promise((resolve, reject) => {
        const scriptTag = document.createElement('script');

        scriptTag.addEventListener('load', () => resolve('script loaded'));
        scriptTag.addEventListener('error', reject);

        scriptTag.setAttribute('async', 'true');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('src', url);

        document.body.appendChild(scriptTag);
      });
};
