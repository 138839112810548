import React, { FC } from 'react';

import { Modal } from '@gaming1/g1-ui';
import { PinCodeFormModalProps } from '@gaming1/g1-user-api';

import { PinCodeForm } from '../PinCodeForm';

/** Multifactor authentication pin code form modal */
export const PinCodeFormModal: FC<PinCodeFormModalProps> = ({
  loading,
  modalId = 'pin-code-form-modal',
  ...rest
}) => (
  <Modal modalId={modalId} {...rest}>
    <PinCodeForm loading={loading} {...rest} />
  </Modal>
);
