import styled from 'styled-components';
import { textAlign, TextAlignProps } from 'styled-system';

import { fontSizes, fontWeight, media, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const PrismicWrapper = styled.div`
  margin-top: ${spaces('xxs')};
  margin-bottom: ${spaces('xxs')};
  width: 100%;

  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const PrismicContentGroupWrapper = styled.div`
  padding-top: ${spaces('sm')};
  padding-bottom: ${spaces('sm')};

  > div {
    margin-top: 0;
  }
`;

export type PrismicPageContentWrapperProps = TextAlignProps;

/** This wrapper can be used to define context related styles */
export const PrismicPageContentWrapper = styled(
  Box,
)<PrismicPageContentWrapperProps>`
  ${textAlign}
`;

/** Title for a group of blog articles */
export const PrismicBlogGroupTitle = styled(Box)`
  align-items: center;
  flex-direction: row;
  font-size: ${fontSizes('lg')};
  ${fontWeight('bold')}
  margin-top: ${spaces('sm')};
  margin-bottom: ${spaces('xs')};

  ${media.xs} {
    font-size: ${fontSizes('xxl')};
  }
`;

/** Wrapper for a group of blog articles */
export const PrismicBlogGroupWrapper = styled(Box)`
  padding: 0 ${spaces('sm')};
`;
