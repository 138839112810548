import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { CoreActions, coreActions } from '@gaming1/g1-core';

import { authenticationReducer } from '../authentication/store/reducer';
import { gamingReducer } from '../gaming/store/reducer';
import { legalDepositLimitReducer } from '../legalDepositLimit/store/reducer';
import { legalModalReducer } from '../legalModal/store/reducer';
import { licensesReducer } from '../licenses/store/reducer';
import { limitsReducer } from '../limits/store/reducer';
import { multifactorAuthenticationReducer } from '../multifactorAuthentication/store/reducer';
import { passwordRecoveryReducer } from '../passwordRecovery/store/reducer';
import { passwordUpdateReducer } from '../passwordUpdate/store/reducer';
import { personalDataReducer } from '../personalData/store/reducer';
import { phoneValidationReducer } from '../phoneValidation/store/reducer';
import { privacyConditionsReducer } from '../privacyConditions/store/reducer';
import { registrationReducer } from '../registration/store/reducer';
import { selfExclusionReducer } from '../selfExclusion/store/reducer';
import { settingsReducer } from '../settings/store/reducer';
import { termsAndConditionsReducer } from '../termsAndConditions/store/reducer';

import { UserActions, UserState } from './types';

const globalReducer = combineReducers<UserState, UserActions>({
  authentication: authenticationReducer,
  gaming: gamingReducer,
  legalDepositLimit: legalDepositLimitReducer,
  licenses: licensesReducer,
  limits: limitsReducer,
  legalModal: legalModalReducer,
  multifactorAuthentication: multifactorAuthenticationReducer,
  personalData: personalDataReducer,
  passwordUpdate: passwordUpdateReducer,
  passwordRecovery: passwordRecoveryReducer,
  phoneValidation: phoneValidationReducer,
  privacyConditions: privacyConditionsReducer,
  registration: registrationReducer,
  selfExclusion: selfExclusionReducer,
  settings: settingsReducer,
  termsAndCondition: termsAndConditionsReducer,
});

/**
 * The purpose of this root reducer is to reset the state when specific actions
 * are successfully made. See https://alligator.io/redux/reset-state-redux
 * for more informations.
 */
export const userReducer: Reducer<UserState, UserActions> = (
  state: UserState | undefined,
  action: UserActions | CoreActions,
) =>
  globalReducer(
    getType(coreActions.loggedOutUser) === action.type &&
      action.payload === true
      ? undefined
      : state,
    action as UserActions,
  );
