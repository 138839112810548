import { logger } from '../logger';
import {
  getLogger,
  getLoggers,
  getLoglevelNameFromNumber,
  getLoglevelNumberFromName,
  LogLevelNames,
  LogLevelNumbers,
} from '../loglevel';

import { conditionalWindow } from './conditionalWindow';
import { getWorkingEnv } from './getWorkingEnv';
import { getSearchParam } from './internals';

export const LOCAL_STORAGE_LEVEL_KEY = 'logger_level';

/**
 * Change one (if loggerName provided) or all (if not) loglevel instance(s)
 * level.
 * If the level is provided as the first argument, it will be used
 * Else the param value "logger" will be read from the current url and used if
 * valid. If not, the local storage will be checked to restore the previously
 * selected logger level
 * @param [options]
 * @param [options.level] the desired log level. Can be 'trace', 'debug',
 *   'info', 'warn', 'error', 'silent' or undefined.
 * @param [options.loggerName] the logger instance name.
 * @param [options.canStoreValue] if false, will not save the
 * current level to localStorage
 */
export const setLoggerLevel = ({
  level,
  loggerName,
  canStoreValue = true,
}: {
  level?: LogLevelNames;
  loggerName?: string;
  canStoreValue?: boolean;
} = {}): LogLevelNumbers | null => {
  const loggerLevelParam = getSearchParam('logger');
  let loggerLevelStorage: null | string = null;
  const isRunningInReactNative = getWorkingEnv() === 'react-native';
  if (!isRunningInReactNative) {
    try {
      if (
        typeof conditionalWindow !== 'undefined' &&
        conditionalWindow.localStorage
      ) {
        loggerLevelStorage = conditionalWindow.localStorage.getItem(
          LOCAL_STORAGE_LEVEL_KEY,
        );
      }
    } catch {
      // Could not get the previous value
    }
  }

  // If the function is called without the level argument, the level is searched
  // in the current url, with the value of the "logger" url param value or
  // in the local storage
  const wantedLevel = level || loggerLevelParam || loggerLevelStorage;
  let source = 'none';
  if (level) {
    source = 'function call';
  } else if (loggerLevelParam) {
    source = 'logger url param';
  } else if (loggerLevelStorage) {
    source = 'local storage';
  }
  if (!wantedLevel) {
    return null;
  }
  const levelFromName = getLoglevelNumberFromName(wantedLevel.toUpperCase());

  if (levelFromName == null) {
    return null;
  }

  logger.debug(
    `[Env] Logger ${
      loggerName || 'global'
    } level set to ${getLoglevelNameFromNumber(
      levelFromName,
    )}. Source: ${source}`,
  );

  const wasManuallyChangedGlobally = !loggerName && (level || loggerLevelParam);

  if (wasManuallyChangedGlobally && canStoreValue && !isRunningInReactNative) {
    try {
      if (
        typeof conditionalWindow !== 'undefined' &&
        conditionalWindow.localStorage
      ) {
        conditionalWindow.localStorage.setItem(
          LOCAL_STORAGE_LEVEL_KEY,
          wantedLevel,
        );
      }
    } catch {
      // Could not store the level in local storage
    }
  }

  // Either set the new level to one logger or to all of them
  if (loggerName) {
    getLogger(loggerName).setLevel(levelFromName);
  } else {
    Object.values(getLoggers()).forEach((loggerInstance) => {
      loggerInstance.setLevel(levelFromName);
    });
  }
  return levelFromName;
};
