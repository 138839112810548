import * as t from 'io-ts';

import {
  KeyValueType,
  Notification,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

export const typedKeyValueCodec = t.intersection(
  [
    t.type({
      Type: createEnumType<KeyValueType>(KeyValueType),
    }),
    t.partial({
      Key: t.string,
      Value: t.string,
    }),
  ],
  'typedKeyValueCodec',
);

export const notificationCodec: t.Type<Notification> = t.recursion(
  'notificationCodec',
  () =>
    t.intersection([
      t.type({
        Level: createEnumType<NotificationLevel>(NotificationLevel),
        Code: createEnumType<NotificationCode>(NotificationCode),
      }),
      t.partial({
        Message: t.string,
        NotificationParameters: t.array(t.union([typedKeyValueCodec, t.null])),
        InnerNotifications: t.array(t.union([notificationCodec, t.null])),
      }),
    ]),
);

export const elementNotificationCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      MarketId: t.number,
      OutcomeId: t.number,
    }),
    t.partial({
      Notification: notificationCodec,
    }),
  ],
  'elementNotificationCodec',
);
