import * as t from 'io-ts';

import { TradingState } from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import {
  eventCodec,
  leagueCodec,
  marketCodec,
  outcomeCodec,
  playerPropCodec,
} from './entities';

export const leagueUpdateCodec = t.partial(
  {
    NewEntity: t.union([t.null, leagueCodec]),
    IdToRemove: t.union([t.null, t.number]),
    // Not used since there is for the moment no way to update a league
    // UpdateEntityId: t.union([t.null, t.number]),
  },
  'LeagueUpdateCodec',
);

export const eventUpdateCodec = t.intersection(
  [
    t.type({
      LeagueId: t.number,
    }),
    t.partial({
      StartDate: t.union([t.null, t.string]),
      IsBlocked: t.union([t.null, t.boolean]),
      NbTotalMarkets: t.union([t.null, t.number]),
      NewEntity: t.union([t.null, eventCodec]),
      IdToRemove: t.union([t.null, t.number]),
      IdToUpdate: t.union([t.null, t.number]),
    }),
  ],
  'EventUpdateCodec',
);

export const marketUpdateCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
    }),
    t.partial({
      StateUpdate: createEnumType<TradingState>(TradingState),
      NewEntity: t.union([t.null, marketCodec]),
      IdToRemove: t.union([t.null, t.number]),
      IdToUpdate: t.union([t.null, t.number]),
    }),
  ],
  'MarketUpdateCodec',
);

export const outcomeUpdateCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      MarketId: t.number,
    }),
    t.partial({
      NewOdd: t.union([t.null, t.number]),
      NewProviderProbabilities: t.union([t.null, t.number]),
      NewEntity: t.union([t.null, outcomeCodec]),
      IdToRemove: t.union([t.null, t.number]),
      IdToUpdate: t.union([t.null, t.number]),
    }),
  ],
  'OutcomeUpdateCodec',
);

export const playerPropUpdateCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      RowCount: t.number,
    }),
    t.partial({
      NewEntity: t.union([t.null, playerPropCodec]),
      IdToRemove: t.union([t.null, t.number]),
      IdToUpdate: t.union([t.null, t.number]),
    }),
  ],
  'playerPropUpdateCodec',
);

export const playerMarketUpdateCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      StateUpdate: createEnumType<TradingState>(TradingState),
      PlayerPropId: t.number,
    }),
    t.partial({
      NewEntity: t.union([t.null, marketCodec]),
      IdToRemove: t.union([t.null, t.number]),
      IdToUpdate: t.union([t.null, t.number]),
    }),
  ],
  'playerMarketUpdateCodec',
);

export const playerOutcomeUpdateCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      MarketId: t.number,
      NewOdd: t.number,
      NewProviderProbabilities: t.number,
    }),
    t.partial({
      NewEntity: t.union([t.null, outcomeCodec]),
      IdToRemove: t.union([t.null, t.number]),
      IdToUpdate: t.union([t.null, t.number]),
    }),
  ],
  'playerOutcomeUpdateCodec',
);

export const sportbookUpdateCodec = t.partial(
  {
    LeagueUpdate: t.union([t.null, leagueUpdateCodec]),
    EventUpdate: t.union([t.null, eventUpdateCodec]),
    MarketUpdate: t.union([t.null, marketUpdateCodec]),
    OutcomeUpdate: t.union([t.null, outcomeUpdateCodec]),
    PlayerPropUpdate: t.union([t.null, playerPropUpdateCodec]),
    PlayerMarketUpdate: t.union([t.null, playerMarketUpdateCodec]),
    PlayerOutcomeUpdate: t.union([t.null, playerOutcomeUpdateCodec]),
    LiveCount: t.union([t.null, t.number]),
  },
  'SportbookUpdateCodec',
);

export const sportbookUpdatesResponseCodec = t.type(
  {
    Updates: t.array(sportbookUpdateCodec),
  },
  'SportbookUpdatesResponseCodec',
);

export const getLiveCountUpdateCodec = t.type(
  {
    EntityUpdates: t.array(
      t.type({
        LiveCountUpdate: t.number,
      }),
    ),
  },
  'GetLiveCountUpdateCodec',
);
