import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetDocumentStatusSummaryResponse,
  GetUploadDocumentStatusResponse,
} from './types';

/** Get document status */
export const getDocumentStatus = createAsyncAction(
  'user/get_document_status_request',
  'user/get_document_status_success',
  'user/get_document_status_failure',
)<void, GetUploadDocumentStatusResponse, FailurePayload>();

export const getDocumentStatusSummary = createAsyncAction(
  'user/get_document_status_summary_request',
  'user/get_document_status_summary_success',
  'user/get_document_status_summary_failure',
)<void, GetDocumentStatusSummaryResponse, FailurePayload>();

/** Send document */
export const sendDocument = createAsyncAction(
  'user/send_document_request',
  'user/send_document_success',
  'user/send_document_failure',
)<void, void, FailurePayload>();
