import React, { FC, memo } from 'react';

import {
  BettingSlipNotification,
  BettingSlipNotificationCodeMessages,
  BettingSlipNotificationMessageParameter,
  BettingSlipNotificationStatusMessages,
  getNotificationLevelMapper,
} from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';
import { Trans } from '@gaming1/g1-i18n';
import { KeyValueType } from '@gaming1/g1-requests-betting';
import { Message } from '@gaming1/g1-ui';
import { isNonNullable } from '@gaming1/g1-utils';

import { BettingSlipCallToAction } from './BettingSlipCallToAction';

const defaultErrorMessage = 'betting:error.bettingSlip.common.error';

type BettingSlipMessageComponentProps = {
  testId: string;
  notification: BettingSlipNotification;
  callToActionsFunc?: {
    updateStake?: (stake: string) => void;
  };
};

const BettingSlipMessageComponent: FC<BettingSlipMessageComponentProps> = ({
  testId,
  notification,
  callToActionsFunc,
}) => {
  const formatMoney = useFormatMoney();

  const notificationType = getNotificationLevelMapper(notification);

  const formatData = (data: BettingSlipNotificationMessageParameter) => {
    switch (data.Type) {
      case KeyValueType.Money:
        return formatMoney(Number(data.Value));
      case KeyValueType.Number:
        return Number(data.Value);

      default:
        return data.Value;
    }
  };

  const formatMessageWithData = (
    baseMessage: string,
    data?: (BettingSlipNotificationMessageParameter | null)[],
  ) => {
    const interpolationValues =
      data &&
      data.filter(isNonNullable).reduce(
        (previous, current) => ({
          ...(previous && { [current.Key || '']: formatData(current) }),
        }),
        {},
      );

    return (
      <Trans
        i18nKey={baseMessage}
        values={interpolationValues}
        components={{
          LinkDesign: (
            <BettingSlipCallToAction
              notification={notification}
              callToActionsFunc={callToActionsFunc}
            />
          ),
        }}
      />
    );
  };

  const message = (): JSX.Element => {
    if (notification.Status) {
      return formatMessageWithData(
        (!!BettingSlipNotificationStatusMessages &&
          BettingSlipNotificationStatusMessages[notification.Status]) ||
          defaultErrorMessage,
        notification.NotificationParameters,
      );
    }
    return formatMessageWithData(
      (!!BettingSlipNotificationCodeMessages &&
        BettingSlipNotificationCodeMessages[notification.Code]) ||
        defaultErrorMessage,
      notification.NotificationParameters,
    );
  };

  return (
    <Message data-testid={testId} p="xs" size="small" type={notificationType}>
      {message()}
    </Message>
  );
};

export const BettingSlipMessage = memo(BettingSlipMessageComponent);
