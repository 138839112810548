import isEqual from 'lodash/isEqual';
import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  EBettingSlipIdentifier,
  getHasFrontNotificationError,
  placeBettingSlipRequestStateGetterSelector,
  useBettingGetterSelector,
  useBettingSlipCombiErrorFrontType,
  useBettingSlipIsDirtyOdd,
  useBettingSlipIsUnavailableItems,
  useBettingSlipSingleErrorFrontType,
  useBettingSlipSystemErrorFrontType,
} from '@gaming1/g1-betting';
import { userLoggedInSelector } from '@gaming1/g1-core';
import {
  DRAWER_URL_SEARCH_PARAMETER,
  useGoToLogin,
} from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Button, DrawerType, Message } from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

import { extractBettingSlipTypeFromId } from '../../../helpers';
import { BettingSlipContext } from '../../BettingSlipContext';
import { useIsMobileTicket } from '../../hooks/render';
import { BettingSlipPlaceBettingSlipError } from '../BettingSlipError/BettingSlipPBError';
import { BettingSlipRiskManagementError } from '../BettingSlipError/BettingSlipRMError';

import { BettingSlipIsDirtyOdd } from './BettingSlipDirtyOdd';
import { BettingSlipValidationCombi } from './BettingSlipValidationCombi';
import { BettingSlipValidationSingle } from './BettingSlipValidationSingle';
import { BettingSlipValidationSystem } from './BettingSlipValidationSystem';
import { BettingSlipValidationContainer } from './styles';

const ErrorWrapper: FC = ({ children }) => (
  <Box data-testid="bettingslip-errorstake-container" width="100%" mb="sm">
    {children}{' '}
  </Box>
);

const ErrorMessage = ({
  isDirtyOdd,
  isUnavailableItems,
}: {
  isDirtyOdd: boolean;
  isUnavailableItems: boolean;
}) => {
  const { t } = useTranslation('betting');
  if (isDirtyOdd) {
    return (
      <ErrorWrapper>
        <BettingSlipIsDirtyOdd />
      </ErrorWrapper>
    );
  }

  if (isUnavailableItems) {
    return (
      <ErrorWrapper>
        <Message
          mb="sm"
          p="xs"
          size="small"
          type="warning"
          data-testid="bettingslip-invalidelement-message"
        >
          {t('error.bettingSlip.pb-InvalidElementToBetOnActually')}
        </Message>
      </ErrorWrapper>
    );
  }

  return (
    <>
      <BettingSlipRiskManagementError />
      <BettingSlipPlaceBettingSlipError />
    </>
  );
};

/**
 * Display the ticket item footer
 */
const BettingSlipValidationComponent: FC = () => {
  const dispatch = useDispatch();
  const goToLogin = useGoToLogin();
  const { t } = useTranslation('betting');

  const isMobileTicket = useIsMobileTicket();

  const { bettingSlipId, type } = useContext(BettingSlipContext);

  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const placeBetRequestState = useBettingGetterSelector({
    getterSelector: placeBettingSlipRequestStateGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const isDirtyOdd = useBettingSlipIsDirtyOdd(bettingSlipId);
  const isUnavailableItems = useBettingSlipIsUnavailableItems(bettingSlipId);

  const singleFrontTypeValid = !getHasFrontNotificationError(
    useBettingSlipSingleErrorFrontType(bettingSlipId)(),
  );

  const combiFrontTypeValid = !getHasFrontNotificationError(
    useBettingSlipCombiErrorFrontType(bettingSlipId)(),
  );
  const systemFrontTypeValid = !getHasFrontNotificationError(
    useBettingSlipSystemErrorFrontType(bettingSlipId)(),
  );

  const askLogin = useCallback(
    () =>
      goToLogin(
        isMobileTicket &&
          extractBettingSlipTypeFromId(bettingSlipId) ===
            EBettingSlipIdentifier.Main
          ? {
              search: new URLSearchParams({
                [DRAWER_URL_SEARCH_PARAMETER]: DrawerType.bettingSlip,
              }).toString(),
            }
          : undefined,
      ),
    [bettingSlipId, goToLogin, isMobileTicket],
  );

  const askValidation = useCallback(() => {
    if (isUserLoggedIn === false) {
      askLogin();
    } else {
      dispatch(actions.startBetPipe({ bettingSlipId, type: 'betPlacement' }));
    }
  }, [askLogin, bettingSlipId, dispatch, isUserLoggedIn]);

  const renderValidationButton = useMemo(() => {
    switch (type) {
      case 'single':
        return <BettingSlipValidationSingle askValidation={askValidation} />;
      case 'combi':
        return <BettingSlipValidationCombi askValidation={askValidation} />;
      case 'system':
        return <BettingSlipValidationSystem askValidation={askValidation} />;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isDirtyOdd, isUserLoggedIn]);

  const defaultErrorMessage = useMemo(
    () => (
      <ErrorMessage
        isUnavailableItems={isUnavailableItems}
        isDirtyOdd={isDirtyOdd}
      />
    ),
    [isUnavailableItems, isDirtyOdd],
  );

  const renderedErrorMessage = useMemo(() => {
    switch (type) {
      case 'single':
        return !singleFrontTypeValid || defaultErrorMessage;
      case 'combi':
        return !combiFrontTypeValid || defaultErrorMessage;
      case 'system':
        return !systemFrontTypeValid || defaultErrorMessage;
      default:
        return null;
    }
  }, [
    combiFrontTypeValid,
    defaultErrorMessage,
    singleFrontTypeValid,
    systemFrontTypeValid,
    type,
  ]);

  const renderFailRequestStateErrorMessage = useMemo(() => {
    if (placeBetRequestState === RemoteData.Error) {
      return (
        <ErrorWrapper>
          <Message
            mb="sm"
            p="xs"
            size="small"
            type="warning"
            data-testid="bettingSlip-placeBet-failure-requestState"
          >
            {t('bettingSlip.placeBet.failure.requestState')}
          </Message>
        </ErrorWrapper>
      );
    }

    return null;
  }, [placeBetRequestState, t]);

  const notLoggedBtn = useMemo(
    () => (
      <Button
        data-testid="bettingslip-login-button"
        width="100%"
        type="submit"
        onClick={askLogin}
      >
        {t('bettingslip.placebet.asklogin')}
      </Button>
    ),
    [askLogin, t],
  );

  const handleErrorMessagesToDisplay = useMemo(() => {
    if (!renderedErrorMessage && renderFailRequestStateErrorMessage) {
      return renderFailRequestStateErrorMessage;
    }

    if (renderedErrorMessage) {
      return renderedErrorMessage;
    }

    return null;
  }, [renderFailRequestStateErrorMessage, renderedErrorMessage]);

  const content = useMemo(
    () => (
      <>
        {handleErrorMessagesToDisplay}
        <BettingSlipValidationContainer justifyContent="center">
          {renderValidationButton}
        </BettingSlipValidationContainer>
      </>
    ),
    [handleErrorMessagesToDisplay, renderValidationButton],
  );

  if (!isUserLoggedIn) {
    return notLoggedBtn;
  }

  return content;
};

export const BettingSlipValidation = memo(BettingSlipValidationComponent);
