import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { NotificationCode } from '@gaming1/g1-requests-betting';
import { isNonNullable, RemoteData } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { BettingSlipNotification } from '../../../../notification/mapper/types';
import {
  bettingSlipGetterSelector,
  bettingSlipRequestGetterSelector,
  bettingSlipsStateSelector,
} from '../common';

export const placeBettingSlipRequestGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) => (bettingSlipId: BettingSlipIdentifierType) =>
    getBettingSlip(bettingSlipId)?.placeBet,
);

export const placeBettingSlipRequestStateGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        getBettingSlip(bettingSlipId)?.requests.placeBet.status,
    ),
);

/**
 * TODO: comment the purpose of this validator
 */
export const placeBettingSlipRequestValidDataGetterSelector = createSelector(
  bettingSlipRequestGetterSelector,
  (getBettingSlipRequestSelector) =>
    (bettingSlipId: BettingSlipIdentifierType) => {
      const status =
        getBettingSlipRequestSelector(bettingSlipId)?.placeBet?.status;
      if (
        !status ||
        status === RemoteData.Loading ||
        status === RemoteData.NotAsked
      ) {
        return false;
      }
      return true;
    },
);

export const placeBettingSlipRequestSuccessGetterSelector = createSelector(
  bettingSlipRequestGetterSelector,
  (getBettingSlipRequestSelector) =>
    (bettingSlipId: BettingSlipIdentifierType) => {
      const status =
        getBettingSlipRequestSelector(bettingSlipId)?.placeBet?.status;
      if (status === RemoteData.Success) {
        return true;
      }
      return false;
    },
);
/**
 * Don't forget to use shallowEqual with this selector
 */
export const placeBettingSlipRequestStatusGetterSelector = createSelector(
  bettingSlipRequestGetterSelector,
  (getBettingSlipRequestSelector) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      getBettingSlipRequestSelector(bettingSlipId)?.placeBet.status ||
      RemoteData.NotAsked,
);

export const placeBettingSlipResultGetterSelector = createSelector(
  bettingSlipsStateSelector,
  placeBettingSlipRequestValidDataGetterSelector,
  (bettingSlipRequests, placeBettingSlipRequestValideData) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      !placeBettingSlipRequestValideData(bettingSlipId)
        ? undefined
        : bettingSlipRequests.bettingSlips[bettingSlipId]?.placeBet,
);

export const isPlaceBetNotificationASuccessSelector = createSelector(
  placeBettingSlipRequestStatusGetterSelector,
  placeBettingSlipResultGetterSelector,
  (placeBettingSlipRequestStatus, placeBettingSlipResult) =>
    (bettingSlipId: BettingSlipIdentifierType): boolean => {
      const notification =
        placeBettingSlipResult(bettingSlipId)?.result?.Notification;

      if (!notification) {
        return false;
      }
      return notification.Code === NotificationCode.PlaceBet_Success;
    },
);

/**
 * Don't forget to use shallowEqual with this selector
 *
 * It'll check if the NotificationCode of the PlaceBet response is a success or not.
 *
 * If success, we return null because there is nothing to display for the errors.
 * If the NotificationCode is not a PlaceBet_Success then we have a Notification
 * to return
 */
export const placeBettingSlipNotificationGetterSelector = createSelector(
  placeBettingSlipRequestStatusGetterSelector,
  placeBettingSlipResultGetterSelector,
  isPlaceBetNotificationASuccessSelector,
  (
      placeBettingSlipRequestStatus,
      placeBettingSlipResult,
      hasASuccessNotification,
    ) =>
    (
      bettingSlipId: BettingSlipIdentifierType,
    ): BettingSlipNotification | null => {
      const notification =
        placeBettingSlipResult(bettingSlipId)?.result?.Notification;

      if (hasASuccessNotification(bettingSlipId)) {
        return null;
      }

      return notification || null;
    },
);

/**
 * Don't forget to use shallowEqual with this selector
 */
export const placeBettingSlipItemNotificationGetterSelector = createSelector(
  placeBettingSlipRequestStatusGetterSelector,
  placeBettingSlipResultGetterSelector,
  (placeBettingSlipRequestStatus, placeBettingSlipResult) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
        if (placeBettingSlipRequestStatus(bettingSlipId) !== RemoteData.Error) {
          return null;
        }
        const element = placeBettingSlipResult(bettingSlipId)
          ?.result?.ElementNotifications?.filter(isNonNullable)
          .find((item) => item.OutcomeId === itemId);

        return element?.Notification || null;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);
