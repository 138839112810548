/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetLiveCountRequest,
  GetLiveCountResponse,
  LiveCountBySportRequest,
  LiveCountBySportResponse,
} from '../entities';

export const getLiveCount = (options: GetLiveCountRequest) =>
  createRequest<GetLiveCountResponse>(201, 'GetLiveCount', options);

export const getLiveCountBySport = (options: LiveCountBySportRequest) =>
  createRequest<LiveCountBySportResponse>(201, 'GetLiveCountBySport', options);
