import { darken, lighten } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  isColorDark,
  media,
  spaces,
} from '@gaming1/g1-style';
import { AppAnchor, FluidContentContainer, SimpleButton } from '@gaming1/g1-ui';

import { TOP_NAV_LARGE_HEIGHT, TOP_NAV_SMALL_HEIGHT } from '../../../styles';
import { Image } from '../Image';

const activeClassName = 'active';

// const topNavContainerNativeCss = css`
//   position: fixed; /** Fallback for old Safari without sticky */
//   position: sticky;
//   top: 0;
//   right: 0;
//   left: 0;

//   ${elevation(2)};
//   z-index: ${zIndex(3)};
// `;

export const TopNavContainer = styled.header`
  background-color: ${colors('topNavBackground')};
  color: ${colors('topNavText')};
  height: ${TOP_NAV_SMALL_HEIGHT}px;
  ${media.lg} {
    height: ${TOP_NAV_LARGE_HEIGHT}px;
  }
`;
/* TODO: decide whether to keep this or not since it create bugs in the layout  */
/* ${getWrapperEnv() === 'rn' && topNavContainerNativeCss} */

export const TopNavContentContainer = styled(FluidContentContainer)`
  flex-direction: row;
  justify-content: space-between;
  height: ${TOP_NAV_SMALL_HEIGHT}px;

  ${media.lg} {
    height: ${TOP_NAV_LARGE_HEIGHT}px;
  }
`;

export const TopNavGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const TopNavImageWrapper = styled.div`
  height: ${({ theme }) => theme.headerLogoHeight}px;
  margin-right: ${spaces('xxs')};
  ${media.xl} {
    margin-right: ${spaces('lg')};
  }
`;

export const TopNavImage = styled(Image)`
  height: 100%;
`;

export const TopNavAppSection = styled.span`
  margin-left: ${spaces('sm')};
  font-size: ${fontSizes('md')};
  font-style: italic;
`;

export const RoundIconButton = styled(SimpleButton)`
  display: flex;
  align-items: center;
  margin-left: ${spaces('sm')};
  height: 26px;
  width: 26px;
  border-radius: 50%;
  justify-content: center;
  position: relative;

  ${media.xl} {
    height: 40px;
    width: 40px;
  }
`;

export const IconSearchButton = styled(RoundIconButton)`
  background-color: ${colors('tertiary')};
`;

export const TopNavMenuContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const VerticalSeparator = styled.span`
  width: 1px;
  height: 24px;
  background-color: ${colors('topNavText')};
  margin: auto ${spaces('xs')};
  display: inline-block;
`;

type TopNavItemProps = {
  /** Index of the current links group */
  groupIndex?: number;
};

const TopNavMenuItemCSS = css<TopNavItemProps>`
  font-size: ${fontSizes('lg')};
  ${fontWeight('bold')};
  letter-spacing: 0.2px;
  white-space: nowrap;
  color: ${({ groupIndex = 0, theme }) =>
    isColorDark(theme.colors.topNavText)
      ? lighten(groupIndex / 5)(theme.colors.topNavText)
      : darken(groupIndex / 5)(theme.colors.topNavText)};
  padding: ${spaces('sm')};
  text-decoration: none;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  svg {
    margin-right: ${spaces('xs')};
    path {
      fill: ${({ groupIndex = 0, theme }) =>
        isColorDark(theme.colors.topNavText)
          ? lighten(groupIndex / 5)(theme.colors.topNavText)
          : darken(groupIndex / 5)(theme.colors.topNavText)};
    }
  }
`;

export const TopNavMenuLink = styled(NavLink)
  .withConfig({
    shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
  })
  .attrs({
    activeClassName,
  })<TopNavItemProps>`
  ${TopNavMenuItemCSS};

  &.${activeClassName}, &:focus,
  &:active,
  &:hover {
    color: ${colors('primary')};
    border-bottom-color: ${colors('primary')};
    svg {
      path {
        fill: ${colors('primary')};
      }
    }
  }
`;

export const TopNavMenuAnchor = styled(AppAnchor).withConfig({
  shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
})<TopNavItemProps>`
  ${TopNavMenuItemCSS};
`;

export const TopNavMenuButton = styled(SimpleButton).withConfig({
  shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
})<TopNavItemProps>`
  ${TopNavMenuItemCSS};
`;
