import { opacify, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  getReadableColor,
  opacity,
} from '@gaming1/g1-style';

import { Box } from '../Box';

/*
  All styles are heavily based on the Radio component
*/

type AppCheckboxSpanProps = {
  /** Whether the radio is selected or not */
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

type CheckboxLabelProps = {
  shouldDisplayLabelAbove?: boolean;
};

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${({ shouldDisplayLabelAbove }) =>
    shouldDisplayLabelAbove &&
    css`
      flex-direction: column;
    `}
`;

export const AppCheckboxSpan = styled.span<AppCheckboxSpanProps>`
  height: 22px;
  width: 22px;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid ${colors('tertiary')};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors('primary')};
      border-color: ${colors('primary')};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      cursor: default;
    `}
  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: default;
    `}
`;

export const HiddenCheckboxInput = styled.input`
  position: absolute;
  opacity: ${opacity('transparent')};
  cursor: pointer;
  height: 0;
  width: 0;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`;

type CheckboxLabelSpanProps = {
  /** Whether the radio is selected or not */
  checked?: boolean;
  disabled?: boolean;
  coloredLabel?: boolean;
};

export const CheckboxLabelSpan = styled(Box)<CheckboxLabelSpanProps>`
  font-size: ${fontSizes('md')};
  display: inline-block;
  line-height: 1em;
  color: ${colors('label')};
  ${({ checked, coloredLabel }) =>
    checked &&
    coloredLabel &&
    css`
      color: ${colors('primary')};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      cursor: default;
    `}
`;

type CheckboxIconSpanProps = {
  /** Whether the radio is selected or not */
  checked?: boolean;
};

export const CheckboxIconSpan = styled.span<CheckboxIconSpanProps>`
  color: ${colors('textLight')};
  font-size: 8px;
  line-height: 1em;
  height: 8px;
  opacity: ${opacity('transparent')};
  transition: opacity 0.3s ease;
  svg path {
    fill: ${getReadableColor('primary')};
  }
  ${({ checked }) =>
    checked &&
    css`
      opacity: ${opacity('opaque')};
    `}
`;

type AppToggleSpanProps = {
  checked?: boolean;
  disabled?: boolean;
};

export const AppToggleSpan = styled.span<AppToggleSpanProps>`
  align-items: center;
  background-color: ${colors('neutrals', { index: 3 })};
  border-radius: 10px;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 39px;
  z-index: 0;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors('primary', { transform: opacify(-0.6) })};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('semi')};
    `}
`;

type ToggleButtonSpanProps = {
  checked?: boolean;
  disabled?: boolean;
};

export const ToggleButtonSpan = styled.span<ToggleButtonSpanProps>`
  height: 24px;
  width: 24px;
  background-color: ${colors('neutrals', { index: 1 })};
  border-radius: 50%;
  position: absolute;
  left: -1px;
  box-shadow: 0;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 0 10px
      ${colors('neutrals', { index: 1, transform: transparentize('0.8') })};
  }
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors('primary')};
      left: calc(100% - 24px);
      &:hover {
        box-shadow: 0 0 0 10px
          ${colors('primary', {
            transform: transparentize('0.8'),
          })};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('seeThrough')};
    `}
`;
