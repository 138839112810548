import { createContext, VFC } from 'react';

const EmptyComponent: VFC = () => null;

/** Context containing public payment components
 * - DepositHistory: an table of deposit history
 * - WithdrawalHistory: an table of withdrawal history
 */
export const PaymentPublicComponentsContext = createContext<{
  DepositHistory: VFC;
  WithdrawalHistory: VFC;
}>({
  DepositHistory: EmptyComponent,
  WithdrawalHistory: EmptyComponent,
});
