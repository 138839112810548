import {
  EGetCreditStatus,
  EGetPlayerProfileStatus,
} from '@gaming1/g1-requests';

export const getUserProfileErrorMessages = {
  [EGetPlayerProfileStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetPlayerProfileStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EGetPlayerProfileStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
};

export const getUserCreditErrorMessages = {
  [EGetCreditStatus.InternalError]: 'core:error.internalServer', // 0
};
