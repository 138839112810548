import { useSelector } from 'react-redux';

import { ApplicationWithBettingState } from './store/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyReturnedSelectorFunction = (...args: any[]) => any;

type BettingGetterSelector<
  Func extends <Args, Return>(...args: Args[]) => Return,
> = {
  getterSelector: (state: ApplicationWithBettingState) => Func;
  args: Parameters<Func>;
  equalityFn?: (value: ReturnType<Func>, other: ReturnType<Func>) => boolean;
};

export const useBettingGetterSelector = <
  Func extends AnyReturnedSelectorFunction,
>({
  getterSelector,
  args,
  equalityFn,
}: BettingGetterSelector<Func>) =>
  useSelector<ApplicationWithBettingState, ReturnType<Func>>(
    (state) => getterSelector(state)(...args),
    equalityFn,
  );
