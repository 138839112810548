import { useRef, useState, VFC } from 'react';
import { createPortal } from 'react-dom';

import { useIsDebugModeEnabled } from '@gaming1/g1-core';

import { DebugPanelContent } from './DebugPanelContent';
import {
  EnterFullScreenIcon,
  ExitFullScreenIcon,
  MinimizePanelIcon,
  OpenPanelIcon,
} from './icons';
import {
  DebugPanelContentWrapper,
  DebugPanelMinimizedWrapper,
  DebugPanelWrapper,
  PositionButton,
  PositionButtonsWrapper,
  Spacer,
} from './styles';
import { DebugPage, DebugPanelPosition } from './types';

type DebugPanelProps = {
  additionalTabs?: DebugPage[];
};

/**
 * A panel that is rendered only if the debug mode of the app is enabled. It
 * displays tabs containing debug information and tools to help the developer
 * debug the app. Additional tabs can be added with the "additionalTabs"
 * property (useful when debugging other domains)
 *
 * To enable the debug mode:
 * - Use the "debugMode=true" query param
 * - Click 7 times the app logo on the Footer
 * - Use the devToolMenu (only for site-default)
 *
 * The panel can be in 3 states:
 * - minimized: only a small button is visible in the viewport, which allow the
 *   panel to be opened
 * - open: the panel takes 40% of the viewport height and is displayed above its
 *   content
 * - fullscreen: the panel content is shown within the page content. Only
 *   possible in the /debug url
 */
export const DebugPanel: VFC<DebugPanelProps> = ({ additionalTabs }) => {
  const [panelPosition, setPanelPosition] =
    useState<DebugPanelPosition>('minimized');

  const isDebugModeEnabled = useIsDebugModeEnabled();

  // The <main> element wrapping the whole app layout
  const mainContainerRef = useRef<HTMLElement | null>(null);
  // Looks like a false positive
  // eslint-disable-next-line prefer-destructuring
  mainContainerRef.current = document.getElementsByTagName('main')[0] || null;

  if (!isDebugModeEnabled) {
    return null;
  }

  const fullscreenButton = (
    <PositionButton
      onClick={() => {
        setPanelPosition((previousPosition) =>
          previousPosition === 'open' ? 'fullscreen' : 'open',
        );
      }}
    >
      {panelPosition === 'open' ? (
        <EnterFullScreenIcon />
      ) : (
        <ExitFullScreenIcon />
      )}
    </PositionButton>
  );

  const minimizeButton = (
    <PositionButton
      onClick={() => {
        setPanelPosition((previousPosition) =>
          previousPosition === 'minimized' ? 'open' : 'minimized',
        );
      }}
    >
      {panelPosition === 'minimized' ? (
        <OpenPanelIcon />
      ) : (
        <MinimizePanelIcon />
      )}
    </PositionButton>
  );

  const positionButtons = (
    <PositionButtonsWrapper panelPosition={panelPosition}>
      {minimizeButton}
      {panelPosition !== 'minimized' && fullscreenButton}
    </PositionButtonsWrapper>
  );

  /** When needed (and possible), add a spacer that will make it possible to
   * view the entirety of the viewport while the panel is open
   */
  const spacer =
    panelPosition === 'open' && mainContainerRef.current
      ? createPortal(<Spacer />, mainContainerRef.current)
      : null;

  return panelPosition === 'minimized' ? (
    <DebugPanelMinimizedWrapper data-testid="minimized-debug-panel">
      {positionButtons}
    </DebugPanelMinimizedWrapper>
  ) : (
    <DebugPanelWrapper
      position={panelPosition}
      data-testid="debug-panel-wrapper"
    >
      {spacer}
      {positionButtons}
      <DebugPanelContentWrapper position={panelPosition}>
        <DebugPanelContent additionalTabs={additionalTabs} />
      </DebugPanelContentWrapper>
    </DebugPanelWrapper>
  );
};
