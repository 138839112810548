import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getInitializationData } from '@gaming1/g1-requests-betting';
import {
  checkIfDifferenceIsBiggerThanMillisecondsGiven,
  createFailurePayload,
  mapGuard,
} from '@gaming1/g1-utils';

import { getInitializationDataSuccessResponseCodec } from '../../bettingSlip/store/codecs/common';
import {
  getInitDataLatestTimeSentRequestGetterSelector,
  getInitDataRequestResultGetterSelector,
} from '../../bettingSlip/store/selectors/getInitData/getInitData';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

/** Fetch the initial data for the bettingslip */
export const getBettingSlipInitializationDataEpic: BettingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getBettingSlipInitializationData.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      const latestRequestTime =
        getInitDataLatestTimeSentRequestGetterSelector(state)();
      if (latestRequestTime) {
        const couldSendRequest = checkIfDifferenceIsBiggerThanMillisecondsGiven(
          new Date(latestRequestTime),
          300000 /* 5 minutes */,
        );

        if (!couldSendRequest) {
          const currentInitDataState =
            getInitDataRequestResultGetterSelector(state)();
          if (currentInitDataState) {
            return of(
              actions.getBettingSlipInitializationData.success(
                formatActionsData({
                  data: currentInitDataState,
                  bettingSlipId,
                  betPipeId,
                }),
              ),
            );
          }
        }
      }

      return wsAdapter.request(getInitializationData({})).pipe(
        takeUntilPipeIsCancelled(betPipeId, action$),
        mapGuard(getInitializationDataSuccessResponseCodec),
        map((data) => {
          const timeOfRequest = new Date().toString();
          return actions.getBettingSlipInitializationData.success(
            formatActionsData({
              data: { timeOfRequest, ...data },
              bettingSlipId,
              betPipeId,
            }),
          );
        }),
        catchError((err) =>
          of(
            actions.getBettingSlipInitializationData.failure(
              formatActionsData({
                data: createFailurePayload(err),
                bettingSlipId,
                betPipeId,
              }),
            ),
          ),
        ),
      );
    }),
  );
