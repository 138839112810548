import React, { memo, useCallback, useContext, useMemo, useState } from 'react';

import {
  BettingSlipStringType,
  useBettingSlipIsEmpty,
  useBettingSlipIsValidType,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { ControlledTabs } from '@gaming1/g1-ui';

import { NoContent } from '../../../common/components/NoContent';
import { BettingSlipContext } from '../../BettingSlipContext';

const { Tab, TabContent, TabContents, TabList } = ControlledTabs;

/**
 * Display the betting slip menu
 *
 */
export const BettingSlipMenuComponent = () => {
  const { t } = useTranslation('betting');

  const { bettingSlipId, switchType, type } = useContext(BettingSlipContext);

  const [activeTab, setActiveTab] = useState(type);

  const isTheBettingSlipEmpty = useBettingSlipIsEmpty(bettingSlipId);

  const isSingleValid = useBettingSlipIsValidType(bettingSlipId)('single');

  const isCombiValid = useBettingSlipIsValidType(bettingSlipId)('combi');

  const isSystemValid = useBettingSlipIsValidType(bettingSlipId)('system');

  const changeTab = useCallback(
    (newTab: string) => {
      setActiveTab(newTab as BettingSlipStringType);
      switchType(newTab as BettingSlipStringType);
    },
    [setActiveTab, switchType],
  );

  const content = useMemo(
    () => (
      <ControlledTabs
        activeTab={isTheBettingSlipEmpty ? activeTab : type}
        setActiveTab={changeTab}
        showBorder
      >
        <TabList fontSize="md" showBorder hasBackgroundColorRemoved>
          <Tab
            key="single"
            borderPosition="bottom"
            id="single"
            isDisabled={!isTheBettingSlipEmpty && !isSingleValid}
            hasBackgroundColorRemoved
            testId="bettingslip-single-tab"
          >
            {t('bettingslip.singleMenu')}
          </Tab>
          <Tab
            key="combi"
            borderPosition="bottom"
            id="combi"
            isDisabled={!isTheBettingSlipEmpty && !isCombiValid}
            hasBackgroundColorRemoved
            testId="bettingslip-combi-tab"
          >
            {t('bettingslip.combiMenu')}
          </Tab>
          <Tab
            key="system"
            borderPosition="bottom"
            id="system"
            isDisabled={!isTheBettingSlipEmpty && !isSystemValid}
            hasBackgroundColorRemoved
            testId="bettingslip-system-tab"
          >
            {t('bettingslip.systemMenu')}
          </Tab>
        </TabList>
        {isTheBettingSlipEmpty ? (
          <TabContents>
            <TabContent key="single" id="single" hasBackgroundColorRemoved>
              <NoContent
                headingKey={t('bettingslip.single.header')}
                descriptionKey={t('bettingslip.single.description')}
                testId="bettingslip-single-description-container"
                px="sm"
              />
            </TabContent>
            <TabContent key="combi" id="combi" hasBackgroundColorRemoved>
              <NoContent
                headingKey={t('bettingslip.combi.header')}
                descriptionKey={t('bettingslip.combi.description')}
                testId="bettingslip-combi-description-container"
                px="sm"
              />
            </TabContent>
            <TabContent key="system" id="system" hasBackgroundColorRemoved>
              <NoContent
                headingKey={t('bettingslip.system.header')}
                descriptionKey={t('bettingslip.system.description')}
                testId="bettingslip-system-description-container"
                px="sm"
              />
            </TabContent>
          </TabContents>
        ) : null}
      </ControlledTabs>
    ),
    [
      isTheBettingSlipEmpty,
      changeTab,
      activeTab,
      isSingleValid,
      isCombiValid,
      isSystemValid,
      t,
      type,
    ],
  );

  return content;
};

export const BettingSlipMenu = memo(BettingSlipMenuComponent);
