import React, { VFC } from 'react';
import { Switch } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-core';
import { ProtectedRoute } from '@gaming1/g1-core-web';

import { inboxRoutes } from '../routes';

import { MessageDetailPage } from './pages/MessageDetailPage';
import { MessagesListPage } from './pages/MessagesListPage';

/** Inbox router */
export const InboxRouter: VFC = () => {
  const { currentLocale: locale } = useI18n();
  return (
    <Switch>
      <ProtectedRoute
        exact
        component={MessagesListPage}
        key="inboxList"
        path={`/${locale}${inboxRoutes.messageList.path}`}
        section={null}
      />
      <ProtectedRoute
        component={MessageDetailPage}
        key="inboxDetail"
        path={`/${locale}${inboxRoutes.messageDetail.path}`}
        section={null}
      />
    </Switch>
  );
};
