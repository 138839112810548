import React, { useState, VFC } from 'react';

import { useConfig } from '@gaming1/g1-config';
import { ControlledTabs } from '@gaming1/g1-ui';

import { CoreDebugger } from '../CoreDebugger';
import { GeolocationDebugger } from '../GeolocationDebugger';
import { MessagesDebugger } from '../MessagesDebugger';
import { MockResponseDebugger } from '../MockResponseDebugger';
import { NetworkDebugger } from '../NetworkDebugger';
import { SessionDebugger } from '../SessionDebugger';

import { TabContentContainer } from './styles';
import { DebugPage } from './types';

type DebugPanelContentProps = {
  additionalTabs?: DebugPage[];
};

const initialTabs: DebugPage[] = [
  {
    tabName: 'Home',
    Component: CoreDebugger,
  },
  {
    tabName: 'Network',
    Component: NetworkDebugger,
  },
  {
    tabName: 'Responses mocking',
    Component: MockResponseDebugger,
  },
  {
    tabName: 'Sessions',
    Component: SessionDebugger,
  },
  {
    tabName: 'Messages',
    Component: MessagesDebugger,
  },
];

export const DebugPanelContent: VFC<DebugPanelContentProps> = ({
  additionalTabs,
}) => {
  const config = useConfig();
  const tabs = [
    ...initialTabs,
    ...(config.core.geoComply.activated
      ? [{ tabName: 'Geolocation', Component: GeolocationDebugger }]
      : []),
    ...(additionalTabs || []),
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].tabName);
  return (
    <ControlledTabs
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      showBorder
    >
      <ControlledTabs.TabList
        fontSize="md"
        showBorder
        hasBackgroundColorRemoved
        hasHorizontalScroll
      >
        {tabs.map(({ tabName }) => (
          <ControlledTabs.Tab
            key={tabName}
            borderPosition="bottom"
            id={tabName}
            hasBackgroundColorRemoved
          >
            {tabName}
          </ControlledTabs.Tab>
        ))}
      </ControlledTabs.TabList>
      <TabContentContainer>
        <ControlledTabs.TabContents>
          {tabs.map(({ tabName, Component }) => (
            <ControlledTabs.TabContent
              key={tabName}
              id={tabName}
              hasBackgroundColorRemoved
            >
              <Component />
            </ControlledTabs.TabContent>
          ))}
        </ControlledTabs.TabContents>
      </TabContentContainer>
    </ControlledTabs>
  );
};
