import React, { FC } from 'react';

import { PrismicVideoSlice } from '@gaming1/g1-cms';

import { PrismicVideoContainer, PrismicVideoWrapper } from './styles';

export type PrismicVideoProps = {
  /** Prismic 'Video' slice */
  slice: PrismicVideoSlice;
  testId?: string;
};

/**
 * This component renders a prismic `Video` slice
 * It renders an iframe or video element, depending on the specified URL
 * Compatible with YouTube and Twitch URLs, or movie files (avi, mp4, mov, mpg, mpeg, mkv)
 */
export const PrismicVideo: FC<PrismicVideoProps> = ({
  slice: {
    primary: { link, width },
  },
  testId = 'prismic-video',
}) => {
  const videoRegex = /\.(avi|mp4|mov|mpe?g|mkv)(\?\.*)?$/i;
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;
  const twitchRegex = /^(?:(?:https?:)?\/\/)?player.twitch.tv\/(\S+)/i;

  const youtubeMatch = link.url?.match(youtubeRegex);
  const twitchMatch = link.url?.match(twitchRegex);
  const videoMatch = link.url?.match(videoRegex);

  if ((youtubeMatch && youtubeMatch.length > 0) || twitchMatch || videoMatch) {
    return (
      <PrismicVideoWrapper width={width || '100%'} data-testid={testId}>
        <PrismicVideoContainer>
          {youtubeMatch && youtubeMatch.length > 0 && (
            <iframe
              data-testid="prismic-youtube-iframe"
              title="youtube video"
              src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
              width="100%"
              height="100%"
            />
          )}

          {twitchMatch && (
            <iframe
              data-testid="prismic-twitch-iframe"
              title="twitch video"
              src={link.url}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              scrolling="no"
            />
          )}

          {videoMatch && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              data-testid="prismic-video-element"
              width="100%"
              height="100%"
              controls
            >
              <source src={link.url} />
            </video>
          )}
        </PrismicVideoContainer>
      </PrismicVideoWrapper>
    );
  }

  return null;
};
