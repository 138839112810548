import * as t from 'io-ts';

import {
  ENewsScope,
  ESlideActionType,
  ESliderScreenSize,
  ESliderStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const slider = t.intersection(
  [
    t.type({
      Order: t.number,
      Scope: createEnumType<ENewsScope>(ENewsScope, 'ENewsScope'),
      Group: t.string,
      Picture: t.string,
      Size: createEnumType<ESliderScreenSize>(
        ESliderScreenSize,
        'ESliderScreenSize',
      ),
      SlideActionType: createEnumType<ESlideActionType>(
        ESlideActionType,
        'ESlideActionType',
      ),
    }),
    t.partial({
      AlternateText: t.string,
      ActionValue: t.string,
      Thumb: t.string,
    }),
  ],
  'Slider',
);

export const getSliderResponse = t.type(
  {
    Status: t.literal(ESliderStatus.Successful),
    List: t.array(slider),
  },
  'GetSliderResponse',
);
