import React, { VFC } from 'react';

import { useConvertDateToElapsedTime } from '@gaming1/g1-core';
import { Box, Icon } from '@gaming1/g1-ui';

import { getIconType } from '../../helpers';
import { MessageData } from '../../types';

import {
  CircleContainer,
  MessageTime,
  MessageTitle,
  RedCircle,
} from './styles';

export type MessagesListItemProps = {
  /** Message */
  message: MessageData;
};

/** Displays the list of messages in the user inbox */
export const MessagesListItem: VFC<MessagesListItemProps> = ({ message }) => {
  const convertDateToElapsedTime = useConvertDateToElapsedTime();
  const isMessageRead = message.read > 0;

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      data-testid={`profile-message-${message.id}`}
    >
      <CircleContainer>{!isMessageRead && <RedCircle />}</CircleContainer>
      <Icon
        type={getIconType(message.meta_data.class)}
        id={`list-message-icon-${message.meta_data.class}`}
        mr="xs"
      />
      <Box>
        <MessageTitle
          hasBeenRead={isMessageRead}
          data-testid="profile-message-title"
        >
          {message.sline}
        </MessageTitle>
        <MessageTime
          hasBeenRead={isMessageRead}
          data-testid="profile-message-elapsed"
        >
          {convertDateToElapsedTime(message.delivered_at)}
        </MessageTime>
      </Box>
    </Box>
  );
};
