import styled, { css } from 'styled-components';
import { color, fontSize, space, width } from 'styled-system';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';

import { StyledSystemProps } from '../types';

export const SeparatorDiv = styled.div<StyledSystemProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${spaces('sm')};
  min-height: calc(1em + 2px);
  position: relative;

  ${color}
  ${fontSize}
  ${space}
  ${width}
`;

type SeparatorHrProps = {
  hrColor?: string;
};

export const SeparatorHr = styled.hr<SeparatorHrProps>`
  border-style: none;
  border-top-style: solid;
  width: 100%;

  ${({ theme, hrColor }) => {
    const borderColor = hrColor || theme.colors.neutrals[2];

    return css`
      border-color: ${borderColor};
    `;
  }}
`;

export const SeparatorSpan = styled.span`
  color: ${colors('neutrals', { index: 2 })};
  ${fontWeight('light')};
  font-size: ${fontSizes('md')};
  line-height: 1;
  padding-left: 1em;
  padding-right: 1em;
`;
