import styled, { css } from 'styled-components';

import { colors, device, fontSizes, spaces } from '@gaming1/g1-style';
import { Box, Icon } from '@gaming1/g1-ui';

import { OutcomeButton } from '../OutcomeButton';

const LIVE_NAV_OUTCOME_SUMMARY_HEIGHT_IN_PX = 50;

export const MenuIcon = styled(Icon)`
  font-size: ${fontSizes('lg')};
`;

export const EventName = styled(Box)`
  color: ${colors('neutrals', { index: 0 })};
  font-size: ${fontSizes('md')};
  padding: ${spaces('xs')} 0;

  ${device.canHover} {
    &:hover {
      color: ${colors('neutrals', { index: 0 })};
    }
  }
`;

export const EventContainer = styled(Box)<{ isActive?: boolean }>`
  position: relative;
  width: 100%;

  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: ${colors('scrollNavItemBorderActive')};
        width: 4px;
        height: 100%;
      }
    `};
`;

export const OutcomeSummaryButton = styled(OutcomeButton)<{
  colWidth?: number;
  totalOutcomes?: number;
}>`
  height: ${LIVE_NAV_OUTCOME_SUMMARY_HEIGHT_IN_PX}px;

  & + & {
    margin-left: ${spaces('xxs')};
  }

  ${({ totalOutcomes }) =>
    totalOutcomes &&
    css`
      width: calc(100% / ${totalOutcomes});
    `}
`;

export const DisabledOutcomeSummary = styled(Box)<{
  colWidth?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: ${fontSizes('sm')};
  height: ${LIVE_NAV_OUTCOME_SUMMARY_HEIGHT_IN_PX}px;
`;

export const EllipsisBox = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const OutcomeSummaryContent = styled(Box)`
  padding: ${spaces('xxs')};
  width: 100%;
`;

export const EllipsisText = styled(Box)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
