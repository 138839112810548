import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { useGetIsMounted } from '@gaming1/g1-utils';

import { RootDiv, ToastBox, ToastContainer } from './styles';
import { Style, Variant } from './types';

type ToastContentComponentProps = {
  close?: () => void;
};
type AddToastInput = {
  delayInMs?: number;
  hideAfterInMs?: number;
  id: string;
  render: FC<ToastContentComponentProps>;
  variant?: Variant;
  containerStyle?: Style;
};

type ToastContextValue = {
  addToast: (input: AddToastInput) => void;
  closeAllToasts: () => void;
  closeToast: (id: string) => void;
};

const ToastContext = createContext<ToastContextValue>(null as never);

export const useToast = () => {
  const ctx = useContext(ToastContext);

  if (!ctx && process.env.NODE_ENV === 'development') {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return ctx;
};

type ToastProviderProps = {
  /**
   * id of the `div` that will be added to the body
   */
  elementId?: string;
};

export const ToastProvider: FC<ToastProviderProps> = memo(
  ({ elementId = 'toast__notification', children }) => {
    const [portalElement, setPortalElement] = useState(
      document.getElementById(elementId),
    );

    const [toasts, setToasts] = useState<
      {
        id: string;
        component: ReactNode;
        hidden: boolean;
        variant: Variant;
        containerStyle: Style;
      }[]
    >([]);

    const closeAllToasts = useCallback(() => setToasts([]), []);

    const getIsMounted = useGetIsMounted();

    const closeToast = useCallback((toastIdToClose: string) => {
      setToasts((currentToasts) => {
        if (!currentToasts.find((r) => r.id === toastIdToClose)) {
          return currentToasts;
        }
        return currentToasts.filter(({ id }) => id !== toastIdToClose);
      });
    }, []);

    const addToast = useCallback(
      ({
        delayInMs = 0,
        hideAfterInMs = undefined,
        id: newToastId,
        render,
        variant,
        containerStyle,
      }: AddToastInput) => {
        setToasts((currentToasts) => {
          if (currentToasts.find((r) => r.id === newToastId)) {
            return currentToasts;
          }
          return [
            ...currentToasts,
            {
              id: newToastId,
              component: render({
                close: () => closeToast(newToastId),
              }),
              hidden: delayInMs > 0,
              variant: variant || 'default',
              containerStyle: containerStyle || 'toast',
            },
          ];
        });
        setTimeout(() => {
          if (getIsMounted()) {
            setToasts((currentToasts) =>
              currentToasts.map((toast) =>
                toast.id === newToastId ? { ...toast, hidden: false } : toast,
              ),
            );
          }
        }, delayInMs);

        if (hideAfterInMs) {
          setTimeout(() => {
            if (getIsMounted()) {
              closeToast(newToastId);
            }
          }, hideAfterInMs);
        }
      },
      [closeToast, getIsMounted],
    );

    useEffect(() => {
      if (!portalElement) {
        const el = document.createElement('div');
        el.id = elementId;
        setPortalElement(el);
      }
    }, [elementId, portalElement]);

    useEffect(() => {
      if (document.body && portalElement) {
        document.body.appendChild(portalElement);
      }
    }, [portalElement]);

    const context = useMemo(
      () => ({ addToast, closeAllToasts, closeToast }),
      [addToast, closeAllToasts, closeToast],
    );

    return (
      <ToastContext.Provider value={context}>
        {children}
        {portalElement &&
          createPortal(
            <RootDiv>
              {toasts
                .filter(({ hidden }) => !hidden)
                .map(({ id, component: Comp, variant, containerStyle }) => (
                  <ToastContainer key={id}>
                    <ToastBox
                      data-testid={id}
                      variant={variant}
                      containerStyle={containerStyle}
                    >
                      {Comp}
                    </ToastBox>
                  </ToastContainer>
                ))}
            </RootDiv>,
            portalElement,
          )}
      </ToastContext.Provider>
    );
  },
);
