import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { PaymentActions } from '../types';

import * as actions from './actions';
import { DepositState } from './types';

export const initialState: DepositState = {
  requests: {
    findDeposits: generateInitialRequestState(null),
    getDepositNextStep: generateInitialRequestState(null),
    getDepositStatus: generateInitialRequestState(null),
    getDepositWalletList: generateInitialRequestState(null),
    getDepositWalletInfo: generateInitialRequestState(null),
    makeDeposit: generateInitialRequestState(null),
    makeVoucherDeposit: generateInitialRequestState(null),
  },
  depositNextStep: null,
  depositStatus: null,
  depositWalletList: null,
  fetchedWallets: {},
  findDeposits: null,
  makeDeposit: null,
  makeVoucherDeposit: null,
};

export const depositReducer = (state = initialState, action: PaymentActions) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.findDeposits.request):
        produceLoadingState(draftState.requests.findDeposits);
        break;

      case getType(actions.findDeposits.failure):
        produceFailureState(draftState.requests.findDeposits, action.payload);
        break;

      case getType(actions.findDeposits.success):
        produceSuccessState(draftState.requests.findDeposits);
        draftState.findDeposits = action.payload;
        break;

      case getType(actions.startAskingDepositStatus):
        draftState.depositStatus = action.payload.depositStatus;
        break;

      case getType(actions.getDepositStatus.request):
        produceLoadingState(draftState.requests.getDepositStatus);
        break;

      case getType(actions.getDepositStatus.failure):
        produceFailureState(
          draftState.requests.getDepositStatus,
          action.payload,
        );
        break;

      case getType(actions.getDepositStatus.success):
        produceSuccessState(draftState.requests.getDepositStatus);
        draftState.depositStatus = action.payload;
        break;

      case getType(actions.cleanGetDepositStatus):
        draftState.depositStatus = initialState.depositStatus;
        draftState.requests.getDepositStatus =
          initialState.requests.getDepositStatus;
        break;

      case getType(actions.makeDeposit.request):
        produceLoadingState(draftState.requests.makeDeposit);
        break;

      case getType(actions.makeDeposit.failure):
        produceFailureState(draftState.requests.makeDeposit, action.payload);
        break;

      case getType(actions.makeDeposit.success):
        produceSuccessState(draftState.requests.makeDeposit);
        draftState.makeDeposit = action.payload;
        break;

      case getType(actions.cleanMakeDeposit):
        draftState.makeDeposit = initialState.makeDeposit;
        draftState.requests.makeDeposit = initialState.requests.makeDeposit;
        break;

      case getType(actions.makeVoucherDeposit.request):
        produceLoadingState(draftState.requests.makeVoucherDeposit);
        break;

      case getType(actions.makeVoucherDeposit.failure):
        produceFailureState(
          draftState.requests.makeVoucherDeposit,
          action.payload,
        );
        break;

      case getType(actions.makeVoucherDeposit.success):
        produceSuccessState(draftState.requests.makeVoucherDeposit);
        draftState.makeVoucherDeposit = action.payload;
        break;

      case getType(actions.getDepositWalletList.request):
        produceLoadingState(draftState.requests.getDepositWalletList);
        break;

      case getType(actions.getDepositWalletList.failure):
        produceFailureState(
          draftState.requests.getDepositWalletList,
          action.payload,
        );
        break;

      case getType(actions.getDepositWalletList.success):
        produceSuccessState(draftState.requests.getDepositWalletList);
        draftState.depositWalletList = action.payload;
        break;

      case getType(actions.getDepositWalletInfo.request):
        produceLoadingState(draftState.requests.getDepositWalletInfo);
        break;

      case getType(actions.getDepositWalletInfo.failure):
        produceFailureState(
          draftState.requests.getDepositWalletInfo,
          action.payload,
        );
        break;

      case getType(actions.getDepositWalletInfo.success):
        produceSuccessState(draftState.requests.getDepositWalletInfo);
        draftState.fetchedWallets[action.payload.Wallet.Id] =
          action.payload.Wallet;
        break;

      case getType(actions.getDepositNextStep.request):
        produceLoadingState(draftState.requests.getDepositNextStep);
        break;

      case getType(actions.getDepositNextStep.failure):
        produceFailureState(
          draftState.requests.getDepositNextStep,
          action.payload,
        );
        break;

      case getType(actions.getDepositNextStep.success):
        produceSuccessState(draftState.requests.getDepositNextStep);
        draftState.depositNextStep = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
