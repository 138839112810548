import React, { FC, useContext, useMemo } from 'react';

import { usePageMinHeight } from '@gaming1/g1-core-web';
import { Box } from '@gaming1/g1-ui';

import { FooterContext } from '../../FooterContext';

import { StyledBox } from './styles';

export const OneColumn: FC = ({ children }) => {
  const Footer = useContext(FooterContext);

  const { minHeight } = usePageMinHeight(true);

  const content = useMemo(
    () => (
      <StyledBox data-testid="one-col-layout">
        <Box minHeight={minHeight} pb="sm">
          {children}
        </Box>
        <Footer />
      </StyledBox>
    ),
    [minHeight, children, Footer],
  );

  return content;
};
