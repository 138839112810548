import React, { useEffect } from 'react';

import { PrismicSlice, PrismicSliceCodecs } from '@gaming1/g1-cms';
import { AppAnchor } from '@gaming1/g1-ui';

import { logger } from '../../logger';
import { PrismicButtons } from '../PrismicButtons';
import { PrismicCollapsible } from '../PrismicCollapsible';
import { PrismicConditional } from '../PrismicConditional';
import { PrismicContainer } from '../PrismicContainer';
import { PrismicGrid } from '../PrismicGrid';
import { PrismicImage } from '../PrismicImage';
import { PrismicInfoBlock } from '../PrismicInfoBlock';
import { PrismicMap } from '../PrismicMap';
import { PrismicMediaCard } from '../PrismicMediaCard';
import { PrismicMediasTextBlock } from '../PrismicMediasTextBlock';
import { PrismicRichText } from '../PrismicRichText';
import { PrismicSpacer } from '../PrismicSpacer/styles';
import { PrismicSteps } from '../PrismicSteps';
import { PrismicTable } from '../PrismicTable';
import { PrismicTabs } from '../PrismicTabs';
import { PrismicVideo } from '../PrismicVideo';

export const useRenderPrismicSlice = (
  parentIds: string[],
  slice: PrismicSlice,
  testId?: string,
) => {
  const loopDetected =
    typeof slice.primary.id === 'string' &&
    parentIds.includes(slice.primary.id);

  useEffect(() => {
    if (loopDetected) {
      logger.warn(
        `[Prismic] A slice inclusion loop has been detected : ${parentIds.join(
          ' > ',
        )} > ${slice.primary.id}`,
      );
    }
  }, [parentIds, slice.primary.id, loopDetected]);

  if (loopDetected) {
    return null;
  }

  if (PrismicSliceCodecs.container.is(slice)) {
    return (
      <PrismicContainer slice={slice} testId={testId} parentIds={parentIds} />
    );
  }

  if (PrismicSliceCodecs.anchor.is(slice) && slice.primary.anchor_id) {
    return <AppAnchor id={slice.primary.anchor_id} />;
  }

  if (PrismicSliceCodecs.richText.is(slice)) {
    return (
      <PrismicRichText
        clearMargins={parentIds && parentIds.length > 0}
        content={slice.primary.content}
        testId={testId}
        sliceId={slice.primary.id}
        parentIds={parentIds}
      />
    );
  }

  if (
    PrismicSliceCodecs.buttons.is(slice) &&
    slice.items &&
    slice.items.length
  ) {
    return <PrismicButtons slice={slice} testId={testId} />;
  }

  if (PrismicSliceCodecs.image.is(slice)) {
    return <PrismicImage slice={slice} testId={testId} />;
  }

  if (PrismicSliceCodecs.tabs.is(slice)) {
    return <PrismicTabs slice={slice} testId={testId} parentIds={parentIds} />;
  }

  if (PrismicSliceCodecs.table.is(slice)) {
    return <PrismicTable slice={slice} testId={testId} />;
  }

  if (PrismicSliceCodecs.grid.is(slice)) {
    return <PrismicGrid slice={slice} testId={testId} parentIds={parentIds} />;
  }

  if (PrismicSliceCodecs.map.is(slice)) {
    return <PrismicMap slice={slice} testId={testId} />;
  }

  if (PrismicSliceCodecs.video.is(slice)) {
    return <PrismicVideo slice={slice} testId={testId} />;
  }

  if (PrismicSliceCodecs.collapsibleBlock.is(slice)) {
    return (
      <PrismicCollapsible slice={slice} testId={testId} parentIds={parentIds} />
    );
  }

  if (PrismicSliceCodecs.mediaCard.is(slice)) {
    return (
      <PrismicMediaCard slice={slice} testId={testId} parentIds={parentIds} />
    );
  }

  if (PrismicSliceCodecs.mediasTextBlock.is(slice)) {
    return (
      <PrismicMediasTextBlock
        slice={slice}
        testId={testId}
        parentIds={parentIds}
      />
    );
  }

  if (PrismicSliceCodecs.spacer.is(slice)) {
    return <PrismicSpacer slice={slice} testId={testId} />;
  }

  if (PrismicSliceCodecs.conditional.is(slice)) {
    return (
      <PrismicConditional slice={slice} testId={testId} parentIds={parentIds} />
    );
  }

  if (PrismicSliceCodecs.infoBlock.is(slice)) {
    return (
      <PrismicInfoBlock slice={slice} testId={testId} parentIds={parentIds} />
    );
  }

  if (PrismicSliceCodecs.steps.is(slice)) {
    return <PrismicSteps slice={slice} testId={testId} />;
  }

  return null;
};
