import * as t from 'io-ts';

import {
  EDepositLimitation,
  EDepositLimitationDetail,
  EDepositNextStepResponseStatus,
  EDepositStatusResponseDepositStatus,
  EDepositStatusResponseStatus,
  EDepositStepActionType,
  EDepositType,
  EFindDepositsStatus,
  EGamingRoomsBonusType,
  EGetDepositWalletInfoStatus,
  EGetDepositWalletsListInfoStatus,
  EMakeDepositStatus,
  EMeansOfLimitCancellation,
  EPrizeType,
  EScriptInjectionType,
  ExtraFieldType,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

type DepositExtraFieldHelperType = {
  Children: DepositExtraFieldHelperType[];
  Type: ExtraFieldType;
} & {
  FieldName?: string;
  FieldTitle?: string;
  Mask?: string;
  Placeholder?: string;
  Regex?: string;
  Value?: string;
};

export const depositExtraField = t.recursion<DepositExtraFieldHelperType>(
  'DepositExtraField',
  (self) =>
    t.intersection(
      [
        t.type({
          Children: t.array(self),
          Type: createEnumType<ExtraFieldType>(
            ExtraFieldType,
            'ERegistrationStatus',
          ),
        }),
        t.partial({
          FieldName: t.string,
          FieldTitle: t.string,
          Mask: t.string,
          Placeholder: t.string,
          Regex: t.string,
          Value: t.string,
        }),
      ],
      'DepositExtraField',
    ),
);
export const ScriptInjectionParam = t.type({
  Key: t.string,
  Value: t.string,
});

export const scriptInjection = t.intersection([
  t.type({
    Type: createEnumType<EScriptInjectionType>(
      EScriptInjectionType,
      'EScriptInjectionType',
    ),
  }),
  t.partial({
    ScriptInjectionParams: t.array(ScriptInjectionParam),
  }),
]);

export const wallet = t.intersection(
  [
    t.type({
      Id: t.string,
      Key: t.string,
      Name: t.string,
      MaximumDeposit: t.number,
      MinimumDeposit: t.number,
      WalletType: createEnumType<EDepositType>(EDepositType, 'EDepositType'),
    }),
    t.partial({
      MonoThumbnail: t.string,
      Thumbnail: t.string,
      ExtraFields: t.array(depositExtraField),
      DisabledDeposit: t.boolean,
      ScriptInjection: scriptInjection,
    }),

    // HasMaximumDeposit: t.boolean,
    // HasMinimumDeposit: t.boolean,
    // HasRequiredAmount: t.boolean,
    // RegistrationStatus: createEnumType<ERegistrationStatus>(ERegistrationStatus,'ERegistrationStatus')
  ],
  'Wallet',
);

export const depositLimitation = t.intersection(
  [
    t.type({
      LimitationOrigin: createEnumType<EDepositLimitation>(
        EDepositLimitation,
        'EDepositLimitation',
      ),
      LimitationOriginDetail: createEnumType<EDepositLimitationDetail>(
        EDepositLimitationDetail,
        'EDepositLimitationDetail',
      ),
    }),
    t.partial({
      AlreadyDepositedAmount: t.number,
      EndOfLimitCoolOff: t.string,
      DepositLimitationEndDate: t.string,
      MaximumAmountAvailable: t.number,
      MeansOfLimitCancellation: createEnumType<EMeansOfLimitCancellation>(
        EMeansOfLimitCancellation,
        'EMeansOfLimitCancellation',
      ),
      TotalMaximumAmount: t.number,
    }),
  ],
  'DepositLimitation',
);

export const depositLimitations = t.intersection(
  [
    t.type({
      MinimumDeposit: t.number,
      MaximumDepositAvailable: t.number,
      TotalMaximumDeposit: t.number,
    }),
    t.partial({
      ActiveDepositLimitation: depositLimitation,
      LegalDepositLimitation: depositLimitation,
      PersonalDepositLimitations: t.type({
        DailyLimitation: depositLimitation,
        WeeklyLimitation: depositLimitation,
        MonthlyLimitation: depositLimitation,
        YearlyLimitation: depositLimitation,
      }),
    }),
  ],
  'DepositLimitations',
);

export const getDepositStatusResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EDepositStatusResponseStatus.Successful),
      Amount: t.number,
      DepositId: t.string,
      DepositStatus: createEnumType<EDepositStatusResponseDepositStatus>(
        EDepositStatusResponseDepositStatus,
        'EDepositStatusResponseDepositStatus',
      ),
      Reference: t.string,
      WaitingForIdentity: t.boolean,
      WalletName: t.string,

      // DepositExtraInfoDTO: DepositExtraInfoDTO,
      // InfoMessage: t.string,
      // Message: t.string,
      // Metadata: t.array(t.type({ key: t.string, value: t.string })),
      // WaitingMessage: t.string,
    }),
    t.partial({
      ErrorMessageTranslationKey: t.string,
      ErrorMessageParameters: t.array(
        t.type({ Key: t.string, Value: t.string }),
      ),
    }),
  ],
  'GetDepositStatusResponse',
);

export const makeDepositResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EMakeDepositStatus.Successful),
      HasExtraConfiguration: t.boolean,
    }),
    t.partial({
      NewTabUrl: t.string,
      OnSiteUrl: t.string,
      PaymentURL: t.string,
      WalletName: t.string,
      DepositScriptInjection: scriptInjection,
    }),
  ],
  'MakeDepositResponse',
);

export const findDepositsResponse = t.type(
  {
    Status: t.literal(EFindDepositsStatus.Successful),
    Wallets: t.array(
      t.intersection(
        [
          t.type({
            Id: t.string,
            Name: t.string,
            Key: t.string,
            // Message: t.string,
          }),
          t.partial({
            Thumbnail: t.string,
            MonoThumbnail: t.string,
          }),
        ],
        'Wallets',
      ),
    ),
  },
  'FindDepositsResponse',
);

// Dupplicate from packages/g1-user/src/common/store/codecs.ts
export const gamingRoomsBonus = t.intersection(
  [
    t.type({
      BonusType: createEnumType<EGamingRoomsBonusType>(
        EGamingRoomsBonusType,
        'EGamingRoomsBonusType',
      ),
      EndDate: t.string,
      Id: t.string,
      MaximumDeposit: t.number,
      Priority: t.number,
      StartDate: t.string,
      Value: t.number,
    }),
    t.partial({
      ConfirmationMessage: t.string,
      Description: t.string,
      HowToGet: t.string,
      JsAction: t.string,
      JsText: t.string,
      Name: t.string,
      Picture: t.string,
      ShortDescription: t.string,
      Validity: t.number,
    }),
  ],
  'GamingRoomsBonus',
);

// Dupplicate from packages/g1-user/src/common/store/codecs.ts
export const prize = t.intersection(
  [
    t.type({
      Amount: t.number,
      Id: t.string,
      IsOnlyFirstDeposit: t.boolean,
      Order: t.number,
      PrizeConfigurationId: t.string,
      PrizeType: createEnumType<EPrizeType>(EPrizeType, 'EPrizeType'),
      Used: t.boolean,
    }),
    t.partial({
      Cost: t.number,
      Description: t.string,
      ExtraInfo: t.string,
      MinDepositAmount: t.number,
      Name: t.string,
      PictureUrl: t.string,
      TermsAndConditions: t.string,
      Title: t.string,
      Unit: t.string,
      Values: t.array(t.string),
    }),
  ],
  'Prize',
);

// Dupplicate from packages/g1-user/src/common/store/codecs.ts
export const prizeConfiguration = t.intersection(
  [
    t.type({
      Id: t.string,
      IsOnlyFirstDeposit: t.boolean,
    }),
    t.partial({
      Description: t.string,
      PictureUrl: t.string,
      Prizes: t.array(prize),
      TermsAndConditions: t.string,
      Title: t.string,
    }),
  ],
  'PrizeConfiguration',
);

export const getDepositWalletListResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetDepositWalletsListInfoStatus.Successful),
      Wallets: t.array(wallet),
      BonusList: t.array(gamingRoomsBonus),
      DefaultAmount: t.number,
      MaximumDeposit: t.number,
      MinimumDeposit: t.number,
      PrizeConfigurationList: t.array(prizeConfiguration),
    }),
    t.partial({
      RemainingDepositAttempts: t.number,
      LastDepositAmount: t.number,
      LastWalletId: t.string,
      DepositLimitations: depositLimitations,
    }),
  ],
  'getDepositWalletListResponse',
);

export const getDepositWalletInfoResponse = t.type(
  {
    Wallet: wallet,
    Status: t.literal(EGetDepositWalletInfoStatus.Successful),
  },
  'GetDepositWalletInfoResponse',
);

export const getDepositNextStepAction = t.intersection(
  [
    t.type({
      Type: createEnumType<EDepositStepActionType>(
        EDepositStepActionType,
        'EDepositStepActionType',
      ),
    }),
    t.partial({
      Script: scriptInjection,
      ScriptInit: scriptInjection,
    }),
  ],
  'GetDepositNextStepAction',
);

export const getDepositNextStepResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EDepositNextStepResponseStatus.Successful),
    }),
    t.partial({
      Action: getDepositNextStepAction,
    }),
  ],
  'GetDepositNextStepResponse',
);
