import React, {
  FC,
  memo,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  actions,
  BettingSlipStringType,
  bettingSlipTypeGetterSelector,
  EBettingSlipIdentifier,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
  useBettingSlipIsValidType,
} from '@gaming1/g1-betting';

import { useUniformizationGiftsDesign } from '../../../../common/hooks/config';
import { BettingSlipTitleContainer } from '../../../../styles';
import { BettingSlipContext } from '../../../BettingSlipContext';
import { OffersAvailableBackdrop } from '../../OffersAvailableBackdrop';
import { OffersAvailableDrawer } from '../../OffersAvailableDrawer';
import { OffersAvailableDrawer as OffersAvailableDrawerV2 } from '../../OffersAvailableDrawerV2';

import { BettingSlipContent } from './BettingSlipContent';
import { BettingSlipTitle } from './BettingSlipTitle';
import { BettingSlipHookContainer } from './containers/BettingSlipHookContainer';

type BettingSlipMainProps = {
  /** If we need to NOT display the title of the bettingSlip */
  showTitle?: boolean;
};

/**
 * Display the main ticket
 * a list of item inside select by user and save into store
 */
export const BettingSlipMainComponent: FC<BettingSlipMainProps> = ({
  showTitle = true,
}) => {
  const bettingSlipId = EBettingSlipIdentifier.Main;
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);
  const uniformizationGiftsDesignActive = useUniformizationGiftsDesign();

  const [refToScrollTo, setRefToScrollTo] =
    useState<RefObject<HTMLDivElement> | null>(() => null);

  const [isInputStakeFocused, setIsInputStakeFocused] =
    useState<boolean>(false);

  const type = useBettingGetterSelector({
    getterSelector: bettingSlipTypeGetterSelector,
    args: [bettingSlipId],
  });

  const isValidType = useBettingSlipIsValidType(bettingSlipId);

  const switchType = useCallback(
    (bettingSlipType: BettingSlipStringType) => {
      if (!isValidType(bettingSlipType)) {
        return;
      }

      if (type === bettingSlipType) {
        return;
      }
      allowBSActions(
        actions.changeBettingSlipType({ type: bettingSlipType, bettingSlipId }),
      );
      allowBSActions(actions.removeFreebetToBS({ bettingSlipId }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isValidType, type],
  );

  const providerContext = useMemo(
    () => ({
      bettingSlipId,
      type,
      switchType,
      refToScrollTo,
      setRefToScrollTo,
      isInputStakeFocused,
      setIsInputStakeFocused,
    }),
    [
      bettingSlipId,
      refToScrollTo,
      switchType,
      type,
      isInputStakeFocused,
      setIsInputStakeFocused,
    ],
  );

  const content = useMemo(
    () => (
      <BettingSlipContext.Provider value={providerContext}>
        <BettingSlipHookContainer />
        <OffersAvailableBackdrop />
        {uniformizationGiftsDesignActive ? (
          <OffersAvailableDrawerV2 />
        ) : (
          <OffersAvailableDrawer />
        )}
        {showTitle ? (
          <BettingSlipTitleContainer>
            <BettingSlipTitle />
          </BettingSlipTitleContainer>
        ) : null}
        <BettingSlipContent />
      </BettingSlipContext.Provider>
    ),
    [providerContext, showTitle, uniformizationGiftsDesignActive],
  );

  return content;
};

export const BettingSlipMain = memo(BettingSlipMainComponent);
