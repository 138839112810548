import React, { FC } from 'react';

import { ApplyBoostusToType } from '@gaming1/g1-config';
import { useFormatMoney } from '@gaming1/g1-core';
import { Box, Text } from '@gaming1/g1-ui';

import { OpacityContainer } from '../../styles';

import { HistoryStrikeThroughTextForBoostus, NoWinningsText } from './styles';

type UpdatedWinningsProps = {
  baseWinnings: number;
  finalWinnings?: number;
  updateType: ApplyBoostusToType;
};

/**
 * This component will render the updated winnings :
 * If the update config is not set at potentialWinnings, it could render the baseWinnings x finalOdds (boosted odds)
 * or simply the baseWinnings (no boostus used).
 *
 * If the update config is set at potentialWinnings, then we have to show the baseWinnings with the finalWinnings.
 */
export const UpdatedWinnings: FC<UpdatedWinningsProps> = ({
  baseWinnings,
  finalWinnings,
  updateType,
}) => {
  const formatMoney = useFormatMoney();

  const winning = finalWinnings || baseWinnings;

  if (winning > 0) {
    // If we have to show the previous winnings, then :
    if (updateType === 'potentialWinnings' && finalWinnings) {
      return (
        <Box
          data-testid="updated-winnings-container"
          flexDirection="row"
          alignItems="center"
        >
          <OpacityContainer>
            <HistoryStrikeThroughTextForBoostus data-testid="base-strikeThrough-winnings-text">
              {formatMoney(baseWinnings)}
            </HistoryStrikeThroughTextForBoostus>
          </OpacityContainer>
          <Text
            ml="xs"
            mt="xxs"
            fontWeight="bold"
            data-testid="final-winnings-text"
          >
            {finalWinnings ? formatMoney(finalWinnings) : '-'}
          </Text>
        </Box>
      );
    }

    return (
      <Text fontWeight="bold" mt="xxs" data-testid="winnings-card-text">
        {winning > 0 ? formatMoney(winning) : '-'}
      </Text>
    );
  }

  return <NoWinningsText>-</NoWinningsText>;
};
