import { EActivitySource, EActivityType } from '@gaming1/g1-requests';
import {
  generateInitialRequestState,
  RemoteData,
  slugifyPayload,
} from '@gaming1/g1-utils';

import { ApplicationWithUserState } from '../../store/types';

import { LimitsActivities } from './types';

/** Set user limits */
export const setLimitsSelector =
  (state: ApplicationWithUserState) => (parameters: LimitsActivities) =>
    state.user.limits.setLimits[slugifyPayload(parameters)] || null;

export const setLimitsStatusSelector =
  (state: ApplicationWithUserState) => (parameters: LimitsActivities) =>
    state.user.limits.requests.setLimits[slugifyPayload(parameters)]?.status ||
    RemoteData.NotAsked;

export const setLimitsErrorSelector =
  (state: ApplicationWithUserState) => (parameters: LimitsActivities) =>
    state.user.limits.requests.setLimits[slugifyPayload(parameters)]
      ?.errorMessage;

/** Get user limits */
export const getLimitsSelector =
  (state: ApplicationWithUserState) => (parameters: LimitsActivities) =>
    state.user.limits.limits[slugifyPayload(parameters)] || null;

export const getLimitsStatusSelector =
  (state: ApplicationWithUserState) => (parameters: LimitsActivities) =>
    state.user.limits.requests.getLimits[slugifyPayload(parameters)]?.status ||
    RemoteData.NotAsked;

export const getLimitsErrorSelector =
  (state: ApplicationWithUserState) =>
  (parameters: { type: EActivityType; source: EActivitySource }) =>
    state.user.limits.requests.getLimits[slugifyPayload(parameters)]
      ?.errorMessage;

/** Get remaining connection time */
export const remainingConnectionTimeSelector = (
  state: ApplicationWithUserState,
) => state.user.limits.remainingConnectionTime;

export const getRemainingConnectionTimeRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.limits.requests.getRemainingConnectionTime;

/** Get remaining time to spend */
export const remainingTimeToSpendSelector = (state: ApplicationWithUserState) =>
  state.user.limits.remainingTimeToSpend;

export const getRemainingTimeToSpendRequestStateSelector =
  (state: ApplicationWithUserState) =>
  (parameters: { source: EActivitySource }) =>
    state.user.limits.requests.getRemainingTimeToSpend[
      slugifyPayload(parameters)
    ] || generateInitialRequestState();
