import { UserConfig } from '../types';

const oneDay = 60 * 24;
const oneWeek = oneDay * 7;
const oneMonth = oneDay * 30;

/**
 * User login/registration/account config
 */
export const defaultUserConfig: UserConfig = {
  amountIconSide: 'right',
  authenticationMethods: ['online'],
  autocompleteNationalNumber: false,
  bankAccountFormat: 'iban',
  birthdateOnLogin: false,
  bonus: false,
  clockFormat: "HH'h'mm",
  closeAccount: false,
  defaultCountry: 'be',
  depositAmountPresets: [20, 50, 100, 200],
  displayPersonalDataBirthCity: true,
  displayPersonalDataBirthCountry: true,
  displayPersonalDataGender: true,
  displayPersonalDataPhoneNumber: ['landline', 'mobile'],
  formValidations: {
    passwordMaxChars: 64,
    passwordMinChars: 8,
    userNameMaxChars: 15,
    userNameMinChars: 5,
  },
  fullScreenForms: true,
  gamePause: true,
  gamePauseDateMaxDurationInDays: 0,
  gamePauseDateMinDurationInDays: 1,
  gamePauseDurations: [oneDay, oneWeek, oneMonth],
  gamePauseInput: 'select',
  hasNationalNumber: 'true',
  histories: {
    deposit: true,
    freeSpins: false,
    scratchcard: false,
    shop: false,
    sponsorship: false,
    withdrawal: true,
  },
  inbox: false,
  inboxProjectKey: '',
  legalDepositLimit: false,
  legalDepositLimitInRegistration: false,
  legalDepositLimitValue: 500,
  legalModal: true,
  legalModalMaxMessages: 4,
  limits: {
    connectionTime: false,
    connectionTimePeriodicities: ['daily'],
    coolOffInMinute: 4320, // 72h
    deposit: true,
    depositPeriodicities: ['daily', 'monthly', 'weekly'],
    lossBetting: false,
    lossGaming: false,
    lossPeriodicities: ['daily', 'monthly', 'weekly'],
    spentTimeBetting: false,
    spentTimeGaming: false,
    spentTimePeriodicities: ['daily'],
    wageBetting: false,
    wageGaming: false,
    wagePeriodicities: ['daily', 'monthly', 'weekly'],
  },
  minimalLegalAge: 18,
  minimalLegalAgePlusOneDay: false,
  noticeOnLogin: false,
  pinCode: false,
  privacyConditionsFileKey: 'privacy',
  privacyConditionsModal: true,
  registrationMultiStep: false,
  registrationSuccessCheckAutenticationStatusPolling: false,
  remainingTimeLimit: false,
  selfBlacklisting: false,
  selfExclusion: true,
  selfExclusionDateMaxDurationInDays: 0,
  selfExclusionDateMinDurationInDays: 1,
  selfExclusionDurationsInMinutes: [
    oneMonth * 2,
    oneMonth * 4,
    oneMonth * 6,
    oneMonth * 12,
  ],
  selfExclusionFixedDurationInMinutes: oneMonth * 6,
  selfExclusionInput: 'fixed',
  showLastConnection: false,
  sponsorship: false,
  termsAndConditionFileKey: 'terms',
  termsAndConditionsModal: true,
  withdrawalAmountPresets: [25, 50, 75, 100],
};
