import styled, { css } from 'styled-components';

import { Alignement } from '@gaming1/g1-cms';
import { elevation, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

type PrismicContainerElementProps = {
  styleName?: string;
  alignment?: Alignement;
  width?: string | null;
};

export const PrismicContainerElement = styled(
  Box,
)<PrismicContainerElementProps>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-self: ${({ alignment }) => {
    switch (alignment?.toLowerCase()) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};
  ${({ styleName }) =>
    styleName?.toLowerCase() === 'card'
      ? css`
          ${elevation(2)}
          padding: ${spaces('sm')};
        `
      : null}
`;
