import { createGlobalStyle, css } from 'styled-components';

import { PageWrapper } from '@gaming1/g1-core-web';

import background from '../../assets/core/background.png';

/** Overwrite the css of some components from the libraries for this partner */
export const OverwriteStyle = createGlobalStyle`
   ${({ theme }) =>
     theme.name === 'rainbow' &&
     css`
       ${PageWrapper} {
         background-image: url('${background}');
         background-position: center;
         background-repeat: repeat;
         box-shadow: inset 0 0 20px 20px orange;
       }

       body {
         color: cyan !important;
       }
     `}
   `;
