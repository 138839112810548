import {
  EChangePlayerProfileStatus,
  EGetAllCountriesStatus,
  EGetStatesStatus,
} from '@gaming1/g1-requests';

export const changePlayerProfileErrorMessages = {
  [EChangePlayerProfileStatus.InternalError]: 'core:error.internalServer', // 0
  [EChangePlayerProfileStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EChangePlayerProfileStatus.FieldError]: 'user:account.message.formInvalid', // 3
  [EChangePlayerProfileStatus.InvalidSecurityPinCode]:
    'user:account.message.invalidPinCode', // 4
};

export const countriesErrorMessages = {
  [EGetAllCountriesStatus.InternalError]: 'core:error.internalServer', // 0
};

export const statesErrorMessages = {
  [EGetStatesStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetStatesStatus.StatesDisabled]: 'user:account.message.statesDisabled', // 2
  [EGetStatesStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
};
