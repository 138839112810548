/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:34:26 01-02-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  ScratchCardHistoryRequest,
  ScratchCardHistoryResponse,
} from '../entities';

export const getHistory = (options: ScratchCardHistoryRequest) =>
  createRequest<ScratchCardHistoryResponse>(100, 'GetHistory', options);
