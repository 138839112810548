import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { NotificationCode } from '@gaming1/g1-requests-betting';
import { isNonNullable, RemoteData } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../store/types';

export const freebetsStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.freebets;

export const getFreebetsStateSelector = (state: ApplicationWithBettingState) =>
  freebetsStateSelector(state).request;

export const freebetsIsLoadingGettorSelector = createSelector(
  getFreebetsStateSelector,
  (freebetsStatus) => freebetsStatus.status === RemoteData.Loading,
);

export const freebetsIsSuccessGettorSelector = createSelector(
  freebetsStateSelector,
  getFreebetsStateSelector,
  (freebetsState, freebetsStatus) => {
    if (freebetsStatus.status !== RemoteData.Success) {
      return false;
    }
    // TODO: check notification code
    if (
      freebetsState.result?.Notification &&
      freebetsState.result.Notification.Code ===
        NotificationCode.Freebet_Success
    ) {
      return true;
    }
    return false;
  },
);
export const freebetsIsErrorGettorSelector = createSelector(
  freebetsStateSelector,
  getFreebetsStateSelector,
  (freebetsState, freebetsStatus) => {
    if (freebetsStatus.status === RemoteData.Error) {
      return true;
    }
    if (
      freebetsState.result?.Notification &&
      freebetsState.result.Notification.Code !==
        NotificationCode.Freebet_Success
    ) {
      return true;
    }
    return false;
  },
);

export const freebetsConditionsListGettorSelector = createSelector(
  freebetsStateSelector,
  (freebetsState) =>
    memoize((id: string) =>
      freebetsState.result?.Conditions?.filter(isNonNullable).find(
        (condition) => condition.Id === id,
      ),
    ),
);
