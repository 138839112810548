import React, { VFC } from 'react';

import { useConfig } from '@gaming1/g1-config';

import { LegalModal } from '../../../legal/components/LegalModal';
import { PrivacyConditionsModal } from '../../../legal/components/PrivacyConditionsModal';
import { TermsAndConditionsModal } from '../../../legal/components/TermsAndConditionsModal';
import { RemainingTimeLimitBanner } from '../../../limits/components/RemainingTimeLimitBanner';
import { PinCodeResetWatcher } from '../../../multifactorAuthentication/components/PinCodeResetWatcher';

/**
 * This component is supposed to be rendered in the "AppLayout" of the partner
 * app
 *
 * It will render (depending on the configuration)
 *
 * - The LegalModal. Rendered for the belgian regulation where user should be
 *   blocked every 30 minutes (or so) and be shown a message.
 * - The PrivacyConditionsModal. It is mainly used by belgian partners to warn
 *   the user a deposit limit has been applied to his account and he has to
 *   approve it.
 * - The TermsAndConditionsModal. Its purpose is to warn the user T&C have been
 *   updated and need to be approved.
 * - The RemainingTimeLimitBanner which indicates to the user that its session
 *   is about to expire
 * - The PinCodeResetWatcher that open a modal when the pin code should be
 *   updated
 */
export const UserWatchers: VFC = () => {
  const { user } = useConfig();
  return (
    <>
      {user.legalModal && <LegalModal />}

      {user.privacyConditionsModal && <PrivacyConditionsModal />}

      {user.termsAndConditionsModal && <TermsAndConditionsModal />}

      {user.remainingTimeLimit && <RemainingTimeLimitBanner />}

      {user.pinCode && <PinCodeResetWatcher />}
    </>
  );
};
