import { createAsyncAction } from 'typesafe-actions';

import { ChangePlayerProfileRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  ChangePlayerProfileResponse,
  GetCountriesResponse,
  GetStatesResponse,
} from './types';

/* Async actions */

/** Change user profile */
export const changeUserProfile = createAsyncAction(
  'user/change_profile_request',
  'user/change_profile_success',
  'user/change_profile_failure',
)<ChangePlayerProfileRequestDTO, ChangePlayerProfileResponse, FailurePayload>();

/** Get all residence countries */
export const getResidenceCountries = createAsyncAction(
  'user/get_residence_countries_request',
  'user/get_residence_countries_success',
  'user/get_residence_countries_failure',
)<void, GetCountriesResponse, FailurePayload>();

/** Get all states */
export const getStates = createAsyncAction(
  'user/get_states_request',
  'user/get_states_success',
  'user/get_states_failure',
)<void, GetStatesResponse, FailurePayload>();
