import React, { FC } from 'react';

import { ApplyBoostusToType } from '@gaming1/g1-config';

import { Odds } from '../../../../common/components/Odds';

type UpdatedOddsProps = {
  baseOdds: number;
  finalOdds?: number;
  updateType: ApplyBoostusToType;
  id?: string;
};

/**
 * If a {finalOdds} is given, it'll render the strikeThrough {baseOdds} and then the {finalOdds} in bold.
 * If not {finalOdds}, the component will just render the {baseOdds} in bold.
 */
export const UpdatedOdds: FC<UpdatedOddsProps> = ({
  baseOdds,
  finalOdds,
  id,
  updateType,
}) => {
  if (updateType !== 'odds' || !finalOdds) {
    return <Odds id={id} baseOdds={baseOdds} />;
  }

  return <Odds id={id} baseOdds={baseOdds} finalOdds={finalOdds} />;
};
