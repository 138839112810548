import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { useCallback, VFC } from 'react';

import { getEventName, LightSearchEventType } from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { HighlightedText, useTracking } from '@gaming1/g1-core-web';
import { EventType } from '@gaming1/g1-requests-betting';
import { Box, Icon } from '@gaming1/g1-ui';

import { useBettingRoutePath } from '../../../hooks';
import { ScoreBoardPlayTime } from '../../../score/components/ScoreBoardPlayTime';
import { DateSpan } from '../../../sportbook/components/EventSummary/styles';
import { EllipsisBox } from '../../../sportbook/components/LateralLiveMenu/styles';

import {
  EventLink,
  SearchLabel,
  SearchResultRow,
  SearchResultWrapper,
} from './styles';

type SearchEventItemProps = {
  event: LightSearchEventType;
  searchTerms: string[];
};

/** Render the result's title, description and will highlight the matching part of the result's title  */
export const SearchEventItem: VFC<SearchEventItemProps> = ({
  event,
  searchTerms,
}) => {
  const { i18n } = useConfig();

  const id = event.EventId;
  const eventType = event.EventType;
  const type = event.EventDisplayType;
  const eventName = event.IsWinner
    ? event.HomeName || ''
    : getEventName(event.HomeName, event.AwayName, type);

  const getBettingRoutePath = useBettingRoutePath();
  const pageName =
    eventType === EventType.Prematch ? 'eventPrematch' : 'eventLive';
  const url = event?.IsWinner
    ? getBettingRoutePath('league', {
        leagueId: `${event.LeagueId || ''}`,
        regionId: `${event.RegionId || ''}`,
        sportId: `${event.SportId || ''}`,
      })
    : getBettingRoutePath(pageName, {
        eventId: `${event.EventId || ''}`,
        leagueId: `${event.LeagueId || ''}`,
        regionId: `${event.RegionId || ''}`,
        sportId: `${event.SportId || ''}`,
      });

  const { pushEvent } = useTracking();

  const onClick = useCallback(() => {
    // Tracking: search item clicked
    pushEvent({
      name: 'search',
      action: 'event clicked',
      category: 'betting',
      label: 'drawer',
      value: eventName || '',
      searchTerm: searchTerms.join(' '),
    });
  }, [eventName, pushEvent, searchTerms]);

  return (
    <EventLink to={url} data-testid="search-event-item-link">
      <SearchResultWrapper
        mb="md"
        width="100%"
        data-testid="search-event-item-wrapper"
        onClick={onClick}
      >
        <SearchResultRow>
          <Box width="calc(100% - 24px)">
            <Box>
              {eventType === EventType.Prematch && (
                <DateSpan data-testid="search-event-prematch-date">
                  {format(
                    parseISO(event.StartDate),
                    i18n.dateTimeShortPartialFormat,
                  )}
                </DateSpan>
              )}
              {eventType === EventType.Live && (
                <ScoreBoardPlayTime eventId={id} />
              )}
            </Box>
            <EllipsisBox mt="xxs">
              <HighlightedText
                ellipsis
                searchTerms={searchTerms}
                text={eventName}
              />
            </EllipsisBox>
            <Box flexDirection="row" mt="xs">
              {event?.SportName && (
                <SearchLabel
                  data-testid="search-event-sport-name"
                  isUppercase
                  mr="xs"
                >
                  {event?.SportName}
                </SearchLabel>
              )}
              {event?.LeagueName && !event?.IsWinner && (
                <SearchLabel data-testid="search-event-league-name">
                  {event?.LeagueName}
                </SearchLabel>
              )}
              {event?.RegionName && event?.IsWinner && (
                <SearchLabel data-testid="search-event-region-name">
                  {event?.RegionName}
                </SearchLabel>
              )}
            </Box>
          </Box>
          <Icon
            type="ArrowRight"
            id="search-result-right"
            width={12}
            height={12}
          />
        </SearchResultRow>
      </SearchResultWrapper>
    </EventLink>
  );
};
