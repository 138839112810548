import * as t from 'io-ts';

import { PrismicCodecs } from '../../common/codecs';

export const pageContentData = t.type(
  {
    body: t.array(PrismicCodecs.slice),
    title: t.array(PrismicCodecs.richText),
    seo_description: t.union([t.null, t.string]),
    seo_title: t.union([t.null, t.string]),
  },
  'PageContentData',
);

export const pageContentDocument = t.type(
  {
    id: t.string,
    type: t.literal('content_v2'),
    data: pageContentData,
    uid: t.string,
  },
  'PageContentDocument',
);

export const pageContentQueryResponse = t.type(
  {
    results: t.array(pageContentDocument),
  },
  'PageContentQueryResponse',
);
