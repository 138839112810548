import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { RemoteData } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../../store/types';

/* COMPONENTS */
export const componentsSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.components;

export const componentStatusSelector = createSelector(
  componentsSelector,
  (components) =>
    memoize(
      (key: string) => components[key]?.request?.status || RemoteData.NotAsked,
    ),
);
