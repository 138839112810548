import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../store/types';

import { orderSuperOddsByStartDateOrAlphabetically } from './helpers';

const getSuperOddsStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.superOdds;

/** hasAvailableDailyOffers selectors */
export const getHasSuperOddsSelector = createSelector(
  getSuperOddsStateSelector,
  (getSuperOddsState) => memoize(() => getSuperOddsState.result.hasSuperOdds),
);

export const getSuperOddsRelatedEventIdsSelector = createSelector(
  getSuperOddsStateSelector,
  (superOdds) =>
    memoize(() => superOdds.result.relatedEventIds.filter(isNonNullable)),
);

/** getAvailableSuperOdds selectors */
export const getSuperOddsRequestStateSelector = (
  state: ApplicationWithBettingState,
) => getSuperOddsStateSelector(state).requests.getAvailableSuperOdds;

export const getSuperOddsRequestStatusSelector = (
  state: ApplicationWithBettingState,
) => getSuperOddsRequestStateSelector(state).status;

export const getSuperOddsGetterSelector = createSelector(
  getSuperOddsStateSelector,
  (superOdds) =>
    memoize(() =>
      orderSuperOddsByStartDateOrAlphabetically(
        superOdds.result.availableSuperOdds.ManualBets?.filter(isNonNullable) ||
          [],
      ),
    ),
);

export const getSuperOddsForEventGetterSelector = createSelector(
  getSuperOddsStateSelector,
  (superOdds) =>
    memoize(
      (eventId: number) =>
        superOdds.result.availableSuperOddsForEvent.ManualBets?.filter(
          isNonNullable,
        ).filter((so) => so.RelatedEventId === Number(eventId)) || [],
    ),
);

export const getAvailableSuperOddsForEventGetterSelector = createSelector(
  getSuperOddsForEventGetterSelector,
  (getEventSuperOdds) =>
    memoize((eventId: number) =>
      orderSuperOddsByStartDateOrAlphabetically(
        getEventSuperOdds(eventId).filter((superOdds) => !superOdds.UsedDate),
      ),
    ),
);
