import * as t from 'io-ts';

import {
  AssociatedPromoType,
  BettingSlipElementStatus,
  BettingSlipStatus,
  BettingSlipType,
  CreditType,
  EventType,
  GiftType,
  PromotionState,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { cashoutDataCodec } from '../../cashout/codecs';
import { notificationCodec } from '../../notification/codecs';

export const boostusHistoryInfoCodec = t.intersection(
  [
    t.type({
      BoostValue: t.number,
      IsValid: t.boolean,
    }),
    t.partial({
      Name: t.string,
    }),
  ],
  'BoostusHistoryInfoCodec',
);

export const promotionInfoCodec = t.intersection(
  [
    t.type({
      State: createEnumType<PromotionState>(PromotionState),
      GiftType: createEnumType<GiftType>(GiftType),
    }),
    t.partial({
      Name: t.string,
    }),
  ],
  'PromotionInfoCodec',
);

export const associatedPromotionCodec = t.intersection(
  [
    t.type({
      Types: t.array(createEnumType<AssociatedPromoType>(AssociatedPromoType)),
    }),
    t.partial({
      Boostus: boostusHistoryInfoCodec,
      Promotion: promotionInfoCodec,
    }),
  ],
  'AssociatedPromotionCodec',
);

export const optimalBetInformationCodec = t.type(
  {
    NumberOfOutcomes: t.number,
    OptimalBetApplied: t.boolean,
  },
  'OptimalBetInformationCodec',
);

export const bettingHistoryElementCodec = t.intersection(
  [
    t.type({
      StartDate: t.string,
      EventId: t.number,
      EventName: t.string,
      MarketId: t.number,
      MarketName: t.string,
      OutcomeId: t.number,
      OutcomeName: t.string,
      EventType: createEnumType<EventType>(EventType),
      Status: createEnumType<BettingSlipElementStatus>(
        BettingSlipElementStatus,
      ),
      BaseOdds: t.number,
    }),
    t.partial({
      FinalOdds: t.number,
      PlayerName: t.string,
      TeamName: t.string,
    }),
  ],
  'bettingHistoryElementCodec',
);

export const bettingHistoryTicketCodec = t.intersection(
  [
    t.type({
      Id: t.string,
      CreatedDate: t.string,
      CreditType: createEnumType<CreditType>(CreditType),
      BaseOdds: t.number,
      Stake: t.number,
      BaseWinnings: t.number,
      Type: createEnumType<BettingSlipType>(BettingSlipType),
      Status: createEnumType<BettingSlipStatus>(BettingSlipStatus),
      Elements: t.array(bettingHistoryElementCodec),
    }),
    t.partial({
      Name: t.string,
      ClosedDate: t.string,
      FinalOdds: t.number,
      FinalWinnings: t.number,
      OptimalBetInformation: optimalBetInformationCodec,
      AssociatedPromotion: associatedPromotionCodec,
      CashoutData: cashoutDataCodec,
    }),
  ],
  'BettingHistoryTicketCodec',
);

export const getBettingHistoryResponseCodec = t.type(
  {
    BettingHistory: t.array(bettingHistoryTicketCodec),
    Notification: notificationCodec,
  },
  'GetBettingHistoryResponseCodec',
);

export const getBettingSlipSystemDetailResponseCodec = t.type(
  {
    Detail: t.array(bettingHistoryTicketCodec),
    Notification: notificationCodec,
  },
  'GetBettingSlipSystemDetailResponseCodec',
);
