import { ApplicationWithUserState } from '../../store/types';

/** Accept Privacy Conditions */
export const acceptPrivacyConditionsRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.privacyConditions.requests.acceptPrivacyConditions;

/** Get Privacy Conditions */
export const getPrivacyConditionsSelector = (state: ApplicationWithUserState) =>
  state.user.privacyConditions.path;

export const getPrivacyConditionsRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.privacyConditions.requests.getPrivacyConditions;

/** Show Privacy Conditions */
export const showPrivacyConditionsSelector = (
  state: ApplicationWithUserState,
) => state.user.privacyConditions.show;

export const showPrivacyConditionsRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.privacyConditions.requests.showPrivacyConditions;
