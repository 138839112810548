/* eslint-disable react/no-array-index-key */
import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardLastPeriodScoreSelector,
  getScoreBoardSportStatsSelector,
  getScoreBoardTeamNamesSelector,
  getScoreBoardTotalCountWonPeriodsSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useMediaBreakPoint } from '@gaming1/g1-ui';

import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardPlayTime } from '../ScoreBoardPlayTime';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import { useScoreBoardFullTranslations } from './hooks';
import {
  ScoreBoardFullTemplateColumn as TemplateColumn,
  ScoreBoardFullTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardFullTemplateEllipsis as TemplateEllipsis,
  ScoreBoardFullTemplateItem as TemplateItem,
  ScoreBoardFullTemplateRow as TemplateRow,
  ScoreBoardFullTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the full scoreboard with a darts template */
export const ScoreBoardFullDartsTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
  sportId,
}) => {
  const isDesktop = useMediaBreakPoint({ min: 'lg' });
  const { team1: name1, team2: name2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: legs1, team2: legs2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Legs'],
    equalityFn: shallowEqual,
  });
  const { team1: lastPeriod1, team2: lastPeriod2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardLastPeriodScoreSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: wonPeriods1, team2: wonPeriods2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalCountWonPeriodsSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  const translations = useScoreBoardFullTranslations();

  return (
    <TemplateWrapper data-testid="scoreboard-full-darts-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper
          flexDirection="row"
          justifyContent="space-between"
        >
          <TemplateColumn gap="xxxs" pt="xxs" ellipsis>
            <TemplateRow pt="xxxs" pb="xxs">
              <TemplateEllipsis>
                <ScoreBoardPlayTime eventId={eventId} fontSize="md" />
              </TemplateEllipsis>
            </TemplateRow>
            <TemplateRow>
              <TemplateItem>
                <TemplateEllipsis>{name1}</TemplateEllipsis>
              </TemplateItem>
            </TemplateRow>
            <TemplateRow>
              <TemplateItem>
                <TemplateEllipsis>{name2}</TemplateEllipsis>
              </TemplateItem>
            </TemplateRow>
          </TemplateColumn>
          <TemplateColumn maxWidth="50%">
            <TemplateRow gap="xxxs" justifyContent="flex-end">
              <TemplateColumn gap="xxxs" px="xxs">
                <TemplateItem />
                <TemplateItem fontSize={isDesktop ? 'xl' : 'lg'}>
                  {legs1}
                </TemplateItem>
                <TemplateItem fontSize={isDesktop ? 'xl' : 'lg'}>
                  {legs2}
                </TemplateItem>
              </TemplateColumn>
              <TemplateColumn gap="xxxs">
                <TemplateItem fontSize="sm">{translations.legs}</TemplateItem>
                <TemplateItem hasBackground>{lastPeriod1}</TemplateItem>
                <TemplateItem hasBackground>{lastPeriod2}</TemplateItem>
              </TemplateColumn>
              <TemplateColumn gap="xxxs">
                <TemplateItem fontSize="sm">{translations.sets}</TemplateItem>
                <TemplateItem hasBackground>{wonPeriods1}</TemplateItem>
                <TemplateItem hasBackground>{wonPeriods2}</TemplateItem>
              </TemplateColumn>
            </TemplateRow>
          </TemplateColumn>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
