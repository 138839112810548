import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable, RemoteData } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../store/types';

import {
  filterBoostusThenOrderToNotUsedBoostus,
  filterBoostusThenOrderToUsedBoostus,
} from './helpers';

const getBoostusStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.boostus;

/** getHasBoostusAvailable selectors */

export const getHasBoostusSelector = (state: ApplicationWithBettingState) =>
  getBoostusStateSelector(state).result.hasBoostus;

export const getHasBoostusRequestSelector = (
  state: ApplicationWithBettingState,
) => getBoostusStateSelector(state).requests.getHasBoostus;

export const getHasBoostusRequestStatusSelector = (
  state: ApplicationWithBettingState,
) => getHasBoostusRequestSelector(state).status;

export const getHasBoostusErrorMessageSelector = (
  state: ApplicationWithBettingState,
) => getHasBoostusRequestSelector(state).errorMessage;

/** getAvailableBoostus selectors */

export const getBoostusRequestStateSelector = (
  state: ApplicationWithBettingState,
) => getBoostusStateSelector(state).requests.getAvailableBoostus;

export const getBoostusRequestStatusSelector = (
  state: ApplicationWithBettingState,
) => getBoostusRequestStateSelector(state).status;

export const boostusIsSuccessGettorSelector = createSelector(
  getBoostusRequestStatusSelector,
  (boostusStatus) => {
    if (boostusStatus !== RemoteData.Success) {
      return false;
    }
    return true;
  },
);

export const getBoostusGetterSelector = (state: ApplicationWithBettingState) =>
  getBoostusStateSelector(
    state,
  )?.result?.getAvailableBoostus?.Boostuses?.filter(isNonNullable) || [];

export const getConditionsSelector = (state: ApplicationWithBettingState) =>
  state.betting.boostus.result.getAvailableBoostus?.Conditions;

export const getBoostusCorrespondingToConditionIdsGetterSelector =
  createSelector(getBoostusGetterSelector, (list) =>
    memoize(
      (ids: string[]) =>
        list &&
        ids &&
        list
          .filter((boostus) => boostus.ConditionId)
          .filter((boostus) => ids.includes(boostus.ConditionId || '')),
    ),
  );

export const boostusConditionsListGettorSelector = createSelector(
  getBoostusStateSelector,
  (boostusState) =>
    memoize((id: string) =>
      boostusState?.result?.getAvailableBoostus?.Conditions?.filter(
        isNonNullable,
      ).find((condition) => condition.Id === id),
    ),
);

export const boostusFromIdSelector = createSelector(
  getBoostusStateSelector,
  (boostusState) =>
    memoize((id: string) =>
      boostusState?.result?.getAvailableBoostus?.Boostuses?.filter(
        isNonNullable,
      ).find((boostus) => boostus.BoostusId === id),
    ),
);

export const getBoostusUsedSelector = createSelector(
  getBoostusGetterSelector,
  (boostus) => filterBoostusThenOrderToUsedBoostus(boostus),
);

export const getBoostusNotUsedSelector = createSelector(
  getBoostusGetterSelector,
  (boostus) => filterBoostusThenOrderToNotUsedBoostus(boostus),
);
