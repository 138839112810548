import styled from 'styled-components';

import { ColorName, colors, elevation, spaces } from '@gaming1/g1-style';

import { PrismicWrapper } from '../styles';

export type PrismicInfoBlockProps = {
  color: ColorName;
};

export const PrismicInfoBlockContainer = styled(
  PrismicWrapper,
)<PrismicInfoBlockProps>`
  ${elevation(2)}
  position: relative;
  padding: ${spaces('sm')} ${spaces('sm')} ${spaces('sm')} ${spaces('xxxxl')};

  > div:first-of-type {
    margin-top: 0;
  }

  &:before {
    content: ' ';
    position: absolute;
    background-color: ${({ color }) => colors(color)};
    top: 0;
    left: 0;
    width: 24px;
    height: 100%;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  svg {
    background-color: ${({ color }) => colors(color)};
    border: 1px solid ${({ color }) => colors(color)};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    margin: auto;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    path {
      fill: ${colors('textLight')};
    }
  }
`;
