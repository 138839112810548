import isEqual from 'lodash/isEqual';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  actions,
  EBettingSlipIdentifier,
  EventState,
  getMainMarketByEventIdSelector,
  isBettingSlipItemSelectedGetterSelector,
  isOutcomeAvailableSelector,
  outcomeSelector,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
} from '@gaming1/g1-betting';
import { usePrevious, usePreviousDifferent } from '@gaming1/g1-utils';

import { SubDrawerContext } from '../../bettingSlip/components/SubDrawer/SubDrawerContext';
import {
  useBettingRoutePath,
  useCleanedParams,
  useFormatOdd,
} from '../../hooks';
import { getMainMarketColumnNames } from '../helpers';
import { SportBookContext } from '../SportBookContext';
import { OutcomeOddUpdate } from '../types';

export type StorageUserFavourites = {
  leagueIds: number[];
};

/** Load user Fav from localStorage on first time
 *  and save them on each "Fav change".
 *  To show previously saved user fav on next visit (before login) */
// export const useBettingFavPersistence = () => {
//   const STORAGE_KEY = 'bet-user-fav';
//   const dispatch = useDispatch();

//   const areUserFavInit = useSelector(areUserFavouritesInitializedSelector);
//   const favLeagueIds = useSelector(getUserFavouriteLeagueIdsSelector);
//   const getUserFavIdsStatus = useSelector(getUserFavouritesIdsStatusSelector);

//   // Load Fav : first time
//   useEffect(() => {
//     // Check if first time
//     if (getUserFavIdsStatus !== RemoteData.NotAsked || areUserFavInit) {
//       return;
//     }

//     const favFromStorage = readFrom(localStorage, STORAGE_KEY);

//     if (bettingFavCodec.is(favFromStorage)) {
//       dispatch(actions.recoverUserFavouritesFromStorage(favFromStorage));
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   // Save Fav : on each "Fav change"
//   useEffect(() => {
//     if (getUserFavIdsStatus !== RemoteData.Success || !areUserFavInit) {
//       return;
//     }

//     const objToStore: StorageUserFavourites = { leagueIds: favLeagueIds };
//     persistIn(localStorage, STORAGE_KEY, objToStore);
//   }, [areUserFavInit, favLeagueIds, getUserFavIdsStatus]);
// };

/**
 * Get state of the sportbook and tools to update it
 * (active sport, leagues, event type, )
 */
export const useSportbook = () => {
  const contextInfo = useContext(SportBookContext);
  return contextInfo;
};

/** Get the odd update type for an outcome */
export const useOutcomeOddUpdates = (outcomeId: number): OutcomeOddUpdate => {
  const outcome = useBettingGetterSelector({
    getterSelector: outcomeSelector,
    args: [outcomeId],
  });
  const currentOdd = outcome?.Odd;

  const latestOdd = usePrevious(currentOdd);
  const previousOddValue = usePreviousDifferent(currentOdd);

  if (!outcome) {
    return null;
  }

  if (currentOdd) {
    if (
      (previousOddValue && currentOdd > previousOddValue) ||
      (latestOdd && currentOdd > latestOdd)
    ) {
      return 'increase';
    }
    if (
      (previousOddValue && currentOdd < previousOddValue) ||
      (latestOdd && currentOdd < latestOdd)
    ) {
      return 'decrease';
    }
  }
  return null;
};

export const useOutcomeAnimation = (outcomeId: number) => {
  const outcome = useBettingGetterSelector({
    getterSelector: outcomeSelector,
    args: [outcomeId],
  });
  const isAvailable = useBettingGetterSelector({
    getterSelector: isOutcomeAvailableSelector,
    args: [outcomeId],
  });

  const oddUpdate = useOutcomeOddUpdates(outcomeId);
  const [animationClass, setAnimationClass] = useState('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  // Ref is needed when using setTimeout
  const setAnimationClassRef = useRef(setAnimationClass);
  setAnimationClassRef.current = setAnimationClass;
  const timerRef = useRef(timer);
  timerRef.current = timer;
  const setTimerRef = useRef(setTimer);
  setTimerRef.current = setTimer;

  // TODO : check player prop update
  useEffect(() => {
    setAnimationClassRef.current('');

    if (oddUpdate && isAvailable) {
      setAnimationClassRef.current(oddUpdate);
      setTimerRef.current(
        setTimeout(() => {
          setAnimationClassRef.current('');
        }, 10000),
      );
    } else if (timerRef.current) {
      clearTimeout(timerRef.current);
      setTimerRef.current(null);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        setTimerRef.current(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcome?.Odd, oddUpdate]);
  // END Animation

  return {
    animationClass,
  };
};

/** Get info and click handler for an outcome */
export const useOutcome = ({
  outcomeId,
  marketId,
  eventId,
}: {
  outcomeId: number;
  marketId: number;
  eventId: number;
}) => {
  const { closeOffersAvailableDrawer, offersAvailableDrawerVisibility } =
    useContext(SubDrawerContext);
  const outcome = useBettingGetterSelector({
    getterSelector: outcomeSelector,
    args: [outcomeId],
  });

  const allowBSActions = useBettingSlipAllowActions(
    EBettingSlipIdentifier.Main,
  );

  const isInBettingSlip = useBettingGetterSelector({
    getterSelector: isBettingSlipItemSelectedGetterSelector,
    args: [EBettingSlipIdentifier.Main, outcomeId],
  });

  const isAvailable = useBettingGetterSelector({
    getterSelector: isOutcomeAvailableSelector,
    args: [outcomeId],
  });

  const handleOutcomeClick = useCallback(() => {
    if (isAvailable || isInBettingSlip) {
      allowBSActions(
        actions.toggleBettingSlipOutcome({
          ids: { event: eventId, market: marketId, outcome: outcomeId },
          bettingSlipId: EBettingSlipIdentifier.Main,
        }),
      );
      if (offersAvailableDrawerVisibility) {
        closeOffersAvailableDrawer();
      }
    }
  }, [
    isAvailable,
    isInBettingSlip,
    allowBSActions,
    eventId,
    marketId,
    outcomeId,
    offersAvailableDrawerVisibility,
    closeOffersAvailableDrawer,
  ]);

  // Animation
  const formattedOdd = useFormatOdd(outcome?.Odd);
  const { animationClass } = useOutcomeAnimation(outcomeId);

  return useMemo(
    () => ({
      outcome,
      isInBettingSlip,
      animationClass,
      formattedOdd,
      handleOutcomeClick,
      isAvailable,
    }),
    [
      outcome,
      isInBettingSlip,
      animationClass,
      formattedOdd,
      handleOutcomeClick,
      isAvailable,
    ],
  );
};

/**
 * Get sportbook URL params
 * (active sportId, sportIds, leagueId, leagueIds, eventId, event type)
 */
export const useSportbookUrlParams = () => {
  const {
    sportId: sportIdParam = '',
    sportIds: sportIdsParam = '',
    leagueId: leagueIdParam,
    leagueIds: leagueIdsParam = '',
    eventId: eventIdParam,
    eventType,
  } = useCleanedParams<{
    sportId?: string;
    sportIds?: string;
    leagueId?: string;
    leagueIds?: string;
    eventId?: string;
    eventType: EventState;
  }>();

  const sportIds = useMemo(() => {
    const arr = sportIdsParam
      .split('-')
      .map((leagueId) => Number(leagueId))
      .filter((leagueId) => leagueId > 0);
    if (arr.length === 0 && Number(sportIdParam) > 0) {
      return [Number(sportIdParam)];
    }

    return arr;
  }, [sportIdsParam, sportIdParam]);
  const leagueIds = useMemo(
    () =>
      leagueIdsParam
        .split('-')
        .map((leagueId) => Number(leagueId))
        .filter((leagueId) => leagueId > 0),
    [leagueIdsParam],
  );

  const sportId = Number(sportIdParam) > 0 ? Number(sportIdParam) : null;
  const leagueId = Number(leagueIdParam) > 0 ? Number(leagueIdParam) : null;
  const eventId = Number(eventIdParam) > 0 ? Number(eventIdParam) : null;

  return { sportId, sportIds, leagueId, leagueIds, eventId, eventType };
};

/**
 * Return a function that returns the correct "navigation item" url (sport/region/league/event).
 */
export const useGetNavItemRoutePath = () => {
  const getBettingRoutePath = useBettingRoutePath();

  // Params should be replaced by Nav Item (need back)
  return (type: 'liveSport', id?: number) => {
    if (type === 'liveSport') {
      return getBettingRoutePath('liveList', {
        sportId: `${id || ''}`,
        regionId: '',
      });
    }

    return '';
  };
};

/**
 * Return a function that returns the default main market and the default column names by event id
 */
export const useDefaultShowcaseMarkets = () => {
  const getDefaultMainMarket = useSelector(
    getMainMarketByEventIdSelector,
    isEqual,
  );

  const getDefaultShowcaseMarkets = useCallback(
    (id: number) => {
      const defaultMainMarket = getDefaultMainMarket(id);

      const columnNames = getMainMarketColumnNames(defaultMainMarket);

      return { defaultMainMarket, columnNames };
    },
    [getDefaultMainMarket],
  );

  return getDefaultShowcaseMarkets;
};
