/* istanbul ignore file */

import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { coreActions, CoreActions } from '@gaming1/g1-core';

import { betPipeReducer } from '../betPipe/reducer';
import { bettingHomeReducer } from '../bettingHome/store/reducer';
import { bettingSlipReducer } from '../bettingSlip/store/reducer';
import { boostusReducer } from '../boostus/reducer';
import { cashoutReducer } from '../cashout/reducer';
import { commonReducer } from '../common/store/reducer';
import { bettingFreebetsReducer } from '../freebets/reducers';
import { historyReducer } from '../history/store/reducer';
import { horizontalBannersReducer } from '../horizontalBanner/store/reducer';
import { dailyOffersReducer } from '../manualBet/dailyOffers/reducer';
import { superOddsReducer } from '../manualBet/superOdds/reducer';
import { navigationReducer } from '../navigation/store/reducer';
import { playerBettingReducer } from '../player/reducer';
import { promotionsReducer } from '../promotions/reducer';
import { scoreReducer } from '../score/reducer';
import { settingsReducer } from '../settings/reducer';
import { sportbookReducer } from '../sportbook/store/reducer';

import { BettingActions, BettingState } from './types';

const globalReducer: Reducer<BettingState, BettingActions> = combineReducers({
  betPipe: betPipeReducer,
  bettingHome: bettingHomeReducer,
  bettingSlip: bettingSlipReducer,
  boostus: boostusReducer,
  cashout: cashoutReducer,
  common: commonReducer,
  dailyOffers: dailyOffersReducer,
  freebets: bettingFreebetsReducer,
  history: historyReducer,
  horizontalBanners: horizontalBannersReducer,
  navigation: navigationReducer,
  score: scoreReducer,
  settings: settingsReducer,
  sportbook: sportbookReducer,
  superOdds: superOddsReducer,
  player: playerBettingReducer,
  promotions: promotionsReducer,
});

/**
 * The purpose of this root reducer is to reset the state when specific actions
 * are successfully made. See https://alligator.io/redux/reset-state-redux
 * for more informations.
 */
export const bettingReducer: Reducer<BettingState, BettingActions> = (
  state: BettingState | undefined,
  action: BettingActions | CoreActions,
) =>
  globalReducer(
    getType(coreActions.switchLocale.success) === action.type ||
      (getType(coreActions.loggedOutUser) === action.type &&
        action.payload === true)
      ? undefined
      : state,
    action as BettingActions,
  );
