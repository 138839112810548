import { conditionalWindow } from './conditionalWindow';

/** Describe in which context the code is exectued */
type WorkingEnv = 'web' | 'react-native' | 'jest' | 'node-js';

/**
 * Returns the current working environment (where the code is executed). Values
 * can be
 * - 'web'
 * - 'react-native'
 * - 'jest'
 * - 'node-js'
 */
export const getWorkingEnv = (): WorkingEnv => {
  if (process.env.JEST_WORKER_ID) {
    return 'jest';
  }
  if (
    typeof conditionalWindow !== 'undefined' &&
    conditionalWindow.navigator &&
    conditionalWindow.navigator.product === 'ReactNative'
  ) {
    return 'react-native';
  }
  if (typeof conditionalWindow !== 'undefined' && conditionalWindow.document) {
    return 'web';
  }
  return 'node-js';
};
