import React, { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';

import { actions, AppSection, currentSectionSelector } from '@gaming1/g1-core';

import { FullScreen } from '../../../layout/components/FullScreen';
import { useIsBlackListedRoute } from '../../../rninterop/hooks/useIsBlackListedRoute';

type PathSource = {
  path: RouteProps['path'];
  route?: undefined;
};
/* |{
  path?: undefined;
  route: RouteObject<string> | RouteObject<null>;
}; */

export type AppRouteProps = Omit<RouteProps, 'path'> & {
  /**
   * Application section that will be used to update the current one.
   * If null doesn't update the section.
   */
  section: AppSection | null;
} & PathSource;

/**
 * Route component from react-router-dom that will update the current section |
 * The application section used by default will be 'default'
 */
export const AppRoute: FC<AppRouteProps> = ({
  section = 'default',
  path,
  route,
  ...rest
}) => {
  const dispatch = useDispatch();
  const currentSection = useSelector(currentSectionSelector);
  /**
   * React native - when path is blacklisted, the view shouldn't be rendered.
   */
  const isBlackListed = useIsBlackListedRoute();

  useLayoutEffect(() => {
    if (section !== null && currentSection !== section) {
      dispatch(actions.switchSection(section));
    }
  }, [currentSection, dispatch, section]);

  if (
    path !== undefined &&
    (typeof path === 'string' ? isBlackListed(path) : path?.some(isBlackListed))
  ) {
    // This ensures any native screen is considered coming from a web full screen,
    // thus avoiding any web content to quickly show before the native views
    const { children, render, component, ...otherRest } = rest;
    return <Route {...otherRest} path={path} render={() => <FullScreen />} />;
  }

  /*
  TODO: make this work. The problem is that the Switch component where those
  routes are being rendered (in an array) is directly accessing its children
  props and reading the `path` value. If there is none, (like here if
  we provide instead a `route` prop), the switch will render that route,
  because it think it's a "catch all route".
  A solution could be a helper that returns directly an array of AppRoutes
  with the proper path
  */
  // const { currentLocale } = useI18n();

  const finalPath = /* route ? `/${currentLocale}${route.path}` : */ path;

  return <Route {...rest} path={finalPath} />;
};
