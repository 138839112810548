import { isOpenHistoryElementLive } from '../helpers';

import { BettingHistoryTicketType } from './types';

export const bettingHistoriesRunningSort = (
  history: BettingHistoryTicketType[],
) =>
  history.sort((a, b) => {
    const aIsLive = isOpenHistoryElementLive(a.Elements);

    const bIsLive = isOpenHistoryElementLive(b.Elements);

    if (aIsLive && !bIsLive) {
      return -1;
    }
    if (!aIsLive && bIsLive) {
      return 1;
    }
    // 2 history elements have live or don't have at same time
    if (a.CreatedDate === b.CreatedDate) {
      return 0;
    }
    return new Date(a.CreatedDate) > new Date(b.CreatedDate) ? -1 : 1;
  });

export const bettingHistoriesClosedSort = (
  history: BettingHistoryTicketType[],
) =>
  history.sort((a, b) => {
    if (!a.ClosedDate) {
      return -1;
    }
    if (!b.ClosedDate) {
      return 1;
    }
    if (a.ClosedDate === b.ClosedDate) {
      return 0;
    }
    return new Date(a.ClosedDate) > new Date(b.ClosedDate) ? -1 : 1;
  });
