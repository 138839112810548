import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { SportbookState } from '../types';

const searchInitialState: SportbookState['search'] = {
  light: {
    searchTokens: [],
    events: {
      TotalResults: 0,
      Events: [],
    },
    competitions: {
      TotalResults: 0,
      Competitions: [],
    },
  },
  full: {
    searchTokens: [],
    competitions: {
      TotalResults: 0,
      Competitions: [],
    },
  },
  term: '',
};

export const searchReducer = (
  state = searchInitialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Light search */
      case getType(actions.lightSearch.success): {
        draftState.light.searchTokens = action.payload.SearchTokens || [];
        draftState.light.events = action.payload?.EventResult || {
          TotalResults: 0,
          Events: [],
        };
        draftState.light.competitions = action.payload?.CompetitionResult || {
          TotalResults: 0,
          Competitions: [],
        };
        break;
      }

      case getType(actions.clearLightSearch): {
        draftState.light.events = {
          TotalResults: 0,
          Events: [],
        };
        draftState.light.competitions = {
          TotalResults: 0,
          Competitions: [],
        };
        break;
      }

      /** Full search */
      case getType(actions.betSearch): {
        draftState.term = action.payload.SearchTerm;
        break;
      }

      case getType(actions.fullSearch.success): {
        draftState.full.searchTokens = action.payload.SearchTokens;
        draftState.full.competitions = action.payload.competitions;
        break;
      }

      default: // Immer will automatically return the state
    }
  });
