import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Request } from '@gaming1/g1-network';
import {
  EActivitySource,
  EActivityType,
  getConnectionLimitRemainingTime,
  getConnectionLimits,
  getConnectionTimeLimits,
  getDepositLimitation,
  GetLimitsRequestDTO,
  getLossLimits,
  getMinimumTimeBetweenConnections,
  getSpentTimeLimitRemainingTime,
  getSpentTimeOnActivityLimit,
  getWageLimits,
  setConnectionLimits,
  setConnectionTimeLimits,
  setDepositLimits,
  SetLimitsRequestDTO,
  setLossLimits,
  setMinimumTimeBetweenConnections,
  setSpentTimeOnActivityLimit,
  setWageLimits,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  getConnectionLimitRemainingTimeResponse,
  getDepositLimitsResponse,
  getLimitsResponse,
  getRemainingTimeToSpendResponse,
  setLimitsResponse,
} from './codecs';
import {
  getDepositLimitsErrorMessages,
  getRemainingConnectionTimeErrorMessages,
  getRemainingTimeToSpendErrorMessages,
  setLimitsErrorMessages,
} from './errorMessages';
import {
  formatLimits,
  formatSetLimitsResponseToSetLimitsResult,
} from './format';
import { getLimitsSelector } from './selectors';

const setLimitsRequests = (activityType: EActivityType) => {
  const setRequests: {
    [k in EActivityType]: (options: SetLimitsRequestDTO) => Request;
  } = {
    [EActivityType.Deposit]: setDepositLimits,
    [EActivityType.Wage]: setWageLimits,
    [EActivityType.Loss]: setLossLimits,
    [EActivityType.Connection]: setConnectionLimits,
    [EActivityType.ConnectionTime]: setConnectionTimeLimits,
    [EActivityType.MinimumTimeBetweenConnections]:
      setMinimumTimeBetweenConnections,
    [EActivityType.SpentTime]: setSpentTimeOnActivityLimit,
  };

  return setRequests[activityType];
};

const getLimitsRequests = (activityType: EActivityType) => {
  const getRequests: {
    [k in EActivityType]: (options: GetLimitsRequestDTO) => Request;
  } = {
    [EActivityType.Deposit]: getDepositLimitation,
    [EActivityType.Wage]: getWageLimits,
    [EActivityType.Loss]: getLossLimits,
    [EActivityType.Connection]: getConnectionLimits,
    [EActivityType.ConnectionTime]: getConnectionTimeLimits,
    [EActivityType.MinimumTimeBetweenConnections]:
      getMinimumTimeBetweenConnections,
    [EActivityType.SpentTime]: getSpentTimeOnActivityLimit,
  };

  return getRequests[activityType];
};

/** Set the user limits */
export const setUserLimitsEpic: UserEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.setLimits.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { params } = payload;
      return wsAdapter
        .request(
          setLimitsRequests(payload.params.type)({
            ...formatLimits(payload.data, getLimitsSelector(state)(params)),
            ActivityType: payload.params.type,
            ActivitySource: payload.params.source,
          }),
        )
        .pipe(
          mapGuard(setLimitsResponse),
          map((response) =>
            actions.setLimits.success({
              data: formatSetLimitsResponseToSetLimitsResult(response),
              params,
            }),
          ),
          catchError((err) =>
            of(
              actions.setLimits.failure({
                data: createFailurePayload(err, setLimitsErrorMessages),
                params,
              }),
            ),
          ),
        );
    }),
  );

/** Get the user limits */
export const getUserLimitsEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getLimits.request)),
    mergeMap(({ payload }) => {
      const params = { type: payload.type, source: payload.source };
      return wsAdapter
        .request(
          getLimitsRequests(payload.type)({
            ActivitySource: payload.source,
          }),
        )
        .pipe(
          mapGuard(
            payload.type === EActivityType.Deposit
              ? getDepositLimitsResponse
              : getLimitsResponse,
          ),
          map((response) =>
            actions.getLimits.success({
              data: response,
              params,
            }),
          ),
          catchError((err) =>
            of(
              actions.getLimits.failure({
                data: createFailurePayload(err, getDepositLimitsErrorMessages),
                params,
              }),
            ),
          ),
        );
    }),
  );

/** After a successful set limits, we get the limits */
export const successSetLimitsToGetLimitsEpic: UserEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.setLimits.success)),
    map(({ payload }) =>
      actions.getLimits.request({
        source: payload.params.source,
        type: payload.params.type,
      }),
    ),
  );

export const successSetConnectionTimeLimitToGetRemainingTimeEpic: UserEpic = (
  action$,
) =>
  action$.pipe(
    filter(isActionOf(actions.setLimits.success)),
    filter(
      ({ payload }) => payload.params.type === EActivityType.ConnectionTime,
    ),
    map(() => actions.getRemainingConnectionTime.request()),
  );

export const succesSetTimeToSpendLimitToGetRemainingTimeEpic: UserEpic = (
  action$,
) =>
  action$.pipe(
    filter(isActionOf(actions.setLimits.success)),
    filter(({ payload }) => payload.params.type === EActivityType.SpentTime),
    map(({ payload }) =>
      actions.getRemainingTimeToSpend.request({
        params: {
          source: payload.params.source,
        },
      }),
    ),
  );

/** Get remaining connection time */
export const getRemainingConnectionTimeEpic: UserEpic = (
  action$,
  _$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRemainingConnectionTime.request)),
    switchMap(() =>
      wsAdapter
        .request(
          getConnectionLimitRemainingTime({
            ActivitySource: EActivitySource.Gaming,
          }),
        )
        .pipe(
          mapGuard(getConnectionLimitRemainingTimeResponse),
          map(actions.getRemainingConnectionTime.success),
          catchError((err) =>
            of(
              actions.getRemainingConnectionTime.failure(
                createFailurePayload(
                  err,
                  getRemainingConnectionTimeErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

/** Get remaining time to spend */
export const getRemainingTimeToSpendEpic: UserEpic = (
  action$,
  _$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRemainingTimeToSpend.request)),
    mergeMap(({ payload }) => {
      const params = {
        source: payload.params.source,
      };

      return wsAdapter
        .request(
          getSpentTimeLimitRemainingTime({
            ActivitySource: payload.params.source,
          }),
        )
        .pipe(
          mapGuard(getRemainingTimeToSpendResponse),
          map((response) =>
            actions.getRemainingTimeToSpend.success({
              data: response,
              params,
            }),
          ),
          catchError((err) =>
            of(
              actions.getRemainingTimeToSpend.failure({
                data: createFailurePayload(
                  err,
                  getRemainingTimeToSpendErrorMessages,
                ),
                params,
              }),
            ),
          ),
        );
    }),
  );
