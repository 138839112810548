import React, { useContext, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { useTranslation } from '@gaming1/g1-i18n';
import { DrawerType, Icon, LayoutContext, useMedia } from '@gaming1/g1-ui';

import { IconSearchButton } from './styles';

/**
 * The TopNav Search icon
 */
export const SearchIcon: VFC = () => {
  const { colors } = useContext(ThemeContext);
  const { mediaBP } = useMedia();
  const { showDrawer } = useContext(LayoutContext);

  const size = ['xl', 'xxl'].includes(mediaBP) ? '16px' : '12px';

  const { t } = useTranslation('core');

  return (
    <IconSearchButton
      data-testid="topnav-search"
      onClick={() => showDrawer(DrawerType.search)}
    >
      <Icon
        id="topnav-search"
        type="MagnifyingGlass"
        height={size}
        width={size}
        fill={colors.backgrounds[2]}
        title={t('search.open')}
      />
    </IconSearchButton>
  );
};
