import { SportType } from '../../sportbook/store/types';

import { NavSportType } from './types';

/** Turns sports into navigation sports */
export const getNavSportsFromSports = (sports: {
  [sportId: string]: SportType;
}): { [sportId: string]: NavSportType } =>
  Object.values(sports)
    .filter((sport) => !!sport.InNav)
    .reduce(
      (acc, sport) => ({
        ...acc,
        [sport.SportId]: {
          Id: sport.SportId,
          Alias: sport.SportAlias || '',
          Name: sport.SportName || '',
          Order: sport.NavOrder,
        },
      }),
      {},
    );
