import { Location } from 'history';
import React, { VFC } from 'react';
import { createPortal } from 'react-dom';
import { SpaceProps } from 'styled-system';

import { BANNERS_CONTAINER_CLASSNAME } from '../constants';
import { Icon } from '../Icon';
import { IconType } from '../Icon/icons';
import { MessageType } from '../Message/types';

import {
  BannerAppLink,
  BannerCloseButton,
  BannerContainer,
  BannerIconWrapper,
  BannerMessage,
} from './styles';

export type BannerProps = {
  /**
   * Override icon
   * By default : icons by type
   */
  icon?: IconType;
  /** Message of the banner */
  message: string | React.ReactNode;
  /** Function called when the close button is pressed */
  onCloseButtonClick?: () => void;
  /** Link of the banner */
  link?: {
    label: string;
    route: Location | string;
  };
  /**
   * Render the banner inside the banner container using a portal.
   * Default: true
   */
  renderInContainer?: boolean;
  /** Test ID used to help in testing */
  testId?: string;
  /** Type of the banner */
  type?: MessageType;
} & SpaceProps;

const iconsByType: { [k in MessageType]: IconType } = {
  danger: 'DiscLess',
  info: 'DiscInfo',
  success: 'DiscCheck',
  warning: 'DiscWarning',
};

export const Banner: VFC<BannerProps> = ({
  icon,
  message,
  link,
  onCloseButtonClick,
  renderInContainer = true,
  testId = 'banner-container',
  type = 'info' as MessageType,
  ...spaceProps
}) => {
  const banner = message ? (
    <BannerContainer data-testid={testId} type={type} {...spaceProps}>
      <BannerIconWrapper>
        <Icon type={icon || iconsByType[type]} id={`banner-${type}`} />
      </BannerIconWrapper>
      <BannerMessage data-testid="banner-message">{message}</BannerMessage>
      {link && (
        <BannerAppLink data-testid="banner-link" to={link.route}>
          {link.label}
        </BannerAppLink>
      )}
      {!!onCloseButtonClick && (
        <BannerCloseButton
          data-testid="banner-close-button"
          onClick={onCloseButtonClick}
          aria-label="Close"
        >
          <Icon id="banner-close" type="Cross" />
        </BannerCloseButton>
      )}
    </BannerContainer>
  ) : null;
  if (!renderInContainer) {
    return banner;
  }
  const bannersContainers = document.querySelectorAll(
    `.${BANNERS_CONTAINER_CLASSNAME}`,
  );
  return bannersContainers.length
    ? // Use the latest container in the dom
      createPortal(banner, bannersContainers[bannersContainers.length - 1])
    : null;
};
