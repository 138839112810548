import { CodecTypeWithoutStatus, RequestState } from '@gaming1/g1-utils';

import { userCredit, userProfile } from './codecs';

export type UserProfileRaw = CodecTypeWithoutStatus<typeof userProfile>;

export type UserProfile = CodecTypeWithoutStatus<typeof userProfile>;

export type UserCredit = CodecTypeWithoutStatus<typeof userCredit>;

export type UserCoreState = {
  /** User data when after login on the http API */
  userProfile: UserProfile | null;
  /** User credit (available, withdrawable...) */
  userCredit: UserCredit | null;
  /** Is the user logged in? null = pending  */
  userLoggedIn: boolean | null;
  /** User common requests */
  requests: {
    userCredit: RequestState;
    userProfile: RequestState;
  };
};

/**
 * Fields name for user registration
 */
export enum UserField {
  acceptDepositLegalLimit = 'AcceptDepositLegalLimit',
  allowRemarketing = 'AllowRemarketing',
  bic = 'AccountNumberBIC',
  birthCity = 'BirthCity',
  birthCountry = 'BirthCountry',
  birthDate = 'Birthday',
  birthMunicipality = 'BirthMunicipality',
  creationSource = 'CreationSource',
  documentExpirationDate = 'IdentificationDocumentExpirationDate',
  documentIssueDate = 'IdentificationDocumentIssueDate',
  documentIssuePlace = 'IdentificationDocumentIssuePlace',
  email = 'Email',
  firstname = 'FirstName',
  fullName = 'FullName',
  gender = 'Civility',
  hasNationalNumber = 'IsBelgian',
  iban = 'AccountNumberIBAN',
  landlinePhone = 'PhoneNumber2',
  landlinePhonePrefix = 'PrefixPhoneNumber2',
  lastname = 'LastName',
  legalDepositLimit = 'LegalMaximumDepositLimit',
  licences = 'Licences',
  locale = 'LanguageIsoCode',
  mobilePhone = 'PhoneNumber',
  mobilePhonePrefix = 'PrefixPhoneNumber',
  nationalNumber = 'NationalIdentityNumber',
  nationality = 'Nationality',
  notificationByEmailApproval = 'ReceiveEmailNotification',
  notificationByPhoneApproval = 'ReceivePhoneNotification',
  notificationBySnailMailApproval = 'ReceivePostNotification',
  notificationBytextMessageApproval = 'ReceiveSMSNotification',
  passportNumber = 'passportNumber',
  password = 'Password',
  pinCode = 'SecurityPinCode',
  profession = 'Profession',
  refuseBonus = 'RefuseBonus',
  secondFirstname = 'SecondFirstName',
  secondLastname = 'SecondLastName',
  signupSource = 'SignupSource',
  sponsorshipCode = 'SponsorshipCode',
  taxIdNumber = 'TaxIdNumber',
  username = 'Pseudo',
  ssnOrTin = 'SsnOrTin',
  // Address
  address = 'Line1',
  address2 = 'Line2',
  city = 'City',
  country = 'Country',
  municipality = 'Municipality',
  province = 'Province',
  state = 'State',
  zipCode = 'ZipCode',
}

export type UserGADetails = {
  Age: number | null;
  Id?: string;
  Civility: string;
  DepositCounter?: number;
  PlayerSegment: string;
};
