import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  isColorDark,
  spaces,
} from '@gaming1/g1-style';

export type TableContainerProps = {
  /** Highlight every alternate line. */
  isAlternate: boolean;
  /** Adds vertical space inside each cell. */
  isLarger: boolean;
  /** Displays the borders for each cell */
  hasCellBorders: boolean;
};

export const TableContainer = styled.table<TableContainerProps>`
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
  margin: ${spaces('sm')} 0;
  width: 100%;

  tr {
    ${({ hasCellBorders }) =>
      hasCellBorders &&
      css`
        border-bottom: 1px solid ${colors('separator')};
      `}

    ${({ isAlternate }) =>
      isAlternate &&
      css`
        &:nth-child(even) {
          background-color: ${colors('backgrounds', { index: 2 })};
        }
      `}

    th,
    td {
      font-size: ${fontSizes('md')};

      ${({ hasCellBorders }) =>
        hasCellBorders &&
        css`
          border-bottom: 1px solid ${colors('separator')};
        `}

      ${({ isLarger }) =>
        isLarger
          ? css`
              padding: ${spaces('sm')} ${spaces('xs')};
            `
          : css`
              padding: ${spaces('xs')} ${spaces('xs')};
            `}
    }
  }

  thead {
    ${fontWeight('regular')};
    text-align: left;
    th {
      color: ${({ theme }) =>
        isColorDark(theme.colors.text)
          ? lighten(0.3, theme.colors.text)
          : darken(0.3, theme.colors.text)};
    }
  }

  tbody {
    border-top: 1px solid ${colors('separator')};

    tr {
      color: ${colors('text')};
    }
  }
`;
