/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../store/types';
import * as actions from '../actions';
import { BettingFreebetsState } from '../types';

export const initialState: BettingFreebetsState = {
  result: null,
  request: generateInitialRequestState(null),
};

export const bettingFreebetsReducer = (
  state: BettingFreebetsState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getFreebets.request): {
        produceLoadingState(draftState.request);
        draftState.result = null;
        break;
      }
      case getType(actions.getFreebets.success): {
        const { payload } = action;
        produceSuccessState(draftState.request);
        draftState.result = payload;

        break;
      }
      case getType(actions.getFreebets.failure): {
        const { payload } = action;
        produceFailureState(draftState.request, payload);

        draftState.result = payload;

        break;
      }

      default: // Immer will automatically return the state
    }
  });
