import styled from 'styled-components';

import { colors, getReadableColor } from '@gaming1/g1-style';
import { Text } from '@gaming1/g1-ui';

export const ResultName = styled(Text)`
  & b {
    background-color: ${colors('buttonPrimaryBackground')};
    color: ${getReadableColor('buttonPrimaryBackground')};
  }
`;
