import React, {
  FC,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { userSearchTermSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { MediaBreakPointNames, mediaBreakPoints } from '@gaming1/g1-style';
import {
  Box,
  Drawer,
  DrawerType,
  LayoutContext,
  useCloseDrawerOnNavigation,
} from '@gaming1/g1-ui';

import { SearchBar } from '../../../search/components/SearchBar';
import { SearchHistory } from '../../../search/components/SearchHistory';
import { SearchNoResult } from '../SearchNoResult';

import { useSearchHistory } from './hooks';
import {
  CloseDrawerButton,
  DrawerNavContainer,
  UserSearchWrapper,
} from './styles';

const widthByMedia: { [k in MediaBreakPointNames]: string } = {
  xs: '100%',
  sm: '100%',
  md: mediaBreakPoints[1].width,
  lg: mediaBreakPoints[1].width,
  xl: mediaBreakPoints[1].width,
  xxl: mediaBreakPoints[1].width,
};

type SearchDrawerProps = {
  /** This prop could be a string with the term that does not provide any
   *  result or just be null if we've some results to display
   */
  noResultTerm: string | null;
  /** Hide the recent searches message when there is no history */
  hideEmptyRecentSearches?: boolean;
  /** Placeholder of the input */
  inputPlaceHolder?: string | null;
  /** Minimum characters threshold before displaying or hiding history and noResult components */
  minCharacters?: number;
};

/** Search drawer */
export const SearchDrawer: FC<SearchDrawerProps> = ({
  children,
  hideEmptyRecentSearches = false,
  noResultTerm,
  inputPlaceHolder,
  minCharacters = 2,
}) => {
  const { searchHistory, clearHistory, isStringArray } = useSearchHistory();

  const userSearchTerm = useSelector(userSearchTermSelector);

  const { media, visibleDrawer, hideDrawer } = useContext(LayoutContext);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const searchDrawerVisibility = visibleDrawer === DrawerType.search;

  const { t } = useTranslation('core');

  const [searchValue, setSearchValue] = useState('');

  const onClickSearchTerm = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  // Make sure the search input is focused when the drawer is shown
  useLayoutEffect(() => {
    if (searchDrawerVisibility && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchDrawerVisibility]);

  useCloseDrawerOnNavigation(DrawerType.search);

  return (
    <Drawer
      type={DrawerType.search}
      appearFrom="right"
      width={widthByMedia[media]}
      ariaLabel="Search menu"
      testId="search-drawer"
    >
      <DrawerNavContainer>
        <UserSearchWrapper>
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            mb="lg"
          >
            <SearchBar
              searchValue={searchValue}
              onSearchValueUpdate={setSearchValue}
              ref={searchInputRef}
              inputPlaceHolder={inputPlaceHolder}
              isInDrawer
            />
            <CloseDrawerButton
              data-testid="search-drawer-close-button"
              onClick={hideDrawer}
            >
              {t('search.close')}
            </CloseDrawerButton>
          </Box>
          {userSearchTerm.length < minCharacters && (
            <SearchHistory
              hideEmptyRecentSearches={hideEmptyRecentSearches}
              clearHistory={clearHistory}
              onClick={onClickSearchTerm}
              searchHistory={isStringArray(searchHistory) ? searchHistory : []}
            />
          )}
          {(userSearchTerm.length > minCharacters - 1 ||
            noResultTerm !== null) &&
            userSearchTerm === noResultTerm && (
              <SearchNoResult failureTerm={noResultTerm} />
            )}
          {children}
        </UserSearchWrapper>
      </DrawerNavContainer>
    </Drawer>
  );
};
