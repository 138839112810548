import React, { memo, useContext, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import {
  isPlaceBetNotificationASuccessSelector,
  useBettingGetterSelector,
  useBettingSlipIsEmpty,
} from '@gaming1/g1-betting';

import { BettingSlipContext } from '../../../../BettingSlipContext';
import { BettingSlipEmptyState } from '../../../BettingSlipEmptystate';
import { BettingSlipSuccess } from '../../../BettingSlipSuccess';

import { BettingSlipSwitchContent } from './BettingSlipSwitchContent';

/**
 * Display the content ticket
 * a list of item inside select by user and save into store
 */

const BettingSlipSwitchStateComponent = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const isPlaceBetASuccess = useBettingGetterSelector({
    getterSelector: isPlaceBetNotificationASuccessSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const isEmpty = useBettingSlipIsEmpty(bettingSlipId);

  const content = useMemo(() => {
    if (isEmpty) {
      return <BettingSlipEmptyState />;
    }
    if (isPlaceBetASuccess) {
      return <BettingSlipSuccess />;
    }
    return <BettingSlipSwitchContent />;
  }, [isEmpty, isPlaceBetASuccess]);

  return content;
};

export const BettingSlipSwitchState = memo(BettingSlipSwitchStateComponent);
