import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getAvailableTicketsForPlayer,
  getShopCategories,
  getShopProducts,
  getUnavailableProducts,
  purchaseScratchCard,
  scratchCardIdentifier,
  shopPurchase,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { shopErrorMessages } from '../common/errorMessages';
import { LoyaltyEpic } from '../types';

import * as actions from './actions';
import {
  getAvailableTicketsResponse,
  getScratchcardIdentifierResponse,
  getShopProductsResponse,
  getUnavailableProductsResponse,
  paginatedProductsData,
  productsData,
  purchaseResponse,
  shopCategoriesResponse,
  shopPurchaseResponse,
} from './codecs';
import {
  availableTicketsErrorMessages,
  purchaseErrorMessages,
  unavailableProductsErrorMessages,
} from './errorMessages';
import { formatGetShopProducts, formatGetShopProductsById } from './format';

/** Get shop categories */
export const getShopCategoriesEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getShopCategories.request)),
    switchMap(() =>
      wsAdapter.request(getShopCategories({})).pipe(
        mapGuard(shopCategoriesResponse),
        map(actions.getShopCategories.success),
        catchError((err) =>
          of(
            actions.getShopCategories.failure(
              createFailurePayload(err, shopErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get shop products */
export const getShopProductsEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getShopProducts.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getShopProducts(payload)).pipe(
        mapGuard(getShopProductsResponse),
        map((response) => formatGetShopProducts(payload, response)),
        mapGuard(paginatedProductsData),
        map(actions.getShopProducts.success),
        catchError((err) =>
          of(
            actions.getShopProducts.failure(
              createFailurePayload(err, shopErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get shop products by id */
export const getShopProductsByIdEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getShopProductsById.request)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(
          getShopProducts({
            AffordableOnlyFilter: false,
            Detailed: true,
            PageCount: payload.length,
            PageIndex: 0,
            Products: payload,
          }),
        )
        .pipe(
          mapGuard(getShopProductsResponse),
          map(formatGetShopProductsById),
          mapGuard(productsData),
          map(actions.getShopProductsById.success),
          catchError((err) =>
            of(
              actions.getShopProductsById.failure(
                createFailurePayload(err, shopErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Get ticket list */
export const getAvailableTicketsEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAvailableTickets.request)),
    switchMap(() =>
      wsAdapter.request(getAvailableTicketsForPlayer({})).pipe(
        mapGuard(getAvailableTicketsResponse),
        map(actions.getAvailableTickets.success),
        catchError((err) =>
          of(
            actions.getAvailableTickets.failure(
              createFailurePayload(err, availableTicketsErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Shop purchase */
export const shopPurchaseEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.purchaseShopProduct.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(shopPurchase(payload)).pipe(
        mapGuard(shopPurchaseResponse),
        map(actions.purchaseShopProduct.success),
        catchError((err) =>
          of(
            actions.purchaseShopProduct.failure(
              createFailurePayload(err, shopErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get Unavailbale Products */
export const getUnavailableProductsEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getUnavailableProducts.request)),
    switchMap(() =>
      wsAdapter.request(getUnavailableProducts({})).pipe(
        mapGuard(getUnavailableProductsResponse),
        map(actions.getUnavailableProducts.success),
        catchError((err) =>
          of(
            actions.getUnavailableProducts.failure(
              createFailurePayload(err, unavailableProductsErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Shop purchase */
export const purchaseEpic: LoyaltyEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.purchase.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(purchaseScratchCard(payload)).pipe(
        mapGuard(purchaseResponse),
        map(actions.purchase.success),
        catchError((err) =>
          of(
            actions.purchase.failure(
              createFailurePayload(err, purchaseErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get scratchcard identifier */
export const getScratchcardIdentifierEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getScratchcardIdentifier.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(scratchCardIdentifier(payload)).pipe(
        mapGuard(getScratchcardIdentifierResponse),
        map(actions.getScratchcardIdentifier.success),
        catchError((err) =>
          of(
            actions.getScratchcardIdentifier.failure(createFailurePayload(err)),
          ),
        ),
      ),
    ),
  );
