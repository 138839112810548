import React, { FC } from 'react';

import { bettingRoutes } from '@gaming1/g1-betting-web';
import { cmsRoutes } from '@gaming1/g1-cms-web';
import {
  Footer,
  MenuGroups,
  usePrivacySettings,
  useRoutePath,
  useZendeskUrl,
} from '@gaming1/g1-core-web';
import { gamingRoutes } from '@gaming1/g1-gaming-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { DepositWalletsImages } from '@gaming1/g1-payment-web';

export const AppFooter: FC = () => {
  const getBettingPath = useRoutePath(bettingRoutes);
  const getGamingPath = useRoutePath(gamingRoutes);
  const getCmsPath = useRoutePath(cmsRoutes);
  const zendeskUrl = useZendeskUrl();
  const { t } = useTranslation('core');
  const { isPrivacySettingsEnabled } = usePrivacySettings();

  const footerGamesItems: MenuGroups = [
    [
      {
        label: 'footer.link.games',
        path: getGamingPath('gameListCasino'),
        testId: 'footer-games',
      },
      {
        label: 'footer.link.tournaments',
        path: getGamingPath('tournaments'),
        testId: 'footer-tournaments',
      },
      {
        label: 'footer.link.blog',
        path: getCmsPath('blog'),
        testId: 'footer-blog',
      },
    ],
  ];

  const footerBettingItems: MenuGroups = [
    [
      {
        label: 'footer.link.onesToWatch',
        path: getBettingPath('bettingHome'),
        testId: 'footer-onesToWatch',
      },
      {
        label: 'footer.link.liveBetting',
        path: getBettingPath('liveListTop'),
        testId: 'footer-liveBetting',
      },
    ],
  ];

  const footerRowItems: MenuGroups = [
    [
      {
        label: 'footer.link.shop',
        path: '/shop',
        testId: 'footer-shop',
      },
      {
        label: 'footer.link.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'footer-promotions',
      },
    ],
    [
      {
        label: 'footer.link.help',
        path: zendeskUrl,
        externalLink: true,
        testId: 'footer-help',
      },
      {
        label: 'footer.link.responsible',
        path: '/help/responsible-gaming',
        testId: 'footer-responsible',
      },
      {
        label: 'footer.link.playsafe',
        path: 'http://playsafe.be/',
        testId: 'footer-playsafe',
        externalLink: true,
      },
    ],
  ];

  return (
    <Footer>
      <Footer.Header backgroundLevel={0}>
        <Footer.HelpButton />
      </Footer.Header>
      <Footer.Section type="wrap" backgroundLevel={1}>
        <Footer.Box mt="none" wideInMedium>
          <Footer.Title hasNoUppercase fontSize="xl">
            {t('footer.socialNetwork')}
          </Footer.Title>
          <Footer.SocialNetwork
            facebookUrl="https://www.facebook.com/CircusBelgium/"
            instagramUrl="https://www.instagram.com/circus_casino/"
            twitterUrl="https://twitter.com/CircusSport"
            youtubeUrl="https://www.youtube.com/user/CircusOnlineGames"
          />
        </Footer.Box>
        <Footer.Box>
          <Footer.Links linkGroups={footerRowItems} linkStyle="text" />
        </Footer.Box>
        <Footer.Box>
          <Footer.Links linkGroups={footerGamesItems} linkStyle="text" />
        </Footer.Box>
        <Footer.Box>
          <Footer.Links linkGroups={footerBettingItems} linkStyle="text" />
        </Footer.Box>
        {getWrapperEnv() !== 'rn' && (
          <Footer.Box wideInMedium>
            <Footer.Title hasNoUppercase fontSize="xl">
              {t('footer.appTitle')}
            </Footer.Title>
            <Footer.AppDownloadLinks px="xs" />
          </Footer.Box>
        )}
      </Footer.Section>
      <Footer.Section type="images" backgroundLevel={1} hasBorder>
        <DepositWalletsImages />
      </Footer.Section>
      {isPrivacySettingsEnabled && (
        <Footer.Section type="row" backgroundLevel={0}>
          <Footer.ComplianceLinks linkStyle="text" />
        </Footer.Section>
      )}
      {getWrapperEnv() === 'rn' && (
        <Footer.Section type="row" backgroundLevel={0}>
          <Footer.AppVersion />
        </Footer.Section>
      )}
      <Footer.ScrollTopButton />
    </Footer>
  );
};
