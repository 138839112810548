import { BettingAssetsContext } from '@gaming1/g1-betting-web';
import { generateAssetsContextValue } from '@gaming1/g1-core-web';

import boostusBanner from './boostus/boostus-banner.jpg';
import dailyOffersBanner from './dailyOffers/dailyOffers-banner.jpg';
import dailyOffersBannerLargeV2 from './dailyOffers/dailyOffers-large.png';
import dailyOffersBannerSmallV2 from './dailyOffers/dailyOffers-small.png';
import promotionsBannerLarge from './promotions/promotions-banner-large.jpg';
import promotionsBannerSmall from './promotions/promotions-banner-small.jpg';
import americanFootballScoreBackground from './score/american-football-background.jpg';
import baseballScoreBackground from './score/baseball-background.jpg';
import basketballScoreBackground from './score/basketball-background.jpg';
import bowlsScoreBackground from './score/bowls-background.jpg';
import boxingScoreBackground from './score/boxing-background.jpg';
import cricketScoreBackground from './score/cricket-background.jpg';
import cyclingScoreBackground from './score/cycling-background.jpg';
import dartsScoreBackground from './score/darts-background.jpg';
import defaultScoreBackground from './score/default-background.jpg';
import footballScoreBackground from './score/football-background.jpg';
import golfScoreBackground from './score/golf-background.jpg';
import handballScoreBackground from './score/handball-background.jpg';
import iceHockeyScoreBackground from './score/ice-hockey-background.jpg';
import martialArtsScoreBackground from './score/martial-arts-background.jpg';
import mixedMartialArtsScoreBackground from './score/mixed-martial-arts-background.jpg';
import motorCycleRacingScoreBackground from './score/motor-cycle-racing-background.jpg';
import motorSportScoreBackground from './score/motor-sport-background.jpg';
import rugbyScoreBackground from './score/rugby-background.jpg';
import snookerScoreBackground from './score/snooker-background.jpg';
import stockCarRacingScoreBackground from './score/stock-car-racing-background.jpg';
import tableTennisScoreBackground from './score/table-tennis-background.jpg';
import tennisScoreBackground from './score/tennis-background.jpg';
import volleyballScoreBackground from './score/volleyball-background.jpg';
import defaultSliderLarge from './sliders/default-slider-large.jpg';
import defaultSliderSmall from './sliders/default-slider-small.jpg';
import superOddsBannerLarge from './superOdds/superOdds-banner-large.jpg';
import superOddsBannerLargeV2 from './superOdds/superOdds-banner-largev2.png';
import superOddsBannerSmall from './superOdds/superOdds-banner-small.jpg';
import superOddsBannerSmallV2 from './superOdds/superOdds-banner-smallv2.png';

export const bettingAssets = generateAssetsContextValue(BettingAssetsContext)({
  /* Banners */
  boostusBanner,
  dailyOffersBanner,
  dailyOffersBannerSmallV2,
  dailyOffersBannerLargeV2,
  defaultSliderLarge,
  defaultSliderSmall,
  superOddsBannerLarge,
  superOddsBannerSmall,
  superOddsBannerLargeV2,
  superOddsBannerSmallV2,
  promotionsBannerLarge,
  promotionsBannerSmall,
  /* Score backgrounds */
  americanFootballScoreBackground,
  baseballScoreBackground,
  basketballScoreBackground,
  bowlsScoreBackground,
  boxingScoreBackground,
  cricketScoreBackground,
  cyclingScoreBackground,
  dartsScoreBackground,
  defaultScoreBackground,
  footballScoreBackground,
  golfScoreBackground,
  handballScoreBackground,
  iceHockeyScoreBackground,
  martialArtsScoreBackground,
  mixedMartialArtsScoreBackground,
  motorCycleRacingScoreBackground,
  motorSportScoreBackground,
  rugbyScoreBackground,
  snookerScoreBackground,
  stockCarRacingScoreBackground,
  tableTennisScoreBackground,
  tennisScoreBackground,
  volleyballScoreBackground,
});
