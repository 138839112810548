import { createAction, createAsyncAction } from 'typesafe-actions';

import { BetPipeType } from '@gaming1/g1-config';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  BettingSlipIdAndType,
  CommonBettingSlipError,
  GetConditionsForBettingSlipResponseType,
  GetInitializationDataSuccessResponseType,
} from '../bettingSlip/store/types/common';
import { GetOptimalBetResponseType } from '../bettingSlip/store/types/optimalBet';
import {
  PlaceBetAndBettingSlipIdResponse,
  PlaceBetError,
  PlaceSystemBetAndBettingSlipIdResponse,
  PlaceSystemBetError,
} from '../bettingSlip/store/types/placeBet';
import { GetPromoForBettingSlipResponseType } from '../bettingSlip/store/types/promotionsForBettingSlip';
import {
  GetSystemRiskManagementResponseType,
  RiskManagementError,
  RiskManagementResponse,
} from '../bettingSlip/store/types/riskManagement';
import {
  BettingSlipIdentifierType,
  BettingSlipIdType,
} from '../common/store/types';
import {
  GetInfoFromOutcomesType,
  OutcomeEventAndMarketIds,
} from '../sportbook/store/types';

import { BetPipeIdentifier, RiskManagementRequest } from './types';

// TODO: move to g1-utils
// Needed for guid generation
/* eslint-disable no-bitwise */
// From https://stackoverflow.com/a/2117523
const generateUuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/* /!\
  Only export here actions (or async actions) that match BetPipeIdentifier or 
  { betPipeId: string } in their payload type
*/

type FailurePayloadWihPipeIdentifier = FailurePayload & BetPipeIdentifier;

/* TODO: decide if the betPipeId is generated by the action creator (like here) 
which ensure that every bet pipe start action will start a new one, or make it
accept an id generated elsewhere
*/

/** Start the betting slip validation or placement pipe, defined in the epic */
export const startBetPipe = createAction(
  'betting/start_bet_pipe',
  ({
    bettingSlipId,
    type,
  }: {
    bettingSlipId: BettingSlipIdentifierType;
    type: BetPipeType;
  }) => ({
    bettingSlipId,
    type,
    betPipeId: generateUuid(),
  }),
)<{
  betPipeId: string;
  bettingSlipId: BettingSlipIdentifierType;
  type: BetPipeType;
}>();

/** Cancel a bet pipe to stop further steps */
export const cancelBetPipe = createAction('betting/cancel_bet_pipe')<{
  betPipeId: string;
}>();

/** Warn that a pipe has been completed */
export const completedPipe = createAction('betting/completed_pipe')<{
  betPipeId: string;
}>();

/** Fetch the init data for a betting slip */
export const getBettingSlipInitializationData = createAsyncAction(
  'betting/get_betting_slip_init_data_request',
  'betting/get_betting_slip_init_data_success',
  'betting/get_betting_slip_init_data_failure',
  'betting/get_betting_slip_init_data_cancel',
)<
  BetPipeIdentifier,
  BetPipeIdentifier &
    GetInitializationDataSuccessResponseType & { timeOfRequest?: string },
  FailurePayloadWihPipeIdentifier & BetPipeIdentifier,
  BetPipeIdentifier
>();

/** Fetch the outcome report of a betting slip */
export const getInfoFromOutcomes = createAsyncAction(
  'betting/get_outcome_info_request',
  'betting/get_outcome_info_success',
  'betting/get_outcome_info_failure',
  'betting/get_outcome_info_cancel',
)<
  BetPipeIdentifier & {
    ids?: OutcomeEventAndMarketIds[];
  },
  GetInfoFromOutcomesType & {
    ids: OutcomeEventAndMarketIds[];
  } & BetPipeIdentifier,
  FailurePayloadWihPipeIdentifier & BetPipeIdentifier,
  BetPipeIdentifier
>();

/**
 * Reset betting slip
 */
export const resetBettingSlip = createAsyncAction(
  'betting/reset_bettingslip_request',
  'betting/reset_bettingslip_success',
  'betting/reset_bettingslip_failure',
  'betting/reset_bettingslip_cancel',
)<
  BetPipeIdentifier,
  BetPipeIdentifier,
  FailurePayload & BetPipeIdentifier,
  BetPipeIdentifier
>();
/**
 * Launch RM
 */
export const launchRiskManagement = createAsyncAction(
  'betting/launch_management_request',
  'betting/launch_management_success',
  'betting/launch_management_failure',
  'betting/launch_management_cancel',
)<
  RiskManagementRequest & BetPipeIdentifier,
  BetPipeIdentifier,
  RiskManagementError & BetPipeIdentifier & BettingSlipIdType,
  RiskManagementRequest & BetPipeIdentifier
>();

export const getRiskManagement = createAsyncAction(
  'betting/get_risk_management_request',
  'betting/get_risk_management_success',
  'betting/get_risk_management_failure',
  'betting/get_risk_management_cancel',
)<
  RiskManagementRequest & BetPipeIdentifier,
  RiskManagementResponse & BetPipeIdentifier,
  RiskManagementError & BetPipeIdentifier,
  BetPipeIdentifier
>();
export const getSystemRiskManagement = createAsyncAction(
  'betting/system_risk_management_request',
  'betting/system_risk_management_success',
  'betting/system_risk_management_failure',
  'betting/system_risk_management_cancel',
)<
  RiskManagementRequest & BetPipeIdentifier,
  BettingSlipIdType & GetSystemRiskManagementResponseType & BetPipeIdentifier,
  RiskManagementError & BetPipeIdentifier,
  BetPipeIdentifier
>();

/**
 * Launch placebet
 */
export const launchPlaceBet = createAsyncAction(
  'betting/launch_place_bet_request',
  'betting/launch_place_bet_success',
  'betting/launch_place_bet_failure',
  'betting/launch_place_bet_cancel',
)<
  BettingSlipIdAndType & BetPipeIdentifier,
  PlaceBetAndBettingSlipIdResponse & BetPipeIdentifier,
  PlaceBetError & BetPipeIdentifier,
  BettingSlipIdAndType & BetPipeIdentifier
>();

export const placeBet = createAsyncAction(
  'betting/place_bet_request',
  'betting/place_bet_success',
  'betting/place_bet_failure',
  'betting/place_bet_cancel',
)<
  BettingSlipIdAndType & BetPipeIdentifier,
  PlaceBetAndBettingSlipIdResponse & BetPipeIdentifier,
  PlaceBetError & BetPipeIdentifier,
  BetPipeIdentifier
>();

export const placeSystemBet = createAsyncAction(
  'betting/place_system_bet_request',
  'betting/place_system_bet_success',
  'betting/place_system_bet_failure',
  'betting/place_system_bet_cancel',
)<
  BettingSlipIdAndType & BetPipeIdentifier,
  PlaceSystemBetAndBettingSlipIdResponse & BetPipeIdentifier,
  PlaceSystemBetError & BetPipeIdentifier,
  BetPipeIdentifier
>();

/**
 * Launch freebet
 */

export const freebetConditionsForBettingSlip = createAsyncAction(
  'betting/launch_bettingslip_freebets_request',
  'betting/launch_bettingslip_freebets_success',
  'betting/launch_bettingslip_freebets_failure',
  'betting/launch_bettingslip_freebets_cancel',
)<
  BettingSlipIdType & BetPipeIdentifier,
  GetConditionsForBettingSlipResponseType &
    BettingSlipIdType &
    BetPipeIdentifier,
  CommonBettingSlipError & BetPipeIdentifier & BettingSlipIdType,
  BetPipeIdentifier
>();

/**
 * Launch Boostus
 */
export const boostusConditionsForBettingSlip = createAsyncAction(
  'betting/launch_bettingslip_boostus_request',
  'betting/launch_bettingslip_boostus_success',
  'betting/launch_bettingslip_boostus_failure',
  'betting/launch_bettingslip_boostus_cancel',
)<
  BettingSlipIdType & BetPipeIdentifier,
  GetConditionsForBettingSlipResponseType &
    BettingSlipIdType &
    BetPipeIdentifier,
  CommonBettingSlipError & BetPipeIdentifier & BettingSlipIdType,
  BetPipeIdentifier
>();

/**
 * Promotions actions
 */

/**
 * getAvailablePromotions action
 */
export const promotionsForBettingSlip = createAsyncAction(
  'betting/launch_bettingslip_promotions_request',
  'betting/launch_bettingslip_promotions_success',
  'betting/launch_bettingslip_promotions_failure',
  'betting/launch_bettingslip_promotions_cancel',
)<
  BettingSlipIdType & BetPipeIdentifier,
  GetPromoForBettingSlipResponseType & BetPipeIdentifier & BettingSlipIdType,
  CommonBettingSlipError & BetPipeIdentifier & BettingSlipIdType,
  BetPipeIdentifier
>();

/**
 *  getOptimalBetInfo action
 */
export const getOptimalBet = createAsyncAction(
  'betting/launch_bettingslip_optimalBet_request',
  'betting/launch_bettingslip_optimalBet_success',
  'betting/launch_bettingslip_optimalBet_failure',
  'betting/launch_bettingslip_optimalBet_cancel',
)<
  BettingSlipIdType & BetPipeIdentifier,
  GetOptimalBetResponseType & BetPipeIdentifier & BettingSlipIdType,
  CommonBettingSlipError & BetPipeIdentifier & BettingSlipIdType,
  BetPipeIdentifier
>();
