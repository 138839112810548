import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { EventType as EEventType } from '@gaming1/g1-requests-betting';

import { ApplicationWithBettingState } from '../../../store/types';

import { sportsSelector } from './entities';

/* Requests */
export const getLightSearchStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.requests.getLightSearch.status;

export const betSearchTermSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.search.term;

export const getFullSearchStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.requests.getFullSearch.status;

export const getLightSearchRequestStateSelector = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.requests.getLightSearch;

/* Search */
export const getLightSearchEvents = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.search.light.events;

export const getFullSearchTokens = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.search.full.searchTokens;

export const getLightSearchTokens = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.search.light.searchTokens;

export const getSortedLightSearchEvents = createSelector(
  getLightSearchEvents,
  sportsSelector,
  (events, getSport) =>
    memoize(() => {
      const list = events.Events || [];

      const listLive = list.filter((e) => e?.EventType === EEventType.Live);
      const listPrem = list.filter((e) => e?.EventType === EEventType.Prematch);

      const sortedLive = sortBy(
        listLive.map((e) => ({
          order: getSport[e?.SportId || -1]?.LiveOrder || 9999,
          sportId: e?.SportId,
          name: e?.HomeName,
          item: e,
        })),
        ['order', 'sportId', 'name'],
      ).map((i) => i.item);

      const sortedPrem = sortBy(listPrem, ['StartDate', 'HomeName']);

      return [...sortedLive, ...sortedPrem];
    }),
);

export const getLightSearchCompetitions = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.search.light.competitions;

export const getSortedLightSearchCompetitions = createSelector(
  getLightSearchCompetitions,
  sportsSelector,
  (competitions, getSport) =>
    memoize(() => {
      const list = competitions.Competitions || [];

      const sortedList = sortBy(
        list.map((c) => ({
          order: getSport[c?.SportId || -1]?.NavOrder || 9999,
          sport: c?.SportName,
          region: c?.RegionName,
          league: c?.LeagueName,
          item: c,
        })),
        ['order', 'sport', 'region', 'league'],
      ).map((i) => i.item);

      return sortedList;
    }),
);

export const getFullSearchCompetitions = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.search.full.competitions;

export const getSortedFullSearchCompetitions = createSelector(
  getFullSearchCompetitions,
  sportsSelector,
  (competitions, getSport) =>
    memoize(() => {
      const list = competitions.Competitions || [];

      const sortedList = sortBy(
        list.map((c) => ({
          order: getSport[c?.SportId || -1]?.NavOrder || 9999,
          sport: c?.SportName,
          region: c?.RegionName,
          league: c?.LeagueName,
          item: c,
        })),
        ['order', 'sport', 'region', 'league'],
      ).map((i) => i.item);

      return sortedList;
    }),
);
