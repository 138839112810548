import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { MultifactorAuthenticationState } from './types';

export const initialState: MultifactorAuthenticationState = {
  getSecurityPinCodeInfo: null,
  resetSecurityPinCode: null,
  changeSecurityPinCode: null,
  requests: {
    getSecurityPinCodeInfo: generateInitialRequestState(null),
    resetSecurityPinCode: generateInitialRequestState(null),
    changeSecurityPinCode: generateInitialRequestState(null),
  },
};

export const multifactorAuthenticationReducer = (
  state: MultifactorAuthenticationState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getSecurityPinCodeInfo.request):
        produceLoadingState(draftState.requests.getSecurityPinCodeInfo);
        break;

      case getType(actions.getSecurityPinCodeInfo.success):
        produceSuccessState(draftState.requests.getSecurityPinCodeInfo);
        draftState.getSecurityPinCodeInfo = action.payload;
        break;

      case getType(actions.getSecurityPinCodeInfo.failure):
        produceFailureState(
          draftState.requests.getSecurityPinCodeInfo,
          action.payload,
        );
        break;

      case getType(actions.resetSecurityPinCode.request):
        produceLoadingState(draftState.requests.resetSecurityPinCode);
        break;

      case getType(actions.resetSecurityPinCode.success):
        produceSuccessState(draftState.requests.resetSecurityPinCode);
        draftState.resetSecurityPinCode = action.payload;
        break;

      case getType(actions.resetSecurityPinCode.failure):
        produceFailureState(
          draftState.requests.resetSecurityPinCode,
          action.payload,
        );
        break;

      case getType(actions.changeSecurityPinCode.request):
        produceLoadingState(draftState.requests.changeSecurityPinCode);
        break;

      case getType(actions.changeSecurityPinCode.success):
        produceSuccessState(draftState.requests.changeSecurityPinCode);
        draftState.changeSecurityPinCode = action.payload;
        break;

      case getType(actions.changeSecurityPinCode.failure):
        produceFailureState(
          draftState.requests.changeSecurityPinCode,
          action.payload,
        );
        break;

      default: // Immer will automatically return the state
    }
  });
