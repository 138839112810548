/* eslint-disable react/no-array-index-key */
import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardSportStatsSelector,
  getScoreBoardTeamNamesSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useMediaBreakPoint } from '@gaming1/g1-ui';

import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardPlayTime } from '../ScoreBoardPlayTime';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import { useScoreBoardFullTranslations } from './hooks';
import {
  ScoreBoardFullTemplateColumn as TemplateColumn,
  ScoreBoardFullTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardFullTemplateEllipsis as TemplateEllipsis,
  ScoreBoardFullTemplateItem as TemplateItem,
  ScoreBoardFullTemplateRow as TemplateRow,
  ScoreBoardFullTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the full scoreboard with a cricket template */
export const ScoreBoardFullCricketTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
  sportId,
}) => {
  const isDesktop = useMediaBreakPoint({ min: 'lg' });
  const { team1: name1, team2: name2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: over1, team2: over2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Over'],
    equalityFn: shallowEqual,
  });
  const { team1: dismissals1, team2: dismissals2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Dismissals'],
    equalityFn: shallowEqual,
  });

  const translations = useScoreBoardFullTranslations();

  return (
    <TemplateWrapper data-testid="scoreboard-full-cricket-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper
          flexDirection="row"
          justifyContent="space-between"
        >
          <TemplateColumn gap="xxxs" pt="xxxs" ellipsis>
            <TemplateRow pt="xxxs" pb="xxs">
              <TemplateEllipsis>
                <ScoreBoardPlayTime eventId={eventId} fontSize="md" />
              </TemplateEllipsis>
            </TemplateRow>
            <TemplateRow>
              <TemplateItem>
                <TemplateEllipsis>{name1}</TemplateEllipsis>
              </TemplateItem>
            </TemplateRow>
            <TemplateRow>
              <TemplateItem>
                <TemplateEllipsis>{name2}</TemplateEllipsis>
              </TemplateItem>
            </TemplateRow>
          </TemplateColumn>
          <TemplateColumn maxWidth="50%">
            <TemplateRow gap="xxxs" justifyContent="flex-end">
              <TemplateColumn gap="xxxs" px="xxxs">
                <TemplateItem>{translations.runs}</TemplateItem>
                <TemplateItem fontSize={isDesktop ? 'xl' : 'lg'}>
                  {[score1, dismissals1].join('/')}
                </TemplateItem>
                <TemplateItem fontSize={isDesktop ? 'xl' : 'lg'}>
                  {[score2, dismissals2].join('/')}
                </TemplateItem>
              </TemplateColumn>
              <TemplateColumn gap="xxxs" px="xxxs">
                <TemplateItem>{translations.over}</TemplateItem>
                <TemplateItem hasBackground>{over1}</TemplateItem>
                <TemplateItem hasBackground>{over2}</TemplateItem>
              </TemplateColumn>
            </TemplateRow>
          </TemplateColumn>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
