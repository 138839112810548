import { createContext } from 'react';

import { AssetsContext } from '@gaming1/g1-core-web';

export const UserAssetsContext = createContext<AssetsContext>({
  /* File upload */
  fileNoPreview: '',
  filePreviewPdf: '',
  /* Login */
  loginBackground: '',
});
