import React, { FC, memo, useContext, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import {
  bettingSlipItemsSportBookIdsGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../../BettingSlipContext';
import { BettingSlipClearAll } from '../../BettingSlipClearAll';
import { BettingSlipOptimalBetMessage } from '../../BettingSlipOptimalBet/BettingSlipOptimalBetMessage';

import { BettingSlipItem } from './BettingSlipItem';

export const BettingSlipItemsComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const itemIds = useBettingGetterSelector({
    getterSelector: bettingSlipItemsSportBookIdsGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const itemsId = useMemo(
    () => itemIds?.map((ids) => ids.outcomeId),
    [itemIds],
  );

  const content = useMemo(
    () => (
      <>
        <Box justifyContent="flex-end" flexDirection="initial">
          <BettingSlipClearAll bettingSlipId={bettingSlipId} />
        </Box>
        {itemsId?.map((id) => (
          <BettingSlipItem id={id} key={id} />
        ))}
        <BettingSlipOptimalBetMessage />
      </>
    ),
    [bettingSlipId, itemsId],
  );

  return content;
};
export const BettingSlipItems = memo(BettingSlipItemsComponent);
