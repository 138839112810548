import { ComponentProps } from 'react';

import { DebugPanel } from '@gaming1/g1-core-web';

import { BetPipeDebugging } from './components/BetPipeDebugging';
import { BettingSlipDebugging } from './components/BettingSlipDebugging';
import { CashoutDebugging } from './components/CashoutDebugging';

export const bettingDebugTabs: Required<
  ComponentProps<typeof DebugPanel>
>['additionalTabs'] = [
  {
    tabName: 'BettingSlip',
    Component: BettingSlipDebugging,
  },
  {
    tabName: 'Bet Pipe',
    Component: BetPipeDebugging,
  },
  {
    tabName: 'Cashout',
    Component: CashoutDebugging,
  },
];
