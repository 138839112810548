import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextAlignProps } from 'styled-system';

import { PrismicPageContentDocument, PrismicSlice } from '@gaming1/g1-cms';
import { PrismicContextType } from '@gaming1/g1-cms-api';
import { userLoggedInSelector } from '@gaming1/g1-core';
import { Seo } from '@gaming1/g1-core-web';
import { BoxProps } from '@gaming1/g1-ui';

import { PrismicCustomFunction } from '../../types';
import { PrismicChildren } from '../PrismicChildren';
import {
  PrismicPageContext,
  PrismicPageContextValue,
} from '../PrismicPageContext';
import { isRichTextContentEmpty } from '../PrismicRichText/helpers';
import { PrismicTitle } from '../PrismicTitle';
import { PrismicPageContentWrapper } from '../styles';

type PrismicPageContentProps = BoxProps &
  TextAlignProps & {
    /** Context of the rendered prismic content (default: 'page') */
    context: PrismicContextType;
    /** Prismic content */
    document: PrismicPageContentDocument;
    /** Test Id */
    testId: string;
    /** Custom functions, callable via 'CustomFunction' internal links */
    customFunctions?: Record<string, PrismicCustomFunction>;
  };

/**
 * This component renders a document of the type `content_v2`
 */
export const PrismicPageContent: FC<PrismicPageContentProps> = ({
  context,
  document,
  testId,
  ...props
}) => {
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const { body } = document.data;

  const [rootSlices, slicesWithIds] = useMemo(
    () => [
      body.filter((slice) => !slice.primary.id),
      body
        .filter((slice) => !!slice.primary.id)
        .reduce<Record<string, PrismicSlice>>(
          (slices, slice) =>
            typeof slice.primary.id === 'string' && !!slice.primary.id
              ? {
                  ...slices,
                  [slice.primary.id]: slice,
                }
              : slices,
          {},
        ),
    ],
    [body],
  );

  const contextValue: PrismicPageContextValue = useMemo(
    () => ({
      rootSlices,
      slicesWithIds,
      isUserLoggedIn: isUserLoggedIn || false,
    }),
    [isUserLoggedIn, rootSlices, slicesWithIds],
  );

  return (
    <PrismicPageContext.Provider value={contextValue}>
      <PrismicPageContentWrapper {...props}>
        {(context === 'page' || context === 'seoFragment') &&
          document.data.seo_title && (
            <Seo
              title={document.data.seo_title}
              description={document.data.seo_description}
            />
          )}
        {!isRichTextContentEmpty(document.data.title) && (
          <PrismicTitle title={document.data.title} />
        )}
        <PrismicChildren slices={rootSlices} />
      </PrismicPageContentWrapper>
    </PrismicPageContext.Provider>
  );
};
