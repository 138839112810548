import { darken, lighten } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, fontSizes, isColorDark, spaces } from '@gaming1/g1-style';
import {
  AppAnchor,
  FluidContentContainer,
  Select,
  SimpleButton,
} from '@gaming1/g1-ui';

import { TOP_BAR_HEIGHT } from '../../../styles';

const activeClassName = 'active';

/** Container */

export const TopBarContainer = styled.header`
  background-color: ${colors('topBarBackground')};
  color: ${colors('topBarText')};
  font-size: ${fontSizes('md')};
  height: ${TOP_BAR_HEIGHT}px;
  border-bottom: 1px solid ${colors('topBarBorder')};
`;

export const TopBarContentContainer = styled(FluidContentContainer)`
  flex-direction: row;
  align-items: center;
  padding-top: ${spaces('xs')};
  padding-bottom: ${spaces('xs')};
  height: ${TOP_BAR_HEIGHT}px;

  svg {
    path {
      fill: ${colors('topBarText')};
    }
  }
`;

/** Clock */

export const TopBarClockContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${spaces('xxxxl')};
  svg {
    margin-right: ${spaces('xxs')};
  }
`;

/** Separator between containers */

export const TopBarSeparator = styled.div`
  flex-grow: 1;
`;

/** Menu */

export const TopBarMenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TopBarMenuGroup = styled.div`
  display: flex;
`;

type TopBarItemProps = {
  /** Index of the current links group */
  groupIndex?: number;
};

const TopBarMenuItemCSS = css<TopBarItemProps>`
  white-space: nowrap;
  color: ${({ groupIndex = 0, theme }) =>
    isColorDark(theme.colors.topBarText)
      ? lighten(groupIndex / 5)(theme.colors.topBarText)
      : darken(groupIndex / 5)(theme.colors.topBarText)};
  margin-left: ${spaces('xxxl')};
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    margin-right: ${spaces('xs')};
    path {
      fill: ${({ groupIndex = 0, theme }) =>
        isColorDark(theme.colors.topBarText)
          ? lighten(groupIndex / 5)(theme.colors.topBarText)
          : darken(groupIndex / 5)(theme.colors.topBarText)};
    }
  }
`;

export const TopBarMenuLink = styled(NavLink)
  .withConfig({
    shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
  })
  .attrs({
    activeClassName,
  })<TopBarItemProps>`
  ${TopBarMenuItemCSS};

  &.${activeClassName}, &:focus,
  &:active,
  &:hover {
    color: ${colors('topBarActiveText')};
    svg {
      path {
        fill: ${colors('topBarActiveText')};
      }
    }
  }
`;

export const TopBarMenuAnchor = styled(AppAnchor).withConfig({
  shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
})<TopBarItemProps>`
  ${TopBarMenuItemCSS};

  &:focus,
  &:hover {
    color: ${colors('topBarActiveText')};
    svg {
      path {
        fill: ${colors('topBarActiveText')};
      }
    }
  }
`;

export const TopBarMenuButton = styled(SimpleButton).withConfig({
  shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
})<TopBarItemProps>`
  ${TopBarMenuItemCSS};

  &:focus,
  &:hover {
    color: ${colors('topBarActiveText')};
    svg {
      path {
        fill: ${colors('topBarActiveText')};
      }
    }
  }
`;

/** Local switcher */

export const TopBarLocaleSelect = styled(Select)`
  border: none;
  cursor: pointer;
  color: ${colors('topBarText')};
  font-size: ${fontSizes('md')};
  margin-left: ${spaces('xxxl')};

  &:focus {
    padding-bottom: ${spaces('sm')};
    font-size: ${fontSizes('md')};
    border-bottom: none;
  }

  option {
    background-color: ${colors('topBarBackground')};
    color: ${colors('topBarText')};
  }
`;
