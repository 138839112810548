import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';

export const BasicCardContainer = styled.div`
  background-color: ${colors('backgrounds', { index: 2 })};
  border-radius: 10px;
  margin-bottom: ${spaces('xs')};
  padding: ${spaces('sm')};
  position: relative;
  cursor: pointer;
`;
