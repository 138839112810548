/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:09:41 24-02-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetDocumentStatusRequestDTO,
  GetDocumentStatusResponseDTO,
} from '../entities';

export const getDocumentStatus = (options: GetDocumentStatusRequestDTO) =>
  createRequest<GetDocumentStatusResponseDTO>(
    100,
    'GetDocumentStatus',
    options,
  );
