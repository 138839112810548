import styled, { css } from 'styled-components';

import { elevation, media } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

import { PrismicImageContainer } from '../PrismicImage/styles';
import { PrismicMapWrapper } from '../PrismicMap/styles';
import { PrismicVideoWrapper } from '../PrismicVideo/styles';

const prismicMediaCardCss = css`
  margin-top: 0;
  margin-bottom: 0;
`;

export const PrismicCard = styled(Box)`
  ${elevation(1)};
  flex: 1 0 auto;
  width: 100%;

  & ${PrismicImageContainer} {
    ${prismicMediaCardCss}
  }
  & ${PrismicVideoWrapper} {
    ${prismicMediaCardCss}
  }
  & ${PrismicMapWrapper} {
    ${prismicMediaCardCss}
  }
`;

type PrismicCardContentProps = {
  /** The card has media */
  hasMedia: boolean;
};

export const PrismicCardContent = styled(Box)<PrismicCardContentProps>`
  align-items: ${({ hasMedia }) => (hasMedia ? 'center' : 'flex-start')};
  text-align: ${({ hasMedia }) => (hasMedia ? 'center' : 'left')};
  padding: ${({ hasMedia, theme }) =>
    hasMedia
      ? `${theme.space.md}px`
      : `${theme.space.sm}px ${theme.space.xs}px`};

  ${media.md} {
    display: block;
    text-align: left;
  }
`;
