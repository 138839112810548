import { createSelector } from 'reselect';

import { ApplicationState } from '../types';

const commonCoreStateSelector = (state: ApplicationState) => state.core.common;

const lastVisitSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.lastVisit,
);

export const hasUserAlreadyVisitedSelector = createSelector(
  lastVisitSelector,
  (lastVisit) => !!lastVisit,
);

export const creationSectionSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.creationSection,
);

export const appInitializedSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.init,
);

export const currentSectionSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.section,
);

export const hasUserAlreadyLoggedInSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.hasUserAlreadyLoggedIn,
);

/** Returns the shell parameters from the http api. Not used at the moment */
export const shellParametersSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.shellParameters,
);

export const shellParametersRequestStateSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.requests.getShellParameters,
);

/** Returns the current mobile app version and build number */
export const mobileVersionSelector = createSelector(
  commonCoreStateSelector,
  ({ mobileBuildNumber, mobileBuildVersion }) => ({
    mobileBuildNumber,
    mobileBuildVersion,
  }),
);

/** Returns the minimum authorised mobile app version from the backend */
export const minimumMobileAppVersionSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.minimumMobileAppVersion,
);
