import styled from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { AppLink, Box, Heading } from '@gaming1/g1-ui';

export const BodyContainer = styled(Box)`
  margin-top: ${spaces('lg')};
  img {
    width: 100%;
    height: auto;
  }
`;

export const WideHeading = styled(Heading)`
  width: 100%;
`;

export const ButtonLink = styled(AppLink)`
  margin: auto;
  margin-top: ${spaces('xxl')};
  margin-bottom: ${spaces('lg')};
  padding-left: ${spaces('lg')};
  padding-right: ${spaces('lg')};
`;
