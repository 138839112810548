import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  media,
  spaces,
  textColorForBackground,
} from '@gaming1/g1-style';
import { Box, RoundIconWrapper } from '@gaming1/g1-ui';

const PROFILE_WRAPPER_BORDER_RADIUS_IN_PX = 5;

const iconSize = {
  width: 65,
  height: 65,
};

export const HeaderText = styled.span`
  color: ${textColorForBackground('backgrounds', { index: 1 })};
  font-size: ${fontSizes('xl')};
  ${fontWeight('bold')};
  margin-top: 0;
`;

export const ProfileIconWrapper = styled(RoundIconWrapper)`
  ${iconSize}
  background-color: ${colors('profileIconBackgroundColor')}
`;

export const ProfileInfoBaseWrapper = styled(Box)`
  width: 100%;
  flex-direction: row;
  padding-top: ${spaces('md')};
  padding-bottom: ${spaces('md')};
  background-color: ${colors('secondary', { index: 4 })};
  border-radius: ${PROFILE_WRAPPER_BORDER_RADIUS_IN_PX}px;
  ${media.lg} {
    background-color: transparent;
  }
`;

export const WrapperHeader = styled(Box)`
  border-left: 1px solid;
  border-color: ${colors('secondary', { index: 3 })};
`;
