import { FC, useEffect, useState } from 'react';
import { BehaviorSubject, combineLatest, timer, zip } from 'rxjs';
import {
  delay,
  delayWhen,
  filter,
  first,
  map,
  retryWhen,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';

import { getDeployEnv } from '@gaming1/g1-logger';
import { loadScript, useGetIsMounted } from '@gaming1/g1-utils';

import { logger } from '../../../logger';

import { IOVATION_DELAY_IN_MS, IOVATION_MAX_TIMEOUT_IN_MS } from './constants';
import { BlackBoxHandler, IovationComponentsProps } from './types';

const deployEnv = getDeployEnv();
const iesnareUrl =
  deployEnv === 'production'
    ? 'https://mpsnare.iesnare.com/snare.js'
    : 'https://ci-mpsnare.iovation.com/snare.js';
const iovationStaticUrl = 'https://first.iovation.com/latest/static_wdp.js';
const iovationDynamicUrl = 'https://first.iovation.com/latest/dyn_wdp.js';

type IovationSnareWindowProperties = {
  /** Install ActiveX */
  io_install_stm?: boolean;
  io_exclude_stm?: number;
  io_install_stm_error_handler?: string; // warning: if io_install_stm is true, this could get eval()uated !
  /** Install Flash */
  io_install_flash?: boolean;
  /** Flash version */
  io_min_flash_version?: number;
  io_flash_needs_update_handler?: string; // warning: if io_install_flash is true, this could get eval()uated !
  /** collect Real IP information */
  io_enable_rip?: boolean;
  /** callbacks */
  io_bb_callback?: BlackBoxHandler;
  fp_bb_callback?: BlackBoxHandler;
};

type WindowWithIovationProperties = Window & IovationSnareWindowProperties;
const windowWithIovationProperties = window as WindowWithIovationProperties;

/**
 * Get some informations from Iovation and store them in the redux store,
 * then send them to the websocket
 *
 * @deprecated . Use IovationLoaderConfiguration instead.
 */
export const IovationSnareConfiguration: FC<IovationComponentsProps> = ({
  onDispatch,
}) => {
  const getIsMounted = useGetIsMounted();

  const [hasScriptBeenAdded, setHasScriptBeenAdded] = useState(
    !!document.querySelector(`script[src="${iesnareUrl}"]`),
  );

  useEffect(() => {
    if (!hasScriptBeenAdded) {
      let stopListening = false;
      const ioBlackBox$ = new BehaviorSubject<string | null>(null);
      const fpBlackBox$ = new BehaviorSubject<string | null>(null);

      const ioBlackBoxUpdateHandler: BlackBoxHandler = (
        blackBox,
        completed,
      ) => {
        if (!stopListening && completed) {
          ioBlackBox$.next(blackBox);
        }
      };

      const fpBlackBoxUpdateHandler: BlackBoxHandler = (
        blackBox,
        completed,
      ) => {
        if (!stopListening && completed) {
          fpBlackBox$.next(blackBox);
        }
      };

      // Configurations must be on page before snare.js
      windowWithIovationProperties.io_install_stm = false;
      windowWithIovationProperties.io_exclude_stm = 12;
      windowWithIovationProperties.io_install_stm_error_handler = '';
      windowWithIovationProperties.io_install_flash = false;
      windowWithIovationProperties.io_min_flash_version = 9999;
      windowWithIovationProperties.io_flash_needs_update_handler = '';
      windowWithIovationProperties.io_enable_rip = true;
      windowWithIovationProperties.io_bb_callback = ioBlackBoxUpdateHandler;
      windowWithIovationProperties.fp_bb_callback = fpBlackBoxUpdateHandler;

      setHasScriptBeenAdded(true);
      const subscription = zip(
        loadScript(iesnareUrl),
        loadScript(iovationStaticUrl),
        loadScript(iovationDynamicUrl),
      )
        .pipe(
          tap(() =>
            logger.info(
              '[Tracking] Iovation scripts loaded (Snare configuration)',
            ),
          ),
          filter(getIsMounted),
          delay(IOVATION_DELAY_IN_MS),
          switchMap(() =>
            combineLatest([ioBlackBox$, fpBlackBox$]).pipe(
              first(),
              map((blackBoxValues) => {
                if (blackBoxValues.every((val) => !!val)) {
                  // Casting is mandatory here as the .every does not warn
                  // TS that all the array values are filled and not null.
                  return blackBoxValues as string[];
                }
                throw new Error(
                  '[Tracking] Could not get blackBoxes from Iovation (Snare configuration).  Retrying...',
                );
              }),
              // Retry on error
              retryWhen((error) =>
                error.pipe(
                  tap((err: Error) =>
                    logger.warn(
                      '[Tracking] Iovation Warning: retrying after error',
                      err.message,
                    ),
                  ),
                  delayWhen(() => timer(IOVATION_DELAY_IN_MS)),
                ),
              ),
              // After IOVATION_MAX_TIMEOUT_IN_MS ms, stop listening to iovation
              timeout(IOVATION_MAX_TIMEOUT_IN_MS),
            ),
          ),
        )
        .subscribe({
          // Success
          next: (ioBlackBoxValue) => {
            stopListening = true;
            onDispatch({
              ioBlackBox: ioBlackBoxValue[0],
              fpBlackBox: ioBlackBoxValue[1],
            });
            // prevents the timeout to keep running
            ioBlackBox$.complete();
            fpBlackBox$.complete();
          },
          // Timeout error
          error: () => {
            stopListening = true;
            logger.warn('[Tracking] Iovation timeout (Snare configuration)');
          },
        });

      return () => subscription.unsubscribe();
    }

    return () => undefined;
    // adding the dependency could make the setup run multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
