import React, { useCallback, VFC } from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';

import { useLocaleSwitch } from '../../../i18n/hooks';

import { TopBarLocaleSelect } from './styles';

export type LocaleSwitcherProps = {
  /** Locale switch callback */
  onLocaleSwitch?: (code: LocaleCode) => void;
};
/**
 * Select field that change the language of the app
 */
export const TopBarLocaleSwitcher: VFC<LocaleSwitcherProps> = ({
  onLocaleSwitch,
}) => {
  const { locale, localeList, switchLocale } = useLocaleSwitch();

  const { t } = useTranslation('core');

  const handleLocaleSelect = useCallback(
    (localeInput) => {
      switchLocale(localeInput);
      if (onLocaleSwitch) {
        onLocaleSwitch(localeInput);
      }
    },
    [onLocaleSwitch, switchLocale],
  );

  return (
    <TopBarLocaleSelect
      aria-label={t('drawerNav.languages')}
      onValueChange={handleLocaleSelect}
      options={localeList}
      simple
      testId="locale-select"
      value={locale}
    />
  );
};
