import React, { useContext, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, IconType } from '@gaming1/g1-ui';

import { FooterImage, FooterImageAndTextContainer, FooterText } from './styles';

export type FooterImageAndTextProps = {
  /** Icon type */
  icon?: IconType;
  /** Icon height */
  iconSize?: number;
  /** Image source */
  imageSrc?: string;
  /** Text next to the image or the icon */
  text: string;
};

export const ImageAndText: VFC<FooterImageAndTextProps> = ({
  icon,
  iconSize,
  imageSrc,
  text,
}) => {
  const { t } = useTranslation('core');
  const { colors } = useContext(ThemeContext);

  return (
    <FooterImageAndTextContainer>
      {imageSrc && (
        <FooterImage url={imageSrc} id="" alt="" data-testid="footer-image" />
      )}
      {icon && (
        <Icon
          type={icon}
          id={`footer-image-${icon}`}
          width={iconSize || 24}
          height={iconSize || 24}
          mr="xs"
          fill={colors.text}
          data-testid="footer-icon"
        />
      )}
      <FooterText>{t(text)}</FooterText>
    </FooterImageAndTextContainer>
  );
};
