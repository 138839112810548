import React, { memo, useContext, useEffect, useState, VFC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  actions,
  BoostusType,
  FreebetType,
  getBoostusForBSGetterSelector,
  getGroupedFreebetForBSGetterSelector,
  getPromotionsForBSGetterSelector,
  GroupedFreebets,
  isLockedFreebet,
  PromotionType,
  useBettingSlipAllowActions,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { DrawerContainer, Icon, LayoutContext, Text } from '@gaming1/g1-ui';

import { Card } from '../../../gifts/components/Card';
import { useIsDesktop } from '../../../hooks';
import { widthBettingSlipDrawerByMedia } from '../../../layout/helpers';
import { BettingSlipContext } from '../../BettingSlipContext';
import { SubDrawerContext } from '../SubDrawer/SubDrawerContext';

import {
  CloseButton,
  OffersAvailableCardsContainer,
  OffersAvailableDrawerContainer,
  OffersAvailableDrawerTitleContainer,
} from './styles';

/**
 * Render a drawer that is containing the freebets and the boostuses available for the user in his bettingSlip.
 */
export const OffersAvailableDrawerComponent: VFC = () => {
  const { t } = useTranslation('betting');

  const { media } = useContext(LayoutContext);

  const location = useLocation();

  const isDesktop = useIsDesktop();

  const { bettingSlipId } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const { closeOffersAvailableDrawer, offersAvailableDrawerVisibility } =
    useContext(SubDrawerContext);

  /* freebet */
  const [freebetList, setFreebetList] = useState<GroupedFreebets[]>([]);

  const groupedFreebetsSelector = useSelector(
    getGroupedFreebetForBSGetterSelector,
    shallowEqual,
  );
  /* boostus */
  const [boostusList, setBoostusList] = useState<BoostusType[]>([]);

  const boostuses = useSelector(getBoostusForBSGetterSelector, shallowEqual);

  /* promotion */
  const [promotionList, setPromotionList] = useState<PromotionType[]>([]);

  const promotionsSelector = useSelector(
    getPromotionsForBSGetterSelector,
    shallowEqual,
  );

  // Freeze the freebets and boostus list when the drawer is opened
  useEffect(() => {
    setFreebetList(groupedFreebetsSelector(bettingSlipId));
    setBoostusList(boostuses(bettingSlipId));
    setPromotionList(promotionsSelector(bettingSlipId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersAvailableDrawerVisibility]);

  useEffect(() => {
    if (offersAvailableDrawerVisibility) {
      closeOffersAvailableDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOffersAvailableDrawer, location]);

  const selectFreebet = (groupedFreebet: GroupedFreebets) => {
    const { data } = groupedFreebet;
    if (isLockedFreebet(data)) {
      return;
    }
    allowBSActions(
      actions.addFreebetToBS({
        bettingSlipId,
        amount: groupedFreebet.amount,
        conditionId: (data as FreebetType).ConditionId,
      }),
    );
    closeOffersAvailableDrawer();
  };

  const selectBoostus = (boostus: BoostusType) => {
    allowBSActions(
      actions.addBoostusToBS({
        boostValue: boostus.BoostValue,
        bettingSlipId,
        conditionId: boostus.ConditionId,
      }),
    );
    closeOffersAvailableDrawer();
  };

  const selectPromotion = (promotion: PromotionType) => {
    allowBSActions(
      actions.addPromotionToBS({
        id: promotion.Id,
        title: promotion.SummaryTitle || '',
        bettingSlipId,
      }),
    );
    closeOffersAvailableDrawer();
  };

  const drawerHeight = isDesktop ? '70%' : '90%';

  const giftLength = freebetList.length + boostusList.length;

  return (
    <DrawerContainer
      appearFrom="bottom"
      width={widthBettingSlipDrawerByMedia[media]}
      height={giftLength >= 5 ? drawerHeight : 'auto'}
      aria-label="Offers available betting slip"
      data-testid="bettingslip-offers-available-drawer"
      visible={offersAvailableDrawerVisibility}
    >
      <OffersAvailableDrawerContainer>
        <OffersAvailableDrawerTitleContainer
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          p="sm"
        >
          <Text fontSize="lg" mt="none">
            {t('bettingslip.offersAvailable.drawer.title')}
          </Text>
          <CloseButton
            onClick={closeOffersAvailableDrawer}
            data-testid="close-bettingslip-offersAvailable-drawer-button"
          >
            <Icon
              id="close-bettingslip-offersAvailable-drawer"
              type="Cross"
              ml="sm"
            />
          </CloseButton>
        </OffersAvailableDrawerTitleContainer>
        <OffersAvailableCardsContainer p="xs">
          <Text>{t('gifts.offersAvailable.main.text')}</Text>
          {boostusList.length > 0 && (
            <div data-testid="boostus-gift-container">
              <Text mt="lg" mb="xs" fontSize="xl">
                {t('gifts.boostus.offersAvailable.section.header')}
              </Text>
              {boostusList.map((boostus, i) => (
                <Card
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${boostus.BoostusId}`}
                  amount={boostus.BoostValue}
                  cardType="boostus"
                  validityDate={boostus.EndDate}
                  testId="grouped-boostus"
                  title={boostus?.Title || ''}
                  showSelectLabel
                  onClick={() => selectBoostus(boostus)}
                />
              ))}
            </div>
          )}
          {freebetList.length > 0 && (
            <div data-testid="freebet-gift-container">
              <Text mt="lg" mb="xs" fontSize="xl">
                {t('gifts.freebets.offersAvailable.section.header')}
              </Text>
              {freebetList.map((group, i) => (
                <Card
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${group.amount}`}
                  amount={group.nb}
                  cardType="freebet"
                  validityDate={group.expirationDate}
                  testId="grouped-freebet"
                  title={group.amount.toString()}
                  showSelectLabel
                  onClick={() => selectFreebet(group)}
                />
              ))}
            </div>
          )}
          {promotionList.length > 0 && (
            <div data-testid="promotion-gift-container">
              <Text mt="lg" mb="xs" fontSize="xl">
                {t('gifts.promotions.offersAvailable.section.header')}
              </Text>
              {promotionList.map((promo, i) => (
                <Card
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${promo.Id}`}
                  cardType="promotion"
                  validityDate={promo.EndDate}
                  testId="grouped-promotion"
                  title={promo.SummaryTitle || ''}
                  showSelectLabel
                  onClick={() => selectPromotion(promo)}
                />
              ))}
            </div>
          )}
        </OffersAvailableCardsContainer>
      </OffersAvailableDrawerContainer>
    </DrawerContainer>
  );
};

export const OffersAvailableDrawer = memo(OffersAvailableDrawerComponent);
