import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getUserPreferences,
  updateUserPreferences,
} from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../store/actions';
import { BettingEpic } from '../store/types';

import {
  getUserPreferencesResponseCodec,
  updateUserPreferencesResponseCodec,
} from './codecs';
import {
  getUserPreferencesErrorMessages,
  updateUserPreferencesErrorMessages,
} from './errorMessages';

/** Get the betting user settings */
export const getBettingUserSettingsEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getBettingUserSettings.request)),
    switchMap(() =>
      wsAdapter.request(getUserPreferences({})).pipe(
        mapGuard(getUserPreferencesResponseCodec),
        map(actions.getBettingUserSettings.success),
        catchError((err) =>
          of(
            actions.getBettingUserSettings.failure(
              createFailurePayload(err, getUserPreferencesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Update the betting user settings */
export const updateBettingUserSettingsEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.updateBettingUserSettings.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(updateUserPreferences(payload)).pipe(
        mapGuard(updateUserPreferencesResponseCodec),
        map(actions.updateBettingUserSettings.success),
        catchError((err) =>
          of(
            actions.updateBettingUserSettings.failure(
              createFailurePayload(err, updateUserPreferencesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** After a successful update of betting user settings, we get the betting user settings */
export const successUpdateBettingUserSettingsToGetBettingUserSettingsEpic: BettingEpic =
  (action$) =>
    action$.pipe(
      filter(isActionOf(actions.updateBettingUserSettings.success)),
      map(() => actions.getBettingUserSettings.request()),
    );
