import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import {
  EventDisplayType,
  EventType,
  StreamingType,
} from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { getEventTeamsValues } from '../../../common/store/helpers';
import { EventState } from '../../../common/store/types';
import { ApplicationWithBettingState } from '../../../store/types';
import { checkEventType } from '../helpers';
import {
  FilteredIds,
  NormalizedEvent,
  NormalizedLeague,
  SportType,
} from '../types';

import {
  componentLeagueIdsSelector,
  eventListSelector,
  leagueSelector,
  leaguesListSelector,
} from './common';
import { componentsSelector } from './components';
import { eventsSelector, leaguesSelector, sportsSelector } from './entities';

export const componentEventIdsSelector = createSelector(
  componentsSelector,
  (components) => memoize((key: string) => components[key]?.eventIds || []),
);

/* Live events count */
export const getLiveCountRequestStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.sportbook.requests.getLiveCount.status;

export const liveEventsCountSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.liveEventsCount;

/**
 * Return a function that takes an event id and returns an event
 */
export const eventSelector = createSelector(eventsSelector, (events) =>
  memoize(
    (eventId: number, filterDeleted: boolean | null = true) =>
      events[eventId]?.deleted && filterDeleted ? undefined : events[eventId],
    // Cache key resolver
    (eventId: number, filterDeleted = true) => `${eventId}${!!filterDeleted}`,
  ),
);
/**
 * Return a function that takes an event id and returns an event start date
 */
export const eventStartDateSelector = createSelector(
  eventSelector,
  (getEvent) =>
    memoize((eventId: number) => getEvent(eventId, false)?.StartDate),
);

/**
 * Return a function that takes an event id and returns an event statistics url
 */
export const eventUrlStatsSelector = createSelector(eventSelector, (getEvent) =>
  memoize((eventId: number) => getEvent(eventId, false)?.UrlStats),
);

/**
 * Return a function that takes a list of event ids and returns a list of events with maximum maxEvents and outrights at the end
 * Don't forget to use shallowEqual with this selector
 */
export const eventsWithOutrightsSelector = createSelector(
  eventListSelector,
  (getEvents) =>
    memoize(
      (eventIds: number[], maxEvents?: number) => {
        const events = getEvents(eventIds).filter(isNonNullable);

        const nonWinners = events.filter((e) => !e?.IsWinner);
        const winners = events.filter((e) => e?.IsWinner);

        const maxWinners = maxEvents && maxEvents - winners.length;

        return [...nonWinners.slice(0, maxWinners), ...winners];
      },

      // Cache key resolver
      (eventIds: number[], maxEvents?: number) =>
        `${eventIds?.join('-')}${!!maxEvents}`,
    ),
);

/**
 * Get EventDisplayType by leagueId
 */
export const eventDisplayTypeByLeagueIdSelector = createSelector(
  leagueSelector,
  (getLeague) =>
    memoize(
      (leagueId: number) =>
        getLeague(leagueId, false)?.EventDisplayType ||
        EventDisplayType.Unknown,
    ),
);

/**
 * Return a function that takes an event id and returns a sport id
 */
export const eventSportIdSelector = createSelector(
  eventsSelector,
  leaguesSelector,
  (events, leagues) =>
    memoize((eventId: number) =>
      events[eventId] ? leagues[events[eventId].LeagueId]?.SportId : undefined,
    ),
);

/**
 * Get EventDisplayType by eventId
 */
export const eventDisplayTypeSelector = createSelector(
  eventSelector,
  eventDisplayTypeByLeagueIdSelector,
  (getEvent, getDisplayType) =>
    memoize((eventId: number) =>
      getDisplayType(getEvent(eventId, false)?.LeagueId || 0),
    ),
);

/**
 * Return a function that takes an event id and returns an event name
 * Don't forget to use shallowEqual with this selector
 */
export const eventNameDetailsSelector = createSelector(
  eventSelector,
  eventDisplayTypeByLeagueIdSelector,
  (getEvent, getDisplayType) =>
    memoize((eventId: number) => {
      const event = getEvent(eventId, false);
      const type = getDisplayType(event?.LeagueId || 0);

      return getEventTeamsValues(event?.HomeName, event?.AwayName, type);
    }),
);

/**
 * Return a function that takes an event id and returns an event name
 */
export const eventNameSelector = createSelector(
  eventNameDetailsSelector,
  (getDetails) =>
    memoize((eventId: number): string => {
      const { team1, team2, separator } = getDetails(eventId);

      if (!eventId || (!team1 && !team2)) {
        return '';
      }

      const displayedSeparator = !team1 || !team2 ? '' : separator || ':';
      return `${team1} ${displayedSeparator} ${team2}`;
    }),
);

/**
 * Return a function that takes an event id and returns the found matchId or null
 */
export const matchIdByEventIdSelector = createSelector(
  eventSelector,
  (getEvent) =>
    memoize((eventId: number) => {
      const event = getEvent(eventId, false);
      const descriptors = event?.StreamingDescriptor;
      const descriptor = descriptors?.find(
        (d) => d.Type === StreamingType.LiveMatchTracker,
      );

      if (!descriptor?.StreamId) {
        return null;
      }

      return +descriptor.StreamId;
    }),
);

/**
 * Takes an array of NormalizedLeague then map their {EventItems} to get every
 * event ids and then retrieve all of the NormalizedEvent
 */
export const eventsListFromLeaguesListSelector = createSelector(
  eventsSelector,
  (events) =>
    memoize(
      (leagues: (NormalizedLeague | undefined)[]) => {
        const eventIds = leagues.map((league) =>
          league?.Events ? league?.Events?.map((id) => id) : [],
        );

        const flattendEventIds = eventIds.reduce(
          (acc, id) => (id ? acc?.concat(id) : []),
          [],
        );

        return flattendEventIds.map((eventId) => events[eventId]);
      },
      // Cache key resolver
      (leagues: (NormalizedLeague | undefined)[]) =>
        `${leagues.map((l) => l?.LeagueId).join('-')}`,
    ),
);

/**
 * Returns true if league contains at least 1 winner event
 */
export const hasLeagueWinnerEventsSelector = createSelector(
  leagueSelector,
  eventListSelector,
  (getLeague, getEvents) =>
    memoize((leagueId: number): boolean =>
      getEvents(getLeague(leagueId)?.Events || []).some((e) => e?.IsWinner),
    ),
);
/**
 * Returns true if league contains only winner events
 */
export const hasOnlyLeagueWinnerEventsSelector = createSelector(
  eventListSelector,
  (getEvents) =>
    memoize((eventIds: number[]): boolean =>
      getEvents(eventIds || []).every((event) => event?.IsWinner),
    ),
);
/**
 * Returns all ids ([sportId/leagueId/eventIds]) for a specified sport by STARTING from EventIds
 * Don't forget to use isEqual with this selector
 */
export const idsFromEventBySportSelector = createSelector(
  componentEventIdsSelector,
  leagueSelector,
  eventListSelector,
  (getEventIds, getLeague, getEvents) =>
    memoize(
      (key: string, sportId: number | null, type?: EventState): FilteredIds => {
        const events = getEvents(getEventIds(key), true).filter(
          (event) => !type || checkEventType(type, event),
        );
        const grouppedEvents = groupBy(events, (e) => e?.LeagueId);

        const ids = Object.entries(grouppedEvents)
          .map(([leagueId, evList]) => {
            const league = getLeague(+leagueId);

            if (!league || (sportId && league.SportId !== sportId)) {
              return null;
            }

            return {
              sportId: league.SportId,
              leagueId: +leagueId,
              eventIds: evList.map((e) => e?.EventId).filter(isNonNullable),
            };
          })
          .filter(isNonNullable);

        return ids;
      },

      // Cache key resolver
      (key: string, sportId: number | null, type?: EventState) =>
        `${key}${sportId},${type}`,
    ),
);

/**
 * Returns all events for a specified sport by STARTING from EventIds
 * Don't forget to use shallowEqual with this selector
 */
export const eventIdsFromEventBySportSelector = createSelector(
  idsFromEventBySportSelector,
  eventListSelector,
  (getIds, getEvents) =>
    memoize(
      (
        key: string,
        sportId: number,
        sortedType?: string[],
        type?: EventState,
      ): number[] => {
        const ids = getIds(key, sportId, type);
        const eventIds = flatten(ids.map((id) => id.eventIds));

        return getEvents(eventIds, true, sortedType)
          .filter(isNonNullable)
          .map((e) => e?.EventId || 0);
      },

      // Cache key resolver
      (
        key: string,
        sportId: number,
        sortedType?: string[],
        type?: EventState,
      ) => `${key}${sportId}${sortedType?.join('-')}${type}`,
    ),
);

/**
 * Returns all ids ([sportId/leagueId/eventIds]) for a specified sport and region id
 * Don't forget to use isEqual with this selector
 */
export const idsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventsWithOutrightsSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize(
      (key: string, maxEvents?: number): FilteredIds => {
        const leagues = getLeagues(getLeagueIds(key)).sort(
          (a, b) => (a?.Order || 0) - (b?.Order || 0),
        );

        return leagues.map((league) => ({
          sportId: league?.SportId || 0,
          leagueId: league?.LeagueId || 0,
          eventIds: getEvents(league?.Events || [], maxEvents).map(
            (e) => e?.EventId,
          ),
        }));
      },

      // Cache key resolver
      (key: string, maxEvents?: number) => `${key}${maxEvents}`,
    ),
);

/**
 * Returns live events by league id
 * Don't forget to use isEqual with this selector
 */
export const eventsByLeagueIdSelector = createSelector(idsSelector, (getIds) =>
  memoize(
    (key: string, leagueId: number, maxEvents?: number) => {
      const filteredIds = getIds(key, maxEvents);
      return filteredIds
        .filter((filteredId) => filteredId.leagueId === leagueId)
        .map((ids) => ids.eventIds)[0];
    },
    // Cache key resolver
    (key: string, leagueId, maxEvents?: number) =>
      `${key}${leagueId}${maxEvents}`,
  ),
);

/**
 * Returns a boolean if there is at least one top event
 */
export const hasAtLeastOneTopEventSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventsListFromLeaguesListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize((key: string): boolean => {
      const leagues = getLeagues(getLeagueIds(key));

      return getEvents(leagues).some(({ IsTopEvent }) => IsTopEvent);
    }),
);

export const isEventAvailableSelector = createSelector(
  eventsSelector,
  (eventsS) =>
    memoize((eventId: number): boolean => {
      const event = eventsS[eventId];
      if (!event || event.IsBlocked || event.deleted) {
        return false;
      }

      return true;
    }),
);

/**
 * Returns all sports by STARTING from EventIds
 * Don't forget to use shallowEqual with this selector
 */
export const sportsFromEventSelector = createSelector(
  idsFromEventBySportSelector,
  sportsSelector,
  (getIds, getSport) =>
    memoize((key: string): SportType[] => {
      const ids = getIds(key, null);
      const sportIds = uniq(ids.map((id) => id.sportId));

      return sportIds.map((id) => getSport[id]).filter(isNonNullable);
    }),
);

/**
 * Returns all ids ([sportId/leagueId/eventIds]) for top events only
 * Don't forget to use isEqual with this selector
 */
export const idsForTopEventsSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventsListFromLeaguesListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize((key: string): FilteredIds => {
      const leagues = getLeagues(getLeagueIds(key)).sort(
        (a, b) => (a?.Order || 0) - (b?.Order || 0),
      );

      const events = getEvents(leagues)
        .filter(({ IsTopEvent }) => IsTopEvent)
        .sort((a, b) => (a?.Order || 0) - (b?.Order || 0));

      return leagues
        .filter((l) => events.some((e) => e.LeagueId === l?.LeagueId))
        .map((league) => ({
          sportId: league?.SportId || 0,
          leagueId: league?.LeagueId || 0,
          eventIds: events
            .filter((e) => e.LeagueId === league?.LeagueId)
            .map((e) => e.EventId),
        }));
    }),
);

/**
 * Returns only winner ids ([sportId/leagueId/eventIds])
 * Don't forget to use isEqual with this selector
 */
export const idsWinnerOnlySelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize((key: string): FilteredIds => {
      const leagues = getLeagues(getLeagueIds(key)).sort(
        (a, b) => (a?.Order || 0) - (b?.Order || 0),
      );

      return leagues
        .map((league) => {
          const events = getEvents(league?.Events || []).filter(
            (event) => event?.IsWinner,
          );

          return !events.length
            ? null
            : {
                sportId: league?.SportId || 0,
                leagueId: league?.LeagueId || 0,
                eventIds: events.map((e) => e?.EventId || 0),
              };
        })
        .filter(isNonNullable);
    }),
);
/**
 * Returns live outrights
 * Don't forget to use isEqual with this selector
 */
export const liveOutrightsSelector = createSelector(
  eventListSelector,
  (getEvents) =>
    memoize(
      (ids: FilteredIds): NormalizedEvent[] | [] => {
        const eventIds = ids
          .map((id) => id.eventIds)
          .reduce((acc, val) => [...acc, ...val], []);

        const events = getEvents(eventIds).filter(
          (event) => event?.IsWinner && event?.EventType === EventType.Live,
        );

        return events.filter(isNonNullable) || [];
      },

      // Cache key resolver
      (ids: FilteredIds) => `${ids?.join('-')}`,
    ),
);

/**
 * Returns all ids ([sportId/leagueId/eventIds]) for a specified type (and filtered by leagueId)
 * Don't forget to use isEqual with this selector
 */
export const idsByTypeSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  eventListSelector,
  (getLeagueIds, getLeagues, getEvents) =>
    memoize(
      (
        key: string,
        type: EventState,
        leagueId?: number,
        maxEvents?: number,
        sortedType?: string[],
      ): FilteredIds => {
        const leagues = getLeagues(getLeagueIds(key)).sort(
          (a, b) => (a?.Order || 0) - (b?.Order || 0),
        );

        const filteredLeagues =
          leagueId != null
            ? leagues.filter((l) => l?.LeagueId === leagueId)
            : leagues;

        return filteredLeagues
          .map((league) => {
            const events = getEvents(
              league?.Events || [],
              true,
              sortedType,
            ).filter((event) => checkEventType(type, event));

            return !events.length
              ? null
              : {
                  sportId: league?.SportId || 0,
                  leagueId: league?.LeagueId || 0,
                  eventIds: events
                    .map((e) => e?.EventId || 0)
                    .slice(0, maxEvents),
                };
          })
          .filter(isNonNullable);
      },

      // Cache key resolver
      (
        key: string,
        type: EventState,
        leagueId?: number,
        maxEvents?: number,
        sortedType?: string[],
      ) => `${key}${type}${leagueId}${maxEvents}${sortedType?.join('-')}`,
    ),
);

/**
 * Returns an array of league ids having at least 1 winner event
 * Don't forget to use shallowEqual with this selector
 */
export const leaguesHavingWinnerEventsOnlySelector = createSelector(
  eventListSelector,
  leagueSelector,
  (getEvents, getLeague) =>
    memoize(
      (ids: FilteredIds): number[] =>
        ids
          ?.map((id) => getLeague(id.leagueId))
          ?.filter(isNonNullable)
          ?.filter(({ Events }) =>
            getEvents(Events || []).every((e) => e?.IsWinner),
          )
          ?.map((league) => league.LeagueId) || [],

      // Cache key resolver
      (ids: FilteredIds) =>
        `${ids.map((id) => `${id.leagueId}${id.eventIds.join('')}`).join('-')}`,
    ),
);
