import { BettingConfig } from '../types/betting';

/**
 * Betting default configuration
 */
export const defaultBettingConfig: BettingConfig = {
  betPipeSteps: {
    betMaker: [
      'resetBettingSlip',
      'getInitializationData',
      'getOutcomeInfos',
      'getOptimalBet',
      'getRiskManagement',
      [
        'getFreebetConditionsForBettingSlip',
        'getBoostusConditionsForBettingSlip',
        'getPromotionsIdsForBettingSlip',
      ],
    ],
    betPlacement: ['placeBet'],
  },
  bettingslip: {
    defaultStake: '20',
    multiSingle: false,
    suggestions: [20, 50, 100, 250],
  },
  boostus: {
    applyTo: 'odds',
    enabled: false,
  },
  dailyOffers: {
    enabled: false,
  },
  defaultOddsUnit: 'decimal',
  enabled: true,
  fastBettingSlip: {
    minStake: 1,
  },
  initialNbSportShown: 10,
  lmt: {
    config: {},
    enabled: true,
    fakeId: 0,
    theme: 'light',
    url: 'de4f75930e98f328c9e763240489caf5',
  },
  promotions: {
    enabled: false,
  },
  showEmailNotifications: false,
  stats: {
    enabled: false,
  },
  superOdds: {
    enabled: false,
  },
  uniformizationGiftsDesign: false,
};
