import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  eventNameDetailsSelector,
  eventStartDateSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useMediaBreakPoint } from '@gaming1/g1-ui';

import { SCOREBOARD_SCORE_SEPARATOR } from '../../constants';
import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import { useScoreBoardPrematchDate } from './hooks';
import {
  ScoreBoardPrematchTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardPrematchTemplateItem as TemplateItem,
  ScoreBoardPrematchTemplateRow as TemplateRow,
  ScoreBoardPrematchTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the prematch scoreboard with a defaut template */
export const ScoreBoardPrematchDefaultTemplate: VFC<
  ScoreBoardTemplateProps
> = ({ eventId, sportId }) => {
  const isDesktop = useMediaBreakPoint({ min: 'lg' });
  const {
    team1: name1,
    team2: name2,
    separator,
  } = useBettingGetterSelector({
    getterSelector: eventNameDetailsSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const startDate = useBettingGetterSelector({
    getterSelector: eventStartDateSelector,
    args: [eventId],
  });

  const { date, hour } = useScoreBoardPrematchDate(startDate) || {};

  return (
    <TemplateWrapper data-testid="scoreboard-prematch-default-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper>
          <TemplateRow height="100%" justifyContent="center" width="100%">
            <TemplateItem isMuted>{[date, hour].join(' - ')}</TemplateItem>
          </TemplateRow>
          <TemplateRow
            alignItems="flex-start"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <TemplateItem
              flex={1}
              fontSize={isDesktop ? 'xl' : 'md'}
              px="sm"
              alignItems="flex-end"
            >
              {name1}
            </TemplateItem>
            <TemplateItem fontSize={isDesktop ? 'xl' : 'md'} px="xs">
              {separator || SCOREBOARD_SCORE_SEPARATOR}
            </TemplateItem>
            <TemplateItem
              flex={1}
              fontSize={isDesktop ? 'xl' : 'md'}
              px="sm"
              alignItems="start"
            >
              {name2}
            </TemplateItem>
          </TemplateRow>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
