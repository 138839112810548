import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ConfigContext } from '@gaming1/g1-config';
import { useRoutePath } from '@gaming1/g1-core-web';
import { Game } from '@gaming1/g1-gaming';
import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, Pellet } from '@gaming1/g1-ui';

import { useGetGamingAssetPath } from '../../../assetsManagement/hooks';
import { gamingRoutes } from '../../../routes';

import {
  GameCardButton,
  GameCardContent,
  GameCardImg,
  GameCardLabelImage,
  GameCardOverlayDiv,
} from './styles';

export type GameCardProps = {
  /** Game object */
  game: Game;
  /** Force the image ratio (the image will be cropped) */
  ratio?: number;
  /**
   * By default, the game card opens the corresponding game on click. This
   * callback can be used to override said behavior.
   */
  onClick?(game: Game): void;
  /** Display favorite icon */
  favoriteIcon?: boolean;
  /** Hide overlay */
  hideOverlay?: boolean;
};

export const DEFAULT_GAMECARD_RATIO = 1.68;

/** Show a single clickable game card */
export const GameCard: FC<GameCardProps> = ({
  game,
  onClick,
  ratio = DEFAULT_GAMECARD_RATIO,
  favoriteIcon,
  hideOverlay,
}) => {
  const config = useContext(ConfigContext);
  const history = useHistory();
  const getGamingRoutePath = useRoutePath(gamingRoutes);
  const getGamingAssetPath = useGetGamingAssetPath();
  const redirectToGamePage = ({ Id }: Game) =>
    history.push(getGamingRoutePath('playGame', { gameId: Id }));
  const { t } = useTranslation('gaming');

  let tagSource;
  let tagName;

  if (game.IsNewGame) {
    tagSource = getGamingAssetPath('newGameTag');
    tagName = 'new';
  } else if (game.IsTopGame) {
    tagSource = getGamingAssetPath('topGameTag');
    tagName = 'top';
  } else if (game.ProgressiveJackpotName) {
    tagSource = getGamingAssetPath('jackpotGameTag');
    tagName = 'jackpot';
  }

  const [gameCardWidth, setGameCardWidth] = useState(0);
  const handleRef = (el: HTMLDivElement | null) => {
    if (el) {
      setGameCardWidth(el.getBoundingClientRect().width);
    }
  };

  const isCardTiny = gameCardWidth <= 100;
  const overlay = (
    <GameCardOverlayDiv $isTiny={isCardTiny} data-testid="game-card-title">
      {isCardTiny ? (
        <>
          {t('gameCard.play')}
          <Icon id="play" type="Play" height="12px" width="12px" />
        </>
      ) : (
        game.Name
      )}
    </GameCardOverlayDiv>
  );

  return (
    <GameCardButton
      onClick={onClick ? () => onClick(game) : () => redirectToGamePage(game)}
      ratio={ratio}
      data-testid="game-card"
      shouldHighlightRestrictedGame={!game.IsVisible}
      aria-label={game.Name}
    >
      <GameCardContent ref={handleRef}>
        <GameCardImg
          alt={game.Name}
          dark
          forceRatio={ratio !== undefined}
          loading="lazy"
          placeholderOnError
          ratio={ratio}
          src={`${config.network.mediaUrl}/${game.LobbyMediaResourceUri}`}
        />
        {tagSource && tagName && !favoriteIcon && (
          <GameCardLabelImage
            alt={tagName}
            $gameCardWidth={gameCardWidth}
            id={`gamecard-tag-${tagName}`}
            url={tagSource}
          />
        )}
        {game.IsFavorite && favoriteIcon && (
          <Pellet data-testid="gameCard-favorite-pellet">
            <Icon id="gamecard-favorite" type="HeartFilled" height="10px" />
          </Pellet>
        )}
        {!hideOverlay && overlay}
      </GameCardContent>
    </GameCardButton>
  );
};
