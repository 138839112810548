import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  RecoverPasswordRequest,
  ValidatePasswordRecoveryResponse,
} from './types';

export const recoverPassword = createAsyncAction(
  'user/recover_password_request',
  'user/recover_password_success',
  'user/recover_password_failure',
)<RecoverPasswordRequest, void, FailurePayload>();

/** Clean recover password request state */
export const cleanRecoverPassword = createAction(
  'user/clean_recover_password',
)();

/** Check if the passord recovery code is valid */
export const validatePasswordRecoveryId = createAsyncAction(
  'user/validate_password_recovery_id_request',
  'user/validate_password_recovery_id_success',
  'user/validate_password_recovery_id_failure',
)<{ recoveryId: string }, ValidatePasswordRecoveryResponse, FailurePayload>();

/** Reset the account password with a new one */
export const resetPassword = createAsyncAction(
  'user/reset_password_request',
  'user/reset_password_success',
  'user/reset_password_failure',
)<
  { password: string; recoveryId: string; pinCode?: string },
  void,
  FailurePayload
>();
