import React, { FC } from 'react';

import { useIsTouchDevice, useWindowSize } from '@gaming1/g1-utils';

import { CollapsibleMenu } from '../CollapsibleMenu';

import { ContentContainer } from './styles';

const CUSTOM_LARGE_BREAKPOINT = 1400;

type CollapsibleMenuContainerProps = {
  /** Shows the live navigation is true */
  isLivePage?: boolean;
  isMenuVisible: boolean;
  toggleMenu: (shouldOpen: boolean) => void;
};

/**
 * This component renders a menu that could be collapsed to only show icons
 */
export const CollapsibleMenuContainer: FC<CollapsibleMenuContainerProps> = ({
  children,
  isLivePage = false,
  isMenuVisible,
  toggleMenu,
}) => {
  const windowSize = useWindowSize();
  const isDesktop = !!(
    windowSize.width && windowSize.width >= CUSTOM_LARGE_BREAKPOINT
  );
  const hasTouchCapabilities = useIsTouchDevice();

  return (
    <>
      <CollapsibleMenu
        showLivesOnly={isLivePage}
        hasTouchCapabilities={hasTouchCapabilities}
        isCollapsed={!isDesktop && !isMenuVisible}
        isDesktop={isDesktop}
        toggleMenu={toggleMenu}
        isMenuVisible={isMenuVisible}
      />
      <ContentContainer data-testid="content-container" isDesktop={isDesktop}>
        {children}
      </ContentContainer>
    </>
  );
};
