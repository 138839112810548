import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  cancelWithdraw as cancelWithdrawal,
  getCreditCardWithdrawInfos,
  getEWalletWithdrawInfos,
  getTransferWithdrawInfos,
  getWithdrawInfo,
  makeCreditCardWithdraw,
  makeEWalletWithdraw,
  makeInRoomWithdraw,
  MakeInRoomWithdrawRequestDTO,
  makeTransferWithdraw,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../actions';
import { PaymentEpic } from '../types';

import {
  cancelWithdrawalResponse,
  getCreditCardWithdrawInfoResponse,
  getEWalletWithdrawInfoResponse,
  getTransferWithdrawInfoResponse,
  getWithdrawInfoResponse,
  makeCreditCardWithdrawResponse,
  makeEWalletWithdrawResponse,
  makeInRoomWithdrawResponse,
  makeTransferWithdrawResponse,
} from './codecs';
import {
  cancelWithdrawalErrorMessages,
  getCreditCardWithdrawInfoErrorMessages,
  getEWalletWithdrawInfoErrorMessages,
  getTransferWithdrawInfoErrorMessages,
  getWithdrawInfoErrorMessages,
  makeCreditCardWithdrawErrorMessages,
  makeEWalletWithdrawErrorMessages,
  makeInRoomWithdrawErrorMessages,
  makeTransferWithdrawErrorMessages,
} from './errorMessages';

/** Cancel a withdrawal */
export const cancelWithdrawalEpic: PaymentEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.cancelWithdrawal.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(cancelWithdrawal(payload)).pipe(
        mapGuard(cancelWithdrawalResponse),
        map(actions.cancelWithdrawal.success),
        catchError((err) =>
          of(
            actions.cancelWithdrawal.failure(
              createFailurePayload(err, cancelWithdrawalErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the Withdraw informations */
export const getWithdrawalInfoEpic: PaymentEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getWithdrawalInfo.request)),
    switchMap(() =>
      wsAdapter.request(getWithdrawInfo({})).pipe(
        mapGuard(getWithdrawInfoResponse),
        map(actions.getWithdrawalInfo.success),
        catchError((err) =>
          of(
            actions.getWithdrawalInfo.failure(
              createFailurePayload(err, getWithdrawInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const getTransferWithdrawalInfoEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTransferWithdrawalInfo.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getTransferWithdrawInfos(payload)).pipe(
        mapGuard(getTransferWithdrawInfoResponse),
        map(actions.getTransferWithdrawalInfo.success),
        catchError((err) =>
          of(
            actions.getTransferWithdrawalInfo.failure(
              createFailurePayload(err, getTransferWithdrawInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const getEWalletWithdrawalInfoEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getEWalletWithdrawalInfo.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getEWalletWithdrawInfos(payload)).pipe(
        mapGuard(getEWalletWithdrawInfoResponse),
        map(actions.getEWalletWithdrawalInfo.success),
        catchError((err) =>
          of(
            actions.getEWalletWithdrawalInfo.failure(
              createFailurePayload(err, getEWalletWithdrawInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const getCreditCardWithdrawalInfoEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getCreditCardWithdrawalInfo.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getCreditCardWithdrawInfos(payload)).pipe(
        mapGuard(getCreditCardWithdrawInfoResponse),
        map(actions.getCreditCardWithdrawalInfo.success),
        catchError((err) =>
          of(
            actions.getCreditCardWithdrawalInfo.failure(
              createFailurePayload(err, getCreditCardWithdrawInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const makeInRoomWithdrawalEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.makeInRoomWithdrawal.request)),
    switchMap(({ payload }) => {
      const params: MakeInRoomWithdrawRequestDTO = {
        Amount: payload.Amount,
        Code: payload.Code,
        WalletId: payload.WalletId,
        VirtualRoomId: payload?.VirtualRoomId,
      };
      return wsAdapter.request(makeInRoomWithdraw(params)).pipe(
        mapGuard(makeInRoomWithdrawResponse),
        map(actions.makeInRoomWithdrawal.success),
        catchError((err) =>
          of(
            actions.makeInRoomWithdrawal.failure(
              createFailurePayload(err, makeInRoomWithdrawErrorMessages),
            ),
          ),
        ),
      );
    }),
  );

export const makeTransferWithdrawalEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.makeTransferWithdrawal.request)),
    switchMap(({ payload }) =>
      wsAdapter
        // This request can take times hence why the timeout is higher (30 sec)
        .request(makeTransferWithdraw(payload), { timeoutAfterMs: 30000 })
        .pipe(
          mapGuard(makeTransferWithdrawResponse),
          map(actions.makeTransferWithdrawal.success),
          catchError((err) =>
            of(
              actions.makeTransferWithdrawal.failure(
                createFailurePayload(err, makeTransferWithdrawErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

export const makeEWalletWithdrawalEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.makeEWalletWithdrawal.request)),
    switchMap(({ payload }) =>
      wsAdapter
        // This request can take times hence why the timeout is higher (30 sec)
        .request(makeEWalletWithdraw(payload), { timeoutAfterMs: 30000 })
        .pipe(
          mapGuard(makeEWalletWithdrawResponse),
          map(actions.makeEWalletWithdrawal.success),
          catchError((err) =>
            of(
              actions.makeEWalletWithdrawal.failure(
                createFailurePayload(err, makeEWalletWithdrawErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

export const makeCreditCardWithdrawalEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.makeCreditCardWithdrawal.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(makeCreditCardWithdraw(payload)).pipe(
        mapGuard(makeCreditCardWithdrawResponse),
        map(actions.makeCreditCardWithdrawal.success),
        catchError((err) =>
          of(
            actions.makeCreditCardWithdrawal.failure(
              createFailurePayload(err, makeCreditCardWithdrawErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
