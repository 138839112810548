import { generatePaginatedInitialRequestState } from '@gaming1/g1-utils';

import { HistoryState } from '../types';

export const initialState: HistoryState = {
  requests: {
    getGameFeatureHistory: generatePaginatedInitialRequestState([]),
    getScratchCardHistory: generatePaginatedInitialRequestState([]),
    getShopHistory: generatePaginatedInitialRequestState([]),
    getSponsorshipHistory: generatePaginatedInitialRequestState([]),
  },
  freeSpins: null,
  scratchcards: null,
  shopOrders: null,
  sponsorships: null,
};
