import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { typography } from 'styled-system';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const SCOREBOARD_COMPACT_BULLET_SIZE_IN_PX = 6;
const SCOREBOARD_COMPACT_BORDER_RADIUS_IN_PX = 3;
const SCOREBOARD_COMPACT_ITEM_MIN_HEIGHT_IN_PX = 22;
const SCOREBOARD_COMPACT_ITEM_MIN_WIDTH_IN_PX = 20;

export const ScoreBoardCompactTemplateWrapper = styled(Box)`
  width: fit-content;
`;

export const ScoreBoardCompactBackgroundWrapper = styled(Box)<{
  backgroundRadius?: 'left' | 'right' | 'bothSides';
}>`
  background-color: ${({ theme }) => theme.colors.backgrounds[0]};

  ${({ backgroundRadius }) =>
    (backgroundRadius === 'left' &&
      css`
        border-top-left-radius: ${SCOREBOARD_COMPACT_BORDER_RADIUS_IN_PX}px;
        border-bottom-left-radius: ${SCOREBOARD_COMPACT_BORDER_RADIUS_IN_PX}px;
      `) ||
    (backgroundRadius === 'right' &&
      css`
        border-top-right-radius: ${SCOREBOARD_COMPACT_BORDER_RADIUS_IN_PX}px;
        border-bottom-right-radius: ${SCOREBOARD_COMPACT_BORDER_RADIUS_IN_PX}px;
      `) ||
    (backgroundRadius === 'bothSides' &&
      css`
        border-radius: ${SCOREBOARD_COMPACT_BORDER_RADIUS_IN_PX}px;
      `)};
`;

export const ScoreBoardCompactTemplateRow = styled(Box)`
  flex-direction: row;
`;

export const ScoreBoardCompactTemplateColumn = styled(Box)`
  flex-direction: column;
  position: relative;
`;

export const ScoreBoardCompactTemplateItem = styled(Box)<{
  isHighlighted?: boolean;
  isMuted?: boolean;
}>`
  align-items: center;
  font-size: ${fontSizes('md')};
  justify-content: center;
  min-height: ${SCOREBOARD_COMPACT_ITEM_MIN_HEIGHT_IN_PX}px;
  min-width: ${SCOREBOARD_COMPACT_ITEM_MIN_WIDTH_IN_PX}px;
  padding: ${spaces('xxxs')} ${spaces('xxs')};
  width: 100%;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      font-weight: ${fontWeight('bold')};
    `};

  ${({ isMuted }) =>
    isMuted &&
    css`
      color: ${colors('text', { transform: transparentize('0.35') })};
    `};

  ${typography}
`;

export const ScoreBoardCompactTemplateItemTitle = styled(
  ScoreBoardCompactTemplateItem,
)`
  position: absolute;
  top: -${SCOREBOARD_COMPACT_ITEM_MIN_HEIGHT_IN_PX}px;
  left: 0;
`;

export const ScoreBoardCompactTemplateBullet = styled(Box)<{ color?: string }>`
  background-color: ${colors('textLight')};
  border-radius: 50%;
  height: ${SCOREBOARD_COMPACT_BULLET_SIZE_IN_PX}px;
  width: ${SCOREBOARD_COMPACT_BULLET_SIZE_IN_PX}px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `};
`;
