import debounce from 'lodash/debounce';
import {
  DependencyList,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getWorkingEnv } from '@gaming1/g1-logger';

import { isNonNullable } from './guards';
import { sleep } from './helpers';
import { RemoteData, WindowOrientation } from './types';

/**
 * Return a function that returns false if the component is unmounted.
 * Useful for async setState
 */
export const useGetIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

/**
 * Returns a function that takes a callback and a delay in ms.
 * Will call the function after the delay only if the component is still mounted
 */
export const useDelayedCallback = () => {
  const getIsMounted = useGetIsMounted();
  return (callback: () => void, delay: number) => {
    sleep(delay)().then(() => {
      if (getIsMounted()) {
        callback();
      }
    });
  };
};

// From https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
/**
 * Gets the previous rendered value of given data
 */
export const usePrevious = <T>(value: T) => {
  // Cf https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065#issuecomment-453841404
  const ref = useRef<T | null>(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Get the latest previous value different from the current one
 * (unlike usePrevious that will return the value at the previous render,
 * even if it's the same as currently)
 */
export const usePreviousDifferent = <T extends NonNullable<unknown>>(
  currentValue: T,
) => {
  const [previousDifferentValue, setPreviousDifferentValue] =
    useState<T | null>(null);

  const previousValue = usePrevious(currentValue);

  const hasPreviousValueChanged =
    isNonNullable(previousValue) &&
    isNonNullable(currentValue) &&
    currentValue !== previousValue;

  useEffect(() => {
    if (hasPreviousValueChanged) {
      setPreviousDifferentValue(previousValue);
    }
  }, [previousValue, currentValue, hasPreviousValueChanged]);
  return hasPreviousValueChanged ? previousValue : previousDifferentValue;
};

// From https://www.reactiflux.com/transcripts/kent-dodds
/**
 * useEffect but without the first execution on mount
 * @param cb callback
 * @param deps useEffect dependencies
 */
export const useEffectPostMount = (
  cb: () => void,
  deps: DependencyList = [],
) => {
  const postMount = useRef(false);
  useEffect(() => {
    if (postMount.current) {
      return cb();
    }
    postMount.current = true;
    return undefined;
    // Unfortunately using this hook prevents the eslint react-hook rule to work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cb, ...deps]);
};

// From https://usehooks.com/useWhyDidYouUpdate/
/**
 * Log in the console why a component has been rerendered.
 * Only for debugging! Do not use in production
 * @param name Name of the component
 * @param props props of the component
 */
export const useWhyDidYouUpdate = <P extends Record<string, unknown>>(
  name: string,
  props: P,
) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<P | null>(null);

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys(
        // By using spread, ts is complaining that previousProps.current is not an object
        // eslint-disable-next-line prefer-object-spread
        { ...previousProps.current, ...props },
      );
      // Use this object to keep track of changed props
      const changesObj: {
        [k in keyof P]?: { from: P[keyof P]; to: P[keyof P] };
      } = {};
      // Iterate through keys
      allKeys.forEach((key: keyof P) => {
        // If previous is different from current
        if (
          previousProps.current &&
          previousProps.current[key] &&
          previousProps.current[key] !== props[key]
        ) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};

// From https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
/**
 * Return a value whose updates are debounced
 * @param value the value of the debounced state
 * @param delay delay in ms
 * @param initialValue the value sent before the first timeout
 */
export const useDebounce = <T>(value: T, delay: number, initialValue?: T) => {
  const getIsMounted = useGetIsMounted();
  const [debouncedValue, setDebouncedValue] = useState(
    initialValue === undefined ? value : initialValue,
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (getIsMounted()) {
        setDebouncedValue(value);
      }
    }, delay);
    return () => clearTimeout(handler);
  }, [getIsMounted, delay, value]);

  return debouncedValue;
};

/**
 * useState but when the state is changed, it will go back to its default value
 * after some delay
 * @param value the default value
 * @param delay the delay (in ms) after which the value will be reset
 */
export const useStateSwitch = <T>(
  value: T,
  delay: number,
): [T, (value: T) => void] => {
  const getIsMounted = useGetIsMounted();
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    const handler =
      value !== currentValue
        ? setTimeout(() => {
            // Prevent updating a state of an unmounted component
            if (getIsMounted()) {
              setCurrentValue(value);
            }
          }, delay)
        : null;
    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [getIsMounted, value, currentValue, delay]);
  return [currentValue, setCurrentValue];
};

/* DOM HOOKS */

/**
 * Listens to the `document.body` scrollTop value and returns a boolean state.
 *
 * `const bodyIsScrolled = useBodyScroll(); // bodyIsScrolled == false`
 *
 * @param threshold scrolling value in px before changing the state to true default is 0
 * @returns boolean
 */
export const useBodyScroll = (threshold = 0) => {
  const [bodyIsScrolled, setBodyIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = document.body.scrollTop > threshold;

      if (isScrolled !== bodyIsScrolled) {
        setBodyIsScrolled(isScrolled);
      }
    };

    document.body.addEventListener('scroll', handleScroll);

    return () => document.body.removeEventListener('scroll', handleScroll);
  }, [bodyIsScrolled, threshold]);

  return bodyIsScrolled;
};

const getSize = (isClient: boolean) => ({
  width: isClient ? window.innerWidth : undefined,
  height: isClient ? window.innerHeight : undefined,
});

const getScroll = (element: HTMLElement | Window, isClient: boolean) => {
  if (isClient) {
    return {
      x: 'scrollX' in element ? element.scrollX : element.scrollLeft,
      y: 'scrollY' in element ? element.scrollY : element.scrollTop,
    };
  }

  return { x: undefined, y: undefined };
};

/**
 * Returns the srollY and scrollX property on scroll.
 * @param element element that will be listened to for the scroll change. By
 * default, this will be the window object.
 * @param debounceDelay debounce of the event triggering to limit performance
 * issues.
 * @param triggerWhileScrolling use the wheel event instead of scroll to get more
 * updates on the scroll. Changing this param later won't change the hook
 * behaviour.
 */
export const useScroll = (scrollOptions?: {
  element?: HTMLElement;
  debounceDelay?: number;
  triggerWhileScrolling?: boolean;
}) => {
  const element = scrollOptions?.element ?? window;
  const debounceDelay = scrollOptions?.debounceDelay ?? 50;

  // The event type should'nt change during a lifecycle. This is why
  // `triggerWhileScrolling` is put in a ref so it doesn't change the `eventName`
  const triggerEventCondition = scrollOptions?.triggerWhileScrolling ?? false;
  const triggerEventWhileScrollingRef = useRef(triggerEventCondition);
  triggerEventWhileScrollingRef.current = triggerEventCondition;
  const eventName = useMemo(() => {
    const scrollEvent = 'ontouchstart' in window ? 'touchmove' : 'wheel';
    return triggerEventWhileScrollingRef.current ? scrollEvent : 'scroll';
  }, []);

  const isClient = ['web', 'jest'].includes(getWorkingEnv());
  const [windowScroll, setWindowScroll] = useState<
    ReturnType<typeof getScroll>
  >({ x: 0, y: 0 });

  const handleScroll = debounce(
    () => setWindowScroll(getScroll(element, isClient)),
    debounceDelay,
  );

  useEffect(() => {
    setWindowScroll(getScroll(element, isClient));
  }, [element, isClient]);

  useEffect(() => {
    if (isClient) {
      element.addEventListener(eventName, handleScroll, false);
    }
    return () => {
      element.removeEventListener(eventName, handleScroll);
      if (typeof handleScroll?.cancel === 'function') {
        handleScroll.cancel();
      }
    };
  }, [element, eventName, handleScroll, isClient]);

  return windowScroll;
};

// From https://usehooks.com/useWindowSize/
/**
 * Listen to the window resize event and returns the viewport size
 */
export const useWindowSize = (debounceDelay = 50) => {
  const isClient = ['web', 'jest'].includes(getWorkingEnv());

  const [windowSize, setWindowSize] = useState(getSize(isClient));

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }

    const handleResize = debounce(
      () => setWindowSize(getSize(isClient)),
      debounceDelay,
    );

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [debounceDelay, isClient]);

  return windowSize;
};

/**
 * This hooks simply returns the device screen orientation based on
 * a comparison between the screen width and height.
 *
 * @returns The screen orientation being either `portrait` or `landscape`
 */
export const useWindowOrientation = (): WindowOrientation => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  return (windowHeight || 0) >= (windowWidth || 0) ? 'portrait' : 'landscape';
};

/**
 * This hooks gets the given element DOMRect and observes said element to send
 * the rect again every time it intersects with the viewport. This proves to be
 * useful to execute effects or animations on elements that are not always
 * visible when they fully come into the viewport.
 *
 * It uses the IntersectionObserver with a threshold of 1 so the returned state
 * only updates when the element becomes fully visible or as soon as one of its
 * pixels dissapears from the viewport.
 *
 * ### Usage
 * ```tsx
 * const TestComponent: FC = () => {
 *  const [element, setElement] = useState<HTMLDivElement>();
 *
 *  const { left = 0, top = 0 } = useBoundingClientRect(element) ?? {};
 *
 *  return (
 *    <div ref={setElement}>
 *      My coordinates are [left: {left}, top: {top}]
 *    </div>
 *  );
 * };
 * ```
 */
export const useBoundingClientRect = <E extends HTMLElement>(
  element: E | null,
) => {
  const [state, setState] = useState<DOMRectReadOnly>();
  // placing the observer in a ref so it never changes or triggers the effect
  const observerRef = useRef(
    new window.IntersectionObserver(
      ([entry]) => {
        setState(entry.boundingClientRect);
      },
      { threshold: 1 },
    ),
  );
  useEffect(() => {
    const observer = observerRef.current;
    if (element) {
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }

    return () => undefined;
  }, [element]);

  return state;
};

/**
 * Calls callbacks when the provided request status value switches from Loading
 * to Success or Failure
 */
export const useRequestCallback = (
  requestStatus: RemoteData,
  successCallback?: () => void,
  failureCallback?: () => void,
) => {
  const previousRequestStatus = usePrevious(requestStatus);
  useEffect(() => {
    if (
      previousRequestStatus === RemoteData.Loading &&
      requestStatus === RemoteData.Success &&
      successCallback
    ) {
      successCallback();
    } else if (
      previousRequestStatus === RemoteData.Loading &&
      requestStatus === RemoteData.Error &&
      failureCallback
    ) {
      failureCallback();
    }
  }, [previousRequestStatus, requestStatus, successCallback, failureCallback]);
};

/**
 * Returns true when the provided request status is equal to 'Loading' (only the first time)
 */
export const useFirstLoadingState = (requestStatus: RemoteData) => {
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const isLoading = requestStatus === RemoteData.Loading;
  const previousIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (previousIsLoading && !isLoading) {
      setHasLoadedOnce(true);
    }
  }, [isLoading, previousIsLoading]);

  return !hasLoadedOnce ? isLoading : false;
};

/**
 * Returns true when the provided request status is equal to 'Success' or 'Error'
 */
export const useRequestProcessed = (requestStatus: RemoteData) =>
  useMemo(
    () =>
      requestStatus === RemoteData.Success ||
      requestStatus === RemoteData.Error,
    [requestStatus],
  );

/**
 * Returns a number that is incremented every X ms
 */
export const useInterval = (intervalInMs: number) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    const interval = window.setInterval(
      () => setValue((val) => val + 1),
      intervalInMs,
    );
    return () => window.clearInterval(interval);
  });
  return value;
};

/**
 * counter going from [from] to [to]
 */
export const useCounter = ({
  from = 0,
  to = Number.MAX_SAFE_INTEGER,
  loop = false,
}: {
  /** Start value  */
  from?: number;
  /** End Value */
  to?: number;
  /** Should the counter loop? */
  loop?: boolean;
} = {}) => {
  const [counter, setCounter] = useState(from);

  const hasNext = () => (loop ? true : counter < to);
  const hasPrevious = () => (loop ? true : counter > from);
  const increment = () => {
    if (loop) {
      const next = counter + 1;
      setCounter(next <= to ? next : from);
    } else if (hasNext()) {
      setCounter(counter + 1);
    }
  };

  const decrement = () => {
    if (loop) {
      const prev = counter - 1;
      setCounter(prev >= from ? prev : to);
    } else if (hasPrevious()) {
      setCounter(counter - 1);
    }
  };

  return { hasPrevious, hasNext, increment, decrement, counter };
};

// From https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/
/**
 * Detect if the device has touch capabilities
 */
export const useIsTouchDevice = () => {
  const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-'];

  const mediaQueries = (query: string) => window.matchMedia(query).matches;

  if (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 // If browser supports Pointer Events
  ) {
    return true;
  }

  /**
   * include the 'heartz' as a way to have a non matching MQ to help terminate the join
   * https://git.io/vznFH
   */
  const heartzQuery = mediaQueries(
    prefixes
      .map((p) => `(${p}touch-enabled)`)
      .concat('(heartz)')
      .join(','),
  );

  return heartzQuery;
};

/**
 * Detect if the document is visible to the user
 *
 * Conditions for which the document is set to hidden :
 *  - The user agent is minimized.
 *  - The user agent is not minimized, but doc is on a background tab.
 *  - The user agent is to unload doc.
 *  - The Operating System lock screen is shown.
 *
 * Set to visible if :
 *  - The user agent is not minimized and doc is the foreground tab.
 *  - The user agent is fully obscured by an accessibility tool, like a magnifier, but a view of the doc is shown.
 */
export const useIsTabVisible = () => {
  const isClient = ['web', 'jest'].includes(getWorkingEnv());
  const [isVisible, setIsVisible] = useState(
    isClient ? document.visibilityState === 'visible' : true,
  );

  const handleVisibilityChange = () => {
    setIsVisible(document.visibilityState === 'visible');

    return document.visibilityState;
  };

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isClient]);

  return isVisible;
};

type Edges = {
  isBottomVisible: boolean;
  isLeftVisible: boolean;
  isRightVisible: boolean;
  isTopVisible: boolean;
};

type EdgeVisibility = [
  Edges,
  (element: HTMLElement | null) => void,
  HTMLElement | null,
];

/**
 * Hook that returns the visibility of the edges of an HTML element.
 *
 * Returns an array containing these values :
 * - An object that contains the visibility of the edges.
 * - A callback reference for the targeted HTML element.
 * - The targeted HTML element returned by the callback reference.
 */
export const useEdgesVisibility = (): EdgeVisibility => {
  const windowSize = useWindowSize(10);
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [edges, setEdges] = useState({
    bottom: true,
    left: true,
    right: true,
    top: true,
  });

  const updateEdges = useCallback((e: HTMLElement) => {
    const top = e.scrollTop === 0;
    const bottom = Math.ceil(e.scrollTop + e.clientHeight) >= e.scrollHeight;
    const left = e.scrollLeft === 0;
    const right = Math.ceil(e.scrollLeft + e.clientWidth) >= e.scrollWidth;

    setEdges({ top, bottom, left, right });
  }, []);

  // handles first render, scroll size change and screen resizing
  useLayoutEffect(() => {
    if (element) {
      updateEdges(element);
    }
  }, [
    element,
    element?.scrollWidth,
    element?.scrollHeight,
    updateEdges,
    windowSize,
  ]);

  // handles scrolling event
  useLayoutEffect(() => {
    const handleScroll = debounce((e: Event) => {
      if (e.target) {
        updateEdges(e.target as HTMLElement);
      }
    }, 10);

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [updateEdges, element]);

  return [
    {
      isBottomVisible: edges.bottom,
      isLeftVisible: edges.left,
      isRightVisible: edges.right,
      isTopVisible: edges.top,
    },
    (e) => setElement(e),
    element,
  ];
};

/** Returns a string ramdom name */
export const useRandomName = () =>
  useMemo(
    () =>
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15),
    [],
  );
