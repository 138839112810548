/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  PlayerAcceptTermsAndConditionsRequest,
  PlayerAcceptTermsAndConditionsResponse,
  ShowTermsAndConditionsRequest,
  ShowTermsAndConditionsResponse,
  TermsAndConditionsRequest,
  TermsAndConditionsResponse,
} from '../entities';

export const getTermsAndConditions = (options: TermsAndConditionsRequest) =>
  createRequest<TermsAndConditionsResponse>(
    100,
    'GetTermsAndConditions',
    options,
  );

export const showTermsAndConditions = (
  options: ShowTermsAndConditionsRequest,
) =>
  createRequest<ShowTermsAndConditionsResponse>(
    100,
    'ShowTermsAndConditions',
    options,
  );

export const playerAcceptTermsAndConditions = (
  options: PlayerAcceptTermsAndConditionsRequest,
) =>
  createRequest<PlayerAcceptTermsAndConditionsResponse>(
    100,
    'PlayerAcceptTermsAndConditions',
    options,
  );
