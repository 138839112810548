import { FC } from 'react';

import {
  GameCategoriesMenuContext,
  GameCategoriesMenuContextType,
} from '../../../gameList/components/GameCategoriesMenuContext';

export type GamingProvidersProps = {
  menus: {
    gameCategories: GameCategoriesMenuContextType;
  };
};

/**
 * Every context providers needed for the gaming package:
 * - GameCategoriesMenuContext that expose data for game categories menu
 */
export const GamingProviders: FC<GamingProvidersProps> = ({
  children,
  menus,
}) => (
  <GameCategoriesMenuContext.Provider value={menus.gameCategories}>
    {children}
  </GameCategoriesMenuContext.Provider>
);
