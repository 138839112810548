import { EChangeStateLicences, EGetAllLicence } from '@gaming1/g1-requests';

export const getLicencesErrorMessages = {
  [EGetAllLicence.InternalError]: 'core:error.internalServer', // 0
  [EGetAllLicence.InvalidRoom]: 'core:error.invalidRoom', // 2
  [EGetAllLicence.InvalidPlayer]: 'core:error.invalidPlayer', // 3
};

export const changeLicencesErrorMessages = {
  [EChangeStateLicences.InternalError]: 'core:error.internalServer', // 0
  [EChangeStateLicences.InvalidRoom]: 'core:error.invalidRoom', // 2
  [EChangeStateLicences.InvalidPlayer]: 'core:error.invalidPlayer', // 3
  [EChangeStateLicences.NotLegalAge]: 'user:account.message.youAreTooYoung', // 4
};
