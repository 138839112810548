import styled, { css } from 'styled-components';

import { Gifts } from '@gaming1/g1-betting';
import {
  colors,
  device,
  elevation,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { Box, Text } from '@gaming1/g1-ui';

const AMOUNT_BORDER_RADIUS_IN_PX = 4;

const AMOUNT_CONTAINER_HEIGHT_IN_PX = 32;

type CardContainerProps = {
  isLocked: boolean;
};

export const CardContainer = styled(Box)<CardContainerProps>`
  ${elevation(1, { noShadow: true })};
  align-items: center;
  flex-direction: row;
  border-radius: ${AMOUNT_BORDER_RADIUS_IN_PX}px;
  padding: ${spaces('xs')} ${spaces('sm')};
  cursor: pointer;
  margin-bottom: ${spaces('sm')};
  border: 1px solid ${colors('backgrounds', { index: 1 })};

  ${({ isLocked }) =>
    isLocked &&
    css`
      padding: ${spaces('sm')} ${spaces('sm')};
    `}
  ${device.canHover} {
    &:hover {
      ${elevation(2)};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OpenDetailsText = styled(Text)`
  color: ${colors('link')};
  margin-top: ${spaces('xs')};
  font-size: ${fontSizes('md')};
`;

type AmountAvailableProps = {
  type: Gifts;
};
export const AmountAvailableBorder = styled.div<AmountAvailableProps>`
  position: relative;
  padding: 1px;
  border-radius: ${AMOUNT_BORDER_RADIUS_IN_PX}px;

  ${({ type }) =>
    type === 'freebet' &&
    css`
      background: linear-gradient(
        to right,
        ${colors('bettingFreebetPrimary')} 0%,
        ${colors('bettingFreebetSecondary')} 50%
      );
    `}
  ${({ type }) =>
    type === 'boostus' &&
    css`
      background: linear-gradient(
        to right,
        ${colors('bettingBoostusPrimary')} 0%,
        ${colors('bettingBoostusSecondary')} 50%
      );
    `}
`;

export const AmountAvailableBox = styled(Box)<AmountAvailableProps>`
  ${fontWeight('bold')};
  font-size: ${fontSizes('xl')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${AMOUNT_BORDER_RADIUS_IN_PX}px;
  padding: ${spaces('xs')} ${spaces('xs')};
  height: ${AMOUNT_CONTAINER_HEIGHT_IN_PX}px;

  ${({ type }) =>
    type !== 'promotion' &&
    css`
      ${elevation(1, { noShadow: true })};
    `}
`;

export const ValidityDate = styled(Text)`
  opacity: ${opacity('semi')};
`;
