import { normalize, schema } from 'normalizr';

import {
  FavoriteRecentGamesData,
  GetFavoriteRecentGamesResponse,
} from './types';

const favoriteRecentGames = new schema.Entity(
  'favoriteRecentGames',
  {},
  { idAttribute: 'Id' },
);

const favoriteRecentGameItems = new schema.Array(favoriteRecentGames);

favoriteRecentGames.define({
  List: favoriteRecentGameItems,
});

export const formatFavoriteRecentGameItems = (
  apiData: GetFavoriteRecentGamesResponse,
): FavoriteRecentGamesData => {
  const { List, ...meta } = apiData;
  const normalizedData = normalize(List, favoriteRecentGameItems);
  const entities =
    normalizedData.entities as FavoriteRecentGamesData['entities'];
  const { result } = normalizedData;
  return { entities, meta, result };
};
