import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  eventNameDetailsSelector,
  getScoreBoardTeamNamesSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box, useMediaBreakPoint } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../common/components/BettingIcon';
import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardFullTemplateColumn as TemplateColumn,
  ScoreBoardFullTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardFullTemplateItem as TemplateItem,
  ScoreBoardFullTemplateRow as TemplateRow,
  ScoreBoardFullTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the full scoreboard with only a name template */
export const ScoreBoardFullOneNameOnlyTemplate: VFC<
  ScoreBoardTemplateProps
> = ({ eventId, sportId }) => {
  const isDesktop = useMediaBreakPoint({ min: 'lg' });
  const { team1: name1, team2: name2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  const { team1: eventTeam1, team2: eventTeam2 } = useBettingGetterSelector({
    getterSelector: eventNameDetailsSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  const name = name1 || name2 || eventTeam1 || eventTeam2;

  return (
    <TemplateWrapper data-testid="scoreboard-one-name-only-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper justifyContent="center">
          <TemplateColumn gap="md" width="100%">
            <TemplateRow justifyContent="center">
              <Box data-testid="score-board-live-icon">
                <BettingIcon
                  id="ScoreBoardIconLive"
                  type="ScoreBoardIconLive"
                />
              </Box>
            </TemplateRow>

            <TemplateRow justifyContent="center">
              <TemplateItem overflow="hidden">
                <TemplateItem
                  flex={1}
                  fontSize={isDesktop ? 'xl' : 'md'}
                  textAlign="center"
                >
                  {name}
                </TemplateItem>
              </TemplateItem>
            </TemplateRow>
          </TemplateColumn>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
