import React, { FC, useContext } from 'react';

import {
  PrismicImageItem,
  PrismicImageSlice as PrismicImageType,
} from '@gaming1/g1-cms';
import { LayoutContext } from '@gaming1/g1-ui';

import { PrismicLink } from '../PrismicLink';

import { PrismicImageContainer, PrismicImg } from './styles';

export type PrismicImageProps = {
  /** Prismic 'Image' slice */
  slice: PrismicImageType;
  /** Test ID */
  testId?: string;
};

/**
 * This component renders a prismic `Image` slice
 * It renders a responsive image with optional link
 */
export const PrismicImage: FC<PrismicImageProps> = ({
  slice,
  testId = 'prismic-image-container',
}) => {
  const { media } = useContext(LayoutContext);

  let image = slice.primary.image as PrismicImageItem;
  if (media === 'xs' || media === 'sm') {
    image = slice.primary.image.Small;
  } else if (media === 'md') {
    image = slice.primary.image.Medium;
  } else if (media === 'lg') {
    image = slice.primary.image.Large;
  }

  const alt = image.alt || '';
  const url = image.url || '';
  const { width } = slice.primary;

  return (
    <PrismicImageContainer
      className="prismic-image-container"
      data-testid={testId}
      alignment={slice.primary.alignment?.toLowerCase()}
    >
      <PrismicLink
        link={slice.primary.link?.url}
        width={width}
        renderChildrenIfEmpty
        testId="prismic-link"
      >
        <PrismicImg
          src={url}
          alt={alt}
          imageWidth={!slice.primary.link?.url ? width : null}
          data-testid="prismic-image"
        />
      </PrismicLink>
    </PrismicImageContainer>
  );
};
