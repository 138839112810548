import React, { forwardRef } from 'react';
import { SpaceProps } from 'styled-system';

import { UserField } from '@gaming1/g1-core';
import { CodeInputField } from '@gaming1/g1-core-web';

export const PIN_CODE_LENGTH = 4;

export type PinCodeFieldProps = {
  /** Specifies if the pin code is required */
  required?: boolean;
} & SpaceProps;

export const PinCodeField = forwardRef<HTMLInputElement, PinCodeFieldProps>(
  ({ required, ...spaceProps }, ref) => (
    <CodeInputField
      codeLength={PIN_CODE_LENGTH}
      inputTestId="pin-code-input"
      name={UserField.pinCode}
      ref={ref}
      required={required}
      type="numeric"
      secret
      {...spaceProps}
    />
  ),
);
