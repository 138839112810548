/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:11:48 20-01-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { OptimalBetRequest, OptimalBetResponse } from '../entities';

export const getOptimalBet = (options: OptimalBetRequest) =>
  createRequest<OptimalBetResponse>(202, 'GetOptimalBet', options);
