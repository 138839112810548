import { darken } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, fontSizes, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const WRAPPER_MAX_HEIGHT = '67px';

export const EventLink = styled(Link)`
  text-decoration: none;
`;

export const SearchResultWrapper = styled(Box)`
  max-height: ${WRAPPER_MAX_HEIGHT};
  cursor: pointer;
`;

export const SearchResultRow = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

type SearchLabelProps = {
  isUppercase?: boolean;
};
export const SearchLabel = styled(Box)<SearchLabelProps>`
  background-color: ${colors('backgrounds', {
    index: 2,
  })};
  color: ${colors('text', { transform: darken(0.2) })};
  border-radius: ${spaces('sm')};
  font-size: ${fontSizes('sm')};
  padding: ${spaces('xxs')} ${spaces('xs')};

  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `};
`;
