import range from 'lodash/range';
import React, { FC, memo } from 'react';
import { shallowEqual } from 'react-redux';

import {
  EventState,
  NormalizedMarket,
  outcomeIdsByMarketSelector,
  SCMByIdSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { EventType as EEventType } from '@gaming1/g1-requests-betting';
import { Box } from '@gaming1/g1-ui';

import { SummaryComponentContainer } from '../EventSummary/styles';

import { OutcomeSummary } from './OutcomeSummary';

type MarketSummaryProps = {
  market: NormalizedMarket | undefined;
  sportId: number;
  eventType: EventState;
};

/**
 * Display the name of a market and its outcome
 */
const MarketSummaryComponent: FC<MarketSummaryProps> = ({
  market,
  sportId,
  eventType,
}) => {
  const outcomeIds = useBettingGetterSelector({
    getterSelector: outcomeIdsByMarketSelector,
    args: [market, sportId, eventType],
    equalityFn: shallowEqual,
  });

  const showcaseMarketId = market?.ShowcaseMarketId || '';

  const showcaseMarket = useBettingGetterSelector({
    getterSelector: SCMByIdSelector,
    args: [showcaseMarketId, sportId, EEventType.Live],
    equalityFn: shallowEqual,
  });

  const columns = showcaseMarket?.Columns;

  const nbOutcomes = outcomeIds?.length || 0;

  const getNoOutcome = (i: number) => (
    <OutcomeSummary id={0} eventId={0} marketId={0} key={i} />
  );

  if (!market) {
    return (
      <SummaryComponentContainer data-testid="market-summary">
        <Box flexDirection="row" width="100%" data-testid="no-market-summary">
          {range(nbOutcomes || 3).map((i: number) => getNoOutcome(i))}
        </Box>
      </SummaryComponentContainer>
    );
  }

  return (
    <SummaryComponentContainer data-testid="market-summary">
      <Box flexDirection="row" width="100%">
        {outcomeIds &&
          outcomeIds.map((outcomeId, i, array) =>
            outcomeId ? (
              <OutcomeSummary
                id={outcomeId}
                eventId={market.EventId}
                marketId={market.MarketId}
                key={outcomeId}
                name={columns?.[i]?.Name}
                totalOutcomes={array.length}
              />
            ) : (
              getNoOutcome(i)
            ),
          )}
      </Box>
    </SummaryComponentContainer>
  );
};

export const MarketSummary = memo(MarketSummaryComponent);
