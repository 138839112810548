import { Context } from '@datadog/browser-core';
import { createContext } from 'react';

import {
  ExtendedGTMEventProps,
  GoogleAnalyticsEvent,
  RawGTMData,
} from './types';

export type TrackingContextValue = {
  isDataDogEnabled: boolean;
  isGtmEnabled: boolean;
  pushDataDogAction: (name: string, context?: Context) => void;
  pushDataDogContext: (key: string, value?: Context) => void;
  pushDataDogError: (error: unknown, context?: Context) => void;
  pushEvent: (eventProps: GoogleAnalyticsEvent | ExtendedGTMEventProps) => void;
  pushRawData: (rawData: RawGTMData) => void;
};

export const TrackingContext = createContext<TrackingContextValue>({
  isDataDogEnabled: false,
  isGtmEnabled: false,
  pushDataDogAction: () => undefined,
  pushDataDogContext: () => undefined,
  pushDataDogError: () => undefined,
  pushEvent: () => undefined,
  pushRawData: () => undefined,
});
