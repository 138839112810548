/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:21:10 10-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetBettingHistoryRequest,
  GetBettingHistoryResponse,
  GetBettingSlipSystemDetailRequest,
  GetBettingSlipSystemDetailResponse,
} from '../entities';

export const getBettingHistory = (options: GetBettingHistoryRequest) =>
  createRequest<GetBettingHistoryResponse>(202, 'GetBettingHistory', options);

export const getBettingSlipSystemDetail = (
  options: GetBettingSlipSystemDetailRequest,
) =>
  createRequest<GetBettingSlipSystemDetailResponse>(
    202,
    'GetBettingSlipSystemDetail',
    options,
  );
