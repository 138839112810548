import styled from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { RatioContainer } from '@gaming1/g1-ui';

import { Image } from '../Image';

export type PlaceHolderContainerProps = {
  dark?: boolean;
};
export type PlaceHolderProps = {
  dark?: boolean;
  image: string;
};

export const PlaceholderContainer = styled(
  RatioContainer,
)<PlaceHolderContainerProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ dark, theme }) =>
    dark ? theme.colors.backgroundDark : theme.colors.backgroundLight};
`;

export const PlaceholderImage = styled(Image)`
  object-fit: contain;
  height: calc(100% - ${spaces('md')});
  width: calc(100% - ${spaces('md')});
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
