import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ConfigContext, License } from '@gaming1/g1-config';
import { userSearchTermSelector } from '@gaming1/g1-core';
import {
  SearchCategoryResult,
  SearchResultDisplay,
  useRoutePath,
  useTracking,
} from '@gaming1/g1-core-web';
import {
  ApplicationWithGamingState,
  GameRange,
  SEARCH_GAMES_DEFAULT_PAGE_NUMBER,
  SEARCH_GAMES_DEFAULT_PAGE_SIZE,
  searchGamesRequestStateSelector,
  searchGamesSelector,
} from '@gaming1/g1-gaming';
import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import { RemoteData, RequestState } from '@gaming1/g1-utils';

import { GameCard } from '../../../gameList/components/GameCard';
import { gamingRoutes } from '../../../routes';
import { SEARCH_GAMES_TERM_PARAMETER } from '../constants';

import { GameLink } from './styles';

export const NUMBER_OF_GAMES_DISPLAYED = 5;

type GamingSearchResultProps = {
  /** The setter used to know which failure term to display or if we have some results to display (null) */
  onTermFailure: (term: string | null) => void;
  onRequestFailure: (requestState: RequestState | null) => void;
};

/** Render a gaming result that will display an image, the game's title and the possibility to redirect to the game page */
export const GamingSearchResult: FC<GamingSearchResultProps> = ({
  onTermFailure,
  onRequestFailure,
}) => {
  const { t } = useTranslation('gaming');
  const { core } = useContext(ConfigContext);
  const getGamingRoutePath = useRoutePath(gamingRoutes);

  const userSearchTerm = useSelector(userSearchTermSelector);

  const request = useMemo(
    () => ({
      GameRange: GameRange.LOBBYALL,
      NameFilter: userSearchTerm,
      PageNumber: SEARCH_GAMES_DEFAULT_PAGE_NUMBER,
      PageSize: SEARCH_GAMES_DEFAULT_PAGE_SIZE,
    }),
    [userSearchTerm],
  );

  const hasLicenses =
    core.licenses?.includes(License.A) && core.licenses?.includes(License.B);

  const searchGames = useSelector(searchGamesSelector)(request);
  const searchGamesA = searchGames.filter((g) => g.GameClass === License.A);
  const searchGamesB = searchGames.filter((g) => g.GameClass === License.B);

  const searchGamesCount = hasLicenses
    ? [...searchGamesA, ...searchGamesB].length
    : searchGames.length;
  const hasAtLeastOneResult = searchGamesCount > 0;

  const requestState: RequestState = useRequestState(
    (state: ApplicationWithGamingState) =>
      searchGamesRequestStateSelector(state)(request),
  );

  useEffect(() => {
    if (requestState.status === RemoteData.Success && !hasAtLeastOneResult) {
      onTermFailure(userSearchTerm);
    }
  }, [requestState, hasAtLeastOneResult, onTermFailure, userSearchTerm]);

  useEffect(() => {
    if (requestState.status === RemoteData.Error) {
      onRequestFailure(requestState);
    } else {
      onRequestFailure(null);
    }
  }, [requestState, onRequestFailure]);

  useEffect(() => {
    if (userSearchTerm.length > 1 && hasAtLeastOneResult) {
      onTermFailure(null);
    }
  }, [hasAtLeastOneResult, onTermFailure, userSearchTerm.length]);

  const { pushEvent } = useTracking();
  useEffect(() => {
    if (
      requestState.status === RemoteData.Success &&
      userSearchTerm.length > 1
    ) {
      // Tracking: search response
      pushEvent({
        name: 'search',
        action: 'response',
        category: 'gaming',
        label: 'drawer',
        value: userSearchTerm,
        nbGames: searchGamesCount,
      });
    }
  }, [requestState, pushEvent, searchGamesCount, userSearchTerm]);

  const onGameClicked = useCallback(
    (gameName: string) => {
      // Tracking: search item clicked
      pushEvent({
        name: 'search',
        action: 'game clicked',
        category: 'gaming',
        label: 'drawer',
        value: gameName,
        searchTerm: userSearchTerm,
      });
    },
    [pushEvent, userSearchTerm],
  );

  if (userSearchTerm.length > 1 && hasAtLeastOneResult) {
    if (hasLicenses) {
      return (
        <>
          {searchGamesA.length > 0 ? (
            <SearchCategoryResult
              testId="search-casino-result-wrapper"
              categoryName={t('search.casinoCategory')}
              numberOfResults={searchGamesA.length}
              allResultsPageUrl={
                searchGamesA.length > NUMBER_OF_GAMES_DISPLAYED
                  ? getGamingRoutePath('searchCasinoResults', undefined, {
                      [SEARCH_GAMES_TERM_PARAMETER]: userSearchTerm,
                    })
                  : undefined
              }
            >
              {searchGamesA.slice(0, NUMBER_OF_GAMES_DISPLAYED).map((game) => (
                <GameLink
                  key={game.Id}
                  to={getGamingRoutePath('playGame', { gameId: game.Id })}
                >
                  <SearchResultDisplay
                    imageComponent={<GameCard game={game} />}
                    title={game.Name}
                    matchingTerms={[userSearchTerm]}
                    onClick={onGameClicked}
                  />
                </GameLink>
              ))}
            </SearchCategoryResult>
          ) : null}
          {searchGamesB.length > 0 ? (
            <SearchCategoryResult
              testId="search-dice-result-wrapper"
              categoryName={t('search.dicesCategory')}
              numberOfResults={searchGamesB.length}
              allResultsPageUrl={
                searchGamesB.length > NUMBER_OF_GAMES_DISPLAYED
                  ? getGamingRoutePath('searchDiceResults', undefined, {
                      [SEARCH_GAMES_TERM_PARAMETER]: userSearchTerm,
                    })
                  : undefined
              }
            >
              {searchGamesB.slice(0, NUMBER_OF_GAMES_DISPLAYED).map((game) => (
                <GameLink
                  key={game.Id}
                  to={getGamingRoutePath('playGame', { gameId: game.Id })}
                >
                  <SearchResultDisplay
                    key={game.Id}
                    imageComponent={<GameCard game={game} />}
                    title={game.Name}
                    matchingTerms={[userSearchTerm]}
                    onClick={onGameClicked}
                  />
                </GameLink>
              ))}
            </SearchCategoryResult>
          ) : null}
        </>
      );
    }

    return (
      <SearchCategoryResult
        testId="search-games-result-wrapper"
        categoryName={t('search.gamesCategory')}
        numberOfResults={searchGames.length}
        allResultsPageUrl={
          searchGames.length > NUMBER_OF_GAMES_DISPLAYED
            ? getGamingRoutePath('searchResults', undefined, {
                [SEARCH_GAMES_TERM_PARAMETER]: userSearchTerm,
              })
            : undefined
        }
      >
        {searchGames.slice(0, NUMBER_OF_GAMES_DISPLAYED).map((game) => (
          <GameLink
            key={game.Id}
            to={getGamingRoutePath('playGame', { gameId: game.Id })}
          >
            <SearchResultDisplay
              key={game.Id}
              imageComponent={<GameCard game={game} />}
              title={game.Name}
              matchingTerms={[userSearchTerm]}
              onClick={onGameClicked}
            />
          </GameLink>
        ))}
      </SearchCategoryResult>
    );
  }

  return null;
};
