import styled, { css } from 'styled-components';

import {
  colors,
  elevation,
  fontSizes,
  fontWeight,
  media,
  spaces,
} from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const SEARCH_RESULT_MIN_HEIGHT_IN_PX = '40px';
const SEARCH_RESULT_MAX_HEIGHT_IN_PX = '67px';
const SEARCH_RESULT_MAX_WIDTH_IN_PX = '112px';
const DEFAULT_ELEVATION_LEVEL = 2;

type SearchResultWrapperProps = {
  elevationLevel?: ElevationLevels;
  isNormalLayout: boolean;
};

type ElevationLevels = 0 | 1 | 2 | 3 | 4;

export const SearchResultDisplayContainer = styled.div`
  gap: 1px;
  display: grid;

  width: 100%;

  ${media.md} {
    grid-template-columns: repeat(auto-fill, minmax(max(250px, 100% / 4), 1fr));
  }
`;

export const SearchResultWrapper = styled(Box)<SearchResultWrapperProps>`
  min-height: ${SEARCH_RESULT_MIN_HEIGHT_IN_PX};
  max-height: ${SEARCH_RESULT_MAX_HEIGHT_IN_PX};
  cursor: pointer;

  padding: ${spaces('xs')} ${spaces('sm')};

  width: 100%;

  ${({ isNormalLayout }) =>
    isNormalLayout &&
    css`
      padding: ${spaces('xs')} 0;
      margin-bottom: ${spaces('xs')};
      min-height: initial;
    `}

  ${({ isNormalLayout, elevationLevel }) =>
    !isNormalLayout &&
    elevation(elevationLevel ?? DEFAULT_ELEVATION_LEVEL, {
      noShadow: true,
    })}
`;

export const SearchResultImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: ${SEARCH_RESULT_MAX_HEIGHT_IN_PX};
  max-width: ${SEARCH_RESULT_MAX_WIDTH_IN_PX};
  margin-right: ${spaces('xs')};
`;

export const SearchResultTitle = styled(Box)`
  ${fontWeight('bold')};
  background-color: ${colors('backgrounds', { index: 1 })};
  padding: ${spaces('sm')};
  font-size: ${fontSizes(1)};
  width: 100%;
  margin-bottom: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EllipsisBox = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
