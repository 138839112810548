import format from 'date-fns/format';
import React, { FC, useContext } from 'react';

import { GroupConditionType, RangeConditionType } from '@gaming1/g1-betting';
import { ConfigContext } from '@gaming1/g1-config';
import { useDateLocale, useFormatMoney } from '@gaming1/g1-core';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { Table, Text } from '@gaming1/g1-ui';

import { formatOdd } from '../../../helpers';
import { BettingMenuContext } from '../../BettingMenuContext';
import {
  ERangeFormatType,
  getGroupConditionKey,
  getRangeConditionKey,
} from '../../helpers';

import { StyledTableBody, TableWrapper } from './styles';

type ConditionsTableProps = {
  /** The groupConditions needed to display the conditions in the render, if there are any */
  groupConditions?: GroupConditionType[];
  /** The rangeConditions needed to display the conditions in the render, if there are any */
  rangeConditions?: RangeConditionType[];
  /** The expirationDate to display within the conditions if there are any, or just simply display the expiration alone if no conditions */
  expirationDate?: string;
};

/** Render a table of conditions that will be retrieved from the groupConditions and rangeConditions (through the props) + the expiration date.
 *  The conditions have a header and then a description just below.
 */
export const ConditionsTable: FC<ConditionsTableProps> = ({
  groupConditions,
  rangeConditions,
  expirationDate,
}) => {
  const { t } = useTranslation('betting');
  const { i18n } = useContext(ConfigContext);
  const dateLocale = useDateLocale();
  const formatMoney = useFormatMoney();

  const { oddUnit } = useContext(BettingMenuContext);

  if (!groupConditions && !rangeConditions && expirationDate) {
    return (
      <Text mt="none" p="xs" fontSize="md">
        <Trans
          i18nKey="gifts.conditions.expirationDate.text"
          ns="betting"
          values={{
            expirationDate: format(
              new Date(expirationDate),
              i18n.dateShortFormat,
            ),
            expirationHour: format(
              new Date(expirationDate),
              i18n.timePartialFormat,
            ),
          }}
        />
      </Text>
    );
  }

  return (
    <TableWrapper data-testid="conditions">
      <Table isAlternate hasCellBorders={false}>
        <StyledTableBody>
          {expirationDate && (
            <tr>
              <td>
                <Text fontWeight="bold" mt="none">
                  {t('gifts.conditions.expirationDate.header')}
                </Text>
              </td>
              <td>
                <Text my="xxs">
                  <Trans
                    i18nKey="gifts.conditions.expirationDate.description"
                    ns="betting"
                    values={{
                      expirationDate: format(
                        new Date(expirationDate),
                        i18n.dateShortFormat,
                        {
                          locale: dateLocale,
                        },
                      ),
                      expirationHour: format(
                        new Date(expirationDate),
                        i18n.timePartialFormat,
                      ),
                    }}
                  />
                </Text>
              </td>
            </tr>
          )}

          {groupConditions &&
            groupConditions.map((groupCondition) => {
              if (groupCondition) {
                const conditionOutput = getGroupConditionKey(groupCondition);
                if (conditionOutput) {
                  return (
                    <tr key={groupCondition.Type}>
                      <td>
                        <Text fontWeight="bold" mt="none">
                          {t(conditionOutput.header)}
                        </Text>
                      </td>
                      <td>
                        <Text my="xxs">{conditionOutput.description}</Text>
                      </td>
                    </tr>
                  );
                }
                return null;
              }
              return null;
            })}

          {rangeConditions &&
            rangeConditions.map((rangeCondition) => {
              if (!rangeCondition) {
                return null;
              }

              const conditionOutput = getRangeConditionKey(rangeCondition);

              if (!conditionOutput) {
                return null;
              }

              const { Min, Max } = rangeCondition;

              const formatRange = (data: number | undefined) => {
                switch (conditionOutput.type) {
                  case ERangeFormatType.Money:
                    return formatMoney(Number(data));
                  case ERangeFormatType.Odd:
                    return data ? formatOdd(data, oddUnit) : data;
                  case ERangeFormatType.Number:
                  default:
                    return data;
                }
              };

              return (
                <tr key={rangeCondition.Type}>
                  <td>
                    <Text fontWeight="bold" mt="none">
                      {t(conditionOutput.header)}
                    </Text>
                  </td>
                  <td>
                    {conditionOutput.description && (
                      <Text my="xxs">
                        {t(conditionOutput.description, {
                          Min: formatRange(Min),
                          Max: formatRange(Max),
                        })}
                      </Text>
                    )}
                  </td>
                </tr>
              );
            })}
        </StyledTableBody>
      </Table>
    </TableWrapper>
  );
};
