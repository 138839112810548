/**
 * A route with a unique path and an optional list of parameters needing
 * to be replaced
 */
export type RouteObject<Parameters extends string | null = null> =
  Parameters extends string
    ? {
        parameters: { [k in Parameters]: string };
        path: string;
      }
    : {
        parameters: undefined;
        path: string;
      };

export type RouteParams<Route extends RouteObject<string> | RouteObject<null>> =
  null extends Route['parameters'] ? null : Route['parameters'];

/**
 * Create a simple RouteObject (without any parameters)
 * @param path the path to the route
 */
export const createRoute = (path: string): RouteObject<null> => ({
  parameters: undefined,
  path,
});

/**
 * Create a RouteObject with parameters
 * @param path the path to the route
 * @param parameters the shape of the required parameters
 *
 * @example
 * // Typing parameters with inference (Params = { username: string})
 * const route = createParamRoute('/profile/:username', { username: '' })
 *
 * @example
 * // Typing parameters with explicit typing (Params = { view: 'day' | 'week' | 'month'})
 * const route = createParamRoute<{
 *  view: 'day' | 'week' | 'month'
 * }>('/calendar/:view', { view: 'day' })
 */
export const createParamRoute = <Params extends Record<string, string>>(
  path: string,
  parameters: Params,
) => ({
  path,
  parameters,
});

/**
 * A flat object containing a list of routes with an unique index, each with a
 * path property
 */
export type RouteList = {
  [k: string]: RouteObject<string> | RouteObject<null>;
};
