import React, { FC, LabelHTMLAttributes } from 'react';

import { AppLabel } from './styles';

export type LabelProps = Omit<
  LabelHTMLAttributes<HTMLLabelElement>,
  'htmlFor'
> & {
  /** id of the input it is linked to */
  htmlFor: string | null;
};

/** Custom select input */
export const Label: FC<LabelProps> = ({ htmlFor, ...rest }) => (
  <AppLabel htmlFor={String(htmlFor) || undefined} {...rest} />
);

export { AppLabel };
