import { ApplicationState } from '../types';

/* Login */

export const userLoginRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.login;

export const userSessionIdSelector = (state: ApplicationState) =>
  state.core.login.sessionId;

/**
 * Return the exclusion date returned by the login handler in case an
 * excluded user attempted to login
 */
export const userExclusionDateSelector = (state: ApplicationState) =>
  state.core.login.requests.login?.exclusionDate;

/* Logout */

export const userLogoutRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.logout;

/* Impersonation */
export const impersonationRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.impersonate;
