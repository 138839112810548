import { createAction } from 'typesafe-actions';

import {
  BettingSlipIdType,
  BettingSlipStringType,
} from '../../common/store/types';

import { BoostusToBSSelectionState } from './types/boostusConditionsForBettingSlip';
import { BettingSlipOutcomeOddUpdate } from './types/common';
import { FreebetToBSSelection } from './types/freebetConditionsForBettingSlip';
import { InitBettingSlip } from './types/import';
import { PromotionToBSSelectionState } from './types/promotionsForBettingSlip';
import { BettingSlipOutcomeSelection } from './types/store';

/**
 * Get the betting slip action
 */

export const addBettingSlipOutcome = createAction(
  'betting/add_bettingslip_outcome',
  (item: BettingSlipOutcomeSelection) => item,
)();

export const removeBettingSlipOutcome = createAction(
  'betting/remove_bettingslip_outcome',
  (item: BettingSlipOutcomeSelection) => item,
)();

export const toggleBettingSlipOutcome = createAction(
  'betting/toggle_bettingslip_outcome',
  (item: BettingSlipOutcomeSelection) => item,
)();

export const toggleCheckItem = createAction(
  'betting/toggle_bettingslip_item_check',
  (itemId: number, bettingSlipId: string) => ({ bettingSlipId, itemId }),
)();

/**
 * Recover session betting slip action
 */
export const initBettingSlipBySession = createAction(
  'betting/init_bettingslip',
  (data: InitBettingSlip) => ({
    bettingSlip: data.bettingSlip,
    sportbook: {
      events: data.events,
      markets: data.markets,
      outcomes: data.outcomes,
    },
  }),
)();

export const initBettingSlipFast = createAction(
  'betting/init_bettingslip',
  (data: InitBettingSlip) => ({
    bettingSlip: data.bettingSlip,
    sportbook: {
      events: data.events,
      markets: data.markets,
      outcomes: data.outcomes,
    },
  }),
)();

export const updateStake = createAction(
  'betting/update_stake',
  (data: { stake: string; bettingSlipId: string }) => data,
)();

export const updateOddChangeForItem = createAction(
  'betting/update_odd_change_item',
  (
    change: BettingSlipOutcomeOddUpdate,
    itemId: number,
    bettingSlipId: string,
  ) => ({
    change,
    itemId,
    bettingSlipId,
  }),
)();

export const resetOddChange = createAction(
  'betting/update_reset_odd_change',
  (bettingSlipId: string) => ({
    bettingSlipId,
  }),
)();

/**
 * The property {itemId} being the id of the rank, as an example "SYSTEM_COMBI".
 */
export const updateStakeForRank = createAction(
  'betting/update_stake_rank',
  (data: { stake: string; rankId: string; bettingSlipId: string }) => data,
)();

export const changeBettingSlipType = createAction(
  'betting/change_bettingslip_type',
  (data: { type: BettingSlipStringType; bettingSlipId: string }) => data,
)();

export const cleanBettingSlip = createAction(
  'betting/clean_bettingslip',
  (bettingSlipId: string) => ({
    bettingSlipId,
  }),
)();

/**
 * freebet action for BS
 */
export const addFreebetToBS = createAction(
  'betting/add_freebet_bs',
  (data: FreebetToBSSelection) => data,
)();
export const removeFreebetToBS = createAction(
  'betting/remove_freebet_bs',
  (data: BettingSlipIdType) => data,
)();

/**
 * boostus action for BS
 */
export const addBoostusToBS = createAction(
  'betting/add_boostus_bs',
  (data: BoostusToBSSelectionState & BettingSlipIdType) => data,
)();
export const removeBoostusToBS = createAction(
  'betting/remove_boostus_bs',
  (data: BettingSlipIdType) => data,
)();

/**
 * promotion action for BS
 */

export const addPromotionToBS = createAction(
  'betting/add_promotion_bs',
  (data: PromotionToBSSelectionState & BettingSlipIdType) => data,
)();
export const removePromotionToBS = createAction(
  'betting/remove_promotion_bs',
  (data: BettingSlipIdType) => data,
)();
