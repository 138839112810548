import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';
import { Box, SimpleButton, Text } from '@gaming1/g1-ui';

const DRAWER_BORDER_RADIUS_IN_PX = 10;

export const OffersAvailableDrawerContainer = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: ${colors('backgrounds', { index: 0 })};
  border-top-left-radius: ${DRAWER_BORDER_RADIUS_IN_PX}px;
  border-top-right-radius: ${DRAWER_BORDER_RADIUS_IN_PX}px;
`;

export const OffersAvailableDrawerTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${spaces('sm')} ${spaces('sm')} 0 ${spaces('sm')};
`;

export const OffersAvailableDrawerSubTitleContainer = styled(Text)`
  margin-bottom: ${spaces('sm')};
  margin-top: 0;
  color: ${colors('neutrals', { index: 2 })};
`;

export const CloseButton = styled(SimpleButton)``;

export const OffersAvailableCardsContainer = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
`;
