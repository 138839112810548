import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { changePassword } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import { changePasswordResponse } from './codecs';
import { changePasswordErrorMessages } from './errorMessages';

/** Change the user password */
export const changePasswordEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.changePassword.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(changePassword(payload)).pipe(
        mapGuard(changePasswordResponse),
        map(actions.changePassword.success),
        catchError((err) =>
          of(
            actions.changePassword.failure(
              createFailurePayload(err, changePasswordErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
