import React, { FC, memo } from 'react';

import {
  isOutcomeAvailableSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Icon } from '@gaming1/g1-ui';

import { formatOutcomeName } from '../../../helpers';
import { useOutcome } from '../../hooks';
import { OutcomeOdd } from '../EventDetails/styles';
import { OutcomeBaseOutcomeSummary } from '../EventSummary/styles';

import {
  EllipsisText,
  OutcomeSummaryButton,
  OutcomeSummaryContent,
} from './styles';

type OutComeSummaryProps = {
  /** The id of the outcome */
  id: number;
  /** The id of the market where the outcome belong */
  marketId: number;
  /** The id of the event linked to the outcome market */
  eventId: number;
  /** outcome name */
  name?: string;
  totalOutcomes?: number;
};

/**
 * Display an outcome button with its name and odd
 */
const OutcomeSummaryComponent: FC<OutComeSummaryProps> = ({
  eventId,
  marketId,
  id,
  name,
  totalOutcomes,
}) => {
  const {
    outcome,
    animationClass,
    formattedOdd,
    handleOutcomeClick,
    isInBettingSlip,
  } = useOutcome({ eventId, marketId, outcomeId: id });

  const isOutcomeAvailable = useBettingGetterSelector({
    getterSelector: isOutcomeAvailableSelector,
    args: [id],
  });

  const content = isOutcomeAvailable ? (
    <OutcomeSummaryContent>
      <OutcomeBaseOutcomeSummary>
        <EllipsisText>{name || outcome?.Name}</EllipsisText>
      </OutcomeBaseOutcomeSummary>
      <OutcomeOdd>{formattedOdd}</OutcomeOdd>
    </OutcomeSummaryContent>
  ) : (
    !!marketId && <Icon type="LockClosed" id="locked-outcome" />
  );

  return (
    <OutcomeSummaryButton
      data-testid={`outcome-summary-${id}`}
      isActive={isInBettingSlip}
      onClick={(e) => {
        e.preventDefault();
        handleOutcomeClick();
      }}
      title={!outcome ? '' : formatOutcomeName(outcome.Name, outcome.Base)}
      className={animationClass}
      isLocked={!isOutcomeAvailable}
      totalOutcomes={totalOutcomes}
    >
      {content}
    </OutcomeSummaryButton>
  );
};

export const OutcomeSummary = memo(OutcomeSummaryComponent);
