import styled, { css } from 'styled-components';
import { color, fontSize, space, width } from 'styled-system';

import { colors, fontSizes, spaces, Theme } from '@gaming1/g1-style';

import { Box } from '../Box';
import { StyledSystemProps } from '../types';

export const InputWrapperDiv = styled(Box)<StyledSystemProps>`
  position: relative;

  margin-top: ${spaces('sm')};

  ${space}
  ${width}
  ${fontSize}
  ${color}
`;

type ErrorDivProps = {
  /** If we need to have a background */
  hasBackground?: boolean;
  /**
   * Color of the highlight used when the input is on error.
   * Default: danger
   */
  highlightColor: keyof Theme['colors'];
};

export const ErrorDiv = styled.div<ErrorDivProps>`
  font-size: ${fontSizes('sm')};
  padding-top: ${spaces('xs')};
  display: flex;
  align-items: center;

  ${({ hasBackground, highlightColor }) =>
    hasBackground
      ? css`
          background-color: ${colors(highlightColor)};
          color: ${color('textLight')};
          padding: ${spaces('xs')};
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        `
      : css`
          color: ${colors(highlightColor)};
          svg path {
            fill: ${colors(highlightColor)};
          }
        `}
`;

type InfoDivProps = {
  /** If we need to have a background */
  hasBackground?: boolean;
};

export const InfoDiv = styled.div<InfoDivProps>`
  font-size: ${fontSizes('sm')};
  padding-top: ${spaces('xs')};
  display: flex;
  align-items: center;

  ${({ hasBackground }) =>
    hasBackground
      ? css`
          background-color: ${colors('info')};
          color: ${color('textLight')};
          padding: ${spaces('xs')};
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        `
      : css`
          color: ${colors('info')};
        `}
`;
