import isEqual from 'lodash/isEqual';
import React, { FC, memo, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  eventsByLeagueIdSelector,
  getSportbookKey,
  NavLeagueType,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box, SimpleButton, Text } from '@gaming1/g1-ui';

import { ColoredIcon } from '../../../common/components/ColoredIcon';
import { MenuItem } from '../../../common/components/MenuItem';
import { cleanParam } from '../../../helpers';
import { useRouteConfig } from '../../../routeurHooks';

import { EventLiveMenuItem } from './EventLiveMenuItem';
import { EllipsisBox } from './styles';

type LeagueLiveMenuItemProps = {
  /** League item */
  item: NavLeagueType & { RegionName: string };
};

const LIVE_MENU_ITEM_HEIGHT_IN_PX = 50;

const LeagueLiveMenuItemComponent: FC<LeagueLiveMenuItemProps> = ({ item }) => {
  const theme = useTheme();
  const key = getSportbookKey('live');
  const location = useLocation();
  const routeConfig = useRouteConfig();

  const testId = `league-live-menu-toggle-${item.Id}`;

  const eventIds = useBettingGetterSelector({
    getterSelector: eventsByLeagueIdSelector,
    args: [key, item.Id],
    equalityFn: isEqual,
  });

  const { leagueId } = useMemo(() => {
    const match = matchPath<{
      leagueId?: string;
    }>(location.pathname, {
      path: Object.values(routeConfig).map((route) => route.path),
      exact: true,
    });
    if (!match) {
      return {
        params: {
          leagueId: null,
        },
      };
    }
    return {
      leagueId: cleanParam(match.params.leagueId?.split('/')[0]),
    };
  }, [routeConfig, location.pathname]);

  const [isLeagueCollapsed, setIsLeagueCollapsed] = useState(
    leagueId !== item.Id.toString(),
  );
  return (
    <>
      <SimpleButton
        onClick={() => {
          setIsLeagueCollapsed(!isLeagueCollapsed);
        }}
        data-testid={testId}
      >
        <MenuItem
          key={item.Id}
          elevationLevel={2}
          mb="1px"
          hasNoShadow
          minHeight={`${LIVE_MENU_ITEM_HEIGHT_IN_PX}px`}
        >
          <MenuItem.Left isActive={!isLeagueCollapsed} mr="sm">
            <ColoredIcon
              ml="xxxs"
              mr="xxxs"
              color={
                !isLeagueCollapsed
                  ? theme.colors.neutrals[0]
                  : theme.colors.neutrals[3]
              }
              type={!isLeagueCollapsed ? 'AngleUp' : 'AngleDown'}
              id={
                !isLeagueCollapsed
                  ? 'sport-menu-item-active'
                  : 'sport-menu-item-not-active'
              }
            />
          </MenuItem.Left>
          <MenuItem.Content padding={0} minWidth={0} mr="sm">
            <EllipsisBox flexDirection="column">
              <Text mt="0" ellipsis>
                {item.Name}
              </Text>
              <Box opacity="0.5">{item.RegionName}</Box>
            </EllipsisBox>
          </MenuItem.Content>
        </MenuItem>
      </SimpleButton>
      {!isLeagueCollapsed &&
        eventIds.map((eventId) => (
          <EventLiveMenuItem
            key={eventId}
            sportId={item.SportId}
            eventId={eventId}
            regionId={item.RegionId}
          />
        ))}
    </>
  );
};

export const LeagueLiveMenuItem = memo(LeagueLiveMenuItemComponent);
