import styled from 'styled-components';
import {
  alignContent,
  alignItems,
  flexDirection,
  justifyContent,
  justifyItems,
  order,
} from 'styled-system';

import { media } from '@gaming1/g1-style';

import { ColProps, ColSystemProps } from './types';

export type ColDivProps = Required<ColProps> & ColSystemProps;

export const ColDiv = styled.div<ColDivProps>`
  padding: 0 ${(props) => (props.noGutter ? 0 : props.theme.sizes.gutter)}px;
  position: relative;
  flex-grow: ${(props) => props.grow};
  flex-shrink: ${(props) => props.shrink};
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  max-width: ${(props) => (props.xs / 12) * 100}%;
  flex-basis: ${(props) => (props.xs / 12) * 100}%;
  display: ${(props) => (props.xs === 0 ? 'none' : 'flex')};

  ${order}
  ${flexDirection}
  ${alignContent}
  ${alignItems}
  ${justifyContent}
  ${justifyItems}

  ${media.sm} {
    max-width: ${(props) => (props.sm / 12) * 100}%;
    flex-basis: ${(props) => (props.sm / 12) * 100}%;
    display: ${(props) => (props.sm === 0 ? 'none' : 'flex')};
  }
  ${media.md} {
    max-width: ${(props) => (props.md / 12) * 100}%;
    flex-basis: ${(props) => (props.md / 12) * 100}%;
    display: ${(props) => (props.md === 0 ? 'none' : 'flex')};
  }
  ${media.lg} {
    max-width: ${(props) => (props.lg / 12) * 100}%;
    flex-basis: ${(props) => (props.lg / 12) * 100}%;
    display: ${(props) => (props.lg === 0 ? 'none' : 'flex')};
  }
  ${media.xl} {
    max-width: ${(props) => (props.xl / 12) * 100}%;
    flex-basis: ${(props) => (props.xl / 12) * 100}%;
    display: ${(props) => (props.xl === 0 ? 'none' : 'flex')};
  }
  ${media.xxl} {
    max-width: ${(props) => (props.xxl / 12) * 100}%;
    flex-basis: ${(props) => (props.xxl / 12) * 100}%;
    display: ${(props) => (props.xxl === 0 ? 'none' : 'flex')};
  }
`;
