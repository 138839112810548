import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { actions as coreActions } from '@gaming1/g1-core';
import {
  changeSecurityPinCode,
  getSecurityPinCodeInfo,
  resetSecurityPinCode,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { UserEpic } from '../../store/types';

import * as actions from './actions';
import {
  changeSecurityPinCodeResponse,
  getSecurityPinCodeInfoResponse,
  resetSecurityPinCodeResponse,
} from './codecs';
import { changeSecurityPinCodeErrorMessages } from './errorMessages';

/** Get security pin code info */
export const getSecurityPinCodeInfoEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getSecurityPinCodeInfo.request)),
    switchMap(() =>
      wsAdapter.request(getSecurityPinCodeInfo({})).pipe(
        mapGuard(getSecurityPinCodeInfoResponse),
        map(actions.getSecurityPinCodeInfo.success),
        catchError((err) =>
          of(actions.getSecurityPinCodeInfo.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/** Reset security pin code */
export const resetSecurityPinCodeEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.resetSecurityPinCode.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(resetSecurityPinCode(payload)).pipe(
        mapGuard(resetSecurityPinCodeResponse),
        map(actions.resetSecurityPinCode.success),
        catchError((err) =>
          of(actions.resetSecurityPinCode.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/** Change security pin code */
export const changeSecurityPinCodeEpic: UserEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.changeSecurityPinCode.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(changeSecurityPinCode(payload)).pipe(
        mapGuard(changeSecurityPinCodeResponse),
        map(actions.changeSecurityPinCode.success),
        catchError((err) =>
          of(
            actions.changeSecurityPinCode.failure(
              createFailurePayload(err, changeSecurityPinCodeErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** After a successful authentication, we get the pin code information */
export const successAuthToGetSecurityPinCodeInfoEpic: UserEpic = (
  action$,
  _,
  { config$ },
) =>
  action$.pipe(
    filter(isActionOf(coreActions.loggedInUser)),
    withLatestFrom(config$),
    filter(([, config]) => !!config.user.pinCode),
    mapTo(actions.getSecurityPinCodeInfo.request()),
  );
