import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  BettingActivity,
  NotificationCode,
  NotificationLevel,
  placeBet,
  placeSystemBet,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  curriedHasNonNullableProperty,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import {
  formatPlaceBetPreparedRequest,
  formatPlaceSystemBetPreparedRequest,
} from '../../bettingSlip/helpers/placeBet';
import { helperBettingSlipIsValid } from '../../bettingSlip/helpers/validators';
import {
  placeBetResponseCodec,
  placeSystemBetResponseCodec,
} from '../../bettingSlip/store/codecs/placeBet';
import {
  bettingSlipActivityGetterSelector,
  bettingSlipTypeGetterSelector,
  freebetSelectedForBSGetterSelector,
  getFreebetForBSGetterSelector,
} from '../../bettingSlip/store/selectors';
import { bettingSlipItemsForValidationGetterSelector } from '../../bettingSlip/store/selectors/elements/items';
import {
  placeBetPrepareCombiGetterSelector,
  placeBetPrepareManualGetterSelector,
  placeBetPrepareSingleGetterSelector,
  placeBetPrepareSystemGetterSelector,
} from '../../bettingSlip/store/selectors/placeBet/preparePlaceBet';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import { getBetPipeIdentifierFromActionPayload } from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

const onTimeOut = {
  retryOnTimeout: false,
  timeoutAfterMs: 300000 /* 5 min */,
};

export const placeBetEpic: BettingEpic = (actions$, state$, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.placeBet.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const bettingSlipType =
        payload.bettingSlipType ??
        bettingSlipTypeGetterSelector(state)(payload.bettingSlipId);
      const bettingSlipActivity = bettingSlipActivityGetterSelector(state)(
        payload.bettingSlipId,
      );

      if (bettingSlipActivity === BettingActivity.Manual) {
        return {
          payload,
          request: formatPlaceBetPreparedRequest(
            placeBetPrepareManualGetterSelector(state)(payload.bettingSlipId),
          ),
        };
      }
      switch (bettingSlipType) {
        case 'single':
          return {
            payload,
            request: formatPlaceBetPreparedRequest(
              placeBetPrepareSingleGetterSelector(state)(payload.bettingSlipId),
            ),
          };
        case 'combi':
          return {
            payload,
            request: formatPlaceBetPreparedRequest(
              placeBetPrepareCombiGetterSelector(state)(payload.bettingSlipId),
            ),
          };

        default:
          return { payload, request: null };
      }
    }),
    filter(curriedHasNonNullableProperty('request')),
    mergeMap(({ payload, request }) => {
      const { bettingSlipId } = payload;
      const betPipeIdentifier = getBetPipeIdentifierFromActionPayload(payload);
      return wsAdapter
        .request(
          placeBet(request),
          // never retry placebet and wait a long time because don't know exactly time this request can take with live event
          onTimeOut,
        )
        .pipe(
          takeUntilPipeIsCancelled(betPipeIdentifier?.betPipeId, actions$),
          mapGuard(placeBetResponseCodec),
          map((data) => {
            if (
              data.Notification &&
              data.Notification.Code === NotificationCode.PlaceBet_Success
            ) {
              return actions.placeBet.success({
                ...data,
                bettingSlipId,
              });
            }
            const { Notification } = data;
            const errorMessage =
              (Notification && Notification.Message) ??
              'betting:bettingSlip.placeBet.noNotificationError';
            return actions.placeBet.failure({
              errorMessage,
              status: DEFAULT_SERVER_ERROR_STATUS,
              bettingSlipId,
              ...data,
            });
          }),
          catchError((err) =>
            of(
              actions.placeBet.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                bettingSlipId,
                betPipeId: betPipeIdentifier?.betPipeId,
                IsFirstBet: false,
              }),
            ),
          ),
        );
    }),
  );

export const placeSystemBetEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.placeSystemBet.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const bettingSlipType =
        payload.bettingSlipType ??
        bettingSlipTypeGetterSelector(state)(payload.bettingSlipId);

      switch (bettingSlipType) {
        case 'system':
          return {
            payload,
            request: formatPlaceSystemBetPreparedRequest(
              placeBetPrepareSystemGetterSelector(state)(payload.bettingSlipId),
            ),
          };

        default:
          return { payload, request: null };
      }
    }),
    filter(curriedHasNonNullableProperty('request')),
    mergeMap(({ payload, request }) => {
      const { bettingSlipId } = payload;
      const betPipeIdentifier = getBetPipeIdentifierFromActionPayload(payload);

      return wsAdapter
        .request(
          placeSystemBet(request),
          // never retry placebet and wait a long time because don't know exactly time this request can take with live event
          onTimeOut,
        )
        .pipe(
          takeUntilPipeIsCancelled(betPipeIdentifier?.betPipeId, actions$),
          mapGuard(placeSystemBetResponseCodec),
          map((data) => {
            if (
              data.Notification &&
              data.Notification.Code === NotificationCode.PlaceBet_Success
            ) {
              return actions.placeSystemBet.success({
                ...data,
                bettingSlipId,
              });
            }
            const { Notification } = data;
            const errorMessage =
              (Notification && Notification.Message) ??
              'betting:bettingSlip.placeSystemBet.noNotificationError';
            return actions.placeSystemBet.failure({
              errorMessage,
              status: DEFAULT_SERVER_ERROR_STATUS,
              bettingSlipId,
              ...data,
            });
          }),
          catchError((err) =>
            of(
              actions.placeSystemBet.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
                bettingSlipId,
                betPipeId: betPipeIdentifier?.betPipeId,
                IsFirstBet: false,
              }),
            ),
          ),
        );
    }),
  );

export const reactToPlaceBetRequestAction: BettingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.launchPlaceBet.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const selectedFreebet = freebetSelectedForBSGetterSelector(state)(
        payload.bettingSlipId,
      );
      if (selectedFreebet) {
        const list = getFreebetForBSGetterSelector(state)(
          payload.bettingSlipId,
        );
        const matchFreebet = list.some(
          (item) =>
            item.Amount === selectedFreebet.amount &&
            item.ConditionId === selectedFreebet.conditionId,
        );

        if (!matchFreebet) {
          return actions.launchPlaceBet.failure({
            errorMessage:
              'betting:bettingSlip.launchPlaceBet.matchFreebet.error',
            status: DEFAULT_SERVER_ERROR_STATUS,
            bettingSlipId: payload.bettingSlipId,
            Notification: {
              Level: NotificationLevel.Warning,
              Code: NotificationCode.Freebet_InternalError,
            },
            IsFirstBet: false,
          });
        }
      }
      const items = bettingSlipItemsForValidationGetterSelector(state)(
        payload.bettingSlipId,
      );
      const isValid = helperBettingSlipIsValid(items);
      if (!isValid) {
        return actions.launchPlaceBet.failure({
          errorMessage: 'betting:bettingSlip.launchPlaceBet.items.notValid',
          status: DEFAULT_SERVER_ERROR_STATUS,
          bettingSlipId: payload.bettingSlipId,
          IsFirstBet: false,
        });
      }

      const bettingSlipType =
        payload.bettingSlipType ??
        bettingSlipTypeGetterSelector(state)(payload.bettingSlipId);

      if (bettingSlipType === 'system') {
        return actions.placeSystemBet.request(payload);
      }
      if (bettingSlipType === 'single' || bettingSlipType === 'combi') {
        return actions.placeBet.request(payload);
      }

      return actions.launchPlaceBet.failure({
        errorMessage: 'betting:bettingSlip.placeBet.pbTypeError',
        status: DEFAULT_SERVER_ERROR_STATUS,
        IsFirstBet: false,
        ...payload,
      });
    }),
  );

export const reactToPlaceBetSuccessAction: BettingEpic = (action$) =>
  action$.pipe(
    filter(
      isActionOf([actions.placeBet.success, actions.placeSystemBet.success]),
    ),
    map(({ payload }) => actions.launchPlaceBet.success(payload)),
  );

export const reactToPlaceBetFailureAction: BettingEpic = (action$) =>
  action$.pipe(
    filter(
      isActionOf([actions.placeBet.failure, actions.placeSystemBet.failure]),
    ),
    map(({ payload }) => actions.launchPlaceBet.failure(payload)),
  );

export const reactToPlaceBetCancelAction: BettingEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.launchPlaceBet.cancel)),
    map(({ payload }) => {
      if (payload.bettingSlipType === 'system') {
        return actions.placeSystemBet.cancel(payload);
      }
      if (
        payload.bettingSlipType === 'single' ||
        payload.bettingSlipType === 'combi'
      ) {
        return actions.placeBet.cancel(payload);
      }

      return actions.launchRiskManagement.failure({
        errorMessage: 'betting:bettingSlip.riskManagement.bsTypeError',
        status: DEFAULT_SERVER_ERROR_STATUS,
        bettingSlipId: payload.bettingSlipId,
        betPipeId: payload.betPipeId,
      });
    }),
  );
