import { createContext } from 'react';

import { Edges, ListDetails } from './types';

const placeHolderScrollHandlers = {
  handleScrollToLeft: () => {},
  handleScrollToRight: () => {},
};

type ScrollableListContextValues = ListDetails & { edges: Edges };

export const ScrollableListContext = createContext<ScrollableListContextValues>(
  {
    areChildrenOverflowing: false,
    edges: {
      isBottomVisible: true,
      isLeftVisible: true,
      isRightVisible: true,
      isTopVisible: true,
    },
    scrollHandlers: placeHolderScrollHandlers,
  },
);
