import { useSelector } from 'react-redux';

import { userCreditSelector } from '@gaming1/g1-core';
import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { getNotificationLevelMapper } from '../../../notification/mapper';
import {
  BettingSlipNotification,
  EFrontErrorType,
} from '../../../notification/mapper/types';
import {
  bettingSlipItemBaseOddGetterSelector,
  bettingSlipItemIsValidOutcomeGetterSelector,
  bettingSlipItemLimitsGetterSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
} from '../../store/selectors';
import { getRealMax } from '../../store/selectors/elements/helpers';

export const useBettingSlipItemBaseOdds = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const oddS = useSelector(bettingSlipItemBaseOddGetterSelector);

  return (itemId: number) => oddS(bettingSlipId, itemId);
};

export const useBettingSlipItemFinalOdds = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const oddS = useSelector(getFinalOddsOfBettingSlipItemGetterSelector);

  return (itemId: number) => oddS(bettingSlipId, itemId);
};

export const useBettingSlipItemErrorType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const odd = useBettingSlipItemFinalOdds(bettingSlipId);

  return (itemId: number): BettingSlipNotification => {
    const oddValue = odd(itemId);
    if (!oddValue) {
      return {
        Status: EFrontErrorType.InvalidOdd,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }
    return {
      Status: EFrontErrorType.None,
      Level: NotificationLevel.Unknown,
      Code: NotificationCode.Unknown,
    };
  };
};

export const useBettingSlipGetItemMaxStake = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const limitsSelector = useSelector(bettingSlipItemLimitsGetterSelector);

  const odds = useBettingSlipItemFinalOdds(bettingSlipId);
  const userCredit = useSelector(userCreditSelector);

  return (itemId: number): number => {
    // TODO: Add credit information and management
    const limits = limitsSelector(bettingSlipId);
    if (!limits) {
      return Number.MAX_VALUE;
    }
    const oddsValue = odds(itemId);

    if (!oddsValue) {
      return 0;
    }

    const { max, winnable } = limits;

    return (
      getRealMax(max ?? null, winnable ?? null, oddsValue) ||
      userCredit?.AvailableAmout ||
      0
    );
  };
};

export const useBettingSlipItemIsValid = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const bettingSlipItemIsValidOutcome = useSelector(
    bettingSlipItemIsValidOutcomeGetterSelector,
  );

  const bettingSlipItemErrorStakeType =
    useBettingSlipItemErrorType(bettingSlipId);

  return (itemId: number): boolean => {
    const notification = bettingSlipItemErrorStakeType(itemId);
    if (getNotificationLevelMapper(notification) === 'danger') {
      return false;
    }
    return bettingSlipItemIsValidOutcome(bettingSlipId, itemId);
  };
};
