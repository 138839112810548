import { of, timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  EDepositStatusResponseDepositStatus,
  EDepositStepActionType,
  findDeposits,
  getDepositNextStep,
  getDepositStatus,
  getDepositWalletInfo,
  getDepositWalletsList,
  makeDeposit,
  makeVoucherDeposit,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../actions';
import { PaymentEpic } from '../types';

import {
  findDepositsResponse,
  getDepositNextStepResponse,
  getDepositStatusResponse,
  getDepositWalletInfoResponse,
  getDepositWalletListResponse,
  makeDepositResponse,
} from './codecs';
import { FAKE_PUSH_THROTTLE_IN_MS } from './constants';
import {
  findDepositsErrorMessages,
  getDepositNextStepErrorMessages,
  getDepositStatusErrorMessages,
  getDepositWalletListErrorMessages,
  makeDepositErrorMessages,
} from './errorMessages';
import { formatMakeDeposit } from './format';

/** Get the deposit wallets */
export const findDepositsEpic: PaymentEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.findDeposits.request)),
    switchMap(() =>
      wsAdapter.request(findDeposits({})).pipe(
        mapGuard(findDepositsResponse),
        map(actions.findDeposits.success),
        catchError((err) =>
          of(
            actions.findDeposits.failure(
              createFailurePayload(err, findDepositsErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the deposit status  */
export const getDepositStatusEpic: PaymentEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getDepositStatus.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getDepositStatus(payload)).pipe(
        mapGuard(getDepositStatusResponse),
        map(actions.getDepositStatus.success),
        catchError((err) =>
          of(
            actions.getDepositStatus.failure(
              createFailurePayload(err, getDepositStatusErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/**
 * Start asking deposit status until a "non pending" response is received or
 * the maximum amount of time trying is reached.
 */
export const startAskingDepositStatusEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.startAskingDepositStatus)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(
          getDepositStatus({
            DepositId: payload.depositId,
            IncludeExtraInfo: true,
            OnlyBMC: true,
          }),
        )
        .pipe(
          mapGuard(getDepositStatusResponse),
          mergeMap((response) =>
            response.DepositStatus ===
            EDepositStatusResponseDepositStatus.Pending
              ? timer(FAKE_PUSH_THROTTLE_IN_MS).pipe(
                  takeUntil(
                    action$.pipe(
                      filter(
                        isActionOf([
                          actions.stopAskingDepositStatus,
                          actions.getDepositStatus.success,
                        ]),
                      ),
                    ),
                  ),

                  map(() =>
                    actions.startAskingDepositStatus({
                      depositId: response.Reference,
                      depositStatus: response,
                    }),
                  ),
                )
              : of(actions.getDepositStatus.success(response)),
          ),
          catchError((err) =>
            of(
              actions.getDepositStatus.failure(
                createFailurePayload(err, getDepositStatusErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Make a deposit  */
export const makeDepositEpic: PaymentEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.makeDeposit.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(makeDeposit(formatMakeDeposit(payload))).pipe(
        mapGuard(makeDepositResponse),
        map(actions.makeDeposit.success),
        catchError((err) =>
          of(
            actions.makeDeposit.failure(
              createFailurePayload(err, makeDepositErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Make a voucher deposit  */
export const makeVoucherDepositEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.makeVoucherDeposit.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(makeVoucherDeposit(payload)).pipe(
        mapGuard(makeDepositResponse),
        map(actions.makeVoucherDeposit.success),
        catchError((err) =>
          of(
            actions.makeVoucherDeposit.failure(
              createFailurePayload(err, makeDepositErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the deposit wallet list */
export const getDepositWalletListEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getDepositWalletList.request)),
    switchMap(() =>
      wsAdapter.request(getDepositWalletsList({})).pipe(
        mapGuard(getDepositWalletListResponse),
        map(actions.getDepositWalletList.success),
        catchError((err) =>
          of(
            actions.getDepositWalletList.failure(
              createFailurePayload(err, getDepositWalletListErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the deposit wallet details */
export const getDepositWalletInfoEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getDepositWalletInfo.request)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(getDepositWalletInfo({ WalletId: payload.WalletId }))
        .pipe(
          mapGuard(getDepositWalletInfoResponse),
          map(actions.getDepositWalletInfo.success),
          catchError((err) =>
            of(
              actions.getDepositWalletInfo.failure(
                createFailurePayload(err, getDepositWalletListErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

export const startAskingNextDepositStepEpic: PaymentEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.startAskingNextDepositStep)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(
          getDepositNextStep({
            DepositId: payload.depositId,
          }),
        )
        .pipe(
          mapGuard(getDepositNextStepResponse),
          mergeMap((response) =>
            response.Action?.Type === EDepositStepActionType.Pending
              ? timer(FAKE_PUSH_THROTTLE_IN_MS).pipe(
                  takeUntil(
                    action$.pipe(
                      filter(
                        isActionOf([
                          actions.stopAskingNextDepositStep,
                          actions.getDepositNextStep.success,
                        ]),
                      ),
                    ),
                  ),
                  map(() =>
                    actions.startAskingNextDepositStep({
                      depositId: payload.depositId,
                    }),
                  ),
                )
              : of(actions.getDepositNextStep.success(response)),
          ),
          catchError((err) =>
            of(
              actions.getDepositNextStep.failure(
                createFailurePayload(err, getDepositNextStepErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
