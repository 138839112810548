import React from 'react';

import { LazyRoute } from '@gaming1/g1-core-web';

import { siteDefaultRoutes } from './routes';

export const getSiteDefaultRouter = (locale: string) => [
  /* Dev pages */
  <LazyRoute
    componentName="LayoutDemoPage"
    importFactory={() => import('./pages/LayoutDemoPage')}
    key="gridSystem"
    path={`/${locale}${siteDefaultRoutes.layoutDemo.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="NetworkTestingPage"
    importFactory={() => import('./pages/NetworkTesting')}
    key="networkTesting"
    path={`/${locale}${siteDefaultRoutes.networkTesting.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="NotificationsAndMessagesPage"
    importFactory={() => import('./pages/NotificationsAndMessagesPage')}
    key="notificationsAndMessages"
    path={`/${locale}${siteDefaultRoutes.notificationsAndMessages.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="SkeletonDemoPage"
    importFactory={() => import('./pages/SkeletonDemoPage')}
    key="skeletonDemo"
    path={`/${locale}${siteDefaultRoutes.skeletonDemo.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="SlidersDemoPage"
    importFactory={() => import('./pages/SlidersDemoPage')}
    key="slidersDemo"
    path={`/${locale}${siteDefaultRoutes.slidersDemo.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="ThemeEditorPage"
    importFactory={() => import('./pages/ThemeEditorPage')}
    key="themeEditor"
    path={`/${locale}${siteDefaultRoutes.themeEditor.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="NetworkTestingPage"
    importFactory={() => import('./pages/NetworkTesting')}
    key="networkTesting"
    path={`/${locale}${siteDefaultRoutes.networkTesting.path}`}
    section="default"
    type="app"
  />,
];
