import { OverwriteConfig, useConfig } from '@gaming1/g1-config';
import { Heading } from '@gaming1/g1-ui';

import { DebugCollapsible, WrappedPre } from './styles';

const safelyStringify = (input: unknown) => {
  try {
    return JSON.stringify(input, null, 2);
  } catch (e) {
    return '[could not parse config]';
  }
};

type WindowWithOverwriteConfig = Window & {
  overwriteConfig?: OverwriteConfig;
};
const windowWithOverwriteConfig = window as WindowWithOverwriteConfig;

const overwriteConfig =
  typeof windowWithOverwriteConfig !== 'undefined'
    ? windowWithOverwriteConfig?.overwriteConfig
    : undefined;

export const ConfigDebugger = () => {
  const config = useConfig();
  return (
    <>
      <DebugCollapsible
        hoverTitle="Current config"
        mainContent={
          <Heading level="h3" mt="none">
            Current config
          </Heading>
        }
        expandedContent={<WrappedPre>{safelyStringify(config)}</WrappedPre>}
      />
      <DebugCollapsible
        hoverTitle="Overwrite from env.js"
        mainContent={
          <Heading level="h3" mt="none">
            Overwrite from env.js
          </Heading>
        }
        expandedContent={
          <WrappedPre>
            {safelyStringify(overwriteConfig) || '[None found]'}
          </WrappedPre>
        }
      />
    </>
  );
};
