import { Subscription } from 'rxjs';

import { twinning } from '@gaming1/g1-twinning';

import { logger } from '../../logger';
import {
  forwardReduxAction,
  reduxActionsTwinningMessages$,
} from '../iframeMessageManager';

let iframeMessagesSubscription: Subscription;

/**
 * Create a redux middleware to apply to redux store.
 * @param whitelist Array of string of whitelisted action type.
 * If white list is null, all actions are shared
 * If white list is empty, not actions are shared
 * If action.type start with one of white listed item, the twinningMiddleware emit action to subscriber
 */
export const twinningMiddleware = (whitelist: string[] | null) => {
  const { subscribe, emit, middleware } = twinning('web-dispatcher', whitelist);

  // Listen to message events on the window object
  if (!iframeMessagesSubscription) {
    iframeMessagesSubscription = reduxActionsTwinningMessages$.subscribe({
      next: (message) => {
        // Commented because it was too verbose
        /*
        logger.info(
          '[Twinning] Received action from RN, dispatching to webapp',
        );
        */
        emit(message.payload);
      },
      error: (err) =>
        logger.error('[Twinning] reduxActionsTwinningMessages$ errored', err),
      complete: () =>
        logger.error('[Twinning] reduxActionsTwinningMessages$ was completed'),
    });
  }

  // Use postMessage to duplicate whitelisted actions
  subscribe(forwardReduxAction);

  return middleware;
};
