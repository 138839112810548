import {
  BlacklistPlayerStatus,
  EAutoExcludeResponseStatus,
} from '@gaming1/g1-requests';

export const selfExclusionErrorMessages = {
  [EAutoExcludeResponseStatus.InternalError]: 'core:error.internalServer', // 1
  [EAutoExcludeResponseStatus.AlreadyExcluded]:
    'user:account.selfExclusion.message.alreadyExcluded', // 20
  [EAutoExcludeResponseStatus.AutoExclusionDisable]:
    'user:account.selfExclusion.message.disabled', // 22
  [EAutoExcludeResponseStatus.InvalidType]:
    'user:account.selfExclusion.message.invalidRequest', // 21
  [EAutoExcludeResponseStatus.InvalidAutoExcludeStartDate]:
    'user:account.selfExclusion.message.invalidRequest', // 23
  [EAutoExcludeResponseStatus.InvalidDurationForType]:
    'user:account.selfExclusion.message.invalidRequest', // 24
};

export const blacklistPlayerErrorMessages = {
  [BlacklistPlayerStatus.InternalError]: 'core:error.internalServer', // 0
  [BlacklistPlayerStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};
