import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getPlayerBettingProfile } from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../common/store/errorMessages';
import { BettingEpic } from '../store/types';

import * as actions from './actions';
import { playerBettingProfileResponseCodec } from './codecs';

const requestOptions = { retryOnTimeout: true, timeoutAfterMs: 10000 };

export const getPlayerBettingProfileEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPlayerBettingProfile.request)),
    switchMap(() =>
      wsAdapter.request(getPlayerBettingProfile({}), requestOptions).pipe(
        mapGuard(playerBettingProfileResponseCodec),
        map(actions.getPlayerBettingProfile.success),
        catchError((err) =>
          of(
            actions.getPlayerBettingProfile.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
