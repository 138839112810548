import format from 'date-fns/format';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  VFC,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { useShouldRenderRequestError } from '@gaming1/g1-core';
import { RequestStateErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  getShopHistoryRequestStateSelector,
  loyaltyActions,
  ShopHistoryElement,
  shopHistoryElementsSelector,
  shopHistorySelector,
} from '@gaming1/g1-loyalty';
import { NewShopProductType, OrderStatus } from '@gaming1/g1-requests';
import { useRequestState } from '@gaming1/g1-store';
import {
  Button,
  Icon,
  Loader,
  Message,
  RenderOnMedia,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';
import { HISTORY_PAGE_SIZE } from '@gaming1/g1-user-api';

import { shopHistoryRequestKeys } from '../helpers';
import { ShopHistoryInformationModal } from '../ShopHistoryInformationModal';

const isValidOrderStatus = (
  num: number,
): num is keyof typeof shopHistoryRequestKeys => num in shopHistoryRequestKeys;

const isValidProductType = (num: number): num is NewShopProductType =>
  num in NewShopProductType;

export const ShopHistory: VFC = () => {
  const { t } = useTranslation('loyalty');
  const { colors } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { i18n } = useContext(ConfigContext);

  const shopHistory = useSelector(shopHistorySelector);
  const shopHistoryElements = useSelector(shopHistoryElementsSelector);
  const { isFirstLoading, isLoading } = useRequestState(
    getShopHistoryRequestStateSelector,
  );
  const { pagination: getShopHistoryPagination } = useSelector(
    getShopHistoryRequestStateSelector,
  );

  const [selectedShopOrderForInfo, setSelectedShopOrderForInfo] =
    useState<ShopHistoryElement | null>();

  const [informationModalVisible, setInformationModalVisible] = useState(false);

  const getShopHistory = useCallback(() => {
    dispatch(
      loyaltyActions.getShopHistory.request({
        PageIndex: 0,
        PageCount: HISTORY_PAGE_SIZE,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getShopHistory();
  }, [getShopHistory]);

  const shouldRenderRequestError = useShouldRenderRequestError(
    getShopHistoryRequestStateSelector,
  );

  if (shouldRenderRequestError) {
    return (
      <RequestStateErrorMessage
        onRetryButtonClick={getShopHistory}
        selector={getShopHistoryRequestStateSelector}
      />
    );
  }

  if (isFirstLoading) {
    return <Loader mt="sm" />;
  }

  return shopHistory.length > 0 ? (
    <>
      <Table testId="shop-history-container">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{t('history.shop.orderDate')}</th>
            <th>{t('history.shop.productTitle')}</th>
            <RenderOnMedia min="lg">
              <th>{t('history.shop.reference')}</th>
              <th>{t('history.shop.status')}</th>
            </RenderOnMedia>
          </tr>
        </thead>
        <tbody>
          {shopHistoryElements.map((history, index) => (
            <tr key={`${history.Id}-${history.Product}`}>
              <td>
                {(history.State === OrderStatus.Delivered && (
                  <Icon
                    id="history-completed"
                    type="CircleCheck"
                    fill={colors.success}
                  />
                )) ||
                  (history.State ===
                    (OrderStatus.PendingDelivery ||
                      OrderStatus.PendingPayment) && (
                    <Icon
                      id="history-pending"
                      type="Clock"
                      fill={colors.warning}
                    />
                  )) || (
                    <Icon
                      id="history-notCompleted"
                      type="CircleStop"
                      fill={colors.danger}
                    />
                  )}
              </td>
              <td>
                {format(
                  new Date(history.OrderDate),
                  i18n.dateTimePartialFormat,
                )}
              </td>
              <td>{history.Title}</td>
              <RenderOnMedia min="lg">
                <td>{history.Id}</td>
                <td>
                  {isValidOrderStatus(history.State) &&
                    isValidProductType(history.NewShopProductType) &&
                    t(
                      shopHistoryRequestKeys[history.State][
                        history.NewShopProductType
                      ],
                    )}
                </td>
              </RenderOnMedia>

              <td>
                <SimpleButton
                  data-testid={`shop-history-information-button-${index}`}
                  onClick={() => {
                    setSelectedShopOrderForInfo(history);
                    setInformationModalVisible(true);
                  }}
                >
                  <Icon
                    id="history-info"
                    type="VerticalTripleDots"
                    height="20px"
                  />
                </SimpleButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {shopHistory.length < getShopHistoryPagination.Total && (
        <Button
          block
          loading={isLoading}
          noBorder
          onClick={() => {
            dispatch(
              loyaltyActions.getShopHistory.request({
                PageIndex: getShopHistoryPagination.PageNumber + 1,
                PageCount: getShopHistoryPagination.PageSize,
              }),
            );
          }}
          testId="shop-history-more-button"
          type="secondary"
        >
          {t('history.seeMore')}
        </Button>
      )}
      {selectedShopOrderForInfo && (
        <ShopHistoryInformationModal
          history={selectedShopOrderForInfo}
          onClose={() => {
            setInformationModalVisible(false);
            setSelectedShopOrderForInfo(null);
          }}
          visible={informationModalVisible}
        />
      )}
    </>
  ) : (
    <Message mt="sm" type="warning">
      {t('history.noResults')}
    </Message>
  );
};
