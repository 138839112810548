import merge from 'lodash/merge';

type StringBundle = { [k: string]: string | StringBundle };

export const nestStringKeys = (record: Record<string, string>): StringBundle =>
  Object.entries(record).reduce(
    (acc, [keys, value]) =>
      merge(
        {},
        acc,
        keys
          .split('.')
          .reverse()
          .reduce<StringBundle | string>(
            (nest, key) => ({ [key]: nest }),
            value,
          ),
      ),
    {},
  );
