import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, getSportsStatusSelector } from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import {
  currentWebSocketLocaleSelector,
  useI18n,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { RemoteData } from '@gaming1/g1-utils';

import { bettingRoutes } from './routes';

export const useSendRequestsOnce = () => {
  const dispatch = useDispatch();
  const config = useConfig();

  const wsLocale = useSelector(currentWebSocketLocaleSelector);
  const getSportsStatus = useSelector(getSportsStatusSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);

  /* Daily offers request */
  useEffect(() => {
    if (config.betting.dailyOffers.enabled) {
      dispatch(actions.hasAvailableDailyOffers.request());
    }
  }, [config.betting.dailyOffers.enabled, dispatch]);

  /* Promotions request */
  useEffect(() => {
    if (config.betting.promotions.enabled && isUserLoggedIn !== null) {
      dispatch(actions.hasAvailablePromotions.request());
    }
  }, [config.betting.promotions.enabled, dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (config.betting.superOdds.enabled) {
      dispatch(actions.hasAvailableSuperOdds.request());
    }
  }, [config.betting.superOdds.enabled, dispatch]);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(actions.getPlayerBettingProfile.request());
    }
  }, [isUserLoggedIn, dispatch]);

  useEffect(() => {
    if (getSportsStatus === RemoteData.NotAsked && wsLocale) {
      dispatch(actions.askSports());
    }
  }, [dispatch, getSportsStatus, wsLocale]);

  useEffect(() => {
    dispatch(actions.getLiveEventsCount.request({ subscribeToUpdates: true }));
    return () => {
      dispatch(actions.getLiveEventsCount.cancel());
    };
  }, [dispatch]);
};

export const useRouteConfig = () => {
  const { currentLocale: locale } = useI18n();

  const routes = {
    bettingHome: {
      path: `/${locale}${bettingRoutes.bettingHome.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    region: {
      path: `/${locale}${bettingRoutes.region.path}*`,
      hasNavLive: false,
      layoutCols: 3,
    },
    league: {
      path: `/${locale}${bettingRoutes.league.path}*`,
      hasNavLive: false,
      layoutCols: 3,
    },
    eventPrematch: {
      path: `/${locale}${bettingRoutes.eventPrematch.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    sport: {
      path: `/${locale}${bettingRoutes.sport.path}*`,
      hasNavLive: false,
      layoutCols: 3,
    },
    liveListTop: {
      path: `/${locale}${bettingRoutes.liveListTop.path}`,
      hasNavLive: false,
      layoutCols: 2,
    },
    liveListAll: {
      path: `/${locale}${bettingRoutes.liveListAll.path}`,
      hasNavLive: false,
      layoutCols: 2,
    },
    liveList: {
      path: `/${locale}${bettingRoutes.liveList.path}`,
      hasNavLive: false,
      layoutCols: 2,
    },
    eventLive: {
      path: `/${locale}${bettingRoutes.eventLive.path}`,
      hasNavLive: true,
      layoutCols: 3,
    },
    boostus: {
      path: `/${locale}${bettingRoutes.boostus.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    dailyOffers: {
      path: `/${locale}${bettingRoutes.dailyOffers.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    superOdds: {
      path: `/${locale}${bettingRoutes.superOdds.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    promotions: {
      path: `/${locale}${bettingRoutes.promotions.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    search: {
      path: `/${locale}${bettingRoutes.search.path}*`,
      hasNavLive: false,
      layoutCols: 3,
    },
    myOpenBets: {
      path: `/${locale}${bettingRoutes.myOpenBets.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    myFinishedBets: {
      path: `/${locale}${bettingRoutes.myFinishedBets.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    myUnlockedGifts: {
      path: `/${locale}${bettingRoutes.myUnlockedGifts.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
    myLockedGifts: {
      path: `/${locale}${bettingRoutes.myLockedGifts.path}`,
      hasNavLive: false,
      layoutCols: 3,
    },
  };
  return routes;
};
