import React, { useContext, useEffect, useState, VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { usePreviousDifferent } from '@gaming1/g1-utils';

import { usePrivacySettings } from '../../hooks/usePrivacySettings';

type WindowWithFullStorySDK = Window & {
  FS?: {
    anonymize: () => void;
    getCurrentSessionURL: () => string;
    identify: (uid: string, userVars?: Record<string, string>) => void;
  };
  _fs_org?: string | null;
  _fs_ready?: () => void;
};
const windowWithFullStorySDK = window as WindowWithFullStorySDK;

const FS_SCRIPT_ID = 'fullstory';

/** Injects the FullStory script if the org key that lies in the config is set */
export const FullStory: VFC = () => {
  const { core, seo } = useContext(ConfigContext);

  const fullstoryScriptPath = `${process.env.REACT_APP_BASE_NAME}fullstory/fullstory.js?v=${process.env.REACT_APP_GIT_HEAD}`;

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { getUserConsentStatusForPurpose } = usePrivacySettings();

  const user = useSelector(userSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const previousIsUserLoggedIn = usePreviousDifferent(isUserLoggedIn);

  const hasUserAcceptedPerformanceMesure = getUserConsentStatusForPurpose(
    core.privacySettings.purposeIDs.performanceMeasure,
  );

  const isFullStoryEnabled =
    !!seo.fullStoryOrgKey &&
    getWrapperEnv() === 'spa' &&
    hasUserAcceptedPerformanceMesure;

  /* Setup fullstory key */
  useEffect(() => {
    if (isFullStoryEnabled) {
      windowWithFullStorySDK._fs_org = seo.fullStoryOrgKey;
      windowWithFullStorySDK._fs_ready = () => {
        setIsScriptLoaded(true);
      };
    }
  }, [isFullStoryEnabled, seo.fullStoryOrgKey]);

  /* Identify the user when authenticated */
  useEffect(() => {
    if (isScriptLoaded && isUserLoggedIn && user?.Id) {
      windowWithFullStorySDK.FS?.identify(user.Id, {
        displayName: user.Pseudo || '',
        email: user.Email || '',
      });
    }
  }, [isFullStoryEnabled, isScriptLoaded, isUserLoggedIn, user]);

  /* Anonymize the user when logged out */
  useEffect(() => {
    if (isScriptLoaded && previousIsUserLoggedIn && !isUserLoggedIn) {
      windowWithFullStorySDK.FS?.anonymize();
    }
  }, [isScriptLoaded, isUserLoggedIn, previousIsUserLoggedIn]);

  return isFullStoryEnabled ? (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          src: fullstoryScriptPath,
          id: FS_SCRIPT_ID,
        },
      ]}
    />
  ) : null;
};
