import { Observable, Subject } from 'rxjs';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

export type LiteWebSocketSubject<T> = Subject<T> & {
  multiplex: InstanceType<typeof WebSocketSubject>['multiplex'];
};

/** Type that will define the factory needed to create a WebSocketSubject-like
 * object without relying on its private members */
export type WebSocketSubjectFactory<T> = (
  urlConfigOrSource: WebSocketSubjectConfig<T>,
) => LiteWebSocketSubject<T>;

/* ENUMS */

/**
 * Type of packets that can be wrapped in a PacketWrapper
 */
export enum EPacketType {
  /** Packets automatically sent from the server every x minute */
  KeepAlivePacket = 0,
  /** First packet to sent to the WS to init the connection */
  IdentificationPacket = 1,
  /** Registration to a multicast group */
  MulticastRegistrationPacket = 4,
  /** Warn the server that the user is closing the connection */
  DisconnectPacket = 6,
  /** Response from the APR following a IdentificationPacket */
  IdentificationResponsePacket = 7,
  /** Metadata to be sent to the server (no response expected) */
  MetaDataPacket = 8,
  /** Inform the server that the user has changed its language */
  ChangeLanguagePacket = 14,
  /** Confirm the language change */
  ChangeLanguageResponsePacket = 15,
  /** Logout the user from the WS */
  ResetAuthenticationPacket = 16,
  /** Confirm the user logout */
  ResetAuthenticationResponsePacket = 17,
  /** Confirm that a metadata packet has been received */
  MetaDataAckPacket = 20,
  /** Packets containing Requests */
  DataPacket = 1000,
}

/**
 * Type of direction of data packets
 */
export enum EDataPacketDirection {
  /** Request to the server */
  Request = 0,
  /** Response from the server */
  Response = 1,
  /** Push data from server without registration */
  Push = 2,
  /** Push data from server following registration to multicast groups */
  Broadcast = 3,
}

/**
 * Types of errors sent by the APR
 */
export enum EErrorType {
  Unknown = 0,
  Unreachable = 1,
  NoHandler = 2,
  InternalError = 3,
}

/**
 * Status of the WebSocket
 */
export enum EWebSocketStatus {
  /** When the websocket is attempting to (re)connect */
  connecting = 'websocket/connecting',
  /** When the websocket opens */
  connected = 'websocket/connected',
  /** When the websocket closes */
  disconnected = 'websocket/disconnected',
  /** When the websocket is ready to send packets */
  ready = 'websocket/ready',
}

/**
 * Status of the queue of packages to be sent to the backend
 */
export enum EQueueStatus {
  /** When requests can be made to the backend */
  active = 'queue/active',
  /**
   * When requests to the backend won't receice responses, du to the legal
   * popup
   */
  paused = 'queue/paused',
}

// Duplicate from packages/g1-requests/src/enums.ts
export enum EAcceptLegalPopupResponse {
  None = 0,
  Successful = 1,
  InternalError = 2,
  InvalidPlayer = 3,
  InvalidRoom = 4,
  InvalidBusinessSession = 5,
  LegalPopupDisabled = 6,
}

// Duplicate from packages/g1-requests/src/enums.ts
export enum ETokenAuthenticationStatus {
  InternalError = 0,
  Successful = 1,
  InvalidToken = 2,
  TokenExpired = 3,
  BlockedByIovation = 4,
}

/* TYPES */

/**
 * The basic data communication type
 */
export type Request = {
  /** Unique ID of the request */
  Id: string;
  /** The numeric type of the request (e.g. 100 for gaming, 200 for betting,
   * etc.) */
  Type: number;
  /** The request handler name */
  Identifier: string;
  /** Content of the request (must be JSON.parsed) */
  Content: string;
  /**
   * Helps differenciate the "injected" requests (requests sent by the backend
   * even though the frontend didn't ask for that data (will come next to a
   * response)
   * true if injected, null if not injected, undefined if the APR is too old
   */
  Injected?: null | true;
};

/**
 * Client information to be sent immediately after WebSocket connection
 */
export type ClientInformations = {
  AppName: string;
  ClientType: string;
  LanguageCode: string;
  RoomDomainName: string;
  Version: string;
  UserAgent: string;
};

/**
 * A packet containing client information to be sent immediately after WebSocket
 * connection
 */
export type IdentificationPacket = {
  ClientInformations: ClientInformations;
  Identity: string;
  NodeType: number;
  SupportedCompressions?: string;
};

/**
 * A packet containing Request objects
 */
export type DataPacket = {
  /** Push or send */
  Direction: EDataPacketDirection;
  /** Unique id */
  Id: string;
  /** Request collection */
  Requests: Request[];
  /** Array of multicast groups */
  Groups: string[];
};

/**
 * Data Packet format received from the API
 */
export type RawDataPacket = {
  /** Push or send */
  Direction: EDataPacketDirection;
  /** Unique id */
  Id: string;
  /** Request collection */
  Requests: {
    $type: string;
    $values: Request[];
  };
  /** Array of multicast groups */
  Groups: {
    $type: string;
    $values: string[];
  };
};

/**
 * The topmost wrapper used in the websocket
 */
export type PacketWrapper = {
  /** Unique id */
  Id: string;
  /** Time To Live */
  TTL: number;
  /** Message type */
  MessageType: EPacketType;
  /** Serialized message */
  Message: string;
};

/**
 * Response from the APR to a request when there was an error getting the server
 * response
 */
export type ErrorRequest = {
  ErrorType: EErrorType;
  OriginalRequest: string;
  Message: string;
  AdditionalInfo: string;
};

/** Meta data that can be send to the APR */
export type MetaData = { [k: string]: string };

/** Options set on wsAdapter init */
export type WsAdapterOptions = {
  /** Address of the websocket */
  address: string;
  /** Name of the compression used (if any) */
  compression?: string | null;
  /**
   * Default delay in ms before a request is marked as timing out.
   * This will only affect the requests where that value was not changed.
   * Default value: 5000
   */
  defaultTimeout?: number;
  /** If true, every network transaction will be logged for debugging purpose */
  debugMode?: boolean;
  /** User locale */
  locale: string;
  /** Logger prefix, by default "[WS]" */
  loggerPrefix?: string;
  /**
   * Max number of reconnection attemps
   * Default value: 50
   */
  reconnectAttempts?: number;
  /**
   * Minimum delay in ms between each reconnection attemps
   * Default value: 2000
   */
  reconnectDelay?: number;
  /**
   * Increment to the delay in ms between each reconnection attemps
   * Default value: 500
   */
  reconnectIncrement?: number;
  /** Room domain name */
  room: string;
  /** Should be false only if reusing an existing WebSocket, already initialized
   * (through the use of setWebSocketSubjectFactory). Default to true */
  shouldSendInitMetadata?: boolean;
  /**
   * Amount that every timeout delay (defaut or custom) will be multiplied
   * against. Useful to increase the custom timeout delay of some requests.
   * Warning: this will affect the defaultTimeoutValueInMs as well.
   * Default value: 1
   */
  timeoutValueMultiplicator?: number;
};

/** Options set on wsAdapter request method */
export type WebSocketRequestOptions = {
  // Should the request be send again (one time) on timeout ? (default true)
  retryOnTimeout?: boolean;
  // The number of ms between each retry (default 8000)
  timeoutAfterMs?: number;
};

/** An object used to log a network transaction for the debug panel */
export type WebsocketDebugStoreItem = {
  direction: EDataPacketDirection;
  type: string;
  id: string;
  content: unknown;
  name?: string;
  injected?: boolean;
  time: number;
};

// Duplicate from packages/g1-requests/src/entities/TokenAuthenticationRequestDTO.ts
export type TokenAuthenticationRequestDTO = {
  LanguageCode: string;
  Token: string;
};

// Duplicate from packages/g1-requests/src/entities/TokenAuthenticationResponseDTO.ts
export type TokenAuthenticationResponseDTO = {
  Status: ETokenAuthenticationStatus;
  Message?: string;
};

/** A regular serializable object with unknown properties and content that
 * represent the content we received from the backend */
export type UnknownObject = { [keys: string]: unknown };

export type WebSocketAdapter = {
  close: () => void;
  debugStore$: Observable<WebsocketDebugStoreItem>;
  getGroupSubscriptionCount$: (groupName: string) => Observable<number>;
  getInjectedRequests$: (requestIdenfifier: string) => Observable<unknown>;
  getIsAutomaticallyReconnecting: () => boolean;
  getPushRequests$: (requestIdenfifier: string) => Observable<unknown>;
  getStatus: () => EWebSocketStatus;
  hasGroupSubscriptions: (groupName: string) => boolean;
  init: (options: WsAdapterOptions) => void;
  isAutomaticallyReconnecting$: Observable<boolean>;
  loginUser: (token: string, locale?: string) => Observable<unknown>;
  logoutUser: () => Observable<unknown>;
  overwrittenResponses$: Observable<Record<string, unknown>>;
  prepareDisconnection: () => void;
  queueStatus$: Observable<EQueueStatus>;
  reconnect: () => void;
  request: <T = unknown>(
    request: Request,
    options?: WebSocketRequestOptions,
  ) => Observable<unknown | T>;
  sendMetadata: (metadata: MetaData) => Observable<unknown>;
  setDebugMode: (enabled: boolean) => void;
  setQueueStatus: (status: EQueueStatus) => void;
  setOverwrittenResponse: (name: string, content?: unknown) => void;
  setResponseDelay: (delay: number) => void;
  setWebSocketSubjectFactory: (
    wsSubjectFactory: WebSocketSubjectFactory<PacketWrapper | null>,
  ) => void;
  status$: Observable<EWebSocketStatus>;
  subscribeToGroup: (
    groupName: string,
    options?: { autoComplete?: boolean },
  ) => Observable<unknown>;
  switchLocale: (locale: string) => Observable<PacketWrapper>;
  triggerDisconnection: () => void;
  unsubscribeToGroup: (groupName: string) => number;
  wasInitialzed: () => boolean;
};
