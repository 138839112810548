import { createSelector } from 'reselect';

import {
  NotificationCode,
  OptimalBetElementStatus,
} from '@gaming1/g1-requests-betting';
import { RemoteData } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import {
  bettingSlipGetterSelector,
  bettingSlipRequestGetterSelector,
} from '../common';

export const getOptimalBetStateSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) => (bettingSlipId: BettingSlipIdentifierType) =>
    getBettingSlip(bettingSlipId)?.optimalBet,
);

/** getOptimalBet selectors */

export const getOptimalBetRequestStatusSelector = createSelector(
  bettingSlipRequestGetterSelector,
  (getBettingSlipRequestSelector) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      getBettingSlipRequestSelector(bettingSlipId)?.getOptimalBet.status ||
      RemoteData.NotAsked,
);

export const getOptimalBetRequestValidDataGetterSelector = createSelector(
  getOptimalBetRequestStatusSelector,
  (optimalBetRequestStatus) => (bettingSlipId: BettingSlipIdentifierType) => {
    const status = optimalBetRequestStatus(bettingSlipId);
    if (
      status === RemoteData.Loading ||
      status === RemoteData.NotAsked ||
      status === RemoteData.Error
    ) {
      return false;
    }
    return true;
  },
);

export const getOptimalBetInfoResultSelector = createSelector(
  getOptimalBetRequestValidDataGetterSelector,
  getOptimalBetStateSelector,
  (getOptimalBetRequestValidData, getOptimalBeState) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      getOptimalBetRequestValidData(bettingSlipId)
        ? getOptimalBeState(bettingSlipId)?.result
        : null,
);

export const getOptimalBetNotificationGetterSelector = createSelector(
  getOptimalBetInfoResultSelector,
  (getOptimalBetInfoResult) => (bettingSlipId: BettingSlipIdentifierType) =>
    getOptimalBetInfoResult(bettingSlipId)?.Notification || null,
);

export const isGetOptimalBetInfoNotificationASuccess = createSelector(
  getOptimalBetNotificationGetterSelector,
  (getOptimalBetNotification) => (bettingSlipId: BettingSlipIdentifierType) =>
    getOptimalBetNotification(bettingSlipId)?.Code ===
    NotificationCode.OptimalBet_Success,
);

export const getOptimalBetGetterSelector = createSelector(
  getOptimalBetInfoResultSelector,
  isGetOptimalBetInfoNotificationASuccess,
  (getOptimalBetInfoResult, optimalBetInfoNotificationASuccess) =>
    (bettingSlipId: BettingSlipIdentifierType) => {
      const result = getOptimalBetInfoResult(bettingSlipId);
      if (result) {
        if (optimalBetInfoNotificationASuccess(bettingSlipId)) {
          return {
            optimalBetElements: result?.OptimalBetElements,
            optimalBetInformation: result?.OptimalBetInformation,
          };
        }
      }
      return null;
    },
);

export const getOptimalInformationBetGetterSelector = createSelector(
  getOptimalBetInfoResultSelector,
  (getOptimalBetInfoResult) => (bettingSlipId: BettingSlipIdentifierType) =>
    getOptimalBetInfoResult(bettingSlipId)?.OptimalBetInformation,
);

/**
 * Returns the optimalBetInformation data if the given item is eligible to optimal bets.
 * The itemId is the outcomeId.
 */
export const getOptimaBetsInformationIfItemIsEligible = createSelector(
  getOptimalBetGetterSelector,
  (optimalBets) =>
    (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
      const optimalBetsResult = optimalBets(bettingSlipId);

      if (!optimalBetsResult?.optimalBetInformation) {
        return null;
      }
      const itemIsEligible =
        optimalBetsResult?.optimalBetElements?.find(
          (element) => element.OutcomeId === itemId,
        )?.OptimalBetStatus === OptimalBetElementStatus.Applicable;

      if (itemIsEligible) {
        return optimalBetsResult.optimalBetInformation;
      }

      return null;
    },
);
