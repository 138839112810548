import * as t from 'io-ts';

import { EPendingGameStatus } from '@gaming1/g1-requests-gaming';

import { game } from '../../common/store/codecs';

export const pendingGame = t.type({
  PendingGameId: t.string,
  Credit: t.number,
  Stake: t.number,
  Game: game,
});

export const getPendingGamesResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EPendingGameStatus.Successful),
      HasPendingGame: t.boolean,
    }),
    t.partial({
      PendingGames: t.array(pendingGame),
      Message: t.string,
      PendingGameId: t.string,
      Game: game,
      Credit: t.number,
      Stake: t.number,
    }),
  ],
  'GetPendingGamesResponse',
);
