import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { EFrontErrorType } from '../../../notification/mapper/types';

import { useBettingSlipBoostusErrorType } from './boostus';
import { useBettingSlipFreebetErrorType } from './freebet';
import { useBettingSlipPromotionErrorType } from './promotion';

export const useBettingSlipGiftErrorType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const freebetError = useBettingSlipFreebetErrorType(bettingSlipId);
  const boostusError = useBettingSlipBoostusErrorType(bettingSlipId);
  const promotionError = useBettingSlipPromotionErrorType(bettingSlipId);

  return () => {
    if (freebetError().Status !== EFrontErrorType.None) {
      return freebetError();
    }
    if (boostusError().Status !== EFrontErrorType.None) {
      return boostusError();
    }
    if (promotionError().Status !== EFrontErrorType.None) {
      return promotionError();
    }
    return {
      Status: EFrontErrorType.None,
      Level: NotificationLevel.Information,
      Code: NotificationCode.Unknown,
    };
  };
};
