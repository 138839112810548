import React, { FC, Fragment, memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  getHasBoostusRequestStatusSelector,
  getSportsNavigationSelector,
  getSportsNavigationStatusSelector,
  getTrendsNavigationSelector,
  getTrendsNavigationStatusSelector,
  sportAliasSelector,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import {
  Header,
  MenuGroups,
  ScrollNav,
  useIsUserLoggedIn,
} from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  Box,
  Drawer,
  DrawerType,
  Icon,
  LayoutContext,
  Text,
  useCloseDrawerOnNavigation,
} from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

import { useMenuLists } from '../../../common/components/BettingMenu/hooks';
import { truncateString } from '../../../helpers';
import { useBettingRoutePath } from '../../../hooks';
import { widthDrawerByMedia } from '../../../layout/helpers';
import { getIconType } from '../../helpers';

import {
  AllSportsSubtitle,
  AllSportTitle,
  DrawerNavContainer,
  EasyAccessButton,
  EasyAccessContainer,
  EasyAccessItem,
  EasyAccessLink,
} from './styles';

/** All sports drawer */
const AllSportsDrawerComponent: FC = ({ children }) => {
  const config = useConfig();
  const isBoostusEnabled = config.betting.boostus.enabled;
  const { t } = useTranslation('betting');
  const getBettingRoutePath = useBettingRoutePath();
  const { media, hideDrawer } = useContext(LayoutContext);
  useCloseDrawerOnNavigation(DrawerType.allSports);

  const dispatch = useDispatch();

  const isUserLoggedIn = useIsUserLoggedIn();

  const {
    featuredMenu,
    quickLinksMenuList,
    mainAuthenticatedMenuList,
    servicesMenuList,
  } = useMenuLists();

  const mainMenuGroups: MenuGroups = [
    quickLinksMenuList,
    featuredMenu,
    isUserLoggedIn ? mainAuthenticatedMenuList : [],
  ];

  const filteredMainMenuGroups: MenuGroups = mainMenuGroups.filter(
    (menu) => menu.length > 0,
  );

  const getSportAlias = useSelector(sportAliasSelector);

  const trends = useSelector(getTrendsNavigationSelector);
  const trendsRequestStatus = useSelector(getTrendsNavigationStatusSelector);

  const sports = useSelector(getSportsNavigationSelector);
  const sportsRequestStatus = useSelector(getSportsNavigationStatusSelector);

  const hasBoostusRequestStatus = useSelector(
    getHasBoostusRequestStatusSelector,
  );

  const locale = useSelector(currentWebSocketLocaleSelector);

  useEffect(() => {
    if (trendsRequestStatus === RemoteData.NotAsked && locale) {
      dispatch(actions.getTrendsNavigation.request());
    }
  }, [dispatch, trendsRequestStatus, locale]);

  useEffect(() => {
    if (sportsRequestStatus === RemoteData.NotAsked) {
      dispatch(actions.askSportsNavigation());
    }
  }, [dispatch, sportsRequestStatus]);

  useEffect(() => {
    if (isBoostusEnabled && hasBoostusRequestStatus === RemoteData.NotAsked) {
      dispatch(actions.getHasBoostusAvailable.request());
    }
  });

  return (
    <Drawer
      appearFrom="right"
      type={DrawerType.allSports}
      width={widthDrawerByMedia[media]}
      ariaLabel="Mobile menu"
      testId="allsports-drawer"
    >
      <DrawerNavContainer>
        <Header
          onCloseButtonClick={hideDrawer}
          shouldDisplayAsMobile
          testId="allsports-drawer-header"
        >
          <AllSportTitle>{t('allSports.allSports')}</AllSportTitle>
        </Header>
        {children}
        <Box data-testid="sportbook-all-sports">
          {trends.length > 0 && (
            <>
              <AllSportsSubtitle data-testid="drawer-trends">
                {t('allSports.trends')}
              </AllSportsSubtitle>
              <ScrollNav
                layout="altMenu"
                shouldStick={false}
                menu={[
                  [
                    ...trends.map((trend) => ({
                      label: truncateString(trend.Name, 15),
                      exact: true,
                      path: getBettingRoutePath('league', {
                        sportId: trend.SportId.toString(),
                        regionId: trend.RegionId.toString(),
                        leagueId: trend.Id.toString(),
                      }),
                      testId: 'betting-sport-all-leagues-link',
                      icon: getIconType(getSportAlias(trend.SportId)),
                    })),
                  ],
                ]}
              />
            </>
          )}
          {filteredMainMenuGroups.some((list) => list.length > 0) && (
            <>
              <AllSportsSubtitle>
                {t('allSports.quickAccess')}
              </AllSportsSubtitle>
              <EasyAccessContainer>
                {filteredMainMenuGroups.map((menuList, groupIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={groupIndex}>
                    {menuList.map((itemAttrs) => (
                      <EasyAccessItem
                        data-testid="easy-access-menu-item"
                        key={`${itemAttrs.testId}${itemAttrs.label}`}
                        hasToTakeFullWidth={
                          filteredMainMenuGroups.length === 1 &&
                          menuList.length === 1
                        }
                      >
                        {'onClick' in itemAttrs ? (
                          <EasyAccessButton onClick={itemAttrs.onClick}>
                            {itemAttrs.label}
                          </EasyAccessButton>
                        ) : (
                          <EasyAccessLink to={itemAttrs.path}>
                            {itemAttrs.label}
                          </EasyAccessLink>
                        )}
                      </EasyAccessItem>
                    ))}
                  </Fragment>
                ))}
              </EasyAccessContainer>
            </>
          )}
          {sports.length > 0 && (
            <>
              <AllSportsSubtitle data-testid="drawer-sports">
                {t('allSports.sports')}
              </AllSportsSubtitle>
              {sports.map((sport) => (
                <EasyAccessLink
                  key={sport.Id}
                  to={getBettingRoutePath('sport', {
                    sportId: sport.Id.toString(),
                  })}
                  data-testid={`mobile-sportmenu-toggle-sport-${sport.Id}`}
                >
                  <Box flexDirection="row">
                    <Icon
                      id="sport-menu-icon"
                      type={getIconType(sport.Alias)}
                    />
                    <Text m={0} ml="md">
                      {sport.Name}
                    </Text>
                  </Box>
                  <Icon type="AngleRight" id="all-sports-menu-item" />
                </EasyAccessLink>
              ))}
            </>
          )}
          {servicesMenuList.length > 0 && (
            <>
              <AllSportsSubtitle>{t('lateralMenu.services')}</AllSportsSubtitle>
              {servicesMenuList.map(({ icon, label, testId, ...props }) => {
                const content = (
                  <>
                    <Box flexDirection="row">
                      {!!icon && typeof icon === 'string' ? (
                        <Icon type={icon} id="sport-menu-icon" />
                      ) : (
                        icon
                      )}
                      <Text m={0} ml="md">
                        {label}
                      </Text>
                    </Box>
                    <Icon type="AngleRight" id="all-sports-menu-item" />
                  </>
                );
                return 'onClick' in props ? (
                  <EasyAccessButton
                    key={`${testId}${label}`}
                    onClick={props.onClick}
                  >
                    {content}
                  </EasyAccessButton>
                ) : (
                  <EasyAccessLink key={`${testId}${label}`} to={props.path}>
                    {content}
                  </EasyAccessLink>
                );
              })}
            </>
          )}
        </Box>
      </DrawerNavContainer>
    </Drawer>
  );
};

export const AllSportsDrawer = memo(AllSportsDrawerComponent);
