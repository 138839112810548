import styled from 'styled-components';

import { fontSizes, fontWeight, opacity, spaces } from '@gaming1/g1-style';
import { Box, Text } from '@gaming1/g1-ui';

export const OddsContainer = styled(Box)`
  align-items: center;
  flex-direction: row;
`;

export const BaseValueContainer = styled.div`
  font-size: ${fontSizes('md')};
  font-weight: ${fontWeight('light')};
  opacity: ${opacity('obscure')};
  text-decoration: line-through;
  color: inherit;
`;

export const FinalValueContainer = styled(Text)`
  font-size: ${fontSizes('md')};
  font-weight: ${fontWeight('bold')};
  margin-top: ${spaces('none')};
  color: inherit;
`;
