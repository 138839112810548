import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { isNonNullable } from '@gaming1/g1-utils';

import * as pipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const freebetConditionsReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(pipeActions.freebetConditionsForBettingSlip.request): {
        const { payload } = action;
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].freebetConditions = [];
        }
        break;
      }
      case getType(pipeActions.freebetConditionsForBettingSlip.success): {
        const { payload } = action;
        const { ConditionsIds } = payload;

        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].freebetConditions =
            ConditionsIds ? ConditionsIds?.filter(isNonNullable) : [];
        }
        break;
      }

      case getType(pipeActions.freebetConditionsForBettingSlip.failure): {
        const { payload } = action;
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].freebetConditions = [];
        }
        break;
      }

      case getType(actions.addFreebetToBS): {
        const { payload } = action;

        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].selectedFreebet = {
            amount: payload.amount,
            conditionId: payload.conditionId,
          };
        }
        break;
      }
      case getType(actions.removeFreebetToBS): {
        const { payload } = action;

        if (!payload.bettingSlipId) {
          break;
        }
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].selectedFreebet = null;
        }

        break;
      }
      case getType(pipeActions.resetBettingSlip.request):
      case getType(pipeActions.launchPlaceBet.success): {
        const { payload } = action;

        if (!payload.bettingSlipId) {
          break;
        }
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].freebetConditions = [];
          draftState.bettingSlips[payload.bettingSlipId].selectedFreebet = null;
        }

        break;
      }
      default: // Immer will automatically return the state
    }
  });
