import { combineReducers } from 'redux';

import { BettingActions } from '../../../store/types';
import { SportbookState } from '../types';

import { componentsReducer } from './components';
import { sportbookEntitiesReducer } from './entities';
import { filtersReducer } from './filter';
import { liveCountBySportReducer } from './liveCountBySport';
import { liveEventsCountReducer } from './liveEventsCount';
import { sportbookRequestsReducer } from './requests';
import { searchReducer } from './search';

export const sportbookReducer = combineReducers<SportbookState, BettingActions>(
  {
    entities: sportbookEntitiesReducer,
    components: componentsReducer,
    liveEventsCount: liveEventsCountReducer,
    liveCountBySport: liveCountBySportReducer,
    filters: filtersReducer,
    search: searchReducer,
    requests: sportbookRequestsReducer,
  },
);
