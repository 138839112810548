import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import subDays from 'date-fns/subDays';
import subYears from 'date-fns/subYears';

import { isNonNullable } from './guards';
import { isDateValid, parseDate } from './helpers';

/** Possible types encountered in input fields */
// type FieldValueTypes = string | number | File[];

/**
 * Object passed to the second parameters of final-form validators
 * Unfortunately has to be typed as "object" to mimic final-form types
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type AllValues = object; //
type AllValuesRecord = Record<string, unknown>;

/** Returned error message of our validators Either
 * - undefined (no error)
 * - a tuple of a string and undefined (simple translation)
 * - a tuble of a string and an object of replacements (translations with
 *   replacements)
 */
type ValidatorError = string | [string, Record<string, string>] | undefined;

// The type FieldValidator is copied from final-form which unfortunately doesn't export it
export type FieldValidator<
  FieldValue = string,
  FormValues extends AllValues = AllValues,
> = (
  value?: FieldValue,
  allValues?: FormValues,
  // meta?: FieldState<FieldValue>,
) => ValidatorError;

/*
export type FieldValidator<FieldValue = string> = NonNullable<
  ReturnType<Required<FieldConfig<FieldValue>>['getValidator']>
>;
*/

/**
 * A validation function that should return true if the test has been passed,
 * false when there is an error with the value
 */
type FieldValidatorBool<
  FieldValue = string,
  FormValues extends AllValues = AllValues,
> = (value?: FieldValue, allValues?: FormValues) => boolean;

/* Regexes */

/**
 * Pattern for email address
 */
const emailPattern =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

/* Validators helpers */

const isNonNullableValidator = <FieldValue = string>(
  validator: FieldValidator<FieldValue> | null,
): validator is FieldValidator<FieldValue> => validator !== null;

/**
 * Validator factory: takes a validation function (that returns true if passing)
 * and returns another function that takes a translation key (and optionally an
 * interpolation map for variables replacement) and returns it only if the
 * validation function did non pass
 * @param checkFunction
 */
export const createValidator =
  <FieldValue, FormValues extends AllValues = AllValues>(
    checkFunction: FieldValidatorBool<FieldValue, FormValues>,
  ) =>
  (
    tKey: string,
    tInterpolationMap?: Record<string, string>,
  ): FieldValidator<FieldValue, FormValues> =>
  (value, allValues) => {
    const hasPassedCheck = checkFunction(value, allValues);
    if (hasPassedCheck) {
      return undefined;
    }
    return tInterpolationMap ? [tKey, tInterpolationMap] : tKey;
  };

/**
 * Take multiple validators and return one that will execute them sequentially
 * (stops execution at the first error)
 */
export const composeValidators =
  <FieldValue = string>(
    ...validators: (FieldValidator<FieldValue> | null)[]
  ): FieldValidator<FieldValue> =>
  (value, allValues) =>
    validators
      .filter(isNonNullableValidator)
      .reduce<ReturnType<FieldValidator<FieldValue>>>(
        (acc, validator) =>
          !acc && validator(value, allValues)
            ? validator(value, allValues)
            : acc,
        undefined,
      );

/** Type gard of ValidatorError (return false if undefined) */
export const isValidatorError = (
  errorTranslation: unknown,
): errorTranslation is NonNullable<ValidatorError> => {
  if (typeof errorTranslation === 'string') {
    return true;
  }
  if (!Array.isArray(errorTranslation)) {
    return false;
  }
  if (errorTranslation.length !== 2) {
    return false;
  }
  const [translation, i18nReplacements] = errorTranslation;
  if (typeof translation !== 'string') {
    return false;
  }
  if (
    i18nReplacements !== undefined &&
    typeof i18nReplacements !== 'object' &&
    !Object.entries(i18nReplacements).every(
      ([key, value]) => typeof key === 'string' && typeof value === 'string',
    )
  ) {
    return false;
  }
  return true;
};

/** Convert text value from input to an amount regardless of decimal separator */
export const convertInputToAmount = (inputValue?: string | number) =>
  typeof inputValue === 'number'
    ? inputValue
    : Number(inputValue?.replace(',', '.') || 0);

/* Validators factories */

/**
 * Takes a maximum length to check if the array of files is not longer than the maximum given.
 * It returns a function that takes an error message if the array is too long.
 * @param max the maximum size of the array of files
 */
export const maximumFilesValidatorFactory = (max: number) =>
  createValidator<File[]>(
    (value) => !!value && Array.isArray(value) && value.length <= max,
  );

/**
 * Takes a maximum size accepted for the total size of the files.
 * It returns a function that takes an error message if the total size of the files is bigger
 * than the maximum size accepted.
 * @param maxSize the maximum size accepted (for example: 2 Go max.)
 */
export const maximumFilesSizeValidatorFactory = (maxSize: number) =>
  createValidator<File[]>((files) => {
    if (files && Array.isArray(files) && files.length > 0) {
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);

      return totalSize <= maxSize;
    }

    return true;
  });

/**
 * Takes a regex expresion and returns a func&tion that takes an error message
 * this function returns the error message only if the value does not match with the regex expression
 */
export const patternValidatorFactory = (regex?: RegExp) =>
  createValidator<string>(
    (value) => !regex || (!!value && regex && !!value.match(regex)),
  );

/**
 * Takes a field name and returns a function that takes an error message
 * this function returns the error message only if the value of the field is not equal to the current one
 */
export const equalToFieldValidatorFactory = (fieldName: string) =>
  createValidator(
    (value, allValues) =>
      !!value &&
      !!allValues &&
      !!(allValues as AllValuesRecord)[fieldName] &&
      value === (allValues as AllValuesRecord)[fieldName],
  );

/**
 * Returns a function that takes an error message.
 * This function returns the error message only if the values of the two fields are not equals.
 * @param otherFieldName The field name to compare
 */
export const notEqualToFieldValidatorFactory = (otherFieldName: string) =>
  createValidator(
    (value, allValues) =>
      !!value &&
      !!allValues &&
      value !== (allValues as AllValuesRecord)[otherFieldName],
  );

/**
 * Takes a min length and an optionnal max length and returns a function that takes an error message
 * this function returns the error message only if the length of the value is >= minLength and <= maxLength
 */
export const lengthValidatorFactory = (minLength: number, maxLength?: number) =>
  createValidator<string>(
    (value) =>
      !value ||
      (value.length >= minLength &&
        (!maxLength || (!!maxLength && value.length <= maxLength))),
  );

/**
 * Takes a min range and an optionnal max range and returns a function that takes an error message
 * this function returns the error message only if the range of the value is < minRange or > maxRange
 */
export const rangeValidatorFactory = (minRange: number, maxRange?: number) =>
  createValidator(
    (value?: string) =>
      !value ||
      (convertInputToAmount(value) >= minRange &&
        (!maxRange || (!!maxRange && convertInputToAmount(value) <= maxRange))),
  );

/**
 * Takes a number (example: 5) and returns a function that takes an error message
 * this function returns the error message only if the value is not a multiple of 5
 */
export const multipleValidatorFactory = (multiple: number) =>
  createValidator(
    (value?: string) => !value || convertInputToAmount(value) % multiple === 0,
  );

/**
 * Asserts a birthday with the given format is old enough to register
 */
export const birthdateValidatorFactory = ({
  format,
  minimalAge,
  minimalAgePlusOneDay = false,
}: {
  format: string;
  minimalAge: number;
  minimalAgePlusOneDay?: boolean;
}) =>
  createValidator((value?: string) => {
    if (!value) {
      return false;
    }
    const now = new Date();
    const dateMinusMinimumYears = subYears(now, minimalAge);
    const earliestDateAllowed = minimalAgePlusOneDay
      ? subDays(dateMinusMinimumYears, 1)
      : dateMinusMinimumYears;
    const parsedBithDate = parse(value, format, now);
    return isBefore(
      // is encoded birthdate ...
      parsedBithDate,
      // ... before minimal accepted birthdate?
      earliestDateAllowed,
    );
  });

/* Validators */

/**
 * Validator for mandatory fields
 */
export const requiredValidator = createValidator(
  (value) =>
    (!!value && Array.isArray(value) && value.length > 0) ||
    (!!value && !Array.isArray(value)),
);

/**
 * Validator for email address
 */
export const emailValidator = patternValidatorFactory(emailPattern);

/**
 * Validator for date strings in the provided format
 */
export const dateFormatValidatorFactory = (format: string) =>
  createValidator(
    (value?: string) => !value || isDateValid(value, format, 1900),
  );

/**
 * Validate a date with the given format is earlier or the same than today
 */
export const dateBeforeOrEqualTodayValidatorFactory = (format: string) =>
  createValidator(
    (value?: string) =>
      !value || isBefore(parseDate(value, format), new Date()),
  );

/**
 * Validate a date with the given format is later than today
 */
export const dateAfterTodayValidatorFactory = (format: string) =>
  createValidator(
    (value?: string) => !value || isAfter(parseDate(value, format), new Date()),
  );

/** Compose a list of validators into one + a required validator if the
 * second argument is true
 */
export const getInputFieldValidator = <FieldValue = string>(
  validate:
    | FieldValidator<FieldValue>
    | null
    | (FieldValidator<FieldValue> | null)[],
  required?: boolean,
) => {
  let validators: FieldValidator<FieldValue>[] = [];

  if (Array.isArray(validate)) {
    validators = validate.filter(isNonNullable);
  } else if (validate) {
    validators = [validate];
  }
  if (required) {
    validators.unshift(requiredValidator('core:validation.required'));
  }
  return validators ? composeValidators<FieldValue>(...validators) : undefined;
};
