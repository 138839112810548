import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getSponsorshipHistory } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../actions';
import { LoyaltyEpic } from '../../types';
import { getSponsorshipHistoryResponse } from '../codecs';
import { getSponsorshipHistoryErrorMessages } from '../errorMessages';
import { formatSponsorshipHistory } from '../format';

export const getSponsorshipHistoryEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getSponsorshipHistory.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getSponsorshipHistory(payload)).pipe(
        mapGuard(getSponsorshipHistoryResponse),
        map(formatSponsorshipHistory),
        map(actions.getSponsorshipHistory.success),
        catchError((err) =>
          of(
            actions.getSponsorshipHistory.failure(
              createFailurePayload(err, getSponsorshipHistoryErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
