import styled, { css } from 'styled-components';

import { colors, device, fontSizes, getReadableColor } from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

import { oddUpdateAnimation } from '../../../styles';

export const OUTCOME_BUTTON_HEIGHT_IN_PX = '72px';

type OutcomeButtonProps = {
  /** is the outcome currently selected in the active ticket ? */
  isActive: boolean;
  /** is the outcome locked */
  isLocked: boolean;
};

/**
 * Stylized generic outcome button that changes its background color based on state
 */
export const OutcomeButton = styled(SimpleButton)<OutcomeButtonProps>`
  ${() => {
    const textColor = getReadableColor('bettingOutcome');
    const textColorActive = getReadableColor('bettingOutcomeActive');

    return css`
      color: ${textColor};
      path {
        fill: ${textColor};
      }
      &:active {
        color: ${textColorActive};
        path {
          fill: ${textColorActive};
        }
      }
    `;
  }}

  background-color: ${colors('bettingOutcome')};
  border: 1px solid ${colors('bettingOutcomeBorder')};
  height: ${OUTCOME_BUTTON_HEIGHT_IN_PX};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: ${fontSizes('md')};
  border-radius: 2px;
  position: relative;
  ${device.canHover} {
    &:hover {
      background-color: ${colors('bettingOutcomeHovered')};
    }
  }
  &:active {
    background-color: ${colors('bettingOutcomeActive')};
    border: 1px solid ${colors('bettingOutcomeActiveBorder')};
  }

  ${({ isActive }) => {
    const textColorActive = getReadableColor('bettingOutcomeActive');

    return (
      isActive &&
      css`
        color: ${textColorActive};
        background-color: ${colors('bettingOutcomeActive')};
        border: 1px solid ${colors('bettingOutcomeActiveBorder')};
        path {
          fill: ${textColorActive};
        }
        ${device.canHover} {
          &:hover {
            background-color: ${colors('bettingOutcomeActiveHovered')};
            border: 1px solid ${colors('bettingOutcomeActiveBorderHovered')};
          }
        }
      `
    );
  }}

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-radius: 2px;
    border-width: 5px;
    right: 0;
  }

  ${({ isLocked }) =>
    !isLocked &&
    css`
      .increase&::after {
        top: 0;
        border-right-color: ${colors('success')};
        border-top-color: ${colors('success')};
        ${oddUpdateAnimation()};
      }
      .decrease&::before {
        bottom: 0;
        border-right-color: ${colors('danger')};
        border-bottom-color: ${colors('danger')};
        ${oddUpdateAnimation()};
      }
    `};

  ${({ isLocked }) =>
    isLocked &&
    css`
      background-color: ${colors('bettingOutcomeLocked')};
      border-color: ${colors('bettingOutcomeLockedBorder')};
      path {
        fill: ${getReadableColor('bettingOutcomeLocked')};
      }
      ${device.canHover} {
        &:hover,
        &:active {
          background-color: ${colors('bettingOutcomeLocked')};
          border-color: ${colors('bettingOutcomeLockedBorder')};
        }
      }
    `};
`;
