import * as t from 'io-ts';

import {
  EAcceptLegalPopupResponse,
  EShowLegalPopupResponse,
} from '@gaming1/g1-requests';

export const acceptLegalModalResponse = t.intersection(
  [
    t.type({
      AcceptLegalPopupStatus: t.literal(EAcceptLegalPopupResponse.Successful),
    }),
    t.partial({
      LastDatePopupAccepted: t.string,
    }),
  ],
  'AcceptLegalModalResponse',
);

export const requestDTOCodec = t.type({
  IdRequest: t.string,
  Identifier: t.string,
  Content: t.string,
});

export const showLegalModalResponse = t.intersection(
  [
    t.type({
      ShowLegalPopupStatus: t.literal(EShowLegalPopupResponse.Successful),
      ShowLegalPopup: t.boolean,
    }),
    t.partial({
      RequestsAborted: t.array(requestDTOCodec),
    }),
  ],
  'ShowLegalModalResponse',
);
