import React, { FC, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  actions,
  getAvailablePromotionsListSelector,
  getAvailablePromotionsRequestStateSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { userLoggedInSelector } from '@gaming1/g1-core';
import { RequestErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';

import { WrappedLoader } from '../../../common/components/WrappedLoader';
import { Fallback } from '../../../gifts/components/Fallback';
import { PromotionsList } from '../PromotionsList';

type PageContentHandlerProps = {
  publicPromotionsOnly?: boolean;
};

export const PageContentHandler: FC<PageContentHandlerProps> = ({
  publicPromotionsOnly = false,
}) => {
  const { t } = useTranslation('betting');
  const { isLoading, isFailing } = useRequestState(
    getAvailablePromotionsRequestStateSelector,
  );
  const dispatch = useDispatch();
  const promotionsList = useBettingGetterSelector({
    getterSelector: getAvailablePromotionsListSelector,
    args: [publicPromotionsOnly],
    equalityFn: shallowEqual,
  });
  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const fetchData = useCallback(() => {
    dispatch(actions.getAvailablePromotions.request({}));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData, isUserLoggedIn]);

  if (isLoading) {
    return <WrappedLoader />;
  }

  if (isFailing) {
    return (
      <RequestErrorMessage
        errorMessage={t('betting.requestfail.error')}
        onRetryButtonClick={fetchData}
      />
    );
  }

  if (promotionsList.length > 0) {
    return <PromotionsList list={promotionsList} />;
  }

  return (
    <Fallback
      primaryMessage="promotions.contentPage.notConfigured"
      secondaryMessage="promotions.contentPage.recommandation"
    />
  );
};
