import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardSportStatsSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardCompactBackgroundWrapper as BackgroundWrapper,
  ScoreBoardCompactTemplateItem as TemplateItem,
  ScoreBoardCompactTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the compact scoreboard with a cricket template */
export const ScoreBoardCompactCricketTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
}) => {
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: dismissals1, team2: dismissals2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Dismissals'],
    equalityFn: shallowEqual,
  });

  return (
    <TemplateWrapper data-testid="scoreboard-compact-cricket-template">
      <BackgroundWrapper backgroundRadius="bothSides">
        <TemplateItem isHighlighted>
          {[score1, dismissals1].join('/')}
        </TemplateItem>
        <TemplateItem isHighlighted>
          {[score2, dismissals2].join('/')}
        </TemplateItem>
      </BackgroundWrapper>
    </TemplateWrapper>
  );
};
