import React, { VFC } from 'react';

import {
  BettingHistoryTicketType,
  BoostusHistoryInfoType,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useFormatNumber } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { multiplyBy100 } from '../../../../boostus/helpers';
import { BettingIcon } from '../../../../common/components/BettingIcon';
import { OpacityContainer } from '../../styles';
import { UpdatedOdds } from '../HistoryCardParts/UpdatedOdds';

import { HistoryExpandedGiftContainer } from './styles';

type ExpandedExtraInfoBoostusProps = {
  boostus: BoostusHistoryInfoType;
  history: BettingHistoryTicketType;
};
export const ExpandedExtraInfoBoostus: VFC<ExpandedExtraInfoBoostusProps> = ({
  boostus,
  history,
}) => {
  const { betting } = useConfig();
  const { t } = useTranslation('betting');
  const formatNumber = useFormatNumber();

  const updateOddsOrWinnings = betting.boostus.applyTo;

  return boostus ? (
    <HistoryExpandedGiftContainer>
      {boostus.IsValid ? (
        <BettingIcon
          id="BoostusGradient"
          type="BoostusGradient"
          width={20}
          height={20}
        />
      ) : (
        <OpacityContainer data-testid="invalid-icon-boostus-container">
          <BettingIcon id="BoostusGradient" type="BoostusGradient" />
        </OpacityContainer>
      )}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text ml="xxs" mt={0} fontSize="md" mr="md">
          {boostus?.Name}{' '}
          {t('history.boostus.boostValue', {
            boostValue: formatNumber(multiplyBy100(boostus.BoostValue), 0),
          })}
        </Text>
        {boostus.IsValid ? (
          <UpdatedOdds
            baseOdds={history.BaseOdds}
            finalOdds={history.FinalOdds}
            updateType={updateOddsOrWinnings}
            id="boostus"
          />
        ) : (
          <Text mt={0} fontSize="md">
            {t('boostus.history.notValid')}
          </Text>
        )}
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
