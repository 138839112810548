import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import parseISO from 'date-fns/parseISO';

import { useConfig } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';

/** Returns the date information used in prematch scoreboards */
export const useScoreBoardPrematchDate = (startDateString?: string) => {
  const { i18n } = useConfig();
  const { t } = useTranslation('betting');

  if (startDateString) {
    const startDate = parseISO(startDateString);

    const date =
      (isToday(startDate) && t('score.boardPrematch.today')) ||
      (isTomorrow(startDate) && t('score.boardPrematch.tomorrow')) ||
      format(startDate, i18n.dateFormat);

    const hour = format(startDate, i18n.timePartialFormat);

    return { date, hour };
  }

  return null;
};
