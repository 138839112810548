import { useRef, useState, VFC } from 'react';

import { CheckboxInput, Icon, Popover, SimpleButton } from '@gaming1/g1-ui';

import { HelpPopoverContent } from '../styles';
import { RowFilterFunc, RowFilterHook } from '../types';

type RecurringPacketsFilterProps = {
  isToggled: boolean;
  onToggle: () => void;
};

const RecurringPacketsFilter: VFC<RecurringPacketsFilterProps> = ({
  isToggled,
  onToggle,
}) => {
  const infoRef = useRef<null | HTMLButtonElement>(null);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  return (
    <>
      <CheckboxInput
        removeMarginTop
        name="keepalive"
        label="Filter out recurring packets"
        checked={isToggled}
        onChange={onToggle}
      />
      <SimpleButton
        ref={infoRef}
        style={{ marginLeft: 4 }}
        onClick={() => setIsInfoVisible((previousValue) => !previousValue)}
      >
        <Icon type="CircleQuestion" id="network-debug-info" />
      </SimpleButton>
      <Popover
        containerRef={infoRef}
        testId="network-debug-popover"
        horizontalPosition="center"
        verticalPosition="bottom"
        visible={isInfoVisible}
      >
        <HelpPopoverContent>
          Will filter out packets of type &quot;KeepAlivePacket&quot; and
          requests with name &quot;GetGoogleTagManager&quot; and
          &quot;GetPlayerCredit&quot;
        </HelpPopoverContent>
      </Popover>
    </>
  );
};

const filterByRecurringPackets =
  (shouldFilter: boolean): RowFilterFunc =>
  ({ name, type }) =>
    shouldFilter
      ? type !== 'KeepAlivePacket' &&
        name !== 'GetGoogleTagManager' &&
        name !== 'GetPlayerCredit'
      : true;

export const useRecurringPacketsFilter: RowFilterHook = () => {
  const [shouldFilterRecuringPackets, setShouldFilterRecuringPackets] =
    useState(true);

  const manageToggle = () =>
    setShouldFilterRecuringPackets((previousValue) => !previousValue);

  const component = (
    <RecurringPacketsFilter
      isToggled={shouldFilterRecuringPackets}
      onToggle={manageToggle}
    />
  );

  const filter = filterByRecurringPackets(shouldFilterRecuringPackets);

  return { filter, component };
};
