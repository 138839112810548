import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { COMMON_PRISMIC_AJAX_CONFIG } from '../../common/constants';
import { getPrismicLocale, prismicQueryToString } from '../../helpers';
import { prismicReferenceSelector } from '../../prismicReference/store/selectors';
import { CMSEpic } from '../../store/types';

import * as actions from './actions';
import {
  blogArticlesResponseCodec,
  blogConfigurationResponseCodec,
} from './codecs';
import {
  formatFetchBlogArticlesResponse,
  isFetchBlogArticlesRequestPayload,
} from './helpers';
import { BlogArticlesRequest, BlogConfigurationRequest } from './types';

/**
 * Epic to fetch blog articles from Prismic (filtered by date and tag)
 */
export const blogArticlesEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(
      isActionOf([
        actions.fetchBlogArticles.request,
        actions.fetchRecentBlogArticles.request,
      ]),
    ),
    withLatestFrom(config$, state$),
    mergeMap(([action, config, state]) => {
      const currentLocale = getPrismicLocale(
        currentWebSocketLocaleSelector(state),
      );
      const { prismicReference } = prismicReferenceSelector(state);
      if (!prismicReference) {
        throw new Error('ERROR : Prismic reference not set');
      }
      let query = '[]';
      if (isFetchBlogArticlesRequestPayload(action.payload)) {
        query = prismicQueryToString({
          predicate: 'at',
          path: 'document.tags',
          value: [action.payload.tag.name],
        });
      } else {
        query = prismicQueryToString({
          predicate: 'any',
          path: 'document.tags',
          value: action.payload.tags?.map((t) => t.name) || [],
        });
      }
      const queryParams: BlogArticlesRequest = {
        page: action.payload.pageNum,
        pageSize: action.payload.pageSize,
        lang: currentLocale,
        ref: prismicReference,
        q: query,
        orderings: '[document.first_publication_date desc]',
      };
      return ajaxFetch(`${config.network.prismicUrl}/documents/search`, {
        ...COMMON_PRISMIC_AJAX_CONFIG,
        queryParams,
      }).pipe(
        mapGuard(blogArticlesResponseCodec),
        map((response) =>
          formatFetchBlogArticlesResponse(response, action.payload),
        ),
        map((payload) =>
          isFetchBlogArticlesRequestPayload(action.payload)
            ? actions.fetchBlogArticles.success(payload)
            : actions.fetchRecentBlogArticles.success(payload),
        ),
        catchError((err) =>
          of(
            isFetchBlogArticlesRequestPayload(action.payload)
              ? actions.fetchBlogArticles.failure({
                  data: createFailurePayload(
                    err,
                    undefined,
                    true,
                    'core:error.fetchPrismicBlogArticles',
                  ),
                  request: action.payload,
                })
              : actions.fetchRecentBlogArticles.failure({
                  data: createFailurePayload(
                    err,
                    undefined,
                    true,
                    'core:error.fetchPrismicBlogArticles',
                  ),
                  request: action.payload,
                }),
          ),
        ),
      );
    }),
  );

/**
 * Epic to fetch the Prismic 'BlogArticles Configuration' document
 */
export const blogConfigurationEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchBlogConfiguration.request)),
    withLatestFrom(config$, state$),
    switchMap(([, config, state]) => {
      const currentLocale = getPrismicLocale(
        currentWebSocketLocaleSelector(state),
      );
      const { prismicReference } = prismicReferenceSelector(state);
      if (!prismicReference) {
        throw new Error('ERROR : Prismic reference not set');
      }
      const queryParams: BlogConfigurationRequest = {
        lang: currentLocale,
        ref: prismicReference,
        q: prismicQueryToString({
          predicate: 'at',
          path: 'document.type',
          value: 'blog_configuration',
        }),
      };
      return ajaxFetch(`${config.network.prismicUrl}/documents/search`, {
        ...COMMON_PRISMIC_AJAX_CONFIG,
        queryParams,
      }).pipe(
        mapGuard(blogConfigurationResponseCodec),
        map((response) => response.results[0].data),
        map(actions.fetchBlogConfiguration.success),
        catchError((err) =>
          of(
            actions.fetchBlogConfiguration.failure(
              createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicBlogConfiguration',
              ),
            ),
          ),
        ),
      );
    }),
  );

/**
 * Epic to fetch a Prismic blogArticle document by its UID
 */
export const blogArticleByUidEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchBlogArticleByUid.request)),
    withLatestFrom(config$, state$),
    switchMap(([action, config, state]) => {
      const currentLocale = getPrismicLocale(
        currentWebSocketLocaleSelector(state),
      );
      const { prismicReference } = prismicReferenceSelector(state);
      if (!prismicReference) {
        throw new Error('ERROR : Prismic reference not set');
      }
      const queryParams: BlogConfigurationRequest = {
        lang: currentLocale,
        ref: prismicReference,
        q: prismicQueryToString({
          predicate: 'at',
          path: 'my.blog_article.uid',
          value: action.payload.uid,
        }),
      };
      return ajaxFetch(`${config.network.prismicUrl}/documents/search`, {
        ...COMMON_PRISMIC_AJAX_CONFIG,
        queryParams,
      }).pipe(
        mapGuard(blogArticlesResponseCodec),
        map((response) => response.results[0]),
        map(actions.fetchBlogArticleByUid.success),
        catchError((err) =>
          of(
            actions.fetchBlogArticleByUid.failure(
              createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicBlogArticles',
              ),
            ),
          ),
        ),
      );
    }),
  );
