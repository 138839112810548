import styled, { css } from 'styled-components';

import { colors, fontSizes, media, spaces } from '@gaming1/g1-style';
import { Box, Text } from '@gaming1/g1-ui';

import { ViewStyle } from '../../HistoryEvent/types';

export const HEADER_ICON_WIDTH_IN_PX = '24';
export const HEADER_ICON_HEIGHT_IN_PX = '24';

export const ClickableContainer = styled(Box)`
  cursor: pointer;
`;

export const ClickableSummaryContainer = styled(ClickableContainer)`
  border-top: 1px solid ${colors('footerBorder')};
  flex-direction: row;
  justify-content: flex-start;
  font-size: ${fontSizes('md')};
`;

export const HistoryExpandedCardContainer = styled(Box)`
  min-height: 64px;
  padding: ${spaces('sm')} ${spaces('xs')};
  margin-top: ${spaces('xs')};
  border-radius: ${spaces('xxxs')};
  background-color: ${colors('secondary', { index: 5 })};
`;

export type MobileForcable = {
  hasMobileView?: boolean;
  view?: ViewStyle;
};

export const HistoryStakeContainer = styled(Box)<MobileForcable>`
  margin: 0 ${spaces('sm')};

  &:first-child {
    margin-left: 0px;
  }

  ${({ hasMobileView }) =>
    !hasMobileView &&
    css`
      ${media.lg} {
        margin: 0 ${spaces('xl')};

        &:first-child {
          margin-left: 0px;
        }
      }
    `}
`;

export const HistoryStrikeThroughTextForBoostus = styled(Text)`
  margin-top: 0;
  font-size: ${fontSizes('md')};
  text-decoration: line-through;
`;

export const NoWinningsText = styled.p`
  align-self: center;
  margin-top: ${spaces('xxs')};
`;
