import React, { useEffect, useState, VFC } from 'react';
import { FontSizeProps, TextAlignProps } from 'styled-system';

import { Box, Text } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../common/components/BettingIcon';

import { getFormattedTime } from './helpers';
import {
  useScoreBoardPlayTimeData,
  useScoreBoardPlayTimeTranslation,
} from './hooks';
import { ScoreBoardPlayTimeWrapper } from './styles';

export type ScoreBoardPlayTimeProps = {
  /** Text that will be append at the end of the play time text */
  additionalText?: string;
  /** Event id that will be used to retrieve the scoreboard */
  eventId: number;
  /** Whether the live icon should be displayed or not (default: true) */
  liveIcon?: boolean;
} & FontSizeProps &
  TextAlignProps;

/** Displays the scoreboard play time */
export const ScoreBoardPlayTime: VFC<ScoreBoardPlayTimeProps> = ({
  additionalText = '',
  eventId,
  liveIcon = true,
  ...styledProps
}) => {
  const { counter, time: baseTime } = useScoreBoardPlayTimeData(eventId) || {};
  const translation = useScoreBoardPlayTimeTranslation(eventId);

  const [time, setTime] = useState<number | null | undefined>(null);
  const formattedTime = time != null ? getFormattedTime(time) : '';

  useEffect(() => {
    let interval: NodeJS.Timeout;
    setTime(baseTime);
    if (counter && baseTime) {
      interval = setInterval(() => {
        setTime((t) => t && (counter === 'forward' ? t + 1 : t - 1));
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseTime, counter]);

  const playTimeText = [translation, formattedTime]
    .filter((value) => !!value)
    .join(' - ');

  return liveIcon || playTimeText ? (
    <ScoreBoardPlayTimeWrapper
      alignItems="center"
      flexDirection="row"
      data-testid="score-board-play-time"
    >
      {liveIcon && (
        <Box data-testid="score-board-play-time-icon" mr="xs">
          <BettingIcon id="ScoreBoardIconLive" type="ScoreBoardIconLive" />
        </Box>
      )}
      {playTimeText && (
        <Text
          data-testid="score-board-play-time-text"
          fontSize="sm"
          mt={0}
          {...styledProps}
        >
          {[playTimeText, additionalText]
            .filter((value) => !!value)
            .join(' : ')}
        </Text>
      )}
    </ScoreBoardPlayTimeWrapper>
  ) : null;
};
