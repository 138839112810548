import React, { memo } from 'react';

import { Box } from '@gaming1/g1-ui';

import { MultiColumns } from './MultiColumns';
import { OneColumn } from './OneColumn';

type LayoutCompoundComponents = {
  OneCol: typeof OneColumn;
  MultiCol: typeof MultiColumns;
};

export const Layout: LayoutCompoundComponents = ({ ...props }) => (
  <Box {...props} />
);

Layout.OneCol = memo(OneColumn);
Layout.MultiCol = memo(MultiColumns);
