import styled from 'styled-components';

import { colors, media } from '@gaming1/g1-style';
import { Box, FLUID_CONTENT_MAX_WIDTH } from '@gaming1/g1-ui';

export const SportBookContainer = styled(Box)`
  position: relative;
  background-color: ${colors('backgrounds', { index: 0 })};
  display: flex;
  width: 100%;
  margin: 0 auto;

  ${media.xxl} {
    /* Passed this breakpoint, the content will stop being in full width */
    max-width: ${FLUID_CONTENT_MAX_WIDTH}px;
  }
`;
