/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:26:32 08-02-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  PurchaseScratchCardRequestDTO,
  ScratchCardPurchaseResponseDTO,
} from '../entities';

export const purchaseScratchCard = (options: PurchaseScratchCardRequestDTO) =>
  createRequest<ScratchCardPurchaseResponseDTO>(
    102,
    'PurchaseScratchCard',
    options,
  );
