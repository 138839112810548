import React, { memo, VFC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { MenuGroups, ScrollNav, useIsUserLoggedIn } from '@gaming1/g1-core-web';

import { bettingRoutes } from '../../../routes';
import { OddsUnits } from '../OddsUnits';

import { useIsDesktopNavigation, useMenuLists } from './hooks';

/**
 * Return a ScrollNav menu, be careful that (for now) it only works if the word
 * "home" is present in the URL, as an example .../betting/myBets won't have a scrollnav
 * but .../betting/home/myBets will.
 */
const BettingMenuComponent: VFC = () => {
  const isDesktop = useIsDesktopNavigation();
  const { pathname } = useLocation();

  const isBettingHome = Boolean(
    matchPath(pathname, {
      path: `*${bettingRoutes.bettingHome.path}`,
      strict: true,
      exact: false,
    }),
  );

  const isUserLoggedIn = useIsUserLoggedIn();

  const shouldDisplayTrends = isBettingHome && !isDesktop;

  const {
    mainMenuList,
    quickLinksMenuList,
    mainAuthenticatedMenuList,
    trendsMenuList,
  } = useMenuLists();

  const scrollNavMenu: MenuGroups = [
    shouldDisplayTrends ? trendsMenuList : [],
    isDesktop ? mainMenuList : quickLinksMenuList,
    isUserLoggedIn ? mainAuthenticatedMenuList : [],
  ].filter((group) => group.length > 0);

  return (
    <ScrollNav
      layout={isDesktop ? 'menu' : 'altMenu'}
      menu={scrollNavMenu}
      shouldStick={false}
    >
      {isDesktop && <OddsUnits type="select" />}
    </ScrollNav>
  );
};

export const BettingMenu = memo(BettingMenuComponent);
