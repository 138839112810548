import React, { FC, useContext } from 'react';

import { PrismicButtonsSlice } from '@gaming1/g1-cms';

import { getItemKey } from '../../helpers';
import { PrismicButton } from '../PrismicButton';
import { PrismicLink } from '../PrismicLink';
import { PrismicPageContext } from '../PrismicPageContext';
import { PrismicWrapper } from '../styles';

import { getFlexJustifyContentFromAlignment } from './helpers';
import { Container } from './styles';

export type PrismicButtonsProps = {
  /** Prismic 'Buttons' slice */
  slice: PrismicButtonsSlice;
  /** Test ID */
  testId?: string;
};

/**
 * This component renders a prismic `Buttons` slice
 * An element containing multiple buttons/links
 */
export const PrismicButtons: FC<PrismicButtonsProps> = ({
  slice: block,
  testId = 'prismic-buttons',
}) => {
  const { isUserLoggedIn } = useContext(PrismicPageContext);
  if (block.primary.enabled === false) {
    return null;
  }

  return (
    <PrismicWrapper>
      <Container
        data-testid={testId}
        justifyContent={getFlexJustifyContentFromAlignment(
          block.primary.block_alignment,
        )}
        alignItems="center"
      >
        {block.items.map((item, index) => {
          if (
            (isUserLoggedIn !== true && item.visible_not_logged) ||
            (isUserLoggedIn === true && item.visible_logged)
          ) {
            return item.type === 'Button' ? (
              <PrismicButton
                key={`prismic-button-${getItemKey(item.text) || index}`}
                colorType={item.color}
                link={item.link.url}
                target={item.link.target || ''}
                testId="prismic-button"
              >
                {item.text}
              </PrismicButton>
            ) : (
              <PrismicLink
                key={`prismic-link-${getItemKey(item.text) || index}`}
                link={item.link.url}
                target={item.link.target || ''}
                testId="prismic-link"
              >
                {item.text}
              </PrismicLink>
            );
          }
          return null;
        })}
      </Container>
    </PrismicWrapper>
  );
};
