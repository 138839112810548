import format from 'date-fns/format';
import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  getCashoutDataGetterSelector,
  hasCashoutEntityGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Icon, Text } from '@gaming1/g1-ui';

import { OpacityContainer } from '../../../styles';
import {
  EXTRA_ICON_HEIGHT_IN_PX,
  EXTRA_ICON_WIDTH_IN_PX,
  ExtraText,
  HistoryExpandedGiftContainer,
} from '../styles';

type ExpandedExtraInfoCashoutProps = {
  cashoutId: string;
};

export const ExpandedExtraInfoCashoutV2: VFC<ExpandedExtraInfoCashoutProps> = ({
  cashoutId,
}) => {
  const { i18n } = useConfig();
  const { t } = useTranslation('betting');
  const formatMoney = useFormatMoney();

  const { colors } = useTheme();

  const isCashable = useBettingGetterSelector({
    getterSelector: hasCashoutEntityGetterSelector,
    args: [cashoutId],
  });

  const cashoutData = useBettingGetterSelector({
    getterSelector: getCashoutDataGetterSelector,
    args: [cashoutId],
    equalityFn: shallowEqual,
  });

  return isCashable && cashoutData ? (
    <HistoryExpandedGiftContainer data-testid="expanded-card-cashout">
      <Icon
        id="Cashout"
        type="Cashout"
        color={colors.bettingCashout}
        height={EXTRA_ICON_HEIGHT_IN_PX}
        width={EXTRA_ICON_WIDTH_IN_PX}
      />
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ExtraText>
          {t('history.expanded.cashout.label', {
            cashoutValue: formatMoney(cashoutData.Amount),
          })}
        </ExtraText>
        <OpacityContainer>
          <Text mt={0} fontSize="md">
            {format(new Date(cashoutData.Date), i18n.dateFormat)} -{' '}
            {format(new Date(cashoutData.Date), i18n.timePartialFormat)}
          </Text>
        </OpacityContainer>
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
