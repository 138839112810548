import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { SportbookState } from '../types';

const liveEventsCountInitialState: SportbookState['liveEventsCount'] = 0;

export const liveEventsCountReducer = (
  state = liveEventsCountInitialState,
  action: BettingActions,
) =>
  produce(state, () => {
    switch (action.type) {
      case getType(actions.getLiveEventsCount.success):
        return action.payload;

      case getType(actions.updateLiveEventsCount):
        return action.payload;

      default: // Immer will automatically return the state
    }
  });
