import { EActorAuthenticationStatus } from '@gaming1/g1-requests';

export const loginErrorMessages = {
  [EActorAuthenticationStatus.InternalError]: 'core:error.internalServer', // 0
  [EActorAuthenticationStatus.ActorNotFound]: 'user:login.message.userNotFound', // 2
  [EActorAuthenticationStatus.IncorrectPassword]:
    'user:login.message.incorrectPassword', // 3
  [EActorAuthenticationStatus.ActorSuspended]:
    'user:login.message.userSuspended', // 4
  [EActorAuthenticationStatus.ActorDeleted]: 'user:login.message.userDeleted', // 5
  [EActorAuthenticationStatus.TooMuchAttempts]:
    'user:login.message.tooMuchAttempts', // 6
  [EActorAuthenticationStatus.LoginMandatory]: 'user:login.message.emptyFields', // 8
  [EActorAuthenticationStatus.PasswordMandatory]:
    'user:login.message.emptyFields', // 9
  [EActorAuthenticationStatus.InvalidRoom]: 'core:error.invalidRoom', // 10
  [EActorAuthenticationStatus.RedirectToRegistration]:
    'core:error.redirectToRegistration', // 11
  [EActorAuthenticationStatus.RegistrationOnlyInRoom]:
    'core:error.registrationOnlyInRoom', // 12
  [EActorAuthenticationStatus.ConnectionNumberLimitReached]:
    'core:error.connectionNumberLimitReached', // 13
  [EActorAuthenticationStatus.ConnectionTimeLimitReached]:
    'core:error.connectionTimeLimitReached', // 14
  [EActorAuthenticationStatus.BadBirthDate]: 'core:error.badBirthDate', // 15
  [EActorAuthenticationStatus.Blacklisted]: 'core:error.blacklisted', // 16
  [EActorAuthenticationStatus.PlayerAutoExclude]:
    'core:error.playerAutoExclude', // 17
  [EActorAuthenticationStatus.TooManyFailedDeposit]:
    'core:error.tooManyFailedDeposit', // 18
  [EActorAuthenticationStatus.FrozenAssetsExclude]:
    'user:login.message.frozenAssetsExclude', // 19
  [EActorAuthenticationStatus.CloseAccount]: 'user:login.message.closeAccount', // 20
  [EActorAuthenticationStatus.IsInCoolOffAfterAutoReactivation]:
    'user:login.message.isInCoolOffAfterAutoReactivation', // 21
  [EActorAuthenticationStatus.AlmostReachedTooManyFailedLogin]:
    'user:login.message.almostReachedTooManyFailedLogin', // 22
};
