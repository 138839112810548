import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';
import {
  generateFailureRequestState,
  generateInitialRequestState,
  generateLoadingRequestState,
  generatePaginatedInitialRequestState,
  generateSuccessRequestState,
  produceFailureState,
  produceLoadingState,
} from '@gaming1/g1-utils';

import { CmsActions } from '../../store/types';

import * as actions from './actions';
import { BlogState } from './types';

export const initialState: BlogState = {
  groupsRequests: {},
  recentRequest: generatePaginatedInitialRequestState([]),
  configurationRequest: generateInitialRequestState([]),
  configuration: { tags: [] },
  articleRequest: generateInitialRequestState(),
  articlesByUid: {},
};

export const blogReducer = (
  state: BlogState = initialState,
  action: CmsActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchBlogArticles.request):
        if (!draftState.groupsRequests[action.payload.tag.name]) {
          draftState.groupsRequests[action.payload.tag.name] =
            generatePaginatedInitialRequestState([]);
        }
        produceLoadingState(draftState.groupsRequests[action.payload.tag.name]);
        break;

      case getType(actions.fetchBlogArticles.failure):
        if (!draftState.groupsRequests[action.payload.request.tag.name]) {
          draftState.groupsRequests[action.payload.request.tag.name] =
            generatePaginatedInitialRequestState([]);
        }
        produceFailureState(
          draftState.groupsRequests[action.payload.request.tag.name],
          action.payload.data,
        );
        break;

      case getType(actions.fetchBlogArticles.success):
        {
          const { tag, uids, pagination, articles } = action.payload;
          if (tag) {
            const currentUids = draftState.groupsRequests[tag]?.result || [];
            draftState.groupsRequests[tag] = generateSuccessRequestState(
              [...currentUids, ...uids],
              pagination,
            );
            draftState.articlesByUid = {
              ...draftState.articlesByUid,
              ...articles,
            };
          }
        }
        break;

      case getType(actions.fetchRecentBlogArticles.request):
        produceLoadingState(draftState.recentRequest);
        break;

      case getType(actions.fetchRecentBlogArticles.failure):
        produceFailureState(draftState.recentRequest, action.payload.data);
        break;

      case getType(actions.fetchRecentBlogArticles.success):
        {
          const { uids, pagination, articles } = action.payload;
          const currentUids = draftState.recentRequest.result || [];
          draftState.recentRequest = generateSuccessRequestState(
            [...currentUids, ...uids],
            pagination,
          );
          draftState.articlesByUid = {
            ...draftState.articlesByUid,
            ...articles,
          };
        }
        break;

      case getType(actions.fetchBlogConfiguration.request):
        draftState.configurationRequest = generateLoadingRequestState();
        break;

      case getType(actions.fetchBlogConfiguration.failure):
        draftState.configurationRequest = generateFailureRequestState(
          action.payload,
        );
        break;

      case getType(actions.fetchBlogConfiguration.success):
        draftState.configurationRequest = generateSuccessRequestState();
        draftState.configuration = action.payload;
        // For each tag, initialize a blogArticles group
        action.payload.tags.forEach((tag) => {
          draftState.groupsRequests[tag.name] =
            generatePaginatedInitialRequestState([]);
        });
        break;

      case getType(actions.fetchBlogArticleByUid.request):
        draftState.articleRequest = generateLoadingRequestState();
        break;

      case getType(actions.fetchBlogArticleByUid.failure):
        draftState.articleRequest = generateFailureRequestState(action.payload);
        break;

      case getType(actions.fetchBlogArticleByUid.success):
        draftState.articleRequest = generateSuccessRequestState();
        draftState.article = action.payload;
        break;

      case getType(actions.fetchBlogArticleByUid.cancel):
        draftState.articleRequest = generateInitialRequestState();
        break;

      default: // Immer will automatically returns the state
    }
  });
