import * as t from 'io-ts';

import { game } from '../../common/store/codecs';
import { gameAttributeCategoryAPI } from '../../gameList/store/codecs';

export const searchGamesResponse = t.intersection(
  [
    t.type({
      PageNumber: t.number,
      PageSize: t.number,
      Total: t.number,
      Status: t.number,
      List: t.array(game),
      AttributeCategoriesList: t.array(gameAttributeCategoryAPI),
    }),
    t.partial({
      GameRange: t.string,
    }),
  ],
  'SearchGamesResponse',
);
