import React, { createContext, FC, memo, useMemo } from 'react';

import {
  IframeMessageManager,
  iframeMessageManager as originalIframeMessageManager,
} from '../../iframeMessageManager';

import {
  useGeolocationMessages,
  useHistoryUpdateMessage,
  useNativeTracking,
  useNavigationDrawerStateChangeMessage,
} from './hooks';

type IframeEventsContextValues = IframeMessageManager & {
  isGeolocationOk: boolean;
};

const iframeEventsContextDefaultValue: IframeEventsContextValues = {
  ...originalIframeMessageManager,
  isGeolocationOk: false,
};

export const IframeEventsContext = createContext<IframeEventsContextValues>(
  iframeEventsContextDefaultValue,
);

type IframeEventsProviderProps = { iframeMessageManager: IframeMessageManager };

/** Provide methods for calling the iframe parent and manage the parent messages */
export const IframeEventsProvider: FC<IframeEventsProviderProps> = memo(
  ({ children, iframeMessageManager }) => {
    const { forwardReduxAction, updateHistory } = iframeMessageManager;

    useHistoryUpdateMessage(iframeMessageManager);
    useNativeTracking(iframeMessageManager);
    useNavigationDrawerStateChangeMessage(iframeMessageManager);

    const { isGeolocationOk } = useGeolocationMessages(iframeMessageManager);

    const contextValue = useMemo(
      () => ({
        ...iframeMessageManager,
        forwardReduxAction,
        updateHistory,
        isGeolocationOk,
      }),
      [
        iframeMessageManager,
        forwardReduxAction,
        updateHistory,
        isGeolocationOk,
      ],
    );

    return (
      <IframeEventsContext.Provider value={contextValue}>
        {children}
      </IframeEventsContext.Provider>
    );
  },
);
