import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  navigationsRequestStateSelector,
  navigationsSelector,
  usePrismicReference,
} from '@gaming1/g1-cms';
import { MenuList, ScrollNav, useRoutePath } from '@gaming1/g1-core-web';
import { getDeployEnv } from '@gaming1/g1-logger';
import { useRequestState } from '@gaming1/g1-store';
import { isNonNullable } from '@gaming1/g1-utils';

import { isCMSRouteName } from '../../helpers';
import { logger } from '../../logger';
import { cmsRoutes } from '../../routes';

type PrismicNavigationProps = {
  /** The navigation you want to render */
  nav: string;
};

export const PrismicNavigation: FC<PrismicNavigationProps> = ({ nav }) => {
  const getCmsRoutePath = useRoutePath(cmsRoutes);

  const dispatch = useDispatch();
  const { isNotAsked } = useRequestState(navigationsRequestStateSelector);
  const navigations = useSelector(navigationsSelector);

  const prismicReference = usePrismicReference();

  const usedNavigation = navigations ? navigations[nav] : undefined;
  useEffect(() => {
    if (prismicReference && isNotAsked) {
      dispatch(actions.fetchNavigations.request(prismicReference));
    }
  }, [dispatch, isNotAsked, prismicReference]);

  useEffect(() => {
    if (nav && !usedNavigation && getDeployEnv() !== 'production') {
      logger.warn(`[CMS] Navigation "${nav}" not found!`);
    }
  }, [usedNavigation, nav]);

  if (usedNavigation && isCMSRouteName(nav)) {
    const navigationMenuItems: MenuList = usedNavigation.items
      .map(({ label, content: { uid } }) =>
        !uid
          ? null
          : ({
              label,
              path: getCmsRoutePath(nav, { content: uid }),
              testId: 'prismic-navigation',
            } as const),
      )
      .filter(isNonNullable);

    return (
      <ScrollNav
        data-testid="prismic-scroll-nav"
        menu={[navigationMenuItems]}
      />
    );
  }
  return null;
};
