import React, { FC, memo } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, Row } from '@gaming1/g1-ui';

import {
  KeyBoardBox,
  KeyBoardBoxContainer,
  KeyBoardBoxWrapper,
  KeyBoardClose,
  KeyBoardCloseWrapper,
} from './styles';
import { KeyboardKey } from './type';

type BettingSlipKeyboardProps = {
  /** Call when a button key box is press */
  keyPress: (key: KeyboardKey) => void;
};

/**
 * Display a inline keyboard with only number, valide button and valide delimiter
 */
export const BettingSlipKeyboardComponent: FC<BettingSlipKeyboardProps> = ({
  keyPress,
}) => {
  const { t } = useTranslation('betting');

  const selection = (key: KeyboardKey) => {
    keyPress(key);
  };

  const close = () => {
    keyPress('ok');
  };

  const getKeys: KeyboardKey[] = [
    '1',
    '2',
    '3',
    '4',
    '5',
    'delimiter',
    '6',
    '7',
    '8',
    '9',
    '0',
    'del',
  ];

  const getKeyContent = (key: KeyboardKey) => {
    if (key === 'del') {
      return <Icon id="betting-numpad-del" type="Erase" ml="xs" />;
    }
    if (key === 'delimiter') {
      return ',';
    }
    return key;
  };
  return (
    <Row data-testid="bettingslip-keyboard-row-container">
      <KeyBoardBoxWrapper xs={10} noGutter>
        <KeyBoardBoxContainer>
          {getKeys.map((item) => (
            <KeyBoardBox
              data-testid="bettingslip-keyboard-row-item"
              hasMaxHeight
              key={item}
              onClick={() => selection(item)}
            >
              {getKeyContent(item)}
            </KeyBoardBox>
          ))}
        </KeyBoardBoxContainer>
      </KeyBoardBoxWrapper>
      <KeyBoardCloseWrapper xs={2} noGutter>
        <KeyBoardClose onClick={() => close()}>
          {t('bettingslip.numpad.keyboard.ok')}
        </KeyBoardClose>
      </KeyBoardCloseWrapper>
    </Row>
  );
};

export const BettingSlipKeyboard = memo(BettingSlipKeyboardComponent);
