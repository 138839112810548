import { createAsyncAction } from 'typesafe-actions';

import {
  ChangeSecurityPinCodeRequestDTO,
  ResetSecurityPinCodeRequestDTO,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  ChangeSecurityPinCodeResponse,
  GetSecurityPinCodeInfoResponse,
  ResetSecurityPinCodeResponse,
} from './types';

/** Get security pin code info */
export const getSecurityPinCodeInfo = createAsyncAction(
  'user/get_security_pin_code_info_request',
  'user/get_security_pin_code_info_success',
  'user/get_security_pin_code_info_failure',
)<void, GetSecurityPinCodeInfoResponse, FailurePayload>();

/** Reset security pin code  */
export const resetSecurityPinCode = createAsyncAction(
  'user/reset_security_pin_code_request',
  'user/reset_security_pin_code_success',
  'user/reset_security_pin_code_failure',
)<
  ResetSecurityPinCodeRequestDTO,
  ResetSecurityPinCodeResponse,
  FailurePayload
>();

/** Change security pin code */
export const changeSecurityPinCode = createAsyncAction(
  'user/change_security_pin_code_request',
  'user/change_security_pin_code_success',
  'user/change_security_pin_code_failure',
)<
  ChangeSecurityPinCodeRequestDTO,
  ChangeSecurityPinCodeResponse,
  FailurePayload
>();
