import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  spaces,
  zIndex,
} from '@gaming1/g1-style';
import { Icon, SimpleButton } from '@gaming1/g1-ui';

import {
  BOTTOM_NAV_HEIGHT,
  MINIMIZED_BOTTOM_NAV_HEIGHT,
} from '../../../styles';

export const BOTTOM_NAV_ICON_SIZE = 24;
export const BOTTOM_NAV_ANIMATION_DURATION_IN_MS = 500;
export const BOTTOM_ELEMENT_CONTAINER_BORDER_RADIUS_IN_PX = 6;
export const BOTTOM_ELEMENT_CONTAINER_POPPED_UP_BORDER_RADIUS_IN_PX = 30;

/* Bottom bar container */

type BottomNavContainerProps = {
  /** Whether the bar is smaller because the label have been hidden */
  isMinimized?: boolean;
  /** When changed, will move the bar up/down to show/hide it */
  isHidden?: boolean;
};

export const BottomNavContainer = styled.div<BottomNavContainerProps>`
  align-items: center;
  background-color: ${colors('bottomNavBackground')};
  bottom: 0;
  box-shadow: 0 -4px 7px 0 rgba(1, 10, 17, 0.6);
  display: flex;
  justify-content: space-around;
  left: 0;
  padding: ${spaces('xxs')} 0;
  position: fixed;
  right: 0;
  transition: transform ${BOTTOM_NAV_ANIMATION_DURATION_IN_MS}ms ease;
  z-index: ${zIndex('bottomnav')};

  height: ${({ isMinimized }) =>
    isMinimized ? MINIMIZED_BOTTOM_NAV_HEIGHT : BOTTOM_NAV_HEIGHT}px;

  ${({ isHidden }) =>
    isHidden &&
    css`
      transform: translateY(${BOTTOM_NAV_HEIGHT}px);
    `}
`;

/* Menu item */

type BottomElementContentWrapperProps = {
  /** When the BottomNav is minimized, activates the highlighting style */
  isHighlighted: boolean;
  /** When the BottomNav is hidden and the highlighted item is popped up */
  isPoppedUp?: boolean;
};

export const BottomElementContainer = styled.div<BottomElementContentWrapperProps>`
  position: relative;
  overflow: hidden;
  border-radius: ${BOTTOM_ELEMENT_CONTAINER_BORDER_RADIUS_IN_PX}px;
  font-size: ${fontSizes('sm')};
  margin: 5% ${spaces('xxs')} 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  flex: 1 1 0;

  transition: ${BOTTOM_NAV_ANIMATION_DURATION_IN_MS}ms ease,
    font-size ${BOTTOM_NAV_ANIMATION_DURATION_IN_MS / 4}ms ease-out;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${colors('bottomNavHighlightedBackground')};
      a,
      button,
      div {
        color: ${colors('bottomNavHighlightedText')};
        font-weight: ${fontWeight('bold')};
        span svg path {
          fill: ${colors('bottomNavHighlightedText')};
        }
      }
    `};

  ${({ isHighlighted }) =>
    !isHighlighted &&
    css`
      a,
      button {
        &.active,
        &:focus,
        &:active,
        &:hover {
          color: ${colors('bottomNavActiveText')};
          span svg path {
            fill: ${colors('bottomNavActiveText')};
          }
        }
      }
    `};

  ${({ isPoppedUp }) =>
    isPoppedUp &&
    css`
      border-radius: ${BOTTOM_ELEMENT_CONTAINER_POPPED_UP_BORDER_RADIUS_IN_PX}px;
      transform: translateY(-${BOTTOM_NAV_HEIGHT * 2}px);
      margin: ${spaces('xxs')};
      flex: auto;
      font-size: ${fontSizes('lg')};
    `}
`;

/* Menu item content */

const BottomNavClickableElementCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  white-space: normal;
  padding: 0 0;
  flex-grow: 1;
  width: 100%;
`;

/** Clickable button (trigger hamburger menu) */
export const BottomNavButton = styled(SimpleButton)`
  ${BottomNavClickableElementCss}
  color: ${colors('bottomNavText')};
`;

type BottomNavLinkProps = {
  isPoppedUp?: boolean;
};

/** Clickable link (nav to a route) */
export const BottomNavLink = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['isPoppedUp'].includes(prop),
})<BottomNavLinkProps>`
  ${BottomNavClickableElementCss}
  text-decoration: none;
  color: ${colors('bottomNavText')};

  ${({ isPoppedUp, theme }) =>
    isPoppedUp &&
    css`
      height: auto;
      width: auto;
      flex-direction: row;
      white-space: nowrap;
      padding: ${theme.space.xs}px ${theme.space.sm}px;
      color: green;
    `}
`;

export const BottomElementContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

/* Icon */

export const BottomNavIconContainer = styled.span`
  position: relative;
  height: ${BOTTOM_NAV_ICON_SIZE}px;
  width: ${BOTTOM_NAV_ICON_SIZE}px;
`;

export const BottomNavIconText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  margin: 0;
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSizes('sm')};
`;

export const BottomNavIcon = styled(Icon).attrs({
  width: BOTTOM_NAV_ICON_SIZE,
  height: BOTTOM_NAV_ICON_SIZE,
})`
  font-size: ${fontSizes('xxl')};
`;

/* Label */

export const BottomNavLabel = styled.div`
  text-align: center;
  margin-top: ${spaces('xxs')};
`;

/** Add a transparent padding at the bottom of the content to prevent the
 * always visible BottomNav to hide its content
 */
export const BottomSpacer = styled.div.attrs({
  'data-testid': 'bottom-nav-spacer',
})`
  height: ${BOTTOM_NAV_HEIGHT}px;
`;
