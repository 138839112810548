import { useDispatch, useSelector } from 'react-redux';

import { coreActions, currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Button, Col, Heading, Row } from '@gaming1/g1-ui';

import { useLocaleSwitch } from '../../../i18n/hooks';

import { ConfigDebugger } from './ConfigDebugger';
import { EnvDebugger } from './EnvDebugger';
import { LoggersDebugger } from './LoggersDebugger';

/**
 * Show info and controls about environment, loggers and config
 */
export const CoreDebugger = () => {
  const dispatch = useDispatch();
  const disabledDebugMode = () => dispatch(coreActions.setDebugMode(false));
  const { i18n } = useTranslation();
  const { switchLocale } = useLocaleSwitch();
  const webSocketLocale = useSelector(currentWebSocketLocaleSelector);
  const areTranslationsBeingDebugged = i18n.language === 'cimode';

  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={8}>
          <Heading level="h1">Core debugging</Heading>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Button
            type="danger"
            onClick={disabledDebugMode}
            block={false}
            mt="xs"
          >
            Disable debug mode
          </Button>
        </Col>
      </Row>
      <Heading level="h2" mb="sm">
        Env
      </Heading>
      <EnvDebugger />
      <Heading level="h2">Loggers</Heading>
      <LoggersDebugger />
      <Heading level="h2">Internationalisation</Heading>
      <Button
        mt="sm"
        type="primary"
        onClick={() =>
          switchLocale(
            areTranslationsBeingDebugged ? webSocketLocale : 'cimode',
          )
        }
      >
        {areTranslationsBeingDebugged
          ? `Revert back to ${webSocketLocale}`
          : 'Disable translations'}
      </Button>
      <Heading level="h2" mb="sm">
        Config
      </Heading>
      <ConfigDebugger />
    </>
  );
};
