import * as t from 'io-ts';

import {
  ECivility,
  EGetCreditStatus,
  EGetPlayerProfileStatus,
  EPhoneValidationStatus,
  EPlayerIdentityStatus,
  EPlayerProfileProperty,
  PlayerSegments,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const stateCodec = t.partial(
  {
    Id: t.string,
    IsoCode: t.string,
    Name: t.string,
  },
  'State',
);

export const countryCodec = t.type(
  {
    IsoCode: t.string,
    Name: t.string,
  },
  'Country',
);

const addressCodec = t.partial(
  {
    City: t.string,
    Country: t.union([countryCodec, t.type({})]),
    HasHouseNumber: t.boolean,
    Line1: t.string,
    Line2: t.string,
    Municipality: t.string,
    State: stateCodec,
    Province: t.string,
    ZipCode: t.string,
  },
  'Address',
);

export const userProfile = t.intersection(
  [
    t.type({
      Status: t.literal(EGetPlayerProfileStatus.Successful),
      Civility: createEnumType<ECivility>(ECivility, 'ECivility'),
      Birthday: t.string,
      HasAlreadyDeposited: t.boolean,
    }),
    t.partial({
      AccountNumberBIC: t.string,
      AccountNumberIBAN: t.string,
      Address: addressCodec,
      BirthCity: t.string,
      BirthCountry: t.union([countryCodec, t.type({})]),
      ConditionsAcceptedDate: t.string,
      Email: t.string,
      EditableFieldsList: t.array(
        createEnumType<EPlayerProfileProperty>(
          EPlayerProfileProperty,
          'EPlayerProfileProperty',
        ),
      ),
      FirstName: t.string,
      FullName: t.string,
      Id: t.string,
      Language: t.string,
      LastConnection: t.string,
      LastName: t.string,
      Message: t.string,
      NbOfDeposit: t.number,
      PhoneNumber2: t.string,
      PhoneNumber: t.string,
      PhoneValidationStatus: createEnumType<EPhoneValidationStatus>(
        EPhoneValidationStatus,
        'EPhoneValidationStatus',
      ),
      PlayerIdentityStatus: createEnumType<EPlayerIdentityStatus>(
        EPlayerIdentityStatus,
        'EPlayerIdentityStatus',
      ),
      PlayerSegment: createEnumType<PlayerSegments>(
        PlayerSegments,
        'PlayerSegments',
      ),
      PrefixPhoneNumber2: t.string,
      PrefixPhoneNumber: t.string,
      Profession: t.string,
      Pseudo: t.string,
      SecondFirstName: t.string,
      SecondLastName: t.string,
      SecurityPinCodeProtectedFieldList: t.array(
        createEnumType<EPlayerProfileProperty>(
          EPlayerProfileProperty,
          'EPlayerProfileProperty',
        ),
      ),
      Type: t.string,
    }),
  ],

  // AffiliateCode?: string;
  // AffiliateSubCode?: string;
  // AffiliateUserCode?: string;
  // Company?: string;
  // Id?: string;
  // IsAccountNumberBICEditable: boolean;
  // IsAccountNumberIBANEditable: boolean;
  // IsAddressEditable: boolean;
  // IsBirthCityEditable: boolean;
  // IsBirthCountryEditable: boolean;
  // IsBirthdayEditable: boolean;
  // IsCivilityEditable: boolean;
  // IsCountryAddressEditable: boolean;
  // IsEmailEditable: boolean;
  // IsFirstNameEditable: boolean;
  // IsFullNameEditable: boolean;
  // IsLastNameEditable: boolean;
  // IsPhoneNumber2Editable: boolean;
  // IsPhoneNumberEditable: boolean;
  // IsProfessionEditable: boolean;
  // IsSecondFirstNameEditable: boolean;
  // IsSecondLastNameEditable: boolean;
  // IsTaxIdNumberEditable: boolean;
  // IsZanox: boolean;
  // LifeTimeValue: number;
  // NbOfDeposit: number;
  // TaxIdNumber: string;

  'UserProfile',
);

export const userCredit = t.type(
  {
    Status: t.literal(EGetCreditStatus.Successful),
    AvailableAmout: t.number,
    LossAmount: t.number,
    OnBetAmount: t.number,
    OnGameAmount: t.number,
    PlayableAmout: t.number,
    WithdrawableAmount: t.number,

    // IncludeInGameAmount: t.boolean,
  },
  'UserCredit',
);
