import React, { FC, useContext } from 'react';

import {
  EBettingSlipIdentifier,
  useSelectedItemsList,
} from '@gaming1/g1-betting';
import { BettingMenuContext, bettingRoutes } from '@gaming1/g1-betting-web';
import { BottomNav, useRoutePath } from '@gaming1/g1-core-web';
import { gamingRoutes } from '@gaming1/g1-gaming-web';
import { loyaltyRoutes } from '@gaming1/g1-loyalty-web';
import { DrawerType, LayoutContext } from '@gaming1/g1-ui';

export const AppBottomNav: FC = () => {
  const getGamingPath = useRoutePath(gamingRoutes);
  const getBettingPath = useRoutePath(bettingRoutes);
  const getLoyaltyPath = useRoutePath(loyaltyRoutes);
  const { outcomeValue } = useContext(BettingMenuContext);
  const { showDrawer } = useContext(LayoutContext);

  const betItems = useSelectedItemsList(EBettingSlipIdentifier.Main);
  const totalBetItems = betItems?.length || 0;

  return (
    <BottomNav
      animationType="hide"
      menus={{
        default: [
          {
            icon: 'Cherry',
            label: 'menu.casino',
            path: getGamingPath('gameListCasino'),
            testId: 'bottomnav-casino',
          },
          {
            icon: 'Dices',
            label: 'menu.dices',
            path: getGamingPath('gameListDice'),
            testId: 'bottomnav-dices',
            highlighted: true,
          },
          {
            icon: 'Soccer',
            label: 'menu.sport',
            path: getBettingPath('bettingHome'),
            testId: 'bottomnav-betting',
          },
          {
            icon: 'Gift',
            label: 'menu.club',
            path: getLoyaltyPath('shop'),
            testId: 'bottomnav-club',
          },
        ],
        gaming: [
          {
            icon: 'Cherry',
            label: 'menu.casino',
            path: getGamingPath('gameListCasino'),
            testId: 'bottomnav-casino',
            exact: true,
          },
          {
            icon: 'Dices',
            label: 'menu.dices',
            path: getGamingPath('gameListDice'),
            testId: 'bottomnav-dices',
            highlighted: true,
          },
          {
            icon: 'CardsLive',
            label: 'menu.liveCasino',
            path: getGamingPath('gameListCasinoCategory', {
              category: 'live-casino-a',
            }),
            testId: 'bottomnav-live',
          },
          {
            icon: 'Trophy',
            label: 'menu.tournaments',
            path: getGamingPath('tournaments'),
            testId: 'bottomnav-tournaments',
          },
        ],
        betting: [
          {
            icon: 'Live',
            label: 'menu.live',
            path: getBettingPath('liveListTop'),
            testId: 'bottomnav-live',
          },
          {
            icon: totalBetItems > 0 ? 'BettingSlipEmpty' : 'BettingSlip',
            label: outcomeValue || 'menu.bettingSlip',
            onClick: () => showDrawer(DrawerType.bettingSlip),
            testId: 'bottomnav-bettingslip',
            highlighted: true,
            iconText: totalBetItems > 0 ? String(totalBetItems) : undefined,
          },
          {
            icon: 'MyBets',
            label: 'menu.myBets',
            path: getBettingPath('myOpenBets'),
            testId: 'bottomnav-mybets',
          },
          {
            icon: 'SportsAZEu',
            label: 'menu.allSports',
            onClick: () => showDrawer(DrawerType.allSports),
            testId: 'bottomnav-allsports',
          },
        ],
        shop: [
          {
            icon: 'Cherry',
            label: 'menu.casino',
            path: getGamingPath('gameListCasino'),
            testId: 'bottomnav-casino',
          },
          {
            icon: 'Dices',
            label: 'menu.dices',
            path: getGamingPath('gameListDice'),
            testId: 'bottomnav-dices',
          },
          {
            icon: 'Soccer',
            label: 'menu.sport',
            path: getBettingPath('bettingHome'),
            testId: 'bottomnav-betting',
          },
          {
            icon: 'Gift',
            label: 'menu.club',
            path: getLoyaltyPath('shop'),
            testId: 'bottomnav-club',
          },
        ],
      }}
    />
  );
};
