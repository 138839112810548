import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { LegalModalState } from './types';

export const initialState: LegalModalState = {
  lastAcceptanceDate: null,
  requests: {
    acceptLegalModal: generateInitialRequestState(null),
    showLegalModal: generateInitialRequestState(null),
  },
  show: false,
};

export const legalModalReducer = (
  state: LegalModalState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.acceptLegalModal.request):
        produceLoadingState(draftState.requests.acceptLegalModal);
        break;
      case getType(actions.showLegalModal.request):
        produceLoadingState(draftState.requests.showLegalModal);
        draftState.show = false;
        break;

      /** Failures */
      case getType(actions.acceptLegalModal.failure):
        produceFailureState(
          draftState.requests.acceptLegalModal,
          action.payload,
        );
        break;
      case getType(actions.showLegalModal.failure):
        produceFailureState(draftState.requests.showLegalModal, action.payload);
        break;

      /** Successes */
      case getType(actions.acceptLegalModal.success):
        produceSuccessState(draftState.requests.acceptLegalModal);
        draftState.lastAcceptanceDate = action.payload.LastDatePopupAccepted
          ? action.payload.LastDatePopupAccepted
          : null;
        draftState.show = false;
        break;
      case getType(actions.showLegalModal.success):
        produceSuccessState(draftState.requests.showLegalModal);
        draftState.show = action.payload.ShowLegalPopup;
        break;

      default: // Immer will automatically return the state
    }
  });
