import * as t from 'io-ts';

import {
  EJackpotDetailsStatus,
  EJackpotRetributionType,
} from '@gaming1/g1-requests-gaming';
import { createEnumType } from '@gaming1/g1-utils';

import { game } from '../../common/store/codecs';

export const jackpotWinner = t.intersection(
  [
    t.type({
      Amount: t.number,
      WonDate: t.string,
    }),
    t.partial({
      ItemKey: t.string,
      JackpotName: t.string,
      Winner: t.string,
    }),
  ],
  'JackpotWinner',
);

export const jackpotItem = t.intersection(
  [
    t.type({
      Key: t.string,
      OrderNum: t.number,
    }),
    t.partial({
      Amount: t.number,
      TotalWonAmount: t.number,
      Winners: t.array(jackpotWinner),
      WonTimes: t.number,
    }),
  ],
  'JackpotItem',
);

export const jackpot = t.intersection(
  [
    t.type({
      Name: t.string,
      RetributionType: createEnumType<EJackpotRetributionType>(
        EJackpotRetributionType,
      ),
    }),
    t.partial({
      Items: t.array(jackpotItem),
      LinkedGames: t.array(game),
      HighlightedGame: game,
      TotalWonAmount: t.number,
      WonTimes: t.number,
    }),
  ],
  'Jackpot',
);

export const jackpotTotals = t.type(
  {
    TotalAmount: t.number,
    TotalWonAmount: t.number,
  },
  'JackpotTotals',
);

export const getJackpotDetailsResponse = t.type(
  {
    // JackpotRefreshSec: t.number,
    Jackpots: t.array(jackpot),
    Status: t.literal(EJackpotDetailsStatus.Successful),
    TotalAmount: t.number,
    TotalWonAmount: t.number,
  },
  'GetJackpotDetailsResponse',
);
