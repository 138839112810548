import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { isNonNullable } from '@gaming1/g1-utils';

import * as pipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const boostusConditionsReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(pipeActions.boostusConditionsForBettingSlip.request): {
        const { payload } = action;
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].boostusConditions = [];
        }
        break;
      }
      case getType(pipeActions.boostusConditionsForBettingSlip.success): {
        const { payload } = action;
        const { ConditionsIds } = payload;

        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].boostusConditions =
            ConditionsIds ? ConditionsIds?.filter(isNonNullable) : [];
        }
        break;
      }

      case getType(pipeActions.boostusConditionsForBettingSlip.failure): {
        const { payload } = action;
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].boostusConditions = [];
        }
        break;
      }
      case getType(pipeActions.resetBettingSlip.request):
      case getType(pipeActions.launchPlaceBet.success): {
        const { payload } = action;

        if (!payload.bettingSlipId) {
          break;
        }
        if (draftState.bettingSlips[payload.bettingSlipId]) {
          draftState.bettingSlips[payload.bettingSlipId].boostusConditions = [];
        }

        break;
      }
      default: // Immer will automatically return the state
    }
  });
