import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { PlayerBettingProfileResponseType } from './types';

export const getPlayerBettingProfile = createAsyncAction(
  'betting/get_player_betting_profile_request',
  'betting/get_player_betting_profile_success',
  'betting/get_player_betting_profile_failure',
)<void, PlayerBettingProfileResponseType, FailurePayload>();
