import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as actions from './actions';
import { GetGameState } from './types';

export const initialState: GetGameState = {
  requests: {
    getGame: generateInitialRequestState(null),
  },
};

export const getGameReducer = (
  state: GetGameState = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getGame.request):
        produceLoadingState(draftState.requests.getGame);
        break;

      case getType(actions.getGame.failure):
        produceFailureState(draftState.requests.getGame, action.payload);
        break;

      case getType(actions.getGame.success):
        produceSuccessState(draftState.requests.getGame);
        break;

      default: // Immer will automatically return the state
    }
  });
