import React from 'react';

import { AppConfig, LocaleCode } from '@gaming1/g1-config';

import { coreRoutes } from './routes';
import { AppRoute } from './routing/components/AppRoute';
import { LazyRoute } from './routing/components/LazyRoute';

export const getCoreRouter = (locale: LocaleCode, config: AppConfig) => [
  <AppRoute
    exact
    component={() => null}
    key="home"
    path={`/${locale}${coreRoutes.home.path}`}
    section="default"
  />,
  config.core.geoComply.activated && (
    <LazyRoute
      exact
      componentName="AppDownloadPage"
      importFactory={() => import('./geolocation/pages/AppDownloadPage')}
      key="downloadApp"
      path={`/${locale}${coreRoutes.downloadApp.path}`}
      section="default"
      type="app"
    />
  ),
];
