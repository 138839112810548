import React, { VFC } from 'react';

import { GeolocationTroubleshooter } from '@gaming1/g1-core';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { getWrapperEnv } from '@gaming1/g1-logger';
import {
  AppButtonAnchor,
  Box,
  Button,
  CollapsiblePanel,
  Heading,
} from '@gaming1/g1-ui';
import { isOnMobileBrowser } from '@gaming1/g1-utils';

import { useZendeskUrl } from '../../../help/hooks/useZendeskUrl';

import { GeolocationModalTipsBox } from './styles';

type GeolocationModalTroubleshooterContentProps = {
  /** Callback for the retry button click */
  onRetryClick: () => void;
  /** Callback for the dismiss button click */
  onCancelClick: () => void;
  /** True if the user should be prompted to request another geolocation */
  shouldShowTryAgainButton: boolean;
  /** Issues with the user geolocation */
  troubleshooters: GeolocationTroubleshooter[];
};

/** Content shown in the modal when the geolocation has been checked and is not valid */
export const GeolocationModalTroubleshooterContent: VFC<
  GeolocationModalTroubleshooterContentProps
> = ({
  onCancelClick,
  onRetryClick,
  shouldShowTryAgainButton,
  troubleshooters,
}) => {
  const { t } = useTranslation('core');
  const zendeskUrl = useZendeskUrl();

  const buttonContent = shouldShowTryAgainButton ? (
    <Button
      type="primary"
      data-testid="geolocation-modal-retry-button"
      mt="xxl"
      onClick={onRetryClick}
    >
      {t('geocomply.modal.tryAgain')}
    </Button>
  ) : (
    <AppButtonAnchor
      type="primary"
      colorType="primary"
      data-testid="geolocation-modal-contact-us-button"
      mt="xxl"
      href={zendeskUrl}
      target="_blank"
      rel="noopener"
    >
      {t('geocomply.modal.contactUs')}
    </AppButtonAnchor>
  );

  return (
    <>
      <Heading level="h2" justifyContent="center" levelStyle="h3" mt="md">
        {t('geocomply.modal.locationNotVerified')}
      </Heading>

      <Heading level="h3" justifyContent="center" levelStyle="h4" mt="md">
        {t('geocomply.modal.tipsTitle')}
      </Heading>

      <GeolocationModalTipsBox mt="sm">
        {t(
          `geocomply.modal.tips${
            isOnMobileBrowser() || getWrapperEnv() === 'rn' ? '-app' : ''
          }`,
        )}
      </GeolocationModalTipsBox>

      <Heading level="h3" levelStyle="h4" mt="lg">
        <Trans
          ns="core"
          i18nKey="geocomply.modal.errorsTitle"
          values={{ errorsNumber: troubleshooters.length }}
        />
      </Heading>

      {troubleshooters.map(
        (troubleshooter: GeolocationTroubleshooter, i: number) => (
          <CollapsiblePanel
            data-testid="geolocation-error"
            key={`geolocation-error-${troubleshooter.Rule || i + 1}`}
            mt="sm"
            mainContent={
              <Box>
                {t(
                  `geocomply.modal.errors.${troubleshooter.Rule || 'unknown'}`,
                )}
              </Box>
            }
            expandedContent={<Box>{troubleshooter.Message}</Box>}
          />
        ),
      )}

      {buttonContent}

      <Button
        type="tertiary"
        data-testid="geolocation-modal-dismiss-button"
        mt="xs"
        onClick={onCancelClick}
      >
        {t('geocomply.modal.dismiss')}
      </Button>
    </>
  );
};
