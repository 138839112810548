/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  DeleteGameFeatureRequestDTO,
  DeleteGameFeatureResponseDTO,
  GetGameFeatureHistoryRequestDTO,
  GetGameFeatureHistoryResponseDTO,
  GetPlayerAvailableGameFeaturesRequestDTO,
  GetPlayerGameFeaturesResponseDTO,
} from '../entities';

export const getPlayerAvailableGameFeatures = (
  options: GetPlayerAvailableGameFeaturesRequestDTO,
) =>
  createRequest<GetPlayerGameFeaturesResponseDTO>(
    103,
    'GetPlayerAvailableGameFeatures',
    options,
  );

export const deleteGameFeature = (options: DeleteGameFeatureRequestDTO) =>
  createRequest<DeleteGameFeatureResponseDTO>(
    103,
    'DeleteGameFeature',
    options,
  );

export const getGameFeatureHistoric = (
  options: GetGameFeatureHistoryRequestDTO,
) =>
  createRequest<GetGameFeatureHistoryResponseDTO>(
    103,
    'GetGameFeatureHistoric',
    options,
  );
