import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../store/types';
import * as actions from '../actions';
import { addDefaultMinStakeToManualBetWithoutOne } from '../helpers';

import { DailyOffersState } from './types';

export const initialState: DailyOffersState = {
  result: {
    hasDailyOffers: false,
    relatedEventIds: [],
    availableDailyOffers: {},
    availableDailyOffersForEvent: {},
  },
  requests: {
    hasDailyOffers: generateInitialRequestState(),
    getAvailableDailyOffers: generateInitialRequestState(null),
    getAvailableDailyOffersForEvent: generateInitialRequestState(null),
  },
};

export const dailyOffersReducer = (
  state: DailyOffersState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.hasAvailableDailyOffers.request):
        produceLoadingState(draftState.requests.hasDailyOffers);
        break;

      case getType(actions.hasAvailableDailyOffers.failure):
        produceFailureState(draftState.requests.hasDailyOffers, action.payload);
        break;

      case getType(actions.hasAvailableDailyOffers.success):
        produceSuccessState(draftState.requests.hasDailyOffers);
        draftState.result.hasDailyOffers = action.payload.IsAvailable;
        draftState.result.relatedEventIds =
          action.payload.RelatedEventIds || [];
        break;

      case getType(actions.getAvailableDailyOffers.request): {
        produceLoadingState(draftState.requests.getAvailableDailyOffers);
        draftState.result.availableDailyOffers = {};
        break;
      }

      case getType(actions.getAvailableDailyOffers.success): {
        produceSuccessState(draftState.requests.getAvailableDailyOffers);
        const { defaultMinStake, ManualBets, Notification } = action.payload;
        const dailyOffersWithMinStake =
          ManualBets &&
          addDefaultMinStakeToManualBetWithoutOne(ManualBets, defaultMinStake);
        draftState.result.availableDailyOffers = {
          Notification,
          ManualBets: dailyOffersWithMinStake,
        };
        break;
      }

      case getType(actions.getAvailableDailyOffers.failure): {
        const { payload } = action;
        produceFailureState(
          draftState.requests.getAvailableDailyOffers,
          payload,
        );
        draftState.result.availableDailyOffers = {};
        break;
      }

      case getType(actions.getAvailableDailyOffersForEvent.request): {
        produceLoadingState(
          draftState.requests.getAvailableDailyOffersForEvent,
        );
        draftState.result.availableDailyOffersForEvent = {};
        break;
      }

      case getType(actions.getAvailableDailyOffersForEvent.success): {
        const { payload } = action;
        produceSuccessState(
          draftState.requests.getAvailableDailyOffersForEvent,
        );
        const { Notification, ManualBets, defaultMinStake } = payload;
        const dailyOffersWithMinStake =
          ManualBets &&
          addDefaultMinStakeToManualBetWithoutOne(ManualBets, defaultMinStake);
        draftState.result.availableDailyOffersForEvent = {
          Notification,
          ManualBets: dailyOffersWithMinStake,
        };
        break;
      }

      case getType(actions.getAvailableDailyOffersForEvent.failure): {
        const { payload } = action;
        produceFailureState(
          draftState.requests.getAvailableDailyOffersForEvent,
          payload,
        );
        draftState.result.availableDailyOffersForEvent = {};
        break;
      }
      default:
    }
  });
