import * as t from 'io-ts';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import {
  ETournamentStateV4,
  getTournament_V4,
  getTournamentDetails_V4,
  getTournamentList_V4,
  getTournamentPlayerRanking_V4,
  getTournamentRanking_V4,
  registerTournament_V4,
} from '@gaming1/g1-requests-gaming';
import {
  createFailurePayload,
  isCodecGuardError,
  mapGuard,
} from '@gaming1/g1-utils';

import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import * as codecs from './codecs';
import { registerTournamentFailureResponse } from './codecs';
import * as errorMessages from './errorMessages';
import * as formats from './formats';

export const getTournamentListEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTournamentList.request)),
    withLatestFrom(state$, config$),
    mergeMap(([action, state, config]) =>
      wsAdapter
        .request(
          getTournamentList_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            RoomDomainName: config.room.roomName,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getTournamentListResponse),
          map(formats.formatTournamentList),
          map(actions.getTournamentList.success),
          catchError((err) =>
            of(
              actions.getTournamentList.failure(
                createFailurePayload(
                  err,
                  errorMessages.getTournamentErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

export const getRunningTournamentsEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRunningTournaments.request)),
    withLatestFrom(state$, config$),
    mergeMap(([action, state, config]) =>
      wsAdapter
        .request(
          getTournamentList_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            RoomDomainName: config.room.roomName,
            ListType: ETournamentStateV4.Running,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getTournamentListResponse),
          map(formats.formatTournamentList),
          map(actions.getRunningTournaments.success),
          catchError((err) =>
            of(
              actions.getRunningTournaments.failure(
                createFailurePayload(
                  err,
                  errorMessages.getTournamentListErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

export const getFinishedTournamentsEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getFinishedTournaments.request)),
    withLatestFrom(state$, config$),
    mergeMap(([action, state, config]) =>
      wsAdapter
        .request(
          getTournamentList_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            RoomDomainName: config.room.roomName,
            ListType: ETournamentStateV4.Finished,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getTournamentListResponse),
          map(formats.formatTournamentList),
          map(actions.getFinishedTournaments.success),
          catchError((err) =>
            of(
              actions.getFinishedTournaments.failure(
                createFailurePayload(
                  err,
                  errorMessages.getTournamentListErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

export const getActiveTournamentsEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getActiveTournaments.request)),
    withLatestFrom(state$, config$),
    mergeMap(([action, state, config]) =>
      forkJoin([
        wsAdapter.request(
          getTournamentList_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            RoomDomainName: config.room.roomName,
            ListType: ETournamentStateV4.Scheduled,
            ...action.payload,
          }),
        ),
        wsAdapter.request(
          getTournamentList_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            RoomDomainName: config.room.roomName,
            ListType: ETournamentStateV4.Running,
            ...action.payload,
          }),
        ),
      ]).pipe(
        mapGuard(t.array(codecs.getTournamentListResponse)),
        map(([scheduledTournaments, runningTournaments]) => ({
          ...scheduledTournaments,
          Total: scheduledTournaments.Total + runningTournaments.Total,
          List: scheduledTournaments.List.concat(runningTournaments.List),
        })),
        map(formats.formatTournamentList),
        map(actions.getActiveTournaments.success),
        catchError((err) =>
          of(
            actions.getActiveTournaments.failure(
              createFailurePayload(
                err,
                errorMessages.getTournamentListErrorMessages,
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const getTournamentDetailsEpic: GamingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTournamentDetails.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      wsAdapter
        .request(
          getTournamentDetails_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getTournamentDetailsResponse),
          map(actions.getTournamentDetails.success),
          catchError((err) =>
            of(
              actions.getTournamentDetails.failure(
                createFailurePayload(
                  err,
                  errorMessages.getTournamentDetailsErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

export const getTournamentRankingEpic: GamingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTournamentRanking.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      wsAdapter
        .request(
          getTournamentRanking_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getTournamentRankingResponse),
          map(formats.formatTournamentRanking),
          map(actions.getTournamentRanking.success),
          catchError((err) =>
            of(
              actions.getTournamentRanking.failure(
                createFailurePayload(
                  err,
                  errorMessages.getTournamentRankingErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

export const getTournamentPlayerRankingEpic: GamingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTournamentPlayerRanking.request)),
    withLatestFrom(state$),
    mergeMap(([action]) =>
      wsAdapter.request(getTournamentPlayerRanking_V4(action.payload)).pipe(
        mapGuard(codecs.getTournamentPlayerRankingResponse),
        map(formats.formatPlayerRanking),
        map(actions.getTournamentPlayerRanking.success),
        catchError((err) =>
          of(
            actions.getTournamentPlayerRanking.failure(
              createFailurePayload(
                err,
                errorMessages.getTournamentPlayerRankingErrorMessages,
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const getTournamentEpic: GamingEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getTournament.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      wsAdapter
        .request(
          getTournament_V4({
            LanguageCode: state.core.i18n.webSocketLocale,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getTournamentResponse),
          map(actions.getTournament.success),
          catchError((err) =>
            of(
              actions.getTournament.failure(
                createFailurePayload(
                  err,
                  errorMessages.getTournamentPlayerRankingErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

export const registerTournamentEpic: GamingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.registerTournament.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      wsAdapter
        .request(
          registerTournament_V4({
            LanguageCode: currentWebSocketLocaleSelector(state),
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.registerTournamentResponse),
          // map(formats.formatTournamentRanking),
          map(actions.registerTournament.success),
          catchError((err) => {
            /**
             * Check if we can decode the failure response.
             * It means that it's a failure we can manage
             * */
            if (
              isCodecGuardError(err) &&
              registerTournamentFailureResponse.is(err.value)
            ) {
              return of(
                actions.registerTournament.failure({
                  status: err.value.Status,
                  errorMessage: err.value.ErrorMessage ?? '',
                  message: err.value?.Message ?? '',
                }),
              );
            }
            /** If not, we fallback to a more generic error */
            return of(
              actions.registerTournament.failure(
                createFailurePayload(
                  err,
                  errorMessages.registerTournamentErrorMessages,
                ),
              ),
            );
          }),
        ),
    ),
  );
