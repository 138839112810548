import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export type SeparatorDentProps = SpaceProps;

export const SeparatorDent = styled.span<SeparatorDentProps>`
  background: inherit;
  display: block;
  height: 8px;
  margin-top: -8px;
  overflow: hidden;
  width: 16px;

  ${space}

  &::after {
    background: inherit;
    border: 1px solid ${({ color, theme }) => color || theme.colors.neutrals[2]};
    content: '';
    display: block;
    height: 16px;
    transform: translateY(2.5px) rotate(45deg);
    width: 16px;
  }
`;
