import { createAction, createAsyncAction } from 'typesafe-actions';

import { GetFreebetsRequest } from '@gaming1/g1-requests-betting';
import { FailurePayload } from '@gaming1/g1-utils';

import { BetPipeIdentifier } from '../betPipe/types';

import { GetFreebetsParamsType, GetFreebetsResponseType } from './types';

export const getFreebets = createAsyncAction(
  'betting/get_freebets_request',
  'betting/get_freebets_success',
  'betting/get_freebets_failure',
  'betting/get_freebets_cancel',
)<
  GetFreebetsRequest & BetPipeIdentifier & GetFreebetsParamsType,
  GetFreebetsResponseType & BetPipeIdentifier & GetFreebetsParamsType,
  GetFreebetsResponseType &
    FailurePayload &
    BetPipeIdentifier &
    GetFreebetsParamsType,
  BetPipeIdentifier & GetFreebetsParamsType
>();

export const getFreebetsWithCache = createAction(
  'betting/get_freebetswithcache',
)<BetPipeIdentifier & GetFreebetsParamsType>();
