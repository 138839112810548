import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../store/types';

import * as actions from './actions';
import { PlayerState } from './types';

export const initialState: PlayerState = {
  result: {},
  requests: {
    getPlayerBettingProfile: generateInitialRequestState(),
  },
};

export const playerBettingReducer = (
  state: PlayerState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getPlayerBettingProfile.request):
        produceLoadingState(draftState.requests.getPlayerBettingProfile);
        break;
      case getType(actions.getPlayerBettingProfile.success):
        draftState.result = action.payload;

        produceSuccessState(draftState.requests.getPlayerBettingProfile);
        break;
      case getType(actions.getPlayerBettingProfile.failure):
        draftState.result = {};
        produceFailureState(
          draftState.requests.getPlayerBettingProfile,
          action.payload,
        );
        break;
      default: // Immer will automatically return the state
    }
  });
