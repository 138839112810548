import format from 'date-fns/format';
import React, { ComponentPropsWithoutRef, FC, useContext } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Withdraw } from '@gaming1/g1-payment';
import { Modal } from '@gaming1/g1-ui';

import { withdrawalHistoryTransactionKeys } from '../../helpers';
import {
  MoreInformationModalLabel,
  MoreInformationModalRow,
  MoreInformationModalTitle,
  MoreInformationModalValue,
  MoreInformationModalWrapper,
} from '../../styles';

const isValidStatus = (
  num: number,
): num is keyof typeof withdrawalHistoryTransactionKeys =>
  num in withdrawalHistoryTransactionKeys;

export type WithdrawalHistoryInformationModalProps = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  history: Withdraw;
};

/**
 * Information modal for withdrawal history
 */
export const WithdrawalHistoryInformationModal: FC<
  WithdrawalHistoryInformationModalProps
> = ({ onClose, history, ...rest }) => {
  const { t } = useTranslation('payment');
  const { i18n } = useContext(ConfigContext);
  const formatMoney = useFormatMoney();

  const informations = [
    {
      label: t('history.withdrawal.withdrawDate'),
      value: format(new Date(history.WithdrawDate), i18n.dateTimePartialFormat),
    },
    {
      label: t('history.withdrawal.reference'),
      value: history.Reference,
    },
    {
      label: t('history.withdrawal.status'),
      value: isValidStatus(history.TransactionState)
        ? t(withdrawalHistoryTransactionKeys[history.TransactionState])
        : undefined,
    },
    {
      label: t('history.withdrawal.amount'),
      value: formatMoney(history.Amount),
    },
    {
      label: '',
      value: history.ExtraInfo,
    },
  ];

  return (
    <Modal
      modalId="withdrawal-history-information-modal"
      onClose={onClose}
      {...rest}
    >
      <>
        <MoreInformationModalTitle>
          {t('history.withdrawal.moreInformationTitle')}
        </MoreInformationModalTitle>
        <MoreInformationModalWrapper>
          {informations.map((information) => (
            <MoreInformationModalRow
              key={`${information.label}${information.value}`}
            >
              <MoreInformationModalLabel>
                {information.label}
              </MoreInformationModalLabel>
              <MoreInformationModalValue>
                {information.value}
              </MoreInformationModalValue>
            </MoreInformationModalRow>
          ))}
        </MoreInformationModalWrapper>
      </>
    </Modal>
  );
};
