import { createAction, createAsyncAction } from 'typesafe-actions';

import { SetLegalLimitRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetLegalDepositLimitResponse,
  SetLegalDepositLimitResponse,
} from './types';

/* Async actions */

/** Set legal deposit limit */
export const setLegalDepositLimit = createAsyncAction(
  'user/set_legal_deposit_limit_request',
  'user/set_legal_deposit_limit_success',
  'user/set_legal_deposit_limit_failure',
)<SetLegalLimitRequestDTO, SetLegalDepositLimitResponse, FailurePayload>();

/** Get legal deposit limits */
export const getLegalDepositLimit = createAsyncAction(
  'user/get_legal_deposit_limit_request',
  'user/get_legal_deposit_limit_success',
  'user/get_legal_deposit_limit_failure',
)<void, GetLegalDepositLimitResponse, FailurePayload>();

/**
 * The user clicked on the button to cancel the legal deposit limit
 */
export const clickUserLegalDepositLimit = createAction(
  'user/legal_deposit_limit_click',
)<SetLegalLimitRequestDTO>();
