import React, { FC, useContext } from 'react';

import { PrismicMediaCardSlice } from '@gaming1/g1-cms';
import { Box, Heading } from '@gaming1/g1-ui';

import { PrismicChildrenContext } from '../PrismicChildren/PrismicChildrenContext';
import { PrismicRichText } from '../PrismicRichText';

import { PrismicCard, PrismicCardContent } from './styles';

export type PrismicMediaCardProps = {
  /** Prismic 'Media card' slice */
  slice: PrismicMediaCardSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Media card` slice
 * A component having a 'card' elevation style, with a media (image/map/video) at the top,
 * and some text content at the bottom
 */
export const PrismicMediaCard: FC<PrismicMediaCardProps> = ({
  slice,
  testId = 'prismic-media-card',
  parentIds = [],
}) => {
  const { component: PrismicChildren } = useContext(PrismicChildrenContext);
  return (
    <PrismicCard data-testid={testId}>
      <Box>
        {slice.primary.media_slice_id && (
          <PrismicChildren
            childrenIds={slice.primary.media_slice_id}
            parentIds={
              slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
            }
          />
        )}
      </Box>
      <PrismicCardContent hasMedia={!!slice.primary.media_slice_id}>
        <Heading level="h3" mt="none">
          {slice.primary.title1}
        </Heading>
        <PrismicRichText
          content={slice.primary.content}
          sliceId={slice.primary.id}
        />
      </PrismicCardContent>
    </PrismicCard>
  );
};
