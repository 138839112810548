/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:12:04 09-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetJackpotDetailsRequestDTO,
  GetJackpotDetailsResponseDTO,
} from '../entities';

export const getJackpotDetails = (options: GetJackpotDetailsRequestDTO) =>
  createRequest<GetJackpotDetailsResponseDTO>(
    103,
    'GetJackpotDetails',
    options,
  );
