import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetTags } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import { userSelector } from '@gaming1/g1-core';

import {
  createListenerOL,
  createOL,
  getMessagesOL,
  setTrackingIdOL,
} from './helpers';
import { InboxContext } from './InboxContext';
import { MessagesResponse } from './types';

export const OL_SCRIPT_ID = 'ol-snippet';

/** Integrate otherLevels sdk and initialize it. */
export const InboxProvider: FC = ({ children }) => {
  // messages context state
  const [inboxContent, setInboxContent] = useState<MessagesResponse | null>(
    null,
  );
  const contextValue = useMemo(() => ({ inboxContent }), [inboxContent]);
  const {
    user: { inboxProjectKey, inbox },
  } = useConfig();
  const userProfile = useSelector(userSelector);

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isOLCreated, setIsOLCreated] = useState(false);
  const [isOLListener, setIsOLListener] = useState(false);
  const trackingId = userProfile?.Id;

  const handleScriptInject = ({ scriptTags }: HelmetTags) => {
    if (scriptTags && scriptTags.length) {
      const olScript = scriptTags.find(
        (scriptTag) => scriptTag.id === OL_SCRIPT_ID,
      );

      if (olScript) {
        olScript.onload = () => {
          setIsScriptLoaded(true);
        };
      }
    }
  };

  useEffect(() => {
    if (isScriptLoaded && trackingId) {
      if (!isOLCreated) {
        createOL(inboxProjectKey, () => setIsOLCreated(true));
      } else if (!isOLListener) {
        createListenerOL((messagesReceived) =>
          setInboxContent(messagesReceived),
        );
        setIsOLListener(true);
      } else {
        setTrackingIdOL(trackingId, getMessagesOL);
      }
    } else {
      setInboxContent(null);
    }
  }, [isOLCreated, trackingId, isOLListener, inboxProjectKey, isScriptLoaded]);

  return inbox && inboxProjectKey ? (
    <InboxContext.Provider value={contextValue}>
      {trackingId && (
        <Helmet
          onChangeClientState={(_, addedTags) => handleScriptInject(addedTags)}
        >
          <script
            id="ol-snippet"
            type="text/javascript"
            data-testid="ol-snippet"
            defer
            src={`https://cdn.otherlevels.com/js-sdk/otherlevels.js?appKey=${inboxProjectKey}`}
          />
        </Helmet>
      )}
      {children}
    </InboxContext.Provider>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
