import React, { forwardRef, memo } from 'react';

import { Box } from '@gaming1/g1-ui';

import { useUniformizationGiftsDesign } from '../../../common/hooks/config';
import { OffersAvailable } from '../OffersAvailable';
import { OffersAvailable as OffersAvailableV2 } from '../OffersAvailableV2';

import { BettingSlipFooterContent } from './BettingSlipFooterContent';

/**
 * Display the ticket item footer
 *
 */
const BettingSlipFooterComponent = forwardRef<HTMLDivElement>((_, ref) => {
  const uniformizationGiftsDesignActive = useUniformizationGiftsDesign();
  return (
    <Box
      data-testid="bettingslip-footer-container"
      ref={ref}
      alignItems="center"
      my="md"
      mx="sm"
    >
      {uniformizationGiftsDesignActive ? (
        <OffersAvailableV2 />
      ) : (
        <OffersAvailable />
      )}

      <BettingSlipFooterContent />
    </Box>
  );
});

export const BettingSlipFooter = memo(BettingSlipFooterComponent);
