import {
  GetRiskManagementRequest,
  GetSystemRiskManagementRequest,
} from '@gaming1/g1-requests-betting';

import {
  RiskManagementRawData,
  SystemRiskManagementRawData,
} from '../store/types/riskManagement';

/**
 * Take a raw data object to format them into a valid RM request.
 * The object needs to be formatted to be used by the RM.
 *
 * @param bettingActivity the betting activity for the request
 * @param bettingSlipType the bettingslip type that contains the fetched item(s)
 * @param preparedItems the formatted items from the bettingSlip
 */
export const formatRiskManagementPreparedRequest = ({
  bettingSlipType,
  stake,
  preparedElements,
}: RiskManagementRawData): GetRiskManagementRequest => ({
  BettingSlipType: bettingSlipType,
  Stake: stake || 0,
  Elements: preparedElements,
});

/**
 * Take a raw data object to format them into a valid SYSTEM RM Request.
 */
export const formatSystemRiskManagementPreparedRequest = ({
  stake,
  preparedElements,
}: SystemRiskManagementRawData): GetSystemRiskManagementRequest => ({
  Stake: stake,
  Elements: preparedElements,
});

/**
 * Takes all the stakes of every rank of the system bettingSlip to add them and get the total stake of the BS.
 * @param stakes every stake for every rank of the system bettingSlip
 */
export const calculateFinalSystemStake = (stakes: number[] | undefined) => {
  if (!stakes) {
    return 0;
  }
  return stakes.reduce((prev, stake) => (stake ? prev + stake : prev));
};
