import * as t from 'io-ts';

import {
  GetGamesCategoriesRequestDTO,
  GetPaginatedGameListRequestDTO,
} from '@gaming1/g1-requests-gaming';
import {
  EntityOf,
  PaginatedRequestState,
  RequestState,
} from '@gaming1/g1-utils';

import {
  gameAttribute,
  gameAttributeCategory,
  gameCategory,
  gamesCategoriesData,
  gamesData,
  getGamesCategoriesReponse,
  getPaginatedGameListSuccessfulResponseCodec,
} from './codecs';

/* State */

export type GameListState = {
  entities: GameListEntitiesState;
  attributes: Record<string, string[]>;
  requests: GameListRequestsState;
};

export type GameListEntitiesState = {
  attributeCategories: AttributeCategoriesEntity;
  attributes: AttributesEntity;
  gameCategories: GameCategoriesEntity;
};

export type GameListRequestsState = {
  fetchGames: {
    [slugifiedRequest: string]:
      | PaginatedRequestState<GameListResults>
      | undefined;
  };
  fetchGameCategories: RequestState<GameCategoriesResult>;
};

/* State Entities */

export type GameAttribute = t.TypeOf<typeof gameAttribute>;
export type AttributesEntity = EntityOf<GameAttribute>;

export type GameAttributeCategory = t.TypeOf<typeof gameAttributeCategory>;
export type AttributeCategoriesEntity = EntityOf<GameAttributeCategory>;

export type GameCategory = t.TypeOf<typeof gameCategory>;
export type GameCategoriesEntity = EntityOf<GameCategory>;

/* State Requests */

export type GameListResults = {
  attributeCategories: string[] | null;
  attributes: string[] | null;
  games: string[] | null;
};

export type GameCategoriesResult = {
  gamesCategories: string[];
};

/* API Requests */

export type GetPaginatedGameListRequest = Required<
  Pick<GetPaginatedGameListRequestDTO, 'GameRange' | 'PageNumber' | 'PageSize'>
> &
  Partial<Pick<GetPaginatedGameListRequestDTO, 'GameSort'>> & {
    AttributesIDList?: string[];
  };

export type GetGameCategoriesRequest = Partial<GetGamesCategoriesRequestDTO>;

/* API Responses */

export type GetPaginatedGameListResponse = t.TypeOf<
  typeof getPaginatedGameListSuccessfulResponseCodec
>;

export type GetGameCategoriesResponse = t.TypeOf<
  typeof getGamesCategoriesReponse
>;

/* Normalized data */

export type GamesData = t.TypeOf<typeof gamesData>;

export type GamesCategoriesData = t.TypeOf<typeof gamesCategoriesData>;

/* Enums */

export enum GameRange {
  LOBBYALL = 'Lobby-All',
  LOBBYALLLICENSEA = 'Lobby-All-A',
  LOBBYALLLICENSEB = 'Lobby-All-B',
  LOBBYALLSLOTS = 'Lobby-All-Slots',
  LOBBYBLACKJACK = 'Lobby-Blackjack',
  LOBBYCARDS = 'Lobby-Cards',
  LOBBYDICES = 'Lobby-Dices',
  LOBBYDICESLOTS = 'Lobby-Dice-Slots',
  LOBBYJACKPOT = 'Lobby-Jackpot',
  LOBBYROULETTES = 'Lobby-Roulettes',
  LOBBYSLOTS = 'Lobby-Slots',
  LOBBYSTARGAMES = 'Lobby-Stargames',
  LOBBYVIDEOPOKER = 'Lobby-Videopoker',
  NEWGAMES = 'NewGames',
  TOPGAMES = 'TopGames',
}

export enum GameAttributeCategoryNameEnum {
  topics = 'Topics',
  providers = 'Provider',
}

export enum GameType {
  all = 'all',
  new = 'new',
  top = 'top',
}
