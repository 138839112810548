import { createContext } from 'react';

import { AssetsContext } from '@gaming1/g1-core-web';

export const defaultBettingAssetsContextValue = {
  /* Banners */
  boostusBanner: '',
  dailyOffersBanner: '',
  dailyOffersBannerSmallV2: '',
  dailyOffersBannerLargeV2: '',
  defaultSliderLarge: '',
  defaultSliderSmall: '',
  promotionsBannerSmall: '',
  promotionsBannerLarge: '',
  superOddsBannerLarge: '',
  superOddsBannerSmall: '',
  superOddsBannerLargeV2: '',
  superOddsBannerSmallV2: '',
  /* Score backgrounds */
  americanFootballScoreBackground: '',
  baseballScoreBackground: '',
  basketballScoreBackground: '',
  bowlsScoreBackground: '',
  boxingScoreBackground: '',
  cricketScoreBackground: '',
  cyclingScoreBackground: '',
  dartsScoreBackground: '',
  defaultScoreBackground: '',
  footballScoreBackground: '',
  golfScoreBackground: '',
  handballScoreBackground: '',
  iceHockeyScoreBackground: '',
  martialArtsScoreBackground: '',
  mixedMartialArtsScoreBackground: '',
  motorCycleRacingScoreBackground: '',
  motorSportScoreBackground: '',
  rugbyScoreBackground: '',
  snookerScoreBackground: '',
  stockCarRacingScoreBackground: '',
  tableTennisScoreBackground: '',
  tennisScoreBackground: '',
  volleyballScoreBackground: '',
};

export const BettingAssetsContext = createContext<AssetsContext>(
  defaultBettingAssetsContextValue,
);
