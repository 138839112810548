import React, { ContextType, FC, useEffect, useMemo, useState } from 'react';

import {
  EBettingSlipIdentifier,
  useBettingSlipBaseOdds,
  useBettingSlipIsEmpty,
} from '@gaming1/g1-betting';
import { OddsUnitsType, useConfig } from '@gaming1/g1-config';
import { readFrom } from '@gaming1/g1-utils';

import { formatOdd } from '../helpers';

import {
  BettingMenuContext,
  BettingMenuContextMenus,
  ODD_UNIT_STORAGE_KEY,
} from './BettingMenuContext';

type BettingMenuProviderProps = {
  menus: BettingMenuContextMenus;
};

export const BettingMenuProvider: FC<BettingMenuProviderProps> = ({
  children,
  menus,
}) => {
  const config = useConfig();
  const oddUnitStorage = readFrom(
    localStorage,
    ODD_UNIT_STORAGE_KEY,
  ) as OddsUnitsType;
  const [outcomeValue, setOutcomeValue] = useState('');
  const [oddUnit, setOddUnit] = useState<OddsUnitsType>(
    oddUnitStorage || config.betting.defaultOddsUnit,
  );

  const isBettingSlipEmpty = useBettingSlipIsEmpty(EBettingSlipIdentifier.Main);

  const oddValue = useBettingSlipBaseOdds(EBettingSlipIdentifier.Main)();
  const formattedOdd = oddValue ? formatOdd(oddValue, oddUnit) : null;

  useEffect(() => {
    if (isBettingSlipEmpty || !oddValue || oddValue <= 1) {
      setOutcomeValue('');
    } else {
      setOutcomeValue(formattedOdd ? formattedOdd.toString() : '');
    }
  }, [isBettingSlipEmpty, formattedOdd, oddValue, oddUnit]);

  const bettingMenuContextValue: ContextType<typeof BettingMenuContext> =
    useMemo(
      () => ({
        menus,
        oddUnit,
        outcomeValue,
        setOddUnit,
      }),
      [menus, oddUnit, outcomeValue],
    );

  return (
    <BettingMenuContext.Provider value={bettingMenuContextValue}>
      {children}
    </BettingMenuContext.Provider>
  );
};
