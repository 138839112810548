import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  BlogArticleDocument,
  BlogConfiguration,
  FetchBlogArticlesRequestPayload,
  FetchBlogArticlesSuccessPayload,
  FetchRecentBlogArticlesRequestPayload,
} from './types';

/** Action to fetch the 'Blog Configuration' document in Prismic */
export const fetchBlogConfiguration = createAsyncAction(
  'prismic/fetch_blog_configuration_request',
  'prismic/fetch_blog_configuration_success',
  'prismic/fetch_blog_configuration_failure',
)<void, BlogConfiguration, FailurePayload>();

/** Action to fetch blog articles documents in Prismic */
export const fetchBlogArticles = createAsyncAction(
  'prismic/fetch_blog_articles_request',
  'prismic/fetch_blog_articles_success',
  'prismic/fetch_blog_articles_failure',
)<
  FetchBlogArticlesRequestPayload,
  FetchBlogArticlesSuccessPayload,
  { data: FailurePayload; request: FetchBlogArticlesRequestPayload }
>();

/** Action to fetch recent blog articles documents in Prismic */
export const fetchRecentBlogArticles = createAsyncAction(
  'prismic/fetch_recent_blog_articles_request',
  'prismic/fetch_recent_blog_articles_success',
  'prismic/fetch_recent_blog_articles_failure',
)<
  FetchRecentBlogArticlesRequestPayload,
  FetchBlogArticlesSuccessPayload,
  { data: FailurePayload; request: FetchRecentBlogArticlesRequestPayload }
>();

/** Action to fetch a blog article document in Prismic, by its UID */
export const fetchBlogArticleByUid = createAsyncAction(
  'prismic/fetch_blog_article_request',
  'prismic/fetch_blog_article_success',
  'prismic/fetch_blog_article_failure',
  'prismic/fetch_blog_article_cancel',
)<{ uid: string }, BlogArticleDocument, FailurePayload, void>();
