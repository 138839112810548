import { createAction, createAsyncAction } from 'typesafe-actions';

import { LocaleCode } from '@gaming1/g1-config';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetAllScoreBoardsRequestType,
  GetScoreBoardNormalizedType,
  GetScoreBoardRequestType,
  GetScoreBoardsNormalizedType,
  GetScoreBoardsRequestType,
} from './types';

/** Get score board */
export const getScoreBoard = createAsyncAction(
  'betting/get_score_board_request',
  'betting/get_score_board_success',
  'betting/get_score_board_failure',
  'betting/get_score_board_cancel',
)<
  GetScoreBoardRequestType,
  { data: GetScoreBoardNormalizedType; request: GetScoreBoardRequestType },
  { data: FailurePayload; request: GetScoreBoardRequestType },
  { eventId: number; locale: LocaleCode }
>();

/** Get score boards */
export const getScoreBoards = createAsyncAction(
  'betting/get_score_boards_request',
  'betting/get_score_boards_success',
  'betting/get_score_boards_failure',
  'betting/get_score_boards_cancel',
)<
  GetScoreBoardsRequestType,
  { data: GetScoreBoardsNormalizedType; request: GetScoreBoardsRequestType },
  { data: FailurePayload; request: GetScoreBoardsRequestType },
  { eventIds: number[]; locale: LocaleCode }
>();

/** Get all score boards */
export const getAllScoreBoards = createAsyncAction(
  'betting/get_all_score_boards_request',
  'betting/get_all_score_boards_success',
  'betting/get_all_score_boards_failure',
  'betting/get_all_score_boards_cancel',
)<
  GetAllScoreBoardsRequestType,
  GetScoreBoardsNormalizedType,
  FailurePayload,
  { locale: LocaleCode }
>();

/** Update scoreboards */
export const updateScoreBoards = createAction(
  'betting/update_scoreboards',
)<GetScoreBoardsNormalizedType>();
