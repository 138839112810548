import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { WebSocketAdapter } from '@gaming1/g1-network';
import { codecGuard, mapGuard, slugifyPayload } from '@gaming1/g1-utils';

import { generateBettingMulticastGroupName } from '../common/store/helpers';
import { logger } from '../logger';
import { ApplicationWithBettingState } from '../store/types';

import * as actions from './actions';
import {
  getScoreBoardsNormalizedCodec,
  getScoreBoardUpdatesCodec,
} from './codecs';
import { formatGetScoreBoardUpdates } from './format';

export const slugifyScoreBoardPayload = ({ eventId }: { eventId: number }) =>
  slugifyPayload({ eventId });

const scoreBoardUpdatePipeActions = pipe(
  filter(codecGuard(getScoreBoardUpdatesCodec, logger)),
  filter((scoreBoardUpdates) => !!scoreBoardUpdates.Updates.length),
  map(formatGetScoreBoardUpdates),
  mapGuard(getScoreBoardsNormalizedCodec),
  map(actions.updateScoreBoards),
);

export const getScoreBoardGroupName = (
  eventId: number,
  state: ApplicationWithBettingState,
) =>
  generateBettingMulticastGroupName('scoreboard', {
    eventId,
    locale: currentWebSocketLocaleSelector(state),
  });

export const getScoreBoardUpdateActions = (
  eventIds: number[],
  state: ApplicationWithBettingState,
  wsAdapter: WebSocketAdapter,
) =>
  eventIds.map((eventId) =>
    wsAdapter
      .subscribeToGroup(getScoreBoardGroupName(eventId, state))
      .pipe(scoreBoardUpdatePipeActions),
  );

export const getAllScoreBoardUpdateActions = (
  state: ApplicationWithBettingState,
  wsAdapter: WebSocketAdapter,
) =>
  wsAdapter
    .subscribeToGroup(
      generateBettingMulticastGroupName('allScoreboards', {
        locale: currentWebSocketLocaleSelector(state),
      }),
    )
    .pipe(scoreBoardUpdatePipeActions);
