import React, { VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Separator } from '@gaming1/g1-ui';

import { SearchLayout, SearchResultDisplay } from '../SearchResultDisplay';
import {
  SearchResultDisplayContainer,
  SearchResultTitle,
} from '../SearchResultDisplay/styles';

import { ClearHistoryButton, SearchHistoryContainer } from './styles';

type SearchHistoryProps = {
  /** The clear history method to use when the button "clearHistory" of the component "RecentSearches" is clicked on */
  clearHistory: () => void;
  /** The onClick method to use when one of the term from the history is clicked on to then change the search bar value to this term */
  onClick: (searchTerm: string) => void;
  /** The search history filled with all the terms wrote by the user */
  searchHistory: string[];
  /** Hide the search history if true and there is no history */
  hideEmptyRecentSearches: boolean;
  layout?: SearchLayout;
};

/** Render all the terms from the search history that the user previously wrote */
export const SearchHistory: VFC<SearchHistoryProps> = ({
  clearHistory,
  onClick,
  searchHistory,
  hideEmptyRecentSearches,
  layout = 'normal',
}) => {
  const { t } = useTranslation('core');

  if (hideEmptyRecentSearches && !searchHistory.length) {
    return null;
  }

  const titleContent = (
    <>
      <span>{t('search.recent')}</span>
      {searchHistory.length > 0 ? (
        <ClearHistoryButton
          data-testid="clear-history-button"
          onClick={clearHistory}
        >
          {t('search.history.clear')}
        </ClearHistoryButton>
      ) : null}
    </>
  );
  return (
    <SearchHistoryContainer data-testid="recent-searches">
      {layout === 'normal' ? (
        <>
          <Box flexDirection="row" justifyContent="space-between" width="100%">
            {titleContent}
          </Box>
          <Box mb="sm" width="100%">
            <Separator data-testid="recent-searches-separator" />
          </Box>
        </>
      ) : (
        <SearchResultTitle>{titleContent}</SearchResultTitle>
      )}

      <SearchResultDisplayContainer>
        {searchHistory && searchHistory.length > 0
          ? searchHistory.map((term, index) => (
              <SearchResultDisplay
                // eslint-disable-next-line react/no-array-index-key
                key={term + index}
                onClick={onClick}
                title={term}
                layout={layout}
              />
            ))
          : t('search.history.isEmpty')}
      </SearchResultDisplayContainer>
    </SearchHistoryContainer>
  );
};
