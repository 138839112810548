import { darken, lighten } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  isColorDark,
  media,
  spaces,
} from '@gaming1/g1-style';
import { Box, SimpleButton } from '@gaming1/g1-ui';

export const ROTATING_ANIMATION_TIME_IN_MS = 1000;

const rotating = keyframes`
  0% { transform: rotate(0deg); transform-origin: 43%; }
  100% { transform: rotate(360deg); transform-origin: 43%; }
`;

export const RefreshIconWrapper = styled(Box)<{ isRotating: boolean }>`
  svg {
    ${({ isRotating }) =>
      isRotating &&
      css`
        animation: ${rotating} ${ROTATING_ANIMATION_TIME_IN_MS}ms linear
          infinite;
      `}
  }
`;

export const RefreshCreditButtonWrapper = styled(Box)`
  height: 75%;
  margin-right: ${spaces('xs')};
`;

export const RefreshCreditButton = styled(SimpleButton)`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;

export const CashText = styled.strong`
  font-size: ${fontSizes('md')};
  ${fontWeight('bold')};
  white-space: nowrap;
`;

export const CashWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) =>
    isColorDark(theme.colors.topNavBackground)
      ? lighten(0.1)(theme.colors.topNavBackground)
      : darken(0.1)(theme.colors.topNavBackground)};
  color: ${colors('topNavText')};
  height: 26px;
  border-radius: 13px;
  font-size: ${fontSizes('sm')};
  padding: 0 ${spaces('xxxs')} 0 ${spaces('xs')};

  ${media.xl} {
    height: 40px;
    border-radius: 20px;
    padding-right: ${spaces('xxs')};
  }
`;

type DepositNavLinkProps = {
  height: number;
};

export const DepositNavLink = styled(NavLink)<DepositNavLinkProps>`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors('deposit')};
  color: ${colors('buttonDepositText')};
  min-width: ${(props) => props.height}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.height / 2}px;
  position: relative;
  text-transform: uppercase;

  ${media.sm} {
    flex-grow: 1;
    justify-content: flex-start;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) =>
      isColorDark(theme.colors.deposit)
        ? lighten(0.1)(theme.colors.deposit)
        : darken(0.1)(theme.colors.deposit)};
    svg {
      path {
        fill: ${({ theme }) =>
          isColorDark(theme.colors.deposit)
            ? lighten(0.1)(theme.colors.deposit)
            : darken(0.1)(theme.colors.deposit)};
      }
    }
  }
`;

export const IconAddText = styled.strong`
  display: none;
  ${media.sm} {
    display: inline;
    font-size: ${fontSizes('md')};
    padding: 0 ${spaces('xs')};
  }
`;

type IconAddInnerProps = {
  wHeight: number;
};

export const IconAddInner = styled.span<IconAddInnerProps>`
  margin: 0;
  height: 16px;
  width: 16px;
  line-height: 0;
  background-color: ${colors('buttonDepositText')};

  border-radius: 50%;

  ${media.sm} {
    height: calc(${(props) => props.wHeight}px - (2 * ${spaces('xxxs')}));
    width: calc(${(props) => props.wHeight}px - (2 * ${spaces('xxxs')}));
    margin-left: ${spaces('xxxs')};
  }

  ${media.xl} {
    height: calc(${(props) => props.wHeight}px - (2 * ${spaces('xxs')}));
    width: calc(${(props) => props.wHeight}px - (2 * ${spaces('xxs')}));
    margin-left: ${spaces('xxs')};
  }

  ${'& svg'} {
    border: 2px solid ${colors('buttonDepositText')};
    border-radius: 50%;
  }
`;
