import { normalize, schema } from 'normalizr';

import {
  GamesCategoriesData,
  GamesData,
  GetGameCategoriesResponse,
  GetPaginatedGameListResponse,
} from './types';

/* Games */

const gameAttribute = new schema.Entity(
  'attributes',
  {},
  { idAttribute: 'Id' },
);
const gameAttributes = new schema.Array(gameAttribute);

const gameAttributeCategory = new schema.Entity(
  'attributeCategories',
  {
    AttributeList: gameAttributes,
  },
  { idAttribute: 'Id' },
);
const gameAttributeCategories = new schema.Array(gameAttributeCategory);

const game = new schema.Entity(
  'games',
  {
    AttributeList: gameAttributes,
  },
  { idAttribute: 'Id' },
);
const games = new schema.Array(game);

export const formatGamesData = (
  apiData: GetPaginatedGameListResponse,
): GamesData => {
  const {
    AttributeCategoriesList,
    List,
    Total,
    PageNumber,
    PageSize,
    ...meta
  } = apiData;
  const { result: gameResult, entities: gameEntities } = normalize(List, games);
  const { result: catResult, entities: catEntities } = normalize(
    AttributeCategoriesList,
    gameAttributeCategories,
  );

  const attributeResult = apiData.AttributeCategoriesList.map(
    (attributeCategory) =>
      attributeCategory.AttributeList.map((attribute) => attribute.Id),
  ).reduce((acc, idList) => [...acc, ...idList], []);

  return {
    entities: {
      games: {},
      attributes: {},
      attributeCategories: {},
      ...gameEntities,
      ...catEntities,
    },
    result: {
      games: gameResult,
      attributeCategories: catResult,
      attributes: attributeResult,
    },
    meta,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};

/* Game Categories */

const gamesCategory = new schema.Entity(
  'gamesCategories',
  {},
  { idAttribute: 'Id' },
);
const gamesCategories = new schema.Array(gamesCategory);

gamesCategory.define({
  AttributeCategoriesList: gameAttributes,
  // Defined after initialization because this prop is recursive
  ChildCategories: gamesCategories,
});

// gamesCategory.define({  });

export const formatgamesCategoriesData = (
  apiData: GetGameCategoriesResponse,
): GamesCategoriesData => {
  const { List, ...meta } = apiData;
  const normalizedData = normalize(List, gamesCategories);
  const entities = normalizedData.entities as GamesCategoriesData['entities'];
  const { result } = normalizedData;
  /*
  const typedGameCategories = (entities as GetGamesCategoriesResponseDTO).List;
  const nonRecursiveGameCategories = Object.entries(typedGameCategories).reduce(
    (acc, [id, entity]) => ({
      ...acc,
      [id]: {
        ...entity,
        ChildCategories: entity.ChildCategories.map((cat) => cat.Id),
      },
    }),
    {},
  );
  */
  return {
    entities,
    meta,
    result,
  };
};
