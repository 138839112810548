import React, { ContextType, FC, memo, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useConfig } from '@gaming1/g1-config';
import {
  isLoggingAllowed,
  LogLevel,
  setupLogInterceptor,
} from '@gaming1/g1-logger';

import { logger } from '../../../logger';
import { TrackingContext } from '../../TrackingContext';
import { DatadogEventsManager } from '../DataDogEventsManager';
import { GTMEventsManager } from '../GTMEventsManager';

import { useDataDog } from './useDataDog';
import { useGTM } from './useGTM';

/**
 * Init Google Tag Manager if the id is in the current config
 * Also adds the google-site-verification if value is in the current config
 * Calls the DataDog hook
 * Provide the TrackingContext to track events
 */
export const TrackingProvider: FC = memo(({ children }) => {
  const {
    seo: { googleSiteVerification, gtmId },
  } = useConfig();

  const { isGtmEnabled, pushEvent, pushRawData } = useGTM();

  const {
    isDataDogEnabled,
    pushDataDogContext,
    pushDataDogAction,
    pushDataDogError,
  } = useDataDog();

  const verificationMetaTag = googleSiteVerification ? (
    <Helmet>
      <meta name="google-site-verification" content={googleSiteVerification} />
    </Helmet>
  ) : null;

  const gtmScript = gtmId ? (
    <Helmet
      script={[
        {
          src: `https://www.googletagmanager.com/gtm.js?id=${gtmId}`,
          id: 'gtm',
        },
      ]}
    />
  ) : null;

  /** Log a successful render of the google-site-verification meta tag */
  useEffect(() => {
    if (googleSiteVerification) {
      logger.info(
        `Google site verification meta tag set with value "${googleSiteVerification}"`,
      );
    }
  }, [googleSiteVerification]);

  useEffect(() => {
    if (isDataDogEnabled) {
      // Forwards all logger.error calls to DD
      setupLogInterceptor((methodName, logLevel) => (...args) => {
        // Only forward errors they aren't printed to the console to avoid
        // dupplicates since DataDog already intercept those
        if (methodName === 'error' && logLevel === LogLevel.SILENT) {
          pushDataDogError(args.length === 1 ? args[0] : args);
        }
        return isLoggingAllowed(methodName, logLevel);
      });
    }
    return () => {
      setupLogInterceptor(null);
    };
  }, [pushDataDogError, isDataDogEnabled]);

  const contextValue: ContextType<typeof TrackingContext> = useMemo(
    () => ({
      isDataDogEnabled,
      isGtmEnabled,
      pushDataDogAction,
      pushDataDogContext,
      pushDataDogError,
      pushEvent,
      pushRawData,
    }),
    [
      isDataDogEnabled,
      isGtmEnabled,
      pushDataDogAction,
      pushDataDogContext,
      pushDataDogError,
      pushEvent,
      pushRawData,
    ],
  );

  return (
    <TrackingContext.Provider value={contextValue}>
      {verificationMetaTag}
      {isGtmEnabled && (
        <>
          {gtmScript}
          <GTMEventsManager />
        </>
      )}
      {isDataDogEnabled && <DatadogEventsManager />}
      {children}
    </TrackingContext.Provider>
  );
});
