import styled, { css } from 'styled-components';

import { colors } from '@gaming1/g1-style';

const fullSize = css`
  height: 100%;
  width: 100%;
`;

export const CircularProgressBarWrapper = styled.div`
  ${fullSize}

  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const CircularProgressBarSvg = styled.svg`
  ${fullSize}
  transform: rotate(-90deg);
  left: 0;
  position: absolute;
  top: 0;
`;

export const BackgroundCircle = styled.circle`
  stroke: ${({ theme }) =>
    theme.dark ? theme.colors.backgroundDark : theme.colors.backgroundLight};
  stroke-width: 10px;
`;

export const ProgressCircle = styled.circle`
  stroke: ${colors('clubStatus')};
  stroke-width: 10px;
`;
