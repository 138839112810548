import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import {
  isOutcomeAvailableSelector,
  marketSelector,
  outcomeSelector,
} from '../../../../sportbook/store/selectors';
import { calcOddsWithOptimalBets } from '../../../helpers/calc';
import { ItemValid } from '../../../helpers/elements/item';
import { BettingSlipItemState } from '../../types/common';
import {
  bettingSlipExtraInfoGetterSelector,
  bettingSlipGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  boostusSelectedForBSGetterSelector,
} from '../common';
import { getOptimaBetsInformationIfItemIsEligible } from '../optimalBet/optimalBet';
import { riskManagementLimitsGetterSelector } from '../riskManagement/riskManagement';

/**
 * Don't forget to use shallowEqual with this selector
 *
 * The itemId is the outcomeId of the item.
 */
export const bettingSlipItemGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
        itemId: number,
      ): BettingSlipItemState | undefined =>
        getBettingSlip(bettingSlipId)?.items.find((item) => item.id === itemId),
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);

export const bettingSlipItemIsValidOutcomeGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  isOutcomeAvailableSelector,
  (bettingSlipsItem, isOutcomeAvailable) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number): boolean => {
        const item = bettingSlipsItem(bettingSlipId, itemId);

        if (!item) {
          return false;
        }

        return isOutcomeAvailable(item.outcome.id);
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);
export const bettingSlipItemListIsValidOutcomeGetterSelector = createSelector(
  bettingSlipItemIsValidOutcomeGetterSelector,
  (bettingSlipsItemIsValid) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, ids: number[]) =>
        ids.map((id) => bettingSlipsItemIsValid(bettingSlipId, id)),
      (bettingSlipId: BettingSlipIdentifierType, ids: number[]) =>
        `${bettingSlipId}-${ids.join('-')}`,
    ),
);

export const isBettingSlipItemSelectedGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  (getBettingSlipsItem) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number): boolean =>
        !!getBettingSlipsItem(bettingSlipId, itemId),
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);

export const bettingSlipItemProviderGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  marketSelector,
  (bettingSlipsItem, marketSelectorS) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
        const item = bettingSlipsItem(bettingSlipId, itemId);

        if (!item) {
          return undefined;
        }
        const market = marketSelectorS(item?.market.id);

        if (!item || !market) {
          return undefined;
        }
        return market.Provider;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);

export const bettingSlipItemBaseOddGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  outcomeSelector,
  (bettingSlipsItem, outcomeSelectorS) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
        const item = bettingSlipsItem(bettingSlipId, itemId);

        if (!item) {
          return 1;
        }
        const outcome = outcomeSelectorS(item?.outcome.id);

        if (!item || !outcome) {
          return 1;
        }
        return outcome.Odd < 1 ? 1 : outcome.Odd;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);
export const bettingSlipItemsListBaseOddGetterSelector = createSelector(
  bettingSlipItemBaseOddGetterSelector,
  (bettingSlipsItemBaseOdds) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, ids: number[]) =>
        ids.map((id) => bettingSlipsItemBaseOdds(bettingSlipId, id)),
      (bettingSlipId: BettingSlipIdentifierType, ids: number[]) =>
        `${bettingSlipId}-${ids.join('-')}`,
    ),
);
/**
 * The oddVariation is by default to null for the moment, check the getInitialTicketItem helper
 */
export const bettingSlipItemOddVariationGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  (bettingSlipsItem) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
        const item = bettingSlipsItem(bettingSlipId, itemId);
        return item?.oddChange;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);

export const bettingSlipItemLimitsGetterSelector = createSelector(
  riskManagementLimitsGetterSelector,
  bettingSlipExtraInfoGetterSelector,
  (limits, extraInfo) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        limits(bettingSlipId) || extraInfo(bettingSlipId)?.limits,
      (bettingSlipId: BettingSlipIdentifierType) => `${bettingSlipId}`,
    ),
);

export const bettingSlipItemIsCheckedGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  (bettingSlipsItem) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
        const item = bettingSlipsItem(bettingSlipId, itemId);
        if (!item) {
          return false;
        }
        return item?.checked;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);

/**
 * ForValidationHelper
 */
export const bettingSlipItemForValidationGetterSelector = createSelector(
  bettingSlipItemIsValidOutcomeGetterSelector,
  bettingSlipItemBaseOddGetterSelector,
  (
    getBettingSlipItemIsValidOutcomeGetterSelector,
    getBettingSlipItemBaseOddGetterSelector,
  ) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
        itemId: number,
      ): ItemValid => ({
        odd: getBettingSlipItemBaseOddGetterSelector(bettingSlipId, itemId),
        isOutcomeValid: getBettingSlipItemIsValidOutcomeGetterSelector(
          bettingSlipId,
          itemId,
        ),
        notification: undefined,
      }),
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);

/**
 * Will get the final odds of an item from the bettingSlip.
 *
 * It'll check if the item is eligible for the opti-odds and add the offset
 * to the odds if it's eligible.
 */
export const getFinalOddsOfBettingSlipItemGetterSelector = createSelector(
  bettingSlipItemBaseOddGetterSelector,
  getOptimaBetsInformationIfItemIsEligible,
  bettingSlipRoundingConfigGetterSelector,
  boostusSelectedForBSGetterSelector,
  (baseOdd, offsetOptimalBets, roundingConfig, boostusSelected) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) => {
        const odds = baseOdd(bettingSlipId, itemId);

        if (boostusSelected(bettingSlipId)) {
          return odds;
        }
        const optimalBetsInformation = offsetOptimalBets(bettingSlipId, itemId);

        if (optimalBetsInformation) {
          const { Coefficient, Offset } = optimalBetsInformation;

          return calcOddsWithOptimalBets(roundingConfig(bettingSlipId))(
            odds,
            Coefficient || 1,
            Offset || 0,
          );
        }

        return odds;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);
