import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { Provider, TradingState } from '@gaming1/g1-requests-betting';

import { NormalizedPlayerProp } from '../types';

import {
  eventsSelector,
  marketsSelector,
  playerPropsSelector,
} from './entities';
import { isMarketAvailableSelector } from './markets';

/**
 * Returns player props by id
 * Don't forget to use shallowEqual with this selector
 */
export const playerPropsByIdSelector = createSelector(
  playerPropsSelector,

  (getPlayerProps) =>
    memoize(
      (ids: number[]) => ids.map((ppId) => getPlayerProps[ppId]),

      // Cache key resolver
      (ids: number[]) => `${ids.join('-')}`,
    ),
);

/**
 * Takes an event id and market names and returns player markets
 * Don't forget to use shallowEqual with this selector
 */
export const playerPropsByMarketNameSelector = createSelector(
  eventsSelector,
  playerPropsSelector,

  (getEvents, getPlayerProps) =>
    memoize(
      (eventId: number, marketName: string) => {
        const playerPropIds = getEvents[eventId]?.PlayerProps || [];
        const playerProps = playerPropIds.map((ppId) => getPlayerProps[ppId]);

        const filteredPP = playerProps.filter(
          (pp) => pp?.PlayerPropName === marketName,
        );

        return filteredPP;
      },

      // Cache key resolver
      (eventId: number, marketName: string) => `${eventId}${marketName}`,
    ),
);

/**
 * Takes a player prop id and returns all his player markets
 * Don't forget to use shallowEqual with this selector
 */
export const playerPropColsSelector = createSelector(
  playerPropsSelector,
  (getPlayerProps) =>
    memoize((playerPropId: number) =>
      orderBy(getPlayerProps[playerPropId]?.Columns || [], 'Position'),
    ),
);

/** Returns true if at least one market is available */
export const hasPlayerMarketsAvailableSelector = createSelector(
  isMarketAvailableSelector,
  (getIsMarketAvailable) =>
    memoize(
      (playerProps: NormalizedPlayerProp[]): boolean =>
        playerProps.some((pp) => pp?.Markets?.some(getIsMarketAvailable)),

      // Cache key resolver
      (playerProps: NormalizedPlayerProp[]) =>
        `${playerProps.map((pp) => pp.Markets?.join(',')).join('-')}`,
    ),
);

/** Returns the Provider of (the first market of) a playerProp */
export const playerPropProviderSelector = createSelector(
  marketsSelector,
  (getMarket) =>
    memoize(
      (playerProps: NormalizedPlayerProp[]) => {
        const marketId = playerProps[0]?.Markets?.[0] || 0;
        const market = getMarket[marketId];

        return Provider[market?.Provider];
      },

      // Cache key resolver
      (playerProps: NormalizedPlayerProp[]) =>
        `${playerProps.map((pp) => pp.Markets?.join(',')).join('-')}`,
    ),
);

/** Returns the State of (the first market of) a playerProp */
export const playerPropStateSelector = createSelector(
  marketsSelector,
  (getMarket) =>
    memoize(
      (playerProps: NormalizedPlayerProp[]) => {
        const marketId = playerProps[0]?.Markets?.[0] || 0;
        const market = getMarket[marketId];

        return TradingState[market?.State];
      },

      // Cache key resolver
      (playerProps: NormalizedPlayerProp[]) =>
        `${playerProps.map((pp) => pp.Markets?.join(',')).join('-')}`,
    ),
);
