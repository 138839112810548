import React, { VFC } from 'react';
import { useSelector } from 'react-redux';

import { currentSectionSelector } from '@gaming1/g1-core';
import { ResettingWrapper } from '@gaming1/g1-core-web';

import { AllSportsDrawer } from '../../../sportbook/components/AllSportsDrawer';
import { BettingSlipDrawer } from '../BettingSlipDrawer';

/**
 * This component is supposed to be rendered in the "AppLayout" of the partner
 * app.
 *
 * It will render :
 *
 * - AllSportsDrawer that contains the betting navigation.
 * - BettingSlipDrawer that contains the betting slip.
 */
export const BettingWatchers: VFC = () => {
  const currentSection = useSelector(currentSectionSelector);
  /*
   * ResettingWrapper component will ensure that the children components are
   * always unmounted while some actions are pending (logout, switch locale...).
   */
  return currentSection === 'betting' ? (
    <ResettingWrapper>
      <AllSportsDrawer />
      <BettingSlipDrawer />
    </ResettingWrapper>
  ) : null;
};
