import * as t from 'io-ts';

import {
  ERegisterGameStatus,
  ERegisterGameType,
} from '@gaming1/g1-requests-gaming';

/* Entities */

export const game = t.intersection([
  t.type({
    Id: t.string,
    Name: t.string,
    Enabled: t.boolean,
    GameType: t.string,
    GameClass: t.string,
    GameProviderName: t.string,
    MinBet: t.number,
    MaxBet: t.number,
    MaxWin: t.number,
    IsOnlyAvailableInRealMoney: t.boolean,
    LobbyMediaResourceUri: t.string,
    // RulesMediaResourceUri: t.string,
    IsNewGame: t.boolean,
    IsTopGame: t.boolean,
    IsRestricted: t.boolean,
    IsFavorite: t.boolean,
    // IsCountryForbidden: t.boolean,
    // ListRTPDate: t.array(t.unknown),
    IsVisible: t.boolean,
    // MetaDatas:  t.array(t.unknown),
  }),
  t.partial({
    GameOrder: t.number,
    ProgressiveJackpotName: t.string,
    /* Those values are marked as partial and loosely typed because they are not
    used for now */
    RulesMediaResourceUri: t.string,
    IsCountryForbidden: t.boolean,
    ListRTPDate: t.array(t.type({ CalculatedDate: t.string, RTP: t.number })),
    MetaDatas: t.array(t.type({ Key: t.string, Value: t.string })),
    AttributeIdList: t.array(t.string),
    MinimalVipRank: t.number,
    ForbiddenCountries: t.string,
    RestrictedCountries: t.string,
    EnabledLanguageCodes: t.array(t.string),
  }),
]);

// Game launcher codecs

export const internalGameParametersCodec = t.type({
  BootScriptName: t.string,
  ConnectionId: t.string,
  GameGuid: t.string,
  GameServerEndpoint: t.string,
  ResourcePath: t.string,
});

const templateDefinitionCodec = t.partial({
  TemplateKey: t.string,
  Parameters: t.array(
    t.partial({
      Name: t.string,
      Value: t.string,
    }),
  ),
});
export const connectGameDataCodec = t.intersection([
  t.type({
    ServerUrl: t.string,
    Token: t.string,
    Language: t.string,
  }),
  t.partial({
    TemplateDefinitions: t.array(templateDefinitionCodec),
  }),
]);
export const externalGameParametersCodec = t.intersection([
  t.type({
    GameLaunchUrl: t.string,
  }),
  t.partial({
    ConnectedPopupParameter: connectGameDataCodec,
  }),
]);

// This is a common codec which has to be composed to create the startTournament
// and registerGame response codecs
const internalGameResponseCodec = t.type({
  Status: t.literal(ERegisterGameStatus.Successful),
  InternalGameParameters: internalGameParametersCodec,
});

const registerInternalGaming1GameResponseCodec = t.intersection(
  [
    internalGameResponseCodec,
    t.type({
      RegisterGameType: t.literal(ERegisterGameType.Gaming1Internal),
    }),
  ],
  'RegisterInternalGaming1GameResponse',
);

const registerExternalGameResponseCodec = t.type(
  {
    Status: t.literal(ERegisterGameStatus.Successful),
    RegisterGameType: t.union([
      t.literal(ERegisterGameType.Gaming1v2),
      t.literal(ERegisterGameType.EGT),
      t.literal(ERegisterGameType.AirDice),
      t.literal(ERegisterGameType.Amatic),
      t.literal(ERegisterGameType.Apollo),
      t.literal(ERegisterGameType.BetSoftGaming),
      t.literal(ERegisterGameType.Blueprint),
      t.literal(ERegisterGameType.BoomingGames),
      t.literal(ERegisterGameType.CasinoTechnology),
      t.literal(ERegisterGameType.EGaming),
      t.literal(ERegisterGameType.Endorphina),
      t.literal(ERegisterGameType.EvolutionGaming),
      t.literal(ERegisterGameType.Ezugi),
      t.literal(ERegisterGameType.Fazi),
      t.literal(ERegisterGameType.GAA),
      t.literal(ERegisterGameType.GreenTube),
      t.literal(ERegisterGameType.IGT),
      t.literal(ERegisterGameType.ISB),
      t.literal(ERegisterGameType.Kajot),
      t.literal(ERegisterGameType.Merkur),
      t.literal(ERegisterGameType.MGA),
      t.literal(ERegisterGameType.MicroGaming),
      t.literal(ERegisterGameType.Nyx),
      t.literal(ERegisterGameType.Oryx),
      t.literal(ERegisterGameType.PariPlay),
      t.literal(ERegisterGameType.Playson),
      t.literal(ERegisterGameType.Playtech),
      t.literal(ERegisterGameType.PragmaticPlay),
      t.literal(ERegisterGameType.ProgaIndia),
      t.literal(ERegisterGameType.QuickSpin),
      t.literal(ERegisterGameType.RedTiger),
      t.literal(ERegisterGameType.RFranco),
      t.literal(ERegisterGameType.Ribalix),
      t.literal(ERegisterGameType.Spinomenal),
      t.literal(ERegisterGameType.Thunderkick),
      t.literal(ERegisterGameType.TomHorn),
      t.literal(ERegisterGameType.Wazdan),
      t.literal(ERegisterGameType.WorldMatch),
      t.literal(ERegisterGameType.Yggdrasil),
      t.literal(ERegisterGameType.Synot),
      t.literal(ERegisterGameType.GameArt),
      t.literal(ERegisterGameType.Mancala),
      t.literal(ERegisterGameType.NoLimitCity),
      t.literal(ERegisterGameType.PowerGaming),
      t.literal(ERegisterGameType.Skywind),
    ]),
    ExternalGameParameters: externalGameParametersCodec,
  },
  'RegisterExternalGameResponse',
);

export const registerGameResponseCodec = t.union(
  [registerExternalGameResponseCodec, registerInternalGaming1GameResponseCodec],
  'RegisterGameResponse',
);

export const startTournamentResponseCodec = internalGameResponseCodec;
