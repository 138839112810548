import formatDate from 'date-fns/format';
import isValidDate from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import React, { FC, useContext } from 'react';

import { BettingHistoryElementType } from '@gaming1/g1-betting';
import { ConfigContext } from '@gaming1/g1-config';
import { useDateLocale } from '@gaming1/g1-core';

import { Date } from './styles';
import { TypeViewStyle } from './types';

export type DateInfoProps = {
  info: BettingHistoryElementType;
} & TypeViewStyle;
export const DateInfo: FC<DateInfoProps> = ({ info, view }) => {
  const { i18n } = useContext(ConfigContext);
  const parsedDate = parseISO(info?.StartDate ?? '');
  const locale = useDateLocale();

  if (!isValidDate(parsedDate)) {
    return null;
  }
  return view === 'compact' || view === 'details' ? (
    <Date view={view} data-testid="history-element-date">
      {formatDate(parsedDate, i18n.dateTimePartialFormat, { locale })}
    </Date>
  ) : null;
};
