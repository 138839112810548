import React, { FC } from 'react';

import { useFormatOdd } from '../../../hooks';

import {
  BaseValueContainer,
  FinalValueContainer,
  OddsContainer,
} from './styles';

type OddsProps = {
  baseOdds?: number;
  finalOdds?: number;
  id?: string;
};

export const Odds: FC<OddsProps> = ({ baseOdds, finalOdds, id }) => {
  const formatOdd = useFormatOdd;

  return (
    <OddsContainer data-testid={id ? `${id}-odds-container` : 'odds-container'}>
      {finalOdds ? (
        <>
          <BaseValueContainer
            data-testid={id ? `${id}-base-odds` : 'base-odds'}
          >
            {formatOdd(baseOdds)}
          </BaseValueContainer>
          <FinalValueContainer
            ml="xs"
            data-testid={id ? `${id}-final-odds` : 'final-odds'}
          >
            {formatOdd(finalOdds)}
          </FinalValueContainer>
        </>
      ) : (
        <FinalValueContainer data-testid={id ? `${id}-base-odds` : 'base-odds'}>
          {formatOdd(baseOdds)}
        </FinalValueContainer>
      )}
    </OddsContainer>
  );
};
