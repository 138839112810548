import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  actions,
  bettingActions,
  bettingSlipAllItemsGetterSelector,
  bettingSlipHaveLiveGetterSelector,
  BettingSlipIdentifierType,
  BettingSlipIdType,
  bettingSlipItemOddVariationGetterSelector,
  bettingSlipItemsGetterSelector,
  bettingSlipItemsSportBookIdsGetterSelector,
  bettingSlipStakeGetterSelector,
  BettingSlipStringType,
  bettingSlipTypeGetterSelector,
  eventListSelector,
  getBettingUserSettingsRequestStateSelector,
  haveBettingSlipGetterSelector,
  ImportBettingSlip,
  importBettingSlipCodec,
  isPlaceBetNotificationASuccessSelector,
  LightEventType,
  LightMarketType,
  LightOutcomeType,
  marketListSelector,
  outcomeListSelector,
  useBettingGetterSelector,
  useBettingSlipType,
} from '@gaming1/g1-betting';
import { userLoggedInSelector } from '@gaming1/g1-core';
import { MenuGroups, useRoutePath } from '@gaming1/g1-core-web';
import { useRequestState } from '@gaming1/g1-store';
import { DrawerType, LayoutContext } from '@gaming1/g1-ui';
import {
  curriedHasNonNullableProperty,
  isNonNullable,
  isNotDuplicate,
  persistIn,
  readFrom,
  Serializable,
  usePrevious,
  usePreviousDifferent,
} from '@gaming1/g1-utils';

import { logger } from '../../logger';
import { bettingRoutes } from '../../routes';

export const THROTTLE_DELAY_IN_MS = 2000;

export const useBettingSlipPersistence = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const haveBS = useBettingGetterSelector({
    getterSelector: haveBettingSlipGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const bsItems =
    useBettingGetterSelector({
      getterSelector: bettingSlipAllItemsGetterSelector,
      args: [bettingSlipId],
      equalityFn: isEqual,
    }) || [];

  const bsStake = useBettingGetterSelector({
    getterSelector: bettingSlipStakeGetterSelector,
    args: [bettingSlipId],
  });

  const type = useBettingSlipType(bettingSlipId);

  const eventIds = bsItems.map((item) => item.event.id).filter(isNotDuplicate);
  const events: LightEventType[] = useBettingGetterSelector({
    getterSelector: eventListSelector,
    args: [eventIds || [], false],
    equalityFn: isEqual,
  })
    .filter(isNonNullable)
    .map((item) => ({
      EventId: item.EventId,
      MarketId: 0,
      OutcomeId: 0,
      HomeName: item.HomeName,
      AwayName: item.AwayName,
    }));

  const marketIds = bsItems
    .map((item) => item.market.id)
    .filter(isNotDuplicate);
  const markets: LightMarketType[] = useBettingGetterSelector({
    getterSelector: marketListSelector,
    args: [marketIds || [], false],
    equalityFn: isEqual,
  })
    .filter(isNonNullable)
    .filter(curriedHasNonNullableProperty('MarketName'))
    .map((item) => ({
      EventId: item.EventId,
      MarketId: item.MarketId,
      MarketName: item.MarketName,
      Provider: item.Provider,
      State: item.State,
    }));

  const outcomeIds = bsItems
    .map((item) => item.outcome.id)
    .filter(isNotDuplicate);
  const outcomes: LightOutcomeType[] = useBettingGetterSelector({
    getterSelector: outcomeListSelector,
    args: [outcomeIds || [], false],
    equalityFn: isEqual,
  })
    .filter(isNonNullable)
    .filter(curriedHasNonNullableProperty('Name'))
    .map((item) => ({
      OutcomeId: item.OutcomeId,
      EventId: item.EventId,
      MarketId: item.MarketId,
      Name: item.Name,
      Odd: item.Odd,
      Base: item.Base,
    }));

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      // if BettingSlip is already fill don't need to import session data (for exemple on change page)
      if (haveBS) {
        return;
      }
      const sessionTicket = readFrom(sessionStorage, `ticket-${bettingSlipId}`);

      if (!importBettingSlipCodec.is(sessionTicket)) {
        return;
      }
      const eventsSession: LightEventType[] = sessionTicket.events.map(
        (item) => ({
          EventId: item.EventId,
          OutcomeId: item.OutcomeId,
          MarketId: item.MarketId,
          HomeName: item.HomeName,
          AwayName: item.AwayName,
        }),
      );
      const marketsSession: LightMarketType[] = sessionTicket.markets.map(
        (item) => ({
          EventId: item.EventId,
          MarketId: item.MarketId,
          MarketName: item.MarketName,
          Provider: item.Provider,
          State: item.State,
        }),
      );
      const outcomesSession: LightOutcomeType[] = sessionTicket.outcomes.map(
        (item) => ({
          OutcomeId: item.OutcomeId,
          EventId: item.EventId,
          MarketId: item.MarketId,
          Name: item.Name,
          Odd: item.Odd,
          Base: item.Base,
        }),
      );

      dispatch(
        actions.initBettingSlipBySession({
          bettingSlip: {
            bettingSlipId,
            bettingSlip: sessionTicket.bettingSlip,
          },
          events: eventsSession,
          markets: marketsSession,
          outcomes: outcomesSession,
        }),
      );
    } catch {
      logger.warn(`Error while restoring ticket ${bettingSlipId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const session: ImportBettingSlip = {
    bettingSlip: {
      items: bsItems.map((item) => ({
        id: item.id,
        event: item.event.id,
        market: item.market.id,
        outcome: item.outcome.id,
      })),
      stake: bsStake || '',
      type,
    },
    events,
    markets,
    outcomes,
  };

  const saveBettingSlip = useRef(
    throttle((slip: ImportBettingSlip) => {
      persistIn(
        sessionStorage,
        `ticket-${bettingSlipId}`,
        slip as Serializable,
      );
    }, THROTTLE_DELAY_IN_MS),
  ).current;

  useEffect(() => {
    saveBettingSlip(session);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(session)]);
};

type AutomatiqueSwitchType = {
  bettingSlipId: BettingSlipIdentifierType;
  type: BettingSlipStringType;
  switchType: (type: BettingSlipStringType) => void;
};
export const useAutomaticSwitchType = ({
  bettingSlipId,
  type,
  switchType,
}: AutomatiqueSwitchType) => {
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const haveLive = useBettingGetterSelector({
    getterSelector: bettingSlipHaveLiveGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const itemsLength = useMemo(() => items?.length || 0, [items]);

  const [previousLength, setPreviousLength] = useState(() => itemsLength);

  useEffect(() => {
    if (previousLength === itemsLength) {
      return;
    }
    setPreviousLength(itemsLength);

    if (type === 'system') {
      if (haveLive) {
        switchType('combi');
        return;
      }
      if (items && items?.length > 2) {
        return;
      }
    }
    if (itemsLength === 1) {
      switchType('single');
    } else {
      switchType('combi');
    }
  }, [haveLive, items, itemsLength, previousLength, switchType, type]);
};

type AfterPlacebetSuccessProps = {
  /** The bettingslip id to work with */
  bettingSlipId: BettingSlipIdentifierType;
};
/**
 * Hook that returns a boolean to tell us if return after a success of placebet
 * @param bettingSlipId the bettingslip id to work with
 */
export const useAfterPlacebetSuccess = ({
  bettingSlipId,
}: AfterPlacebetSuccessProps) => {
  const isPlaceBetASuccess = useBettingGetterSelector({
    getterSelector: isPlaceBetNotificationASuccessSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const previousState = usePrevious(isPlaceBetASuccess);

  return previousState === true && isPlaceBetASuccess === false;
};

type TypeHasBeenModifyProps = {
  /** The bettingslip id to work with */
  bettingSlipId: BettingSlipIdentifierType;
};
/**
 * Hook that returns a boolean to tell us if there is a new outcome added or not.
 * @param bettingSlipId the bettingslip id to work with
 */
export const useTypeHasBeenModify = ({
  bettingSlipId,
}: TypeHasBeenModifyProps) => {
  const type = useBettingGetterSelector({
    getterSelector: bettingSlipTypeGetterSelector,
    args: [bettingSlipId],
  });
  const previousType = usePrevious(type);

  return type && previousType && type !== previousType;
};

type OutcomesHasBeenModifyProps = {
  /** The bettingslip id to work with */
  bettingSlipId: BettingSlipIdentifierType;
};
/**
 * Hook that returns a boolean to tell us if there is a new outcome added or not.
 * @param bettingSlipId the bettingslip id to work with
 */
export const useOutcomesHasBeenAdded = ({
  bettingSlipId,
}: OutcomesHasBeenModifyProps) => {
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const itemsLength = items?.length || 0;

  const previousItemsLength = usePrevious(itemsLength) || 0;

  return previousItemsLength < itemsLength;
};

/**
 * Hook that returns a boolean to tell us if there is a new outcome added or not.
 * @param bettingSlipId the bettingslip id to work with
 */
export const useOutcomesHasBeenDeleted = ({
  bettingSlipId,
}: OutcomesHasBeenModifyProps) => {
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const itemsLength = items?.length || 0;

  const previousItemsLength = usePrevious(itemsLength) || 0;

  return previousItemsLength > itemsLength;
};

/**
 * Hook that returns a boolean to tell us if there is a new outcome added or not.
 * @param bettingSlipId the bettingslip id to work with
 */
export const useOutcomesHasBeenUpdated = ({
  bettingSlipId,
}: OutcomesHasBeenModifyProps) => {
  const added = useOutcomesHasBeenAdded({ bettingSlipId });
  const deleted = useOutcomesHasBeenDeleted({ bettingSlipId });

  return added || deleted;
};

export const useBettingSlipUserPreference = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const { isNotAsked } = useRequestState(
    getBettingUserSettingsRequestStateSelector,
  );

  useEffect(() => {
    if (isUserLoggedIn && isNotAsked) {
      dispatch(actions.getBettingUserSettings.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, isNotAsked]);
};

/** Get the odd update type for an outcome */
export const useSetBettingSlipOutcomeOddUpdates = (
  currentOdd: number | undefined,
  itemId: number,
  bettingSlipId: BettingSlipIdentifierType,
): void => {
  const dispatch = useDispatch();
  const previousOdd = usePreviousDifferent(currentOdd);
  const oddVariation = useBettingGetterSelector({
    getterSelector: bettingSlipItemOddVariationGetterSelector,
    args: [bettingSlipId, itemId],
  });

  useEffect(() => {
    if (!currentOdd) {
      return;
    }
    if (currentOdd && previousOdd) {
      if (currentOdd > previousOdd && oddVariation !== 'increase') {
        dispatch(
          actions.updateOddChangeForItem('increase', itemId, bettingSlipId),
        );
      } else if (currentOdd < previousOdd && oddVariation !== 'decrease') {
        dispatch(
          actions.updateOddChangeForItem('decrease', itemId, bettingSlipId),
        );
      } else if (oddVariation !== null) {
        dispatch(actions.updateOddChangeForItem(null, itemId, bettingSlipId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOdd, previousOdd]);
};

/** Get the odd update type for an outcome */
export const useSetBettingSlipOutcomeSubscribe = (
  bettingSlipId: BettingSlipIdentifierType,
): void => {
  const dispatch = useDispatch();

  const ids = useBettingGetterSelector({
    getterSelector: bettingSlipItemsSportBookIdsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });
  const outcomeIds = useMemo(() => ids?.map((id) => id.outcomeId) || [], [ids]);

  const previousOutcomeIds = usePrevious(outcomeIds);

  useEffect(() => {
    if (
      (!previousOutcomeIds || previousOutcomeIds.length === 0) &&
      (!outcomeIds || outcomeIds.length === 0)
    ) {
      return;
    }

    if (outcomeIds && outcomeIds.length > 0) {
      const newId = outcomeIds.filter(
        (id) => !previousOutcomeIds || previousOutcomeIds.indexOf(id) === -1,
      );
      batch(() => {
        newId?.forEach((id) =>
          dispatch(actions.subscribeToOutcome({ outcomeId: id })),
        );
      });
    }

    if (previousOutcomeIds && previousOutcomeIds.length > 0) {
      const removeId = previousOutcomeIds.filter(
        (id) => !outcomeIds || outcomeIds.indexOf(id) === -1,
      );
      batch(() => {
        removeId?.forEach((id) =>
          dispatch(actions.unsubscribeToOutcome({ outcomeId: id })),
        );
      });
    }
  }, [previousOutcomeIds, outcomeIds, dispatch]);
};

export const useRefScrollTo = (
  bettingSlipId: BettingSlipIdentifierType,
  refToScrollTo: RefObject<HTMLDivElement> | null,
  bettingSlipDrawerVisibility: boolean,
) => {
  const isNewOutcomeAdded = useOutcomesHasBeenAdded({ bettingSlipId });
  useLayoutEffect(() => {
    /**
     * If we're on desktop and a new outcome is added, we scroll to the footer.
     */
    if (isNewOutcomeAdded && refToScrollTo && refToScrollTo.current) {
      refToScrollTo.current.scrollIntoView({ behavior: 'smooth' });

      /**
       * But if we're in mobile and the bettingslip is opened through the drawer, we've to scroll
       * to the footer in the drawer too.
       */
    } else if (
      refToScrollTo &&
      refToScrollTo.current &&
      bettingSlipDrawerVisibility
    ) {
      refToScrollTo.current.scrollIntoView();
    }
  }, [refToScrollTo, isNewOutcomeAdded, bettingSlipDrawerVisibility]);
};

const useIsUserLoggedInDifferentFromPrevious = () => {
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const previousLoggedInState = usePrevious(isUserLoggedIn);

  return isUserLoggedIn !== previousLoggedInState;
};

/* Pipe */
export const usePipeManager = ({ bettingSlipId }: BettingSlipIdType) => {
  const dispatch = useDispatch();

  const outcomeListUpdated = useOutcomesHasBeenUpdated({ bettingSlipId });
  const typeModified = useTypeHasBeenModify({ bettingSlipId });
  const placebetState = useAfterPlacebetSuccess({ bettingSlipId });

  const [pipeId, setPipeId] = useState<string | null>(() => null);

  const isUserLoggedInDifferentFromPrevious =
    useIsUserLoggedInDifferentFromPrevious();

  const cancelPipe = useCallback(() => {
    if (pipeId !== null) {
      dispatch(bettingActions.cancelBetPipe({ betPipeId: pipeId }));
      setPipeId(null);
    }
  }, [dispatch, pipeId, setPipeId]);

  const launchPipe = useRef(
    debounce(() => {
      const startPipe = dispatch(
        bettingActions.startBetPipe({ bettingSlipId, type: 'betMaker' }),
      );
      setPipeId(startPipe.payload.betPipeId);
    }, 500),
  ).current;

  useEffect(() => {
    if (
      outcomeListUpdated ||
      typeModified ||
      isUserLoggedInDifferentFromPrevious ||
      placebetState
    ) {
      cancelPipe();
      launchPipe();
    }

    return () => {
      cancelPipe();
    };
  }, [
    bettingSlipId,
    cancelPipe,
    dispatch,
    isUserLoggedInDifferentFromPrevious,
    launchPipe,
    outcomeListUpdated,
    pipeId,
    typeModified,
    placebetState,
  ]);

  return pipeId;
};

/* For Menu */
export const useGetEmptyMenu = (): MenuGroups => {
  const getBettingPath = useRoutePath(bettingRoutes);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const { showDrawer } = useContext(LayoutContext);

  return [
    [
      {
        label: 'bettingSlip.inPlay.games.link',
        path: getBettingPath('liveListTop'),
        testId: 'betting-bettingslip-inplay-games-link',
        icon: 'Flame',
      },
      {
        label: 'bettingSlip.sportPanel.link',
        onClick: () => showDrawer(DrawerType.allSports),
        testId: 'betting-bettingslip-sportPanel-openButton',
        icon: 'Soccer',
      },
    ],
    [
      {
        label: 'bettingSlip.myHistory.link',
        path: getBettingPath('myOpenBets'),
        testId: 'betting-bettingslip-history',
        icon: 'BettingSlip',
        hidden: !isUserLoggedIn,
      },
    ],
  ];
};

export const useGetSuccessMenu = (): MenuGroups => {
  const getBettingPath = useRoutePath(bettingRoutes);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const { showDrawer } = useContext(LayoutContext);

  return [
    [
      {
        label: 'bettingSlip.inPlay.games.link',
        path: getBettingPath('liveListTop'),
        testId: 'betting-bettingslip-inplay-games-link',
        icon: 'Flame',
      },
      {
        label: 'bettingSlip.sportPanel.link',
        onClick: () => showDrawer(DrawerType.allSports),
        testId: 'betting-bettingslip-prematch',
        icon: 'Soccer',
      },
    ],
    [
      {
        label: 'bettingSlip.myHistory.link',
        path: getBettingPath('myOpenBets'),
        testId: 'betting-bettingslip-history',
        icon: 'BettingSlip',
        hidden: !isUserLoggedIn,
      },
    ],
  ];
};
