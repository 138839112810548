import styled, { css } from 'styled-components';

import { zIndex } from '@gaming1/g1-style';

/** Which side of the screen should the drawer come from */
type appearFrom = 'left' | 'right' | 'bottom';

type DrawerContainerProps = {
  appearFrom: appearFrom;
  /** Whether to show the drawer or not (animated when switched) */
  visible: boolean;
  /** The width of the drawer (with unit) */
  width: string;
  /** The height of the drawer (with unit) */
  height: string;
  /** The max height of the drawer (with unit) */
  maxHeight?: string;
};

const drawerNormalHeight = css<DrawerContainerProps>`
  ${({ appearFrom, height, width }) =>
    appearFrom === 'bottom'
      ? css`
          bottom: calc(-${height} - 10px);
          transition: bottom 0.3s ease, top 0.3s ease;
        `
      : css`
          ${appearFrom}: calc(-${width} - 10px);
          top: 0;
          transition: left 0.3s ease, right 0.3s ease;
        `}

  ${({ appearFrom, visible }) =>
    visible &&
    css`
      ${appearFrom}: 0;
    `}
`;

const drawerAutoHeight = css<DrawerContainerProps>`
  ${({ appearFrom }) => {
    if (appearFrom === 'bottom') {
      return css`
        transform: translate(0, 100%);
        bottom: 0;
      `;
    }

    if (appearFrom === 'right') {
      return css`
        transform: translate(100%, 0);
        right: 0;
      `;
    }

    return css`
      transform: translate(-100%, 0);
      left: 0;
    `;
  }}

  ${({ visible }) =>
    visible &&
    css`
      transform: translate(0, 0);
    `}
`;

export const DrawerContainer = styled.div<DrawerContainerProps>`
  position: fixed;
  height: ${({ height }) => height};
  overflow-y: visible;
  overflow-x: auto;
  width: ${({ width }) => width};
  z-index: ${zIndex('drawer')};

  transition: transform 0.3s ease, top 0.3s ease;

  ${({ height }) =>
    height === 'auto'
      ? css`
          ${drawerAutoHeight}
        `
      : css`
          ${drawerNormalHeight}
        `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
`;
