import { slugifyPayload } from '@gaming1/g1-utils';

export type SimpleValue = string | number | boolean;

export const slugifyArray = (arr: SimpleValue[]) =>
  slugifyPayload(arr.reduce((acc, curVal, i) => ({ ...acc, [i]: curVal }), {}));

export type SportbookType =
  | 'live'
  | 'topEvents'
  | 'oneSportLive'
  | 'prematchSport'
  | 'oneLeague'
  | 'oneRegion'
  | 'event'
  | 'search';

export const getSportbookKey = (
  type: SportbookType,
  ...params: SimpleValue[]
): string => slugifyArray([type, ...params]);
