import React, { FC } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-core';

import { concatLocation } from '../../helpers';

export const MultiLanguageRouter: FC = ({ children }) => {
  const location = useLocation();
  const { availableLanguages, currentLocale } = useI18n();

  const separatedLanguages = availableLanguages.join('|');
  const pathWithLanguages = `/:lng(${separatedLanguages})`;

  return (
    <Switch>
      <Route path={pathWithLanguages} render={() => children} />
      <Redirect to={concatLocation(location, currentLocale)} />
    </Switch>
  );
};
