import { normalize, schema } from 'normalizr';

import { GetPaginatedGameListResponse } from '../../gameList/store/types';

import { SearchGamesData } from './types';
/* Games */

const gameAttribute = new schema.Entity(
  'attributes',
  {},
  { idAttribute: 'Id' },
);
const gameAttributes = new schema.Array(gameAttribute);

const gameAttributeCategory = new schema.Entity(
  'attributeCategories',
  {
    AttributeList: gameAttributes,
  },
  { idAttribute: 'Id' },
);
const gameAttributeCategories = new schema.Array(gameAttributeCategory);

const game = new schema.Entity(
  'games',
  {
    AttributeList: gameAttributes,
  },
  { idAttribute: 'Id' },
);
const games = new schema.Array(game);

export const formatGamesDataWithoutAttributes = (
  apiData: GetPaginatedGameListResponse,
): SearchGamesData => {
  const {
    AttributeCategoriesList,
    List,
    Total,
    PageNumber,
    PageSize,
    ...meta
  } = apiData;
  const { result: gameResult, entities: gameEntities } = normalize(List, games);
  const { entities: catEntities } = normalize(
    AttributeCategoriesList,
    gameAttributeCategories,
  );

  return {
    entities: {
      games: {},
      attributes: {},
      attributeCategories: {},
      ...gameEntities,
      ...catEntities,
    },
    result: {
      games: gameResult,
    },
    meta,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};
