import { createContext } from 'react';

import { MessagesResponse } from './types';

/** Inbox context */
export const InboxContext = createContext<{
  inboxContent: MessagesResponse | null;
}>({
  inboxContent: null,
});
