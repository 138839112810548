import {
  EDepositBonusChoice,
  MakeDepositRequestDTO,
} from '@gaming1/g1-requests';

import { MakeDepositRequest } from './types';

/**
 * Should format the make deposit request |
 * It will set the appropriate value to 'BonusChoice' (None (no bonus or prize), Specific (bonus), Package (prize))
 */
export const formatMakeDeposit = (
  payload: MakeDepositRequest,
): MakeDepositRequestDTO => {
  const payloadRequest: MakeDepositRequestDTO = {
    Amount: payload.Amount,
    BonusChoice: EDepositBonusChoice.Default,
    ExtraFields: payload.ExtraFields,
    WalletId: payload.WalletId,
  };

  if (payload.BonusId) {
    payloadRequest.BonusId = payload.BonusId;
    payloadRequest.BonusChoice = EDepositBonusChoice.Specific;
  } else if (payload.SelectedPrizeId) {
    payloadRequest.SelectedPrizeId = payload.SelectedPrizeId;
    payloadRequest.BonusChoice = EDepositBonusChoice.Package;
  } else {
    payloadRequest.BonusChoice = EDepositBonusChoice.None;
  }

  return payloadRequest;
};
