import React, { VFC } from 'react';

import {
  eventSportIdSelector,
  getScoreBoardSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import {
  ScoreBoardPeriodType,
  ScoreBoardSportType,
} from '@gaming1/g1-requests-betting';

import { ScoreBoardStats } from '../ScoreBoardStats';

import { getScoreBoardTemplate } from './helpers';
import { ScoreBoardTemplateManagerWrapper } from './styles';
import { ScoreBoardTemplateManagerProps } from './types';

/** Displays the scoreboard with a specific template */
export const ScoreBoardTemplateManager: VFC<ScoreBoardTemplateManagerProps> = ({
  eventId,
  type,
}) => {
  /* Event sport id will be used for prematch events */
  const eventSportId = useBettingGetterSelector({
    getterSelector: eventSportIdSelector,
    args: [eventId],
  });

  /* Scoreboard object will be used for live events */
  const scoreBoard = useBettingGetterSelector({
    getterSelector: getScoreBoardSelector,
    args: [eventId],
  });

  /**
   * The data used by the scoreboard should be in the store before the first
   * render. This will ensure that the fallback template is not displayed
   * to early.
   */
  if (
    (type === 'prematch' && eventSportId === undefined) ||
    (type !== 'prematch' &&
      eventSportId === undefined &&
      scoreBoard === undefined)
  ) {
    return null;
  }

  const sportId = scoreBoard?.SportId || eventSportId || -1;
  const sportParser = scoreBoard?.SportParser || ScoreBoardSportType.Unknown;
  const periodType = scoreBoard?.PeriodType || ScoreBoardPeriodType.Unknown;

  const Template = getScoreBoardTemplate(
    sportId,
    sportParser,
    periodType,
    type,
  );

  const shouldDisplayStats = ['prematch', 'full'].includes(type);

  return Template ? (
    <ScoreBoardTemplateManagerWrapper data-testid="scoreboard-template-manager">
      {shouldDisplayStats && <ScoreBoardStats eventId={eventId} />}
      <Template {...{ eventId, sportId, type }} />
    </ScoreBoardTemplateManagerWrapper>
  ) : null;
};
