import React, { VFC } from 'react';
import { JustifyContentProps } from 'styled-system';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, RenderOnMedia } from '@gaming1/g1-ui';

import { MenuGroups } from '../types';

import {
  BulletSeparator,
  FooterLinkAnchor,
  FooterLinkButton,
  FooterLinkContainer,
  FooterLinkGroup,
  FooterLinkLink,
  FooterTitle,
} from './styles';

export type FooterLinkProps = JustifyContentProps & {
  /** Links groups */
  linkGroups: MenuGroups;
  /** Alternative styles of the links
   * Title (same as FooterTitle) : no underline, bold, uppercase, footer text color
   * Text : no underline, bold, no uppercase, alternative footer text color, bigger font size
   */
  linkStyle?: 'title' | 'text';
  /** Links title */
  title?: string;
  /** When the links are displayed in row */
  row?: boolean;
};

export const Links: VFC<FooterLinkProps> = ({
  linkGroups,
  linkStyle,
  row,
  title,
  ...rest
}) => {
  const { t } = useTranslation('core');

  return (
    <FooterLinkContainer row={row}>
      {title && <FooterTitle>{t(title)}</FooterTitle>}
      {linkGroups.map((menuList, group, array) => (
        <FooterLinkGroup
          // eslint-disable-next-line react/no-array-index-key
          key={group}
          row={row}
          data-testid="footer-link-group"
          {...rest}
        >
          {menuList.map(({ label, testId, icon, ...props }) => {
            if ('onClick' in props) {
              return (
                <FooterLinkButton
                  data-testid={testId}
                  key={`${testId}${label}`}
                  onClick={props.onClick}
                >
                  {label}
                </FooterLinkButton>
              );
            }

            return props.externalLink ? (
              <FooterLinkAnchor
                data-testid={testId}
                key={`${props.path}${label}`}
                href={props.path}
                rel="noopener"
                target="_blank"
                linkStyle={linkStyle}
              >
                {!!icon && typeof icon === 'string' ? (
                  <Icon
                    type={icon}
                    id={`footer-link-${icon}`}
                    testId={`footer-link-${icon}`}
                  />
                ) : (
                  icon
                )}
                <span>{t(label)}</span>
              </FooterLinkAnchor>
            ) : (
              <FooterLinkLink
                data-testid={testId}
                exact={props.exact}
                key={`${props.path}${label}`}
                to={props.path}
                linkStyle={linkStyle}
              >
                {!!icon && typeof icon === 'string' ? (
                  <Icon
                    type={icon}
                    id={`footer-link-${icon}`}
                    testId={`footer-link-${icon}`}
                  />
                ) : (
                  icon
                )}
                <span>{t(label)}</span>
              </FooterLinkLink>
            );
          })}
          {row && group !== array.length - 1 && (
            <RenderOnMedia min="lg">
              <BulletSeparator data-testid="footer-link-bullet" />
            </RenderOnMedia>
          )}
        </FooterLinkGroup>
      ))}
    </FooterLinkContainer>
  );
};
