import React, { useCallback, useContext, VFC } from 'react';
import { useDispatch } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { actions } from '@gaming1/g1-core';

import { logger } from '../../../logger';

import { IovationLoaderConfiguration } from './IovationLoaderConfiguration';
import { IovationSnareConfiguration } from './IovationSnareConfiguration';
import { BlackBoxesDispatchMessage } from './types';

/**
 * Get some informations from Iovation and store them in the redux store,
 * then send them to the websocket
 */
export const BlackBoxFetcher: VFC = () => {
  const {
    core: {
      iovation: { enabled: isIovationEnabled, version: iovationVersion },
    },
  } = useContext(ConfigContext);
  const dispatch = useDispatch();

  const handleBlackBoxDispatch = useCallback(
    (blackBoxes: BlackBoxesDispatchMessage) => {
      logger.info('[Tracking] Iovation blackBoxes set');
      dispatch(actions.setBlackBoxes(blackBoxes));
    },
    [dispatch],
  );

  if (isIovationEnabled) {
    return iovationVersion === 'loader' ? (
      <IovationLoaderConfiguration onDispatch={handleBlackBoxDispatch} />
    ) : (
      <IovationSnareConfiguration onDispatch={handleBlackBoxDispatch} />
    );
  }

  return null;
};
