import { combineEpics } from 'redux-observable';

import {
  BettingActions,
  bettingEpics,
  bettingReducer,
  BettingState,
} from '@gaming1/g1-betting';
import { CmsActions, cmsEpics, cmsReducer, CmsState } from '@gaming1/g1-cms';
import {
  ApplicationState,
  CoreActions,
  coreEpics,
  coreReducer,
  CoreState,
  filterCoreEpicsByEnv,
} from '@gaming1/g1-core';
import { getReduxMiddlewares } from '@gaming1/g1-core-web';
import {
  GamingActions,
  gamingEpics,
  gamingReducer,
  GamingState,
} from '@gaming1/g1-gaming';
import { getDeployEnv } from '@gaming1/g1-logger';
import {
  LoyaltyActions,
  loyaltyEpics,
  loyaltyReducer,
  LoyaltyState,
} from '@gaming1/g1-loyalty';
import {
  PaymentActions,
  paymentEpics,
  paymentReducer,
  PaymentState,
} from '@gaming1/g1-payment';
import {
  PlatformJackpotActions,
  platformJackpotEpics,
  platformJackpotReducer,
  PlatformJackpotState,
} from '@gaming1/g1-platform-jackpot/shared';
import { createStore, LazyStore } from '@gaming1/g1-store';
import {
  UserActions,
  userEpics,
  userReducer,
  UserState,
} from '@gaming1/g1-user';

import { config$ } from './configs';

type AppStateRecord = ApplicationState &
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any>;

export interface AppState extends AppStateRecord {
  betting: BettingState;
  core: CoreState;
  cms: CmsState;
  gaming: GamingState;
  loyalty: LoyaltyState;
  payment: PaymentState;
  platformJackpot: PlatformJackpotState;
  user: UserState;
}

type AppActions =
  | BettingActions
  | CoreActions
  | GamingActions
  | CmsActions
  | LoyaltyActions
  | PaymentActions
  | PlatformJackpotActions
  | UserActions;

/*
  This unfortunate usage of any is caused by typesafe actions strictness
  The problem is that each reducer/epic has its own type of actions (e.g.
  coreReducer knowns only CoreActions). Here we are saying that they should
  also accept other types of actions (e.g. coreReducer should also accepts
  UserActions).
  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AllAppActions = AppActions | any;

const reducers = {
  betting: bettingReducer,
  cms: cmsReducer,
  core: coreReducer,
  gaming: gamingReducer,
  loyalty: loyaltyReducer,
  payment: paymentReducer,
  platformJackpot: platformJackpotReducer,
  user: userReducer,
};

const filteredCoreEpics = filterCoreEpicsByEnv(coreEpics);

const epics = combineEpics<AllAppActions, AppActions, AppState>(
  ...Object.values(bettingEpics),
  ...Object.values(cmsEpics),
  ...Object.values(filteredCoreEpics),
  ...Object.values(gamingEpics),
  ...Object.values(loyaltyEpics),
  ...Object.values(paymentEpics),
  ...Object.values(platformJackpotEpics),
  ...Object.values(userEpics),
);

type WindowWithReduxStore = Window & {
  store?: LazyStore;
};

const windowWithReduxStore = window as WindowWithReduxStore;

/**
 * Create the redux store and attach it to the global window object
 * This allows the redux state to stay put between HMR
 */
const configureStore = () => {
  if (windowWithReduxStore.store == null) {
    const reduxStore = createStore<AppState, AllAppActions>({
      reducers,
      epics,
      config$,
      middlewares: getReduxMiddlewares(['core/', 'gaming/']),
    });
    if (getDeployEnv() === 'local') {
      windowWithReduxStore.store = reduxStore;
    }
    return reduxStore;
  }
  /* if (process.env.NODE_ENV === "development") {
    window.store.replaceReducer(reducers);
  } */
  return windowWithReduxStore.store;
};

export const store = configureStore();
