import { addLocizeSavedHandler, locizePlugin } from 'locize';
import merge from 'lodash/merge';
import {
  ContextType,
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { AppConfig } from '@gaming1/g1-config';
import { AppProvider, useInitialAppLanguage } from '@gaming1/g1-core-web';
import { geti18n, I18n } from '@gaming1/g1-i18n';
import { defaultTheme, Theme } from '@gaming1/g1-style';
import {
  getSearchParam,
  persistIn,
  removeFrom,
  Serializable,
} from '@gaming1/g1-utils';

import {
  config$,
  configs,
  LOCAL_STORAGE_CONFIG_KEY,
  LOCAL_STORAGE_NETWORK_CONFIG_KEY,
} from '../../configs';
import i18nConfigJson from '../../i18n-config.json';
import { store } from '../../store';
import {
  LOCAL_STORAGE_CUSTOM_THEME_KEY,
  LOCAL_STORAGE_THEME_KEY,
  themes,
} from '../../themes';
import { CustomThemesContext } from '../CustomThemesProvider/CustomThemesContext';

import { initialSwitchState, SwitchContext } from './contexts';
import { nestStringKeys } from './helpers';
import { switchStateReducer } from './switchStateReducer';

type SwitchProviderProps = {
  /** i18next instance (useful for DI) */
  i18n?: I18n;
};

/**
 * Special AppProvider wrapper (only available for site-default) to allow
 * switching theme and config on the fly.
 */
export const SwitchProvider: FC<SwitchProviderProps> = memo(
  ({ children, i18n: i18nInstance }) => {
    const { themes: customThemes } = useContext(CustomThemesContext);
    const [switchState, dispatch] = useReducer(
      switchStateReducer,
      initialSwitchState,
    );

    const config = useMemo<AppConfig>(
      () => configs[switchState.config],
      [switchState.config],
    );

    const theme = useMemo<Theme>(
      () => themes[switchState.theme],
      [switchState.theme],
    );

    // TODO: Fix this type (PartialTheme? CustomTheme?)
    const customTheme = useMemo(
      () =>
        switchState.customTheme
          ? customThemes.find((x) => x.name === switchState.customTheme) || null
          : null,
      [customThemes, switchState.customTheme],
    );

    const currentTheme = customTheme
      ? merge({}, defaultTheme, customTheme)
      : theme;

    // Checks if the query param ?translate=false is set.
    const shouldHideTranslations =
      !!getSearchParam('translate') && getSearchParam('translate') === 'false';
    // Cannot use useLocation here since the router is not rendered yet
    const initialURLLanguage = useInitialAppLanguage(window.location.pathname);
    const initialLanguage = shouldHideTranslations
      ? 'cimode'
      : initialURLLanguage;

    const i18n = useMemo<I18n>(
      () =>
        geti18n({
          availableLanguages: config.i18n.availableLanguages,
          awsPrefix: i18nConfigJson.awsPrefix,
          currency: i18nConfigJson.values.currency,
          defaultLanguage: config.i18n.defaultLanguage,
          initialLanguage,
          locizePlugin,
        }),
      [
        config.i18n.availableLanguages,
        config.i18n.defaultLanguage,
        initialLanguage,
      ],
    );

    useEffect(() => {
      // Listen to translation changes
      addLocizeSavedHandler(({ updated }) => {
        const bundles = updated.reduce<Record<string, Record<string, string>>>(
          (acc, { lng, ns, key, data: { value } }) =>
            merge({}, acc, {
              [`${lng.substring(0, 2)}::${ns}`]: { [key]: value },
            }),
          {},
        );
        Object.entries(bundles).forEach(([lngNS, bundle]) => {
          const [lng, ns] = lngNS.split('::');
          i18n.addResourceBundle(lng, ns, nestStringKeys(bundle), true, true);
        });

        // As the i18n instance is configured to bind this event which should be
        // forwarded automatically when using the inContext editor "normally", it
        // is triggered manually here so the UI rerenders based on the new bundle.
        i18n.emit('editorSaved');
      });
    }, [i18n]);

    // Update the local storage and the redux observable config dependency on
    // config change.  Also removes the saved network config to match the new one
    useEffect(() => {
      persistIn(localStorage, LOCAL_STORAGE_CONFIG_KEY, switchState.config);
      removeFrom(localStorage, LOCAL_STORAGE_NETWORK_CONFIG_KEY);
      config$.next(config);
    }, [switchState.config, config]);

    // Update the local storage on theme change
    useEffect(() => {
      persistIn(localStorage, LOCAL_STORAGE_THEME_KEY, switchState.theme);
    }, [switchState.theme]);

    // Update the local storage on custom theme change
    useEffect(() => {
      if (switchState.customTheme) {
        persistIn(
          localStorage,
          LOCAL_STORAGE_CUSTOM_THEME_KEY,
          switchState.customTheme,
        );
      } else {
        removeFrom(localStorage, LOCAL_STORAGE_CUSTOM_THEME_KEY);
      }
    }, [switchState.customTheme]);

    // Update the local storage on network config change
    // Remove the local storage key LOCAL_STORAGE_NETWORK_CONFIG_KEY
    // when the payload is set to null : usually when the "reset" button is clicked.
    useEffect(() => {
      if (switchState.networkConfig) {
        // Cast our Partial config to a Serializable to avoid a TS error
        persistIn(
          localStorage,
          LOCAL_STORAGE_NETWORK_CONFIG_KEY,
          switchState.networkConfig as Serializable,
        );
      } else {
        removeFrom(localStorage, LOCAL_STORAGE_NETWORK_CONFIG_KEY);
      }
    }, [switchState.networkConfig]);

    const switchContextValue: ContextType<typeof SwitchContext> = useMemo(
      () => [switchState, dispatch],
      [switchState],
    );

    return (
      <AppProvider
        config={config}
        i18n={i18nInstance || i18n}
        i18nValues={{
          availableLanguagesFullCode: i18nConfigJson.languages,
          values: i18nConfigJson.values,
        }}
        store={store}
        theme={currentTheme}
      >
        <SwitchContext.Provider value={switchContextValue}>
          {children}
        </SwitchContext.Provider>
      </AppProvider>
    );
  },
);
