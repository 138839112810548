import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getAvailableManualBets,
  hasAvailableManualBet,
  ManualBetType,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../common/store/errorMessages';
import { BettingEpic } from '../store/types';

import * as actions from './actions';
import {
  getAvailableManualBetsResponseCodec,
  hasAvailableManualBetResponseCodec,
} from './codecs';

// DAILY OFFERS

export const hasAvailableDailyOffersEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter, config$ },
) =>
  action$.pipe(
    filter(isActionOf(actions.hasAvailableDailyOffers.request)),
    withLatestFrom(config$),
    switchMap(([, config]) => {
      if (!config.betting.dailyOffers.enabled) {
        return of(
          actions.hasAvailableDailyOffers.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      return wsAdapter
        .request(
          hasAvailableManualBet({
            Type: ManualBetType.DailyOffer,
          }),
        )
        .pipe(
          mapGuard(hasAvailableManualBetResponseCodec),
          map(actions.hasAvailableDailyOffers.success),
          catchError((err) =>
            of(
              actions.hasAvailableDailyOffers.failure(
                createFailurePayload(err, commonBettingErrorMessages),
              ),
            ),
          ),
        );
    }),
  );

export const getAvailableDailyOffersEpic: BettingEpic = (
  actions$,
  _,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAvailableDailyOffers.request)),
    withLatestFrom(config$),
    switchMap(([, config]) => {
      const areDailyOfferEnabled = config.betting.dailyOffers.enabled;

      if (!areDailyOfferEnabled) {
        return of(
          actions.getAvailableDailyOffers.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      const defaultMinStake = config.betting.fastBettingSlip.minStake;

      return wsAdapter
        .request(getAvailableManualBets({ Type: ManualBetType.DailyOffer }))
        .pipe(
          mapGuard(getAvailableManualBetsResponseCodec),
          map((data) =>
            actions.getAvailableDailyOffers.success({
              ...data,
              defaultMinStake,
            }),
          ),
          catchError((err) =>
            of(
              actions.getAvailableDailyOffers.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
              }),
            ),
          ),
        );
    }),
  );

export const getAvailableDailyOffersForEventEpic: BettingEpic = (
  actions$,
  _,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAvailableDailyOffersForEvent.request)),
    withLatestFrom(config$),
    switchMap(([{ payload }, config]) => {
      const areDailyOfferEnabled = config.betting.dailyOffers.enabled;

      if (!areDailyOfferEnabled) {
        return of(
          actions.getAvailableDailyOffersForEvent.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      const defaultMinStake = config.betting.fastBettingSlip.minStake;

      return wsAdapter
        .request(
          getAvailableManualBets({
            Type: ManualBetType.DailyOffer,
            RelatedEventId: payload.RelatedEventId,
          }),
        )
        .pipe(
          mapGuard(getAvailableManualBetsResponseCodec),
          map((data) =>
            actions.getAvailableDailyOffersForEvent.success({
              ...data,
              defaultMinStake,
            }),
          ),
          catchError((err) =>
            of(
              actions.getAvailableDailyOffersForEvent.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
              }),
            ),
          ),
        );
    }),
  );

// SUPER ODDS

export const hasAvailableSuperOddsEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter, config$ },
) =>
  action$.pipe(
    filter(isActionOf(actions.hasAvailableSuperOdds.request)),
    withLatestFrom(config$),
    switchMap(([, config]) => {
      if (!config.betting.superOdds.enabled) {
        return of(
          actions.hasAvailableSuperOdds.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      return wsAdapter
        .request(
          hasAvailableManualBet({
            Type: ManualBetType.SuperOdds,
          }),
        )
        .pipe(
          mapGuard(hasAvailableManualBetResponseCodec),
          map(actions.hasAvailableSuperOdds.success),
          catchError((err) =>
            of(
              actions.hasAvailableSuperOdds.failure(
                createFailurePayload(err, commonBettingErrorMessages),
              ),
            ),
          ),
        );
    }),
  );

export const getAvailableSuperOddsEpic: BettingEpic = (
  actions$,
  _,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAvailableSuperOdds.request)),
    withLatestFrom(config$),
    switchMap(([, config]) => {
      const areSuperOddsEnabled = config.betting.superOdds.enabled;
      if (!areSuperOddsEnabled) {
        return of(
          actions.getAvailableSuperOdds.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      const defaultMinStake = config.betting.fastBettingSlip.minStake;

      return wsAdapter
        .request(getAvailableManualBets({ Type: ManualBetType.SuperOdds }))
        .pipe(
          mapGuard(getAvailableManualBetsResponseCodec),
          map((data) =>
            actions.getAvailableSuperOdds.success({
              ...data,
              defaultMinStake,
            }),
          ),
          catchError((err) =>
            of(
              actions.getAvailableSuperOdds.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
              }),
            ),
          ),
        );
    }),
  );

export const getAvailableSuperOddsForEventEpic: BettingEpic = (
  actions$,
  _,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAvailableSuperOddsForEvent.request)),
    withLatestFrom(config$),
    switchMap(([{ payload }, config]) => {
      const areSuperOddsEnabled = config.betting.superOdds.enabled;

      if (!areSuperOddsEnabled) {
        return of(
          actions.getAvailableSuperOddsForEvent.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      const defaultMinStake = config.betting.fastBettingSlip.minStake;

      return wsAdapter
        .request(
          getAvailableManualBets({
            Type: ManualBetType.SuperOdds,
            RelatedEventId: payload.RelatedEventId,
          }),
        )
        .pipe(
          mapGuard(getAvailableManualBetsResponseCodec),
          map((data) =>
            actions.getAvailableSuperOddsForEvent.success({
              ...data,
              defaultMinStake,
            }),
          ),
          catchError((err) =>
            of(
              actions.getAvailableSuperOddsForEvent.failure({
                ...createFailurePayload(err, commonBettingErrorMessages),
              }),
            ),
          ),
        );
    }),
  );
