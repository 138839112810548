import * as t from 'io-ts';
import mergeWith from 'lodash/mergeWith';
import { BehaviorSubject } from 'rxjs';

import {
  AppConfig,
  getConfig,
  License,
  OverwriteConfig,
  PartnerConfig,
  Rooms,
} from '@gaming1/g1-config';
import { interopMessages } from '@gaming1/g1-core';
import { sendIframeMessage } from '@gaming1/g1-core-web';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { getSearchParam, readFrom } from '@gaming1/g1-utils';

const overwriteConfigCodec = t.partial({
  apiUrl: t.string,
  gameLauncherUrl: t.string,
  mediaUrl: t.string,
  prismicUrl: t.string,
  siteUrl: t.string,
  wsUrl: t.string,
  zendeskUrl: t.string,
});

export enum ConfigName {
  // default
  default = 'default',
  defaultBis = 'default Bis',
  defaultTer = 'default Ter',
  // betting
  betting01CircusBe = 'betting01CircusBe',
  betting02CircusBe = 'betting02CircusBe',
  betting03CircusBe = 'betting03CircusBe',
  betting03CircusBeAlternative = 'betting03CircusBeAlternative',
  betting03CircusBeGeolocation = 'betting03CircusBeGeolocation',
  betting03CircusBeUsa = 'betting03CircusBeUsa',
  // front
  front02CircusBe = 'front02CircusBe',
  front02CircusBeGeolocation = 'front02CircusBeGeolocation',
  front02CircusBeGeolocationWithoutApps = 'front02CircusBeGeolocationWithoutApps',
  front02CircusBeWithTracking = 'front02CircusBeWithTracking',
  front02LuckyGames = 'front02LuckyGames',
  // loyalty
  loyalty01BetlyTn = 'loyalty01BetlyTn',
  loyalty01CircusBe = 'loyalty01CircusBe',
  // player
  player01BetlyAr = 'player01BetlyAr',
  player01BetlyTn = 'player01BetlyTn',
  player01LuckyGames = 'player01LuckyGames',
  player02BetlyTn = 'player02BetlyTn',
  // others
  localGameLauncher = 'localGameLauncher',
}

type Configs = {
  [C in ConfigName]: AppConfig;
};

const getMinutesFromDaysNumber = (days: number) => days * 24 * 60;

const radioExclusionDurations = [
  1,
  60 * 1,
  getMinutesFromDaysNumber(1),
  getMinutesFromDaysNumber(7),
  getMinutesFromDaysNumber(30),
];
const selectExclusionDurations = [
  1,
  ...Array.from(Array(30)).map((_, index) => getMinutesFromDaysNumber(index)),
];
const calendarExclusionDurations = Array.from(Array(10)).map((_, index) =>
  getMinutesFromDaysNumber(index + 30),
);

export const LOCAL_STORAGE_CONFIG_KEY = 'defaultConfig';
export const LOCAL_STORAGE_NETWORK_CONFIG_KEY = 'defaultNetworkConfig';

/**
 * Used in dev environments only, allows one to switch the network config properties
 * from text inputs.
 *
 * @returns An overwrite of the network config values
 */
export const getOverwrittenNetworkConfigValues = (
  networkConfig: Partial<AppConfig['network']> = {},
): OverwriteConfig => {
  const localStorageNetworkConfig = readFrom(
    localStorage,
    LOCAL_STORAGE_NETWORK_CONFIG_KEY,
  );
  const paramName = getSearchParam('config');

  return !paramName && overwriteConfigCodec.is(localStorageNetworkConfig)
    ? mergeWith({}, networkConfig, localStorageNetworkConfig)
    : networkConfig;
};

const configOverwrite: Parameters<typeof getConfig>[1] = {
  acceptance: {
    core: {
      googleAPIKey: 'AIzaSyD5JiTG0g30qzw7_rOKLflxcL2Rzm7OUDM',
    },
    network: getOverwrittenNetworkConfigValues(),
    seo: { gtmId: 'GTM-TVX8PVS' },
  },
  development: {
    core: {
      googleAPIKey: 'AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks',
    },
    network: getOverwrittenNetworkConfigValues(),
    seo: {
      gtmId: 'GTM-WQ3GQB3',
      // Only activate FS when within the app
      fullStoryOrgKey: getWrapperEnv() === 'rn' ? '17Z2PH' : null,
    },
  },
  local: {
    core: {
      iovation: {
        enabled: false,
      },
      googleAPIKey: 'AIzaSyAYMrXwuyaJte-nARkCegNT8nfR5RRhSks',
    },
    seo: {
      // Disable DD when developing
      dataDog: { applicationId: null, clientToken: null },
    },
    network: getOverwrittenNetworkConfigValues(),
  },
  production: {
    core: {
      geoComply: {
        environment: 'production',
        umsServerUrl: 'https://ums.geocomply.com/installer/url/',
      },
    },
    network: getOverwrittenNetworkConfigValues(),
    seo: {
      gtmId: 'GTM-KT7CGLC',
    },
  },
  testing: {
    core: {
      googleAPIKey: 'AIzaSyCpmjqsM8Msln_EqLRZsNIgQIy4pPpcuLw',
    },
    network: getOverwrittenNetworkConfigValues(),
    seo: { gtmId: 'GTM-TVX8PVS' },
  },
};

const siteDefaultConfig: PartnerConfig = {
  betting: {},
  core: {
    cguFileNamePrefix: 'cgu-luckygames_',
    cguPrivacyFileNamePrefix: 'cgu-luckygamesbe-privacy_',
    geoComply: {
      activated: false,
      timeoutSeconds: 20 * 60,
    },
    licenses: [License.A, License.B, License.F],
    mobileAppIdentifiers: {
      android: 'be.gaming1.blanco',
      ios: '1560187768',
    },
    privacySettings: {
      type: 'message',
    },
    zendeskWidgetKey: 'e8d07522-238f-4b30-b24b-8b8b93760306',
  },
  i18n: {
    dateFormat: 'dd/MM/yyyy',
    shouldBeRestrictedByShellParams: true,
  },
  gaming: {
    shouldDisplayFiltersOnGameListByCategory: true,
    shouldDisplayTitleOnGameListByCategory: true,
  },
  loyalty: {
    shop: true,
  },
  network: getOverwrittenNetworkConfigValues({
    apiUrl: 'https://apifront-circus-be-front02.gaming1.com',
    gameLauncherUrl:
      'https://default-front02.gaming1.com/gamelauncher/index.html',
    keepAliveInterval: 5 * 60 * 1000,
    mediaUrl: 'https://media-circus-be-front02.gaming1.com',
    prismicUrl: 'https://gaming1-default.prismic.io/api/v2',
    siteUrl: 'https://default-front02.gaming1.com',
    wsUrl: 'wss://circus-be-front02.gaming1.com:10002',
    wsCompression: 'LZS2',
    zendeskUrl: 'https://luckygamesbe1597760180.zendesk.com',
  }),
  room: {
    roomName: Rooms.CIRCUS,
  },
  seo: {
    dataDog: {
      applicationId: '9f55ed26-8e83-4d39-8a49-a4a6cbf5052f',
      clientToken: 'pub53c7f763cb840a5ff92d91273489c975',
      partnerTag: 'blanco.be',
      site: 'datadoghq.eu',
    },
  },
  user: {
    authenticationMethods: ['online', 'inroom', 'email'],
    autocompleteNationalNumber: true,
    bonus: true,
    clockFormat: "HH'h'mm",
    closeAccount: true,
    gamePauseDateMaxDurationInDays: 0,
    gamePauseDateMinDurationInDays: 1,
    gamePauseDurations: radioExclusionDurations,
    gamePauseInput: 'radio',
    legalDepositLimit: true,
    legalDepositLimitInRegistration: true,
    limits: {
      connectionTime: true,
      lossBetting: true,
      lossGaming: true,
      spentTimeBetting: true,
      spentTimeGaming: true,
      wageBetting: true,
      wageGaming: true,
    },
    inbox: false,
    noticeOnLogin: true,
    selfBlacklisting: true,
    selfExclusionDateMaxDurationInDays: 0,
    selfExclusionDateMinDurationInDays: 1,
    selfExclusionDurationsInMinutes: [
      getMinutesFromDaysNumber(60),
      getMinutesFromDaysNumber(120),
      getMinutesFromDaysNumber(180),
      getMinutesFromDaysNumber(365),
    ],
    selfExclusionFixedDurationInMinutes: 259200,
    selfExclusionInput: 'fixed',
    sponsorship: true,
    histories: {
      shop: true,
      freeSpins: true,
      sponsorship: true,
    },
  },
};

export const mergeWithSiteDefaultConfig = (partialConfig: PartnerConfig) =>
  mergeWith({}, siteDefaultConfig, partialConfig, (_, srcValue) =>
    Array.isArray(srcValue) ? srcValue : undefined,
  );

export const configs: Configs = {
  // default
  [ConfigName.default]: getConfig(siteDefaultConfig, configOverwrite),
  [ConfigName.defaultBis]: getConfig(
    mergeWithSiteDefaultConfig({
      core: {
        licenses: [License.B, License.F],
        mobileAppIdentifiers: {
          android: null,
          ios: null,
        },
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        limits: { wageGaming: true },
        registrationMultiStep: true,
      },
    }),
    configOverwrite,
  ),
  [ConfigName.defaultTer]: getConfig(
    mergeWithSiteDefaultConfig({
      core: {
        licenses: null,
        mobileAppApkPath: 'https://media.circus.be/app-myCircusBe-release.apk',
        mobileAppIdentifiers: {
          android: null,
          ios: null,
        },
      },
      user: {
        fullScreenForms: false,
        gamePauseDurations: calendarExclusionDurations,
        gamePauseInput: 'date',
        registrationMultiStep: true,
      },
    }),
    configOverwrite,
  ),
  // betting
  [ConfigName.betting01CircusBe]: getConfig(
    {
      betting: {
        enabled: true,
        showEmailNotifications: true,
      },
      network: {
        apiUrl: 'https://circus-be-betting01.gaming1.com',
        mediaUrl: 'https://media-circus-be-betting01.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-betting01.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.betting02CircusBe]: getConfig(
    {
      betting: {
        enabled: true,
        showEmailNotifications: true,
        promotions: {
          enabled: true,
        },
        uniformizationGiftsDesign: true,
      },
      network: {
        apiUrl: 'https://circus-be-betting02.gaming1.com',
        mediaUrl: 'https://media-circus-be-betting02.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-betting02.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.betting03CircusBe]: getConfig(
    {
      betting: {
        enabled: true,
        showEmailNotifications: true,
        boostus: {
          enabled: true,
        },
        dailyOffers: {
          enabled: true,
        },
        lmt: {
          enabled: true,
        },
        promotions: {
          enabled: true,
        },
        stats: {
          enabled: true,
        },
        superOdds: {
          enabled: true,
        },
        uniformizationGiftsDesign: true,
      },
      network: {
        apiUrl: 'https://circus-be-betting03.gaming1.com',
        mediaUrl: 'https://media-circus-be-betting03.gaming1.com',
        prismicUrl: 'https://gaming1-default.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-betting03.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.betting03CircusBeAlternative]: getConfig(
    {
      betting: {
        enabled: true,
        boostus: {
          enabled: true,
        },
        dailyOffers: {
          enabled: true,
        },
        promotions: {
          enabled: true,
        },
        superOdds: {
          enabled: true,
        },
      },
      network: {
        apiUrl: 'https://circus-be-betting03.gaming1.com',
        mediaUrl: 'https://media-circus-be-betting03.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-betting03.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.betting03CircusBeGeolocation]: getConfig(
    {
      betting: {
        enabled: true,
        showEmailNotifications: true,
      },
      core: {
        geoComply: {
          activated: true,
          installerKey: 'qCOcVaXSSy',
        },
      },
      network: {
        apiUrl: 'https://circus-be-betting03.gaming1.com',
        mediaUrl: 'https://media-circus-be-betting03.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-betting03.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.betting03CircusBeUsa]: getConfig(
    {
      betting: {
        enabled: true,
        showEmailNotifications: true,
      },
      i18n: {
        dateFormat: 'MM/dd/yyyy',
        dateShortFormat: 'MM/dd',
        dateTimeFormat: 'MM/dd/yyyy HH:mm:ss',
        dateTimePartialFormat: 'MM/dd/yyyy HH:mm',
        dateTimeShortPartialFormat: 'MM/dd HH:mm',
        defaultLanguage: 'en',
      },
      network: {
        apiUrl: 'https://circus-be-betting03.gaming1.com',
        mediaUrl: 'https://media-circus-be-betting03.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-betting03.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
    },
    configOverwrite,
  ),
  // front
  [ConfigName.front02CircusBe]: getConfig(
    {
      core: {
        privacySettings: {
          didomiApiKey: 'b2982887-c780-4c05-b0e7-94694e8e484d',
          purposeIDs: {
            advertizing: 'marketing-WVpdjVjz',
            essential: 'necessary-q3Gyx4DP',
            performanceMeasure: 'analytics-tZ2HVYZA',
            uxImprovement: 'preference-xKVGYFFb',
          },
          type: 'Didomi',
        },
      },
      network: {
        apiUrl: 'https://apifront-circus-be-front02.gaming1.com',
        mediaUrl: 'https://media-circus-be-front02.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-front02.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.front02CircusBeGeolocation]: getConfig(
    mergeWithSiteDefaultConfig({
      core: {
        geoComply: {
          activated: true,
          installerKey: 'qCOcVaXSSy',
        },
        privacySettings: {
          didomiApiKey: 'b2982887-c780-4c05-b0e7-94694e8e484d',
          purposeIDs: {
            advertizing: 'marketing-WVpdjVjz',
            essential: 'necessary-q3Gyx4DP',
            performanceMeasure: 'analytics-tZ2HVYZA',
            uxImprovement: 'preference-xKVGYFFb',
          },
          type: 'Didomi',
        },
      },
      network: {
        apiUrl: 'https://apifront-circus-be-front02.gaming1.com',
        mediaUrl: 'https://media-circus-be-front02.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-front02.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    }),
    configOverwrite,
  ),
  [ConfigName.front02CircusBeGeolocationWithoutApps]: getConfig(
    mergeWithSiteDefaultConfig({
      core: {
        geoComply: {
          activated: true,
          installerKey: 'qCOcVaXSSy',
        },
        mobileAppIdentifiers: {
          android: null,
          ios: null,
        },
        privacySettings: {
          didomiApiKey: 'b2982887-c780-4c05-b0e7-94694e8e484d',
          purposeIDs: {
            advertizing: 'marketing-WVpdjVjz',
            essential: 'necessary-q3Gyx4DP',
            performanceMeasure: 'analytics-tZ2HVYZA',
            uxImprovement: 'preference-xKVGYFFb',
          },
          type: 'Didomi',
        },
      },
      network: {
        apiUrl: 'https://apifront-circus-be-front02.gaming1.com',
        mediaUrl: 'https://media-circus-be-front02.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-front02.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    }),
    configOverwrite,
  ),
  [ConfigName.front02CircusBeWithTracking]: getConfig(
    mergeWithSiteDefaultConfig({
      core: {
        iovation: {
          version: 'loader',
        },
      },
      seo: {
        fullStoryOrgKey: '17Z2PH',
        hotjar: { id: 2569070, version: 6 },
      },
    }),
    configOverwrite,
  ),
  [ConfigName.front02LuckyGames]: getConfig(
    {
      betting: {
        enabled: false,
      },
      core: {
        cguFileNamePrefix: 'cgu-luckygames_',
        cguPrivacyFileNamePrefix: 'cgu-luckygamesbe-privacy_',
        zendeskWidgetKey: 'e8d07522-238f-4b30-b24b-8b8b93760306',
      },
      gaming: { shouldDisplayTitleOnGameListByCategory: true },
      i18n: {
        availableLanguages: ['fr', 'nl', 'en'],
      },
      network: {
        apiUrl: 'https://apifront-luckygames-be-front02.gaming1.com',
        gameLauncherUrl:
          'https://luckygames-be-front02.gaming1.com/gamelauncher/index.html',
        mediaUrl: 'https://media-luckygames-be-front02.gaming1.com',
        prismicUrl: 'https://luckygamesbe.prismic.io/api/v2',
        siteUrl: 'https://luckygames-be-front02.gaming1.com',
        wsUrl: 'wss://luckygames-be-front02.gaming1.com:10002',
        zendeskUrl: 'https://luckygamesbe1597760180.zendesk.com',
      },
      room: {
        roomName: Rooms.LUCKYGAMES,
      },
      user: {
        gamePauseDurations: [60 * 24, 60 * 24 * 7, 60 * 24 * 30],
        gamePauseInput: 'radio',
        legalDepositLimit: true,
        minimalLegalAge: 21,
        noticeOnLogin: true,
      },
    },
    configOverwrite,
  ),
  // loyalty
  [ConfigName.loyalty01BetlyTn]: getConfig(
    {
      network: {
        apiUrl: 'https://apifront-betlytn-us-loyalty01.gaming1.com',
        loyaltyGameServerUrl: 'https://loyaltygames-loyalty01.gaming1.com',
        mediaUrl: 'https://media-betlytn-us-loyalty01.gaming1.com',
        prismicUrl: 'https://betlytn.cdn.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://betlytn-us-loyalty01.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.BETLYTN,
      },
      loyalty: {
        loggedOutCmsHomePage: true,
        profileRank: false,
      },
      user: {
        closeAccount: true,
        defaultCountry: 'us',
        gamePauseDurations: [60 * 72, 60 * 24 * 7, 60 * 24 * 30],
        gamePauseInput: 'radio',
        pinCode: true,
        selfExclusion: false,
      },
    },
    configOverwrite,
  ),
  [ConfigName.loyalty01CircusBe]: getConfig(
    {
      network: {
        apiUrl: 'https://circus-be-loyalty01.gaming1.com',
        loyaltyGameServerUrl: 'https://loyaltygames-loyalty01.gaming1.com',
        mediaUrl: 'https://media-circus-be-loyalty01.gaming1.com',
        prismicUrl: 'https://circusbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://circus-be-loyalty01.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.CIRCUS,
      },
      loyalty: {
        fullShop: true,
        profileRank: true,
      },
      user: {
        inbox: true,
        inboxProjectKey: 'a258912c5893579e6d1b24fbf7db2d5b',
        histories: {
          deposit: true,
          freeSpins: true,
          scratchcard: true,
          shop: true,
          sponsorship: true,
          withdrawal: true,
        },
      },
    },
    configOverwrite,
  ),
  // player
  [ConfigName.player01BetlyTn]: getConfig(
    {
      i18n: {
        dateFormat: 'MM/dd/yyyy',
        dateShortFormat: 'MM/dd',
        dateTimeFormat: 'MM/dd/yyyy HH:mm:ss',
        dateTimePartialFormat: 'MM/dd/yyyy HH:mm',
        dateTimeShortPartialFormat: 'MM/dd HH:mm',
        defaultLanguage: 'en',
      },
      network: {
        apiUrl: 'https://apifront-betlytn-us-player01.gaming1.com',
        mediaUrl: 'https://media-betlytn-us-player01.gaming1.com',
        prismicUrl: 'https://betlytn.cdn.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://betlytn-us-player01.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.BETLYTN,
      },
      user: {
        closeAccount: true,
        defaultCountry: 'us',
        displayPersonalDataPhoneNumber: ['mobile'],
        displayPersonalDataBirthCity: false,
        displayPersonalDataBirthCountry: false,
        displayPersonalDataGender: false,
        gamePauseDurations: [60 * 72, 60 * 24 * 7, 60 * 24 * 14, 60 * 24 * 30],
        gamePauseInput: 'radio',
        legalModal: false,
        limits: {
          connectionTime: true,
          coolOffInMinute: 1440,
          deposit: true,
          lossBetting: true,
          spentTimeBetting: true,
          wageBetting: true,
        },
        pinCode: true,
        privacyConditionsModal: false,
        registrationSuccessCheckAutenticationStatusPolling: true,
        selfBlacklisting: true,
        selfExclusion: false,
        showLastConnection: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.player01BetlyAr]: getConfig(
    {
      core: {
        geoComply: {
          activated: true,
          installerKey: 'qCOcVaXSSy',
        },
      },
      i18n: {
        dateFormat: 'MM/dd/yyyy',
        dateShortFormat: 'MM/dd',
        dateTimeFormat: 'MM/dd/yyyy HH:mm:ss',
        dateTimePartialFormat: 'MM/dd/yyyy HH:mm',
        dateTimeShortPartialFormat: 'MM/dd HH:mm',
        defaultLanguage: 'en',
      },
      network: {
        apiUrl: 'https://apifront-betlyar-us-player01.gaming1.com',
        mediaUrl: 'https://media-betlyar-us-player01.gaming1.com',
        prismicUrl: 'https://betlyar.cdn.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://betlyar-us-player01.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.BETLYAR,
      },
      user: {
        closeAccount: true,
        defaultCountry: 'us',
        displayPersonalDataPhoneNumber: ['mobile'],
        displayPersonalDataBirthCity: false,
        displayPersonalDataBirthCountry: false,
        displayPersonalDataGender: false,
        gamePauseDurations: [60 * 72, 60 * 24 * 7, 60 * 24 * 14, 60 * 24 * 30],
        gamePauseInput: 'radio',
        legalModal: false,
        limits: {
          connectionTime: true,
          coolOffInMinute: 1440,
          deposit: true,
          lossBetting: true,
          spentTimeBetting: true,
          wageBetting: true,
        },
        pinCode: true,
        privacyConditionsModal: false,
        registrationSuccessCheckAutenticationStatusPolling: true,
        selfExclusion: false,
        showLastConnection: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.player01LuckyGames]: getConfig(
    {
      network: {
        apiUrl: 'https://apifront-luckygames-be-player01.gaming1.com',
        mediaUrl: 'https://media-luckygames-be-player01.gaming1.com',
        prismicUrl: 'https://luckygamesbe.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://luckygames-be-player01.gaming1.com:10002',
        zendeskUrl: 'https://luckygamesbe1597760180.zendesk.com',
      },
      room: {
        roomName: Rooms.LUCKYGAMES,
      },
      user: {
        gamePauseDurations: selectExclusionDurations,
        gamePauseInput: 'select',
        legalDepositLimit: true,
        noticeOnLogin: true,
        sponsorship: true,
      },
    },
    configOverwrite,
  ),
  [ConfigName.player02BetlyTn]: getConfig(
    {
      core: {
        privacySettings: {
          purposeIDs: {
            performanceMeasure: 'analytics-tZ2HVYZA',
          },
        },
      },
      network: {
        apiUrl: 'https://apifront-betlytn-us-player02.gaming1.com',
        mediaUrl: 'https://media-betlytn-us-player02.gaming1.com',
        prismicUrl: 'https://betlytn.cdn.prismic.io/api/v2',
        siteUrl: 'https://default-front02.gaming1.com',
        wsUrl: 'wss://betlytn-us-player02.gaming1.com:10002',
      },
      room: {
        roomName: Rooms.BETLYTN,
      },
      seo: {
        gtmId: 'GTM-WQ3GQB3',
      },
      user: {
        amountIconSide: 'left',
        bankAccountFormat: 'usa',
        bonus: true,
        closeAccount: true,
        defaultCountry: 'us',
        gamePauseDurations: [60 * 72, 60 * 24 * 7, 60 * 24 * 30],
        gamePauseInput: 'radio',
        legalDepositLimit: true,
        pinCode: true,
        registrationSuccessCheckAutenticationStatusPolling: true,
        selfExclusion: false,
      },
    },
    configOverwrite,
  ),
  // others
  [ConfigName.localGameLauncher]: getConfig(
    mergeWithSiteDefaultConfig({
      network: {
        gameLauncherUrl: 'http://localhost:3003',
      },
    }),
    configOverwrite,
  ),
};

export const isValidConfigName = (name: unknown): name is ConfigName =>
  typeof name === 'string' &&
  Object.values(ConfigName).includes(name as ConfigName);

const getDefaultConfigName = (): ConfigName => {
  const paramName = getSearchParam('config');
  const storedName = readFrom(localStorage, LOCAL_STORAGE_CONFIG_KEY);

  if (isValidConfigName(paramName)) {
    return paramName;
  }
  if (isValidConfigName(storedName)) {
    return storedName;
  }

  return ConfigName.default;
};

export const defaultConfigName = getDefaultConfigName();

if (getWrapperEnv() === 'rn') {
  sendIframeMessage(interopMessages.switchConfig, {
    configName: defaultConfigName,
    config: configs[defaultConfigName],
  });
}

export const config$ = new BehaviorSubject(configs[defaultConfigName]);
