import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';
import { CoreActions } from '../types';

import { DebuggingState } from './types';

export const initialState: DebuggingState = {
  isDebugModeEnabled: false,
};

export const debuggingReducer = (
  state: DebuggingState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      // loggedOutUser
      case getType(actions.setDebugMode):
        draftState.isDebugModeEnabled = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
