import * as t from 'io-ts';

import {
  EResponseStatus,
  EventDisplayType,
  EventType,
  Provider,
  TradingState,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

export const leagueInformationCodec = t.type(
  {
    LeagueId: t.number,
    EventDisplayType: createEnumType<EventDisplayType>(EventDisplayType),
  },
  'LeagueInformation',
);
export const eventInformationCodec = t.type(
  {
    EventId: t.number,
    HomeName: t.string,
    AwayName: t.string,
    StartDate: t.string,
    EventType: createEnumType<EventType>(EventType),
    IsBlocked: t.boolean,
  },
  'EventInformation',
);
export const marketInformationCodec = t.intersection(
  [
    t.type({
      MarketId: t.number,
      MarketName: t.string,
      State: createEnumType<TradingState>(TradingState),
      Provider: createEnumType<Provider>(Provider),
      IsCashable: t.boolean,
    }),
    t.partial({
      TeamName: t.string,
      PlayerName: t.string,
      HasOptiOdds: t.boolean,
    }),
  ],
  'MarketInformation',
);
export const outcomeInformationCodec = t.intersection(
  [
    t.type({
      OutcomeId: t.number,
      OutcomeName: t.string,
      Odd: t.number,
      OddProbability: t.number,
    }),
    t.partial({
      Base: t.number,
    }),
  ],
  'OutcomeInformation',
);
export const outcomeInfoCodec = t.partial(
  {
    EventInfo: eventInformationCodec,
    MarketInfo: marketInformationCodec,
    OutcomeInfo: outcomeInformationCodec,
    LeagueInfo: leagueInformationCodec,
  },
  'OutcomeInfo',
);

export const getInfoFromOutcomesCodec = t.intersection(
  [
    t.type({
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      Infos: t.array(outcomeInfoCodec),
    }),
  ],
  'GetInfoFromOutcomesCodec',
);
