import { concat, merge, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getScoreBoard, getScoreBoards } from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../common/store/errorMessages';
import { generateBettingMulticastGroupName } from '../common/store/helpers';
import { BettingActions, BettingEpic } from '../store/types';

import * as actions from './actions';
import {
  getScoreBoardNormalizedCodec,
  getScoreBoardResponseCodec,
  getScoreBoardsNormalizedCodec,
  getScoreBoardsResponseCodec,
} from './codecs';
import { formatGetScoreBoard, formatGetScoreBoards } from './format';
import {
  getAllScoreBoardUpdateActions,
  getScoreBoardGroupName,
  getScoreBoardUpdateActions,
} from './helpers';

/** Get score board */
export const getScoreBoardEpic: BettingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getScoreBoard.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const groupName = getScoreBoardGroupName(payload.eventId, state);
      const hasSubscriptions = wsAdapter.hasGroupSubscriptions(groupName);

      /**
       * Do NOT dispatch the fetch action if a subscription is already active
       * (because we know that score data is up to date)
       */
      const fetchAction$ = !hasSubscriptions
        ? [
            wsAdapter.request(getScoreBoard({ EventId: payload.eventId })).pipe(
              mapGuard(getScoreBoardResponseCodec),
              map(formatGetScoreBoard),
              mapGuard(getScoreBoardNormalizedCodec),
              map((response) =>
                actions.getScoreBoard.success({
                  data: response,
                  request: payload,
                }),
              ),
              catchError((err) =>
                of(
                  actions.getScoreBoard.failure({
                    data: createFailurePayload(err, commonBettingErrorMessages),
                    request: payload,
                  }),
                ),
              ),
            ),
          ]
        : [];

      const updateActions$: Observable<BettingActions>[] = [
        ...(payload.subscribeToUpdates
          ? getScoreBoardUpdateActions([payload.eventId], state, wsAdapter)
          : []),
      ];

      /**
       * The concat ensure that the success action will be dispatched before
       * the updates (even if they arrive before the fetch data).
       */
      return concat(...fetchAction$, merge(...updateActions$));
    }),
  );

/** Cancel score board subscription */
export const cancelScoreBoardSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getScoreBoard.cancel)),
    withLatestFrom(state$),
    tap(([{ payload }]) =>
      wsAdapter.unsubscribeToGroup(
        generateBettingMulticastGroupName('scoreboard', {
          eventId: payload.eventId,
          locale: payload.locale,
        }),
      ),
    ),
    ignoreElements(),
  );

/** Get score boards */
export const getScoreBoardsEpic: BettingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getScoreBoards.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const fetchAction$ = wsAdapter
        .request(getScoreBoards({ EventIds: payload.eventIds }))
        .pipe(
          mapGuard(getScoreBoardsResponseCodec),
          map(formatGetScoreBoards),
          mapGuard(getScoreBoardsNormalizedCodec),
          map((response) =>
            actions.getScoreBoards.success({
              data: response,
              request: payload,
            }),
          ),
          catchError((err) =>
            of(
              actions.getScoreBoards.failure({
                data: createFailurePayload(err, commonBettingErrorMessages),
                request: payload,
              }),
            ),
          ),
        );

      const updateActions$: Observable<BettingActions>[] = [
        ...(payload.subscribeToUpdates
          ? getScoreBoardUpdateActions(payload.eventIds, state, wsAdapter)
          : []),
      ];
      /**
       * The concat ensure that the success action will be dispatched before
       * the updates (even if they arrive before the fetch data).
       */
      return concat(fetchAction$, merge(...updateActions$));
    }),
  );

/** Cancel score boards subscription */
export const cancelScoreBoardsSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getScoreBoards.cancel)),
    withLatestFrom(state$),
    tap(([{ payload }]) =>
      payload.eventIds.forEach((eventId) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('scoreboard', {
            eventId,
            locale: payload.locale,
          }),
        ),
      ),
    ),
    ignoreElements(),
  );

/** Get all score boards */
export const getAllScoreBoardsEpic: BettingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getAllScoreBoards.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const fetchAction$ = wsAdapter.request(getScoreBoards({})).pipe(
        mapGuard(getScoreBoardsResponseCodec),
        map(formatGetScoreBoards),
        mapGuard(getScoreBoardsNormalizedCodec),
        map(actions.getAllScoreBoards.success),
        catchError((err) =>
          of(
            actions.getAllScoreBoards.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      );

      const updateActions$: Observable<BettingActions>[] = [
        ...(payload.subscribeToUpdates
          ? [getAllScoreBoardUpdateActions(state, wsAdapter)]
          : []),
      ];
      /**
       * The concat ensure that the success action will be dispatched before
       * the updates (even if they arrive before the fetch data).
       */
      return concat(fetchAction$, merge(...updateActions$));
    }),
  );

/** Cancel all score boards subscription */
export const cancelAllScoreBoardsSubscriptionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAllScoreBoards.cancel)),
    withLatestFrom(state$),
    tap(
      ([
        {
          payload: { locale },
        },
      ]) =>
        wsAdapter.unsubscribeToGroup(
          generateBettingMulticastGroupName('allScoreboards', { locale }),
        ),
    ),
    ignoreElements(),
  );
