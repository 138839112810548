import format from 'date-fns/format';
import React, {
  FC,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { useFormatMoney, useShouldRenderRequestError } from '@gaming1/g1-core';
import { RequestStateErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  Deposit,
  depositHistorySelector,
  getDepositHistoryRequestStateSelector,
  paymentActions,
} from '@gaming1/g1-payment';
import { useRequestState } from '@gaming1/g1-store';
import {
  Button,
  Icon,
  Loader,
  Message,
  RenderOnMedia,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';
import { HISTORY_PAGE_SIZE } from '@gaming1/g1-user-api';

import { depositHistoryTransactionKeys } from '../../helpers';
import { DepositHistoryInformationModal } from '../DepositHistoryInformationModal';

const isValidTransactionType = (
  num: number,
): num is keyof typeof depositHistoryTransactionKeys =>
  num in depositHistoryTransactionKeys;

export const DepositHistory: FC = () => {
  const { t } = useTranslation('payment');
  const dispatch = useDispatch();
  const formatMoney = useFormatMoney();
  const { i18n } = useContext(ConfigContext);

  const depositHistory = useSelector(depositHistorySelector);
  const { isFirstLoading, isLoading } = useRequestState(
    getDepositHistoryRequestStateSelector,
  );
  const { pagination: getDepositHistoryPagination } = useSelector(
    getDepositHistoryRequestStateSelector,
  );

  const [selectedDeposit, setSelectedDeposit] = useState<Deposit>();
  const [modalVisible, setModalVisible] = useState(false);

  const getDepositHistory = useCallback(() => {
    dispatch(
      paymentActions.getDepositHistory.request({
        PageNumber: 0,
        PageSize: HISTORY_PAGE_SIZE,
        IncludeTotal: true,
      }),
    );
  }, [dispatch]);

  useLayoutEffect(() => {
    getDepositHistory();
  }, [getDepositHistory]);

  const shouldRenderRequestError = useShouldRenderRequestError(
    getDepositHistoryRequestStateSelector,
  );

  if (shouldRenderRequestError) {
    return (
      <RequestStateErrorMessage
        onRetryButtonClick={getDepositHistory}
        selector={getDepositHistoryRequestStateSelector}
      />
    );
  }

  if (isFirstLoading) {
    return <Loader mt="sm" />;
  }

  return depositHistory.length > 0 ? (
    <>
      <Table testId="deposit-history-container">
        <thead>
          <tr>
            <th>{t('history.deposit.depositDate')}</th>
            <th>{t('history.deposit.status')}</th>
            <th>{t('history.deposit.amount')}</th>
            <RenderOnMedia min="lg">
              <th>{t('history.deposit.walletName')}</th>
              <th>{t('history.deposit.orderId')}</th>
            </RenderOnMedia>
            <RenderOnMedia max="md">
              <th>&nbsp;</th>
            </RenderOnMedia>
          </tr>
        </thead>
        <tbody>
          {depositHistory.map((history, index) => (
            <tr key={history.OrderId}>
              <td>
                {format(
                  new Date(history.DepositDate),
                  i18n.dateTimePartialFormat,
                )}
              </td>
              <td>
                {isValidTransactionType(history.TransactionState) &&
                  t(depositHistoryTransactionKeys[history.TransactionState])}
              </td>
              <td>{formatMoney(history.Amount)}</td>
              <RenderOnMedia min="lg">
                <td>{history.WalletName}</td>
                <td>{history.OrderId}</td>
              </RenderOnMedia>
              <RenderOnMedia max="md">
                <td>
                  <SimpleButton
                    data-testid={`deposit-history-information-button-${index}`}
                    onClick={() => {
                      setSelectedDeposit(history);
                      setModalVisible(true);
                    }}
                  >
                    <Icon
                      id="history-info"
                      type="VerticalTripleDots"
                      height="20px"
                    />
                  </SimpleButton>
                </td>
              </RenderOnMedia>
            </tr>
          ))}
        </tbody>
      </Table>
      {depositHistory.length < getDepositHistoryPagination.Total && (
        <Button
          block
          loading={isLoading}
          noBorder
          onClick={() => {
            dispatch(
              paymentActions.getDepositHistory.request({
                PageNumber: getDepositHistoryPagination.PageNumber + 1,
                PageSize: getDepositHistoryPagination.PageSize,
                IncludeTotal: true,
              }),
            );
          }}
          testId="deposit-history-more-button"
          type="secondary"
        >
          {t('history.seeMore')}
        </Button>
      )}
      {selectedDeposit && (
        <DepositHistoryInformationModal
          history={selectedDeposit}
          onClose={() => setModalVisible(false)}
          visible={modalVisible}
        />
      )}
    </>
  ) : (
    <Message mt="sm" type="warning">
      {t('history.noResults')}
    </Message>
  );
};
