import { opacify } from 'polished';
import styled, { css } from 'styled-components';
import {
  alignItems,
  space,
  textAlign,
  TextAlignProps,
  typography,
} from 'styled-system';

import {
  colors,
  fontSizes,
  fontWeight,
  media,
  spaces,
} from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

type Spaces = Parameters<typeof spaces>[0];

const SCOREBOARD_FULL_BULLET_SIZE_IN_PX = 7;
const SCOREBOARD_FULL_CONTENT_MAX_WIDTH_IN_PX = 560;
const SCOREBOARD_FULL_ITEM_MIN_SIZE_IN_PX = 28;
export const SCOREBOARD_FULL_SMALL_HEIGHT_IN_PX = 165;
export const SCOREBOARD_FULL_LARGE_HEIGHT_IN_PX = 180;

export const ScoreBoardFullTemplateWrapper = styled(Box)`
  display: grid;
  min-height: ${SCOREBOARD_FULL_SMALL_HEIGHT_IN_PX}px;
  height: 100%;
  width: 100%;

  ${media.lg} {
    min-height: ${SCOREBOARD_FULL_LARGE_HEIGHT_IN_PX}px;
  }
`;

export const ScoreBoardFullTemplateContentWrapper = styled(Box)`
  align-items: center;
  height: 100%;
  max-width: ${SCOREBOARD_FULL_CONTENT_MAX_WIDTH_IN_PX}px;
  position: relative;
  width: 100%;
`;

export const ScoreBoardFullTemplateRow = styled(Box)<{ gap?: Spaces }>`
  align-items: center;
  flex-direction: row;

  ${({ gap }) =>
    gap &&
    css`
      column-gap: ${spaces(gap)};
    `};
`;

export const ScoreBoardFullTemplateColumn = styled(Box)<{
  gap?: Spaces;
  shadow?: 'right' | 'left';
  ellipsis?: boolean;
}>`
  flex-direction: column;
  justify-content: center;

  ${({ shadow }) =>
    shadow === 'left' &&
    css`
      box-shadow: -8px 0 5px -5px rgba(1, 10, 17, 0.6);
    `};

  ${({ shadow }) =>
    shadow === 'right' &&
    css`
      box-shadow: 8px 0 5px -5px rgba(1, 10, 17, 0.6);
    `};

  ${({ gap }) =>
    gap &&
    css`
      row-gap: ${spaces(gap)};
    `};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `};
`;

export const ScoreBoardFullTemplateItem = styled(Box)<
  {
    hasBackground?: boolean;
    isMuted?: boolean;
  } & TextAlignProps
>`
  align-items: center;
  font-size: ${fontSizes('md')};
  font-weight: ${fontWeight('bold')};
  justify-content: center;
  min-height: ${SCOREBOARD_FULL_ITEM_MIN_SIZE_IN_PX}px;
  min-width: ${SCOREBOARD_FULL_ITEM_MIN_SIZE_IN_PX}px;

  ${media.lg} {
    font-size: ${fontSizes('lg')};
    ${typography}
  }

  ${({ hasBackground }) =>
    hasBackground &&
    css`
      background-color: ${({ theme }) =>
        opacify(-0.5)(theme.colors.backgrounds[4])};
      padding: ${spaces('xxxs')} ${spaces('xxs')};
      width: 100%;
    `};

  ${({ isMuted }) =>
    isMuted &&
    css`
      color: ${({ theme }) => opacify(-0.6)(theme.colors.textLight)};
    `};

  ${textAlign}
  ${alignItems}
  ${space}
  ${typography}
`;

export const ScoreBoardFullTemplateEllipsis = styled.div<TextAlignProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${textAlign}
`;

export const ScoreBoardFullTemplateBullet = styled(Box)<{ color?: string }>`
  background-color: ${colors('textLight')};
  border-radius: 50%;
  height: ${SCOREBOARD_FULL_BULLET_SIZE_IN_PX}px;
  width: ${SCOREBOARD_FULL_BULLET_SIZE_IN_PX}px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `};
`;
