/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:34:57 16-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { EventsForLeagueRequest, EventsForLeagueResponse } from '../entities';

export const getEventsForLeague = (options: EventsForLeagueRequest) =>
  createRequest<EventsForLeagueResponse>(201, 'GetEventsForLeague', options);
