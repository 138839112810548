import React, { useState, VFC } from 'react';

import { PromotionType } from '@gaming1/g1-betting';
import { Col, Row } from '@gaming1/g1-ui';

import { PromotionCard } from '../PromotionCard';
import { PromotionDetails } from '../PromotionDetails';

type PromotionsListProps = {
  list: PromotionType[];
};

export const PromotionsList: VFC<PromotionsListProps> = ({ list }) => {
  const [openedPromotionId, setOpenedPromotionId] = useState<string | null>(
    null,
  );

  return (
    <>
      <Row data-testid="promotions-list">
        {list.map((promotion) => (
          <Col
            xs={12}
            md={6}
            lg={12}
            xl={6}
            xxl={4}
            key={promotion.Id}
            alignItems="center"
            data-testid={`promotion-${promotion.Id}`}
          >
            <PromotionCard
              key={promotion?.Id}
              promotion={promotion}
              onClick={() => {
                setOpenedPromotionId(promotion.Id);
              }}
            />
          </Col>
        ))}
      </Row>
      {openedPromotionId && (
        <PromotionDetails
          promotionsIds={[openedPromotionId]}
          onClose={() => setOpenedPromotionId(null)}
        />
      )}
    </>
  );
};
