import { Location } from 'history';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-core';
import { userPublicRoutes } from '@gaming1/g1-user-api';
import {
  encodeQuery,
  getSearchParam,
  RouteList,
  usePrevious,
  usePreviousDifferent,
} from '@gaming1/g1-utils';

import { coreRoutes } from '../routes';

import { HistoryContext } from './components/HistoryProvider/HistoryContext';
import { concatLocation, getRoutePath } from './helpers';

/**
 * Accepts a RouteList object and return a function that returns a path from a
 * route name
 */
export const useRoutePath = <Routes extends RouteList>(routes: Routes) => {
  const { currentLocale: locale } = useI18n();
  return useMemo(() => getRoutePath<Routes>(routes, locale), [locale, routes]);
};

/**
 * Returns the previous app Location object (if any)
 */
export const usePreviousLocation = () => {
  const { previousLocation } = useContext(HistoryContext);
  return previousLocation;
};

/** Returns a function that takes a Location or a pathname and returns true
 * only if the previous location is identical to it
 */
export const useCanGoBackToReachLocation = () => {
  const previousLocation = usePreviousLocation();
  return useCallback(
    (location: string | Location) => {
      const locationObject: Location =
        typeof location === 'string'
          ? {
              pathname: location,
              search: '',
              state: null,
              hash: '',
            }
          : location;

      return (
        previousLocation &&
        previousLocation.pathname === locationObject.pathname &&
        previousLocation.search === locationObject.search
      );
    },
    [previousLocation],
  );
};

/**
 * Export a function that redirect the user to the desired path when called,
 * either by adding a new entry to the history or by going back if the user
 * is coming from that url
 */
export const useSmartBackRedirect = (location: string | Location) => {
  const canGoBackToReachLocation = useCanGoBackToReachLocation();
  const history = useHistory();
  const shouldGoBack = canGoBackToReachLocation(location);
  const backRedirect = useCallback(
    () => (shouldGoBack ? history.goBack() : history.push(location)),
    [location, history, shouldGoBack],
  );
  return backRedirect;
};

/**
 * Export a function that handle the goBack from history when called,
 * either by going back if the previous page was from this app or by
 * going to the provided url.
 */
export const useGoBackWithFallback = (
  fallbackUrl: string,
  { forceRedirect = false }: { forceRedirect?: boolean } = {},
) => {
  const previousLocation = usePreviousLocation();
  const history = useHistory();

  const goBack = useCallback(
    () =>
      !previousLocation || forceRedirect
        ? history.push(fallbackUrl)
        : history.goBack(),
    [fallbackUrl, forceRedirect, history, previousLocation],
  );

  return goBack;
};

/**
 * Return the latest Location that doesn't display a Fullscreen content
 */
export const useExitFullscreenLocation = (): Location => {
  const { exitFullscreenLocation } = useContext(HistoryContext);
  const currentLocation = useLocation();
  const getCoreRoutePath = useRoutePath(coreRoutes);
  return useMemo(() => {
    /*
    If the exit location is set to the current location there was obviously a
    problem with the exitFullscreenLocation value, so we fallback to redirecting
    the user to the homepage
    */
    if (
      exitFullscreenLocation.pathname === currentLocation.pathname &&
      exitFullscreenLocation.search === currentLocation.search
    ) {
      return {
        hash: '',
        pathname: getCoreRoutePath('home'),
        search: '',
        state: { from: 'fullscreen' },
      };
    }
    return {
      ...exitFullscreenLocation,
      state: {
        ...(typeof exitFullscreenLocation.state === 'object'
          ? exitFullscreenLocation.state
          : {}),
        from: 'fullscreen',
      },
    };
  }, [getCoreRoutePath, currentLocation, exitFullscreenLocation]);
};

/**
 * Return wether the current layout use Fullscreen or not
 */
export const useIsFullscreenLayout = () => {
  const { isFullscreenLayout } = useContext(HistoryContext);
  return isFullscreenLayout;
};

/**
 * Returns the value of the passed query param
 */
export const useSearchParam = (queryName: string) => {
  const { search } = useLocation();
  const href = `http://localhost/${search}`;
  return getSearchParam(queryName, href);
};

export const useCoreRoutePath = () => useRoutePath(coreRoutes);

/**
 * This hook will return a function to set/update parameters in the url
 * Optionally can also change the url but keep the parameters
 */
export const useUpdateRouteParameters = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return (params: Record<string, string>, pathname?: string) => {
    const consolidatedParams = params;
    query.forEach((value, key) => {
      if (!params[key]) {
        consolidatedParams[key] = value;
      }
    });
    history.push({
      pathname: pathname || location.pathname,
      search: encodeQuery(consolidatedParams),
    });
  };
};

/**
 * This hook will return a function to remove parameters from the url
 * Optionally can also change the url but keep the parameters
 */
export const useDeleteRouteParameters = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return (params: string[], pathname?: string) => {
    params.forEach((param) => query.delete(param));
    history.push({
      pathname: pathname || location.pathname,
      search: query.toString(),
    });
  };
};

/**
 * This hook will return a function that redirect to the login page, with
 * the 'from' parameter configured (for the post login redirection).
 */
export const useGoToLogin = () => {
  const history = useHistory();
  const location = useLocation();
  const getUserRoutePath = useRoutePath(userPublicRoutes);
  return useCallback(
    (postRedirectLocation?: Partial<Location>) => {
      history.push(
        getUserRoutePath('login', undefined, {
          from: concatLocation({
            ...location,
            ...postRedirectLocation,
          }),
        }),
      );
    },
    [getUserRoutePath, history, location],
  );
};

/**
 * Calls the callback when a page change is detected.
 * Meant to be used with an internal navigation (above the router).
 */
export const usePageChangeCallback = (
  callback: (pathname?: string) => void,
) => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);
  const latestDifferentPathname = usePreviousDifferent(pathname);

  useEffect(() => {
    if (pathname !== previousPathname && pathname !== latestDifferentPathname) {
      callback(pathname);
    }
  }, [callback, pathname, latestDifferentPathname, previousPathname]);
};
