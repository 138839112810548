import React, { FC } from 'react';

import { PrismicMapSlice } from '@gaming1/g1-cms';

import { PrismicMapWrapper } from './styles';

export type PrismicMapProps = {
  /** Prismic 'Map' slice */
  slice: PrismicMapSlice;
  /** Test ID */
  testId?: string;
};

const GOOGLE_MAPS_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?google\.com\/maps\/embed\?/;

/**
 * This component renders a prismic `Map` slice
 * It renders an iframe with Google Maps
 */
export const PrismicMap: FC<PrismicMapProps> = ({
  slice: {
    primary: { link, width, height },
  },
  testId = 'prismic-map',
}) => {
  if (link.url?.match(GOOGLE_MAPS_REGEX)) {
    return (
      <PrismicMapWrapper
        className="prismic-map-wrapper"
        width={width}
        height={height}
        data-testid={testId}
      >
        <iframe
          title="Google Maps"
          src={link.url}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          aria-hidden="false"
          data-testid="prismic-googlemaps-iframe"
        />
      </PrismicMapWrapper>
    );
  }

  return null;
};
