import {
  ECancelWithdrawStatus,
  ECreditCardWithdrawStatus,
  EGetWithdrawInfoStatus,
  EMakeInRoomWithdrawStatus,
  EMakeTransferWithdrawStatus,
  EWalletWithdrawStatus,
  EWithdrawMethodInfoStatus,
  GetCreditCardWithdrawInfosStatus,
  GetEWalletWithdrawInfosStatus,
  GetTransferWithdrawInfosStatus,
} from '@gaming1/g1-requests';

export const cancelWithdrawalErrorMessages = {
  [ECancelWithdrawStatus.InternalError]: 'core:error.internalServer', // 0
  [ECancelWithdrawStatus.InvalidWithdrawId]:
    'payment:withdrawal.cancel.message.invalidWithdrawId', // 2
  [ECancelWithdrawStatus.CancelDenied]:
    'payment:withdrawal.cancel.message.cancelDenied', // 3
};

export const getWithdrawInfoErrorMessages = {
  [EGetWithdrawInfoStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetWithdrawInfoStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EGetWithdrawInfoStatus.WaitingForIdentification]:
    'payment:withdrawal.message.waitingForIdentification', // 3
  [EGetWithdrawInfoStatus.PendingDocuments]:
    'payment:withdrawal.message.pendingDocuments', // 4
  [EGetWithdrawInfoStatus.PendingWithdraw]:
    'payment:withdrawal.message.pendingWithdraw', // 5
  [EGetWithdrawInfoStatus.HasRunningGame]:
    'payment:withdrawal.message.closePendingGames', // 6
};

export const getTransferWithdrawInfoErrorMessages = {
  [GetTransferWithdrawInfosStatus.InternalError]: 'core:error.internalServer', // 0
  [GetTransferWithdrawInfosStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};

export const getEWalletWithdrawInfoErrorMessages = {
  [GetEWalletWithdrawInfosStatus.InternalError]: 'core:error.internalServer', // 0
  [GetEWalletWithdrawInfosStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};

export const getCreditCardWithdrawInfoErrorMessages = {
  [GetCreditCardWithdrawInfosStatus.InternalError]: 'core:error.internalServer', // 0
  [GetCreditCardWithdrawInfosStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};

export const withdrawMethodInfoErrorMessages = {
  [EWithdrawMethodInfoStatus.MinimumDepositAmountNotReached]:
    'payment:withdrawal.methodInfo.error.MinimumDepositAmountNotReached', // 1
  [EWithdrawMethodInfoStatus.MaxPendingWithdrawReached]:
    'payment:withdrawal.methodInfo.error.MaxPendingWithdrawReached', // 2
  [EWithdrawMethodInfoStatus.InRoomWithdrawDisabled]:
    'payment:withdrawal.methodInfo.error.InRoomWithdrawDisabled', // 3
  [EWithdrawMethodInfoStatus.PlayerTooYoung]:
    'payment:withdrawal.methodInfo.error.PlayerTooYoung', // 4
  [EWithdrawMethodInfoStatus.NoPreviousInRoomDeposit]:
    'payment:withdrawal.methodInfo.error.NoPreviousInRoomDeposit', // 5
  [EWithdrawMethodInfoStatus.TooMuchInRoomWithdraws]:
    'payment:withdrawal.methodInfo.error.TooMuchInRoomWithdraws', // 6
};

export const makeInRoomWithdrawErrorMessages = {
  [EMakeInRoomWithdrawStatus.InternalError]: 'core:error.internalServer', // 0
  [EMakeInRoomWithdrawStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EMakeInRoomWithdrawStatus.WaitingForIdentity]:
    'payment:withdrawal.message.waitingForIdentification', // 3
  [EMakeInRoomWithdrawStatus.AccountNotActivated]:
    'payment:withdrawal.message.accountActivationRequired', // 4
  [EMakeInRoomWithdrawStatus.InvalidAmount]:
    'payment:withdrawal.message.invalidAmount', // 5
  [EMakeInRoomWithdrawStatus.InsufficientCredit]:
    'payment:withdrawal.message.insufficientCredit', // 6
  [EMakeInRoomWithdrawStatus.TooFrequentRequest]:
    'payment:withdrawal.message.TooFrequentRequest', // 7
  [EMakeInRoomWithdrawStatus.BonusGameRunning]:
    'payment:withdrawal.message.bonusGameRunning', // 8
  [EMakeInRoomWithdrawStatus.HasRunningGame]:
    'payment:withdrawal.message.hasRunningGame', // 9
  [EMakeInRoomWithdrawStatus.InRoomWithdrawDisabled]:
    'payment:withdrawal.message.inRoomWithdrawDisabled', // 10
  [EMakeInRoomWithdrawStatus.TooMuchInRoomWithdraws]:
    'payment:withdrawal.message.tooMuchInRoomWithdraws', // 11
  [EMakeInRoomWithdrawStatus.PlayerTooYoung]:
    'payment:withdrawal.message.playerTooYoung', // 12
  [EMakeInRoomWithdrawStatus.IovationDenied]:
    'payment:withdrawal.message.iovationDenied', // 13
  [EMakeInRoomWithdrawStatus.BadCodeFormat]:
    'payment:withdrawal.message.badCodeFormat', // 14
  [EMakeInRoomWithdrawStatus.InRoomWithdrawVirtualRoomSelectionMandatory]:
    'payment:withdrawal.message.virtualRoomIsMandatory', // 15
  [EMakeInRoomWithdrawStatus.UnknownVirtualRoom]:
    'payment:withdrawal.message.UnknownVirtualRoom', // 16
  [EMakeInRoomWithdrawStatus.NoPreviousInRoomDeposit]:
    'payment:withdrawal.message.noPreviousInRoomDeposit', // 17
  [EMakeInRoomWithdrawStatus.PendingWithdraw]:
    'payment:withdrawal.message.inRoomPendingWithdraw', // 18
  [EMakeInRoomWithdrawStatus.MaximumWithdrawPerDayReached]:
    'payment:withdrawal.message.MaximumWithdrawPerDayReached', // 20
  [EMakeInRoomWithdrawStatus.MaximumRunningWithdrawReached]:
    'payment:withdrawal.message.MaximumRunningWithdrawReached', // 21
  [EMakeInRoomWithdrawStatus.MinimumDepositAmountNotReached]:
    'payment:withdrawal.message.MinimumDepositAmountNotReached', // 22
};

export const makeTransferWithdrawErrorMessages = {
  [EMakeTransferWithdrawStatus.InternalError]: 'core:error.internalServer', // 0
  [EMakeTransferWithdrawStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EMakeTransferWithdrawStatus.WaitingForIdentity]:
    'payment:withdrawal.message.waitingForIdentification', // 3
  [EMakeTransferWithdrawStatus.AccountNotActivated]:
    'payment:withdrawal.message.accountActivationRequired', // 4
  [EMakeTransferWithdrawStatus.InvalidAmount]:
    'payment:withdrawal.message.invalidAmount', // 5
  [EMakeTransferWithdrawStatus.InsufficientCredit]:
    'payment:withdrawal.message.insufficientCredit', // 6
  [EMakeTransferWithdrawStatus.TooFrequentRequest]:
    'payment:withdrawal.message.tooFrequentRequest', // 7
  [EMakeTransferWithdrawStatus.BonusGameRunning]:
    'payment:withdrawal.message.bonusGameRunning', // 8
  [EMakeTransferWithdrawStatus.HasRunningGame]:
    'payment:withdrawal.message.hasRunningGame', // 9
  [EMakeTransferWithdrawStatus.NoIban]: 'payment:withdrawal.message.noIban', // 10
  [EMakeTransferWithdrawStatus.NoBic]: 'payment:withdrawal.message.noBic', // 11
  [EMakeTransferWithdrawStatus.InvalidIban]:
    'payment:withdrawal.message.invalidIban', // 12
  [EMakeTransferWithdrawStatus.IbanSuspicious]:
    'payment:withdrawal.message.ibanSuspicious', // 13
  [EMakeTransferWithdrawStatus.IbanUsedTooManyTimes]:
    'payment:withdrawal.message.ibanUsedTooManyTimes', // 14
  [EMakeTransferWithdrawStatus.ForbiddenIban]:
    'payment:withdrawal.message.forbiddenIban', // 15
  [EMakeTransferWithdrawStatus.IovationDenied]:
    'payment:withdrawal.message.iovationDenied', // 16
  [EMakeTransferWithdrawStatus.IbanWaitingTrust]:
    'payment:withdrawal.message.ibanWaitingTrust', // 17
  [EMakeTransferWithdrawStatus.WithdrawForbidden]:
    'payment:withdrawal.message.withdrawForbidden', // 18
  [EMakeTransferWithdrawStatus.PendingWithdraw]:
    'payment:withdrawal.message.pendingWithdraw', // 19
  [EMakeTransferWithdrawStatus.InvalidSecurityPinCode]:
    'payment:withdrawal.message.invalidSecurityPinCode', // 22
};

export const makeEWalletWithdrawErrorMessages = {
  [EWalletWithdrawStatus.InternalError]: 'core:error.internalServer', // 0
  [EWalletWithdrawStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EWalletWithdrawStatus.WaitingForIdentity]:
    'payment:withdrawal.message.waitingForIdentification', // 3
  [EWalletWithdrawStatus.AccountNotActivated]:
    'payment:withdrawal.message.accountActivationRequired', // 4
  [EWalletWithdrawStatus.InvalidAmount]:
    'payment:withdrawal.message.invalidAmount', // 5
  [EWalletWithdrawStatus.InsufficientCredit]:
    'payment:withdrawal.message.insufficientCredit', // 6
  [EWalletWithdrawStatus.TooFrequentRequest]:
    'payment:withdrawal.message.tooFrequentRequest', // 7
  [EWalletWithdrawStatus.BonusGameRunning]:
    'payment:withdrawal.message.bonusGameRunning', // 8
  [EWalletWithdrawStatus.HasRunningGame]:
    'payment:withdrawal.message.hasRunningGame', // 9
  [EWalletWithdrawStatus.IovationDenied]:
    'payment:withdrawal.message.iovationDenied', // 10
  [EWalletWithdrawStatus.AccountNotAllowed]:
    'payment:withdrawal.message.accountNotAllowed', // 11
  [EWalletWithdrawStatus.PendingWithdraw]:
    'payment:withdrawal.message.pendingWithdraw', // 12
  [EWalletWithdrawStatus.MaximumWithdrawPerDayReached]:
    'payment:withdrawal.message.maximumWithdrawPerDayReached', // 20
  [EWalletWithdrawStatus.MaximumRunningWithdrawReached]:
    'payment:withdrawal.message.maximumRunningWithdrawReached', // 21
};

export const makeCreditCardWithdrawErrorMessages = {
  [ECreditCardWithdrawStatus.InternalError]: 'core:error.internalServer', // 0
  [ECreditCardWithdrawStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [ECreditCardWithdrawStatus.WaitingForIdentity]:
    'payment:withdrawal.message.waitingForIdentification', // 3
  [ECreditCardWithdrawStatus.AccountNotActivated]:
    'payment:withdrawal.message.accountActivationRequired', // 4
  [ECreditCardWithdrawStatus.InvalidAmount]:
    'payment:withdrawal.message.invalidAmount', // 5
  [ECreditCardWithdrawStatus.InsufficientCredit]:
    'payment:withdrawal.message.insufficientCredit', // 6
  [ECreditCardWithdrawStatus.TooFrequentRequest]:
    'payment:withdrawal.message.tooFrequentRequest', // 7
  [ECreditCardWithdrawStatus.BonusGameRunning]:
    'payment:withdrawal.message.bonusGameRunning', // 8
  [ECreditCardWithdrawStatus.HasRunningGame]:
    'payment:withdrawal.message.hasRunningGame', // 9
  [ECreditCardWithdrawStatus.IovationDenied]:
    'payment:withdrawal.message.iovationDenied', // 10
  [ECreditCardWithdrawStatus.AccountNotAllowed]:
    'payment:withdrawal.message.accountNotAllowed', // 11
  [ECreditCardWithdrawStatus.PendingWithdraw]:
    'payment:withdrawal.message.pendingWithdraw', // 12
  [ECreditCardWithdrawStatus.MaximumWithdrawPerDayReached]:
    'payment:withdrawal.message.maximumWithdrawPerDayReached', // 20
  [ECreditCardWithdrawStatus.MaximumRunningWithdrawReached]:
    'payment:withdrawal.message.maximumRunningWithdrawReached', // 21
};
