import * as t from 'io-ts';

import {
  EDepositHistoryStatus,
  EWithdrawtHistoryStatus,
  EWithdrawType,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

/**
 * DEPOSIT
 */
export const deposit = t.intersection(
  [
    t.type({
      Amount: t.number,
      DepositDate: t.string,
      OrderId: t.string,
      TransactionState: t.number, // TODO : TransactionState should be a proper enum and not a number
    }),
    t.partial({
      WalletName: t.string,

      // Metadata: t.array(t.type({ key: t.string, value: t.string })),
      // StatusDescription: t.string,
    }),
  ],
  'Deposit',
);

export const getDepositHistoryResponse = t.type(
  {
    List: t.array(deposit),
    PageNumber: t.number,
    PageSize: t.number,
    Status: t.literal(EDepositHistoryStatus.Successful),
    Total: t.number,
  },
  'GetDepositHistoryResponse',
);

/**
 * WITHDRAWAL
 */
const withdrawType = createEnumType<EWithdrawType>(
  EWithdrawType,
  'EWithdrawType',
);
export const withdraw = t.intersection(
  [
    t.type({
      Amount: t.number,
      TransactionState: t.number,
      WalletId: t.string,
      WithdrawDate: t.string,
    }),
    t.partial({
      Reference: t.string,
      ExtraInfo: t.string,
      CanCancelWithdraw: t.boolean,
      WithdrawId: t.string,
      WithdrawType: withdrawType,
      // AvailableDate: t.string,
      // IBAN: t.string,
      // BIC: t.string,
      // VirtualRoomName: t.string,
      // CreditCardReference: t.string,
      // InRoomValidationCode: t.string,
      // WithdrawStatusDescription: t.string,
    }),
  ],
  'Withdraw',
);

export const getWithdrawalHistoryResponse = t.type(
  {
    Status: t.literal(EWithdrawtHistoryStatus.Successful),
    PageNumber: t.number,
    PageSize: t.number,
    Total: t.number,
    List: t.array(withdraw),
  },
  'GetTransferWithdrawInfoResponse',
);
