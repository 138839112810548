export enum Rooms {
  BETLYAR = 'BETLYAR',
  BETLYTN = 'BETLYTENN',
  BETLYWV = 'BETLYWV',
  BLITZ = 'BLITZ',
  CAROUSEL = 'CAROUSEL',
  CASINO333 = 'BET333',
  CASINOBELGIUM = 'CASINOBELGIUM',
  CIRCUS = 'CIRCUS',
  CIRCUSPE = 'CIRCUSPE',
  DEFAULT = 'DEFAULT',
  ESTORILCASINO = 'ESTORIL-CASINO',
  FAMILYGAMEONLINE = 'FAMILYGAMES',
  GOLDENVEGAS = 'GOLDENVEGAS',
  JOA = 'JOA',
  LUCKYGAMES = 'LUCKYGAMES',
  MAGICWINS = 'MAGICWINS',
  PANACHE = 'PANACHE',
  ZAMBA = 'ZAMBA',
}

export type RoomConfig = Readonly<{
  /** Unique room name of the partner */
  roomName: Rooms | string;
}>;
