import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import {
  passwordRecovery,
  resetPassword,
  validatePasswordRecovery,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  passwordRecoverySuccessResponse,
  resetPasswordSuccessResponseCodec,
  validatePasswordRecoverySucessResponseCodec,
} from './codecs';
import {
  passwordRecoveryErrorMessages,
  resetPasswordErrorMessages,
  validatePasswordRecoveryErrorMessages,
} from './errorMessages';

/** Ask for an email to be sent to the user to recover its password */
export const passwordRecoveryEpic: UserEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.recoverPassword.request)),
    withLatestFrom(state$, config$),
    mergeMap(([action, state, config]) =>
      wsAdapter
        .request(
          passwordRecovery({
            Email: action.payload.email,
            LanguageCode: currentWebSocketLocaleSelector(state),
            RoomDomainName: config.room.roomName,
          }),
        )
        .pipe(
          mapGuard(passwordRecoverySuccessResponse),
          map(() => actions.recoverPassword.success()),
          catchError((err) =>
            of(
              actions.recoverPassword.failure(
                createFailurePayload(err, passwordRecoveryErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Check if the password recovery id from the email is valid */
export const passwordRecoveryIdValidationEpic: UserEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.validatePasswordRecoveryId.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      wsAdapter
        .request(
          validatePasswordRecovery({
            PasswordRecoveryId: action.payload.recoveryId,
            LanguageCode: currentWebSocketLocaleSelector(state),
          }),
        )
        .pipe(
          mapGuard(validatePasswordRecoverySucessResponseCodec),
          map((response) =>
            actions.validatePasswordRecoveryId.success(response),
          ),
          catchError((err) =>
            of(
              actions.validatePasswordRecoveryId.failure(
                createFailurePayload(
                  err,
                  validatePasswordRecoveryErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

/** Reset the user password using a recovery id */
export const passwordResetEpic: UserEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.resetPassword.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      wsAdapter
        .request(
          resetPassword({
            NewPassword: action.payload.password,
            PasswordConfirm: action.payload.password,
            PasswordRecoveryId: action.payload.recoveryId,
            LanguageCode: currentWebSocketLocaleSelector(state),
            SecurityPinCode: action.payload.pinCode,
          }),
        )
        .pipe(
          mapGuard(resetPasswordSuccessResponseCodec),
          map(() => actions.resetPassword.success()),
          catchError((err) =>
            of(
              actions.resetPassword.failure(
                createFailurePayload(err, resetPasswordErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
