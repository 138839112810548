/* istanbul ignore file */

import { combineReducers, Reducer } from 'redux';

import { GamingActions } from '../../../store/types';
import { GameListState } from '../types';

import { gameListAttributesReducer } from './attributes';
import { gameListEntitiesReducer } from './entities';
import { gameListRequestsReducer } from './requests';

export const gameListReducer: Reducer<GameListState, GamingActions> =
  combineReducers({
    attributes: gameListAttributesReducer,
    entities: gameListEntitiesReducer,
    requests: gameListRequestsReducer,
  });
