import { isLeft, left } from 'fp-ts/Either';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CodecGuardError, isCodecGuardError } from '@gaming1/g1-utils';

import { logger } from '../logger';

import {
  iframeMessageCodec,
  localeChangedMessageCodec,
  locationChangedMessageCodec,
  openAppSettingsMessageCodec,
  openBrowserMessageCodec,
  openExternalLinkViewMessageCodec,
  reduxActionTwinningMessageCodec,
} from './codecs';
import {
  LocaleChangedMessage,
  LocationChangedMessage,
  OpenAppSettingsMessage,
  OpenBrowserMessage,
  OpenExternalLinkViewMessage,
  ReduxActionTwinningMessage,
  SimpleIframeMessage,
} from './types';
/**
 * Check if it's a redux action sent through the iframe messages
 * @param action
 */
export const isReduxActionIframeMessage = (
  action: SimpleIframeMessage,
): action is ReduxActionTwinningMessage =>
  reduxActionTwinningMessageCodec.is(action);

export const isLocationChangeMessage = (
  action: SimpleIframeMessage,
): action is LocationChangedMessage => locationChangedMessageCodec.is(action);

export const isOpenBrowserMessage = (
  action: SimpleIframeMessage,
): action is OpenBrowserMessage => openBrowserMessageCodec.is(action);

export const isOpenExternalLinkViewMessage = (
  action: SimpleIframeMessage,
): action is OpenExternalLinkViewMessage =>
  openExternalLinkViewMessageCodec.is(action);

export const isOpenAppSettingsMessage = (
  action: SimpleIframeMessage,
): action is OpenAppSettingsMessage => openAppSettingsMessageCodec.is(action);

export const isLocaleChangeMessage = (
  action: SimpleIframeMessage,
): action is LocaleChangedMessage => localeChangedMessageCodec.is(action);

/** Pipeable operator to be used when reading the messages sent through
 * postMessage with an observable. Will filter out messages that are not object
 * or that lack the "type" property. Will also parse the message if it's a
 * stringified object */
export const parseInteropMessages = pipe(
  filter(
    (data): data is string | Record<string, unknown> =>
      !!data && (typeof data === 'string' || typeof data === 'object'),
  ),
  // Ensure the object have the "type" prop
  filter((data) =>
    typeof data === 'string'
      ? // Filter out strings that aren't a stringified object with a type prop
        data.indexOf('{') !== -1 && data.indexOf('type') !== -1
      : // Filter out object without a "type" prop
        'type' in data,
  ),
  // Parse stringified messages
  map((message) => {
    if (typeof message === 'object') {
      return message;
    }
    try {
      const parsedMessage = JSON.parse(message);
      return parsedMessage;
    } catch (e) {
      logger.error('[Iframe Message] Could not parse message', message);
    }
    return message;
  }),
);

/** Logs the malformed iframe messages received */
const logMessageErrors = (err: unknown) => {
  if (isCodecGuardError(err)) {
    logger.debug(
      '[Iframe] Received malformed iframe message',
      err.message,
      'the type',
      err.type,
      'could not be infered in value',
      err.value,
      'details:',
      err.report,
    );
  } else {
    logger.debug('[Iframe] Received malformed iframe message', err);
  }
};

/** Type guard for the SimpleInteropMessage type */
export const isSimpleIframeMessage = (
  message: unknown,
): message is SimpleIframeMessage => {
  if (!message) {
    return false;
  }

  const decodeResult = iframeMessageCodec.decode(message);
  if (isLeft(decodeResult)) {
    const error = new CodecGuardError(
      iframeMessageCodec.name,
      message,
      left(decodeResult.left),
    );
    logMessageErrors(error);
    return false;
  }
  return true;
};
