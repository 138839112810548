import { RemoteData } from '@gaming1/g1-utils';

import { ApplicationWithCmsState } from '../../store/types';

import { PageContentDocument } from './types';

export const pageContentDocumentsStatusByQuerySelector =
  (query: string) =>
  (state: ApplicationWithCmsState): RemoteData =>
    state.cms.prismicPageContent.requests[query]?.status || RemoteData.NotAsked;

export const pageContentDocumentsByQuerySelector =
  (query: string) =>
  (state: ApplicationWithCmsState): PageContentDocument[] =>
    (state.cms.prismicPageContent.requests[query]?.result || []).map(
      (id) => state.cms.prismicPageContent.entities.documents[id],
    );
