import React, { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  EBettingSlipIdentifier,
  useBettingSlipIsEmpty,
} from '@gaming1/g1-betting';
import { DepositButton } from '@gaming1/g1-core-web';
import { Box, Drawer, DrawerType, Icon, LayoutContext } from '@gaming1/g1-ui';

import { SubDrawerProvider } from '../../../bettingSlip/components/SubDrawer/SubDrawerProvider';
import { BettingSlipMain } from '../../../bettingSlip/components/Templates/BettingSlipMain';
import { BettingSlipTitle } from '../../../bettingSlip/components/Templates/BettingSlipMain/BettingSlipTitle';
import { useIsMobileTicket } from '../../../bettingSlip/hooks/render';
import { BettingSlipTitleContainer } from '../../../styles';
import { widthBettingSlipDrawerByMedia } from '../../helpers';

import { BettingSlipDrawerContainer, CloseButton } from './styles';

export const BettingSlipDrawer: FC = () => {
  const { media, visibleDrawer, hideDrawer } = useContext(LayoutContext);
  const showTicket = useIsMobileTicket();
  const bettingSlipDrawerVisibility = visibleDrawer === DrawerType.bettingSlip;

  const location = useLocation();

  /**
   * We need to know if the betting slip is empty or not.
   *
   * If the betting slip is NOT empty, we don't need to add a height to the BettingSlipDrawerContainer,
   * if the betting slip is empty, we have to add a 100% height to it so the justifyContent will work as intended.
   */
  const isBettingSlipEmpty = useBettingSlipIsEmpty(EBettingSlipIdentifier.Main);

  useEffect(() => {
    if (bettingSlipDrawerVisibility) {
      hideDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideDrawer, location]);

  if (!showTicket) {
    return null;
  }

  return (
    <Drawer
      type={DrawerType.bettingSlip}
      appearFrom="right"
      width={widthBettingSlipDrawerByMedia[media]}
      ariaLabel="Betting slip"
      testId="bettingslip-drawer"
    >
      <SubDrawerProvider>
        <BettingSlipDrawerContainer
          data-testid="bettingslip-drawer-container"
          hasAFixedHeight={isBettingSlipEmpty}
        >
          <BettingSlipTitleContainer
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <BettingSlipTitle
              bettingSlipIdFallback={EBettingSlipIdentifier.Main}
            />
            <Box alignItems="center" flexDirection="row" mr="sm">
              <DepositButton />
              <CloseButton
                onClick={hideDrawer}
                data-testid="close-bettingslip-drawer-button"
              >
                <Icon id="close-bettingslip-drawer" type="Cross" ml="sm" />
              </CloseButton>
            </Box>
          </BettingSlipTitleContainer>
          <BettingSlipMain showTitle={false} />
        </BettingSlipDrawerContainer>
      </SubDrawerProvider>
    </Drawer>
  );
};
