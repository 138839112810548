/**
 * Returns a path with a proper basename depending on where the web app is
 * deployed (has no effect on external paths)
 */
export const getAssetsPath = (path: string) => {
  const appBaseName = process.env.REACT_APP_BASE_NAME;
  const isExternalPath = path.indexOf('http') === 0;
  if (isExternalPath) {
    return path;
  }
  const baseName =
    appBaseName && appBaseName.slice(-1) === '/' ? appBaseName : '/';
  const relativePath = path.charAt(0) === '/' ? path.substr(1) : path;
  return `${baseName}${relativePath}`;
};
