import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { SportbookState } from '../types';

const filtersInitialState: SportbookState['filters'] = [];

export const filtersReducer = (
  state = filtersInitialState,
  action: BettingActions,
) =>
  produce(state, () => {
    switch (action.type) {
      case getType(actions.getFilters.success):
        return action.payload.filters;

      default: // Immer will automatically return the state
    }
  });
