import { ScoreBoardState } from '@gaming1/g1-requests-betting';

/** Separator used in different scoreboards */
export const SCOREBOARD_SCORE_SEPARATOR = '-';

/** State keys that will be used in translation keys */
export const SCOREBOARD_STATE_KEYS: {
  [k in Exclude<ScoreBoardState, ScoreBoardState.Unknown>]: string;
} = {
  [ScoreBoardState.Set1]: 'set1',
  [ScoreBoardState.Set2]: 'set2',
  [ScoreBoardState.Set3]: 'set3',
  [ScoreBoardState.Set4]: 'set4',
  [ScoreBoardState.Set5]: 'set5',
  [ScoreBoardState.Set6]: 'set6',
  [ScoreBoardState.Set7]: 'set7',
  [ScoreBoardState.Set8]: 'set8',
  [ScoreBoardState.Set9]: 'set9',
  [ScoreBoardState.Set10]: 'set10',
  [ScoreBoardState.Set11]: 'set11',
  [ScoreBoardState.Set12]: 'set12',
  [ScoreBoardState.Set13]: 'set13',
  [ScoreBoardState.Wait]: 'wait',
  [ScoreBoardState.Timeout]: 'timeout',
  [ScoreBoardState.Penalty]: 'penalty',
  [ScoreBoardState.Finished]: 'finished',
  [ScoreBoardState.Cancel]: 'cancel',
};
