/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:23:12 26-01-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetScratchCardPrizeInfoRequestDTO,
  GetScratchCardPrizeInfoResponseDTO,
} from '../entities';

export const getScratchCardPrizeInfo = (
  options: GetScratchCardPrizeInfoRequestDTO,
) =>
  createRequest<GetScratchCardPrizeInfoResponseDTO>(
    102,
    'GetScratchCardPrizeInfo',
    options,
  );
