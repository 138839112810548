import format from 'date-fns/format';
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
  VFC,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { useShouldRenderRequestError } from '@gaming1/g1-core';
import { RequestStateErrorMessage } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  FreeSpin,
  freeSpinsHistorySelector,
  getGameFeatureHistoryRequestStateSelector,
  loyaltyActions,
} from '@gaming1/g1-loyalty';
import { useRequestState } from '@gaming1/g1-store';
import {
  Button,
  Icon,
  Loader,
  Message,
  RenderOnMedia,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';
import { HISTORY_PAGE_SIZE } from '@gaming1/g1-user-api';

import { FreeSpinsHistoryInformationModal } from '../FreeSpinsHistoryInformationModal';
import { freeSpinsHistoryTransactionKeys } from '../helpers';

import { FreeSpinsHistoryWinnings } from './FreeSpinsHistoryWinnings';

export const FreeSpinsHistory: VFC = () => {
  const { t } = useTranslation('loyalty');
  const dispatch = useDispatch();
  const { i18n } = useContext(ConfigContext);

  const freeSpinsHistory = useSelector(freeSpinsHistorySelector);
  const { isFirstLoading, isLoading } = useRequestState(
    getGameFeatureHistoryRequestStateSelector,
  );
  const { pagination: getFreeSpinsHistoryPagination } = useSelector(
    getGameFeatureHistoryRequestStateSelector,
  );

  const [selectedFreeSpins, setSelectedFreeSpins] = useState<FreeSpin>();
  const [modalVisible, setModalVisible] = useState(false);

  const getFreeSpinsHistory = useCallback(() => {
    dispatch(
      loyaltyActions.getFreeSpinsHistory.request({
        PageNumber: 0,
        PageSize: HISTORY_PAGE_SIZE,
        IncludeTotal: true,
      }),
    );
  }, [dispatch]);

  useLayoutEffect(() => {
    getFreeSpinsHistory();
  }, [getFreeSpinsHistory]);

  const shouldRenderRequestError = useShouldRenderRequestError(
    getGameFeatureHistoryRequestStateSelector,
  );

  if (shouldRenderRequestError) {
    return (
      <RequestStateErrorMessage
        onRetryButtonClick={getFreeSpinsHistory}
        selector={getGameFeatureHistoryRequestStateSelector}
      />
    );
  }

  if (isFirstLoading) {
    return <Loader mt="sm" />;
  }

  return freeSpinsHistory.length > 0 ? (
    <>
      <Table testId="freespins-history-container">
        <thead>
          <tr>
            <th>{t('history.freeSpins.createdDate')}</th>
            <RenderOnMedia min="lg">
              <th>{t('history.freeSpins.updatedDate')}</th>
              <th>{t('history.freeSpins.number')}</th>
              <th>{t('history.freeSpins.stake')}</th>
            </RenderOnMedia>
            <th>{t('history.freeSpins.status')}</th>
            <th>{t('history.freeSpins.winnings')}</th>
            <RenderOnMedia max="md">
              <th>&nbsp;</th>
            </RenderOnMedia>
          </tr>
        </thead>
        <tbody>
          {freeSpinsHistory.map((history, index) => (
            <tr key={`freespins-history-${index + 1}`}>
              <td>
                {format(
                  new Date(history.CreatedDate),
                  i18n.dateTimePartialFormat,
                )}
              </td>
              <RenderOnMedia min="lg">
                <td>
                  {format(
                    new Date(history.UpdatedDate),
                    i18n.dateTimePartialFormat,
                  )}
                </td>
                <td>{history.NumberOfSpins}</td>
                <td>{history.StakeValue}</td>
              </RenderOnMedia>
              <td>{t(freeSpinsHistoryTransactionKeys[history.Status])}</td>
              <td>
                <FreeSpinsHistoryWinnings history={history} />
              </td>
              <RenderOnMedia max="md">
                <td>
                  <SimpleButton
                    data-testid={`freespins-history-information-button-${index}`}
                    onClick={() => {
                      setSelectedFreeSpins(history);
                      setModalVisible(true);
                    }}
                  >
                    <Icon
                      id="history-info"
                      type="VerticalTripleDots"
                      height="20px"
                    />
                  </SimpleButton>
                </td>
              </RenderOnMedia>
            </tr>
          ))}
        </tbody>
      </Table>
      {freeSpinsHistory.length < getFreeSpinsHistoryPagination.Total && (
        <Button
          block
          loading={isLoading}
          noBorder
          onClick={() => {
            dispatch(
              loyaltyActions.getFreeSpinsHistory.request({
                PageNumber: getFreeSpinsHistoryPagination.PageNumber + 1,
                PageSize: getFreeSpinsHistoryPagination.PageSize,
                IncludeTotal: true,
              }),
            );
          }}
          testId="freespins-history-more-button"
          type="secondary"
        >
          {t('history.seeMore')}
        </Button>
      )}
      {selectedFreeSpins && (
        <FreeSpinsHistoryInformationModal
          history={selectedFreeSpins}
          onClose={() => setModalVisible(false)}
          visible={modalVisible}
        />
      )}
    </>
  ) : (
    <Message mt="sm" type="warning">
      {t('history.noResults')}
    </Message>
  );
};
