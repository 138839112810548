import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getSlider } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { CoreEpic } from '../types';

import * as actions from './actions';
import { getSliderResponse } from './codecs';
import { getSliderErrorMessages } from './errorMessages';

/** Get slider */
export const getSliderEpic: CoreEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getSlider.request)),
    withLatestFrom(config$),
    mergeMap(([{ payload }, { room }]) => {
      const request = payload;
      return wsAdapter
        .request(getSlider({ ...payload, RoomDomainName: room.roomName }))
        .pipe(
          mapGuard(getSliderResponse),
          map((data) => actions.getSlider.success({ ...data, request })),
          catchError((err) =>
            of(
              actions.getSlider.failure({
                request,
                ...createFailurePayload(err, getSliderErrorMessages),
              }),
            ),
          ),
        );
    }),
  );
