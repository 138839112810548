/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:12:04 09-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetFavoriteAndRecentGameListRequestDTO,
  GetFavoriteAndRecentGameListResponseDTO,
  GetFavoriteGameListRequestDTO,
  GetFavoriteGameListResponseDTO,
  GetGameCountForRangeRequestDTO,
  GetGameCountForRangeResponseDTO,
  GetGameDetailsRequestDTO,
  GetGameDetailsResponseDTO,
  GetGameHistoryRequestDTO,
  GetGameHistoryResponseDTO,
  GetGameListRequestDTO,
  GetGameListResponseDTO,
  GetGameRequestDTO,
  GetGameResponseDTO,
  GetGamesCategoriesRequestDTO,
  GetGamesCategoriesResponseDTO,
  GetLobbyDisplayRequestDTO,
  GetLobbyDisplayResponseDTO,
  GetPaginatedGameListRequestDTO,
  GetPaginatedGameListResponseDTO,
  GetPendingGameRequestDTO,
  GetPendingGameResponseDTO,
  GetPendingGamesResponseDTO,
  GetRecentGameListRequestDTO,
  GetRecentGameListResponseDTO,
  RegisterBonusGameRequestDTO,
  RegisterBonusGameResponseDTO,
  RegisterGameRequestDTO,
  RegisterGameResponseDTO,
  ResumeGameRequestDTO,
  ResumeGameResponseDTO,
  SetFavoriteGameListRequestDTO,
  SetFavoriteGameListResponseDTO,
} from '../entities';

export const getGameList = (options: GetGameListRequestDTO) =>
  createRequest<GetGameListResponseDTO>(103, 'GetGameList', options);

export const getPaginatedGameList = (options: GetPaginatedGameListRequestDTO) =>
  createRequest<GetPaginatedGameListResponseDTO>(
    103,
    'GetPaginatedGameList',
    options,
  );

export const getLobbyDisplay = (options: GetLobbyDisplayRequestDTO) =>
  createRequest<GetLobbyDisplayResponseDTO>(103, 'GetLobbyDisplay', options);

export const getGameCountForRange = (options: GetGameCountForRangeRequestDTO) =>
  createRequest<GetGameCountForRangeResponseDTO>(
    103,
    'GetGameCountForRange',
    options,
  );

export const getGame = (options: GetGameRequestDTO) =>
  createRequest<GetGameResponseDTO>(103, 'GetGame', options);

export const getGameDetails = (options: GetGameDetailsRequestDTO) =>
  createRequest<GetGameDetailsResponseDTO>(103, 'GetGameDetails', options);

export const getGameHistory = (options: GetGameHistoryRequestDTO) =>
  createRequest<GetGameHistoryResponseDTO>(103, 'GetGameHistory', options);

export const setFavoriteGameList = (options: SetFavoriteGameListRequestDTO) =>
  createRequest<SetFavoriteGameListResponseDTO>(
    103,
    'SetFavoriteGameList',
    options,
  );

export const getFavoriteGameList = (options: GetFavoriteGameListRequestDTO) =>
  createRequest<GetFavoriteGameListResponseDTO>(
    103,
    'GetFavoriteGameList',
    options,
  );

export const getRecentGameList = (options: GetRecentGameListRequestDTO) =>
  createRequest<GetRecentGameListResponseDTO>(
    103,
    'GetRecentGameList',
    options,
  );

export const getGamesCategories = (options: GetGamesCategoriesRequestDTO) =>
  createRequest<GetGamesCategoriesResponseDTO>(
    103,
    'GetGamesCategories',
    options,
  );

export const getFavoriteAndRecentGameList = (
  options: GetFavoriteAndRecentGameListRequestDTO,
) =>
  createRequest<GetFavoriteAndRecentGameListResponseDTO>(
    103,
    'GetFavoriteAndRecentGameList',
    options,
  );

export const registerGame = (options: RegisterGameRequestDTO) =>
  createRequest<RegisterGameResponseDTO>(103, 'RegisterGame', options);

export const registerBonusGame = (options: RegisterBonusGameRequestDTO) =>
  createRequest<RegisterBonusGameResponseDTO>(
    103,
    'RegisterBonusGame',
    options,
  );

export const resumeGame = (options: ResumeGameRequestDTO) =>
  createRequest<ResumeGameResponseDTO>(103, 'ResumeGame', options);

export const getPendingGame = (options: GetPendingGameRequestDTO) =>
  createRequest<GetPendingGameResponseDTO>(103, 'GetPendingGame', options);

export const getPendingGames = (options: GetPendingGameRequestDTO) =>
  createRequest<GetPendingGamesResponseDTO>(103, 'GetPendingGames', options);
