import { FC, useEffect, useState } from 'react';
import { BehaviorSubject, from, timer } from 'rxjs';
import {
  delay,
  delayWhen,
  filter,
  map,
  retryWhen,
  switchMap,
  tap,
  timeout,
} from 'rxjs/operators';

import { getDeployEnv } from '@gaming1/g1-logger';
import { loadScript, useGetIsMounted } from '@gaming1/g1-utils';

import { logger } from '../../../logger';

import { IOVATION_DELAY_IN_MS, IOVATION_MAX_TIMEOUT_IN_MS } from './constants';
import { BlackBoxHandler, IovationComponentsProps } from './types';

type IovationLoaderWindowProperties = {
  io_global_object_name?: string;
  IGLOO?: {
    /**
     * This JavaScript function is an event handler that is called when a collection method has finished
     * updating a blackbox. This must be a function, not a string.
     */
    bb_callback?: BlackBoxHandler;
    /**
     * The ID of the HTML element to populate with the blackbox from the third-party JavaScript.
     * If bb_callback is specified, this parameter has no effect.
     */
    bbout_element_id?: string;
    /**
     * Determines if Flash is used to collect device information
     */
    enable_flash?: boolean;
    /**
     * collect Real IP information
     */
    enable_rip?: boolean;
    /**
     * If install_flash is set to false, this handler will not run.
     * Users may see an error if the required version of Flash is not installed to their systems.
     * Use this variable to define your own JavaScript error handling for this condition.
     */
    flash_needs_update_handler?: () => void;
    /**
     * Determines whether the user is prompted to install or upgrade Flash if the required version of Flash is not installed.
     * If the required version of Flash is installed, this setting has no effect.
     */
    install_flash?: boolean;
    /**
     * Minimum version of Flash required for collection Flash values
     */
    min_flash_version?: number;
    loader: {
      enable_legacy_compatibility?: boolean;
      /**
       * This will be an TransUnion assigned value that tracks requests from your site.
       * This is primarily used for debugging and troubleshooting purposes.
       */
      subkey?: string;
      /**
       * This JavaScript function can be used to provide tracing messages for the script.
       * This will provide information on the progress of the script and is useful for debugging purposes.
       */
      trace_handler?: (message: string) => void;
      /**
       * Location of dynamic first party components. This should be a reference to the web directory being proxied.
       * You can use relative or absolute references, but should not use a complete URL.
       */
      uri_hook: string;
      /**
       * where general5 retrieves the latest version of the JavaScript. This is the version of the script to load.
       * This value should not be changed to ensure compatibility with downloaded resources.
       */
      version: string;
    };
  };
};

type WindowWithIovationProperties = Window & IovationLoaderWindowProperties;
const windowWithIovationProperties = window as WindowWithIovationProperties;

// "loader_only" always makes sure the latest iovation's Loader version is selected
const iovationLoaderPath = `${process.env.REACT_APP_BASE_NAME}iovation/loader_only.js`;

/**
 * Latest Iovation configuration using a global interface called (by default) IGLOO
 */
export const IovationLoaderConfiguration: FC<IovationComponentsProps> = ({
  onDispatch,
}) => {
  const getIsMounted = useGetIsMounted();

  const [hasScriptBeenAdded, setHasScriptBeenAdded] = useState(
    !!document.querySelector(`script[src="${iovationLoaderPath}"]`),
  );

  useEffect(() => {
    if (!hasScriptBeenAdded) {
      let stopListening = false;
      const ioBlackBox$ = new BehaviorSubject<string | null>(null);

      const ioBlackBoxUpdateHandler: BlackBoxHandler = (
        blackBox,
        completed,
      ) => {
        if (!stopListening && completed) {
          ioBlackBox$.next(blackBox);
        }
      };

      windowWithIovationProperties.io_global_object_name = 'IGLOO';
      windowWithIovationProperties.IGLOO =
        windowWithIovationProperties.IGLOO || {
          bb_callback: ioBlackBoxUpdateHandler,
          bbout_element_id: 'ioBlackBox',
          enable_flash: false,
          loader: {
            uri_hook: 'iojs',
            // "general5" retrieves the latest version of the JavaScript
            version: 'general5',
          },
        };

      // Trace_handler will only be used for debugging.
      if (getDeployEnv() !== 'production') {
        windowWithIovationProperties.IGLOO.loader.trace_handler = (
          message: string,
        ) => {
          logger.warn('[Tracking] Iovation trace', message);
        };
      }

      setHasScriptBeenAdded(true);
      // Start from a promise, in that case, that loads the Iovation JS script.
      const subscription = from(loadScript(iovationLoaderPath))
        .pipe(
          tap(() =>
            logger.info(
              '[Tracking] Iovation scripts loaded (Loader configuration)',
            ),
          ),
          filter(getIsMounted),
          delay(IOVATION_DELAY_IN_MS),
          switchMap(() =>
            // Listen to the ioBlackBox value in a new observable so that the 'retryWhen'
            // Observable only retries on the given pipe instead of the whole one
            // (including the loadScript for instance).
            ioBlackBox$.pipe(
              map((ioBlackBoxValue) => {
                if (ioBlackBoxValue) {
                  return ioBlackBoxValue;
                }
                throw new Error(
                  '[Tracking] Could not get blackBoxes from Iovation (Loader configuration).  Retrying...',
                );
              }),
              // Retry on error, with the give delay in ms.
              retryWhen((error) =>
                error.pipe(
                  tap((err: Error) =>
                    logger.warn(
                      '[Tracking] Iovation Warning: retrying after error',
                      err.message,
                    ),
                  ),
                  delayWhen(() => timer(IOVATION_DELAY_IN_MS)),
                ),
              ),
              // After IOVATION_MAX_TIMEOUT_IN_MS ms, stop listening to iovation
              timeout(IOVATION_MAX_TIMEOUT_IN_MS),
            ),
          ),
        )
        .subscribe({
          // Success
          next: (ioBlackBoxValue) => {
            stopListening = true;
            onDispatch({ ioBlackBox: ioBlackBoxValue });
            // prevents the timeout to keep running
            ioBlackBox$.complete();
          },
          // Timeout error
          error: () => {
            stopListening = true;
            logger.warn('[Tracking] Iovation timeout (Loader configuration)');
          },
        });

      return () => subscription.unsubscribe();
    }

    return () => undefined;
    // adding the dependency could make the setup run multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
