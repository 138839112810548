import React, { FC, memo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { actions, EFrontErrorType } from '@gaming1/g1-betting';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import { Message } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipCallToAction } from '../BettingSlipMessage/BettingSlipCallToAction';

export const BettingSlipIsDirtyOddComposent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const dispatch = useDispatch();
  const { t } = useTranslation('betting');

  const resetOdds = () => {
    dispatch(actions.resetOddChange(bettingSlipId));
  };

  return (
    <Message
      mb="sm"
      p="xs"
      size="small"
      type="warning"
      testId="bettingslip.dirtyodd.message"
    >
      <Trans
        components={{
          LinkDesign: (
            <BettingSlipCallToAction
              data-testid="dirtyodds-message-button"
              notification={{
                Status: EFrontErrorType.DirtyOdd,
                Level: NotificationLevel.Warning,
                Code: NotificationCode.Unknown,
              }}
              callToActionsFunc={{ resetOdds }}
            />
          ),
        }}
        i18nKey="bettingslip.dirtyodd.message"
        t={t}
      />
    </Message>
  );
};
export const BettingSlipIsDirtyOdd = memo(BettingSlipIsDirtyOddComposent);
