import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { outcomeListSelector } from '../../../../sportbook/store/selectors';
import { extractOddFromRankCombination, haveRanks } from '../../../helpers';
import { BettingSlipRankState } from '../../types/store';
import {
  bettingSlipGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
} from '../common';

export const bettingSlipRanksGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) =>
    (
      bettingSlipId: BettingSlipIdentifierType,
    ): BettingSlipRankState[] | undefined => {
      const bettingslip = getBettingSlip(bettingSlipId);
      if (!bettingslip) {
        return undefined;
      }
      if (!haveRanks(bettingslip)) {
        return [];
      }

      return bettingslip.ranks;
    },
);

export const bettingSlipRankGetterSelector = createSelector(
  bettingSlipRanksGetterSelector,
  (bettingSlipRanks) =>
    (
      bettingSlipId: BettingSlipIdentifierType,
      rankId: string,
    ): BettingSlipRankState | undefined =>
      bettingSlipRanks(bettingSlipId)?.find((rank) => rank.id === rankId),
);

export const bettingSlipRankOddsGetterSelector = createSelector(
  bettingSlipRanksGetterSelector,
  bettingSlipRankGetterSelector,
  outcomeListSelector,
  bettingSlipRoundingConfigGetterSelector,
  (bettingSlipRanks, bettingSlipRank, outcomeList, roundingConfig) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
        rankId: string,
      ): number | undefined => {
        const ranks = bettingSlipRanks(bettingSlipId);
        if (!ranks) {
          return undefined;
        }
        const rank = bettingSlipRank(bettingSlipId, rankId);
        if (!rank) {
          return undefined;
        }

        if (rank.type === 'normal') {
          return extractOddFromRankCombination(
            rank,
            outcomeList,
            roundingConfig(bettingSlipId),
          );
        }
        return ranks
          .filter((rankItem) => rankItem.type && rankItem.type === 'normal')
          .reduce(
            (oldValue, rankItem) =>
              oldValue +
              extractOddFromRankCombination(
                rankItem,
                outcomeList,
                roundingConfig(bettingSlipId),
              ),
            0,
          );
      },
      (bettingSlipId: BettingSlipIdentifierType, rankId: string) =>
        `${bettingSlipId}-${rankId}`,
    ),
);
/*
 *
 * Selector for Rank
 */
export const bettingSlipRankAvailableGetterSelector = createSelector(
  bettingSlipRankGetterSelector,
  (bettingSlipRank) =>
    (bettingSlipId: BettingSlipIdentifierType, id: string) => {
      const rank = bettingSlipRank(bettingSlipId, id);
      if (!rank) {
        return undefined;
      }
      return rank.available;
    },
);
export const bettingSlipRankNbElementsGetterSelector = createSelector(
  bettingSlipRankGetterSelector,
  (bettingSlipRank) =>
    (bettingSlipId: BettingSlipIdentifierType, id: string) => {
      const rank = bettingSlipRank(bettingSlipId, id);
      if (!rank) {
        return undefined;
      }

      return rank.itemsCombination.length > 0
        ? rank.itemsCombination[0].length
        : 0;
    },
);
export const bettingSlipRankStakeGetterSelector = createSelector(
  bettingSlipRankGetterSelector,
  (bettingSlipRank) =>
    (bettingSlipId: BettingSlipIdentifierType, id: string) => {
      const rank = bettingSlipRank(bettingSlipId, id);
      if (!rank) {
        return undefined;
      }
      return rank.stake;
    },
);

export const bettingSlipRankStakeInFloatGetterSelector = createSelector(
  bettingSlipRankStakeGetterSelector,
  (bettingSlipRankStake) =>
    (
      bettingSlipId: BettingSlipIdentifierType,
      id: string,
    ): number | undefined => {
      const stake = bettingSlipRankStake(bettingSlipId, id);
      if (!stake) {
        return undefined;
      }
      return parseFloat(stake);
    },
);

export const bettingSlipRankNbCombinationsGetterSelector = createSelector(
  bettingSlipRanksGetterSelector,
  bettingSlipRankGetterSelector,
  (bettingSlipRanks, bettingSlipRank) =>
    (
      bettingSlipId: BettingSlipIdentifierType,
      id: string,
    ): number | undefined => {
      const rank = bettingSlipRank(bettingSlipId, id);
      if (!rank) {
        return undefined;
      }
      if (rank.type === 'normal') {
        return rank.itemsCombination.length;
      }
      const ranks = bettingSlipRanks(bettingSlipId);
      if (!ranks) {
        return undefined;
      }
      return ranks
        .filter((rankItem) => rankItem.type === 'normal')
        .reduce(
          (oldValue, rankItem) => oldValue + rankItem.itemsCombination.length,
          0,
        );
    },
);
