import {
  GetConditionsForBettingSlipRequest,
  GetPromoForBettingSlipRequest,
} from '@gaming1/g1-requests-betting';

import { RiskManagementRawData } from '../store/types/riskManagement';

export const formatForBettingSlipPreparedRequest = ({
  bettingSlipType,
  preparedElements,
}: RiskManagementRawData):
  | GetConditionsForBettingSlipRequest
  | GetPromoForBettingSlipRequest => ({
  BettingSlipType: bettingSlipType,
  Elements: preparedElements,
});
