import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getDocumentStatus,
  getUploadDocumentStatus,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { UserEpic } from '../../store/types';

import * as actions from './actions';
import {
  getDocumentStatusResponse,
  getUploadDocumentStatusResponse,
} from './codecs';
import {
  getDocumentStatusErrorMessages,
  getUploadDocumentStatusErrorMessages,
} from './errorMessages';

/** Get document status */
export const getDocumentStatusEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getDocumentStatus.request)),
    switchMap(() =>
      wsAdapter.request(getUploadDocumentStatus({})).pipe(
        mapGuard(getUploadDocumentStatusResponse),
        map(actions.getDocumentStatus.success),
        catchError((err) =>
          of(
            actions.getDocumentStatus.failure(
              createFailurePayload(err, getUploadDocumentStatusErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get document status summary */
export const getDocumentStatusSummaryEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getDocumentStatusSummary.request)),
    switchMap(() =>
      wsAdapter.request(getDocumentStatus({})).pipe(
        mapGuard(getDocumentStatusResponse),
        map(actions.getDocumentStatusSummary.success),
        catchError((err) =>
          of(
            actions.getDocumentStatusSummary.failure(
              createFailurePayload(err, getDocumentStatusErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
