import { Alignement } from '@gaming1/g1-cms';
import { ButtonType } from '@gaming1/g1-ui';

type JustifyContent = 'flex-start' | 'flex-end' | 'center';

/**
 * Convert prismic alignment values to justify-content values.
 */
export const getFlexJustifyContentFromAlignment = (
  alignment: Alignement,
): JustifyContent => {
  switch (alignment?.toLowerCase()) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

/**
 * Convert prismic color to ButtonType
 */
export const getButtonColorType = (type: string): ButtonType => {
  switch (type) {
    case 'primary':
      return 'primary';
    case 'primary negative':
      return 'secondary';
    default:
      return 'tertiary';
  }
};
