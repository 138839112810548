import React, { ReactNode } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';

import { HeaderText, ProfileInfoBaseWrapper, WrapperHeader } from './styles';

export type ProfileInfoBaseProps = {
  /** Profile icon component  */
  profileIcon: ReactNode;
  /** Player rank info displayed below the username */
  subtext?: ReactNode;
  /** User first name */
  firstName: string | undefined;
};

/**
 * This component renders the common parts of the profileInfo component
 */

export const ProfileInfoBase = ({
  profileIcon,
  subtext,
  firstName,
}: ProfileInfoBaseProps) => {
  const { t } = useTranslation('user');

  return (
    <ProfileInfoBaseWrapper>
      {profileIcon}
      <WrapperHeader ml="sm" pl="sm">
        {firstName && (
          <HeaderText>
            {t('profile.headerTitle', { name: firstName })}
          </HeaderText>
        )}
        {subtext}
      </WrapperHeader>
    </ProfileInfoBaseWrapper>
  );
};
