import React, { useContext, VFC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { ConfigContext } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-core';

import { buildHeadTags } from './buildHeadTags';
import { LanguageAlternate } from './types';

export type SeoProps = {
  description: string | null;
  image?: string;
  title: string | null;
};

/** Add multiple seo-srelated <head> tags */
export const Seo: VFC<SeoProps> = ({ title, description, image }) => {
  const { pathname, search } = useLocation();

  // Get the current language
  const { currentLocale, values } = useI18n();

  // Get the available languages and the siteUrl from the config
  const {
    i18n: { availableLanguages, defaultLanguage },
    network: { siteUrl },
    seo: { defaultImage, twitterCard, twitterSite },
  } = useContext(ConfigContext);

  const generateCurrentHrefByLang = (lang: string, includeSearch = true) =>
    `${siteUrl}/${lang}${pathname.substr(3)}${includeSearch ? search : ''}`;

  const areThereMultipleLanguagesAvailable = availableLanguages.length > 1;

  // Generate the alternate language links props (should not be set when only
  // one language is available)
  const languageAlternates: ReadonlyArray<LanguageAlternate> =
    areThereMultipleLanguagesAvailable
      ? availableLanguages.map((lang) => ({
          href: generateCurrentHrefByLang(lang),
          hrefLang: lang,
        }))
      : [];

  const commonDescription = description !== null ? description : undefined;

  return (
    <Helmet htmlAttributes={{ lang: currentLocale }}>
      {buildHeadTags({
        title: title === null ? values.siteName : title,
        description: commonDescription,
        languageAlternates,
        defaultLanguageHref: areThereMultipleLanguagesAvailable
          ? generateCurrentHrefByLang(defaultLanguage)
          : undefined,
        openGraph: {
          type: 'website',
          locale: currentLocale,
          description: commonDescription,
          images: [{ url: image || defaultImage }],
        },
        twitter: {
          cardType: twitterCard,
          site: twitterSite,
        },
        canonical: generateCurrentHrefByLang(currentLocale, false),
      })}
    </Helmet>
  );
};
