import * as t from 'io-ts';

import {
  EPlayerAcceptTermsAndConditionsResponseStatus,
  ETermsAndConditionsResponseStatus,
} from '@gaming1/g1-requests';

export const acceptTermsAndConditionsResponse = t.type(
  {
    Status: t.literal(EPlayerAcceptTermsAndConditionsResponseStatus.Successful),
  },
  'AcceptTermsAndConditions',
);

export const showTermsAndConditionsResponse = t.type(
  {
    ShowPopup: t.boolean,
  },
  'ShowTermsAndConditions',
);

export const getTermsAndConditionsResponse = t.type(
  {
    Status: t.literal(ETermsAndConditionsResponseStatus.Successful),
    File: t.string,
  },
  'GetTermsAndConditions',
);
