/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:04:26 11-02-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  ChangeSecurityPinCodeRequestDTO,
  ChangeSecurityPinCodeResponseDTO,
  GetSecurityPinCodeInfoRequestDTO,
  GetSecurityPinCodeInfoResponseDTO,
  ResetSecurityPinCodeRequestDTO,
  ResetSecurityPinCodeResponseDTO,
} from '../entities';

export const getSecurityPinCodeInfo = (
  options: GetSecurityPinCodeInfoRequestDTO,
) =>
  createRequest<GetSecurityPinCodeInfoResponseDTO>(
    100,
    'GetSecurityPinCodeInfo',
    options,
  );

export const resetSecurityPinCode = (options: ResetSecurityPinCodeRequestDTO) =>
  createRequest<ResetSecurityPinCodeResponseDTO>(
    100,
    'ResetSecurityPinCode',
    options,
  );

export const changeSecurityPinCode = (
  options: ChangeSecurityPinCodeRequestDTO,
) =>
  createRequest<ChangeSecurityPinCodeResponseDTO>(
    100,
    'ChangeSecurityPinCode',
    options,
  );
