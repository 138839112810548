import { freebetCodec } from '../codecs';
import { FreebetType, GroupedFreebets, LockedFreebetType } from '../types';

const getEarlierDate = (gp: GroupedFreebets, fb: FreebetType) => {
  if (!gp.expirationDate) {
    return fb.ExpirationDate;
  }
  return gp.expirationDate < fb.ExpirationDate
    ? gp.expirationDate
    : fb.ExpirationDate;
};
export const groupFreebets = (
  list: FreebetType[] | undefined,
): GroupedFreebets[] => {
  if (!list) {
    return [];
  }
  return list.reduce((groups: GroupedFreebets[], fb) => {
    const group = groups.find((gr) => {
      if (gr.amount !== fb.Amount) {
        return false;
      }

      if (freebetCodec.is(gr.data)) {
        return gr.data.ConditionId === fb.ConditionId;
      }

      return true;
    });

    if (!group) {
      return [
        ...groups,
        {
          nb: 1,
          amount: fb.Amount,
          expirationDate: fb.ExpirationDate,
          data: fb,
        },
      ];
    }

    return groups
      .map((gp) =>
        gp === group
          ? {
              ...gp,
              ...{ nb: gp.nb + 1, expirationDate: getEarlierDate(gp, fb) },
            }
          : gp,
      )
      .sort((a, b) => {
        if (!a.expirationDate) {
          return -1;
        }
        if (!b.expirationDate) {
          return 1;
        }
        if (a.expirationDate < b.expirationDate) {
          return -1;
        }
        if (a.expirationDate > b.expirationDate) {
          return 1;
        }

        return 0;
      });
  }, []);
};

export const groupLockedFreebets = (
  list: LockedFreebetType[] | undefined,
): GroupedFreebets[] => {
  if (!list) {
    return [];
  }
  return list
    .map((item) => ({
      nb: item.FreebetAvailableToUnlock,
      amount: item.Amount,
      expirationDate: undefined,
      data: item,
    }))
    .sort((a, b) => {
      if (!a.data.CreatedDate) {
        return 1;
      }
      if (!b.data.CreatedDate) {
        return -1;
      }
      if (a.data.CreatedDate < b.data.CreatedDate) {
        return -1;
      }
      if (a.data.CreatedDate > b.data.CreatedDate) {
        return 1;
      }

      return 0;
    });
};
