import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

import { colors, fontSizes, isColorDark, spaces } from '@gaming1/g1-style';
import { Select, SimpleButton } from '@gaming1/g1-ui';

export const OddsUnitsRadioLabel = styled.p`
  color: ${colors('muted')};
  font-size: ${fontSizes('sm')};
  padding: ${spaces('xs')} ${spaces('sm')};
`;

export const OddsUnitsRadioWrapper = styled.div`
  display: flex;
  padding: ${spaces('sm')};
  background-color: ${({ theme }) =>
    isColorDark(theme.colors.drawerNavBackground)
      ? lighten(0.05)(theme.colors.drawerNavBackground)
      : darken(0.05)(theme.colors.drawerNavBackground)};
`;

export const OddsUnitsRadioButton = styled(SimpleButton)<{ active: boolean }>`
  color: ${colors('drawerNavText')};
  font-size: ${fontSizes('md')};

  & + & {
    margin-left: ${spaces('sm')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors('drawerNavActiveText')};
    `}
`;

export const OddsUnitsSelectWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: ${fontSizes('md')};
  justify-content: flex-end;
  margin-right: ${spaces(4)};
`;

export const OddsUnitsSelect = styled(Select)`
  border: none;
  color: ${colors('scrollNavItemForegroundActive')};
  cursor: pointer;
  font-size: ${fontSizes('md')};

  &:focus {
    border-bottom: none;
    font-size: ${fontSizes('md')};
    padding-bottom: ${spaces('sm')};
  }

  option {
    background-color: ${colors('scrollNavBackground')};
    color: ${colors('scrollNavItemForegroundActive')};
    font-size: ${fontSizes('md')};
  }
`;
