import { createAsyncAction } from 'typesafe-actions';

import {
  GetDepositHistoryRequestDTO,
  GetWithdrawHistoryRequestDTO,
} from '@gaming1/g1-requests';
import { FailurePayload, PaginationList } from '@gaming1/g1-utils';

import { Deposit, Withdraw } from './types';

/** Get deposit history */
export const getDepositHistory = createAsyncAction(
  'payment/get_deposit_history_request',
  'payment/get_deposit_history_success',
  'payment/get_deposit_history_failure',
)<GetDepositHistoryRequestDTO, PaginationList<Deposit>, FailurePayload>();

/** Get withdrawal history */
export const getWithdrawalHistory = createAsyncAction(
  'payment/get_withdrawal_history_request',
  'payment/get_withdrawal_history_success',
  'payment/get_withdrawal_history_failure',
)<GetWithdrawHistoryRequestDTO, PaginationList<Withdraw>, FailurePayload>();
