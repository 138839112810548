/* istanbul ignore file */
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

/*
 * Has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
 * typed as it, otherwise this break the RouteParams mapped type !
 */
export const inboxRoutes = {
  // MUST start with /profile to be integrated into the user profile
  messageList: createRoute('/profile/messages'),
  // MUST start with /profile to be integrated into the user profile
  messageDetail: createParamRoute('/profile/messages/detail/:messageId', {
    messageId: '',
  }),
};
