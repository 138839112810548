import { MAX_NUMBER_OF_ELEMENTS_IN_SYSTEM_BETTINGSLIP } from '../const';
import { BettingSlipItemState } from '../store/types/common';

import { areItemsValid, ItemValid } from './elements/item';

/**
 * Check if there are more items than the {count} parameter.
 * @param items the array of items from the bettingSlip
 * @param count the maximum of items accepted in the bettingSlip
 */
export const helperBettingSlipIsTooMuch = (
  items: BettingSlipItemState[] | undefined,
  count: number,
) => {
  if (!items || items.length === 0 || items.length <= count) {
    return false;
  }

  return true;
};

/**
 * Check if there are less items than the {count} parameter.
 * @param items the array of items from the bettingSlip
 * @param count the maximum of items accepted in the bettingSlip
 */
export const helperBettingSlipIsEnough = (
  items: unknown[] | undefined,
  count: number,
) => {
  if (!items || items.length === 0 || items.length < count) {
    return false;
  }

  return true;
};

/**
 * Check if the items given are valid ones.
 * @param items
 */
export const helperBettingSlipIsValid = (items: ItemValid[] | undefined) =>
  !items ? false : areItemsValid(items);

/**
 * Check if the array of items given has not a length greater than the maximum
 * of elements accepted in the system bettingSlip.
 *
 * See the constant {MAX_NUMBER_OF_ELEMENTS_IN_SYSTEM_BETTINGSLIP} for the maximum.
 *
 * @param items the array of items that are in the system bettingSlip.
 */
export const hasSystemTooManyElements = (
  items: {
    outcomeId: number;
    marketId: number;
    eventId: number;
  }[],
) => items.length > MAX_NUMBER_OF_ELEMENTS_IN_SYSTEM_BETTINGSLIP;
