import {
  EPlayerAcceptPrivacyConditionsResponseStatus,
  EPrivacyConditionsResponseStatus,
  EShowPrivacyConditions,
} from '@gaming1/g1-requests';

export const acceptPrivacyConditionsErrorMessages = {
  [EPlayerAcceptPrivacyConditionsResponseStatus.InternalError]:
    'core:error.internalServer', // 0
};

export const getPrivacyConditionsErrorMessages = {
  [EPrivacyConditionsResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EPrivacyConditionsResponseStatus.FileNotFound]:
    'user:privacyConditions.error.fileNotFound', // 2
};

export const showPrivacyConditionsErrorMessages = {
  [EShowPrivacyConditions.None]: '', // 0
  [EShowPrivacyConditions.InternalError]: 'core:error.internalServer', // 2
  [EShowPrivacyConditions.PlayerNotFound]: 'core:error.invalidPlayer', // 3
};
