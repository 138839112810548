import React, { FC } from 'react';

import { cmsRoutes } from '@gaming1/g1-cms-web';
import {
  LastConnection,
  MenuGroups,
  TopBar,
  useRoutePath,
  useZendeskUrl,
} from '@gaming1/g1-core-web';

export const AppTopBar: FC = () => {
  const getCmsPath = useRoutePath(cmsRoutes);
  const zendeskUrl = useZendeskUrl();
  const topBarMenuItems: MenuGroups = [
    [
      {
        label: 'menu.blog',
        path: getCmsPath('blog'),
        testId: 'topbar-blog',
        icon: 'World',
      },
      {
        label: 'menu.about',
        path: getCmsPath('aboutUs', { content: '' }),
        testId: 'topbar-about',
        icon: 'Brandtag',
      },
      {
        label: 'menu.help',
        path: zendeskUrl,
        externalLink: true,
        testId: 'topbar-help',
        icon: 'CircleQuestion',
      },
    ],
  ];

  return (
    <TopBar>
      <TopBar.Clock />
      <LastConnection />
      <TopBar.Separator />
      <TopBar.Menu menu={topBarMenuItems} />
      <TopBar.LocaleSwitcher />
    </TopBar>
  );
};
