import React, { VFC } from 'react';

import {
  eventUrlStatsSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { Icon } from '@gaming1/g1-ui';

import { ScoreBoardStatsAppAnchor } from './styles';

const SCOREBOARD_STATS_ICON_SIZE_IN_PX = 18;

export type ScoreBoardStatsProps = {
  /** Event id that will be used to retrieve the statistics url */
  eventId: number;
};

/** Displays the event statistics icon with an anchor */
export const ScoreBoardStats: VFC<ScoreBoardStatsProps> = ({ eventId }) => {
  const config = useConfig();
  const { enabled } = config.betting.stats;

  const urlStats = useBettingGetterSelector({
    getterSelector: eventUrlStatsSelector,
    args: [eventId],
  });

  return enabled && urlStats ? (
    <ScoreBoardStatsAppAnchor
      data-testid="score-board-stats"
      href={urlStats}
      rel="noopener"
      target="_blank"
    >
      <Icon
        id="score-board-stats"
        type="Stats"
        height={`${SCOREBOARD_STATS_ICON_SIZE_IN_PX}px`}
        width={`${SCOREBOARD_STATS_ICON_SIZE_IN_PX}px`}
      />
    </ScoreBoardStatsAppAnchor>
  ) : null;
};
