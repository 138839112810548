import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateFailureRequestState,
  generateInitialRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
} from '@gaming1/g1-utils';

import { CmsActions } from '../../store/types';

import * as actions from './actions';
import { PrismicNavigationsState } from './types';

export const initialState: PrismicNavigationsState = {
  request: generateInitialRequestState([]),
  navigations: {},
};

export const prismicNavigationsReducer = (
  state: PrismicNavigationsState = initialState,
  action: CmsActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchNavigations.request):
        draftState.request = generateLoadingRequestState(
          state.request?.result || [],
        );
        break;

      case getType(actions.fetchNavigations.failure):
        draftState.request = generateFailureRequestState(
          action.payload,
          state.request?.result || [],
        );
        break;

      case getType(actions.fetchNavigations.success):
        draftState.navigations = action.payload.results.reduce(
          (navs, { data }) => ({ ...navs, [data.text_identifier]: data }),
          {},
        );

        draftState.request = generateSuccessRequestState(
          state.request?.result || [],
        );
        break;

      default: // Immer will automatically returns the state
    }
  });
