import { createSelector } from 'reselect';

import { ElementInfo } from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { eventSelector } from '../../../sportbook/store/selectors/events';
import { formatPreparedItem } from '../../helpers/common';

import {
  bettingSlipItemBaseOddGetterSelector,
  bettingSlipItemGetterSelector,
  bettingSlipItemProviderGetterSelector,
} from './elements/bettingslipItem';

export const prepareItemGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  bettingSlipItemBaseOddGetterSelector,
  bettingSlipItemProviderGetterSelector,
  eventSelector,
  (
      bettingSlipItem,
      bettingSlipItemOdd,
      bettingSlipItemTradingProvider,
      getEvent,
    ) =>
    (bettingSlipId: BettingSlipIdentifierType, itemId: number): ElementInfo => {
      const baseOdd = bettingSlipItemOdd(bettingSlipId, itemId);

      const finalOdd = bettingSlipItemOdd(bettingSlipId, itemId);

      const item = bettingSlipItem(bettingSlipId, itemId);

      const eventType = getEvent(item?.event.id || 0)?.EventType;

      const provider = bettingSlipItemTradingProvider(
        bettingSlipId,
        item?.id || 0,
      );

      const preparedItem = formatPreparedItem({
        baseOdd,
        finalOdd,
        item,
        eventType,
        provider,
      });

      return preparedItem;
    },
);
