import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import { PrismicConditionItem } from '@gaming1/g1-cms';
import { UserProfile } from '@gaming1/g1-core';
import { MediaBreakPointNames } from '@gaming1/g1-style';
import {
  isMediaBreakPointAboveOrEqual,
  isMediaBreakPointBelowOrEqual,
} from '@gaming1/g1-ui';

export type ConditionOptions = {
  conditionData: PrismicConditionItem;
  isUserLoggedIn: boolean | null;
  mediaBP: MediaBreakPointNames;
  now: Date;
  userProfile: Pick<UserProfile, 'HasAlreadyDeposited'> | null;
};

export const evaluateCondition = ({
  conditionData,
  isUserLoggedIn,
  mediaBP,
  now,
  userProfile,
}: ConditionOptions): boolean | null => {
  switch (conditionData.condition) {
    case 'USER_IS_CONNECTED':
      return isUserLoggedIn;
    case 'USER_IS_NOT_CONNECTED':
      return isUserLoggedIn === null ? null : !isUserLoggedIn;
    case 'BEFORE_DATE':
      if (conditionData.datetime === null) {
        return false;
      }
      return isBefore(now, new Date(conditionData.datetime));
    case 'AFTER_DATE':
      if (conditionData.datetime === null) {
        return false;
      }
      return isAfter(now, new Date(conditionData.datetime));
    case 'SCREEN_IS_SMALL':
      return isMediaBreakPointBelowOrEqual(mediaBP, 'sm');
    case 'SCREEN_IS_SMALL_OR_MEDIUM':
      return isMediaBreakPointBelowOrEqual(mediaBP, 'md');
    case 'SCREEN_IS_MEDIUM':
      return mediaBP === 'md';
    case 'SCREEN_IS_MEDIUM_OR_LARGE':
      return isMediaBreakPointAboveOrEqual(mediaBP, 'md');
    case 'SCREEN_IS_LARGE':
      return isMediaBreakPointAboveOrEqual(mediaBP, 'lg');
    case 'SCREEN_IS_SMALL_OR_LARGE':
      return (
        isMediaBreakPointBelowOrEqual(mediaBP, 'sm') ||
        isMediaBreakPointAboveOrEqual(mediaBP, 'lg')
      );
    case 'USER_IS_DEPOSITOR':
      if (!isUserLoggedIn || userProfile === null) {
        return null;
      }
      return userProfile.HasAlreadyDeposited;
    case 'USER_IS_NOT_DEPOSITOR':
      if (!isUserLoggedIn || userProfile === null) {
        return null;
      }
      return !userProfile.HasAlreadyDeposited;
    default:
      return null;
  }
};
