import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  RemoteData,
} from '@gaming1/g1-utils';

import * as sportbookActions from '../sportbook/store/actions';
import { BettingActions } from '../store/types';

import * as actions from './actions';
import { slugifyScoreBoardPayload } from './helpers';
import { ScoreState } from './types';

export const initialState: ScoreState = {
  entities: {
    scoreBoards: {},
  },
  requests: {
    getScoreBoard: {},
    getAllScoreBoards: generateInitialRequestState(null),
  },
};

export const scoreReducer = (
  state: ScoreState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getScoreBoard.request): {
        const slug = slugifyScoreBoardPayload(action.payload);
        draftState.requests.getScoreBoard[slug] = {
          ...generateInitialRequestState(),
          ...state.requests.getScoreBoard[slug],
          errorCode: undefined,
          errorMessage: undefined,
          status: RemoteData.Loading,
        };
        break;
      }

      case getType(actions.getScoreBoard.failure): {
        const slug = slugifyScoreBoardPayload(action.payload.request);
        draftState.requests.getScoreBoard[slug] = {
          ...generateInitialRequestState(),
          ...state.requests.getScoreBoard[slug],
          errorCode: action.payload.data.status,
          errorMessage: action.payload.data.errorMessage,
          status: RemoteData.Error,
        };
        break;
      }

      case getType(actions.getScoreBoard.success): {
        const slug = slugifyScoreBoardPayload(action.payload.request);
        draftState.requests.getScoreBoard[slug] = {
          ...generateInitialRequestState(),
          ...state.requests.getScoreBoard[slug],
          errorCode: undefined,
          errorMessage: undefined,
          status: RemoteData.Success,
        };
        draftState.entities.scoreBoards = {
          ...draftState.entities.scoreBoards,
          ...action.payload.data,
        };
        break;
      }

      case getType(actions.getScoreBoards.request): {
        action.payload.eventIds.forEach((eventId) => {
          const slug = slugifyScoreBoardPayload({ eventId });
          draftState.requests.getScoreBoard[slug] = {
            ...generateInitialRequestState(),
            ...state.requests.getScoreBoard[slug],
            errorCode: undefined,
            errorMessage: undefined,
            status: RemoteData.Loading,
          };
        });
        break;
      }

      case getType(actions.getScoreBoards.failure): {
        action.payload.request.eventIds.forEach((eventId) => {
          const slug = slugifyScoreBoardPayload({ eventId });
          draftState.requests.getScoreBoard[slug] = {
            ...generateInitialRequestState(),
            ...state.requests.getScoreBoard[slug],
            errorCode: action.payload.data.status,
            errorMessage: action.payload.data.errorMessage,
            status: RemoteData.Error,
          };
        });
        break;
      }

      case getType(actions.getScoreBoards.success): {
        action.payload.request.eventIds.forEach((eventId) => {
          const slug = slugifyScoreBoardPayload({ eventId });
          draftState.requests.getScoreBoard[slug] = {
            ...generateInitialRequestState(),
            ...state.requests.getScoreBoard[slug],
            errorCode: undefined,
            errorMessage: undefined,
            status: RemoteData.Success,
          };
        });
        draftState.entities.scoreBoards = {
          ...draftState.entities.scoreBoards,
          ...action.payload.data.scoreBoards,
        };
        break;
      }

      case getType(actions.getAllScoreBoards.request): {
        produceLoadingState(draftState.requests.getAllScoreBoards);
        break;
      }

      case getType(actions.getAllScoreBoards.failure): {
        produceFailureState(
          draftState.requests.getAllScoreBoards,
          action.payload,
        );
        break;
      }

      case getType(actions.getAllScoreBoards.success):
        produceSuccessState(draftState.requests.getAllScoreBoards);
        draftState.entities.scoreBoards = {
          ...draftState.entities.scoreBoards,
          ...action.payload.scoreBoards,
        };
        break;

      case getType(actions.updateScoreBoards): {
        draftState.entities.scoreBoards = {
          ...draftState.entities.scoreBoards,
          ...action.payload.scoreBoards,
        };
        break;
      }

      /** Light search */
      case getType(sportbookActions.lightSearch.success): {
        const scores =
          action.payload?.EventResult?.Events?.map(
            (le) => le?.ScoreBoard,
          ).reduce(
            (acc, val) => (val?.EventId ? { ...acc, [val.EventId]: val } : acc),
            {},
          ) || {};

        draftState.entities.scoreBoards = {
          ...draftState.entities.scoreBoards,
          ...scores,
        };

        break;
      }

      default: // Immer will automatically return the state
    }
  });
