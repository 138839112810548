import { useRoutePath } from '@gaming1/g1-core-web';
import { AppLink, Heading } from '@gaming1/g1-ui';

import { siteDefaultRoutes } from '../../routes';

export const LinksList = () => {
  const getSiteDefaultRoutePath = useRoutePath(siteDefaultRoutes);

  return (
    <>
      <Heading level="h1">Development links</Heading>
      <ul>
        <li>
          <AppLink to={getSiteDefaultRoutePath('layoutDemo')}>
            Layout demo
          </AppLink>
        </li>
        <li>
          <AppLink to={getSiteDefaultRoutePath('networkTesting')}>
            Network testing
          </AppLink>
        </li>
        <li>
          <AppLink to={getSiteDefaultRoutePath('notificationsAndMessages')}>
            Notification and messages
          </AppLink>
        </li>
        <li>
          <AppLink to={getSiteDefaultRoutePath('skeletonDemo')}>
            Skeleton demo
          </AppLink>
        </li>
        <li>
          <AppLink to={getSiteDefaultRoutePath('slidersDemo')}>
            Sliders demo
          </AppLink>
        </li>
        <li>
          <AppLink to={getSiteDefaultRoutePath('themeEditor')}>
            Theme editor
          </AppLink>
        </li>
      </ul>
    </>
  );
};
