import memoize from 'lodash/memoize';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

import { componentLeagueIdsSelector, leaguesListSelector } from './common';
import { leaguesSelector } from './entities';

export type Region = { regionId: number; regionName: string };

/**
 * Returns all regions (id/name) currently available for specified sportId
 * Don't forget to use shallowEqual with this selector
 */
export const allRegionsBySportSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  (getLeagueIds, getLeagues) =>
    memoize(
      (key: string, sportId: number): Region[] => {
        const leagues = getLeagues(getLeagueIds(key));

        return uniqBy(
          leagues
            .filter(
              (league) =>
                league?.SportId === sportId &&
                league.RegionId &&
                league.Events?.length,
            )
            .map((league) => ({
              regionId: league?.RegionId || 0,
              regionName: league?.RegionName || '',
            })),
          ({ regionId }) => regionId,
        );
      },
      // Cache key resolver
      (key: string, sportId: number) => `${key}${sportId}`,
    ),
);

/**
 * Returns the region name for specified sportId and regionId
 */
export const getRegionNameByIdSelector = createSelector(
  allRegionsBySportSelector,
  (getRegions) =>
    memoize(
      (key: string, sportId: number, regionId: number): string | undefined => {
        const regions: Region[] = getRegions(key, sportId);

        return regions.find((region) => region?.regionId === regionId)
          ?.regionName;
      },
      // Cache key resolver
      (key: string, sportId: number, regionId: number) =>
        `${key}${sportId}${regionId}`,
    ),
);

/**
 * Returns all region names present in the sportbook
 */
export const getAllRegionNamesSelector = createSelector(
  leaguesSelector,
  (leagues) =>
    memoize((): { [regionId: string]: string } =>
      Object.values(leagues).reduce(
        (acc, value) => ({
          ...acc,
          [`${value.SportId}-${value.RegionId}`]: value.RegionName,
        }),
        {},
      ),
    ),
);

/**
 * Returns a region names from the sportbook
 */
export const getRegionNameSelector = createSelector(
  getAllRegionNamesSelector,
  (getAllRegions) =>
    memoize(
      (sportId: number, regionId: number): string =>
        getAllRegions()[`${sportId}-${regionId}`],

      // Cache key resolver
      (sportId: number, regionId: number) => `${sportId}${regionId}`,
    ),
);
