import * as t from 'io-ts';

import { createEnumType } from '@gaming1/g1-utils';

import { GeolocationReason } from '../geolocation/constants';

import { interopMessages, TrackingEventKind } from './constants';

/* Codecs */

/** Generic codec for the messages sent/received from parent to iframe */
export const iframeMessageCodec = t.intersection(
  [
    t.type({
      type: t.string,
    }),
    t.partial({
      payload: t.unknown,
    }),
  ],
  'IframeMessage',
);

export const historyActionCodec = t.union([
  t.literal('PUSH'),
  t.literal('REPLACE'),
  t.literal('POP'),
]);

export const locationChangedMessageCodec = t.type(
  {
    type: t.literal(interopMessages.locationChanged),
    payload: t.intersection([
      t.type({
        action: historyActionCodec,
        pathname: t.string,
      }),
      t.partial({
        search: t.string,
      }),
    ]),
  },
  'LocationChangedMessage',
);

export const navigateMessageCodec = t.type(
  {
    type: t.literal(interopMessages.navigate),
    payload: t.type({
      method: t.union([t.literal('push'), t.literal('replace')]),
      path: t.string,
    }),
  },
  'NavigateMessage',
);

export const exitFullScreenLocationMessageCodec = t.type(
  {
    type: t.literal(interopMessages.exitFullscreenLocation),
  },
  'exitFullScreenLocationMessageCodec',
);

export const blacklistedRoutesMessageCodec = t.type(
  {
    type: t.literal(interopMessages.blacklistedRoutes),
    payload: t.array(t.string),
  },
  'blackListedRouteMessage',
);

export const geolocationMessageCodec = t.type(
  {
    type: t.literal(interopMessages.geocomplyStatus),
    payload: t.type({
      isValid: t.boolean,
    }),
  },
  'GeolocationMessage',
);

export const geolocationRequestParamsCodec = t.intersection(
  [
    t.type({
      reason: createEnumType<GeolocationReason>(GeolocationReason),
      userId: t.string,
      license: t.string,
    }),
    t.partial({
      businessSessionId: t.string,
    }),
  ],
  'GeolocationRequestParams',
);

export const iframeGeolocationRequestMessageCodec = t.type(
  {
    type: t.literal(interopMessages.geolocationRequest),
    payload: geolocationRequestParamsCodec,
  },
  'IframeGeolocationRequestMessage',
);

export const gtmTrackingEventCodec = t.intersection(
  [
    t.record(t.string, t.string),
    t.type({
      category: t.string,
      action: t.string,
    }),
    t.partial({
      label: t.string,
      value: t.number,
    }),
  ],
  'GTMTrackingEvent',
);

export const rawTrackingEventCodec = t.record(
  t.string,
  t.union([t.string, t.number]),
  'RawTrackingEvent',
);

export const trackingEventMessageCodec = t.type(
  {
    type: t.literal(interopMessages.trackingEvent),
    payload: t.union([
      t.type({
        kind: t.literal(TrackingEventKind.RawData),
        rawData: rawTrackingEventCodec,
      }),
      t.type({
        kind: t.literal(TrackingEventKind.Event),
        event: gtmTrackingEventCodec,
      }),
    ]),
  },
  'TrackingEventMessage',
);

export const navigationDrawerStateChangeMessageCodec = t.type(
  {
    type: t.literal(interopMessages.navigationDrawerStateChange),
    payload: t.type({
      open: t.boolean,
    }),
  },
  'NavigationDrawerStateChangeMessage',
);

export const reduxActionTwinningMessageCodec = t.type(
  {
    type: t.literal(interopMessages.reduxActionDispatched),
    payload: t.intersection([
      t.type({
        type: t.string,
      }),
      t.partial({
        payload: t.unknown,
        meta: t.type({
          dispatcherId: t.string,
        }),
      }),
    ]),
  },
  'ReduxActionTwinningMessage',
);

export const openBrowserMessageCodec = t.type(
  {
    type: t.literal(interopMessages.openBrowser),
    payload: t.type({
      url: t.string,
    }),
  },
  'OpenBrowserMessage',
);

export const openExternalLinkViewMessageCodec = t.type(
  {
    type: t.literal(interopMessages.openExternalLinkView),
    payload: t.intersection([
      t.type({
        url: t.string,
      }),
      t.partial({
        method: t.union([t.literal('GET'), t.literal('POST')]),
        headers: t.record(t.string, t.string),
        body: t.record(t.string, t.string),
        title: t.union([t.string, t.null]),
      }),
    ]),
  },
  'OpenExternalLinkViewMessage',
);

export const openAppSettingsMessageCodec = t.type(
  {
    type: t.literal(interopMessages.openAppSettings),
  },
  'OpenAppSettingsMessage',
);

export const localeChangedMessageCodec = t.type(
  {
    type: t.literal(interopMessages.localeChanged),
    payload: t.type({
      locale: t.string,
    }),
  },
  'LocaleChangedMessage',
);

// Request type packages/g1-network/src/types.ts
const requestCodec = t.type(
  {
    Id: t.string,
    Type: t.number,
    Identifier: t.string,
    Content: t.string,
  },
  'Request',
);

export const networkRequestMessageCodec = t.type(
  {
    type: t.literal(interopMessages.networkRequest),
    payload: requestCodec,
  },
  'NetworkRequestMessage',
);

export const networkResponseMessageCodec = t.type(
  {
    type: t.literal(interopMessages.networkResponse),
    payload: t.type({
      requestId: t.string,
      content: t.unknown,
    }),
  },
  'NetworkReponseMessage',
);

export const changeConnectionTypeMessageCodec = t.type(
  {
    type: t.literal(interopMessages.changeConnectionType),
    payload: t.type({
      connectionType: t.string,
      previousConnectionType: t.union([t.string, t.null]),
    }),
  },
  'changeConnectionTypeMessageCodec',
);

export const switchConfigMessageCodec = t.type(
  {
    type: t.literal(interopMessages.switchConfig),
    payload: t.type({
      configName: t.string,
      config: t.unknown,
    }),
  },
  'changeConnectionTypeMessageCodec',
);
