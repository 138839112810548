import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { Button, Col, Label, Message, Row } from '@gaming1/g1-ui';

import { TextInputField } from '../../../form/components/TextInputField';
import { logger } from '../../../logger';

import { AppFieldset, AppLegend } from './styles';
import { TextAreaInputField } from './TextareaInputField';

type DispatcherForm = {
  type: string;
  payload: string;
};

export const ActionsDispatcherForm = () => {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');
  const handleFormSubmit = (formData: DispatcherForm) => {
    try {
      const parsedPayload = formData.payload
        ? JSON.parse(formData.payload)
        : undefined;
      setFormError('');
      dispatch({ type: formData.type, payload: parsedPayload });
    } catch (e) {
      logger.error('[Debug panel] Could not parse action payload', e);
      setFormError(
        'Could not parse payload. Ensure you are inputing a valid json object (that means every property should be wrapped into quotes)',
      );
    }
  };
  return (
    <Form<DispatcherForm>
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, form }) => (
        <AppFieldset>
          <AppLegend>Actions dispatcher</AppLegend>
          {!!formError && (
            <Message mb="sm" type="danger">
              {formError}
            </Message>
          )}
          <Row>
            <Col md={8}>
              <Label htmlFor="payload">Payload</Label>
              <TextAreaInputField name="payload" />
            </Col>
            <Col md={4}>
              <TextInputField
                name="type"
                autoHideLabel={false}
                label="Type"
                required
                wrapperProps={{ mt: 0 }}
              />
              <Row>
                <Col sm={6}>
                  <Button
                    type="tertiary"
                    onClick={() => form.restart()}
                    mt="sm"
                  >
                    Clear
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button type="submit" onClick={handleSubmit} mt="sm">
                    Dispatch
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </AppFieldset>
      )}
    />
  );
};
