import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { ItemValid } from '../../../helpers/elements/item';
import { bettingSlipItemsGetterSelector } from '../common';

import { bettingSlipItemForValidationGetterSelector } from './bettingslipItem';

export const bettingSlipItemsForValidationGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  bettingSlipItemForValidationGetterSelector,
  (getBettingSlipItems, getItemForValidation) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType): ItemValid[] | undefined =>
        getBettingSlipItems(bettingSlipId)
          ?.filter(isNonNullable)
          .map((item) => getItemForValidation(bettingSlipId, item.id)),
    ),
);
