import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { THROTTLE_TIME_IN_MS } from '@gaming1/g1-core';
import { getLegalLimit, setLegalLimit } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  getLegalDepositLimitResponse,
  setLegalDepositLimitResponse,
} from './codecs';
import {
  getLegalDepositLimitErrorMessages,
  setLegalDepositLimitErrorMessages,
} from './errorMessages';

/** Trigger a legal deposit limit change request from user input */
export const setLegalDepositLimitRequestEpic: UserEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.clickUserLegalDepositLimit)),
    throttleTime(THROTTLE_TIME_IN_MS),
    map(({ payload }) => actions.setLegalDepositLimit.request(payload)),
  );

export const getLegalDepositLimitEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getLegalDepositLimit.request)),
    switchMap(() =>
      wsAdapter.request(getLegalLimit({})).pipe(
        mapGuard(getLegalDepositLimitResponse),
        map(actions.getLegalDepositLimit.success),
        catchError((err) =>
          of(
            actions.getLegalDepositLimit.failure(
              createFailurePayload(err, getLegalDepositLimitErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

export const setLegalDepositLimitEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.setLegalDepositLimit.request)),
    mergeMap(({ payload }) =>
      wsAdapter
        .request(setLegalLimit(payload))
        .pipe(
          mapGuard(setLegalDepositLimitResponse),
          map(actions.setLegalDepositLimit.success),
        ),
    ),
    catchError((err) =>
      of(
        actions.setLegalDepositLimit.failure(
          createFailurePayload(err, setLegalDepositLimitErrorMessages),
        ),
      ),
    ),
  );
export const successSetLegalDepositLimitToGetLegalDepositLimitEpic: UserEpic = (
  action$,
) =>
  action$.pipe(
    filter(isActionOf(actions.setLegalDepositLimit.success)),
    map(() => actions.getLegalDepositLimit.request()),
  );
