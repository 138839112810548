import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { colors, spaces } from '@gaming1/g1-style';

import { MessageType } from '../Message/types';
import { SimpleButton } from '../styles';

type BannerContainerProps = {
  type: MessageType;
};

export const BannerContainer = styled.div<BannerContainerProps>`
  ${({ type }) =>
    type &&
    css`
      background-color: ${colors(type)};
    `}

  padding: ${spaces('md')};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${space}
`;

export const BannerIconWrapper = styled.div`
  margin-right: ${spaces('md')};
  line-height: 0;
`;

export const BannerMessage = styled.span`
  margin-right: ${spaces('xxl')};
`;

export const BannerAppLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text};
`;

export const BannerCloseButton = styled(SimpleButton)`
  margin-left: auto;
  line-height: 0;
`;
