import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import { getType } from 'typesafe-actions';

import { actions as coreActions, CoreActions } from '@gaming1/g1-core';
import {
  generateFailureRequestState,
  generateInitialRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
  RemoteData,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { Component, GrouppedSCMs, SportbookState } from '../types';

/** Add new SCM groups to the end (to keep previously selected at the same place) */
const addSCMToEnd = (
  oldSCMs: GrouppedSCMs,
  newSCMs: GrouppedSCMs,
): GrouppedSCMs => {
  if ((!oldSCMs && !newSCMs) || (oldSCMs && !newSCMs)) {
    return {};
  }
  if (!oldSCMs && newSCMs) {
    return newSCMs;
  }

  return Object.keys(newSCMs).reduce<GrouppedSCMs>((acc, key): GrouppedSCMs => {
    const oldGroups = oldSCMs[+key] || [];
    const oldSCMNames = oldGroups.map((scm) => scm?.name);

    const curGroups = newSCMs[+key] || [];
    const newGroups =
      curGroups?.filter((scm) => !oldSCMNames.includes(scm?.name)) || [];

    return { ...acc, [+key]: [...oldGroups, ...newGroups] };
  }, {});
};

const initComponent = (): Component => ({
  request: {
    errorCode: undefined,
    errorMessage: undefined,
    status: RemoteData.NotAsked,
  },
  leagueIds: null,
  eventIds: null,
  selectedSCMGroups: { live: {}, prematch: {} },
});

const componentsInitialState: SportbookState['components'] = {};

export const componentsReducer = (
  state = componentsInitialState,
  action: BettingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getEvent.request):
      case getType(actions.getLives.request):
      case getType(actions.getPrematchSport.request):
      case getType(actions.getLiveSport.request):
      case getType(actions.getLeague.request):
      case getType(actions.getTopEvents.request):
      case getType(actions.fullSearch.request):
      case getType(actions.getRegion.request): {
        const { key } = action.payload;

        // If refresh is true, do not change the state of the request
        const refresh = 'refresh' in action.payload;
        if (refresh && draftState[key]) {
          break;
        }

        const componentState = draftState[key] || initComponent();
        componentState.request = generateLoadingRequestState();
        componentState.leagueIds = null;
        componentState.eventIds = null;
        draftState[key] = componentState;
        break;
      }

      // Requests that starts with leagues
      case getType(actions.getLives.success):
      case getType(actions.getLiveSport.success):
      case getType(actions.getPrematchSport.success):
      case getType(actions.getLeague.success):
      case getType(actions.getRegion.success):
      case getType(actions.getEvent.success): {
        const { key } = action.payload;

        const { leagues = {} } = action.payload.entities;
        const leagueIds = Object.values(leagues).map((e) => e.LeagueId);

        const componentState = draftState[key] || initComponent();
        componentState.request = generateSuccessRequestState();
        componentState.leagueIds = leagueIds;
        componentState.eventIds = null;
        draftState[key] = componentState;
        break;
      }

      // Requests that starts with events
      case getType(actions.fullSearch.success):
      case getType(actions.getTopEvents.success): {
        const { events = {} } = action.payload.entities;
        const { key } = action.payload;

        const eventIds = Object.values(events).map((e) => e.EventId);
        const componentState = draftState[key] || initComponent();
        componentState.request = generateSuccessRequestState();
        componentState.eventIds = eventIds;
        componentState.leagueIds = null;
        draftState[key] = componentState;
        break;
      }

      case getType(actions.getEvent.failure):
      case getType(actions.getLives.failure):
      case getType(actions.getPrematchSport.failure):
      case getType(actions.getLiveSport.failure):
      case getType(actions.getLeague.failure):
      case getType(actions.getTopEvents.failure):
      case getType(actions.fullSearch.failure):
      case getType(actions.getRegion.failure): {
        const { key } = action.payload;
        const componentState = draftState[key] || initComponent();
        componentState.request = generateFailureRequestState(action.payload);
        componentState.leagueIds = null;
        componentState.eventIds = null;
        draftState[key] = componentState;
        break;
      }

      case getType(actions.cleanSportbookComponent):
      case getType(actions.getLives.cancel):
      case getType(actions.getLeague.cancel):
      case getType(actions.getRegion.cancel): {
        const { key } = action.payload;
        const componentState = draftState[key] || initComponent();
        componentState.request = generateInitialRequestState();
        draftState[key] = componentState;
        break;
      }

      case getType(actions.addLeague): {
        const {
          key,
          entities: { leagues },
        } = action.payload;
        const league = Object.values(leagues)[0];
        const componentState = draftState[key];

        if (
          league &&
          componentState &&
          !!componentState?.leagueIds &&
          !componentState?.leagueIds?.includes(league.LeagueId)
        ) {
          componentState.leagueIds?.push(league.LeagueId);
        }

        break;
      }
      case getType(actions.addSCMInComponent): {
        const { key, type, scmGroups } = action.payload;

        const componentState: Component = draftState[key] || initComponent();

        const oldGroups = componentState.selectedSCMGroups[type];
        const newGroups = addSCMToEnd(oldGroups, scmGroups);

        if (!isEqual(oldGroups, newGroups)) {
          componentState.selectedSCMGroups[type] = newGroups;
          draftState[key] = componentState;
        }

        break;
      }
      case getType(actions.updateSCMInComponent): {
        const {
          key,
          id: leagueId,
          type,
          oldIndex: index1,
          newIndex: index2,
        } = action.payload;

        const componentState = draftState[key];

        if (componentState && !!componentState?.selectedSCMGroups) {
          const selectedSCMG =
            (componentState.selectedSCMGroups[type] || {})[leagueId] || [];
          const tempValue = { ...selectedSCMG[index1] };

          selectedSCMG[index1] = { ...selectedSCMG[index2] };
          selectedSCMG[index2] = tempValue;
          componentState.selectedSCMGroups[type][leagueId] = [...selectedSCMG];
        }

        break;
      }

      case getType(actions.addEvent): {
        const {
          key,
          entities: { events },
        } = action.payload;
        const event = Object.values(events)[0];
        const componentState = draftState[key];

        if (
          event &&
          componentState &&
          !!componentState?.eventIds &&
          !componentState?.eventIds?.includes(event.EventId)
        ) {
          componentState.eventIds?.push(event.EventId);
        }

        break;
      }

      case getType(actions.removeLeague): {
        const { key, leagueId } = action.payload;
        const componentState = draftState[key];

        if (
          leagueId &&
          componentState &&
          !!componentState?.leagueIds &&
          componentState?.leagueIds?.includes(leagueId)
        ) {
          componentState.leagueIds = componentState.leagueIds?.filter(
            (id) => id !== leagueId,
          );
        }

        break;
      }

      case getType(actions.removeEvent): {
        const { key, eventId } = action.payload;
        const componentState = draftState[key];

        if (
          eventId &&
          componentState &&
          !!componentState?.eventIds &&
          componentState?.eventIds?.includes(eventId)
        ) {
          componentState.eventIds = componentState.eventIds?.filter(
            (id) => id !== eventId,
          );
        }

        break;
      }

      case getType(coreActions.switchLocale.success):
        return {};

      default: // Immer will automatically return the state
    }
  });
