import { createContext } from 'react';

const dummyFunction = () => null;

export const BettingSlipNumpadContext = createContext<{
  setInputFocused: (id: string | null) => void;
  currentInput: string | null;
}>({
  setInputFocused: dummyFunction,
  currentInput: null,
});
