import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { GamingState } from './types';

export const initialState: GamingState = {
  pendingGames: null,
  requests: {
    pendingGames: generateInitialRequestState(null),
  },
};

export const gamingReducer = (
  state: GamingState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getPendingGames.request):
        produceLoadingState(draftState.requests.pendingGames);
        break;

      case getType(actions.getPendingGames.failure):
        produceFailureState(draftState.requests.pendingGames, action.payload);
        break;

      case getType(actions.getPendingGames.success):
        produceSuccessState(draftState.requests.pendingGames);
        draftState.pendingGames = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
