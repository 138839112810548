import styled from 'styled-components';

import { colors, fontSizes, spaces } from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

export const BettingSlipClearAllButton = styled(SimpleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  max-width: 33%;
  color: ${colors('neutrals', { index: 3 })};
  margin-right: ${spaces('sm')};
  font-size: ${fontSizes('md')};
  svg {
    path {
      fill: ${colors('neutrals', { index: 3 })};
    }
  }
`;
