import {
  EDepositNextStepResponseStatus,
  EDepositStatusResponseStatus,
  EFindDepositsStatus,
  EGetDepositWalletsListInfoStatus,
  EMakeDepositStatus,
} from '@gaming1/g1-requests';

export const getDepositWalletListErrorMessages = {
  [EGetDepositWalletsListInfoStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetDepositWalletsListInfoStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EGetDepositWalletsListInfoStatus.PlayerBlackListed]:
    'payment:deposit.message.playerBlackListed', // 3
  [EGetDepositWalletsListInfoStatus.PlayerHasNotMinimumLegalAge]:
    'payment:deposit.message.playerHasNotMinimumLegalAge', // 4
  [EGetDepositWalletsListInfoStatus.PlayerDepositLimitReached]:
    'payment:deposit.message.playerDepositLimitReached', // 5
  [EGetDepositWalletsListInfoStatus.PlayerHasPendingWithdraw]:
    'payment:deposit.message.playerHasPendingWithdraw', // 6
  [EGetDepositWalletsListInfoStatus.AccountActivationRequired]:
    'payment:deposit.message.accountActivationRequired', // 8
  [EGetDepositWalletsListInfoStatus.PlayerAutoExcluded]:
    'payment:deposit.message.playerAutoExcluded', // 9
  [EGetDepositWalletsListInfoStatus.ErrorMajor]:
    'payment:deposit.message.errorMajor', // 10
  [EGetDepositWalletsListInfoStatus.PlayerWaitingForIdentification]:
    'payment:deposit.message.playerWaitingForIdentification', // 11
};

export const findDepositsErrorMessages = {
  [EFindDepositsStatus.InternalError]: 'core:error.internalServer', // 0
  [EFindDepositsStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
};

export const getDepositStatusErrorMessages = {
  [EDepositStatusResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EDepositStatusResponseStatus.InvalidDepositId]:
    'payment:deposit.message.invalidDepositId', // 2
};

export const getDepositNextStepErrorMessages = {
  [EDepositNextStepResponseStatus.InternalError]: 'core:error.internalServer', // 0
  [EDepositNextStepResponseStatus.InvalidDepositId]:
    'payment:deposit.message.invalidDepositId', // 2
};

export const makeDepositErrorMessages = {
  [EMakeDepositStatus.InternalError]: 'core:error.internalServer', // 0
  [EMakeDepositStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [EMakeDepositStatus.PlayerBlackListed]:
    'payment:deposit.message.playerBlackListed', // 3
  [EMakeDepositStatus.PlayerHasNotMinimumLegalAge]:
    'payment:deposit.message.playerHasNotMinimumLegalAge', // 4
  [EMakeDepositStatus.PlayerDepositLimitReached]:
    'payment:deposit.message.playerDepositLimitReached', // 5
  [EMakeDepositStatus.PlayerHasPendingWithdraw]:
    'payment:deposit.message.playerHasPendingWithdraw', // 6
  [EMakeDepositStatus.WalletDisabled]: 'payment:deposit.message.walletDisabled', // 9
  [EMakeDepositStatus.AccountActivationRequired]:
    'payment:deposit.message.accountActivationRequired', // 16
  [EMakeDepositStatus.PlayerAutoExcluded]:
    'payment:deposit.message.playerAutoExcluded', // 17
};
