import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { LoyaltyActions } from '../../types';
import * as actions from '../actions';

import { initialState } from './initialState';

export const shopHistoryReducer = (
  state = initialState,
  action: LoyaltyActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getShopHistory.request):
        produceLoadingState(draftState.requests.getShopHistory);
        break;

      case getType(actions.getShopHistory.failure):
        produceFailureState(draftState.requests.getShopHistory, action.payload);
        break;

      case getType(actions.getShopHistory.success):
        produceSuccessState(draftState.requests.getShopHistory);

        draftState.shopOrders =
          action.payload.pagination.PageNumber > 0 && state.shopOrders
            ? [...state.shopOrders, ...action.payload.list]
            : action.payload.list;

        draftState.requests.getShopHistory.pagination =
          action.payload.pagination;
        break;

      default: // Immer will automatically return the state
    }
  });
