import { cmsPublicRoutes } from '@gaming1/g1-cms-api';
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitely
// typed as it, otherwise this break the RouteParams mapped type!
export const cmsRoutes = {
  ...cmsPublicRoutes,
  blog: createRoute('/blog'),
  blogTag: createParamRoute('/blog/tag/:slug?', { slug: '' }),
  blogAll: createRoute('/blog/all'),
  blogArticle: createParamRoute('/blog/article/:uid?', { uid: '' }),
  allPromotions: createRoute('/promotions'),
  promotions: createParamRoute('/promotions/:slug?', { slug: '' }),
  promotionDetails: createParamRoute('/promotions/details/:uid?', { uid: '' }),
  preview: createRoute('/preview'),
};
