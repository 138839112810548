import {
  KeyValueType,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import { floorNumber } from '@gaming1/g1-utils';

import {
  BettingSlipNotification,
  EFrontErrorType,
} from '../../../../notification/mapper/types';

/*
 * compute the max stake by comparaison between RM max and winnable max
 * @param max RM max
 * @param winnable winnable max
 * @param winnable odd
 */
export const getRealMax = (
  max: number | null,
  winnable: number | null,
  odd: number,
): number | null => {
  if (!max && !winnable) {
    return null;
  }
  if (!max) {
    return winnable;
  }
  if (!winnable) {
    return max;
  }

  const maxByWinnable = floorNumber(winnable / (odd - 1), 2);

  return max < maxByWinnable ? max : maxByWinnable;
};

type GetLimitStatus = {
  stake: number | undefined;
  min: number | null;
  max: number | null;
  nbCombinations?: number;
  winnable: number | null;
  // Should be the final odds
  odd: number;
};

export const getLimitStatus = ({
  stake,
  min,
  max,
  nbCombinations = 1,
  winnable,
  odd,
}: GetLimitStatus): BettingSlipNotification => {
  if (!stake) {
    return {
      Status: EFrontErrorType.NoStake,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
      NotificationParameters: [
        {
          Key: 'minCurrency',
          Value: min?.toString() || '0',
          Type: KeyValueType.Money,
        },
      ],
    };
  }

  const realMax = getRealMax(max, winnable, odd);
  if (min && realMax && min >= realMax) {
    return {
      Status: EFrontErrorType.MinMax,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
    };
  }
  if (min && min > stake) {
    return {
      Status: EFrontErrorType.Min,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
      NotificationParameters: [
        { Key: 'minCurrency', Value: min.toString(), Type: KeyValueType.Money },
      ],
    };
  }

  if (realMax && realMax < stake) {
    return {
      Status: EFrontErrorType.Max,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
      NotificationParameters: [
        {
          Key: 'maxCurrency',
          Value: floorNumber(realMax / nbCombinations, 2).toString(),
          Type: KeyValueType.Money,
        },
      ],
    };
  }

  return {
    Status: EFrontErrorType.None,
    Level: NotificationLevel.Unknown,
    Code: NotificationCode.Unknown,
  };
};
