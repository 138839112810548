export const getStatus = (
  isScratched: boolean,
  prizeId: string | undefined,
): string => {
  if (!isScratched) {
    return `scratchcard.history.status.toscratch`;
  }
  if (prizeId) {
    return `scratchcard.history.status.win`;
  }
  return `scratchcard.history.status.lost`;
};
