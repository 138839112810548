import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as actions from './actions';
import { PendingGamesState } from './types';

export const initialState: PendingGamesState = {
  pendingGames: null,
  requests: {
    getPendingGames: generateInitialRequestState(null),
  },
};

export const pendingGamesReducer = (
  state: PendingGamesState = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.getPendingGames.request):
        produceLoadingState(draftState.requests.getPendingGames);
        break;

      /** Failures */
      case getType(actions.getPendingGames.failure):
        produceFailureState(
          draftState.requests.getPendingGames,
          action.payload,
        );
        break;

      /** Successes */
      case getType(actions.getPendingGames.success):
        produceSuccessState(draftState.requests.getPendingGames);
        draftState.pendingGames = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
