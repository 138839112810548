import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { userLoggedInSelector } from '@gaming1/g1-core';
import { getFreebets } from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { getBetPipeIdentifierFromActionPayload } from '../../betPipe/helpers';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import { getFreebetsResponseCodec } from '../codecs';

/** Get  */
export const getFreebetsEpic: BettingEpic = (actions$, state$, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.getFreebets.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const isUserLoggedIn = userLoggedInSelector(state);
      const betPipeIdentifier = getBetPipeIdentifierFromActionPayload(payload);

      if (!isUserLoggedIn) {
        return of(
          actions.getFreebets.failure({
            errorMessage: 'betting:freebets.error.userNotLoggedIn',
            status: DEFAULT_SERVER_ERROR_STATUS,
            ...betPipeIdentifier,
          }),
        );
      }

      return wsAdapter.request(getFreebets({})).pipe(
        mapGuard(getFreebetsResponseCodec),
        map((data) =>
          actions.getFreebets.success({
            ...data,
            ...betPipeIdentifier,
            askFreebetConditions:
              'askFreebetConditions' in payload
                ? payload.askFreebetConditions
                : false,
          }),
        ),
        catchError((err) =>
          of(
            actions.getFreebets.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              ...betPipeIdentifier,
              askFreebetConditions:
                'askFreebetConditions' in payload
                  ? payload.askFreebetConditions
                  : false,
            }),
          ),
        ),
      );
    }),
  );
