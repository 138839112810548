import React, { VFC } from 'react';
import { useSelector } from 'react-redux';

import { currentSectionSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { useMedia } from '@gaming1/g1-ui';

import { TopNavAppSection } from './styles';

/**
 * The section name displayed on TopNav (except for xl and bigger screens)
 */
export const AppSection: VFC = () => {
  const { t, i18n } = useTranslation('core');
  const currentSection = useSelector(currentSectionSelector);

  const translationKey = `sectionNames.${currentSection}`;
  const { mediaBP } = useMedia();
  const isMediaBetweenSmallAndLarge = ['sm', 'md', 'lg'].includes(mediaBP);

  return isMediaBetweenSmallAndLarge ? (
    <TopNavAppSection data-testid="topnav-appsection">
      {i18n.exists(translationKey) ? t(translationKey) : null}
    </TopNavAppSection>
  ) : null;
};
