import { darken } from 'polished';
import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const SearchHistoryContainer = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  /* column-gap: ${spaces('md')}; */
`;

export const ClearHistoryButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${colors('buttonSecondaryText')};
  cursor: pointer;
  outline: none;
  padding: 0;

  &:hover {
    color: ${colors('buttonSecondaryText', { transform: darken(0.1) })};
  }
`;
