/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  FilterRequest,
  FilterResponse,
  LiveSportRequest,
  LiveSportResponse,
  PrematchSportRequest,
  PrematchSportResponse,
} from '../entities';

export const getFilters = (options: FilterRequest) =>
  createRequest<FilterResponse>(201, 'GetFilters', options);

export const getPrematchSport = (options: PrematchSportRequest) =>
  createRequest<PrematchSportResponse>(201, 'GetPrematchSport', options);

export const getLiveSport = (options: LiveSportRequest) =>
  createRequest<LiveSportResponse>(201, 'GetLiveSport', options);
