import cloneDeep from 'lodash/cloneDeep';

import { BettingActivity, TradingState } from '@gaming1/g1-requests-betting';

import { InitBettingSlip } from '../bettingSlip/store/types/import';
import { BettingSlipIdentifierType } from '../common/store/types';

import { ManualBetType } from './types';

export const formatManualForBettingSlipInit = (
  id: BettingSlipIdentifierType,
  item: ManualBetType,
  hasUserAlreadyMakeAFirstDeposit?: boolean,
): InitBettingSlip | null => {
  const elementInfo = item.ElementInfo;

  if (!elementInfo) {
    return null;
  }

  return {
    bettingSlip: {
      bettingSlip: {
        items: [
          {
            id: elementInfo.OutcomeId,
            event: elementInfo.EventId,
            market: elementInfo.MarketId,
            outcome: elementInfo.OutcomeId,
            extra: {
              baseManualBetOdd: elementInfo.BaseOdds,
            },
          },
        ],
        stake: '10',
        type: 'single',
        extra: {
          title: elementInfo.Title1,
          limits: {
            StakeMin: item.MinStake || 0,
            StakeMax: item.MaxStake,
            WinnableMax: 0,
          },
          manualBetConfigId: item.ManualBetConfigId,
          firstDepositDone: hasUserAlreadyMakeAFirstDeposit,
        },
        configuration: {
          activity: BettingActivity.Manual,
        },
      },
      bettingSlipId: id,
    },
    events: [
      {
        EventId: elementInfo.EventId,
        MarketId: elementInfo.MarketId,
        OutcomeId: elementInfo.OutcomeId,
        HomeName: elementInfo.EventName || '',
        AwayName: '',
      },
    ],
    markets: [
      {
        EventId: elementInfo.EventId,
        MarketId: elementInfo.MarketId,
        MarketName: elementInfo.MarketName,
        Provider: elementInfo.Provider,
        State: TradingState.Open,
      },
    ],
    outcomes: [
      {
        OutcomeId: elementInfo.OutcomeId,
        MarketId: elementInfo.MarketId,
        EventId: elementInfo.EventId,
        Name: elementInfo.OutcomeName,
        Odd: elementInfo.FinalOdds || -1,
      },
    ],
  };
};

/**
 * This will check every manualBet of the manualBets given and
 * initialize their {MinStake} property with the {defaultMinStake} given
 * if their {MinStake} property is undefined.
 */
export const addDefaultMinStakeToManualBetWithoutOne = (
  manualBets: ManualBetType[],
  defaultMinStake: number,
): ManualBetType[] => {
  const tempManualBets = cloneDeep(manualBets);
  const dailyOffersWithMinStake = tempManualBets.map((manualBet) => {
    const manualBetTemp = manualBet;
    if (!manualBetTemp.MinStake) {
      manualBetTemp.MinStake = defaultMinStake;
    }

    return manualBetTemp;
  });

  return dailyOffersWithMinStake;
};
