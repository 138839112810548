import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { useRequestState } from '@gaming1/g1-store';
import { encodeQuery, RemoteData } from '@gaming1/g1-utils';

import { getPrismicLocale, prismicQueriesToString } from '../helpers';
import {
  pageContentDocumentsByQuerySelector,
  pageContentDocumentsStatusByQuerySelector,
} from '../prismicPageContent/store/selectors';
import {
  getPrismicReferenceRequestStateSelector,
  prismicReferenceSelector,
} from '../prismicReference/store/selectors';
import * as actions from '../store/actions';
import { PrismicQueryInput } from '../types';

/**
 * Get the current prismic reference. If the reference hasn't been fetched yet,
 * it'll dispatch the `fetchPrismicReference.request` action and return the
 * prismicReference as a string.
 */
export const usePrismicReference = () => {
  const dispatch = useDispatch();
  const { isNotAsked } = useRequestState(
    getPrismicReferenceRequestStateSelector,
  );
  const { prismicReference } = useSelector(prismicReferenceSelector);

  useEffect(() => {
    if (isNotAsked) {
      dispatch(actions.fetchPrismicReference.request());
    }
  }, [dispatch, isNotAsked]);

  return prismicReference;
};

/**
 * Hook that based on the input query and an optional reference returns a page content document along
 * with an action to fetch it and other utilities.
 * @param query Prismic Query
 * @param ref: Prismic Reference
 */
export const useSinglePageContentQuery = (
  queries: PrismicQueryInput[],
  ref?: string,
) => {
  const dispach = useDispatch();
  const currentLocale = getPrismicLocale(
    useSelector(currentWebSocketLocaleSelector),
  );
  const prismicReference = usePrismicReference() || '';

  const uriQueryString = encodeQuery({
    lang: currentLocale,
    q: prismicQueriesToString(queries),
    ref: ref || prismicReference,
  });

  const documents = useSelector(
    pageContentDocumentsByQuerySelector(uriQueryString),
  );

  // For some reason, accessing an array's first element is inferred as the
  // array element type without the undefined union. I used this ternary
  // expression to explicitly make the document type "undefined friendly".
  const document = documents.length > 0 ? documents[0] : undefined;

  const status = useSelector(
    pageContentDocumentsStatusByQuerySelector(uriQueryString),
  );

  const fetchDocument = useCallback(() => {
    if (prismicReference) {
      dispach(actions.fetchSinglePageContentDocument.request(uriQueryString));
    }
  }, [dispach, prismicReference, uriQueryString]);

  const isLoading = status === RemoteData.Loading;

  return useMemo(
    () => ({
      fetchDocument,
      status,
      document,
      isLoading,
    }),
    [document, fetchDocument, isLoading, status],
  );
};

/**
 * Hook that takes a `content_v2` document uid, and an optional tags list, and returns
 * the document along with an action to fetch it and other utilities.
 * @param uid document uid
 */
export const usePageContentByUid = (uid: string, tags?: string[]) => {
  const queries: PrismicQueryInput[] = [
    {
      predicate: 'at',
      path: 'my.content_v2.uid',
      value: uid,
    },
  ];
  if (tags && tags.length > 0) {
    queries.push({
      predicate: 'at',
      path: 'document.tags',
      value: tags,
    });
  }
  return useSinglePageContentQuery(queries);
};
