import React, { memo, useContext, useEffect, VFC } from 'react';

import {
  EFrontErrorType,
  getHasFrontNotificationError,
  useBettingSlipSystemErrorFrontType,
} from '@gaming1/g1-betting';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

type BettingSlipSystemErrorProps = {
  setHasSystemTooManyElements: (newState: boolean) => void;
};

const BettingSlipSystemErrorComponent: VFC<BettingSlipSystemErrorProps> = ({
  setHasSystemTooManyElements,
}) => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const errorNotification = useBettingSlipSystemErrorFrontType(bettingSlipId)();
  const hasFrontNotificationError =
    getHasFrontNotificationError(errorNotification);
  const testId = `bettingslip-error-stake-system-${errorNotification.Status}`;
  useEffect(() => {
    if (
      errorNotification.Status === EFrontErrorType.None ||
      errorNotification.Status === EFrontErrorType.NoStake
    ) {
      setHasSystemTooManyElements(false);
    }

    if (errorNotification.Status === EFrontErrorType.TooManyElementsInSystem) {
      setHasSystemTooManyElements(true);
    }
  }, [errorNotification.Status, setHasSystemTooManyElements]);

  return hasFrontNotificationError ? (
    <BettingSlipMessage testId={testId} notification={errorNotification} />
  ) : null;
};

export const BettingSlipSystemError = memo(BettingSlipSystemErrorComponent);
