import styled from 'styled-components';

import { PrismicWrapper } from '../styles';

export type PrismicMapWrapperProps = {
  width: string | null;
  height: string | null;
};

export const PrismicMapWrapper = styled(PrismicWrapper)<PrismicMapWrapperProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
`;
