import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FontSizeProps, SpaceProps } from 'styled-system';

import { Box } from '../Box';
import { Chips } from '../Chips';
import { ScrollableList } from '../ScrollableList';

import {
  ArrowContainer,
  TabContainer,
  TabContentContainer,
  TabListContainer,
  TabsContainer,
} from './styles';

const context = createContext<{
  activeTab: string;
  setActiveTab: (newTab: string) => void;
}>({ activeTab: '', setActiveTab: () => null });

const TabsProvider = context.Provider;

type TabListProps = {
  /** Display a border to the bottom of the tabs container (like the ScrollNav) */
  showBorder?: boolean;
  hasBackgroundColorRemoved?: boolean;
  hasGapBetweenItems?: boolean;
  hasHorizontalScroll?: boolean;
  isFullWidth?: boolean;
  setTabContainerScrollValue?: (value: number) => void;
  tabContainerScrollValue?: { current: number };
} & FontSizeProps;

const TabList: FC<TabListProps> = ({
  children,
  fontSize,
  showBorder = false,
  hasBackgroundColorRemoved = false,
  hasGapBetweenItems = false,
  hasHorizontalScroll = false,
  isFullWidth = false,
  setTabContainerScrollValue,
  tabContainerScrollValue,
}) => {
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const tabListContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tabContainer = tabListContainerRef.current;

    const handleScroll = () => {
      if (tabContainer && setTabContainerScrollValue) {
        setTabContainerScrollValue(tabContainer.scrollLeft);
      }
    };

    if (tabContainer && tabContainerScrollValue) {
      tabContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tabContainer) {
        tabContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    tabListContainerRef,
    tabContainerScrollValue,
    setTabContainerScrollValue,
  ]);

  useEffect(() => {
    if (tabListContainerRef.current && tabContainerScrollValue) {
      tabListContainerRef.current.scrollLeft = tabContainerScrollValue.current;
    }
  });

  return (
    <ScrollableList
      containerRef={tabsContainerRef}
      listRef={tabListContainerRef}
    >
      <ArrowContainer width={isFullWidth ? '100%' : 'auto'}>
        <ScrollableList.ScrollableListButton side="left" />
        <TabsContainer
          hasBackgroundColorRemoved={hasBackgroundColorRemoved}
          hasHorizontalScroll={hasHorizontalScroll}
          ref={tabsContainerRef}
        >
          <ScrollableList.ScrollableListWrapper hasGradient>
            <TabListContainer
              fontSize={fontSize}
              showBorder={showBorder}
              hasHorizontalScroll={hasHorizontalScroll}
              ref={tabListContainerRef}
              hasGapBetweenItems={hasGapBetweenItems}
            >
              {children}
            </TabListContainer>
          </ScrollableList.ScrollableListWrapper>
        </TabsContainer>
        <ScrollableList.ScrollableListButton side="right" />
      </ArrowContainer>
    </ScrollableList>
  );
};

const TabContents: FC<SpaceProps> = ({ children, ...spaceProps }) => (
  <Box {...spaceProps}>{children}</Box>
);

type TabPanelProps = {
  id: string;
  hasBackgroundColorRemoved?: boolean;
};

const TabContent: FC<TabPanelProps> = ({
  children,
  id,
  hasBackgroundColorRemoved = false,
}) => {
  const { activeTab } = useContext(context);
  return activeTab === id ? (
    <TabContentContainer hasBackgroundColorRemoved={hasBackgroundColorRemoved}>
      {children}
    </TabContentContainer>
  ) : null;
};

type TabProps = {
  /** The position of the border of the active tab */
  borderPosition?: 'top' | 'bottom';
  /** If the tab should not be clickable */
  isDisabled?: boolean;
  /** Unique identifier for the tab */
  id: string;
  /** Should the tab take all space available */
  isFullWidth?: boolean;
  /** Remove the background color of the tab */
  hasBackgroundColorRemoved?: boolean;
  /** Test id for the tab */
  testId?: string;
  /** Style of the tab */
  displayAs?: 'tab' | 'chips';
  /** Width of the border in % */
  borderWidth?: number;
  /** Size of the vertical padding */
  hasSmallHeight?: boolean;
};

export const Tab: FC<TabProps> = ({
  children,
  borderPosition = 'top',
  id,
  isDisabled = false,
  isFullWidth = true,
  hasBackgroundColorRemoved = false,
  displayAs = 'tab',
  borderWidth = 100,
  hasSmallHeight,
  testId,
}) => {
  const { activeTab, setActiveTab } = useContext(context);
  return displayAs === 'tab' ? (
    <TabContainer
      disabled={isDisabled}
      type="button"
      isActive={activeTab === id}
      isFullWidth={isFullWidth}
      borderPosition={borderPosition}
      onClick={() => setActiveTab(id)}
      hasBackgroundColorRemoved={hasBackgroundColorRemoved}
      data-testid={testId}
      borderWidth={borderWidth}
      hasSmallHeight={hasSmallHeight}
    >
      {children}
    </TabContainer>
  ) : (
    <Chips
      margin="xxs"
      active={activeTab === id}
      onClick={() => setActiveTab(id)}
      data-testid={testId}
    >
      {children}
    </Chips>
  );
};

export type TabsProps = {
  /**
   * Id of the tab which is active by default
   */
  defaultActiveTab: string;
  /** Remove the background color of the tabs */
  hasBackgroundColorRemoved?: boolean;
};

type TabsCompoundComponents = {
  TabList: typeof TabList;
  Tab: typeof Tab;
  TabContents: typeof TabContents;
  TabContent: typeof TabContent;
};

export const Tabs: FC<TabsProps> & TabsCompoundComponents = ({
  children,
  defaultActiveTab,
  hasBackgroundColorRemoved = false,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const ctx = useMemo(() => ({ activeTab, setActiveTab }), [activeTab]);

  return (
    <TabsProvider value={ctx}>
      <TabsContainer hasBackgroundColorRemoved={hasBackgroundColorRemoved}>
        {children}
      </TabsContainer>
    </TabsProvider>
  );
};

Tabs.TabList = TabList;
Tabs.Tab = Tab;
Tabs.TabContents = TabContents;
Tabs.TabContent = TabContent;

type ControlledTabsProps<T extends string = string> = {
  activeTab: T;
  setActiveTab: (newTab: T) => void;
  showBorder?: boolean;
};

export const ControlledTabs: FC<ControlledTabsProps> &
  TabsCompoundComponents = ({ children, activeTab, setActiveTab }) => {
  const ctx = useMemo(
    () => ({ activeTab, setActiveTab }),
    [activeTab, setActiveTab],
  );

  return <TabsProvider value={ctx}>{children}</TabsProvider>;
};

ControlledTabs.TabList = TabList;
ControlledTabs.Tab = Tab;
ControlledTabs.TabContents = TabContents;
ControlledTabs.TabContent = TabContent;
