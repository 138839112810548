/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetGoogleTagManagerRequestDTO,
  GetGoogleTagManagerResponseDTO,
  GoogleTagManagerTrackingPlacedRequestDTO,
} from '../entities';

export const getGoogleTagManager = (options: GetGoogleTagManagerRequestDTO) =>
  createRequest<GetGoogleTagManagerResponseDTO>(
    100,
    'GetGoogleTagManager',
    options,
  );

export const googleTagManagerTrackingPlaced = (
  options: GoogleTagManagerTrackingPlacedRequestDTO,
) => createRequest<string>(100, 'GoogleTagManagerTrackingPlaced', options);
