import React, { ComponentPropsWithoutRef, FC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { Withdraw } from '@gaming1/g1-payment';
import { Button, Heading, Modal } from '@gaming1/g1-ui';

export type WithdrawalCancelModalProp = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  /** Is the cancel request pending */
  isLoading: boolean;
  /** Callback called to cancel withdrawal */
  onCancelWithdraw: (id: string) => void;
  /** The withdrawal to be cancelled */
  withdrawal: Withdraw;
};

/**
 * The component is a modal that allows the user to actually
 * cancel the given withdrawal
 */
export const WithdrawalCancelModal: FC<WithdrawalCancelModalProp> = ({
  isLoading = false,
  onCancelWithdraw,
  onClose,
  withdrawal,
  ...rest
}) => {
  const { t } = useTranslation(['payment', 'core']);

  return withdrawal.WithdrawId ? (
    <Modal modalId="withdrawal-cancel-modal" onClose={onClose} {...rest}>
      <Heading level="h2" mt="0">
        {t('withdrawal.cancel.title')}
      </Heading>

      <Button
        data-testid="withdrawal-cancel-modal-yes-button"
        disabled={isLoading}
        loading={isLoading}
        mt="sm"
        onClick={() => {
          if (withdrawal.WithdrawId) {
            onCancelWithdraw(withdrawal.WithdrawId);
          }
        }}
        type="primary"
      >
        {t('core:button.yes')}
      </Button>
      <Button
        data-testid="withdrawal-cancel-modal-no-button"
        mt="xs"
        noBorder
        onClick={onClose}
        type="secondary"
      >
        {t('core:button.no')}
      </Button>
    </Modal>
  ) : null;
};
