import React, { VFC } from 'react';
import { useTheme } from 'styled-components';

import {
  BettingHistoryTicketType,
  BoostusHistoryInfoType,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useFormatNumber } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Icon, Text } from '@gaming1/g1-ui';

import { multiplyBy100 } from '../../../../../boostus/helpers';
import { OpacityContainer } from '../../../styles';
import { UpdatedOdds } from '../../HistoryCardParts/UpdatedOdds';
import {
  EXTRA_ICON_HEIGHT_IN_PX,
  EXTRA_ICON_WIDTH_IN_PX,
  ExtraText,
  HistoryExpandedGiftContainer,
} from '../styles';

type ExpandedExtraInfoBoostusProps = {
  boostus: BoostusHistoryInfoType;
  history: BettingHistoryTicketType;
};

export const ExpandedExtraInfoBoostusV2: VFC<ExpandedExtraInfoBoostusProps> = ({
  boostus,
  history,
}) => {
  const { betting } = useConfig();
  const { t } = useTranslation('betting');
  const formatNumber = useFormatNumber();
  const { colors } = useTheme();

  const updateOddsOrWinnings = betting.boostus.applyTo;

  const boostusIcon = (
    <Icon
      id="BoostusWithBackground"
      type="BoostusWithBackground"
      color={colors.bettingBoostus}
      height={EXTRA_ICON_HEIGHT_IN_PX}
      width={EXTRA_ICON_WIDTH_IN_PX}
    />
  );

  return boostus ? (
    <HistoryExpandedGiftContainer>
      {boostus.IsValid ? (
        boostusIcon
      ) : (
        <OpacityContainer data-testid="extra-boostus-invalid-container">
          {boostusIcon}
        </OpacityContainer>
      )}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ExtraText color={colors.bettingBoostus}>
          {boostus?.Name}{' '}
          {t('history.boostus.boostValue', {
            boostValue: formatNumber(multiplyBy100(boostus.BoostValue), 0),
          })}
        </ExtraText>
        {boostus.IsValid ? (
          <UpdatedOdds
            baseOdds={history.BaseOdds}
            finalOdds={history.FinalOdds}
            updateType={updateOddsOrWinnings}
            id="boostus"
          />
        ) : (
          <Text mt={0} fontSize="md">
            {t('boostus.history.notValid')}
          </Text>
        )}
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
