import { conditionalWindow } from './conditionalWindow';
import { getSearchParam } from './internals';

const wrapperSearch = getSearchParam('wrapperEnv');

export type WrapperEnv = 'spa' | 'rn' | 'interopwebapp';

const wrapperEnvs: WrapperEnv[] = ['spa', 'rn', 'interopwebapp'];

const isWrapperEnv = (envValue?: string): envValue is WrapperEnv =>
  !!envValue && wrapperEnvs.includes(envValue as WrapperEnv);

/**
 * Get the environnement where the code is executed. Either "browser" if the web
 * app is a regular website or "rn" if the web app is served from a webview
 * inside a React Native app
 */
export const getWrapperEnv = (): WrapperEnv => {
  // This is for testing purpose only: the query param can be set to test the
  // app behaviour in a browser while the env param can be used in jest
  const forcedWrapperEnv = wrapperSearch || process.env.REACT_APP_WRAPPER_ENV;
  if (isWrapperEnv(forcedWrapperEnv)) {
    return forcedWrapperEnv;
  }
  if (
    typeof conditionalWindow !== 'undefined' &&
    typeof conditionalWindow.ReactNativeWebView !== 'undefined'
  ) {
    return 'rn';
  }
  if (
    typeof conditionalWindow !== 'undefined' &&
    typeof conditionalWindow.reactInterop !== 'undefined'
  ) {
    return 'interopwebapp';
  }
  return 'spa';
};
