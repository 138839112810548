import { Game } from '../../common/store/types';

import { GetJackpotDetailsResponse, Jackpot, JackpotTotals } from './types';

export const formatJackpotDetails = (
  response: GetJackpotDetailsResponse,
): Jackpot[] => {
  const { Jackpots } = response;

  /** Ensure the items are correctly sorted by rank (from highest to lowest) */
  const listWithSortedItems = Jackpots.map((jackpot) => {
    const items = jackpot.Items?.slice().sort((a, b) => {
      if (a && b) {
        return b.OrderNum - a.OrderNum;
      }
      return 0;
    });
    return { ...jackpot, Items: items };
  });
  return listWithSortedItems;
};

export const formatJackpotGames = (
  response: GetJackpotDetailsResponse,
): Game[] => {
  const list = response.Jackpots.map((jackpot) => {
    if (jackpot.LinkedGames) {
      return jackpot.LinkedGames;
    }
    return [];
  }).reduce((acc, cur) => [...acc, ...cur], []);

  return list;
};

export const formatJackpotTotals = (
  response: GetJackpotDetailsResponse,
): JackpotTotals => {
  const { TotalAmount, TotalWonAmount } = response;

  return { TotalAmount, TotalWonAmount };
};
