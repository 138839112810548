/* eslint-disable no-console */
import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { calcPotWinning } from '../../helpers/common';

import {
  bettingSlipRoundingConfigGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  freebetSelectedForBSGetterSelector,
} from './common';
import { getInitDataRequestResultGetterSelector } from './getInitData/getInitData';
import {
  bettingSlipBaseOddsGetterSelector,
  bettingSlipFinalOddsGettorSelector,
} from './odds';

/**
 * Get the pot winning of bettingslip
 */
export const bettingSlipWinningsGettorSelector = createSelector(
  getInitDataRequestResultGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  bettingSlipBaseOddsGetterSelector,
  freebetSelectedForBSGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  (
    getInitDataRequestResult,
    bettingSlipStakeInFloat,
    bettingSlipCombiOdd,
    freebetSelectedForBS,
    roundingConfig,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const config = getInitDataRequestResult();
      const stake = bettingSlipStakeInFloat(bettingSlipId);
      const odd = bettingSlipCombiOdd(bettingSlipId);
      const selectedFreebet = freebetSelectedForBS(bettingSlipId);

      // If the initial odd is undefined, the bettingSlipCombiOdd will returns
      // 1 as a default value, so the odd won't be undefined
      if (!stake || !odd) {
        return undefined;
      }

      return calcPotWinning({
        stake,
        odd,
        config: {
          ...{
            isFreeBetRetributeStakeAmount:
              config?.IsFreeBetRetributeStakeAmount,
          },
          ...{ roundingConfig: roundingConfig(bettingSlipId) },
        },
        options: { isFreebetSelected: !!selectedFreebet },
      });
    }),
);

/**
 * Get the potential winnings of bettingslip after applying boostus
 */
export const bettingSlipFinalWinningsGettorSelector = createSelector(
  getInitDataRequestResultGetterSelector,
  bettingSlipFinalOddsGettorSelector,
  bettingSlipStakeInFloatGetterSelector,
  freebetSelectedForBSGetterSelector,
  bettingSlipRoundingConfigGetterSelector,
  (
    getInitDataRequestResult,
    finalOdd,
    bettingSlipStakeInFloat,
    freebetSelectedForBS,
    roundingConfig,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const config = getInitDataRequestResult();
      const stake = bettingSlipStakeInFloat(bettingSlipId);
      const finalOddValue = finalOdd(bettingSlipId);
      const selectedFreebet = freebetSelectedForBS(bettingSlipId);

      if (!stake) {
        return undefined;
      }

      return calcPotWinning({
        stake,
        odd: finalOddValue,
        config: {
          ...{
            isFreeBetRetributeStakeAmount:
              config?.IsFreeBetRetributeStakeAmount,
          },
          ...{ roundingConfig: roundingConfig(bettingSlipId) },
        },
        options: { isFreebetSelected: !!selectedFreebet },
      });
    }),
);
