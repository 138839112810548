import styled, { css } from 'styled-components';

import { colors, elevation, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const StyledBox = styled(Box)`
  background-color: ${colors('backgrounds', { index: 0 })};
  flex-direction: column;
  max-width: 100vw;
`;

export const StyledBettingSlipContainer = styled(Box)`
  ${elevation(0)};
  border-left: 2px solid ${colors('backgrounds', { index: 4 })};
  border-right: 2px solid ${colors('backgrounds', { index: 4 })};
  overflow-y: auto;
  overflow-x: hidden;
  flex-basis: 360px;
  flex-shrink: 0;
`;

export const CollapsibleChildrenSubContainer = styled(Box)<{
  numberOfColumns: number;
}>`
  ${({ numberOfColumns }) =>
    numberOfColumns === 3 &&
    css`
      padding: ${spaces('sm')};
    `}
  ${({ numberOfColumns }) =>
    numberOfColumns === 2 &&
    css`
      padding: 0 ${spaces('xs')} ${spaces('sm')};
    `}
`;
