import { useLayoutEffect, useState } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';

/** Returns all translations used in full scoreboards */
export const useScoreBoardFullTranslations = () => {
  const { t } = useTranslation('betting');

  return {
    legs: t('score.boardFull.legs'),
    over: t('score.boardFull.over'),
    runs: t('score.boardFull.runs'),
    sets: t('score.boardFull.sets'),
  };
};

/**
 * This hook returns a callback reference and automatically scrolls to the
 * right of the container.
 */
export const useAutomaticRightScroll = (): ((
  element: HTMLElement | null,
) => void) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (element && 'scrollTo' in element) {
      element.scrollTo({ left: element.clientWidth });
    }
  }, [element]);

  return (e) => setElement(e);
};
