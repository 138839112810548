import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable, RemoteData } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { BettingSlipNotification } from '../../../../notification/mapper/types';
import {
  GetRiskManagementResponseType,
  GetSystemRiskManagementResponseType,
} from '../../types/riskManagement';
import { BettingSlipItemLimitsState } from '../../types/store';
import {
  bettingSlipGetterSelector,
  bettingSlipRequestGetterSelector,
  bettingSlipsStateSelector,
} from '../common';

/**
 * Request
 */
export const riskManagementRequestGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (getBettingSlip) => (bettingSlipId: BettingSlipIdentifierType) =>
    getBettingSlip(bettingSlipId)?.riskManagement,
);

export const riskManagementRequestStatusGetterSelector = createSelector(
  bettingSlipRequestGetterSelector,
  (getBettingSlipRequestSelector) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      getBettingSlipRequestSelector(bettingSlipId)?.riskManagement.status ||
      RemoteData.NotAsked,
);

export const riskManagementRequestValidDataGetterSelector = createSelector(
  riskManagementRequestStatusGetterSelector,
  (riskManagementRequestStatus) =>
    (bettingSlipId: BettingSlipIdentifierType) => {
      const status = riskManagementRequestStatus(bettingSlipId);
      if (status === RemoteData.Loading || status === RemoteData.NotAsked) {
        return false;
      }
      return true;
    },
);

/**
 * Risk Management Selectors
 */
export const riskManagementGetterSelector = createSelector(
  bettingSlipsStateSelector,
  riskManagementRequestValidDataGetterSelector,
  (bettingSlipRequests, riskManagementRequestValideData) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      !riskManagementRequestValideData(bettingSlipId)
        ? undefined
        : bettingSlipRequests.bettingSlips[bettingSlipId]?.riskManagement,
);

export const riskManagementSuccessGetterSelector = createSelector(
  riskManagementGetterSelector,
  riskManagementRequestValidDataGetterSelector,
  (riskManagementReport, riskManagementRequestValideData) =>
    (bettingSlipId: BettingSlipIdentifierType) =>
      !riskManagementRequestValideData(bettingSlipId)
        ? undefined
        : riskManagementReport(bettingSlipId)?.result,
);

export const riskManagementSuccessItemGetterSelector = createSelector(
  riskManagementGetterSelector,
  riskManagementRequestValidDataGetterSelector,
  (riskManagementReport, riskManagementRequestValideData) =>
    (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
      !riskManagementRequestValideData(bettingSlipId)
        ? undefined
        : riskManagementReport(bettingSlipId)
            ?.result?.ElementNotifications?.filter(isNonNullable)
            .find((item) => item.OutcomeId === itemId),
);

/**
 * System Risk Management Selectors
 */

export const riskManagementSuccessRankGetterSelector = createSelector(
  riskManagementGetterSelector,
  riskManagementRequestValidDataGetterSelector,
  (riskManagement, riskManagementRequestValideData) =>
    (bettingSlipId: BettingSlipIdentifierType, id: string) => {
      if (riskManagementRequestValideData(bettingSlipId)) {
        const riskManagementState = riskManagement(bettingSlipId);
        if (riskManagementState && riskManagementState.result) {
          const result =
            riskManagementState.result as GetSystemRiskManagementResponseType;

          return result?.Ranks?.filter(isNonNullable).find(
            (rank) => rank.Rank === id,
          );
        }
      }
      return undefined;
    },
);

/**
 * Limits
 */

/**
 * Guard that tells us if the result state is a "GetSystemRiskManagementResponse" type (so if it has a "Ranks" property).
 * @param result The result's state retrieved from the riskManagement store's slice
 */
const isSystemResult = (
  result: GetRiskManagementResponseType | GetSystemRiskManagementResponseType,
): result is GetSystemRiskManagementResponseType =>
  Object.prototype.hasOwnProperty.call(result, 'Ranks');

/**
 * Give the limits received by the response of the RiskManagementRequest
 *
 */
export const riskManagementLimitsGetterSelector = createSelector(
  riskManagementSuccessGetterSelector,
  (riskManagement) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) => {
        const riskManagementResult = riskManagement(bettingSlipId);

        if (riskManagementResult && !isSystemResult(riskManagementResult)) {
          const { Limits } = riskManagementResult;
          if (!Limits) {
            return null;
          }
          return {
            min: Limits.StakeMin,
            max: Limits.StakeMax,
            winnable: Limits.WinnableMax,
          };
        }
        return null;
      },
      (bettingSlipId: BettingSlipIdentifierType) => `${bettingSlipId}`,
    ),
);

/**
 * Give the rank limits
 */
export const systemRiskManagementRankLimitsGetterSelector = createSelector(
  riskManagementSuccessRankGetterSelector,
  (riskManagementRank) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
        id: string,
      ): BettingSlipItemLimitsState => {
        const rank = riskManagementRank(bettingSlipId, id);
        if (!rank || !rank.Limits) {
          return { min: undefined, max: undefined, winnable: undefined };
        }
        return {
          min: rank.Limits.StakeMin,
          max: rank.Limits.StakeMax,
          winnable: rank.Limits.WinnableMax,
        };
      },
      (bettingSlipId: BettingSlipIdentifierType, id: string) =>
        `${bettingSlipId}-${id}`,
    ),
);

export const bettingSlipRankLimitsGetterSelector = createSelector(
  systemRiskManagementRankLimitsGetterSelector,
  (limits) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
        id: string,
      ): BettingSlipItemLimitsState => limits(bettingSlipId, id),
      (bettingSlipId: BettingSlipIdentifierType, id: string) =>
        `${bettingSlipId}-${id}`,
    ),
);

/**
 * Error
 */
export const riskManagementNotificationGetterSelector = createSelector(
  riskManagementRequestStatusGetterSelector,
  riskManagementGetterSelector,
  (riskManagementRequestStatus, riskManagement) =>
    (
      bettingSlipId: BettingSlipIdentifierType,
    ): BettingSlipNotification | null => {
      if (riskManagementRequestStatus(bettingSlipId) !== RemoteData.Error) {
        return null;
      }
      const notification = riskManagement(bettingSlipId)?.result?.Notification;

      return notification || null;
    },
);

/**
 * Don't forget to use shallowEqual with this selector
 */
export const riskManagementItemNotificationGetterSelector = createSelector(
  riskManagementRequestStatusGetterSelector,
  riskManagementGetterSelector,
  (riskManagementRequestStatus, riskManagement) =>
    memoize(
      (
        bettingSlipId: BettingSlipIdentifierType,
        itemId: number,
      ): BettingSlipNotification | null => {
        if (riskManagementRequestStatus(bettingSlipId) !== RemoteData.Error) {
          return null;
        }
        const element = riskManagement(bettingSlipId)
          ?.result?.ElementNotifications?.filter(isNonNullable)
          .find((item) => item.OutcomeId === itemId);

        return element?.Notification || null;
      },
      (bettingSlipId: BettingSlipIdentifierType, itemId: number) =>
        `${bettingSlipId}-${itemId}`,
    ),
);
