import { TOptions } from 'i18next';
import React, {
  ComponentProps,
  FC,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SpaceProps } from 'styled-system';

import { useTranslation } from '@gaming1/g1-i18n';
import { useMediaBreakPoint } from '@gaming1/g1-ui';
import { useGetIsMounted, usePrevious } from '@gaming1/g1-utils';

import { NotificationContainer } from '../../../notifications/components/NotificationContainer';
import { usePageMinHeight } from '../../hooks';
import { Seo } from '../Seo';

import {
  PageContainer,
  PageFluidContentContainer,
  PageResponsiveContentContainer,
  PageWrapper,
} from './styles';

type ScrollBehavior = 'upwardsOnly' | 'always' | 'off';

type LocizeKey = {
  /** Locize namespace (by default: 'core') */
  ns?: string;
  /** Locize options */
  options?: TOptions;
  /** Locize key */
  tkey: string | string[];
};

export type PageProps = {
  /** The locize props used as title for helmet */
  title: LocizeKey | null;
  /** The locize props used as description for helmet */
  description: LocizeKey | null;
  /** Specifies whether the page should automatically scroll to the top on mount (default: upwardsOnly) */
  mountScrollBehavior?: ScrollBehavior;
  /** Specifies whether the page should automatically scroll to the top on navigation (default: upwardsOnly) */
  navigationScrollBehavior?: ScrollBehavior;
  /**
   * Specifies whether the page should compute its own minimum height. When
   * the minimum height is managed by a parent for example, the automatic
   * computation can be disabled with this property.
   */
  shouldComputeMinHeight?: boolean;
  /** A mandatory testId */
  testId: string;
  /** The react children */
  children: React.ReactNode;
};

export type PageCompoundComponents = {
  Content: typeof PageResponsiveContentContainer;
  ContentFluid: typeof PageFluidContentContainer;
  Wrapper: FC<ComponentProps<typeof PageWrapper>>;
};

type PageComponent = FC<PageProps & SpaceProps> & PageCompoundComponents;

export const Page: PageComponent = ({
  title,
  description,
  mountScrollBehavior = 'upwardsOnly',
  navigationScrollBehavior = 'upwardsOnly',
  shouldComputeMinHeight = true,
  testId,
  children,
  ...props
}) => {
  const currentLocation = useLocation();
  const previousLocation = usePrevious(currentLocation);
  const history = useHistory();
  const { t: translateTitle } = useTranslation(title?.ns);
  const { t: translateDescription } = useTranslation(description?.ns);

  const translatedTitle = title
    ? translateTitle(title.tkey, title.options)
    : null;
  const translatedDescription = description
    ? translateDescription(description.tkey, description.options)
    : null;

  const hasSmallNav = useMediaBreakPoint({ max: 'md' });

  const { minHeight } = usePageMinHeight(true);

  const getIsMounted = useGetIsMounted();
  const pageContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = useCallback(
    (domElement: HTMLDivElement | null, behavior: ScrollBehavior) => {
      if (domElement) {
        const elementTop = domElement.getBoundingClientRect().top;
        const top = hasSmallNav ? 0 : window.scrollY + elementTop;
        if (
          behavior === 'always' ||
          (behavior === 'upwardsOnly' && window.scrollY > top)
        ) {
          window.scrollTo({ top, behavior: 'auto' });
        }
      }
    },
    [hasSmallNav],
  );

  const canScroll =
    // Ensure the component is still mounted
    getIsMounted() &&
    // Prevent the automatic scroll on "back" navigation
    history.action !== 'POP' &&
    // Ensure the page container is there
    !!pageContainerRef.current;

  const didNavigate = previousLocation?.pathname !== currentLocation.pathname;

  // scroll up when the component is mounted
  useLayoutEffect(() => {
    if (canScroll && mountScrollBehavior !== 'off') {
      scrollToTop(pageContainerRef.current, mountScrollBehavior);
    }
  }, [canScroll, mountScrollBehavior, scrollToTop]);

  // scroll up when navigating (only when the component is already mounted)
  useLayoutEffect(() => {
    if (canScroll && navigationScrollBehavior !== 'off' && didNavigate) {
      scrollToTop(pageContainerRef.current, navigationScrollBehavior);
    }
  }, [
    canScroll,
    getIsMounted,
    navigationScrollBehavior,
    didNavigate,
    scrollToTop,
  ]);

  return (
    <PageContainer
      data-testid={testId}
      minHeight={shouldComputeMinHeight ? minHeight : 0}
      ref={pageContainerRef}
      {...props}
    >
      <Seo title={translatedTitle} description={translatedDescription} />
      {children}
    </PageContainer>
  );
};
/**
 * This component is used to visually differentiate the page content
 * from the main background. An basic example would be a website having
 * a black main background behind white pages.
 * Do not use this component within a full page context.
 */
const PageWrapperWithNotificationContainer: FC<
  ComponentProps<typeof PageWrapper>
> = ({ children, ...rest }) => (
  <PageWrapper {...rest}>
    <NotificationContainer data-testid="pagewrapper-notificationcontainer" />
    {children}
  </PageWrapper>
);

Page.Content = PageResponsiveContentContainer;
Page.ContentFluid = PageFluidContentContainer;
Page.Wrapper = PageWrapperWithNotificationContainer;
