import { of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getGoogleTagManager,
  googleTagManagerTrackingPlaced,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { CoreEpic } from '../types';

import * as actions from './actions';
import { getGoogleTagManagerSuccessResponse } from './codecs';
import { formatGTMEvents } from './format';

/**
 * When the user change its locale, let the websocket know
 */
export const blackboxesEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.setBlackBoxes)),
    tap(({ payload }) =>
      wsAdapter.sendMetadata({
        BlackBox: payload.ioBlackBox,
        FirstPartyBlackBox: payload.fpBlackBox || '',
      }),
    ),
    ignoreElements(),
  );

/** Fetch a list of GTM events to be sent */
export const getGTMEventsEpic: CoreEpic = (
  action$,
  _,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getGTMEvents.request)),
    withLatestFrom(config$),
    switchMap(([, { room }]) =>
      wsAdapter
        .request(getGoogleTagManager({ RoomDomainName: room.roomName }))
        .pipe(
          mapGuard(getGoogleTagManagerSuccessResponse),
          map(({ DataBlocks }) => formatGTMEvents(DataBlocks)),
          map(actions.getGTMEvents.success),
          catchError((err) =>
            of(actions.getGTMEvents.failure(createFailurePayload(err))),
          ),
        ),
    ),
  );

/**
 * Dispatch an action when the gtm events are received through injected packets
 * */
export const injectedGTMEventsEpic: CoreEpic = (_, __, { wsAdapter }) =>
  wsAdapter.getInjectedRequests$('GetGoogleTagManager').pipe(
    filter(getGoogleTagManagerSuccessResponse.is),
    map(({ DataBlocks }) => formatGTMEvents(DataBlocks)),
    map(actions.getGTMEvents.success),
  );

/** Marks some gtm event ids as sent */
export const markGTMEventsAsSentEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.markGTMEventsAsSent.request)),
    switchMap(({ payload: eventIds }) =>
      wsAdapter
        .request(googleTagManagerTrackingPlaced({ PlaceDataBlock: eventIds }))
        .pipe(
          map(() => actions.markGTMEventsAsSent.success(eventIds)),
          catchError((err) =>
            of(actions.getGTMEvents.failure(createFailurePayload(err))),
          ),
        ),
    ),
  );
