/* istanbul ignore file */
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!

/** Public cms routes */
export const cmsPublicRoutes = {
  aboutUs: createParamRoute('/about-us/:content?', { content: '' }),
  responsibleGaming: createRoute('/responsible-gaming'),
};
