import React, { FC, memo, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Box } from '@gaming1/g1-ui';

import { cleanParam } from '../../../helpers';
import { useRouteConfig } from '../../../routeurHooks';

import { SportLiveMenuItem } from './SportLiveMenuItem';

type SportsLiveMenuProps = {
  isCollapsed?: boolean;
  items: {
    sportId: number | undefined;
    sportName: string;
    sportAlias: string;
    count: number | undefined;
    liveOrder: number;
  }[];
};

const SportsLiveMenuComponent: FC<SportsLiveMenuProps> = ({
  items,
  isCollapsed = false,
}) => {
  const location = useLocation();
  const routeConfig = useRouteConfig();

  const { sportId } = useMemo(() => {
    const match = matchPath<{
      sportId?: string;
    }>(location.pathname, {
      path: Object.values(routeConfig).map((route) => route.path),
      exact: true,
    });
    if (!match) {
      return {
        params: {
          sportId: null,
        },
      };
    }
    return {
      sportId: cleanParam(match.params.sportId?.split('/')[0]),
    };
  }, [routeConfig, location.pathname]);

  return items.length > 0 ? (
    <Box>
      {items.map((item) => (
        <SportLiveMenuItem
          key={item.sportId}
          isCollapsed={sportId !== item.sportId?.toString()}
          isMenuCollapsed={isCollapsed}
          item={item}
        />
      ))}
    </Box>
  ) : null;
};

export const SportsLiveMenu = memo(SportsLiveMenuComponent);
