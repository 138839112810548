/* istanbul ignore file */
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!

/** Public gaming routes */
export const gamingPublicRoutes = {
  gameList: createRoute('/games'),
  playGame: createParamRoute('/play/:gameId', { gameId: '' }),
  pendingGames: createRoute('/pending-games'),
};
