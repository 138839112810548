import { createAsyncAction } from 'typesafe-actions';

import {
  GetGameFeatureHistoryRequestDTO,
  GetShopHistoryRequestDTO,
  GetSponsorshipHistoryRequestDTO,
  ScratchCardHistoryRequest,
} from '@gaming1/g1-requests';
import { FailurePayload, PaginationList } from '@gaming1/g1-utils';

import {
  FreeSpin,
  ScratchCardHistoryElement,
  ShopOrder,
  Sponsorship,
} from './types';

/** Get game feature history */
export const getFreeSpinsHistory = createAsyncAction(
  'user/get_free_spins_history_request',
  'user/get_free_spins_history_success',
  'user/get_free_spins_history_failure',
)<GetGameFeatureHistoryRequestDTO, PaginationList<FreeSpin>, FailurePayload>();

/** Get sponsorship history */
export const getSponsorshipHistory = createAsyncAction(
  'user/get_sponsorship_history_request',
  'user/get_sponsorship_history_success',
  'user/get_sponsorship_history_failure',
)<
  GetSponsorshipHistoryRequestDTO,
  PaginationList<Sponsorship>,
  FailurePayload
>();

/** Get shop history */
export const getShopHistory = createAsyncAction(
  'user/get_shop_history_request',
  'user/get_shop_history_success',
  'user/get_shop_history_failure',
)<GetShopHistoryRequestDTO, PaginationList<ShopOrder>, FailurePayload>();

/** Get scratchcard history */
export const getScratchCardHistory = createAsyncAction(
  'user/get_scratchcard_history_request',
  'user/get_scratchcard_history_success',
  'user/get_scratchcard_history_failure',
)<
  ScratchCardHistoryRequest,
  PaginationList<ScratchCardHistoryElement>,
  FailurePayload
>();
