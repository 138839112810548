import * as t from 'io-ts';

import {
  EGetFavoriteGameListStatus,
  ESetFavoriteGameListStatus,
} from '@gaming1/g1-requests-gaming';

import { game } from '../../common/store/codecs';

export const getFavoriteRecentGames = t.type(
  {
    Status: t.literal(EGetFavoriteGameListStatus.Successful),
    List: t.array(game),
  },
  'FavoriteRecentGames',
);

export const setFavoriteGameListResponse = t.type({
  Status: t.literal(ESetFavoriteGameListStatus.Successful),
});

export const favoriteRecentGamesData = t.type({
  entities: t.type({
    favoriteRecentGames: t.record(t.string, game),
  }),
  result: t.array(t.string),
  meta: t.type({
    Status: t.number,
  }),
});
