import React, { FC, memo, useContext } from 'react';
import { shallowEqual } from 'react-redux';

import {
  riskManagementNotificationGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

const BettingSlipRiskManagementErrorComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const notification = useBettingGetterSelector({
    getterSelector: riskManagementNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  if (!notification) {
    return null;
  }

  return (
    <Box
      mb="sm"
      data-testid="bettingslip-riskmanagement-errormessage-container"
    >
      <BettingSlipMessage
        testId={`bettingslip-riskmanagement-message-${notification.Code}`}
        notification={notification}
      />
    </Box>
  );
};

export const BettingSlipRiskManagementError = memo(
  BettingSlipRiskManagementErrorComponent,
);
