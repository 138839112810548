import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable } from '@gaming1/g1-utils';

import { freebetsStateSelector } from './common';
import { groupFreebets } from './helpers';

export const freebetsUnlockedListGettorSelector = createSelector(
  freebetsStateSelector,
  (freebetsState) =>
    freebetsState.result?.Freebets?.filter(isNonNullable) || [],
);

export const freebetsNbUnlockedGettorSelector = createSelector(
  freebetsUnlockedListGettorSelector,
  (list) => list?.length || 0,
);

export const freebetsGroupedGettorSelector = createSelector(
  freebetsUnlockedListGettorSelector,
  (list) => groupFreebets(list),
);

/**
 * Get every freebets that do NOT have a condition id in their properties
 */
export const getFreebetsWithoutAnyConditionIdGetterSelector = createSelector(
  freebetsUnlockedListGettorSelector,
  (list) => (list ? list.filter((freebet) => !freebet.ConditionId) : []),
);

/**
 * Get the freebets without any condition id and return them grouped
 */
export const getGroupedFreebetsWithoutAnyConditionIdGetterSelector =
  createSelector(
    getFreebetsWithoutAnyConditionIdGetterSelector,
    (listWithoutConditionId) => groupFreebets(listWithoutConditionId),
  );

/**
 * Get every freebets that do HAVE a condition id included in the identifiers given
 */
export const getFreebetsCorrespondingToConditionIdsGetterSelector =
  createSelector(freebetsUnlockedListGettorSelector, (list) =>
    memoize(
      (ids: string[]) =>
        list &&
        ids &&
        list
          .filter((freebet) => freebet.ConditionId)
          .filter((freebet) => ids.includes(freebet.ConditionId || '')),
    ),
  );

/** Get the freebets with a condition id that is included in the identifiers given and then return them grouped */
export const getGroupedFreebetsCorrespondingToConditionIdsGetterSelector =
  createSelector(
    getFreebetsCorrespondingToConditionIdsGetterSelector,
    (conditionFreebets) =>
      memoize((conditionIds: string[]) =>
        groupFreebets(conditionFreebets(conditionIds)),
      ),
  );
