import { useConfig } from '@gaming1/g1-config';

const ANDROID_STORE_BASE_URL = 'https://play.google.com/store/apps/details?id=';
const IOS_STORE_BASE_URL = 'https://apps.apple.com/app/apple-store/id';

/** Hook that returns Android/iOS store app links */
export const useAppStoreLinks = () => {
  const { core } = useConfig();
  const { android, ios } = core.mobileAppIdentifiers;

  return {
    android: android ? `${ANDROID_STORE_BASE_URL}${android}` : undefined,
    ios: ios ? `${IOS_STORE_BASE_URL}${ios}` : undefined,
  };
};
