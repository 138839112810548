import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getGame, GetGameRequestDTO } from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import { getGame as getGameCodec } from './codecs';
import { gameErrorMessages } from './errorMessages';

export const startGameEpic: GamingEpic = (actions$, _, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.getGame.request)),
    mergeMap(({ payload }) => {
      const params: GetGameRequestDTO = {
        GameId: payload.GameId,
      };
      return wsAdapter.request(getGame(params)).pipe(
        mapGuard(getGameCodec),
        map((response) => actions.getGame.success(response.Game)),
        catchError((err) =>
          of(
            actions.getGame.failure(
              createFailurePayload(err, gameErrorMessages),
            ),
          ),
        ),
      );
    }),
  );
