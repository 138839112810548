import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { BettingHistoryTicketType } from '@gaming1/g1-betting';
import {
  AssociatedPromoType,
  CreditType,
  PromotionState,
} from '@gaming1/g1-requests-betting';
import { Icon } from '@gaming1/g1-ui';

import { OpacityContainer } from '../../../styles';
import { HEADER_ICON_HEIGHT_IN_PX, HEADER_ICON_WIDTH_IN_PX } from '../styles';

/**
 * Will check the given {ticket} and check if it has a boostus, a freebet or a daily offer associated promotion.
 *
 * If it contains a Boostus => We display the Icon of the Boostus;
 * If it contains a INVALID Boostus => We display the Icon of the Boostus with an opacity;
 * If it contains a Freebet => We display the Icon of the Freebet;
 * If it contains a Daily Offer => We display the Icon of the Daily Offers
 * If none of the above => We return null
 */

export type HistoryCardHeaderPromotionIconProps = {
  historyTicket: BettingHistoryTicketType;
};

export const HistoryCardHeaderPromotionIconV2: FC<
  HistoryCardHeaderPromotionIconProps
> = ({ historyTicket }) => {
  const { colors } = useTheme();

  // We check if the ticket has a Boostus and if it's valid
  if (historyTicket.AssociatedPromotion?.Boostus) {
    const boostusIcon = (
      <Icon
        id="BoostusWithBackground"
        type="BoostusWithBackground"
        height={HEADER_ICON_HEIGHT_IN_PX}
        width={HEADER_ICON_WIDTH_IN_PX}
        color={colors.bettingBoostus}
        ml="xxs"
      />
    );
    if (historyTicket.AssociatedPromotion?.Boostus.IsValid) {
      return boostusIcon;
    }
    return (
      <OpacityContainer data-testid="header-boostus-invalid-container">
        {boostusIcon}
      </OpacityContainer>
    );
  }

  if (
    historyTicket.AssociatedPromotion?.Types?.includes(
      AssociatedPromoType.Promotion,
    )
  ) {
    const promotionIcon = (
      <Icon
        id="BettingPromotionWithBackground"
        type="BettingPromotionWithBackground"
        height={HEADER_ICON_HEIGHT_IN_PX}
        width={HEADER_ICON_WIDTH_IN_PX}
        color={colors.bettingPromotions}
        ml="xxs"
      />
    );
    if (
      historyTicket.AssociatedPromotion?.Promotion &&
      historyTicket.AssociatedPromotion?.Promotion.State !==
        PromotionState.NotValidated
    ) {
      return promotionIcon;
    }
    return <OpacityContainer>{promotionIcon}</OpacityContainer>;
  }

  if (historyTicket.CreditType === CreditType.Freebet) {
    return (
      <Icon
        type="FreebetV2"
        id="FreebetV2"
        height={HEADER_ICON_HEIGHT_IN_PX}
        width={HEADER_ICON_WIDTH_IN_PX}
        color={colors.bettingFreebet}
        ml="xxs"
      />
    );
  }

  if (
    historyTicket.AssociatedPromotion?.Types?.includes(
      AssociatedPromoType.DailyOffer,
    )
  ) {
    return (
      <Icon
        type="DailyOfferWithBackground"
        id="DailyOfferWithBackground"
        height={HEADER_ICON_HEIGHT_IN_PX}
        width={HEADER_ICON_WIDTH_IN_PX}
        color={colors.bettingDailyOffers}
        ml="xxs"
      />
    );
  }

  if (
    historyTicket.AssociatedPromotion?.Types?.includes(
      AssociatedPromoType.SuperOdds,
    )
  ) {
    return (
      <Icon
        type="SuperOddsWithBackground"
        id="SuperOddsWithBackground"
        height={HEADER_ICON_HEIGHT_IN_PX}
        width={HEADER_ICON_WIDTH_IN_PX}
        color={colors.bettingSuperOdds}
        ml="xxs"
      />
    );
  }

  if (historyTicket.OptimalBetInformation) {
    const optimalBetIcon = (
      <Icon
        type="OptimalBet"
        id="OptimalBet"
        height={HEADER_ICON_HEIGHT_IN_PX}
        width={HEADER_ICON_WIDTH_IN_PX}
        color={colors.bettingOptimalBet}
        ml="xxs"
      />
    );
    if (historyTicket.OptimalBetInformation.OptimalBetApplied) {
      return optimalBetIcon;
    }
    return <OpacityContainer>{optimalBetIcon}</OpacityContainer>;
  }

  return null;
};
