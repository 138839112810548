import { GeoComplyStatusCode, GeolocationReason } from '@gaming1/g1-core';

export type GeoComplyEnv = 'staging' | 'production';

export type GeoComplyEventsTypes = 'connect' | 'error' | 'geolocation' | 'log';

// Convert the enum to a record to be able to merge it into GeoComplyClient
type GeoComplyStatusCodeRecord = Record<
  keyof typeof GeoComplyStatusCode,
  number
>;

export type GeoComplyClient = GeoComplyStatusCodeRecord & {
  /**
   * Connects Client object to PLC (local service).
   * @param installerKey provided by GeoComply to download PLC from UMS server
   * @param environmentId The id of the environment, to which Web SDK is deployed. Possible values – (staging, production)
   */
  connect: (installerKey: string, environmentId: GeoComplyEnv) => void;
  /**
   * Object that implements methods to manage custom fields associated with the geolocation transaction.
   * The custom fields should be set before calling .requestGeolocation()
   */
  customFields: {
    /**
     * Sets a custom field based
     * @param key string identifying the custom field
     * @param val string value
     */
    set: (key: string, val: string) => void;
    /**
     * Get a custom field by key
     * @param key identifier
     */
    get: (key: string) => void;
    /**
     * Removes a custom field front the collection
     * @param key identifier
     */
    remove: (key: string) => void;
    /** Clears all the custom fields */
    clear: () => void;
  };
  /** Disconnects Client object from PLC (local service) and releases internal objects. */
  disconnect: () => GeoComplyClient;
  /** Returns the code of the last error reported by the Client object. */
  getLastErrorCode: () => number;
  /** Returns the version of GeoComply SDK. */
  getVersion: () => string;
  /** Returns true if Client object is connected to PLC (local service). */
  isConnected: () => boolean;
  /** Triggers geolocation process. */
  requestGeolocation: () => void;
  /** Sets the geolocation reason */
  setGeolocationReason: (reason: GeolocationReason) => void;
  /** Sets the license string required by Client object to start geolocation. */
  setLicense: (license: string) => void;
  /** Sets the geolocating user’s id. This is a required field. */
  setUserId: (userId?: string) => void;

  /**
   * Subscribes to GeoComply's connection
   *
   * @param event connect
   * @param handler Callback fired after the connection
   *
   * @returns the instance of the GeoComply's Client
   */
  on(event: 'connect', handler: () => void): GeoComplyClient;
  /**
   * Subscribes to GeoComply's errors
   *
   * @param event error
   * @param handler Callback containing the errorCode (string) and the errorMessage (string)
   *
   * @returns the instance of the GeoComply's Client
   */
  on(
    event: 'error',
    handler: (errorCode: string, errorMessage: string) => void,
  ): GeoComplyClient;
  /**
   * Subscribes to GeoComply's geolocation
   *
   * @param event geolocation
   * @param handler Callback fired after the connection
   *
   * @returns the instance of the GeoComply's Client
   */
  on(event: 'geolocation', handler: (data: string) => void): GeoComplyClient;
  /**
   * Subscribes to GeoComply's log
   *
   * @param event log
   * @param handler Callback fired after the connection
   *
   * @returns the instance of the GeoComply's Client
   */
  on(event: 'log', handler: (message: string) => void): GeoComplyClient;
  /**
   * Unsubscribes from a GeoComply's
   *
   * @param event
   *
   * @returns the instance of the GeoComply's Client
   */
  off(event: GeoComplyEventsTypes): GeoComplyClient;
};

export type GeoComplyEvents = {
  /**
   * Add an event listender
   *
   * @param ref HTML DOM Element
   * @param event Mouse event
   * @param callBack callback fired after the event was added
   *
   * @returns void
   */
  add: (ref: HTMLElement, event: MouseEvent, callBack: () => void) => void;
  /**
   * Remove an event listender
   *
   * @param ref HTML DOM Element
   * @param event Mouse event
   * @param callBack callback fired after the event was added
   *
   * @returns void
   */
  remove: (ref: HTMLElement, event: MouseEvent, callBack: () => void) => void;
  /**
   * Event fired when GeoComply is ready
   *
   * @param window Window element
   * @param callBack callback fired after the event was added
   *
   * @returns void
   */
  ready: (window: Window, callback: () => void) => void;
};

export type GeoComply = {
  /**
   * allows the operator’s app to communicate with the PLC,
   * receive events and trigger geolocation to receive location data.
   * This object is already instantiated and can be accessible through GeoComply namespace
   */
  Client: GeoComplyClient;
  /**
   * Add event listeners on main events and handle them properly
   * through Geocomply.Events
   */
  Events: GeoComplyEvents;
};

type WindowWithGeoComplySDK = Window & {
  GeoComply?: GeoComply;
};

export const windowWithGeoComplySDK = window as WindowWithGeoComplySDK;
