import { transparentize } from 'polished';
import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';
import { Box, Text } from '@gaming1/g1-ui';

export const ScoreBoardPlayTimeWrapper = styled(Box)`
  ${Text} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: ${colors('text', { transform: transparentize('0.35') })};
  }
`;
