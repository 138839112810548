import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import reverse from 'lodash/reverse';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import {
  ColumnReorder,
  EventType as EEventType,
} from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { EventState } from '../../../common/store/types';
import { NormalizedMarket, NormalizedOutcome } from '../types';

import { marketsSelector, outcomesSelector } from './entities';
import {
  isMarketAvailableSelector,
  isMarketReversibleSelector,
  marketsByPlayerPropIdsSelector,
  SCMByIdSelector,
} from './markets';
import { playerPropColsSelector } from './playerProps';

/**
 * Return a function that takes an outcome id and returns an outcome
 */
export const outcomeSelector = createSelector(outcomesSelector, (outcomes) =>
  memoize(
    (outcomeId: number, filterDeleted: boolean | null = true) =>
      outcomes[outcomeId]?.deleted && filterDeleted
        ? undefined
        : outcomes[outcomeId],
    // Cache key resolver
    (outcomeId: number, filterDeleted = true) =>
      `${outcomeId}${!!filterDeleted}`,
  ),
);

/**
 * Return a function that takes an outcome id and returns its avaibility as a boolean
 */
export const isOutcomeAvailableSelector = createSelector(
  outcomesSelector,
  isMarketAvailableSelector,
  (outcomesS, isMarketAvailableS) =>
    memoize((outcomeId: number): boolean => {
      const outcome = outcomesS[outcomeId];
      if (!outcome || outcome.Odd === -1 || outcome.deleted) {
        return false;
      }

      if (!outcome.MarketId) {
        return true;
      }

      return isMarketAvailableS(outcome.MarketId);
    }),
);

/**
 * Return a function that takes a list of outcome ids and returns an array of outcomes
 * Don't forget to use shallowEqual with this selector
 */
export const outcomeListSelector = createSelector(
  outcomesSelector,
  (outcomes) =>
    memoize(
      (outcomeIds: number[], filterDeleted: boolean | null = true) =>
        outcomeIds?.map((outcomeId) =>
          outcomes[outcomeId]?.deleted && filterDeleted
            ? undefined
            : outcomes[outcomeId],
        ),

      // Cache key resolver
      (outcomeIds: number[], filterDeleted = true) =>
        `${outcomeIds?.join('-')}${!!filterDeleted}`,
    ),
);

/**
 * Return a function that takes a player marketId and a colPosition and returns a player outcome
 */
export const outcomeByColPositionSelector = createSelector(
  marketsSelector,
  outcomeListSelector,
  (getMarkets, getOutcomes) =>
    memoize(
      (marketId: number, colPosition: number) => {
        const pMarket = getMarkets[marketId];
        const outcomes = getOutcomes(pMarket?.Outcomes || []);
        return outcomes.find((o) => o?.ColPosition === colPosition);
      },

      // Cache key resolver
      (marketId: number, colPosition: number) => `${marketId}${colPosition}`,
    ),
);

/**
 * Takes a player prop id(s) and returns the col position of the first available outcome
 */
export const firstOutcomeColForPlayerPropSelector = createSelector(
  playerPropColsSelector,
  marketsByPlayerPropIdsSelector,
  outcomeByColPositionSelector,
  (getCols, getMarkets, getOutcome) =>
    memoize(
      (playerPropIds: number[]) => {
        const cols = getCols(playerPropIds[0]);
        const market = getMarkets(playerPropIds)[0];
        if (!cols.length || !market) {
          return -1;
        }

        return (
          cols.find((col) => !!getOutcome(market.MarketId, col.Position))
            ?.Position || -1
        );
      },

      // Cache key resolver
      (playerPropIds: number[]) => `${playerPropIds?.join('-')}`,
    ),
);

/**
 * Return a function that takes a list of markets and returns an array containing all outcomes
 * Don't forget to use shallowEqual with this selector
 */
export const outcomesFromMarketListSelector = createSelector(
  outcomeListSelector,
  (getOutcomes) =>
    memoize(
      (markets: NormalizedMarket[]) => {
        const outcomes = (
          markets?.map((market) => {
            const propsOrder =
              market?.ColumnReorder === ColumnReorder.ByOutcome
                ? ['Order']
                : ['GroupOrder', 'Order'];

            return orderBy(getOutcomes(market?.Outcomes || []), propsOrder);
          }) || []
        )
          .reduce((acc, cur) => [...acc, ...cur], [])
          .filter(isNonNullable);

        const hasBase = markets.some((m) => m.Base != null);

        return hasBase
          ? outcomes.sort(
              (a, b) => Math.abs(a.Base || 0) - Math.abs(b.Base || 0),
            )
          : outcomes;
      },

      // Cache key resolver
      (markets: NormalizedMarket[]) =>
        `${markets?.map((m) => m?.MarketId).join('-')}`,
    ),
);

/**
 * Return outcome col index
 */
export const getOutcomeColsIndex = (
  outcome: NormalizedOutcome,
  index: number,
) =>
  outcome.GroupOrder // && outcome.ShortName
    ? (outcome.GroupOrder - 1) % 3
    : index % 3;

/**
 * Returns marketList outcomes for a specified column index
 * Don't forget to use shallowEqual with this selector
 */
export const outcomeColSelector = createSelector(
  outcomesFromMarketListSelector,
  (getOutcomes) =>
    memoize(
      (markets: NormalizedMarket[], index: number): NormalizedOutcome[] => {
        const outcomes = getOutcomes(markets).filter(
          (o, i) => getOutcomeColsIndex(o, i) === index,
        );
        const hasBase = markets.some((m) => m.Base != null);

        return hasBase
          ? outcomes.sort(
              (a, b) => Math.abs(a.Base || 0) - Math.abs(b.Base || 0),
            )
          : outcomes;
      },

      // Cache key resolver
      (markets: NormalizedMarket[], index: number) =>
        `${markets?.map((m) => m?.MarketId).join('-')}${index}`,
    ),
);

/**
 * Returns an array that contains outcome cols indexes (reversed if needed)
 * Don't forget to use shallowEqual with this selector
 */
export const outcomeColsIndexesSelector = createSelector(
  outcomesFromMarketListSelector,
  isMarketReversibleSelector,
  (getOutcomes, getIsMarketReversible) =>
    memoize(
      (markets: NormalizedMarket[]) => {
        const indexes = uniq(
          getOutcomes(markets).map(getOutcomeColsIndex),
        ).sort();

        return getIsMarketReversible(markets[0]) ? reverse(indexes) : indexes;
      },

      // Cache key resolver
      (markets: NormalizedMarket[]) =>
        `${markets?.map((m) => m?.MarketId).join('-')}`,
    ),
);

/**
 * Returns outcomes for a specified markets (reversed if needed)
 * Don't forget to use shallowEqual with this selector
 */
export const outcomeIdsByMarketSelector = createSelector(
  isMarketReversibleSelector,
  outcomeListSelector,
  SCMByIdSelector,
  (getIsMarketReversible, getOutcomes, getSCM) =>
    memoize(
      (
        market: NormalizedMarket | undefined,
        sportId: number,
        eventType: EventState,
      ) => {
        const sortDirection =
          market && getIsMarketReversible(market) ? 'desc' : 'asc';

        // Get sorted outcomes
        const outcomeIds = market?.Outcomes || [];
        const outcomes = getOutcomes(outcomeIds).filter(isNonNullable);

        // Get SCM (to know the number of outcomes that we should have)
        const type =
          eventType === 'live' ? EEventType.Live : EEventType.Prematch;
        const scm = getSCM(market?.ShowcaseMarketId || '', sportId, type);

        // No SCM found => return ids 'as is'
        if (!scm?.Columns?.length) {
          return orderBy(outcomes, ['GroupOrder', 'Order'], sortDirection).map(
            (o) => o.OutcomeId,
          );
        }

        // Check for missing outcome indexes
        const toIndex =
          (scm?.Columns?.length || 0) + (market?.Base == null ? 1 : 0);
        const indexes = range(1, toIndex);

        // Add missing outcomes at good place if needed
        const mappedOutcomes = orderBy(
          indexes.map((i) => outcomes.find((o) => o.GroupOrder === i)),
          ['GroupOrder', 'Order'],
          sortDirection,
        );

        return mappedOutcomes.map((o) => o?.OutcomeId);
      },

      // Cache key resolver
      (
        market: NormalizedMarket | undefined,
        sportId: number,
        eventType: EventState,
      ) => `${market?.MarketId}${sportId}${eventType}`,
    ),
);
