import { SetLimitsRequestDTO } from '@gaming1/g1-requests';

import { setLimitsErrorMessages } from './errorMessages';
import {
  GetDepositLimitsResponse,
  GetLimitsResponse,
  SetLimitsRequest,
  SetLimitsResponse,
  SetLimitsResult,
} from './types';

/**
 * format limits and set limited boolean to true if the value has changed or is above 0
 */
export const formatLimits = (
  payload: SetLimitsRequest,
  initialLimits?: GetDepositLimitsResponse | GetLimitsResponse | null,
): Omit<SetLimitsRequestDTO, 'ActivitySource' | 'ActivityType'> => {
  const payloadRequest = {
    DailyLimit: payload.DailyLimit,
    DailyLimited: true,
    WeeklyLimit: payload.WeeklyLimit,
    WeeklyLimited: true,
    MonthlyLimit: payload.MonthlyLimit,
    MonthlyLimited: true,
    YearlyLimit: -1, // YearlyLimit is not used (-1 (unlimited) by default)
    YearlyLimited: false, // YearlyLimited is not used (false by default)
  };

  if (initialLimits) {
    payloadRequest.DailyLimited =
      initialLimits.DailyLimit !== payload.DailyLimit ||
      initialLimits.DailyLimited;
    payloadRequest.WeeklyLimited =
      initialLimits.WeeklyLimit !== payload.WeeklyLimit ||
      initialLimits.WeeklyLimited;
    payloadRequest.MonthlyLimited =
      initialLimits.MonthlyLimit !== payload.MonthlyLimit ||
      initialLimits.MonthlyLimited;
  }

  return payloadRequest;
};

const isLimitErrorKey = (
  key: number,
): key is keyof typeof setLimitsErrorMessages => key in setLimitsErrorMessages;

/**
 * format limits response and replace periodicities status by their message keys
 */
export const formatSetLimitsResponseToSetLimitsResult = (
  response: SetLimitsResponse,
): SetLimitsResult => ({
  DailyLimitChangeMessage: isLimitErrorKey(response.DailyLimitChangeStatus)
    ? setLimitsErrorMessages[response.DailyLimitChangeStatus]
    : '',
  WeeklyLimitChangeMessage: isLimitErrorKey(response.WeeklyLimitChangeStatus)
    ? setLimitsErrorMessages[response.WeeklyLimitChangeStatus]
    : '',
  MonthlyLimitChangeMessage: isLimitErrorKey(response.MonthlyLimitChangeStatus)
    ? setLimitsErrorMessages[response.MonthlyLimitChangeStatus]
    : '',
});
