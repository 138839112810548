import { createAsyncAction } from 'typesafe-actions';

import { UpdatePlayerPreferencesRequest } from '@gaming1/g1-requests-betting';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetUserPreferencesResponseType,
  UpdateUserPreferencesResponseType,
} from './types';

/** Get betting user settings */
export const getBettingUserSettings = createAsyncAction(
  'betting/get_betting_settings_request',
  'betting/get_betting_settings_success',
  'betting/get_betting_settings_failure',
)<void, GetUserPreferencesResponseType, FailurePayload>();

/** Update betting user settings */
export const updateBettingUserSettings = createAsyncAction(
  'betting/update_betting_settings_request',
  'betting/update_betting_settings_success',
  'betting/update_betting_settings_failure',
)<
  UpdatePlayerPreferencesRequest,
  UpdateUserPreferencesResponseType,
  FailurePayload
>();
