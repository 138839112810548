import { ReplaySubject } from 'rxjs';

import { BetPipeType } from '@gaming1/g1-config';

export type CommonDebugLog = {
  time: number;
  id: string;
  info: string;
};

export type BetPipeDebugLog = {
  time: number;
  id: string;
  type: BetPipeType;
  inputSteps: string[];
  outputSteps: string[];
};
export const betPipeDebugLogs$ = new ReplaySubject<BetPipeDebugLog>(200);

export const cashoutDebugLogs$ = new ReplaySubject<CommonDebugLog>(200);
