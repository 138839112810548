import React, { FC, memo, useContext } from 'react';

import {
  actions,
  bettingSlipExtraInfoGetterSelector,
  bettingSlipStakeGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  isHighlightStakeByError,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
  useBettingSlipGetItemMaxStake,
  useBettingSlipSingleErrorFrontType,
} from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { OpacityContainer } from '../../../history/components/styles';
import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipSingleError } from '../BettingSlipError/BettingSlipSingleError';
import { BettingSlipNumpad } from '../BettingSlipNumpad';
import { useNumpadActionManage } from '../BettingSlipNumpad/hooks';

import { BettingSlipStakeBox } from './styles';

import { BettingSlipStake } from '.';

type BettingSlipItemSingleStakeProps = {
  /** id to find ticket item */
  id: number;
};

/**
 * Display the stake for an item
 * show and manage stake
 */
export const BettingSlipSingleStakeComponent: FC<
  BettingSlipItemSingleStakeProps
> = ({ id }) => {
  const { t } = useTranslation('betting');
  const { bettingSlipId } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const {
    hasKeyBoardCapabilities,
    showSuggestion,
    showKeyBoard,
    showNumpad,
    focusChanged,
    closeKeyboard,
  } = useNumpadActionManage(bettingSlipId);

  const updateStake = (newStake: string) => {
    allowBSActions(
      actions.updateStake({
        stake: newStake,
        bettingSlipId,
      }),
    );
  };
  const stakeMax = useBettingSlipGetItemMaxStake(bettingSlipId)(id);

  const stake = useBettingGetterSelector({
    getterSelector: bettingSlipStakeGetterSelector,
    args: [bettingSlipId],
  });

  const stakeInFloat = useBettingGetterSelector({
    getterSelector: bettingSlipStakeInFloatGetterSelector,
    args: [bettingSlipId],
  });

  // The extra data could be use in the case of manual bet, as we don't have
  // any risk management when we open a fast betting slip (manual bet).
  const extraDataOfBettingSlip = useBettingGetterSelector({
    getterSelector: bettingSlipExtraInfoGetterSelector,
    args: [bettingSlipId],
  });

  const isErrorStake = isHighlightStakeByError(
    useBettingSlipSingleErrorFrontType(bettingSlipId)(),
  );

  const formatMoney = useFormatMoney();

  return (
    <>
      <BettingSlipStakeBox data-testid="bettingslip-single-stake-input-container">
        <Box flexDirection="row">
          {t('bettingslip.single.stake')}
          {!!extraDataOfBettingSlip?.limits?.max && (
            <OpacityContainer>
              <Text ml="xxs" mt={0}>
                {t('bettingslip.single.showMax.stake', {
                  stakeMax: formatMoney(extraDataOfBettingSlip?.limits.max),
                })}
              </Text>
            </OpacityContainer>
          )}
        </Box>
        <BettingSlipStake
          inError={isErrorStake}
          testId="bettingslip-single-stake-input"
          stake={stake}
          stakeInFloat={stakeInFloat}
          updateStake={updateStake}
          focusChanged={focusChanged}
          hasKeyBoardCapabilities={hasKeyBoardCapabilities}
          isKeyboardDisplayed={showKeyBoard}
        />
      </BettingSlipStakeBox>

      <BettingSlipSingleError stake={stake || ''} />
      {showNumpad ? (
        <BettingSlipNumpad
          showSuggestion={showSuggestion}
          showKeyBoard={showKeyBoard}
          stake={stake?.toString() || ''}
          stakeMax={stakeMax}
          updateStake={updateStake}
          askClose={closeKeyboard}
        />
      ) : undefined}
    </>
  );
};

export const BettingSlipSingleStake = memo(BettingSlipSingleStakeComponent);
