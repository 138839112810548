import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { LoyaltyActions } from '../../types';
import * as actions from '../actions';

import { initialState } from './initialState';

export const freeSpinsHistoryReducer = (
  state = initialState,
  action: LoyaltyActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getFreeSpinsHistory.request):
        produceLoadingState(draftState.requests.getGameFeatureHistory);
        break;

      case getType(actions.getFreeSpinsHistory.failure):
        produceFailureState(
          draftState.requests.getGameFeatureHistory,
          action.payload,
        );
        break;

      case getType(actions.getFreeSpinsHistory.success):
        produceSuccessState(draftState.requests.getGameFeatureHistory);

        draftState.freeSpins =
          action.payload.pagination.PageNumber > 0 && state.freeSpins
            ? [...state.freeSpins, ...action.payload.list]
            : action.payload.list;

        draftState.requests.getGameFeatureHistory.pagination =
          action.payload.pagination;
        break;

      default: // Immer will automatically return the state
    }
  });
