/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:20:22 15-03-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetRunningJackpotsRequestDTO,
  GetRunningJackpotsResponseDTO,
} from '../entities';

export const getRunningJackpots = (options: GetRunningJackpotsRequestDTO) =>
  createRequest<GetRunningJackpotsResponseDTO>(
    107,
    'GetRunningJackpots',
    options,
  );
