import { createAsyncAction } from 'typesafe-actions';

import { GetGameRequestDTO } from '@gaming1/g1-requests-gaming';
import { FailurePayload } from '@gaming1/g1-utils';

import { Game } from '../../common/store/types';

export const getGame = createAsyncAction(
  'gaming/get_game_request',
  'gaming/get_game_success',
  'gaming/get_game_failure',
)<GetGameRequestDTO, Game, FailurePayload>();
