import * as t from 'io-ts';

import { typedKeyValueCodec } from '../codecs';
import { NotificationType } from '../types';

export enum EFrontErrorType {
  DirtyOdd,
  None,
  NoStake,
  InvalidOdd,
  NotEnoughCredit,
  Min,
  Max,
  MinMax,
  TooManyElementsInSystem,
  InvalidBoostus,
  InvalidFreebet,
  InvalidPromotion,
  BoostusMaxProfit,
  NoStakeWithMin,
  FirstDepositRequired,
}

/**
 * Notification
 */
export type BettingSlipNotification = NotificationType & {
  Status?: EFrontErrorType;
};
export type BettingSlipNotificationMessageParameter = t.TypeOf<
  typeof typedKeyValueCodec
>;
