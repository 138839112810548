import { PaginationList } from '@gaming1/g1-utils';

import {
  Deposit,
  GetDepositHistoryResponse,
  GetWithdrawHistoryResponse,
  Withdraw,
} from './types';

export const formatDepositHistory = (
  response: GetDepositHistoryResponse,
): PaginationList<Deposit> => {
  const { List, PageNumber, PageSize, Total } = response;

  return {
    list: List,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};

export const formatWithdrawalHistory = (
  response: GetWithdrawHistoryResponse,
): PaginationList<Withdraw> => {
  const { List, PageNumber, PageSize, Total } = response;

  return {
    list: List,
    pagination: {
      PageNumber,
      PageSize,
      Total,
    },
  };
};
