import styled, { css } from 'styled-components';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';
import { Box, Text } from '@gaming1/g1-ui';

import { RibbonPlacement } from './types';

const RIBBON_HEIGHT_IN_PX = 30;
const RIBBON_TEXT_LINE_HEIGHT_IN_PX = 32;
const RIBBON_LETTER_SPACING_IN_PX = 0.3;
const RIBBON_SKEW_DEGREE = 20;
const RIBBON_TEXT_SHADOW = '0 2px 0px #00000026';
const RIBBON_BORDER_RADIUS_IN_PX = 4;
const RIBBON_PLACED_INSIDE_WRAPPER_BUFFER_IN_PX = 8;
const RIBBON_PLACED_OUTSIDE_WRAPPER_BUFFER_IN_PX = 4;

type RibbonWrapperProps = {
  hasGutter: boolean;
  placement: RibbonPlacement;
  top: number;
};

export const RibbonWrapper = styled.div<RibbonWrapperProps>`
  align-items: center;
  display: flex;
  height: ${RIBBON_HEIGHT_IN_PX}px;
  overflow: hidden;
  position: absolute;

  ${({ top }) =>
    top !== 0 &&
    css`
      top: ${top}px;
    `};

  ${({ placement }) =>
    placement === 'inside' &&
    css`
      left: ${RIBBON_PLACED_INSIDE_WRAPPER_BUFFER_IN_PX}px;
      border-radius: ${RIBBON_BORDER_RADIUS_IN_PX}px 0 0
        ${RIBBON_BORDER_RADIUS_IN_PX}px;
    `};

  ${({ placement, hasGutter }) =>
    placement === 'outside' && !hasGutter
      ? css`
          left: -${RIBBON_PLACED_OUTSIDE_WRAPPER_BUFFER_IN_PX}px;
          border-radius: ${RIBBON_BORDER_RADIUS_IN_PX}px 0 0
            ${RIBBON_BORDER_RADIUS_IN_PX}px;
        `
      : css`
          left: ${RIBBON_PLACED_OUTSIDE_WRAPPER_BUFFER_IN_PX}px;
          border-radius: ${RIBBON_BORDER_RADIUS_IN_PX}px 0 0
            ${RIBBON_BORDER_RADIUS_IN_PX}px;
        `};

  ${({ placement }) =>
    placement === 'none' &&
    css`
      left: 0px;
      border-radius: 0 ${RIBBON_BORDER_RADIUS_IN_PX}px
        ${RIBBON_BORDER_RADIUS_IN_PX}px 0;
    `};
`;

type RibbonProps = {
  color: string;
};

export const RibbonContainer = styled(Box)<RibbonProps>`
  ${fontWeight('bold')}
  align-items: center;
  border-radius: 0 ${RIBBON_BORDER_RADIUS_IN_PX}px
    ${RIBBON_BORDER_RADIUS_IN_PX}px 0;
  color: ${colors('text')};
  flex-direction: row;
  height: ${RIBBON_HEIGHT_IN_PX}px;
  justify-content: center;
  position: relative;
  padding-left: ${spaces('sm')};
  padding-right: ${spaces('sm')};
  right: ${spaces('xs')};
  text-align: center;
  transform: skew(-${RIBBON_SKEW_DEGREE}deg, 0);

  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `}
`;

export const RibbonText = styled(Text)`
  font-size: ${fontSizes('lg')};
  line-height: ${RIBBON_TEXT_LINE_HEIGHT_IN_PX}px;
  letter-spacing: ${RIBBON_LETTER_SPACING_IN_PX}px;
  text-shadow: ${RIBBON_TEXT_SHADOW};
  transform: skew(${RIBBON_SKEW_DEGREE}deg, 0);
`;

export const RibbonIcon = styled(Box)`
  padding-right: ${spaces('xs')};
  transform: skew(${RIBBON_SKEW_DEGREE}deg, 0);
`;
