import React, { useContext, useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  getPlayerMonthlyBoxInfoSelector,
  getPlayerWheelInfoSelector,
  loyaltyActions,
} from '@gaming1/g1-loyalty';
import { Icon, MenuItemProfile } from '@gaming1/g1-ui';

import { loyaltyRoutes } from '../../../routes';
import { MOBILE_GAMES_ICON_SIZE } from '../../constants';

import {
  MenuIconTextColored,
  NotificationMobile,
  RoundIconMenuWrapperInactive,
  RoundIconMenuWrapperRelative,
} from './styles';

export const ClubFeatureLinksMobile: VFC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('loyalty');
  const { colors } = useContext(ThemeContext);
  const { loyalty } = useContext(ConfigContext);
  const getLoyaltyPath = useRoutePath(loyaltyRoutes);

  const playerMonthlyBoxInfo = useSelector(getPlayerMonthlyBoxInfoSelector);
  const playerWheelInfo = useSelector(getPlayerWheelInfoSelector);

  useEffect(() => {
    if (loyalty.shop || loyalty.fullShop) {
      dispatch(loyaltyActions.getPlayerMonthlyBoxInfo.request());
      dispatch(loyaltyActions.getPlayerWheelInfo.request());
    }
  }, [dispatch, loyalty.shop, loyalty.fullShop]);

  const wheelColor = playerWheelInfo?.CanPlayWheel ? colors.text : colors.muted;
  const giftboxColor = playerMonthlyBoxInfo?.CanPlayMonthlyBox
    ? colors.text
    : colors.muted;
  const WheelWrapper = playerWheelInfo?.CanPlayWheel
    ? RoundIconMenuWrapperRelative
    : RoundIconMenuWrapperInactive;
  const GiftboxWrapper = playerMonthlyBoxInfo?.CanPlayMonthlyBox
    ? RoundIconMenuWrapperRelative
    : RoundIconMenuWrapperInactive;

  return (
    <>
      {playerWheelInfo?.IsWheelEnabled && (
        <MenuItemProfile
          exact
          to="/"
          data-testid="button-wheel"
          key="roundicon-profile-wheel-button"
        >
          <WheelWrapper>
            <Icon
              type="WheelClub"
              id={`profile-round-icon-menu-${'WheelClub'.toLowerCase()}`}
              height={MOBILE_GAMES_ICON_SIZE}
              width={MOBILE_GAMES_ICON_SIZE}
              fill={wheelColor}
            />
            {playerWheelInfo?.WheelAvailable && (
              <NotificationMobile data-testid="notification-wheel" />
            )}
          </WheelWrapper>
          <MenuIconTextColored color={wheelColor}>
            {t('games.icon.wheel')}
          </MenuIconTextColored>
        </MenuItemProfile>
      )}
      {playerMonthlyBoxInfo?.IsMonthlyBoxEnabled && (
        <MenuItemProfile
          exact
          to={getLoyaltyPath('giftbox')}
          data-testid="button-giftbox"
          key="roundicon-profile-giftbox-button"
        >
          <GiftboxWrapper>
            <Icon
              type="Gift"
              id={`profile-round-icon-menu-${'Gift'.toLowerCase()}`}
              height={MOBILE_GAMES_ICON_SIZE}
              width={MOBILE_GAMES_ICON_SIZE}
              fill={giftboxColor}
            />
            {playerMonthlyBoxInfo?.MonthlyBoxAvailable && (
              <NotificationMobile data-testid="notification-giftbox" />
            )}
          </GiftboxWrapper>
          <MenuIconTextColored color={giftboxColor}>
            {t('games.icon.giftbox')}
          </MenuIconTextColored>
        </MenuItemProfile>
      )}
    </>
  );
};
