import styled, { css } from 'styled-components';

import { colors, elevation } from '@gaming1/g1-style';
import { Box, SimpleButton } from '@gaming1/g1-ui';

type BettingSlipDrawerContainerProps = {
  /** If we need to use a specific % of height */
  hasAFixedHeight: boolean;
};

export const BettingSlipDrawerContainer = styled(
  Box,
)<BettingSlipDrawerContainerProps>`
  ${elevation(0)}
  min-height: 100%;
  ${({ hasAFixedHeight }) =>
    hasAFixedHeight &&
    css`
      height: 100%;
    `}
`;

export const CloseButton = styled(SimpleButton)`
  color: ${colors('textDark')};
`;
