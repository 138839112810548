import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { NotificationLevel } from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { filterBoostusThenOrderToNotUsedBoostus } from '../../../../boostus/helpers';
import {
  boostusConditionsListGettorSelector,
  getBoostusCorrespondingToConditionIdsGetterSelector,
} from '../../../../boostus/selectors';
import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { freebetsConditionsListGettorSelector } from '../../../../freebets/selectors/common';
import { groupFreebets } from '../../../../freebets/selectors/helpers';
import {
  getFreebetsCorrespondingToConditionIdsGetterSelector,
  getFreebetsWithoutAnyConditionIdGetterSelector,
} from '../../../../freebets/selectors/unlocked';
import { getPromotionsCorrespondingToIdsGettorSelector } from '../../../../promotions/selectors';
import { bettingSlipGetterSelector } from '../common';
import {
  bettingSlipItemBaseOddGetterSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
} from '../elements/bettingslipItem';
import {
  bettingSlipBaseOddsGetterSelector,
  bettingSlipFinalOddsGettorSelector,
} from '../odds';
import {
  bettingSlipFinalWinningsGettorSelector,
  bettingSlipWinningsGettorSelector,
} from '../winnings';

import { getBoostusConditionsGetterSelector } from './boostusConditions';
import { getFreebetConditionsGetterSelector } from './freebetConditions';
import { isAvailableByRangeCondition } from './helpers';
import { getPromotionsIdsGetterSelector } from './promotions';

/*
 * Freebet section
 */
export const getFreebetForBSGetterSelector = createSelector(
  getFreebetConditionsGetterSelector,
  getFreebetsWithoutAnyConditionIdGetterSelector,
  getFreebetsCorrespondingToConditionIdsGetterSelector,
  freebetsConditionsListGettorSelector,
  bettingSlipGetterSelector,
  bettingSlipFinalOddsGettorSelector,
  bettingSlipWinningsGettorSelector,
  bettingSlipFinalWinningsGettorSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
  (
    getFreebetConditions,
    getFreebetsWithoutAnyConditionId,
    getFreebetsCorrespondingToConditionIds,
    freebetsConditionsList,
    bettingSlip,
    bettingSlipFinalOdds,
    bettingSlipWinnings,
    bettingSlipFinalWinnings,
    bettingSlipItemFinalOdds,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const FreebetsWithoutConditionId = getFreebetsWithoutAnyConditionId || [];

      const conditions = getFreebetConditions(bettingSlipId);
      const FreebetsWithConditionId = conditions
        ? getFreebetsCorrespondingToConditionIds(conditions) || []
        : [];

      const bs = bettingSlip(bettingSlipId);

      const fb = [
        ...FreebetsWithoutConditionId,
        ...FreebetsWithConditionId,
      ].filter((freebet) => {
        if (!freebet.ConditionId) {
          return true;
        }
        const condition = freebetsConditionsList(freebet.ConditionId);

        return (
          isAvailableByRangeCondition(
            condition?.RangeConditions?.filter(isNonNullable),
            {
              items:
                bs?.items.map((item) => ({
                  finalOdd:
                    bettingSlipItemFinalOdds(bettingSlipId, item.id) || 0,
                })) || [],
              totalFinalOdd: bettingSlipFinalOdds(bettingSlipId),
              winnings: {
                base: bettingSlipWinnings(bettingSlipId) || 0,
                final: bettingSlipFinalWinnings(bettingSlipId) || 0,
              },
            },
          ).Level === NotificationLevel.Information
        );
      });

      return fb;
    }),
);

export const getGroupedFreebetForBSGetterSelector = createSelector(
  getFreebetForBSGetterSelector,
  (getFreebetForBS) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) =>
      groupFreebets(getFreebetForBS(bettingSlipId)),
    ),
);

export const getNumberOfFreebetForBSGetterSelector = createSelector(
  getFreebetForBSGetterSelector,
  (getFreebetForBS) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        getFreebetForBS(bettingSlipId)?.length || 0,
    ),
);

/*
 * Boostus section
 */
export const getBoostusForBSGetterSelector = createSelector(
  getBoostusConditionsGetterSelector,
  getBoostusCorrespondingToConditionIdsGetterSelector,
  boostusConditionsListGettorSelector,
  bettingSlipGetterSelector,
  bettingSlipBaseOddsGetterSelector,
  bettingSlipWinningsGettorSelector,
  bettingSlipFinalWinningsGettorSelector,
  bettingSlipItemBaseOddGetterSelector,
  (
    getBoostusConditions,
    getBoostusCorrespondingToConditionIds,
    boostusContionsList,
    bettingSlip,
    bettingSlipOdd,
    bettingSlipWinnings,
    bettingSlipFinalWinnings,
    bettingSlipItemBaseOdd,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const conditions = getBoostusConditions(bettingSlipId)();

      if (!conditions || conditions.length === 0) {
        return [];
      }
      const bs = bettingSlip(bettingSlipId);
      const boostusList = getBoostusCorrespondingToConditionIds(conditions);

      return filterBoostusThenOrderToNotUsedBoostus(
        boostusList.filter((boostus) => {
          if (!boostus.ConditionId) {
            return true;
          }
          const condition = boostusContionsList(boostus.ConditionId);
          return (
            isAvailableByRangeCondition(
              condition?.RangeConditions?.filter(isNonNullable),
              {
                items:
                  bs?.items.map((item) => ({
                    finalOdd:
                      bettingSlipItemBaseOdd(bettingSlipId, item.id) || 0,
                  })) || [],
                totalFinalOdd: bettingSlipOdd(bettingSlipId),
                winnings: {
                  base: bettingSlipWinnings(bettingSlipId) || 0,
                  final: bettingSlipFinalWinnings(bettingSlipId) || 0,
                },
              },
            ).Level === NotificationLevel.Information
          );
        }),
      );
    }),
);
export const getNumberOfBoostusForBSGetterSelector = createSelector(
  getBoostusForBSGetterSelector,
  (getBoostusForBS) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        getBoostusForBS(bettingSlipId)?.length || 0,
    ),
);

/*
 * Promotion section
 */
export const getPromotionsForBSGetterSelector = createSelector(
  getPromotionsIdsGetterSelector,
  getPromotionsCorrespondingToIdsGettorSelector,
  bettingSlipGetterSelector,
  bettingSlipBaseOddsGetterSelector,
  bettingSlipWinningsGettorSelector,
  bettingSlipFinalWinningsGettorSelector,
  bettingSlipItemBaseOddGetterSelector,
  (
    getPromotionsIds,
    getPromotionCorrespondingToIds,
    bettingSlip,
    bettingSlipOdd,
    bettingSlipWinnings,
    bettingSlipFinalWinnings,
    bettingSlipItemBaseOdd,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const ids = getPromotionsIds(bettingSlipId)();

      if (!ids || ids.length === 0) {
        return [];
      }
      const bs = bettingSlip(bettingSlipId);
      const promotionList = getPromotionCorrespondingToIds(ids);

      return promotionList.filter((promotion) => {
        if (!promotion.Conditions) {
          return true;
        }
        return (
          isAvailableByRangeCondition(
            promotion.Conditions.RangeConditions?.filter(isNonNullable),
            {
              items:
                bs?.items.map((item) => ({
                  finalOdd: bettingSlipItemBaseOdd(bettingSlipId, item.id) || 0,
                })) || [],
              totalFinalOdd: bettingSlipOdd(bettingSlipId),
              winnings: {
                base: bettingSlipWinnings(bettingSlipId) || 0,
                final: bettingSlipFinalWinnings(bettingSlipId) || 0,
              },
            },
          ).Level === NotificationLevel.Information
        );
      });
    }),
);
export const getNumberOfPromotionsForBSGetterSelector = createSelector(
  getPromotionsForBSGetterSelector,
  (getPromotionsForBSGetter) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        getPromotionsForBSGetter(bettingSlipId)?.length || 0,
    ),
);

/*
 * Gifts
 */
export const getNumberOfGiftsForBSGetterSelector = createSelector(
  getNumberOfFreebetForBSGetterSelector,
  getNumberOfBoostusForBSGetterSelector,
  getNumberOfPromotionsForBSGetterSelector,
  (
    getNumberOfFreebetForBS,
    getNumberOfBoostusForBS,
    getNumberOfPromotionsForBSGetter,
  ) =>
    memoize(
      (bettingSlipId: BettingSlipIdentifierType) =>
        getNumberOfFreebetForBS(bettingSlipId) +
        getNumberOfBoostusForBS(bettingSlipId) +
        getNumberOfPromotionsForBSGetter(bettingSlipId),
    ),
);
