import React, { VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon } from '@gaming1/g1-ui';

import { MenuGroups } from '../types';

import {
  MobileMenuAnchor,
  MobileMenuButton,
  MobileMenuLink,
  MobileMenuNav,
  MobileMenuNavGroup,
} from './styles';

export type DrawerNavMenuProps = {
  /** List of menu entries to display */
  menu: MenuGroups;
};

export const Menu: VFC<DrawerNavMenuProps> = ({ menu }) => {
  const { t } = useTranslation('core');
  return (
    <MobileMenuNav>
      {menu.map((group, groupIndex) => (
        <MobileMenuNavGroup key={group[0].label}>
          {group.map(({ testId, label, icon, ...props }) => {
            if ('onClick' in props) {
              return (
                <MobileMenuButton key={testId} onClick={props.onClick}>
                  {label}
                </MobileMenuButton>
              );
            }
            return props.externalLink ? (
              <MobileMenuAnchor
                href={props.path}
                target="_blank"
                rel="noopener"
                key={`${props.path}${label}`}
                data-testid={testId}
                groupIndex={groupIndex}
              >
                {icon && typeof icon === 'string' ? (
                  <Icon type={icon} id={`drawernav-${icon}`} />
                ) : (
                  icon
                )}
                <span>{t(label)}</span>
              </MobileMenuAnchor>
            ) : (
              <MobileMenuLink
                exact={props.exact}
                to={props.path}
                key={`${props.path}${label}`}
                data-testid={testId}
                groupIndex={groupIndex}
              >
                {!!icon && typeof icon === 'string' ? (
                  <Icon type={icon} id={`drawernav-${icon}`} />
                ) : (
                  icon
                )}
                <span>{t(label)}</span>
              </MobileMenuLink>
            );
          })}
        </MobileMenuNavGroup>
      ))}
    </MobileMenuNav>
  );
};
