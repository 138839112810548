import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { colors, device, fontWeight } from '@gaming1/g1-style';
import { AppAnchor, AppLink, SimpleButton } from '@gaming1/g1-ui';

const actionSharedStyle = css`
  color: ${colors('textLight')};
  font-weight: ${fontWeight('bold')};
  display: contents;

  ${device.canHover} {
    &:hover {
      color: ${colors('textLight', { transform: darken(0.1) })};
    }
  }
`;

export const CallDecoration = styled.span`
  text-decoration: underline;
`;
export const CallToActionButton = styled(SimpleButton)`
  ${actionSharedStyle}
`;

export const CallToActionLink = styled(AppLink)`
  ${actionSharedStyle}
`;

export const CallToActionAppAnchor = styled(AppAnchor)`
  ${actionSharedStyle}
`;
