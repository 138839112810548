import { useContext } from 'react';

import { ConfigContext } from '@gaming1/g1-config';

import { PrivacySettingsContext } from '../components/PrivacySettingsProvider';

/**
 * Returns functions allowing to get the user consent for a particular
 * purpose/vendor.
 *
 * Due to DidomiSDK loading asynchronously, these 2 functions always return
 * `false` as a restrictive fallback when didomi is configured, `true` otherwise.
 */
export const usePrivacySettings = () => {
  const config = useContext(ConfigContext);
  const shouldAllowByDefault = !config.core.privacySettings.didomiApiKey;
  const {
    getUserConsentStatusForPurpose = () => shouldAllowByDefault,
    getUserConsentStatusForVendor = () => shouldAllowByDefault,
    openPrivacySettings,
  } = useContext(PrivacySettingsContext);

  const isPrivacySettingsEnabled = !!openPrivacySettings;

  return {
    getUserConsentStatusForPurpose,
    getUserConsentStatusForVendor,
    isPrivacySettingsEnabled,
    openPrivacySettings,
  };
};
