import React from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { LazyRoute } from '@gaming1/g1-core-web';

import { cmsRoutes } from './routes';

export const getCmsRouter = (locale: LocaleCode) => [
  <LazyRoute
    componentName="AboutUsRouter"
    importFactory={() => import('./routers/AboutUsRouter')}
    key="aboutUs"
    path={`/${locale}${cmsRoutes.aboutUs.path}`}
    section="aboutUs"
    type="app"
  />,
  <LazyRoute
    componentName="BlogRouter"
    importFactory={() => import('./routers/BlogRouter')}
    key="blog"
    path={`/${locale}${cmsRoutes.blog.path}`}
    section="blog"
    type="app"
  />,
  <LazyRoute
    componentName="PromotionRouter"
    importFactory={() => import('./routers/PromotionRouter')}
    key="promotions"
    path={`/${locale}${cmsRoutes.promotions.path}`}
    section="promotions"
    type="app"
  />,
  <LazyRoute
    componentName="ResponsibleGamingPage"
    importFactory={() => import('./pages/ResponsibleGamingPage')}
    key="responsibleGaming"
    path={`/${locale}${cmsRoutes.responsibleGaming.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="PreviewPage"
    importFactory={() => import('./pages/PreviewPage')}
    key="preview"
    path={`/${locale}${cmsRoutes.preview.path}`}
    section="default"
    type="app"
  />,
];
