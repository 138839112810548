import { useSelector } from 'react-redux';

import { userLoggedInSelector } from '@gaming1/g1-core';

import { useBettingGetterSelector } from '../../hooks';
import {
  canPlayerParticipateToSuperOdds,
  isPlayerEligibleForSuperOdds,
} from '../../player/selectors';

import { getHasSuperOddsSelector } from './selectors';

export const useDisplaySuperOdds = () => {
  const has = useBettingGetterSelector({
    getterSelector: getHasSuperOddsSelector,
    args: [],
  });
  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const eligible = useBettingGetterSelector({
    getterSelector: isPlayerEligibleForSuperOdds,
    args: [],
  });

  const participate = useBettingGetterSelector({
    getterSelector: canPlayerParticipateToSuperOdds,
    args: [],
  });

  if (!has) {
    return false;
  }
  if (isUserLoggedIn === false) {
    return true;
  }

  return eligible || participate;
};
