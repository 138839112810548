/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:17:08 17-01-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  ScratchCardIdentifierRequest,
  ScratchCardIdentifierResponse,
} from '../entities';

export const scratchCardIdentifier = (options: ScratchCardIdentifierRequest) =>
  createRequest<ScratchCardIdentifierResponse>(
    100,
    'ScratchCardIdentifier',
    options,
  );
