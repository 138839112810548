import { shallowEqual } from 'react-redux';

import {
  BettingSlipIdentifierType,
  BettingSlipStringType,
} from '../../../common/store/types';
import { useBettingGetterSelector } from '../../../hooks';
import {
  placeBettingSlipItemNotificationGetterSelector,
  placeBettingSlipNotificationGetterSelector,
} from '../../store/selectors/placeBet/placeBet';
import {
  riskManagementItemNotificationGetterSelector,
  riskManagementNotificationGetterSelector,
} from '../../store/selectors/riskManagement/riskManagement';
import { useBettingSlipCombiErrorFrontType } from '../elements/combi';
import { useBettingSlipSingleErrorFrontType } from '../elements/single';
import { useBettingSlipSystemErrorFrontType } from '../elements/system';

export const useBettingSlipErrorFrontSwitchType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const single = useBettingSlipSingleErrorFrontType(bettingSlipId);
  const combi = useBettingSlipCombiErrorFrontType(bettingSlipId);
  const system = useBettingSlipSystemErrorFrontType(bettingSlipId);

  return (type: BettingSlipStringType) => {
    switch (type) {
      case 'single':
        return single();
      case 'combi':
        return combi();
      case 'system':
        return system();
      default:
        return null;
    }
  };
};
export const useBettingSlipNotification = (
  bettingSlipId: BettingSlipIdentifierType,
  type: BettingSlipStringType,
) => {
  const front = useBettingSlipErrorFrontSwitchType(bettingSlipId)(type);

  const pb = useBettingGetterSelector({
    getterSelector: placeBettingSlipNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const rm = useBettingGetterSelector({
    getterSelector: riskManagementNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  return front || pb || rm || null;
};
export const useBettingSlipIsItemInterationError = (
  bettingSlipId: BettingSlipIdentifierType,
  id: number,
): boolean => {
  const pb = useBettingGetterSelector({
    getterSelector: placeBettingSlipItemNotificationGetterSelector,
    args: [bettingSlipId, id],
    equalityFn: shallowEqual,
  });
  const rm = useBettingGetterSelector({
    getterSelector: riskManagementItemNotificationGetterSelector,
    args: [bettingSlipId, id],
    equalityFn: shallowEqual,
  });

  if (pb) {
    return true;
  }
  if (rm) {
    return true;
  }
  return false;
};
