import React, {
  createContext,
  Suspense,
  useContext,
  useMemo,
  VFC,
} from 'react';

import { lazifyComponent } from '@gaming1/g1-utils';

import { IconType } from './icons';
import { IconSvg } from './styles';
import { SVGIconProps } from './types';

export type IconProps = {
  /** unique id of the icon usage. Useful for overwriting a precise icon */
  id: string;
  /**
   * the test-id of the SVG. Default to the id prop + '-icon' (if provided),
   * otherwise it will be based on the type (also with the '-icon' suffix)
   */
  testId?: string;
  /** the type of icon to displayed (finite list of available icons) */
  type: IconType;
} & SVGIconProps;

type IconContextType = {
  ids: { [id: string]: IconType };
  types: { [type in IconType]?: VFC<SVGIconProps> };
};

const SuspenseIcon = (props: SVGIconProps) => (
  <IconSvg height="1em" width="1em" {...props} />
);

export const IconContext = createContext<IconContextType>({
  ids: {},
  types: {},
});

export const Icon: VFC<IconProps> = ({ id, testId, type, ...props }) => {
  const contextIcons = useContext(IconContext);

  const iconType = id && contextIcons.ids[id] ? contextIcons.ids[id] : type;

  const dataTestId =
    testId || (id ? `${id}-icon` : `icon-${type.toLowerCase()}`);

  /**
   * The lazy component must be inside a useMemo to be entirely sure that the
   * lazifyComponent function does not throw a new promise every time the
   * component is rerendered by its parent.
   */
  const IconComponent = useMemo(
    () =>
      contextIcons.types[iconType] ||
      lazifyComponent(iconType, () => import(`./icons`)),
    [contextIcons.types, iconType],
  );

  return IconComponent ? (
    <Suspense fallback={<SuspenseIcon data-testid={dataTestId} {...props} />}>
      <IconComponent data-testid={dataTestId} {...props} />
    </Suspense>
  ) : null;
};

export type { IconType } from './icons';
export type { SVGIconProps } from './types';
export { IconSvg } from './styles';
