import { generateAssetsContextValue } from '@gaming1/g1-core-web';
import { UserAssetsContext } from '@gaming1/g1-user-web';

import fileNoPreview from './fileUpload/fileNoPreview.svg';
import filePreviewPdf from './fileUpload/filePreviewPdf.svg';
import loginBackground from './promotions/default-login-background-large.png';

export const userAssets = generateAssetsContextValue(UserAssetsContext)({
  /* File upload */
  fileNoPreview,
  filePreviewPdf,
  /* Login */
  loginBackground,
});
