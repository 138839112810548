import { opacify } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';

type AppRadioSpanProps = {
  /** Whether the radio is selected or not */
  checked?: boolean;
  disabled?: boolean;
  focused?: boolean;
};

export const RadioLabel = styled.label`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
`;

export const AppRadioSpan = styled.span<AppRadioSpanProps>`
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${colors('tertiary')};
  box-shadow: 0 0 1px 0px ${colors('tertiary')} inset,
    0 0 1px 0px ${colors('tertiary')};
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 22px;
  justify-content: center;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  width: 22px;
  z-index: 0;

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${colors('primary')};
      box-shadow: 0 0 1px 0px ${colors('primary')} inset,
        0 0 1px 0px ${colors('primary')};
      &:before {
        content: '';
        border-radius: 100%;
        position: absolute;
        height: 12px;
        width: 12px;
        background-color: ${colors('primary')};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      cursor: default;
    `}

  ${({ focused }) =>
    focused &&
    css`
      &:after {
        content: '';

        position: absolute;
        z-index: -1;

        display: block;
        width: 40px;
        height: 40px;

        background-color: ${colors('neutrals', {
          index: 3,
          transform: opacify(-0.6),
        })};
        border-radius: 100%;
      }
    `}
  
  ${({ checked, focused }) =>
    checked &&
    focused &&
    css`
      &:after {
        background-color: ${colors('primary', { transform: opacify(-0.6) })};
      }
    `}
`;

export const HiddenRadioInput = styled.input.attrs({
  type: 'radio',
})`
  position: absolute;
  opacity: ${opacity('transparent')};
  cursor: pointer;
  height: 0;
  width: 0;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`;

type RadioLabelSpanProps = {
  /** Whether the radio is selected or not */
  checked?: boolean;
  disabled?: boolean;
};

export const RadioLabelSpan = styled.span<RadioLabelSpanProps>`
  font-size: ${fontSizes('md')};
  padding-left: ${spaces('sm')};
  display: inline-block;
  line-height: 1em;
  color: ${colors('label')};
  ${({ checked }) =>
    checked &&
    css`
      color: ${colors('primary')};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      cursor: default;
    `}
`;
const RADIO_CARD_WRAPPER_MIN_HEIGHT = 70;
export const RadioCardWrapper = styled.div`
  cursor: pointer;
  background-color: ${colors('inputBackground')};
  display: flex;
  align-items: center;
  border-radius: 5px;
  min-height: ${RADIO_CARD_WRAPPER_MIN_HEIGHT}px;
  padding-top: ${spaces('xs')};
  padding-bottom: ${spaces('xs')};
  & > ${AppRadioSpan} {
    margin: ${spaces('none')} ${spaces('sm')};
  }
  & > ${RadioLabelSpan} {
    padding-left: ${spaces('none')};
    margin-left: ${spaces('none')};
    font-size: ${fontSizes('lg')};
    font-weight: ${fontWeight('regular')};
    color: ${colors('text')};
  }
`;

export const RadioCardContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex: 1;
  & > div {
    color: ${colors('text')};
    &:not(:last-child) {
      padding-bottom: ${spaces('xs')};
    }
  }
`;
