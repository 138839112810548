import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { colors, spaces, zIndex } from '@gaming1/g1-style';

import { SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX } from '../constants';
import { Icon } from '../Icon';
import { SimpleButton } from '../styles';

import { ListButtonProps, ListWrapperProps } from './types';

const BUTTON_SIZE_IN_PX = 22;
const LIST_BORDER_HEIGHT_IN_PX = 2;
export const ICON_SIZE_IN_PX = 16;
export const ICON_SIZE_TILE_IN_PX = 20;

/* Helpers */

const commonEdgeCss = css<ListWrapperProps>`
  ${({ shouldGradientHaveFullHeight }) => css`
    bottom: 0;
    content: '';
    height: calc(
      100% - ${shouldGradientHaveFullHeight ? 0 : LIST_BORDER_HEIGHT_IN_PX}px
    );
    position: absolute;
    top: 0;
    width: ${SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX}px;
    z-index: ${zIndex('fullscreen')};
  `}
`;

const generateEdgeGradiant = (orientation: number) => css<ListWrapperProps>`
  ${commonEdgeCss}

  ${({ layout }) => {
    const bgColor =
      layout === 'alt' ? 'scrollNavTileBackground' : 'scrollNavBackground';

    return css`
      background: linear-gradient(
        ${orientation}deg,
        ${colors(bgColor, { transform: transparentize(1) })} 0%,
        ${colors(bgColor)} 100%
      );
    `;
  }}
`;

/* Styled components */

export const ListWrapper = styled('div')<ListWrapperProps>`
  min-width: 100%;

  ${({ areChildrenOverflowing, edges, hasGradient }) =>
    areChildrenOverflowing &&
    hasGradient &&
    css`
      ${!edges.isLeftVisible &&
      css`
        &::before {
          ${generateEdgeGradiant(270)}
          left: 0;
        }
      `}

      ${!edges.isRightVisible &&
      css`
        &::after {
          ${generateEdgeGradiant(90)}
          right: 0;
        }
      `}
    `}
`;

export const ListButton = styled(SimpleButton)<ListButtonProps>`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${({ top }) => top || '50%'};
  transform: translateY(-50%);
  z-index: 11;

  /** Only display the buttons when using no touchscreen (OR IF 'isAlwaysVisible' is true) */
  ${({ isVisible, hasTouchCapabilities, isAlwaysVisible }) =>
    (!hasTouchCapabilities || !!isAlwaysVisible) &&
    css`
      display: ${isVisible ? 'flex' : 'none'};
    `}

  ${({ side, sideInPx }) =>
    css`
      ${side === 'right'
        ? `right: ${sideInPx || SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX}px`
        : `left: ${sideInPx || SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX}px`}
    `}
`;

export const ListButtonIcon = styled(Icon)`
  width: ${BUTTON_SIZE_IN_PX + 2}px;
  height: ${BUTTON_SIZE_IN_PX + 2}px;
  border-radius: 50%;
  background-color: ${colors('scrollNavButtonBackground')};
  padding: ${spaces('xxs')};
  path {
    fill: ${colors('scrollNavButtonForeground')};
  }
`;
