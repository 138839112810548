import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generatePaginatedInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { PaymentActions } from '../types';

import { HistoryState } from './types';

const initialState: HistoryState = {
  requests: {
    getDepositHistory: generatePaginatedInitialRequestState([]),
    getWithdrawalHistory: generatePaginatedInitialRequestState([]),
  },
  deposits: null,
  withdrawals: null,
};

export const historyReducer = (state = initialState, action: PaymentActions) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /* Deposit */
      case getType(actions.getDepositHistory.request):
        produceLoadingState(draftState.requests.getDepositHistory);
        break;

      case getType(actions.getDepositHistory.success):
        produceSuccessState(draftState.requests.getDepositHistory);

        draftState.deposits =
          action.payload.pagination.PageNumber > 0 && state.deposits
            ? [...state.deposits, ...action.payload.list]
            : action.payload.list;

        draftState.requests.getDepositHistory.pagination =
          action.payload.pagination;
        break;

      case getType(actions.getDepositHistory.failure):
        produceFailureState(
          draftState.requests.getDepositHistory,
          action.payload,
        );
        break;

      /* Withdrawal */
      case getType(actions.getWithdrawalHistory.request):
        produceLoadingState(draftState.requests.getWithdrawalHistory);
        break;

      case getType(actions.getWithdrawalHistory.failure):
        produceFailureState(
          draftState.requests.getWithdrawalHistory,
          action.payload,
        );
        break;

      case getType(actions.getWithdrawalHistory.success):
        produceSuccessState(draftState.requests.getWithdrawalHistory);

        draftState.withdrawals =
          action.payload.pagination.PageNumber > 0 && state.withdrawals
            ? [...state.withdrawals, ...action.payload.list]
            : action.payload.list;

        draftState.requests.getWithdrawalHistory.pagination =
          action.payload.pagination;
        break;

      default: // Immer will automatically return the state
    }
  });
