import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as historyActions from '../history/store/actions';
import { BettingActions } from '../store/types';

import * as actions from './actions';
import { CashoutListEntities, CashoutState } from './types';

export const initialState: CashoutState = {
  entities: {
    cashout: {},
  },
};

const getCashoutEntity = (id: string, state: CashoutState) =>
  state.entities.cashout[id];

export const cashoutReducer = (state = initialState, action: BettingActions) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.cashout.request): {
        const { BettingSlipId } = action.payload;
        const entity = getCashoutEntity(BettingSlipId, draftState);
        produceLoadingState(entity.requests.cashout);
        break;
      }

      case getType(actions.cashout.success): {
        const { bettingSlipId } = action.payload;
        const entity = getCashoutEntity(bettingSlipId, draftState);

        produceSuccessState(entity.requests.cashout);
        entity.result = action.payload;
        break;
      }

      case getType(actions.cashout.failure): {
        const { payload } = action;
        const { bettingSlipId } = action.payload;
        const entity = getCashoutEntity(bettingSlipId, draftState);

        produceFailureState(entity.requests.cashout, payload);
        break;
      }

      case getType(actions.getCashoutInfo.request): {
        const { BettingSlipId } = action.payload;
        const entity = getCashoutEntity(BettingSlipId, draftState);

        produceLoadingState(entity.requests.getCashoutInfo);
        break;
      }

      case getType(actions.getCashoutInfo.success): {
        const { bettingSlipId } = action.payload;
        const entity = getCashoutEntity(bettingSlipId, draftState);

        produceSuccessState(entity.requests.getCashoutInfo);
        entity.result = action.payload;
        break;
      }

      case getType(actions.getCashoutInfo.failure): {
        const { bettingSlipId } = action.payload;
        const entity = getCashoutEntity(bettingSlipId, draftState);

        produceFailureState(entity.requests.getCashoutInfo, action.payload);
        entity.result = {};
        break;
      }

      case getType(historyActions.getBettingHistory.success): {
        const { BettingHistory } = action.payload.histories;

        if (!BettingHistory || BettingHistory.length === 0) {
          draftState.entities.cashout = {};
          break;
        }
        draftState.entities.cashout =
          BettingHistory.reduce<CashoutListEntities>((cashoutList, history) => {
            if (!history.CashoutData) {
              return cashoutList;
            }
            const bettingSlipId =
              history.CashoutData.CashoutInformation?.BettingSlipId ||
              history.CashoutData.CashedOutData?.BettingSlipId;
            if (bettingSlipId && !cashoutList[bettingSlipId]) {
              cashoutList[bettingSlipId] = {
                ...{
                  result: history.CashoutData,
                },
                ...{
                  requests: {
                    cashout: generateInitialRequestState(null),
                    getCashoutInfo: generateInitialRequestState(null),
                  },
                },
              };
            }
            return cashoutList;
          }, {});
        break;
      }
      default: // Immer will automatically return the state
    }
  });
