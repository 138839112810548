import React, { FC } from 'react';
import { SpaceProps } from 'styled-system';

import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Heading, Text } from '@gaming1/g1-ui';

type NoContentProps = {
  /** The key to translate for the description (text under the heading) */
  descriptionKey: string;
  /** The key to translate the heading text */
  headingKey: string;
  testId?: string;
};

/** Render two centered texts, a heading which has an opacity and a description text under it that has no opacity */
export const NoContent: FC<NoContentProps & SpaceProps> = ({
  descriptionKey,
  headingKey,
  testId,
  ...spaceProps
}) => {
  const { t } = useTranslation('betting');

  return (
    <Box alignItems="center" data-testid={testId} mt="xl" {...spaceProps}>
      <Heading level="h4">{t(headingKey)}</Heading>
      <Text mt="xs" fontSize="md" textAlign="center">
        {t(descriptionKey)}
      </Text>
    </Box>
  );
};
