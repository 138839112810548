import styled, { css } from 'styled-components';

import { elevation, spaces, zIndex } from '@gaming1/g1-style';

export const POPOVER_MARGIN_IN_PX = 16;
const POPOVER_BORDER_RADIUS_IN_PX = 10;

export const PopoverAside = styled.aside`
  position: absolute;
  z-index: ${zIndex('popover')};
  pointer-events: none;
  box-sizing: border-box;

  & *,
  & *:before,
  & *:after {
    box-sizing: inherit;
  }
`;

export type VerticalPosition = 'top' | 'center' | 'bottom';
export type HorizontalPosition = 'left' | 'center' | 'right';

const setPosition = (
  horizontal: HorizontalPosition,
  vertical: VerticalPosition,
) => {
  let top = 'initial';
  let left = 'initial';
  let right = 'initial';
  let bottom = 'initial';
  let transform: string;
  let padding = '0';

  switch (vertical) {
    case 'top': {
      top = '0';
      transform = horizontal === 'center' ? 'translateY(-100%)' : '';
      padding = horizontal === 'center' ? `${POPOVER_MARGIN_IN_PX}px` : '0';
      break;
    }
    case 'bottom': {
      bottom = '0';
      transform = horizontal === 'center' ? 'translateY(100%)' : '';
      padding = horizontal === 'center' ? `${POPOVER_MARGIN_IN_PX}px` : '0';
      break;
    }
    default: {
      top = '50%';
      transform = 'translateY(-50%)';
    }
  }

  switch (horizontal) {
    case 'left': {
      left = '0';
      transform += ' translateX(-100%)';
      padding += ` ${POPOVER_MARGIN_IN_PX}px`;
      break;
    }
    case 'right': {
      right = '0';
      transform += ' translateX(100%)';
      padding += ` ${POPOVER_MARGIN_IN_PX}px`;
      break;
    }
    default: {
      left = '50%';
      transform += ' translateX(-50%)';
      padding += ' 0';
    }
  }

  return css({ top, left, right, bottom, transform, padding });
};

type PopoverContainerProps = {
  horizontalPosition: HorizontalPosition;
  verticalPosition: VerticalPosition;
};

export const PopoverContainer = styled.div<PopoverContainerProps>`
  outline: 0;
  position: absolute;
  pointer-events: all;
  ${({ horizontalPosition, verticalPosition }) =>
    setPosition(horizontalPosition, verticalPosition)};

  & > div > * {
    max-height: inherit;
  }
`;

type PopoverContentProps = {
  /** Default to 250px */
  width?: string;
};

export const PopoverContent = styled.div<PopoverContentProps>`
  ${elevation(4)};
  border-radius: ${POPOVER_BORDER_RADIUS_IN_PX}px;
  max-height: calc(100% - 2 * ${spaces('sm')});
  overflow-x: hidden;
  overflow-y: auto;

  ${({ width }) => css`
    width: ${width || '250px'};
  `}
`;
