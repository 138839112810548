import * as t from 'io-ts';

import {
  EProductCategory,
  NewShopProductType,
  PriceType,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const pricePart = t.intersection(
  [
    t.type({
      Amount: t.number,
      Type: createEnumType<PriceType>(PriceType),
    }),
    t.partial({
      MinAmount: t.number,
      MaxAmount: t.number,
      Ticket: t.number,
    }),
  ],
  'PricePart',
);

export const shopPrice = t.intersection(
  [
    t.type({
      IsMainPrice: t.boolean,
      RequireVipRank: t.number,
      Type: createEnumType<PriceType>(PriceType),
    }),
    t.partial({
      Id: t.string,
      Parts: t.array(pricePart),
    }),
  ],
  'ShopPrice',
);

export const shopProduct = t.intersection(
  [
    t.type({
      CanAfford: t.boolean,
      CreatedDate: t.string,
      Id: t.string,
      NewShopProductType:
        createEnumType<NewShopProductType>(NewShopProductType),
      Popularity: t.number,
    }),
    t.partial({
      BasePrice: shopPrice,
      Categories: t.array(t.string),
      Conditions: t.string,
      Description: t.string,
      Image: t.string,
      OrderConfirmationMessage: t.string,
      Price: shopPrice,
      Prices: t.array(shopPrice),
      ProductCategory: createEnumType<EProductCategory>(EProductCategory),
      PromotionalImage: t.string,
      Thumb: t.string,
      TicketPrice: shopPrice,
      Title: t.string,
    }),
  ],
  'ShopProduct',
);
