import React, { memo } from 'react';

import { BettingSlipSwitchState } from './containers/BettingSlipSwitchState';

/**
 * Display the content ticket
 * a list of item inside select by user and save into store
 */

const BettingSlipContentComponent = () => <BettingSlipSwitchState />;

export const BettingSlipContent = memo(BettingSlipContentComponent);
