import React, { useContext, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon } from '@gaming1/g1-ui';

import { IconAddInner, IconAddText } from './styles';

type IconAddProps = {
  /** Wether the 'button.deposit' label should be hidden or not */
  shouldHideText: boolean;
  wrapperHeight: number;
};

/**
 * The deposit icon to be displayed on the DepositButton
 */
export const IconAdd: VFC<IconAddProps> = ({
  shouldHideText,
  wrapperHeight,
}) => {
  const { t } = useTranslation();
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <IconAddInner wHeight={wrapperHeight}>
        <Icon
          id="deposit-add"
          type="Plus"
          fill={colors.deposit}
          height="100%"
          width="100%"
        />
      </IconAddInner>
      {!shouldHideText && <IconAddText>{t('button.deposit')}</IconAddText>}
    </>
  );
};
