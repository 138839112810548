import React, { useEffect, useRef, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  coreActions,
  GeolocationTroubleshooter,
  geolocationTroubleshootersSelector,
  interopMessages,
} from '@gaming1/g1-core';
import { Modal } from '@gaming1/g1-ui';

import { sendIframeMessage } from '../../../rninterop/iframeMessageManager/helpers';
import { useGeolocation } from '../../hooks/useGeolocation';

import { GeolocationModalApproximateGeolocationContent } from './GeolocationModalApproximateGeolocationContent';
import { GeolocationModalLocationPermissionDeniedContent } from './GeolocationModalLocationPermissionDeniedContent';
import { GeolocationModalTechnicalIssueContent } from './GeolocationModalTechnicalIssueContent';
import { GeolocationModalTroubleshooterContent } from './GeolocationModalTroubleshooterContent';

export type GeolocationModalType =
  | 'troubleshooters'
  | 'technicalIssue'
  | 'noLicense'
  | 'pluginConnectionIssue'
  | 'approximateGeolocation'
  | 'locationPermissionDenied';

export type GeolocationModalProps = {
  /** Is the modal visible */
  isVisible: boolean;
  /** onClose callback (called when dismiss button is clicked) */
  onClose: () => void;
  /** The type of issue with the geolocation */
  type: GeolocationModalType;
};

/**
 * Modal used to display geolocation errors
 */
export const GeolocationModal: VFC<GeolocationModalProps> = ({
  isVisible,
  onClose,
  type,
}) => {
  const geolocationTroubleshooters = useSelector(
    geolocationTroubleshootersSelector,
  );

  const { retryLatestGeolocationRequest } = useGeolocation();

  const dispatch = useDispatch();

  // Hide the "Try again" button if a single troubleshooter asks not to show that button
  const shouldShowTryAgainButton = !geolocationTroubleshooters.length
    ? true
    : geolocationTroubleshooters.every(
        (troubleshooter: GeolocationTroubleshooter) =>
          troubleshooter.ShowTryAgain,
      );

  const fetchLicense = () => {
    dispatch(coreActions.getGeoComplyLicenseKey.request());
  };

  const requestGeolocationAgain = (shouldCloseAfterRetrying: boolean) => () => {
    retryLatestGeolocationRequest();
    if (shouldCloseAfterRetrying) {
      onClose();
    }
  };

  const reconnectToPLC = () =>
    dispatch(coreActions.connectToGeoComply.request());

  const openAppSettings = () => {
    sendIframeMessage(interopMessages.openAppSettings);
  };

  // Removing the function from the dependencies
  const onCloseRef = useRef(onClose);

  /** Close the current modal each time the type change */
  useEffect(() => {
    onCloseRef.current();
  }, [type]);

  return (
    <Modal
      modalId="geolocation-modal"
      onClose={onClose}
      visible={isVisible}
      showCloseButton={false}
      icon={{
        id: 'geolocation-modal-location',
        height: 24,
        type: 'LocationPin',
        width: 24,
      }}
    >
      {type === 'troubleshooters' && (
        <GeolocationModalTroubleshooterContent
          onCancelClick={onClose}
          onRetryClick={requestGeolocationAgain(true)}
          shouldShowTryAgainButton={shouldShowTryAgainButton}
          troubleshooters={geolocationTroubleshooters}
        />
      )}
      {type === 'technicalIssue' && (
        <GeolocationModalTechnicalIssueContent
          onCancelClick={onClose}
          onRetryClick={requestGeolocationAgain(false)}
        />
      )}
      {type === 'noLicense' && (
        <GeolocationModalTechnicalIssueContent
          onCancelClick={onClose}
          onRetryClick={fetchLicense}
        />
      )}
      {type === 'pluginConnectionIssue' && (
        <GeolocationModalTechnicalIssueContent
          onCancelClick={onClose}
          onRetryClick={reconnectToPLC}
        />
      )}
      {type === 'approximateGeolocation' && (
        <GeolocationModalApproximateGeolocationContent
          onCancelClick={onClose}
          onOpenAppSettings={openAppSettings}
          onRetryClick={requestGeolocationAgain(true)}
        />
      )}
      {type === 'locationPermissionDenied' && (
        <GeolocationModalLocationPermissionDeniedContent
          onCancelClick={onClose}
          onOpenAppSettings={openAppSettings}
          onRetryClick={requestGeolocationAgain(true)}
        />
      )}
    </Modal>
  );
};
