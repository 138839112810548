/* istanbul ignore file */
import { userPublicRoutes } from '@gaming1/g1-user-api';
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

import { LimitsPeriodicity } from './limits/types';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!

export const userRoutes = {
  ...userPublicRoutes,
  impersonation: createParamRoute<{ impersonationId: string }>(
    '/032a8106-9e3f-4e92-9910-c5bd7b0bcdb1/:impersonationId',
    { impersonationId: '' },
  ),
  registrationForm: createRoute('/registration-form'),
  profileHistory: createRoute('/profile/history'),
  profileMessages: createRoute('/profile/messages'),
  profileGifts: createRoute('/profile/gifts'),
  profileSponsorship: createRoute('/profile/sponsorship'),
  passwordReset: createRoute('/password-reset/:passwordRecoveryId'),
  accountActivation: createParamRoute<{ activationId: string }>(
    '/activate/:activationId',
    { activationId: '' },
  ),
  account: createRoute('/account'),
  accountPersonalData: createRoute('/account/personal-data'),
  accountAddressForm: createRoute('/account/personal-data/address'),
  accountPhoneNumberForm: createRoute('/account/personal-data/mobilephone'),
  accountPhoneNumber2Form: createRoute('/account/personal-data/landlinephone'),
  accountSecurity: createRoute('/account/security'),
  accountPasswordForm: createRoute('/account/security/password'),
  accountPinForm: createRoute('/account/security/pin'),
  accountResponsibleGaming: createRoute('/account/responsible-gaming'),
  /**
   * Deposit limits
   */
  accountDepositLimitForm: createParamRoute<{ periodicity: LimitsPeriodicity }>(
    '/account/responsible-gaming/deposit-limits/:periodicity',
    {
      periodicity: 'daily',
    },
  ),

  /**
   * Wage limits
   */
  accountWageLimits: createRoute('/account/responsible-gaming/wage-limits'),
  accountWageGamingLimitForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>('/account/responsible-gaming/wage-limits/:periodicity', {
    periodicity: 'daily',
  }),
  accountWageBettingLimitForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>('/account/responsible-gaming/wage-limits-betting/:periodicity', {
    periodicity: 'daily',
  }),

  /**
   * Loss limits
   */
  accountLossLimits: createRoute('/account/responsible-gaming/loss-limits'),
  accountLossGamingLimitForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>('/account/responsible-gaming/loss-limits/:periodicity', {
    periodicity: 'daily',
  }),
  accountLossBettingLimitForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>('/account/responsible-gaming/loss-limits-betting/:periodicity', {
    periodicity: 'daily',
  }),

  /**
   * Session limits
   */
  accountSessionLimits: createRoute(
    '/account/responsible-gaming/session-limits',
  ),
  accountConnectionTimeLimitsForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>(
    '/account/responsible-gaming/session-limits/connection-time/:periodicity',
    {
      periodicity: 'daily',
    },
  ),
  accountSpentTimeGamingLimitsForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>('/account/responsible-gaming/session-limits/time-spent/:periodicity', {
    periodicity: 'daily',
  }),
  accountSpentTimeBettingLimitsForm: createParamRoute<{
    periodicity: LimitsPeriodicity;
  }>(
    '/account/responsible-gaming/session-limits/time-spent-betting/:periodicity',
    {
      periodicity: 'daily',
    },
  ),

  accountLicenses: createRoute('/account/responsible-gaming/licenses'),
  accountPhoneValidation: createRoute('/account/phone-validation'),
  historyDeposit: createRoute('/profile/history/deposit'),
  historyFreeSpins: createRoute('/profile/history/freespins'),
  historySponsorship: createRoute('/profile/history/sponsorship'),
};
