import { CoreConfig } from '../types/core';

/**
 * Core default configuration
 */
export const defaultCoreConfig: CoreConfig = {
  cguFileNamePrefix: 'CGU_',
  cguPrivacyFileNamePrefix: 'cgu-privacy_',
  geoComply: {
    activated: false,
    environment: 'staging',
    installerKey: '',
    timeoutSeconds: 60 * 20, // 60 seconds * 20 = 20 minutes
    umsServerUrl: 'https://stg-ums.geocomply.net/installer/url/',
    version: '3.1.1.3',
  },
  googleAPIKey: '', // leave empty to ensure a wrong key is never used !
  ibanAllowedCountries: [],
  iovation: {
    enabled: true,
    version: 'loader',
  },
  licenses: null,
  mobileAppApkPath: null,
  mobileAppIdentifiers: {
    android: null,
    ios: null,
  },
  moneyPrecision: 2,
  privacySettings: {
    didomiApiKey: null,
    purposeIDs: {
      advertizing: '',
      essential: '',
      performanceMeasure: '',
      uxImprovement: '',
    },
    type: 'none',
  },
  skipRegistrationSteps: false,
  zendeskWidgetKey: null,
};
