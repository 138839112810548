import React, { ContextType, FC } from 'react';

import { UserPublicComponentsContext } from '@gaming1/g1-user-api';

import { DeclarationOfHonorModal } from '../../../legalDepositLimit/components/DeclarationOfHonorModal';
import { PinCodeFormModal } from '../../../multifactorAuthentication/components/PinCodeFormModal';

const contextValue: ContextType<typeof UserPublicComponentsContext> = {
  DeclarationOfHonorModal,
  PinCodeFormModal,
};

export const UserPublicComponentsProvider: FC = ({ children }) => (
  <UserPublicComponentsContext.Provider value={contextValue}>
    {children}
  </UserPublicComponentsContext.Provider>
);
