import * as t from 'io-ts';

import {
  ManualBetPlayerStatus,
  ManualBetType,
  NavigationLinkType,
  Provider,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { notificationCodec } from '../notification/codecs';

/* Object data */
export const elementInfoCodec = t.intersection([
  t.type({
    StartDate: t.string,
    EventId: t.number,
    MarketId: t.number,
    OutcomeId: t.number,
    BaseOdds: t.number,
    EventType: t.number,
    Provider: createEnumType<Provider>(Provider),
  }),
  t.partial({
    Title1: t.string,
    Title2: t.string,
    EventName: t.string,
    MarketName: t.string,
    OutcomeName: t.string,
    MarketBase: t.string,
    FinalOdds: t.number,
  }),
]);

export const manualBetCodec = t.intersection(
  [
    t.type({
      Id: t.string,
      MaxStake: t.number,
      PlayerStatus: createEnumType<ManualBetPlayerStatus>(
        ManualBetPlayerStatus,
      ),
      LinkType: createEnumType<NavigationLinkType>(NavigationLinkType),
    }),
    t.partial({
      MinStake: t.number,
      NavigationLink: t.string,
      RelatedEventId: t.number,
      UsedDate: t.string,
      ManualBetConfigId: t.string,
      Type: createEnumType<ManualBetType>(ManualBetType),
      ElementInfo: elementInfoCodec,
    }),
  ],
  'ManualBetCodec',
);

/* Response data */

export const getAvailableManualBetsResponseCodec = t.partial(
  {
    ManualBets: t.array(manualBetCodec),
    Notification: notificationCodec,
  },
  'GetAvailableManualBetsResponseCodec',
);

export const hasAvailableManualBetResponseCodec = t.intersection(
  [
    t.type({
      IsAvailable: t.boolean,
    }),
    t.partial({
      RelatedEventIds: t.array(t.number),
      Notification: notificationCodec,
    }),
  ],
  'HasAvailableManualBetResponseCodec',
);
