import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { colors, device, fontSizes, media, spaces } from '@gaming1/g1-style';
import { AppLink, Box } from '@gaming1/g1-ui';

import { OUTCOME_BUTTON_HEIGHT_IN_PX, OutcomeButton } from '../OutcomeButton';

const INFO_CELL_MIN_HEIGHT_IN_PX = '15px';

export type SummaryComponentContProp = {
  colWidth?: number;
};

export const SummaryComponentContainer = styled(Box)<SummaryComponentContProp>`
  width: ${({ colWidth }) => (colWidth ? `${colWidth}px;` : `100%`)};
  align-items: flex-end;
  padding-top: ${spaces('xs')};
  padding-bottom: ${spaces('xs')};

  & + & {
    margin-left: ${spaces('sm')};
  }
`;

export const SummaryUSComponentContainer = styled(Box)<{ colWidth?: number }>`
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;

  ${({ colWidth }) =>
    colWidth &&
    css`
      width: ${colWidth}px;
      flex: initial;
    `};

  & + & {
    margin-left: ${spaces('xs')};
  }
`;

export const MarketUSSummaryContainer = styled(Box)<{ colWidth?: number }>`
  width: ${({ colWidth }) => (colWidth ? `${colWidth}px;` : `100%`)};
  flex-direction: row;
  flex: 1;
  height: 120px;
  & + & {
    margin-left: ${spaces('sm')};
  }
`;

export const EventRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors('backgrounds', { index: 2 })};
  margin-bottom: 1px;
  min-height: 101px;

  padding: ${spaces('xs')};

  ${media.sm} {
    padding: ${spaces('xs')} ${spaces('sm')};
  }
  ${device.canHover} {
    &:hover {
      background-color: ${colors('backgrounds', { index: 3 })};
    }
  }
`;

export const OutcomeBaseOutcomeSummary = styled(Box)`
  opacity: 0.5;
  font-size: ${fontSizes('sm')};
  margin-bottom: ${spaces('xs')};
`;
export const EventCell = styled(Box)`
  display: flex;
`;

export const EventDetailsCell = styled(EventCell)`
  align-items: left;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  min-width: 0px;
  overflow: hidden;
  width: 0px;
`;

export const EventInfosCell = styled(EventCell)`
  align-items: center;
  flex-direction: row;
  min-width: 0px;
  min-height: ${INFO_CELL_MIN_HEIGHT_IN_PX};
`;

export const EventCenterCell = styled(EventCell)`
  height: 100%;
  padding: 0 ${spaces('sm')};
  align-self: center;
  justify-content: center;
`;

export const EventStatsCenterCell = styled(Box)`
  height: 100%;
  justify-content: center;
`;

export type EventMarketsCellProp = {
  colWidth?: number;
};

export const EventMarketsCell = styled(EventCell)<EventMarketsCellProp>`
  flex-direction: row;
  align-items: flex-end;
  min-width: ${({ colWidth }) => (colWidth ? `${colWidth}px;` : 'auto')};
  overflow: auto;

  > div + div {
    margin-left: ${spaces('sm')};
  }
`;

export const OutcomeSummaryButton = styled(OutcomeButton)<{
  colWidth?: number;
}>`
  ${({ colWidth }) =>
    colWidth &&
    css`
      width: ${colWidth}px;
      flex: initial;
    `};

  & + & {
    margin-left: ${spaces('xxs')};
  }
`;

export const OutcomeUSSummaryButton = styled(OutcomeButton)<{
  colWidth?: number;
}>`
  width: 100%;

  & + & {
    margin-top: ${spaces('xxs')};
  }
`;

export const DisabledOutcomeSummary = styled(Box)<{
  colWidth?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: ${fontSizes('sm')};
  height: ${OUTCOME_BUTTON_HEIGHT_IN_PX};
  ${({ colWidth }) =>
    colWidth &&
    css`
      width: ${colWidth}px;
      flex: initial;
    `};
`;

export const DateSpan = styled.span`
  color: ${colors('text', { transform: transparentize('0.35') })};
  font-size: ${fontSizes('sm')};
`;

export const EventName = styled(AppLink)`
  color: ${colors('neutrals', { index: 0 })};
  font-size: ${fontSizes('md')};
  padding: ${spaces('xs')} 0;

  &:hover {
    color: ${colors('neutrals', { index: 0 })};
  }
`;

export const EventNameTeam1 = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

export const EventNameTeam2 = styled.div`
  padding-top: ${spaces('xxs')};
`;

export const TeamName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Separator = styled.div`
  flex: 0 0 auto;
  padding-left: ${spaces('xxs')};
`;

export const Markets = styled(AppLink)`
  color: ${colors('text', { transform: transparentize('0.35') })};
  font-size: ${fontSizes('sm')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;

  &:hover {
    color: ${colors('neutrals', { index: 3 })};
  }
`;

export const WinnerEventLink = styled(AppLink)`
  color: ${colors('primary')};
  margin: auto ${spaces('sm')};

  &:hover {
    color: ${colors('primary', { transform: darken(0.1) })};
  }
`;
