import {
  Action,
  AnyAction,
  applyMiddleware,
  compose,
  createStore as createReduxStore,
  Middleware,
  PreloadedState,
  Reducer,
  ReducersMapObject,
} from 'redux';
import { createEpicMiddleware, Epic, StateObservable } from 'redux-observable';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AppConfig, getConfig } from '@gaming1/g1-config';
import { ajaxFetch, wsAdapter } from '@gaming1/g1-network';

import { createReducerManager } from './reducersManager';
import { AnyState, EpicDependencies, LazyStore } from './types';

// The lib can be used in both dom and non dom env
type GlobalWithConditionalWindow = NodeJS.Global & {
  window?: {
    // using "typeof compose" should work but it doesn't
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  };
};
const conditionalWindow = (global as GlobalWithConditionalWindow).window;

// By default, no custom dependency  is set
const defaultCustomDependencies = {};
type DefaultCustomDependencies = typeof defaultCustomDependencies;

/* REDUX DEV TOOL */

// This property name cannot be changed
const composeEnhancers =
  typeof conditionalWindow !== 'undefined' &&
  conditionalWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? conditionalWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export type StoreCreationConfig<
  State extends Record<string, unknown> = AnyState,
  Actions extends Action = AnyAction,
  CustomDependencies extends DefaultCustomDependencies = DefaultCustomDependencies,
> = {
  // TODO: put back ReducersMapObject<AppState>, when https://github.com/Microsoft/TypeScript/issues/21592 is fixed
  reducers: ReducersMapObject<State, Actions>;
  epics?: Epic;
  config$?: BehaviorSubject<AppConfig>;
  preloadedState?: PreloadedState<State>;
  dependencies?: EpicDependencies;
  customDependencies?: CustomDependencies;
  middlewares?: Middleware[];
};

const dummyEpic: Epic = () => EMPTY;

/**
 *
 * Create the redux store of the app
 * @param storeCreationConfig configuration for the store creation
 * @param storeCreationConfig.reducers Combined reducers
 * @param storeCreationConfig.epics Combined epics
 * @param storeCreationConfig.config Config from g1-config
 * @param storeCreationConfig.preloadedState Initial state of the store
 * @param storeCreationConfig.dependencies Dependencies that will be available
 *   in all epics
 * @param storeCreationConfig.customDependencies Custom dependencies that
 *   will be available in all epics
 */
export const createStore = <
  AppState extends Record<string, unknown> = AnyState,
  AppActions extends Action = AnyAction,
  CustomDependencies extends DefaultCustomDependencies = DefaultCustomDependencies,
>({
  reducers,
  epics = dummyEpic,
  config$ = new BehaviorSubject(getConfig()),
  middlewares = [],
  preloadedState,
  dependencies,
  customDependencies = defaultCustomDependencies as CustomDependencies,
}: StoreCreationConfig<AppState, AppActions, CustomDependencies>): LazyStore<
  AppState,
  AppActions
> => {
  // This allows us to add Reducers after the store iinitialisation
  const reducerManager = createReducerManager<AppState, AppActions>(reducers);

  type CombinedEpicDependencies = EpicDependencies &
    Omit<CustomDependencies, 'ajaxFetch' | 'config$' | 'wsAdapter'>;

  /* EPICS MIDDLEWARE */
  const epicDependencies: CombinedEpicDependencies = {
    ...customDependencies,
    ajaxFetch,
    config$,
    wsAdapter,
    ...dependencies,
  };

  const epicMiddleware = createEpicMiddleware<
    AppActions,
    AppActions,
    AppState,
    CombinedEpicDependencies
  >({
    dependencies: epicDependencies,
  });

  const enhancers = composeEnhancers(
    applyMiddleware(epicMiddleware, ...middlewares),
  );

  /* STORE CREATION */
  const store = createReduxStore<
    AppState,
    AppActions,
    typeof preloadedState,
    typeof enhancers
  >(reducerManager.reduce, preloadedState, enhancers);

  /* EPICS LAUNCH */
  const epic$: BehaviorSubject<
    Epic<AppActions, AppActions, AppState, EpicDependencies>
  > = new BehaviorSubject(epics);
  const rootEpics = (
    action$: Observable<AppActions>,
    state$: StateObservable<AppState>,
  ) => epic$.pipe(mergeMap((epic) => epic(action$, state$, epicDependencies)));

  // This come from the official doc (https://redux-observable.js.org/docs/recipes/HotModuleReplacement.html)
  //
  /*
  const hotReloadingEpic = (...args: any[]) =>
    epic$.pipe(switchMap((epic) => epic(...args)));
  */
  epicMiddleware.run(rootEpics);

  const addEpic = (epic: Epic) => epic$.next(epic);

  /* HOT MODULE RELOAD */

  // This is required to happen here for HMR to work
  /* eslint-disable global-require */
  /*
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers').default);
    });

    module.hot.accept('./epics', () => {
      const nextRootEpic = require('./epics').default;
      epic$.next(nextRootEpic);
    });
  }
  */

  return {
    ...store,
    addEpic,
    addReducer: <StoreKey extends string, StateSlice extends AnyState>(
      index: StoreKey,
      reducer: Reducer<StateSlice, AppActions>,
    ) => {
      reducerManager.add(index, reducer);
      store.replaceReducer(reducerManager.reduce);
    },
    removeReducer: (index: string) => {
      reducerManager.remove(index);
      store.replaceReducer(reducerManager.reduce);
    },
  };
};
