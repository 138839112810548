import {
  Elements,
  RichText,
  RichTextBlock,
  RichTextSpan,
} from 'prismic-reactjs';
import React, { FC, ReactNode, useContext, useMemo } from 'react';
import { TypographyProps } from 'styled-system';

import { PrismicRichTextElement } from '@gaming1/g1-cms';

import {
  PrismicChildrenContext,
  PrismicChildrenContextValue,
} from '../PrismicChildren/PrismicChildrenContext';
import { PrismicLink } from '../PrismicLink';

import { getTextAlignFromAlignment } from './helpers';
import {
  PrismicH1,
  PrismicH2,
  PrismicH3,
  PrismicH4,
  PrismicInnerContentWrapper,
  PrismicLi,
  PrismicOl,
  PrismicParagraph,
  PrismicUl,
} from './styles';

export type PrismicRichTextProps = {
  /** Specify if we must clear the margins of first and last child */
  clearMargins?: boolean;
  /** Prismic RichText elements to render */
  content: PrismicRichTextElement[];
  /** Test ID */
  testId?: string;
  /** Text alignment (left/center/right) */
  align?: string;
  /** ID of the slice */
  sliceId?: string | null;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
  /** Disable slice inclusions for this component */
  disableInclusion?: boolean;
} & TypographyProps;

type BlockElements = RichTextBlock['type'];

type SpanElement = RichTextSpan['type'];

// A list to be found here in prismic-reactjs/index.d.ts
const elementTypes = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'heading6',
  'paragraph',
  'preformatted',
  'strong',
  'em',
  'list-item',
  'o-list-item',
  'group-list-item',
  'group-o-list-item',
  'image',
  'embed',
  'hyperlink',
  'label',
  'span',
];

const spanTypes = ['em', 'strong', 'hyperlink', 'label'];

const isElement = (type: string): type is BlockElements =>
  elementTypes.includes(type);

const isSpanType = (type: string): type is SpanElement =>
  spanTypes.includes(type);

type ValidContent = {
  text: string;
  type: BlockElements;
  spans: RichTextSpan[];
};

const hasValidType = (
  content: PrismicRichTextElement,
): content is ValidContent =>
  isElement(content.type) &&
  content.spans.every((span) => isSpanType(span.type));

/**
 * Function to generate a serializer function, to serialize RichText elements to HTML
 * It is used to handle some features like internal links inside RichText, or slice inclusion
 */
const htmlSerializer =
  (
    PrismicChildren: PrismicChildrenContextValue['component'],
    disableInclusion: boolean,
    sliceId?: string | null,
    parentIds: string[] = [],
  ) =>
  (
    type: Elements,
    // Disable the "any" warning, since this signature is used by prismic function
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any,
    content: string,
    children: ReactNode[],
    key: string,
  ) => {
    const { data, text } = element;
    if (type === Elements.hyperlink && data) {
      const { url, target } = data;
      return (
        <PrismicLink link={url} target={target} key={key}>
          {children}
        </PrismicLink>
      );
    }
    if (type === Elements.paragraph && !disableInclusion) {
      const sliceIdRegex = /{{(.*)}}/g;
      const sliceIds: string[] = [];
      let sliceIdMatch = null;
      do {
        sliceIdMatch = sliceIdRegex.exec(text);
        if (sliceIdMatch && sliceIdMatch.length > 1) {
          sliceIds.push(sliceIdMatch[1].trim());
        }
      } while (sliceIdMatch);
      if (sliceIds.length > 0) {
        const newParentIds = sliceId ? [...parentIds, sliceId] : parentIds;
        return (
          <PrismicChildren
            childrenIds={sliceIds}
            parentIds={newParentIds}
            key={key}
          />
        );
      }
    }
    return null;
  };

/**
 * Render rich text content from Prismic using the component from
 * prismic-reactjs but add some style and fix the lists display
 */
export const PrismicRichText: FC<PrismicRichTextProps> = ({
  clearMargins = true,
  content,
  testId = 'prismic-rich-text',
  align,
  sliceId,
  parentIds,
  disableInclusion = false,
  ...props
}) => {
  /* Additional check because our type check use an union type while the typing
  of this lib uses an (unexported) enum for the "type" attribute */
  const validContent = content.filter(hasValidType);
  const { component: PrismicChildren } = useContext(PrismicChildrenContext);

  const serializer = useMemo(
    () => htmlSerializer(PrismicChildren, disableInclusion, sliceId, parentIds),
    [PrismicChildren, disableInclusion, parentIds, sliceId],
  );

  return (
    <PrismicInnerContentWrapper
      data-testid={testId}
      textAlign={getTextAlignFromAlignment(align)}
      clearMargins={clearMargins}
      {...props}
    >
      <RichText
        render={validContent}
        elements={{
          heading1: PrismicH1,
          heading2: PrismicH2,
          heading3: PrismicH3,
          heading4: PrismicH4,
          heading5: PrismicH4,
          heading6: PrismicH4,
          paragraph: PrismicParagraph,
          'group-o-list-item': PrismicOl,
          'group-list-item': PrismicUl,
          'list-item': PrismicLi,
          'o-list-item': PrismicLi,
        }}
        htmlSerializer={serializer}
      />
    </PrismicInnerContentWrapper>
  );
};
