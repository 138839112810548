/** Types of scoreboards that can be displayed */
export type ScoreBoardType = 'compact' | 'full' | 'prematch' | 'summary';

/** Ids of scoreboard sports that can be displayed */
export enum ScoreBoardSportId {
  AmericanFootball = 870,
  Badminton = 900,
  Baseball = 856,
  Basketball = 850,
  BeachVolley = 862,
  Bowls = 4655821,
  Boxing = 858,
  Cricket = 894,
  Cycling = 902,
  Darts = 882,
  EBasketball = 15310,
  EIceHockey = 19510,
  Football = 844,
  Golf = 898,
  Handball = 854,
  IceHockey = 846,
  MartialArts = 924,
  MixedMartialArts = 94499213,
  MotorCycleRacing = 19010,
  MotorSport = 16,
  Rugby = 866,
  Snooker = 868,
  Squash = 197402286,
  StockCarRacing = 19110,
  TableTennis = 884,
  Tennis = 848,
  Volleyball = 852,
}
