import styled from 'styled-components';

import { colors, fontSizes, fontWeight } from '@gaming1/g1-style';
import { Text } from '@gaming1/g1-ui';

export const StyledValidityDateText = styled(Text)`
  ${fontWeight('bold')};
  font-size: ${fontSizes('sm')};
  color: ${colors('neutrals', { index: 2 })};
`;
