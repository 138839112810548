import format from 'date-fns/format';
import React, { ComponentPropsWithoutRef, FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { Sponsorship } from '@gaming1/g1-loyalty';
import { Icon, Modal } from '@gaming1/g1-ui';

import { sponsorshipHistoryRequestStateKeys } from '../helpers';
import {
  MoreInformationModalLabel,
  MoreInformationModalRow,
  MoreInformationModalTitle,
  MoreInformationModalValue,
  MoreInformationModalWrapper,
} from '../styles';

export type SponsorshipHistoryInformationModalProps = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  history: Sponsorship;
};

const isValidStatus = (
  num: number,
): num is keyof typeof sponsorshipHistoryRequestStateKeys =>
  num in sponsorshipHistoryRequestStateKeys;

export const SponsorshipHistoryInformationModal: FC<
  SponsorshipHistoryInformationModalProps
> = ({ onClose, history, ...rest }) => {
  const { t } = useTranslation('loyalty');
  const { colors } = useContext(ThemeContext);
  const { i18n } = useContext(ConfigContext);

  const info = [
    {
      label: t('history.sponsorship.date'),
      testId: 'sponsorship-history-information-modal-date',
      value: format(new Date(history.CreatedDate), i18n.dateTimePartialFormat),
    },
    {
      label: t('history.sponsorship.sponsored'),
      testId: 'sponsorship-history-information-modal-sponsored',
      value: history.Godson,
    },
    {
      label: t('history.sponsorship.status'),
      testId: 'sponsorship-history-information-modal-status',
      value: isValidStatus(history.Status)
        ? t(sponsorshipHistoryRequestStateKeys[history.Status])
        : null,
    },
    {
      label: t('history.sponsorship.deposit'),
      testId: 'sponsorship-history-information-modal-deposit',
      value: history.WasDeposited ? (
        <Icon id="history-completed" type="CircleCheck" fill={colors.success} />
      ) : (
        <Icon
          id="history-notCompleted"
          type="CircleStop"
          fill={colors.danger}
        />
      ),
    },
    {
      label: t('history.sponsorship.progression'),
      testId: 'sponsorship-history-information-modal-progression',
      value: `${history.GodsonBonusProgression}%`,
    },
  ];

  return (
    <Modal
      modalId="sponsorship-history-information-modal"
      onClose={onClose}
      {...rest}
    >
      <>
        <MoreInformationModalTitle>
          {t('history.sponsorship.moreInformationTitle')}
        </MoreInformationModalTitle>
        <MoreInformationModalWrapper>
          {info.map((dataItem) => (
            <MoreInformationModalRow key={dataItem.label}>
              <MoreInformationModalLabel>
                {dataItem.label}
              </MoreInformationModalLabel>
              <MoreInformationModalValue data-testid={dataItem.testId}>
                {dataItem.value}
              </MoreInformationModalValue>
            </MoreInformationModalRow>
          ))}
        </MoreInformationModalWrapper>
      </>
    </Modal>
  );
};
