import { Location } from 'history';

import { isLocaleCode, LocaleCode } from '@gaming1/g1-config';
import {
  extractLocaleFromUrl,
  getLocaleRegex,
  interopMessages,
  removeLocaleFromUrl,
  trimUrl,
} from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { RouteList, RouteParams } from '@gaming1/g1-utils';

import { sendIframeMessage } from '../rninterop/iframeMessageManager/helpers';

/**
 * Replace the given locale in the URL
 */
export const replaceLocaleInUrl = (
  url: string,
  nextLocale: string,
): string | null => {
  const locale = extractLocaleFromUrl(url);
  return locale && isLocaleCode(nextLocale)
    ? trimUrl(url.replace(getLocaleRegex(locale), `/${nextLocale}/`))
    : null;
};

/**
 * Concat a location object (pathname, search, hash and locale code if needed)
 */
export const concatLocation = (
  location: Pick<Location, 'hash' | 'pathname' | 'search'>,
  locale = '',
) => {
  const newPathname = locale
    ? removeLocaleFromUrl(location.pathname)
    : location.pathname;

  const trimedSearch =
    location.search.charAt(0) === '?'
      ? location.search.substr(1)
      : location.search;
  const trimedHash =
    location.hash.charAt(0) === '#' ? location.hash.substr(1) : location.hash;

  const { hash, pathname, search } = {
    hash: trimedHash ? `#${trimedHash}` : '',
    pathname: newPathname ? `/${newPathname}` : '',
    search: trimedSearch ? `?${trimedSearch}` : '',
  };

  return trimUrl(`/${locale}${pathname}${search}${hash}`);
};

type SearchParams = { [k: string]: string };

// TODO: find out (and fix) why using this with a route with params works in the codebase but throws an error in spec files
/**
 * Return a function to extract path from route objects
 * @param routes a route list
 * @param localeCode the locale code to insert at the start of the URL
 */
export const getRoutePath =
  <Routes extends RouteList>(routes: Routes, localeCode: LocaleCode) =>
  /**
   * Extract the path of a route object
   * @param index the unique index of the route
   * @param parameters either undefined or an object of strings to fill the url
   * parameters (when needed)
   * @param searchParameters an optional key-value object of search params
   */
  <RouteIndex extends keyof Routes>(
    index: RouteIndex,
    ...parameters: RouteParams<Routes[RouteIndex]> extends undefined
      ? [undefined?, SearchParams?]
      : [RouteParams<Routes[RouteIndex]>, SearchParams?]
  ) => {
    const routeParams = routes[index].parameters;
    const routePath = routes[index].path;
    const params = parameters[0];
    const searchParams = parameters[1];
    let path = '';

    if (!params || !routeParams || !Object.keys(routeParams).length) {
      path = routePath;
    } else {
      // Replace every :param or :param? in the path with the value
      path = Object.entries(routeParams)
        .map(([key]) => [key, params[key]])
        .reduce(
          (acc, [key, value]) => acc.replace(new RegExp(`:${key}\\??`), value),
          routePath,
        );
    }
    if (!searchParams) {
      return trimUrl(`/${localeCode}${path}`);
    }
    const search = Object.entries(searchParams).reduce(
      (acc, [key, value]) =>
        `${acc}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      '',
    );
    return trimUrl(`/${localeCode}${path}?${search.slice(1)}`);
  };

/**
 * Navigate to an URL :
 * - In a native environment, will send an interop message of type 'openExternalLinkView'
 * (will open the external WebView).
 * - In a web environment, will navigate using window.location.assign
 * (will replace the URL in the current tab).
 *
 * @param url The URL to navigate to
 * @param title The title of the ExternalWebView
 */
export const navigateUsingExternalLinkView = (
  url: string,
  title?: string | null,
) => {
  if (getWrapperEnv() === 'rn') {
    sendIframeMessage(interopMessages.openExternalLinkView, { url, title });
  } else {
    window.location.assign(url);
  }
};
