import React, { memo, useContext, useEffect, useRef } from 'react';

import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../../../BettingSlipContext';
import { BettingSlipFooter } from '../../../BettingSlipFooter';
import { BettingSlipMenu } from '../../../BettingSlipMenu';
import { BettingSlipItems } from '../BettingSlipItems';

/**
 * Display the content ticket
 * a list of item inside select by user and save into store
 */

const BettingSlipSwitchContentComponent = () => {
  const { setRefToScrollTo } = useContext(BettingSlipContext);
  const bettingSlipFooterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRefToScrollTo(bettingSlipFooterRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box justifyContent="space-between" flex={1}>
      <div data-testid="bettingslip-switchcontent-menu-items">
        <BettingSlipMenu />
        <BettingSlipItems />
      </div>
      <div data-testid="bettingslip-switchcontent-offers-footer">
        <BettingSlipFooter ref={bettingSlipFooterRef} />
      </div>
    </Box>
  );
};

export const BettingSlipSwitchContent = memo(BettingSlipSwitchContentComponent);
