import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, fontWeight, spaces } from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

const MARGIN_BETWEEN_LINK_IN_PX = 1;

const BettingSlipContainerBaseCss = css`
  ${fontWeight('bold')};
  display: flex;
  background-color: ${colors('backgrounds', { index: 2 })};
  margin: ${MARGIN_BETWEEN_LINK_IN_PX}px 0;
  height: 40px;
  text-decoration: none;
  color: ${colors('text')};
  padding: ${spaces('md')} ${spaces('sm')};
  align-items: center;
`;

export const BettingSlipLinkContainer = styled(NavLink)`
  ${BettingSlipContainerBaseCss}
`;

export const BettingSlipButtonContainer = styled(SimpleButton)`
  ${BettingSlipContainerBaseCss}
`;
