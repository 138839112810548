import { interval, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { EWebSocketStatus } from '@gaming1/g1-network';
import {
  createFailurePayload,
  isCodecGuardError,
  mapGuard,
  parseUTCDate,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { currentWebSocketLocaleSelector } from '../i18n/selectors';
import { logger } from '../logger';
import { blackBoxesSelector } from '../tracking/selectors';
import { CoreEpic } from '../types';
import { userLoggedInSelector } from '../user/selectors';

import {
  getAuthTokenSuccessResponseCodec,
  impersonateUserSuccessResponseCodec,
  loginFailureResponseCodec,
  loginSuccessResponseCodec,
  logoutInjectedRequestCodec,
  sessionKeepAliveResponseCodec,
  tokenAuthenticationResponseCodec,
} from './codecs';
import { loginErrorMessages } from './errorMessages';

/** Login the user with a request to the ajax API, using username and password  */
export const loginEpic: CoreEpic = (action$, state$, { ajaxFetch, config$ }) =>
  action$.pipe(
    filter(isActionOf(actions.login.request)),
    withLatestFrom(state$, config$),
    mergeMap(([action, state, config]) => {
      const birthDateInput = action.payload.birthDate;
      const birthDate = birthDateInput
        ? parseUTCDate(birthDateInput)
        : undefined;
      return ajaxFetch(`${config.network.apiUrl}/Ajax/AjaxHandler.ashx`, {
        method: 'POST',
        body: {
          Login: action.payload.username,
          Password: action.payload.password,
          BirthDate: birthDate ? birthDate.toISOString() : undefined,
          BlackBox: blackBoxesSelector(state).ioBlackBox ?? undefined,
          FirstPartyBlackBox: blackBoxesSelector(state).fpBlackBox ?? undefined,
        },
        queryParams: {
          action: 'login',
        },
        withCredentials: true,
      }).pipe(
        mapGuard(loginSuccessResponseCodec),
        map((response) =>
          actions.login.success({
            ...response,
            Username: action.payload.username || '',
          }),
        ),
        catchError((err) => {
          /**
           * Check if we can decode the failure response.
           * It means that it's a failure we can manage
           * */
          if (
            isCodecGuardError(err) &&
            loginFailureResponseCodec.is(err.value)
          ) {
            return of(
              actions.login.failure({
                ...createFailurePayload(err, loginErrorMessages),
                exclusionDate: err.value.ExclusionDate,
              }),
            );
          }
          /** If not, we fallback to a more generic error */
          return of(
            actions.login.failure(
              createFailurePayload(err, loginErrorMessages),
            ),
          );
        }),
      );
    }),
  );

/** Get a new auth token from the ajax API */
export const getAuthTokenEpic: CoreEpic = (
  action$,
  _,
  { ajaxFetch, config$ },
) =>
  action$.pipe(
    filter(isActionOf(actions.refreshAuthToken.request)),
    withLatestFrom(config$),
    mergeMap(([, config]) =>
      ajaxFetch(`${config.network.apiUrl}/Ajax/AjaxHandler.ashx`, {
        method: 'POST',
        queryParams: {
          action: 'gettoken',
        },
        withCredentials: true,
      }).pipe(
        mapGuard(getAuthTokenSuccessResponseCodec),
        map(actions.refreshAuthToken.success),
        catchError((err) =>
          of(
            actions.refreshAuthToken.failure(
              createFailurePayload(err, undefined, false),
            ),
          ),
        ),
      ),
    ),
  );

/** When a token is received from the ajax API, auth the user on the websocket with it  */
export const tokenToAuthEpic: CoreEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.login.success,
        actions.refreshAuthToken.success,
        actions.impersonateUser.success,
      ]),
    ),
    mergeMap((action) => {
      /**
       * Send the session to the WS id if any found in the login/getToken
       * network calls
       */
      let sessionId = '';
      if ('SessionId' in action.payload && !!action.payload.SessionId) {
        sessionId = action.payload.SessionId;
      } else if (
        'BusinessSessionId' in action.payload &&
        !!action.payload.BusinessSessionId
      ) {
        sessionId = action.payload.BusinessSessionId;
      }
      const shouldSendMetaData = !!sessionId;
      // Send the SessionId if found in the action payload
      const sentMetadata$ = shouldSendMetaData
        ? wsAdapter.sendMetadata({
            SessionId: sessionId,
          })
        : null;
      // Wait for the metadata ack if needed otherwise, continue immediately
      // IsWaitForMetaDataAckEnabled is no longer relevant, the wait should always be done
      const readyToAuth$ = sentMetadata$ || of(true);
      return readyToAuth$.pipe(mapTo(action));
    }),
    map((action) =>
      'AuthenticationToken' in action.payload
        ? action.payload.AuthenticationToken
        : action.payload.Token,
    ),
    withLatestFrom(state$),
    map(([token, state]) =>
      actions.auth.request({
        Token: token,
        LanguageCode: currentWebSocketLocaleSelector(state),
      }),
    ),
  );

/** auth the user with the WS */
export const authEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.auth.request)),
    mergeMap((action) =>
      wsAdapter.loginUser(action.payload.Token).pipe(
        mapGuard(tokenAuthenticationResponseCodec),
        map(actions.auth.success),
        catchError((err) =>
          of(actions.auth.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/** When the WebSocket (re-)init, and the user is logged in, request another token */
export const authReconnectEpic: CoreEpic = (_, state$, { wsAdapter }) =>
  wsAdapter.status$.pipe(
    filter((status) => status === EWebSocketStatus.ready),
    withLatestFrom(state$),
    filter(([, state]) => !!userLoggedInSelector(state)),
    map(() => actions.refreshAuthToken.request()),
  );

/**
 * After the user successfully authenticated himself,
 * launch the a keep alive request every x ms
 */
export const keepAliveIntervalEpic: CoreEpic = (action$, _, { config$ }) =>
  action$.pipe(
    filter(isActionOf(actions.auth.success)),
    withLatestFrom(config$),
    switchMap(([, config]) =>
      interval(config.network.keepAliveInterval).pipe(
        takeUntil(action$.pipe(filter(isActionOf(actions.logout.request)))),
      ),
    ),
    mapTo(actions.keepSessionAlive.request()),
  );

/** Send a keep alive request to the HTTP API and logout the user if the
 * "Authenticated" property in the response is set to false
 */
export const keepSessionAliveEpic: CoreEpic = (
  action$,
  _,
  { ajaxFetch, config$ },
) =>
  action$.pipe(
    filter(isActionOf(actions.keepSessionAlive.request)),
    withLatestFrom(config$),
    switchMap(([, config]) =>
      ajaxFetch(`${config.network.apiUrl}/Ajax/AjaxHandler.ashx`, {
        method: 'POST',
        queryParams: {
          action: 'keepalive',
        },
        withCredentials: true,
      }).pipe(
        mapGuard(sessionKeepAliveResponseCodec),
        map((response) => !response.Authenticated),
        tap(
          (shouldLogoutTheUser) =>
            shouldLogoutTheUser &&
            logger.warn('[WS] Logging out the user due to a keepalive'),
        ),
        map((shouldLogoutTheUser) =>
          shouldLogoutTheUser
            ? actions.logout.request()
            : actions.keepSessionAlive.success(),
        ),
        catchError((err) =>
          of(actions.keepSessionAlive.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

/** Attempt to get a user token at the start of the application */
export const getTokenOnInitEpic: CoreEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.initApp)),
    map(() => actions.refreshAuthToken.request()),
  );

/** Logout the user from the front API if the keep alive request sent
 * Authenticated === false or if the user has manually logged out */
export const logoutAjaxEpic: CoreEpic = (action$, _, { config$, ajaxFetch }) =>
  action$.pipe(
    filter(isActionOf(actions.logout.request)),
    withLatestFrom(config$),
    mergeMap(([, config]) =>
      ajaxFetch(`${config.network.apiUrl}/Ajax/AjaxHandler.ashx`, {
        method: 'POST',
        queryParams: {
          action: 'logout',
        },
        withCredentials: true,
      }),
    ),
    mapTo(actions.logout.success()),
    catchError((err) => of(actions.logout.failure(createFailurePayload(err)))),
  );

/** Logout the user from the Web Socket if the keep alive request sent
 * Authenticated === false or if the user has manually logged out */
export const logoutWsEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.logout.request)),
    tap(() => wsAdapter.logoutUser()),
    ignoreElements(),
  );

/** Map login action to core login action */
export const successAuthToCoreLoginEpic: CoreEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.auth.success)),
    mapTo(actions.loggedInUser()),
  );

/** Map logout action to core logout action */
export const logoutToCoreLogoutEpic: CoreEpic = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.auth.failure,
        actions.refreshAuthToken.failure,
        actions.logout.success,
      ]),
    ),
    withLatestFrom(state$),
    map(([, state]) => actions.loggedOutUser(!!userLoggedInSelector(state))),
  );

/**
 * On some occasions (wrong business session id, lack of user activity...), the
 * backend can ask for the user to be logged out. This epic ensure that it is
 * done
 */
export const logoutOnInjectedPackageEpic: CoreEpic = (_, __, { wsAdapter }) =>
  wsAdapter.getInjectedRequests$('AccessManager').pipe(
    filter((req) => logoutInjectedRequestCodec.is(req)),
    mapTo(actions.logout.request()),
  );

/** Logout the current user and ask for a token to impersonate another user */
export const impersonateUserEpic: CoreEpic = (
  action$,
  _,
  { ajaxFetch, config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.impersonateUser.request)),
    tap(() => wsAdapter.logoutUser()),
    withLatestFrom(config$),
    mergeMap(
      ([
        {
          payload: { Login, Password, Domain, ImpersonationId },
        },
        config,
      ]) =>
        ajaxFetch(`${config.network.apiUrl}/Ajax/Impersonate.ashx`, {
          method: 'POST',
          body: {
            Login,
            Password,
            Domain,
            ImpersonationId,
          },
        }).pipe(
          mapGuard(impersonateUserSuccessResponseCodec),
          map(actions.impersonateUser.success),
          catchError((err) =>
            of(actions.impersonateUser.failure(createFailurePayload(err))),
          ),
        ),
    ),
  );
