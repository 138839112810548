import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import {
  ManualBetPlayerStatus,
  PlayerCashoutPolicy,
} from '@gaming1/g1-requests-betting';
import { RemoteData } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../store/types';

const getPlayerBettingProfileSelector = (state: ApplicationWithBettingState) =>
  state.betting.player;
export const getPlayerBettingProfileResultSelector = (
  state: ApplicationWithBettingState,
) => memoize(() => getPlayerBettingProfileSelector(state).result);
export const getPlayerBettingProfileRequestSelector = (
  state: ApplicationWithBettingState,
) =>
  memoize(
    () =>
      getPlayerBettingProfileSelector(state).requests.getPlayerBettingProfile,
  );
export const getPlayerBettingProfileRequestStatusSelector = (
  state: ApplicationWithBettingState,
) => memoize(() => getPlayerBettingProfileRequestSelector(state)().status);
export const hasCashoutAvailableForPlayerSelector = (
  state: ApplicationWithBettingState,
) =>
  memoize(() =>
    /* if request is not ask or back end not answer us we need to show icon by default */
    getPlayerBettingProfileRequestStatusSelector(state)() === RemoteData.Success
      ? getPlayerBettingProfileSelector(state).result.CashoutProfile
          ?.CashoutPolicy === PlayerCashoutPolicy.Allowed
      : true,
  );
/**
 * Returns a boolean to know if the user has already done
 * his first deposit or not.
 */
export const hasPlayerAlreadyDoneHisFirstDeposit = createSelector(
  getPlayerBettingProfileSelector,
  (playerProfile) => memoize(() => playerProfile.result.FirstDepositDone),
);
/**
 * Returns a boolean to know if the user has already participated
 * in the Super Odds Welcome offer.
 */
export const hasPlayerAlreadyUsedSuperOdds = createSelector(
  getPlayerBettingProfileSelector,
  (playerProfile) =>
    memoize(
      () =>
        playerProfile.result.SuperOddsStatus ===
        ManualBetPlayerStatus.AlreadyParticipate,
    ),
);

/**
 * Returns a boolean to know if the user is not allowed to participate
 * in the Super Odds Welcome offer.
 */
export const isPlayerNotEligibleForSuperOdds = createSelector(
  getPlayerBettingProfileSelector,
  (playerProfile) =>
    memoize(
      () =>
        playerProfile.result.SuperOddsStatus ===
        ManualBetPlayerStatus.NotAllowedToParticipate,
    ),
);

/**
 * Returns a boolean to know if the user is eligible to participate
 * to the Super Odds Welcome offer.
 * (first deposit not done + first welcome offer not choosen)
 */
export const isPlayerEligibleForSuperOdds = createSelector(
  getPlayerBettingProfileSelector,
  (playerProfile) =>
    memoize(
      () =>
        playerProfile.result.SuperOddsStatus === ManualBetPlayerStatus.Eligible,
    ),
);
/**
 * Returns a boolean to know if the user can participate
 * to the Super Odds Welcome offer.
 * (first deposit done + Super Odds choosen as welcome offer)
 */
export const canPlayerParticipateToSuperOdds = createSelector(
  getPlayerBettingProfileSelector,
  (playerProfile) =>
    memoize(
      () =>
        playerProfile.result.SuperOddsStatus ===
        ManualBetPlayerStatus.Participate,
    ),
);
