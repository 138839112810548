import styled from 'styled-components';

import { Box } from '@gaming1/g1-ui';

import { ScoreBoardStatsAppAnchor } from '../ScoreBoardStats/styles';

const SCOREBOARD_TEMPLATE_MANAGER_STATS_ICON_TOP_IN_PX = 4;
const SCOREBOARD_TEMPLATE_MANAGER_STATS_ICON_RIGHT_IN_PX = 4;

export const ScoreBoardTemplateManagerWrapper = styled(Box)`
  position: relative;
  height: 100%;

  ${ScoreBoardStatsAppAnchor} {
    position: absolute;
    top: ${SCOREBOARD_TEMPLATE_MANAGER_STATS_ICON_TOP_IN_PX}px;
    right: ${SCOREBOARD_TEMPLATE_MANAGER_STATS_ICON_RIGHT_IN_PX}px;
  }
`;
