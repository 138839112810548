import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import {
  addTicketItem,
  getInitialRootBettingSlipState,
  getTicketItem,
  haveTicketItem,
  removeTicketItem,
} from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const bettingSlipItemsReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.addBettingSlipOutcome): {
        const {
          payload: { bettingSlipId, ids },
        } = action;
        const { items } = draftState.bettingSlips[bettingSlipId];
        draftState.bettingSlips[bettingSlipId].items = addTicketItem(
          items,
          ids,
        );

        break;
      }
      case getType(actions.removeBettingSlipOutcome): {
        const {
          payload: { bettingSlipId, ids },
        } = action;

        if (!draftState.bettingSlips[bettingSlipId]) {
          return draftState;
        }
        const { items } = draftState.bettingSlips[bettingSlipId];
        draftState.bettingSlips[bettingSlipId].items = removeTicketItem(
          items,
          ids,
        );

        break;
      }
      case getType(actions.toggleBettingSlipOutcome): {
        const {
          payload: { bettingSlipId, ids },
        } = action;

        const { items } = draftState.bettingSlips[bettingSlipId];

        draftState.bettingSlips[bettingSlipId].items = haveTicketItem(
          items,
          ids,
        )
          ? removeTicketItem(items, ids)
          : addTicketItem(items, ids);
        break;
      }
      case getType(actions.toggleCheckItem): {
        const {
          payload: { bettingSlipId, itemId },
        } = action;

        const item = getTicketItem(
          draftState.bettingSlips[bettingSlipId],
          itemId,
        );

        if (!item) {
          return;
        }
        item.checked = !item.checked;

        break;
      }
      case getType(actions.updateOddChangeForItem): {
        const {
          payload: { bettingSlipId, itemId, change },
        } = action;

        const item = getTicketItem(
          draftState.bettingSlips[bettingSlipId],
          itemId,
        );
        if (!item) {
          return;
        }
        item.oddChange = change;

        break;
      }
      case getType(actions.resetOddChange): {
        const {
          payload: { bettingSlipId },
        } = action;

        draftState.bettingSlips[bettingSlipId].items.forEach((item) => {
          item.oddChange = null;
        });

        break;
      }

      default: // Immer will automatically return the state
    }
  });
