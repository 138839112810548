import React, { FC, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch } from 'react-router-dom';

import { bettingRoutes, getBettingRouter } from '@gaming1/g1-betting-web';
import { cmsRoutes, getCmsRouter } from '@gaming1/g1-cms-web';
import { ConfigContext } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-core';
import {
  AppRoute,
  catchGoneURL,
  catchMovedURL,
  coreRoutes,
  ErrorBoundary,
  getCoreRouter,
  LazyRoute,
  NotFoundPage,
} from '@gaming1/g1-core-web';
import { gamingRoutes, getGamingRouter } from '@gaming1/g1-gaming-web';
import { getLoyaltyRouter, loyaltyRoutes } from '@gaming1/g1-loyalty-web';
import { getPaymentRouter, paymentRoutes } from '@gaming1/g1-payment-web';
import { getUserRouter, userRoutes } from '@gaming1/g1-user-web';
import { RouteList } from '@gaming1/g1-utils';

import { getSiteDefaultRouter } from './router';
import { goneUrls, movedUrls } from './urlManifests';

export const AppRouter: FC = () => {
  const config = useContext(ConfigContext);
  const { currentLocale: locale, values } = useI18n();

  // Those routes will be accessible by their page name ('/?pagename=deposit' -> redirect to '/deposit')
  const routes: RouteList = {
    ...bettingRoutes,
    ...cmsRoutes,
    ...gamingRoutes,
    ...loyaltyRoutes,
    ...paymentRoutes,
    ...userRoutes,
  };

  return (
    <ErrorBoundary>
      <Helmet titleTemplate={`%s | ${values.siteName}`} />
      <Switch>
        {/* HomePage + pageName redirection handling */}
        <LazyRoute
          exact
          componentName="HomePage"
          importFactory={() => import('./pages/HomePage')}
          path={`/${locale}${coreRoutes.home.path}`}
          routes={routes}
          section="default"
          type="redirectable"
        />
        {/* App specific router */}
        {getSiteDefaultRouter(locale)}
        {/* Imported routers */}
        {getCoreRouter(locale, config)}
        {getBettingRouter(locale)}
        {getCmsRouter(locale)}
        {getGamingRouter(locale, config)}
        {getLoyaltyRouter(locale)}
        {getPaymentRouter(locale)}
        {getUserRouter(locale)}
        {/* 410 and 301 catchers */}
        {catchMovedURL(movedUrls)}
        {catchGoneURL(goneUrls)}
        {/* Nothing found or catched, this is a 404 */}
        <AppRoute path={undefined} component={NotFoundPage} section="default" />
      </Switch>
    </ErrorBoundary>
  );
};
