import { createContext } from 'react';

import { AssetsContext } from '@gaming1/g1-core-web';

export const GamingAssetsContext = createContext<AssetsContext>({
  /* Game tags */
  newGameTag: '',
  topGameTag: '',
  jackpotGameTag: '',
  /* Jackpot coin animation */
  jackpotCoin1: '',
  jackpotCoin2: '',
  jackpotCoin3: '',
  jackpotCoin4: '',
  jackpotCoin5: '',
  jackpotCoin6: '',
});
