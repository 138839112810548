import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import React, { useRef, VFC } from 'react';
import { SpaceProps } from 'styled-system';

import { useNetworkState } from '@gaming1/g1-core';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { EWebSocketStatus } from '@gaming1/g1-network';
import { Button } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';

import {
  RequestErrorMessageContainer,
  RequestErrorMessageImage,
  RequestErrorMessageText,
} from './styles';

export type RequestErrorMessageProps = {
  /**
   * Custom debounce time for the onRetryButtonClick callback.
   * Default to 500ms.
   */
  debounceTime?: number;
  /**
   * An error message to be displayed instead of the generic "unable to load
   * content" one. Must be already translated!
   */
  errorMessage?: string;
  /**
   * Should have an elevation
   * Default: true
   */
  hasElevation?: boolean;
  /**
   * Setting loading to true will show a loader and disable the button (thus
   * the callback won't be callable until the prop goes back to false)
   */
  loading?: boolean;
  /**
   * Callback called when the user clicks the retry button (as long as it is
   * not disabled and the debounce period has passed)
   * */
  onRetryButtonClick?: () => void;
  /**
   * Custom data-testid attr.
   * Default: 'RequestErrorMessage'
   */
  testId?: string;
} & SpaceProps;

/**
 * Component meant to be displayed when an UI component is unable to be shown
 * because the data it depends on hasn't been received from the backend. The
 * error could be a lack of network connexion, a timeout, an error in the
 * backend response structure.
 * This component offers a "retry" button that will attempt to re-execute the
 * network call that has failed (if the prop is given). This mecanism is meant
 * for the network calls that triggers when a component mounts (in contrast
 * with the forms with a submit button for example)
 */
export const RequestErrorMessage: VFC<RequestErrorMessageProps> = ({
  debounceTime = 500,
  errorMessage,
  hasElevation = true,
  loading = false,
  onRetryButtonClick,
  testId = 'requesterrormessage',
  ...spaceProps
}) => {
  const { t } = useTranslation('core');
  const debouncedCallback = useRef(
    debounce(onRetryButtonClick || noop, debounceTime, { leading: true }),
  ).current;
  const getAssetPath = useGetCoreAssetPath();
  const networkState = useNetworkState();
  const isNetworkOnline = networkState === EWebSocketStatus.ready;
  return (
    <RequestErrorMessageContainer
      {...spaceProps}
      data-testid={testId}
      hasElevation={hasElevation}
    >
      {isNetworkOnline ? (
        <RequestErrorMessageImage
          alt={t('alt.genericError')}
          id="generic-request-error"
          url={getAssetPath('genericError')}
        />
      ) : (
        <RequestErrorMessageImage
          alt={t('alt.networkError')}
          id="network-request-error"
          url={getAssetPath('networkError')}
        />
      )}
      <RequestErrorMessageText shouldHavePadding={!onRetryButtonClick}>
        {errorMessage || <Trans ns="core" i18nKey="error.networkGeneric" />}
      </RequestErrorMessageText>
      {onRetryButtonClick && (
        <Button
          type="tertiary"
          testId={`${testId}-button`}
          loading={loading}
          onClick={debouncedCallback}
          my="xs"
        >
          {t('error.retryButton')}
        </Button>
      )}
    </RequestErrorMessageContainer>
  );
};
