import { RawDataBlock, RawGTMEvent } from './codecs';
import { GTMDataBlock } from './types';

const keyReplacements: Record<string, string> = {
  custom_dimension4: 'userID',
  custom_dimension5: 'gameName',
  event_name: 'name',
  event_category: 'category',
  event_action: 'action',
  event_label: 'label',
  event_value: 'value',
};

const valueReplacements: Record<string, string> = {
  eventGA: 'event',
  game_start: 'start a game',
};

/** Changes some misnamed key or values sent by the backend */
const refineEventData = ({ Key, Value }: Required<RawGTMEvent>) => ({
  Key: keyReplacements[Key] ?? Key,
  Value: valueReplacements[Value] ?? Value,
});

/**
 * Convert "True" and "False" values to 0 and 1
 * It looks like the backend is only able to send strings for all values
 * but GTM expect a number on "value" eventProps
 */
const makeValueNumbers = (keyValueEvent: Required<RawGTMEvent>) =>
  keyValueEvent.Key === 'value' &&
  ['True', 'False'].includes(keyValueEvent.Value)
    ? {
        Key: keyValueEvent.Key,
        Value: keyValueEvent.Value === 'False' ? 0 : 1,
      }
    : keyValueEvent;

const isValidKeyValuePair = (
  event: RawGTMEvent,
): event is Required<RawGTMEvent> => !!event.Key && !!event.Value;

export const formatGTMEvents = (dataBlocks: RawDataBlock[]): GTMDataBlock[] =>
  dataBlocks.map(({ Id, List, IsGoogleAnalytics4Event }) => ({
    id: Id,
    isGoogleAnalytics4Event: IsGoogleAnalytics4Event,
    data: List.filter(isValidKeyValuePair)
      .map(refineEventData)
      .map(makeValueNumbers)
      .reduce<Record<string, string | number>>((acc, { Key, Value }) => {
        acc[Key] = Value;
        return acc;
      }, {}),
  }));
