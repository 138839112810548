import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { colors, fontSizes, spaces } from '@gaming1/g1-style';

import { Icon } from '../Icon';

import { ArrowType } from './types';

export const SelectContainerDiv = styled.div<{ arrowType: ArrowType }>`
  overflow: hidden;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  ${({ arrowType }) =>
    arrowType === 'full' &&
    css`
      &:after {
        content: '';
        position: absolute;
        display: block;
        pointer-events: none;
        width: 0;
        height: 0;
        top: calc(50% - 3px);
        right: 12px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid
          ${colors('text', { transform: transparentize('0.5') })};
      }
    `}
`;

export const SelectArrowIcon = styled(Icon)`
  height: 12px;
  width: 12px;
  position: absolute;
  right: 8px;
`;

type AppSelectProps = {
  /**
   * Whether the input should show an erroneous state
   * Default: false
   */
  error?: boolean;
};

export const AppSelect = styled.select<AppSelectProps>`
  cursor: pointer;
  color: ${colors('text')};
  padding: ${spaces('sm')} 0;
  padding-right: ${spaces('md')};
  appearance: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${colors('inputBorder')};
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  background-image: none;

  &,
  &:focus {
    /* Must be at least 16px for iOS to stop zooming on focus */
    font-size: ${fontSizes('lg')};
  }

  &:focus {
    border-bottom: 2px solid ${colors('inputActive')};
    padding-bottom: calc(${spaces('sm')} - 1px);

    outline: none;
  }

  ${({ error }) =>
    error &&
    css`
      &,
      &:focus {
        border-bottom-color: ${colors('danger')};
      }
    `}

  &::-ms-expand {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }

  option {
    color: ${colors('textDark')};
    padding: ${spaces('xxs')};
  }
`;
