import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  bettingUserSettingsSelector,
  EBettingSlipIdentifier,
  placeBettingSlipResultGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { AppAnchor, Box, Button, LayoutContext, Message } from '@gaming1/g1-ui';

import { HistoryCard } from '../../../history/components/HistoryCard';
import { BettingSlipContext } from '../../BettingSlipContext';
import { useGetSuccessMenu } from '../../hooks';
import { useIsMobileTicket } from '../../hooks/render';
import { BettingSlipMobileLinks } from '../BettingSlipMobileLinks';

import {
  BettingSlipSuccessStateButtonContainer,
  BettingSlipSuccessStateContainer,
  BettingSlipSuccessStateMessageContainer,
} from './styles';

type askCloseType = {
  ask: boolean;
  forcedKeep: boolean;
};

const generateAskType = (forcedKeep?: boolean) => ({
  ask: true,
  forcedKeep: !!forcedKeep,
});
const generateResetAskType = () => ({ ask: false, forcedKeep: false });
/**
 * Display when bettingslip has placed
 *
 */
export const BettingSlipSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('betting');
  const { bettingSlipId } = useContext(BettingSlipContext);
  const { hideDrawer } = useContext(LayoutContext);

  const isMobileTicket = useIsMobileTicket();

  const placeBetResult = useSelector(placeBettingSlipResultGetterSelector)(
    EBettingSlipIdentifier.Main,
  );

  const resultTicketId = placeBetResult?.result?.TicketSummaryId;

  const cleanBS = useSelector(bettingUserSettingsSelector)?.bCleanBettingSlips;

  const [askClose, setAskClose] = useState<askCloseType>(
    generateResetAskType(),
  );

  // react to ask close state
  useEffect(() => {
    if (!askClose.ask) {
      return;
    }
    if (!askClose.forcedKeep && cleanBS) {
      dispatch(actions.cleanBettingSlip(bettingSlipId));
    }
    dispatch(actions.placeBet.cancel({ bettingSlipId }));
    if (isMobileTicket) {
      hideDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askClose]);

  const forcedKeepAndClose = () => {
    setAskClose(generateAskType(true));
  };

  const close = useCallback(() => {
    setAskClose(generateAskType());
  }, []);

  // execute reset on page reload
  useEffect(() => {
    window.addEventListener('beforeunload', close);
    return () => {
      window.removeEventListener('beforeunload', close);
    };
  }, [close]);

  // execute reset on unmount the componenent
  useEffect(
    () => () => {
      close();
      setAskClose(generateResetAskType());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const menu = useGetSuccessMenu();

  return (
    <BettingSlipSuccessStateContainer data-testid="bettingslip-success-state-container">
      <Box>
        <BettingSlipSuccessStateMessageContainer>
          <Message p="xs" size="small" type="success">
            {t('bettingslip.successstate.message')}
          </Message>
        </BettingSlipSuccessStateMessageContainer>

        {resultTicketId ? (
          <HistoryCard
            activeTab="fromTicket"
            betId={resultTicketId}
            hasMobileView
          />
        ) : null}

        <Box flexDirection="row" justifyContent="center" my="sm">
          <AppAnchor onClick={forcedKeepAndClose}>
            {t('bettingslip.successstate.keepselection')}
          </AppAnchor>
        </Box>
      </Box>
      <Box>
        {isMobileTicket && <BettingSlipMobileLinks menu={menu} />}
        <BettingSlipSuccessStateButtonContainer>
          <Button type="primary" onClick={close}>
            {t('bettingslip.successstate.validation')}
          </Button>
        </BettingSlipSuccessStateButtonContainer>
      </Box>
    </BettingSlipSuccessStateContainer>
  );
};
