import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  BettingHistoryElementType,
  getScoreBoardIsEventRemovedSelector,
  getScoreBoardSelector,
  getScoreBoardTeamNamesSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box, Text } from '@gaming1/g1-ui';

import { ScoreBoard } from '../../../score/components/ScoreBoard';
import { ScoreBoardPlayTime } from '../../../score/components/ScoreBoardPlayTime';

import { EventFullName, EventName } from './styles';
import { TypeViewStyle } from './types';

type EventProps = {
  info: BettingHistoryElementType;
} & TypeViewStyle;
export const Event: VFC<EventProps> = ({ info, view }) => {
  const scoreBoard = useBettingGetterSelector({
    getterSelector: getScoreBoardSelector,
    args: [info.EventId],
    equalityFn: shallowEqual,
  });
  const scoreBoardIsEventRemoved = useBettingGetterSelector({
    getterSelector: getScoreBoardIsEventRemovedSelector,
    args: [info.EventId],
  });
  const { team1, team2, separator } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [info.EventId],
    equalityFn: shallowEqual,
  });

  if (scoreBoard && !scoreBoardIsEventRemoved) {
    return view === 'compact' ? (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        data-testid="history-element-event"
      >
        <div>
          <ScoreBoardPlayTime eventId={info.EventId} />
          <EventFullName
            data-testid="history-event-full-name"
            mt={0}
            view={view}
          >
            <Text>{`${team1} ${separator || ''}`}</Text>
            <Text>{team2}</Text>
          </EventFullName>
        </div>
        <div>
          <ScoreBoard eventId={info.EventId} type="compact" />
        </div>
      </Box>
    ) : null;
  }
  return (
    <EventName data-testid="history-element-event" ellipsis mt={0} view={view}>
      {info.EventName}
    </EventName>
  );
};
