import React, { FC } from 'react';

import { MenuItem } from '../../../common/components/MenuItem';

type MenuTitleProps = {
  isCollapsed: boolean;
};

export const MenuTitle: FC<MenuTitleProps> = ({ children, isCollapsed }) => (
  <MenuItem elevationLevel={1} mb="1px" hasNoHoverBehavior hasNoShadow>
    <MenuItem.Content>{!isCollapsed && children}</MenuItem.Content>
  </MenuItem>
);
