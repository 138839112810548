import { createSelector } from 'reselect';

import { BetPipeType } from '@gaming1/g1-config';
import { RequestState } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../common/store/types';
import { ApplicationWithBettingState } from '../store/types';

import { BetPipeRequestName, BetPipeState, BetPipeStatus } from './types';

/** Get a full bet pipe state using its id */
export const betPipeGetterSelector =
  (state: ApplicationWithBettingState) =>
  (betPipeId: string): BetPipeState | undefined =>
    state.betting.betPipe[betPipeId];

/** Get some data about a bet pipe (undefined if the id is not found) */
export const betPipeInfoGetterSelector = createSelector(
  betPipeGetterSelector,
  (getBetPipe) =>
    (
      betPipeId: string | undefined,
    ):
      | {
          betPipeId: string;
          bettingSlipId: BettingSlipIdentifierType;
          status: BetPipeStatus;
          type: BetPipeType;
        }
      | undefined => {
      if (!betPipeId) {
        return undefined;
      }
      const pipeState = getBetPipe(betPipeId);
      if (!pipeState) {
        return undefined;
      }
      const { bettingSlipId, type, status } = pipeState;
      return {
        betPipeId,
        bettingSlipId,
        status,
        type,
      };
    },
);

/** Get all request states for a bet pipe id (undefined if not found) */
export const betPipeRequestsStateGetterSelector = createSelector(
  betPipeGetterSelector,
  (getBetPipe) =>
    (betPipeId: string): BetPipeState['requests'] | undefined => {
      const pipeState = getBetPipe(betPipeId);
      if (!pipeState) {
        return undefined;
      }
      const { requests } = pipeState;
      return requests;
    },
);

/** Get a single request state for a bet pipe id (undefined if not found) */
export const getPipeRequestStateGetterSelector = createSelector(
  betPipeRequestsStateGetterSelector,
  (getPipeRequestsStates) =>
    (
      betPipeId: string,
      requestName: BetPipeRequestName,
    ): RequestState | undefined =>
      getPipeRequestsStates(betPipeId)?.[requestName],
);

/* TODO: maybe fallback to a general status in case the request has not been
made yet ? For example get the ininitalization data manually on the app
launch */
/**
 * Returns true if the optimal bet is enabled, false if not, undefined if
 * the info is not known
 */
export const isOptimalBetEnabledGetterSelector = createSelector(
  betPipeGetterSelector,
  (getBetPipe) =>
    (betPipeId: string): boolean | undefined =>
      !!getBetPipe(betPipeId), // .data.initializationData?.IsOptimalBetEnabled,
);
