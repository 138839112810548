import isEqual from 'lodash/isEqual';
import React, { memo, useContext, useMemo, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { BettingHistoryElementType } from '@gaming1/g1-betting';
import { BettingSlipType, EventType } from '@gaming1/g1-requests-betting';
import { Box, Separator } from '@gaming1/g1-ui';
import { isNonNullable } from '@gaming1/g1-utils';

import { useScoreBoards } from '../../../score/hooks';
import { HistoryEvent } from '../HistoryEvent';
import { ViewStyle } from '../HistoryEvent/types';

type HistoryEventListProps = {
  list: BettingHistoryElementType[] | undefined;
  bettingHistoryId: string;
  type: BettingSlipType;
  view?: ViewStyle;
};
export const HistoryEventListComponent: VFC<HistoryEventListProps> = ({
  list,
  bettingHistoryId,
  type,
  view = 'compact',
}) => {
  const { colors } = useContext(ThemeContext);
  const ids = useMemo(
    () =>
      list
        ?.filter(isNonNullable)
        .map((item) =>
          item.EventType === EventType.Live ? item.EventId : null,
        )
        .filter(isNonNullable),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useScoreBoards(ids, true);

  if (!list) {
    return null;
  }
  return (
    <div data-testid="betting-history-event-list-container">
      {list.filter(isNonNullable).map((element, i, elements) =>
        view === 'compact' || view === 'details' ? (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`row-element-${i}`}
            data-testid="betting-history-event-container-compact"
          >
            <Box>
              <HistoryEvent
                bettingHistoryId={bettingHistoryId}
                outcomeId={element.OutcomeId}
                type={type}
                view={view}
              />
            </Box>
            {elements.length - 1 !== i ? (
              <Separator
                opacity="translucent"
                hrColor={colors.secondary[0]}
                data-testid="event-separator"
              />
            ) : null}
          </Box>
        ) : null,
      )}
    </div>
  );
};

export const HistoryEventList = memo(HistoryEventListComponent, isEqual);
