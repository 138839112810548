import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { CoreActions } from '../types';

import { TrackingState } from './types';

export const initialState: TrackingState = {
  blackBoxes: {
    ioBlackBox: null,
    fpBlackBox: null,
  },
  gtmEvents: [],
  requests: {
    getGoogleTagManager: generateInitialRequestState(),
    googleTagManagerTrackingPlaced: generateInitialRequestState(),
  },
};

export const trackingReducer = (
  state: TrackingState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.setBlackBoxes):
        draftState.blackBoxes.ioBlackBox = action.payload.ioBlackBox;
        draftState.blackBoxes.fpBlackBox = action.payload.fpBlackBox || null;
        break;

      case getType(actions.getGTMEvents.request):
        produceLoadingState(draftState.requests.getGoogleTagManager);
        break;

      case getType(actions.getGTMEvents.failure):
        produceFailureState(
          draftState.requests.getGoogleTagManager,
          action.payload,
        );
        break;

      case getType(actions.getGTMEvents.success):
        produceSuccessState(draftState.requests.getGoogleTagManager);
        draftState.gtmEvents = action.payload;
        break;

      case getType(actions.markGTMEventsAsSent.request):
        produceLoadingState(draftState.requests.googleTagManagerTrackingPlaced);
        break;
      case getType(actions.markGTMEventsAsSent.failure):
        produceFailureState(
          draftState.requests.googleTagManagerTrackingPlaced,
          action.payload,
        );
        break;

      case getType(actions.markGTMEventsAsSent.success):
        produceSuccessState(draftState.requests.googleTagManagerTrackingPlaced);
        // Removes sent events from the state
        draftState.gtmEvents = draftState.gtmEvents.filter(
          ({ id }) => !action.payload.includes(id),
        );
        break;

      case getType(actions.loggedOutUser):
        draftState.gtmEvents = [];
        draftState.requests = initialState.requests;
        break;

      default: // Immer will automatically return the state
    }
  });
