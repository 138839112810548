import {
  RangeConditionType as ERangeConditionType,
  KeyValueType,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { RangeConditionType } from '../../../../common/gifts/types';
import { EFrontErrorType } from '../../../../notification/mapper';
import { BettingSlipNotification } from '../../../../notification/mapper/types';
import { calcProfitWinning } from '../../../helpers/calc';
import { RoundingConfig } from '../../types/common';

enum ItemError {
  MinStake = 'minStake',
  MaxStake = 'maxStake',
}

type CheckedItem = {
  isValid: boolean;
  type?: ItemError;
  value?: number;
  min?: number;
  max?: number;
};

export const isValidRange = (data: {
  value: number;
  min?: number;
  max?: number;
}): CheckedItem => {
  const { value, min, max } = data;

  if (min && value < min) {
    return { isValid: false, type: ItemError.MinStake };
  }
  if (max && value > max) {
    return { isValid: false, type: ItemError.MaxStake };
  }
  return { isValid: true };
};

/** Private functions for the isAvailableByRangeCondition function */

/**
 * Check if the RangeCondition "ElementOdd" is met.
 * @param items the items containing the finalOdd
 * @param max the maximum odd accepted
 * @param min the minimum odd accepted
 * @returns an error (InvalidOdd) Notification or null if respected
 */
const checkElementOddRangeCondition = (
  items: { finalOdd: number }[],
  max?: number,
  min?: number,
): BettingSlipNotification | null => {
  const areEachItemsValid = items.some(
    (item) =>
      isValidRange({
        value: item.finalOdd,
        min,
        max,
      }).isValid,
  );

  if (!areEachItemsValid) {
    return {
      Status: EFrontErrorType.InvalidOdd,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
    };
  }

  return null;
};

/**
 * Check if the RangeCondition "Stake" is met
 * @param stake the stake put into the bettingSlip
 * @param max the maximum stake accepted
 * @param min the minimum stake accepted
 * @returns an error (Min or Max) Notification or null if respected
 */
const checkStakeRangeCondition = (
  stake: number,
  max?: number,
  min?: number,
): BettingSlipNotification | null => {
  const validRange = isValidRange({ value: stake, min, max });
  if (
    !validRange.isValid &&
    validRange.type &&
    validRange.type === ItemError.MinStake
  ) {
    return {
      Status: EFrontErrorType.Min,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
      NotificationParameters: [
        {
          Key: 'minCurrency',
          Value: min?.toString(),
          Type: KeyValueType.Money,
        },
      ],
    };
  }

  if (
    !validRange.isValid &&
    validRange.type &&
    validRange.type === ItemError.MaxStake
  ) {
    return {
      Status: EFrontErrorType.Max,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
      NotificationParameters: [
        {
          Key: 'maxCurrency',
          Value: max?.toString(),
          Type: KeyValueType.Money,
        },
      ],
    };
  }

  return null;
};

/**
 * Check if the RangeCondition "TicketOdd" is met.
 * @param totalFinalOdd the total final odd from the bettingSlip
 * @param max the maximum final odd accepted
 * @param min the minimum final odd accepted
 * @returns an error "InvalidOdd" notification or null if respected
 */
const checkTicketOddRangeCondition = (
  totalFinalOdd: number,
  max?: number,
  min?: number,
): BettingSlipNotification | null => {
  const validRange = isValidRange({
    value: totalFinalOdd,
    min,
    max,
  });

  if (!validRange.isValid) {
    return {
      Status: EFrontErrorType.InvalidOdd,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
    };
  }

  return null;
};

/**
 * Check if the RangeCondition "ElementCount" is met
 * @param itemsLength the number of elements in the bettingSlip
 * @param max the maximum of elements that we can have in the bettingSlip
 * @param min the minimum of elements that we must have in the bettingSlip
 * @returns An error (InvalidOdd) Notification or null if the condition is respected
 */
const checkElementCountRangeCondition = (
  itemsLength: number,
  max?: number,
  min?: number,
) => {
  const validRange = isValidRange({
    value: itemsLength,
    min,
    max,
  });

  if (!validRange.isValid) {
    return {
      Status: EFrontErrorType.TooManyElementsInSystem,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
    };
  }

  return null;
};

/**
 * Check if the RangeCondition "FreebetNetWinnings" is met
 * @param finalWinnings the final winnings that we have in the given bettingSlip
 * @param max the maximum of net freebet winnings that we can win
 * @param min the minimum of net freebet winnings that we can win
 * @returns an error (InvalidFreebet) Notification or null if the condition is respected
 */
const checkFreebetNetWinningsRangeCondition = (
  finalWinnings: number,
  max?: number,
  min?: number,
): BettingSlipNotification | null => {
  const validRange = isValidRange({
    value: finalWinnings,
    min,
    max,
  });

  if (!validRange.isValid) {
    return {
      Status: EFrontErrorType.InvalidFreebet,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
    };
  }

  return null;
};

/**
 * Check if the RangeCondition "BoostusMaxProfit" is met
 * @param finalWinnings the final winnings of the given bettingSlip
 * @param baseWinnings the base winnings of the given bettingSlip
 * @param max the maximum of profit that we can have with the boostus
 * @param min the minimum of profit that we can have with the boostus
 * @returns an error (BoostusMaxProfit) Notification or null if respected
 */
const checkBoostusMaxProfitRangeCondition = (
  finalWinnings: number,
  baseWinnings: number,
  max?: number,
  min?: number,
  roundingConfig?: RoundingConfig,
): BettingSlipNotification | null => {
  const validRange = isValidRange({
    value: calcProfitWinning(roundingConfig)(baseWinnings, finalWinnings),
    min,
    max,
  });

  if (!validRange.isValid) {
    return {
      Status: EFrontErrorType.BoostusMaxProfit,
      Level: NotificationLevel.Error,
      Code: NotificationCode.Unknown,
    };
  }

  return null;
};

/**
 * This helper will check the RangeCondition that are presents
 * in the {rangeGroups} given property.
 *
 * When the function encounter a RangeCondition that is not met,
 * it'll return an error Notification with the linked EFrontErrorType.
 *
 * As soon as a RangeCondition is not met, the loop break to directly
 * send the error Notification.
 * @param rangeGroups the RangeConditions that we have to check
 * @param bs the bettingSlip that we need to check if it respects the conditions
 * @param options if we have a boostus or a freebet used that we have to check
 * @returns a success || error Notification with its status
 */
type IsAvailableByRangeConditionOptionType = {
  boostusMaxProfit?: boolean;
  roundingConfig?: RoundingConfig;
  freebetNetWinnings?: boolean;
};
export const isAvailableByRangeCondition = (
  rangeGroups: RangeConditionType[] | undefined,
  bs: {
    items: { finalOdd: number }[];
    stake?: number;
    totalFinalOdd: number;
    winnings: {
      base?: number;
      final?: number;
    };
  },
  options?: IsAvailableByRangeConditionOptionType,
): BettingSlipNotification => {
  if (!rangeGroups || rangeGroups.length === 0) {
    return {
      Status: EFrontErrorType.None,
      Level: NotificationLevel.Information,
      Code: NotificationCode.Unknown,
    };
  }

  let result: BettingSlipNotification = {
    Status: EFrontErrorType.None,
    Level: NotificationLevel.Information,
    Code: NotificationCode.Unknown,
  };
  /* eslint-disable no-restricted-syntax */
  for (const range of rangeGroups) {
    const { Max, Min, Type } = range;

    // ElementOdd condition
    if (Type === ERangeConditionType.ElementOdd) {
      const checkedOddRange = checkElementOddRangeCondition(bs.items, Max, Min);
      if (checkedOddRange) {
        result = checkedOddRange;
        break;
      }
    }

    // Stake condition
    if (bs.stake && Type === ERangeConditionType.Stake) {
      const checkedStake = checkStakeRangeCondition(bs.stake, Max, Min);
      if (checkedStake) {
        result = checkedStake;
        break;
      }
    }

    // TicketOdd condition
    if (Type === ERangeConditionType.TicketOdd) {
      const checkedTicketOdd = checkTicketOddRangeCondition(
        bs.totalFinalOdd,
        Max,
        Min,
      );
      if (checkedTicketOdd) {
        result = checkedTicketOdd;
        break;
      }
    }

    // ElementCount condition
    if (Type === ERangeConditionType.ElementCount) {
      const checkedElementCount = checkElementCountRangeCondition(
        bs.items.length,
        Max,
        Min,
      );
      if (checkedElementCount) {
        result = checkedElementCount;
        break;
      }
    }

    // FreebetNetWinnings condition
    if (
      options &&
      options.freebetNetWinnings &&
      Type === ERangeConditionType.FreebetNetWinnings
    ) {
      const checkedFreebetNetWinnings = checkFreebetNetWinningsRangeCondition(
        bs.winnings.final || 0,
        Max,
        Min,
      );
      if (checkedFreebetNetWinnings) {
        result = checkedFreebetNetWinnings;
        break;
      }
    }

    // BoostusMaxProfit condition
    if (
      options &&
      options.boostusMaxProfit &&
      bs.winnings.final &&
      bs.winnings.base &&
      Type === ERangeConditionType.BoostusMaxProfit
    ) {
      const checkedBoostusMaxProfit = checkBoostusMaxProfitRangeCondition(
        bs.winnings.final,
        bs.winnings.base,
        Max,
        Min,
        options.roundingConfig,
      );
      if (checkedBoostusMaxProfit) {
        result = checkedBoostusMaxProfit;
        break;
      }
    }
  }

  return result;
};
