import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, elevation, spaces } from '@gaming1/g1-style';
import { Box, MenuIconText, RoundIconMenuWrapper } from '@gaming1/g1-ui';

const ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT = 10;

export const IconsWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin-left: ${spaces('lg')};
`;

export const IconWrapper = styled(Box)<{ isActive: boolean }>`
  flex-direction: row;
  cursor: pointer;
  ${({ isActive }) =>
    !isActive &&
    css`
      color: ${colors('muted')};
      cursor: initial;
    `}
`;

export const RoundIconMenuWrapperRelative = styled(RoundIconMenuWrapper)`
  position: relative;
`;

export const RoundIconMenuWrapperInactive = styled(
  RoundIconMenuWrapperRelative,
)`
  ${elevation(1)}
  path {
    fill: ${colors('muted')};
  }
`;

export const Notification = styled.div`
  border-radius: 5px;
  background: ${colors('danger')};
  width: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
  height: ${ACTIVE_FILTER_RED_CIRCLE_WIDTH_AND_HEIGHT}px;
  margin-left: ${spaces('xs')};
`;

export const VerticalSeparator = styled.span`
  width: 1px;
  border-left: 1px solid;
  height: ${spaces('md')};
  padding-left: ${spaces('sm')};
  margin-left: ${spaces('xs')};
  display: inline-block;
`;

export const NotificationMobile = styled(Notification)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const MenuIconTextColored = styled(MenuIconText)<{ color: string }>`
  color: ${({ color }) => color};
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors('text')};
`;
