import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SpaceProps, TypographyProps } from 'styled-system';

import {
  SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX,
  ScrollableList,
} from '@gaming1/g1-ui';

import { MenuGroups } from '../types';

import { ScrollNavItem } from './ScrollNavItem';
import {
  ScrollNavContent,
  ScrollNavContentContainer,
  ScrollNavFluidContentContainer,
  ScrollNavScrollableContainer,
  ScrollNavStickyContainer,
  VerticalSeparator,
} from './styles';
import { Layout } from './types';

export type ScrollNavProps = {
  /** The menu groups */
  menu: MenuGroups;
  /** Should the topnav stick to the top of the screen when scrolling down */
  shouldStick?: boolean;
  /** In what flavour should the ScrollNav be displayed */
  layout?: Layout;
} & TypographyProps &
  SpaceProps;

export const ScrollNav: FC<ScrollNavProps> = ({
  menu,
  children,
  shouldStick = true,
  layout = 'menu',
  ...props
}) => {
  const [activeElement, setActiveElement] = useState<HTMLElement | null>(null);
  const slideMenuRef = useRef<HTMLElement | null>(null);
  const [slideMenuElement, setSlideMenuElement] = useState<HTMLElement | null>(
    null,
  );
  const slideMenuContentRef = useRef<HTMLDivElement | null>(null);
  const hasScrolledRef = useRef(false);
  slideMenuRef.current = slideMenuElement;
  const location = useLocation();

  useEffect(() => {
    if (
      activeElement &&
      slideMenuElement &&
      !hasScrolledRef.current &&
      // Prevent the scroll to be made before the element is ready (this can
      // happen when the menu is re-rendered multiple times)
      activeElement.getBoundingClientRect().left > 0
    ) {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      );
      const elementPosition = activeElement.getBoundingClientRect().left;
      const offsetPosition =
        elementPosition +
        activeElement.offsetWidth +
        SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX;

      const scrollLeftDistance = Math.ceil(offsetPosition) - vw;

      // Prevent the scroll to reset if the distance is negative,
      // which can happen in certain cases (i.e on item click on first load)
      if (scrollLeftDistance >= 0) {
        slideMenuElement.scrollLeft = scrollLeftDistance;
      }

      hasScrolledRef.current = true;
    }
  }, [activeElement, slideMenuElement]);

  // TODO: keep trying to clean that freaking mess of a component and avoid
  // nesting that much DOM for no apparent reason.
  return (
    <ScrollNavStickyContainer
      data-testid="scrollnav"
      shouldStick={shouldStick}
      layout={layout}
      {...props}
    >
      <ScrollNavFluidContentContainer layout={layout}>
        <ScrollNavContentContainer layout={layout}>
          <ScrollableList
            containerRef={slideMenuRef}
            listRef={slideMenuContentRef}
          >
            <ScrollableList.ScrollableListButton side="left" />
            <ScrollNavScrollableContainer
              data-testid="scrolling-wrapper"
              ref={(ref) => {
                setSlideMenuElement(ref);
                // needs to be set immediately, otherwise areChildrenOverflowing
                // from useScrollableListContext is not calculated correctly
                slideMenuRef.current = ref;
              }}
            >
              <ScrollableList.ScrollableListWrapper
                layout={layout === 'altMenu' ? 'alt' : 'normal'}
                hasGradient
                shouldGradientHaveFullHeight={[
                  'altMenu',
                  'alt',
                  'flexTiles',
                  'tiles',
                ].includes(layout)}
              >
                <ScrollNavContent ref={slideMenuContentRef} layout={layout}>
                  {menu.map((menuList, groupIndex) => (
                    <Fragment key={menuList.toString()}>
                      {menuList.map((itemAttrs, menuItemIndex) => (
                        <ScrollNavItem
                          key={`${itemAttrs.testId}${itemAttrs.label}`}
                          layout={layout}
                          ref={
                            'path' in itemAttrs &&
                            itemAttrs.path === location.pathname &&
                            menuItemIndex > 0
                              ? setActiveElement
                              : null
                          }
                          {...itemAttrs}
                          {...props}
                        />
                      ))}
                      {groupIndex !== menu.length - 1 && <VerticalSeparator />}
                    </Fragment>
                  ))}
                </ScrollNavContent>
              </ScrollableList.ScrollableListWrapper>
            </ScrollNavScrollableContainer>
            <ScrollableList.ScrollableListButton side="right" />
          </ScrollableList>
        </ScrollNavContentContainer>
        {children}
      </ScrollNavFluidContentContainer>
    </ScrollNavStickyContainer>
  );
};
