import React, { FC, useCallback, useContext, useMemo } from 'react';

import { OddsUnitsType, useConfig } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';
import { persistIn } from '@gaming1/g1-utils';

import {
  BettingMenuContext,
  ODD_UNIT_STORAGE_KEY,
} from '../../BettingMenuContext';

import {
  OddsUnitsRadioButton,
  OddsUnitsRadioLabel,
  OddsUnitsRadioWrapper,
  OddsUnitsSelect,
  OddsUnitsSelectWrapper,
} from './styles';

type Option = { label: string; value: OddsUnitsType };

export type OddsUnitsProps = {
  /* Specifies the type of the odds units selector */
  type: 'select' | 'radio';
};

/* Displays an odds units selector */
export const OddsUnits: FC<OddsUnitsProps> = ({ type }) => {
  const { betting } = useConfig();
  const { t } = useTranslation('betting');
  const { oddUnit, setOddUnit } = useContext(BettingMenuContext);

  const oddsUnits: { [k in OddsUnitsType]: Option } = useMemo(
    () => ({
      decimal: {
        label: t('bettingMenu.oddsUnitSelection.decimal'),
        value: 'decimal',
      },
      fraction: {
        label: t('bettingMenu.oddsUnitSelection.fraction'),
        value: 'fraction',
      },
      american: {
        label: t('bettingMenu.oddsUnitSelection.american'),
        value: 'american',
      },
    }),
    [t],
  );

  /**
   * The order of the options will always be the same. We just put the default
   * option at the beginning of the array.
   */
  const oddsUnitsOptions = useMemo(() => {
    const defaultOption = oddsUnits[betting.defaultOddsUnit];

    return [
      defaultOption,
      ...[oddsUnits.decimal, oddsUnits.fraction, oddsUnits.american].filter(
        (unit) => unit.value !== defaultOption.value,
      ),
    ];
  }, [betting.defaultOddsUnit, oddsUnits]);

  const handleOddsUnitSelection = useCallback(
    (selectedOddsUnit) => {
      setOddUnit(selectedOddsUnit);
      persistIn(localStorage, ODD_UNIT_STORAGE_KEY, selectedOddsUnit);
    },
    [setOddUnit],
  );

  if (type === 'select') {
    return (
      <OddsUnitsSelectWrapper>
        <Box mr="md">{`${t('bettingMenu.odds')} :`}</Box>
        <OddsUnitsSelect
          onValueChange={handleOddsUnitSelection}
          options={oddsUnitsOptions}
          simple
          testId="odds-units-select"
          value={oddUnit}
        />
      </OddsUnitsSelectWrapper>
    );
  }

  if (type === 'radio') {
    return (
      <div role="radiogroup" aria-labelledby="odds-units-radio">
        <OddsUnitsRadioLabel id="odds-units-radio">
          {t('bettingMenu.odds')}
        </OddsUnitsRadioLabel>
        <OddsUnitsRadioWrapper data-testid="odds-units-radio">
          {oddsUnitsOptions.map((option) => (
            <OddsUnitsRadioButton
              active={option.value === oddUnit}
              aria-checked={option.value === oddUnit}
              data-testid={`odds-unit-radio-${option.value}`}
              key={option.value}
              onClick={() => handleOddsUnitSelection(option.value)}
              role="radio"
              type="button"
            >
              {option.label}
            </OddsUnitsRadioButton>
          ))}
        </OddsUnitsRadioWrapper>
      </div>
    );
  }

  return null;
};
