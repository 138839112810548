import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  EBettingSlipOddChangeSettings,
  EventType,
} from '@gaming1/g1-requests-betting';
import { RemoteData } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../common/store/types';
import { useBettingGetterSelector } from '../../hooks';
import { getNotificationLevelMapper } from '../../notification/mapper';
import { bettingUserSettingsSelector } from '../../settings/selectors';
import { eventListSelector } from '../../sportbook/store/selectors';
import { BettingActions } from '../../store/types';
import {
  helperBettingSlipIsEnough,
  helperBettingSlipIsTooMuch,
} from '../helpers/validators';
import {
  bettingSlipAllItemsGetterSelector,
  bettingSlipFirstItemGetterSelector,
  bettingSlipItemOddVariationGetterSelector,
  bettingSlipItemsGetterSelector,
  bettingSlipRanksGetterSelector,
  placeBettingSlipNotificationGetterSelector,
  placeBettingSlipRequestStatusGetterSelector,
  riskManagementNotificationGetterSelector,
} from '../store/selectors';
import { bettingSlipTypeGetterSelector } from '../store/selectors/common';

import { useBettingSlipCombiErrorFrontType } from './elements/combi';
import { useBettingSlipItemIsValid } from './elements/item';
import { useBettingSlipRankErrorFrontType } from './elements/rank';
import { useBettingSlipSingleErrorFrontType } from './elements/single';
import {
  useBettingSlipSystemErrorFrontType,
  useBettingSlipSystemStake,
} from './elements/system';
import { useBettingSlipGiftErrorType } from './gifts/common';

export const useSelectedItemsList = (
  bettingSlipId: BettingSlipIdentifierType,
) =>
  useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

export const useBettingSlipType = (bettingSlipId: BettingSlipIdentifierType) =>
  useBettingGetterSelector({
    getterSelector: bettingSlipTypeGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

export const useBettingSlipIsEnough = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipAllItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  return (count = 0): boolean => helperBettingSlipIsEnough(items, count);
};

export const useBettingSlipIsEnoughSelected = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const items = useSelectedItemsList(bettingSlipId);
  return (count = 0): boolean => helperBettingSlipIsEnough(items, count);
};

export const useBettingSlipIsTooMuchSelected = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const items = useSelectedItemsList(bettingSlipId);
  return (count = 0): boolean => helperBettingSlipIsTooMuch(items, count);
};

export const useBettingSlipHasLiveItem = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const itemsEventIdList =
    useBettingGetterSelector({
      getterSelector: bettingSlipItemsGetterSelector,
      args: [bettingSlipId],
      equalityFn: isEqual,
    })?.map((item) => item.event.id) || [];

  const eventList = useBettingGetterSelector({
    getterSelector: eventListSelector,
    args: [itemsEventIdList, false],
    equalityFn: isEqual,
  });

  return eventList && eventList.length > 0
    ? eventList.some(
        (currentEvent) => currentEvent?.EventType === EventType.Live,
      )
    : false;
};

export const useBettingSlipIsEmpty = (
  bettingSlipId: BettingSlipIdentifierType,
): boolean => useBettingSlipIsEnough(bettingSlipId)() === false;

/**
 * Dirty odd
 */
export const useBettingSlipIsDirtyOdd = (
  bettingSlipId: BettingSlipIdentifierType,
): boolean => {
  const bettingSlipSettings = useSelector(bettingUserSettingsSelector);
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });
  const bettingSlipItemOddVariation = useSelector(
    bettingSlipItemOddVariationGetterSelector,
  );

  const oddSettings =
    bettingSlipSettings?.OddChangeSettings !== undefined
      ? bettingSlipSettings?.OddChangeSettings
      : EBettingSlipOddChangeSettings.AllOdds;

  if (oddSettings === EBettingSlipOddChangeSettings.AllOdds) {
    return false;
  }

  if (!items || items.length === 0) {
    return false;
  }

  return !!items.find((item) => {
    const change = bettingSlipItemOddVariation(bettingSlipId, item.id);

    if (oddSettings === EBettingSlipOddChangeSettings.OnlyHigherOdds) {
      if (change === 'decrease') {
        return true;
      }
      return false;
    }

    if (oddSettings === EBettingSlipOddChangeSettings.None) {
      return !!change;
    }
    return false;
  });
};

/**
 * Unavailable item
 */
export const useBettingSlipIsUnavailableItems = (
  bettingSlipId: BettingSlipIdentifierType,
): boolean => {
  const items = useBettingGetterSelector({
    getterSelector: bettingSlipItemsGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const validItem = useBettingSlipItemIsValid(bettingSlipId);

  const noUnavailableItem =
    items?.map((item) => item.id).find((id) => !validItem(id)) === undefined;

  return !noUnavailableItem;
};

export const useBettingSlipIsInvalidRanks = (
  bettingSlipId: BettingSlipIdentifierType,
): boolean => {
  const ranksSelector = useSelector(bettingSlipRanksGetterSelector, isEqual);
  const rankValidator = useBettingSlipRankErrorFrontType(bettingSlipId);

  if (
    !ranksSelector(bettingSlipId) ||
    ranksSelector(bettingSlipId)?.length === 0
  ) {
    return false;
  }

  return Boolean(
    ranksSelector(bettingSlipId)
      ?.map((rank) => rank.id)
      .find((id) => getNotificationLevelMapper(rankValidator(id)) === 'danger'),
  );
};

export const useBettingSlipSingleValidate = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const itemSelector = useSelector(bettingSlipFirstItemGetterSelector, isEqual);
  const isDirtyOdd = useBettingSlipIsDirtyOdd(bettingSlipId);
  const bettingSlipIsTooMuchSelected =
    useBettingSlipIsTooMuchSelected(bettingSlipId);
  const bettingSlipIsEnoughSelected =
    useBettingSlipIsEnoughSelected(bettingSlipId);
  const rmNotification = useBettingGetterSelector({
    getterSelector: riskManagementNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const pbNotification = useBettingGetterSelector({
    getterSelector: placeBettingSlipNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const frontNotification = useBettingSlipSingleErrorFrontType(bettingSlipId);
  const validItem = useBettingSlipItemIsValid(bettingSlipId);

  const giftValidation = useBettingSlipGiftErrorType(bettingSlipId);

  return (): boolean => {
    if (isDirtyOdd) {
      return false;
    }
    if (
      bettingSlipIsTooMuchSelected(1) === true ||
      bettingSlipIsEnoughSelected(1) === false
    ) {
      return false;
    }
    if (
      rmNotification &&
      getNotificationLevelMapper(rmNotification) === 'danger'
    ) {
      return false;
    }
    if (
      pbNotification &&
      getNotificationLevelMapper(pbNotification) === 'danger'
    ) {
      return false;
    }
    if (getNotificationLevelMapper(frontNotification()) === 'danger') {
      return false;
    }
    if (getNotificationLevelMapper(giftValidation()) === 'danger') {
      return false;
    }
    const item = itemSelector(bettingSlipId);
    if (!item) {
      return false;
    }
    return validItem(item.id);
  };
};

export const useBettingSlipCombiValidate = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const itemsSelector = useSelector(bettingSlipItemsGetterSelector, isEqual);
  const isDirtyOdd = useBettingSlipIsDirtyOdd(bettingSlipId);

  const bettingSlipIsEnoughSelected =
    useBettingSlipIsEnoughSelected(bettingSlipId);
  const rmNotification = useBettingGetterSelector({
    getterSelector: riskManagementNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const pbNotification = useBettingGetterSelector({
    getterSelector: placeBettingSlipNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const frontNotification = useBettingSlipCombiErrorFrontType(bettingSlipId);
  const isUnavailableItems = useBettingSlipIsUnavailableItems(bettingSlipId);

  const giftValidation = useBettingSlipGiftErrorType(bettingSlipId);

  return (): boolean => {
    if (isDirtyOdd) {
      return false;
    }
    if (bettingSlipIsEnoughSelected(2) === false) {
      return false;
    }
    if (
      rmNotification &&
      (getNotificationLevelMapper(rmNotification) === 'danger' ||
        getNotificationLevelMapper(rmNotification) === 'warning')
    ) {
      return false;
    }
    if (
      pbNotification &&
      getNotificationLevelMapper(pbNotification) === 'danger'
    ) {
      return false;
    }
    if (getNotificationLevelMapper(frontNotification()) === 'danger') {
      return false;
    }
    if (getNotificationLevelMapper(giftValidation()) === 'danger') {
      return false;
    }
    const items = itemsSelector(bettingSlipId);
    if (!items || items.length === 0) {
      return false;
    }
    if (isUnavailableItems) {
      return false;
    }
    return true;
  };
};

export const useBettingSlipSystemValidate = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const itemsSelector = useSelector(bettingSlipItemsGetterSelector, isEqual);
  const isDirtyOdd = useBettingSlipIsDirtyOdd(bettingSlipId);

  const bettingSlipIsEnoughSelected =
    useBettingSlipIsEnoughSelected(bettingSlipId);
  const rmNotification = useBettingGetterSelector({
    getterSelector: riskManagementNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });
  const pbNotification = useBettingGetterSelector({
    getterSelector: placeBettingSlipNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const frontNotification = useBettingSlipSystemErrorFrontType(bettingSlipId);
  const isUnavailableItems = useBettingSlipIsUnavailableItems(bettingSlipId);
  const isInvalidRanks = useBettingSlipIsInvalidRanks(bettingSlipId);
  const stake = useBettingSlipSystemStake(bettingSlipId);

  return (): boolean => {
    if (!stake) {
      return false;
    }
    if (isDirtyOdd) {
      return false;
    }
    if (bettingSlipIsEnoughSelected(3) === false) {
      return false;
    }
    if (
      rmNotification &&
      (getNotificationLevelMapper(rmNotification) === 'danger' ||
        getNotificationLevelMapper(rmNotification) === 'warning')
    ) {
      return false;
    }
    if (
      pbNotification &&
      getNotificationLevelMapper(pbNotification) === 'danger'
    ) {
      return false;
    }
    if (getNotificationLevelMapper(frontNotification()) === 'danger') {
      return false;
    }

    if (isInvalidRanks) {
      return false;
    }

    const items = itemsSelector(bettingSlipId);
    if (!items || items.length === 0) {
      return false;
    }
    if (isUnavailableItems) {
      return false;
    }
    return true;
  };
};

export const useBettingSlipAllowActions = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const dispatch = useDispatch();
  const placebetStatus = useBettingGetterSelector({
    getterSelector: placeBettingSlipRequestStatusGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  return useCallback(
    (action: BettingActions): void => {
      if (placebetStatus !== RemoteData.Loading) {
        dispatch(action);
      }
    },
    [dispatch, placebetStatus],
  );
};

export const useDoesBettingSlipAllowActions = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const placebetStatus = useBettingGetterSelector({
    getterSelector: placeBettingSlipRequestStatusGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  return placebetStatus !== RemoteData.Loading;
};
