import * as t from 'io-ts';

import {
  BlacklistPlayerStatus,
  EAutoExcludeResponseStatus,
  EExclusionType,
  EGetAutoExcludeinfoResponse,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const autoExcludeResponse = t.type(
  {
    Status: t.literal(EAutoExcludeResponseStatus.Successful),
  },
  'AutoExcludeResponse',
);

export const getAutoExcludeInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetAutoExcludeinfoResponse.Successful),
      ExcludeType: createEnumType<EExclusionType>(
        EExclusionType,
        'EExclusionType',
      ),
      Excluded: t.boolean,
    }),
    t.partial({
      ExcludedUntil: t.string,
      RevocationDate: t.string,
    }),
  ],
  'GetAutoExcludeInfoResponse',
);

export const blacklistPlayerResponse = t.type(
  {
    Status: t.literal(BlacklistPlayerStatus.Successful),
  },
  'BlacklistPlayerResponse',
);
