import React, { FC } from 'react';

import { PrismicInfoBlockIcon, PrismicInfoBlockSlice } from '@gaming1/g1-cms';
import { ColorName } from '@gaming1/g1-style';
import { Icon, IconType } from '@gaming1/g1-ui';

import { PrismicRichText } from '../PrismicRichText';

import { PrismicInfoBlockContainer } from './styles';

export type PrismicInfoBlockProps = {
  /** Prismic 'Info block' slice */
  slice: PrismicInfoBlockSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Info block` slice
 * Renders a small info/warning/error block with text
 */
const getIconName = (icon: PrismicInfoBlockIcon): IconType => {
  switch (icon) {
    case 'error':
      return 'DiscCross';
    case 'warning':
      return 'DiscWarning';
    default:
      return 'DiscInfo';
  }
};

const getColorName = (icon: PrismicInfoBlockIcon): ColorName => {
  switch (icon) {
    case 'error':
      return 'danger';
    case 'warning':
      return 'warning';
    default:
      return 'info';
  }
};

export const PrismicInfoBlock: FC<PrismicInfoBlockProps> = ({
  slice,
  testId = 'prismic-info-block',
  parentIds = [],
}) => (
  <PrismicInfoBlockContainer
    color={getColorName(slice.primary.icon)}
    data-testid={testId}
  >
    <Icon id="prismic-info" type={getIconName(slice.primary.icon)} />
    <PrismicRichText
      content={slice.primary.content}
      sliceId={slice.primary.id}
      parentIds={
        slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
      }
    />
  </PrismicInfoBlockContainer>
);
