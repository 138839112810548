import { useContext } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-core';

/** Returns the URL for zendesk based on current locale */
export const useZendeskUrl = () => {
  const config = useContext(ConfigContext);
  const { currentLocaleFullCode } = useI18n();

  return currentLocaleFullCode
    ? `${config.network.zendeskUrl}/hc/${currentLocaleFullCode.toLowerCase()}`
    : config.network.zendeskUrl;
};
