import React, { FC } from 'react';

import { UserField } from '@gaming1/g1-core';
import { CodeInputField } from '@gaming1/g1-core-web';
import { notEqualToFieldValidatorFactory } from '@gaming1/g1-utils';

import { PIN_CODE_LENGTH, PinCodeFieldProps } from '.';

type NewPinCodeFieldProps = PinCodeFieldProps;

export const NewPinCodeField: FC<NewPinCodeFieldProps> = ({
  required,
  ...spaceProps
}) => {
  const notEqualToPinCodeValidator = notEqualToFieldValidatorFactory(
    UserField.pinCode,
  );

  return (
    <CodeInputField
      codeLength={PIN_CODE_LENGTH}
      inputTestId="new-pin-code-input"
      name="newPinCode"
      required={required}
      type="numeric"
      secret
      validators={[
        notEqualToPinCodeValidator('registration.validation.newPinCode'),
      ]}
      {...spaceProps}
    />
  );
};
