import React, { useContext, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { Icon } from '@gaming1/g1-ui';

import { RefreshIconWrapper } from './styles';

const ICON_SIZE_IN_PX = 15;

type IconRefreshProps = {
  /** Trigger a rotation animation for the icon */
  isRotating: boolean;
};

/**
 * Refresh icon to be displayed on the DepositButton
 */
export const IconRefresh: VFC<IconRefreshProps> = ({ isRotating }) => {
  const { colors } = useContext(ThemeContext);

  return (
    <RefreshIconWrapper isRotating={isRotating} pr="xs">
      <Icon
        id="refresh-credit"
        type="Refresh"
        fill={colors.topNavText}
        height={`${ICON_SIZE_IN_PX}px`}
        width={`${ICON_SIZE_IN_PX}px`}
      />
    </RefreshIconWrapper>
  );
};
