import React, { useContext, useEffect, VFC } from 'react';

import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Loader, Message } from '@gaming1/g1-ui';

import { inboxRoutes } from '../../../routes';
import { getMessagesOL } from '../../helpers';
import { InboxContext } from '../../InboxContext';

import { MessagesListItem } from './MessagesListItem';
import { ListItem } from './styles';

/** Displays the list of messages in the user inbox */
export const MessagesList: VFC = () => {
  const { t } = useTranslation('loyalty');
  const { inboxContent } = useContext(InboxContext);
  const getInboxPath = useRoutePath(inboxRoutes);

  useEffect(() => {
    getMessagesOL();
  }, []);

  if (!inboxContent || !inboxContent.messages) {
    return <Loader />;
  }

  return (
    <div data-testid="profile-messages-page">
      {inboxContent.messages.length > 0 ? (
        inboxContent.messages.map((message) => (
          <ListItem
            key={message.id}
            to={getInboxPath('messageDetail', {
              messageId: message.id.toString(),
            })}
            data-testid="profile-message"
          >
            <MessagesListItem message={message} />
          </ListItem>
        ))
      ) : (
        <Message type="info" mt="md" data-testid="profile-message-empty">
          {t('inbox.no.messages')}
        </Message>
      )}
    </div>
  );
};
