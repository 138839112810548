import styled from 'styled-components';

import {
  colors,
  elevation,
  fontSizes,
  fontWeight,
  spaces,
} from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

import { TextInputFieldProps } from '../TextInputField';

const CODE_INPUT_MINIMUM_WIDTH = 37;

const CODE_BLOCK_MARGIN = 8;

const TOGGLE_ICON_WIDTH = '24px';

export const CodeInputWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: center;
  position: relative;
`;

export const CodeInputDisplay = styled.div`
  ${elevation(2)}
  ${fontWeight('bold')}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spaces('xs')};
  height: 48px;
  width: 37px;
  border-radius: 4px;
  color: ${colors('text')};
  font-size: ${fontSizes('xxl')};

  :last-child {
    margin-right: 0;
  }
`;

export const CodeInputFocused = styled.div`
  position: absolute;
  box-shadow: 0 0 0 1px ${colors('tertiary')};
  border-radius: 4px;
  height: 48px;
  width: 37px;
`;

type BackgroundTextInputProps = {
  codeLength: number;
};

export const BackgroundTextInput = styled.input<
  BackgroundTextInputProps & TextInputFieldProps
>`
  position: absolute;
  border: none;
  line-height: 0;
  inline-size: 0;
  text-align: center;
  background-color: transparent;
  caret-color: transparent;
  outline: none;
  width: ${({ codeLength }) =>
    CODE_INPUT_MINIMUM_WIDTH * codeLength +
    CODE_BLOCK_MARGIN * (codeLength - 1)}px;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  appearance: textfield;
`;

export const SideIconButton = styled(SimpleButton)`
  margin-top: 4px;
  min-width: ${TOGGLE_ICON_WIDTH};
  font-size: ${TOGGLE_ICON_WIDTH};
  color: ${colors('muted')};
`;
