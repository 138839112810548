/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:54:35 09-08-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  CashoutInfoRequest,
  CashoutInfoResponse,
  CashoutRequest,
  CashoutResponse,
} from '../entities';

export const cashout = (options: CashoutRequest) =>
  createRequest<CashoutResponse>(202, 'Cashout', options);

export const getCashoutInfo = (options: CashoutInfoRequest) =>
  createRequest<CashoutInfoResponse>(202, 'GetCashoutInfo', options);
