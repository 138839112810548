import format from 'date-fns/format';
import React, { useContext, useEffect, useState, VFC } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { Icon } from '@gaming1/g1-ui';

import { TopBarClockContainer } from './styles';

/** Show the current time of the day */
export const TopBarClock: VFC = () => {
  const config = useContext(ConfigContext);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 60000);
    return () => clearInterval(interval);
  }, []);

  const currentTime = format(currentDate, config.user.clockFormat);

  return (
    <TopBarClockContainer>
      <Icon id="topbar-clock" type="Clock" /> {currentTime}
    </TopBarClockContainer>
  );
};
