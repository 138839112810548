import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  BettingSlipSystemDetailAndBettingSlipId,
  HistoryBettingSlipId,
  HistoryType,
  HistoryTypeAndData,
} from './types';

export const getBettingHistory = createAsyncAction(
  'betting/bettingslip_histoy_request',
  'betting/bettingslip_histoy_success',
  'betting/bettingslip_histoy_failure',
)<HistoryType, HistoryTypeAndData, HistoryType & FailurePayload>();

export const getBettingSlipSystemDetail = createAsyncAction(
  'betting/bettingslip_system_detail_request',
  'betting/bettingslip_system_detail_success',
  'betting/bettingslip_system_detail_failure',
)<
  HistoryBettingSlipId,
  BettingSlipSystemDetailAndBettingSlipId,
  HistoryBettingSlipId & FailurePayload
>();
