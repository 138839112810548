import { useState, VFC } from 'react';

import { WebsocketDebugStoreItem } from '@gaming1/g1-network';
import { CheckboxInput, Label } from '@gaming1/g1-ui';
import { curriedHasNonNullableProperty } from '@gaming1/g1-utils';

import { FilterContainer, RequestNameToggle } from '../styles';
import { RowFilterFunc, RowFilterHook } from '../types';

type RequestNameFilter = {
  name: string;
  count: number;
  isSelected: boolean;
};

type RequestsNamesListProps = {
  filters: RequestNameFilter[];
  onFilterToggle: (name: string) => void;
};

const RequestsNamesList: VFC<RequestsNamesListProps> = ({
  filters,
  onFilterToggle,
}) => (
  <FilterContainer>
    <Label htmlFor={null}> Filter by name:</Label>
    {filters.map(({ name, isSelected, count }) => (
      <RequestNameToggle key={name} isSelected={isSelected}>
        <CheckboxInput
          checked={isSelected}
          label={`${name} (${count})`}
          onChange={() => onFilterToggle(name)}
          removeMarginTop
        />
      </RequestNameToggle>
    ))}
  </FilterContainer>
);

const filterByRequestName =
  (names: string[]): RowFilterFunc =>
  ({ name }) =>
    !names.length || (!!name && names.includes(name));

export const useRequestNameFilter: RowFilterHook = (
  debugStore: WebsocketDebugStoreItem[],
) => {
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const requestNameList = debugStore
    .filter(curriedHasNonNullableProperty('name'))
    .map((request) => request.name);

  const requestNameFilters: RequestNameFilter[] = requestNameList
    .filter((name, index, names) => names.indexOf(name) === index)
    .sort((nameA, nameB) => nameA.localeCompare(nameB))
    .map((name) => ({
      name,
      count: requestNameList.filter((item) => item === name).length,
      isSelected: selectedNames.includes(name),
    }));

  const handleNameToggle = (name: string) =>
    setSelectedNames((previousValues) =>
      previousValues.includes(name)
        ? previousValues.filter((value) => value !== name)
        : [...previousValues, name],
    );

  const component = (
    <RequestsNamesList
      filters={requestNameFilters}
      onFilterToggle={handleNameToggle}
    />
  );

  const filter = filterByRequestName(selectedNames);

  return {
    component,
    filter,
  };
};
