import isEqual from 'lodash/isEqual';
import React, { FC, memo, useMemo } from 'react';

import {
  allSportsSelector,
  getSportbookKey,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';

import { MenuTitle } from '../LateralMenu/MenuTitle';

import { SportsLiveMenu } from './SportsLiveMenu';

type SportMenuProps = {
  /** Is the menu expanded or shrinked */
  isCollapsed?: boolean;
};

/**
 * Navigation used for the sportbook to select sports/leagues
 */
const LateralLiveMenuComponent: FC<SportMenuProps> = ({
  isCollapsed = false,
}) => {
  const key = getSportbookKey('live');
  const { t } = useTranslation('betting');

  const sportIds = useBettingGetterSelector({
    getterSelector: allSportsSelector,
    args: [key, null],
    equalityFn: isEqual,
  });

  const content = useMemo(
    () => (
      <Box data-testid="sportbook-live-menu">
        <MenuTitle isCollapsed={isCollapsed}>{t('menu.live')}</MenuTitle>
        <SportsLiveMenu items={sportIds} isCollapsed={isCollapsed} />
      </Box>
    ),
    [isCollapsed, sportIds, t],
  );

  return content;
};

export const LateralLiveMenu = memo(LateralLiveMenuComponent);
