import { combineEpics } from 'redux-observable';

import { BetPipeType } from '@gaming1/g1-config';

import { BettingEpic } from '../../store/types';
import { pipeEpicBuilder } from '../pipeEpicBuilder';

export * from './placeBet';
export * from './riskManagement';
export * from './initData';
export * from './optimalBet';
export * from './outcomeInfo';
export * from './freebets';
export * from './boostus';
export * from './reset';
export * from './promotions';

/** Create epics for each type of bet pipe by reading the config */
export const betPipeEpic: BettingEpic = (action$, state$, epicDependencies) => {
  const config = epicDependencies.config$.value;
  const epics = Object.entries(config.betting.betPipeSteps)
    .map(([betPipeType, betPipeConfig]) =>
      pipeEpicBuilder(betPipeConfig, betPipeType as BetPipeType),
    )
    .reduce((acc, pipeEpics) => [...acc, ...pipeEpics], [] as BettingEpic[]);
  return combineEpics(...epics)(action$, state$, epicDependencies);
};
