import React from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { LazyRoute } from '@gaming1/g1-core-web';

import { paymentRoutes } from './routes';

export const getPaymentRouter = (locale: LocaleCode) => [
  <LazyRoute
    componentName="DepositRouter"
    importFactory={() => import('./deposit/DepositRouter')}
    key="deposit"
    path={`/${locale}${paymentRoutes.deposit.path}`}
    section="deposit"
    type="app"
  />,
  <LazyRoute
    componentName="WithdrawalRouter"
    importFactory={() => import('./withdrawal/WithdrawalRouter')}
    key="withdrawal"
    path={`/${locale}${paymentRoutes.withdrawal.path}`}
    section="withdrawal"
    type="app"
  />,
];
