import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { isNotDuplicate } from '@gaming1/g1-utils';

import { GamingActions } from '../../../store/types';
import * as actions from '../actions';
import { GameListState } from '../types';

export const initalState: GameListState['attributes'] = {};

/** Store available attributes ids per GameRange */
export const gameListAttributesReducer = (
  state = initalState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchGames.success): {
        const { GameRange } = action.payload.request;
        const { attributes } = action.payload.data.result;
        draftState[GameRange] = [
          ...(state[GameRange] || []),
          ...attributes,
        ].filter(isNotDuplicate);
        break;
      }

      default: // Immer will automatically return the state
    }
  });
