import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DrawerType, LayoutContext, useMediaBreakPoint } from '@gaming1/g1-ui';
import { usePrevious, useWindowSize } from '@gaming1/g1-utils';

import { useIsFullscreenLayout } from '../routing/hooks';
import {
  BOTTOM_NAV_HEIGHT,
  FOOTER_HEADER_HEIGHT,
  FULLSCREEN_FOOTER_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_NAV_LARGE_HEIGHT,
  TOP_NAV_SMALL_HEIGHT,
} from '../styles';

import { DRAWER_URL_SEARCH_PARAMETER } from './constants';

const isDrawerType = (type: string): type is DrawerType => type in DrawerType;

/** Returns the minimum height of the page content */
export const usePageMinHeight = (
  footerVisible: boolean,
): {
  minHeight: number;
} => {
  const isFullscreen = useIsFullscreenLayout();
  const hasBottomNav = useMediaBreakPoint({ max: 'md' });
  const { width, height } = useWindowSize();

  const [minHeight, setMinHeight] = useState(0);

  useLayoutEffect(() => {
    const { innerHeight } = window;

    if (isFullscreen) {
      setMinHeight(innerHeight - FULLSCREEN_FOOTER_HEIGHT);
    } else if (hasBottomNav) {
      setMinHeight(innerHeight - TOP_NAV_SMALL_HEIGHT - BOTTOM_NAV_HEIGHT);
    } else {
      setMinHeight(
        innerHeight -
          TOP_BAR_HEIGHT -
          TOP_NAV_LARGE_HEIGHT -
          (footerVisible ? FOOTER_HEADER_HEIGHT : 0),
      );
    }
  }, [footerVisible, hasBottomNav, isFullscreen, height, width]);

  return { minHeight };
};

/**
 * This hook will use the url search parameter. To see if a drawer has to be
 * opened automatically after a navigation.
 */
export const useDrawerByUrlSearchParam = (drawerType: DrawerType) => {
  const history = useHistory();
  const location = useLocation();

  const { showDrawer, visibleDrawer } = useContext(LayoutContext);
  const previousVisibleDrawer = usePrevious(visibleDrawer);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const drawerUrlSearchParam = searchParams.get(DRAWER_URL_SEARCH_PARAMETER);

    if (
      drawerUrlSearchParam &&
      isDrawerType(drawerUrlSearchParam) &&
      drawerUrlSearchParam === drawerType
    ) {
      showDrawer(drawerUrlSearchParam);
    }
  }, [drawerType, location.search, showDrawer]);

  useEffect(() => {
    if (previousVisibleDrawer !== null && visibleDrawer === null) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(DRAWER_URL_SEARCH_PARAMETER);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [
    history,
    location.pathname,
    location.search,
    previousVisibleDrawer,
    visibleDrawer,
  ]);
};
