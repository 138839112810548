import * as t from 'io-ts';

import {
  elementNotificationCodec,
  notificationCodec,
} from '../../../notification/codecs';

export const riskManagementLimitsCodec = t.type(
  {
    StakeMin: t.number,
    StakeMax: t.number,
    WinnableMax: t.number,
  },
  'RiskManagementLimitsCodec',
);

const riskManagementSystemRank = t.type(
  {
    Rank: t.string,
    Limits: riskManagementLimitsCodec,
  },
  'RiskManagementSystemRank',
);

/* Response data */

export const getRiskManagementResponseCodec = t.partial(
  {
    ElementNotifications: t.array(elementNotificationCodec),
    Limits: riskManagementLimitsCodec,
    Notification: notificationCodec,
  },
  'GetRiskManagementResponseCodec',
);

export const getSystemRiskManagementResponseCodec = t.partial(
  {
    ElementNotifications: t.array(elementNotificationCodec),
    Notification: notificationCodec,
    Ranks: t.array(riskManagementSystemRank),
  },
  'GetSystemRiskManagementResponseCodec',
);
