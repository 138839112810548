import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { getRunningJackpots } from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from './actions';
import { getPlatformJackpotsResponse } from './codecs';
import { getPlatformJackpotsErrorMessages } from './errorMessages';
import { PlatformJackpotEpic } from './types/store';

export const getPlatformJackpotsEpic: PlatformJackpotEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPlatformJackpots.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      wsAdapter
        .request(
          getRunningJackpots({
            LanguageCode: currentWebSocketLocaleSelector(state),
          }),
        )
        .pipe(
          mapGuard(getPlatformJackpotsResponse),
          map((response) =>
            actions.getPlatformJackpots.success(response.RunningJackpots),
          ),
          catchError((err) =>
            of(
              actions.getPlatformJackpots.failure(
                createFailurePayload(err, getPlatformJackpotsErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
