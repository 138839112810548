// Duplicate of
// packages/g1-gaming/src/winnings/store/__mocks__/biggestWinnings.ts Made
// because it needs to be included in the bundle and __mocks__ folders are
// ignored by tsconfig
export const getBiggestWinningsMockRequest = {
  Count: 10,
  PageNumber: 0,
  PageSize: 10,
};

export const getBiggestWinningsMockResponse = {
  Status: 1,
  PageNumber: 0,
  PageSize: 8,
  Total: 40,
  List: [
    {
      Pseudo: 'Céline T.',
      Winnings: 54320,
      Country: 'Flémalle',
      Game: {
        Id: 'eb311cd2-3fa0-4146-94be-93940f6dc635',
        Name: 'Blueprint - Wish Upon A Jackpot',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2-IE-Belgium',
        MinBet: 0.2,
        MaxBet: 500,
        MaxWin: 0,
        GameOrder: 3054,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'wsfanydy.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 102.544609285158,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 83.4793221958376,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 94.966681949827,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.6222624733474,
          },
        ],
        AttributeIdList: ['25711aae-4c97-4bf4-87bd-750e2702af4b'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-12T20:45:49.273669+01:00',
    },
    {
      Pseudo: 'Claudia V.',
      Winnings: 44080,
      Country: 'Meldert',
      Game: {
        Id: '10945d5b-b432-4708-900f-fc509f3b38d0',
        Name: 'BALLY - Cash Spin',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2-IE-Belgium',
        MinBet: 0.4,
        MaxBet: 480,
        MaxWin: 0,
        GameOrder: 1472,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'aryhqxme.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 100.311102407853,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 91.0451274358375,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 96.9694523280628,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 92.7141648345578,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 96.651014871046,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 87.456428047987,
          },
        ],
        AttributeIdList: [
          'c03f7df4-67d4-4834-ac42-6bffa77f30f3',
          '0639f920-52a3-4ab7-8b1b-fc5a0787434b',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-09T23:59:10.527344+01:00',
    },
    {
      Pseudo: 'Andy V.',
      Winnings: 43415,
      Country: 'Aalst',
      Game: {
        Id: 'e8c8ec27-6857-4a02-938d-944fdf0eafb3',
        Name: 'GAMOMAT - Back to the Fruits Respins of Amun Re',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2-IE-Belgium',
        MinBet: 0,
        MaxBet: 0,
        MaxWin: 0,
        GameOrder: 3052,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'j4jtkraa.png',
        RulesMediaResourceUri: '',
        IsNewGame: true,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 100,
          },
        ],
        AttributeIdList: [
          'c03f7df4-67d4-4834-ac42-6bffa77f30f3',
          '60ae1306-9cf2-48b5-afc4-342e3df2dba4',
          'aab3a8a3-a04c-49ac-b7e2-858e7615eef4',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '1',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-08T14:47:28.683924+01:00',
    },
    {
      Pseudo: 'Céline T.',
      Winnings: 38857.5,
      Country: 'Flémalle',
      Game: {
        Id: 'eee06871-ca2e-4e19-83d4-eecca9b0db43',
        Name: 'Quarter Million Slot',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1',
        MinBet: 1,
        MaxBet: 100,
        MaxWin: 10000,
        GameOrder: 3072,
        ProgressiveJackpotName: 'QuarterMillion',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: '2t3yz0kd.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 113.106572197592,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 88.9053380708044,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 105.89085817964,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 86.5497332120779,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 98.8950693764048,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 92.1439672630044,
          },
        ],
        AttributeIdList: [
          'c03f7df4-67d4-4834-ac42-6bffa77f30f3',
          '3eb7a8de-4a97-455a-bf53-0fe8469bc21c',
          '6521cc89-e7f0-43d4-be67-24825a9f1ab1',
          'e64676a1-df85-4686-a551-6856765b338c',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-12T15:26:25.723352+01:00',
    },
    {
      Pseudo: 'Rungnapa N.',
      Winnings: 31500,
      Country: 'Antwerpen',
      Game: {
        Id: '178b967b-a06a-4b22-817d-63b3c9ccf9e1',
        Name: 'EVO - Blackjack Live Lobby',
        Enabled: true,
        GameType: 'BLACKJACK',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2',
        MinBet: 1,
        MaxBet: 2000,
        MaxWin: 0,
        GameOrder: 2275,
        IsOnlyAvailableInRealMoney: true,
        LobbyMediaResourceUri: 'rfccbz4i.jpg',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 97.6612822245205,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 97.1412525706652,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 98.6685905092196,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 97.6036966660021,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 96.7655436223713,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 97.2324260081411,
          },
        ],
        AttributeIdList: [],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-20T08:34:27.302931+01:00',
    },
    {
      Pseudo: 'Claudia V.',
      Winnings: 27960,
      Country: 'Meldert',
      Game: {
        Id: '9d8c76bd-ef7b-4886-8162-143d964356c7',
        Name: 'Blueprint - The Goonies',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2-IE-Belgium',
        MinBet: 0,
        MaxBet: 0,
        MaxWin: 0,
        GameOrder: 3041,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'tjlhcgqu.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 100.220918001087,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 89.1824806266236,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 105.142687581397,
          },
        ],
        AttributeIdList: [
          'c03f7df4-67d4-4834-ac42-6bffa77f30f3',
          '31b3c13b-a3ea-4eff-a70d-533e9417c904',
          '6521cc89-e7f0-43d4-be67-24825a9f1ab1',
          '25711aae-4c97-4bf4-87bd-750e2702af4b',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-10T15:40:15.588119+01:00',
    },
    {
      Pseudo: 'Renaud C.',
      Winnings: 24282,
      Country: 'Taintignies',
      Game: {
        Id: 'ff33b198-50ba-4062-b2fd-089ef6d8a968',
        Name: 'NetEnt - Jumanji',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2',
        MinBet: 0.1,
        MaxBet: 200,
        MaxWin: 0,
        GameOrder: 3047,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'kplrtxpq.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 86.1636760360543,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 103.920688915735,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 98.1711299338154,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 101.683465791012,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 96.4412384837194,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.9510980394956,
          },
        ],
        AttributeIdList: [
          '31b3c13b-a3ea-4eff-a70d-533e9417c904',
          'f475e9c9-351f-4f21-82d9-b247d7885704',
          '68d2c31a-f42a-40db-a3cd-52d8ec9c0281',
          '8ad0622d-97d1-4147-b72f-ce589ceae763',
          '44291731-6eef-4540-88ce-643ce17840d7',
          'bf2be22f-e207-455e-8497-0830cdca22b9',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-10T07:36:37.729822+01:00',
    },
    {
      Pseudo: 'Renaud C.',
      Winnings: 24090,
      Country: 'Taintignies',
      Game: {
        Id: '4f69234c-f816-4c14-8ad9-df350356ff28',
        Name: 'PragmaticPlay - Wolf Gold',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2-IE-Belgium',
        MinBet: 0.25,
        MaxBet: 125,
        MaxWin: 0,
        GameOrder: 3071,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'y2fshdkf.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 95.2006722045651,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 97.6384463838129,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 94.1193523980505,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 98.8147055590014,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.1016548934905,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 94.2898785438584,
          },
        ],
        AttributeIdList: [
          '022f1a35-c486-4877-bd3a-f82e84b38d02',
          '33877639-3820-4a8e-b7cc-a851a143f0ae',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-08T13:45:53.68293+01:00',
    },
    {
      Pseudo: 'Alizée W.',
      Winnings: 21500,
      Country: 'Liege',
      Game: {
        Id: '178b967b-a06a-4b22-817d-63b3c9ccf9e1',
        Name: 'EVO - Blackjack Live Lobby',
        Enabled: true,
        GameType: 'BLACKJACK',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2',
        MinBet: 1,
        MaxBet: 2000,
        MaxWin: 0,
        GameOrder: 2275,
        IsOnlyAvailableInRealMoney: true,
        LobbyMediaResourceUri: 'rfccbz4i.jpg',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 97.6612822245205,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 97.1412525706652,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 98.6685905092196,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 97.6036966660021,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 96.7655436223713,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 97.2324260081411,
          },
        ],
        AttributeIdList: [],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-20T08:34:27.302931+01:00',
    },
    {
      Pseudo: 'Lola A.',
      Winnings: 20050,
      Country: 'Meldert',
      Game: {
        Id: '9d8c76bd-ef7b-4886-8162-143d964356c7',
        Name: 'Blueprint - The Goonies',
        Enabled: true,
        GameType: 'SLOT',
        GameClass: 'A',
        GameProviderName: 'Gaming1v2-IE-Belgium',
        MinBet: 0,
        MaxBet: 0,
        MaxWin: 0,
        GameOrder: 3041,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'tjlhcgqu.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-07-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-08-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-09-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-10-01T12:00:00',
            RTP: 100.220918001087,
          },
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 89.1824806266236,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 105.142687581397,
          },
        ],
        AttributeIdList: [
          'c03f7df4-67d4-4834-ac42-6bffa77f30f3',
          '31b3c13b-a3ea-4eff-a70d-533e9417c904',
          '6521cc89-e7f0-43d4-be67-24825a9f1ab1',
          '25711aae-4c97-4bf4-87bd-750e2702af4b',
        ],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-01-10T15:40:15.588119+01:00',
    },
  ],
};

export const get24BiggestWinningsMockResponse = {
  Status: 1,
  PageNumber: 0,
  PageSize: 24,
  Total: 24,
  List: [
    {
      Pseudo: 'Angelica M.',
      Winnings: 22340,
      Country: 'Antwerpen',
      Game: {
        Id: '4810dc89-fbf7-4c55-9f08-3ee51dc592d7',
        Name: 'Mystery Rush',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'Gaming1',
        MinBet: 0.25,
        MaxBet: 10,
        MaxWin: 500,
        GameOrder: 216,
        ProgressiveJackpotName: 'MysteryRush',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'hg4xbv5a.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 93.7886260612177,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 94.0034704633498,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 96.7513879196456,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 95.9624691882979,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 95.0271606856419,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 95.041790423602,
          },
        ],
        AttributeIdList: ['db083a11-7cc3-4e63-aa4e-870e93a036e2'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-27T09:55:43.080943+00:00',
    },
    {
      Pseudo: 'Kris V.',
      Winnings: 5843.25,
      Country: 'Schoten',
      Game: {
        Id: 'd625f936-108b-423e-ba19-d0fc0d167e7b',
        Name: 'EGT - 20 Joker Dice',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'EGT',
        MinBet: 0.1,
        MaxBet: 10,
        MaxWin: 0,
        GameOrder: 353,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'lte4lgfk.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 95.1979139070126,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 99.3732127743508,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 95.7777437338596,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 97.2938130112361,
          },
        ],
        AttributeIdList: ['15332bd5-214e-408a-96d7-e9bc0872b735'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T18:38:36.873474+00:00',
    },
    {
      Pseudo: 'Filippa C.',
      Winnings: 5558.55,
      Country: 'Bassenge (Wonck)',
      Game: {
        Id: 'de918c38-0d4a-4ead-a89b-59971cb64d65',
        Name: 'EGT - Amazon’s Story',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'EGT',
        MinBet: 0.3,
        MaxBet: 9,
        MaxWin: 0,
        GameOrder: 133,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'EGT-AmazonsStory.jpg',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 95.8174864639831,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 99.722870994338,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 94.4056427431839,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 93.8814438257557,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 99.990639813408,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 95.2112320032434,
          },
        ],
        AttributeIdList: ['15332bd5-214e-408a-96d7-e9bc0872b735'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T21:22:38.787221+00:00',
    },
    {
      Pseudo: 'Coraline C.',
      Winnings: 4927.2,
      Country: 'Courrière',
      Game: {
        Id: '31096463-ea41-49c6-b50e-5beb647d8eca',
        Name: 'EGT - 20 Burning Dice',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'EGT',
        MinBet: 0.2,
        MaxBet: 10,
        MaxWin: 0,
        GameOrder: 136,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'EGT-20-Burning-Dice.jpg',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 106.179169921768,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 95.0548226657007,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 99.0984715883346,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 106.814407237771,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 96.1091668175635,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 93.937599311239,
          },
        ],
        AttributeIdList: ['15332bd5-214e-408a-96d7-e9bc0872b735'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-22T16:48:14.628626+00:00',
    },
    {
      Pseudo: 'Caterina M.',
      Winnings: 4022,
      Country: 'Moignelee',
      Game: {
        Id: '5efc255e-9c7b-46ce-8fea-0fe77e1f6c03',
        Name: 'Rapid Rush',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'Gaming1',
        MinBet: 0.25,
        MaxBet: 5,
        MaxWin: 500,
        GameOrder: 211,
        ProgressiveJackpotName: 'QuickHitJackpotDice_00',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'utk21jhu.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: true,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 93.2637122283451,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 94.029021079992,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 94.4091642836678,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 94.125449346199,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 94.5976091907758,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 94.4530924926403,
          },
        ],
        AttributeIdList: ['db083a11-7cc3-4e63-aa4e-870e93a036e2'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '1',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T20:32:55.362332+00:00',
    },
    {
      Pseudo: 'Dominique D.',
      Winnings: 3575,
      Country: 'Knokke Heist',
      Game: {
        Id: '9c977689-b394-4b37-9357-f4fef10f73e1',
        Name: 'ADG - Infinity Dice',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 50,
        MaxWin: 0,
        GameOrder: 200,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'kkgunh4r.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: true,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 98.3986634179426,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 95.5309166296234,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 97.1796850444829,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 98.6125085323575,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 94.5821443235599,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.527907394918,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '1',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-26T12:52:14.960312+00:00',
    },
    {
      Pseudo: 'Benjamin P.',
      Winnings: 3055,
      Country: 'Auderghem',
      Game: {
        Id: '9c977689-b394-4b37-9357-f4fef10f73e1',
        Name: 'ADG - Infinity Dice',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 50,
        MaxWin: 0,
        GameOrder: 200,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'kkgunh4r.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: true,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 98.3986634179426,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 95.5309166296234,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 97.1796850444829,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 98.6125085323575,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 94.5821443235599,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.527907394918,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '1',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T18:18:48.925074+00:00',
    },
    {
      Pseudo: 'Martine G.',
      Winnings: 2950,
      Country: 'Walcourt',
      Game: {
        Id: '9c977689-b394-4b37-9357-f4fef10f73e1',
        Name: 'ADG - Infinity Dice',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 50,
        MaxWin: 0,
        GameOrder: 200,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'kkgunh4r.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: true,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 98.3986634179426,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 95.5309166296234,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 97.1796850444829,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 98.6125085323575,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 94.5821443235599,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.527907394918,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '1',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-28T00:18:42.802645+00:00',
    },
    {
      Pseudo: 'Veronika V.',
      Winnings: 2950,
      Country: 'Desselgem',
      Game: {
        Id: '067f95a7-574b-428c-8d61-d0fa481f4bbe',
        Name: 'ADG - Pure',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 50,
        MaxWin: 0,
        GameOrder: 204,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'bc4zftg1.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.6511345558086,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.4519915775445,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 96.8370455574968,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 99.1781471465791,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 90.9820123590092,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 100.19737347273,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T02:37:43.060235+00:00',
    },
    {
      Pseudo: 'Nathalie D.',
      Winnings: 2925,
      Country: 'Oostende',
      Game: {
        Id: '9c977689-b394-4b37-9357-f4fef10f73e1',
        Name: 'ADG - Infinity Dice',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 50,
        MaxWin: 0,
        GameOrder: 200,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'kkgunh4r.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: true,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 98.3986634179426,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 95.5309166296234,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 97.1796850444829,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 98.6125085323575,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 94.5821443235599,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.527907394918,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '1',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-29T01:07:19.710444+00:00',
    },
    {
      Pseudo: 'Selina D.',
      Winnings: 2570,
      Country: 'Melle',
      Game: {
        Id: '18fb663c-6516-4ba1-8430-addef0eb8b6a',
        Name: 'ADG - Mega Wheels',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 25,
        MaxWin: 0,
        GameOrder: 198,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'jzijcejg.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.5714288751518,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.0071473113475,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 96.544645725925,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 97.2785424848761,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 96.8466760537575,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.4112071915194,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-24T15:04:03.938699+00:00',
    },
    {
      Pseudo: 'Kim B.',
      Winnings: 2547.62,
      Country: 'Liège',
      Game: {
        Id: 'bbd30264-cbbf-40e2-97f9-6c43b0ee8e7e',
        Name: 'EGT - 20 Super Dice',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'EGT',
        MinBet: 0.2,
        MaxBet: 10,
        MaxWin: 0,
        GameOrder: 187,
        ProgressiveJackpotName: 'EGT',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'EGT-20sd.jpg',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 94.6954595251376,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 100.527368262816,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 95.0868904387642,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 98.9782311335728,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 94.4349776498929,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 94.3451375941681,
          },
        ],
        AttributeIdList: ['15332bd5-214e-408a-96d7-e9bc0872b735'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-28T03:02:55.049528+00:00',
    },
    {
      Pseudo: 'Selina D.',
      Winnings: 2400,
      Country: 'Melle',
      Game: {
        Id: '18fb663c-6516-4ba1-8430-addef0eb8b6a',
        Name: 'ADG - Mega Wheels',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 25,
        MaxWin: 0,
        GameOrder: 198,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'jzijcejg.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.5714288751518,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.0071473113475,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 96.544645725925,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 97.2785424848761,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 96.8466760537575,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.4112071915194,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-24T14:31:49.396368+00:00',
    },
    {
      Pseudo: 'Jan B.',
      Winnings: 2360,
      Country: 'Keerbergen',
      Game: {
        Id: '5893d704-2c2b-473a-aecc-87cc48edc5af',
        Name: 'Mirror Jackpot',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'Gaming1',
        MinBet: 0.25,
        MaxBet: 10,
        MaxWin: 1000,
        GameOrder: 218,
        ProgressiveJackpotName: 'MirrorGrid',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'ucywqvor.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 99.186153444069,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 97.2320471511709,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 93.9386678199329,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 96.2342701568026,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 99.3183576001473,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 93.391081503356,
          },
        ],
        AttributeIdList: ['db083a11-7cc3-4e63-aa4e-870e93a036e2'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-22T18:58:23.838375+00:00',
    },
    {
      Pseudo: 'Dwight D.',
      Winnings: 2269.4,
      Country: 'Meulebeke',
      Game: {
        Id: '3e0cbafe-0a08-4857-9492-1d1163d2dc62',
        Name: 'Fakir DiceSlot',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'Gaming1',
        MinBet: 0.1,
        MaxBet: 10,
        MaxWin: 1000,
        GameOrder: 240,
        ProgressiveJackpotName: 'Fakir',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'zd2euk1k.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: true,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 93.9396586196509,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 97.3025877275798,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 94.1773934797834,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 98.3635866296208,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 92.0808009249077,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 94.8768262240855,
          },
        ],
        AttributeIdList: ['db083a11-7cc3-4e63-aa4e-870e93a036e2'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '1',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-25T06:16:20.797175+00:00',
    },
    {
      Pseudo: 'Pascal M.',
      Winnings: 2070,
      Country: 'Opglabbeek',
      Game: {
        Id: '18fb663c-6516-4ba1-8430-addef0eb8b6a',
        Name: 'ADG - Mega Wheels',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 25,
        MaxWin: 0,
        GameOrder: 198,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'jzijcejg.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.5714288751518,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.0071473113475,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 96.544645725925,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 97.2785424848761,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 96.8466760537575,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.4112071915194,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-27T18:53:12.926024+00:00',
    },
    {
      Pseudo: 'Martine V.',
      Winnings: 2001,
      Country: 'Desselgem',
      Game: {
        Id: '7a8df475-ce8d-4926-91db-d5771cb4bf39',
        Name: 'Mega Spinner',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'Gaming1',
        MinBet: 0.25,
        MaxBet: 15,
        MaxWin: 500,
        GameOrder: 206,
        ProgressiveJackpotName: 'MegaSpinnerJackpotDice',
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'wo2cfdnt.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 93.3040256949452,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 91.8035232579835,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 93.6686277242641,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 91.5823277856141,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 95.6776719203141,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 93.8199219971936,
          },
        ],
        AttributeIdList: ['db083a11-7cc3-4e63-aa4e-870e93a036e2'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-24T23:50:31.840531+00:00',
    },
    {
      Pseudo: 'Gerardus W.',
      Winnings: 1992.98,
      Country: 'Antwerpen',
      Game: {
        Id: 'fe682980-e6fe-455c-ac53-8d78ac62d2c9',
        Name: 'ADG - Bank Robbers 3S',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 25,
        MaxWin: 0,
        GameOrder: 157,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'xdxltski.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 66.8117551876376,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 116.524954928194,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 94.323168926175,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 88.5193633693421,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 89.4256449485166,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 76.0040412594462,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-27T22:17:21.327938+00:00',
    },
    {
      Pseudo: 'Kris V.',
      Winnings: 1983.5,
      Country: 'Schoten',
      Game: {
        Id: 'd625f936-108b-423e-ba19-d0fc0d167e7b',
        Name: 'EGT - 20 Joker Dice',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'EGT',
        MinBet: 0.1,
        MaxBet: 10,
        MaxWin: 0,
        GameOrder: 353,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'lte4lgfk.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 95.1979139070126,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 99.3732127743508,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 95.7777437338596,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 97.2938130112361,
          },
        ],
        AttributeIdList: ['15332bd5-214e-408a-96d7-e9bc0872b735'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T20:05:14.62528+00:00',
    },
    {
      Pseudo: 'Dorien R.',
      Winnings: 1914.88,
      Country: 'Diepenbeek',
      Game: {
        Id: 'a7a71b32-379f-4604-b01a-da54f6717484',
        Name: 'EGT - Shining Dice',
        Enabled: true,
        GameType: 'DICESLOT',
        GameClass: '',
        GameProviderName: 'EGT',
        MinBet: 0.1,
        MaxBet: 10,
        MaxWin: 0,
        GameOrder: 360,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: '0z2lglc5.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 100,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 97.0890429602848,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 91.1782687738583,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 101.454135634492,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 95.0565792685854,
          },
        ],
        AttributeIdList: ['15332bd5-214e-408a-96d7-e9bc0872b735'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-22T20:41:10.810678+00:00',
    },
    {
      Pseudo: 'Raf V.',
      Winnings: 1900,
      Country: 'Dilsen-Stokkem',
      Game: {
        Id: '9f9f8f3f-7295-4fd2-b003-dab2d704b8c4',
        Name: 'ADG - Choco Deluxe',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 25,
        MaxWin: 0,
        GameOrder: 148,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'f51badxm.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 91.3268858511927,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 95.7243624159705,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 97.2280525483631,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 90.3743247977117,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 96.2008775963959,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 110.314810031478,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-27T15:29:16.992495+00:00',
    },
    {
      Pseudo: 'Kurt B.',
      Winnings: 1771.98,
      Country: 'Kinrooi',
      Game: {
        Id: 'fd4b81a9-8d1e-49af-bcaa-f6ec7fd07d08',
        Name: 'ADG - RedLotus',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 5,
        MaxWin: 0,
        GameOrder: 239,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'katfzaek.png',
        RulesMediaResourceUri: '',
        IsNewGame: true,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.2419719031772,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 93.4700066924382,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 95.7360457120441,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 94.4212152514955,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 93.5162959387835,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 97.1435199120614,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '1',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-24T15:51:19.553529+00:00',
    },
    {
      Pseudo: 'Brigitte W.',
      Winnings: 1750.35,
      Country: 'Oupeye',
      Game: {
        Id: '8f2bd4fe-f737-47f3-8605-183a8ec1bfb9',
        Name: 'DealOrNoDealBlue 3G',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'Gaming1',
        MinBet: 0.25,
        MaxBet: 5,
        MaxWin: 500,
        GameOrder: 207,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: '3i0nchah.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 94.3317605129427,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 91.8163657412171,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 93.8315508043422,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 93.7180156923698,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 98.7521822063764,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 97.4062201974303,
          },
        ],
        AttributeIdList: ['db083a11-7cc3-4e63-aa4e-870e93a036e2'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-27T20:20:59.899486+00:00',
    },
    {
      Pseudo: 'Stéphanie W.',
      Winnings: 1704,
      Country: 'Hollogne-Aux-Pierres',
      Game: {
        Id: '18fb663c-6516-4ba1-8430-addef0eb8b6a',
        Name: 'ADG - Mega Wheels',
        Enabled: true,
        GameType: 'DICE',
        GameClass: '',
        GameProviderName: 'AirDice',
        MinBet: 0.25,
        MaxBet: 25,
        MaxWin: 0,
        GameOrder: 198,
        IsOnlyAvailableInRealMoney: false,
        LobbyMediaResourceUri: 'jzijcejg.png',
        RulesMediaResourceUri: '',
        IsNewGame: false,
        IsTopGame: false,
        IsRestricted: false,
        IsCountryForbidden: false,
        IsFavorite: false,
        ListRTPDate: [
          {
            CalculatedDate: '2019-11-01T12:00:00',
            RTP: 97.5714288751518,
          },
          {
            CalculatedDate: '2019-12-01T12:00:00',
            RTP: 96.0071473113475,
          },
          {
            CalculatedDate: '2020-01-01T12:00:00',
            RTP: 96.544645725925,
          },
          {
            CalculatedDate: '2020-02-01T12:00:00',
            RTP: 97.2785424848761,
          },
          {
            CalculatedDate: '2020-03-01T12:00:00',
            RTP: 96.8466760537575,
          },
          {
            CalculatedDate: '2020-04-01T12:00:00',
            RTP: 96.4112071915194,
          },
        ],
        AttributeIdList: ['de8ae0d5-fb8a-40fe-94da-32e92f43ec87'],
        MinimalVipRank: 0,
        IsVisible: true,
        MetaDatas: [
          {
            Key: 'IsNewGame',
            Value: '0',
          },
          {
            Key: 'IsTopGame',
            Value: '0',
          },
        ],
        ForbiddenCountries: '',
        RestrictedCountries: '',
        EnabledLanguageCodes: [],
      },
      WinningDate: '2020-05-23T18:33:59.61727+00:00',
    },
  ],
};
