import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { EBettingSlipIdentifier } from '../../common/store/types';
import { BettingActions } from '../../store/types';
import * as actions from '../actions';
import { generateInitialPipeState, isActionWithBetPipeId } from '../helpers';
import { BetPipeStateSlice } from '../types';

const initialState: BetPipeStateSlice = {};

/**
 * Ensure the bettingSlipState is initialized for any action having a betPipeId
 */
export const betPipeInitReducer = (
  state = initialState,
  action: BettingActions,
): BetPipeStateSlice =>
  produce(state, (draftState) => {
    // console.log("action", action);
    // console.log("action.payload.bettingSlipId", (action as unknown as {payload: {bettingSlipId: string}})?.payload?.bettingSlipId);
    if (action.type === getType(actions.startBetPipe)) {
      const {
        payload: { bettingSlipId, betPipeId, type },
      } = action;

      draftState[betPipeId] = generateInitialPipeState({
        bettingSlipId,
        type,
      });
    } else if (isActionWithBetPipeId(action)) {
      const { betPipeId } = action.payload;
      /* Shouldn't happen, every pipe step must be executed after a
      `startBetPipe` action has been dispatched */
      if (!(betPipeId in draftState)) {
        draftState[betPipeId] = generateInitialPipeState({
          bettingSlipId: EBettingSlipIdentifier.Missing,
          type: 'betPlacement',
        });
      }
    }
  });
