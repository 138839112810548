/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { InfoFromOutcomesRequest, InfoFromOutcomesResponse } from '../entities';

export const getInfoFromOutcomes = (options: InfoFromOutcomesRequest) =>
  createRequest<InfoFromOutcomesResponse>(201, 'GetInfoFromOutcomes', options);
