import parseISO from 'date-fns/parseISO';
import { either } from 'fp-ts/Either';
import * as t from 'io-ts';

/* String literals unions */

const stringOrNull = t.union([t.string, t.null]);

// Should be 'image' or 'document'
const mediaKindCodec = t.string;

const linkTypeCodec = t.union([
  t.literal('Web'),
  t.literal('Document'),
  t.literal('Media'),
  t.literal('Any'),
]);

// Should be 'left', 'right', 'top', 'bottom' or 'center'
const alignementCodec = t.string;

// Should be 'primary' or 'primary negative'
const colorTypeCodec = t.string;

// Comes from the type Elements in prismic-reactjs/index.d.ts
// Should be 'paragraph', 'list-item', ...
const richTextElementTypeCodec = t.string;

const spanTypeCodec = t.union([
  t.literal('em'),
  t.literal('strong'),
  t.literal('hyperlink'),
  t.literal('label'),
]);

// We don't use this technique as it makes compilation painfully slow and ts output too long
// https://github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals

/* Decoders */

const isDateString = (u: unknown): u is string =>
  typeof u === 'string' && !Number.isNaN(parseISO(u).getTime());

// source: https://github.com/gcanti/io-ts/blob/master/index.md#custom-types
const dateString = new t.Type<string, Date, unknown>(
  'DateFromString',
  isDateString,
  (u, c) =>
    either.chain(t.string.validate(u, c), (s) =>
      isDateString(s) ? t.failure(u, c) : t.success(s),
    ),
  (a) => parseISO(a),
);

/* Plain Codecs */

// From "Link" type from prismic-reactjs/index.d.ts
const linkCodec = t.partial(
  {
    link_type: linkTypeCodec,
    url: t.string,
    target: t.string,
    id: t.string,
    uid: t.string,
    isBroken: t.boolean,
    lang: t.string,
    slug: t.string,
    tags: t.array(t.string),
    type: t.string,
    height: t.string,
    kind: t.string,
    name: t.string,
    size: t.string,
    width: t.string,
  },
  'Link',
);

const span = t.intersection(
  [
    t.type({
      start: t.number,
      end: t.number,
      type: spanTypeCodec,
    }),
    t.partial({
      data: linkCodec,
    }),
  ],
  'Span',
);

const richText = t.type(
  {
    type: richTextElementTypeCodec,
    text: t.string,
    spans: t.array(span),
  },
  'RichText',
);

const title = t.array(richText, 'Title');

const anchorSlice = t.type(
  {
    slice_type: t.literal('anchor'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      anchor_id: stringOrNull,
    }),
  },
  'AnchorSlice',
);

const containerSlice = t.type(
  {
    slice_type: t.literal('container'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      style: t.string,
      alignment: alignementCodec,
      width: stringOrNull,
      content: t.array(richText),
    }),
  },
  'ContainerSlice',
);

const infoBlockIcon = t.union([
  t.literal('info'),
  t.literal('warning'),
  t.literal('error'),
]);

const infoBlockSlice = t.type(
  {
    slice_type: t.literal('info_block'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      icon: infoBlockIcon,
      content: t.array(richText),
    }),
  },
  'InfoBlockSlice',
);

const stepItem = t.type({
  content: t.array(richText),
});

const stepsSlice = t.type(
  {
    slice_type: t.literal('steps'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
    }),
    items: t.array(stepItem),
  },
  'StepsSlice',
);

const tabsSlice = t.type(
  {
    slice_type: t.literal('tabs'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
    }),
    items: t.array(
      t.type({ header: stringOrNull, content: t.array(richText) }),
    ),
  },
  'TabsSlice',
);

const collapsibleBlockItem = t.type({
  header: stringOrNull,
  content: t.array(richText),
  expanded: t.boolean,
});

const collapsibleBlockSlice = t.type(
  {
    slice_type: t.literal('collapsible_block'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
    }),
    items: t.array(collapsibleBlockItem),
  },
  'CollapsibleBlockSlice',
);

const tableColumnItem = t.array(richText);

const imageItem = t.type(
  {
    dimensions: t.type({ width: t.number, height: t.number }),
    alt: t.union([t.null, t.string]),
    copyright: t.union([t.null, t.string]),
    url: t.string,
  },
  'ImageItem',
);

const getCodecImageType = <T extends string>(names: T[]) =>
  names.reduce(
    (acc, name) => ({ ...acc, [name]: imageItem }),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as { [k in T]: typeof imageItem },
  );

const responsiveImage = <T extends string>(names: T[]) =>
  t.intersection([imageItem, t.type(getCodecImageType<T>(names))]);

const imageSlice = t.type(
  {
    slice_type: t.literal('image'),
    primary: t.intersection([
      t.type({
        enabled: t.boolean,
        id: stringOrNull,
        image: responsiveImage(['Small', 'Medium', 'Large']),
        link: linkCodec,
        width: stringOrNull,
        alignment: alignementCodec,
      }),
      t.partial({
        link: linkCodec,
      }),
    ]),
  },
  'ImageSlice',
);

const gridItem = t.type({
  style: stringOrNull,
  width: stringOrNull,
  content: t.array(richText),
  horizontal_alignment: stringOrNull,
  vertical_alignment: stringOrNull,
});

const gridSlice = t.type(
  {
    slice_type: t.literal('grid'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      count_large: t.string,
      count_medium: t.string,
      count_small: t.string,
      alignment: alignementCodec,
    }),
    items: t.array(gridItem),
  },
  'GridSlice',
);

const mediasTextBlockSlice = t.type(
  {
    slice_type: t.literal('medias_text_block'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      content: t.array(richText),
      main_media: stringOrNull,
      main_media_alignment: alignementCodec,
      media_width: stringOrNull,
    }),
    items: t.array(
      t.type({
        media: stringOrNull,
      }),
    ),
  },
  'MediasTextBlockSlice',
);

const buttonItem = t.type(
  {
    type: t.union([t.literal('Button'), t.literal('Link')]),
    color: t.union([
      t.literal('primary'),
      t.literal('secondary'),
      t.literal('tertiary'),
    ]),
    text: t.string,
    link: linkCodec,
    visible_logged: t.boolean,
    visible_not_logged: t.boolean,
  },
  'ButtonItem',
);

const buttonsSlice = t.type(
  {
    slice_type: t.literal('buttons'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      block_alignment: alignementCodec,
    }),
    items: t.array(buttonItem),
  },
  'ButtonsSlice',
);

const tableRowItem = t.type(
  {
    column_1: tableColumnItem,
    column_2: tableColumnItem,
    column_3: tableColumnItem,
    column_4: tableColumnItem,
    column_5: tableColumnItem,
    column_6: tableColumnItem,
  },
  'TableRow',
);

const tableSlice = t.type(
  {
    slice_type: t.literal('table'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      columns_count: t.string,
      has_headers: t.boolean,
    }),
    items: t.array(tableRowItem),
  },
  'TableSlice',
);

const richTextSlice = t.type(
  {
    slice_type: t.literal('rich_text'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      content: t.array(richText),
      text_align: alignementCodec,
    }),
  },
  'RichTextSlice',
);

const videoSlice = t.type(
  {
    slice_type: t.literal('video'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      link: linkCodec,
      width: stringOrNull,
    }),
  },
  'VideoSlice',
);

const mapSlice = t.type(
  {
    slice_type: t.literal('map'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      link: linkCodec,
      width: stringOrNull,
      height: stringOrNull,
    }),
  },
  'MapSlice',
);

const mediaCardSlice = t.type(
  {
    slice_type: t.literal('media_card'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      title1: stringOrNull,
      media_slice_id: stringOrNull,
      content: t.array(richText),
    }),
  },
  'MediaCardSlice',
);

const conditionName = t.union([
  t.literal('USER_IS_CONNECTED'),
  t.literal('USER_IS_NOT_CONNECTED'),
  t.literal('USER_IS_DEPOSITOR'),
  t.literal('USER_IS_NOT_DEPOSITOR'),
  t.literal('BEFORE_DATE'),
  t.literal('AFTER_DATE'),
  t.literal('SCREEN_IS_SMALL'),
  t.literal('SCREEN_IS_SMALL_OR_MEDIUM'),
  t.literal('SCREEN_IS_MEDIUM'),
  t.literal('SCREEN_IS_MEDIUM_OR_LARGE'),
  t.literal('SCREEN_IS_LARGE'),
  t.literal('SCREEN_IS_SMALL_OR_LARGE'),
]);

const conditionItem = t.type(
  {
    condition: conditionName,
    datetime: t.union([t.null, dateString]),
  },
  'ConditionItem',
);

const conditionalSlice = t.type(
  {
    slice_type: t.literal('conditional_slice'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      content_false: t.array(richText),
      content_true: t.array(richText),
    }),
    items: t.array(conditionItem),
  },
  'ConditionalSlice',
);

const spacerSlice = t.type(
  {
    slice_type: t.literal('spacer'),
    primary: t.type({
      enabled: t.boolean,
      id: stringOrNull,
      size: stringOrNull,
    }),
  },
  'SpacerSlice',
);

export enum PromotionConditionsType {
  connected = 'connected',
  notConnected = 'not connected',
  depositor = 'depositor',
  notDepositor = 'depositor',
}

const slice = t.type(
  {
    slice_type: t.string,
    primary: t.UnknownRecord,
  },
  'Slice',
);
// Having the full type makes the generated .d.ts huge
/*
t.union([
  cardsGrid,
  collapsibleBlock,
  conditionalSlice,
  imageAndLink,
  infoBlock,
  linksOrbuttonsBlock,
  mediasGrid,
  mediaTextBlock,
  steps,
  table2Columns,
  table3Columns,
  tabs,
  text,
]);
*/

export const PrismicCodecs = {
  alignementCodec,
  colorTypeCodec,
  dateString,
  linkCodec,
  linkTypeCodec,
  mediaKindCodec,
  richText,
  richTextElementTypeCodec,
  slice,
  span,
  spanTypeCodec,
  title,
};

export const PrismicPartialCodecs = {
  buttonItem,
  collapsibleBlockItem,
  conditionItem,
  conditionName,
  gridItem,
  imageItem,
  infoBlockIcon,
  responsiveImage,
  stepItem,
  tableColumnItem,
  tableRowItem,
};

export const PrismicSliceCodecs = {
  anchor: anchorSlice,
  buttons: buttonsSlice,
  collapsibleBlock: collapsibleBlockSlice,
  conditional: conditionalSlice,
  container: containerSlice,
  grid: gridSlice,
  image: imageSlice,
  infoBlock: infoBlockSlice,
  map: mapSlice,
  mediaCard: mediaCardSlice,
  mediasTextBlock: mediasTextBlockSlice,
  richText: richTextSlice,
  spacer: spacerSlice,
  steps: stepsSlice,
  tabs: tabsSlice,
  video: videoSlice,
  table: tableSlice,
};
