import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '@gaming1/g1-betting';
import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';

/**
 * This hook dispatch a getScoreBoard request. It will automatically subscribe
 * to updates unless subscribeToUpdates is set to false. All subscriptions
 * will be cancelled on unmount.
 */
export const useScoreBoard = (eventId?: number, subscribeToUpdates = true) => {
  const dispatch = useDispatch();

  const locale = useSelector(currentWebSocketLocaleSelector);

  useEffect(() => {
    if (eventId) {
      dispatch(actions.getScoreBoard.request({ eventId, subscribeToUpdates }));
    }
    return () => {
      if (eventId && subscribeToUpdates) {
        dispatch(actions.getScoreBoard.cancel({ eventId, locale }));
      }
    };
  }, [dispatch, eventId, subscribeToUpdates, locale]);
};

/**
 * This hook dispatch a getScoreBoards request. It will automatically subscribe
 * to updates unless subscribeToUpdates is set to false. All subscriptions
 * will be cancelled on unmount.
 */
export const useScoreBoards = (
  eventIds?: number[],
  subscribeToUpdates = true,
) => {
  const dispatch = useDispatch();

  const locale = useSelector(currentWebSocketLocaleSelector);

  /**
   * We don't want to dispatch a new action when the array reference changes
   * or when the ids are sorted differently. This key will ensure that
   * the useEffect is triggered only when different ids are provided.
   */
  const key = useMemo(() => (eventIds || []).sort().join('-'), [eventIds]);

  useEffect(() => {
    if (eventIds && !!eventIds.length) {
      dispatch(
        actions.getScoreBoards.request({ eventIds, subscribeToUpdates }),
      );
    }
    return () => {
      if (eventIds && !!eventIds.length && subscribeToUpdates) {
        dispatch(actions.getScoreBoards.cancel({ eventIds, locale }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, key, subscribeToUpdates, locale]);
};

/**
 * This hook dispatch a getAllScoreBoards request. It will automatically
 * subscribe to all updates unless subscribeToUpdates is set to false. All
 * subscriptions will be cancelled on unmount.
 */
export const useAllScoreBoards = (subscribeToUpdates = true) => {
  const dispatch = useDispatch();

  const locale = useSelector(currentWebSocketLocaleSelector);

  useEffect(() => {
    dispatch(actions.getAllScoreBoards.request({ subscribeToUpdates }));

    return () => {
      if (subscribeToUpdates) {
        dispatch(actions.getAllScoreBoards.cancel({ locale }));
      }
    };
  }, [dispatch, subscribeToUpdates, locale]);
};
