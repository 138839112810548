import {
  ContextType,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useConfig } from '@gaming1/g1-config';
import { useGetIsMounted } from '@gaming1/g1-utils';

import { logger } from '../../../logger';
import { usePrivacySettings } from '../../hooks/usePrivacySettings';
import { TrackingContext } from '../../TrackingContext';
import {
  DataLayerItem,
  ExtendedGTMEventProps,
  GoogleAnalyticsEvent,
  RawGTMData,
  windowWithDataLayer,
} from '../../types';

export const DELAY_BEFORE_PAGEVIEW_EVENT_IN_MS = 1000;

type GTMContextValue = Pick<
  ContextType<typeof TrackingContext>,
  'isGtmEnabled' | 'pushEvent' | 'pushRawData'
>;

/** returns true if the type matches a GTMEvent */
const isExtendedGTMEventProps = (
  props: ExtendedGTMEventProps | GoogleAnalyticsEvent,
): props is ExtendedGTMEventProps => !!(props as ExtendedGTMEventProps).action;

/** returns true if the type matches a GAEvent */
const isGoogleAnalyticsEvent = (
  props: ExtendedGTMEventProps | GoogleAnalyticsEvent,
): props is GoogleAnalyticsEvent => !(props as ExtendedGTMEventProps).action;

const pushToDataLayer = (item: DataLayerItem) => {
  windowWithDataLayer.dataLayer = windowWithDataLayer.dataLayer || [];
  windowWithDataLayer.dataLayer.push(item);
};

/**
 * Init Google Tag Manager if the id is in the current config
 * Also adds the google-site-verification if value is in the current config
 * Calls the DataDog hook
 * Provide the TrackingContext to track events
 */
export const useGTM = (): GTMContextValue => {
  const {
    core: { privacySettings },
    seo: { googleSiteVerification, gtmId },
  } = useConfig();

  const { getUserConsentStatusForPurpose } = usePrivacySettings();

  const isGtmEnabled =
    !!getUserConsentStatusForPurpose(
      privacySettings.purposeIDs.performanceMeasure,
    ) && !!gtmId;

  const { pathname, search } = useLocation();

  const getIsMounted = useGetIsMounted();

  /** Init the dataLayer on mount */
  useLayoutEffect(() => {
    if (isGtmEnabled) {
      // Legacy way it seems
      // window.dataLayer.push(['js', new Date()]);
      // window.dataLayer.push(['config', gtmId]);
      pushToDataLayer({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    }
  }, [isGtmEnabled]);

  /** Trigger the "pageview" event each time the location changes */
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isGtmEnabled) {
      // Wait for a bit because the title are changed using react-helmet-async
      timeout = setTimeout(() => {
        if (getIsMounted()) {
          logger.debug('[Tracking] pageview event');
          pushToDataLayer({
            event: 'pageview',
          });
        }
      }, DELAY_BEFORE_PAGEVIEW_EVENT_IN_MS);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [getIsMounted, isGtmEnabled, pathname, search]);

  /** Push a GTM event to the dataLayer array */
  const pushEvent = useCallback(
    (eventProps: ExtendedGTMEventProps | GoogleAnalyticsEvent) => {
      if (!isGtmEnabled) {
        logger.debug(
          '[Tracking] An event was reported, but GTM is disabled',
          eventProps,
        );
      } else if (isGoogleAnalyticsEvent(eventProps)) {
        pushToDataLayer({
          ...eventProps,
        });
        logger.debug(
          `[Tracking][GA4] Event: event=${eventProps.event}, event props=${eventProps}`,
          eventProps,
        );
      } else if (isExtendedGTMEventProps(eventProps)) {
        pushToDataLayer({
          event: 'event',
          eventProps,
        });
        logger.debug(
          `[Tracking][Legacy] Event: category=${eventProps.category}, action=${eventProps.action}, event props=`,
          eventProps,
        );
      }
    },
    [isGtmEnabled],
  );

  /** Push an arbitrary record to the dataLayer array */
  const pushRawData = useCallback(
    (data: RawGTMData) => {
      if (!isGtmEnabled) {
        logger.debug(
          '[Tracking] A set of data was reported, but GTM is disabled',
          data,
        );
      } else {
        pushToDataLayer(data);
        logger.debug(`[Tracking] Raw data: `, data);
      }
    },
    [isGtmEnabled],
  );

  /** Log a successful render of the google-site-verification meta tag */
  useEffect(() => {
    if (googleSiteVerification) {
      logger.info(
        `Google site verification meta tag set with value "${googleSiteVerification}"`,
      );
    }
  }, [googleSiteVerification]);

  const gtmData: GTMContextValue = useMemo(
    () => ({
      isGtmEnabled,
      pushEvent,
      pushRawData,
    }),
    [isGtmEnabled, pushEvent, pushRawData],
  );

  return gtmData;
};
