import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  generateLoadingRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { launchPlaceBet } from '../../betPipe/actions';
import * as cashoutActions from '../../cashout/actions';
import { BettingActions } from '../../store/types';

import * as actions from './actions';
import { HistoryState } from './types';

export const initialState: HistoryState = {
  list: { type: 'open', histories: null },
  details: {},
  fromBettingSlip: {},
  requests: {
    getBettingHistory: generateInitialRequestState(null),
    getBettingSlipSystemDetail: {},
  },
};

export const historyReducer = (state = initialState, action: BettingActions) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getBettingHistory.request): {
        produceLoadingState(draftState.requests.getBettingHistory);
        draftState.list.histories = null;
        break;
      }
      case getType(actions.getBettingHistory.success): {
        produceSuccessState(draftState.requests.getBettingHistory);
        draftState.list.type = action.payload.type;
        draftState.list.histories = action.payload.histories || [];
        if (action.payload.type === 'open') {
          draftState.numberOfBetsOpened =
            action.payload.histories.BettingHistory?.length;
        }
        break;
      }
      case getType(actions.getBettingHistory.failure): {
        const { payload } = action;
        produceFailureState(draftState.requests.getBettingHistory, payload);
        draftState.list.histories = null;

        break;
      }
      /* for system detail */
      case getType(actions.getBettingSlipSystemDetail.request): {
        const { bettingSlipId } = action.payload;
        draftState.requests.getBettingSlipSystemDetail[bettingSlipId] =
          generateLoadingRequestState();

        draftState.details[bettingSlipId] = null;
        break;
      }
      case getType(actions.getBettingSlipSystemDetail.success): {
        const { bettingSlipId } = action.payload;

        produceSuccessState(
          draftState.requests.getBettingSlipSystemDetail[bettingSlipId],
        );
        draftState.details[bettingSlipId] = action.payload || null;

        break;
      }
      case getType(actions.getBettingSlipSystemDetail.failure): {
        const { bettingSlipId } = action.payload;
        const { payload } = action;
        produceFailureState(
          draftState.requests.getBettingSlipSystemDetail[bettingSlipId],
          payload,
        );
        draftState.details[bettingSlipId] = null;
        break;
      }
      /* catch history after bettingslip placebet success */
      case getType(launchPlaceBet.success): {
        const historyId =
          action.payload.TicketSummaryId || action.payload.Ticket?.Id;

        if (!historyId) {
          break;
        }
        draftState.fromBettingSlip[historyId] = action.payload.Ticket || null;
        break;
      }

      case getType(cashoutActions.closeHistoryTicket): {
        const { historyId } = action.payload;

        if (draftState.list.type === 'open') {
          if (!draftState.list.histories) {
            return;
          }

          const filteredHistory =
            draftState.list.histories.BettingHistory?.filter(
              (history) => history.Id !== historyId,
            );
          draftState.list.histories.BettingHistory = filteredHistory;
          draftState.numberOfBetsOpened = filteredHistory?.length;
        }
        break;
      }

      default: // Immer will automatically return the state
    }
  });
