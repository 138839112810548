import { ApplicationWithUserState } from '../../store/types';

export const getLegalDepositLimitRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.legalDepositLimit.requests.getLegalDepositLimit;

export const setLegalDepositLimitRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.legalDepositLimit.requests.setLegalDepositLimit;

export const legalDepositLimitSelector = (state: ApplicationWithUserState) =>
  state.user.legalDepositLimit.legalDepositLimit;
