import { lockedFreebetCodec } from './codecs';
import { FreebetType, LockedFreebetType } from './types';

export const isLockedFreebet = (
  fb: FreebetType | LockedFreebetType | undefined,
) => lockedFreebetCodec.is(fb);

export const getConditionId = (
  fb: FreebetType | LockedFreebetType | undefined,
): string | undefined =>
  fb && ('ConditionId' in fb ? fb.ConditionId : undefined);
