import React, { FC, useMemo } from 'react';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { Box } from '@gaming1/g1-ui';

import { HEADER_HEIGHT } from '../../../styles';
import { FOOTER_LEGAL_AGE_ICON_SIZE } from '../Footer/Header';
import {
  FOOTER_HELP_CONTAINER_MARGIN_TOP,
  FOOTER_LEGAL_AGE_CONTAINER_MARGIN_TOP,
  FOOTER_WRAPPER_PADDING_TOP_BOTTOM,
} from '../Footer/styles';

export type ContentContainerProps = {
  /** Height of the logo in the footer (used to compute min height) */
  footerLogoHeight: number;
};

/**
 * This component will compute the minimum height of the content for the
 * nav drawer. By default, the minimum height will be equal to the
 * viewport's height, in the other case for react-native, some constants will
 * be subtracted to make a part of the footer visible.
 */
export const ContentContainer: FC<ContentContainerProps> = ({
  children,
  footerLogoHeight,
}) => {
  const isInMobileApp = getWrapperEnv() === 'rn';
  const minHeight = window.innerHeight - HEADER_HEIGHT;

  const minHeightWithFooterVisible = useMemo(
    () =>
      minHeight -
      FOOTER_WRAPPER_PADDING_TOP_BOTTOM -
      (footerLogoHeight || 0) -
      FOOTER_LEGAL_AGE_CONTAINER_MARGIN_TOP -
      FOOTER_LEGAL_AGE_ICON_SIZE -
      FOOTER_HELP_CONTAINER_MARGIN_TOP,
    [footerLogoHeight, minHeight],
  );

  return (
    <Box minHeight={isInMobileApp ? minHeightWithFooterVisible : minHeight}>
      {children}
    </Box>
  );
};
