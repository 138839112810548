import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import { appInitializedSelector, useNetworkState } from '@gaming1/g1-core';
import { EWebSocketStatus } from '@gaming1/g1-network';
import { useToast } from '@gaming1/g1-ui';

import { NetworkConnectionLostToast } from './components/NetworkConnectionLostToast';

/** This will prevent the toast to be shown if the network is quickly restored */
export const DELAY_BEFORE_SHOWING_OFFLINE_TOAST_IN_MS = 1500;

const NETWORK_TOAST_ID = 'network_issue_toast';

/**
 * Hook that displays a toast when user lost connection to the websocket
 */
export const useNetworkConnectionLostToast = () => {
  const { addToast, closeToast } = useToast();
  const { network } = useConfig();

  const wsStatus = useNetworkState();

  const appInitialized = useSelector(appInitializedSelector);

  useEffect(() => {
    // Only trigger the toast if the web socket is supposed to be connected
    if (
      appInitialized &&
      wsStatus !== EWebSocketStatus.ready &&
      network.wsUrl
    ) {
      addToast({
        delayInMs: DELAY_BEFORE_SHOWING_OFFLINE_TOAST_IN_MS,
        id: NETWORK_TOAST_ID,
        render: () => <NetworkConnectionLostToast />,
        variant: 'danger',
      });
    } else {
      closeToast(NETWORK_TOAST_ID);
    }
  }, [addToast, appInitialized, closeToast, wsStatus, network.wsUrl]);
};
