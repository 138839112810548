import { TIME_SEPARATOR } from './constants';

/**
 * Takes a number of seconds and returns a formatted time 'mm:ss'.
 * With the withHours parameter, the function will process hours 'hh:mm:ss'.
 */
export const getFormattedTime = (seconds: number, withHours?: boolean) => {
  const { floor } = Math;
  const hrs = floor(seconds / 3600);
  const mins = withHours ? floor((seconds % 3600) / 60) : floor(seconds / 60);
  const secs = floor(seconds % 60);

  return (withHours ? [hrs, mins, secs] : [mins, secs])
    .map((value) => (value > 0 ? value : 0))
    .map((value) => `${value < 10 ? `0${value}` : value}`)
    .join(TIME_SEPARATOR);
};
