import isEqual from 'lodash/isEqual';
import React, { FC, useState } from 'react';

import {
  bettingHistoryByIdGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';

import { CashoutSection } from '../CashoutSection';
import { HistoryTableRowProps } from '../types';

import { ExpandedExtraInfo } from './ExpandedExtraInfo';
import { ExpandedExtraInfoV2 } from './ExpandedExtraInfo/V2';
import { HistoryCardContent } from './HistoryCardParts/HistoryCardContent';
import { HistoryCardFooter } from './HistoryCardParts/HistoryCardFooter';
import { HistoryCardHeader } from './HistoryCardParts/HistoryCardHeader';
import { HistoryCardHeaderV2 } from './HistoryCardParts/V2/HistoryCardHeaderV2';
import { HistoryCardContainer, HistoryCardWrapper } from './styles';

/** Render a row that contains a bet state, a date, the bettingSlip type, an odd, a stake and some winnings.
 *  It also could be expanded to show the outcomes of the bet (the details) but for a smaller layout.
 */
export const HistoryCard: FC<HistoryTableRowProps> = ({
  activeTab,
  betId,
  openSystemDetails,
  hasMobileView = false,
}) => {
  const config = useConfig();

  // This config is used to make the change "smoothly" from the old design of history
  // to the new design.
  // TODO : Remove the use of this config and change old components by new one
  //        when all the partners are up to date.
  const isRevampActivated = config.betting.uniformizationGiftsDesign;

  const historyTicket = useBettingGetterSelector({
    getterSelector: bettingHistoryByIdGetterSelector,
    args: [betId],
    equalityFn: isEqual,
  });

  const [isExpanded, setIsExpanded] = useState(false);

  if (!historyTicket) {
    return null;
  }

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const date =
    activeTab === 'close' && historyTicket.ClosedDate
      ? historyTicket.ClosedDate
      : historyTicket.CreatedDate;

  return (
    <HistoryCardContainer data-testid="history-card-container">
      <HistoryCardWrapper>
        {isRevampActivated ? (
          <HistoryCardHeaderV2
            activeTab={activeTab}
            date={date}
            historyTicket={historyTicket}
            onClick={handleExpand}
          />
        ) : (
          <HistoryCardHeader
            activeTab={activeTab}
            date={date}
            historyTicket={historyTicket}
            onClick={handleExpand}
          />
        )}
        <HistoryCardContent
          historyTicket={historyTicket}
          isExpanded={isExpanded}
          onClick={handleExpand}
        />

        {isRevampActivated ? (
          <ExpandedExtraInfoV2
            data-testid="expanded-extra-info"
            betId={betId}
            isExpanded={isExpanded}
            activeTab={activeTab}
            history={historyTicket}
            openSystemDetails={openSystemDetails}
          />
        ) : (
          <ExpandedExtraInfo
            data-testid="expanded-extra-info"
            betId={betId}
            isExpanded={isExpanded}
            activeTab={activeTab}
            history={historyTicket}
            openSystemDetails={openSystemDetails}
          />
        )}
      </HistoryCardWrapper>
      <HistoryCardFooter
        activeTab={activeTab}
        hasMobileView={hasMobileView}
        historyTicket={historyTicket}
        onClick={handleExpand}
      />
      <CashoutSection
        activeTab={activeTab}
        betId={betId}
        historyTicket={historyTicket}
      />
    </HistoryCardContainer>
  );
};
