import React, { FC } from 'react';

import { bettingRoutes, OddsUnits } from '@gaming1/g1-betting-web';
import { cmsRoutes } from '@gaming1/g1-cms-web';
import {
  DrawerNav,
  FOOTER_LOGO_HEIGHT,
  MenuGroups,
  ResponsiveImage,
  useRoutePath,
  useZendeskUrl,
} from '@gaming1/g1-core-web';
import { gamingRoutes } from '@gaming1/g1-gaming-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { loyaltyRoutes } from '@gaming1/g1-loyalty-web';
import { userRoutes } from '@gaming1/g1-user-web';

import bago from '../../assets/core/footer/legal/bago.svg';
import commission from '../../assets/core/footer/legal/commission.svg';
import playsafe from '../../assets/core/footer/legal/playsafe.svg';

import { AppFooter } from './AppFooter';

export const AppDrawerNav: FC = () => {
  const { t } = useTranslation('core');
  const getBettingPath = useRoutePath(bettingRoutes);
  const getCmsPath = useRoutePath(cmsRoutes);
  const getGamingPath = useRoutePath(gamingRoutes);
  const getLoyaltyPath = useRoutePath(loyaltyRoutes);
  const getUserPath = useRoutePath(userRoutes);
  const zendeskUrl = useZendeskUrl();

  const mobileMenuItems: MenuGroups = [
    [
      {
        label: 'menu.casino',
        path: getGamingPath('gameListCasino'),
        testId: 'drawernav-casino',
        icon: 'Slot',
      },
      {
        label: 'menu.dices',
        path: getGamingPath('gameListDice'),
        testId: 'drawernav-dices',
        icon: 'Dices',
      },
      {
        label: 'menu.tournaments',
        path: getGamingPath('tournaments'),
        testId: 'drawernav-tournaments',
        icon: 'Tournaments',
      },
      {
        label: 'menu.sport',
        path: getBettingPath('bettingHome'),
        testId: 'drawernav-betting',
        icon: 'Soccer',
      },
    ],
    [
      {
        label: 'menu.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'drawernav-promotions',
        icon: 'Discount',
      },
      {
        label: 'menu.shop',
        path: getLoyaltyPath('shop'),
        testId: 'drawernav-shop',
        icon: 'Tag',
      },
      {
        label: 'menu.about',
        path: getCmsPath('aboutUs', { content: '' }),
        testId: 'topbar-about',
        icon: 'Brandtag',
      },
      {
        label: 'menu.help',
        path: zendeskUrl,
        externalLink: true,
        testId: 'topbar-help',
        icon: 'CircleQuestion',
      },
    ],
  ];

  const mobileMenuLinks: MenuGroups = [
    [
      {
        label: 'drawerNav.link.responsibleGaming',
        path: getUserPath('accountResponsibleGaming'),
        testId: 'drawernav-responsibleaming',
      },
      {
        label: 'drawerNav.link.limits',
        path: getUserPath('accountDepositLimits'),
        testId: 'drawernav-limits',
      },
    ],
    [
      {
        label: 'drawerNav.link.terms',
        path: '/terms',
        testId: 'drawernav-terms',
      },
      {
        label: 'drawerNav.link.privacy',
        path: '/privacy',
        testId: 'drawernav-privacy',
      },
    ],
  ];

  return (
    <DrawerNav>
      <DrawerNav.ContentContainer footerLogoHeight={FOOTER_LOGO_HEIGHT}>
        <DrawerNav.Menu menu={mobileMenuItems} />
        <DrawerNav.LocaleList />
        <OddsUnits type="radio" />
        <DrawerNav.Links links={mobileMenuLinks} />
        <DrawerNav.Footer>
          <ResponsiveImage src={playsafe} alt={t('alt.playsafe')} height="20" />
          <ResponsiveImage src={bago} alt={t('alt.bago')} height="20" />
          <ResponsiveImage
            src={commission}
            alt={t('alt.commission')}
            height="20"
          />
        </DrawerNav.Footer>
      </DrawerNav.ContentContainer>
      <AppFooter />
    </DrawerNav>
  );
};
