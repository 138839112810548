/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { HomeModuleOrderRequest, HomeModuleOrderResponse } from '../entities';

export const getBettingHomeModuleOrder = (options: HomeModuleOrderRequest) =>
  createRequest<HomeModuleOrderResponse>(
    201,
    'GetBettingHomeModuleOrder',
    options,
  );
