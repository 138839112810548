/* istanbul ignore file */

import { combineReducers, Reducer } from 'redux';

import { clubReducer } from './club/reducer';
import { commonReducer } from './common/reducer';
import { historyReducer } from './history/reducer';
import { searchReducer } from './search/reducer';
import { shopReducer } from './shop/reducer';
import { LoyaltyActions, LoyaltyState } from './types';

const globalReducer: Reducer<LoyaltyState, LoyaltyActions> = combineReducers({
  club: clubReducer,
  common: commonReducer,
  history: historyReducer,
  search: searchReducer,
  shop: shopReducer,
});

export const loyaltyReducer: Reducer<LoyaltyState, LoyaltyActions> = (
  state: LoyaltyState | undefined,
  action: LoyaltyActions,
) => globalReducer(state, action);
