import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { BettingSearchResult } from '@gaming1/g1-betting-web';
import { currentSectionSelector } from '@gaming1/g1-core';
import { SearchDrawer } from '@gaming1/g1-core-web';
import { GamingSearchResult } from '@gaming1/g1-gaming-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { RemoteData, RequestState } from '@gaming1/g1-utils';

export const AppSearchDrawer: FC = () => {
  const { t } = useTranslation('betting');

  /**
   * To display the failure term in the "SearchNoResult" component, we have to get the failure term
   * from the components that would display the results if there are some.
   *
   * We can't use only one "failureTerm" state or we would have some concurrency problem with the "setFailureTerm"
   * and the value that is given to the state. One component would set it to null, the other with a string.
   *
   * Even if we use only the gamingFailureTerm to display the failure term, we still need to know if gaming AND betting
   * does not provide any result to show the "SearchNoResult" component.
   *
   * We can't use the selector "userSearchTermSelector" because the failure term would be updated and display the wrong failure term,
   * it could also display a term that would give some results as a failure term for a short time before showing the results.
   */
  const [gamingFailureTerm, setGamingFailureTerm] = useState<string | null>(
    null,
  );

  const [bettingFailureTerm, setBettingFailureTerm] = useState<string | null>(
    null,
  );

  const [gamingRequestFailureState, setGamingRequestFailureState] =
    useState<RequestState | null>(null);

  const [bettingRequestFailureState, setBettingRequestFailureState] =
    useState<RequestState | null>(null);

  /**
   * Checks if there is a failed request. This is useful to ensure that if one request returns nothing
   * but is successful, the other requests failing will not prevent the no result component to be displayed.
   * If every request is successful but has no result, it should also display the no result component.
   */
  const failureTerm =
    bettingRequestFailureState?.status === RemoteData.Error ||
    gamingRequestFailureState?.status === RemoteData.Error ||
    (gamingFailureTerm?.length && bettingFailureTerm?.length)
      ? gamingFailureTerm || bettingFailureTerm
      : null;

  /**
   * If the user is searching when being in the "gaming" section,
   * it'll be the gaming search results that will be displayed in priority,
   * then betting, then shop.
   *
   * If the user is searching in the homepage (as an example), the fallback order
   * is used.
   *
   * The fallback order is : gaming > betting > shop
   */
  const currentSection = useSelector(currentSectionSelector);

  const createResultDisplay = () => {
    const casinoCategory = (
      <GamingSearchResult
        key="gamingResult"
        onTermFailure={setGamingFailureTerm}
        onRequestFailure={setGamingRequestFailureState}
      />
    );
    const bettingCategory = (
      <BettingSearchResult
        key="bettingResult"
        onTermFailure={setBettingFailureTerm}
        onRequestFailure={setBettingRequestFailureState}
      />
    );

    // TODO : Complete this when the shop RH and slice in the store are done
    // See issue : V4-1214
    const shopCategory = null;

    switch (currentSection) {
      case 'betting':
        return (
          <>
            {bettingCategory}
            {casinoCategory}
            {shopCategory}
          </>
        );
      case 'shop':
        return (
          <>
            {shopCategory}
            {casinoCategory}
            {bettingCategory}
          </>
        );
      case 'default':
      case 'gaming':
      default:
        return (
          <>
            {casinoCategory}
            {bettingCategory}
            {shopCategory}
          </>
        );
    }
  };

  return (
    <SearchDrawer
      hideEmptyRecentSearches={currentSection === 'betting'}
      noResultTerm={failureTerm || null}
      inputPlaceHolder={
        currentSection === 'betting' ? t('search.placeholder') : null
      }
    >
      {createResultDisplay()}
    </SearchDrawer>
  );
};
