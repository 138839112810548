/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  PlayerAcceptPrivacyConditionsRequest,
  PlayerAcceptPrivacyConditionsResponse,
  PrivacyConditionsRequest,
  PrivacyConditionsResponse,
  ShowPrivacyConditionsRequest,
  ShowPrivacyConditionsResponse,
} from '../entities';

export const getPrivacyConditions = (options: PrivacyConditionsRequest) =>
  createRequest<PrivacyConditionsResponse>(
    100,
    'GetPrivacyConditions',
    options,
  );

export const showPrivacyConditions = (options: ShowPrivacyConditionsRequest) =>
  createRequest<ShowPrivacyConditionsResponse>(
    100,
    'ShowPrivacyConditions',
    options,
  );

export const playerAcceptPrivacyConditions = (
  options: PlayerAcceptPrivacyConditionsRequest,
) =>
  createRequest<PlayerAcceptPrivacyConditionsResponse>(
    100,
    'PlayerAcceptPrivacyConditions',
    options,
  );
