import { ShopResponseCode } from '@gaming1/g1-requests';

export const shopErrorMessages = {
  [ShopResponseCode.ServerError]: 'core:error.internalServer', // 0
  [ShopResponseCode.CannotAfford]: 'loyalty:shop.error.cannotAfford', // 5
  [ShopResponseCode.OutOfStockRefunded]: 'loyalty:shop.error.outOfStock', // 12
  [ShopResponseCode.InvalidAddress]: 'loyalty:shop.error.invalidAddress', // 13
  [ShopResponseCode.InvalidZipCode]: 'loyalty:shop.error.invalidZipCode', // 14
  [ShopResponseCode.InvalidCity]: 'loyalty:shop.error.invalidCity', // 15
  [ShopResponseCode.InvalidCountryIsoCode]: 'loyalty:shop.error.invalidCountry', // 16
  [ShopResponseCode.InvalidMunicipality]:
    'loyalty:shop.error.invalidMunicipality', // 17
  [ShopResponseCode.InvalidDepartment]: 'loyalty:shop.error.invalidDepartment', // 18
  [ShopResponseCode.PlayerRefuseBonus]: 'loyalty:shop.error.playerRefuseBonus', // 20
  [ShopResponseCode.PlayerBonusNotAllowed]:
    'loyalty:shop.error.playerBonusNotAllowed', // 21
  [ShopResponseCode.PlayerLimitedBetting]:
    'loyalty:shop.error.playerLimitedBetting', // 22
};
