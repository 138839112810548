import React, { ReactNode, VFC } from 'react';

import { Box, Icon, IconType, Text } from '@gaming1/g1-ui';

import { HighlightedText } from '../HighlightedText';

import {
  EllipsisBox,
  SearchResultImageWrapper,
  SearchResultWrapper,
} from './styles';

export type SearchLayout = 'normal' | 'grid';

type SearchResultDisplayProps = {
  /** The component that will be used as a description */
  description?: ReactNode;
  /** The component image to display near the text */
  imageComponent?: ReactNode;
  /** The matching term to highlight the title's result */
  matchingTerms?: string[];
  /** The onClick method to use when the SearchResultDisplay is clicked on to change the searchBar value to the term clicked on */
  onClick?: (term: string) => void;
  /** The result's title to display */
  title: string;
  icon?: IconType;
  /** Layout used. Normal layout is one column, grid up to three with darker style */
  layout?: SearchLayout;
};

/** Render the result's title, description and will highlight the matching part of the result's title  */
export const SearchResultDisplay: VFC<SearchResultDisplayProps> = ({
  description,
  imageComponent,
  icon,
  matchingTerms,
  title,
  onClick,
  layout = 'normal',
}) => (
  <SearchResultWrapper
    alignItems="center"
    flexDirection="row"
    data-testid="search-result-display-wrapper"
    onClick={onClick ? () => onClick(title) : undefined}
    isNormalLayout={layout === 'normal'}
  >
    {imageComponent ? (
      <SearchResultImageWrapper data-testid="search-result-image">
        {imageComponent}
      </SearchResultImageWrapper>
    ) : null}

    {icon ? (
      <Box mr="sm">
        <Icon id="icon-search-result" type={icon} />
      </Box>
    ) : null}
    <Box
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      minWidth={0}
    >
      <EllipsisBox fontSize={layout === 'normal' ? 'lg' : 'md'}>
        {matchingTerms?.length ? (
          <HighlightedText ellipsis searchTerms={matchingTerms} text={title} />
        ) : (
          <Text ellipsis mt={0}>
            {title}
          </Text>
        )}
        {description || null}
      </EllipsisBox>
    </Box>
    <Icon
      type={layout === 'normal' ? 'ArrowRight' : 'AngleRight'}
      id="search-result-right"
      width={12}
      height={12}
    />
  </SearchResultWrapper>
);
