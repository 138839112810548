import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';

import { Box } from '../Box';
import { SimpleButton } from '../styles';

import { MessageSize, MessageType } from './types';

export const CLOSE_BUTTON_SIZE_IN_PX = 30;
export const CLOSE_ICON_SIZE_IN_PX = 10;
export const MESSAGE_LINK_HOVER_DARKENING = 0.15;
export const NORMAL_ICON_SIZE_IN_PX = 16;
export const SMALL_ICON_SIZE_IN_PX = 13;

export const MessageWrapper = styled(Box)<{
  type: MessageType;
}>`
  ${fontWeight('regular')};
  border-radius: ${spaces('xxs')};
  color: ${colors('textLight')};
  flex-direction: row;
  flex-grow: 1;
  font-size: ${fontSizes('md')};
  padding: ${spaces('sm')};
  position: relative;

  a {
    color: ${colors('textLight')};
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: ${colors('textLight', {
        transform: darken(MESSAGE_LINK_HOVER_DARKENING),
      })};
    }
  }

  ${({ type }) =>
    type === 'success' &&
    css`
      background-color: ${colors('success')};
    `}

  ${({ type }) =>
    type === 'warning' &&
    css`
      background-color: ${colors('warning')};
    `}

  ${({ type }) =>
    type === 'danger' &&
    css`
      background-color: ${colors('danger')};
    `}

  ${({ type }) =>
    type === 'info' &&
    css`
      background-color: ${colors('info')};
    `}

  ${space}
`;

export const MessageIconWrapper = styled(Box)<{ size: MessageSize }>`
  svg {
    path {
      fill: ${colors('textLight')};
    }
  }

  ${({ size }) =>
    size === 'small'
      ? css`
          margin-right: ${spaces('xs')};
          margin-top: ${spaces('xxxs')};
          min-width: ${SMALL_ICON_SIZE_IN_PX}px;
        `
      : css`
          margin-right: ${spaces('sm')};
          min-width: ${NORMAL_ICON_SIZE_IN_PX}px;
        `}
`;

export const MessageContentWrapper = styled.div<{ size: MessageSize }>`
  ${({ size }) =>
    size === 'small'
      ? css`
          width: calc(
            100% - ${SMALL_ICON_SIZE_IN_PX + CLOSE_BUTTON_SIZE_IN_PX}px
          );
        `
      : css`
          width: calc(
            100% - ${NORMAL_ICON_SIZE_IN_PX + CLOSE_BUTTON_SIZE_IN_PX}px
          );
        `}
`;

export const MessageCloseButton = styled(SimpleButton)`
  height: ${CLOSE_BUTTON_SIZE_IN_PX}px;
  max-height: 100%;
  min-width: ${CLOSE_BUTTON_SIZE_IN_PX}px;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    path {
      fill: ${colors('textLight')};
    }
  }
`;
