import React, { FC, memo, useContext } from 'react';

import {
  actions,
  getHasFrontNotificationError,
  useBettingSlipAllowActions,
  useBettingSlipSingleErrorFrontType,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { FastBettingSlipContext } from '../../../layout/components/FastBettingSlipProvider/FastBettingSlipContext';
import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

type BettingSlipSingleErrorProps = {
  stake?: string;
};

const BettingSlipSingleErrorComponent: FC<BettingSlipSingleErrorProps> = ({
  stake,
}) => {
  const { bettingSlipId, isInputStakeFocused } = useContext(BettingSlipContext);
  // This component could be used into a FastBettingSlip, if it's the case,
  // we need to get the type of the fastBs to know how to manage some errors,
  // as for an example, we lock the fastBs if the user hasn't deposit yet
  // ONLY when the type would be a SuperOdds (EManualBetType.SuperOdds).
  const { type } = useContext(FastBettingSlipContext);

  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const errorNotification = useBettingSlipSingleErrorFrontType(
    bettingSlipId,
    type,
  )();
  const testId = `bettingslip-error-stake-single-${errorNotification.Status}`;

  const hasFrontNotificationError =
    getHasFrontNotificationError(errorNotification);

  const updateStake = (newStake: string) => {
    allowBSActions(
      actions.updateStake({
        stake: newStake,
        bettingSlipId,
      }),
    );
  };

  const callToActionsFunc = {
    updateStake,
  };

  const shouldDisplayError = !(!stake && isInputStakeFocused);

  return shouldDisplayError && hasFrontNotificationError ? (
    <Box
      width="100%"
      data-testid="bettingslip-single-error-message-container"
      mb="sm"
    >
      <BettingSlipMessage
        testId={testId}
        notification={errorNotification}
        callToActionsFunc={callToActionsFunc}
      />
    </Box>
  ) : null;
};

export const BettingSlipSingleError = memo(BettingSlipSingleErrorComponent);
