import styled from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { Collapsible } from '@gaming1/g1-ui';

export const WrappedPre = styled.pre`
  white-space: pre-wrap;
`;

export const DebugCollapsible = styled(Collapsible)`
  margin-top: ${spaces('sm')};
  padding: ${spaces('xxs')};
`;
