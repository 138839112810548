/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { GetCreditRequestDTO, GetCreditResponseDTO } from '../entities';

export const getPlayerCredit = (options: GetCreditRequestDTO) =>
  createRequest<GetCreditResponseDTO>(100, 'GetPlayerCredit', options);
