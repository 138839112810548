import { LocaleCode, LocaleCodeEnum } from '@gaming1/g1-config';

/**
 * Removes trailing slash and regroups multiple characters ('/';'#';'?')
 */
export const trimUrl = (url: string) =>
  url
    .replace(/\/+/g, '/')
    .replace(/#+/g, '#')
    .replace(/\?+/g, '?')
    .replace(/(?!^\/)\/$/, '');

/**
 * Generates a regex to match the locale in the URL
 */
export const getLocaleRegex = (locale: string) =>
  new RegExp(`^((/${locale}/)|(${locale}/)|(/${locale}(?=(#|\\?+)|$)))`);

/**
 * Check if the URL has a locale and extract it to use it later
 * @param url the current URL
 */
export const extractLocaleFromUrl = (
  url: string,
): LocaleCodeEnum | undefined => {
  const key = Object.keys(LocaleCodeEnum).find((locale) =>
    url.match(getLocaleRegex(locale)),
  ) as LocaleCode;
  return key ? LocaleCodeEnum[key] : undefined;
};

/**
 * Remove the given locale from the URL
 */
export const removeLocaleFromUrl = (url: string) => {
  const locale = extractLocaleFromUrl(url);
  return locale ? url.replace(getLocaleRegex(locale), '') : url;
};
