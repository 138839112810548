import React, { FC, memo } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';

import { useMenuLists } from '../../../common/components/BettingMenu/hooks';
import { MenuItem } from '../../../common/components/MenuItem';

import { MenuTitle } from './MenuTitle';
import { MenuIcon } from './styles';

type ServicesMenuProps = {
  isCollapsed?: boolean;
  onItemClick: (id: number | string) => void;
  activeId: string | number;
};

const ServicesMenuComponent: FC<ServicesMenuProps> = ({
  isCollapsed = false,
  onItemClick,
  activeId,
}) => {
  const { t } = useTranslation('betting');

  const { servicesMenuList } = useMenuLists();

  return servicesMenuList.length > 0 ? (
    <>
      <MenuTitle isCollapsed={isCollapsed}>
        {t('lateralMenu.services')}
      </MenuTitle>
      {servicesMenuList.map(({ icon, label, testId, ...props }) => {
        const menuItemContent = !isCollapsed ? (
          <>
            {icon ? (
              <MenuItem.Left isActive={activeId === label}>
                {typeof icon === 'string' ? (
                  <MenuIcon type={icon} id={`icon-${label}`} />
                ) : (
                  icon
                )}
              </MenuItem.Left>
            ) : null}
            <MenuItem.Content>{label}</MenuItem.Content>
          </>
        ) : (
          <MenuItem.Content justifyContent="center" alignItems="center">
            {!!icon && typeof icon === 'string' ? (
              <MenuIcon type={icon} id={`icon-${label}`} />
            ) : null}
          </MenuItem.Content>
        );

        return 'onClick' in props ? (
          <MenuItem
            key={`${testId}${label}`}
            elevationLevel={2}
            hasNoShadow
            onClick={() => onItemClick(label)}
            mb="1px"
          >
            <MenuItem.Button onClick={props.onClick}>
              {menuItemContent}
            </MenuItem.Button>
          </MenuItem>
        ) : (
          <MenuItem
            key={`${testId}${label}`}
            elevationLevel={2}
            hasNoShadow
            onClick={() => onItemClick(testId)}
            mb="1px"
          >
            <MenuItem.Link to={props.path}>{menuItemContent}</MenuItem.Link>
          </MenuItem>
        );
      })}
    </>
  ) : null;
};

export const ServicesMenu = memo(ServicesMenuComponent);
