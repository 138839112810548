import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { shouldShowLegalModalSelector } from '@gaming1/g1-user';
import { LegalModalContext } from '@gaming1/g1-user-api';

export const LegalModalProvider: FC = ({ children }) => {
  const shouldShowLegalModal = useSelector(shouldShowLegalModalSelector);
  const contextValue = useMemo(
    () => ({
      shouldShowLegalModal,
    }),
    [shouldShowLegalModal],
  );
  return (
    <LegalModalContext.Provider value={contextValue}>
      {children}
    </LegalModalContext.Provider>
  );
};
