import { EMPTY, of, timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  scan,
  switchMap,
  takeUntil,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getPlayerCredit, getPlayerProfile } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard, RemoteData } from '@gaming1/g1-utils';

import * as actions from '../actions';
import { THROTTLE_TIME_IN_MS } from '../common/constants';
import { logger } from '../logger';
import { CoreEpic } from '../types';

import { userCredit, userProfile } from './codecs';
import {
  USER_CREDIT_MANUAL_POLLING_AMOUNT,
  USER_CREDIT_MANUAL_POLLING_INTERVAL_IN_MS,
  USER_CREDIT_REGULAR_POLLING_INTERVAL_IN_MS,
} from './constants';
import {
  getUserCreditErrorMessages,
  getUserProfileErrorMessages,
} from './errorMessages';
import {
  getUserProfileStatusSelector,
  userCreditStatusSelector,
  userLoggedInSelector,
} from './selectors';

/** Get user profil  */
export const getUserProfileEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getUserProfile.request)),
    switchMap(() =>
      wsAdapter.request(getPlayerProfile({ IncludePlayerDetails: true })).pipe(
        mapGuard(userProfile),
        map(actions.getUserProfile.success),
        catchError((err) =>
          of(
            actions.getUserProfile.failure(
              createFailurePayload(err, getUserProfileErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get user credit  */
export const getUserCreditEpic: CoreEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getUserCredit.request)),
    switchMap(({ payload }) =>
      wsAdapter
        .request(
          getPlayerCredit({
            ForceRefresh: payload.forceRefresh,
            IncludeInGameAmount: true,
            UserAction: !!payload.isTriggeredByUser,
          }),
        )
        .pipe(
          mapGuard(userCredit),
          map(actions.getUserCredit.success),
          catchError((err) =>
            of(
              actions.getUserCredit.failure(
                createFailurePayload(err, getUserCreditErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

/** Refresh the user credit manually (throttled) */
export const clickUserRefreshCreditToGetUserCreditEpic: CoreEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.clickRefreshCredit)),
    throttleTime(THROTTLE_TIME_IN_MS),
    map(() =>
      actions.getUserCredit.request({
        forceRefresh: true,
        isTriggeredByUser: true,
      }),
    ),
  );

/** Updates the user credit when a credit push is received */
export const userCreditPushEpic: CoreEpic = (_, __, { wsAdapter }) =>
  wsAdapter.getPushRequests$('GetPlayerCredit').pipe(
    mapGuard(userCredit),
    map(actions.updateUserCredit),
    catchError((err) => {
      logger.error('Error while updating credits', err);
      return EMPTY;
    }),
  );

/** Updates the user credit when a credit injected package is received */
export const userCreditInjectedEpic: CoreEpic = (_, __, { wsAdapter }) =>
  wsAdapter.getInjectedRequests$('GetPlayerCredit').pipe(
    mapGuard(userCredit),
    map(actions.updateUserCredit),
    catchError((err) => {
      logger.error('Error while updating credits', err);
      return EMPTY;
    }),
  );

/**
 * Poll the user credit while the user is connected
 * Also handles "manual" polling when receiving startPollingUserCredit, the
 * polling interval will be much shorter for a couple of times, then will go
 * back to normal
 * */
export const userCreditPollingEpic: CoreEpic = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.loggedInUser,
        actions.startPollingUserCredit,
        actions.stopUserCreditIntensePolling,
      ]),
    ),
    switchMap((action) => {
      const isManual = isActionOf(actions.startPollingUserCredit)(action);
      const pollingTime = isManual
        ? USER_CREDIT_MANUAL_POLLING_INTERVAL_IN_MS
        : USER_CREDIT_REGULAR_POLLING_INTERVAL_IN_MS;
      return timer(isManual ? 1 : pollingTime, pollingTime).pipe(
        scan((acc) => acc + 1, 0),
        map((count) => ({
          manual: isManual,
          stopManualPolling:
            isManual && count > USER_CREDIT_MANUAL_POLLING_AMOUNT,
        })),
        takeUntil(action$.pipe(filter(isActionOf(actions.loggedOutUser)))),
      );
    }),
    withLatestFrom(state$),
    /**
     * Ensure the user is logged in. Should be useless because the takeUntil
     * should handle that case but better be safe than sorry
     */
    filter(([, state]) => !!userLoggedInSelector(state)),
    /**
     * Ensure the request is not sent if it was already asked shortly before and
     * is still loading
     */
    filter(
      ([, state]) => userCreditStatusSelector(state) !== RemoteData.Loading,
    ),
    map(([{ stopManualPolling, manual }]) =>
      stopManualPolling
        ? actions.stopUserCreditIntensePolling()
        : actions.getUserCredit.request({ forceRefresh: manual }),
    ),
  );

/** After a successful authentication, we get the user profil */
export const successAuthToUserProfileEpic: CoreEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loggedInUser)),
    withLatestFrom(state$),
    /**
     * Make sure the user profile was not already asked
     * in order to avoid a getProfile on each WS reconnection
     */
    filter(
      ([, state]) =>
        getUserProfileStatusSelector(state) === RemoteData.NotAsked,
    ),
    mapTo(actions.getUserProfile.request()),
  );

/** After a successful authentication, we get the user credit */
export const successAuthToUserCreditEpic: CoreEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.loggedInUser)),
    mapTo(actions.getUserCredit.request({ forceRefresh: true })),
  );
