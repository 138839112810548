import React, { useContext, useEffect, useState, VFC } from 'react';
import { useSelector } from 'react-redux';

import { AppSection, currentSectionSelector } from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { mediaBreakPoints } from '@gaming1/g1-style';
import { DrawerType, LayoutContext, RenderOnMedia } from '@gaming1/g1-ui';
import { useScroll } from '@gaming1/g1-utils';

import { HistoryContext } from '../../../routing/components/HistoryProvider/HistoryContext';
import { useIsFullscreenLayout } from '../../../routing/hooks';
import { desktopLayoutBreakpoint } from '../../constants';
import { MenuList } from '../types';

import { BottomNavItem } from './BottomNavItem';
import { BottomNavItemContent } from './BottomNavItemContent';
import {
  BottomNavButton,
  BottomNavContainer,
  BottomNavLink,
  BottomSpacer,
} from './styles';
import { AnimationType } from './types';

type BottomNavSectionMenus = {
  [section in AppSection]?: MenuList;
} & {
  default: MenuList;
};

type BottomNavProps = {
  /**
   * Hide every menu items of the bottom nav (save the highlighted one) after a
   * hasScrolledDownward scroll. Otherwise hides only the label of the menu items.
   * Default = true
   */
  animationType?: AnimationType;
  /**
   * An object containing a menu for each section. The default section is
   * mandatory
   */
  menus: BottomNavSectionMenus;
};

const breakpointNames = mediaBreakPoints.map(({ name }) => name);
/** Biggest breakpoint in which the nav can be visible */
const maxAllowedBreakpoint =
  breakpointNames[breakpointNames.indexOf(desktopLayoutBreakpoint) - 1];

export const BottomNav: VFC<BottomNavProps> = ({
  animationType = 'hide',
  menus,
}) => {
  const [{ lastY, hasScrolledDownward }, setScrolling] = useState({
    lastY: window.scrollY,
    hasScrolledDownward: false,
  });
  const { y: windowY = window.scrollY } = useScroll({
    debounceDelay: 10,
    triggerWhileScrolling: true,
  });

  useEffect(() => {
    if (lastY < windowY - 10) {
      setScrolling({ lastY: windowY, hasScrolledDownward: true });
    } else if (lastY > windowY + 10) {
      setScrolling({ lastY: windowY, hasScrolledDownward: false });
    }
  }, [hasScrolledDownward, lastY, windowY]);

  const { showDrawer } = useContext(LayoutContext);
  const appSection = useSelector(currentSectionSelector);
  const isFullscreenLayout = useIsFullscreenLayout();
  const { isBottomNavVisibleInFullscreen } = useContext(HistoryContext);

  const menu: MenuList = menus[appSection] || menus.default;

  const animationTypeForEnv = getWrapperEnv() === 'rn' ? 'none' : animationType;

  return isFullscreenLayout && !isBottomNavVisibleInFullscreen ? null : (
    <RenderOnMedia max={maxAllowedBreakpoint}>
      {animationTypeForEnv === 'none' && <BottomSpacer />}
      <BottomNavContainer
        data-testid="bottomnav"
        isMinimized={hasScrolledDownward && animationTypeForEnv === 'minimize'}
        isHidden={hasScrolledDownward && animationTypeForEnv === 'hide'}
      >
        <BottomNavItem
          animationType={animationTypeForEnv}
          key="burgerbuton"
          isMinimized={hasScrolledDownward}
          isHighLighted={false}
          testId="bottomnav-burger"
        >
          <BottomNavButton
            data-testid="bottomnav-burgerbutton"
            onClick={() => showDrawer(DrawerType.mobileMenu)}
          >
            <BottomNavItemContent
              label="menu.menu"
              icon="BurgerMenu"
              isLabelHidden={
                animationTypeForEnv === 'minimize' && hasScrolledDownward
              }
              testId="bottomnav-burgerbutton"
            />
          </BottomNavButton>
        </BottomNavItem>
        {menu
          // Max 6 menu items
          .slice(0, 6)
          .map(
            (
              { icon, highlighted, label, testId, iconText, ...props },
              index,
            ) => {
              // Keep only one highlighted item if there was multiple provided

              const isHighLighted =
                !!highlighted &&
                menu.findIndex((item) => item.highlighted) === index;

              const BottomNavItemContentElement = (
                <BottomNavItemContent
                  label={label}
                  icon={icon}
                  isLabelHidden={
                    animationTypeForEnv === 'minimize' && hasScrolledDownward
                  }
                  iconText={iconText}
                  testId={testId}
                />
              );

              return (
                <BottomNavItem
                  animationType={animationTypeForEnv}
                  key={`${testId}${label}`}
                  isMinimized={hasScrolledDownward}
                  isHighLighted={isHighLighted}
                  testId={testId}
                >
                  {'onClick' in props ? (
                    <BottomNavButton
                      data-testid={`bottomnav-button-${label}`}
                      onClick={props.onClick}
                    >
                      {BottomNavItemContentElement}
                    </BottomNavButton>
                  ) : (
                    <BottomNavLink
                      data-testid={`${testId}-link`}
                      exact={props.exact}
                      isPoppedUp={
                        hasScrolledDownward &&
                        isHighLighted &&
                        animationTypeForEnv === 'hide'
                      }
                      to={props.path}
                    >
                      {BottomNavItemContentElement}
                    </BottomNavLink>
                  )}
                </BottomNavItem>
              );
            },
          )}
      </BottomNavContainer>
    </RenderOnMedia>
  );
};
