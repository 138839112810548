import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardLastPeriodScoreSelector,
  getScoreBoardPassTeamSelector,
  getScoreBoardSportStatsSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import { useScoreBoardCompactTranslations } from './hooks';
import {
  ScoreBoardCompactBackgroundWrapper as BackgroundWrapper,
  ScoreBoardCompactTemplateBullet as TemplateBullet,
  ScoreBoardCompactTemplateColumn as TemplateColumn,
  ScoreBoardCompactTemplateItem as TemplateItem,
  ScoreBoardCompactTemplateItemTitle as TemplateItemTitle,
  ScoreBoardCompactTemplateRow as TemplateRow,
  ScoreBoardCompactTemplateWrapper as TemplateWrapper,
} from './styles';

const BULLET_COLOR = '#fae316';

const parseTennisPasses = (passes: number) => (passes === 50 ? 'A' : passes);

/** Displays the compact scoreboard with a tennis template */
export const ScoreBoardCompactTennisTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
}) => {
  const { team1: pass1, team2: pass2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardPassTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: passes1, team2: passes2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Passes'],
    equalityFn: shallowEqual,
  });
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: lastPeriod1, team2: lastPeriod2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardLastPeriodScoreSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  const translations = useScoreBoardCompactTranslations();

  return (
    <TemplateWrapper data-testid="scoreboard-compact-tennis-template">
      <TemplateRow>
        <TemplateColumn>
          <TemplateItem>
            {pass1 && <TemplateBullet color={BULLET_COLOR} />}
          </TemplateItem>
          <TemplateItem>
            {pass2 && <TemplateBullet color={BULLET_COLOR} />}
          </TemplateItem>
        </TemplateColumn>
        <TemplateColumn>
          <TemplateItemTitle title={translations.points} isMuted>
            {translations.points.charAt(0).toUpperCase()}
          </TemplateItemTitle>
          <BackgroundWrapper backgroundRadius="left">
            <TemplateItem>{parseTennisPasses(passes1)}</TemplateItem>
            <TemplateItem>{parseTennisPasses(passes2)}</TemplateItem>
          </BackgroundWrapper>
        </TemplateColumn>
        <TemplateColumn>
          <TemplateItemTitle title={translations.games} isMuted>
            {translations.games.charAt(0).toUpperCase()}
          </TemplateItemTitle>
          <BackgroundWrapper>
            <TemplateItem>{lastPeriod1}</TemplateItem>
            <TemplateItem>{lastPeriod2}</TemplateItem>
          </BackgroundWrapper>
        </TemplateColumn>
        <TemplateColumn>
          <TemplateItemTitle title={translations.sets} isMuted>
            {translations.sets.charAt(0).toUpperCase()}
          </TemplateItemTitle>
          <BackgroundWrapper backgroundRadius="right">
            <TemplateItem isHighlighted>{score1}</TemplateItem>
            <TemplateItem isHighlighted>{score2}</TemplateItem>
          </BackgroundWrapper>
        </TemplateColumn>
      </TemplateRow>
    </TemplateWrapper>
  );
};
