import React, { FC } from 'react';

import { AppButtonAnchor, Button } from '@gaming1/g1-ui';

import { usePrismicInternalLink } from '../../hooks';
import { logger } from '../../logger';

export type PrismicButtonProps = {
  /** Color of the button */
  colorType?: 'primary' | 'secondary' | 'tertiary';
  /** Link url */
  link?: string;
  /** Link target */
  target?: string;
  /** Test ID */
  testId?: string;
};

/**
 * This component renders a button with internal or external link
 */

export const PrismicButton: FC<PrismicButtonProps> = ({
  children,
  colorType,
  link,
  target,
  testId = 'prismic-button',
}) => {
  const { onClick, path, error } = usePrismicInternalLink(link);
  if (error) {
    logger.error('[Prismic][Link]', error);
  }

  if (onClick) {
    return (
      <Button
        data-testid={testId}
        color={colorType || 'primary'}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }

  if (path) {
    return (
      <AppButtonAnchor
        data-testid={testId}
        colorType={colorType || 'primary'}
        href={path}
        target={target}
        rel="noopener"
      >
        {children}
      </AppButtonAnchor>
    );
  }

  return null;
};
