import { darken, lighten } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { TOP_NAV_LARGE_HEIGHT } from '@gaming1/g1-core-web';
import { EWebSocketStatus } from '@gaming1/g1-network';
import { colors, elevation, media, spaces, zIndex } from '@gaming1/g1-style';
import { Button, Heading, Row, SimpleButton } from '@gaming1/g1-ui';

const LEFT_HANDLE_SIZE = 6;

type DevToolWrapperDivProps = {
  /** Visible or hidden (hidden by default) */
  visible: boolean;
};

export const DevToolContentDiv = styled.div`
  ${elevation(1)};
  padding: ${spaces('xs')};
  overflow: auto;
  max-height: calc(100vh - ${TOP_NAV_LARGE_HEIGHT}px);
`;

export const DevToolWrapperDiv = styled.div<DevToolWrapperDivProps>`
  color: ${colors('text')};
  position: fixed;
  right: 0;
  top: 0;
  max-height: 100vh;
  transform: translateX(100%);
  transition: all 0.3s ease;
  z-index: ${zIndex('devTool')};
  ${({ visible }) =>
    css`
      ${visible &&
      css`
        transform: translateX(0);
      `};
    `}
`;

export const colorAnimation = keyframes`
  0% { background-color: #e74c3c; }
  20% { background-color: #f1c40f; }
  40% { background-color: #1abc9c; }
  60% { background-color: #3498db; }
  80% { background-color: #9b59b6; }
  100% { background-color: #e74c3c; }
`;

export const DevToolTitleH3 = styled(Heading).attrs(() => ({ level: 'h3' }))`
  background-color: ${({ theme }) =>
    theme.dark
      ? lighten(0.1, theme.colors.backgrounds[0])
      : darken(0.1, theme.colors.backgrounds[0])};
  height: ${TOP_NAV_LARGE_HEIGHT}px;
  margin: 0;
  line-height: ${TOP_NAV_LARGE_HEIGHT}px;
  text-align: center;

  ${media.lg} {
    margin: 0;
  }
`;

type DevToolVisibilityButtonProps = {
  animated: boolean;
  visible: boolean;
};

export const DevToolVisibilityButton = styled.button.attrs({
  type: 'button',
})<DevToolVisibilityButtonProps>`
  animation: ${colorAnimation} 10s infinite;
  background-color: #e74c3c;
  border: none;
  cursor: pointer;
  height: ${TOP_NAV_LARGE_HEIGHT}px;
  transform: scaleX(1);
  position: absolute;
  width: ${LEFT_HANDLE_SIZE}px;
  left: -${LEFT_HANDLE_SIZE}px;

  ${media.lg} {
    &:hover {
      ${({ visible }) =>
        !visible &&
        css`
          transform: scaleX(2);
          transition: all 0.3s ease;
        `}
    }
  }

  &:focus {
    outline: none;
  }

  ${({ animated }) =>
    css`
      animation-play-state: ${animated ? 'playing' : 'paused'};
    `}
`;

export const WsStatusDiv = styled.div<{ status: EWebSocketStatus }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-top: ${spaces('xs')};
  &:before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: orange;
    margin-right: ${spaces('xs')};
    ${({ status }) =>
      status === EWebSocketStatus.ready &&
      css`
        background-color: lime;
      `}
    ${({ status }) =>
      status === EWebSocketStatus.disconnected &&
      css`
        background-color: red;
      `}
  }
`;

export const SimpleSubmitButton = styled(SimpleButton)`
  background-color: ${colors('primary')};
  padding: ${spaces('xxs')} ${spaces('xs')};
`;

export const EditableNetworkConfigButtonWrapper = styled(Row)`
  justify-content: space-between;
  gap: ${spaces('xs')};
  margin-top: ${spaces('sm')};
  padding: 0 ${spaces('xs')};
`;

export const EditableNetworkConfigButton = styled(Button)`
  flex: 1;
`;
