import React, { FC } from 'react';

import { PrismicPromotionDetailsModalProp } from '@gaming1/g1-cms-api';
import { Modal } from '@gaming1/g1-ui';

import { PrismicPromotionDetailsModalContent } from './PrismicPromotionDetailsModalContent';

/**
 * Component that display the details of a promotion
 * It loads a 'content_v2' Prismic document having the matching UID and a 'Promotion' tag
 */
export const PrismicPromotionDetailsModal: FC<
  PrismicPromotionDetailsModalProp
> = ({ uid, visible, close }) => {
  // If no UID is specified
  if (!uid) {
    return null;
  }

  return (
    <Modal
      modalId="PrismicPromotionDetailsModal"
      noPadding
      visible={visible}
      onClose={() => {
        close();
      }}
    >
      <PrismicPromotionDetailsModalContent uid={uid} />
    </Modal>
  );
};
