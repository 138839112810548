/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       09:46:16 31-08-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetUnavailableProductsRequestDTO,
  GetUnavailableProductsResponseDTO,
} from '../entities';

export const getUnavailableProducts = (
  options: GetUnavailableProductsRequestDTO,
) =>
  createRequest<GetUnavailableProductsResponseDTO>(
    102,
    'GetUnavailableProducts',
    options,
  );
