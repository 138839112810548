import React, { FC, memo, useContext } from 'react';

import {
  useBettingSlipItemBaseOdds,
  useBettingSlipItemFinalOdds,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { useSetBettingSlipOutcomeOddUpdates } from '../../hooks';

import { IndicatorIconContainer } from './IndicatorIconContainer';
import { OddsContainer } from './OddsContainer';

type BettingSlipOddProps = {
  /** id to find ticket item */
  id: number;
};

export const BettingSlipItemOddsComponent: FC<BettingSlipOddProps> = ({
  id,
}) => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const baseOddsValue = useBettingSlipItemBaseOdds(bettingSlipId)(id);
  const finalOddsValue = useBettingSlipItemFinalOdds(bettingSlipId)(id);

  useSetBettingSlipOutcomeOddUpdates(baseOddsValue, id, bettingSlipId);

  if (!baseOddsValue) {
    return null;
  }

  return (
    <>
      <Box justifyContent="center" alignItems="center" mr="xs">
        <IndicatorIconContainer id={id} />
      </Box>
      <OddsContainer baseOdds={baseOddsValue} finalOdds={finalOddsValue} />
    </>
  );
};

export const BettingSlipItemOdds = memo(BettingSlipItemOddsComponent);
