import React, { FC, memo } from 'react';
import { shallowEqual } from 'react-redux';

import {
  eventNameDetailsSelector,
  eventSelector,
  getMainMarketByEventIdSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { MenuItem } from '../../../common/components/MenuItem';
import { useBettingRoutePath, useCleanedParams } from '../../../hooks';
import { ScoreBoard } from '../../../score/components/ScoreBoard';
import { ScoreBoardPlayTime } from '../../../score/components/ScoreBoardPlayTime';
import { useScoreBoard } from '../../../score/hooks';
import {
  EventDetailsCell,
  EventNameTeam1,
  EventNameTeam2,
  Separator,
  TeamName,
} from '../EventSummary/styles';

import { MarketSummary } from './MarketSummary';
import { EventContainer, EventName } from './styles';

type EventLiveMenuItemProps = {
  eventId: number;
  sportId: number;
  regionId: number;
};

const EventLiveMenuItemComponent: FC<EventLiveMenuItemProps> = ({
  eventId,
  sportId,
  regionId,
}) => {
  const getBettingRoutePath = useBettingRoutePath();

  const event = useBettingGetterSelector({
    getterSelector: eventSelector,
    args: [eventId],
  });

  const { team1, team2, separator } = useBettingGetterSelector({
    getterSelector: eventNameDetailsSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  const mainMarket = useBettingGetterSelector({
    getterSelector: getMainMarketByEventIdSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  useScoreBoard(eventId);

  const { eventId: activeEventid } =
    useCleanedParams<{ eventId: string; leagueId: string; sportId: string }>();

  return (
    <MenuItem key={`${event?.EventId}`} elevationLevel={2} mb="1px">
      <MenuItem.Link
        to={getBettingRoutePath('eventLive', {
          sportId: sportId.toString(),
          regionId: regionId.toString(),
          leagueId: event?.LeagueId.toString() || '',
          eventId: eventId.toString(),
        })}
        data-testid={`menu-live-event-${eventId}`}
      >
        <EventContainer
          flex={1}
          padding="sm"
          flexDirection="column"
          isActive={eventId.toString() === activeEventid}
        >
          <ScoreBoardPlayTime eventId={eventId} />
          <Box flex={1} flexDirection="row">
            <EventDetailsCell>
              <EventName data-testid="event-summary-name">
                <EventNameTeam1>
                  <TeamName>{team1}</TeamName>
                  <Separator>{separator}</Separator>
                </EventNameTeam1>
                <EventNameTeam2>
                  <TeamName>{team2}</TeamName>
                </EventNameTeam2>
              </EventName>
            </EventDetailsCell>

            <Box alignSelf="center">
              <ScoreBoard eventId={eventId} type="compact" />
            </Box>
          </Box>

          <MarketSummary
            market={mainMarket}
            sportId={sportId}
            eventType="live"
          />
        </EventContainer>
      </MenuItem.Link>
    </MenuItem>
  );
};

export const EventLiveMenuItem = memo(EventLiveMenuItemComponent);
