import * as t from 'io-ts';

import { EGetLegalLimitStatus, ResponseStatus } from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const getLegalDepositLimitResponse = t.intersection(
  [
    t.type({
      Status: t.literal(ResponseStatus.Success),
    }),
    t.partial({
      Amount: t.number,
      NextRequestAllowed: t.string,
      EndCoolOffDate: t.string,
      HasLegalLimit: t.boolean,
      LimitRemovedDate: t.string,
      LegalLimitStatus: createEnumType<EGetLegalLimitStatus>(
        EGetLegalLimitStatus,
        'EGetLegalLimitStatus',
      ),
      LegalLimitDeclarationOfHonorNeeded: t.boolean,
    }),
  ],
  'GetLegalDepositLimitResponse',
);

export const setLegalDepositLimitResponse = t.type(
  {
    Status: t.literal(ResponseStatus.Success),
  },
  'SetLegalDepositLimitResponse',
);
