import isEqual from 'lodash/isEqual';
import React, { FC, memo, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  getSportbookKey,
  leaguesBySportIdSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { SimpleButton } from '@gaming1/g1-ui';

import { ColoredIcon } from '../../../common/components/ColoredIcon';
import { MenuItem } from '../../../common/components/MenuItem';
import { getIconType } from '../../helpers';

import { LeagueLiveMenuItem } from './LeagueLiveMenuItem';
import { MenuIcon } from './styles';

type SportLiveMenuItemProps = {
  /** Is the sport leagues visible */
  isCollapsed?: boolean;
  /** Is the nav collapsed */
  isMenuCollapsed?: boolean;
  item: {
    sportId: number | undefined;
    sportName: string;
    sportAlias: string;
    count: number | undefined;
    liveOrder: number;
  };
};

const SportLiveMenuItemComponent: FC<SportLiveMenuItemProps> = ({
  isCollapsed = false,
  isMenuCollapsed = false,
  item,
}) => {
  const theme = useTheme();
  const key = getSportbookKey('live');

  const [isActive, setIsActive] = useState(!isCollapsed);

  const leagues = useBettingGetterSelector({
    getterSelector: leaguesBySportIdSelector,
    args: [key, item.sportId || 0, null],
    equalityFn: isEqual,
  });

  const testId = `sport-live-menu-sport-toggle-${item.sportId}`;

  return (
    <>
      <SimpleButton onClick={() => setIsActive(!isActive)} data-testid={testId}>
        <MenuItem key={item.sportId} elevationLevel={2} mb="1px">
          {!isMenuCollapsed ? (
            <>
              <MenuItem.Left isActive={isActive}>
                <MenuIcon
                  id="sport-menu-icon"
                  type={getIconType(item.sportAlias)}
                />
              </MenuItem.Left>
              <MenuItem.Content>{item.sportName}</MenuItem.Content>
              <MenuItem.Right>
                <ColoredIcon
                  color={
                    isActive
                      ? theme.colors.neutrals[0]
                      : theme.colors.neutrals[3]
                  }
                  type={isActive ? 'AngleUp' : 'AngleDown'}
                  id={
                    isActive
                      ? 'sport-menu-item-active'
                      : 'sport-menu-item-not-active'
                  }
                />
              </MenuItem.Right>
            </>
          ) : (
            <MenuItem.Content justifyContent="center" alignItems="center">
              <MenuIcon
                id="sport-menu-icon"
                type={getIconType(item.sportAlias)}
              />
            </MenuItem.Content>
          )}
        </MenuItem>
      </SimpleButton>
      {isActive && !isMenuCollapsed && (
        <>
          {leagues.map((league) => (
            <LeagueLiveMenuItem key={league.Id} item={league} />
          ))}
        </>
      )}
    </>
  );
};

export const SportLiveMenuItem = memo(SportLiveMenuItemComponent);
