/* istanbul ignore file */
import { loyaltyPublicRoutes } from '@gaming1/g1-loyalty-api';
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

/*
 * Has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
 * typed as it, otherwise this break the RouteParams mapped type !
 */
export const loyaltyRoutes = {
  ...loyaltyPublicRoutes,
  shopProduct: createParamRoute('/shop/product/:productId', {
    productId: '',
  }),
  shopPurchase: createParamRoute('/shop/purchase/:productId', {
    productId: '',
  }),
  shopDeliveryInfo: createParamRoute('/shop/purchase-delivery/:productId', {
    productId: '',
  }),
  shopPurchaseSuccess: createRoute('/shop/purchase-success'),
  shopCategory: createParamRoute('/shop/category/:categoryId', {
    categoryId: '',
  }),
  shopPurchaseScratchcard: createRoute('/shop/purchase-scratchcard'),
  shopScratchcardRules: createRoute('/shop/scratchcard-rules'),
  scratchcard: createParamRoute('/shop/scratchcard/:scratchcardId', {
    scratchcardId: '',
  }),
  giftbox: createRoute('/club/giftbox'),
};
