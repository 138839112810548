import React, { ContextType, FC, useCallback, useMemo, useState } from 'react';

import { BettingSlipNumpadContext } from './BettingSlipNumpadContext';

export const BettingSlipNumpadProvider: FC = ({ children }) => {
  const [currentInput, setCurrentInput] = useState<string | null>(null);

  const setInputFocused = useCallback((id: string | null) => {
    setCurrentInput(id);
  }, []);

  const bettingSlipNumpadContextValue: ContextType<
    typeof BettingSlipNumpadContext
  > = useMemo(
    () => ({
      currentInput,
      setInputFocused,
    }),
    [currentInput, setInputFocused],
  );

  return (
    <BettingSlipNumpadContext.Provider value={bettingSlipNumpadContextValue}>
      {children}
    </BettingSlipNumpadContext.Provider>
  );
};
