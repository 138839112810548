import React, { FC, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PrismicConditionalSlice } from '@gaming1/g1-cms';
import {
  coreActions,
  getUserProfileStatusSelector,
  userSelector,
} from '@gaming1/g1-core';
import { useMedia } from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

import { PrismicPageContext } from '../PrismicPageContext';
import { PrismicRichText } from '../PrismicRichText';

import { evaluateCondition } from './helpers';

type PrismicConditionalProps = {
  /** Prismic 'Conditional slice' slice */
  slice: PrismicConditionalSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Conditional slice` slice
 * This renders a different content, regarding if some conditions are TRUE or FALSE
 */
export const PrismicConditional: FC<PrismicConditionalProps> = ({
  slice,
  testId = 'prismic-conditional-slice',
  parentIds = [],
}) => {
  const { isUserLoggedIn } = useContext(PrismicPageContext);
  const userProfile = useSelector(userSelector);
  const getUserProfileStatus = useSelector(getUserProfileStatusSelector);
  const { mediaBP } = useMedia();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isUserLoggedIn &&
      slice.items.some(
        (conditionData) =>
          conditionData.condition === 'USER_IS_DEPOSITOR' ||
          conditionData.condition === 'USER_IS_NOT_DEPOSITOR',
      ) &&
      getUserProfileStatus === RemoteData.NotAsked
    ) {
      dispatch(coreActions.getUserProfile.request());
    }
  }, [dispatch, getUserProfileStatus, isUserLoggedIn, slice.items]);

  if (!slice.primary.enabled) {
    return null;
  }

  const now = new Date(Date.now());

  const evaluatedConditions = slice.items.map((conditionData) =>
    evaluateCondition({
      conditionData,
      isUserLoggedIn,
      now,
      mediaBP,
      userProfile,
    }),
  );

  /**
   * If some of the conditions can't be determied yet (e.g we don't know yet if the user is logged or not)
   * we display nothing to avoid flash of content.
   */
  if (evaluatedConditions.some((res) => res === null)) {
    return null;
  }

  const areAllConditionsSatisfied = evaluatedConditions.every(
    (res) => res === true,
  );

  return (
    <PrismicRichText
      content={
        areAllConditionsSatisfied
          ? slice.primary.content_true
          : slice.primary.content_false
      }
      testId={testId}
      sliceId={slice.primary.id}
      parentIds={
        slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
      }
    />
  );
};
