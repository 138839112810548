import * as t from 'io-ts';

import { conditionListCodec } from '../common/gifts/codecs';
import { notificationCodec } from '../notification/codecs';

export const freebetCodec = t.intersection(
  [
    t.type({
      Id: t.string,
      Amount: t.number,
      ActivationDate: t.string,
      ExpirationDate: t.string,
    }),
    t.partial({
      ConditionId: t.string,
    }),
  ],
  'Freebet',
);
export const lockedFreebetCodec = t.intersection([
  t.type(
    {
      Id: t.string,
      CreatedDate: t.string,
      Amount: t.number,
      FreebetAvailableToUnlock: t.number,
      Threshold: t.number,
      AmountToUnlockNext: t.number,
    },
    'LockedFreebet',
  ),
  t.partial({
    UnlockConditions: conditionListCodec,
  }),
]);
export const getFreebetsResponseCodec = t.partial(
  {
    Freebets: t.array(freebetCodec),
    LockedFreebets: t.array(lockedFreebetCodec),
    Conditions: t.array(conditionListCodec),
    Notification: notificationCodec,
  },
  'GetFreebetsResponseCodec',
);
