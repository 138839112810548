import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../store/types';

import * as actions from './actions';
import { SettingsState } from './types';

export const initialState: SettingsState = {
  requests: {
    updateUserPreferences: generateInitialRequestState(null),
    getUserPreferences: generateInitialRequestState(null),
  },
  userSettings: null,
};

export const settingsReducer = (
  state: SettingsState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.updateBettingUserSettings.request):
        produceLoadingState(draftState.requests.updateUserPreferences);
        break;

      case getType(actions.updateBettingUserSettings.failure):
        produceFailureState(
          draftState.requests.updateUserPreferences,
          action.payload,
        );
        break;

      case getType(actions.updateBettingUserSettings.success):
        produceSuccessState(draftState.requests.updateUserPreferences);
        break;

      case getType(actions.getBettingUserSettings.request):
        produceLoadingState(draftState.requests.getUserPreferences);
        break;

      case getType(actions.getBettingUserSettings.failure):
        produceFailureState(
          draftState.requests.getUserPreferences,
          action.payload,
        );
        break;

      case getType(actions.getBettingUserSettings.success):
        produceSuccessState(draftState.requests.getUserPreferences);
        draftState.userSettings = action.payload.Result;
        break;

      default: // Immer will automatically return the state
    }
  });
