import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getAvailableBoostusV4,
  hasAvailableBoostus,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../common/store/errorMessages';
import { BettingEpic } from '../store/types';

import * as actions from './actions';
import { getAvailableBoostusCodec, hasBoostusResponseCodec } from './codecs';

export const hasAvailableBoostusEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter, config$ },
) =>
  action$.pipe(
    filter(isActionOf(actions.getHasBoostusAvailable.request)),
    withLatestFrom(config$),
    switchMap(([, config]) => {
      const areBoostusEnabled = config.betting.boostus.enabled;

      if (!areBoostusEnabled) {
        return of(
          actions.getHasBoostusAvailable.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      return wsAdapter.request(hasAvailableBoostus({})).pipe(
        mapGuard(hasBoostusResponseCodec),
        map(actions.getHasBoostusAvailable.success),
        catchError((err) =>
          of(
            actions.getHasBoostusAvailable.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      );
    }),
  );

export const getAvailableBoostusEpic: BettingEpic = (
  actions$,
  _,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAvailableBoostus.request)),
    withLatestFrom(config$),
    switchMap(([{ payload }, config]) => {
      const areBoostusEnabled = config.betting.boostus.enabled;

      if (!areBoostusEnabled) {
        return of(
          actions.getAvailableBoostus.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
            ...payload,
          }),
        );
      }

      return wsAdapter.request(getAvailableBoostusV4({})).pipe(
        mapGuard(getAvailableBoostusCodec),
        map((data) =>
          actions.getAvailableBoostus.success({
            ...data,
            ...payload,
          }),
        ),
        catchError((err) =>
          of(
            actions.getAvailableBoostus.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              ...payload,
            }),
          ),
        ),
      );
    }),
  );
