import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TrackingEventKind } from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { DrawerType, LayoutContext } from '@gaming1/g1-ui';

import { useTracking } from '../../../tracking/hooks/useTracking';
import { IframeMessageManager } from '../../iframeMessageManager';

/**
 * Send a message warning that the user is still active each time the location
 * changes (only if inside a RN app)
 */
export const useHistoryUpdateMessage = (
  iframeManager: IframeMessageManager,
) => {
  const history = useHistory();

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const historyUnsubscribe = history.listen((location, action) => {
        iframeManager.updateHistory(location, action);
      });

      return () => historyUnsubscribe();
    }
    return () => undefined;
  }, [history, iframeManager]);
};

/** Return the geolocation status based on the parent app messages */
export const useGeolocationMessages = (iframeManager: IframeMessageManager) => {
  const { geolocation$ } = iframeManager;

  /** Is the geolocation status (sent by the parent app) ok */
  const [isGeolocationOk, setIsGeolocationOK] = useState(false);

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const geolocationSubscription = geolocation$.subscribe(({ payload }) => {
        if (isGeolocationOk !== payload.isValid) {
          setIsGeolocationOK(payload.isValid);
        }
      });
      return () => geolocationSubscription.unsubscribe();
    }
    return () => undefined;
  }, [geolocation$, isGeolocationOk]);
  return {
    isGeolocationOk,
  };
};

/**
 * A hook that subscribe on "trackingEvent" messages form rninterop and
 * forward the received payload to the webapp analytics service(s).
 */
export const useNativeTracking = (iframeManager: IframeMessageManager) => {
  const { trackingEvent$ } = iframeManager;
  const { pushEvent, pushRawData } = useTracking();

  useEffect(() => {
    if (getWrapperEnv() !== 'rn') {
      return () => undefined;
    }

    const subscription = trackingEvent$.subscribe(({ payload }) => {
      switch (payload.kind) {
        case TrackingEventKind.Event:
          return pushEvent(payload.event);
        case TrackingEventKind.RawData:
          return pushRawData(payload.rawData);
        default:
          return undefined;
      }
    });

    return () => subscription.unsubscribe();
  });
};

/** Return the navigation drawer based on the parent app messages */
export const useNavigationDrawerStateChangeMessage = (
  iframeManager: IframeMessageManager,
) => {
  const { navigationDrawerStateChange$ } = iframeManager;
  const { showDrawer, hideDrawer, visibleDrawer } = useContext(LayoutContext);

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const drawerSubscription = navigationDrawerStateChange$.subscribe(
        ({ payload }) => {
          if (payload.open) {
            showDrawer(DrawerType.mobileMenu, true);
          } else {
            hideDrawer();
          }
        },
      );
      return () => drawerSubscription.unsubscribe();
    }
    return () => undefined;
  });

  return visibleDrawer === DrawerType.mobileMenu;
};
