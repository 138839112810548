import React, { FC, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { ThemeContext } from 'styled-components';

import {
  bettingHistoryFirstElementGetterSelector,
  BettingHistoryTicketType,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { BettingSlipType } from '@gaming1/g1-requests-betting';
import { Box, Icon, Separator, Text } from '@gaming1/g1-ui';

import { giveClickableContainerTitleTranslationKey } from '../../helpers';
import { HistoryEventList } from '../../HistoryEventList';
import { ExpandButton, OpacityContainer } from '../../styles';

import { ClickableContainer, HistoryExpandedCardContainer } from './styles';

const TEXT_TOP_SPACING = 'xxs';

type HistoryCardContentProps = {
  historyTicket: BettingHistoryTicketType;
  isExpanded: boolean;
  onClick: () => void;
};

/**
 * It will render a clickable container displaying the type of betting slip
 * (single, combi, system), the number of elements (if not a single)
 * and a button to expand the content.
 *
 * If the content is expanded, it'll also display each event of the history
 * ticket.
 *
 */
export const HistoryCardContent: FC<HistoryCardContentProps> = ({
  historyTicket,
  isExpanded,
  onClick,
}) => {
  const { t } = useTranslation('betting');

  const { colors } = useContext(ThemeContext);

  const { AssociatedPromotion, Elements, Id, Type } = historyTicket;

  const firstElement = useBettingGetterSelector({
    getterSelector: bettingHistoryFirstElementGetterSelector,
    args: [Id],
    equalityFn: shallowEqual,
  });

  return (
    <HistoryExpandedCardContainer>
      <ClickableContainer
        onClick={onClick}
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        data-testid="clickable-card-container"
      >
        <Box mt={TEXT_TOP_SPACING} width="100%">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            mr="xs"
            fontSize={1}
            fontWeight="bold"
          >
            {t(
              giveClickableContainerTitleTranslationKey(
                Type,
                AssociatedPromotion?.Types,
              ),
            )}
            <ExpandButton
              isVisible={isExpanded}
              data-testid="expand-button-history-card"
            >
              <Icon type="AngleDown" id="AngleDown" />
            </ExpandButton>
          </Box>
          <OpacityContainer>
            <Text mt={TEXT_TOP_SPACING} ellipsis>
              {Type === BettingSlipType.Single
                ? firstElement?.EventName
                : t('history.element.number', {
                    nbElements: Elements?.length,
                  })}
            </Text>
          </OpacityContainer>
        </Box>
      </ClickableContainer>
      {isExpanded && (
        <div data-testid="expanded-card-container">
          <Separator
            my="xxs"
            opacity="translucent"
            hrColor={colors.secondary[0]}
            data-testid="card-event-separator"
          />
          <HistoryEventList
            bettingHistoryId={Id}
            list={Elements}
            type={Type}
            view="compact"
          />
        </div>
      )}
    </HistoryExpandedCardContainer>
  );
};
