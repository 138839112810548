import React, { FC } from 'react';

import { PrismicCollapsibleBlockSlice } from '@gaming1/g1-cms';
import { Box, Collapsible } from '@gaming1/g1-ui';

import { getItemKey } from '../../helpers';
import { PrismicRichText } from '../PrismicRichText';
import { PrismicWrapper } from '../styles';

export type PrismicCollapsibleProps = {
  /** Prismic 'Collapsible block' slice */
  slice: PrismicCollapsibleBlockSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};
/**
 * This component renders a prismic `Buttons` slice
 * A block filled with text that could be collapsed
 */
export const PrismicCollapsible: FC<PrismicCollapsibleProps> = ({
  slice,
  testId = 'prismic-collapsible',
  parentIds = [],
}) => (
  <>
    {(slice.items || []).map((item, index) => (
      <PrismicWrapper
        key={`prismic-collapsible-${getItemKey(item.header) || index}`}
      >
        <Collapsible
          data-testid={testId}
          hoverTitle={item.header || ''}
          mainContent={item.header || ''}
          mainStyle={{ p: 'sm', flexDirection: 'row' }}
          visibleByDefault={item.expanded}
          expandedContent={
            <Box pl="sm" pr="sm" pb="sm">
              <PrismicRichText
                testId="prismic-collapsible-content"
                content={item.content}
                sliceId={slice.primary.id}
                parentIds={
                  slice.primary.id
                    ? [...parentIds, slice.primary.id]
                    : parentIds
                }
              />
            </Box>
          }
        />
      </PrismicWrapper>
    ))}
  </>
);
