/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetSponsorshipHistoryRequestDTO,
  GetSponsorshipHistoryResponseDTO,
  GetSponsorshipInfosRequestDTO,
  GetSponsorshipInfosResponseDTO,
  NewSponsorshipRequestDTO,
  NewSponsorshipResponseDTO,
} from '../entities';

export const getSponsorshipHistory = (
  options: GetSponsorshipHistoryRequestDTO,
) =>
  createRequest<GetSponsorshipHistoryResponseDTO>(
    100,
    'GetSponsorshipHistory',
    options,
  );

export const getSponsorshipInfos = (options: GetSponsorshipInfosRequestDTO) =>
  createRequest<GetSponsorshipInfosResponseDTO>(
    100,
    'GetSponsorshipInfos',
    options,
  );

export const newSponsorship = (options: NewSponsorshipRequestDTO) =>
  createRequest<NewSponsorshipResponseDTO>(100, 'NewSponsorship', options);
