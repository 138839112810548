import * as t from 'io-ts';

import {
  ECancelWithdrawStatus,
  ECreditCardWithdrawStatus,
  EGetWithdrawInfoStatus,
  EMakeInRoomWithdrawStatus,
  EMakeTransferWithdrawStatus,
  EWalletWithdrawStatus,
  EWithdrawMethodInfoStatus,
  EWithdrawType,
  ExtraFieldType,
  GetCreditCardWithdrawInfosStatus,
  GetEWalletWithdrawInfosStatus,
  GetTransferWithdrawInfosStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

const withdrawType = createEnumType<EWithdrawType>(
  EWithdrawType,
  'EWithdrawType',
);

export const withdrawMethodInfo = t.intersection(
  [
    t.type({
      Id: t.string,
      MaximumAmount: t.number,
      MinimumAmount: t.number,
      MinimumDepositAmount: t.number,
      Order: t.number,
      Status: createEnumType<EWithdrawMethodInfoStatus>(
        EWithdrawMethodInfoStatus,
      ),
      WithdrawBlocked: t.boolean,
      WithdrawType: withdrawType,
    }),
    t.partial({
      Delay: t.string,
      Description: t.string,
      Key: t.string,
      MultipleAmount: t.number,
      Name: t.string,
      Thumbnail: t.string,
    }),
  ],
  'WithdrawMethodInfo',
);

export const getWithdrawInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetWithdrawInfoStatus.Successful),
      AvailableAmount: t.number,
      WithdrawableAmount: t.number,
      LastWithdrawType: withdrawType,
      LastWithdrawWalletId: t.string,
    }),
    t.partial({
      WithdrawMethods: t.array(withdrawMethodInfo),
      IbanWaitingForIdentification: t.array(t.string),
    }),
  ],
  'GetWithdrawInfoResponse',
);

const extraFieldOption = t.partial({ Name: t.string, Title: t.string });

type WithdrawExtraFieldHelperType = {
  Children: WithdrawExtraFieldHelperType[];
  Type: ExtraFieldType;
} & {
  FieldName?: string;
  FieldTitle?: string;
  Mask?: string;
  Placeholder?: string;
  Regex?: string;
  Value?: string;
};

export const withdrawExtraField = t.recursion<WithdrawExtraFieldHelperType>(
  'WithdrawExtraField',
  (self) =>
    t.intersection(
      [
        t.type({
          Children: t.array(self),
          Type: createEnumType<ExtraFieldType>(
            ExtraFieldType,
            'ERegistrationStatus',
          ),
        }),
        t.partial({
          FieldName: t.string,
          FieldTitle: t.string,
          Mask: t.string,
          Placeholder: t.string,
          Regex: t.string,
          Value: t.string,
          Option: t.array(extraFieldOption),
        }),
      ],
      'WithdrawExtraField',
    ),
);

export const getTransferWithdrawInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(GetTransferWithdrawInfosStatus.Successful),
      BankInformationId: t.string,
    }),
    t.partial({
      Iban: t.string,
      Bic: t.string,
      AccountType: t.string,
      ExtraFields: t.array(withdrawExtraField),
    }),
  ],
  'GetTransferWithdrawInfoResponse',
);

export const getEWalletWithdrawInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(GetEWalletWithdrawInfosStatus.Successful),
    }),
    t.partial({
      Identifier: t.array(t.string),
    }),
  ],
  'GetEWalletWithdrawInfoResponse',
);

export const getCreditCardWithdrawInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(GetCreditCardWithdrawInfosStatus.Successful),
    }),
    t.partial({
      ExtraFields: t.array(withdrawExtraField),
    }),
  ],
  'GetEWalletWithdrawInfoResponse',
);

export const makeInRoomWithdrawResponse = t.type({
  Status: t.literal(EMakeInRoomWithdrawStatus.Successful),
  WithdrawableAmount: t.number,
  Reference: t.string,
  Available: t.string,
});

export const makeTransferWithdrawResponse = t.type({
  Status: t.literal(EMakeTransferWithdrawStatus.Successful),
  Message: t.union([t.string, t.undefined]),
  IbanDocumentRequested: t.boolean,
  WithdrawableAmount: t.number,
  Reference: t.union([t.string, t.undefined]),
});

export const makeEWalletWithdrawResponse = t.intersection([
  t.type({
    Status: t.literal(EWalletWithdrawStatus.Successful),
    WithdrawableAmount: t.number,
  }),
  t.partial({
    Reference: t.union([t.string, t.undefined]),
    Message: t.union([t.string, t.undefined]),
  }),
]);

export const makeCreditCardWithdrawResponse = t.intersection([
  t.type({
    Status: t.literal(ECreditCardWithdrawStatus.Successful),
    WithdrawableAmount: t.number,
  }),
  t.partial({
    Reference: t.union([t.string, t.undefined]),
    Message: t.union([t.string, t.undefined]),
  }),
]);

export const cancelWithdrawalResponse = t.type({
  Status: t.literal(ECancelWithdrawStatus.Successful),
});
