import { useSelector } from 'react-redux';

import { userCreditSelector } from '@gaming1/g1-core';
import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import {
  BettingSlipNotification,
  EFrontErrorType,
} from '../../../notification/mapper/types';
import {
  bettingSlipRankNbCombinationsGetterSelector,
  bettingSlipRankOddsGetterSelector,
  bettingSlipRankStakeInFloatGetterSelector,
} from '../../store/selectors/elements/bettingslipSystem';
import { getLimitStatus } from '../../store/selectors/elements/helpers';
import { bettingSlipRankLimitsGetterSelector } from '../../store/selectors/riskManagement/riskManagement';

export const useBettingSlipRankStakeWithCombinations = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const stakeSelector = useSelector(bettingSlipRankStakeInFloatGetterSelector);
  const nbCombinationsSelector = useSelector(
    bettingSlipRankNbCombinationsGetterSelector,
  );

  return (id: string): number | undefined => {
    const stake = stakeSelector(bettingSlipId, id);
    const nbCombinations = nbCombinationsSelector(bettingSlipId, id);

    if (!stake || !nbCombinations) {
      return undefined;
    }
    return Number((stake * nbCombinations).toPrecision(15));
  };
};

export const useBettingSlipRankOdd = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const bettingSlipRank = useSelector(bettingSlipRankOddsGetterSelector);

  return (id: string): number | undefined => bettingSlipRank(bettingSlipId, id);
};

export const useBettingSlipRankPotWinning = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const stakeSelector = useSelector(bettingSlipRankStakeInFloatGetterSelector);

  const odd = useBettingSlipRankOdd(bettingSlipId);

  return (id: string): number | undefined => {
    const stake = stakeSelector(bettingSlipId, id);
    const oddValue = odd(id);

    if (!stake || !oddValue) {
      return undefined;
    }
    return Number((stake * oddValue).toPrecision(15));
  };
};

export const useBettingSlipRankErrorFrontType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const stakeSelector = useBettingSlipRankStakeWithCombinations(bettingSlipId);

  const limitsSelector = useSelector(bettingSlipRankLimitsGetterSelector);

  const odd = useBettingSlipRankOdd(bettingSlipId);
  const userCredit = useSelector(userCreditSelector);

  const nbCombinationsSelector = useSelector(
    bettingSlipRankNbCombinationsGetterSelector,
  );

  return (id: string): BettingSlipNotification => {
    const stake = stakeSelector(id);

    const nbCombinations = nbCombinationsSelector(bettingSlipId, id);

    if (!stake) {
      return {
        Status: EFrontErrorType.None,
        Level: NotificationLevel.Information,
        Code: NotificationCode.Unknown,
      };
    }

    if (userCredit && stake > userCredit.AvailableAmout) {
      return {
        Status: EFrontErrorType.NotEnoughCredit,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const limits = limitsSelector(bettingSlipId, id);
    if (!limits) {
      return {
        Status: EFrontErrorType.None,
        Level: NotificationLevel.Information,
        Code: NotificationCode.Unknown,
      };
    }

    const oddValue = odd(id);
    // We should always have an odd as the selectors will round any negative one
    // or undefined one to "1"
    if (!oddValue) {
      return {
        Status: EFrontErrorType.InvalidOdd,
        Level: NotificationLevel.Error,
        Code: NotificationCode.Unknown,
      };
    }

    const { min, max, winnable } = limits;

    return getLimitStatus({
      stake,
      min: min ?? null,
      max: max ?? null,
      nbCombinations,
      winnable: winnable ?? null,
      odd: oddValue,
    });
  };
};
