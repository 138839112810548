import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardTeamNamesSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardSummaryTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardSummaryTemplateEllipsis as TemplateEllipsis,
  ScoreBoardSummaryTemplateItem as TemplateItem,
  ScoreBoardSummaryTemplateRow as TemplateRow,
  ScoreBoardSummaryTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the summary scoreboard with only a name template */
export const ScoreBoardSummaryOneNameOnlyTemplate: VFC<
  ScoreBoardTemplateProps
> = ({ eventId, sportId }) => {
  const { team1: name1, team2: name2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const name = name1 || name2;

  return (
    <TemplateWrapper data-testid="scoreboard-summary-one-name-only-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper>
          <TemplateRow width="100%" justifyContent="center">
            <TemplateItem px="sm" overflow="hidden">
              <TemplateEllipsis textAlign="center">{name}</TemplateEllipsis>
            </TemplateItem>
          </TemplateRow>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
