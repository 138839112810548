import React, { ContextType, FC, useMemo } from 'react';

import { useBettingIconOverrideList } from '@gaming1/g1-betting-web';
import { IconContext } from '@gaming1/g1-ui';

const IconsContextValue: ContextType<typeof IconContext> = {
  types: {},
  ids: {},
};

export const IconsProvider: FC = ({ children }) => {
  const bettingIconOverrideListHook = useBettingIconOverrideList();
  const bettingTypeOverride = useMemo(
    () => ({ types: bettingIconOverrideListHook }),
    [bettingIconOverrideListHook],
  );

  const contextValue = useMemo(
    () => ({ ...IconsContextValue, ...bettingTypeOverride }),
    [bettingTypeOverride],
  );
  return (
    <IconContext.Provider value={contextValue}>{children}</IconContext.Provider>
  );
};
