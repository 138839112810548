import { VFC } from 'react';

type IconProps = {
  fill?: string;
};

export const EnterFullScreenIcon: VFC<IconProps> = ({ fill }) => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <title>enter fullscreen</title>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M1 1v6h2V3h4V1H1zm2 12H1v6h6v-2H3v-4zm14 4h-4v2h6v-6h-2v4zm0-16h-4v2h4v4h2V1h-2z"
    />
  </svg>
);

export const ExitFullScreenIcon: VFC<IconProps> = ({ fill }) => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <title>exit fullscreen</title>
    <path
      fillRule="evenodd"
      fill={fill}
      d="M7 7V1H5v4H1v2h6zM5 19h2v-6H1v2h4v4zm10-4h4v-2h-6v6h2v-4zm0-8h4V5h-4V1h-2v6h2z"
    />
  </svg>
);

export const MinimizePanelIcon: VFC<IconProps> = ({ fill }) => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <title>minimize panel</title>
    <path fill={fill} d="M4 9h12v2H4z" />
  </svg>
);

export const OpenPanelIcon: VFC<IconProps> = ({ fill }) => (
  <svg fill={fill} width="20" height="20" viewBox="0 0 20 20">
    <title>open panel</title>
    <path
      fill={fill}
      d="M3 12H1v5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h-5v2h5v14H3v-5z"
    />
    <path fill={fill} d="M1 9l3.3-3.3 5.7 5.7 1.4-1.4-5.7-5.7L9 1H1z" />
  </svg>
);
