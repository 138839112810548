import { PrismicRichTextContent, PromotionScope } from '@gaming1/g1-cms';
import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { IconType } from '@gaming1/g1-ui';
import { roundNumber } from '@gaming1/g1-utils';

import { cmsRoutes } from './routes';
import { PrismicCustomFunction, PrismicInternalLinkResult } from './types';

/** Regex for games GUID */
const guidRegex =
  /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/;

/** List of prismic icons name and corresponding icons */
const PRISMIC_ICONS: { [k: string]: IconType } = {
  default: 'Wireframe',
  betting: 'SportsAZEu',
  casino: 'Coin',
  deposit: 'Deposit',
  gift: 'Gift',
  heart: 'Heart',
  like: 'Like',
  logo: 'Brandtag',
  sponsorship: 'Affiliates',
  withdraw: 'Withdrawals',
};

/** Get the icon depending on the string sent by Prismic */
export const getPromoIconType = (icon: string) =>
  PRISMIC_ICONS[icon] || 'Wireframe';

/** Get a string without spaces and in lowercase */
export const removeSpacesAndUppercases = (value: string) =>
  value.replace(/\s/g, '').toLowerCase();

type CmsRouteName = keyof typeof cmsRoutes & string;

export const isCMSRouteName = (input: string): input is CmsRouteName =>
  Object.keys(cmsRoutes).includes(input);

const internalUrlRegex = /^https?:\/\/internal\/([^/]+)(\/(.*))?$/;

export const isPrismicInternalLink = (link?: string) =>
  !!link?.match(internalUrlRegex);

export const getPrismicInternalLinkParams = (link?: string) => {
  const result = link?.match(internalUrlRegex);
  if (result && result.length > 1 && result[1] && result[1].length > 0) {
    const action: string = result[1].replace("'", "\\'");
    const param: string =
      result.length > 3 && result[3] && result[3].length > 0
        ? result[3].replace("'", "\\'")
        : '';
    return { action, param };
  }
  return {
    action: null,
    param: null,
  };
};

export const parsePrismicInternalLink = (
  link?: string | null,
  customFunctions?: Record<string, PrismicCustomFunction>,
): PrismicInternalLinkResult => {
  if (!link) {
    return { error: 'No link specified' };
  }
  if (!isPrismicInternalLink(link)) {
    return { path: link };
  }

  const { action, param } = getPrismicInternalLinkParams(link);

  switch (action) {
    /**
     * in V4, when the user browses pages for which he must log in,
     * he is automatically redirected to the login page
     * and after that to the correct page.
     * This action 'LoginRedirect' is not very useful here.
     */
    case 'NavigateUrl':
      // Do a LoginRedirect to the specified page
      if (!param || !param.length) {
        return { error: `No URL specified for '${action}'` };
      }
      try {
        /**
         * In case it's a full url. e.g http://localhost:3000/fr/games/
         * We only care about the pathname
         */
        return { path: new URL(param).pathname };
      } catch (error) {
        return { path: param.trim() };
      }
      break;

    case 'LoginRedirect':
    case 'NavigatePage':
      // Navigate to the specified page, using a page ID
      if (!param || !param.length) {
        return { error: `No page name specified for '${action}'` };
      }
      return { path: `/?pagename=${param.trim()}` };
      break;

    case 'ShowLogin':
      return { path: '/?pagename=login' };
      break;

    case 'ShowRegistration':
      return { path: '/?pagename=registration' };
      break;

    case 'ShowGameLauncher':
      if (!param || !param.length) {
        return { error: `No game ID specified for '${action}'` };
      }
      if (!param.match(guidRegex)) {
        return { error: `Invalid game ID specified for '${action}'` };
      }
      return { path: `/play/${param}` };
      break;

    case 'ScrollToAnchor':
      if (!param || !param.length) {
        return { error: `No anchor ID specified for '${action}'` };
      }
      return {
        onClick: () =>
          document
            .getElementById(param)
            ?.scrollIntoView({ behavior: 'smooth' }),
      };
      break;

    case 'ScrollToTop':
      return { onClick: () => window.scrollTo({ top: 0, behavior: 'smooth' }) };
      break;

    case 'ScrollToTitle':
      return {
        onClick: () =>
          document
            .getElementsByTagName('h1')[0]
            .scrollIntoView({ behavior: 'smooth' }),
      };
      break;

    case 'CustomFunction':
      if (!param || !param.length) {
        return { error: `No function name specified for '${action}'` };
      }
      if (customFunctions && typeof customFunctions[param] === 'function') {
        return { onClick: customFunctions[param] };
      }
      return { error: `Function '${param}' not found for '${action}'` };
      break;

    default:
      return { error: `Unknown action : '${action}'` };
      break;
  }
};

/**
 * Generates an element key from a text
 */
export const getItemKey = (text?: string | null): string =>
  (text || '')
    .replace(/\s+/g, '-')
    .replace(/[^A-Za-z0-9-]+/g, '')
    .replace(/(^[-\s]+)|([-\s]+$)/g, '')
    .toLowerCase();

/**
 * Generates an element key from the first item of a rich text content
 */
export const getItemKeyFromRichText = (
  content: PrismicRichTextContent,
): string =>
  getItemKey(
    content.filter((item) => item.type.match(/^paragraph|heading\d$/))[0]?.text,
  );

/**
 * Calculates the width of a grid item, based on number of items and spacing between them
 */
export const getGridItemWidth = (itemsCount: number, spacing: string) => {
  if (itemsCount <= 1) {
    return '100%';
  }
  return `calc(${roundNumber(
    100 / itemsCount,
    2,
  )}% - ${spacing} / ${roundNumber(itemsCount / (itemsCount - 1), 3)})`;
};

/**
 * Get promotions scopes from the user's login status and profile
 */
export const getPromotionScopes = (
  isUserLoggedIn: ReturnType<typeof userLoggedInSelector>,
  userProfile: ReturnType<typeof userSelector>,
): PromotionScope[] => {
  const scopes: PromotionScope[] = ['none'];
  if (!isUserLoggedIn) {
    scopes.push('not connected');
  } else {
    scopes.push('connected');
    if (userProfile) {
      if (userProfile.HasAlreadyDeposited) {
        scopes.push('depositor');
      } else {
        scopes.push('non depositor');
      }
    }
  }
  return scopes;
};
