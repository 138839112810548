/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       08:58:35 15-01-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  AcceptLegalPopupRequestDTO,
  AcceptLegalPopupResponseDTO,
  GetLegalLimitRequestDTO,
  GetLegalLimitResponseDTO,
  SetLegalLimitRequestDTO,
  SetLegalLimitResponseDTO,
  ShowLegalPopupRequestDTO,
  ShowLegalPopupResponseDTO,
} from '../entities';

export const getLegalLimit = (options: GetLegalLimitRequestDTO) =>
  createRequest<GetLegalLimitResponseDTO>(100, 'GetLegalLimit', options);

export const setLegalLimit = (options: SetLegalLimitRequestDTO) =>
  createRequest<SetLegalLimitResponseDTO>(100, 'SetLegalLimit', options);

export const acceptLegalPopup = (options: AcceptLegalPopupRequestDTO) =>
  createRequest<AcceptLegalPopupResponseDTO>(100, 'AcceptLegalPopup', options);

export const showLegalPopup = (options: ShowLegalPopupRequestDTO) =>
  createRequest<ShowLegalPopupResponseDTO>(100, 'ShowLegalPopup', options);
