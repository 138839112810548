import React, { ContextType, FC } from 'react';

import { InboxPublicComponentsContext } from '@gaming1/g1-inbox-api';

import { InboxNotification } from '../../../inbox/components/InboxNotification';
import { InboxProvider } from '../../../inbox/InboxProvider';
import { InboxRouter } from '../../../inbox/InboxRouter';

const contextValue: ContextType<typeof InboxPublicComponentsContext> = {
  InboxNotification,
  InboxRouter,
};

/** Every context inbox needed for the inbox package:
 * - InboxContext: context containing inbox content
 * - InboxPublicComponentsContext: public inbox components
 */
export const InboxProviders: FC = ({ children }) => (
  <InboxProvider>
    <InboxPublicComponentsContext.Provider value={contextValue}>
      {children}
    </InboxPublicComponentsContext.Provider>
  </InboxProvider>
);
