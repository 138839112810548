import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardSportStatsSelector,
  getScoreBoardTeamNamesSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { SCOREBOARD_SCORE_SEPARATOR } from '../../constants';
import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardPlayTime } from '../ScoreBoardPlayTime';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardSummaryTemplateColumn as TemplateColumn,
  ScoreBoardSummaryTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardSummaryTemplateEllipsis as TemplateEllipsis,
  ScoreBoardSummaryTemplateItem as TemplateItem,
  ScoreBoardSummaryTemplateRow as TemplateRow,
  ScoreBoardSummaryTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the summary scoreboard with a cricket template */
export const ScoreBoardSummaryCricketTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
  sportId,
}) => {
  const {
    team1: name1,
    team2: name2,
    separator,
  } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: dismissals1, team2: dismissals2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Dismissals'],
    equalityFn: shallowEqual,
  });

  return (
    <TemplateWrapper data-testid="scoreboard-summary-cricket-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper>
          <TemplateRow width="100%">
            <TemplateItem px="sm" flex={1} overflow="hidden">
              <TemplateEllipsis textAlign="right">{name1}</TemplateEllipsis>
            </TemplateItem>
            <TemplateColumn px="xs">
              <TemplateRow pb="xxs" justifyContent="center">
                <TemplateItem fontSize="xl" px="xs">
                  {[score1, dismissals1].join('/')}
                </TemplateItem>
                <TemplateItem fontSize="xl" px="xs">
                  {separator || SCOREBOARD_SCORE_SEPARATOR}
                </TemplateItem>
                <TemplateItem fontSize="xl" px="xs">
                  {[score2, dismissals2].join('/')}
                </TemplateItem>
              </TemplateRow>
              <TemplateRow justifyContent="center">
                <ScoreBoardPlayTime eventId={eventId} liveIcon={false} />
              </TemplateRow>
            </TemplateColumn>
            <TemplateItem px="sm" flex={1} overflow="hidden">
              <TemplateEllipsis textAlign="left">{name2}</TemplateEllipsis>
            </TemplateItem>
          </TemplateRow>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
