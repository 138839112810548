import React, { FC, useContext } from 'react';

import { interopMessages } from '@gaming1/g1-core';
import { sendIframeMessage } from '@gaming1/g1-core-web';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { Heading, Select } from '@gaming1/g1-ui';

import { ConfigName, configs, isValidConfigName } from '../../configs';
import { SwitchContext } from '../SwitchProvider/contexts';

type ConfigSwitchType = {
  onChange: () => void;
};

export const ConfigSwitch: FC<ConfigSwitchType> = ({ onChange }) => {
  const [switchState, switchDispatch] = useContext(SwitchContext);

  const onSwitch = (configName: string) => {
    if (!isValidConfigName(configName)) {
      return;
    }

    switchDispatch({
      type: 'switch/config',
      payload: configName,
    });

    if (getWrapperEnv() === 'rn') {
      sendIframeMessage(interopMessages.switchConfig, {
        configName,
        config: configs[configName],
      });
    }

    onChange();
  };

  return (
    <>
      <Heading level="h4">Config</Heading>
      <Select
        onValueChange={onSwitch}
        options={Object.values(ConfigName).map((label) => ({
          value: label,
          label,
        }))}
        testId="devtoolmenu-config-select"
        value={switchState.config}
        aria-label="Config select"
      />
    </>
  );
};
