import { concatAll, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { BetPipeConfig, BetPipeStep, BetPipeType } from '@gaming1/g1-config';
import { isDebugModeEnabledSelector } from '@gaming1/g1-core';
import { RemoteData } from '@gaming1/g1-utils';

import { betPipeDebugLogs$ } from '../debugging/helpers';
import { logger } from '../logger';
import { BettingActions, BettingEpic } from '../store/types';

import * as betPipeActions from './actions';
import { isActionWithBetPipeId } from './helpers';
import {
  betPipeInfoGetterSelector,
  betPipeRequestsStateGetterSelector,
} from './selectors';
import { BetPipeRequestName } from './types';

/** Object containing all the steps that can take place inside the
 * bet pipe. Each step can be started using only the pipe id (additional
 * data will be read from the store inside the epics).
 * The properties of the object are the step name and their corresponding value
 * is the associated async action creator
 */
const betPipeActionsByStepName = {
  // keys = BetPipeStep
  resetBettingSlip: betPipeActions.resetBettingSlip,
  getFreebetConditionsForBettingSlip:
    betPipeActions.freebetConditionsForBettingSlip,
  getBoostusConditionsForBettingSlip:
    betPipeActions.boostusConditionsForBettingSlip,
  getPromotionsIdsForBettingSlip: betPipeActions.promotionsForBettingSlip,
  getInitializationData: betPipeActions.getBettingSlipInitializationData,
  getOutcomeInfos: betPipeActions.getInfoFromOutcomes,
  getOptimalBet: betPipeActions.getOptimalBet,
  getRiskManagement: betPipeActions.launchRiskManagement,
  placeBet: betPipeActions.launchPlaceBet,
};

/** Object containing all the requests names for a bet pipe.
 * The properties of the object are the step name and their corresponding value
 * is the associated request name
 */
const betPipeRequestNameByStepName: Record<BetPipeStep, BetPipeRequestName> = {
  resetBettingSlip: 'resetBettingSlip',
  getFreebetConditionsForBettingSlip: 'getFreebetConditionsForBettingSlip',
  getBoostusConditionsForBettingSlip: 'getBoostusConditionsForBettingSlip',
  getPromotionsIdsForBettingSlip: 'getPromotionsIdsForBettingSlip',
  getInitializationData: 'getBettingSlipInitializationData',
  getOutcomeInfos: 'getOutcomeInfos',
  getOptimalBet: 'getOptimalBet',
  getRiskManagement: 'getRiskManagement',
  placeBet: 'placeBettingSlip',
};

// Same typing as ActionCreator, only more precise for the returned action type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BettingActionCreator = (...args: any[]) => BettingActions;

/*
Example of actions dispatched:
For the config ['getInitializationData', ['getOptimalBet', 'boostus'], 'freebet']
assuming, every request is successful, these actions will be dispatched:
- betting/start_bet_pipe
- betting/get_betting_slip_init_data_request
- betting/get_betting_slip_init_data_success
- betting/get_optimal_bet_report_request
- betting/get_freebet_credit_report_request
- betting/get_optimal_bet_report_success
- betting/get_freebet_credit_report_success
- betting/get_boostus_report_request
- betting/get_boostus_report_success
- betting/completed_pipe

For the same config, if the getOptimalBet were to fail, those actions would be
dispatched: 
- betting/start_bet_pipe
- betting/get_betting_slip_init_data_request
- betting/get_betting_slip_init_data_success
- betting/get_optimal_bet_report_request
- betting/get_freebet_credit_report_request
- betting/get_optimal_bet_report_failure
*/

/**
 * Returns an array of epics that will deal with a complete bet pipe, defined
 * as the first argument. The second argument is the type of pipe
 */
export const pipeEpicBuilder = (
  betPipeConfig: BetPipeConfig,
  betPipeType: BetPipeType,
): BettingEpic[] => {
  // Log an error and bail if the config is empty
  if (!betPipeConfig.length) {
    logger.error(
      '[Bet pipe] the pipeEpicBuild was called with an empty config, this is a noop',
    );
    return [];
  }
  // Get the first step(s) of the pipe config
  const firstSteps = Array.isArray(betPipeConfig[0])
    ? betPipeConfig[0]
    : [betPipeConfig[0]];
  // Map those step names with async action creators
  const firstActions = firstSteps.map(
    (stepName) => betPipeActionsByStepName[stepName],
  );
  /**
   * The epic that will dispatch the first action(s) following a startPipe
   * action
   */
  const startEpic$: BettingEpic = (action$) =>
    action$.pipe(
      // Listen to start pipe actions
      filter(isActionOf(betPipeActions.startBetPipe)),
      // Only listen to actions with the right pipe type
      filter(({ payload: { type } }) => type === betPipeType),
      // Log the start of the pipe
      tap(({ payload: { betPipeId, type, bettingSlipId } }) =>
        logger.debug(
          `[Bet pipe] Starting a new bet pipe. Type: ${type}, Id: ${betPipeId}, Betting Slip id: ${bettingSlipId}, step(s) triggered: ${firstSteps.join(
            ', ',
          )}`,
        ),
      ),
      // Map all actions of the first step
      map(({ payload: { betPipeId, bettingSlipId } }) =>
        firstActions.map((asyncAction) =>
          asyncAction.request({ betPipeId, bettingSlipId }),
        ),
      ),
      // Dispatch all the actions successively
      concatAll(),
    );

  const pipeEpics$: BettingEpic[] = betPipeConfig.map(
    (currentSteps, index, steps) => {
      /** The next element in the config */
      const nextSteps = steps[index + 1];
      /** The step(s) we are listening to to start the next */
      const inputSteps = Array.isArray(currentSteps)
        ? currentSteps
        : [currentSteps];
      /** The next step(s) to be started */
      const outputSteps = Array.isArray(nextSteps) ? nextSteps : [nextSteps];
      /** True if there is no output steps (the pipe is comple) */
      const hasPipeCompleted = outputSteps[0] === undefined;
      /** The async action creators used as input */
      const inputAsyncActions = inputSteps.map(
        (stepName) => betPipeActionsByStepName[stepName],
      );
      /** The actions creators that will be used to trigger the next step(s) */
      const outputActionCreators: BettingActionCreator[] =
        // If there is no next step, send the complete action
        hasPipeCompleted
          ? [betPipeActions.completedPipe]
          : outputSteps.map(
              (stepName) => betPipeActionsByStepName[stepName].request,
            );

      // const testTruc = [betPipeActions.getBettingSlipInitializationData.failure, betPipeActions.getBettingSlipInitializationData.success];

      // type MappedAction<Action extends BettingActions> = PayloadActionCreator<Action['type'], Action['payload']>[];

      /** The success/failure action creators we are listening to */
      const inputActionCreators = inputAsyncActions.reduce(
        (acc, asyncAction) => [
          ...acc,
          asyncAction.failure,
          asyncAction.success,
        ],
        [] as BettingActionCreator[],
      );

      return (action$, state$) =>
        action$.pipe(
          // Ensure the action is a failure/success async action of one of our step(s)
          filter(isActionOf(inputActionCreators)),
          // Ensure the action has a betPipeId
          filter(isActionWithBetPipeId),
          // Read the current state
          withLatestFrom(state$),
          // Only take care of the current bet pipe type
          filter(
            ([
              {
                payload: { betPipeId },
              },
              state,
            ]) => {
              const pipeInfo = betPipeInfoGetterSelector(state)(betPipeId);
              return pipeInfo?.type === betPipeType;
            },
          ),
          // Only dispatch the next step(s) if all of the input steps are successful
          filter(
            ([
              {
                payload: { betPipeId },
              },
              state,
            ]) => {
              // Read the requests states for the pipe
              const getRequestsStates =
                betPipeRequestsStateGetterSelector(state);
              const requestsStates = getRequestsStates(betPipeId);
              if (!requestsStates) {
                // Should not happen since betPipeInitReducer init a pipeState on the start of a pipe
                logger.error(
                  `[Bet pipe] a pipe action with betPipeId ${betPipeId} was received but no corresponding requests state were found in the store.`,
                );
                return false;
              }
              // Returns true if every requests for the current step are succesful
              return inputSteps.every(
                (stepName) =>
                  requestsStates[betPipeRequestNameByStepName[stepName]]
                    .status === RemoteData.Success,
              );
            },
          ),
          // Log the triggering of the next steps
          tap(([{ payload }, state]) => {
            const { betPipeId, bettingSlipId } = payload as unknown as {
              betPipeId: string;
              bettingSlipId: string;
            };
            if (isDebugModeEnabledSelector(state)) {
              betPipeDebugLogs$.next({
                time: new Date().getTime(),
                id: betPipeId,
                type: betPipeType,
                inputSteps,
                outputSteps,
              });
            }
            logger.debug(
              `[Bet pipe] Id: ${betPipeId}, type: ${betPipeType}, bettingSlipId: ${bettingSlipId}. Step(s) completed: ${inputSteps.join(
                ', ',
              )}. Step(s) started: ${
                outputSteps.length && outputSteps[0]
                  ? outputSteps.join(', ')
                  : 'none (pipe completed)'
              }`,
            );

            if (!bettingSlipId) {
              logger.warn(`[Bet pipe] NO bettingSlipID WARN: ${bettingSlipId}`);
            }
          }),
          // Send all the output actions using the betPipeId
          map(
            ([
              {
                payload: { betPipeId },
              },
            ]) =>
              outputActionCreators.map((outputActionCreator) =>
                outputActionCreator({ betPipeId }),
              ),
          ),
          // Dispatch all the actions successively
          concatAll(),
        );
    },
  );
  return [startEpic$, ...pipeEpics$];
};
