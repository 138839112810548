/* eslint-disable react/jsx-curly-brace-presence */
import React, { FC } from 'react';

import {
  PrismicTableRowItem,
  PrismicTableSlice as PrismicTableType,
} from '@gaming1/g1-cms';

import { getItemKeyFromRichText } from '../../helpers';
import { PrismicRichText } from '../PrismicRichText';
import { PrismicWrapper } from '../styles';

import { PrismicStyledTable, PrismicTd, PrismicTh, PrismicTr } from './styles';

export type PrismicTableProps = {
  /** Prismic 'Table' slice */
  slice: PrismicTableType;
  /** Test ID */
  testId?: string;
};

const getContents = (row: PrismicTableRowItem, count: number) =>
  [
    row.column_1,
    row.column_2,
    row.column_3,
    row.column_4,
    row.column_5,
    row.column_6,
  ].slice(0, count);

/**
 * This component renders a prismic `Table` slice
 * Simple table, having up to 6 columns
 */
export const PrismicTable: FC<PrismicTableProps> = ({
  slice,
  testId = 'prismic-table',
}) => {
  const count = Number(slice.primary.columns_count) || 0;
  const headers =
    slice.primary.has_headers && slice.items.length > 0 ? slice.items[0] : null;
  const rows =
    slice.primary.has_headers && slice.items.length > 0
      ? slice.items.slice(1)
      : slice.items;

  return (
    <PrismicWrapper>
      <PrismicStyledTable data-testid={testId}>
        {headers && (
          <thead>
            <tr>
              {getContents(headers, count).map((header, index) => (
                <PrismicTh
                  key={`prismic-header-${
                    getItemKeyFromRichText(header) || index
                  }`}
                  data-testid="prismic-table-header-cell"
                >
                  <PrismicRichText
                    content={header}
                    sliceId={slice.primary.id}
                    disableInclusion
                  />
                </PrismicTh>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((row, rowIndex) => (
            <PrismicTr
              key={`prismic-row-${rowIndex + 1}`}
              data-testid="prismic-table-row"
            >
              {getContents(row, count).map((col, index) => (
                <PrismicTd
                  key={`prismic-cell-${getItemKeyFromRichText(col) || index}`}
                  data-testid="prismic-table-cell"
                >
                  <PrismicRichText
                    content={col}
                    sliceId={slice.primary.id}
                    disableInclusion
                  />
                </PrismicTd>
              ))}
            </PrismicTr>
          ))}
        </tbody>
      </PrismicStyledTable>
    </PrismicWrapper>
  );
};
