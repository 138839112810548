import { createAsyncAction } from 'typesafe-actions';

import { ValidatePhoneCodeRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  SendPhoneValidationCodeResponse,
  ValidatePhoneCodeResponse,
} from './types';

/** Send Phone Validation Code */
export const sendPhoneValidationCode = createAsyncAction(
  'user/send_phone_validation_code_request',
  'user/send_phone_validation_code_success',
  'user/send_phone_validation_code_failure',
)<void, SendPhoneValidationCodeResponse, FailurePayload>();

/** Validate Phone Code */
export const validatePhoneCode = createAsyncAction(
  'user/validate_phone_code_request',
  'user/validate_phone_code_success',
  'user/validate_phone_code_failure',
)<ValidatePhoneCodeRequestDTO, ValidatePhoneCodeResponse, FailurePayload>();
