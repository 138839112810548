import { SwitchAction, SwitchState } from './contexts';

export const switchStateReducer = (
  state: SwitchState,
  action: SwitchAction,
): SwitchState => {
  switch (action.type) {
    case 'switch/config':
      return {
        ...state,
        config: action.payload,
      };
    case 'switch/network_config':
      return {
        ...state,
        networkConfig: action.payload,
      };
    case 'switch/theme':
      return {
        ...state,
        customTheme: null,
        theme: action.payload,
      };
    case 'switch/custom_theme':
      return {
        ...state,
        customTheme: action.payload,
      };
    default:
      return state;
  }
};
