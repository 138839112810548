import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  getRegionNavigationLeaguesSelector,
  NavRegionType,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';

import { ColoredIcon } from '../../../common/components/ColoredIcon';
import { MenuItem } from '../../../common/components/MenuItem';
import { cleanParam } from '../../../helpers';
import { useBettingRoutePath } from '../../../hooks';
import { useRouteConfig } from '../../../routeurHooks';

import { LeagueMenuItem } from './LeagueMenuItem';
import { LeagueMenuContainer, RegionMenuItemContainer } from './styles';

type RegionMenuItemProps = {
  item: NavRegionType;
};

const RegionMenuItemComponent: FC<RegionMenuItemProps> = ({ item }) => {
  const theme = useTheme();
  const location = useLocation();
  const routeConfig = useRouteConfig();

  const { t } = useTranslation('betting');

  const leagues = useBettingGetterSelector({
    getterSelector: getRegionNavigationLeaguesSelector,
    args: [item.SportId, item.Id],
    equalityFn: shallowEqual,
  });

  const [isActive, setIsActive] = useState(true);

  const { regionId, leagueId } = useMemo(() => {
    const match = matchPath<{
      regionId?: string;
      leagueId?: string;
      eventId?: string;
    }>(location.pathname, {
      path: Object.values(routeConfig).map((route) => route.path),
      exact: true,
    });
    if (!match) {
      return {
        params: {
          regionId: null,
          leagueId: null,
        },
      };
    }
    return {
      regionId: cleanParam(match.params.regionId?.split('/')[0]),
      leagueId: cleanParam(match.params.leagueId?.split('/')[0]),
    };
  }, [routeConfig, location.pathname]);

  const isCurrentPage = regionId === item.Id.toString();

  // regionId is not set on event page
  const activeRegionIdFromLeague = leagues.find(
    (league) => league.Id.toString() === leagueId,
  )?.RegionId;

  const isExpanded =
    (activeRegionIdFromLeague === item.Id || isCurrentPage) && isActive;

  const getBettingRoutePath = useBettingRoutePath();

  useEffect(() => {
    if (isCurrentPage) {
      setIsActive(true);
    }
  }, [isCurrentPage]);
  const testId = `sportmenu-region-toggle-${item.SportId}-${item.Id}`;

  const content = useMemo(
    () => (
      <>
        <RegionMenuItemContainer
          key={item.Id}
          elevationLevel={2}
          hasNoShadow
          pl={6}
          width="100%"
          isActive={isCurrentPage}
          data-testid={testId}
        >
          <MenuItem.Link
            onClick={(e) => {
              if (isCurrentPage) {
                e.preventDefault();
              }
              setIsActive(!isActive);
            }}
            to={getBettingRoutePath('region', {
              regionId: String(item.Id),
              sportId: String(item.SportId),
            })}
            key={item.Id}
          >
            <MenuItem.Content fontWeight={isCurrentPage ? 'bold' : null}>
              {item.Name}
            </MenuItem.Content>
            <MenuItem.Right>
              <ColoredIcon
                color={
                  isExpanded
                    ? theme.colors.neutrals[0]
                    : theme.colors.neutrals[3]
                }
                type={isExpanded ? 'AngleUp' : 'AngleDown'}
                id={`icon-${item.Id}`}
              />
            </MenuItem.Right>
          </MenuItem.Link>
        </RegionMenuItemContainer>
        {isExpanded && (
          <LeagueMenuContainer>
            {leagues.length > 1 && (
              <MenuItem
                key={item.Id}
                hasNoShadow
                margin={0}
                pl="xs"
                pr="md"
                py="xxs"
                hasNoHoverBehavior
                hasNoElevation
              >
                <MenuItem.Link
                  to={getBettingRoutePath('region', {
                    regionId: String(item.Id),
                    sportId: String(item.SportId),
                  })}
                  key={item.Id}
                >
                  <MenuItem.Content
                    elevationLevel={!leagueId ? 3 : undefined}
                    hoverElevationLevel={4}
                    hasBorderRadius
                    ml="md"
                    pl="md"
                    py="xxs"
                    color={
                      !leagueId
                        ? theme.colors.neutrals[0]
                        : theme.colors.neutrals[2]
                    }
                  >
                    {t('sport.navigation.allLeagues')}
                  </MenuItem.Content>
                </MenuItem.Link>
              </MenuItem>
            )}
            {leagues.map((league) => (
              <LeagueMenuItem
                item={league}
                key={league.Id}
                isSelected={league.Id.toString() === leagueId}
              />
            ))}
          </LeagueMenuContainer>
        )}
      </>
    ),
    [
      item,
      isCurrentPage,
      isActive,
      setIsActive,
      leagueId,
      getBettingRoutePath,
      isExpanded,
      leagues,
      t,
      testId,
      theme,
    ],
  );

  return content;
};

export const RegionMenuItem = memo(RegionMenuItemComponent);
