import * as t from 'io-ts';

import {
  conditionListCodec,
  groupConditionCodec,
  rangeConditionCodec,
} from './codecs';

export type Gifts = 'freebet' | 'boostus' | 'promotion';

export enum EGiftsType {
  freebet = 'freebet',
  boostus = 'boostus',
  promotion = 'promotion',
}

export type ConditionListType = t.TypeOf<typeof conditionListCodec>;

export type GroupConditionType = t.TypeOf<typeof groupConditionCodec>;

export type RangeConditionType = t.TypeOf<typeof rangeConditionCodec>;
