import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { PasswordUpdateState } from './types';

export const initialState: PasswordUpdateState = {
  requests: {
    changePassword: generateInitialRequestState(null),
  },
};

export const passwordUpdateReducer = (
  state: PasswordUpdateState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.changePassword.request):
        produceLoadingState(draftState.requests.changePassword);
        break;

      case getType(actions.changePassword.failure):
        produceFailureState(draftState.requests.changePassword, action.payload);
        break;

      case getType(actions.changePassword.success):
        produceSuccessState(draftState.requests.changePassword);
        break;

      default: // Immer will automatically return the state
    }
  });
