import styled, { css } from 'styled-components';

import { elevation, spaces } from '@gaming1/g1-style';
import { Box, Text } from '@gaming1/g1-ui';

import { Image } from '../../../layout/components/Image';

type RequestErrorMessageContainerProps = {
  hasElevation?: boolean;
};

export const RequestErrorMessageContainer = styled(
  Box,
)<RequestErrorMessageContainerProps>`
  align-items: center;
  justify-content: center;
  margin-bottom: ${spaces('sm')};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spaces('sm')};
  max-width: 420px;
  padding: 0 ${spaces('sm')};
  width: 100%;

  ${({ hasElevation }) =>
    hasElevation &&
    css`
      ${elevation(1)}
    `};
`;

type RequestErrorMessageTextProps = {
  shouldHavePadding?: boolean;
};

export const RequestErrorMessageText = styled(
  Text,
)<RequestErrorMessageTextProps>`
  text-align: center;
  ${({ shouldHavePadding }) =>
    shouldHavePadding &&
    css`
      padding-bottom: ${spaces('sm')};
    `};
`;

export const RequestErrorMessageImage = styled(Image)`
  padding-top: ${spaces('sm')};
  max-width: 100%;
`;
