/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';

import * as favoriteActions from '../../../favoriteRecentGames/store/actions';
import * as gameListActions from '../../../gameList/store/actions';
import * as searchGamesActions from '../../../search/store/actions';
import * as startGameActions from '../../../startGame/store/actions';
import { GamingActions } from '../../../store/types';
import { CommonEntitiesState } from '../types';

export const initialState: CommonEntitiesState = {
  games: {},
};

export const commonEntitiesReducer = (
  state: CommonEntitiesState = initialState,
  action: GamingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(gameListActions.fetchGames.success):
      case getType(searchGamesActions.searchGames.success): {
        // The fetchGames is giving us some games with the property isFavorite at true,
        // even if the property should be false.

        // To avoid this problem, we only merge the different games that fetchGames could give us,
        // so when we call getGame (as an example), the right state of the game will still be in entities store slice
        // and won't be erase by fetchGames result.

        const currentGameIds = Object.keys(draftState.games);
        const gamesToUpdate = Object.entries(action.payload.data.entities.games)
          .filter(([id]) => !currentGameIds.includes(id))
          .reduce<CommonEntitiesState['games']>((acc, [id, data]) => {
            acc[id] = data;
            return acc;
          }, {});

        draftState.games = {
          ...draftState.games,
          ...gamesToUpdate,
        };

        break;
      }

      case getType(favoriteActions.fetchFavoriteAndRecentGames.success): {
        draftState.games = {
          ...draftState.games,
          ...action.payload.entities.favoriteRecentGames,
        };

        break;
      }

      case getType(favoriteActions.setFavoriteGame.success): {
        const { gameId, isFavorite } = action.payload;
        const gameToSet = {
          ...draftState.games[gameId],
          IsFavorite: isFavorite,
        };
        draftState.games = {
          ...draftState.games,
          [gameId]: gameToSet,
        };

        break;
      }

      case getType(startGameActions.getGame.success): {
        const { Id } = action.payload;
        draftState.games = {
          ...draftState.games,
          [Id]: action.payload,
        };

        break;
      }

      default: // Immer will automatically return the state
    }
  });
