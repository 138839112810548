import { createAction, createAsyncAction } from 'typesafe-actions';

import { LocaleCode } from '@gaming1/g1-config';
import {
  EventType as EEventType,
  EventRequest,
  EventsForLeagueRequest,
  EventsForRegionRequest,
  FilterRequest,
  FullSearchRequest,
  LightSearchRequest,
  LightSearchResponse,
  LiveSportRequest,
  PrematchSportRequest,
  SearchCompetitionResult,
} from '@gaming1/g1-requests-betting';
import { FailurePayload } from '@gaming1/g1-utils';

import { EventState } from '../../common/store/types';

import {
  EventUpdateType,
  FilterType,
  GetTopEventsRequestType,
  MarketUpdateType,
  NormalizedEventData,
  NormalizedLeagueData,
  NormalizedMarketData,
  NormalizedPlayerPropData,
  NormalizedSportData,
  OutcomeType,
  OutcomeUpdateType,
  PlayerPropUpdateType,
  SCMGroup,
} from './types';

/**
 * Clean the sportbook component part of the store for a specified key.
 * Can be used to avoid useless renders
 */
export const cleanSportbookComponent = createAction(
  'betting/clean_sportbook_component',
)<{
  key: string;
}>();

/**
 * Clean the light search competitions and events
 * Has to be used when the searchTerm length is below the minimum characters threshold
 */
export const clearLightSearch = createAction('betting/clean_light_search')();

/**
 * Get a list of live events for a sport
 */
export const getLives = createAsyncAction(
  'betting/get_lives_request',
  'betting/get_lives_success',
  'betting/get_lives_failure',
  'betting/get_lives_cancel',
)<
  { key: string; subscribeToUpdates: boolean },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string },
  { key: string; locale: LocaleCode }
>();

/**
 * Get the total live events count
 */
export const getLiveEventsCount = createAsyncAction(
  'betting/get_live_events_count_request',
  'betting/get_live_events_count_success',
  'betting/get_live_events_count_failure',
  'betting/get_live_events_count_cancel',
)<{ subscribeToUpdates: boolean }, number, FailurePayload, void>();

/* Subscriptions */

/* Updates */

/** Add a new league */
export const addLeague = createAction('betting/add_league')<
  {
    key: string;
    isFullEvent: boolean;
  } & NormalizedLeagueData
>();
/** Add a new scm league */
export const addSCMInComponent = createAction('betting/add_scm_in_Component')<{
  key: string;
  scmGroups: {
    [id: number]: SCMGroup[];
  };
  type: EventState;
}>();

export const updateSCMInComponent = createAction(
  'betting/update_scm_in_component',
)<{
  key: string;
  id: number;
  type: EventState;
  oldIndex: number;
  newIndex: number;
}>();
/* Remove a league */
export const removeLeague = createAction('betting/remove_league')<{
  key: string;
  leagueId: number;
}>();

/** Add a new event */
export const addEvent = createAction('betting/add_event')<
  { key: string; isFullEvent: boolean } & NormalizedEventData
>();
/** Update an existing event  */
export const updateEvent = createAction('betting/update_event')<
  EventUpdateType & { IdToUpdate: number }
>();
/* Remove an event */
export const removeEvent = createAction('betting/remove_event')<{
  key: string;
  eventId: number;
  leagueId: number;
}>();

/** Add a new market */
export const addMarket = createAction('betting/add_market')<
  { isFullEvent: boolean } & NormalizedMarketData
>();
/** Add a new playerProp market */
export const addPlayerMarket = createAction('betting/add_player_market')<
  { playerPropId: number } & NormalizedMarketData
>();
/** Update an existing market  */
export const updateMarket = createAction('betting/update_market')<
  MarketUpdateType & { IdToUpdate: number }
>();
/* Remove a market */
export const removeMarket = createAction('betting/remove_market')<{
  isFullEvent: boolean;
  marketId: number;
  eventId: number;
}>();
/* Remove a playerProp market */
export const removePlayerMarket = createAction('betting/remove_player_market')<{
  marketId: number;
  playerPropId: number;
}>();

/** Add a new outcome */
export const addOutcome = createAction('betting/add_outcome')<{
  outcome: OutcomeType;
  marketId: number;
}>();
/** Update an existing outcome  */
export const updateOutcome = createAction('betting/update_outcome')<
  OutcomeUpdateType & { IdToUpdate: number; EventId: number }
>();
/* Remove a outcome */
export const removeOutcome = createAction('betting/remove_outcome')<{
  outcomeId: number;
  marketId: number;
}>();

/** Add a new playerProp */
export const addPlayerProp = createAction(
  'betting/add_playerprop',
)<NormalizedPlayerPropData>();
/** Update an existing playerProp  */
export const updatePlayerProp = createAction(
  'betting/update_playerprop',
)<PlayerPropUpdateType>();
/* Remove a playerProp */
export const removePlayerProp = createAction('betting/remove_playerprop')<{
  playerPropId: number;
  eventId: number;
}>();

/** Update the total live event count via multicast update */
export const updateLiveEventsCount = createAction(
  'betting/update_live_events_count',
)<number>();

/** Listen to outcome push */
export const subscribeToOutcome = createAction('betting/subscribe_to_outcome')<{
  outcomeId: number;
}>();

/** Unlisten to outcome push */
export const unsubscribeToOutcome = createAction(
  'betting/unsubscribe_to_outcome',
)<{
  outcomeId: number;
}>();

/**
 * Get the total live count by sport
 */
export const getLiveCountBySport = createAsyncAction(
  'betting/get_live_count_by_sport_request',
  'betting/get_live_count_by_sport_success',
  'betting/get_live_count_by_sport_failure',
  'betting/get_live_count_by_sport_cancel',
)<
  { subscribeToUpdates: boolean },
  { Id: number; Count: number }[],
  FailurePayload,
  void
>();

/**
 * Ask sports if no request currently pending
 */
export const askSports = createAction('betting/ask_sports_request')<void>();

/**
 * Get sports
 */
export const getSports = createAsyncAction(
  'betting/get_sports_request',
  'betting/get_sports_success',
  'betting/get_sports_failure',
)<void, NormalizedSportData, FailurePayload>();

/**
 * Get top events
 */
export const getTopEvents = createAsyncAction(
  'betting/get_top_events_request',
  'betting/get_top_events_success',
  'betting/get_top_events_failure',
  'betting/get_top_events_cancel',
)<
  GetTopEventsRequestType & { key: string; subscribeToUpdates: boolean },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string },
  { key: string; EventType: EEventType; locale: LocaleCode }
>();

/**
 * Get filters
 */
export const getFilters = createAsyncAction(
  'betting/get_filters_request',
  'betting/get_filters_success',
  'betting/get_filters_failure',
)<FilterRequest, { filters: FilterType[]; sportId: number }, FailurePayload>();

/**
 * Get prematch Sports
 */
export const getPrematchSport = createAsyncAction(
  'betting/get_prematch_sport_request',
  'betting/get_prematch_sport_success',
  'betting/get_prematch_sport_failure',
)<
  PrematchSportRequest & { key: string },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string }
>();

/** Listen to push updating a prematch sport */
export const subscribeToPrematchSportUpdates = createAction(
  'betting/subscribe_to_prematch_sport_updates',
)<PrematchSportRequest & { key: string }>();

/** Stop listening to push updating a prematch sport */
export const unsubscribeToPrematchSportUpdates = createAction(
  'betting/unsubscribe_to_prematch_sport_updates',
)<{
  sportId: number;
  key: string;
  locale: LocaleCode;
}>();

/**
 * Get live Sports
 */
export const getLiveSport = createAsyncAction(
  'betting/get_live_sport_request',
  'betting/get_live_sport_success',
  'betting/get_live_sport_failure',
  'betting/get_live_sport_cancel',
)<
  LiveSportRequest & { key: string; subscribeToUpdates: boolean },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string },
  {
    sportId: number;
    key: string;
    locale: LocaleCode;
  }
>();

/**
 * Get event
 */
export const getEvent = createAsyncAction(
  'betting/get_event_request',
  'betting/get_event_success',
  'betting/get_event_failure',
  'betting/get_event_cancel',
)<
  EventRequest & { key: string; subscribeToUpdates: boolean },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string },
  {
    eventId: number;
    key: string;
    locale: LocaleCode;
  }
>();

/** Get league */
export const getLeague = createAsyncAction(
  'betting/get_league_request',
  'betting/get_league_success',
  'betting/get_league_failure',
  'betting/get_league_cancel',
)<
  EventsForLeagueRequest & { key: string; subscribeToUpdates: boolean },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string },
  { leagueId: number; key: string; locale: LocaleCode }
>();

/** Get Region */
export const getRegion = createAsyncAction(
  'betting/get_region_request',
  'betting/get_region_success',
  'betting/get_region_failure',
  'betting/get_region_cancel',
)<
  EventsForRegionRequest & { key: string; subscribeToUpdates: boolean },
  NormalizedLeagueData & { key: string },
  FailurePayload & { key: string },
  { sportId: number; regionId: number; key: string; locale: LocaleCode }
>();

/** Search */
export const lightSearch = createAsyncAction(
  'betting/light_search_request',
  'betting/light_search_success',
  'betting/light_search_failure',
)<LightSearchRequest, LightSearchResponse, FailurePayload>();

export const betSearch = createAction(
  'betting/bet_search',
  (payload: { SearchTerm: string; key: string }) => payload,
)();

export const fullSearch = createAsyncAction(
  'betting/full_search_request',
  'betting/full_search_success',
  'betting/full_search_failure',
  'betting/full_search_cancel',
)<
  FullSearchRequest & { key: string },
  NormalizedLeagueData & {
    SearchTokens: string[];
    competitions: SearchCompetitionResult;
  } & {
    key: string;
  },
  FailurePayload & { key: string },
  { key: string }
>();
