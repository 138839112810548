import React, { FC, useLayoutEffect, useRef } from 'react';
import { useField } from 'react-final-form';

import { UserField } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Button } from '@gaming1/g1-ui';
import { PinCodeProps } from '@gaming1/g1-user-api';

import { PIN_CODE_LENGTH, PinCodeField } from '../PinCodeField';
import { NewPinCodeField } from '../PinCodeField/NewPinCodeField';

import {
  PinCodeError,
  PinCodeLabel,
  PinCodeText,
  PinCodeTitle,
} from './styles';

/** Multifactor authentication pin code */
export const PinCode: FC<PinCodeProps> = ({
  errorMessage,
  loading,
  resetMode,
}) => {
  const { t } = useTranslation('user');
  const { input } = useField(UserField.pinCode);
  const { input: newInput } = useField('newPinCode');
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Put the focus on the first PIN code field
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {resetMode ? (
        <>
          <Box alignItems="center">
            <PinCodeTitle>
              {t('multifactorAuthentication.pinCodeReset.title')}
            </PinCodeTitle>
          </Box>
          <Box mt="md">
            <PinCodeLabel>
              {t('multifactorAuthentication.pinCodeReset.currentCode')}
            </PinCodeLabel>
            <PinCodeField ref={inputRef} mt={0} required />
          </Box>
          <Box mt="md">
            <PinCodeLabel>
              {t('multifactorAuthentication.pinCodeReset.newCode')}
            </PinCodeLabel>
            <NewPinCodeField mt={0} required />
          </Box>
          {errorMessage && <PinCodeError>{errorMessage}</PinCodeError>}
        </>
      ) : (
        <Box alignItems="center">
          <PinCodeTitle>
            {t('multifactorAuthentication.pinCode.title')}
          </PinCodeTitle>
          <PinCodeText data-testid="pin-code-text">
            {t('multifactorAuthentication.pinCode.text')}
          </PinCodeText>
          <PinCodeField ref={inputRef} required />
          {errorMessage && <PinCodeError>{errorMessage}</PinCodeError>}
        </Box>
      )}
      <Button
        block
        disabled={
          resetMode
            ? input.value.length < PIN_CODE_LENGTH ||
              newInput.value.length < PIN_CODE_LENGTH
            : input.value.length < PIN_CODE_LENGTH
        }
        loading={loading}
        mt="md"
        testId="pin-code-button"
        type="submit"
      >
        {t('multifactorAuthentication.pinCode.confirm')}
      </Button>
    </>
  );
};
