import styled from 'styled-components';

import {
  colors,
  elevation,
  fontSizes,
  media,
  spaces,
  zIndex,
} from '@gaming1/g1-style';

import { BOTTOM_NAV_HEIGHT } from '../../../styles';

const BANNER_MAX_WIDTH = 768;

const ICON_WIDTH = 16;
const ICON_HEIGHT = 16;

const WRAPPER_BOTTOM_OFFSET = 3;

export const CookieMessageWrapper = styled.div`
  ${elevation(4)};
  position: fixed;
  width: 100%;
  border-radius: 4px;
  z-index: ${zIndex('cookieMessage')};
  background: ${colors('backgrounds', { index: 1 })};
  margin-bottom: calc((${BOTTOM_NAV_HEIGHT}px + ${WRAPPER_BOTTOM_OFFSET}px));
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  ${media.lg} {
    max-width: ${BANNER_MAX_WIDTH}px;
  }

  ${media.xl} {
    margin-left: ${spaces('xs')};
    margin-right: ${spaces('xs')};
  }
`;

export const CookieMessageContent = styled.div`
  display: flex;
  margin-left: ${spaces('sm')};
  margin-top: ${spaces('sm')};

  ${media.sm} {
    flex-direction: row;
    margin: ${spaces('sm')};
  }
`;

export const PrivacyPolicyLinkWrapper = styled.div`
  font-size: ${fontSizes('md')};
  margin-right: ${spaces('xxxxl')};
`;

export const IconWrapper = styled.div`
  width: ${ICON_WIDTH}px;
  height: ${ICON_HEIGHT}px;
  margin-right: ${spaces('sm')};
`;

export const CookieMessageButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: ${spaces('md')};
  padding-top: ${spaces('xs')};
  padding-bottom: ${spaces('sm')};

  ${media.sm} {
    align-items: center;
    padding: ${spaces('none')};
    margin: ${spaces('none')};
  }
`;
