export const stringifyRequestContent = (
  content: unknown,
  prettyPrint = false,
) => {
  let stringifiedContent = '[Could not stringify content!]';
  try {
    stringifiedContent = JSON.stringify(
      content,
      null,
      prettyPrint ? 2 : undefined,
    );
  } catch (e) {
    // Silenty ignore
  }
  return stringifiedContent === '{}' ? '' : stringifiedContent;
};
