import format from 'date-fns/format';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { useFormatMoney, useShouldRenderRequestError } from '@gaming1/g1-core';
import {
  RequestStateErrorMessage,
  useNotifications,
} from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  cancelWithdrawalRequestStateSelector,
  getWithdrawalHistoryRequestStateSelector,
  paymentActions,
  Withdraw,
  withdrawalHistorySelector,
} from '@gaming1/g1-payment';
import { useRequestState } from '@gaming1/g1-store';
import {
  Button,
  Icon,
  Loader,
  Message,
  RenderOnMedia,
  SimpleButton,
  Table,
} from '@gaming1/g1-ui';
import { HISTORY_PAGE_SIZE } from '@gaming1/g1-user-api';
import { useRequestCallback } from '@gaming1/g1-utils';

import { withdrawalHistoryTransactionKeys } from '../../helpers';
import { WithdrawalHistoryTransactionState } from '../../types';
import { WithdrawalCancelModal } from '../WithdrawalCancelModal';
import { WithdrawalHistoryInformationModal } from '../WithdrawalHistoryInformationModal';

const isValidStatus = (
  num: number,
): num is keyof typeof withdrawalHistoryTransactionKeys =>
  num in withdrawalHistoryTransactionKeys;

export const WithdrawalHistory: FC = () => {
  const { t } = useTranslation('payment');
  const { colors } = useContext(ThemeContext);
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();
  const formatMoney = useFormatMoney();
  const { i18n } = useContext(ConfigContext);

  const withdrawalHistory = useSelector(withdrawalHistorySelector);
  const { isFirstLoading, isLoading: isWithdrawalHistoryLoading } =
    useRequestState(getWithdrawalHistoryRequestStateSelector);
  const { pagination: getWithdrawalHistoryPagination } = useSelector(
    getWithdrawalHistoryRequestStateSelector,
  );
  const {
    errorMessage: cancelWithdrawalErrorMessage,
    isLoading: isCancelWithdrawalLoading,
    status: cancelWithdrawalStatus,
  } = useRequestState(cancelWithdrawalRequestStateSelector);

  const [selectedWithdrawalForInfo, setSelectedWithdrawalForInfo] =
    useState<Withdraw | null>();
  const [informationModalVisible, setInformationModalVisible] = useState(false);

  const [selectedWithdrawalForCancel, setSelectedWithdrawalForCancel] =
    useState<Withdraw | null>();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const onCloseCancelModal = () => {
    setCancelModalVisible(false);
    setSelectedWithdrawalForCancel(null);
  };
  const onCancelWithdrawalClicked = (id: string) => {
    dispatch(
      paymentActions.cancelWithdrawal.request({
        WithdrawId: id,
      }),
    );
  };

  const getHistory = useCallback(() => {
    dispatch(
      paymentActions.getWithdrawalHistory.request({
        PageNumber: 0,
        PageSize: HISTORY_PAGE_SIZE,
        IncludeTotal: true,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  useRequestCallback(
    cancelWithdrawalStatus,
    () => {
      onCloseCancelModal();
      addNotification({
        message: t('withdrawal.cancel.message.success'),
        type: 'success',
      });
      getHistory();
    },
    () => {
      onCloseCancelModal();
      addNotification({
        message: t(cancelWithdrawalErrorMessage || 'account.error'),
        type: 'danger',
      });
      getHistory();
    },
  );

  const shouldRenderRequestError = useShouldRenderRequestError(
    getWithdrawalHistoryRequestStateSelector,
  );

  if (shouldRenderRequestError) {
    return (
      <RequestStateErrorMessage
        onRetryButtonClick={getHistory}
        selector={getWithdrawalHistoryRequestStateSelector}
      />
    );
  }

  if (isFirstLoading) {
    return <Loader mt="sm" />;
  }

  return withdrawalHistory.length > 0 ? (
    <>
      <Table testId="withdrawal-history-container">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{t('history.withdrawal.withdrawDate')}</th>
            <RenderOnMedia min="lg">
              <th>{t('history.withdrawal.reference')}</th>
              <th>{t('history.withdrawal.status')}</th>
            </RenderOnMedia>
            <th>{t('history.withdrawal.amount')}</th>
            <th>&nbsp;</th>
            <RenderOnMedia max="md">
              <th>&nbsp;</th>
            </RenderOnMedia>
          </tr>
        </thead>
        <tbody>
          {withdrawalHistory.map((history, index) => (
            <tr key={history.Reference}>
              <td>
                {(history.TransactionState ===
                  WithdrawalHistoryTransactionState.Accepted && (
                  <Icon
                    id="history-completed"
                    type="CircleCheck"
                    fill={colors.success}
                  />
                )) ||
                  (history.TransactionState ===
                    WithdrawalHistoryTransactionState.Pending && (
                    <Icon
                      id="history-pending"
                      type="Clock"
                      fill={colors.warning}
                    />
                  )) || (
                    <Icon
                      id="history-notCompleted"
                      type="CircleStop"
                      fill={colors.danger}
                    />
                  )}
              </td>
              <td>
                {format(
                  new Date(history.WithdrawDate),
                  i18n.dateTimePartialFormat,
                )}
              </td>
              <RenderOnMedia min="lg">
                <td>{history.Reference}</td>
                <td>
                  {isValidStatus(history.TransactionState) &&
                    t(
                      withdrawalHistoryTransactionKeys[
                        history.TransactionState
                      ],
                    )}
                </td>
              </RenderOnMedia>
              <td>{formatMoney(history.Amount)}</td>
              <RenderOnMedia min="lg">
                <td>{history.ExtraInfo}</td>
              </RenderOnMedia>
              <td>
                {history.CanCancelWithdraw && history.WithdrawId && (
                  <SimpleButton
                    data-testid="withdrawal-history-cancel-button"
                    onClick={() => {
                      setSelectedWithdrawalForCancel(history);
                      setCancelModalVisible(true);
                    }}
                  >
                    <Icon id="history-cancel" type="Trash" height="20px" />
                  </SimpleButton>
                )}
              </td>
              <RenderOnMedia max="md">
                <td>
                  <SimpleButton
                    data-testid={`withdrawal-history-information-button-${index}`}
                    onClick={() => {
                      setSelectedWithdrawalForInfo(history);
                      setInformationModalVisible(true);
                    }}
                  >
                    <Icon
                      id="history-info"
                      type="VerticalTripleDots"
                      height="20px"
                    />
                  </SimpleButton>
                </td>
              </RenderOnMedia>
            </tr>
          ))}
        </tbody>
      </Table>

      {withdrawalHistory.length < getWithdrawalHistoryPagination.Total && (
        <Button
          block
          loading={isWithdrawalHistoryLoading}
          noBorder
          onClick={() => {
            dispatch(
              paymentActions.getWithdrawalHistory.request({
                PageNumber: getWithdrawalHistoryPagination.PageNumber + 1,
                PageSize: getWithdrawalHistoryPagination.PageSize,
                IncludeTotal: true,
              }),
            );
          }}
          testId="withdrawal-history-more-button"
          type="secondary"
        >
          {t('history.seeMore')}
        </Button>
      )}
      {selectedWithdrawalForInfo && (
        <WithdrawalHistoryInformationModal
          history={selectedWithdrawalForInfo}
          onClose={() => {
            setInformationModalVisible(false);
            setSelectedWithdrawalForInfo(null);
          }}
          visible={informationModalVisible}
        />
      )}
      {selectedWithdrawalForCancel && (
        <WithdrawalCancelModal
          isLoading={isCancelWithdrawalLoading}
          withdrawal={selectedWithdrawalForCancel}
          onCancelWithdraw={onCancelWithdrawalClicked}
          onClose={onCloseCancelModal}
          visible={cancelModalVisible}
        />
      )}
    </>
  ) : (
    <Message mt="sm" type="warning">
      {t('history.noResults')}
    </Message>
  );
};
