import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateFailureRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
} from '@gaming1/g1-utils';

import { CmsActions } from '../../store/types';

import * as actions from './actions';
import { PrismicPageContentState } from './types';

export const initialState: PrismicPageContentState = {
  requests: {},
  entities: {
    documents: {},
  },
};

export const prismicPageContentReducer = (
  state: PrismicPageContentState = initialState,
  action: CmsActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchPageContentDocument.request):
        draftState.requests[action.payload] = generateLoadingRequestState(
          state.requests[action.payload]?.result || [],
        );
        break;

      case getType(actions.fetchPageContentDocument.failure):
        draftState.requests[action.payload.request] =
          generateFailureRequestState(
            action.payload.error,
            state.requests[action.payload.request]?.result || [],
          );
        break;

      case getType(actions.fetchPageContentDocument.success):
        action.payload.data.results.forEach((result) => {
          draftState.entities.documents[result.id] = result;
        });
        draftState.requests[action.payload.request] =
          generateSuccessRequestState(
            action.payload.data.results.map((result) => result.id),
          );
        break;

      // Single page content
      case getType(actions.fetchSinglePageContentDocument.request):
        draftState.requests[action.payload] = generateLoadingRequestState(
          state.requests[action.payload]?.result || [],
        );
        break;

      case getType(actions.fetchSinglePageContentDocument.failure):
        draftState.requests[action.payload.request] =
          generateFailureRequestState(
            action.payload.error,
            state.requests[action.payload.request]?.result || [],
          );
        break;

      case getType(actions.fetchSinglePageContentDocument.success):
        action.payload.data.results.forEach((result) => {
          draftState.entities.documents[result.id] = result;
        });
        draftState.requests[action.payload.request] =
          generateSuccessRequestState(
            action.payload.data.results.map((result) => result.id),
          );
        break;
      default: // Immer will automatically returns the state
    }
  });
