import styled, { css } from 'styled-components';

import { colors, shadows, Theme } from '@gaming1/g1-style';

import { Box } from '../Box';

export const CollapsibleContainer = styled(Box)`
  background-color: ${({ theme }) =>
    theme.dark ? theme.colors.backgrounds[2] : theme.colors.backgroundLight};
  box-shadow: ${shadows(1)};
`;

type CollapsibleContentProps = {
  isVisible: boolean;
};

export const CollapsibleContent = styled.div<CollapsibleContentProps>`
  display: none;
  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `};
`;

type CollapsibleTitleProps = {
  isVisible: boolean;
  textColor?: keyof Theme['colors'];
};

export const CollapsibleMainContent = styled(Box)<CollapsibleTitleProps>`
  cursor: pointer;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${colors(textColor)};
      svg[data-testid='collapsible-icon'] {
        path {
          fill: ${colors(textColor)};
        }
      }
    `}

  svg[data-testid="collapsible-icon"] {
    transition: all ease-out 0.3s;

    ${({ isVisible }) =>
      isVisible &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;
