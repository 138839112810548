import { ActionCreator, ActionType, isActionOf } from 'typesafe-actions';

import { BetPipeType } from '@gaming1/g1-config';
import { EBettingSlipType } from '@gaming1/g1-requests-betting';
import { generateInitialRequestState } from '@gaming1/g1-utils';

import { bettingSlipTypeGetterSelector } from '../bettingSlip/store/selectors/common';
import {
  BettingSlipIdentifierType,
  BettingSlipStringType,
} from '../common/store/types';
import { ApplicationWithBettingState, BettingActions } from '../store/types';

import * as betPipeActions from './actions';
import { extractBettingSlipId } from './epics/helpers';
import { BetPipeIdentifier, BetPipeState } from './types';

type ActionWithBetPipeId<BettingAction extends BettingActions> =
  BettingAction & {
    payload: { betPipeId: string };
  };

/** Returns true if the action has a betPipeId string property in its payload */
export const isActionWithBetPipeId = <BettingAction extends BettingActions>(
  action: BettingAction,
): action is ActionWithBetPipeId<BettingAction> =>
  'payload' in action &&
  typeof (action as ActionWithBetPipeId<BettingAction>).payload === 'object' &&
  'betPipeId' in (action as ActionWithBetPipeId<BettingAction>).payload &&
  typeof (action as ActionWithBetPipeId<BettingAction>)?.payload.betPipeId ===
    'string';

/**
 * A list of all actions creators exported in ./actions
 */
const betPipeActionCreators: ActionCreator[] = Object.values(betPipeActions)
  .map((actionCreator) => {
    if ('request' in actionCreator) {
      return [
        actionCreator.request,
        actionCreator.failure,
        actionCreator.success,
      ];
    }
    return [actionCreator];
  })
  .reduce((acc, actions) => [...acc, ...actions], [] as ActionCreator[]);

type BetPipeAction = ActionType<typeof betPipeActions>;
/** Returns true if the action is one of the pipe actions defined in ./actions */
export const isBetPipeAction = (
  action: BettingActions,
): action is BetPipeAction => isActionOf(betPipeActionCreators)(action);

/** Returns the betPipeId property from an action payload or undefined */
export const extractBetPipeIdFromAction = (
  action: BettingActions,
): string | undefined =>
  isBetPipeAction(action) && 'betPipeId' in action.payload
    ? action.payload.betPipeId
    : undefined;

/** Returns the initial state of a single bet pipe */
export const generateInitialPipeState = ({
  bettingSlipId,
  type,
}: {
  bettingSlipId: BettingSlipIdentifierType;
  type: BetPipeType;
}): BetPipeState => ({
  bettingSlipId,
  status: 'started',
  requests: {
    resetBettingSlip: generateInitialRequestState(),
    getBettingSlipInitializationData: generateInitialRequestState(),
    getFreebetConditionsForBettingSlip: generateInitialRequestState(),
    getBoostusConditionsForBettingSlip: generateInitialRequestState(),
    getPromotionsIdsForBettingSlip: generateInitialRequestState(),
    getOutcomeInfos: generateInitialRequestState(),
    getOptimalBet: generateInitialRequestState(),
    getRiskManagement: generateInitialRequestState(),
    placeBettingSlip: generateInitialRequestState(),
  },
  type,
});

/** Extract a BetPipeIdentifier form a bet pipe action payload */
export const getBetPipeIdentifierFromActionPayload = (
  payload: BetPipeIdentifier,
) => {
  if ('betPipeId' in payload) {
    return { betPipeId: payload.betPipeId };
  }
  return { bettingSlipId: payload.bettingSlipId };
};

/** Get a EBettingSlipType using a BettingSlipType */
export const getBettingSlipTypeEnumValue = (
  type: BettingSlipStringType,
): EBettingSlipType => {
  switch (type) {
    case 'single':
      return EBettingSlipType.Single;
    case 'combi':
      return EBettingSlipType.Combi;
    case 'system':
      return EBettingSlipType.System;
    default:
      // Should not happen
      return EBettingSlipType.Unknown;
  }
};

export const extractInformationForBettingSlip = (
  payload: BetPipeIdentifier & { bettingSlipType?: BettingSlipStringType },
  state: ApplicationWithBettingState,
): {
  bettingSlipId: string;
  betPipeId: string;
  bettingSlipType: BettingSlipStringType;
} => {
  const betPipeId =
    getBetPipeIdentifierFromActionPayload(payload).betPipeId || '';
  const bettingSlipId = extractBettingSlipId(payload, betPipeId, state) || '';
  const bettingSlipType =
    payload.bettingSlipType ||
    bettingSlipTypeGetterSelector(state)(bettingSlipId);

  return {
    betPipeId,
    bettingSlipId,
    bettingSlipType,
  };
};

type formatActionsDataType<T> = {
  data: T;
  bettingSlipId: string;
  betPipeId: string;
};
export const formatActionsData = <T>(
  info: formatActionsDataType<T>,
): T & { bettingSlipId: string; betPipeId: string } => ({
  ...info.data,
  ...{
    bettingSlipId: info.bettingSlipId,
    betPipeId: info.betPipeId,
  },
});
