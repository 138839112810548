import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  generatePaginatedInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as actions from './actions';
import { TournamentState } from './types';

export const initialState: TournamentState = {
  requests: {
    getActiveTournaments: generatePaginatedInitialRequestState([]),
    getFinishedTournaments: generatePaginatedInitialRequestState([]),
    getRunningTournaments: generatePaginatedInitialRequestState([]),
    getTournamentDetails: generateInitialRequestState(null),
    getTournament: generateInitialRequestState(null),
    getTournamentList: generatePaginatedInitialRequestState([]),
    getTournamentPlayerRanking: generateInitialRequestState(null),
    getTournamentRanking: generatePaginatedInitialRequestState([]),
    registerTournament: {
      ...generateInitialRequestState(null),
      message: undefined,
    },
  },
  entities: {},
  playerRanking: null,
  tournamentDetails: null,
  tournamentList: null,
  tournamentRanking: null,
};

export const tournamentReducer = (
  state: TournamentState = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** getTournamentList */
      case getType(actions.getTournamentList.request):
        produceLoadingState(draftState.requests.getTournamentList);
        break;

      case getType(actions.getTournamentList.failure):
        produceFailureState(
          draftState.requests.getTournamentList,
          action.payload,
        );
        break;

      case getType(actions.getTournamentList.success):
        produceSuccessState(draftState.requests.getTournamentList);

        // update list
        draftState.tournamentList =
          action.payload.pagination.PageNumber > 0 && state.tournamentList
            ? [...state.tournamentList, ...action.payload.list]
            : action.payload.list;

        // update pagination
        draftState.requests.getTournamentList.pagination =
          action.payload.pagination;
        break;

      /** getRunningTournaments */
      case getType(actions.getRunningTournaments.request):
        produceLoadingState(draftState.requests.getRunningTournaments);
        break;

      case getType(actions.getRunningTournaments.failure):
        produceFailureState(
          draftState.requests.getRunningTournaments,
          action.payload,
        );
        break;

      case getType(actions.getRunningTournaments.success):
        produceSuccessState(
          draftState.requests.getRunningTournaments,
          action.payload.list.map((t) => t.Id),
        );
        action.payload.list.forEach((tournament) => {
          draftState.entities[tournament.Id] = tournament;
        });
        draftState.requests.getRunningTournaments.pagination =
          action.payload.pagination;
        break;

      /** getFinishedTournaments */
      case getType(actions.getFinishedTournaments.request):
        produceLoadingState(draftState.requests.getFinishedTournaments);
        break;

      case getType(actions.getFinishedTournaments.failure):
        produceFailureState(
          draftState.requests.getFinishedTournaments,
          action.payload,
        );
        break;

      case getType(actions.getFinishedTournaments.success):
        produceSuccessState(
          draftState.requests.getFinishedTournaments,
          action.payload.list.map((t) => t.Id),
        );
        action.payload.list.forEach((tournament) => {
          draftState.entities[tournament.Id] = tournament;
        });
        draftState.requests.getFinishedTournaments.pagination =
          action.payload.pagination;
        break;

      /** getActiveTournaments */
      case getType(actions.getActiveTournaments.request):
        produceLoadingState(draftState.requests.getActiveTournaments);
        break;

      case getType(actions.getActiveTournaments.failure):
        produceFailureState(
          draftState.requests.getActiveTournaments,
          action.payload,
        );
        break;

      case getType(actions.getActiveTournaments.success):
        produceSuccessState(
          draftState.requests.getActiveTournaments,
          action.payload.list.map((t) => t.Id),
        );
        action.payload.list.forEach((tournament) => {
          draftState.entities[tournament.Id] = tournament;
        });
        draftState.requests.getActiveTournaments.pagination =
          action.payload.pagination;
        break;

      /** getTournament */
      case getType(actions.getTournament.request):
        produceLoadingState(draftState.requests.getTournament);
        break;

      case getType(actions.getTournament.failure):
        produceFailureState(draftState.requests.getTournament, action.payload);
        break;

      case getType(actions.getTournament.success):
        produceSuccessState(draftState.requests.getTournament);
        draftState.entities[action.payload.Tournament.Id] =
          action.payload.Tournament;
        break;

      /** getTournamentDetails */
      case getType(actions.getTournamentDetails.request):
        produceLoadingState(draftState.requests.getTournamentDetails);
        break;

      case getType(actions.getTournamentDetails.failure):
        produceFailureState(
          draftState.requests.getTournamentDetails,
          action.payload,
        );
        break;

      case getType(actions.getTournamentDetails.success):
        produceSuccessState(draftState.requests.getTournamentDetails);
        draftState.tournamentDetails = action.payload;
        break;

      /** getTournamentRanking */
      case getType(actions.getTournamentRanking.request):
        produceLoadingState(draftState.requests.getTournamentRanking);
        break;

      case getType(actions.getTournamentRanking.failure):
        produceFailureState(
          draftState.requests.getTournamentRanking,
          action.payload,
        );
        break;

      case getType(actions.getTournamentRanking.success):
        produceSuccessState(draftState.requests.getTournamentRanking);

        // update
        draftState.tournamentRanking =
          action.payload.pagination.PageNumber > 0 && state.tournamentRanking
            ? [...state.tournamentRanking, ...action.payload.list]
            : action.payload.list;

        // update pagination
        draftState.requests.getTournamentRanking.pagination =
          action.payload.pagination;
        break;

      /** getTournamentPlayerRanking */
      case getType(actions.getTournamentPlayerRanking.request):
        produceLoadingState(draftState.requests.getTournamentPlayerRanking);
        break;

      case getType(actions.getTournamentPlayerRanking.failure):
        produceFailureState(
          draftState.requests.getTournamentPlayerRanking,
          action.payload,
        );
        break;

      case getType(actions.getTournamentPlayerRanking.success):
        produceSuccessState(draftState.requests.getTournamentPlayerRanking);
        draftState.playerRanking = action.payload;
        break;

      /** registerTournament */
      case getType(actions.registerTournament.request):
        produceLoadingState(draftState.requests.registerTournament);
        break;

      case getType(actions.registerTournament.failure):
        produceFailureState(
          draftState.requests.registerTournament,
          action.payload,
        );
        draftState.requests.registerTournament.message = action.payload.message;
        break;

      case getType(actions.registerTournament.success):
        produceSuccessState(draftState.requests.registerTournament);
        break;

      /** resetRegisterTournamentStatus */
      case getType(actions.resetRegisterTournamentStatus):
        draftState.requests.registerTournament = {
          ...generateInitialRequestState(null),
          message: undefined,
        };
        break;

      default: // Immer will automatically return the state
    }
  });
