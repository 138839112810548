import { ApplicationWithLoyaltyState } from '../types';

export const getVipInitializationRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.getVipInitialization;

export const vipInitializationSelector = (state: ApplicationWithLoyaltyState) =>
  state.loyalty.club.vipInitData;

export const playVipWheelRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.playVipWheel;

export const playVipWheelSelector = (state: ApplicationWithLoyaltyState) =>
  state.loyalty.club.vipWheelWonPrizeId;

export const playMonthlyBoxRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.playMonthlyBox;

export const playMonthlyBoxSelector = (state: ApplicationWithLoyaltyState) =>
  state.loyalty.club.playMonthlyBoxData;

export const getPlayerMonthlyBoxInfoRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.getPlayerMonthlyBoxInfo;

export const getPlayerMonthlyBoxInfoSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.playerMonthlyBoxInfo;

export const getPlayerWheelInfoRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.getPlayerWheelInfo;

export const getPlayerWheelInfoSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.playerWheelInfo;

export const playerVipRankInfoSelector = (state: ApplicationWithLoyaltyState) =>
  state.loyalty.club.playerVipRankInfo;

export const playerVipRankProgressionSelector = (
  state: ApplicationWithLoyaltyState,
) => {
  const playerVipRankInfo = playerVipRankInfoSelector(state);

  if (!playerVipRankInfo) {
    return 0;
  }

  const { CurrentStatus, NextStatus, Points } = playerVipRankInfo;

  if (!CurrentStatus) {
    return 0;
  }

  if (!NextStatus) {
    return 100;
  }

  return Math.floor(
    ((Points - CurrentStatus.Threshold) /
      (NextStatus.Threshold - CurrentStatus.Threshold)) *
      100,
  );
};

export const getPlayerVipRankInfoRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.getPlayerVipRankInfo;

export const getScratchcardDetailsRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.getScratchcardDetails;

export const getScratchcardDetailsSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.scratchcardDetails;

export const consumeScratchcardRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.consumeScratchcard;

export const getScratchCardPrizeInfoRequestStateSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.requests.getScratchCardPrizeInfo;

export const scratchCardPrizeInfoSelector = (
  state: ApplicationWithLoyaltyState,
) => state.loyalty.club.scratchCardPrizeInfo;
