import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getOptimalBet,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { formatOptimalBetPreparedRequest } from '../../bettingSlip/helpers/optimalBet';
import { helperBettingSlipIsValid } from '../../bettingSlip/helpers/validators';
import { getOptimalBetResponseCodec } from '../../bettingSlip/store/codecs/optimalBet';
import { isOptimalBetEnabledGetterSelector } from '../../bettingSlip/store/selectors';
import { bettingSlipItemsForValidationGetterSelector } from '../../bettingSlip/store/selectors/elements/items';
import { optimalBetPrepareGetterSelector } from '../../bettingSlip/store/selectors/optimalBet/prepareOptimalBet';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

export const getOptimalBetEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getOptimalBet.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const isOptimalBetEnabled = isOptimalBetEnabledGetterSelector(state)();

      const { betPipeId, bettingSlipId, bettingSlipType } =
        extractInformationForBettingSlip(payload, state);

      if (
        !isOptimalBetEnabled ||
        !bettingSlipId ||
        bettingSlipType === 'system'
      ) {
        return of(
          actions.getOptimalBet.success(
            formatActionsData({
              data: {
                Notification: {
                  Level: NotificationLevel.Information,
                  Code: NotificationCode.OptimalBet_InternalError,
                },
                ...payload,
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }

      const items =
        bettingSlipItemsForValidationGetterSelector(state)(bettingSlipId);
      const isValid = helperBettingSlipIsValid(items);
      if (!isValid) {
        return of(
          actions.getOptimalBet.failure(
            formatActionsData({
              data: {
                errorMessage: 'betting:bettingSlip.optimalbet.items.notValid',
                status: DEFAULT_SERVER_ERROR_STATUS,
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }

      const request = formatOptimalBetPreparedRequest(
        optimalBetPrepareGetterSelector(state)(bettingSlipId),
      );

      return wsAdapter.request(getOptimalBet(request)).pipe(
        mapGuard(getOptimalBetResponseCodec),
        map((data) =>
          actions.getOptimalBet.success(
            formatActionsData({
              data,
              bettingSlipId,
              betPipeId,
            }),
          ),
        ),
        catchError((err) =>
          of(
            actions.getOptimalBet.failure(
              formatActionsData({
                data: createFailurePayload(err, commonBettingErrorMessages),
                bettingSlipId,
                betPipeId,
              }),
            ),
          ),
        ),
      );
    }),
  );
