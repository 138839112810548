import React, { VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';

import { MenuGroups, MenuItem } from '../types';

import {
  BottomLinksAnchor,
  BottomLinksButton,
  BottomLinkSeparator,
  BottomLinksLink,
  LinksContainer,
  LinksRow,
} from './styles';

export type DrawerNavLinksProps = {
  /** List of menu entries to display */
  links: MenuGroups;
};

const DrawerNavLink: VFC<MenuItem> = ({ testId, label, ...props }) => {
  const { t } = useTranslation('core');

  if ('onClick' in props) {
    return (
      <BottomLinksButton onClick={props.onClick} key={`${testId}${label}`}>
        {t(label)}
      </BottomLinksButton>
    );
  }

  const { externalLink, exact, path } = props;

  return (
    <>
      {externalLink ? (
        <BottomLinksAnchor
          href={path}
          target="_href"
          rel="noopener"
          key={`${path}${label}`}
          data-testid={testId}
        >
          {t(label)}
        </BottomLinksAnchor>
      ) : (
        <BottomLinksLink
          exact={exact}
          to={path}
          key={`${path}${label}`}
          data-testid={testId}
        >
          {t(label)}
        </BottomLinksLink>
      )}
      <BottomLinkSeparator />
    </>
  );
};

export const Links: VFC<DrawerNavLinksProps> = ({ links }) => (
  <LinksContainer>
    {links.map((group) => (
      <LinksRow key={group[0].label}>
        {group.map((linkProps) => (
          <DrawerNavLink key={linkProps.label} {...linkProps} />
        ))}
      </LinksRow>
    ))}
  </LinksContainer>
);
