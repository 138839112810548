import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import {
  getPrivacyConditions,
  playerAcceptPrivacyConditions,
  showPrivacyConditions,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { UserEpic } from '../../store/types';

import * as actions from './actions';
import * as codecs from './codecs';
import * as errorMessages from './errorMessages';

/** Accept Privacy Conditions */
export const acceptPrivacyConditionsEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.acceptPrivacyConditions.request)),
    switchMap(() =>
      wsAdapter.request(playerAcceptPrivacyConditions({})).pipe(
        mapGuard(codecs.acceptPrivacyConditionsResponse),
        map(actions.acceptPrivacyConditions.success),
        catchError((err) =>
          of(
            actions.acceptPrivacyConditions.failure(createFailurePayload(err)),
          ),
        ),
      ),
    ),
  );

/** Get Privacy Conditions */
export const getPrivacyConditionsEpic: UserEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPrivacyConditions.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      wsAdapter
        .request(
          getPrivacyConditions({
            Language: currentWebSocketLocaleSelector(state),
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(codecs.getPrivacyConditionsResponse),
          map(actions.getPrivacyConditions.success),
          catchError((err) =>
            of(
              actions.getPrivacyConditions.failure(
                createFailurePayload(
                  err,
                  errorMessages.getPrivacyConditionsErrorMessages,
                ),
              ),
            ),
          ),
        ),
    ),
  );

/** Show Privacy Conditions */
export const showPrivacyConditionsEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.showPrivacyConditions.request)),
    switchMap(() =>
      wsAdapter.request(showPrivacyConditions({})).pipe(
        mapGuard(codecs.showPrivacyConditionsResponse),
        map(actions.showPrivacyConditions.success),
        catchError((err) =>
          of(actions.showPrivacyConditions.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );
