import { logger } from '../../../logger';
import { ApplicationWithBettingState } from '../../../store/types';
// import { ApplicationWithBettingState } from '../../../types';
import { eventSelector } from '../selectors';
import { SportbookUpdateType } from '../types';

/** Min delay needed between 2 resend if an incoherence detected after pushs received */
const INCOHERENCE_RESEND_DELAY_SEC = 30;

/**
 * Returns true if there is an incoherent store
 * eg. a market add witout related event in the same array of pushs
 * @param updates the EntityUpdates array from the push content
 * @param state the state to be able to check it
 */
const isStoreIncoherent = (
  updates: SportbookUpdateType[],
  state: ApplicationWithBettingState,
): boolean =>
  updates.some((update) => {
    const { MarketUpdate } = update;

    // Check if the market has been added on a non existing event
    if (MarketUpdate && MarketUpdate.NewEntity) {
      const market = MarketUpdate.NewEntity;
      const event = eventSelector(state)(market.EventId);

      if (!event) {
        logger.debug('Market added without event in the store', MarketUpdate);
        return true;
      }
    }

    return false;
  });

/**
 * Returns true if there is an incoherent store and the minimum delay since the last response received is elapsed
 * eg. a market add witout related event in the same array of pushs
 * @param updates the EntityUpdates array from the push content
 * @param state the state to be able to check it
 * @param lastReqSent the time of the last response received
 */
export const checkIncoherence = (
  updates: SportbookUpdateType[],
  state: ApplicationWithBettingState,
  lastReqSent: number,
): boolean => {
  const isIncoherent = isStoreIncoherent(updates, state);
  const elapsedSec = (new Date().getTime() - lastReqSent) / 1000;
  return isIncoherent && elapsedSec > INCOHERENCE_RESEND_DELAY_SEC;
};

/**
 * Adds information to payload to specify that the request should refresh but shouldn't display the loader on the page
 */
export const getRefreshPayload = <T>(payload: T): T & { refresh: true } => ({
  ...payload,
  refresh: true,
  subscribeToUpdates: false,
});
