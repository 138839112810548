import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { coreActions, CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  RequestState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../../../store/types';
import * as actions from '../../actions';
import { GameCategoriesResult } from '../../types';

export const initialState: RequestState<GameCategoriesResult> =
  generateInitialRequestState({
    gamesCategories: [],
  });

export const fetchGameCategoriesRequestReducer = (
  state = initialState,
  action: CoreActions | GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchGameCategories.request):
        produceLoadingState(draftState);
        break;

      case getType(actions.fetchGameCategories.failure):
        produceFailureState(draftState, action.payload);
        break;

      case getType(actions.fetchGameCategories.success):
        produceSuccessState(draftState, {
          gamesCategories: action.payload.result,
        });
        break;

      case getType(coreActions.switchLocale.success):
        return initialState;

      default: // Immer will automatically return the state
    }
  });
