import {
  defaultMethodFactory,
  getLoggers,
  isLoggingAllowed,
  LoggingMethod,
  MethodFactory,
} from '../loglevel';

/**
 * Basically MethodFactory but with the ability to return "false" to prevent
 * the log to be made
 */
type LogInterceptor = (
  ...logFactoryParams: Parameters<MethodFactory>
) => (...logParams: Parameters<LoggingMethod>) => boolean | undefined;

/** This method factory will call the default one then call the interceptor
 * with all the same params. The original method will be called unless the
 * interceptor prevent is by returning false
 */
const createMethodFactoryWithInterceptor =
  (logInterceptor: LogInterceptor): MethodFactory =>
  (logMethodName, currentLogLevel, loggerName) => {
    const rawMethod = defaultMethodFactory(
      logMethodName,
      currentLogLevel,
      loggerName,
    );
    return (...messages) => {
      // Call the interceptor in all cases
      const interceptorResult = logInterceptor(
        logMethodName,
        currentLogLevel,
        loggerName,
      )(...messages);
      if (
        interceptorResult === true ||
        (interceptorResult === undefined &&
          isLoggingAllowed(logMethodName, currentLogLevel))
      ) {
        // Call the real log method if the interceptor didn't prevent it
        rawMethod(...messages);
      }
    };
  };

/**
 * Setup a log interceptor that will receive all logs sent through loggers
 * @param {LogInterceptor} newLogInterceptor: a curried log interceptor that
 * will receive the method name, current level and logger name, then in the
 * second call every arguments sent through the log. Can return "false" to
 * prevent the print of the log to the console. If null, the default method
 * factory will be used
 * @param {string} loggerName: the optional logger name on which the interceptor
 * should be set. If empty, will be set on all existing loggers
 * @returns {boolean} if false, the log interceptor won't work because it is not
 * allowed (see above)
 */
export const setupLogInterceptor = (
  logInterceptor: LogInterceptor | null = null,
  loggerName?: string,
) => {
  const loggers = loggerName
    ? [getLoggers()[loggerName]].filter(Boolean)
    : Object.values(getLoggers());
  loggers.forEach((logger) => {
    // This will allow the factory to be called even if the log is muted
    logger.setShouldAlwaysCallMethod(!!logInterceptor);
    logger.setMethodFactory(
      logInterceptor
        ? createMethodFactoryWithInterceptor(logInterceptor)
        : defaultMethodFactory,
    );
  });
};
