import React, { Fragment, VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, RenderOnMedia } from '@gaming1/g1-ui';

import { desktopLayoutBreakpoint } from '../../constants';
import { MenuGroups } from '../types';

import {
  TopNavMenuAnchor,
  TopNavMenuButton,
  TopNavMenuContainer,
  TopNavMenuLink,
  VerticalSeparator,
} from './styles';

export type TopNavMenuProps = {
  /** The menu groups */
  menu: MenuGroups;
};

/** The menu display on lg screens in the TopNav */
export const Menu: VFC<TopNavMenuProps> = ({ menu }) => {
  const { t } = useTranslation('core');

  return (
    <RenderOnMedia min={desktopLayoutBreakpoint}>
      <TopNavMenuContainer>
        {menu.map((menuList, groupIndex, array) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={groupIndex}>
            {menuList.map(({ icon, label, testId, ...props }) => {
              if ('onClick' in props) {
                return (
                  <TopNavMenuButton
                    data-testid={testId}
                    key={`${testId}${label}`}
                    onClick={props.onClick}
                  >
                    {t(label)}
                  </TopNavMenuButton>
                );
              }
              return props.externalLink ? (
                <TopNavMenuAnchor
                  data-testid={testId}
                  key={`${props.path}${label}`}
                  href={props.path}
                  rel="noopener"
                  target="_blank"
                  groupIndex={groupIndex}
                >
                  {!!icon && typeof icon === 'string' ? (
                    <Icon type={icon} id={`topnav-${icon}`} />
                  ) : (
                    icon
                  )}
                  <span>{t(label)}</span>
                </TopNavMenuAnchor>
              ) : (
                <TopNavMenuLink
                  data-testid={testId}
                  exact={props.exact}
                  key={`${props.path}${label}`}
                  to={props.path}
                  groupIndex={groupIndex}
                >
                  {!!icon && typeof icon === 'string' ? (
                    <Icon type={icon} id={`topnav-${icon}`} />
                  ) : (
                    icon
                  )}
                  <span>{t(label)}</span>
                </TopNavMenuLink>
              );
            })}
            {groupIndex !== array.length - 1 && (
              // eslint-disable-next-line react/no-array-index-key
              <VerticalSeparator key={groupIndex} />
            )}
          </Fragment>
        ))}
      </TopNavMenuContainer>
    </RenderOnMedia>
  );
};
