import React, { FC, memo, useContext } from 'react';
import { shallowEqual } from 'react-redux';

import {
  placeBettingSlipItemNotificationGetterSelector,
  riskManagementItemNotificationGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

type BettingSlipItemErrorProps = {
  /** id to find ticket item */
  id: number;
};

const BettingSlipItemErrorComponent: FC<BettingSlipItemErrorProps> = ({
  id,
}) => {
  const { bettingSlipId } = useContext(BettingSlipContext);

  const placeBettingSlipNotification = useBettingGetterSelector({
    getterSelector: placeBettingSlipItemNotificationGetterSelector,
    args: [bettingSlipId, id],
    equalityFn: shallowEqual,
  });

  const riskManagementNotification = useBettingGetterSelector({
    getterSelector: riskManagementItemNotificationGetterSelector,
    args: [bettingSlipId, id],
    equalityFn: shallowEqual,
  });

  if (!placeBettingSlipNotification && !riskManagementNotification) {
    return null;
  }

  return (
    <>
      {placeBettingSlipNotification ? (
        <BettingSlipMessage
          testId="placebet-betting-element-notification-error"
          notification={placeBettingSlipNotification}
        />
      ) : undefined}
      {riskManagementNotification ? (
        <BettingSlipMessage
          testId="riskmanagement-betting-element-notification-error"
          notification={riskManagementNotification}
        />
      ) : undefined}
    </>
  );
};

export const BettingSlipItemError = memo(BettingSlipItemErrorComponent);
