import { produce } from 'immer';
import uniq from 'lodash/uniq';
import { getType } from 'typesafe-actions';

import {
  generatePaginatedInitialRequestState,
  PaginatedRequestState,
  RemoteData,
} from '@gaming1/g1-utils';

import * as actions from '../../../../store/actions';
import { GamingActions } from '../../../../store/types';
import { slugifyGameListPayload } from '../../helpers';
import { GameListResults } from '../../types';

const getInitialState = () =>
  generatePaginatedInitialRequestState({
    attributeCategories: null,
    attributes: null,
    games: null,
  });

export const initialState: {
  [slugifiedRequest: string]:
    | PaginatedRequestState<GameListResults>
    | undefined;
} = {};

export const fetchGamesRequestsReducer = (
  state = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchGames.request): {
        const slug = slugifyGameListPayload(action.payload);

        draftState[slug] = {
          ...getInitialState(),
          ...state[slug],
          errorCode: undefined,
          errorMessage: undefined,
          status: RemoteData.Loading as RemoteData,
        };

        break;
      }

      case getType(actions.fetchGames.failure): {
        const slug = slugifyGameListPayload(action.payload.request);

        draftState[slug] = {
          ...getInitialState(),
          ...state[slug],
          errorCode: action.payload.data.status,
          errorMessage: action.payload.data.errorMessage,
          status: RemoteData.Error as RemoteData,
        };

        break;
      }

      case getType(actions.fetchGames.success): {
        const slug = slugifyGameListPayload(action.payload.request);

        draftState[slug] = {
          ...getInitialState(),
          ...state[slug],
          ...action.payload.data.meta,
          errorCode: undefined,
          errorMessage: undefined,
          pagination: action.payload.data.pagination,
          status: RemoteData.Success as RemoteData,
          result: {
            attributeCategories: action.payload.data.result.attributeCategories,
            attributes: action.payload.data.result.attributes,
            games:
              action.payload.data.pagination.PageNumber > 0 && draftState[slug]
                ? // Uniq ensure that no duplicate id is in the array, which can unfortunately happen
                  uniq([
                    ...(draftState[slug]?.result?.games || []),
                    ...action.payload.data.result.games,
                  ])
                : action.payload.data.result.games,
          },
        };

        break;
      }

      default: // Immer will automatically return the state
    }
  });
