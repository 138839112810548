import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

import { colors, fontSizes, media, spaces } from '@gaming1/g1-style';
import { appAnchorCSS, Text } from '@gaming1/g1-ui';

import { PrismicSection } from '../PrismicSection';

export type BackLinkProps = LinkProps & SpaceProps;

/* 4:2 ratio on mobile */
const BANNER_MOBILE_RATIO_IN_PERCENT = 50;
/* 4:1 ratio on desktop and tablet */
const BANNER_DESKTOP_RATIO_IN_PERCENT = 25;

export const PromotionDetailsBackLink = styled(Text)`
  ${appAnchorCSS}

  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: ${fontSizes('md')};
  text-decoration: none;
`;

type PromotionDetailsBannerProps = {
  /** URL of the image */
  url?: string;
};

export const PromotionDetailsBanner = styled.div<PromotionDetailsBannerProps>`
  background-image: ${({ url }) => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  width: 100%;
  height: 0;
  padding-bottom: ${BANNER_MOBILE_RATIO_IN_PERCENT}%;

  ${media.md} {
    padding-bottom: ${BANNER_DESKTOP_RATIO_IN_PERCENT}%;
  }
`;

export const PromotionDetailsType = styled(Text)`
  margin-top: ${spaces('sm')};
  color: ${colors('muted')};
`;

export const PromotionPrismicSection = styled(PrismicSection)`
  h1 {
    margin-top: ${spaces('sm')};
  }
`;
