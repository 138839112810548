import { createSelector } from 'reselect';

import { ApplicationWithBettingState } from '../../../../store/types';

/** Get the state of the {getInitData} slice */
export const bettingSlipInitDataStateSelector = (
  state: ApplicationWithBettingState,
) => state.betting.bettingSlip.getInitData;
/**
 * Return the "getInitData" slice from the store
 */
const getInitDataRequestGetterSelector = createSelector(
  bettingSlipInitDataStateSelector,
  (getBettingSlipInitData) => () => getBettingSlipInitData,
);

/**
 * Return the result slice of the getInitData state
 */
export const getInitDataRequestResultGetterSelector = createSelector(
  getInitDataRequestGetterSelector,
  (getInitData) => () => getInitData()?.result,
);

/**
 * Return the time of the latest getInitData request sent
 * (So we don't request it to the back-end if it's not long enough)
 */
export const getInitDataLatestTimeSentRequestGetterSelector = createSelector(
  getInitDataRequestGetterSelector,
  (getInitData) => () => getInitData()?.result?.timeOfRequest,
);

/**
 * Returns true if the optimal bet is enabled, false if not, undefined if
 * the info is not known
 */
export const isOptimalBetEnabledGetterSelector = createSelector(
  getInitDataRequestGetterSelector,
  (getInitData) => () => getInitData()?.result?.IsOptimalBetEnabled || false,
);
/**
 * Returns true if the optimal bet is enabled, false if not, undefined if
 * the info is not known
 */
export const isOptimalBetMinimalOddsGetterSelector = createSelector(
  getInitDataRequestGetterSelector,
  (getInitData) => () =>
    getInitData()?.result?.MinimalOddForOptimalBetAdjustment || 1,
);
