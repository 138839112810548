/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:33:19 14-07-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetPlayerVipRankInfoRequestDTO,
  GetPlayerVipRankInfoResponseDTO,
} from '../entities';

export const getPlayerVipRankInfo = (options: GetPlayerVipRankInfoRequestDTO) =>
  createRequest<GetPlayerVipRankInfoResponseDTO>(
    104,
    'GetPlayerVipRankInfo',
    options,
  );
