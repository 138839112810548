import React, { FC, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  getLightSearchCompetitions,
  getLightSearchEvents,
  getLightSearchRequestStateSelector,
  getLightSearchTokens,
  getSortedLightSearchCompetitions,
  getSortedLightSearchEvents,
  LightSearchEventType,
  MIN_SEARCH_TERM_LENGTH,
  SearchCompetitionType,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import {
  currentSectionSelector,
  userSearchTermSelector,
} from '@gaming1/g1-core';
import {
  SearchCategoryResult,
  useRoutePath,
  useTracking,
} from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';
import { RemoteData, RequestState } from '@gaming1/g1-utils';

import { WrappedLoader } from '../../../common/components/WrappedLoader';
import { bettingRoutes } from '../../../routes';
import { BettingSearchTrends } from '../BettingSearchTrends';
import { SEARCH_BETTING_TERM_PARAMETER } from '../constants';
import { SearchCompetitionItem } from '../SearchCompetitionItem';
import { SearchEventItem } from '../SearchEventItem';

type BettingSearchResultProps = {
  /** The setter used to know which failure term to display or if we have some results to display (null) */
  onTermFailure: (term: string | null) => void;
  onRequestFailure: (requestState: RequestState | null) => void;
};

const MAX_DISPLAY = 5;

/**
 * This component renders a header with a title and
 * then display the search results.
 */
export const BettingSearchResult: FC<BettingSearchResultProps> = ({
  onTermFailure,
  onRequestFailure,
}) => {
  const { t } = useTranslation('betting');

  const userSearchTerm = useSelector(userSearchTermSelector);
  const searchTokens = useSelector(getLightSearchTokens);

  const lightEvents = useSelector(getLightSearchEvents);
  const eventsList = useBettingGetterSelector({
    getterSelector: getSortedLightSearchEvents,
    args: [],
    equalityFn: shallowEqual,
  });

  const lightCompetitions = useSelector(getLightSearchCompetitions);
  const competitionsList = useBettingGetterSelector({
    getterSelector: getSortedLightSearchCompetitions,
    args: [],
    equalityFn: shallowEqual,
  });

  const hasAtLeastOneResult =
    lightEvents.TotalResults > 0 || lightCompetitions.TotalResults > 0;

  const hasResults = userSearchTerm.length > 2 && hasAtLeastOneResult;

  const currentSection = useSelector(currentSectionSelector);

  const requestState = useSelector(getLightSearchRequestStateSelector);
  const isSuccessful = requestState.status === 'Success';
  const isLoading = requestState.status === RemoteData.Loading;

  useEffect(() => {
    if (requestState.status === RemoteData.Error) {
      onRequestFailure(requestState);
    } else {
      onRequestFailure(null);
    }
  }, [requestState, onRequestFailure]);

  const getBettingRoutePath = useRoutePath(bettingRoutes);
  const searchUrl = getBettingRoutePath('search', undefined, {
    [SEARCH_BETTING_TERM_PARAMETER]: userSearchTerm,
  });

  useEffect(() => {
    if (isSuccessful && !hasAtLeastOneResult) {
      onTermFailure(userSearchTerm);
    }
  }, [isSuccessful, hasAtLeastOneResult, onTermFailure, userSearchTerm]);

  useEffect(() => {
    if (hasResults || userSearchTerm.length < MIN_SEARCH_TERM_LENGTH) {
      onTermFailure(null);
    }
  }, [hasResults, onTermFailure, userSearchTerm]);

  const { pushEvent } = useTracking();

  useEffect(() => {
    if (isSuccessful && userSearchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
      // Tracking: search response
      pushEvent({
        name: 'search',
        action: 'response',
        category: 'betting',
        label: 'drawer',
        value: userSearchTerm,
        nbEvents: lightEvents.TotalResults,
        nbCompetitions: lightCompetitions.TotalResults,
      });
    }
  }, [
    competitionsList.length,
    isSuccessful,
    lightCompetitions?.TotalResults,
    lightEvents?.TotalResults,
    pushEvent,
    userSearchTerm,
  ]);

  if (isLoading) {
    return <WrappedLoader />;
  }

  const eventsResult = eventsList.length ? (
    <SearchCategoryResult
      allResultsPageUrl={
        lightEvents.TotalResults > MAX_DISPLAY ? searchUrl : undefined
      }
      testId="search-betting-result-event-wrapper"
      categoryName={t('search.sportsCategory')}
      numberOfResults={lightEvents.TotalResults}
    >
      {eventsList.slice(0, MAX_DISPLAY).map((event) => (
        <SearchEventItem
          key={event?.EventId}
          event={event as LightSearchEventType}
          searchTerms={searchTokens}
        />
      ))}
    </SearchCategoryResult>
  ) : null;

  const competitionsResult = competitionsList.length ? (
    <SearchCategoryResult
      allResultsPageUrl={
        lightCompetitions.TotalResults > MAX_DISPLAY ? searchUrl : undefined
      }
      testId="search-betting-result-competition-wrapper"
      categoryName={t('search.competitionsCategory')}
      numberOfResults={lightCompetitions.TotalResults}
    >
      {competitionsList.slice(0, MAX_DISPLAY).map((competition) => (
        <SearchCompetitionItem
          key={competition?.LeagueId}
          competition={competition as SearchCompetitionType}
          searchTerms={searchTokens}
          context="drawer"
        />
      ))}
    </SearchCategoryResult>
  ) : null;

  return (
    <>
      {hasResults && (
        <>
          {eventsResult}
          {competitionsResult}
        </>
      )}
      {((currentSection === 'betting' && userSearchTerm.length < 3) ||
        !hasResults) && (
        <Box marginY="xl">
          <BettingSearchTrends />
        </Box>
      )}
    </>
  );
};
