import {
  DepositHistoryTransactionState,
  WithdrawalHistoryTransactionState,
} from './types';

export const depositHistoryTransactionKeys = {
  [DepositHistoryTransactionState.Accepted]:
    'history.deposit.transactionState.accepted',
  [DepositHistoryTransactionState.Pending]:
    'history.deposit.transactionState.pending',
  [DepositHistoryTransactionState.Cancelled]:
    'history.deposit.transactionState.cancelled',
  [DepositHistoryTransactionState.Refused]:
    'history.deposit.transactionState.refused',
};

export const withdrawalHistoryTransactionKeys = {
  [WithdrawalHistoryTransactionState.Accepted]:
    'history.withdraw.transactionState.accepted',
  [WithdrawalHistoryTransactionState.Pending]:
    'history.withdraw.transactionState.pending',
  [WithdrawalHistoryTransactionState.Cancelled]:
    'history.withdraw.transactionState.cancelled',
  [WithdrawalHistoryTransactionState.Refused]:
    'history.withdraw.transactionState.refused',
};
