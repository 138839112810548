import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import {
  actions,
  BettingSlipIdentifierType,
  bettingSlipItemListIsValidOutcomeGetterSelector,
  bettingSlipItemsGetterSelector,
  bettingSlipItemsListBaseOddGetterSelector,
  isOptimalBetEnabledGetterSelector,
  isOptimalBetMinimalOddsGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { usePrevious } from '@gaming1/g1-utils';

/**
 * Hook that returns a boolean to tell us if there is a new outcome added or not.
 * @param bettingSlipId the bettingslip id to work with
 */
export const useOddsOptimalOddsPassedMinimum = (
  ids: number[],
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const oddses = useBettingGetterSelector({
    getterSelector: bettingSlipItemsListBaseOddGetterSelector,
    args: [bettingSlipId, ids],
    equalityFn: shallowEqual,
  });

  const previousOddses = usePrevious(oddses) || [];

  const minimalOdds = useBettingGetterSelector({
    getterSelector: isOptimalBetMinimalOddsGetterSelector,
    args: [],
  });

  if (oddses.length !== previousOddses.length) {
    return false;
  }
  return oddses
    .map((odds, index) => {
      const previousOdds = previousOddses[index];
      if (odds === previousOdds) {
        return false;
      }
      if (odds > minimalOdds && previousOdds > minimalOdds) {
        return false;
      }
      if (odds < minimalOdds && previousOdds < minimalOdds) {
        return false;
      }
      return true;
    })
    .some((changed) => changed);
};
export const useOutcomesChangedStatus = (
  ids: number[],
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const validities = useBettingGetterSelector({
    getterSelector: bettingSlipItemListIsValidOutcomeGetterSelector,
    args: [bettingSlipId, ids],
    equalityFn: shallowEqual,
  });
  const previousValidities = usePrevious(validities) || [];

  if (validities.length !== previousValidities.length) {
    return false;
  }
  return validities
    .map((validity, index) => {
      const previousValidity = previousValidities[index];
      if (validity === previousValidity) {
        return false;
      }
      return true;
    })
    .some((changed) => changed);
};

/* OptiOdds detected changed */
export const useOptiOddsChangedDetected = (
  bettingSlipId: BettingSlipIdentifierType,
): void => {
  const dispatch = useDispatch();
  const [changeDetected, setChangeDetected] = useState(() => false);
  const ids =
    useBettingGetterSelector({
      getterSelector: bettingSlipItemsGetterSelector,
      args: [bettingSlipId],
      equalityFn: isEqual,
    })?.map((item) => item.id) || [];

  const oddsChangeDetected = useOddsOptimalOddsPassedMinimum(
    ids,
    bettingSlipId,
  );
  const statusChangeDetected = useOutcomesChangedStatus(ids, bettingSlipId);
  const isOptimalEnabled = useBettingGetterSelector({
    getterSelector: isOptimalBetEnabledGetterSelector,
    args: [],
  });
  const changed =
    isOptimalEnabled && (oddsChangeDetected || statusChangeDetected);

  if (changed && changed !== changeDetected) {
    setChangeDetected(changed);
  }

  useEffect(() => {
    if (changeDetected) {
      dispatch(actions.getOptimalBet.request({ bettingSlipId }));
      setChangeDetected(false);
    }
  }, [bettingSlipId, changeDetected, dispatch]);
};
