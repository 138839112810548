import format from 'date-fns/format';
import React, { ComponentPropsWithoutRef, FC, useContext } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { ShopHistoryElement } from '@gaming1/g1-loyalty';
import { NewShopProductType, OrderStatus } from '@gaming1/g1-requests';
import { Modal } from '@gaming1/g1-ui';

import { shopHistoryRequestKeys } from '../helpers';
import {
  MoreInformationModalLabel,
  MoreInformationModalRow,
  MoreInformationModalTitle,
  MoreInformationModalValue,
  MoreInformationModalWrapper,
} from '../styles';

export type ShopHistoryInformationModalProps = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  history: ShopHistoryElement;
};

const isValidOrderStatus = (
  num: number,
): num is keyof typeof shopHistoryRequestKeys => num in shopHistoryRequestKeys;

const isValidProductType = (num: number): num is NewShopProductType =>
  num in NewShopProductType;

/**
 * Information modal for shop history
 */
export const ShopHistoryInformationModal: FC<
  ShopHistoryInformationModalProps
> = ({ onClose, history, ...rest }) => {
  const { t } = useTranslation('loyalty');
  const { i18n } = useContext(ConfigContext);

  const informations = [
    {
      label: t('history.shop.orderDate'),
      value: format(new Date(history.OrderDate), i18n.dateTimePartialFormat),
    },
    {
      label: t('history.shop.productTitle'),
      value: history.Title,
    },
    {
      label: t('history.shop.reference'),
      value: history.Id,
    },
    {
      label: t('history.shop.status'),
      value:
        isValidOrderStatus(history.State) &&
        isValidProductType(history.NewShopProductType)
          ? t(shopHistoryRequestKeys[history.State][history.NewShopProductType])
          : null,
    },
    {
      visible: !!history.Tracking,
      label: t('history.shop.tracking'),
      value: history.Tracking,
    },
    {
      visible:
        history.State !== OrderStatus.Failed &&
        history.NewShopProductType === NewShopProductType.VirtualExternal &&
        !!history.ProductCode &&
        !history.IsExchanged,
      label: t('history.shop.productCode'),
      value: history.ProductCode,
    },
    {
      // TODO:JDU manage link history component
      visible:
        history.State !== OrderStatus.Failed &&
        history.NewShopProductType === NewShopProductType.VirtualExternal &&
        !!history.RedeemUrl &&
        !history.IsExchanged,
      label: t('history.shop.redeemUrl'),
      value: history.RedeemUrl,
    },
    {
      visible: !!history.IsExchanged && !!history.ExchangeDateTime,
      label: t('history.shop.exchangeDateTime'),
      value: history.ExchangeDateTime
        ? format(new Date(history.ExchangeDateTime), i18n.dateTimePartialFormat)
        : '',
    },
  ];

  return (
    <Modal modalId="shop-history-information-modal" onClose={onClose} {...rest}>
      <>
        <MoreInformationModalTitle>
          {t('history.shop.moreInformationTitle')}
        </MoreInformationModalTitle>
        <MoreInformationModalWrapper>
          {informations.map(
            (information) =>
              information.visible !== false && (
                <MoreInformationModalRow
                  key={`${information.label}${information.value}`}
                >
                  <MoreInformationModalLabel>
                    {information.label}
                  </MoreInformationModalLabel>
                  <MoreInformationModalValue>
                    {information.value}
                  </MoreInformationModalValue>
                </MoreInformationModalRow>
              ),
          )}
        </MoreInformationModalWrapper>
      </>
    </Modal>
  );
};
