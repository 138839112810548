import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const BettingSlipSuccessStateContainer = styled(Box)`
  flex: 1;
  margin: ${spaces('xs')} ${spaces('xs')};
  justify-content: space-between;
`;
export const BettingSlipSuccessStateMessageContainer = styled(Box)`
  margin-bottom: ${spaces('sm')};
`;
export const BettingSlipSuccessStateButtonContainer = styled(Box)`
  background-color: ${colors('backgrounds', { index: 1 })};
  padding: ${spaces('sm')};
  margin-top: ${spaces('sm')};
`;
