import React, { VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';

import { usePrivacySettings } from '../../../tracking/hooks/usePrivacySettings';
import { FooterLinkButton } from '../Footer/styles';

type ComplianceLinksProps = {
  /** Alternative styles of the links */
  linkStyle?: 'title' | 'text';
};

/**
 * This component displays a legal disclaimer and a button which opens the
 * privacy settings modal if it is configured.
 */
export const ComplianceLinks: VFC<ComplianceLinksProps> = ({ linkStyle }) => {
  const { t } = useTranslation('core');
  const { isPrivacySettingsEnabled, openPrivacySettings } =
    usePrivacySettings();

  return isPrivacySettingsEnabled ? (
    <FooterLinkButton
      onClick={openPrivacySettings}
      data-testid="footer-link-privacy-settings"
      linkStyle={linkStyle}
    >
      {t('footer.link.cookiesSettings')}
    </FooterLinkButton>
  ) : null;
};
