import styled, { css } from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

import { COLLAPSIBLE_MENU_WIDTH } from '../CollapsibleMenu';

type ContentContainerProps = {
  isDesktop: boolean;
};

export const ContentContainer = styled(Box)<ContentContainerProps>`
  flex-direction: row;
  flex-grow: 1;
  max-width: 100vw;
  padding-left: ${spaces('xxxxl')};
  min-width: 0;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      max-width: calc(100vw - ${COLLAPSIBLE_MENU_WIDTH}px);
      padding-left: 0;
    `};
`;
