import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  AcceptTermsAndConditionsResponse,
  GetTermsAndConditionsResponse,
  ShowTermsAndConditionsResponse,
} from './types';

/** Accepts Terms & Conditions */
export const acceptTermsAndConditions = createAsyncAction(
  'user/accept_terms_and_conditions_request',
  'user/accept_terms_and_conditions_success',
  'user/accept_terms_and_conditions_failure',
)<void, AcceptTermsAndConditionsResponse, FailurePayload>();

/** Get Terms & Conditions */
export const getTermsAndConditions = createAsyncAction(
  'user/get_terms_and_conditions_request',
  'user/get_terms_and_conditions_success',
  'user/get_terms_and_conditions_failure',
)<{ Key: string }, GetTermsAndConditionsResponse, FailurePayload>();

/** Show Terms & Conditions */
export const showTermsAndConditions = createAsyncAction(
  'user/show_terms_and_conditions_request',
  'user/show_terms_and_conditions_success',
  'user/show_terms_and_conditions_failure',
)<void, ShowTermsAndConditionsResponse, FailurePayload>();
