import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { THROTTLE_TIME_IN_MS } from '@gaming1/g1-core';
import {
  changePlayerSettings,
  ChangePlayerSettingsRequestDTO,
  getPlayerSettings,
  PlayerSettingsProperties,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  changePlayerSettingsResponse,
  getPlayerSettingsResponse,
} from './codecs';
import {
  changePlayerSettingsErrorMessages,
  getPlayerSettingsErrorMessages,
} from './errorMessages';

/** Change the user settings */
export const changeUserSettingsEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.changeUserSettings.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(changePlayerSettings(payload)).pipe(
        mapGuard(changePlayerSettingsResponse),
        map(actions.changeUserSettings.success),
        catchError((err) =>
          of(
            actions.changeUserSettings.failure(
              createFailurePayload(err, changePlayerSettingsErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the user settings */
export const getUserSettingsEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getUserSettings.request)),
    switchMap(() =>
      wsAdapter.request(getPlayerSettings({})).pipe(
        mapGuard(getPlayerSettingsResponse),
        map(actions.getUserSettings.success),
        catchError((err) =>
          of(
            actions.getUserSettings.failure(
              createFailurePayload(err, getPlayerSettingsErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** After a successful change user settings, we get the user settings */
export const successChangeUserSettingsToGetUserSettingsEpic: UserEpic = (
  action$,
) =>
  action$.pipe(
    filter(isActionOf(actions.changeUserSettings.success)),
    map(() => actions.getUserSettings.request()),
  );

/** Update the user settings on click (throttled) */
export const clickUserSettingsToChangeUserSettingsEpic: UserEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.clickUserSettings)),
    throttleTime(THROTTLE_TIME_IN_MS),
    map(({ payload }) => {
      const updatedProperties = Object.keys(payload);
      const changeUserSettingsPayload: ChangePlayerSettingsRequestDTO = {
        ...payload,
        PropertiesToUpdate: [
          updatedProperties.includes('AllowRemarketing')
            ? PlayerSettingsProperties.AllowRemarketing
            : null,
          updatedProperties.includes('ReceiveEmailNotification')
            ? PlayerSettingsProperties.ReceiveEmailNotification
            : null,
          updatedProperties.includes('ReceivePhoneNotification')
            ? PlayerSettingsProperties.ReceivePhoneNotification
            : null,
          updatedProperties.includes('ReceivePostNotification')
            ? PlayerSettingsProperties.ReceivePostNotification
            : null,
          updatedProperties.includes('ReceiveSMSNotification')
            ? PlayerSettingsProperties.ReceiveSMSNotification
            : null,
          updatedProperties.includes('RefuseBonus')
            ? PlayerSettingsProperties.RefuseBonus
            : null,
        ].filter((prop) => prop !== null),
      };
      return actions.changeUserSettings.request(changeUserSettingsPayload);
    }),
  );
