import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardLastPeriodScoreSelector,
  getScoreBoardPassTeamSelector,
  getScoreBoardSportStatsSelector,
  getScoreBoardTeamNamesSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { SCOREBOARD_SCORE_SEPARATOR } from '../../constants';
import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardPlayTime } from '../ScoreBoardPlayTime';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardSummaryTemplateBullet as TemplateBullet,
  ScoreBoardSummaryTemplateColumn as TemplateColumn,
  ScoreBoardSummaryTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardSummaryTemplateEllipsis as TemplateEllipsis,
  ScoreBoardSummaryTemplateItem as TemplateItem,
  ScoreBoardSummaryTemplateRow as TemplateRow,
  ScoreBoardSummaryTemplateWrapper as TemplateWrapper,
} from './styles';

const BULLET_COLOR = '#fae316';

const parseTennisPasses = (passes: number) => (passes === 50 ? 'A' : passes);

/** Displays the summary scoreboard with a tennis template */
export const ScoreBoardSummaryTennisTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
  sportId,
}) => {
  const {
    team1: name1,
    team2: name2,
    separator,
  } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: pass1, team2: pass2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardPassTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: passes1, team2: passes2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Passes'],
    equalityFn: shallowEqual,
  });
  const { team1: lastPeriod1, team2: lastPeriod2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardLastPeriodScoreSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  return (
    <TemplateWrapper data-testid="scoreboard-summary-tennis-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper>
          <TemplateRow width="100%">
            <TemplateItem px="sm" flex={1} overflow="hidden">
              <TemplateEllipsis textAlign="right">{name1}</TemplateEllipsis>
            </TemplateItem>
            <TemplateColumn px="xs">
              <TemplateRow pb="xxs" justifyContent="center">
                {pass1 && <TemplateBullet color={BULLET_COLOR} />}
                <TemplateItem fontSize="xl" px="xs">
                  {parseTennisPasses(passes1)}
                </TemplateItem>
                <TemplateItem fontSize="xl" px="xs">
                  {separator || SCOREBOARD_SCORE_SEPARATOR}
                </TemplateItem>
                <TemplateItem fontSize="xl" px="xs">
                  {parseTennisPasses(passes2)}
                </TemplateItem>
                {pass2 && <TemplateBullet color={BULLET_COLOR} />}
              </TemplateRow>
              <TemplateRow justifyContent="center">
                <ScoreBoardPlayTime
                  additionalText={[lastPeriod1, lastPeriod2].join('-')}
                  eventId={eventId}
                  liveIcon={false}
                />
              </TemplateRow>
            </TemplateColumn>
            <TemplateItem px="sm" flex={1} overflow="hidden">
              <TemplateEllipsis textAlign="left">{name2}</TemplateEllipsis>
            </TemplateItem>
          </TemplateRow>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
