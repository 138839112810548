/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetPlayerRankInfoRequestDTO,
  GetPlayerRankInfoResponseDTO,
  GetShopCategoriesRequestDTO,
  GetShopCategoriesResponseDTO,
  GetShopHistoryRequestDTO,
  GetShopHistoryResponseDTO,
  GetShopProductsRequestDTO,
  GetShopProductsResponseDTO,
  ShopPurchaseRequestDTO,
  ShopPurchaseResponseDTO,
  ShopSearchRequestDTO,
} from '../entities';

export const getPlayerRankInfo = (options: GetPlayerRankInfoRequestDTO) =>
  createRequest<GetPlayerRankInfoResponseDTO>(
    102,
    'GetPlayerRankInfo',
    options,
  );

export const getShopCategories = (options: GetShopCategoriesRequestDTO) =>
  createRequest<GetShopCategoriesResponseDTO>(
    102,
    'GetShopCategories',
    options,
  );

export const shopPurchase = (options: ShopPurchaseRequestDTO) =>
  createRequest<ShopPurchaseResponseDTO>(102, 'ShopPurchase', options);

export const getShopHistory = (options: GetShopHistoryRequestDTO) =>
  createRequest<GetShopHistoryResponseDTO>(102, 'GetShopHistory', options);

export const getShopProducts = (options: GetShopProductsRequestDTO) =>
  createRequest<GetShopProductsResponseDTO>(102, 'GetShopProducts', options);

export const shopSearch = (options: ShopSearchRequestDTO) =>
  createRequest<GetShopProductsResponseDTO>(102, 'ShopSearch', options);
