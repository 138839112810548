import { animated, useTransition } from '@react-spring/web';
import { transparentize } from 'polished';
import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { BACKDROP_ANIMATION_DURATION_IN_MS } from '@gaming1/g1-ui';

import { SubDrawerContext } from '../SubDrawer/SubDrawerContext';

/**
 * Render a backdrop that takes half of the bettingSlip height.
 *
 * If the user closes the drawer or clicks on the backdrop, it vanishes.
 */
export const OffersAvailableBackdrop: FC = () => {
  const { offersAvailableDrawerVisibility, backdropClickHandler } =
    useContext(SubDrawerContext);

  const backdropVisibility = offersAvailableDrawerVisibility;

  const theme = useContext(ThemeContext);

  const transitions = useTransition(backdropVisibility, {
    config: { clamp: true, duration: BACKDROP_ANIMATION_DURATION_IN_MS },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const backdropButtonStyles = {
    border: 'none',
    position: 'fixed' as const,
    width: '100%',
    height: '100%',
    backgroundColor: transparentize(theme.opacity.translucent)('#000'),
    zIndex: theme.zIndices.backdrop,
    transform: 'translateZ(0)',
  };

  return transitions(
    (style, show) =>
      show && (
        <animated.button
          aria-label="Close modal or drawer"
          aria-hidden={backdropVisibility ? undefined : 'true'}
          type="button"
          data-testid="offersAvailable-backdrop"
          onClick={backdropClickHandler}
          style={{ ...backdropButtonStyles, ...style }}
        />
      ),
  );
};
