import orderBy from 'lodash/orderBy';

import { curriedHasNonNullableProperty } from '@gaming1/g1-utils';

import { BoostusType } from './types';

export const filterBoostusThenOrderToNotUsedBoostus = (
  boostusList: BoostusType[],
) =>
  orderBy(
    boostusList.filter((boost) => !boost.UsedDate),
    [(a) => new Date(a.EndDate), 'BoostValue', (a) => new Date(a.CreatedDate)],
    ['asc', 'desc', 'asc'],
  );

export const filterBoostusThenOrderToUsedBoostus = (
  boostusList: BoostusType[],
) =>
  orderBy(
    boostusList.filter(curriedHasNonNullableProperty('UsedDate')),
    [(a) => new Date(a.UsedDate), 'BoostValue', (a) => new Date(a.CreatedDate)],
    ['desc', 'desc', 'desc'],
  );
