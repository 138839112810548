import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardCompactBackgroundWrapper as BackgroundWrapper,
  ScoreBoardCompactTemplateItem as TemplateItem,
  ScoreBoardCompactTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the compact scoreboard with a point template */
export const ScoreBoardCompactPointTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
}) => {
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  return (
    <TemplateWrapper data-testid="scoreboard-compact-point-template">
      <BackgroundWrapper backgroundRadius="bothSides">
        <TemplateItem isHighlighted>{score1}</TemplateItem>
        <TemplateItem isHighlighted>{score2}</TemplateItem>
      </BackgroundWrapper>
    </TemplateWrapper>
  );
};
