import { createContext } from 'react';

type FooterContextValue = {
  position: 'bottom' | 'drawerNav';
};

const footerContextDefaultValue: FooterContextValue = {
  position: 'bottom',
};

export const FooterContext = createContext<FooterContextValue>(
  footerContextDefaultValue,
);
