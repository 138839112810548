import { createSelector } from 'reselect';

import { ApplicationWithGamingState } from '../../store/types';

const pendingGamesStateSelector = (state: ApplicationWithGamingState) =>
  state.gaming.pendingGames;

export const getPendingGamesRequestSelector = createSelector(
  pendingGamesStateSelector,
  (pendingGameState) => pendingGameState.requests.getPendingGames,
);

export const pendingGamesSelector = createSelector(
  pendingGamesStateSelector,
  (pendingGameState) => pendingGameState.pendingGames,
);
