import React, { ContextType, FC } from 'react';

import { PaymentPublicComponentsContext } from '@gaming1/g1-payment-api';

import { DepositHistory } from '../../../history/components/DepositHistory';
import { WithdrawalHistory } from '../../../history/components/WithdrawalHistory';

const contextValue: ContextType<typeof PaymentPublicComponentsContext> = {
  DepositHistory,
  WithdrawalHistory,
};

/** Every context providers needed for the payment package:
 * - PaymentPublicComponentsContext that shares public components to other packages
 */
export const PaymentProviders: FC = ({ children }) => (
  <PaymentPublicComponentsContext.Provider value={contextValue}>
    {children}
  </PaymentPublicComponentsContext.Provider>
);
