import styled, { css } from 'styled-components';
import { TextAlignProps, TypographyProps } from 'styled-system';

import { spaces } from '@gaming1/g1-style';
import { Heading } from '@gaming1/g1-ui';

export const PrismicH1 = styled(Heading).attrs(() => ({ level: 'h1' }))`
  margin-top: ${spaces('lg')};
`;

export const PrismicH2 = styled(Heading).attrs(() => ({ level: 'h2' }))`
  margin-top: ${spaces('lg')};
`;

export const PrismicH3 = styled(Heading).attrs(() => ({ level: 'h3' }))`
  margin-top: ${spaces('md')};
`;

export const PrismicH4 = styled(Heading).attrs(() => ({ level: 'h4' }))`
  margin-top: ${spaces('sm')};
`;

export const PrismicParagraph = styled.p`
  margin-top: ${spaces('sm')};
  margin-bottom: ${spaces('sm')};
`;

export const PrismicUl = styled.ul`
  line-height: 1.4;
`;

export const PrismicOl = styled.ol`
  line-height: 1.4;
`;

export const PrismicLi = styled.li``;

export type PrismicInnerContentWrapperProps = TypographyProps &
  TextAlignProps & {
    clearMargins: boolean;
  };

export const PrismicInnerContentWrapper = styled.div<PrismicInnerContentWrapperProps>`
  text-align: ${({ textAlign }) => textAlign || 'left'};
  width: 100%;

  ${({ clearMargins }) =>
    clearMargins &&
    css`
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
    `}
`;
