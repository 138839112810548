import * as t from 'io-ts';

import {
  EResponseStatus,
  ERoundingMethod,
  ERoundingTarget,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { notificationCodec } from '../../../notification/codecs';

/* Response data */

/* Initialization data */

export const roundingProcessorCodec = t.type(
  {
    Method: createEnumType<ERoundingMethod>(ERoundingMethod),
    Target: createEnumType<ERoundingTarget>(ERoundingTarget),
    Precision: t.number,
  },
  'RoundingProcessor',
);

export const getInitializationDataSuccessResponseCodec = t.intersection(
  [
    t.type({
      IsSystemBettingSlipEnabled: t.boolean,
      IsOptimalBetEnabled: t.boolean,
      IsFreeBetRetributeStakeAmount: t.boolean,
      IsMultiSingleDisabled: t.boolean,
      Status: t.literal(EResponseStatus.Successfull),
    }),
    t.partial({
      MinimalOddForOptimalBetAdjustment: t.number,
      RoundingProcessors: t.array(roundingProcessorCodec),
      timeOfRequest: t.string,
    }),
  ],
  'GetInitializationDataSuccessResponse',
);

export const getConditionsForBettingSlipResponseCodec = t.intersection(
  [
    t.type({
      Notification: notificationCodec,
    }),
    t.partial({
      ConditionsIds: t.array(t.string),
    }),
  ],
  'GetConditionsForBettingSlipResponseCodec',
);
