import {
  interopMessages,
  InteropMessagesContent,
  SimpleIframeMessage,
} from '@gaming1/g1-core';

import { logger } from '../../logger';

export const logReceivedMessages = ({ type, payload }: SimpleIframeMessage) => {
  if (payload) {
    logger.info(`[Iframe] [Received] Type: ${type}, Payload:`, payload);
  } else {
    logger.info(`[Iframe] [Received] Type: ${type}`);
  }
};

const logSentMessages = ({ type, payload }: SimpleIframeMessage) => {
  if (payload) {
    logger.info(`[Iframe] [Sent] Type: ${type}, Payload:`, payload);
  } else {
    logger.info(`[Iframe] [Sent] Type: ${type}`);
  }
};

const generateIframeMessage = <P = undefined>(
  type: interopMessages,
  payload?: P,
): SimpleIframeMessage => ({
  type,
  payload,
});

/**
 *
 *
 * This one won't work.
 *  window.ReactNativeWebView.postMessage(JSON.stringify(data), "*");
 * This will work.
 *  window.ReactNativeWebView.postMessage(JSON.stringify(data));
 *
 */

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

// TODO: rename sendInteropMessage

type ExtractMessageContent<
  Input extends InteropMessagesContent,
  Type extends interopMessages,
> = Input extends { type: Type } ? Input : never;

type ExtractMessageWithPayload<Input extends InteropMessagesContent> =
  Input extends { payload: unknown } ? Input : never;

type MesagesWithPayload = ExtractMessageWithPayload<InteropMessagesContent>;

export const sendIframeMessage = <InteropType extends interopMessages>(
  type: InteropType,
  payload?: ExtractMessageContent<MesagesWithPayload, InteropType>['payload'],
  options: { destination: 'parent' | 'rn' } = { destination: 'rn' },
) => {
  const message = generateIframeMessage(type, payload);
  const destProp =
    options.destination === 'parent' ? 'parent' : 'ReactNativeWebView';
  if (destProp in window && window[destProp]?.postMessage) {
    // window.ReactNativeWebView can be undefined if we simulate wrapper-env from a browser.
    if (destProp === 'ReactNativeWebView') {
      // Implementation of postMessage from ReactNativeWebView is not exactly the same
      // Just accept one argument as string.
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
      } else {
        logger.info(
          '[window.ReactNativeWebView] is undefined. Your are not embed in a react-native webview.',
        );
      }
    } else {
      window[destProp]?.postMessage(message, '*');
    }
    logSentMessages(message);
  } else {
    logger.error(
      `[Iframe] Message could not be sent because ${destProp} is not in the window object`,
    );
  }
};
