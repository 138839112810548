import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { GetPendingGamesResponse } from './types';

/**
 * Send a request to fetch the pending games
 */
export const getPendingGames = createAsyncAction(
  'gaming/get_pending_games_request',
  'gaming/get_pending_games_success',
  'gaming/get_pending_games_failure',
)<void, GetPendingGamesResponse, FailurePayload>();
