import { NetworkConfig } from '../types';

/**
 * Parameters linked to the network
 */
export const defaultNetworkConfig: NetworkConfig = {
  apiUrl: 'http://localhost',
  gameLauncherUrl: 'http://localhost:3003',
  keepAliveInterval: 2 * 60 * 1000,
  loyaltyGameServerUrl: 'https://loyaltygames-loyalty01.gaming1.com',
  mediaUrl: '',
  prismicUrl: '',
  siteUrl: 'http://localhost',
  wsCompression: 'LZS2',
  wsUrl: '',
  zendeskUrl: '',
};
