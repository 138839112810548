import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  ETournamentStateV4,
  GetTournamentPlayerRankingV4RequestDTO,
} from '@gaming1/g1-requests-gaming';
import { FailurePayload, PaginationList } from '@gaming1/g1-utils';

import {
  GetTournamentDetailsResponse,
  GetTournamentResponse,
  RegisterTournamentResponse,
  Tournament,
  TournamentRanking,
} from './types';

/** Get tournament list */
export const getTournamentList = createAsyncAction(
  'gaming/get_tournament_list_request',
  'gaming/get_tournament_list_success',
  'gaming/get_tournament_list_failure',
)<
  {
    ListType: ETournamentStateV4;
    PageNumber: number;
    PageSize: number;
  },
  PaginationList<Tournament>,
  FailurePayload
>();

/** Get running tournament list */
export const getRunningTournaments = createAsyncAction(
  'gaming/get_running_tournaments_request',
  'gaming/get_running_tournaments_success',
  'gaming/get_running_tournaments_failure',
)<
  {
    PageNumber: number;
    PageSize: number;
  },
  PaginationList<Tournament>,
  FailurePayload
>();

/** Get finished tournament list */
export const getFinishedTournaments = createAsyncAction(
  'gaming/get_finished_tournaments_request',
  'gaming/get_finished_tournaments_success',
  'gaming/get_finished_tournaments_failure',
)<
  {
    PageNumber: number;
    PageSize: number;
  },
  PaginationList<Tournament>,
  FailurePayload
>();

/** Get active tournament list */
export const getActiveTournaments = createAsyncAction(
  'gaming/get_active_tournaments_request',
  'gaming/get_active_tournaments_success',
  'gaming/get_active_tournaments_failure',
)<
  {
    PageNumber: number;
    PageSize: number;
  },
  PaginationList<Tournament>,
  FailurePayload
>();

/** Get tournament details */
export const getTournamentDetails = createAsyncAction(
  'gaming/get_tournament_details_request',
  'gaming/get_tournament_details_success',
  'gaming/get_tournament_details_failure',
)<{ TournamentId: string }, GetTournamentDetailsResponse, FailurePayload>();

/** Get tournament ranking */
export const getTournamentRanking = createAsyncAction(
  'gaming/get_tournament_ranking_request',
  'gaming/get_tournament_ranking_success',
  'gaming/get_tournament_ranking_failure',
)<
  { PageNumber: number; PageSize: number; TournamentId: string },
  PaginationList<TournamentRanking>,
  FailurePayload
>();

/** Get player ranking */
export const getTournamentPlayerRanking = createAsyncAction(
  'gaming/get_tournament_player_ranking_request',
  'gaming/get_tournament_player_ranking_success',
  'gaming/get_tournament_player_ranking_failure',
)<
  GetTournamentPlayerRankingV4RequestDTO,
  TournamentRanking[],
  FailurePayload
>();

/** Register to tournament */
export const registerTournament = createAsyncAction(
  'gaming/register_tournament_request',
  'gaming/register_tournament_success',
  'gaming/register_tournament_failure',
)<
  { TournamentId: string },
  RegisterTournamentResponse,
  FailurePayload & { message?: string | null }
>();

/** Reset the tournament registration status */
export const resetRegisterTournamentStatus = createAction(
  'gaming/reset_register_tournament_status',
)<void>();

/** Get tournament */
export const getTournament = createAsyncAction(
  'gaming/get_tournament_request',
  'gaming/get_tournament_success',
  'gaming/get_tournament_failure',
)<{ TournamentId: string }, GetTournamentResponse, FailurePayload>();
