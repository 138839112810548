import * as t from 'io-ts';

import {
  EBannerType,
  EHorizontalBannerResponseErrorStatus,
  EResponseStatus,
  ESliderScope,
  ETargetType,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

export const target = t.type(
  {
    Type: createEnumType<ETargetType>(ETargetType, 'ETargetType'),
    Url: t.string,
  },
  'Target',
);

export const bannerInfo = t.intersection(
  [
    t.type({
      SmallURL: t.string,
      MediumURL: t.string,
      LargeURL: t.string,
      Title: t.string,
      BannerType: createEnumType<EBannerType>(EBannerType, 'EBannerType'),
      Target: target,
      Scope: createEnumType<ESliderScope>(ESliderScope, 'ESliderScope'),
    }),
    t.partial({
      OrderId: t.number,
      EventId: t.number,
      // LeagueDataSource: leaguesDataSourceCodec,
    }),
  ],
  'BannerInfo',
);

export const getHorizontalBannerResponse = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    ErrorStatus: t.literal(EHorizontalBannerResponseErrorStatus.None),
    BannersInfos: t.array(bannerInfo),
  },
  'GetHorizontalBanner',
);
