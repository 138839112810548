import { ComponentProps, FC, VFC } from 'react';

import { FooterContext } from '../../../layout/FooterContext';
import { BettingMenuProvider } from '../../BettingMenuProvider';

import { BettingPublicComponentsProvider } from './BettingPublicComponentsProvider';
import { BettingUserSettingsProvider } from './BettingUserSettingsProvider';

export type BettingProvidersProps = {
  /** The app footer, defined in the partner repository */
  footer: VFC;
  /** Betting menus */
  menus: ComponentProps<typeof BettingMenuProvider>['menus'];
};

/** Every context providers needed for the betting package:
 * - BettingUserSettingsContext returns data about the current betting user
 *   settings and methods to read/update them
 */
export const BettingProviders: FC<BettingProvidersProps> = ({
  children,
  footer,
  menus,
}) => (
  <BettingMenuProvider menus={menus}>
    <FooterContext.Provider value={footer}>
      <BettingUserSettingsProvider>
        <BettingPublicComponentsProvider>
          {children}
        </BettingPublicComponentsProvider>
      </BettingUserSettingsProvider>
    </FooterContext.Provider>
  </BettingMenuProvider>
);
