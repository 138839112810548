export const SCROLL_BAR_SIZE = 5;
export const SCROLL_BAR_BORDER_SIZE = 3;

export const SCROLL_LOCK_CLASSNAME = 'no-scroll';

export const BACKDROP_ANIMATION_DURATION_IN_MS = 200;

/**
 * Time to wait before showing the loader if "smart"
 */
export const LOADER_DELAY_IN_MS = 500;

export const SLIDER_ITEM_PADDING = 160;

/**
 * The classname of the div that will be appended to the layout to display
 * the different Banner components
 */
export const BANNERS_CONTAINER_CLASSNAME = 'banners-container';

/**
 * Width of the shadow shown on each side of ScrollableList/ScrollNav when
 * there are still elements hidden on that side
 */
export const SCROLLABLE_GRADIENT_OVERLAY_WIDTH_IN_PX = 20;
