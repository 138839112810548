import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { TermsAndConditionsState } from './types';

export const initialState: TermsAndConditionsState = {
  requests: {
    acceptTermsAndConditions: generateInitialRequestState(null),
    getTermsAndConditions: generateInitialRequestState(null),
    showTermsAndConditions: generateInitialRequestState(null),
  },
  path: null,
  show: false,
};

export const termsAndConditionsReducer = (
  state: TermsAndConditionsState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.acceptTermsAndConditions.request):
        produceLoadingState(draftState.requests.acceptTermsAndConditions);
        break;
      case getType(actions.getTermsAndConditions.request):
        produceLoadingState(draftState.requests.getTermsAndConditions);
        break;
      case getType(actions.showTermsAndConditions.request):
        produceLoadingState(draftState.requests.showTermsAndConditions);
        draftState.show = false;
        break;

      /** Failures */
      case getType(actions.acceptTermsAndConditions.failure):
        produceFailureState(
          draftState.requests.acceptTermsAndConditions,
          action.payload,
        );
        break;
      case getType(actions.getTermsAndConditions.failure):
        produceFailureState(
          draftState.requests.getTermsAndConditions,
          action.payload,
        );
        break;
      case getType(actions.showTermsAndConditions.failure):
        produceFailureState(
          draftState.requests.showTermsAndConditions,
          action.payload,
        );
        break;

      /** Successes */
      case getType(actions.acceptTermsAndConditions.success):
        produceSuccessState(draftState.requests.acceptTermsAndConditions);
        break;
      case getType(actions.getTermsAndConditions.success):
        produceSuccessState(draftState.requests.getTermsAndConditions);
        draftState.path = action.payload.File;
        break;
      case getType(actions.showTermsAndConditions.success):
        produceSuccessState(draftState.requests.showTermsAndConditions);
        draftState.show = action.payload.ShowPopup;
        break;

      default: // Immer will automatically return the state
    }
  });
