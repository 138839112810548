import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fromEvent, scan, Subscription, timeInterval } from 'rxjs';

import { ConfigContext } from '@gaming1/g1-config';
import { coreActions, useIsDebugModeEnabled } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { FluidContentContainer, Icon } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';

import {
  FooterBackground,
  FooterBackgroundLevels,
  FooterHeaderContainer,
  FooterHelpContainer,
  FooterLegalAge,
  FooterLegalAgeContainer,
  FooterLogoImg,
  FooterLogoWrapper,
} from './styles';

export const FOOTER_LEGAL_AGE_ICON_SIZE = 24;
export const LOGO_CLICK_TO_ENABLE_DEBUG_MODE_AMOUNT = 7;
export const LOGO_CLICK_TO_ENABLE_DEBUG_MODE_INTERVAL_IN_MS = 250;

export type HeaderProps = {
  /** Backgrounds with three different colors options */
  backgroundLevel: FooterBackgroundLevels;
};

/** Footer Header */
export const Header: FC<HeaderProps> = ({ backgroundLevel, children }) => {
  const { t } = useTranslation('core');
  const config = useContext(ConfigContext);
  const getAssetPath = useGetCoreAssetPath();
  const dispatch = useDispatch();

  const [logoElement, setLogoElement] = useState<HTMLElement | null>(null);

  const isDebugModeEnabled = useIsDebugModeEnabled();

  /*
   * When the logo is clicked quickly x times, the debug mode is enabled. This
   * allow the debug mode to be set within a RN app.
   */
  useEffect(() => {
    let logoClickSubscription: Subscription | null = null;
    if (logoElement) {
      logoClickSubscription = fromEvent(logoElement, 'click')
        .pipe(
          timeInterval(),
          scan(
            (count, { interval }) =>
              interval < LOGO_CLICK_TO_ENABLE_DEBUG_MODE_INTERVAL_IN_MS
                ? count + 1
                : 0,
            0,
          ),
        )
        .subscribe({
          next: (count) => {
            if (
              !isDebugModeEnabled &&
              count >= LOGO_CLICK_TO_ENABLE_DEBUG_MODE_AMOUNT
            ) {
              dispatch(coreActions.setDebugMode(true));
            }
          },
        });
    }
    return () => {
      if (logoClickSubscription) {
        logoClickSubscription.unsubscribe();
      }
    };
  }, [dispatch, isDebugModeEnabled, logoElement]);

  return (
    <FooterBackground backgroundLevel={backgroundLevel} hasBorder>
      <FluidContentContainer>
        <FooterHeaderContainer>
          <FooterLogoWrapper>
            <FooterLogoImg
              alt={t('alt.logo')}
              id="footer-logo"
              ref={setLogoElement}
              url={getAssetPath('logo')}
            />
          </FooterLogoWrapper>

          {!!children && <FooterHelpContainer>{children}</FooterHelpContainer>}

          {(config.betting.enabled || config.gaming.enabled) && (
            <FooterLegalAgeContainer>
              {config.betting.enabled && (
                <FooterLegalAge>
                  <Icon
                    id="footer-betting-age"
                    type="Circle18Plus"
                    height={`${FOOTER_LEGAL_AGE_ICON_SIZE}px`}
                    width={`${FOOTER_LEGAL_AGE_ICON_SIZE}px`}
                  />
                  <span>{t('footer.betting')}</span>
                </FooterLegalAge>
              )}
              {config.gaming.enabled && (
                <FooterLegalAge>
                  <Icon
                    id="footer-casino-age"
                    type="Circle21Plus"
                    height={`${FOOTER_LEGAL_AGE_ICON_SIZE}px`}
                    width={`${FOOTER_LEGAL_AGE_ICON_SIZE}px`}
                  />
                  <span>{t('footer.casino')}</span>
                </FooterLegalAge>
              )}
            </FooterLegalAgeContainer>
          )}
        </FooterHeaderContainer>
      </FluidContentContainer>
    </FooterBackground>
  );
};
