import React, {
  FC,
  InputHTMLAttributes,
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { shallowEqual } from 'react-redux';

import {
  freebetSelectedForBSGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useFormatMoney, useFormatNumber } from '@gaming1/g1-core';

import { BettingSlipContext } from '../../BettingSlipContext';

import { BettingSlipInput, BettingSlipInputContainer } from './styles';

type BettingSlipItemStakeProps = InputHTMLAttributes<HTMLInputElement> & {
  disabled?: boolean;
  /** id to find ticket item */
  testId?: string;
  /** stake */
  stake?: string;
  /** stake in float */
  stakeInFloat?: number;
  /** error on this input. default: false */
  inError?: boolean;
  /** update stake  */
  updateStake: (stake: string) => void;
  /** focus on input change  */
  focusChanged?: (focus: boolean) => void;
  /** will the keyboard be displayed when isKeyboardDisplayed is true */
  hasKeyBoardCapabilities: boolean;
  /** is keyboard displayed  */
  isKeyboardDisplayed?: boolean;
  /** If the inputs need to be blank (true by default in system when no input has been filled yet) */
  shouldBeBlank?: boolean;
} & {
  value?: string | string[] | number;
};

/**
 * Display the stake for an item
 * show and manage stake
 */
const BettingSlipStakeComponent: FC<BettingSlipItemStakeProps> = ({
  disabled = false,
  testId = 'bettingslip-stake',
  stake,
  stakeInFloat,
  inError = false,
  updateStake,
  focusChanged,
  hasKeyBoardCapabilities,
  isKeyboardDisplayed = false,
  shouldBeBlank = false,
  ...rest
}) => {
  const formatNumber = useFormatNumber();
  const { bettingSlipId, isInputStakeFocused, setIsInputStakeFocused } =
    useContext(BettingSlipContext);
  const formatMoney = useFormatMoney();

  const stakeFormated = useCallback(() => {
    if (!stakeInFloat && shouldBeBlank) {
      return '';
    }

    return stakeInFloat
      ? formatMoney(stakeInFloat)
      : formatMoney(parseFloat(formatNumber(0, 2)));
  }, [formatMoney, formatNumber, shouldBeBlank, stakeInFloat]);

  const selectedFreebet = useBettingGetterSelector({
    getterSelector: freebetSelectedForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const changeStake = (newStake: string) => {
    if (newStake !== '' && Number.isNaN(Number(newStake))) {
      return;
    }
    updateStake(newStake);
  };
  const changeFocus = (newFocus: boolean) => {
    setIsInputStakeFocused(newFocus);
    if (focusChanged) {
      focusChanged(newFocus);
    }
  };

  const showRawStake = isInputStakeFocused || isKeyboardDisplayed;

  const stakeValue = useMemo(
    () => (showRawStake ? stake || '' : stakeFormated()),
    [showRawStake, stake, stakeFormated],
  );

  return (
    <BettingSlipInputContainer data-testid="bettingslip-input-container">
      <BettingSlipInput
        testId={testId}
        inputMode="numeric"
        onValueChange={changeStake}
        onBlur={() => changeFocus(false)}
        onFocus={() => changeFocus(true)}
        onClick={(event) => event.currentTarget.select()}
        value={stakeValue}
        error={inError}
        disabled={disabled || !!selectedFreebet}
        pattern="\d*"
        hasBorder
        readOnly={hasKeyBoardCapabilities}
        {...rest}
      />
    </BettingSlipInputContainer>
  );
};

export const BettingSlipStake = memo(BettingSlipStakeComponent, shallowEqual);
