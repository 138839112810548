import formatISO from 'date-fns/formatISO';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { COMMON_PRISMIC_AJAX_CONFIG } from '../../common/constants';
import {
  getPrismicLocale,
  prismicQueriesToString,
  prismicQueryToString,
} from '../../helpers';
import { prismicReferenceSelector } from '../../prismicReference/store/selectors';
import { CMSEpic } from '../../store/types';
import { PrismicQueryInput } from '../../types';

import * as actions from './actions';
import {
  promotionsConfigurationResponseCodec,
  promotionsResponseCodec,
} from './codecs';
import { formatFetchPromotionsResponse } from './helpers';
import { PromotionsConfigurationRequest, PromotionsRequest } from './types';

/**
 * Epic to fetch Prismic promotions (filtered by date, scopes and tag)
 */
export const promotionsEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchPromotions.request)),
    withLatestFrom(config$, state$),
    mergeMap(([action, config, state]) => {
      const currentLocale = getPrismicLocale(
        currentWebSocketLocaleSelector(state),
      );
      const { prismicReference } = prismicReferenceSelector(state);
      if (!prismicReference) {
        throw new Error('ERROR : Prismic reference not set');
      }
      // Add date and scopes conditions to the Prismic query
      const queries: PrismicQueryInput[] = [
        {
          predicate: 'date.before',
          path: 'my.promotion.start_date',
          value: formatISO(new Date()),
        },
        {
          predicate: 'date.after',
          path: 'my.promotion.end_date',
          value: formatISO(new Date()),
        },
        {
          predicate: 'any',
          path: 'my.promotion.scope',
          value: action.payload.scopes,
        },
      ];
      // If specified, add tag condition to the Prismic query
      if (action.payload.tag) {
        queries.push({
          predicate: 'at',
          path: 'document.tags',
          value: [action.payload.tag.name],
        });
      }
      const query = prismicQueriesToString(queries);
      if (!query) {
        throw new Error('ERROR : the query is invalid (query is null)');
      }
      const queryParams: PromotionsRequest = {
        page: action.payload.pageNum,
        pageSize: action.payload.pageSize,
        lang: currentLocale,
        ref: prismicReference,
        q: query,
        orderings: '[document.last_publication_date]',
      };
      return ajaxFetch(`${config.network.prismicUrl}/documents/search`, {
        ...COMMON_PRISMIC_AJAX_CONFIG,
        queryParams,
      }).pipe(
        mapGuard(promotionsResponseCodec),
        map((response) =>
          formatFetchPromotionsResponse(response, action.payload),
        ),
        map(actions.fetchPromotions.success),
        catchError((err) =>
          of(
            actions.fetchPromotions.failure({
              data: createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicPromotions',
              ),
              request: action.payload,
            }),
          ),
        ),
      );
    }),
  );

/**
 * Epic to fetch the Prismic 'Promotions Configuration' document
 */
export const promotionsConfigurationEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchPromotionsConfiguration.request)),
    withLatestFrom(config$, state$),
    switchMap(([, config, state]) => {
      const currentLocale = getPrismicLocale(
        currentWebSocketLocaleSelector(state),
      );
      const { prismicReference } = prismicReferenceSelector(state);
      if (!prismicReference) {
        throw new Error('ERROR : Prismic reference not set');
      }
      const queryParams: PromotionsConfigurationRequest = {
        lang: currentLocale,
        ref: prismicReference,
        q: prismicQueryToString({
          predicate: 'at',
          path: 'document.type',
          value: 'promotions_configuration',
        }),
      };
      return ajaxFetch(`${config.network.prismicUrl}/documents/search`, {
        ...COMMON_PRISMIC_AJAX_CONFIG,
        queryParams,
      }).pipe(
        mapGuard(promotionsConfigurationResponseCodec),
        map((response) => response.results[0].data),
        map(actions.fetchPromotionsConfiguration.success),
        catchError((err) =>
          of(
            actions.fetchPromotionsConfiguration.failure(
              createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicPromotionsConfiguration',
              ),
            ),
          ),
        ),
      );
    }),
  );

/**
 * Epic to fetch a Prismic promotion document by its UID
 */
export const promotionByUidEpic: CMSEpic = (
  actions$,
  state$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchPromotionByUid.request)),
    withLatestFrom(config$, state$),
    switchMap(([action, config, state]) => {
      const currentLocale = getPrismicLocale(
        currentWebSocketLocaleSelector(state),
      );
      const { prismicReference } = prismicReferenceSelector(state);
      if (!prismicReference) {
        throw new Error('ERROR : Prismic reference not set');
      }
      const queryParams: PromotionsConfigurationRequest = {
        lang: currentLocale,
        ref: prismicReference,
        q: prismicQueryToString({
          predicate: 'at',
          path: 'my.promotion.uid',
          value: action.payload.uid,
        }),
      };
      return ajaxFetch(`${config.network.prismicUrl}/documents/search`, {
        ...COMMON_PRISMIC_AJAX_CONFIG,
        queryParams,
      }).pipe(
        mapGuard(promotionsResponseCodec),
        map((response) => response.results[0]),
        map(actions.fetchPromotionByUid.success),
        catchError((err) =>
          of(
            actions.fetchPromotionByUid.failure(
              createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicPromotions',
              ),
            ),
          ),
        ),
      );
    }),
  );
