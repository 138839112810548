import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { isNonNullable, RemoteData, slugifyPayload } from '@gaming1/g1-utils';

import { ApplicationWithBettingState } from '../../store/types';

import { NavLeagueType, NavRegionType, NavSportType } from './types';

/* Sports regions leagues and trends */

const sportsRegionsLeaguesSelector = (state: ApplicationWithBettingState) =>
  state.betting.navigation.sportsRegionsLeagues;

const trendsSelector = (state: ApplicationWithBettingState) =>
  state.betting.navigation.trends;

/* Get sport navigation */

export const getSportsNavigationSelector = memoize(
  createSelector(
    sportsRegionsLeaguesSelector,
    (sportsRegionsLeagues): NavSportType[] =>
      sortBy(
        Object.values(sportsRegionsLeagues.sports)
          .map((sport) => sport.data)
          .filter(isNonNullable),
        'Order',
      ),
  ),
);

export const getSportNavigationByIdSelector = createSelector(
  sportsRegionsLeaguesSelector,
  (sportsRegionsLeagues) =>
    memoize(
      (sportId: number): NavSportType | undefined =>
        sportsRegionsLeagues.sports[sportId]?.data,
    ),
);

export const getSportsNavigationStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.navigation.requests.getSports.status;

export const getSportsNavigationErrorMessageSelector = (
  state: ApplicationWithBettingState,
) => state.betting.navigation.requests.getSports.errorMessage;

/* Get regions navigation */

export const getRegionsNavigationSelector = createSelector(
  sportsRegionsLeaguesSelector,
  (sportsRegionsLeagues) =>
    memoize((sportId: number): NavRegionType[] =>
      sortBy(
        Object.values(sportsRegionsLeagues.sports[sportId]?.regions || {})
          .map((region) => region.data)
          .filter(isNonNullable),
        'Order',
      ),
    ),
);

export const getRegionNavigationByIdSelector = createSelector(
  sportsRegionsLeaguesSelector,
  (sportsRegionsLeagues) =>
    memoize(
      (sportId: number, regionId: number): NavRegionType | undefined =>
        sportsRegionsLeagues.sports[sportId]?.regions[regionId]?.data,
      (sportId: number, regionId: number) => `${sportId}${regionId}`,
    ),
);

export const getRegionNavigationNameByIdSelector = createSelector(
  getRegionNavigationByIdSelector,
  (getRegion) =>
    memoize(
      (sportId: number, regionId: number): string | undefined =>
        getRegion(sportId, regionId)?.Name,
      (sportId: number, regionId: number) => `${sportId}${regionId}`,
    ),
);

export const getRegionNavigationLeaguesSelector = createSelector(
  sportsRegionsLeaguesSelector,
  (sportsRegionsLeagues) =>
    memoize(
      (sportId: number, regionId: number): NavLeagueType[] =>
        sortBy(
          Object.values(
            sportsRegionsLeagues.sports[sportId]?.regions[regionId]?.leagues ||
              {},
          ),
          'Order',
        ),
      (sportId: number, regionId: number) => `${sportId}${regionId}`,
    ),
);

export const getRegionsNavigationStatusSelector = (
  state: ApplicationWithBettingState,
) =>
  memoize(
    (sportId: number) =>
      state.betting.navigation.requests.getRegions[slugifyPayload({ sportId })]
        ?.status || RemoteData.NotAsked,
  );

export const getRegionsNavigationErrorMessageSelector = (
  state: ApplicationWithBettingState,
) =>
  memoize(
    (sportId: number) =>
      state.betting.navigation.requests.getRegions[slugifyPayload({ sportId })]
        ?.errorMessage,
  );

/* Get trends navigation */

export const getTrendsNavigationSelector = memoize(
  createSelector(trendsSelector, (trends): NavLeagueType[] =>
    sortBy(Object.values(trends), 'Order'),
  ),
);

export const getTrendsNavigationBySportIdSelector = createSelector(
  trendsSelector,
  (trends) =>
    memoize((sportId: number): NavLeagueType[] =>
      sortBy(
        Object.values(trends).filter((trend) => trend.SportId === sportId),
        'Order',
      ),
    ),
);

export const getTrendsNavigationStatusSelector = (
  state: ApplicationWithBettingState,
) => state.betting.navigation.requests.getTrends.status;

export const getTrendsNavigationErrorMessageSelector = (
  state: ApplicationWithBettingState,
) => state.betting.navigation.requests.getTrends.errorMessage;
