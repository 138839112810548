import React, { FC, memo, useContext } from 'react';

import {
  actions,
  getHasFrontNotificationError,
  useBettingSlipAllowActions,
  useBettingSlipCombiErrorFrontType,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

type BettingSlipSingleErrorProps = {
  stake?: string;
};

const BettingSlipCombiErrorComponent: FC<BettingSlipSingleErrorProps> = ({
  stake,
}) => {
  const { bettingSlipId, isInputStakeFocused } = useContext(BettingSlipContext);

  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const errorNotification = useBettingSlipCombiErrorFrontType(bettingSlipId)();

  const hasFrontNotificationError =
    getHasFrontNotificationError(errorNotification);

  const updateStake = (newStake: string) => {
    allowBSActions(actions.updateStake({ stake: newStake, bettingSlipId }));
  };
  const testId = `bettingslip-error-stake-combi-${errorNotification.Status}`;
  const callToActionsFunc = {
    updateStake,
  };

  const shouldDisplayError = !(!stake && isInputStakeFocused);

  return shouldDisplayError && hasFrontNotificationError ? (
    <Box
      width="100%"
      data-testid="bettingslip-combi-error-message-container"
      mb="sm"
    >
      <BettingSlipMessage
        testId={testId}
        notification={errorNotification}
        callToActionsFunc={callToActionsFunc}
      />
    </Box>
  ) : null;
};

export const BettingSlipCombiError = memo(BettingSlipCombiErrorComponent);
