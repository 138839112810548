import format from 'date-fns/format';
import React, { useContext, VFC } from 'react';
import { useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';

import { LastConnectionTime } from './styles';

/** Show the last connectin date and time of the user */
export const LastConnection: VFC = () => {
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const user = useSelector(userSelector);
  const config = useContext(ConfigContext);
  const { t } = useTranslation('user');

  return isUserLoggedIn &&
    config.user.showLastConnection &&
    !!user &&
    user.LastConnection ? (
    <LastConnectionTime
      data-testid="topbar-last-connection"
      dateTime={user.LastConnection}
    >
      {t('topBar.lastConnection', {
        time: format(new Date(user.LastConnection), config.i18n.dateTimeFormat),
      })}
    </LastConnectionTime>
  ) : null;
};
