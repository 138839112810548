import * as t from 'io-ts';

const prismicReference = t.type(
  {
    ref: t.string,
    // id: t.string,
    // label: t.string,
    isMasterRef: t.boolean,
  },
  'PrismicReference',
);

export const getPrismicReferencesResponse = t.type(
  {
    refs: t.tuple([prismicReference]),
  },
  'GetPrismicReferencesResponse',
);
