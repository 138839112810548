import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  device,
  fontSizes,
  fontWeight,
  getReadableColor,
  spaces,
} from '@gaming1/g1-style';
import { Box, Col } from '@gaming1/g1-ui';

const NUMPAD_BACKSPACE_ICON_WIDTH_IN_PX = 22;
const NUMPAD_BACKSPACE_ICON_HEIGHT_IN_PX = 22;

const MAX_HEIGHT_KEYBOARD_BOX_IN_PX = 45;
const MAX_WiDTH_KEYBOARD_BOX_IN_PERCENTAGE = 14.4;

export const SuggestionList = styled(Box)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${spaces('xs')};
`;
export type SuggestionBoxProps = {
  /** disabled the box */
  disabled?: boolean;
};
export const SuggestionBox = styled.span<SuggestionBoxProps>`
  ${fontWeight('bold')};
  cursor: pointer;
  background-color: ${colors('bettingOutcomeLocked')};
  color: ${getReadableColor('bettingOutcomeLocked')};
  font-size: ${fontSizes('sm')};
  padding: ${spaces('xs')} 0;
  flex: 1;
  text-align: center;
  margin-right: ${spaces('xxs')};

  &:last-child {
    margin-right: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: auto;
    `}

  ${device.canHover} {
    &:hover {
      background-color: ${colors('backgrounds', {
        index: 1,
        transform: lighten(0.1),
      })};
      color: ${getReadableColor('backgrounds', {
        index: 1,
        transform: lighten(0.1),
      })};
    }
  }
`;

export const KeyBoardBoxWrapper = styled(Col)`
  padding-right: 0;
`;
export const KeyBoardCloseWrapper = styled(Col)`
  padding-left: 0;
  margin-right: 0;
`;

export const KeyBoardBoxContainer = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

type KeyBoardBoxProps = {
  hasMaxHeight?: boolean;
};
export const KeyBoardBox = styled.div<KeyBoardBoxProps>`
  ${fontWeight('bold')};
  width: ${MAX_WiDTH_KEYBOARD_BOX_IN_PERCENTAGE}%;
  cursor: pointer;
  background-color: ${colors('bettingSlipKey')};
  color: ${getReadableColor('bettingSlipKey')};
  font-size: ${fontSizes('sm')};
  padding: ${spaces('sm')} 0;
  text-align: center;
  justify-content: center;
  margin-right: ${spaces('xxs')};
  margin-bottom: ${spaces('xxs')};
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  svg {
    margin: 0;
    path {
      fill: ${getReadableColor('bettingSlipKey')};
    }
    width: ${NUMPAD_BACKSPACE_ICON_WIDTH_IN_PX}px;
    height: ${NUMPAD_BACKSPACE_ICON_HEIGHT_IN_PX}px;
  }

  ${device.canHover} {
    &:hover {
      background-color: ${colors('backgrounds', {
        index: 2,
        transform: lighten(0.1),
      })};
      color: ${getReadableColor('backgrounds', {
        index: 2,
        transform: lighten(0.1),
      })};

      svg {
        path {
          fill: ${getReadableColor('backgrounds', {
            index: 2,
            transform: lighten(0.1),
          })};
        }
      }
    }
  }

  ${({ hasMaxHeight }) =>
    hasMaxHeight &&
    css`
      max-height: ${MAX_HEIGHT_KEYBOARD_BOX_IN_PX}px;
    `};
`;

export const KeyBoardClose = styled(KeyBoardBox)`
  width: auto;
  margin-right: 0;
`;
