import { IconType } from '@gaming1/g1-ui';

import { MessageData, MessageMeta, MessageUpdatedData } from './types';

/** Create the connection to the OtherLevels sdk */
export const createOL = (richInboxProjectKey: string, callback: () => void) => {
  if (window._ol) {
    window._ol('create', richInboxProjectKey, {}, () => {
      callback();
    });
  }
};

/** Verify if the trackingId (userId) is set and correct in the OtherLevels sdk. If not, set it. */
export const setTrackingIdOL = (trackingId: string, callback: () => void) => {
  if (window._ol) {
    window._ol('getTrackingId', (externalTrackingId: string) => {
      if (externalTrackingId !== trackingId) {
        if (window?._ol) {
          window._ol('setTrackingId', trackingId, callback);
        }
      } else {
        callback();
      }
    });
  }
};

/** Send a request to get the messages to the OtherLevels sdk. It will trigger the listener below */
export const getMessagesOL = () => {
  if (window._ol) {
    window._ol('rims.getNewMessages', {});
  }
};

/** Create a listener on all type of messages in the OtherLevels sdk */
export const createListenerOL = (
  callback: (messages: MessageUpdatedData) => void,
) => {
  if (window._ol) {
    window._ol(
      'rims.onMessagesUpdated',
      (
        messages: MessageData[],
        newMessages: MessageData[],
        updatedMessages: MessageData[],
        deletedMessages: MessageData[],
        meta: MessageMeta,
      ): void => {
        const messageUpdatedData: MessageUpdatedData = {
          messages,
          newMessages,
          updatedMessages,
          deletedMessages,
          meta,
        };
        callback(messageUpdatedData);
      },
    );
  }
};

/** Ask a message deletion to the OtherLevels sdk */
export const markAsDeletedOL = (msgId: number): void => {
  if (window._ol) {
    window._ol('rims.markAsDeleted', msgId);
  }
};

/** Ask to mark the message as read to the OtherLevels sdk */
export const markAsReadOL = (msgId: number): void => {
  if (window._ol) {
    window._ol('rims.markAsRead', msgId);
  }
};

/** List of class name and corresponding icons */
const INBOX_ICONS: { [k: string]: IconType } = {
  basketball: 'Basketball',
  blackjack: 'CardsHeart',
  casino: 'Cherry',
  coins: 'Coin',
  fire: 'Flame',
  football: 'Soccer',
  freebet: 'Freebet',
  gift: 'Gift',
  giftbox: 'Gift',
  important: 'DiscWarning',
  live: 'Live',
  livecasino: 'CardsLive',
  luck: 'Luck',
  new: 'New',
  newgames: 'ShinyCherry',
  slots: 'Slot',
  spinquest: 'Spinquest',
  sport: 'SportsAZEu',
  tournament: 'Tournaments',
};

/** Get the icon depending on the class */
export const getIconType = (metaClass = '') =>
  INBOX_ICONS[metaClass] || 'Envelope';
