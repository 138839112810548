import { FluxStandardAction } from '@gaming1/g1-utils';

type StartsWithAction = (
  words: string[] | null,
) => (actionToTest: { type: string }) => boolean;

/**
 * startsWithAction help to determine if an action is valid
 * @param whitelist an array of action type
 * @returns a function that test if action type (a string) start with one item of the whitelist
 */
export const startsWithAction: StartsWithAction = (whitelist) => {
  if (!whitelist) {
    return () => true;
  }

  return ({ type }) => {
    const allowed = !!whitelist.find((prefix) => type.indexOf(prefix) === 0);
    return allowed;
  };
};

/**
 * Returns true if
 * - the action has a meta.dispatcherId property
 * - the property is different than the given dispatcherId
 * @param dispatcherId the current dispatcher id
 * @param action an action from store
 */
export const isExternalAction = (
  dispatcherId: string,
  action: FluxStandardAction<
    string,
    unknown,
    undefined | { dispatcherId: string }
  >,
): boolean =>
  !!action?.meta?.dispatcherId && action.meta.dispatcherId !== dispatcherId;
