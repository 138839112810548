import React, {
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useState,
  VFC,
} from 'react';

import { MediaBreakPointNames } from '@gaming1/g1-style';
import { ResponsiveImg } from '@gaming1/g1-ui';

import { Placeholder } from '../Placeholder';

type ResponsiveSources = { [key in MediaBreakPointNames]?: string };

export type ResponsiveImageProps = {
  /** alt of the <img /> element */
  alt: string;
  /** Optional current breakpoint. Needed for sources to work */
  breakpoint?: MediaBreakPointNames;
  /** Class of the <img /> element */
  className?: string;
  /** Dark placeholder */
  dark?: boolean;
  /** Crop the image to respect the given ratio */
  forceRatio?: boolean;
  /** Show placeholder is loading problem */
  placeholderOnError?: boolean;
  /** Image aspect ratio (for the placeholder) */
  ratio?: number;
  /** Default source of the media */
  src: string;
  /** Optional sources overwriting the src by breakpoints */
  sources?: ResponsiveSources;
};

/** img that can show a placeholder when the source cannot be loaded */
export const ResponsiveImage: VFC<
  ResponsiveImageProps & ImgHTMLAttributes<HTMLImageElement>
> = ({
  alt,
  breakpoint,
  className = '',
  dark = false,
  forceRatio = false,
  loading = 'eager',
  placeholderOnError = false,
  ratio = 1,
  sources,
  src,
  ...rest
}) => {
  const [placeHolderVisibility, setPlaceHolderVisibility] = useState(false);

  // Find the best source for the breakpoint
  const setSourceImage = useCallback(() => {
    if (!breakpoint || !sources || !sources[breakpoint]) {
      return src;
    }
    return sources[breakpoint];
  }, [breakpoint, sources, src]);

  const [source, setSource] = useState(setSourceImage);

  // Update the source when props changes
  useEffect(() => {
    const newSource = setSourceImage();
    if (source !== newSource) {
      setSource(newSource);
    }
  }, [breakpoint, sources, src, setSourceImage, source]);

  // When the source changes, reset the place holder visibility
  useEffect(() => {
    setPlaceHolderVisibility(false);
  }, [source]);

  if (placeHolderVisibility) {
    if (placeholderOnError) {
      return (
        <Placeholder
          className={className}
          dark={dark}
          data-testid="image-placeholder"
          ratio={ratio}
        />
      );
    }
    return null;
  }
  return (
    <ResponsiveImg
      {...rest}
      $objectFit={forceRatio ? 'cover' : 'contain'}
      alt={alt}
      className={className}
      data-testid="image-img"
      loading={loading}
      onError={() => setPlaceHolderVisibility(true)}
      src={source}
    />
  );
};
