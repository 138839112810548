import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getAvailablePromo,
  hasAvailablePromo,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../common/store/errorMessages';
import { BettingEpic } from '../store/types';

import * as actions from './actions';
import {
  getAvailablePromotionsResponseCodec,
  hasAvailablePromotionsResponseCodec,
} from './codecs';

/**
 * hasAvailablePromotions epic
 */

export const hasAvailablePromotionsEpic: BettingEpic = (
  actions$,
  _,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.hasAvailablePromotions.request)),
    withLatestFrom(config$),
    switchMap(([, config]) => {
      const arePromotionsEnabled = config.betting.promotions.enabled;

      if (!arePromotionsEnabled) {
        return of(
          actions.hasAvailablePromotions.failure({
            status: DEFAULT_SERVER_ERROR_STATUS,
            errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
          }),
        );
      }

      return wsAdapter.request(hasAvailablePromo({})).pipe(
        mapGuard(hasAvailablePromotionsResponseCodec),
        map(actions.hasAvailablePromotions.success),
        catchError((err) =>
          of(
            actions.hasAvailablePromotions.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
            }),
          ),
        ),
      );
    }),
  );

/**
 * getAvailablePromotions epic
 */
export const getAvailablePromotionsEpic: BettingEpic = (
  actions$,
  state$,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getAvailablePromotions.request)),
    withLatestFrom(config$),
    switchMap(([{ payload }, config]) => {
      const arePromotionsEnabled = config.betting.promotions.enabled;

      if (!arePromotionsEnabled) {
        return of(
          actions.getAvailablePromotions.success({
            Notification: {
              Level: NotificationLevel.Information,
              Code: NotificationCode.Promo_Success,
            },
            Promotions: [],
            ...payload,
          }),
        );
      }

      return wsAdapter.request(getAvailablePromo({})).pipe(
        mapGuard(getAvailablePromotionsResponseCodec),
        map((data) =>
          actions.getAvailablePromotions.success({
            ...data,
            ...payload,
          }),
        ),
        catchError((err) =>
          of(
            actions.getAvailablePromotions.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              ...payload,
            }),
          ),
        ),
      );
    }),
  );
