import { animated, useTransition } from '@react-spring/web';
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
  HeightProps,
  MaxWidthProps,
  SpaceProps,
  WidthProps,
} from 'styled-system';

import { ColorName } from '@gaming1/g1-style';
import { usePrevious } from '@gaming1/g1-utils';

import { BACKDROP_ANIMATION_DURATION_IN_MS } from '../constants';
import { ScrollContext } from '../contexts';
import { Icon } from '../Icon';
import { IconType } from '../Icon/icons';
import { LayoutContext } from '../LayoutProvider/LayoutContext';

import {
  CloseButton,
  ModalContainer,
  ModalContentDiv,
  ModalIconWrapper,
} from './styles';

type StyledProps = HeightProps & WidthProps & MaxWidthProps & SpaceProps;

export type ModalProps = {
  /** Id linked to the modal, also used as test id */
  modalId: string;
  /** Callback called when the user clicks the close button or the backdrop */
  onClose: () => void;
  /** Callback called when the fade out animation of the backdrop is finished */
  onClosed?: () => void;
  /** Removes the default content padding */
  noPadding?: boolean;
  /** Icon displayed on top center of the modal */
  icon?: {
    /** Icon background color. Default: 'info' */
    bgColor?: ColorName;
    /** Icon height in pixels */
    height: number;
    /** Icon identifier */
    id: string;
    /** Icon type */
    type: IconType;
    /** Icon width in pixels */
    width: number;
  };
  /** Ensure the modal cannot be closed by clicking outside or pressing ESC */
  isClosableByUser?: boolean;
  /** Specifies whether the modal should be in full screen or not */
  fullscreen?: boolean;
  /** Whether to show the close button. Default: true */
  showCloseButton?: boolean;
  /** Set the visibility of the modal */
  visible: boolean;
} & StyledProps;

export const Modal: FC<ModalProps> = ({
  children,
  fullscreen,
  modalId,
  noPadding,
  onClose,
  onClosed,
  icon,
  isClosableByUser = true,
  showCloseButton = true,
  visible,
  ...styledProps
}) => {
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const { hideModal, visibleModal, showModal } = useContext(LayoutContext);
  const hideModalRef = useRef(hideModal);
  hideModalRef.current = hideModal;
  const showModalRef = useRef(showModal);
  showModalRef.current = showModal;

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    // Update the LayoutContext to notify that a modal has opened
    if (visible) {
      showModalRef.current(modalId, isClosableByUser);
    } else {
      // Update the LayoutContext to notify that a modal has closed
      hideModalRef.current(modalId);
    }
  }, [isClosableByUser, modalId, visible]);

  /*
    If the visibleModal from the LayoutContext has been set to null
    (by clicking on the backdrop), call the onClose callback
  */
  const previousContextVisibility = usePrevious(visibleModal);

  useLayoutEffect(() => {
    if (!visibleModal && previousContextVisibility === modalId) {
      onCloseRef.current();
    }
  }, [modalId, previousContextVisibility, visibleModal]);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const shouldBeVisible = visibleModal === modalId && visible;

  const transitions = useTransition(shouldBeVisible, {
    config: {
      clamp: false,
      duration: BACKDROP_ANIMATION_DURATION_IN_MS,
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onRest: () => {
      if (
        onClosed &&
        visibleModal !== modalId &&
        previousContextVisibility === modalId
      ) {
        onClosed();
      }
    },
  });

  /** Ensure the modal is properly closed if it's not in the dom anymore */
  useEffect(
    () => () => {
      if (isClosableByUser && visibleModal === modalId) {
        hideModalRef.current(modalId);
        onCloseRef.current();
      }
    },
    [isClosableByUser, modalId, visibleModal],
  );

  /** Ensure the modal is properly closed after a page change */
  const { pathname } = useLocation();
  const previousPathName = usePrevious(pathname);
  useEffect(() => {
    if (
      previousPathName !== pathname &&
      isClosableByUser &&
      visibleModal === modalId
    ) {
      onCloseRef.current();
      hideModalRef.current(modalId);
    }
  }, [isClosableByUser, modalId, pathname, previousPathName, visibleModal]);

  return transitions(
    (style, show) =>
      show &&
      createPortal(
        <animated.aside
          aria-hidden={shouldBeVisible ? undefined : 'true'}
          data-testid={modalId}
          aria-modal
          role="dialog"
          tabIndex={-1}
          style={{
            ...style,
            position: 'absolute',
            zIndex: theme.zIndices.modal,
          }}
        >
          <ModalContainer
            data-testid={`${modalId}-container`}
            fullscreen={fullscreen}
            hasIcon={!!icon}
            {...styledProps}
          >
            {showCloseButton && isClosableByUser && (
              <CloseButton
                onClick={() => {
                  onClose();
                }}
                aria-label="Close modal"
                data-testid={`${modalId}-closebutton`}
              >
                <Icon id="modal-close-button" type="Cross" />
              </CloseButton>
            )}
            {!!icon && (
              <ModalIconWrapper
                bgColor={icon.bgColor || 'info'}
                wrapperHeight={`${icon.height * 2}px`}
                wrapperWidth={`${icon.width * 2}px`}
              >
                <Icon
                  id={icon.id}
                  height={`${icon.height}px`}
                  width={`${icon.width}px`}
                  type={icon.type}
                />
              </ModalIconWrapper>
            )}
            <ModalContentDiv
              fullscreen={fullscreen}
              noCloseButton={!showCloseButton}
              noPadding={noPadding}
              ref={scrollRef}
            >
              <ScrollContext.Provider value={scrollRef}>
                {children}
              </ScrollContext.Provider>
            </ModalContentDiv>
          </ModalContainer>
        </animated.aside>,
        document.body,
      ),
  );
};
