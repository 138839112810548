import { BettingActivity } from '@gaming1/g1-requests-betting';
import { generateInitialRequestState } from '@gaming1/g1-utils';

import { combination } from '../../helpers';
import { BettingSlipconfig, BettingSlipItemState } from '../types/common';
import { ImportBettingSlipUnitState } from '../types/import';
import {
  BettingSlipOutcomeIdsSelection,
  BettingSlipRankState,
  BettingSlipsState,
  BettingSlipUnitState,
} from '../types/store';

export const getInitialTicketItem = (
  ids: BettingSlipOutcomeIdsSelection,
): BettingSlipItemState => ({
  id: ids.outcome,
  checked: true,
  outcome: { id: ids.outcome },
  market: { id: ids.market },
  event: { id: ids.event },
  oddChange: null,
  extra: ids.extra,
});

export const getInitialRootBettingSlipState = (): BettingSlipsState => ({
  bettingSlips: {},
  requests: {
    getInitData: generateInitialRequestState(null),
  },
  getInitData: {},
  config: { defaultStake: '' },
});

export const getInitialBettingSlipState = (
  config: BettingSlipconfig,
): BettingSlipUnitState => ({
  items: [],
  ranks: [],
  stake: config.defaultStake,
  selectedFreebet: null,
  freebetConditions: [],
  boostusConditions: [],
  selectedBoostus: null,
  promotionsIds: [],
  selectedPromotion: null,
  configuration: { type: 'combi', activity: BettingActivity.Sportbook },
  requests: {
    getOptimalBet: generateInitialRequestState(),
    placeBet: generateInitialRequestState(),
    riskManagement: generateInitialRequestState(),
  },
  riskManagement: {},
  placeBet: {},
  optimalBet: {},
});

export const getInitialBettingSlipStateByImport = (
  data: ImportBettingSlipUnitState,
  config: BettingSlipconfig,
): BettingSlipUnitState => ({
  ...getInitialBettingSlipState(config),
  ...{
    items: data.items.map((item) => ({
      ...getInitialTicketItem({
        outcome: item.outcome,
        market: item.market,
        event: item.event,
        extra: item.extra,
      }),
    })),
  },
  configuration: {
    type: data.type,
    activity: data.configuration?.activity || BettingActivity.Sportbook,
  },
  stake: data.stake || config.defaultStake,
  extra: {
    title: data.extra?.title,
    limits: {
      min: data.extra?.limits?.StakeMin,
      max: data.extra?.limits?.StakeMax,
      winnable: undefined,
    },
    manualBetConfigId: data.extra?.manualBetConfigId,
    firstDepositDone: data.extra?.firstDepositDone,
  },
});

const mappingTrixieByItemLenght: Record<number, string> = {
  3: 'TRIXIE',
  4: 'YANKEE',
  5: 'CANADIAN',
  6: 'HEINZ',
  7: 'SUPER-HEINZ',
  8: 'GOLIATH',
};
export const getRanks = (itemIds: number[]): BettingSlipRankState[] => {
  if (!mappingTrixieByItemLenght[itemIds.length]) {
    return [];
  }
  const ranks: BettingSlipRankState[] = [];
  for (let i = 2; i < itemIds.length; i += 1) {
    ranks.push({
      id: `SYSTEM_RANK_${i.toString()}`,
      available: true,
      stake: '0',
      type: 'normal',
      itemsCombination: combination(itemIds, i),
    });
  }
  // add combi combination
  ranks.push({
    id: 'SYSTEM_COMBI',
    available: true,
    stake: '0',
    type: 'normal',
    itemsCombination: combination(itemIds, itemIds.length),
  });

  // add trixie
  ranks.push({
    id: `SYSTEM_${mappingTrixieByItemLenght[itemIds.length]}`,
    available: true,
    stake: '0',
    type: 'trixie',
    itemsCombination: [],
  });
  return ranks;
};

export const getRankById = (ranks: BettingSlipRankState[], id: string) =>
  ranks.find((rank) => rank.id === id);

/**
 * Ticket item
 */
export const haveTicketItem = (
  list: BettingSlipItemState[],
  ids: BettingSlipOutcomeIdsSelection,
): boolean => list.findIndex((item) => item.outcome.id === ids.outcome) > -1;

export const addTicketItem = (
  list: BettingSlipItemState[],
  ids: BettingSlipOutcomeIdsSelection,
): BettingSlipItemState[] =>
  haveTicketItem(list, ids) ? list : [...list, getInitialTicketItem(ids)];

export const removeTicketItem = (
  list: BettingSlipItemState[],
  ids: BettingSlipOutcomeIdsSelection,
) => list.filter((item) => !(item.outcome.id === ids.outcome));

export const getTicketItem = (bettingSlip: BettingSlipUnitState, id: number) =>
  bettingSlip.items.find((item) => item.id === id);
