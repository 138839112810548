import { useState } from 'react';

import {
  getLoggers,
  getLoglevelNameFromNumber,
  LogLevelNames,
  setLoggerLevel,
} from '@gaming1/g1-logger';
import { Button, Col, Row, Select } from '@gaming1/g1-ui';

const logLevels: LogLevelNames[] = [
  'TRACE',
  'DEBUG',
  'INFO',
  'WARN',
  'ERROR',
  'SILENT',
];

const isLogLevel = (level: string): level is LogLevelNames =>
  logLevels.includes(level as LogLevelNames);

const levelOptions = logLevels.map((label) => ({
  value: label,
  label,
}));

export const LoggersDebugger = () => {
  const [, setLatestChange] = useState(new Date().getTime());
  const handleLogLevelSwitch = (level: string, loggerName?: string) => {
    if (isLogLevel(level)) {
      setLoggerLevel({ level, loggerName });
      // Dirty trick to force a rerender since the loggers level change does not trigger one
      setLatestChange(new Date().getTime());
    }
  };
  return (
    <>
      <p>
        Switch all loggers to{' '}
        {logLevels.map((level) => (
          <Button
            type="secondary"
            onClick={() => handleLogLevelSwitch(level)}
            key={level}
            mr="xs"
            mb="xs"
          >
            {level}
          </Button>
        ))}
      </p>
      <Row>
        {Object.entries(getLoggers())
          .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
          .map(([name, logger]) => (
            <Col xs={6} md={4} lg={3} key={name}>
              <Select
                label={name}
                options={levelOptions}
                value={getLoglevelNameFromNumber(logger.getLevel())}
                onValueChange={(value) => handleLogLevelSwitch(value, name)}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};
