import styled, { css } from 'styled-components';

import {
  colors,
  elevation,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const TICKET_BOTTOM_SPACE = '1px';

type TicketItemProps = { lock: boolean; isError: boolean; checked: boolean };

export const TicketItem = styled(Box)<TicketItemProps>`
  justify-content: center;
  ${elevation(1, { noShadow: true })};
  margin-bottom: ${TICKET_BOTTOM_SPACE};
  padding: ${spaces('sm')} 0;

  ${({ checked }) =>
    !checked &&
    css`
      opacity: ${opacity('semi')};
    `}

  ${({ lock, isError }) =>
    (lock || isError) &&
    css`
      border-left: 3px ${colors('danger')} solid;
      background-color: ${colors('backgrounds', { index: 2 })};
    `};
`;

export const TicketItemWrapper = styled.div`
  margin-right: ${spaces('sm')};
  margin-left: ${spaces('sm')};
`;

export const EventNameContainer = styled(Box).attrs({
  'data-testid': 'bettingslip-item-event-name',
})`
  font-size: ${fontSizes('md')};
  opacity: ${opacity('semi')};
`;

export const OutcomeNameContainer = styled.div.attrs({
  'data-testid': 'bettingslip-item-outcome-name',
})`
  ${fontWeight('bold')};
  font-size: ${fontSizes('md')};
  margin-left: ${spaces('xs')};
  margin-right: ${spaces('xs')};
`;

export const OutcomeBaseContainer = styled.span`
  font-size: ${fontSizes('md')};
  margin-left: ${spaces('xxs')};
`;
