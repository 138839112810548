import { of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getSportNav, getTrends } from '@gaming1/g1-requests-betting';
import { createFailurePayload, mapGuard, RemoteData } from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import * as sportbookActions from '../../sportbook/store/actions';
import { BettingEpic } from '../../store/types';

import * as actions from './actions';
import { getRegionsResponseCodec, getTrendsResponseCodec } from './codecs';
import {
  getRegionsNavigationStatusSelector,
  getTrendsNavigationStatusSelector,
} from './selectors';

const requestOptions = { retryOnTimeout: true, timeoutAfterMs: 10000 };

/** Ask sports navigation (mapTo askSports request) */
export const askSportsNavigationEpic: BettingEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.askSportsNavigation)),
    mapTo(sportbookActions.askSports()),
  );

/** Ask region navigation (mapTo getRegionsNavigation request if no request currently pending) */
export const askRegionsNavigationEpic: BettingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.askRegionsNavigation)),
    delay(1),
    withLatestFrom(state$),
    filter(
      ([{ payload }, state]) =>
        getRegionsNavigationStatusSelector(state)(payload.sportId) ===
        RemoteData.NotAsked,
    ),
    map(([{ payload }]) => actions.getRegionsNavigation.request(payload)),
  );

/** Get regions navigation */
export const getRegionsNavigationEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRegionsNavigation.request)),
    switchMap(({ payload }) => {
      const request = payload;
      return wsAdapter
        .request(getSportNav({ SportId: request.sportId }), requestOptions)
        .pipe(
          mapGuard(getRegionsResponseCodec),
          map((response) =>
            actions.getRegionsNavigation.success({ data: response, request }),
          ),
          catchError((err) =>
            of(
              actions.getRegionsNavigation.failure({
                data: createFailurePayload(err, commonBettingErrorMessages),
                request,
              }),
            ),
          ),
        );
    }),
  );

/** Ask trends navigation (mapTo getTrendsNavigation request if no request currently pending) */
export const askTrendsNavigationEpic: BettingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.askTrendsNavigation)),
    delay(1),
    withLatestFrom(state$),
    filter(
      ([, state]) =>
        getTrendsNavigationStatusSelector(state) !== RemoteData.NotAsked &&
        false,
    ),
    mapTo(actions.getTrendsNavigation.request()),
  );

/** Get trends navigation */
export const getTrendsNavigationEpic: BettingEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTrendsNavigation.request)),
    switchMap(() =>
      wsAdapter.request(getTrends({}), requestOptions).pipe(
        mapGuard(getTrendsResponseCodec),
        map(actions.getTrendsNavigation.success),
        catchError((err) =>
          of(
            actions.getTrendsNavigation.failure(
              createFailurePayload(err, commonBettingErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
