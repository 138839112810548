import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from './actions';
import { PlatformJackpotState } from './types/entities';
import { PlatformJackpotActions } from './types/store';

export const initialState: PlatformJackpotState = {
  list: null,
  requests: {
    getPlatformJackpotList: generateInitialRequestState(null),
  },
};

export const platformJackpotReducer = (
  state: PlatformJackpotState = initialState,
  action: PlatformJackpotActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.getPlatformJackpots.request):
        produceLoadingState(draftState.requests.getPlatformJackpotList);
        break;

      /** Failures */
      case getType(actions.getPlatformJackpots.failure):
        produceFailureState(
          draftState.requests.getPlatformJackpotList,
          action.payload,
        );
        break;

      /** Successes */
      case getType(actions.getPlatformJackpots.success):
        produceSuccessState(draftState.requests.getPlatformJackpotList);
        draftState.list = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
