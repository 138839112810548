import { lighten } from 'polished';
import styled from 'styled-components';

import { colors, media, spaces } from '@gaming1/g1-style';

export const DrawerNavContainer = styled.div`
  background-color: ${colors('drawerNavBackground')};
  min-height: 100%;
`;

export const CloseDrawerButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${colors('text')};
  cursor: pointer;
  outline: none;
  height: 17px;
  margin-top: ${spaces('xs')};

  ${media.md} {
    margin-top: ${spaces('xxl')};
  }

  &:hover {
    color: ${colors('text', { transform: lighten(0.3) })};
  }
`;

export const UserSearchWrapper = styled.div`
  margin-left: ${spaces('sm')};
  margin-right: ${spaces('sm')};

  ${media.md} {
    margin-left: ${spaces('xxxl')};
    margin-right: ${spaces('xxxl')};
  }
`;
