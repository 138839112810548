import { useContext } from 'react';
import { useSelector } from 'react-redux';

import {
  getHasAvailablePromotionsSelector,
  getHasBoostusSelector,
  getHasDailyOffersSelector,
  getTrendsNavigationSelector,
  liveEventsCountSelector,
  sportAliasSelector,
  useDisplaySuperOdds,
} from '@gaming1/g1-betting';
import { MenuList } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { useMediaBreakPoint } from '@gaming1/g1-ui';

import { truncateString } from '../../../helpers';
import { useBettingRoutePath } from '../../../hooks';
import { getIconType } from '../../../sportbook/helpers';
import { BettingMenuContext } from '../../BettingMenuContext';

export const BREAKPOINT_NAVIGATION = 'lg';

/**
 Return true if the user is on Desktop size
 */
export const useIsDesktopNavigation = () =>
  useMediaBreakPoint({ min: BREAKPOINT_NAVIGATION });

/**
 * Returns the default menu lists merged with the menu lists from the context.
 */
export const useMenuLists = () => {
  const { t } = useTranslation('betting');
  const getBettingRoutePath = useBettingRoutePath();
  const { menus } = useContext(BettingMenuContext);

  const getSportAlias = useSelector(sportAliasSelector);
  const liveCount = useSelector(liveEventsCountSelector);
  const trends = useSelector(getTrendsNavigationSelector);
  const hasBoostus = useSelector(getHasBoostusSelector);
  const hasDailyOffers = useSelector(getHasDailyOffersSelector);
  const hasSuperOdds = useDisplaySuperOdds();
  const hasPromotions = useSelector(getHasAvailablePromotionsSelector);

  const mainMenuList: MenuList = [
    ...menus.main.firstItems,
    {
      icon: 'Home',
      label: t('menu.home'),
      path: getBettingRoutePath('bettingHome'),
      testId: 'betting-home',
    },
    {
      icon: 'Live',
      label: `${t('menu.live')} ${liveCount ? `(${liveCount})` : ''}`,
      path: getBettingRoutePath('liveListTop'),
      testId: 'betting-live',
    },
    ...menus.main.lastItems,
  ];

  const quickLinksMenuList: MenuList = [
    ...menus.main.firstItems,
    ...menus.main.lastItems,
  ];

  const mainAuthenticatedMenuList: MenuList = [
    ...menus.mainAuthenticated.firstItems,
    {
      icon: 'Gift',
      label: t('menu.myGifts'),
      path: getBettingRoutePath('myUnlockedGifts'),
      testId: 'betting-my-unlocked-gifts',
    },
    {
      icon: 'MyBets',
      label: t('menu.myBets'),
      path: getBettingRoutePath('myOpenBets'),
      testId: 'betting-mybets',
    },
    ...menus.mainAuthenticated.lastItems,
  ];

  const trendsMenuList: MenuList = trends.map((trend) => ({
    label: truncateString(trend.Name, 15),
    exact: true,
    path: getBettingRoutePath('league', {
      sportId: trend.SportId.toString(),
      regionId: trend.RegionId.toString(),
      leagueId: trend.Id.toString(),
    }),
    testId: 'betting-trend',
    icon: getIconType(getSportAlias(trend.SportId)),
  }));

  /**
   * Here we could add every menu that could be link to a feature like the boostus, etc...
   *
   * If we need to have it separated someday in a specific menu, it could be great to have this separation.
   */
  const featuredMenu: MenuList = [
    ...(hasPromotions
      ? [
          {
            icon: 'BettingPromotion' as const,
            label: t('allSports.menu.promotions.text'),
            path: getBettingRoutePath('promotions'),
            testId: 'promotions-menu-entry',
          },
        ]
      : []),
    ...(hasSuperOdds
      ? [
          {
            icon: 'SuperOdds' as const,
            label: t('allSports.menu.superOdds.text'),
            path: getBettingRoutePath('superOdds'),
            testId: 'superOdds-menu-entry',
          },
        ]
      : []),
    ...(hasBoostus
      ? [
          {
            icon: 'Boostus' as const,
            label: t('allSports.menu.boostus.text'),
            path: getBettingRoutePath('boostus'),
            testId: 'boostus-menu-entry',
          },
        ]
      : []),
    ...(hasDailyOffers
      ? [
          {
            icon: 'DailyOffer' as const,
            label: t('allSports.menu.dailyOffers.text'),
            path: getBettingRoutePath('dailyOffers'),
            testId: 'dailyOffers-menu-entry',
          },
        ]
      : []),
  ];

  return {
    mainMenuList,
    quickLinksMenuList,
    mainAuthenticatedMenuList,
    servicesMenuList: menus.services,
    trendsMenuList,
    featuredMenu,
  };
};
