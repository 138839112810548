import React, { FC } from 'react';

import {
  BettingHistoryElementType,
  isEventLostStatus,
  isEventWinStatus,
} from '@gaming1/g1-betting';
import { Box, Icon } from '@gaming1/g1-ui';

import { getIconForStatus } from '../helpers';

import { OutcomeStatus } from './styles';

type StatusInfoProps = {
  info: BettingHistoryElementType;
};
export const StatusInfo: FC<StatusInfoProps> = ({ info }) => (
  <Box flexDirection="row" data-testid="history-element-status-info">
    <OutcomeStatus
      key={getIconForStatus(info)}
      isLost={isEventLostStatus(info)}
      isWin={isEventWinStatus(info)}
    >
      <Icon
        height={13}
        width={13}
        id={`outcome-status-${getIconForStatus(info)}`}
        type={getIconForStatus(info)}
      />
    </OutcomeStatus>
  </Box>
);
