import React, { FC } from 'react';

import { useMediaBreakPoint } from '../hooks';

export type RenderOnMediaProps = Parameters<typeof useMediaBreakPoint>[0] & {
  children: React.ReactNode;
};

/** Conditonnaly renders its children base on a min or max breakpoint */
export const RenderOnMedia: FC<RenderOnMediaProps> = ({
  children,
  ...args
}) => {
  const shouldShowChildren = useMediaBreakPoint(args);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return shouldShowChildren ? <>{children}</> : null;
};
