import { darken, lighten, readableColor } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { color, fontSize, space, width } from 'styled-system';

import {
  colors,
  elevation,
  fontWeight,
  getReadableColor,
  isColorDark,
  opacity,
  spaces,
} from '@gaming1/g1-style';

import { SimpleButton } from '../styles';
import { StyledSystemProps } from '../types';

import { ButtonType } from './types';

export const RIPPLE_ANIMATION_DELAY_IN_MS = 600;

export type AppButtonProps = StyledSystemProps & {
  /** Start the ripple effect animation */
  animating?: boolean;
  /** Display the button as a block element */
  block?: boolean;
  /** The type of button */
  colorType?: ButtonType;
  /** Disable the button */
  disabled?: boolean;
  /** Show a loader */
  loading?: boolean;
  /** No border on button */
  noBorder?: boolean;
};

const getActiveButtonColors = (
  backgroundColor: string,
  borderColor: string,
  textColor: string,
) => css`
  &:hover,
  &:focus,
  &:active {
    border-color: ${borderColor};
    background-color: ${backgroundColor};
    color: ${textColor};
  }
`;

const getActiveOthersButtonColors = (
  backgroundColor: string,
  borderColor: string,
  textColor: string,
) => css`
  &:hover,
  &:focus,
  &:active {
    border-color: ${isColorDark(backgroundColor)
      ? lighten(0.2, borderColor)
      : darken(0.2, borderColor)};
    background-color: ${isColorDark(backgroundColor)
      ? lighten(0.2, backgroundColor)
      : darken(0.2, backgroundColor)};
    /* If the background won't change color, change the text color */
    ${backgroundColor === 'transparent' &&
    css`
      color: ${isColorDark(textColor)
        ? lighten(0.2, textColor)
        : darken(0.2, textColor)};
    `}
  }
`;

export const appButtonCSS = css<AppButtonProps>`
  /* General */
  cursor: pointer;
  display: inline-block;
  vertical-align: middle; /* fix the pseudo bottom margin induced by inline-lock display */
  padding: ${spaces('sm')} ${spaces('sm')};
  position: relative;
  border-radius: ${({ theme }) =>
    theme.options.buttonsRoundBorder ? '30px' : '0'};

  /* Colors */
  border-color: ${colors('inputBorder')};
  border-width: 1px;
  border-style: solid;
  color: ${colors('text')};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  ${elevation(0)};

  /* Text */
  letter-spacing: 0.5px;
  font-size: ${({ theme }) => theme.options.buttonsFontSize};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  ${({ theme: { options } }) =>
    options.buttonsTitleFont
      ? fontWeight(options.buttonsFontWeight, 'title')
      : fontWeight(options.buttonsFontWeight, 'content')};

  /* Remove browsers default outline shadow */
  &:focus {
    outline: none;
  }

  /* Ripple effect */
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, white 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(0, 0);
    opacity: ${opacity('semi')};
    border-radius: 50%;
  }
  ${({ animating }) =>
    animating &&
    css`
      &:after {
        transform: scale(10, 6);
        opacity: ${opacity('transparent')};
        transition: transform ${RIPPLE_ANIMATION_DELAY_IN_MS / 1.2 / 1000}s,
          opacity ${RIPPLE_ANIMATION_DELAY_IN_MS / 1000}s;
      }
    `}

  /* Primary / Submit buttons */
  ${({ colorType, theme, disabled }) =>
    (colorType === 'primary' || colorType === 'submit') &&
    css`
      background-color: ${colors('buttonPrimaryBackground')};
      border-color: ${colors('buttonPrimaryBorder')};
      color: ${colors('buttonPrimaryText')};
      ${disabled !== true &&
      getActiveButtonColors(
        theme.colors.buttonPrimaryBackgroundActive,
        theme.colors.buttonPrimaryBorderActive,
        theme.colors.buttonPrimaryTextActive,
      )}
    `}

  /* Secondary buttons */
  ${({ colorType, disabled, theme }) =>
    colorType === 'secondary' &&
    css`
      background-color: ${colors('buttonSecondaryBackground')};
      border-color: ${colors('buttonSecondaryBorder')};
      color: ${colors('buttonSecondaryText')};
      ${disabled !== true &&
      getActiveButtonColors(
        theme.colors.buttonSecondaryBackgroundActive,
        theme.colors.buttonSecondaryBorderActive,
        theme.colors.buttonSecondaryTextActive,
      )}
    `}

  /* Tertiary buttons */
  ${({ colorType, disabled, theme }) =>
    colorType === 'tertiary' &&
    css`
      background-color: ${colors('buttonTertiaryBackground')};
      border-color: ${colors('buttonTertiaryBorder')};
      color: ${colors('buttonTertiaryText')};
      ${disabled !== true &&
      getActiveButtonColors(
        theme.colors.buttonTertiaryBackgroundActive,
        theme.colors.buttonTertiaryBorderActive,
        theme.colors.buttonTertiaryTextActive,
      )}
    `}

  /* Danger buttons */
  ${({ colorType, disabled, theme }) =>
    colorType === 'danger' &&
    css`
      background-color: ${colors('danger')};
      border-color: ${colors('danger')};
      color: ${readableColor(
        theme.colors.danger,
        theme.colors.textDark,
        theme.colors.textLight,
      )};
      ${disabled !== true &&
      getActiveOthersButtonColors(
        theme.colors.danger,
        theme.colors.danger,
        getReadableColor('danger')({ theme }),
      )}
    `}

    /* Warning buttons */
  ${({ colorType, disabled, theme }) =>
    colorType === 'warning' &&
    css`
      background-color: ${colors('warning')};
      border-color: ${colors('warning')};
      color: ${readableColor(
        theme.colors.warning,
        theme.colors.textDark,
        theme.colors.textLight,
      )};
      ${disabled !== true &&
      getActiveOthersButtonColors(
        theme.colors.warning,
        theme.colors.warning,
        getReadableColor('warning')({ theme }),
      )}
    `}

  /* Props */
  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  ${({ block }) =>
    block &&
    css`
      display: block;
      width: 100%;
    `}

  ${({ disabled, loading }) =>
    disabled &&
    !loading &&
    css`
      opacity: ${opacity('translucent')};
      cursor: default;
      &:after {
        content: none;
      }
    `}

  ${space}
  ${width}
  ${fontSize}
  ${color}
`;

export const AppButton = styled(SimpleButton)<AppButtonProps>`
  ${appButtonCSS}
`;

export const AppButtonAnchor = styled.a<AppButtonProps>`
  ${appButtonCSS}
`;

export const AppButtonLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['colorType', 'block'].includes(prop),
})<AppButtonProps>`
  ${appButtonCSS}
`;

export const LoaderWrapper = styled.div`
  height: 1em;
  line-height: 1;

  /** This ensure the child is well centered even if it's bigger than the '1em height' */
  display: flex;
  justify-content: center;
  align-items: center;
`;
