import styled, { css, keyframes } from 'styled-components';

import { elevation, media, zIndex } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const USED_GIFT_GRADIENT_DIRECTION_IN_DEGREE = '256.65';

export const BOOSTUS_LINEAR_GRADIENT_DIRECTION_IN_DEGREE = '135.03';

export const OUTCOME_GIFT_LINEAR_GRADIENT_DIRECTION_IN_DEGREE = '227.43';

export const SUPER_ODDS_GIFT_LINEAR_GRADIENT_DIRECTION_IN_DEGREE = '39.4';

export const PROMOTIONS_GIFT_LINEAR_GRADIENT_DIRECTION_IN_DEGREE = '134.92';

export const BettingSlipTitleContainer = styled(Box)`
  ${elevation(1, { noShadow: true })};
  position: sticky;
  top: 0;
  z-index: ${zIndex(1)};
  ${media.lg} {
    position: static;
  }
`;

const oddUpdateBlinking = keyframes`
  0% { opacity: 0; }
  8% { opacity: 1;}
  16% { opacity: 0; }
  24% { opacity: 1;}
  32% { opacity: 0; }
  40% { opacity: 1; }
  94% { opacity: 1; }
  100% { opacity: 0; }
`;

export const oddUpdateAnimation = () =>
  css`
    animation: 10s forwards ${oddUpdateBlinking};
  `;
