import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';

export const PrismicStyledTable = styled.table`
  max-width: 810px;
  margin: auto;
  width: 100%;
  border-collapse: collapse;
`;

export const PrismicTh = styled.th`
  text-align: left;
  padding: ${spaces('xs')};
  padding-bottom: ${spaces('sm')};
  border-bottom: 1px solid ${colors('separator')};
`;
export const PrismicTd = styled.td`
  padding: ${spaces('xs')};
`;

export const PrismicTr = styled.tr`
  padding: ${spaces('xs')};
  &:nth-child(even) {
    background-color: ${({ theme }) =>
      theme.dark ? theme.colors.backgrounds[1] : theme.colors.neutrals[1]};
  }
  &:nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.dark ? theme.colors.backgrounds[1] : theme.colors.neutrals[0]};
  }
`;
