import React, { FC, memo } from 'react';

import { actions, useBettingSlipAllowActions } from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Icon } from '@gaming1/g1-ui';

import { BettingSlipClearAllButton } from './styles';

type BettingSlipClearAllProps = {
  /** The bettingSlip id to clean */
  bettingSlipId: string;
};

/** Render a button to clean the bettingSlip when pressed */
export const BettingSlipClearAllComponent: FC<BettingSlipClearAllProps> = ({
  bettingSlipId,
}) => {
  const { t } = useTranslation('betting');

  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const cleanBettingSlip = () => {
    allowBSActions(actions.cleanBettingSlip(bettingSlipId));
  };

  return (
    <BettingSlipClearAllButton
      data-testid="clear-bettingslip"
      onClick={cleanBettingSlip}
    >
      {t('bettingSlip.clearAll.text')}
      <Icon id="clear-bettingslip" type="Trash" ml="xs" />
    </BettingSlipClearAllButton>
  );
};

export const BettingSlipClearAll = memo(BettingSlipClearAllComponent);
