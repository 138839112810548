import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import {
  colors,
  elevation,
  fontSizes,
  getReadableColor,
  opacity,
  spaces,
} from '@gaming1/g1-style';

export type ChipsProps = {
  active?: boolean;
  disabled?: boolean;
} & SpaceProps;

const chipsActiveStyle = css`
  background-color: ${colors('chipsActive')};
  color: ${getReadableColor('chipsActive')};
`;

const chipStyle = ({ active = false, disabled = false }: ChipsProps) => css`
  ${elevation(1)};

  background-color: ${colors('chips')};
  color: ${getReadableColor('chips')};

  display: flex;
  align-items: center;
  border-radius: ${spaces('lg')};
  border: none;
  cursor: pointer;
  flex-grow: 0;
  font-size: ${fontSizes('md')};
  justify-content: center;
  margin-bottom: ${spaces('xs')};
  padding: ${spaces('xs')} ${spaces('md')};

  text-decoration: none;

  :last-child {
    margin-right: 0;
  }

  &.active {
    ${chipsActiveStyle}
  }

  ${active && chipsActiveStyle}

  ${disabled
    ? css`
        opacity: ${opacity('seeThrough')};
        cursor: default;
      `
    : css`
        :focus,
        :hover {
          outline: none;
          background-color: ${colors('chipsHover')};
          color: ${getReadableColor('chipsHover')};
        }
      `}
`;
export const LinkChips = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<ChipsProps>`
  ${chipStyle}
  ${space}
`;

export const Chips = styled.button<ChipsProps>`
  ${chipStyle}
  ${space}
`;
