/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       13:53:56 25-10-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  FullSearchRequest,
  FullSearchResponse,
  LightSearchRequest,
  LightSearchResponse,
} from '../entities';

export const lightSearch = (options: LightSearchRequest) =>
  createRequest<LightSearchResponse>(201, 'LightSearch', options);

export const fullSearch = (options: FullSearchRequest) =>
  createRequest<FullSearchResponse>(201, 'FullSearch', options);
