import { createContext } from 'react';

import { NotificationType } from './types';

export type PartialNotificationContent = {
  message: string;
  timestamp?: number;
  type: NotificationType;
  urlToNotify?: string;
};

export type NotificationContent = Required<PartialNotificationContent>;

/**
 * MUST BE IN PRIORITY ORDER (danger > warning > success > info)
 *
 */
export type NotificationQueue = {
  danger: NotificationContent[];
  warning: NotificationContent | null;
  success: NotificationContent | null;
  info: NotificationContent | null;
};

export const NotificationContext = createContext<{
  /** The notification queue */
  notificationQueue: NotificationQueue;
  /** Add a new notification to the queue */
  addNotification: (notification: PartialNotificationContent) => void;
  /** Callback called when a notification for given type is closed */
  closeNotification: (type: NotificationType) => void;
}>({
  notificationQueue: {
    danger: [],
    warning: null,
    success: null,
    info: null,
  },
  addNotification: () => undefined,
  closeNotification: () => undefined,
});
