/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { EventRequest, EventResponse } from '../entities';

export const getEvent = (options: EventRequest) =>
  createRequest<EventResponse>(201, 'GetEvent', options);
