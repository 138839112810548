import { License } from '@gaming1/g1-config';

/** Default throttle time to trigger a network action after a user action */
export const THROTTLE_TIME_IN_MS = 500;

/** Different informations about licenses */
export const LICENSES_METADATA: {
  [k in License]: { name: string; minimalAge: number };
} = {
  A: { name: 'gaming', minimalAge: 21 },
  B: { name: 'dice', minimalAge: 21 },
  F: { name: 'betting', minimalAge: 18 },
};
