import { createAsyncAction } from 'typesafe-actions';

import { GetGamesCategoriesRequestDTO } from '@gaming1/g1-requests-gaming';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GamesCategoriesData,
  GamesData,
  GetPaginatedGameListRequest,
} from './types';

/**
 * The additional key property is for storing the request metadata in separated
 * objects
 */
export const fetchGames = createAsyncAction(
  'gaming/fetch_games_request',
  'gaming/fetch_games_success',
  'gaming/fetch_games_failure',
)<
  GetPaginatedGameListRequest,
  { data: GamesData; request: GetPaginatedGameListRequest },
  { data: FailurePayload; request: GetPaginatedGameListRequest }
>();

/**
 * Send a request to fetch the game categories
 */
export const fetchGameCategories = createAsyncAction(
  'gaming/fetch_categories_request',
  'gaming/fetch_categories_success',
  'gaming/fetch_categories_failure',
)<Partial<GetGamesCategoriesRequestDTO>, GamesCategoriesData, FailurePayload>();
