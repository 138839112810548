import React, { FC, useContext } from 'react';

import {
  bettingSlipIsCashableGetterSelector,
  hasCashoutAvailableForPlayerSelector,
  useBettingGetterSelector,
  useIsOptimalBetIsAplyOnBettingSlip,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../common/components/BettingIcon';
import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipOdds } from '../BettingSlipOdds/BettingSlipOdds';
import { BettingSlipCombiPotWinning } from '../BettingSlipPotWinning/BettingSlipCombiPotWinning';
import { BettingSlipCombiStake } from '../BettingSlipStake/BettingSlipCombiStake';
import { BettingSlipValidation } from '../BettingSlipValidation';

import { BettingSlipFooterLineContainer } from './styles';

/** Render a Combi BettingSlip (ticket) with a stake, an odd, a potWinning and a CTA to validate the BS */
export const BettingSlipFooterCombi: FC = () => {
  const { t } = useTranslation('betting');
  const { bettingSlipId } = useContext(BettingSlipContext);

  const hasCashoutAvailable = useBettingGetterSelector({
    getterSelector: hasCashoutAvailableForPlayerSelector,
    args: [],
  });

  const isCashable = useBettingGetterSelector({
    getterSelector: bettingSlipIsCashableGetterSelector,
    args: [bettingSlipId],
  });

  const showOptiOddsIcons = useIsOptimalBetIsAplyOnBettingSlip(bettingSlipId);

  return (
    <>
      <BettingSlipCombiStake />
      <BettingSlipFooterLineContainer data-testid="bettingslip-combi-odd-container">
        <Box flexDirection="row" alignItems="center">
          {t('bettingslip.combi.odd')}
          {/* TODO: create unit test but need to wait for API */}
          {showOptiOddsIcons && (
            <BettingIcon
              id="optiOdds"
              type="OptiOddsTag"
              ml="xxs"
              width={16}
              height={16}
            />
          )}
        </Box>
        <BettingSlipOdds />
      </BettingSlipFooterLineContainer>
      <BettingSlipFooterLineContainer data-testid="bettingslip-combi-potwinning-container">
        <Box flexDirection="row" alignItems="center">
          {t('bettingslip.combi.potWinning')}
          {/* TODO: create unit test but need to wait for API */}
          {hasCashoutAvailable && isCashable && (
            <BettingIcon
              id="cashout"
              type="CashoutTag"
              ml="xxs"
              width={16}
              height={16}
            />
          )}
        </Box>
        <BettingSlipCombiPotWinning />
      </BettingSlipFooterLineContainer>
      <Box mt="sm" width="100%">
        <BettingSlipValidation />
      </Box>
    </>
  );
};
