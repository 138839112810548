import React, { FC, useState } from 'react';

import {
  getLoglevelNameFromNumber,
  LogLevelNames,
  setLoggerLevel,
} from '@gaming1/g1-logger';
import { Heading, Select } from '@gaming1/g1-ui';

import { logger } from '../../logger';

const logLevels: LogLevelNames[] = ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'];

const isLogLevel = (level: string): level is LogLevelNames =>
  logLevels.includes(level as LogLevelNames);

/* Retrieve stored log level */
export const LogLevelSwitch: FC = () => {
  const initialLevel = getLoglevelNameFromNumber(logger.getLevel());
  const [logLevel, setLogLevel] = useState(initialLevel);
  const onSwitch = (level: string) => {
    if (isLogLevel(level)) {
      setLogLevel(level);
      setLoggerLevel({ level });
    }
  };
  return (
    <>
      <Heading level="h4">Logger</Heading>
      <Select
        onValueChange={onSwitch}
        options={logLevels.map((label) => ({
          value: label,
          label,
        }))}
        testId="devtoolmenu-logger-select"
        value={logLevel}
        aria-label="Logger level select"
      />
    </>
  );
};
