import { createContext, VFC } from 'react';

import { PrismicPromotionDetailsModalProp, PrismicSectionProps } from './types';

const EmptyComponent: VFC = () => null;

/** Context containing public CMS components
 * - PrismicSection: render a cms content from Prismic using its uid
 * - PrismicPromotionDetailsModalProp: render a modal with Prismic content inside using its uid
 */
export const CMSPublicComponentsContext = createContext<{
  PrismicSection: VFC<PrismicSectionProps>;
  PrismicPromotionDetailsModal: VFC<PrismicPromotionDetailsModalProp>;
}>({
  PrismicSection: EmptyComponent,
  PrismicPromotionDetailsModal: EmptyComponent,
});
