import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateFailureRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
  slugifyPayload,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as actions from './actions';
import { WinningsState } from './types';

export const initialState: WinningsState = {
  requests: {
    getBiggestWinnings: {},
  },
  biggestWinnings: {},
};

export const winningsReducer = (
  state: WinningsState = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchBiggestWinnings.failure): {
        const slug = slugifyPayload(action.payload.request);
        draftState.requests.getBiggestWinnings[slug] =
          generateFailureRequestState(action.payload);
        break;
      }

      case getType(actions.fetchBiggestWinnings.request): {
        const slug = slugifyPayload(action.payload);
        draftState.requests.getBiggestWinnings[slug] =
          generateLoadingRequestState();
        break;
      }

      case getType(actions.fetchBiggestWinnings.success): {
        const slug = slugifyPayload(action.payload.request);
        draftState.requests.getBiggestWinnings[slug] =
          generateSuccessRequestState();
        draftState.biggestWinnings[slug] = action.payload;
        break;
      }

      default: // Immer will automatically return the state
    }
  });
