import React, { FC } from 'react';

import { useGetBettingAssetPath } from '../../../assetsManagement/hooks';
import { ScoreBoardSportId } from '../../types';

import { SCOREBOARD_BACKGROUNDS } from './constants';
import { ScoreBoardBackgroundImage } from './styles';

type ScoreBoardBackgroundProps = {
  /** Sport id used to retrieve the background */
  sportId: number;
};

/** Displays the background for a specific sport */
export const ScoreBoardBackground: FC<ScoreBoardBackgroundProps> = ({
  children,
  sportId,
}) => {
  const getBettingAssetPath = useGetBettingAssetPath();

  const id = sportId as ScoreBoardSportId;
  const path = SCOREBOARD_BACKGROUNDS[id] || 'defaultScoreBackground';

  return (
    <ScoreBoardBackgroundImage
      data-testid={`score-board-background-${path.toLowerCase()}`}
      imagePath={getBettingAssetPath(path) || ''}
    >
      {children}
    </ScoreBoardBackgroundImage>
  );
};
