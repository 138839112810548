import React, { BaseHTMLAttributes, FC } from 'react';

import { Theme } from '@gaming1/g1-style';
import { useDebounce, usePrevious } from '@gaming1/g1-utils';

import { BoxProps } from '../Box';
import { Icon } from '../Icon';
import { StyledSystemProps } from '../types';

import { ErrorDiv, InfoDiv, InputWrapperDiv } from './styles';

type InputWrapperProps = BaseHTMLAttributes<HTMLDivElement> &
  StyledSystemProps & {
    /** Validation errors */
    errors?: string[];
    /** Whether the input is focused or not */
    focused?: boolean;
    /** If we need to have a background */
    hasBackground?: boolean;
    /** Specifies the color of the highlight, danger by default */
    highlightColor?: keyof Theme['colors'];
    /** Information visible when the input is focused */
    info?: string[];
    /** Optional test id */
    testId?: string;
  };

export const InputWrapper: FC<InputWrapperProps & BoxProps> = ({
  children,
  errors = [],
  focused,
  hasBackground = false,
  highlightColor = 'danger',
  info = [],
  testId,
  ...rest
}) => {
  const shouldShowErrors = !!errors.length;

  /*
    This trick prevent the form from "jumping" when a focused info field is
    blurred between the mousedown and mouseup event of the form submit button,
    preventing the click from registrering if the cursor has left the button
    on mouseup
  */
  const focusedDebounced = useDebounce(focused, 100);
  const wasFocused = usePrevious(focusedDebounced);
  const shownOnFocus =
    wasFocused !== focusedDebounced
      ? !wasFocused && focusedDebounced
      : focusedDebounced;

  const shouldShowInfo = !shouldShowErrors && shownOnFocus && !!info.length;

  const inputErrors = shouldShowErrors
    ? errors.map((err) => (
        <ErrorDiv
          data-testid={`${testId}-error`}
          hasBackground={hasBackground}
          highlightColor={highlightColor}
          key={err}
        >
          <Icon
            id="input-error"
            type="DiscCross"
            height="16px"
            width="16px"
            mr="xs"
          />
          {err}
        </ErrorDiv>
      ))
    : null;
  const inputInfos = shouldShowInfo
    ? info.map((inf) => (
        <InfoDiv
          hasBackground={hasBackground}
          key={inf}
          data-testid={`${testId}-info`}
        >
          <Icon id="input-info" type="DiscInfo" mr="xs" /> {inf}
        </InfoDiv>
      ))
    : null;
  return (
    <InputWrapperDiv data-testid={`${testId}-wrapper`} {...rest}>
      {children}
      {inputErrors}
      {inputInfos}
    </InputWrapperDiv>
  );
};
