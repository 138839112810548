import * as t from 'io-ts';

import { countryCodec, stateCodec } from '@gaming1/g1-core';
import {
  EActivateAccountStatus,
  EFieldRegisterPlayerProperty,
  EGetAllProvincesStatus,
  EGetMunicipalitiesStatus,
  EGetRegistrationFormInfo,
  EPlayerRegistratioStatus,
  ERegistrationInfoStatus,
} from '@gaming1/g1-requests';
import { createEnumType, partializeCodec } from '@gaming1/g1-utils';

import {
  departmentAndMunicipalities,
  municipality,
  prize,
  province,
} from '../../common/store/codecs';

export const getRegistrationFormInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetRegistrationFormInfo.Successful),
      MandatoryFieldsList: t.array(t.number),
      // MinimumAgeToRegister: t.number,
      NationalityCountryList: t.array(countryCodec),
      PrizeList: t.array(prize),
      ResidenceCountryList: t.array(countryCodec),
      isPhoneMobileOrFixeMantatory: t.boolean,
    }),
    t.partial({
      IsSecurityPinCodeEnabled: t.boolean,
      LegalMaximumDepositLimit: t.number,
      StateList: t.array(stateCodec),
      UseFullSSn: t.boolean,

      // @TODO should be reset as mandatory once LG is up to date (18/02/2021)
      MinimumAgeToRegister: t.number,
    }),
  ],
  'GetRegistrationFormInfoResponse',
);

export const getProvincesResponse = t.type(
  {
    Status: t.literal(EGetAllProvincesStatus.Successful),
    Provinces: t.array(province),
  },
  'GetAllProvincesResponse',
);

export const getMunicipalitiesResponse = t.type(
  {
    Status: t.literal(EGetMunicipalitiesStatus.Successful),
    Municipalities: t.array(municipality),
  },
  'GetMunicipalitiesResponse',
);

export const getDepartmentsAndMunicipalitiesResponse = t.type(
  {
    Status: t.literal(EGetMunicipalitiesStatus.Successful),
    DepartmentsAndMunicipalities: t.array(departmentAndMunicipalities),
  },
  'GetDepartmentsAndMunicipalitiesResponse',
);

export const playerRegistrationSuccessResponse = t.type(
  {
    Status: t.literal(EPlayerRegistratioStatus.Successful),
  },
  'PlayerRegistrationSuccessResponse',
);

export const playerRegistrationFailureResponse = partializeCodec(
  t.type(
    {
      Status: createEnumType<EPlayerRegistratioStatus>(
        EPlayerRegistratioStatus,
      ),
      FieldErrors: t.array(
        t.type({
          FieldError: createEnumType<EFieldRegisterPlayerProperty>(
            EFieldRegisterPlayerProperty,
          ),
          PropertyPropertyValue: t.number,
          Message: t.string,
        }),
      ),
    },
    'PlayerRegistrationFailureResponse',
  ),
  ['FieldErrors'],
);

export const getRegistrationInfoResponse = t.intersection(
  [
    t.type({
      Status: t.literal(ERegistrationInfoStatus.Successful),
      SponsorshipHideDescription: t.boolean,
      SponsorshipReadOnly: t.boolean,
      ForceSponsorshipCode: t.boolean,
    }),
    t.partial({
      SponsorshipDescription: t.string,
    }),
  ],
  'GetRegistrationInfoSuccessResponse',
);

export const activateAccountResponse = t.type(
  {
    Status: t.literal(EActivateAccountStatus.Successful),
  },
  'ActivateAccountResponse',
);
