import { ComponentType, FC, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheetManager } from 'styled-components';

import { appInitializedSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { WsAdapterOptions } from '@gaming1/g1-network';
import { Box, GlobalStyle } from '@gaming1/g1-ui';

import { SuspenseLoader } from '../../../layout/components/SuspenseLoader';
import { useNetworkConnectionLostToast } from '../../../network/hooks';

import {
  useAppExit,
  useConfigStateUpdate,
  useDebugMode,
  useInitApp,
  useInitialLog,
  useLanguageUpdate,
  useSplashScreen,
} from './hooks';
import { useConnectionChangeDetection } from './useConnectionChangeDetection';
import { useNavigationFromNativeApp } from './useNavigationFromNativeApp';
import { useScrollRestoration } from './useScrollRestoration';

type AppProps = {
  /** Suspense fallback element to show while the app is loading */
  fallbackContent?: ComponentType;
  /** Whether to wait for appInitialized to be true to render or not */
  noInitWait?: boolean;
  /** Wheter to render only children or the wrappers also (useful for testing) */
  renderOnlyChildren?: boolean;
  /** Optional custom options for wsAdapter init (will override config) */
  wsAdapterOptions?: Partial<WsAdapterOptions>;
};

/**
 * Component that updates the store when the locale changes (and on first app
 * initialization). It will prevent any render until the locale has been set.
 * It will also trigger an exit action on app exit.
 *
 * This component also includes the GlobalStyle and inits various thrid party
 * libs/SDKs:
 * - ioVation (user tracking)
 * - Zendesk (customer support)
 */
export const App: FC<AppProps> = ({
  children,
  fallbackContent = <SuspenseLoader />,
  noInitWait,
  renderOnlyChildren = false,
  wsAdapterOptions,
}) => {
  const { i18n } = useTranslation(undefined, { useSuspense: false });

  const appInitialized = useSelector(appInitializedSelector);

  const isDebugModeEnabled = useDebugMode();

  const initApp = useInitApp({
    wsAdapterOptions: { ...wsAdapterOptions, debugMode: isDebugModeEnabled },
  });

  // see https://docs.prerender.io/article/7-faq
  const isPrerenderBot = !!navigator.userAgent.match(/Prerender/m);

  useAppExit();
  useConfigStateUpdate();
  useInitialLog();
  useLanguageUpdate({ initCallback: initApp });
  useNavigationFromNativeApp();
  useNetworkConnectionLostToast();
  useScrollRestoration();
  useSplashScreen();
  useConnectionChangeDetection();

  /** Prevent loading the app while the locale hasn't be initiated yet */
  if ((!appInitialized || i18n.language === undefined) && !noInitWait) {
    return null;
  }

  if (renderOnlyChildren) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <>
      <GlobalStyle />
      <Suspense fallback={<Box minHeight="100vh">{fallbackContent}</Box>}>
        {/*
         * An incompatibility of the CSSOM API makes the crawler unable to display
         * styled components correctly. We need to disable CSSOM injection
         * to be able to properly render the website (when crawled by prerender.io).
         * https://styled-components.com/docs/api#stylesheetmanager
         * https://github.com/prerender/prerender/issues/574
         */}
        <StyleSheetManager disableCSSOMInjection={isPrerenderBot}>
          {children}
        </StyleSheetManager>
      </Suspense>
    </>
  );
};
