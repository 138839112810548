import React from 'react';

import { AppConfig, License, LocaleCode } from '@gaming1/g1-config';
import { LazyRoute } from '@gaming1/g1-core-web';

import { gamingRoutes } from './routes';

export const getGamingRouter = (locale: LocaleCode, config: AppConfig) => [
  !config.core.licenses && (
    <LazyRoute
      exact
      componentName="GameListPage"
      importFactory={() => import('./gameList/pages/GameListPage')}
      key="gameList"
      path={`/${locale}${gamingRoutes.gameList.path}`}
      section="gaming"
      type="app"
    />
  ),
  !config.core.licenses && (
    <LazyRoute
      exact
      componentName="GameListMyGamesPage"
      importFactory={() => import('./gameList/pages/GameListMyGamesPage')}
      key="gameListMyGames"
      path={`/${locale}${gamingRoutes.gameListMyGames.path}`}
      section="gaming"
      type="protected"
    />
  ),
  !config.core.licenses && (
    <LazyRoute
      exact
      componentName="GameListPage"
      importFactory={() => import('./gameList/pages/GameListPage')}
      key="gameListCategory"
      path={`/${locale}${gamingRoutes.gameListCategory.path}`}
      section="gaming"
      type="app"
    />
  ),
  config.core.licenses?.includes(License.A) && (
    <LazyRoute
      exact
      componentName="GameListCasinoPage"
      importFactory={() => import('./gameList/pages/GameListCasinoPage')}
      key="gameListCasino"
      path={`/${locale}${gamingRoutes.gameListCasino.path}`}
      section="gaming"
      type="app"
    />
  ),
  config.core.licenses?.includes(License.A) && (
    <LazyRoute
      exact
      componentName="GameListCasinoMyGamesPage"
      importFactory={() => import('./gameList/pages/GameListCasinoMyGamesPage')}
      key="gameListCasinoMyGames"
      path={`/${locale}${gamingRoutes.gameListCasinoMyGames.path}`}
      section="gaming"
      type="protected"
    />
  ),
  config.core.licenses?.includes(License.A) && (
    <LazyRoute
      exact
      componentName="GameListCasinoPage"
      importFactory={() => import('./gameList/pages/GameListCasinoPage')}
      key="gameListCasinoCategory"
      path={`/${locale}${gamingRoutes.gameListCasinoCategory.path}`}
      section="gaming"
      type="app"
    />
  ),
  config.core.licenses?.includes(License.B) && (
    <LazyRoute
      exact
      componentName="GameListDicePage"
      importFactory={() => import('./gameList/pages/GameListDicePage')}
      key="gameListDice"
      path={`/${locale}${gamingRoutes.gameListDice.path}`}
      section="gaming"
      type="app"
    />
  ),
  config.core.licenses?.includes(License.B) && (
    <LazyRoute
      exact
      componentName="GameListDiceMyGamesPage"
      importFactory={() => import('./gameList/pages/GameListDiceMyGamesPage')}
      key="gameListDiceMyGames"
      path={`/${locale}${gamingRoutes.gameListDiceMyGames.path}`}
      section="gaming"
      type="protected"
    />
  ),
  config.core.licenses?.includes(License.B) && (
    <LazyRoute
      exact
      componentName="GameListDicePage"
      importFactory={() => import('./gameList/pages/GameListDicePage')}
      key="gameListDiceCategory"
      path={`/${locale}${gamingRoutes.gameListDiceCategory.path}`}
      section="gaming"
      type="app"
    />
  ),
  !config.core.licenses && (
    <LazyRoute
      exact
      componentName="GamingSearchResultsPage"
      importFactory={() => import('./search/pages/GamingSearchResultsPage')}
      key="searchResults"
      path={`/${locale}${gamingRoutes.searchResults.path}`}
      section="gaming"
      type="app"
    />
  ),
  config.core.licenses?.includes(License.A) && (
    <LazyRoute
      exact
      componentName="GamingSearchCasinoResultsPage"
      importFactory={() =>
        import('./search/pages/GamingSearchCasinoResultsPage')
      }
      key="searchCasinoResults"
      path={`/${locale}${gamingRoutes.searchCasinoResults.path}`}
      section="gaming"
      type="app"
    />
  ),
  config.core.licenses?.includes(License.B) && (
    <LazyRoute
      exact
      componentName="GamingSearchDiceResultsPage"
      importFactory={() => import('./search/pages/GamingSearchDiceResultsPage')}
      key="searchDiceResults"
      path={`/${locale}${gamingRoutes.searchDiceResults.path}`}
      section="gaming"
      type="app"
    />
  ),
  <LazyRoute
    exact
    componentName="PlayGamePage"
    importFactory={() => import('./startGame/pages/PlayGamePage')}
    key="playGame"
    path={`/${locale}${gamingRoutes.playGame.path}`}
    section="gaming"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="PendingGamesPage"
    importFactory={() => import('./pendingGames/pages/PendingGamesPage')}
    key="PendingGames"
    path={`/${locale}${gamingRoutes.pendingGames.path}`}
    section="gaming"
    type="protected"
  />,
  <LazyRoute
    exact
    componentName="TournamentsPage"
    importFactory={() => import('./tournament/pages/TournamentsPage')}
    key="tournaments"
    path={`/${locale}${gamingRoutes.tournaments.path}`}
    section="gaming"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="FinishedTournamentsPage"
    importFactory={() => import('./tournament/pages/FinishedTournamentsPage')}
    key="finishedTournaments"
    path={`/${locale}${gamingRoutes.finishedTournaments.path}`}
    section="gaming"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="TournamentDetailsPage"
    importFactory={() => import('./tournament/pages/TournamentDetailsPage')}
    key="TournamentDetails"
    path={`/${locale}${gamingRoutes.tournamentDetails.path}`}
    section="gaming"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="PlayTournamentPage"
    importFactory={() => import('./tournament/pages/PlayTournamentPage')}
    key="playTournament"
    path={`/${locale}${gamingRoutes.playTournament.path}`}
    section="gaming"
    type="protected"
  />,
];
