import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getHistory } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../actions';
import { LoyaltyEpic } from '../../types';
import { scratchCardHistoryResponse } from '../codecs';
import { getScratchCardHistoryErrorMessages } from '../errorMessages';
import { formatScratchcardHistory } from '../format';

export const getScratchCardHistoryEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getScratchCardHistory.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getHistory(payload)).pipe(
        mapGuard(scratchCardHistoryResponse),
        map((response) => formatScratchcardHistory(response)),
        map(actions.getScratchCardHistory.success),
        catchError((err) =>
          of(
            actions.getScratchCardHistory.failure(
              createFailurePayload(err, getScratchCardHistoryErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
