import { Middleware } from 'redux';

import { getWrapperEnv } from '@gaming1/g1-logger';

import { twinningMiddleware } from '../twinningMiddleware';

/**
 * function that compute all necessary redux middlewares to start core-web correclty
 * @param whitelist array of string to configure twinning middleware
 * If null, there is not filter. Middleware emit every actions to subscribers
 * If empty, not actions are emitted to subscribers
 * Every action.type starting with whitelist will be emitted to subscribers.
 * @returns an array of middleware to apply to redux store
 */
export const getReduxMiddlewares = (
  whitelist: string[] | null = ['core/'],
): Middleware<Record<string, never>, unknown>[] =>
  getWrapperEnv() === 'rn' ? [twinningMiddleware(whitelist)] : [];
