import { createContext, VFC } from 'react';

const EmptyComponent: VFC = () => null;

/** Context containing public inbox components
 * - InboxNotification: Display an icon when there are unread message from the
 *   inbox
 * - InboxRouter: Router for the loyalty inbox
 */
export const InboxPublicComponentsContext = createContext<{
  InboxNotification: VFC;
  InboxRouter: VFC;
}>({
  InboxNotification: EmptyComponent,
  InboxRouter: EmptyComponent,
});
