import React, { FC, memo, useEffect, useState } from 'react';
import { SpaceProps } from 'styled-system';

import { Theme } from '@gaming1/g1-style';

import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';

import {
  CollapsibleContainer,
  CollapsibleContent,
  CollapsibleMainContent,
} from './styles';

export type CollapsibleStyle = { textColor?: keyof Theme['colors'] } & BoxProps;

export type CollapsibleProps = SpaceProps & {
  /** Title displayed on 'mainContent' hover */
  hoverTitle: string;
  /** Sets the default value of the 'isVisible' internal state */
  visibleByDefault?: boolean;
  /** Visible content when panel is open */
  expandedContent: React.ReactNode;
  /** Visible content when panel is collapsed */
  mainContent: React.ReactNode;
  /** Style of the main content */
  mainStyle?: CollapsibleStyle;
  /** Sets the default value of the 'isVisible' internal state */
  isLocked?: boolean;
  /** Call this handler on every toggle with the current collapsibility state */
  onToggle?: (isCollapsed: boolean) => void;
};

/**
 * This component renders a block filled with text that could be collapsed
 */
export const Collapsible: FC<CollapsibleProps> = ({
  children,
  hoverTitle,
  visibleByDefault,
  mainContent,
  expandedContent,
  mainStyle,
  isLocked = false,
  onToggle = () => null,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(visibleByDefault || false);
  const [shouldBeRendered, setShouldBeRendered] = useState(
    visibleByDefault || false,
  );
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    onToggle(isVisible);
  };

  // On mount, do not render expandedContent (but keep rendering it if visibility is set to false)
  useEffect(() => {
    if (!shouldBeRendered && isVisible) {
      setShouldBeRendered(true);
    }
  }, [isVisible, shouldBeRendered]);

  return (
    <CollapsibleContainer {...rest}>
      <CollapsibleMainContent
        onClick={isLocked ? () => {} : () => toggleVisibility()}
        isVisible={!isLocked && isVisible}
        title={hoverTitle}
        textColor={mainStyle?.textColor}
        {...mainStyle}
      >
        {mainContent}
        <Box>
          <Icon id="collapsible" type="AngleDown" />
        </Box>
      </CollapsibleMainContent>
      <CollapsibleContent isVisible={!isLocked && isVisible}>
        {shouldBeRendered ? expandedContent : null}
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

export const MemoizedCollapsible = memo(Collapsible);
