import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { LoyaltyActions } from '../../types';
import * as actions from '../actions';

import { initialState } from './initialState';

export const sponsorshipHistoryReducer = (
  state = initialState,
  action: LoyaltyActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getSponsorshipHistory.request):
        produceLoadingState(draftState.requests.getSponsorshipHistory);
        break;

      case getType(actions.getSponsorshipHistory.failure):
        produceFailureState(
          draftState.requests.getSponsorshipHistory,
          action.payload,
        );
        break;

      case getType(actions.getSponsorshipHistory.success):
        produceSuccessState(draftState.requests.getSponsorshipHistory);

        draftState.sponsorships =
          action.payload.pagination.PageNumber > 0 && state.sponsorships
            ? [...state.sponsorships, ...action.payload.list]
            : action.payload.list;

        draftState.requests.getSponsorshipHistory.pagination =
          action.payload.pagination;
        break;

      default: // Immer will automatically return the state
    }
  });
