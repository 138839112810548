import * as t from 'io-ts';

import { notificationCodec } from '../../../notification/codecs';

export const getPromoForBettingSlipResponseCodec = t.intersection(
  [
    t.type({
      Notification: notificationCodec,
    }),
    t.partial({
      PromoIds: t.array(t.string),
    }),
  ],
  'GetPromoForBettingSlipResponseCodec',
);
