import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { currentSectionSelector, userLoggedInSelector } from '@gaming1/g1-core';
import {
  coreRoutes,
  useIsFullscreenLayout,
  useRoutePath,
} from '@gaming1/g1-core-web';
import { actions, pendingGamesSelector } from '@gaming1/g1-gaming';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { Banner } from '@gaming1/g1-ui';

import { gamingRoutes } from '../../../routes';

import { MessageValue } from './styles';

export const PendingGamesBanner: FC = () => {
  const isFullscreenLayout = useIsFullscreenLayout();
  const currentSection = useSelector(currentSectionSelector);
  const { t } = useTranslation(['gaming']);

  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const getGamingPath = useRoutePath(gamingRoutes);
  const getCorePath = useRoutePath(coreRoutes);
  const pendingGames = useSelector(pendingGamesSelector);

  const dispatch = useDispatch();
  const location = useLocation();

  const isPlayingGame = location.pathname.includes('/play/');
  const isInTournaments = location.pathname === getGamingPath('tournaments');
  const isOnHomePage = location.pathname === getCorePath('home');
  const shouldDisplayBanner =
    (currentSection === 'gaming' || isOnHomePage) &&
    !isPlayingGame &&
    !isInTournaments &&
    !isFullscreenLayout;
  const shouldFetchPendingGames = !!isUserLoggedIn && shouldDisplayBanner;

  useEffect(() => {
    if (shouldFetchPendingGames) {
      dispatch(actions.getPendingGames.request());
    }
  }, [dispatch, shouldFetchPendingGames]);

  if (
    !pendingGames?.PendingGames ||
    pendingGames.PendingGames.length === 0 ||
    !shouldDisplayBanner
  ) {
    return null;
  }

  const transProps: { i18nKey: string; values: Record<string, string> } =
    pendingGames.PendingGames.length === 1
      ? {
          i18nKey: 'pendingGames.singleGame',
          values: {
            game:
              pendingGames.Game?.Name || pendingGames.PendingGames[0].Game.Name,
          },
        }
      : {
          i18nKey: 'pendingGames.multipleGames',
          values: { hit: pendingGames.PendingGames.length.toString() },
        };

  const link =
    pendingGames.PendingGames.length === 1
      ? {
          label: t('pendingGames.open'),
          route: `${getGamingPath('playGame', {
            gameId:
              pendingGames.PendingGameId ||
              pendingGames.PendingGames[0].Game.Id,
          })}`,
        }
      : {
          label: t('pendingGames.see'),
          route: getGamingPath('pendingGames'),
        };

  return (
    <Banner
      message={
        <Trans
          ns="gaming"
          components={{
            MessageValue: <MessageValue />,
            Span: <span />,
          }}
          {...transProps}
        />
      }
      link={link}
      testId="resume-game-banner-container"
    />
  );
};
