import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  AcceptPrivacyConditionsResponse,
  GetPrivacyConditionsResponse,
  ShowPrivacyConditionsResponse,
} from './types';

/** Accept Privacy Conditions */
export const acceptPrivacyConditions = createAsyncAction(
  'user/accept_privacy_conditions_request',
  'user/accept_privacy_conditions_success',
  'user/accept_privacy_conditions_failure',
)<void, AcceptPrivacyConditionsResponse, FailurePayload>();

/** Get Privacy Conditions */
export const getPrivacyConditions = createAsyncAction(
  'user/get_privacy_conditions_request',
  'user/get_privacy_conditions_success',
  'user/get_privacy_conditions_failure',
)<{ Key: string }, GetPrivacyConditionsResponse, FailurePayload>();

/** Show Privacy Conditions */
export const showPrivacyConditions = createAsyncAction(
  'user/show_privacy_conditions_request',
  'user/show_privacy_conditions_success',
  'user/show_privacy_conditions_failure',
)<void, ShowPrivacyConditionsResponse, FailurePayload>();
