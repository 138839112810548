import { useDispatch } from 'react-redux';

import { coreActions, useIsDebugModeEnabled } from '@gaming1/g1-core';
import { CheckboxInput } from '@gaming1/g1-ui';

export const DebugModeToggle = () => {
  const isDebugModeEnabled = useIsDebugModeEnabled();
  const dispatch = useDispatch();

  return (
    <CheckboxInput
      checked={isDebugModeEnabled}
      onChange={() => dispatch(coreActions.setDebugMode(!isDebugModeEnabled))}
      label="Debug mode"
    />
  );
};
