import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, useMediaBreakPoint } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';
import { useExitFullscreenLocation } from '../../../routing/hooks';

import {
  BackButton,
  CloseButton,
  CloseLink,
  CloseText,
  DesktopBackButtonWrapper,
  DesktopCloseButtonWrapper,
  DesktopHeaderWrapper,
  HeaderLogoImg,
  HeaderPlaceholder,
  HeaderTitle,
  MobileBackButtonWrapper,
  MobileCloseButtonWrapper,
  MobileHeaderWrapper,
} from './styles';

type HeaderProps = {
  className?: string;
  /**
   * Function called when the back button is pressed. If not provided, the
   * button will not be displayed
   */
  onBackButtonClick?: () => void;
  /**
   * Optional function called when the close button is pressed. If not
   * provided, it will revert to the default behaviour
   */
  onCloseButtonClick?: () => void;
  /**
   * If true, the header will always look as if it was displayed on a mobile
   */
  shouldDisplayAsMobile?: boolean;
  /** Optional testId (default: 'header') */
  testId?: string;
  /** Title displayed on the middle of the header */
  title?: string;
};

/**
 * Header showing a title, a close button and optionally a back button (on
 * mobile only)
 */
export const Header: FC<HeaderProps> = ({
  children,
  className,
  onBackButtonClick,
  onCloseButtonClick,
  shouldDisplayAsMobile = false,
  testId = 'header',
  title,
}) => {
  const { t } = useTranslation(['user', 'core']);
  const exitFullscreenLocation = useExitFullscreenLocation();

  const { dark } = useContext(ThemeContext);
  const getAssetPath = useGetCoreAssetPath();
  const logoUrl = dark ? getAssetPath('logoDark') : getAssetPath('logoLight');

  const isMediaLargeOrAbove = useMediaBreakPoint({ min: 'lg' });
  const shouldUseMobileLayout = !isMediaLargeOrAbove || shouldDisplayAsMobile;

  return (
    <HeaderPlaceholder
      className={className}
      data-testid={testId}
      shouldBeSticky={shouldUseMobileLayout}
    >
      {shouldUseMobileLayout ? (
        <MobileHeaderWrapper>
          <MobileBackButtonWrapper>
            {onBackButtonClick && (
              <BackButton
                onClick={onBackButtonClick}
                aria-label="Back"
                data-testid={`${testId}-backbutton`}
              >
                <Icon id="header-back" type="ArrowLeft" />
              </BackButton>
            )}
          </MobileBackButtonWrapper>
          {children || <HeaderTitle>{title}</HeaderTitle>}
          <MobileCloseButtonWrapper>
            {onCloseButtonClick ? (
              <CloseButton
                onClick={onCloseButtonClick}
                aria-label="Close"
                data-testid={`${testId}-closebutton`}
              >
                <Icon id="header-close" type="Cross" />
              </CloseButton>
            ) : (
              <CloseLink
                to={exitFullscreenLocation}
                aria-label="Close"
                data-testid={`${testId}-closebutton`}
              >
                <Icon id="header-close" type="Cross" />
              </CloseLink>
            )}
          </MobileCloseButtonWrapper>
        </MobileHeaderWrapper>
      ) : (
        <DesktopHeaderWrapper>
          <DesktopBackButtonWrapper>
            {onBackButtonClick && (
              <BackButton
                onClick={onBackButtonClick}
                aria-label="Back"
                data-testid={`${testId}-backbutton`}
              >
                <Icon id="header-back" type="ArrowLeft" />
              </BackButton>
            )}
          </DesktopBackButtonWrapper>

          <HeaderLogoImg
            url={logoUrl}
            alt={t('core:alt.logo')}
            id="header-logo"
          />

          <DesktopCloseButtonWrapper>
            {onCloseButtonClick ? (
              <CloseButton
                onClick={onCloseButtonClick}
                aria-label="Close"
                data-testid={`${testId}-closebutton`}
              >
                <CloseText>{t('header.back')}</CloseText>

                <Icon id="header-close" type="Cross" />
              </CloseButton>
            ) : (
              <CloseLink
                to={exitFullscreenLocation}
                aria-label="Close"
                data-testid={`${testId}-closebutton`}
              >
                <CloseText>{t('header.back')}</CloseText>

                <Icon id="header-close" type="Cross" />
              </CloseLink>
            )}
          </DesktopCloseButtonWrapper>
        </DesktopHeaderWrapper>
      )}
    </HeaderPlaceholder>
  );
};
