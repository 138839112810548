import format from 'date-fns/format';
import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getCashoutDataGetterSelector,
  hasCashoutEntityGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../../common/components/BettingIcon';
import { OpacityContainer } from '../../styles';

import { HistoryExpandedGiftContainer } from './styles';

type ExpandedExtraInfoCashoutProps = {
  cashoutId: string;
};
export const ExpandedExtraInfoCashout: VFC<ExpandedExtraInfoCashoutProps> = ({
  cashoutId,
}) => {
  const { i18n } = useConfig();
  const { t } = useTranslation('betting');
  const formatMoney = useFormatMoney();

  const isCashable = useBettingGetterSelector({
    getterSelector: hasCashoutEntityGetterSelector,
    args: [cashoutId],
  });

  const cashoutData = useBettingGetterSelector({
    getterSelector: getCashoutDataGetterSelector,
    args: [cashoutId],
    equalityFn: shallowEqual,
  });

  return isCashable && cashoutData ? (
    <HistoryExpandedGiftContainer data-testid="expanded-card-cashout">
      <BettingIcon id="Cashout" type="CashoutTag" width={16} height={16} />
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text ml="xxs" mt={0} fontSize="md" mr="md">
          {t('history.expanded.cashout.label', {
            cashoutValue: formatMoney(cashoutData.Amount),
          })}
        </Text>
        <OpacityContainer>
          <Text mt={0} fontSize="md">
            {format(new Date(cashoutData.Date), i18n.dateFormat)} -{' '}
            {format(new Date(cashoutData.Date), i18n.timePartialFormat)}
          </Text>
        </OpacityContainer>
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
