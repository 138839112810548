import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as actions from './actions';
import { JackpotState } from './types';

export const initialState: JackpotState = {
  jackpotDetails: null,
  jackpotGames: null,
  jackpotTotals: null,
  requests: {
    getJackpotDetails: generateInitialRequestState(null),
    getJackpotGames: generateInitialRequestState(null),
    getJackpotTotals: generateInitialRequestState(null),
  },
};

export const jackpotReducer = (
  state: JackpotState = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.getJackpotDetails.request):
        produceLoadingState(draftState.requests.getJackpotDetails);
        break;
      case getType(actions.getJackpotGames.request):
        produceLoadingState(draftState.requests.getJackpotGames);
        break;
      case getType(actions.getJackpotTotals.request):
        produceLoadingState(draftState.requests.getJackpotTotals);
        break;

      /** Failures */
      case getType(actions.getJackpotDetails.failure):
        produceFailureState(
          draftState.requests.getJackpotDetails,
          action.payload,
        );
        break;
      case getType(actions.getJackpotGames.failure):
        produceFailureState(
          draftState.requests.getJackpotGames,
          action.payload,
        );
        break;
      case getType(actions.getJackpotTotals.failure):
        produceFailureState(
          draftState.requests.getJackpotTotals,
          action.payload,
        );
        break;

      /** Successes */
      case getType(actions.getJackpotDetails.success):
        produceSuccessState(draftState.requests.getJackpotDetails);
        draftState.jackpotDetails = action.payload;
        break;
      case getType(actions.getJackpotGames.success):
        produceSuccessState(draftState.requests.getJackpotGames);
        draftState.jackpotGames = action.payload;
        break;
      case getType(actions.getJackpotTotals.success):
        produceSuccessState(draftState.requests.getJackpotTotals);
        draftState.jackpotTotals = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
