import React, { VFC } from 'react';
import { Link } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-core';
import { useMedia } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';
import { coreRoutes } from '../../../routes';
import { useRoutePath } from '../../../routing/hooks';

import { TopNavImage, TopNavImageWrapper } from './styles';

/**
 * The TopNav app logo (that link to the homepage)
 */
export const AppLogo: VFC = () => {
  const { mediaBP } = useMedia();
  const { values } = useI18n();
  const getCoreRoutePath = useRoutePath(coreRoutes);
  const getAssetPath = useGetCoreAssetPath();

  /** The partner logo URI */
  const partnerLogoURI = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(mediaBP)
    ? getAssetPath('logo')
    : getAssetPath('logoSmall');

  return (
    <TopNavImageWrapper>
      <Link to={getCoreRoutePath('home')} data-testid="topnav-home">
        <TopNavImage
          url={partnerLogoURI}
          alt={values.siteName}
          id="top-nav-logo"
        />
      </Link>
    </TopNavImageWrapper>
  );
};
