import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { BettingActions } from '../../store/types';
import { extractBetPipeIdFromAction } from '../helpers';
import { BetPipeStateSlice } from '../types';

const initialState: BetPipeStateSlice = {};

/** Manage the state of all the bet pipe requests states */
export const betPipeRequestsReducer = (
  state = initialState,
  action: BettingActions,
): BetPipeStateSlice =>
  produce(state, (draftState) => {
    /** Only deals with actions linked to a bet pipe */
    const betPipeId = extractBetPipeIdFromAction(action);
    if (!betPipeId) {
      return state;
    }
    /** The part of the state we should work with */
    const pipeState = draftState[betPipeId];

    switch (action.type) {
      /* getBettingSlipInitializationData */
      case getType(actions.getBettingSlipInitializationData.request):
        produceLoadingState(
          pipeState.requests.getBettingSlipInitializationData,
        );
        break;
      case getType(actions.getBettingSlipInitializationData.failure):
        produceFailureState(
          pipeState.requests.getBettingSlipInitializationData,
          action.payload,
        );
        break;
      case getType(actions.getBettingSlipInitializationData.success):
        produceSuccessState(
          pipeState.requests.getBettingSlipInitializationData,
        );
        break;

      /* getOutcomeInfos */
      case getType(actions.getInfoFromOutcomes.request):
        produceLoadingState(pipeState.requests.getOutcomeInfos);
        break;
      case getType(actions.getInfoFromOutcomes.failure):
        produceFailureState(pipeState.requests.getOutcomeInfos, action.payload);
        break;
      case getType(actions.getInfoFromOutcomes.success):
        produceSuccessState(pipeState.requests.getOutcomeInfos);
        break;

      /* reset status */
      case getType(actions.resetBettingSlip.request):
        produceLoadingState(pipeState.requests.resetBettingSlip);
        break;
      case getType(actions.resetBettingSlip.success):
        produceSuccessState(pipeState.requests.resetBettingSlip);
        break;

      /* getRiskManagementReport */
      case getType(actions.launchRiskManagement.request):
        produceLoadingState(pipeState.requests.getRiskManagement);
        break;
      case getType(actions.launchRiskManagement.failure):
        produceFailureState(
          pipeState.requests.getRiskManagement,
          action.payload,
        );
        break;
      case getType(actions.launchRiskManagement.success):
        produceSuccessState(pipeState.requests.getRiskManagement);
        break;

      /* placeBet */
      case getType(actions.launchPlaceBet.request):
        produceLoadingState(pipeState.requests.placeBettingSlip);
        break;
      case getType(actions.launchPlaceBet.failure):
        produceFailureState(
          pipeState.requests.placeBettingSlip,
          action.payload,
        );
        break;
      case getType(actions.launchPlaceBet.success):
        produceSuccessState(pipeState.requests.placeBettingSlip);
        break;

      /** freebets conditions */
      case getType(actions.freebetConditionsForBettingSlip.request):
        produceLoadingState(
          pipeState.requests.getFreebetConditionsForBettingSlip,
        );
        break;
      case getType(actions.freebetConditionsForBettingSlip.failure):
        produceFailureState(
          pipeState.requests.getFreebetConditionsForBettingSlip,
          action.payload,
        );
        break;
      case getType(actions.freebetConditionsForBettingSlip.success):
        produceSuccessState(
          pipeState.requests.getFreebetConditionsForBettingSlip,
        );
        break;

      /** boostus conditions */
      case getType(actions.boostusConditionsForBettingSlip.request):
        produceLoadingState(
          pipeState.requests.getBoostusConditionsForBettingSlip,
        );
        break;
      case getType(actions.boostusConditionsForBettingSlip.failure):
        produceFailureState(
          pipeState.requests.getBoostusConditionsForBettingSlip,
          action.payload,
        );
        break;
      case getType(actions.boostusConditionsForBettingSlip.success):
        produceSuccessState(
          pipeState.requests.getBoostusConditionsForBettingSlip,
        );
        break;

      /** promotions conditions */
      case getType(actions.promotionsForBettingSlip.request):
        produceLoadingState(pipeState.requests.getPromotionsIdsForBettingSlip);
        break;
      case getType(actions.promotionsForBettingSlip.failure):
        produceFailureState(
          pipeState.requests.getPromotionsIdsForBettingSlip,
          action.payload,
        );
        break;
      case getType(actions.promotionsForBettingSlip.success):
        produceSuccessState(pipeState.requests.getPromotionsIdsForBettingSlip);
        break;

      /** optimal bet */
      case getType(actions.getOptimalBet.request):
        produceLoadingState(pipeState.requests.getOptimalBet);
        break;
      case getType(actions.getOptimalBet.failure):
        produceFailureState(pipeState.requests.getOptimalBet, action.payload);
        break;
      case getType(actions.getOptimalBet.success):
        produceSuccessState(pipeState.requests.getOptimalBet);
        break;

      default: // Immer will automatically return the state
    }
  });
