import { EBettingSlipIdentifier } from '@gaming1/g1-betting';
import { OddsUnitsType } from '@gaming1/g1-config';

export const formatOutcomeName = (
  name?: string | number | null,
  base?: string | number | null,
) => {
  let oddName = name ? String(name) : '';
  if (base) {
    oddName += ` (${base})`;
  }
  return oddName;
};

export const formatOdd = (odd: number, unit: OddsUnitsType) => {
  if (unit === 'decimal') {
    // always keep at least 2 fraction digits and the dot separator representation
    // note: This helper doesn't uses the `formatNumber` helper as odds formating
    // is very specific and doesn't complies with regular number formating specs.
    const FRACTION_DIGITS = 2;
    const [integers, fractionals] = odd.toString().split('.');

    let updatedFractionals = fractionals || '';
    for (
      let i = updatedFractionals.length, l = FRACTION_DIGITS - 1;
      i <= l;
      i += 1
    ) {
      updatedFractionals += '0';
    }
    return [integers, updatedFractionals].join('.');
  }

  const computeGCD = (numerator: number, denominator: number) => {
    /* Compute greatest common denominator */
    let x = Math.abs(numerator);
    let y = denominator;
    while (y) {
      const t = y;
      y = x % y;
      x = t;
    }

    return x;
  };

  const computeNumerator = (decimalOdd: number, denominator: number) =>
    Math.round(((decimalOdd - 1) / 1) * denominator);

  const formatToFraction = () => {
    let denominator = 100;
    let numerator = computeNumerator(odd, denominator);
    const x = computeGCD(numerator, denominator);

    // Should never happen
    if (!Number.isInteger(x)) {
      denominator = 1000;
      numerator = computeNumerator(odd, denominator);
      return `${numerator}/${denominator}`;
    }

    // Classical render
    const fixedNominator = Math.round(numerator / x);
    const fixedDenominator = Math.round(denominator / x);

    return `${fixedNominator}/${fixedDenominator}`;
  };

  const formatToAmerican = () => {
    const fixedOdd = Number.parseFloat((odd - 1).toPrecision(3));

    if (odd <= 1) {
      return '';
    }

    if (odd < 2) {
      return Math.round(-100 / fixedOdd);
    }

    return `+${Math.round(fixedOdd * 100)}`;
  };

  if (unit === 'fraction') {
    return formatToFraction();
  }

  return formatToAmerican().toString();
};

export const truncateString = (str: string, maxChar: number) =>
  `${str.slice(0, 15)}${str.length > maxChar ? '...' : ''}`;

export const extractBettingSlipTypeFromId = (
  bettingSlipId: string,
): EBettingSlipIdentifier => {
  const extractedType =
    bettingSlipId.indexOf('-') > -1
      ? bettingSlipId.substring(0, bettingSlipId.indexOf('-'))
      : bettingSlipId;

  switch (extractedType.toLocaleLowerCase()) {
    case EBettingSlipIdentifier.Main.toLocaleLowerCase():
      return EBettingSlipIdentifier.Main;
    case EBettingSlipIdentifier.Fast.toLocaleLowerCase():
      return EBettingSlipIdentifier.Fast;
    default:
      return EBettingSlipIdentifier.Missing;
  }
};

export const cleanParam = (value?: string): string | null =>
  value ? value.substring(value.lastIndexOf('-') + 1) : null;
