import styled from 'styled-components';

import { AppAnchor, AppAnchorProps, SimpleButton } from '@gaming1/g1-ui';

export type PrismicLinksProps = {
  /** Width of the link (in percent) */
  width?: string | null;
};

export const PrismicInternalLink = styled(SimpleButton)<PrismicLinksProps>`
  width: ${({ width }) => width || 'auto'};
`;

export const PrismicExternalLink = styled(AppAnchor)<
  PrismicLinksProps & AppAnchorProps
>`
  width: ${({ width }) => width || 'auto'};
`;
