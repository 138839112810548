import styled from 'styled-components';

import { fontWeight } from '@gaming1/g1-style';

export const PrivacyModalContent = styled.div`
  ul {
    em {
      font-style: normal;
      ${fontWeight('bold')};
    }
  }
`;
