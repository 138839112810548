import styled from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const BettingSlipEmptyStateContainer = styled(Box)`
  height: 100%;
  width: 100%;
  margin-bottom: ${spaces('xs')};
  justify-content: space-between;
`;
