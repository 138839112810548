import React, { FC, ReactNode } from 'react';

import {
  RibbonContainer,
  RibbonIcon,
  RibbonText,
  RibbonWrapper,
} from './styles';
import { RibbonPlacement } from './types';

type RibbonProps = {
  color: string;
  hasGutter?: boolean;
  icon?: ReactNode;
  placement?: RibbonPlacement;
  text: string;
  top?: number;
};

/**
 * Render a ribbon that will use a color for the ribbon's background. It'll display the icon and the text inside of the
 * ribbon. It can be placed inside, outside or exactly at the furthest left of an element.
 * If children are sent, it will be displayed at the right of the ribbbon.
 */
export const Ribbon: FC<RibbonProps> = ({
  color,
  hasGutter = false,
  icon,
  placement = 'outside',
  text,
  top = 0,
  children,
}) => (
  <RibbonWrapper
    data-testid="ribbon-wrapper"
    placement={placement}
    top={top}
    hasGutter={hasGutter}
  >
    <RibbonContainer data-testid="ribbon-container" color={color}>
      <RibbonIcon data-testid="ribbon-icon">{icon}</RibbonIcon>
      <RibbonText mt={0} data-testid="ribbon-text">
        {text}
      </RibbonText>
    </RibbonContainer>
    {children}
  </RibbonWrapper>
);
