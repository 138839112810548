import React, { FC, useEffect, useState } from 'react';

import {
  getPromotionsCorrespondingToIdsGettorSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Modal, useMediaBreakPoint } from '@gaming1/g1-ui';

import { PromotionDetailsContent } from '../PromotionDetailsContent';

type PromotionDetailsProps = {
  promotionsIds: string[];
  onClose: () => void;
};

export const PromotionDetails: FC<PromotionDetailsProps> = ({
  promotionsIds,
  onClose,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesCount, setSlidesCount] = useState(0);

  const isMobileAndTabletMedia = useMediaBreakPoint({ max: 'sm' });

  const promotions = useBettingGetterSelector({
    getterSelector: getPromotionsCorrespondingToIdsGettorSelector,
    args: [promotionsIds],
  });

  useEffect(() => {
    setCurrentSlide(0);
  }, [promotionsIds]);

  useEffect(() => {
    setSlidesCount(promotions.length || 0);
  }, [promotions]);

  const handleIndexClick = (index: number) => {
    setCurrentSlide(index);
  };

  const handlePreviousClick = () => {
    const previousIndex = (currentSlide - 1 + slidesCount) % slidesCount;
    setCurrentSlide(previousIndex);
  };

  const handleNextClick = () => {
    const nextIndex = (currentSlide + 1 + slidesCount) % slidesCount;
    setCurrentSlide(nextIndex);
  };

  return (
    <Modal
      modalId="details-promotion"
      visible
      onClose={onClose}
      noPadding
      width={isMobileAndTabletMedia ? '90%' : '796px'}
      maxWidth="90%"
    >
      {promotions.length > 0 && (
        <PromotionDetailsContent
          key={promotions[currentSlide].Id}
          promotion={promotions[currentSlide]}
          currentSlide={currentSlide}
          slidesCount={slidesCount}
          goToPrev={handlePreviousClick}
          goToNext={handleNextClick}
          goToIndex={handleIndexClick}
        />
      )}
    </Modal>
  );
};
