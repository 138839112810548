import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getJackpotDetails } from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import { getJackpotDetailsResponse } from './codecs';
import { getJackpotDetailsErrorMessages } from './errorMessages';
import {
  formatJackpotDetails,
  formatJackpotGames,
  formatJackpotTotals,
} from './format';

export const getJackpotDetailsEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getJackpotDetails.request)),
    withLatestFrom(config$),
    switchMap(([action, config]) =>
      wsAdapter
        .request(
          getJackpotDetails({
            RoomDomainName: config.room.roomName,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(getJackpotDetailsResponse),
          map(formatJackpotDetails),
          map(actions.getJackpotDetails.success),
          catchError((err) =>
            of(
              actions.getJackpotDetails.failure(
                createFailurePayload(err, getJackpotDetailsErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

export const getJackpotGamesEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getJackpotGames.request)),
    withLatestFrom(config$),
    switchMap(([action, config]) =>
      wsAdapter
        .request(
          getJackpotDetails({
            RoomDomainName: config.room.roomName,
            GetLinkedGames: true,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(getJackpotDetailsResponse),
          map(formatJackpotGames),
          map(actions.getJackpotGames.success),
          catchError((err) =>
            of(
              actions.getJackpotGames.failure(
                createFailurePayload(err, getJackpotDetailsErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

export const getJackpotTotalsEpic: GamingEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getJackpotTotals.request)),
    withLatestFrom(config$),
    switchMap(([, config]) =>
      wsAdapter
        .request(
          getJackpotDetails({
            GetLinkedGames: false,
            RoomDomainName: config.room.roomName,
            JackpotNames: [''],
          }),
        )
        .pipe(
          mapGuard(getJackpotDetailsResponse),
          map(formatJackpotTotals),
          map(actions.getJackpotTotals.success),
          catchError((err) =>
            of(
              actions.getJackpotTotals.failure(
                createFailurePayload(err, getJackpotDetailsErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
