import { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  riskManagementLimitsGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

export const BettingSlipRMDebugging: VFC<{
  bsId: string;
}> = ({ bsId }) => {
  const limits = useBettingGetterSelector({
    getterSelector: riskManagementLimitsGetterSelector,
    // eslint-disable-next-line react/destructuring-assignment
    args: [bsId],
    equalityFn: shallowEqual,
  });

  return <pre>{JSON.stringify(limits, null, 2)}</pre>;
};
