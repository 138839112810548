import { NotificationCode } from '@gaming1/g1-requests-betting';

import { EFrontErrorType } from './types';

/*
 * Map the error message for a notification
 */
/** Map for front error (by status) */
export const BettingSlipNotificationCodeMessages: Record<number, string> = {
  [NotificationCode.RiskManagement_Success]: '', // no message for this case, because no display request
  [NotificationCode.RiskManagement_InvalidBettingSlipType]:
    'betting:error.bettingSlip.rm-InvalidBettingSlipType',
  [NotificationCode.RiskManagement_InternalError]:
    'betting:error.bettingSlip.rm-InternalError',
  [NotificationCode.RiskManagement_UnspecifiedError]:
    'betting:error.bettingSlip.rm-UnspecifiedError',
  [NotificationCode.RiskManagement_TooManyElements]:
    'betting:error.bettingSlip.rm-TooManyElements',
  [NotificationCode.RiskManagement_TooFewElements]:
    'betting:error.bettingSlip.rm-TooFewElements',
  [NotificationCode.RiskManagement_InvalidOutcomeCombination]:
    'betting:error.bettingSlip.rm-InvalidOutcomeCombination',
  [NotificationCode.RiskManagement_LiveSystemNotAllowed]:
    'betting:error.bettingSlip.rm-LiveSystemNotAllowed',
  [NotificationCode.RiskManagement_StakeTooHigh]:
    'betting:error.bettingSlip.rm-StakeTooHigh',
  [NotificationCode.RiskManagement_StakeTooLow]:
    'betting:error.bettingSlip.rm-StakeTooLow',
  [NotificationCode.RiskManagement_TimingFreeze]:
    'betting:error.bettingSlip.rm-TimingFreeze',

  [NotificationCode.PlaceBet_Success]: 'betting:error.bettingSlip.pb-Success',
  [NotificationCode.PlaceBet_InternalError]:
    'betting:error.bettingSlip.pb-InternalError',
  [NotificationCode.PlaceBet_UnspecifiedError]:
    'betting:error.bettingSlip.pb-UnspecifiedError',
  [NotificationCode.PlaceBet_InvalidBoostus]:
    'betting:error.bettingSlip.pb-InvalidBoostus',
  [NotificationCode.PlaceBet_InvalidSafeData]:
    'betting:error.bettingSlip.pb-InvalidSafeData',
  [NotificationCode.PlaceBet_FreebetNotFound]:
    'betting:error.bettingSlip.pb-FreebetNotFound',
  [NotificationCode.PlaceBet_NoFreebetGrantedBeforeMinElementStartDate]:
    'betting:error.bettingSlip.pb-NoFreebetGrantedBeforeMinElementStartDate',
  [NotificationCode.PlaceBet_InsufficientAmount]:
    'betting:error.bettingSlip.pb-InsufficientAmount',
  [NotificationCode.PlaceBet_InsufficientRealAmount]:
    'betting:error.bettingSlip.pb-InsufficientRealAmount',
  [NotificationCode.PlaceBet_AmountAboveLimit]:
    'betting:error.bettingSlip.pb-AmountAboveLimit',
  [NotificationCode.PlaceBet_WageLimitExceeded]:
    'betting:error.bettingSlip.pb-WageLimitExceeded',
  [NotificationCode.PlaceBet_LossLimitExceeded]:
    'betting:error.bettingSlip.pb-LossLimitExceeded',
  [NotificationCode.PlaceBet_RunningGame]:
    'betting:error.bettingSlip.pb-RunningGame',
  [NotificationCode.PlaceBet_PlayerCheckFailed]:
    'betting:error.bettingSlip.pb-PlayerCheckFailed',
  [NotificationCode.PlaceBet_CreditTransferError]:
    'betting:error.bettingSlip.pb-CreditTransferError',
  [NotificationCode.PlaceBet_InvalidBettingSlipType]:
    'betting:error.bettingSlip.pb-InvalidBettingSlipType',
  [NotificationCode.PlaceBet_InvalidPlayer]:
    'betting:error.bettingSlip.pb-InvalidPlayer',
  [NotificationCode.PlaceBet_InvalidElementsCount]:
    'betting:error.bettingSlip.pb-InvalidElementsCount',
  [NotificationCode.PlaceBet_ProviderError]:
    'betting:error.bettingSlip.pb-ProviderError',
  [NotificationCode.PlaceBet_PromoAttributionFailed]:
    'betting:error.bettingSlip.pb-PromoAttributionFailed',
  [NotificationCode.PlaceBet_InvalidElementToBetOnActually]:
    'betting:error.bettingSlip.pb-InvalidElementToBetOnActually',
  [NotificationCode.PlaceBet_ValidationError]:
    'betting:error.bettingSlip.pb-ValidationError',
  [NotificationCode.PlaceBet_PlayerIsNotAllowedDueToGeoLocation]:
    'betting:error.bettingSlip.pb-PlayerIsNotAllowedDueToGeoLocation',
  [NotificationCode.PlaceBet_TimeSpentLimitReached]:
    'betting:error.bettingSlip.pb-TimeSpentLimitReached',
};

/** Map for interaction error (by code) */
export const BettingSlipNotificationStatusMessages: Record<number, string> = {
  [EFrontErrorType.DirtyOdd]: 'betting:error.bettingslip.dirtyodd.message',
  [EFrontErrorType.InvalidOdd]: 'betting:error.bettingSlip.invalidOdd',
  [EFrontErrorType.Min]: 'betting:error.bettingSlip.min',
  [EFrontErrorType.Max]: 'betting:error.bettingSlip.max',
  [EFrontErrorType.MinMax]: 'betting:error.bettingSlip.minMax',
  [EFrontErrorType.NotEnoughCredit]:
    'betting:error.bettingSlip.notEnoughCredit',
  [EFrontErrorType.NoStake]: 'betting:error.bettingSlip.noStake.withoutMin',
  [EFrontErrorType.TooManyElementsInSystem]:
    'betting:error.bettingSlip.tooManyElements.system',
  [EFrontErrorType.InvalidBoostus]: 'betting:error.bettingSlip.invalidBoostus',
  [EFrontErrorType.InvalidFreebet]: 'betting:error.bettingSlip.invalidFreebet',
  [EFrontErrorType.InvalidPromotion]:
    'betting:error.bettingSlip.invalidPromotion',
  [EFrontErrorType.BoostusMaxProfit]:
    'betting:error.bettingSlip.boostusMaxProfit',
  [EFrontErrorType.NoStakeWithMin]: 'betting:error.bettingSlip.noStake.withMin',
  [EFrontErrorType.FirstDepositRequired]:
    'betting:error.bettingSlip.firstDepositNotDone',
};
