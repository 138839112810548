import React, { FC } from 'react';

import {
  BettingHistoryTicketType,
  BettingSlipHistoryType,
  hasCashoutEntityGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { CashoutSteps } from '../../../cashout/components/CashoutSteps';
import { useIsDesktop } from '../../../hooks';
import { extractCashoutId } from '../helpers';

type CashoutSectionProps = {
  activeTab: BettingSlipHistoryType;
  betId: string;
  historyTicket: BettingHistoryTicketType;
};

export const CashoutSection: FC<CashoutSectionProps> = ({
  activeTab,
  betId,
  historyTicket,
}) => {
  const isDesktop = useIsDesktop();

  const cashoutId = extractCashoutId(historyTicket);

  const isCashable = useBettingGetterSelector({
    getterSelector: hasCashoutEntityGetterSelector,
    args: [cashoutId],
  });

  return (
    <div>
      {isCashable &&
        activeTab === 'open' &&
        historyTicket?.CashoutData?.CashoutInformation?.BettingSlipId && (
          <Box alignItems="center" pb="sm" mt="xxs" px={!isDesktop ? 'sm' : 0}>
            <CashoutSteps
              cashoutBettingSlipId={
                historyTicket?.CashoutData?.CashoutInformation?.BettingSlipId
              }
              historyId={betId}
              isDesktop={isDesktop}
            />
          </Box>
        )}
    </div>
  );
};
