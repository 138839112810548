import React, { memo, useMemo } from 'react';
import { matchPath, Redirect, Switch, useLocation } from 'react-router-dom';

import { LocaleCode } from '@gaming1/g1-config';
import {
  AppRoute,
  LazyRoute,
  NotificationContainer,
  useRoutePath,
} from '@gaming1/g1-core-web';

import { BettingMenu } from './common/components/BettingMenu';
import { useIsDesktop } from './hooks';
import { Layout } from './layout/components/Layout';
import { bettingRoutes } from './routes';
import { useRouteConfig, useSendRequestsOnce } from './routeurHooks';

const BettingRouterComponent = () => {
  const getBettingRoutePath = useRoutePath(bettingRoutes);
  const routeConfig = useRouteConfig();
  const isDesktop = useIsDesktop();
  const location = useLocation();

  useSendRequestsOnce();

  const { pathname } = useLocation();
  const isBettingHomePage = useMemo(
    () =>
      Boolean(
        matchPath(pathname, {
          path: `*${bettingRoutes.bettingHome.path}`,
          strict: true,
          exact: true,
        }),
      ),
    [pathname],
  );

  const isBettingMenuVisible = isDesktop ? true : isBettingHomePage;
  const routes = useMemo(
    () => (
      <Switch>
        {/* Home route */}
        <LazyRoute
          componentName="BettingHomeRouter"
          importFactory={() => import('./routers/BettingHomeRouter')}
          path={routeConfig.bettingHome.path}
          section={null}
          type="app"
        />

        {/* Sports route */}
        <LazyRoute
          componentName="BettingSportsRouter"
          importFactory={() => import('./routers/BettingSportsRouter')}
          path={[
            routeConfig.sport.path,
            routeConfig.region.path,
            routeConfig.league.path,
            routeConfig.eventPrematch.path,
          ]}
          section={null}
          type="app"
        />

        {/* Live routes */}
        <LazyRoute
          componentName="BettingLiveRouter"
          importFactory={() => import('./routers/BettingLiveRouter')}
          path={routeConfig.liveListTop.path}
          section={null}
          type="app"
        />

        {/* Gifts routes */}
        <LazyRoute
          componentName="BettingGiftsRouter"
          importFactory={() => import('./routers/BettingGiftsRouter')}
          path={[
            routeConfig.boostus.path,
            routeConfig.dailyOffers.path,
            routeConfig.superOdds.path,
            routeConfig.promotions.path,
          ]}
          section={null}
          type="app"
        />

        {/* Search route */}
        <LazyRoute
          componentName="BettingSearchRouter"
          importFactory={() => import('./routers/BettingSearchRouter')}
          path={routeConfig.search.path}
          section={null}
          type="app"
        />

        {/* Protected routes */}
        <LazyRoute
          componentName="BettingProtectedRouter"
          importFactory={() => import('./routers/BettingProtectedRouter')}
          path={[
            routeConfig.myOpenBets.path,
            routeConfig.myFinishedBets.path,
            routeConfig.myUnlockedGifts.path,
            routeConfig.myLockedGifts.path,
          ]}
          section={null}
          type="app"
        />
        <Redirect to={getBettingRoutePath('bettingHome')} />
      </Switch>
    ),
    [
      getBettingRoutePath,
      routeConfig.bettingHome.path,
      routeConfig.boostus.path,
      routeConfig.dailyOffers.path,
      routeConfig.eventPrematch.path,
      routeConfig.league.path,
      routeConfig.liveListTop.path,
      routeConfig.myFinishedBets.path,
      routeConfig.myLockedGifts.path,
      routeConfig.myOpenBets.path,
      routeConfig.myUnlockedGifts.path,
      routeConfig.promotions.path,
      routeConfig.region.path,
      routeConfig.search.path,
      routeConfig.sport.path,
      routeConfig.superOdds.path,
    ],
  );

  const isTwoColLayout = !!matchPath(location.pathname, {
    path: Object.values(routeConfig)
      .filter((route) => route.layoutCols === 2)
      .map((route) => route.path),
    exact: true,
  });

  const hasLiveNav = matchPath(location.pathname, {
    path: Object.values(routeConfig)
      .filter((route) => route.hasNavLive)
      .map((route) => route.path),
    exact: true,
  });

  const multiColLayoutContent = useMemo(
    () =>
      isTwoColLayout ? (
        <Layout.MultiCol numberOfColumns={2}>{routes}</Layout.MultiCol>
      ) : (
        <Layout.MultiCol numberOfColumns={3} isLivePage={!!hasLiveNav || false}>
          {routes}
        </Layout.MultiCol>
      ),
    [hasLiveNav, isTwoColLayout, routes],
  );

  const notificationContent = useMemo(() => <NotificationContainer />, []);
  const menuContent = useMemo(() => <BettingMenu />, []);

  const routerContent = useMemo(
    () => (
      <>
        {notificationContent}
        {isBettingMenuVisible && menuContent}
        {isDesktop ? (
          multiColLayoutContent
        ) : (
          <Layout.OneCol>{routes}</Layout.OneCol>
        )}
      </>
    ),
    [
      isBettingMenuVisible,
      isDesktop,
      menuContent,
      multiColLayoutContent,
      notificationContent,
      routes,
    ],
  );

  return routerContent;
};

const BettingRouter = memo(BettingRouterComponent);

export const getBettingRouter = (locale: LocaleCode) => [
  <AppRoute
    component={BettingRouter}
    key="betting"
    path={`/${locale}${bettingRoutes.betting.path}`}
    section="betting"
  />,
];
