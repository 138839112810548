import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { PasswordRecoveryState } from './types';

export const initialState: PasswordRecoveryState = {
  requests: {
    passwordRecovery: generateInitialRequestState(null),
    resetPassword: generateInitialRequestState(null),
    validatePasswordRecovery: generateInitialRequestState(null),
  },
  validatePasswordRecovery: null,
};

export const passwordRecoveryReducer = (
  state: PasswordRecoveryState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.recoverPassword.request):
        produceLoadingState(draftState.requests.passwordRecovery);
        break;

      case getType(actions.recoverPassword.failure):
        produceFailureState(
          draftState.requests.passwordRecovery,
          action.payload,
        );
        break;

      case getType(actions.recoverPassword.success):
        produceSuccessState(draftState.requests.passwordRecovery);
        break;

      case getType(actions.cleanRecoverPassword):
        return initialState;

      case getType(actions.resetPassword.request):
        produceLoadingState(draftState.requests.resetPassword);
        break;

      case getType(actions.resetPassword.failure):
        produceFailureState(draftState.requests.resetPassword, action.payload);
        break;

      case getType(actions.resetPassword.success):
        produceSuccessState(draftState.requests.resetPassword);
        break;

      case getType(actions.validatePasswordRecoveryId.request):
        produceLoadingState(draftState.requests.validatePasswordRecovery);
        break;

      case getType(actions.validatePasswordRecoveryId.failure):
        produceFailureState(
          draftState.requests.validatePasswordRecovery,
          action.payload,
        );
        break;

      case getType(actions.validatePasswordRecoveryId.success):
        produceSuccessState(draftState.requests.validatePasswordRecovery);
        draftState.validatePasswordRecovery = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
