/* istanbul ignore file */

import { combineReducers, Reducer } from 'redux';

import { GamingActions } from '../../../../store/types';
import { GameListRequestsState } from '../../types';

import { fetchGamesRequestsReducer } from './fetchGames';
import { fetchGameCategoriesRequestReducer } from './fetchGamesCategories';

export const gameListRequestsReducer: Reducer<
  GameListRequestsState,
  GamingActions
> = combineReducers({
  fetchGames: fetchGamesRequestsReducer,
  fetchGameCategories: fetchGameCategoriesRequestReducer,
});
