import { ApplyBoostusToType, BettingConfig } from '@gaming1/g1-config';

type showValueBeforeAndAfterBoostusType = {
  bettingConfig: BettingConfig;
  valueType: ApplyBoostusToType;
  baseValue: number;
  finalValue: number | undefined;
};

export const showValueBeforeAndAfterBoostus = ({
  bettingConfig,
  valueType,
  baseValue,
  finalValue,
}: showValueBeforeAndAfterBoostusType) =>
  finalValue !== undefined &&
  baseValue !== finalValue &&
  bettingConfig.boostus.enabled &&
  bettingConfig.boostus.applyTo === valueType;

export const multiplyBy100 = (number: number) => number * 100;
