import * as t from 'io-ts';

import { EGamingRoomsBonusType, EPrizeType } from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const prize = t.intersection(
  [
    t.type({
      Amount: t.number,
      Id: t.string,
      IsOnlyFirstDeposit: t.boolean,
      Order: t.number,
      PrizeConfigurationId: t.string,
      PrizeType: createEnumType<EPrizeType>(EPrizeType, 'EPrizeType'),
      Used: t.boolean,
    }),
    t.partial({
      Cost: t.number,
      Description: t.string,
      ExtraInfo: t.string,
      MinDepositAmount: t.number,
      Name: t.string,
      PictureUrl: t.string,
      TermsAndConditions: t.string,
      Title: t.string,
      Unit: t.string,
      Values: t.array(t.string),
    }),
  ],
  'Prize',
);

export const prizeConfiguration = t.intersection(
  [
    t.type({
      Id: t.string,
      IsOnlyFirstDeposit: t.boolean,
    }),
    t.partial({
      Description: t.string,
      PictureUrl: t.string,
      Prizes: t.array(prize),
      TermsAndConditions: t.string,
      Title: t.string,
    }),
  ],
  'PrizeConfiguration',
);

export const rankInfo = t.intersection([
  t.type({ Status: t.number, Threshold: t.number }),
  t.partial({ Name: t.string }),
]);

export const gamingRoomsBonus = t.intersection(
  [
    t.type({
      BonusType: createEnumType<EGamingRoomsBonusType>(
        EGamingRoomsBonusType,
        'EGamingRoomsBonusType',
      ),
      EndDate: t.string,
      Id: t.string,
      MaximumDeposit: t.number,
      Priority: t.number,
      StartDate: t.string,
      Value: t.number,
    }),
    t.partial({
      ConfirmationMessage: t.string,
      Description: t.string,
      HowToGet: t.string,
      JsAction: t.string,
      JsText: t.string,
      Name: t.string,
      Picture: t.string,
      ShortDescription: t.string,
      Validity: t.number,
    }),
  ],
  'GamingRoomsBonus',
);

export const province = t.type(
  {
    Name: t.string,
  },
  'Province',
);

export const municipality = t.type(
  {
    DepartmentCode: t.number,
    DepartmentName: t.string,
    MunicipalityCode: t.number,
    MunicipalityName: t.string,
    ZipCode: t.string,
  },
  'Municipality',
);

export const departmentAndMunicipalities = t.type(
  {
    DepartmentCode: t.number,
    DepartmentName: t.string,
    Municipalities: t.array(
      t.type({
        MunicipalityCode: t.number,
        MunicipalityName: t.string,
        ZipCodes: t.array(t.string),
      }),
    ),
  },
  'DepartmentAndMunicipalities',
);
