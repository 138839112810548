import { normalize, schema } from 'normalizr';

import { SearchShopProductsData, SearchShopProductsResponse } from './types';

const products = new schema.Entity('products', {}, { idAttribute: 'Id' });
const productItems = new schema.Array(products);

export const formatSearchShopProducts = (
  response: SearchShopProductsResponse,
): SearchShopProductsData => {
  const normalizedData = normalize(response.Products, productItems);
  const entities =
    normalizedData.entities as SearchShopProductsData['entities'];
  const { result } = normalizedData;

  return { entities, result };
};
