import React, { FC, useContext, useMemo } from 'react';

import { PrismicGridSlice } from '@gaming1/g1-cms';
import {
  isMediaBreakPointAboveOrEqual,
  isMediaBreakPointBelowOrEqual,
  LayoutContext,
} from '@gaming1/g1-ui';

import { getItemKeyFromRichText } from '../../helpers';
import { PrismicRichText } from '../PrismicRichText';

import { PrismicGridContainer, PrismicGridWrapper } from './styles';

export type PrismicGridProps = {
  /** Prismic 'Grid' slice */
  slice: PrismicGridSlice;
  /** Test ID */
  testId?: string;
  /** Stack of the IDs of parent slices */
  parentIds?: string[];
};

/**
 * This component renders a prismic `Grid` slice
 * It renders a flex grid with multiple children
 */
export const PrismicGrid: FC<PrismicGridProps> = ({
  slice,
  testId = 'prismic-grid',
  parentIds = [],
}) => {
  const { media } = useContext(LayoutContext);

  const itemsCount = useMemo(() => {
    if (isMediaBreakPointAboveOrEqual(media, 'lg')) {
      return parseInt(slice.primary.count_large, 10);
    }
    if (isMediaBreakPointBelowOrEqual(media, 'sm')) {
      return parseInt(slice.primary.count_small, 10);
    }
    return parseInt(slice.primary.count_medium, 10);
  }, [
    media,
    slice.primary.count_large,
    slice.primary.count_medium,
    slice.primary.count_small,
  ]);

  return (
    <PrismicGridWrapper
      alignment={slice.primary.alignment}
      data-testid={testId}
    >
      {slice.items.map((item, index) => (
        <PrismicGridContainer
          key={`prismic-grid-item-${
            getItemKeyFromRichText(item.content) || index
          }`}
          width={item.width || undefined}
          itemsCount={itemsCount}
          styleName={item.style}
          horizontalAlignment={item.horizontal_alignment}
          verticalAlignment={item.vertical_alignment}
          data-testid="prismic-grid-item"
        >
          <PrismicRichText
            content={item.content}
            align={item.horizontal_alignment || 'left'}
            sliceId={slice.primary.id}
            parentIds={
              slice.primary.id ? [...parentIds, slice.primary.id] : parentIds
            }
          />
        </PrismicGridContainer>
      ))}
    </PrismicGridWrapper>
  );
};
