import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { coreActions, CoreActions } from '@gaming1/g1-core';

import { blogReducer } from '../blog/store/reducer';
import { prismicNavigationsReducer } from '../prismicNavigation/store/reducer';
import { prismicPageContentReducer } from '../prismicPageContent/store/reducer';
import { prismicReferenceReducer } from '../prismicReference/store/reducer';
import { promotionsReducer } from '../promotions/store/reducer';

import { CmsActions, CmsState } from './types';

const globalReducer: Reducer<CmsState, CmsActions | CoreActions> =
  combineReducers({
    blog: blogReducer,
    promotions: promotionsReducer,
    prismicNavigations: prismicNavigationsReducer,
    prismicReference: prismicReferenceReducer,
    prismicPageContent: prismicPageContentReducer,
  });

/**
 * The purpose of this root reducer is to reset the state when specific actions
 * are successfully made. See https://alligator.io/redux/reset-state-redux
 * for more informations.
 */
export const cmsReducer: Reducer<CmsState, CmsActions | CoreActions> = (
  state,
  action,
) =>
  globalReducer(
    getType(coreActions.switchLocale.success) === action.type ||
      (getType(coreActions.loggedOutUser) === action.type &&
        action.payload === true)
      ? undefined
      : state,
    action as CmsActions,
  );
