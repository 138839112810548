import React, { FC, memo, useContext } from 'react';

import { useBettingSlipSystemPotWinning } from '@gaming1/g1-betting';
import { useFormatMoney } from '@gaming1/g1-core';

import { BettingSlipContext } from '../../BettingSlipContext';

import { PotWinningContainer } from './styles';

/**
 * Display the ticket item stake and pot winning information
 * a item (base on outcome) select by user and save into store
 */
const BettingSlipSystemPotWinningComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const formatMoney = useFormatMoney();

  const potWinning = useBettingSlipSystemPotWinning(bettingSlipId);

  if (!potWinning) {
    return null;
  }
  return <PotWinningContainer>{formatMoney(potWinning)}</PotWinningContainer>;
};

export const BettingSlipSystemPotWinning = memo(
  BettingSlipSystemPotWinningComponent,
);
