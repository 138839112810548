import {
  BlogArticlesResponse,
  FetchBlogArticlesRequestPayload,
  FetchBlogArticlesSuccessPayload,
  FetchRecentBlogArticlesRequestPayload,
} from './types';

/**
 * Checks if the specified payload is a FetchBlogArticlesRequestPayload
 */
export const isFetchBlogArticlesRequestPayload = (
  payload:
    | FetchBlogArticlesRequestPayload
    | FetchRecentBlogArticlesRequestPayload,
): payload is FetchBlogArticlesRequestPayload => 'tag' in payload;

/**
 * Parse a raw response from Prismic into a FetchBlogArticlesSuccessPayload object
 */
export const formatFetchBlogArticlesResponse = (
  response: BlogArticlesResponse,
  request:
    | FetchBlogArticlesRequestPayload
    | FetchRecentBlogArticlesRequestPayload,
): FetchBlogArticlesSuccessPayload => ({
  tag: isFetchBlogArticlesRequestPayload(request)
    ? request.tag?.name
    : undefined,
  pagination: {
    PageNumber: request.pageNum,
    PageSize: request.pageSize,
    Total: response.total_results_size,
  },
  uids: response.results.map((article) => article.uid),
  articles: response.results.reduce(
    (prev, cur) => ({ ...prev, [cur.uid]: cur }),
    {},
  ),
});
