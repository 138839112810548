import styled, { css } from 'styled-components';
import {
  justifyContent,
  JustifyContentProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

import {
  colors,
  fontSizes,
  fontWeight,
  media,
  spaces,
} from '@gaming1/g1-style';

import { IconType } from '../Icon/icons';

import { HeadingLevel } from './types';

type StyledHeadingProps = SpaceProps &
  JustifyContentProps &
  TextAlignProps & {
    mono?: boolean;
    /** Level of the heading */
    level?: HeadingLevel;
    /** Icon of the title */
    icon?: IconType;
  };

export const StyledHeading = styled.div<StyledHeadingProps>`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${spaces('xs')};
  }

  ${({ level }) =>
    level === 'h1' &&
    css`
      color: ${colors('heading1')};
      ${({ theme }) => fontWeight(theme.options.heading1FontWeight, 'title')};
      font-size: ${({ theme }) => theme.options.heading1FontSize};
      text-transform: ${({ theme }) =>
        theme.options.heading1Uppercase ? 'uppercase' : 'none'};
      ${space}

      ${media.lg} {
        font-size: ${({ theme }) =>
          `calc(${theme.options.heading1FontSize} + 3px)`};
        margin-top: 0;
        ${space}
      }

      svg path {
        fill: ${colors('heading1')};
      }
    `};

  ${({ level }) =>
    level === 'h2' &&
    css`
      color: ${colors('heading2')};
      ${({ theme }) => fontWeight(theme.options.heading2FontWeight, 'title')};
      font-size: ${({ theme }) => theme.options.heading2FontSize};
      text-transform: ${({ theme }) =>
        theme.options.heading2Uppercase ? 'uppercase' : 'none'};
      margin-top: ${spaces('md')};
      ${space}

      ${media.lg} {
        font-size: ${({ theme }) =>
          `calc(${theme.options.heading2FontSize} + 3px)`};
        margin-top: ${spaces('lg')};
        ${space}
      }

      svg path {
        fill: ${colors('heading2')};
      }
    `};

  ${({ level }) =>
    level === 'h3' &&
    css`
      color: ${colors('heading3')};
      ${({ theme }) => fontWeight(theme.options.heading3FontWeight, 'title')};
      font-size: ${({ theme }) => theme.options.heading3FontSize};
      text-transform: ${({ theme }) =>
        theme.options.heading3Uppercase ? 'uppercase' : 'none'};
      margin-top: ${spaces('md')};
      ${space}

      ${media.lg} {
        font-size: ${({ theme }) =>
          `calc(${theme.options.heading3FontSize} + 2px)`};
        margin-top: ${spaces('lg')};
        ${space}
      }

      svg path {
        fill: ${colors('heading3')};
      }
    `};

  ${({ level }) =>
    level === 'h4' &&
    css`
      color: ${colors('heading4')};
      ${({ theme }) => fontWeight(theme.options.heading4FontWeight, 'title')};
      font-size: ${({ theme }) => theme.options.heading4FontSize};
      text-transform: ${({ theme }) =>
        theme.options.heading4Uppercase ? 'uppercase' : 'none'};
      margin-top: ${spaces('sm')};
      ${space}

      ${media.lg} {
        margin-top: ${spaces('md')};
        ${space}
      }

      svg path {
        fill: ${colors('heading3')};
      }
    `};

  ${({ level }) =>
    level === 'h5' &&
    css`
      color: ${colors('text')};
      ${fontWeight('light', 'title')};
      font-size: ${fontSizes('lg')};
      margin-top: ${spaces('sm')};
      ${space}

      ${media.lg} {
        font-size: 1.188rem;
        margin-top: ${spaces('md')};
        ${space}
      }
    `};

  ${justifyContent}
  ${textAlign}
`;
