import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardSportStatsSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';

import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardCompactBackgroundWrapper as BackgroundWrapper,
  ScoreBoardCompactTemplateItem as TemplateItem,
  ScoreBoardCompactTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the compact scoreboard with a darts template */
export const ScoreBoardCompactDartsTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
}) => {
  const { team1: legs1, team2: legs2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardSportStatsSelector,
    args: [eventId, 'Legs'],
    equalityFn: shallowEqual,
  });

  return (
    <TemplateWrapper data-testid="scoreboard-compact-darts-template">
      <BackgroundWrapper backgroundRadius="bothSides">
        <TemplateItem isHighlighted>{legs1}</TemplateItem>
        <TemplateItem isHighlighted>{legs2}</TemplateItem>
      </BackgroundWrapper>
    </TemplateWrapper>
  );
};
