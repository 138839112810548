import { useSelector } from 'react-redux';

import {
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../common/store/types';
import { EFrontErrorType } from '../../../notification/mapper/types';
import { isValidFreebetForBSGetterSelector } from '../../store/selectors/gifts/freebetConditions';

export const useBettingSlipFreebetErrorType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const isValid = useSelector(isValidFreebetForBSGetterSelector)(bettingSlipId);

  return () =>
    !isValid
      ? {
          Status: EFrontErrorType.InvalidFreebet,
          Level: NotificationLevel.Error,
          Code: NotificationCode.Unknown,
        }
      : {
          Status: EFrontErrorType.None,
          Level: NotificationLevel.Information,
          Code: NotificationCode.Unknown,
        };
};
