import styled, { css } from 'styled-components';

import { fontSizes, opacity, spaces } from '@gaming1/g1-style';
import { Button, Text } from '@gaming1/g1-ui';

const CANCEL_OR_CONFIRMATION_CASHOUT_BUTTON_MIN_WIDTH_IN_PX = 160;
const CANCEL_OR_CONFIRMATION_CASHOUT_BUTTON_MAX_HEIGHT_IN_PX = 40;

export const MainCashoutButton = styled(Button)`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('semi')};
    `}
`;

export const ConfirmationOrCancelButton = styled(Button)`
  min-width: ${CANCEL_OR_CONFIRMATION_CASHOUT_BUTTON_MIN_WIDTH_IN_PX}px;
  max-height: ${CANCEL_OR_CONFIRMATION_CASHOUT_BUTTON_MAX_HEIGHT_IN_PX}px;
  padding: ${spaces('xs')};
`;

export const ErrorMessage = styled(Text)`
  margin-top: ${spaces('xs')};
  font-size: ${fontSizes('md')};
  opacity: ${opacity('semi')};
`;
