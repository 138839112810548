import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { PaymentActions } from '../types';

import { WithdrawalState } from './types';

export const initialState: WithdrawalState = {
  requests: {
    cancelWithdrawal: generateInitialRequestState(null),
    getEWalletWithdrawInfo: generateInitialRequestState(null),
    getTransferWithdrawInfo: generateInitialRequestState(null),
    getWithdrawInfo: generateInitialRequestState(null),
    getCreditCardWithdrawInfo: generateInitialRequestState(null),
    makeInRoomWithdraw: generateInitialRequestState(null),
    makeTransferWithdraw: generateInitialRequestState(null),
    makeEWalletWithdraw: generateInitialRequestState(null),
    makeCreditCardWithdraw: generateInitialRequestState(null),
  },
  cancelWithdrawal: null,
  eWalletWithdrawalInfo: null,
  inRoomWithdrawResult: null,
  transferWithdrawResult: null,
  eWalletWithdrawResult: null,
  transferWithdrawalInfo: null,
  withdrawalInfo: null,
  creditCardWithdrawalInfo: null,
  creditCardWithdrawResult: null,
};

export const withdrawalReducer = (
  state = initialState,
  action: CoreActions | PaymentActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /**
       * CANCEL WITHDRAWAL
       */
      case getType(actions.cancelWithdrawal.request):
        produceLoadingState(draftState.requests.cancelWithdrawal);
        break;

      case getType(actions.cancelWithdrawal.failure):
        produceFailureState(
          draftState.requests.cancelWithdrawal,
          action.payload,
        );
        break;

      case getType(actions.cancelWithdrawal.success):
        produceSuccessState(draftState.requests.cancelWithdrawal);
        draftState.cancelWithdrawal = action.payload;
        break;

      /**
       * GET WITHDRAWAL INFO
       */
      case getType(actions.getWithdrawalInfo.request):
        produceLoadingState(draftState.requests.getWithdrawInfo);
        break;

      case getType(actions.getWithdrawalInfo.failure):
        produceFailureState(
          draftState.requests.getWithdrawInfo,
          action.payload,
        );
        break;

      case getType(actions.getWithdrawalInfo.success):
        produceSuccessState(draftState.requests.getWithdrawInfo);
        draftState.withdrawalInfo = action.payload;
        break;

      /**
       * GET TRANSFER WITHDRAWAL INFO
       */
      case getType(actions.getTransferWithdrawalInfo.request):
        produceLoadingState(draftState.requests.getTransferWithdrawInfo);
        break;

      case getType(actions.getTransferWithdrawalInfo.failure):
        produceFailureState(
          draftState.requests.getTransferWithdrawInfo,
          action.payload,
        );
        break;

      case getType(actions.getTransferWithdrawalInfo.success):
        produceSuccessState(draftState.requests.getTransferWithdrawInfo);
        draftState.transferWithdrawalInfo = action.payload;
        break;

      /**
       * GET EWALLET WITHDRAWAL INFO
       */
      case getType(actions.getEWalletWithdrawalInfo.request):
        produceLoadingState(draftState.requests.getEWalletWithdrawInfo);
        break;

      case getType(actions.getEWalletWithdrawalInfo.failure):
        produceFailureState(
          draftState.requests.getEWalletWithdrawInfo,
          action.payload,
        );
        break;

      case getType(actions.getEWalletWithdrawalInfo.success):
        produceSuccessState(draftState.requests.getEWalletWithdrawInfo);
        draftState.eWalletWithdrawalInfo = action.payload;
        break;

      /**
       * MAKE INROOM WITHDRAWAL
       */
      case getType(actions.makeInRoomWithdrawal.request):
        produceLoadingState(draftState.requests.makeInRoomWithdraw);
        break;

      case getType(actions.makeInRoomWithdrawal.failure):
        produceFailureState(
          draftState.requests.makeInRoomWithdraw,
          action.payload,
        );
        break;

      case getType(actions.makeInRoomWithdrawal.success):
        produceSuccessState(draftState.requests.makeInRoomWithdraw);
        draftState.inRoomWithdrawResult = action.payload;
        break;

      /**
       * CLEAN MAKE INROOM WITHDRAWAL
       */
      case getType(actions.cleanMakeInRoomWithdrawal):
        draftState.inRoomWithdrawResult = initialState.inRoomWithdrawResult;
        draftState.requests.makeInRoomWithdraw =
          initialState.requests.makeInRoomWithdraw;
        break;

      /**
       * MAKE TRANSFER WITHDRAWAL
       */
      case getType(actions.makeTransferWithdrawal.request):
        produceLoadingState(draftState.requests.makeTransferWithdraw);
        break;

      case getType(actions.makeTransferWithdrawal.failure):
        produceFailureState(
          draftState.requests.makeTransferWithdraw,
          action.payload,
        );
        break;

      case getType(actions.makeTransferWithdrawal.success):
        produceSuccessState(draftState.requests.makeTransferWithdraw);
        draftState.transferWithdrawResult = action.payload;
        break;
      /**
       * CLEAN TRANSFER WITHDRAWAL
       */
      case getType(actions.cleanMakeTransferWithdrawal):
        draftState.transferWithdrawResult = initialState.transferWithdrawResult;
        draftState.requests.makeTransferWithdraw =
          initialState.requests.makeTransferWithdraw;
        break;

      /**
       * MAKE EWALLET WITHDRAWAL
       */
      case getType(actions.makeEWalletWithdrawal.request):
        produceLoadingState(draftState.requests.makeEWalletWithdraw);
        break;

      case getType(actions.makeEWalletWithdrawal.failure):
        produceFailureState(
          draftState.requests.makeEWalletWithdraw,
          action.payload,
        );
        break;

      case getType(actions.makeEWalletWithdrawal.success):
        produceSuccessState(draftState.requests.makeEWalletWithdraw);
        draftState.eWalletWithdrawResult = action.payload;
        break;

      /**
       * CLEAN EWALLET WITHDRAWAL
       */
      case getType(actions.cleanMakeEWalletWithdrawal):
        draftState.eWalletWithdrawResult = initialState.eWalletWithdrawResult;
        draftState.requests.makeEWalletWithdraw =
          initialState.requests.makeEWalletWithdraw;
        break;

      /**
       * GET CREDITCARD WITHDRAWAL INFO
       */
      case getType(actions.getCreditCardWithdrawalInfo.request):
        produceLoadingState(draftState.requests.getCreditCardWithdrawInfo);
        break;

      case getType(actions.getCreditCardWithdrawalInfo.failure):
        produceFailureState(
          draftState.requests.getCreditCardWithdrawInfo,
          action.payload,
        );
        break;

      case getType(actions.getCreditCardWithdrawalInfo.success):
        produceSuccessState(draftState.requests.getCreditCardWithdrawInfo);
        draftState.creditCardWithdrawalInfo = action.payload;
        break;
      /**
       * MAKE CREDITCARD WITHDRAWAL
       */
      case getType(actions.makeCreditCardWithdrawal.request):
        produceLoadingState(draftState.requests.makeCreditCardWithdraw);
        break;

      case getType(actions.makeCreditCardWithdrawal.failure):
        produceFailureState(
          draftState.requests.makeCreditCardWithdraw,
          action.payload,
        );
        break;

      case getType(actions.makeCreditCardWithdrawal.success):
        produceSuccessState(draftState.requests.makeCreditCardWithdraw);
        draftState.creditCardWithdrawResult = action.payload;
        break;

      /**
       * CLEAN CREDITCARD WITHDRAWAL
       */
      case getType(actions.cleanMakeCreditCardWithdrawal):
        draftState.creditCardWithdrawResult =
          initialState.creditCardWithdrawResult;
        draftState.requests.makeCreditCardWithdraw =
          initialState.requests.makeCreditCardWithdraw;
        break;

      default: // Immer will automatically return the state
    }
  });
