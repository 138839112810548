import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getDeployEnv } from '@gaming1/g1-logger';
import { getBiggestWinnings } from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import { getBiggestWinnings as getBiggestWinningsCodec } from './codecs';
import {
  get24BiggestWinningsMockResponse,
  getBiggestWinningsMockResponse,
} from './mockData';

const shouldUseMockData = ['local', 'development', 'testing'].includes(
  getDeployEnv(),
);

export const fetchBiggestWinningsEpic: GamingEpic = (
  actions$,
  state$,
  { config$, wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchBiggestWinnings.request)),
    withLatestFrom(config$, state$),
    mergeMap(([action, { room }, { core }]) => {
      const request = action.payload;
      const params = {
        LanguageCode: core.i18n.webSocketLocale,
        RoomDomainName: room.roomName,
        ...action.payload,
      };
      return wsAdapter.request(getBiggestWinnings(params)).pipe(
        mapGuard(getBiggestWinningsCodec),
        map((data) =>
          /* This feature is mainly based on the activity of the players
          the data must be mocked for some environments
          ('local', 'development' and 'testing') */
          shouldUseMockData
            ? actions.fetchBiggestWinnings.success({
                ...(request.Count >= 24
                  ? get24BiggestWinningsMockResponse
                  : getBiggestWinningsMockResponse),
                request,
              })
            : actions.fetchBiggestWinnings.success({ ...data, request }),
        ),
        catchError((err) =>
          of(
            actions.fetchBiggestWinnings.failure({
              request,
              ...createFailurePayload(err),
            }),
          ),
        ),
      );
    }),
  );
