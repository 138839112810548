import React, { FC } from 'react';

import { useDateFormat } from '../../../hooks';

import { StyledValidityDateText } from './styles';

type ValidityDateTextProps = {
  validityDate: string;
};

export const ValidityDateText: FC<ValidityDateTextProps> = ({
  validityDate,
}) => {
  const formatDateCorrectly = useDateFormat(validityDate);

  return (
    <StyledValidityDateText mt="0">
      {formatDateCorrectly}
    </StyledValidityDateText>
  );
};
