import React, { useContext, useState, VFC } from 'react';
import { ThemeContext } from 'styled-components';

import { BoxProps } from '../Box';
import { Icon } from '../Icon';
import { SeparatorDent } from '../SeparatorDent';

import { PanelDetails, PanelSummaryWrapper, PanelWrapper } from './styles';

export type CollapsiblePanelProps = BoxProps & {
  /** Main box Border radius */
  borderRadius?: string;
  /** Optional visible content when panel is open */
  expandedContent: React.ReactNode;
  /** Shows a separator between main content and expanded content */
  hasSeparator?: boolean;
  /** Visible content when panel is collapsed */
  mainContent: React.ReactNode;
};

export const CollapsiblePanel: VFC<CollapsiblePanelProps> = ({
  mainContent,
  expandedContent,
  hasSeparator = false,
  borderRadius,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);
  const toggleDetails = () => setExpanded((state) => !state);

  return (
    <PanelWrapper
      isExpanded={expanded}
      onClick={toggleDetails}
      borderRadius={borderRadius}
      {...props}
    >
      <PanelSummaryWrapper
        isExpanded={expanded}
        hasSeparator={hasSeparator}
        data-testid="collapsible-panel-summary"
      >
        {mainContent}
        <Icon
          id={
            expanded
              ? 'collapsible-panel-expanded'
              : 'collapsible-panel-notExpanded'
          }
          fill={theme.colors.muted}
          type={expanded ? 'DiscInfo' : 'CircleInfo'}
        />
      </PanelSummaryWrapper>
      {expanded && (
        <>
          {hasSeparator && <SeparatorDent mr="sm" color={theme.colors.muted} />}
          <PanelDetails data-testid="collapsible-panel-details">
            {expandedContent}
          </PanelDetails>
        </>
      )}
    </PanelWrapper>
  );
};
