import { OptimalBetRequest } from '@gaming1/g1-requests-betting';

import { OptimalBetRawData } from '../store/types/optimalBet';

/**
 * Take a raw data object to format them into a valid RM request.
 * The object needs to be formatted to be used by the RM.
 *
 * @param bettingSlipType the bettingslip type that contains the fetched item(s)
 * @param preparedItems the formatted items from the bettingSlip
 */
export const formatOptimalBetPreparedRequest = ({
  bettingSlipType,
  preparedElements,
}: OptimalBetRawData): OptimalBetRequest => ({
  BettingSlipType: bettingSlipType,
  Elements: preparedElements,
});
