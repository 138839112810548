import * as t from 'io-ts';

import {
  EDocumentStatus,
  EDocumentStatusResponse,
  EDocumentToUpload,
  EDocumentType,
  EIdentityFieldDocument,
  EReasonToUploadDocument,
  EUploadDocumentStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

const documentToUpload = t.intersection([
  t.type({
    Reason: createEnumType<EReasonToUploadDocument>(EReasonToUploadDocument),
  }),
  t.partial({
    Documents: t.array(createEnumType<EDocumentToUpload>(EDocumentToUpload)),
  }),
]);

export const getUploadDocumentStatusResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EUploadDocumentStatus.Successful),
      HasIbanWaitingForIdentification: t.boolean,
      HasIdentityWaitingForIdentification: t.boolean,
      Ibans: t.array(t.string),
      PlayerMustLegalLimitDocument: t.boolean,
      PlayerToken: t.string,
      UploadUrl: t.string,
    }),
    t.partial({
      DocumentsToUpload: t.array(documentToUpload),
    }),
  ],
  'GetUploadDocumentStatus',
);

export const officialDocument = t.intersection([
  t.type({
    DocumentStatus: createEnumType<EDocumentStatus>(EDocumentStatus),
  }),
  t.partial({
    DocumentName: t.string,
    DocumentTypeList: t.array(createEnumType<EDocumentType>(EDocumentType)),
    IdentityFieldDocument: t.array(
      createEnumType<EIdentityFieldDocument>(EIdentityFieldDocument),
    ),
  }),
]);

export const getDocumentStatusResponse = t.intersection([
  t.type({
    StatusResponse: t.literal(EDocumentStatusResponse.Successful),
  }),
  t.partial({
    Documents: t.array(officialDocument),
  }),
]);

export const sendDocumentResponseCodec = t.type(
  {
    files: t.array(
      t.intersection([
        t.type({
          error: t.null,
          name: t.string,
        }),
        t.partial({
          deleteType: t.string,
          deleteUrl: t.string,
          size: t.number,
          thumbnailUrl: t.string,
          type: t.string,
          url: t.string,
        }),
      ]),
    ),
  },
  'SendDocumentResponse',
);
