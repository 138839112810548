/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:07:05 09-04-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetAvailableTicketsForPlayerRequestDTO,
  GetAvailableTicketsForPlayerResponseDTO,
  GetPlayerTicketsRequestDTO,
  GetPlayerTicketsResponseDTO,
} from '../entities';

export const getPlayerTickets = (options: GetPlayerTicketsRequestDTO) =>
  createRequest<GetPlayerTicketsResponseDTO>(100, 'GetPlayerTickets', options);

export const getAvailableTicketsForPlayer = (
  options: GetAvailableTicketsForPlayerRequestDTO,
) =>
  createRequest<GetAvailableTicketsForPlayerResponseDTO>(
    100,
    'GetAvailableTicketsForPlayer',
    options,
  );
