import { createContext } from 'react';

import { PrismicSlice } from '@gaming1/g1-cms';

import { PrismicCustomFunction } from '../types';

export type PrismicPageContextValue = {
  rootSlices: PrismicSlice[];
  slicesWithIds: Record<string, PrismicSlice>;
  isUserLoggedIn: boolean;
  customFunctions?: Record<string, PrismicCustomFunction>;
};

export const PrismicPageContext = createContext<PrismicPageContextValue>({
  rootSlices: [],
  slicesWithIds: {},
  isUserLoggedIn: false,
});
