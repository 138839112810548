import React, { ComponentProps, FC, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  bettingDebugTabs,
  BettingProviders,
  BettingWatchers,
} from '@gaming1/g1-betting-web';
import { CMSProviders } from '@gaming1/g1-cms-web';
import { currentSectionSelector } from '@gaming1/g1-core';
import {
  Content,
  coreRoutes,
  CoreWatchers,
  DebugPanel,
  GoogleFont,
  SuspenseLoader,
  useRoutePath,
} from '@gaming1/g1-core-web';
import { GamingProviders, GamingWatchers } from '@gaming1/g1-gaming-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { InboxProviders } from '@gaming1/g1-inbox-web';
import { LoyaltyProviders } from '@gaming1/g1-loyalty-web';
import { PaymentProviders } from '@gaming1/g1-payment-web';
import { Backdrop, BannersContainer, MainContainer } from '@gaming1/g1-ui';
import { UserProviders, UserWatchers } from '@gaming1/g1-user-web';

import { LinksList } from '../LinkList';

import { AppBottomNav } from './AppBottomNav';
import { AppDrawerNav } from './AppDrawerNav';
import { AppFooter } from './AppFooter';
import { AppSearchDrawer } from './AppSearchDrawer';
import { AppTopBar } from './AppTopBar';
import { AppTopNav } from './AppTopNav';

const defaultDebugTabs: Required<
  ComponentProps<typeof DebugPanel>
>['additionalTabs'] = [
  {
    tabName: 'Links',
    Component: LinksList,
  },
];

const additionalDebugTabs = [...defaultDebugTabs, ...bettingDebugTabs];

export const AppLayout: FC = ({ children }) => {
  const { t } = useTranslation(['betting', 'core']);
  const currentSection = useSelector(currentSectionSelector);
  const getCoreRoutePath = useRoutePath(coreRoutes);

  const bettingMenus: ComponentProps<typeof BettingProviders>['menus'] =
    useMemo(
      () => ({
        main: { firstItems: [], lastItems: [] },
        mainAuthenticated: { firstItems: [], lastItems: [] },
        services: [
          {
            label: t('betting:lateralMenu.howToBet'),
            path: '',
            icon: 'HowToBetRound',
            testId: 'how-to-bet-menu-link',
          },
          {
            label: t('betting:lateralMenu.statistics'),
            path: '',
            icon: 'Stats',
            testId: 'statistics-menu-link',
          },
          {
            label: t('betting:lateralMenu.scoreAndResults'),
            path: '',
            icon: 'CalendarMonth',
            testId: 'score-and-results-menu-link',
          },
        ],
      }),
      [t],
    );

  const gamingMenus: ComponentProps<typeof GamingProviders>['menus'] = useMemo(
    () => ({
      gameCategories: {
        firstItems: [
          {
            exact: true,
            label: t('core:menu.home'),
            path: getCoreRoutePath('home'),
            icon: 'Home',
            testId: 'game-categories-menu-home-item',
          },
        ],
      },
    }),
    [getCoreRoutePath, t],
  );

  return (
    <MainContainer>
      <BettingProviders menus={bettingMenus} footer={AppFooter}>
        <CMSProviders>
          <GamingProviders menus={gamingMenus}>
            <LoyaltyProviders>
              <PaymentProviders>
                <UserProviders>
                  <InboxProviders>
                    <AppTopBar />
                    <AppTopNav />
                    <BannersContainer />
                    <Suspense fallback={<SuspenseLoader />}>
                      <Content>{children}</Content>
                    </Suspense>
                    <DebugPanel additionalTabs={additionalDebugTabs} />
                    <CoreWatchers />
                    <BettingWatchers />
                    <GamingWatchers />
                    <UserWatchers />
                    {currentSection !== 'betting' && <AppFooter />}
                    <AppBottomNav />
                    <Backdrop />
                    {/* Drawers */}
                    <AppDrawerNav />
                    <AppSearchDrawer />
                    <GoogleFont fontFamily="Source+Sans+Pro:300,400,600" />
                  </InboxProviders>
                </UserProviders>
              </PaymentProviders>
            </LoyaltyProviders>
          </GamingProviders>
        </CMSProviders>
      </BettingProviders>
    </MainContainer>
  );
};
