import {
  PlaceBetRequest,
  PlaceSystemBetRequest,
  SystemRankInfo,
} from '@gaming1/g1-requests-betting';

import {
  PlaceBetRawData,
  PlaceSystemBetRawData,
  SystemRankInfoRawData,
} from '../store/types/placeBet';

/**
 * This helper takes a PlaceBetRawData object as an argument and format it into a valid PlaceBetRequest.
 */
export const formatPlaceBetPreparedRequest = ({
  bettingActivity,
  bettingSlipType,
  creditType,
  stake,
  freebetConditionId,
  selectedPromotion,
  optimalBet,
  elements,
}: PlaceBetRawData): PlaceBetRequest => ({
  BettingActivity: bettingActivity,
  BettingSlipType: bettingSlipType,
  CreditType: creditType,
  Stake: stake || 0,
  FreebetConditionId: freebetConditionId,
  SelectedPromotion: selectedPromotion,
  OptimalBet: optimalBet,
  Elements: elements,
});

/**
 * This helper takes a PlaceSystemBetRawData object which contains some rankInfos (rankId + stake)
 * and the bettingSlip elements to return a valid placeBet system request.
 */
export const formatPlaceSystemBetPreparedRequest = ({
  rankInfos,
  elements,
}: PlaceSystemBetRawData): PlaceSystemBetRequest => ({
  RankInfos: rankInfos,
  Elements: elements,
});

/**
 * This helper takes an object containing a rankId and a stake to format them into a valid SystemRankInfo object.
 */
export const formatRankIdAndStake = ({
  rankId,
  stake,
}: SystemRankInfoRawData): SystemRankInfo => ({
  RankType: rankId,
  Stake: stake,
});
