import { NotificationCode } from '@gaming1/g1-requests-betting';

import { EFrontErrorType } from './types';

export type actionsNotificationType =
  | null
  | 'deposit'
  | 'dirtyOdd'
  | 'helpdesk'
  | 'pendingGames'
  | 'settings'
  | 'setToMax'
  | 'setToMin';

/*
 * add callToAction inside error message
 */
/** Map for front error */
export const BettingSlipNotificationStatusCallToAction: Record<
  number,
  actionsNotificationType
> = {
  [EFrontErrorType.NotEnoughCredit]: 'deposit',
  [EFrontErrorType.Max]: 'setToMax',
  [EFrontErrorType.Min]: 'setToMin',
  [EFrontErrorType.NoStake]: 'setToMin',
  [EFrontErrorType.DirtyOdd]: 'dirtyOdd',
  [EFrontErrorType.NoStakeWithMin]: 'setToMin',
  [EFrontErrorType.FirstDepositRequired]: 'deposit',
};

/** Map for interaction error (rm-pb) */
export const BettingSlipNotificationCodeCallToAction: Record<
  number,
  actionsNotificationType
> = {
  [NotificationCode.RiskManagement_Success]: null,
  [NotificationCode.RiskManagement_InternalError]: null,
  [NotificationCode.RiskManagement_TooManyElements]: null,
  [NotificationCode.RiskManagement_TooFewElements]: null,
  [NotificationCode.RiskManagement_InvalidOutcomeCombination]: null,
  [NotificationCode.RiskManagement_LiveSystemNotAllowed]: null,

  [NotificationCode.PlaceBet_InternalError]: null,
  [NotificationCode.PlaceBet_UnspecifiedError]: null,
  [NotificationCode.PlaceBet_InvalidBoostus]: null,
  [NotificationCode.PlaceBet_InvalidSafeData]: null,
  [NotificationCode.PlaceBet_FreebetNotFound]: null,
  [NotificationCode.PlaceBet_NoFreebetGrantedBeforeMinElementStartDate]: null,
  [NotificationCode.PlaceBet_InsufficientAmount]: 'deposit',
  [NotificationCode.PlaceBet_InsufficientRealAmount]: 'deposit',
  [NotificationCode.PlaceBet_AmountAboveLimit]: 'settings',
  [NotificationCode.PlaceBet_WageLimitExceeded]: 'settings',
  [NotificationCode.PlaceBet_LossLimitExceeded]: 'settings',
  [NotificationCode.PlaceBet_RunningGame]: 'pendingGames',
  [NotificationCode.PlaceBet_PlayerCheckFailed]: 'helpdesk',
  [NotificationCode.PlaceBet_CreditTransferError]: 'helpdesk',
  [NotificationCode.PlaceBet_InvalidBettingSlipType]: null,
  [NotificationCode.PlaceBet_InvalidPlayer]: 'helpdesk',
  [NotificationCode.PlaceBet_InvalidElementsCount]: null,
  [NotificationCode.PlaceBet_ProviderError]: null,
  [NotificationCode.PlaceBet_PromoAttributionFailed]: null,
  [NotificationCode.PlaceBet_InvalidElementToBetOnActually]: null,
  [NotificationCode.PlaceBet_ValidationError]: 'helpdesk',
  [NotificationCode.PlaceBet_PlayerIsNotAllowedDueToGeoLocation]: 'helpdesk',
  [NotificationCode.PlaceBet_TimeSpentLimitReached]: 'settings',
};
