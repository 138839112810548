import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePageChangeCallback } from '../../../routing/hooks';
import {
  NotificationContent,
  NotificationContext,
  NotificationQueue,
  PartialNotificationContent,
} from '../../NotificationContext';
import { NotificationType } from '../../types';
import { NotificationWatcher } from '../NotificationWatcher';

/** Context provider for global notification system */
export const NotificationProvider: FC = memo(({ children }) => {
  const [notificationQueue, setNotificationQueue] = useState<NotificationQueue>(
    {
      danger: [],
      warning: null,
      success: null,
      info: null,
    },
  );
  const currentLocation = useLocation();

  const addNotification = useCallback(
    (notification: PartialNotificationContent) => {
      const newNotification: NotificationContent = {
        ...notification,
        // if there is no property 'urlToNotify' specified, we set the current URL by default
        urlToNotify: notification.urlToNotify || currentLocation.pathname,
        timestamp: new Date().getTime(),
      };
      const newNotificationType = newNotification.type;

      setNotificationQueue((previousNotificationQueue) => {
        const newNotificationObj: NotificationQueue = {
          ...previousNotificationQueue,
        };
        /**
         * If the property IS an ARRAY, we've to work with a queue system.
         * (As an example, if more than one danger notification are coming, we need to stack them)
         *
         * Else, we just erase the property value by the new notification.
         */
        if (newNotificationType === 'danger') {
          newNotificationObj[newNotificationType] = [
            ...previousNotificationQueue.danger,
            newNotification,
          ];
        } else {
          newNotificationObj[newNotificationType] = newNotification;
        }
        return newNotificationObj;
      });
    },
    [currentLocation.pathname],
  );

  const closeNotification = useCallback(
    (type: 'danger' | 'warning' | 'success' | 'info') => {
      setNotificationQueue((previousNotificationQueue) => {
        // Removes the first notification if it is an Array
        // Otherwise, remove the current notification for any other type
        const notification = previousNotificationQueue[type];
        return {
          ...previousNotificationQueue,
          [type]: Array.isArray(notification) ? notification.slice(1) : null,
        };
      });
    },
    [],
  );

  /** Remove notifications from queue when the pathname changes */
  usePageChangeCallback((pathname) => {
    setNotificationQueue((previousNotificationQueue) => {
      const newNotificationQueue = Object.entries(
        previousNotificationQueue,
      ).reduce(
        (acc, [key, value]) => {
          const notificationType = key as NotificationType;
          if (notificationType === 'danger' && Array.isArray(value)) {
            acc[notificationType] = value.filter(
              (notification) => notification.urlToNotify === pathname,
            );
          } else if (
            notificationType !== 'danger' &&
            !Array.isArray(value) &&
            value !== null &&
            value.urlToNotify !== pathname
          ) {
            acc[notificationType] = null;
          }
          return acc;
        },
        { ...previousNotificationQueue },
      );
      return newNotificationQueue;
    });
  });

  const notificationContextValue = useMemo(
    () => ({
      notificationQueue,
      addNotification,
      closeNotification,
    }),
    [addNotification, notificationQueue, closeNotification],
  );

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      <NotificationWatcher />
      {children}
    </NotificationContext.Provider>
  );
});
