import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { userLoggedInSelector } from '@gaming1/g1-core';
import {
  getFreebetConditionsForBettingSlip,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { formatForBettingSlipPreparedRequest } from '../../bettingSlip/helpers/forBettingSlip';
import { getConditionsForBettingSlipResponseCodec } from '../../bettingSlip/store/codecs/common';
import { prepareForBSGetterSelector } from '../../bettingSlip/store/selectors/gifts/prepareConditionsForBS';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import * as freebetsActions from '../../freebets/actions';
import { freebetsIsSuccessGettorSelector } from '../../freebets/selectors/common';
import { freebetsUnlockedListGettorSelector } from '../../freebets/selectors/unlocked';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

const onTimeOut = {
  retryOnTimeout: true,
  timeoutAfterMs: 10000 /* 10 sec */,
};

export const freebetConditionsForBettingSlipEpic: BettingEpic = (
  actions$,
  state$,
) =>
  actions$.pipe(
    filter(isActionOf(actions.freebetConditionsForBettingSlip.request)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const isUserLoggedIn = userLoggedInSelector(state);

      const { betPipeId, bettingSlipId, bettingSlipType } =
        extractInformationForBettingSlip(payload, state);

      if (!isUserLoggedIn || bettingSlipType === 'system') {
        return actions.freebetConditionsForBettingSlip.success(
          formatActionsData({
            data: {
              Notification: {
                Code: NotificationCode.Freebet_InternalError,
                Level: NotificationLevel.Information,
              },
              ConditionsIds: [],
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }

      const isSuccessFbRequest = freebetsIsSuccessGettorSelector(state);
      if (isSuccessFbRequest) {
        return freebetsActions.getFreebetsWithCache({
          ...payload,
          ...{ askFreebetConditions: true },
        });
      }
      return freebetsActions.getFreebets.request({
        ...payload,
        ...{ askFreebetConditions: true },
      });
    }),
  );

export const reactToFreebetSuccessActionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(
      isActionOf([
        freebetsActions.getFreebets.success,
        freebetsActions.getFreebetsWithCache,
      ]),
    ),
    withLatestFrom(state$),
    filter(([{ payload }]) => !!payload.askFreebetConditions),
    map(([{ payload }, state]) => {
      const isUserLoggedIn = userLoggedInSelector(state);

      if (!isUserLoggedIn) {
        return {
          payload,
          request: null,
          state,
          isUserLoggedIn,
        };
      }

      const { bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      const list = freebetsUnlockedListGettorSelector(state);
      const haveCondition = list?.some((item) => item.ConditionId);

      if (haveCondition) {
        return {
          payload,
          request: formatForBettingSlipPreparedRequest(
            prepareForBSGetterSelector(state)(bettingSlipId),
          ),
          state,
          isUserLoggedIn,
        };
      }
      return {
        payload,
        request: null,
        state,
        isUserLoggedIn,
      };
    }),
    mergeMap(({ payload, request, state, isUserLoggedIn }) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      if (!isUserLoggedIn) {
        return of(
          actions.freebetConditionsForBettingSlip.failure(
            formatActionsData({
              data: {
                errorMessage:
                  'betting:freebetConditions.error.userNotAuthenticated',
                status: DEFAULT_SERVER_ERROR_STATUS,
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }

      if (!request) {
        return of(
          actions.freebetConditionsForBettingSlip.success(
            formatActionsData({
              data: {
                Notification: {
                  Code: NotificationCode.Freebet_InternalError,
                  Level: NotificationLevel.Information,
                },
                ConditionsIds: [],
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }
      return wsAdapter
        .request(getFreebetConditionsForBettingSlip(request), onTimeOut)
        .pipe(
          takeUntilPipeIsCancelled(betPipeId, actions$),
          mapGuard(getConditionsForBettingSlipResponseCodec),
          map((data) => {
            if (
              data.Notification &&
              data.Notification.Code === NotificationCode.Freebet_Success
            ) {
              return actions.freebetConditionsForBettingSlip.success(
                formatActionsData({
                  data,
                  bettingSlipId,
                  betPipeId,
                }),
              );
            }
            const { Notification } = data;
            const errorMessage =
              (Notification && Notification.Message) ??
              'betting:bettingSlip.freebetConditionsForBettingSlip.noNotificationError';
            return actions.freebetConditionsForBettingSlip.failure(
              formatActionsData({
                data: {
                  errorMessage,
                  status: DEFAULT_SERVER_ERROR_STATUS,
                  ...data,
                },
                bettingSlipId,
                betPipeId,
              }),
            );
          }),
          catchError((err) =>
            of(
              actions.freebetConditionsForBettingSlip.failure(
                formatActionsData({
                  data: createFailurePayload(err, commonBettingErrorMessages),
                  bettingSlipId,
                  betPipeId,
                }),
              ),
            ),
          ),
        );
    }),
  );

export const reactToFreebetFailActionEpic: BettingEpic = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(freebetsActions.getFreebets.failure)),
    withLatestFrom(state$),
    filter(([{ payload }]) => !!payload.askFreebetConditions),
    map(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      return actions.freebetConditionsForBettingSlip.failure(
        formatActionsData({
          data: {
            errorMessage:
              'betting:bettingSlip.freebetConditionsForBettingSlip.freebetListError',
            status: DEFAULT_SERVER_ERROR_STATUS,
          },
          bettingSlipId,
          betPipeId,
        }),
      );
    }),
  );

export const reactToFreebetCancelActionEpic: BettingEpic = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(actions.freebetConditionsForBettingSlip.cancel)),
    withLatestFrom(state$),
    map(([{ payload }]) => freebetsActions.getFreebets.cancel(payload)),
  );
