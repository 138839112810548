import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as pipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import * as actions from '../actions';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const bettingslipRequestsReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.addBettingSlipOutcome):
      case getType(actions.removeBettingSlipOutcome):
      case getType(actions.toggleBettingSlipOutcome):
      case getType(pipeActions.placeBet.cancel): {
        const {
          payload: { bettingSlipId },
        } = action;

        if (!bettingSlipId) {
          break;
        }
        draftState.bettingSlips[bettingSlipId].requests.placeBet =
          generateInitialRequestState(null);

        break;
      }
      case getType(pipeActions.placeBet.request): {
        const {
          payload: { bettingSlipId },
        } = action;

        produceLoadingState(
          draftState.bettingSlips[bettingSlipId].requests.placeBet,
        );
        break;
      }
      case getType(pipeActions.placeBet.success): {
        const {
          payload: { bettingSlipId },
        } = action;

        produceSuccessState(
          draftState.bettingSlips[bettingSlipId].requests.placeBet,
        );
        break;
      }
      case getType(pipeActions.placeBet.failure): {
        const {
          payload: { bettingSlipId },
        } = action;

        produceFailureState(
          draftState.bettingSlips[bettingSlipId].requests.placeBet,
          action.payload,
        );
        break;
      }

      case getType(pipeActions.placeSystemBet.request): {
        const {
          payload: { bettingSlipId },
        } = action;

        produceLoadingState(
          draftState.bettingSlips[bettingSlipId].requests.placeBet,
        );
        break;
      }
      case getType(pipeActions.placeSystemBet.success): {
        const {
          payload: { bettingSlipId },
        } = action;

        produceSuccessState(
          draftState.bettingSlips[bettingSlipId].requests.placeBet,
        );
        break;
      }
      case getType(pipeActions.placeSystemBet.failure): {
        const {
          payload: { bettingSlipId },
        } = action;

        produceFailureState(
          draftState.bettingSlips[bettingSlipId].requests.placeBet,
          action.payload,
        );
        break;
      }

      default: // Immer will automatically return the state
    }
  });
