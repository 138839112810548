import { combineReducers } from 'redux';
import { Reducer } from 'typesafe-actions';

import { LoyaltyActions } from '../../types';
import { CommonState } from '../types';

import { commonEntitiesReducer } from './entities';

export const commonReducer: Reducer<CommonState, LoyaltyActions> =
  combineReducers({
    entities: commonEntitiesReducer,
  });
