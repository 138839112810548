import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { isDebugModeEnabledSelector } from '@gaming1/g1-core';
import {
  cashout,
  CashoutResponse,
  getCashoutInfo,
  NotificationCode,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { commonBettingErrorMessages } from '../common/store/errorMessages';
import { cashoutDebugLogs$ } from '../debugging/helpers';
import { BettingEpic } from '../store/types';

import * as actions from './actions';
import { cashoutResponseCodec, getCashoutInfoResponseCodec } from './codecs';

const onCashoutTimeOut = {
  retryOnTimeout: false,
  timeoutAfterMs: 300000 /* 5 min */,
};

export const cashoutEpic: BettingEpic = (actions$, _, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.cashout.request)),
    mergeMap(({ payload }) =>
      wsAdapter.request(cashout({ ...payload }), onCashoutTimeOut).pipe(
        mapGuard(cashoutResponseCodec),
        map((data: CashoutResponse) => {
          const { Notification } = data;
          if (
            Notification &&
            Notification.Code !== NotificationCode.Cashout_Success
          ) {
            return actions.cashout.failure({
              bettingSlipId: payload.BettingSlipId,
              status: DEFAULT_SERVER_ERROR_STATUS,
              errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
              ...Notification,
            });
          }
          return actions.cashout.success({
            ...data,
            ...{
              bettingSlipId: payload.BettingSlipId,
            },
          });
        }),
        catchError((err) =>
          of(
            actions.cashout.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              ...{
                bettingSlipId: payload.BettingSlipId,
              },
            }),
          ),
        ),
      ),
    ),
  );

export const getCashoutInfoEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getCashoutInfo.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) =>
      wsAdapter.request(getCashoutInfo({ ...payload })).pipe(
        mapGuard(getCashoutInfoResponseCodec),
        tap((data) => {
          if (isDebugModeEnabledSelector(state)) {
            cashoutDebugLogs$.next({
              time: new Date().getTime(),
              id: payload.BettingSlipId,
              info: JSON.stringify(data, null, 2),
            });
          }
        }),
        map((data) => {
          const { Notification } = data;
          if (
            Notification &&
            Notification.Code !== NotificationCode.Cashout_Success
          ) {
            return actions.getCashoutInfo.failure({
              bettingSlipId: payload.BettingSlipId,
              status: DEFAULT_SERVER_ERROR_STATUS,
              errorMessage: DEFAULT_SERVER_ERROR_MESSAGE,
              ...Notification,
            });
          }
          return actions.getCashoutInfo.success({
            ...data,
            ...{
              bettingSlipId: payload.BettingSlipId,
            },
          });
        }),
        catchError((err) =>
          of(
            actions.getCashoutInfo.failure({
              ...createFailurePayload(err, commonBettingErrorMessages),
              ...{
                bettingSlipId: payload.BettingSlipId,
              },
            }),
          ),
        ),
      ),
    ),
  );
