import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { gutter } from '@gaming1/g1-style';

type RowProps = {
  /** Fix the height to the max available. Default to true */
  fullHeight?: boolean;
  /** Manually set the row height */
  height?: string;
  /**
   * Removes the negative left and right margin (typically used with Cols with
   * the noGutter attribute)
   */
  noGutter?: boolean;
};

/** Wrapper of Col components */
export const Row = styled.div<RowProps & SpaceProps>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -${gutter};
  margin-right: -${gutter};
  position: relative;
  min-width: 100%;

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

  ${({ fullHeight = true }) =>
    fullHeight &&
    css`
      flex: 1;
      align-items: stretch;
    `}

  ${({ noGutter }) =>
    noGutter &&
    css`
      margin-left: 0;
      margin-right: 0;
    `}

  ${space}
`;
