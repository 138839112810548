import { createSelector } from 'reselect';

import {
  BettingActivity,
  CreditType,
  EBettingSlipType,
  ElementInfo,
  SelectedPromotion,
} from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { eventSelector } from '../../../../sportbook/store/selectors';
import { formatPreparedItem } from '../../../helpers/common';
import { formatRankIdAndStake } from '../../../helpers/placeBet';
import { PlaceBetRawData, PlaceSystemBetRawData } from '../../types/placeBet';
import {
  bettingSlipExtraInfoGetterSelector,
  bettingSlipItemsGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  boostusSelectedForBSGetterSelector,
  freebetSelectedForBSGetterSelector,
  promotionSelectedForBSGetterSelector,
} from '../common';
import {
  bettingSlipItemBaseOddGetterSelector,
  bettingSlipItemGetterSelector,
  bettingSlipItemProviderGetterSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
} from '../elements/bettingslipItem';
import {
  bettingSlipRanksGetterSelector,
  bettingSlipRankStakeInFloatGetterSelector,
} from '../elements/bettingslipSystem';
import { getOptimalInformationBetGetterSelector } from '../optimalBet/optimalBet';
import { bettingSlipFinalWinningsGettorSelector } from '../winnings';

export const placeBetPrepareItemGetterSelector = createSelector(
  bettingSlipItemGetterSelector,
  bettingSlipItemBaseOddGetterSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
  bettingSlipItemProviderGetterSelector,
  eventSelector,
  (
      bettingSlipItem,
      bettingSlipItemBaseOdds,
      bettingSlipItemFinalOdds,
      bettingSlipItemProvider,
      getEvent,
    ) =>
    (bettingSlipId: BettingSlipIdentifierType, itemId: number): ElementInfo => {
      const baseOdd = bettingSlipItemBaseOdds(bettingSlipId, itemId);
      const finalOdd = bettingSlipItemFinalOdds(bettingSlipId, itemId);

      const item = bettingSlipItem(bettingSlipId, itemId);

      const eventType = getEvent(item?.event?.id || 0)?.EventType;

      const provider = bettingSlipItemProvider(bettingSlipId, itemId);

      const preparedItem = formatPreparedItem({
        baseOdd,
        finalOdd,
        item,
        eventType,
        provider,
      });

      return preparedItem;
    },
);

/**
 * Selector that retrieve the items of the bettingSlip through its id {bettingSlipId} and its stake.
 * It will "prepare" (format the data) the items of the bettingSlip so they have the right properties to use afterward.
 *
 * This selector returns the betting activity, bettingslip type, the credit type, the stake of the single item, an optional freebetConditionId,
 * an optional selectedPromotion and the elements (the prepared items).
 *
 * In this case, the prepared items array will only contains one item, because the bettingslip type is a SINGLE.
 *
 * This selector has to be used only for a single.
 */
export const placeBetPrepareSingleGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  placeBetPrepareItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  bettingSlipFinalWinningsGettorSelector,
  freebetSelectedForBSGetterSelector,
  boostusSelectedForBSGetterSelector,
  promotionSelectedForBSGetterSelector,
  getOptimalInformationBetGetterSelector,
  (
      bettingSlipItems,
      prepareItem,
      bettingSlipItemStake,
      finalWinnings,
      freebetSelected,
      boostusSelected,
      promotionSelected,
      getOptimalInformation,
    ) =>
    (bettingSlipId: BettingSlipIdentifierType): PlaceBetRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const bettingActivity = BettingActivity.Sportbook;

      const bettingSlipType = EBettingSlipType.Single;

      const creditType = freebetSelected(bettingSlipId)
        ? CreditType.Freebet
        : CreditType.Money;

      const elements = items?.map((item) =>
        prepareItem(bettingSlipId, item.id),
      );

      // As we're on a single bettingSlipType, we have to get the stake through the first bettingSlip item
      const stake = items ? bettingSlipItemStake(bettingSlipId) : undefined;

      const freebetConditionId = freebetSelected(bettingSlipId)?.conditionId;

      const selectedPromotion: SelectedPromotion = {};
      const boostus = boostusSelected(bettingSlipId);
      const promotion = promotionSelected(bettingSlipId);

      const optimalBet = getOptimalInformation(bettingSlipId);

      if (boostus) {
        selectedPromotion.Boostus = {
          BoostValue: boostus.boostValue,
          BoostedWinnings: finalWinnings(bettingSlipId) || 0,
          BoostusConfigId: boostus.conditionId || '',
        };
      } else if (promotion) {
        selectedPromotion.PromotionId = promotion.id;
      }

      return {
        bettingActivity,
        bettingSlipType,
        creditType,
        stake,
        freebetConditionId,
        selectedPromotion,
        optimalBet,
        elements,
      };
    },
);

/**
 * Selector that retrieve the items of the bettingSlip through its id {bettingSlipId} and its stake.
 * It will "prepare" (format the data) the items of the bettingSlip so they have the right properties to use afterward.
 *
 * This selector returns the betting activity, bettingslip type, the credit type, the total stake of the combi, an optional freebetConditionId,
 * an optional selectedPromotion and the elements (the prepared items).
 *
 * This selector has to be used only for a combi.
 */
export const placeBetPrepareCombiGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  placeBetPrepareItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  bettingSlipFinalWinningsGettorSelector,
  freebetSelectedForBSGetterSelector,
  boostusSelectedForBSGetterSelector,
  promotionSelectedForBSGetterSelector,
  getOptimalInformationBetGetterSelector,
  (
      bettingSlipItems,
      prepareItem,
      bettingSlipCombiStakeInFloat,
      finalWinnings,
      freebetSelected,
      boostusSelected,
      promotionSelected,
      getOptimalInformation,
    ) =>
    (bettingSlipId: BettingSlipIdentifierType): PlaceBetRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const bettingActivity = BettingActivity.Sportbook;

      const bettingSlipType = EBettingSlipType.Combi;

      const creditType = freebetSelected(bettingSlipId)
        ? CreditType.Freebet
        : CreditType.Money;

      const elements = items?.map((item) =>
        prepareItem(bettingSlipId, item.id),
      );

      const stake = bettingSlipCombiStakeInFloat(bettingSlipId);

      const freebetConditionId = freebetSelected(bettingSlipId)?.conditionId;

      const selectedPromotion: SelectedPromotion = {};
      const boostus = boostusSelected(bettingSlipId);
      const promotion = promotionSelected(bettingSlipId);

      const optimalBet = getOptimalInformation(bettingSlipId);

      if (boostus) {
        selectedPromotion.Boostus = {
          BoostValue: boostus.boostValue,
          BoostedWinnings: finalWinnings(bettingSlipId) || 0,
          BoostusConfigId: boostus.conditionId || '',
        };
      } else if (promotion) {
        selectedPromotion.PromotionId = promotion.id;
      }

      return {
        bettingActivity,
        bettingSlipType,
        creditType,
        stake,
        freebetConditionId,
        selectedPromotion,
        optimalBet,
        elements,
      };
    },
);

/**
 * Selector that retrieve the items of the bettingSlip through its id {bettingSlipId} and its stake.
 * It will "prepare" (format the data) the items of the bettingSlip so they have the right properties to use afterward.
 *
 * This selector returns the betting activity, bettingslip type, the credit type, the total stake of each combinations an optional freebetConditionId,
 * an optional selectedPromotion and the elements (the prepared items).
 *
 * This selector has to be used only for a system.
 */
export const placeBetPrepareSystemGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  placeBetPrepareItemGetterSelector,
  bettingSlipRanksGetterSelector,
  bettingSlipRankStakeInFloatGetterSelector,
  (
      bettingSlipItems,
      prepareItem,
      bettingSlipSystemRanks,
      bettingSlipSystemRankStake,
    ) =>
    (bettingSlipId: BettingSlipIdentifierType): PlaceSystemBetRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const ranks = bettingSlipSystemRanks(bettingSlipId);

      const elements = items
        ? items?.map((item) => prepareItem(bettingSlipId, item.id))
        : [];

      const rankInfos = ranks
        ? ranks
            ?.filter((rank) => rank.available)
            .map((rank) => {
              const { id } = rank;
              const stakeOfGivenRank = bettingSlipSystemRankStake(
                bettingSlipId,
                id,
              );

              return formatRankIdAndStake({
                rankId: id,
                stake: stakeOfGivenRank || 0,
              });
            })
            .filter((rank) => rank.Stake > 0)
        : [];

      return {
        rankInfos,
        elements,
      };
    },
);

/**
 * Selector that retrieve the items of the bettingSlip through its id {bettingSlipId} and its stake.
 * It will "prepare" (format the data) the items of the bettingSlip so they have the right properties to use afterward.
 *
 * This selector returns the betting activity, bettingslip type, the credit type, the stake of the manuel item
 *
 * In this case, the prepared items array will only contains one item, because the bettingslip type is a SINGLE.
 *
 * This selector has to be used only for a manual bet.
 */
export const placeBetPrepareManualGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  placeBetPrepareItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  bettingSlipExtraInfoGetterSelector,
  (bettingSlipItems, prepareItem, bettingSlipItemStake, bettingSlipExtra) =>
    (bettingSlipId: BettingSlipIdentifierType): PlaceBetRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const bettingActivity = BettingActivity.Manual;

      const bettingSlipType = EBettingSlipType.Single;

      const creditType = CreditType.Money;

      const elements = items?.map((item) =>
        prepareItem(bettingSlipId, item.id),
      );

      // As we're on a single bettingSlipType, we have to get the stake through the first bettingSlip item
      const stake = items ? bettingSlipItemStake(bettingSlipId) : undefined;

      const selectedPromotion: SelectedPromotion = {};
      const configId = bettingSlipExtra(bettingSlipId)?.manualBetConfigId;
      if (configId) {
        selectedPromotion.ManualBetConfigId = configId;
      }

      return {
        bettingActivity,
        bettingSlipType,
        creditType,
        stake,
        selectedPromotion,
        elements,
      };
    },
);
