import React, { ContextType, FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  bettingActions,
  bettingUserSettingsSelector,
  getBettingUserSettingsRequestStateSelector,
  updateBettingUserSettingsRequestStateSelector,
} from '@gaming1/g1-betting';
import { BettingUserSettingsContext } from '@gaming1/g1-betting-api';
import { EBettingSlipOddChangeSettings } from '@gaming1/g1-requests-betting';
import { useRequestState } from '@gaming1/g1-store';

type UserSettingsContextValue = ContextType<typeof BettingUserSettingsContext>;
type UserSettings = NonNullable<
  UserSettingsContextValue['bettingUserSettings']
>;

/** Provide the values for the BettingUserSettingsContext in order to make
 * other feature packages access the betting settings
 */
export const BettingUserSettingsProvider: FC = ({ children }) => {
  const updateBettingUserSettingsRequestState = useSelector(
    updateBettingUserSettingsRequestStateSelector,
  );
  const { isLoading } = useRequestState(updateBettingUserSettingsRequestState);
  const getBettingUserSettingsRequestState = useSelector(
    getBettingUserSettingsRequestStateSelector,
  );
  const currentUserSettings = useSelector(bettingUserSettingsSelector);
  const dispatch = useDispatch();
  const updateBettingUserSettings = useCallback(
    (newUserSettings: Partial<UserSettings>) => {
      if (!isLoading && currentUserSettings) {
        dispatch(
          bettingActions.updateBettingUserSettings.request({
            ...currentUserSettings,
            ...newUserSettings,
            OddChangeSettings: newUserSettings.OddChangeSettings
              ? // Convert the union type to the enum
                EBettingSlipOddChangeSettings[newUserSettings.OddChangeSettings]
              : currentUserSettings.OddChangeSettings,
          }),
        );
      }
    },
    [currentUserSettings, dispatch, isLoading],
  );
  const getBettingUserSettings = useCallback(
    () => dispatch(bettingActions.getBettingUserSettings.request()),
    [dispatch],
  );
  const bettingUserSettings = useMemo(
    () =>
      currentUserSettings
        ? {
            ...currentUserSettings,
            // Convert the enum value to an union type
            OddChangeSettings: EBettingSlipOddChangeSettings[
              currentUserSettings?.OddChangeSettings
            ] as keyof typeof EBettingSlipOddChangeSettings,
          }
        : null,
    [currentUserSettings],
  );
  const contextValue: UserSettingsContextValue = useMemo(
    () => ({
      updateBettingUserSettings,
      getBettingUserSettings,
      bettingUserSettings,
      updateBettingUserSettingsRequestState,
      getBettingUserSettingsRequestState,
    }),
    [
      bettingUserSettings,
      getBettingUserSettings,
      getBettingUserSettingsRequestState,
      updateBettingUserSettings,
      updateBettingUserSettingsRequestState,
    ],
  );
  return (
    <BettingUserSettingsContext.Provider value={contextValue}>
      {children}
    </BettingUserSettingsContext.Provider>
  );
};
