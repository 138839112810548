import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  EGameSort,
  EGameTechnology,
  getPaginatedGameList,
} from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { GamingEpic } from '../../../store/types';
import * as actions from '../actions';
import {
  gamesData,
  getPaginatedGameListSuccessfulResponseCodec,
} from '../codecs';
import { gameListErrorMessages } from '../errorMessages';
import { formatGamesData } from '../formats';

export const paginatedGameListEpic: GamingEpic = (
  action$,
  _,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchGames.request)),
    withLatestFrom(config$),
    mergeMap(([action, config]) => {
      const request = action.payload;
      return wsAdapter
        .request(
          getPaginatedGameList({
            GameSort: EGameSort.New,
            GameTechnology: EGameTechnology.Unknown,
            HasRules: false,
            IncludeJackpotDetails: false,
            RoomDomainName: config.room.roomName,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(getPaginatedGameListSuccessfulResponseCodec),
          map(formatGamesData),
          mapGuard(gamesData),
          map((data) => actions.fetchGames.success({ data, request })),
          catchError((err) =>
            of(
              actions.fetchGames.failure({
                data: createFailurePayload(err, gameListErrorMessages),
                request,
              }),
            ),
          ),
        );
    }),
  );
