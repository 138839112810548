import * as t from 'io-ts';

import {
  EPasswordRecoveryStatus,
  EResetPasswordStatus,
  EValidatePasswordRecoveryStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const passwordRecoveryResponse = t.intersection([
  t.type({
    Status: createEnumType<EPasswordRecoveryStatus>(
      EPasswordRecoveryStatus,
      'EPasswordRecoveryStatus',
    ),
  }),
  t.partial({
    Message: t.string,
  }),
]);

export const passwordRecoverySuccessResponse = t.type({
  // EmailNoExists & AccountSuspended should be treated as Successful
  Status: t.union([
    t.literal(EPasswordRecoveryStatus.Successful),
    t.literal(EPasswordRecoveryStatus.EmailNoExists),
    t.literal(EPasswordRecoveryStatus.AccountSuspended),
  ]),
});

export const validatePasswordRecoverySucessResponseCodec = t.intersection([
  t.type({
    Status: t.literal(EValidatePasswordRecoveryStatus.Successful),
  }),
  t.partial({
    IsSecurityPinCodeEnabled: t.boolean,
  }),
]);

export const validatePasswordRecoveryResponse = t.intersection([
  t.type({
    Status: createEnumType<EValidatePasswordRecoveryStatus>(
      EValidatePasswordRecoveryStatus,
      'EValidatePasswordRecoveryStatus',
    ),
  }),
  t.partial({
    IsSecurityPinCodeEnabled: t.boolean,
  }),
]);

export const resetPasswordSuccessResponseCodec = t.type({
  Status: t.literal(EResetPasswordStatus.Successful),
});
