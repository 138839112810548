import styled from 'styled-components';

import { colors, fontSizes, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

const iconSize = {
  width: 65,
  height: 65,
};

export const ProfileWithRankingIconWrapper = styled(Box)`
  ${iconSize}
`;

export const SubTextStatus = styled.div`
  color: ${colors('clubStatus')};
  text-align: center;
  margin-top: ${spaces('xs')};
  font-size: ${fontSizes('md')};
`;

export const HeaderSubTextPoints = styled.span`
  font-size: ${fontSizes('md')};
  margin-top: ${spaces('xs')};
  padding-top: ${spaces('xs')};
`;

export const HeaderSubTextNextStatus = styled.div`
  font-size: ${fontSizes('sm')};
  margin-top: ${spaces('xs')};
  color: ${colors('muted')};
  padding-top: ${spaces('xs')};
`;
