import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { LegalDepositLimitState } from './types';

export const initialState: LegalDepositLimitState = {
  requests: {
    getLegalDepositLimit: generateInitialRequestState(null),
    setLegalDepositLimit: generateInitialRequestState(null),
  },
  legalDepositLimit: null,
};

export const legalDepositLimitReducer = (
  state = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getLegalDepositLimit.request):
        produceLoadingState(draftState.requests.getLegalDepositLimit);
        break;

      case getType(actions.getLegalDepositLimit.failure):
        produceFailureState(
          draftState.requests.getLegalDepositLimit,
          action.payload,
        );
        break;

      case getType(actions.getLegalDepositLimit.success):
        produceSuccessState(draftState.requests.getLegalDepositLimit);
        draftState.legalDepositLimit = action.payload;
        break;

      case getType(actions.setLegalDepositLimit.request):
        produceLoadingState(draftState.requests.setLegalDepositLimit);
        break;

      case getType(actions.setLegalDepositLimit.failure):
        produceFailureState(
          draftState.requests.setLegalDepositLimit,
          action.payload,
        );
        break;

      case getType(actions.setLegalDepositLimit.success):
        produceSuccessState(draftState.requests.setLegalDepositLimit);
        break;

      default: // Immer will automatically return the state
    }
  });
