import { reduceReducers } from '@gaming1/g1-store';

import { BettingActions } from '../../../store/types';
import { BettingSlipsState } from '../types/store';

import { boostusReducer } from './boostus';
import { boostusConditionsReducer } from './boostusConditions';
import { bettingSlipConfigurationReducer } from './configuration';
import { freebetConditionsReducer } from './freebetConditions';
import { getInitDataReducer } from './getInitData';
import { getInitialRootBettingSlipState } from './helpers';
import { bettingSlipInitReducer } from './init';
import { bettingSlipItemsReducer } from './items';
import { optimalBetReducer } from './optimalBet';
import { placeBetReducer } from './placeBet';
import { promotionReducer } from './promotion';
import { promotionsForBettingSlipReducer } from './promotionsForBettingSlip';
import { bettingSlipRanksReducer } from './ranks';
import { bettingslipRequestsReducer } from './requests';
import { riskManagementReducer } from './riskManagement';

// This reducer uses reduceReducers instead of combineReducer, meaning every
// "sub" reducer will receive the root BettingSlipsState and be called one
// after another
export const bettingSlipReducer = reduceReducers<
  BettingSlipsState,
  BettingActions
>(
  getInitialRootBettingSlipState(),
  bettingSlipInitReducer,
  bettingslipRequestsReducer,
  bettingSlipConfigurationReducer,
  getInitDataReducer,
  bettingSlipItemsReducer,
  bettingSlipRanksReducer,
  placeBetReducer,
  riskManagementReducer,
  freebetConditionsReducer,
  boostusConditionsReducer,
  boostusReducer,
  promotionsForBettingSlipReducer,
  promotionReducer,
  optimalBetReducer,
);
