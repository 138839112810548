import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { coreActions, userLoggedInSelector } from '@gaming1/g1-core';
import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import {
  AppAnchor,
  Button,
  CheckboxInput,
  Heading,
  Modal,
} from '@gaming1/g1-ui';
import {
  acceptTermsAndConditionsRequestStateSelector,
  actions,
  getTermsAndConditionsPathRequestStateSelector,
  getTermsAndConditionsPathSelector,
  showTermsAndConditionsSelector,
} from '@gaming1/g1-user';
import { usePrevious, useRequestCallback } from '@gaming1/g1-utils';

import { TermsModalContent } from './styles';

/**
 * The TermsAndConditionsModal purpose is to warn the user T&C have been updated and
 * need to be approved.
 *
 * This modal can't be closed by any other mean than to accept or refuse.
 *
 * Refusing should log the user out
 */
export const TermsAndConditionsModal: FC = () => {
  const { t } = useTranslation(['user', 'core']);

  const { user: userConfig } = useContext(ConfigContext);

  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const previousLoggedInState = usePrevious(isUserLoggedIn);

  const shouldShowTerms = useSelector(showTermsAndConditionsSelector);
  const termsPath = useSelector(getTermsAndConditionsPathSelector);
  const { isFailing, isSuccessful } = useRequestState(
    getTermsAndConditionsPathRequestStateSelector,
  );
  const {
    isLoading: isAcceptTermsAndConditionsLoading,
    status: acceptTermsAndConditionsStatus,
  } = useRequestState(acceptTermsAndConditionsRequestStateSelector);

  const handleAcceptTerms = () => {
    dispatch(actions.acceptTermsAndConditions.request());
  };

  const handleRefuseTerms = () => {
    setHasAcceptedTerms(false);
    setIsModalVisible(false);

    dispatch(coreActions.logout.request());
  };

  /** Ask if T&C has to be accepted */
  useEffect(() => {
    if (!previousLoggedInState && isUserLoggedIn) {
      dispatch(actions.showTermsAndConditions.request());
    }
  }, [dispatch, isUserLoggedIn, previousLoggedInState]);

  /** Request T&C file path if it has to be accepted */
  useEffect(() => {
    if (shouldShowTerms) {
      dispatch(
        actions.getTermsAndConditions.request({
          Key: userConfig.termsAndConditionFileKey,
        }),
      );
    }
  }, [dispatch, shouldShowTerms, userConfig]);

  /** Display modal when ready */
  useEffect(() => {
    if (shouldShowTerms && (isSuccessful || isFailing)) {
      setIsModalVisible(true);
    }
  }, [isFailing, isSuccessful, shouldShowTerms]);

  /** Close modal on accept T&C request success */
  useRequestCallback(acceptTermsAndConditionsStatus, () => {
    setIsModalVisible(false);
  });

  return (
    <Modal
      modalId="termsModal"
      onClose={() => null}
      isClosableByUser={false}
      showCloseButton={false}
      visible={isModalVisible}
    >
      <Heading level="h2" mt="0" data-testid="termsHeading">
        {t('termsAndConditions.modal.heading')}
      </Heading>

      <TermsModalContent data-testid="termsContent">
        <Trans
          i18nKey="termsAndConditions.modal.content"
          ns="user"
          components={{
            Paragraph: <p />,
            AppAnchor: (
              <AppAnchor
                data-testid="termsLink"
                href={termsPath || ''}
                rel="noopener"
                target="_blank"
              />
            ),
          }}
        />
      </TermsModalContent>

      <CheckboxInput
        checked={hasAcceptedTerms}
        data-testid="termsCheckbox"
        label={t('termsAndConditions.modal.checkbox')}
        onChange={() => {
          setHasAcceptedTerms(!hasAcceptedTerms);
        }}
      />
      <Button
        block
        disabled={!hasAcceptedTerms}
        loading={isAcceptTermsAndConditionsLoading}
        mt="lg"
        onClick={handleAcceptTerms}
        testId="termsAcceptBtn"
        type="primary"
      >
        {t('core:button.confirm')}
      </Button>
      <Button
        block
        onClick={handleRefuseTerms}
        testId="termsRefuseBtn"
        type="secondary"
        noBorder
      >
        {t('core:button.cancel')}
      </Button>
    </Modal>
  );
};
