import React, { FC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  bettingHistoryElementGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { BettingSlipType } from '@gaming1/g1-requests-betting';
import { Box, useMediaBreakPoint } from '@gaming1/g1-ui';

import { Odds } from '../../../common/components/Odds';

import { DateInfo } from './DateInfo';
import { Event } from './Event';
import { LabelInfo } from './LabelInfo';
import { StatusInfo } from './StatusInfo';
import { MarketName, OutcomeName, PlayerMarketName } from './styles';
import { ViewStyle } from './types';

type HistoryEventProps = {
  bettingHistoryId: string;
  outcomeId: number;
  type: BettingSlipType;
  view?: ViewStyle;
};
export const HistoryEvent: FC<HistoryEventProps> = ({
  bettingHistoryId,
  outcomeId,
  type,
  view = 'compact',
}) => {
  const isMobile = useMediaBreakPoint({
    max: 'sm',
  });

  const info = useBettingGetterSelector({
    getterSelector: bettingHistoryElementGetterSelector,
    args: [bettingHistoryId, outcomeId],
    equalityFn: shallowEqual,
  });

  if (!info) {
    return null;
  }

  const playerMarketName = info.PlayerName
    ? `${info.PlayerName} / ${info.TeamName}`
    : '';

  return view === 'compact' || view === 'details' ? (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        data-testid="history-event-compact"
      >
        <DateInfo info={info} view={view} />
      </Box>
      <Box flexDirection="row" justifyContent="space-between" mt="xs">
        <Box flexDirection="row" width={isMobile ? '75%' : '90%'}>
          {type === BettingSlipType.System && (
            <LabelInfo
              info={info}
              bettingHistoryId={bettingHistoryId}
              view={view}
            />
          )}
          <StatusInfo info={info} />
          <OutcomeName
            data-testid="history-element-outcome-name"
            mt={0}
            ellipsis
            view={view}
          >
            {info.OutcomeName}
          </OutcomeName>
        </Box>

        <Odds
          id="history-element"
          baseOdds={info.BaseOdds}
          finalOdds={info.FinalOdds}
        />
      </Box>

      <Box mt="xs" width="80%">
        <MarketName
          data-testid="history-element-maket-name"
          ellipsis
          mt={0}
          view={view}
        >
          {info.MarketName}
        </MarketName>
        {!!playerMarketName && (
          <PlayerMarketName ellipsis mt={0} view={view}>
            {playerMarketName}
          </PlayerMarketName>
        )}
      </Box>
      <Box>
        <Event info={info} view={view} />
      </Box>
    </>
  ) : null;
};
