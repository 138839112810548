import {
  EGetPlayerGameFeaturesStatus,
  ESponsorshipHistoryStatus,
  ShopResponseCode,
} from '@gaming1/g1-requests';

export const getGameFeatureHistoryErrorMessages = {
  [EGetPlayerGameFeaturesStatus.Unknown]: 'core:error.unknownServer', // 0
  [EGetPlayerGameFeaturesStatus.InternalError]: 'core:error.internalServer', // 1
};

export const getSponsorshipHistoryErrorMessages = {
  [ESponsorshipHistoryStatus.InternalError]: 'core:error.internalServer', // 0
  [ESponsorshipHistoryStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};

export const getShopHistoryErrorMessages = {
  [ShopResponseCode.ServerError]: 'core:error.internalServer', // 0
  [ShopResponseCode.InvalidUser]: 'core:error.invalidPlayer', // 7
};

export const getScratchCardHistoryErrorMessages = {
  [ShopResponseCode.ServerError]: 'core:error.internalServer', // 0
  [ShopResponseCode.InvalidUser]: 'core:error.invalidPlayer', // 7
};
