import * as t from 'io-ts';

import {
  EActorAuthenticationStatus,
  ETokenAuthenticationStatus,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

export const loginSuccessResponseCodec = t.type(
  {
    Status: t.literal(EActorAuthenticationStatus.Successful),
    AuthenticationToken: t.string,
    ErrorMessage: t.null,
    LastConnection: t.union([t.null, t.string]),
    SessionId: t.union([t.string, t.null]),
  },
  'LoginSuccessResponse',
);

export const loginFailureResponseCodec = t.intersection(
  [
    t.type({
      Status: createEnumType<EActorAuthenticationStatus>(
        EActorAuthenticationStatus,
        'LoginStatus',
      ),
    }),
    t.partial({
      ErrorMessage: t.union([t.null, t.string]),
      ExclusionDate: t.union([t.null, t.string]),
    }),
  ],
  'LoginFailureResponse',
);

export const sessionKeepAliveResponseCodec = t.type(
  {
    Authenticated: t.boolean,
  },
  'SessionKeepAliveResponse',
);

export const getAuthTokenSuccessResponseCodec = t.intersection(
  [
    t.type({
      Token: t.string,
    }),
    t.partial({
      BusinessSessionId: t.union([t.string, t.null]),
    }),
  ],
  'GetAuthTokenSuccessResponse',
);

export const tokenAuthenticationResponseCodec = t.intersection(
  [
    t.type({ Status: t.literal(ETokenAuthenticationStatus.Successful) }),
    t.partial({ Message: t.string }),
  ],
  'TokenAuthenticationSuccessResponse',
);

export const logoutInjectedRequestCodec = t.type(
  {
    RedirectionPage: t.literal('Logout'),
  },
  'LogoutInjectedResponse',
);

export const impersonateUserSuccessResponseCodec = t.intersection(
  [
    t.type({
      Success: t.literal(true),
      AuthenticationToken: t.string,
    }),
    t.partial({
      BusinessSessionId: t.string,
    }),
  ],
  'ImpersonateUserSuccessResponse',
);
