import React, { FC } from 'react';
import { Form } from 'react-final-form';

import { UserField } from '@gaming1/g1-core';
import { PinCodeFormProps } from '@gaming1/g1-user-api';

import { PinCode } from '../PinCode';

/** Multifactor authentication pin code form */
export const PinCodeForm: FC<PinCodeFormProps> = ({
  onFocus,
  onSubmit,
  ...rest
}) => (
  <Form
    onSubmit={({ [UserField.pinCode]: pinCode, newPinCode }) =>
      onSubmit(pinCode, newPinCode)
    }
    render={({ handleSubmit }) => (
      <form onFocus={onFocus} onSubmit={handleSubmit}>
        <PinCode {...rest} />
      </form>
    )}
  />
);
