import React, { FC, memo, useContext } from 'react';

import {
  useBettingSlipSingleFinalWinning,
  useBettingSlipSingleWinning,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useFormatMoney } from '@gaming1/g1-core';

import { showValueBeforeAndAfterBoostus } from '../../../boostus/helpers';
import { BettingSlipContext } from '../../BettingSlipContext';

import { PotWinningContainer, PrevPotWinningContainer } from './styles';

/**
 * Display the ticket item stake and pot winning information
 * a item (base on outcome) select by user and save into store
 */
const BettingSlipSinglePotWinningComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const formatMoney = useFormatMoney();
  const config = useConfig();

  // before applying boostus
  const potWinning = useBettingSlipSingleWinning(bettingSlipId);
  // after applying boostus
  const finalPotWinning = useBettingSlipSingleFinalWinning(bettingSlipId);

  if (!potWinning) {
    return null;
  }

  const formattedPotWinning = formatMoney(potWinning);
  const formattedFinalPotWinning = finalPotWinning
    ? formatMoney(finalPotWinning)
    : undefined;

  const showPrevPotWinning = showValueBeforeAndAfterBoostus({
    bettingConfig: config.betting,
    valueType: 'potentialWinnings',
    baseValue: potWinning,
    finalValue: finalPotWinning,
  });

  return showPrevPotWinning ? (
    <span>
      <PrevPotWinningContainer data-testid="potential-winning">
        {formattedPotWinning}
      </PrevPotWinningContainer>
      <PotWinningContainer data-testid="final-potential-winning">
        {formattedFinalPotWinning}
      </PotWinningContainer>
    </span>
  ) : (
    <PotWinningContainer data-testid="final-potential-winning">
      {formattedFinalPotWinning}
    </PotWinningContainer>
  );
};

export const BettingSlipSinglePotWinning = memo(
  BettingSlipSinglePotWinningComponent,
);
