import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { getPendingGames } from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import { getPendingGamesResponse } from './codecs';
import { getPendingGameErrorMessages } from './errorMessages';

/** Request a list of pending games of the user */
export const getPendingGamesEpic: GamingEpic = (
  action$,
  state$,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPendingGames.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      wsAdapter
        .request(
          getPendingGames({
            LanguageCode: currentWebSocketLocaleSelector(state),
          }),
        )
        .pipe(
          mapGuard(getPendingGamesResponse),
          map(actions.getPendingGames.success),
          catchError((err) =>
            of(
              actions.getPendingGames.failure(
                createFailurePayload(err, getPendingGameErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
