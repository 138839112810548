import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as actions from './actions';
import { FavoriteRecentGamesState } from './types';

export const initialState: FavoriteRecentGamesState = {
  requests: {
    getFavoriteRecentGames: generateInitialRequestState([]),
    setFavoriteGameList: generateInitialRequestState(null),
  },
};

export const favoriteRecentGamesReducer = (
  state: FavoriteRecentGamesState = initialState,
  action: GamingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchFavoriteAndRecentGames.request):
        produceLoadingState(draftState.requests.getFavoriteRecentGames);
        break;

      case getType(actions.fetchFavoriteAndRecentGames.failure):
        produceFailureState(
          draftState.requests.getFavoriteRecentGames,
          action.payload,
        );
        break;

      case getType(actions.fetchFavoriteAndRecentGames.success):
        produceSuccessState(
          draftState.requests.getFavoriteRecentGames,
          action.payload.result,
        );
        break;

      case getType(actions.setFavoriteGame.request):
        produceLoadingState(draftState.requests.setFavoriteGameList);
        break;

      case getType(actions.setFavoriteGame.failure):
        produceFailureState(
          draftState.requests.setFavoriteGameList,
          action.payload,
        );
        break;

      case getType(actions.setFavoriteGame.success):
        produceSuccessState(draftState.requests.setFavoriteGameList);
        break;

      default: // Immer will automatically return the state
    }
  });
