import uniqBy from 'lodash/uniqBy';

import { EFieldRegisterPlayerProperty } from '@gaming1/g1-requests';
import { isCodecGuardError } from '@gaming1/g1-utils';

import { convertPlayerPropertyToFieldName } from '../../common/helpers/form';

import { playerRegistrationFailureResponse } from './codecs';
import { registrationFieldErrorMessages } from './errorMessages';
import {
  GetDepartmentsAndMunicipalitiesResponse,
  GetMunicipalitiesResponse,
  RegistrationFormFieldsErrors,
} from './types';

const getFieldErrorMessage = (
  fieldError: EFieldRegisterPlayerProperty,
): string | undefined => {
  if (
    fieldError === EFieldRegisterPlayerProperty.Valid ||
    fieldError === EFieldRegisterPlayerProperty.Denied
  ) {
    return undefined;
  }
  return registrationFieldErrorMessages[fieldError];
};

/**
 * Extract registrtion form fields errors from a registration failure action
 * payload
 */
export const extractRegisrationValidationErrors = (
  error: unknown,
): RegistrationFormFieldsErrors | undefined => {
  if (
    isCodecGuardError(error) &&
    playerRegistrationFailureResponse.is(error.value) &&
    error.value.FieldErrors
  ) {
    return error.value.FieldErrors.reduce<RegistrationFormFieldsErrors>(
      (acc, fieldError) => {
        const userFieldName = convertPlayerPropertyToFieldName(
          fieldError.PropertyPropertyValue,
        );
        if (userFieldName) {
          acc[userFieldName] =
            /* Use the backend message if any (it is often more precise, e.g.
            "This e-mail is already used") */
            fieldError.Message ||
            /* Use a frontend translation using the enum in the FieldError
            prop */
            getFieldErrorMessage(fieldError.FieldError) ||
            /* As a fallback use a generic error message to avoid blank errors
             */
            registrationFieldErrorMessages[
              EFieldRegisterPlayerProperty.Invalid
            ];
        }
        return acc;
      },
      {},
    );
  }
  return undefined;
};

export const formatDepartmentsAndMunicipalities = (
  data: GetMunicipalitiesResponse,
): GetDepartmentsAndMunicipalitiesResponse => ({
  ...data,
  DepartmentsAndMunicipalities: uniqBy(data.Municipalities, 'DepartmentName')
    .sort((a, b) => a.DepartmentName.localeCompare(b.DepartmentName))
    .map((department) => ({
      DepartmentCode: department.DepartmentCode,
      DepartmentName: department.DepartmentName,
      Municipalities: uniqBy(
        data.Municipalities.filter(
          (municipality) =>
            municipality.DepartmentName === department.DepartmentName,
        ),
        'MunicipalityName',
      )
        .sort((a, b) => a.MunicipalityName.localeCompare(b.MunicipalityName))
        .map((municipality) => ({
          MunicipalityCode: municipality.MunicipalityCode,
          MunicipalityName: municipality.MunicipalityName,
          ZipCodes: data.Municipalities.filter(
            (m) => m.MunicipalityName === municipality.MunicipalityName,
          )
            .map((mun) => mun.ZipCode)
            .sort((a, b) => a.localeCompare(b)),
        })),
    })),
});
