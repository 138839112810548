import {
  ScoreBoardPeriodType,
  ScoreBoardSportType,
} from '@gaming1/g1-requests-betting';

import { ScoreBoardSportId, ScoreBoardType } from '../../types';

import {
  FALLBACK_TEMPLATES,
  PARSER_TEMPLATES,
  SPORT_TEMPLATES,
} from './constants';

/**
 * Returns the appropriate template for the scoreboard (based on parameters).
 */
export const getScoreBoardTemplate = (
  sportId: ScoreBoardSportId,
  sportParser: ScoreBoardSportType,
  periodType: ScoreBoardPeriodType,
  type: ScoreBoardType,
) => {
  const SportTemplate = SPORT_TEMPLATES[sportId]?.[type];
  const ParserTemplate = PARSER_TEMPLATES[sportParser]?.[type];
  const FallbackTemplate = FALLBACK_TEMPLATES[periodType]?.[type];

  return SportTemplate || ParserTemplate || FallbackTemplate || null;
};
