import { createContext, VFC } from 'react';

const EmptyComponent: VFC = () => null;

/** Context containing public betting components
 * - Promotions: Betting retention promotions
 */
export const BettingPublicComponentsContext = createContext<{
  Promotions: VFC;
}>({
  Promotions: EmptyComponent,
});
