import React, { VFC } from 'react';

import { ScoreBoardType } from '../../types';
import { ScoreBoardTemplateManager } from '../ScoreBoardTemplateManager';

type ScoreBoardProps = {
  /** Event id that will be used to retrieve the scoreboard */
  eventId: number;
  /** Specifies the type of the scoreboard (default: 'compact') */
  type?: ScoreBoardType;
};

/** Displays the scoreboard for a specific event */
export const ScoreBoard: VFC<ScoreBoardProps> = ({
  eventId,
  type = 'compact',
}) => <ScoreBoardTemplateManager eventId={eventId} type={type} />;
