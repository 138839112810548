import styled, { css } from 'styled-components';

import { colors, elevation, fontSizes, spaces } from '@gaming1/g1-style';
import { Box, Icon } from '@gaming1/g1-ui';

import { MenuItem } from '../../../common/components/MenuItem';

const ACTIVE_BORDER_WIDTH_IN_PX = 3;

export const RegionMenuItemContainer = styled(MenuItem)<{ isActive: boolean }>`
  position: relative;
  margin-bottom: 1px;
  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        content: '';
        display: flex;
        width: ${ACTIVE_BORDER_WIDTH_IN_PX}px;
        height: 100%;
        position: absolute;
        background-color: ${colors('primary')};
        left: 0;
      }
    `}
`;

export const LeagueMenuContainer = styled(Box)`
  padding-top: ${spaces('xs')};
  padding-bottom: ${spaces('xs')};
  ${elevation(1, { noShadow: true })};
  width: 100%;
`;

export const MenuIcon = styled(Icon)`
  font-size: ${fontSizes('lg')};
`;
