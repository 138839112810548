import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { createFailurePayload, encodeQuery, mapGuard } from '@gaming1/g1-utils';

import { COMMON_PRISMIC_AJAX_CONFIG } from '../../common/constants';
import { getPrismicLocale, prismicQueryToString } from '../../helpers';
import { CMSEpic } from '../../store/types';

import * as actions from './actions';
import { navigationQueryResponse } from './codecs';

export const navigationQueryEpic: CMSEpic = (
  actions$,
  store$,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchNavigations.request)),
    withLatestFrom(config$, store$),
    switchMap(([action, config, store]) =>
      ajaxFetch(
        `${config.network.prismicUrl}/documents/search?${encodeQuery({
          lang: getPrismicLocale(store.core.i18n.webSocketLocale),
          q: prismicQueryToString({
            path: 'document.type',
            predicate: 'at',
            value: 'navigation',
          }),
          ref: action.payload,
        })}`,
        COMMON_PRISMIC_AJAX_CONFIG,
      ).pipe(
        mapGuard(navigationQueryResponse),
        map(actions.fetchNavigations.success),
        catchError((err) =>
          of(
            actions.fetchNavigations.failure(
              createFailurePayload(
                err,
                undefined,
                true,
                'core:error.fetchPrismicNavigation',
              ),
            ),
          ),
        ),
      ),
    ),
  );
