import { ComponentProps, useState, VFC } from 'react';

import { Label, Select } from '@gaming1/g1-ui';

import { RowFilterFunc, RowFilterHook } from '../types';

type PacketType = 'all' | 'requests' | 'other';
const packetTypes: PacketType[] = ['all', 'requests', 'other'];

const filterByPacketType =
  (packetType: PacketType): RowFilterFunc =>
  ({ type }) =>
    packetType === 'all' ||
    (packetType === 'other' && type.indexOf('equest') === -1) ||
    (packetType === 'requests' && type.indexOf('equest') !== -1);

type PacketTypeFilterProps = {
  currentPacketType: PacketType;
  onPacketTypeChange: (packetType: string) => void;
};

const isPacketType = (input: string): input is PacketType =>
  packetTypes.includes(input as PacketType);

const packetTypeOptions: ComponentProps<typeof Select>['options'] =
  packetTypes.map((type) => ({
    label: type,
    value: type,
  }));

const PacketTypeFilter: VFC<PacketTypeFilterProps> = ({
  currentPacketType,
  onPacketTypeChange,
}) => (
  <>
    <Label htmlFor={null}> Filter by packet type:</Label>
    <Select
      options={packetTypeOptions}
      value={currentPacketType}
      onValueChange={onPacketTypeChange}
      wrapperProps={{ mt: 0 }}
    />
  </>
);

export const useFilterByPacketType: RowFilterHook = () => {
  const [selectedPacketType, setSelectedPacketType] =
    useState<PacketType>('all');

  const managePacketTypeChange = (input: string) => {
    if (isPacketType(input)) {
      setSelectedPacketType(input);
    }
  };

  const component = (
    <PacketTypeFilter
      currentPacketType={selectedPacketType}
      onPacketTypeChange={managePacketTypeChange}
    />
  );

  const filter = filterByPacketType(selectedPacketType);

  return {
    filter,
    component,
  };
};
