import React, { VFC } from 'react';

import { PromotionInfoType } from '@gaming1/g1-betting';
import { useRoutePath } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { GiftType, PromotionState } from '@gaming1/g1-requests-betting';
import { Box, Text } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../../common/components/BettingIcon';
import { bettingRoutes } from '../../../../routes';
import { OpacityContainer } from '../../styles';

import { CallToActionLink, HistoryExpandedGiftContainer } from './styles';

type ExpandedExtraInfoPromotionProps = {
  promotion: PromotionInfoType;
};
export const ExpandedExtraInfoPromotion: VFC<
  ExpandedExtraInfoPromotionProps
> = ({ promotion }) => {
  const { t } = useTranslation('betting');
  const getBettingPath = useRoutePath(bettingRoutes);

  return promotion ? (
    <HistoryExpandedGiftContainer>
      {promotion.State !== PromotionState.NotValidated ? (
        <BettingIcon
          id="PromotionsGradient"
          type="PromotionsGradient"
          width={20}
          height={20}
        />
      ) : (
        <OpacityContainer data-testid="invalid-icon-promotion-container">
          <BettingIcon
            id="PromotionsGradient"
            type="PromotionsGradient"
            width={20}
            height={20}
          />
        </OpacityContainer>
      )}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text ml="xxs" mt={0} fontSize="md" mr="md">
          {promotion?.Name}
        </Text>
        {promotion.State === PromotionState.Validated &&
          promotion.GiftType === GiftType.Freebet && (
            <CallToActionLink
              mt={0}
              fontSize="md"
              to={getBettingPath('myUnlockedGifts')}
            >
              {t('promotion.history.freebetgift')}
            </CallToActionLink>
          )}
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
