import { readFrom } from '@gaming1/g1-utils';

import { CustomTheme } from './types';

const isPartialTheme = (theme: unknown): theme is CustomTheme => {
  if (typeof theme !== 'object' || !theme) {
    return false;
  }
  if (
    !('name' in theme) ||
    ('name' in (theme as CustomTheme) &&
      typeof (theme as CustomTheme).name !== 'string')
  ) {
    return false;
  }
  return true;
};

export const getSavedThemes = () => {
  const themes = readFrom(localStorage, 'themes');
  return Array.isArray(themes) ? themes.filter(isPartialTheme) : [];
};

export const getSavedTheme = (name: string) =>
  getSavedThemes().find((theme) => theme.name === name);
