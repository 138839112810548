import React, { VFC } from 'react';

import { geolocationRequestStateSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import { Button, Heading } from '@gaming1/g1-ui';

type GeolocationModalLocationPermissionDeniedContentProps = {
  /** Callback for the dismiss button click */
  onCancelClick: () => void;
  /** Callback for the "open phone settings" button */
  onOpenAppSettings: () => void;
  /** Callback for the retry button click */
  onRetryClick: () => void;
};

/** Content shown in the modal when
 * - the user is using the native app (getWrapperEnv() === 'rn')
 * - Geolocation request has failed and its error code is GeolocationCustomErrorCode.LOCATION_PERMISSION_DENIED (704)
 */
export const GeolocationModalLocationPermissionDeniedContent: VFC<
  GeolocationModalLocationPermissionDeniedContentProps
> = ({ onCancelClick, onOpenAppSettings, onRetryClick }) => {
  const { t } = useTranslation('core');
  const { isLoading } = useRequestState(geolocationRequestStateSelector);

  return (
    <>
      <Heading level="h2" justifyContent="center" levelStyle="h3" mt="md">
        {t('geocomply.modal.locationPermissionDenied.title')}
      </Heading>

      <Heading level="h3" justifyContent="center" levelStyle="h4" mt="md">
        {t('geocomply.modal.locationPermissionDenied.text')}
      </Heading>

      <Button
        type="primary"
        data-testid="geolocation-modal-open-settings-button"
        mt="xxl"
        onClick={onOpenAppSettings}
      >
        {t('geocomply.modal.locationPermissionDenied.openSettings')}
      </Button>

      <Button
        type="secondary"
        data-testid="geolocation-modal-retry-button"
        mt="xs"
        loading={isLoading}
        onClick={onRetryClick}
      >
        {t('geocomply.modal.technicalIssue.tryAgainButton')}
      </Button>

      <Button
        type="tertiary"
        data-testid="geolocation-modal-dismiss-button"
        mt="xs"
        onClick={onCancelClick}
      >
        {t('geocomply.modal.dismiss')}
      </Button>
    </>
  );
};
