import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { PhoneValidationState } from './types';

export const initialState: PhoneValidationState = {
  sendPhoneValidationCode: null,
  validatePhoneCode: null,
  requests: {
    sendPhoneValidationCode: generateInitialRequestState(null),
    validatePhoneCode: generateInitialRequestState(null),
  },
};

export const phoneValidationReducer = (
  state: PhoneValidationState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.sendPhoneValidationCode.request):
        produceLoadingState(draftState.requests.sendPhoneValidationCode);
        break;
      case getType(actions.validatePhoneCode.request):
        produceLoadingState(draftState.requests.validatePhoneCode);
        break;

      /** Failures */
      case getType(actions.sendPhoneValidationCode.failure):
        produceFailureState(
          draftState.requests.sendPhoneValidationCode,
          action.payload,
        );
        break;
      case getType(actions.validatePhoneCode.failure):
        produceFailureState(
          draftState.requests.validatePhoneCode,
          action.payload,
        );
        break;

      /** Successes */
      case getType(actions.sendPhoneValidationCode.success):
        produceSuccessState(draftState.requests.sendPhoneValidationCode);
        draftState.sendPhoneValidationCode = action.payload;
        break;
      case getType(actions.validatePhoneCode.success):
        produceSuccessState(draftState.requests.validatePhoneCode);
        draftState.validatePhoneCode = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
