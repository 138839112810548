// TODO: those enums should be provided by the back-end

export enum DepositHistoryTransactionState {
  Accepted = 10,
  Pending = 20,
  Cancelled = 30,
  Refused = 40,
}

export enum WithdrawalHistoryTransactionState {
  Accepted = 10,
  Pending = 20,
  Cancelled = 30,
  Refused = 40,
}
