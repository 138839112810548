import React, { FC } from 'react';

import { BettingHistoryTicketType } from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  AssociatedPromoType,
  CreditType,
  PromotionState,
} from '@gaming1/g1-requests-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../../common/components/BettingIcon';
import { GiftLabel } from '../../../../common/components/GiftLabel';
import { OpacityContainer } from '../../styles';

/**
 * Will check the given {ticket} and check if it has a boostus, a freebet or a daily offer associated promotion.
 *
 * If it contains a Boostus => We display the Icon of the Boostus;
 * If it contains a INVALID Boostus => We display the Icon of the Boostus with an opacity;
 * If it contains a Freebet => We display the Icon of the Freebet;
 * If it contains a Daily Offer => We display the Icon of the Daily Offers
 * If none of the above => We return null
 */

export type HistoryCardHeaderPromotionIconProps = {
  historyTicket: BettingHistoryTicketType;
};

export const HistoryCardHeaderPromotionIcon: FC<
  HistoryCardHeaderPromotionIconProps
> = ({ historyTicket }) => {
  const { t } = useTranslation('betting');

  // We check if the ticket has a Boostus and if it's valid
  if (historyTicket.AssociatedPromotion?.Boostus) {
    if (historyTicket.AssociatedPromotion?.Boostus.IsValid) {
      return <BettingIcon id="BoostusLogo" type="BoostusLogo" ml="xs" />;
    }
    return (
      <OpacityContainer data-testid="header-boostus-invalid-container">
        <BettingIcon id="BoostusLogo" type="BoostusLogo" ml="xs" />
      </OpacityContainer>
    );
  }

  if (
    historyTicket.AssociatedPromotion?.Types?.includes(
      AssociatedPromoType.Promotion,
    )
  ) {
    if (
      historyTicket.AssociatedPromotion?.Promotion &&
      historyTicket.AssociatedPromotion?.Promotion.State !==
        PromotionState.NotValidated
    ) {
      return (
        <Box ml="xs">
          <BettingIcon type="PromotionsTag" id="PromotionsTag" />
        </Box>
      );
    }
    return (
      <Box ml="xs">
        <OpacityContainer data-testid="header-promotion-invalid-container">
          <BettingIcon type="PromotionsTag" id="PromotionsTag" />
        </OpacityContainer>
      </Box>
    );
  }

  if (historyTicket.CreditType === CreditType.Freebet) {
    return (
      <BettingIcon
        type="FreebetSquare"
        id="FreebetSquare"
        ml="xs"
        width={20}
        height={20}
      />
    );
  }

  if (
    historyTicket.AssociatedPromotion?.Types?.includes(
      AssociatedPromoType.DailyOffer,
    )
  ) {
    return (
      <Box ml="xs">
        <BettingIcon type="DailyOfferLabel" id="BestOddsTag" />
      </Box>
    );
  }

  if (
    historyTicket.AssociatedPromotion?.Types?.includes(
      AssociatedPromoType.SuperOdds,
    )
  ) {
    return (
      <Box ml="xs">
        <BettingIcon type="SuperOddsGradientIcon" id="SuperOddsTag" />
      </Box>
    );
  }

  if (historyTicket.OptimalBetInformation) {
    if (historyTicket.OptimalBetInformation.OptimalBetApplied) {
      return (
        <Box ml="xs">
          <GiftLabel type="optiOdds" name={t('history.optiOdds.status')} />
        </Box>
      );
    }
    return (
      <Box ml="xs">
        <OpacityContainer data-testid="header-optimalodds-invalid-container">
          <GiftLabel type="optiOdds" name={t('history.optiOdds.status')} />
        </OpacityContainer>
      </Box>
    );
  }

  return null;
};
