import { opacify } from 'polished';
import styled, { css } from 'styled-components';
import { color, fontSize, space, width } from 'styled-system';

import { colors, fontSizes, spaces, Theme, zIndex } from '@gaming1/g1-style';

import { AppLabel } from '../Label';
import { SimpleButton } from '../styles';
import { StyledSystemProps } from '../types';

const TOGGLE_ICON_WIDTH = '1.5em';

type BaseTextInputProps = {
  /**
   * Whether the input should take the whole width available.
   * Default: false
   */
  block?: boolean;
  /**
   * Whether the input should show an erroneous state.
   * Default: false
   */
  error?: boolean;
  /**
   * Color of the highlight used when the input is on error.
   * Default: danger
   */
  highlightColor?: keyof Theme['colors'];
  /**
   * Whether to return a simple input or the full container with the errors.
   * Default: false
   */
  simple?: boolean;
};

/**
 * Basic properties that will be inherited by other types of text inputs
 */
export const BaseTextInput = styled.input<BaseTextInputProps>`
  /* Must be at least 16px for iOS to stop zooming on focus */
  font-size: ${fontSizes('lg')};
  padding: ${spaces('sm')} 0;
  color: ${colors('text')};
  background-color: transparent;
  border-color: ${colors('inputBorder')};
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors('label')};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }

  ${({ error, highlightColor }) =>
    error &&
    css`
      border-color: ${colors(highlightColor || 'danger')};
    `}

  ${({ block }) =>
    block &&
    css`
      display: block;
      width: 100%;
    `}
`;

export const SimpleTextInput = styled(BaseTextInput)<StyledSystemProps>`
  /* Must be at least 16px for iOS to stop zooming on focus */
  ${space}
  ${width}
  ${fontSize}
  ${color}
`;

type TextInputWrapperDivProps = {
  /**
   * Whether the input should show a button on the right or not.
   * Default: false
   */
  button?: boolean;
  /**
   * If we need to have a background
   * Default: false
   * */
  hasBackground?: boolean;
  /**
   * Whether the input should have a full border or just a border bottom.
   * Default: false
   */
  hasBorder?: boolean;
  /**
   * Color of the highlight used when the input is on error.
   * Default: danger
   */
  highlightColor?: keyof Theme['colors'];
};

export const AppTextInput = styled(BaseTextInput)<TextInputWrapperDivProps>`
  background-color: transparent;

  ${({ hasBorder }) =>
    hasBorder
      ? css`
          border: 1px solid ${colors('inputBorder')};
          text-align: center;
          padding-right: ${spaces('sm')};
          &:focus {
            padding-bottom: calc(${spaces('sm')} - 2px);
          }
        `
      : css`
          border: none;
          border-bottom: 1px solid ${colors('inputBorder')};
          &:focus {
            padding-bottom: calc(${spaces('sm')} - 1px);
          }
        `};

  &:focus {
    border-width: 2px;
    border-color: ${colors('inputActive')};
  }

  ${({ error, highlightColor }) =>
    error &&
    css`
      &,
      &:focus {
        border-color: ${colors(highlightColor || 'danger')};
      }
    `}

  ${({ hasBackground }) =>
    hasBackground &&
    css`
      background-color: ${colors('inputBackground')};
      border-radius: 4px;
      border-color: transparent;
      height: 48px;

      &:focus {
        border-color: transparent;
      }
    `};

  ${({ error, hasBackground, highlightColor }) =>
    hasBackground &&
    error &&
    css`
      color: ${colors(highlightColor || 'danger')};
      &,
      &:focus {
        border: 1px solid ${colors(highlightColor || 'danger')};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `};

  ${({ button }) =>
    button &&
    css`
      padding-right: calc(${TOGGLE_ICON_WIDTH} + ${spaces('xs')});
    `}
`;

/**
 * The z-index property is set to a number bigger than the TextInputLabelSpan styled component
 * in order to fix a weird bug happening on iOS only which causes, in some cases,
 * the field not to be skippable when using the iOS native keyboard arrows.
 *
 * This apparently happens only on fields with a label bigger than other field's labels.
 * For instance : "Date of birth (dd/mm/yyyy)" vs "Postcode".
 *
 * TODO : find why this happens and maybe a better fix than this one.
 */
export const InputSpan = styled.span`
  display: block;
  position: relative;
  z-index: ${zIndex(0, 4)}; /** One more than input labels */
`;

type LabelSpanProps = {
  asPlaceholder?: boolean;
  error?: boolean;
  focused?: boolean;
  hidden?: boolean;
  /**
   * Color of the highlight used when the label is on error.
   * Default: danger
   */
  highlightColor?: keyof Theme['colors'];
};

export const TextInputLabelSpan = styled(AppLabel)<LabelSpanProps>`
  /* Must be the same as the input */
  font-size: ${fontSizes('lg')};
  display: block;
  position: absolute;
  top: ${spaces('xs')};
  left: 0;
  color: ${({ theme }) =>
    theme.dark ? theme.colors.label : theme.colors.neutrals[4]};

  transition: color 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, 0) scale(0.85);
  transform-origin: top left;
  z-index: ${zIndex(0, 3)};

  ${({ focused }) =>
    focused &&
    css`
      color: ${colors('inputActive')};
    `};

  ${({ error, highlightColor }) =>
    error &&
    css`
      color: ${colors(highlightColor || 'danger')};
    `};

  ${({ asPlaceholder }) =>
    asPlaceholder &&
    css`
      transform: translate(0, 24px) scale(1);
    `};

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;

export const TextInputLabel = styled.label`
  display: block;
  padding-top: ${fontSizes('lg')};
`;

const sideButtonStyle = css`
  color: ${({ theme }) => theme.colors.neutrals[theme.dark ? 2 : 3]};
  font-size: ${TOGGLE_ICON_WIDTH};
  height: 100%;
  min-width: ${TOGGLE_ICON_WIDTH};
  padding-top: 5px;
  position: absolute;
  top: 0px;
  z-index: 0;
`;

type SideIconButtonProps = {
  error?: boolean;
  highlightColor?: keyof Theme['colors'];
  removePaddingTop?: boolean;
  side: 'left' | 'right';
};

export const SideIconButton = styled(SimpleButton).attrs({
  type: 'button',
})<SideIconButtonProps>`
  ${sideButtonStyle}

  ${({ side }) =>
    css`
      ${side}: ${spaces('xxs')}
    `};

  ${({ removePaddingTop }) =>
    removePaddingTop &&
    css`
      padding-top: 0;
    `}

  &:focus:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    margin-top: calc(${TOGGLE_ICON_WIDTH} / 2 * -1);
    width: ${TOGGLE_ICON_WIDTH};
    height: ${TOGGLE_ICON_WIDTH};
    background-color: ${colors('primary', { transform: opacify(-0.6) })};
    border-radius: 100%;
  }

  ${({ error, highlightColor }) =>
    error &&
    css`
      svg path {
        fill: ${colors(highlightColor || 'danger')};
      }
    `};
`;
