import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  GetDepositNextStepRequestDTO,
  GetDepositStatusRequestDTO,
  GetDepositWalletInfoRequestDTO,
  MakeVoucherDepositRequestDTO,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  FindDepositsResponse,
  GetDepositNextStepResponse,
  GetDepositStatusResponse,
  GetDepositWalletInfoResponse,
  GetDepositWalletListResponse,
  MakeDepositRequest,
  MakeDepositResponse,
} from './types';

export const startAskingDepositStatus = createAction(
  'payment/start_asking_deposit_status',
)<{
  depositId: string;
  depositStatus: GetDepositStatusResponse | null;
}>();
export const stopAskingDepositStatus = createAction(
  'payment/stop_asking_deposit_status',
)<void>();

/** Clean get deposit status request state */
export const cleanGetDepositStatus = createAction(
  'payment/clean_get_deposit_status',
)();

/* Async actions */

/** Get deposit wallets */
export const findDeposits = createAsyncAction(
  'payment/find_deposits_info_request',
  'payment/find_deposits_info_success',
  'payment/find_deposits_info_failure',
)<void, FindDepositsResponse, FailurePayload>();

/** Get the deposit status */
export const getDepositStatus = createAsyncAction(
  'payment/get_deposit_status_request',
  'payment/get_deposit_status_success',
  'payment/get_deposit_status_failure',
)<GetDepositStatusRequestDTO, GetDepositStatusResponse, FailurePayload>();

/** Make a deposit */
export const makeDeposit = createAsyncAction(
  'payment/make_deposit_request',
  'payment/make_deposit_success',
  'payment/make_deposit_failure',
)<MakeDepositRequest, MakeDepositResponse, FailurePayload>();

/** Clean make deposit request state */
export const cleanMakeDeposit = createAction('payment/clean_make_deposit')();

/** Make a voucher deposit */
export const makeVoucherDeposit = createAsyncAction(
  'payment/make_voucher_deposit_request',
  'payment/make_voucher_deposit_success',
  'payment/make_voucher_deposit_failure',
)<MakeVoucherDepositRequestDTO, MakeDepositResponse, FailurePayload>();

/** Get deposit wallets list */
export const getDepositWalletList = createAsyncAction(
  'payment/get_deposit_wallet_list_request',
  'payment/get_deposit_wallet_list_success',
  'payment/get_deposit_wallet_list_failure',
)<void, GetDepositWalletListResponse, FailurePayload>();

/** Get deposit wallet details */
export const getDepositWalletInfo = createAsyncAction(
  'payment/get_deposit_wallet_info_request',
  'payment/get_deposit_wallet_info_success',
  'payment/get_deposit_wallet_info_failure',
)<
  GetDepositWalletInfoRequestDTO,
  GetDepositWalletInfoResponse,
  FailurePayload
>();

export const startAskingNextDepositStep = createAction(
  'payment/start_asking_next_deposit_step',
)<{
  depositId: string;
}>();
export const stopAskingNextDepositStep = createAction(
  'payment/stop_asking_next_deposit_step',
)<void>();

export const getDepositNextStep = createAsyncAction(
  'payment/get_deposit_next_step_request',
  'payment/get_deposit_next_step_success',
  'payment/get_deposit_next_step_failure',
)<GetDepositNextStepRequestDTO, GetDepositNextStepResponse, FailurePayload>();
