import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { ScrollableListContext } from './context';
import { curryScrollhandler, getElementWidth } from './helpers';
import { ListDetails, ScrollHandlers } from './types';

const convertMarginToValue = (marginString: string): number =>
  Number(marginString.slice(0, marginString.length - 2)) || 0;

const getListWidth = (width: number, child: HTMLElement): number => {
  const childStyles = getComputedStyle(child);

  return (
    width +
    child.offsetWidth +
    convertMarginToValue(childStyles.marginLeft) +
    convertMarginToValue(childStyles.marginRight)
  );
};

export const useListDetails = (
  containerRef: MutableRefObject<Element | null>,
  listRef: MutableRefObject<Element | null>,
): ListDetails => {
  const [areChildrenOverflowing, setAreChildrenOverflowing] =
    useState<boolean>(false);

  const [scrollHandlers, setScrollHandlers] = useState<ScrollHandlers>(null);

  const compareRefs = useCallback(() => {
    if (containerRef && listRef) {
      const scrollListContainer = containerRef.current;
      const scrollListContent = listRef.current;

      if (scrollListContainer && scrollListContent) {
        const list = [].slice.call(scrollListContent.children);
        const containerWidth = getElementWidth(scrollListContainer);
        const listWidth = list.reduce(getListWidth, 0) - 5; // -5 to avoid browser accuracy problem
        const isOverflowing = containerWidth < listWidth;
        setAreChildrenOverflowing(isOverflowing);
        setScrollHandlers(
          curryScrollhandler(scrollListContainer, containerWidth),
        );
      }
    }
  }, [containerRef, listRef]);

  useEffect(() => {
    if (listRef.current && containerRef.current) {
      const observer = new ResizeObserver(compareRefs);
      observer.observe(listRef.current);
      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return () => undefined;
  }, [compareRefs, containerRef, listRef]);

  useLayoutEffect(() => compareRefs(), [compareRefs, containerRef, listRef]);

  return { areChildrenOverflowing, scrollHandlers };
};

export const useScrollableListContext = () => {
  const context = useContext(ScrollableListContext);
  if (!context) {
    throw new Error(
      `ScrollableList compound components cannot be rendered outside the ScrollableList component`,
    );
  }
  return context;
};
