import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { NavSportType } from '@gaming1/g1-betting';
import { ConfigContext } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { Icon, SimpleButton } from '@gaming1/g1-ui';

import { MenuItem } from '../../../common/components/MenuItem';
import { useCleanedParams } from '../../../hooks';

import { MenuTitle } from './MenuTitle';
import { SportMenuItem } from './SportMenuItem';

type SportsMenuProps = {
  isCollapsed?: boolean;
  items: NavSportType[];
};

const SportsMenuComponent: FC<SportsMenuProps> = ({
  items,
  isCollapsed = false,
}) => {
  const { t } = useTranslation('betting');
  const { betting } = useContext(ConfigContext);
  const [nbSportShown, setNbSportShown] = useState(betting.initialNbSportShown);

  const itemsDisplayed = useMemo(
    () => items.slice(0, nbSportShown),
    [nbSportShown, items],
  );

  const { sportId } =
    useCleanedParams<{ eventId: string; leagueId: string; sportId: string }>();

  useEffect(() => {
    const isCurrentSportVisible = itemsDisplayed.some(
      (item) => item.Id.toString() === sportId,
    );

    if (!isCurrentSportVisible && sportId) {
      setNbSportShown(items.length);
    }
  }, [itemsDisplayed, items.length, sportId]);

  const content = useMemo(
    () =>
      itemsDisplayed.length > 0 ? (
        <>
          <MenuTitle isCollapsed={isCollapsed}>
            {t('lateralMenu.sports')}
          </MenuTitle>
          {itemsDisplayed.map((item) => (
            <SportMenuItem
              key={item.Id}
              isCollapsed={isCollapsed}
              item={item}
            />
          ))}
          {itemsDisplayed.length < items.length ? (
            <SimpleButton
              onClick={(e) => {
                e.stopPropagation();
                setNbSportShown(items.length);
              }}
              data-testid="sportmenu-show-more"
            >
              <MenuItem elevationLevel={2} mb="1px">
                {!isCollapsed ? (
                  <>
                    <MenuItem.Left isActive={false}>
                      <Icon type="Plus" id="sport-menu-icon" />
                    </MenuItem.Left>
                    <MenuItem.Content>
                      {t('lateralMenu.showMoreSports')}
                    </MenuItem.Content>
                  </>
                ) : (
                  <MenuItem.Content justifyContent="center" alignItems="center">
                    <Icon type="Plus" id="sport-menu-icon" />
                  </MenuItem.Content>
                )}
              </MenuItem>
            </SimpleButton>
          ) : null}
        </>
      ) : null,
    [itemsDisplayed, isCollapsed, setNbSportShown, items.length, t],
  );

  return content;
};

export const SportsMenu = memo(SportsMenuComponent);
