import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';
import {
  generateFailureRequestState,
  generateInitialRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
} from '@gaming1/g1-utils';

import { CmsActions } from '../../store/types';

import * as actions from './actions';
import { generatePromotionsGroupState } from './helpers';
import { AllPromotionsState } from './types';

export const initialState: AllPromotionsState = {
  groups: {},
  groupsRequests: {},
  configurationRequest: generateInitialRequestState([]),
  configuration: { tags: [] },
  promotionRequest: generateInitialRequestState(),
  promotionDocumentsByUid: {},
};

export const promotionsReducer = (
  state: AllPromotionsState = initialState,
  action: CmsActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.fetchPromotions.request):
        draftState.groupsRequests[action.payload.tag.name] =
          generateLoadingRequestState();
        break;

      case getType(actions.fetchPromotions.failure):
        draftState.groupsRequests[action.payload.request.tag.name] =
          generateFailureRequestState(action.payload.data);
        break;

      case getType(actions.fetchPromotions.success):
        {
          const { tag, pagination, promotionDocuments } = action.payload;
          draftState.groupsRequests[tag] = generateSuccessRequestState();
          if (!draftState.groups[tag]) {
            draftState.groups[tag] = generatePromotionsGroupState();
          }
          const newUids = promotionDocuments.map((promo) => promo.uid);
          const newDocumentsByUid = promotionDocuments.reduce(
            (prev, cur) => ({ ...prev, [cur.uid]: cur }),
            {},
          );
          const draft = draftState.groups[tag];
          draft.pagination = pagination;
          draft.promotionsUids = [...draft.promotionsUids, ...newUids];
          draftState.promotionDocumentsByUid = {
            ...draftState.promotionDocumentsByUid,
            ...newDocumentsByUid,
          };
        }
        break;

      case getType(actions.fetchPromotionsConfiguration.request):
        draftState.configurationRequest = generateLoadingRequestState();
        break;

      case getType(actions.fetchPromotionsConfiguration.failure):
        draftState.configurationRequest = generateFailureRequestState(
          action.payload,
        );
        break;

      case getType(actions.fetchPromotionsConfiguration.success):
        draftState.configurationRequest = generateSuccessRequestState();
        draftState.configuration = action.payload;
        // For each tag, initialize a promotions group
        action.payload.tags.forEach((tag) => {
          draftState.groups[tag.name] = generatePromotionsGroupState();
        });
        break;

      case getType(actions.fetchPromotionByUid.request):
        draftState.promotionRequest = generateLoadingRequestState();
        break;

      case getType(actions.fetchPromotionByUid.failure):
        draftState.promotionRequest = generateFailureRequestState(
          action.payload,
        );
        break;

      case getType(actions.fetchPromotionByUid.success):
        draftState.promotionRequest = generateSuccessRequestState();
        draftState.promotionDocument = action.payload;
        break;

      case getType(actions.fetchPromotionByUid.cancel):
        draftState.promotionRequest = generateInitialRequestState();
        draftState.promotionDocument = undefined;
        break;

      default: // Immer will automatically returns the state
    }
  });
