import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { actions as coreActions, CoreActions } from '@gaming1/g1-core';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../store/types';

import * as actions from './actions';
import { HorizontalBannersState } from './types';

export const initialState: HorizontalBannersState = {
  banners: null,
  requests: {
    getHorizontalBanner: generateInitialRequestState(null),
  },
};

export const horizontalBannersReducer = (
  state: HorizontalBannersState = initialState,
  action: BettingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getHorizontalBanner.request):
        produceLoadingState(draftState.requests.getHorizontalBanner);
        break;

      case getType(actions.getHorizontalBanner.failure):
        produceFailureState(
          draftState.requests.getHorizontalBanner,
          action.payload,
        );
        break;

      case getType(actions.getHorizontalBanner.success):
        produceSuccessState(draftState.requests.getHorizontalBanner);
        draftState.banners = action.payload;
        break;

      case getType(coreActions.loggedInUser):
        return initialState;

      default: // Immer will automatically return the state
    }
  });
