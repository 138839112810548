import { ApplicationWithPaymentState } from '../types';

/* Deposit */

export const depositHistorySelector = (state: ApplicationWithPaymentState) =>
  state.payment.history.deposits || [];

export const getDepositHistoryRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.history.requests.getDepositHistory;

/* Withdrawal */
export const withdrawalHistorySelector = (state: ApplicationWithPaymentState) =>
  state.payment.history.withdrawals || [];

export const getWithdrawalHistoryRequestStateSelector = (
  state: ApplicationWithPaymentState,
) => state.payment.history.requests.getWithdrawalHistory;
