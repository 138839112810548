import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const SearchTrendsContainer = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${spaces('md')};
`;

type BettingLinkProps = { isNormalLayout?: boolean };

export const BettingLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isNormalLayout'].includes(prop),
})<BettingLinkProps>`
  text-decoration: none;

  ${({ isNormalLayout }) =>
    !isNormalLayout &&
    css`
      margin-bottom: 0;
      background-color: ${colors('backgrounds', {
        index: 2,
      })};
      padding-bottom: 0;
    `}
`;
