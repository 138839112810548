import styled from 'styled-components';

import { opacity, spaces, zIndex } from '@gaming1/g1-style';
import { AppAnchor } from '@gaming1/g1-ui';

export const ScoreBoardStatsAppAnchor = styled(AppAnchor)`
  opacity: ${opacity('seeThrough')};
  padding: ${spaces('xxs')};
  z-index: ${zIndex(1)};
`;
