import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  getHasBoostusRequestStatusSelector,
  getRegionsNavigationStatusSelector,
  getSportsNavigationSelector,
  getSportsNavigationStatusSelector,
  getTrendsNavigationSelector,
  getTrendsNavigationStatusSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { currentWebSocketLocaleSelector } from '@gaming1/g1-core';
import { Box } from '@gaming1/g1-ui';
import { RemoteData } from '@gaming1/g1-utils';

import { useCleanedParams } from '../../../hooks';

import { ServicesMenu } from './ServicesMenu';
import { SportsMenu } from './SportsMenu';
import { TrendsMenu } from './TrendsMenu';
import { UnmissableMenu } from './UnmissableMenu';

type SportMenuProps = {
  /** Is the menu expanded or shrinked */
  isCollapsed?: boolean;
};

/**
 * Navigation used for the sportbook to select sports/leagues
 */
const LateralMenuComponent: FC<SportMenuProps> = ({ isCollapsed = false }) => {
  const config = useConfig();
  const isBoostusEnabled = config.betting.boostus.enabled;
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState<number | string>('');

  const { sportId } = useCleanedParams<{ sportId: string }>();

  const trends = useSelector(getTrendsNavigationSelector);
  const trendsRequestStatus = useSelector(getTrendsNavigationStatusSelector);

  const sports = useSelector(getSportsNavigationSelector);
  const sportsRequestStatus = useSelector(getSportsNavigationStatusSelector);

  const locale = useSelector(currentWebSocketLocaleSelector);

  const hasBoostusRequestStatus = useSelector(
    getHasBoostusRequestStatusSelector,
  );

  const regionsRequestStatus = useBettingGetterSelector({
    getterSelector: getRegionsNavigationStatusSelector,
    args: [Number(sportId) || -1],
  });

  useEffect(() => {
    if (trendsRequestStatus === RemoteData.NotAsked && locale) {
      dispatch(actions.askTrendsNavigation());
    }
  }, [dispatch, trendsRequestStatus, locale]);

  useEffect(() => {
    if (sportsRequestStatus === RemoteData.NotAsked) {
      dispatch(actions.askSportsNavigation());
    }
  }, [dispatch, sportsRequestStatus]);

  useEffect(() => {
    if (sportId && regionsRequestStatus === RemoteData.NotAsked) {
      dispatch(actions.askRegionsNavigation({ sportId: Number(sportId) }));
    }
  }, [dispatch, regionsRequestStatus, sportId]);

  useEffect(() => {
    if (isBoostusEnabled && hasBoostusRequestStatus === RemoteData.NotAsked) {
      dispatch(actions.getHasBoostusAvailable.request());
    }
  });

  const content = useMemo(
    () => (
      <Box data-testid="sportbook-menu">
        <TrendsMenu items={trends} isCollapsed={isCollapsed} />
        <UnmissableMenu
          isCollapsed={isCollapsed}
          onClick={setActiveId}
          activeId={activeId}
        />
        <SportsMenu items={sports} isCollapsed={isCollapsed} />
        <ServicesMenu
          isCollapsed={isCollapsed}
          onItemClick={setActiveId}
          activeId={activeId}
        />
      </Box>
    ),
    [isCollapsed, trends, setActiveId, activeId, sports],
  );

  return content;
};

export const LateralMenu = memo(LateralMenuComponent);
