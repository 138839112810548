import { ApplicationWithBettingState } from '../../../store/types';

export const outcomesSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.entities.outcomes;
export const marketsSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.entities.markets;
export const eventsSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.entities.events;
export const leaguesSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.entities.leagues;
export const sportsSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.entities.sports;
export const playerPropsSelector = (state: ApplicationWithBettingState) =>
  state.betting.sportbook.entities.playerProps || {};
