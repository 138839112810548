import { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  coreActions,
  getGoogleTagManagerRequestStateSelector,
  gtmEventsSelector,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { useRequestState } from '@gaming1/g1-store';

import { isValidGTMEventProps } from '../../helpers';
import { useTracking } from '../../hooks/useTracking';
import { ExtendedGTMEventProps } from '../../types';

/**
 * Convert "raw" GA events to valid GTM events (i.e. with "action" and
 * "category" properties)
 * */
const convertToValidGTMEvent = (
  rawEvent: Record<string, string | number>,
): ExtendedGTMEventProps => ({
  ...rawEvent,
  action:
    typeof rawEvent?.action === 'string' && rawEvent.action !== 'undefined'
      ? rawEvent.action
      : 'event',
  category:
    typeof rawEvent?.category === 'string' && rawEvent.category !== 'undefined'
      ? rawEvent.category
      : 'category',
});

/** Send to GTM events received from the backend */
export const GTMEventsManager: VFC = () => {
  const dispatch = useDispatch();
  const events = useSelector(gtmEventsSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const { isNotAsked } = useRequestState(
    getGoogleTagManagerRequestStateSelector,
  );

  const { pushEvent, pushRawData, isGtmEnabled } = useTracking();

  /** Fetch the events when logged in */
  useEffect(() => {
    if (isGtmEnabled && isNotAsked && isUserLoggedIn) {
      dispatch(coreActions.getGTMEvents.request());
    }
  }, [dispatch, isGtmEnabled, isNotAsked, isUserLoggedIn]);

  /** Send all the events in the store */
  useEffect(() => {
    if (isGtmEnabled && events.length) {
      events.forEach((event) => {
        const { data, isGoogleAnalytics4Event } = event;
        if (isGtmEnabled) {
          if (
            isGoogleAnalytics4Event &&
            data['event'] &&
            typeof data['event'] === 'string'
          ) {
            pushEvent({
              event: data['event'],
              ...data,
            });
          } else if (isValidGTMEventProps(data)) {
            pushEvent(data);
          } else {
            pushEvent(convertToValidGTMEvent(data));
            /** Previously we were sending the raw data directly into the
             * dataLayer if the event was missing "action" and "category"
             * properties. This resulted in events not sent to Analytics. So now
             * we always send the event using with the "eventProps", adding them
             * if missing */
            // pushRawData(data);
          }
        }
      });
      if (isGtmEnabled) {
        dispatch(
          coreActions.markGTMEventsAsSent.request(events.map(({ id }) => id)),
        );
      }
    }
  }, [dispatch, events, isGtmEnabled, pushEvent, pushRawData]);
  return null;
};
