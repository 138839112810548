/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { TrendsRequest, TrendsResponse } from '../entities';

export const getTrends = (options: TrendsRequest) =>
  createRequest<TrendsResponse>(201, 'GetTrends', options);
