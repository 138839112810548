import React, { FC } from 'react';

import { bettingRoutes } from '@gaming1/g1-betting-web';
import { cmsRoutes } from '@gaming1/g1-cms-web';
import {
  AccountLinks,
  DepositButton,
  MenuGroups,
  TopNav,
  useRoutePath,
} from '@gaming1/g1-core-web';
import { gamingRoutes } from '@gaming1/g1-gaming-web';
import { loyaltyRoutes } from '@gaming1/g1-loyalty-web';

export const AppTopNav: FC = () => {
  const getBettingPath = useRoutePath(bettingRoutes);
  const getCmsPath = useRoutePath(cmsRoutes);
  const getGamingPath = useRoutePath(gamingRoutes);
  const getLoyaltyPath = useRoutePath(loyaltyRoutes);

  const topNavMenuItems: MenuGroups = [
    [
      {
        label: 'menu.casino',
        path: getGamingPath('gameListCasino'),
        testId: 'topnav-casino',
      },
      {
        label: 'menu.dices',
        path: getGamingPath('gameListDice'),
        testId: 'topnav-dices',
      },
      {
        label: 'menu.tournaments',
        path: getGamingPath('tournaments'),
        testId: 'topnav-tournaments',
      },
      {
        label: 'menu.sport',
        path: getBettingPath('bettingHome'),
        testId: 'topnav-betting',
      },
    ],
    [
      {
        label: 'menu.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'topnav-promotions',
      },
      {
        label: 'menu.shop',
        path: getLoyaltyPath('shop'),
        testId: 'topnav-shop',
      },
    ],
  ];

  return (
    <TopNav>
      <TopNav.Group>
        <TopNav.AppLogo />
        <TopNav.AppSection />
        <TopNav.Menu menu={topNavMenuItems} />
      </TopNav.Group>
      <TopNav.Group>
        <DepositButton />
        <AccountLinks />
        <TopNav.SearchIcon />
      </TopNav.Group>
    </TopNav>
  );
};
