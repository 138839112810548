import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { acceptLegalPopup, showLegalPopup } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { logger } from '../../logger';
import { UserEpic } from '../../store/types';

import * as actions from './actions';
import * as codecs from './codecs';
import * as errorMessages from './errorMessages';

/** Accept Legal Popup */
export const acceptLegalModalEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.acceptLegalModal.request)),
    switchMap(() =>
      wsAdapter.request(acceptLegalPopup({})).pipe(
        mapGuard(codecs.acceptLegalModalResponse),
        map(actions.acceptLegalModal.success),
        catchError((err) =>
          of(
            actions.acceptLegalModal.failure(
              createFailurePayload(
                err,
                errorMessages.acceptLegalModalErrorMessages,
              ),
            ),
          ),
        ),
      ),
    ),
  );

/** Show Legal Popup */
export const showLegalModalEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.showLegalModal.request)),
    switchMap(() =>
      wsAdapter.request(showLegalPopup({})).pipe(
        mapGuard(codecs.showLegalModalResponse),
        map(actions.showLegalModal.success),
        catchError((err) =>
          of(
            actions.showLegalModal.failure(
              createFailurePayload(
                err,
                errorMessages.showLegalModalErrorMessages,
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const legalModalInjectedEpic: UserEpic = (_, __, { wsAdapter }) =>
  wsAdapter.getInjectedRequests$('ShowLegalPopup').pipe(
    mapGuard(codecs.showLegalModalResponse),
    map(actions.showLegalModal.success),
    catchError((err) => {
      logger.error('Error with the legal modal response object', err);
      return EMPTY;
    }),
  );
