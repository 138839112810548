import React, { BaseHTMLAttributes, VFC } from 'react';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';

import { PlaceholderContainer, PlaceholderImage } from './styles';

export type PlaceholderProps = {
  /** className of the RationContainer div */
  className?: string;
  /** Dark placeholder */
  dark?: boolean;
  /** Aspect ratio of the placeholder */
  ratio?: number;
};

/** Image show when an image is missing */
export const Placeholder: VFC<
  PlaceholderProps & BaseHTMLAttributes<HTMLImageElement>
> = ({ className = '', dark = false, ratio = 1, ...rest }) => {
  const getAssetPath = useGetCoreAssetPath();

  return (
    <PlaceholderContainer
      dark={dark}
      ratio={ratio || 1}
      className={className}
      data-testid="placeholder"
    >
      <PlaceholderImage
        alt=""
        {...rest}
        id={dark ? 'placeholder-dark' : 'placeholder'}
        data-testid="placeholder-img"
        url={dark ? getAssetPath('logoLight') : getAssetPath('logoDark')}
      />
    </PlaceholderContainer>
  );
};
