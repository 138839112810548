import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { shopSearch } from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { shopErrorMessages } from '../common/errorMessages';
import { LoyaltyEpic } from '../types';

import * as actions from './actions';
import { searchShopProductsData, searchShopProductsResponse } from './codecs';
import { formatSearchShopProducts } from './format';

export const searchShopProductsEpic: LoyaltyEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.searchShopProducts.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(shopSearch(payload)).pipe(
        mapGuard(searchShopProductsResponse),
        map(formatSearchShopProducts),
        mapGuard(searchShopProductsData),
        map(actions.searchShopProducts.success),
        catchError((err) =>
          of(
            actions.searchShopProducts.failure(
              createFailurePayload(err, shopErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );
