import React, { FC } from 'react';

import {
  Left,
  MenuItemButton,
  MenuItemContent,
  MenuItemLink,
  MenuItemWrapper,
  Right,
} from './styles';

type MenuItemCompoundComponents = {
  Button: typeof MenuItemButton;
  Link: typeof MenuItemLink;
  Left: typeof Left;
  Right: typeof Right;
  Content: typeof MenuItemContent;
};

export const MenuItem: FC<React.ComponentProps<typeof MenuItemWrapper>> &
  MenuItemCompoundComponents = ({ ...props }) => <MenuItemWrapper {...props} />;

MenuItem.Button = MenuItemButton;
MenuItem.Link = MenuItemLink;
MenuItem.Content = MenuItemContent;
MenuItem.Left = Left;
MenuItem.Right = Right;
