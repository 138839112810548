import { memo, useContext } from 'react';

import { useDrawerByUrlSearchParam } from '@gaming1/g1-core-web';
import { DrawerType, LayoutContext } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../../../BettingSlipContext';
import {
  useAutomaticSwitchType,
  useBettingSlipPersistence,
  useBettingSlipUserPreference,
  usePipeManager,
  useRefScrollTo,
  useSetBettingSlipOutcomeSubscribe,
} from '../../../../hooks';
import { useAutomating } from '../../../../hooks/automating';
import { useOptiOddsChangedDetected } from '../../../../hooks/optiOdds';
/**
 * Display the content ticket
 * a list of item inside select by user and save into store
 */

const BettingSlipHookContainerComponent = () => {
  const { visibleDrawer } = useContext(LayoutContext);
  const { bettingSlipId, type, switchType, refToScrollTo } =
    useContext(BettingSlipContext);

  const bettingSlipDrawerVisibility = visibleDrawer === DrawerType.bettingSlip;

  useAutomating({ bettingSlipId });

  usePipeManager({
    bettingSlipId,
  });

  useBettingSlipUserPreference();

  useSetBettingSlipOutcomeSubscribe(bettingSlipId);

  useBettingSlipPersistence(bettingSlipId);

  useAutomaticSwitchType({
    bettingSlipId,
    type,
    switchType,
  });

  /* Optiodds detection odds changed */
  useOptiOddsChangedDetected(bettingSlipId);

  /* Manage the scroll to footer behavior */
  useRefScrollTo(bettingSlipId, refToScrollTo, bettingSlipDrawerVisibility);

  /* Allows the betting slip drawer to be opened with url search parameter */
  useDrawerByUrlSearchParam(DrawerType.bettingSlip);

  return null;
};

export const BettingSlipHookContainer = memo(BettingSlipHookContainerComponent);
