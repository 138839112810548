import range from 'lodash/range';
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useState,
} from 'react';
import { useField } from 'react-final-form';
import { SpaceProps } from 'styled-system';

import { Icon, InputWrapper } from '@gaming1/g1-ui';
import {
  FieldValidator,
  getInputFieldValidator,
  lengthValidatorFactory,
  patternValidatorFactory,
  requiredValidator,
} from '@gaming1/g1-utils';

import { useInputFieldError } from '../../hooks';

import {
  BackgroundTextInput,
  CodeInputDisplay,
  CodeInputFocused,
  CodeInputWrapper,
  SideIconButton,
} from './styles';

type CodeInputFieldProps = {
  /* The number of blocks of the code field */
  codeLength?: number;
  /** Data test id of the display component */
  displayTestId?: string;
  /** Data test id of the selected component */
  focusedTestId?: string;
  /** Data test id of the input component */
  inputTestId?: string;
  /** Name of the input field */
  name: string;
  /** Specifies if the field is required */
  required?: boolean;
  /** Type of characters accepted */
  type: 'numeric' | 'alphanumeric';
  /** Validators that will be added to the existing ones */
  validators?: FieldValidator<string>[];
  /** Data test id of the wrapper component */
  wrapperTestId?: string;
  /** Should the input content be hidden ? */
  secret?: boolean;
} & SpaceProps;

const isValidCharacter = (type: 'numeric' | 'alphanumeric') =>
  type === 'numeric' ? /^[0-9]$/ : /^[a-zA-Z0-9]$/;

/* It renders an input with {codeLength} blocks which could be filled with numbers or alphanumeric characters */
export const CodeInputField = forwardRef<HTMLInputElement, CodeInputFieldProps>(
  (
    {
      codeLength = 4,
      displayTestId = 'code-display',
      focusedTestId = 'code-focused',
      inputTestId = 'code-input',
      name,
      required,
      type,
      validators = [],
      wrapperTestId = 'code-wrapper',
      secret = false,
      ...spaceProps
    },
    ref,
  ) => {
    const regexCharacters = type === 'numeric' ? '0-9' : 'a-zA-Z0-9';
    const [passwordInput, setPasswordInput] = useState(secret);

    const codeValidator = patternValidatorFactory(
      new RegExp(`^[${regexCharacters}]{${codeLength}}$`),
    );
    const lengthValidator = lengthValidatorFactory(codeLength, codeLength);

    const toggleButton: ReactNode = (
      <SideIconButton
        data-testid={`${inputTestId}-toggle`}
        onClick={() => {
          setPasswordInput(!passwordInput);
        }}
        type="button"
      >
        {passwordInput ? (
          <Icon type="EyeOpen" id="password-input-open" />
        ) : (
          <Icon type="EyeClose" id="password-input-close" />
        )}
      </SideIconButton>
    );

    const validator = [
      ...(required
        ? [
            requiredValidator(
              type === 'numeric'
                ? 'validation.code.numeric.required'
                : 'validation.code.alphanumeric.required',
              { codeLength: String(codeLength) },
            ),
          ]
        : []),
      lengthValidator(
        type === 'numeric'
          ? 'validation.code.numeric.validLength'
          : 'validation.code.alphanumeric.validLength',
        { codeLength: String(codeLength) },
      ),
      codeValidator(
        type === 'numeric'
          ? 'validation.code.validNumber'
          : 'validation.code.validAlphaNumeric',
      ),
      ...validators,
    ];

    const inputFieldValidator = getInputFieldValidator(validator);

    const { input, meta } = useField(name, {
      validate: inputFieldValidator,
    });

    const errorMessage = useInputFieldError(meta, 'user');

    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      input.onBlur(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e) {
        const { value } = e.target;
        if (value.match(isValidCharacter(type))) {
          const slicedValues = (input.value + value).slice(0, codeLength);
          input.onChange(slicedValues);
        }
      }
    };

    const handleDelete = (e: KeyboardEvent) => {
      if (e.key === 'Backspace' && input.value) {
        const slicedValues = input.value.slice(0, input.value.length - 1);
        input.onChange(slicedValues);
      }
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      input.onFocus(e);
    };

    const handleDisplay = (index: number) => {
      if (passwordInput && input.value && input.value?.length > index) {
        return '*';
      }
      return input.value?.length ? input.value[index] : null;
    };

    return (
      <InputWrapper
        alignItems="center"
        errors={errorMessage ? [errorMessage] : undefined}
        testId={inputTestId}
        {...spaceProps}
      >
        <CodeInputWrapper data-testid={wrapperTestId}>
          <BackgroundTextInput
            codeLength={codeLength}
            data-testid={inputTestId}
            inputMode={type === 'numeric' ? 'numeric' : 'text'}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyUp={handleDelete}
            pattern={`^[${regexCharacters}]*$`}
            ref={ref}
            validate={validator}
            value=""
          />
          {range(codeLength).map((index) => {
            const isDisplayFocused = input.value?.length === index;
            const arrayIsFilled =
              input.value?.length === codeLength && index === codeLength - 1;
            return (
              <CodeInputDisplay
                data-testid={`${displayTestId}${index}`}
                key={index}
              >
                {handleDisplay(index)}
                {(isDisplayFocused || arrayIsFilled) && isFocused && (
                  <CodeInputFocused data-testid={focusedTestId} />
                )}
              </CodeInputDisplay>
            );
          })}
          {secret && toggleButton}
        </CodeInputWrapper>
      </InputWrapper>
    );
  },
);
