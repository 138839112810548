/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { normalize, schema } from 'normalizr';

import {
  BaseSportbookResponseType,
  EventType,
  FullSearchResponseType,
  GetEventResponse,
  GetLeagueResponseType,
  GetSportsResponseType,
  LeagueType,
  MarketType,
  NormalizedEventData,
  NormalizedLeagueData,
  NormalizedMarketData,
  NormalizedPlayerPropData,
  NormalizedSportData,
  PlayerPropType,
  SearchCompetitionResultType,
} from './types';

const outcomeEntity = new schema.Entity(
  'outcomes',
  {},
  { idAttribute: 'OutcomeId' },
);

const outcomeArray = new schema.Array(outcomeEntity);

const marketEntity = new schema.Entity(
  'markets',
  { Outcomes: outcomeArray },
  { idAttribute: 'MarketId' },
);

const marketArray = new schema.Array(marketEntity);

const playerPropsEntity = new schema.Entity(
  'playerProps',
  { Markets: marketArray },
  { idAttribute: 'PlayerPropId' },
);

const playerPropsArray = new schema.Array(playerPropsEntity);

const eventEntity = new schema.Entity(
  'events',
  { Markets: marketArray, PlayerProps: playerPropsArray },
  { idAttribute: 'EventId' },
);

const eventArray = new schema.Array(eventEntity);

const leagueEntity = new schema.Entity(
  'leagues',
  { Events: eventArray },
  { idAttribute: 'LeagueId' },
);

const leagueArray = new schema.Array(leagueEntity);

const sportEntity = new schema.Entity('sports', {}, { idAttribute: 'SportId' });

const sportArray = new schema.Array(sportEntity);

export const formatLeagues = (leagues: LeagueType[]) => {
  const { entities } = normalize(leagues, leagueArray);
  return {
    entities,
  } as NormalizedLeagueData;
};

export const formatLeague = (league: LeagueType) => {
  const { entities } = normalize(league, leagueEntity);
  return {
    entities,
  } as NormalizedLeagueData;
};

export const formatGetEvent = (league: GetEventResponse) => {
  const { entities } = normalize(league.League, leagueEntity);

  return {
    entities,
  } as NormalizedLeagueData;
};

export const formatEvent = (event: EventType) => {
  const { entities } = normalize(event, eventEntity);
  return {
    entities,
  } as NormalizedEventData;
};

export const formatPlayerProp = (playerProp: PlayerPropType) => {
  const { entities } = normalize(playerProp, playerPropsEntity);
  return {
    entities,
  } as NormalizedPlayerPropData;
};

export const formatMarket = (market: MarketType) => {
  const { entities } = normalize(market, marketEntity);
  return {
    entities,
  } as NormalizedMarketData;
};
export const formatSport = (sport: GetSportsResponseType) => {
  const { entities } = normalize(sport.Sports, sportArray);
  return {
    entities,
  } as NormalizedSportData;
};

export const formatBaseSportBook = (league: BaseSportbookResponseType) => {
  const { entities } = normalize(league.Leagues, leagueArray);

  return {
    entities: { ...entities },
    key: '',
  } as NormalizedLeagueData & { key: string };
};

export const formatFullSearch = (search: FullSearchResponseType) => {
  const { entities } = normalize(
    search.EventResult?.Leagues || [],
    leagueArray,
  );

  const { TotalResults, Competitions = [] } = search.CompetitionResult || {};

  return {
    entities: { ...entities },
    competitions: { TotalResults, Competitions },
    SearchTokens: search.SearchTokens || [],
    key: '',
  } as NormalizedLeagueData & {
    SearchTokens: string[];
    competitions: SearchCompetitionResultType;
  } & {
    key: string;
  };
};

export const formatGetLeagueResponse = (response: GetLeagueResponseType) => {
  const { entities } = normalize(response.League, leagueEntity);

  return {
    entities: { ...entities },
    key: '',
  } as NormalizedLeagueData & { key: string };
};
