import { createAsyncAction } from 'typesafe-actions';

import { AutoExcludeRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import { BlacklistPlayerResponse, GetAutoExcludeInfoResponse } from './types';

/* Async actions */

/** Create a self exclusion for a certain amount of days */
export const setSelfExclusion = createAsyncAction(
  'user/set_self_exclusion_request',
  'user/set_self_exclusion_success',
  'user/set_self_exclusion_failure',
)<AutoExcludeRequestDTO, void, FailurePayload>();

/** Get the user self exclusion status */
export const getSelfExclusionInfo = createAsyncAction(
  'user/get_self_exclusion_info_request',
  'user/get_self_exclusion_info_success',
  'user/get_self_exclusion_info_failure',
)<void, GetAutoExcludeInfoResponse, FailurePayload>();

/** Create a gaming pause for a certain amount of days  */
export const setGamePause = createAsyncAction(
  'user/set_game_pause_request',
  'user/set_game_pause_success',
  'user/set_game_pause_failure',
)<{ durationInMinutes: number }, GetAutoExcludeInfoResponse, FailurePayload>();

/** Closes the account of the user  */
export const closeAccount = createAsyncAction(
  'user/close_account_request',
  'user/close_account_success',
  'user/close_account_failure',
)<void, void, FailurePayload>();

/** Blacklist the player */
export const blacklistPlayer = createAsyncAction(
  'user/blacklist_player_request',
  'user/blacklist_player_success',
  'user/blacklist_player_failure',
)<void, BlacklistPlayerResponse, FailurePayload>();
