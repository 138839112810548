import * as t from 'io-ts';

import {
  EventType,
  OptimalBetElementStatus,
  Provider,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

import { notificationCodec } from '../../../notification/codecs';

/* Object data */

export const elementInfoCodec = t.intersection(
  [
    t.type({
      EventId: t.number,
      MarketId: t.number,
      OutcomeId: t.number,
      BaseOdds: t.number,
      EventType: createEnumType(EventType),
      Provider: createEnumType(Provider),
    }),
    t.partial({
      FinalOdds: t.number,
    }),
  ],
  'ElementInfoCodec',
);

export const optimalBetElementCodec = t.type(
  {
    OutcomeId: t.number,
    OptimalBetStatus: createEnumType(OptimalBetElementStatus),
  },
  'OptimalBetElement',
);

export const optimalBetInformationCodec = t.partial(
  {
    Coefficient: t.number,
    Offset: t.number,
  },
  'OptimalBetInformationCodec',
);

/* Response data */

export const getOptimalBetResponseCodec = t.intersection(
  [
    t.type({
      Notification: notificationCodec,
    }),
    t.partial({
      OptimalBetElements: t.array(optimalBetElementCodec),
      OptimalBetInformation: optimalBetInformationCodec,
    }),
  ],
  'GetOptimalBetResponseCodec',
);
