import * as t from 'io-ts';

import { ShopResponseCode } from '@gaming1/g1-requests';

import { shopProduct } from '../common/codecs';

/* Response data */

export const searchShopProductsResponse = t.type(
  {
    Status: t.literal(ShopResponseCode.Success),
    Count: t.number,
    Products: t.array(shopProduct),
  },
  'SearchShopProductsResponse',
);

/* Normalized data */

export const searchShopProductsData = t.type(
  {
    entities: t.type({
      products: t.record(t.string, shopProduct),
    }),
    result: t.array(t.string),
  },
  'SearchShopProductsData',
);
