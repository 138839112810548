import styled, { css } from 'styled-components';

import { BettingSlipStatus } from '@gaming1/g1-requests-betting';
import {
  colors,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { Box, SimpleButton } from '@gaming1/g1-ui';

type StateContainerProps = {
  isLive?: boolean;
  status?: BettingSlipStatus;
};

/* Background-color for Lost and Open status are the same */
export const StateContainer = styled(Box)<StateContainerProps>`
  ${fontWeight('bold')}
  width: fit-content;
  background-color: ${colors('secondary', { index: 3 })};
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: ${fontSizes('sm')};
  padding: ${spaces('xxs')} ${spaces('xs')};

  ${({ isLive }) =>
    isLive &&
    css`
      background-color: ${colors('bettingActivityStatusLive')};
    `};

  ${({ status }) => {
    if (
      status === BettingSlipStatus.Won ||
      status === BettingSlipStatus.Cashout
    ) {
      return css`
        background-color: ${colors('bettingActivityStatusOnlineWinning')};
      `;
    }
    if (status === BettingSlipStatus.Refund) {
      return css`
        background-color: ${colors('secondary', { index: 1 })};
      `;
    }
    return null;
  }};
`;

export const OpacityContainer = styled.div`
  opacity: ${opacity('semi')};
  font-size: ${fontSizes(1)};
  line-height: ${fontSizes(2)};
  align-items: center;
  display: flex;
`;

type ExpandButtonProps = {
  isVisible?: boolean;
};
export const ExpandButton = styled(SimpleButton)<ExpandButtonProps>`
  opacity: ${opacity('semi')};
  svg {
    transition: all ease-out 0.15s;
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
