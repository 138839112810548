import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../store/types';

import * as actions from './actions';
import { BoostusState } from './types';

export const initialState: BoostusState = {
  result: {
    hasBoostus: false,
    getAvailableBoostus: null,
  },
  requests: {
    getHasBoostus: generateInitialRequestState(),
    getAvailableBoostus: generateInitialRequestState(null),
  },
};

export const boostusReducer = (state = initialState, action: BettingActions) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getAvailableBoostus.request): {
        produceLoadingState(draftState.requests.getAvailableBoostus);
        draftState.result.getAvailableBoostus = null;
        break;
      }

      case getType(actions.getAvailableBoostus.success): {
        produceSuccessState(draftState.requests.getAvailableBoostus);
        draftState.result.getAvailableBoostus = action.payload;
        break;
      }

      case getType(actions.getAvailableBoostus.failure): {
        const { payload } = action;
        produceFailureState(draftState.requests.getAvailableBoostus, payload);
        draftState.result.getAvailableBoostus = null;
        break;
      }

      case getType(actions.getHasBoostusAvailable.request):
        produceLoadingState(draftState.requests.getHasBoostus);
        break;

      case getType(actions.getHasBoostusAvailable.failure):
        produceFailureState(draftState.requests.getHasBoostus, action.payload);
        break;

      case getType(actions.getHasBoostusAvailable.success):
        produceSuccessState(draftState.requests.getHasBoostus);
        draftState.result.hasBoostus = action.payload.IsAvailable;
        break;

      default: // Immer will automatically return the state
    }
  });
