import styled, { css } from 'styled-components';

import { colors, fontSizes, spaces } from '@gaming1/g1-style';

import { GiftLabelType } from './types';

const GIFT_LABEL_HEIGHT_IN_PX = 21;
const GIFT_LABEL_BORDER_RADIUS_PX = 2;
const GIFT_LABEL_LINE_HEIGHT_IN_PX = 15;

export const StyledGiftLabel = styled.div<{
  type: GiftLabelType;
}>`
  align-items: center;
  display: flex;
  font-size: ${fontSizes('sm')};
  height: ${GIFT_LABEL_HEIGHT_IN_PX}px;
  line-height: ${GIFT_LABEL_LINE_HEIGHT_IN_PX}px;
  padding-left: ${spaces('xs')};
  padding-right: ${spaces('xs')};

  ${({ type }) =>
    type === 'optiOdds' &&
    css`
      border: 1px solid ${colors('bettingOptiOddsLabel')};
      border-radius: ${GIFT_LABEL_BORDER_RADIUS_PX}px;
      color: ${colors('bettingOptiOddsLabel')};
    `};
`;
