import format from 'date-fns/format';
import React, { ComponentPropsWithoutRef, FC, useContext } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { FreeSpin } from '@gaming1/g1-loyalty';
import { Modal } from '@gaming1/g1-ui';

import { FreeSpinsHistoryWinnings } from '../FreeSpinsHistory/FreeSpinsHistoryWinnings';
import { freeSpinsHistoryTransactionKeys } from '../helpers';
import {
  MoreInformationModalLabel,
  MoreInformationModalRow,
  MoreInformationModalTitle,
  MoreInformationModalValue,
  MoreInformationModalWrapper,
} from '../styles';

export type FreeSpinsHistoryInformationModalProps = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  history: FreeSpin;
};

/**
 * Information modal for free spins history
 */
export const FreeSpinsHistoryInformationModal: FC<
  FreeSpinsHistoryInformationModalProps
> = ({ onClose, history, ...rest }) => {
  const { t } = useTranslation('loyalty');
  const { i18n } = useContext(ConfigContext);

  const informations = [
    {
      label: t('history.freeSpins.createdDate'),
      value: format(new Date(history.CreatedDate), i18n.dateTimePartialFormat),
    },
    {
      label: t('history.freeSpins.expirationDate'),
      value: format(
        new Date(history.ExpirationDate),
        i18n.dateTimePartialFormat,
      ),
    },
    {
      label: t('history.freeSpins.number'),
      value: history.NumberOfSpins,
    },
    {
      label: t('history.freeSpins.status'),
      value: t(freeSpinsHistoryTransactionKeys[history.Status]),
    },
    {
      label: t('history.freeSpins.winnings'),
      value: <FreeSpinsHistoryWinnings history={history} />,
    },
    {
      label: t('history.freeSpins.game'),
      value: history.LinkedGameName,
    },
  ];

  return (
    <Modal
      modalId="freespins-history-information-modal"
      onClose={onClose}
      {...rest}
    >
      <>
        <MoreInformationModalTitle>
          {t('history.freeSpins.moreInformationTitle')}
        </MoreInformationModalTitle>
        <MoreInformationModalWrapper>
          {informations.map((information) => (
            <MoreInformationModalRow key={information.label}>
              <MoreInformationModalLabel>
                {information.label}
              </MoreInformationModalLabel>
              <MoreInformationModalValue>
                {information.value}
              </MoreInformationModalValue>
            </MoreInformationModalRow>
          ))}
        </MoreInformationModalWrapper>
      </>
    </Modal>
  );
};
