import { createContext } from 'react';

const dummyFunction = () => null;

export const SubDrawerContext = createContext<{
  openOffersAvailableDrawer: () => void;
  closeOffersAvailableDrawer: () => void;
  offersAvailableDrawerVisibility: boolean;
  backdropVisibility: boolean;
  backdropClickHandler: () => void;
}>({
  openOffersAvailableDrawer: dummyFunction,
  closeOffersAvailableDrawer: dummyFunction,
  offersAvailableDrawerVisibility: false,
  backdropVisibility: false,
  backdropClickHandler: dummyFunction,
});
