import { createAction, createAsyncAction } from 'typesafe-actions';

import { GetFavoriteAndRecentGameListRequestDTO } from '@gaming1/g1-requests-gaming';
import { FailurePayload } from '@gaming1/g1-utils';

import { FavoriteGame, FavoriteRecentGamesData } from './types';

/**
 * WARNING: Right now, this request handler will ALWAYS give us a list of games with their property
 * "IsFavorite" at false. So we don't know if the game is a recent one or a favorite one.
 */
export const fetchFavoriteAndRecentGames = createAsyncAction(
  'gaming/fetch_favorite_recent_games_request',
  'gaming/fetch_favorite_recent_games_success',
  'gaming/fetch_favorite_recent_games_failure',
)<
  GetFavoriteAndRecentGameListRequestDTO,
  FavoriteRecentGamesData,
  FailurePayload
>();

/**
 * This action is using a custom request type because the one given
 * by the back-end (SetFavoriteGameListRequestDTO) is too loose and not typed as we need.
 *
 * Normally the response to this request would be empty (because it's only sending a Status),
 * BUT we need some kind of response to edit the game entities
 * with the current state of the game's IsFavorite property.
 *
 * To do so, I created the type {FavoriteGame} which contains
 * the "gameId" and the "isFavorite" properties of the "new" favorite game,
 * or the deleted one.
 *
 */
export const setFavoriteGame = createAsyncAction(
  'gaming/set_favorite_game_request',
  'gaming/set_favorite_game_success',
  'gaming/set/favorite_game_failure',
)<FavoriteGame, FavoriteGame, FailurePayload>();

/** The user clicked on the favorite button */
export const clickOnSetFavoriteGame = createAction(
  'gaming/click_on_set_favorite_game',
)<FavoriteGame>();
