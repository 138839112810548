import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as betPipeActions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const getInitDataReducer = (
  state = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(betPipeActions.getBettingSlipInitializationData.request): {
        produceLoadingState(draftState.requests.getInitData);

        break;
      }

      case getType(betPipeActions.getBettingSlipInitializationData.success): {
        const { payload } = action;

        produceSuccessState(draftState.requests.getInitData);

        const { getInitData } = draftState;
        if (getInitData) {
          getInitData.result = payload;
        }

        break;
      }

      case getType(betPipeActions.getBettingSlipInitializationData.failure): {
        const { payload } = action;
        produceFailureState(draftState.requests.getInitData, payload);

        break;
      }

      default: // Immer will automatically return the state
    }
  });
