import React, { ComponentProps, FC } from 'react';

import { Box } from '../Box';

import { SeparatorHr, SeparatorSpan } from './styles';

export type SeparatorProps = ComponentProps<typeof Box> & {
  hrColor?: string;
};

/**
 * Horizontal separator
 */
export const Separator: FC<SeparatorProps> = ({
  children,
  hrColor,
  ...props
}) => (
  <Box flexDirection="row" {...props}>
    <SeparatorHr hrColor={hrColor} />
    {!!children && <SeparatorSpan>{children}</SeparatorSpan>}
    <SeparatorHr hrColor={hrColor} />
  </Box>
);
