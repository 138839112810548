import format from 'date-fns/format';
import React, { ComponentPropsWithoutRef, FC, useContext } from 'react';

import { ConfigContext } from '@gaming1/g1-config';
import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Deposit } from '@gaming1/g1-payment';
import { Modal } from '@gaming1/g1-ui';

import { depositHistoryTransactionKeys } from '../../helpers';
import {
  MoreInformationModalLabel,
  MoreInformationModalRow,
  MoreInformationModalTitle,
  MoreInformationModalValue,
  MoreInformationModalWrapper,
} from '../../styles';

export type DepositHistoryInformationModalProps = Omit<
  ComponentPropsWithoutRef<typeof Modal>,
  'modalId'
> & {
  history: Deposit;
};

const isValidTransactionType = (
  num: number,
): num is keyof typeof depositHistoryTransactionKeys =>
  num in depositHistoryTransactionKeys;

/**
 * Information modal for deposit history
 */
export const DepositHistoryInformationModal: FC<
  DepositHistoryInformationModalProps
> = ({ onClose, history, ...rest }) => {
  const { t } = useTranslation('payment');
  const { i18n } = useContext(ConfigContext);
  const formatMoney = useFormatMoney();

  const informations = [
    {
      label: t('history.deposit.depositDate'),
      value: format(new Date(history.DepositDate), i18n.dateTimePartialFormat),
    },
    {
      label: t('history.deposit.walletName'),
      value: history.WalletName,
    },
    {
      label: t('history.deposit.orderId'),
      value: history.OrderId,
    },
    {
      label: t('history.deposit.status'),
      value: isValidTransactionType(history.TransactionState)
        ? t(depositHistoryTransactionKeys[history.TransactionState])
        : null,
    },
    {
      label: t('history.deposit.amount'),
      value: formatMoney(history.Amount),
    },
  ];

  return (
    <Modal
      modalId="deposit-history-information-modal"
      onClose={onClose}
      {...rest}
    >
      <>
        <MoreInformationModalTitle>
          {t('history.deposit.moreInformationTitle')}
        </MoreInformationModalTitle>
        <MoreInformationModalWrapper>
          {informations.map((information) => (
            <MoreInformationModalRow key={information.label}>
              <MoreInformationModalLabel>
                {information.label}
              </MoreInformationModalLabel>
              <MoreInformationModalValue>
                {information.value}
              </MoreInformationModalValue>
            </MoreInformationModalRow>
          ))}
        </MoreInformationModalWrapper>
      </>
    </Modal>
  );
};
