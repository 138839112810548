import {
  BettingSlipIdentifierType,
  BettingSlipStringType,
} from '../../common/store/types';
import { useBettingGetterSelector } from '../../hooks';
import { bettingSlipRoundingConfigGetterSelector } from '../store/selectors/common';
import { RoundingConfig } from '../store/types/common';

import {
  useBettingSlipHasLiveItem,
  useBettingSlipIsEnoughSelected,
  useBettingSlipIsTooMuchSelected,
} from './validator';

/**
 * Hook for inject config
 */
export const useCalcInjectConfig = <T>(
  bettingSlipId: BettingSlipIdentifierType,
  calcFunc: (config: RoundingConfig) => T,
): T => {
  const config = useBettingGetterSelector({
    getterSelector: bettingSlipRoundingConfigGetterSelector,
    args: [bettingSlipId],
  });
  return calcFunc(config);
};

export const useBettingSlipIsValidType = (
  bettingSlipId: BettingSlipIdentifierType,
) => {
  const isEnough = useBettingSlipIsEnoughSelected(bettingSlipId);
  const tooMuch = useBettingSlipIsTooMuchSelected(bettingSlipId);
  const hasLiveItem = useBettingSlipHasLiveItem(bettingSlipId);

  return (type: BettingSlipStringType): boolean => {
    switch (type) {
      case 'single':
        return isEnough(1) && !tooMuch(1);
      case 'combi':
        return isEnough(2);
      case 'system':
        return isEnough(3) && !hasLiveItem;
      default:
        return true;
    }
  };
};
