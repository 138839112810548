import React, { BaseHTMLAttributes, FC } from 'react';

import { ColDiv } from './styles';
import { ColProps, ColSystemProps } from './types';

/**
 * Div used as a column inside a Row. Can take a number between 1 and 12 for
 * each css media breakpoint for defining its width (similar to Bootstrap)
 */
export const Col: FC<
  ColProps & Omit<BaseHTMLAttributes<HTMLDivElement>, 'role'> & ColSystemProps
> = ({
  children,
  grow = 0,
  lg,
  md,
  noGutter = false,
  shrink = 0,
  sm,
  xl,
  xs,
  xxl,
  ...rest
}) => {
  const xsCols = xs === undefined ? 12 : xs;
  const smCols = sm === undefined ? xsCols : sm;
  const mdCols = md === undefined ? smCols : md;
  const lgCols = lg === undefined ? mdCols : lg;
  const xlCols = xl === undefined ? lgCols : xl;
  const xxlCols = xxl === undefined ? xlCols : xxl;
  return (
    <ColDiv
      {...rest}
      grow={grow}
      lg={lgCols}
      md={mdCols}
      noGutter={noGutter}
      shrink={shrink}
      sm={smCols}
      xl={xlCols}
      xs={xsCols}
      xxl={xxlCols}
    >
      {children}
    </ColDiv>
  );
};

export type { ColProps, ColSystemProps };
