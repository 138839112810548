import { SeoConfig } from '../types';

/**
 * SEO specific config
 */
export const defaultSeoConfig: SeoConfig = {
  dataDog: {
    applicationId: null,
    clientToken: null,
    partnerTag: null,
    sampleRate: null,
    site: 'datadoghq.com',
  },
  defaultImage:
    'https://www.gaming1.com/media/games/54887eb8-3518-e5a0-b25f-4bfec4c4de63.jpg',
  fullStoryOrgKey: null,
  googleSiteVerification: null,
  gtmId: null,
  hotjar: { id: null, version: 6 },
  twitterCard: 'summary_large_image',
  twitterSite: '@gaming1',
};
