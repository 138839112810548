import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { CoreActions } from '../types';

import * as actions from './actions';
import { CommonCoreState } from './types';

export const initialState: CommonCoreState = {
  creationSection: 'default',
  init: false,
  isInMobileApp: false,
  hasUserAlreadyLoggedIn: false,
  lastVisit: null,
  minimumMobileAppVersion: null,
  requests: {
    getShellParameters: generateInitialRequestState(),
  },
  section: 'default',
  shellParameters: null,
  mobileBuildNumber: '',
  mobileBuildVersion: '',
};

export const commonCoreReducer = (
  state: CommonCoreState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.initApp):
        draftState.init = true;
        draftState.lastVisit = action.payload.lastVisitTimestamp;
        draftState.hasUserAlreadyLoggedIn =
          action.payload.hasUserAlreadyLoggedIn;
        draftState.isInMobileApp = action.payload.isInMobileApp;
        break;

      case getType(actions.switchSection):
        draftState.section = action.payload;
        draftState.creationSection =
          action.payload !== 'registration'
            ? action.payload
            : state.creationSection;
        break;

      case getType(actions.getShellParams.request):
        produceLoadingState(draftState.requests.getShellParameters);
        break;

      case getType(actions.getShellParams.failure):
        produceFailureState(
          draftState.requests.getShellParameters,
          action.payload,
        );
        break;

      case getType(actions.getShellParams.success):
        produceSuccessState(draftState.requests.getShellParameters);
        draftState.shellParameters = action.payload;
        draftState.minimumMobileAppVersion =
          action.payload.MinimumMobileAppVersion;
        break;

      case getType(actions.setMobileVersion):
        draftState.mobileBuildNumber = action.payload.mobileBuildNumber;
        draftState.mobileBuildVersion = action.payload.mobileBuildVersion;
        break;

      default: // Immer will automatically return the state
    }
  });
