import { createAction, createAsyncAction } from 'typesafe-actions';

import { ChangePlayerSettingsRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  ChangePlayerSettingsResponse,
  GetPlayerSettingsResponse,
} from './types';

/* Async actions */
/** Change user settings */
export const changeUserSettings = createAsyncAction(
  'user/change_settings_request',
  'user/change_settings_success',
  'user/change_settings_failure',
)<
  ChangePlayerSettingsRequestDTO,
  ChangePlayerSettingsResponse,
  FailurePayload
>();

/** Get user settings */
export const getUserSettings = createAsyncAction(
  'user/get_settings_request',
  'user/get_settings_success',
  'user/get_settings_failure',
)<void, GetPlayerSettingsResponse, FailurePayload>();

/* User actions */

type ClickUserSettingsPayload = Omit<
  Partial<ChangePlayerSettingsRequestDTO>,
  'PropertiesToupdate' | 'LanguageIsoCode'
>;

/**
 * The user clicked on a checkbox/radio in its settings
 */
export const clickUserSettings = createAction(
  'user/settings_click',
)<ClickUserSettingsPayload>();
