import * as t from 'io-ts';

import { PrismicCodecs, PrismicPartialCodecs } from '../../common/codecs';

export const blogArticleCodec = t.type(
  {
    image: PrismicPartialCodecs.imageItem,
    banner: PrismicPartialCodecs.responsiveImage(['Mobile']),
    title: t.string,
    summary: t.array(PrismicCodecs.richText),
    link: PrismicCodecs.linkCodec,
  },
  'BlogArticle',
);

export const blogArticleDocumentCodec = t.type(
  {
    data: blogArticleCodec,
    first_publication_date: t.string,
    tags: t.array(t.string),
    uid: t.string,
  },
  'BlogArticleDocument',
);

export const blogArticleDocumentsCodec = t.array(
  blogArticleDocumentCodec,
  'BlogArticleDocuments',
);

export const blogArticlesResponseCodec = t.type(
  {
    results: blogArticleDocumentsCodec,
    total_pages: t.number,
    total_results_size: t.number,
  },
  'BlogArticlesResponse',
);

export const blogArticleTagCodec = t.type(
  {
    enabled: t.boolean,
    name: t.string,
    slug: t.string,
    title: t.string,
  },
  'BlogTag',
);

export const blogConfigurationCodec = t.type(
  {
    tags: t.array(blogArticleTagCodec),
  },
  'BlogConfiguration',
);

export const blogConfigurationResponseCodec = t.type(
  {
    results: t.array(
      t.type({
        data: blogConfigurationCodec,
      }),
    ),
  },
  'BlogConfigurationResponse',
);
