import { FieldMetaState } from 'react-final-form';

import { useTranslation } from '@gaming1/g1-i18n';
import { isValidatorError } from '@gaming1/g1-utils';

/**
 * return an already translated error message from final form only if it should
 * be visible to the user
 * @param meta the meta property returned from the useField hook
 * @param ns the useTransation namespace(s)
 */
export const useInputFieldError = <FieldValue>(
  meta: FieldMetaState<FieldValue>,
  namespace: string | string[],
): string | undefined => {
  const { t } = useTranslation(namespace);
  if (
    meta.submitError &&
    !meta.dirtySinceLastSubmit &&
    typeof meta.submitError === 'string'
  ) {
    return t(meta.submitError);
  }
  if (meta.error && meta.touched && isValidatorError(meta.error)) {
    if (typeof meta.error === 'string') {
      return t(meta.error);
    }
    const [translation, i18nReplacements] = meta.error;
    return t(translation, i18nReplacements);
  }

  return undefined;
};
