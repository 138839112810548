import { useEffect, useMemo, useRef } from 'react';
import { Selector, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  RemoteData,
  RequestState,
  useFirstLoadingState,
  usePrevious,
} from '@gaming1/g1-utils';

/**
 * Get a piece of state as an observable
 * @param selector the state selector
 * @param useLatest whether to use BehaviorSubject or a simple Subject
 */
export const useSelector$ = <TState, TSelected>(
  selector: (state: TState) => TSelected,
): Observable<TSelected> => {
  const selectedState = useSelector(selector);
  // We only want one subject that will be unsubscribed when unmounting
  /* eslint-disable react-hooks/exhaustive-deps */
  const selectedState$ = useMemo(() => new BehaviorSubject(selectedState), []);
  useEffect(() => () => selectedState$.unsubscribe(), []);
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    selectedState$.next(selectedState);
  }, [selectedState, selectedState$]);
  return useMemo(
    () => selectedState$.pipe(distinctUntilChanged()),
    [selectedState$],
  );
};

/**
 * Get the whole state as an observable
 */
export const useState$ = <State>(): Observable<State> => {
  const store = useStore<State>();

  return useMemo(
    () =>
      new Observable((observer) => {
        observer.next(store.getState());
        const disposeSubscription = store.subscribe(() =>
          observer.next(store.getState()),
        );
        return disposeSubscription;
      }),
    [store],
  );

  // return useMemo(() => from(store), []);
  /*
  const state$ = useMemo(() => new BehaviorSubject(store.getState()), []);
  useEffect(() => {
    const disposeSubscription = store.subscribe(() => {
      state$.next(store.getState());
    });
    return disposeSubscription;
  }, [store, state$]);
  return state$;
  */
};

type RequestStateHelpers = {
  isNotAsked: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
  isFailing: boolean;
  wasNotAsked: boolean;
  wasLoading: boolean;
  wasSuccessful: boolean;
  wasFailed: boolean;
  lastErrorMessage: string | undefined;
  isFirstLoading: boolean;
};

const isRequestState = <State>(
  stateOrSelector: Selector<State, RequestState> | RequestState,
): stateOrSelector is RequestState => typeof stateOrSelector === 'object';
/**
 * Given a RequestState, or a selector returning a RequestState, will return
 * the state + some helpers
 * @param stateOrStateSelector the selector to the RequestState from the store
 * or the RequestState itself
 */
export const useRequestState = <State>(
  stateOrStateSelector: Selector<State, RequestState> | RequestState,
): RequestState & RequestStateHelpers => {
  const isInputRequestState = isRequestState(stateOrStateSelector);
  const statusSelector = useMemo(
    () =>
      !isInputRequestState
        ? createSelector(
            stateOrStateSelector,
            (stateRequest) => stateRequest.status,
          )
        : () => stateOrStateSelector.status,
    [stateOrStateSelector, isInputRequestState],
  );
  const errorCodeSelector = useMemo(
    () =>
      !isInputRequestState
        ? createSelector(
            stateOrStateSelector,
            (stateRequest) => stateRequest.errorCode,
          )
        : () => stateOrStateSelector.errorCode,
    [stateOrStateSelector, isInputRequestState],
  );
  const errorMessageSelector = useMemo(
    () =>
      !isInputRequestState
        ? createSelector(
            stateOrStateSelector,
            (stateRequest) => stateRequest.errorMessage,
          )
        : () => stateOrStateSelector.errorMessage,
    [stateOrStateSelector, isInputRequestState],
  );
  const status = useSelector(statusSelector);
  const errorCode = useSelector(errorCodeSelector);
  const errorMessage = useSelector(errorMessageSelector);
  const lastErrorMessageRef = useRef(errorMessage);

  const isNotAsked = status === RemoteData.NotAsked;
  const isLoading = status === RemoteData.Loading;
  const isSuccessful = status === RemoteData.Success;
  const isFailing = status === RemoteData.Error;
  const wasNotAsked = usePrevious(isNotAsked) ?? false;
  const wasLoading = usePrevious(isLoading) ?? false;
  const wasSuccessful = usePrevious(isSuccessful) ?? false;
  const wasFailed = usePrevious(isFailing) ?? false;
  const isFirstLoading = useFirstLoadingState(status);

  /** Store the latest non-null message */
  if (lastErrorMessageRef.current !== errorMessage && errorMessage) {
    lastErrorMessageRef.current = errorMessage;
  }
  return useMemo(
    () => ({
      errorCode,
      errorMessage,
      lastErrorMessage: lastErrorMessageRef.current,
      status,
      isNotAsked,
      isLoading,
      isSuccessful,
      isFailing,
      wasNotAsked,
      wasLoading,
      wasSuccessful,
      wasFailed,
      isFirstLoading,
    }),
    [
      errorCode,
      errorMessage,
      status,
      isNotAsked,
      isLoading,
      isSuccessful,
      isFailing,
      wasNotAsked,
      wasLoading,
      wasSuccessful,
      wasFailed,
      isFirstLoading,
    ],
  );
};
