import * as t from 'io-ts';

import { EHomeModuleType, EResponseStatus } from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

export const homeModuleOrderCodec = t.type(
  {
    ModuleType: createEnumType<EHomeModuleType>(EHomeModuleType),
    Order: t.number,
  },
  'PlaceBettingSlipResponseError',
);

export const getBettingHomeModuleOrderResponse = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    ModuleOrder: t.array(homeModuleOrderCodec),
  },
  'GetBettingHomeModuleOrder',
);
