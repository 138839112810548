import React, { FC } from 'react';

import { PrismicStepsSlice as PrismicStepsType } from '@gaming1/g1-cms';
import { isMediaBreakPointAboveOrEqual, useMedia } from '@gaming1/g1-ui';

import { getItemKeyFromRichText } from '../../helpers';
import { PrismicRichText } from '../PrismicRichText';

import {
  PrismicStepCounter,
  PrismicStepDiv,
  PrismicStepsContainer,
  PrismicStepsSeparator,
  PrismicStepsWrapper,
} from './styles';

export type PrismicStepsProps = {
  /** Prismic 'Steps' slice */
  slice: PrismicStepsType;
  /** Test Id */
  testId?: string;
};

/**
 * This component renders a prismic `Steps` slice
 * It renders a suite of steps with some text for each step
 */
export const PrismicSteps: FC<PrismicStepsProps> = ({
  slice,
  testId = 'prismic-steps',
}) => {
  const { mediaBP } = useMedia();
  const isLargeBP = isMediaBreakPointAboveOrEqual(mediaBP, 'lg');
  return (
    <PrismicStepsWrapper data-testid={testId}>
      <PrismicStepsContainer justifyContent="center">
        {(slice.items || []).map((item, index) => (
          <React.Fragment
            key={`step-${getItemKeyFromRichText(item.content) || index}`}
          >
            {index > 0 && !isLargeBP && <PrismicStepsSeparator />}
            <PrismicStepDiv>
              <PrismicStepCounter>{index + 1}</PrismicStepCounter>
              <PrismicRichText
                content={item.content}
                align={isLargeBP ? 'center' : 'left'}
                sliceId={slice.primary.id}
                disableInclusion
                testId="prismic-step"
              />
            </PrismicStepDiv>
          </React.Fragment>
        ))}
      </PrismicStepsContainer>
    </PrismicStepsWrapper>
  );
};
