import React, { FC } from 'react';

import { LegalModalProvider } from './LegalModalProvider';
import { PinCodeProvider } from './PinCodeProvider';
import { UserPublicComponentsProvider } from './UserPublicComponentsProvider';

/** Every context providers needed for the user package:
 * - UserPublicComponentsContext that shares public components to other packages
 * - PinCodeContext that shares data about the pin code requirements
 * - LegalModalContext that shares data about the legal modal visibility
 */
export const UserProviders: FC = ({ children }) => (
  <UserPublicComponentsProvider>
    <LegalModalProvider>
      <PinCodeProvider>{children}</PinCodeProvider>
    </LegalModalProvider>
  </UserPublicComponentsProvider>
);
