import { EChangePasswordStatus } from '@gaming1/g1-requests';

export const changePasswordErrorMessages = {
  [EChangePasswordStatus.InternalError]: 'core:error.internalServer', // 0
  [EChangePasswordStatus.BadOldPassword]:
    'user:account.message.wrongOldPassword', // 2
  [EChangePasswordStatus.PasswordMismatch]:
    'user:account.message.passwordMismatch', // 3
  [EChangePasswordStatus.InvalidPassword]:
    'user:account.message.invalidPassword', // 4
  [EChangePasswordStatus.InvalidSecurityPinCode]:
    'user:account.message.invalidSecurityPinCode', // 5
};
