/* istanbul ignore file */
import { createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitely
// typed as it, otherwise this break the RouteParams mapped type!
export const siteDefaultRoutes = {
  betPipeTesting: createRoute('/betpipe-testing'),
  layoutDemo: createRoute('/layout'),
  networkTesting: createRoute('/network-testing'),
  notificationsAndMessages: createRoute('/notifications-messages'),
  skeletonDemo: createRoute('/skeleton'),
  slidersDemo: createRoute('/sliders'),
  themeEditor: createRoute('/theme'),
};
