import orderBy from 'lodash/orderBy';

import { curriedHasNonNullableProperty } from '@gaming1/g1-utils';

import { ManualBetType } from '../types';

export const orderSuperOddsByStartDateOrAlphabetically = (
  superOddsList: ManualBetType[],
) =>
  orderBy(
    superOddsList.filter(curriedHasNonNullableProperty('ElementInfo')),
    [
      (superOdd) => new Date(superOdd.ElementInfo.StartDate),
      (superOdd) => superOdd.ElementInfo.Title1,
    ],
    ['asc', 'asc'],
  );
