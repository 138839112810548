import styled, { css } from 'styled-components';
import { fontSize, FontSizeProps } from 'styled-system';

import { colors, opacity, spaces } from '@gaming1/g1-style';

import { Box } from '../Box';
import { horizontalScrollWithoutScrollbarCss, SimpleButton } from '../styles';

const TABS_CONTAINER_BORDER_HEIGHT = 2;

export const ArrowContainer = styled(Box)`
  position: relative;
`;

export const TabsContainer = styled(Box)<{
  hasBackgroundColorRemoved: boolean;
  hasHorizontalScroll?: boolean;
}>`
  scroll-behavior: smooth;
  ${({ hasBackgroundColorRemoved }) =>
    !hasBackgroundColorRemoved &&
    css`
      background-color: ${colors('backgrounds', { index: 1 })};
    `}
  ${({ hasHorizontalScroll }) =>
    hasHorizontalScroll &&
    css`
      ${horizontalScrollWithoutScrollbarCss}
    `}
`;

export const TabListContainer = styled(Box)<
  {
    showBorder: boolean;
    hasHorizontalScroll: boolean;
    hasGapBetweenItems: boolean;
  } & FontSizeProps
>`
  position: relative;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  width: fit-content;

  ${({ hasGapBetweenItems }) =>
    hasGapBetweenItems &&
    css`
      gap: ${spaces('xs')};
      padding: 0 ${spaces('xs')};
    `};

  ${({ showBorder }) =>
    showBorder &&
    css`
      &:before {
        background-color: ${colors('scrollNavBorder')};
        bottom: 0;
        content: '';
        height: ${TABS_CONTAINER_BORDER_HEIGHT}px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    `};

  ${fontSize}
`;
export const TabContainer = styled(SimpleButton)<{
  borderPosition: 'top' | 'bottom';
  borderWidth?: number;
  fontSize?: number;
  hasBackgroundColorRemoved: boolean;
  hasSmallHeight?: boolean;
  isActive: boolean;
  isFullWidth: boolean;
}>`
  align-items: center;
  background-color: ${(props) =>
    props.isActive && !props.hasBackgroundColorRemoved
      ? props.theme.colors.backgrounds[1]
      : 'inherit'};
  box-shadow: ${(props) =>
    props.isActive && !props.hasBackgroundColorRemoved
      ? props.theme.shadows[2]
      : 'inherit'};
  flex-direction: row;
  padding: ${({ hasSmallHeight, ...props }) =>
    hasSmallHeight
      ? spaces('xs')
      : `${props.theme.space['sm']}px ${props.theme.space['sm']}px`};
  position: relative;
  white-space: nowrap;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'initial')};

  &:hover {
    border-color: ${colors('primary')};
  }

  ${({ isActive, borderPosition }) =>
    borderPosition &&
    css`
      z-index: 1;
      border-${borderPosition}: ${TABS_CONTAINER_BORDER_HEIGHT}px solid ${
      isActive ? colors('primary') : 'transparent'
    };
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
      cursor: default;

      &:hover {
        border-color: transparent;
      }
    `}
`;

export const TabContentContainer = styled.div<{
  hasBackgroundColorRemoved: boolean;
}>`
  ${({ hasBackgroundColorRemoved }) =>
    !hasBackgroundColorRemoved &&
    css`
      background-color: ${(props) => props.theme.colors.backgrounds[1]};
      box-shadow: ${(props) => props.theme.shadows[2]};
    `}
`;
