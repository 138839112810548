import {
  EGetTournamentListV4Status,
  EGetTournamentV4Status,
  ERegisterTournamentV4Status,
  ETournamentDetailsV4Status,
  ETournamentPlayerRankingV4Status,
  ETournamentRankingV4Status,
} from '@gaming1/g1-requests-gaming';

export const getTournamentListErrorMessages = {
  [EGetTournamentListV4Status.InternalError]: 'core:error.internalServer', // 0
  [EGetTournamentListV4Status.InvalidRoom]: 'core:error.invalidRoom', // 2
};

export const getTournamentDetailsErrorMessages = {
  [ETournamentDetailsV4Status.InternalError]: 'core:error.internalServer', // 0
  [ETournamentDetailsV4Status.InvalidTournament]:
    'gaming:tournament.errors.invalidTournament', // 2
};

export const getTournamentRankingErrorMessages = {
  [ETournamentRankingV4Status.InternalError]: 'core:error.internalServer', // 0
  [ETournamentRankingV4Status.InvalidTournament]:
    'gaming:tournament.errors.invalidTournament', // 2
};

export const getTournamentErrorMessages = {
  [EGetTournamentV4Status.InternalError]: 'core:error.internalServer', // 0
  [EGetTournamentV4Status.InvalidRoom]: 'core:error.invalidRoom', // 2
  [EGetTournamentV4Status.InvalidTournament]:
    'gaming:tournament.errors.invalidTournament', // 3
};

export const getTournamentPlayerRankingErrorMessages = {
  [ETournamentPlayerRankingV4Status.InternalError]: 'core:error.internalServer', // 0
  [ETournamentPlayerRankingV4Status.InvalidTournament]:
    'gaming:tournament.errors.invalidTournament', // 2
  [ETournamentPlayerRankingV4Status.InvalidPlayer]: 'core:error.internalServer', // 3
  [ETournamentPlayerRankingV4Status.PlayerWithoutRanking]:
    'gaming:tournament.errors.playerWithoutRanking', // 4
  [ETournamentPlayerRankingV4Status.PlayerWithoutRegistration]:
    'gaming:tournament.errors.playerWithoutRegistration', // 5
};

export const registerTournamentErrorMessages = {
  [ERegisterTournamentV4Status.InternalError]: 'core:error.internalServer', // 0
  [ERegisterTournamentV4Status.InvalidTournament]:
    'gaming:tournament.errors.invalidTournament', // 2
  [ERegisterTournamentV4Status.InvalidPlayer]: 'core:error.internalServer', // 3
  [ERegisterTournamentV4Status.RegistrationFailed]:
    'gaming:tournament.errors.registrationFailed', // 4
};
