import React, { VFC } from 'react';
import { SpaceProps } from 'styled-system';

import { useConfig } from '@gaming1/g1-config';
import { useAppStoreLinks } from '@gaming1/g1-core';
import { AppAnchor, Box } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';
import { Image } from '../Image';

const HEIGHT_IMAGE_IN_PX = 40;
const WIDTH_IMAGE_IN_PX = 120;

/**
 * Component with links to download iOS and Android App
 */
export const AppDownloadLinks: VFC<SpaceProps> = ({ ...spaceProps }) => {
  const { core } = useConfig();
  const { android, ios } = useAppStoreLinks();
  const getCoreAssetsPath = useGetCoreAssetPath();

  return (
    <Box {...spaceProps}>
      <Box my="xs">
        {ios ? (
          <AppAnchor
            data-testid="app-store-link"
            href={ios}
            target="_blank"
            rel="noopener"
          >
            <Image
              url={{
                fr: getCoreAssetsPath('appStoreFr'),
                en: getCoreAssetsPath('appStoreEn'),
                default: getCoreAssetsPath('appStoreEn'),
              }}
              alt="App Store"
              id="app-store"
              height={HEIGHT_IMAGE_IN_PX}
              width={WIDTH_IMAGE_IN_PX}
            />
          </AppAnchor>
        ) : (
          <Image
            url={{
              fr: getCoreAssetsPath('appStoreComingSoonFr'),
              en: getCoreAssetsPath('appStoreComingSoonEn'),
              default: getCoreAssetsPath('appStoreComingSoonEn'),
            }}
            alt="App Store"
            id="app-store-soon"
            height={HEIGHT_IMAGE_IN_PX}
            width={WIDTH_IMAGE_IN_PX}
          />
        )}
      </Box>
      <Box my="xs">
        {android ? (
          <AppAnchor
            data-testid="google-play-link"
            href={android}
            target="_blank"
            rel="noopener"
          >
            <Image
              url={{
                fr: getCoreAssetsPath('googlePlayFr'),
                en: getCoreAssetsPath('googlePlayEn'),
                default: getCoreAssetsPath('googlePlayEn'),
              }}
              alt="Google Play"
              id="google-play"
              height={HEIGHT_IMAGE_IN_PX}
              width={WIDTH_IMAGE_IN_PX}
            />
          </AppAnchor>
        ) : (
          <Image
            url={{
              fr: getCoreAssetsPath('googlePlayComingSoonFr'),
              en: getCoreAssetsPath('googlePlayComingSoonEn'),
              default: getCoreAssetsPath('googlePlayComingSoonEn'),
            }}
            alt="Google Play"
            id="google-play-soon"
            height={HEIGHT_IMAGE_IN_PX}
            width={WIDTH_IMAGE_IN_PX}
          />
        )}
      </Box>
      {core.mobileAppApkPath && (
        <Box my="xs">
          <AppAnchor
            data-testid="android-apk-link"
            href={core.mobileAppApkPath}
            target="_blank"
            rel="noopener"
          >
            <Image
              url={{
                fr: getCoreAssetsPath('androidApkFr'),
                en: getCoreAssetsPath('androidApkEn'),
                default: getCoreAssetsPath('androidApkEn'),
              }}
              alt="Android Apk"
              id="android-apk"
              height={HEIGHT_IMAGE_IN_PX}
              width={WIDTH_IMAGE_IN_PX}
            />
          </AppAnchor>
        </Box>
      )}
    </Box>
  );
};
