import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import { FilterType } from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { NavLeagueType } from '../../../navigation/store/types';
import { temporalFilterEvent } from '../helpers';
import { FilteredIds, FilteredIdsBySportId } from '../types';

import {
  componentLeagueIdsSelector,
  leagueSelector,
  leaguesListSelector,
} from './common';
import { eventSelector, eventsListFromLeaguesListSelector } from './events';
import {
  allSportsByLiveOrderSelector,
  idsBySportRegionSelector,
  sportNameSelector,
} from './sports';

/**
 * Returns all leagueIds that are filtered by the temporal filter
 * Don't forget to use shallowEqual with this selector
 */
export const leagueIdsFilteredSelector = createSelector(
  leaguesListSelector,
  eventsListFromLeaguesListSelector,
  (getLeagues, getEvents) =>
    memoize(
      (
        dateFilter: string,
        typeFilter: FilterType,
        visibleLeagueIds: number[],
      ) => {
        const leagues = getLeagues(visibleLeagueIds);

        const events = getEvents(leagues);

        return uniq(
          events
            .filter(({ IsTopEvent, StartDate }) =>
              typeFilter === FilterType.Top
                ? IsTopEvent
                : temporalFilterEvent(StartDate, dateFilter, typeFilter),
            )
            .map(({ LeagueId }) => LeagueId),
        );
      }, // Cache key resolver
      (
        dateFilter: string,
        typeFilter: FilterType,
        visibleLeagueIds: number[],
      ) => `${visibleLeagueIds.join('-')}${dateFilter}${typeFilter}`,
    ),
);

/**
 * Returns ids filtered by the temporal filter
 * Don't forget to use shallowEqual with this selector
 */
export const filteredIdsSelector = createSelector(eventSelector, (getEvent) =>
  memoize(
    (dateFilter: string, typeFilter: FilterType, ids: FilteredIds) =>
      ids
        .map((id) => ({
          ...id,
          eventIds: id.eventIds.filter((evId) => {
            const event = getEvent(evId);

            if (!event) {
              return false;
            }

            return typeFilter === FilterType.Top
              ? event.IsTopEvent
              : temporalFilterEvent(event.StartDate, dateFilter, typeFilter);
          }),
        }))
        .filter((id) => !!id.eventIds.length),

    // Cache key resolver
    (dateFilter: string, typeFilter: FilterType, ids: FilteredIds) =>
      `${ids
        .map((id) => `${id.leagueId}${id.eventIds.join('')}`)
        .join('-')}${dateFilter}${typeFilter}`,
  ),
);

/**
 * Returns leagues by sport id
 * Don't forget to use isEqual with this selector
 */
export const leaguesBySportIdSelector = createSelector(
  idsBySportRegionSelector,
  leagueSelector,
  (getIdsBySportRegion, getLeague) =>
    memoize(
      (
        key: string,
        sportId: number,
        regionId: number | null,
        sortedType?: string[],
      ): (NavLeagueType & { RegionName: string })[] => {
        const idsBySportRegion = getIdsBySportRegion(
          key,
          sportId,
          regionId,
          sortedType,
        );

        return idsBySportRegion.map((filteredId) => {
          const league = getLeague(filteredId.leagueId);

          return {
            Id: league?.LeagueId || -1,
            Name: league?.LeagueName || '',
            Order: league?.Order || 0,
            RegionId: league?.RegionId || -1,
            SportId: league?.SportId || -1,
            RegionName: league?.RegionName || '',
          };
        });
      }, // Cache key resolver
      (
        key: string,
        sportId: number,
        regionId: number | null,
        sortedType?: string[],
      ) => `${key}${sportId}${regionId}${sortedType?.join('') || ''}`,
    ),
);

/**
 * Returns all ids ([sportId/sportName/leagues]) grouped by SportId filtered by liveOrder
 * Don't forget to use isEqual with this selector
 */
export const allIdsGroupedBySportSelector = createSelector(
  componentLeagueIdsSelector,
  leaguesListSelector,
  sportNameSelector,
  allSportsByLiveOrderSelector,
  (getLeagueIds, getLeagues, getSportName, getSportIdsByLiveOrder) =>
    memoize((key: string): FilteredIdsBySportId => {
      const leagues = getLeagues(getLeagueIds(key))
        .sort((a, b) => (a?.Order || 0) - (b?.Order || 0))
        .filter(isNonNullable);

      const sportsIdsByLiveOrder = getSportIdsByLiveOrder(key);

      const sportIds = uniq(leagues.map((league) => league?.SportId || null))
        .filter(isNonNullable)
        .sort(
          (keyA, keyB) =>
            sportsIdsByLiveOrder.indexOf(Number(keyA)) -
            sportsIdsByLiveOrder.indexOf(Number(keyB)),
        );

      return sportIds.map((sportId) => {
        const sportName = getSportName(sportId) || '';

        return {
          sportId,
          sportName,
          leagues: leagues
            .filter((league) => league?.SportId === sportId)
            .map((league) => ({
              sportId: league?.SportId || 0,
              leagueId: league?.LeagueId || 0,
              eventIds: league?.Events || [],
            })),
        };
      });
    }),
);

/**
 * Return a function that takes a league id and returns a league name
 */
export const leagueNameSelector = createSelector(leagueSelector, (getLeague) =>
  memoize((leagueId: number) => getLeague(leagueId)?.LeagueName),
);
