import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { LicensesState } from './types';

export const initialState: LicensesState = {
  requests: {
    getLicenses: generateInitialRequestState(null),
    changeLicenses: generateInitialRequestState(null),
  },
  licenses: null,
};

export const licensesReducer = (
  state: LicensesState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getLicenses.request):
        produceLoadingState(draftState.requests.getLicenses);
        break;

      case getType(actions.getLicenses.failure):
        produceFailureState(draftState.requests.getLicenses, action.payload);
        break;

      case getType(actions.getLicenses.success):
        produceSuccessState(draftState.requests.getLicenses);
        draftState.licenses = action.payload;
        break;

      case getType(actions.changeLicenses.request):
        produceLoadingState(draftState.requests.changeLicenses);
        break;

      case getType(actions.changeLicenses.failure):
        produceFailureState(draftState.requests.changeLicenses, action.payload);
        break;

      case getType(actions.changeLicenses.success):
        produceSuccessState(draftState.requests.changeLicenses);
        break;

      default: // Immer will automatically return the state
    }
  });
