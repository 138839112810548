import * as t from 'io-ts';

import { EGetGameStatus } from '@gaming1/g1-requests-gaming';

import { game } from '../../common/store/codecs';

export const getGame = t.type({
  Status: t.literal(EGetGameStatus.Success),
  Game: game,
});
