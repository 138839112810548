/* Needed for mobile safari */
import 'intersection-observer';
import 'raf/polyfill';
import React from 'react';
import { render } from 'react-dom';

import { setLoggerLevel } from '@gaming1/g1-logger';

import { App } from './App';
import './devTools';
import { logger } from './logger';
import { reportWebVitals } from './reportWebVitals';

const rootEl = document.getElementById('root');

// Set initial log level based on url/local storage
setLoggerLevel();

/*
TODO: move this to a core-web component (that could be enabled only on non
production env) if those data are useful
*/
const logVitals = (report: unknown) => {
  logger.debug('[Performance] Web vitals report', report);
};

reportWebVitals(logVitals);

render(<App />, rootEl);
// serviceWorker.unregister();
