import isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
  actions,
  bettingHistoryByIdGetterSelector,
  cashoutDebugLogs$,
  getCashoutInformationGetterSelector,
  getCashoutRequestStatusSelector,
  isOutcomeAvailableSelector,
  outcomeListSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useIsDebugModeEnabled } from '@gaming1/g1-core';
import {
  isNonNullable,
  RemoteData,
  usePrevious,
  usePreviousDifferent,
} from '@gaming1/g1-utils';

/**
 * With the given {historyTicketId}, it'll retrieve the history ticket
 * corresponding and then fetching all the outcomes from this ticket history.
 *
 * When all the outcomes are fetched, it'll subscribe to these outcomes
 * to get any of these outcome updates, if it's not already subscribed.
 *
 * This hook will also unsubscribe to the outcomes of
 * the ticket history on dismount.
 *
 * @param historyTicketId the id of the ticket history we want to retrieve
 */
export const useSubscribeToOutcomeOfHistoryTicketElements = (
  historyTicketId: string,
) => {
  const dispatch = useDispatch();

  const historyTicket = useBettingGetterSelector({
    getterSelector: bettingHistoryByIdGetterSelector,
    args: [historyTicketId],
    equalityFn: isEqual,
  });

  const outcomeAndEventIds = useMemo(
    () =>
      historyTicket?.Elements?.map((element) => ({
        outcomeId: element?.OutcomeId || 0,
        eventId: element?.EventId || 0,
        marketId: element?.MarketId || 0,
      })) || [],
    [historyTicket?.Elements],
  );

  useEffect(() => {
    if (outcomeAndEventIds && outcomeAndEventIds.length > 0) {
      const filteredIds = outcomeAndEventIds.filter(isNonNullable);

      dispatch(actions.getInfoFromOutcomes.request({ ids: filteredIds }));

      batch(() => {
        filteredIds?.forEach(
          (elementIds) =>
            elementIds.outcomeId &&
            dispatch(
              actions.subscribeToOutcome({ outcomeId: elementIds.outcomeId }),
            ),
        );
      });
    }

    return () => {
      const removeCurrentOutcomeIds = outcomeAndEventIds.filter(isNonNullable);

      batch(() => {
        removeCurrentOutcomeIds?.forEach(
          (elementIds) =>
            elementIds.outcomeId &&
            dispatch(
              actions.unsubscribeToOutcome({ outcomeId: elementIds.outcomeId }),
            ),
        );
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeAndEventIds, dispatch]);
};

/**
 * This hook will fetch the history ticket with the given {historyTicketId} and
 * then fetch all the outcomes from this history ticket.
 *
 * If the odd of one of those outcomes changes, it will dispatch a
 * "getCashoutInfo" action request.
 *
 * @param historyTicketId the id of the history ticket we need to fetch
 * @param cashoutBettingSlipId the cashout betting slip id that we need to
 *                             update with the "getCashoutInfo"
 */
export const useCallCashoutInfoOnOutcomeOddUpdate = (
  historyTicketId: string,
  cashoutBettingSlipId: string | undefined,
  callToAction: () => void,
) => {
  const isDebugModeEnabled = useIsDebugModeEnabled();
  const dispatch = useDispatch();

  const historyTicket = useBettingGetterSelector({
    getterSelector: bettingHistoryByIdGetterSelector,
    args: [historyTicketId],
    equalityFn: isEqual,
  });

  const outcomeIds =
    historyTicket?.Elements?.map((element) => element?.OutcomeId).filter(
      isNonNullable,
    ) || [];

  const outcomesFromTicket = useBettingGetterSelector({
    getterSelector: outcomeListSelector,
    args: [outcomeIds],
    equalityFn: isEqual,
  });

  /**
   * We need to use the useMemo here to prevent the hook to re-render in an infinite
   * loop the component where it's used.
   */
  const odds = useMemo(
    () => outcomesFromTicket.map((outcome) => outcome?.Odd),
    [outcomesFromTicket],
  );
  const previousOdds = usePreviousDifferent(odds);

  useEffect(() => {
    if (!odds || !cashoutBettingSlipId) {
      return;
    }
    const hasOddsChanged = odds.some(
      (odd, i) => previousOdds && odd !== previousOdds[i],
    );

    if (hasOddsChanged) {
      if (isDebugModeEnabled) {
        cashoutDebugLogs$.next({
          time: new Date().getTime(),
          id: cashoutBettingSlipId,
          info: 'Odd change',
        });
      }
      callToAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousOdds, odds, dispatch]);
};

/**
 * This hook will fetch the history ticket with the given {historyTicketId}.
 * It'll also retrieve all the outcomes of the history ticket to then check if
 * their probability has changed. If the probability has changed, it will
 * dispatch a "getCashoutInfo" action request.
 *
 * @param historyTicketId the history ticket id that we need to fetch
 * @param cashoutBettingSlipId the cashout betting slip id that we need
 *                             to update with the "getCashoutInfo" request
 */
export const useCallCashoutInfoOnOutcomeProbabilityUpdate = (
  historyTicketId: string,
  cashoutBettingSlipId: string | undefined,
  callToAction: () => void,
) => {
  const isDebugModeEnabled = useIsDebugModeEnabled();
  const dispatch = useDispatch();

  const ticketHistory = useBettingGetterSelector({
    getterSelector: bettingHistoryByIdGetterSelector,
    args: [historyTicketId],
    equalityFn: isEqual,
  });

  const outcomeIds =
    ticketHistory?.Elements?.map((element) => element?.OutcomeId).filter(
      isNonNullable,
    ) || [];

  const outcomesFromTicket = useBettingGetterSelector({
    getterSelector: outcomeListSelector,
    args: [outcomeIds],
    equalityFn: isEqual,
  });

  const probabilities = useMemo(
    () => outcomesFromTicket.map((outcome) => outcome?.ProviderProbabilities),
    [outcomesFromTicket],
  );

  const previousProbabilities = usePreviousDifferent(probabilities);

  useEffect(() => {
    if (!cashoutBettingSlipId) {
      return;
    }
    const hasProbabilityFromOneOutcomeChanged = outcomesFromTicket.some(
      (outcome, i) =>
        previousProbabilities &&
        outcome?.ProviderProbabilities !== previousProbabilities[i],
    );

    if (hasProbabilityFromOneOutcomeChanged) {
      if (isDebugModeEnabled) {
        cashoutDebugLogs$.next({
          time: new Date().getTime(),
          id: cashoutBettingSlipId,
          info: 'Probability change',
        });
      }
      callToAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [probabilities, previousProbabilities, dispatch]);
};

/**
 * This hook will fetch the CashoutInformation corresponding to the given
 * {cashoutBettingSlipId} from the store.
 *
 * It'll check the availability of the CashoutInformation.
 * If the availability changes, it'll dispatch a "getCashoutInfo" action request
 *
 * @param cashoutBettingSlipId the cashoutBettingSlip we need to fetch and
 *                             update if needed
 */
export const useCallCashoutInfoOnOutcomeAvailabilityUpdate = (
  historyTicketId: string,
  cashoutBettingSlipId: string | undefined,
  callToAction: () => void,
) => {
  const isDebugModeEnabled = useIsDebugModeEnabled();
  const dispatch = useDispatch();

  const ticketHistory = useBettingGetterSelector({
    getterSelector: bettingHistoryByIdGetterSelector,
    args: [historyTicketId],
    equalityFn: isEqual,
  });

  const outcomeIds =
    ticketHistory?.Elements?.map((element) => element?.OutcomeId).filter(
      isNonNullable,
    ) || [];

  const outcomesFromTicket = useBettingGetterSelector({
    getterSelector: outcomeListSelector,
    args: [outcomeIds],
    equalityFn: isEqual,
  });

  const isOutcomeAvailable = useSelector(isOutcomeAvailableSelector);

  const availabilities = useMemo(
    () =>
      outcomesFromTicket.map((outcome) =>
        isOutcomeAvailable(outcome?.OutcomeId || 0),
      ),
    [isOutcomeAvailable, outcomesFromTicket],
  );

  const previousAvailabilities = usePreviousDifferent(availabilities);

  useEffect(() => {
    if (!cashoutBettingSlipId) {
      return;
    }
    const hasAvailabilityFromOneOutcomeChanged = outcomesFromTicket.some(
      (outcome, i) =>
        previousAvailabilities &&
        isOutcomeAvailable(outcome?.OutcomeId || 0) !==
          previousAvailabilities[i],
    );

    if (hasAvailabilityFromOneOutcomeChanged) {
      if (isDebugModeEnabled) {
        cashoutDebugLogs$.next({
          time: new Date().getTime(),
          id: cashoutBettingSlipId,
          info: 'Availability change',
        });
      }
      callToAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilities, previousAvailabilities, dispatch]);
};

/**
 * This hook will use the "setIsAskingConfirmation" given function
 * when the whole CashoutInformation has changed.
 *
 * This hook is useful when we need to change the step of the cashout process
 * on the update of the cashout information.
 *
 * @param setIsAskingConfirmation the function to use on update
 * @param cashoutBettingSlipId the cashout id that we need to retrieve
 */
export const useHandleUpdateOfCashoutInfoToStopCashoutProcess = (
  setIsAskingConfirmation: (value: React.SetStateAction<boolean>) => void,
  cashoutBettingSlipId: string | undefined,
) => {
  const currentCashoutInformation = useBettingGetterSelector({
    getterSelector: getCashoutInformationGetterSelector,
    args: [cashoutBettingSlipId || ''],
    equalityFn: isEqual,
  });

  const isPerformCashout =
    useBettingGetterSelector({
      getterSelector: getCashoutRequestStatusSelector,
      args: [cashoutBettingSlipId || ''],
    }) === RemoteData.Loading;

  const previousCashoutInformation = usePrevious(currentCashoutInformation);

  useEffect(() => {
    if (!currentCashoutInformation || isPerformCashout) {
      return;
    }

    if (currentCashoutInformation !== previousCashoutInformation) {
      setIsAskingConfirmation(false);
    }
  }, [
    currentCashoutInformation,
    previousCashoutInformation,
    isPerformCashout,
    setIsAskingConfirmation,
  ]);
};

/**
 * This hook will launch the "closeAnHistoryTicket" action if the "getCashout"
 * action request status is a success.
 *
 * The point is to remove the bet that has been cashouted from the open history.
 *
 * @param cashoutBettingSlipId the cashout that has been processed
 * @param historyId the history ticket to remove from the open ones
 */
export const useMoveOpenBetToCloseWhenCashoutIsPerformed = (
  cashoutBettingSlipId: string | undefined,
  historyId: string,
) => {
  const dispatch = useDispatch();

  const getCashoutStatus = useBettingGetterSelector({
    getterSelector: getCashoutRequestStatusSelector,
    args: [cashoutBettingSlipId || ''],
  });

  useEffect(() => {
    if (!cashoutBettingSlipId) {
      return;
    }

    if (getCashoutStatus === RemoteData.Success) {
      dispatch(
        actions.closeHistoryTicket({
          bettingSlipId: cashoutBettingSlipId,
          historyId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getCashoutStatus]);
};
