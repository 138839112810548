import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { THROTTLE_TIME_IN_MS } from '@gaming1/g1-core';
import {
  changeStateLicences,
  getAllLicencesAndStatesByPlayer,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import { changeLicencesResponse, getLicencesResponse } from './codecs';
import { getLicencesErrorMessages } from './errorMessages';

/** Trigger a license change request from user input */
export const changeLicenseRequestEpic: UserEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.clickUserLicense)),
    throttleTime(THROTTLE_TIME_IN_MS),
    map(({ payload }) => actions.changeLicenses.request(payload)),
  );

export const getLicensesEpic: UserEpic = (
  action$,
  state$,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getLicenses.request)),
    withLatestFrom(config$, state$),
    switchMap(([, { room }, { core }]) =>
      wsAdapter
        .request(
          getAllLicencesAndStatesByPlayer({
            LanguageCode: core.i18n.webSocketLocale,
            RoomDomainName: room.roomName,
          }),
        )
        .pipe(
          mapGuard(getLicencesResponse),
          map(actions.getLicenses.success),
          catchError((err) =>
            of(
              actions.getLicenses.failure(
                createFailurePayload(err, getLicencesErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );

export const changeLicensesEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.changeLicenses.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(changeStateLicences(payload)).pipe(
        mapGuard(changeLicencesResponse),
        map(actions.changeLicenses.success),
        catchError((err) =>
          of(
            actions.changeLicenses.failure(
              createFailurePayload(err, getLicencesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** After a successful changeLicenses, get the updated licenses from the back-end */
export const successChangeLicensesToGetLicensesEpic: UserEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.changeLicenses.success)),
    map(() => actions.getLicenses.request()),
  );
