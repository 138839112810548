import React, { ContextType, FC } from 'react';

import { CMSPublicComponentsContext } from '@gaming1/g1-cms-api';

import { PrismicPromotionDetailsModal } from '../PrismicPromotionDetailsModal';
import { PrismicSection } from '../PrismicSection';

const contextValue: ContextType<typeof CMSPublicComponentsContext> = {
  PrismicSection,
  PrismicPromotionDetailsModal,
};

/** Every context providers needed for the cms package:
 * - CMSPublicComponentsContext that shares public components to other packages
 */
export const CMSProviders: FC = ({ children }) => (
  <CMSPublicComponentsContext.Provider value={contextValue}>
    {children}
  </CMSPublicComponentsContext.Provider>
);
