import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { CoreActions, coreActions } from '@gaming1/g1-core';

import { depositReducer } from './deposit/reducer';
import { historyReducer } from './history/reducer';
import { PaymentActions, PaymentState } from './types';
import { withdrawalReducer } from './withdrawal/reducer';

const globalReducer = combineReducers<PaymentState, PaymentActions>({
  deposit: depositReducer,
  history: historyReducer,
  withdrawal: withdrawalReducer,
});

/**
 * The purpose of this root reducer is to reset the state when specific actions
 * are successfully made. See https://alligator.io/redux/reset-state-redux
 * for more informations.
 */
export const paymentReducer: Reducer<PaymentState, PaymentActions> = (
  state: PaymentState | undefined,
  action: PaymentActions | CoreActions,
) =>
  globalReducer(
    getType(coreActions.loggedOutUser) === action.type &&
      action.payload === true
      ? undefined
      : state,
    action as PaymentActions,
  );
