import { Alignement, PrismicRichTextContent } from '@gaming1/g1-cms';

type TextAlign = 'left' | 'center' | 'right';

/**
 * Convert prismic alignment values to justify-content values.
 */
export const getTextAlignFromAlignment = (
  alignment?: Alignement,
): TextAlign => {
  const lowercase = alignment?.toLowerCase() || '';
  return ['center', 'right'].includes(lowercase)
    ? (lowercase as TextAlign)
    : 'left';
};

/**
 * Checks if Prismic RichText content is empty
 * It will check every RichText element inside the content, to check if is has some text
 */
export const isRichTextContentEmpty = (content: PrismicRichTextContent) =>
  (content || []).reduce((prev, cur) => prev && !cur.text, true);
