import isEqual from 'lodash/isEqual';
import React, { FC, memo, useContext, useState } from 'react';
import { shallowEqual } from 'react-redux';

import {
  boostusSelectedForBSGetterSelector,
  getOptimalBetNotificationGetterSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useCMSPublicComponents } from '@gaming1/g1-cms-api';
import { Trans } from '@gaming1/g1-i18n';
import { Notification, NotificationCode } from '@gaming1/g1-requests-betting';
import { Box, Icon, SimpleButton, Text } from '@gaming1/g1-ui';

import { BettingIcon } from '../../../common/components/BettingIcon';
import { BettingSlipContext } from '../../BettingSlipContext';

import { OptimalBetMessageContainer } from './styles';

/**
 * Display the optimal bet message
 */
const BettingSlipOptimalBetMessageComponent: FC = () => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const { PrismicPromotionDetailsModal } = useCMSPublicComponents();

  const [visibleModal, setVisibleModal] = useState(() => false);

  const notification = useBettingGetterSelector({
    getterSelector: getOptimalBetNotificationGetterSelector,
    args: [bettingSlipId],
    equalityFn: isEqual,
  });

  const boostusSelected = useBettingGetterSelector({
    getterSelector: boostusSelectedForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const extractMissingElementCount = (notif: Notification) =>
    Number(
      notif.NotificationParameters?.find(
        (params) => params?.Key === 'MissingElementCount',
      )?.Value || 0,
    );

  if (
    boostusSelected ||
    !notification ||
    notification.Code === NotificationCode.OptimalBet_InternalError
  ) {
    return null;
  }

  const getContent = () => {
    if (notification.Code === NotificationCode.OptimalBet_Success) {
      return (
        <Trans i18nKey="bettingSlip.optimalbet.message.success" ns="betting" />
      );
    }

    if (
      notification.Code ===
      NotificationCode.OptimalBet_NotEligible_MissingElement
    ) {
      return (
        <Trans
          i18nKey="bettingSlip.optimalbet.message.miss-element"
          ns="betting"
          values={{
            count: extractMissingElementCount(notification),
          }}
        />
      );
    }
    return null;
  };

  return (
    <OptimalBetMessageContainer data-testid="bettingslip-optimalbet-message">
      <Box>
        <BettingIcon id="optiodds" type="OptiOddsTag" height={16} width={16} />
      </Box>
      <Box px="sm" verticalAlign="middle">
        <Text m={0}>{getContent()}</Text>
      </Box>
      <Box>
        <SimpleButton
          onClick={() => {
            setVisibleModal(true);
          }}
        >
          <Icon
            type="HowToBetRound"
            height={16}
            width={16}
            id="bettingslip-optimalbet-HowToBetRound"
          />
        </SimpleButton>
      </Box>
      {visibleModal && (
        <PrismicPromotionDetailsModal
          uid="welcome-offer"
          visible={visibleModal}
          close={() => {
            setVisibleModal(false);
          }}
        />
      )}
    </OptimalBetMessageContainer>
  );
};

export const BettingSlipOptimalBetMessage = memo(
  BettingSlipOptimalBetMessageComponent,
);
