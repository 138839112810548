import * as t from 'io-ts';

import {
  EBettingSlipOddChangeSettings,
  EGetBettingSlipPlayerPreferencesStatus,
  EResponseStatus,
} from '@gaming1/g1-requests-betting';
import { createEnumType } from '@gaming1/g1-utils';

export const getUserPreferencesResultCodec = t.type(
  {
    Status: t.literal(EGetBettingSlipPlayerPreferencesStatus.Ok),
    bSendRegistrationMail: t.boolean,
    bSendResultMail: t.boolean,
    bCleanBettingSlips: t.boolean,
    OddChangeSettings: createEnumType<EBettingSlipOddChangeSettings>(
      EBettingSlipOddChangeSettings,
      'EBettingSlipOddChangeSettings',
    ),
  },
  'GetUserPreferencesResult',
);

export const getUserPreferencesResponseCodec = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
    Result: getUserPreferencesResultCodec,
  },
  'GetUserPreferencesResponse',
);

export const updateUserPreferencesResponseCodec = t.type(
  {
    Status: t.literal(EResponseStatus.Successfull),
  },
  'UpdateUserPreferencesResponse',
);
