import differenceInYears from 'date-fns/differenceInYears';
import { createSelector } from 'reselect';

import { ECivility, PlayerSegments } from '@gaming1/g1-requests';

import { ApplicationState } from '../types';

import { UserGADetails } from './types';

const userCoreStateSelector = (state: ApplicationState) => state.core.user;

export const userLoggedInSelector = createSelector(
  userCoreStateSelector,
  (state) => state.userLoggedIn,
);

export const userSelector = createSelector(
  userCoreStateSelector,
  (state) => state.userProfile,
);

export const getUserProfileStatusSelector = createSelector(
  userCoreStateSelector,
  (state) => state.requests.userProfile.status,
);

export const userAgeSelector = createSelector(
  userCoreStateSelector,
  (state) =>
    state.userProfile &&
    differenceInYears(new Date(), new Date(state.userProfile.Birthday)),
);

export const userIdSelector = createSelector(
  userCoreStateSelector,
  (state) => state.userProfile?.Id,
);

export const usernameSelector = createSelector(
  userCoreStateSelector,
  (state) => state.userProfile?.Pseudo,
);

export const userCreditSelector = createSelector(
  userCoreStateSelector,
  (state) => state.userCredit,
);

export const userCreditStatusSelector = createSelector(
  userCoreStateSelector,
  (state) => state.requests.userCredit.status,
);

export const userIdentityStatusSelector = createSelector(
  userCoreStateSelector,
  (state) => state.userProfile?.PlayerIdentityStatus,
);

export const userDetailsForGASelector = createSelector(
  [userAgeSelector, userSelector],
  (age, profile): UserGADetails | null => {
    const personalTitles = {
      [ECivility.Unknown]: 'unknown',
      [ECivility.Mister]: 'male',
      [ECivility.Mrs]: 'female',
      [ECivility.Miss]: 'female',
    };
    const playerSegments = {
      [PlayerSegments.Active]: 'active',
      [PlayerSegments.Inactive]: 'inactive',
      [PlayerSegments.New]: 'new',
      [PlayerSegments.Reactivated]: 'reactivated',
    };

    return profile
      ? {
          Age: age,
          Id: profile?.Id,
          Civility: personalTitles[profile?.Civility] || 'unknown',
          DepositCounter: profile?.NbOfDeposit,
          PlayerSegment: profile?.PlayerSegment
            ? playerSegments[profile.PlayerSegment]
            : 'unknown',
        }
      : null;
  },
);
