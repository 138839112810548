import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, fontWeight, spaces } from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

export const GeolocationBannerWrapperContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const GeolocationBannerContent = styled.span`
  margin-right: ${spaces('xs')};
`;

export const GeolocationBannerButton = styled(SimpleButton)`
  text-transform: uppercase;
  ${fontWeight('bold', 'content')};
`;

export const GeolocationBannerLink = styled(Link)`
  text-transform: uppercase;
  ${fontWeight('bold', 'content')};
  text-decoration: none;
  color: ${colors('text')};
`;
