import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { userLoggedInSelector } from '@gaming1/g1-core';
import {
  getPromoForBettingSlip,
  NotificationCode,
  NotificationLevel,
} from '@gaming1/g1-requests-betting';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { formatForBettingSlipPreparedRequest } from '../../bettingSlip/helpers/forBettingSlip';
import { getPromoForBettingSlipResponseCodec } from '../../bettingSlip/store/codecs/promotionsForBettingSlip';
import { prepareForBSGetterSelector } from '../../bettingSlip/store/selectors/gifts/prepareConditionsForBS';
import { commonBettingErrorMessages } from '../../common/store/errorMessages';
import * as promotionsActions from '../../promotions/actions';
import {
  getAvailablePromotionsListSelector,
  isGetAvailablePromotionsNotificationASuccess,
} from '../../promotions/selectors';
import { BettingEpic } from '../../store/types';
import * as actions from '../actions';
import {
  extractInformationForBettingSlip,
  formatActionsData,
} from '../helpers';

import { takeUntilPipeIsCancelled } from './helpers';

const onTimeOut = {
  retryOnTimeout: true,
  timeoutAfterMs: 10000 /* 10 sec */,
};

export const promotionsForBettingSlipEpic: BettingEpic = (
  actions$,
  state$,
  { config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.promotionsForBettingSlip.request)),
    withLatestFrom(state$, config$),
    map(([{ payload }, state, config]) => {
      const isUserLoggedIn = userLoggedInSelector(state);
      const { betPipeId, bettingSlipId, bettingSlipType } =
        extractInformationForBettingSlip(payload, state);

      if (!config.betting.promotions.enabled || bettingSlipType === 'system') {
        return actions.promotionsForBettingSlip.success(
          formatActionsData({
            data: {
              PromoIds: [],
              Notification: {
                Level: NotificationLevel.Information,
                Code: NotificationCode.Promo_Success,
              },
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }
      if (!isUserLoggedIn) {
        return actions.promotionsForBettingSlip.failure(
          formatActionsData({
            data: {
              errorMessage: 'betting:promotion.error.userNotAuthenticated',
              status: DEFAULT_SERVER_ERROR_STATUS,
            },
            bettingSlipId,
            betPipeId,
          }),
        );
      }

      if (isGetAvailablePromotionsNotificationASuccess(state)) {
        return promotionsActions.getAvailablePromotionsWithCache({
          ...payload,
          ...{ askPromotion: true },
        });
      }
      return promotionsActions.getAvailablePromotions.request({
        ...payload,
        ...{ askPromotion: true },
      });
    }),
  );

export const reactToPromotionSuccessActionEpic: BettingEpic = (
  actions$,
  state$,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(
      isActionOf([
        promotionsActions.getAvailablePromotions.success,
        promotionsActions.getAvailablePromotionsWithCache,
      ]),
    ),
    withLatestFrom(state$),
    filter(([{ payload }]) => !!payload.askPromotion),
    map(([{ payload }, state]) => {
      const isUserLoggedIn = userLoggedInSelector(state);

      if (!isUserLoggedIn) {
        return {
          payload,
          request: null,
          state,
          isUserLoggedIn,
        };
      }
      const { bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );
      const list = getAvailablePromotionsListSelector(state)();
      if (list.length > 0) {
        return {
          payload,
          request: formatForBettingSlipPreparedRequest(
            prepareForBSGetterSelector(state)(bettingSlipId),
          ),
          state,
          isUserLoggedIn,
        };
      }

      return {
        payload,
        request: null,
        state,
        isUserLoggedIn,
      };
    }),
    mergeMap(({ payload, request, state, isUserLoggedIn }) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      if (!isUserLoggedIn) {
        return of(
          actions.promotionsForBettingSlip.failure(
            formatActionsData({
              data: {
                errorMessage: 'betting:promotion.error.userNotAuthenticated',
                status: DEFAULT_SERVER_ERROR_STATUS,
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }

      if (!request) {
        return of(
          actions.promotionsForBettingSlip.success(
            formatActionsData({
              data: {
                PromoIds: [],
                Notification: {
                  Level: NotificationLevel.Information,
                  Code: NotificationCode.Promo_Success,
                },
              },
              bettingSlipId,
              betPipeId,
            }),
          ),
        );
      }
      return wsAdapter.request(getPromoForBettingSlip(request), onTimeOut).pipe(
        takeUntilPipeIsCancelled(betPipeId, actions$),
        mapGuard(getPromoForBettingSlipResponseCodec),
        map((data) =>
          actions.promotionsForBettingSlip.success(
            formatActionsData({
              data,
              bettingSlipId,
              betPipeId,
            }),
          ),
        ),
        catchError((err) =>
          of(
            actions.promotionsForBettingSlip.failure(
              formatActionsData({
                data: createFailurePayload(err, commonBettingErrorMessages),
                bettingSlipId,
                betPipeId,
              }),
            ),
          ),
        ),
      );
    }),
  );

export const reactToPromotionFailActionEpic: BettingEpic = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(promotionsActions.getAvailablePromotions.failure)),
    withLatestFrom(state$),
    filter(([{ payload }]) => !!payload.askPromotion),
    map(([{ payload }, state]) => {
      const { betPipeId, bettingSlipId } = extractInformationForBettingSlip(
        payload,
        state,
      );

      return actions.promotionsForBettingSlip.failure(
        formatActionsData({
          data: {
            errorMessage:
              'betting:bettingSlip.PromotionsForBettingSlip.PromotionListError',
            status: DEFAULT_SERVER_ERROR_STATUS,
          },
          bettingSlipId,
          betPipeId,
        }),
      );
    }),
  );

export const reactToPromotionCancelActionEpic: BettingEpic = (
  actions$,
  state$,
) =>
  actions$.pipe(
    filter(isActionOf(actions.promotionsForBettingSlip.cancel)),
    withLatestFrom(state$),
    map(([{ payload }]) =>
      promotionsActions.getAvailablePromotions.cancel(payload),
    ),
  );
