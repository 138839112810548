import React, { FC, memo, useContext, useMemo } from 'react';

import { BettingSlipContext } from '../../BettingSlipContext';

import { BettingSlipFooterCombi } from './BettingSlipFooterCombi';
import { BettingSlipFooterSingle } from './BettingSlipFooterSingle';
import { BettingSlipFooterSystem } from './BettingSlipFooterSystem';
/**
 * Display the ticket content information footer
 *
 */
const BettingSlipFooterContentComponent: FC = () => {
  const { type } = useContext(BettingSlipContext);

  const content = useMemo(() => {
    switch (type) {
      case 'single':
        return <BettingSlipFooterSingle />;
      case 'combi':
        return <BettingSlipFooterCombi />;
      case 'system':
        return <BettingSlipFooterSystem />;
      default:
        return null;
    }
  }, [type]);

  return content;
};

export const BettingSlipFooterContent = memo(BettingSlipFooterContentComponent);
