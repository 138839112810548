import { darken, lighten } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  isColorDark,
  spaces,
} from '@gaming1/g1-style';
import { AppAnchor, SimpleButton } from '@gaming1/g1-ui';

const activeClassName = 'active';

// Remove a weird false positive
// "130:3  ✖  Expected selector ".selector11 svg path" to come before selector ".selector8.$dummyValue svg path""
/* stylelint-disable no-descending-specificity */

export const DrawerNavContainer = styled.div`
  background-color: ${colors('drawerNavBackground')};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DrawerNavTitle = styled.div`
  ${fontWeight('light')}
  color: ${colors('drawerNavText')};
  font-size: ${fontSizes('xl')};
`;

/* Menu */

export const MobileMenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: ${spaces('sm')};
`;

export const MobileMenuNavGroup = styled.div`
  & + & {
    margin-top: ${spaces('xs')};
  }
`;

type MobileMenuItemProps = {
  /** Index of the current links group */
  groupIndex?: number;
};

const MobileMenuItemCSS = css<MobileMenuItemProps>`
  color: ${({ groupIndex = 0, theme }) =>
    isColorDark(theme.colors.drawerNavText)
      ? lighten(groupIndex / 5)(theme.colors.drawerNavText)
      : darken(groupIndex / 5)(theme.colors.drawerNavText)};
  margin: ${spaces('sm')} 0;
  padding: ${spaces('xxs')} ${spaces('md')};
  text-decoration: none;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
  svg {
    margin-right: ${spaces('sm')};
    path {
      fill: ${({ groupIndex = 0, theme }) =>
        isColorDark(theme.colors.drawerNavText)
          ? lighten(groupIndex / 5)(theme.colors.drawerNavText)
          : darken(groupIndex / 5)(theme.colors.drawerNavText)};
    }
  }
`;

export const MobileMenuButton = styled(SimpleButton)`
  ${MobileMenuItemCSS}
`;

export const MobileMenuAnchor = styled(AppAnchor).withConfig({
  shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
})<MobileMenuItemProps>`
  ${MobileMenuItemCSS}
`;

export const MobileMenuLink = styled(NavLink)
  .withConfig({
    shouldForwardProp: (prop) => !['groupIndex'].includes(prop),
  })
  .attrs({
    activeClassName,
  })<MobileMenuItemProps>`
  ${MobileMenuItemCSS}
  &.${activeClassName}, &:focus,
  &:active,
  &:hover {
    color: ${colors('drawerNavActiveText')};
    border-color: ${colors('drawerNavActiveText')};
    svg path {
      fill: ${colors('drawerNavActiveText')};
    }
  }
`;

/* Locale */

export const LocaleListLabelP = styled.p`
  color: ${colors('muted')};
  padding: ${spaces('xs')} ${spaces('sm')};
  font-size: ${fontSizes('sm')};
`;

export const LocaleListContainer = styled.div`
  padding: ${spaces('sm')};
  background-color: ${({ theme }) =>
    isColorDark(theme.colors.drawerNavBackground)
      ? lighten(0.05)(theme.colors.drawerNavBackground)
      : darken(0.05)(theme.colors.drawerNavBackground)};

  display: flex;
`;

type LocaleButtonProps = {
  active: boolean;
};

export const LocaleButton = styled(SimpleButton)<LocaleButtonProps>`
  color: ${colors('drawerNavText')};
  font-size: ${fontSizes('md')};

  & + & {
    margin-left: ${spaces('sm')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors('drawerNavActiveText')};
    `}
`;

/* Links */

export const LinksContainer = styled.div`
  margin-top: ${spaces('md')};
  border-top: 1px solid ${colors('separator')};
  border-bottom: 1px solid ${colors('separator')};
  padding: ${spaces('md')};
`;

export const LinksRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: ${spaces('sm')};
  }
`;

const BottomLinksItemCSS = css`
  color: ${colors('drawerNavText')};
  text-decoration: none;
  font-size: ${fontSizes('sm')};
`;

export const BottomLinksButton = styled(SimpleButton)`
  ${BottomLinksItemCSS}
`;

export const BottomLinksAnchor = styled(AppAnchor)`
  ${BottomLinksItemCSS}
`;

export const BottomLinksLink = styled(NavLink)`
  ${BottomLinksItemCSS}

  &.${activeClassName}, &:focus,
  &:active,
  &:hover {
    color: ${colors('drawerNavActiveText')};
  }
`;

export const BottomLinkSeparator = styled.span`
  background-color: ${colors('drawerNavText')};
  height: 3px;
  width: 3px;
  border-radius: 50%;
  &:last-child {
    display: none;
  }
`;

/* Footer */

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${spaces('md')};
`;
