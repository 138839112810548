import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { UserCredit, UserProfile } from './types';

/** Triggered when the user is logged in */
export const loggedInUser = createAction('core/user_logged_in')();

/** Triggered when the user is logged out */
export const loggedOutUser = createAction(
  'core/user_logged_out',
  (previouslyLoggedIn: boolean) => previouslyLoggedIn,
)();

/**
 * Start polling aggressivly the user credit to ensure the updated value is
 * retrieved
 */
export const startPollingUserCredit = createAction(
  'core/start_polling_user_credit',
)();

/**
 * Stop polling aggressivly the user credit. This action is automatically
 * dispatched by the epic handling the polling.
 */
export const stopUserCreditIntensePolling = createAction(
  'core/stop_polling_user_credit',
)();

/**
 * User clicked refresh button to manually refresh credits.
 */
export const clickRefreshCredit = createAction('core/refresh_credit_click')();

/** Get user profile */
export const getUserProfile = createAsyncAction(
  'core/get_user_profile_request',
  'core/get_user_profile_success',
  'core/get_user_profile_failure',
)<void, UserProfile, FailurePayload>();

/** Get user credit */
export const getUserCredit = createAsyncAction(
  'core/get_user_credit_request',
  'core/get_user_credit_success',
  'core/get_user_credit_failure',
)<
  {
    /** Must be true if the action is triggered by a button */
    isTriggeredByUser?: boolean;
    /** If true, the backend will make more checks, making the response more reliable but more costly */
    forceRefresh: boolean;
  },
  UserCredit,
  FailurePayload
>();

/** Action triggered when the user credit is updated via push */
export const updateUserCredit = createAction(
  'core/update_user_credit',
)<UserCredit>();
