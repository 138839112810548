import styled, { css } from 'styled-components';

import {
  colors,
  elevation,
  fontSizes,
  fontWeight,
  spaces,
} from '@gaming1/g1-style';

import { Box } from '../Box';

export type PanelWrapperProps = {
  borderRadius?: string;
  isExpanded?: boolean;
};

export const PanelWrapper = styled(Box)<PanelWrapperProps>`
  ${({ isExpanded, theme }) =>
    elevation(2, { noShadow: !isExpanded })({ theme })}

  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}` : `4px`};
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const paddingStyles = css`
  padding: ${spaces('xs')};
`;

export const PanelSummaryWrapper = styled.div<{
  hasSeparator?: boolean;
  isExpanded?: boolean;
}>`
  ${paddingStyles}

  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${colors('muted')};
  font-size: ${fontSizes('lg')};
  ${fontWeight('bold')};

  svg {
    flex-shrink: 0;
    flex-basis: 16px;
  }

  ${({ isExpanded, hasSeparator }) =>
    isExpanded &&
    hasSeparator &&
    css`
      border-bottom: 1px solid ${colors('muted')};
    `}
`;

export const PanelDetails = styled.div`
  ${paddingStyles}

  margin-bottom: ${spaces('xs')};
  width: 100%;
  color: ${colors('text')};
  font-size: ${fontSizes('md')};
`;
