import React, {
  BaseHTMLAttributes,
  createContext,
  ReactChild,
  ReactNode,
  useContext,
} from 'react';

import {
  ColumnWidth,
  getBreakpointFromWidth,
  MediaBreakPointNames,
  MediaBreakPointsEnum,
} from '@gaming1/g1-style';

import { Col, ColProps, ColSystemProps } from '../Col';
import { LayoutContext } from '../LayoutProvider/LayoutContext';

export const InnerBPContext = createContext<MediaBreakPointNames | null>(null);

export type MediaColInjectedProps = {
  /** Inner breakpoint */
  innerBP: MediaBreakPointNames;
  /** Width of the column (from 1 to 12) */
  colSize: ColumnWidth;
  /** Media breakpoint */
  mediaBP: MediaBreakPointNames;
};

export type ChildrenRenderer = (props: MediaColInjectedProps) => ReactNode;

export type MediaColProps = {
  /** Either a react node or function as child */
  children: ChildrenRenderer | ReactChild;
};

const isChildrenRenderer = (children: unknown): children is ChildrenRenderer =>
  typeof children === 'function';
/**
 * Component that either act in the same way as Col or, when used with a
 * function as child, provide the "inner breakpoint" of its children
 * can also be used with the InnerBPContext set by it
 */
export const MediaCol = ({
  children,
  lg,
  md,
  sm,
  xl,
  xs,
  xxl,
  ...rest
}: MediaColProps &
  ColProps &
  ColSystemProps &
  BaseHTMLAttributes<HTMLDivElement>) => {
  const xsCols = xs === undefined ? 12 : xs;
  const smCols = sm === undefined ? xsCols : sm;
  const mdCols = md === undefined ? smCols : md;
  const lgCols = lg === undefined ? mdCols : lg;
  const xlCols = xl === undefined ? lgCols : xl;
  const xxlCols = xxl === undefined ? xlCols : xxl;
  let childRender: ReactNode | null = null;
  let computedColumns: ColumnWidth;
  const { media } = useContext(LayoutContext);
  switch (media) {
    case 'xs':
      computedColumns = xsCols;
      break;
    case 'sm':
      computedColumns = smCols;
      break;
    case 'md':
      computedColumns = mdCols;
      break;
    case 'lg':
      computedColumns = lgCols;
      break;
    case 'xl':
      computedColumns = xlCols;
      break;
    case 'xxl':
      computedColumns = xxlCols;
      break;
    default:
      computedColumns = xsCols;
  }
  const minWidth = MediaBreakPointsEnum[media];
  const colWidth = (minWidth / 12) * computedColumns;
  const breakPoint = getBreakpointFromWidth(colWidth);
  if (isChildrenRenderer(children)) {
    /* The component is used with a function as child => we need to pass the
      computed media size . Else component is used a simple Col */
    childRender = children({
      innerBP: breakPoint.name,
      colSize: computedColumns,
      mediaBP: media,
    });
  }
  return (
    <Col
      lg={lgCols}
      md={mdCols}
      sm={smCols}
      xl={xlCols}
      xs={xsCols}
      xxl={xxlCols}
      {...rest}
    >
      <InnerBPContext.Provider value={breakPoint.name}>
        {childRender || children}
      </InnerBPContext.Provider>
    </Col>
  );
};
