import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  colors,
  device,
  fontSizes,
  fontWeight,
  spaces,
} from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

export const DrawerNavContainer = styled.div`
  background-color: ${colors('drawerNavBackground')};
  min-height: 100%;
`;

export const EasyAccessContainer = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
`;

type EasyAccessProps = {
  hasToTakeFullWidth?: boolean;
};

export const EasyAccessItem = styled.li<EasyAccessProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    padding-left: 1px;
  }

  ${({ hasToTakeFullWidth }) =>
    hasToTakeFullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: 50%;
        `}
`;

const AllSportMenuItemBaseCss = css`
  background-color: ${colors('backgrounds', { index: 1 })};
  display: flex;
  padding: ${spaces('sm')};
  align-items: center;
  font-size: ${fontSizes('md')};
  transition: border-bottom 0.2s, color 0.2s;
  cursor: pointer;
  color: ${colors('scrollNavItemForeground')};
  text-decoration: none;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  ${device.canHover} {
    &:hover {
      color: ${colors('drawerNavText')};
    }
  }
`;

export const AllSportsMenuLink = styled(NavLink)`
  ${AllSportMenuItemBaseCss}
`;

export const EasyAccessLink = styled(AllSportsMenuLink)<EasyAccessProps>`
  background-color: ${colors('backgrounds', { index: 2 })};
  justify-content: space-between;
  margin-bottom: 1px;
`;

export const EasyAccessButton = styled(SimpleButton)`
  ${AllSportMenuItemBaseCss}
  justify-content: space-between;
`;

export const AllSportsSubtitle = styled.p`
  margin: 0;
  margin-bottom: 1px;
  padding: ${spaces('sm')};
  font-size: ${fontSizes('md')};
  color: ${colors('drawerNavText')};
  background-color: ${colors('backgrounds', { index: 1 })};
  ${fontWeight('bold')};
`;

export const AllSportTitle = styled.div`
  ${fontWeight('light')}
  color: ${colors('drawerNavText')};
  font-size: ${fontSizes('xl')};
`;
