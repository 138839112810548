import React, { FC, memo, useContext } from 'react';

import {
  actions,
  getHasFrontNotificationError,
  useBettingSlipAllowActions,
  useBettingSlipRankErrorFrontType,
} from '@gaming1/g1-betting';
import { Box } from '@gaming1/g1-ui';

import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';

type BettingSlipRankErrorProps = {
  /** id to find rank */
  id: string;
};

const BettingSlipRankErrorComponent: FC<BettingSlipRankErrorProps> = ({
  id,
}) => {
  const { bettingSlipId } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const notification = useBettingSlipRankErrorFrontType(bettingSlipId)(id);
  const hasFrontNotificationError = getHasFrontNotificationError(notification);
  const testId = `bettingslip-error-stake-system-${notification.Status}`;
  const updateStake = (newStake: string) => {
    allowBSActions(
      actions.updateStakeForRank({
        stake: newStake,
        rankId: id,
        bettingSlipId,
      }),
    );
  };

  const callToActionsFunc = {
    updateStake,
  };

  return hasFrontNotificationError ? (
    <Box mb="sm" data-testid="bettingslip-system-error-container" width="100%">
      <BettingSlipMessage
        testId={testId}
        notification={notification}
        callToActionsFunc={callToActionsFunc}
      />
    </Box>
  ) : null;
};

export const BettingSlipRankError = memo(BettingSlipRankErrorComponent);
