import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { ApplicationWithBettingState } from '../store/types';

export const getCashoutStateSelector = (state: ApplicationWithBettingState) =>
  state.betting.cashout.entities.cashout;

export const getCashoutEntityGetterSelector = createSelector(
  getCashoutStateSelector,
  (getCashoutState) => memoize((id: string) => getCashoutState[id]),
);

export const getCashoutResultGetterSelector = createSelector(
  getCashoutEntityGetterSelector,
  (getCashoutEntity) => memoize((id: string) => getCashoutEntity(id)?.result),
);

export const hasCashoutEntityGetterSelector = createSelector(
  getCashoutStateSelector,
  (getCashoutState) =>
    memoize((id: string | undefined) => id && !!getCashoutState[id]),
);

export const getCashoutInformationGetterSelector = createSelector(
  getCashoutResultGetterSelector,
  (getCashoutResult) =>
    memoize((id: string) => getCashoutResult(id)?.CashoutInformation),
);

export const getCashoutDataGetterSelector = createSelector(
  getCashoutResultGetterSelector,
  (getCashoutEntity) =>
    memoize((id: string | undefined) => {
      if (!id) {
        return null;
      }
      const cashoutEntity = getCashoutEntity(id);
      if (cashoutEntity && 'CashedOutData' in cashoutEntity) {
        return cashoutEntity.CashedOutData;
      }
      return null;
    }),
);

/*
 * ForRequest
 */

/**
 * Get the current state of the request slice of the cashout
 */
export const getCashoutRequestStateSelector = createSelector(
  getCashoutEntityGetterSelector,
  (getCashoutEntity) =>
    memoize((id: string) => getCashoutEntity(id)?.requests.cashout),
);

/**
 * Get the status of the latest cashout request
 */
export const getCashoutRequestStatusSelector = createSelector(
  getCashoutRequestStateSelector,
  (getCashoutRequest) => memoize((id: string) => getCashoutRequest(id)?.status),
);

/**
 * GetCashoutInfo
 */

/**
 * Get the current state of the request slice of the getCashoutInfo
 */
export const getCashoutInfoRequestStateSelector = createSelector(
  getCashoutEntityGetterSelector,
  (getCashoutEntity) =>
    memoize((id: string) => getCashoutEntity(id).requests.getCashoutInfo),
);

export const getCashoutInfoRequestStatusSelector = createSelector(
  getCashoutInfoRequestStateSelector,
  (getCashoutRequest) => memoize((id: string) => getCashoutRequest(id).status),
);
