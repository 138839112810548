import { EBettingSlipOddChangeSettings } from '@gaming1/g1-requests-betting';
import { isNonNullable } from '@gaming1/g1-utils';

import { NormalizedOutcome } from '../../../sportbook/store/types';
import { BettingSlipItemState } from '../types/common';

export const extractOutcomeListId = (items: BettingSlipItemState[]): number[] =>
  items.map((item) => item.outcome.id) || [];

export const extractOddsFromOutcomes = (
  outcomes: (NormalizedOutcome | undefined)[],
): number[] =>
  outcomes
    .filter(isNonNullable)
    .filter((outcome) => outcome.Odd >= 1)
    .map((outcome) => outcome.Odd);

export const convertStakeToFloat = (stake?: string): number | undefined => {
  if (!stake) {
    return undefined;
  }
  return parseFloat(stake);
};

export const extractPreferenceForPlaceBet = () => ({
  OddChangeSettings: EBettingSlipOddChangeSettings.AllOdds,
  SaveUserPreferences: false,
});
