import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { AuthenticationState } from './types';

export const initialState: AuthenticationState = {
  uploadDocumentStatus: null,
  documentStatus: null,
  requests: {
    getUploadDocumentStatus: generateInitialRequestState(null),
    getDocumentStatus: generateInitialRequestState(null),
    sendDocument: generateInitialRequestState(null),
  },
};

export const authenticationReducer = (
  state: AuthenticationState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Get document status */

      case getType(actions.getDocumentStatus.request):
        produceLoadingState(draftState.requests.getUploadDocumentStatus);
        break;

      case getType(actions.getDocumentStatus.failure):
        produceFailureState(
          draftState.requests.getUploadDocumentStatus,
          action.payload,
        );
        break;

      case getType(actions.getDocumentStatus.success):
        produceSuccessState(draftState.requests.getUploadDocumentStatus);
        draftState.uploadDocumentStatus = action.payload;
        break;

      /** Get document status summary */

      case getType(actions.getDocumentStatusSummary.request):
        produceLoadingState(draftState.requests.getDocumentStatus);
        break;

      case getType(actions.getDocumentStatusSummary.failure):
        produceFailureState(
          draftState.requests.getDocumentStatus,
          action.payload,
        );
        break;

      case getType(actions.getDocumentStatusSummary.success):
        produceSuccessState(draftState.requests.getDocumentStatus);
        draftState.documentStatus = action.payload;
        break;

      /** Send document */

      case getType(actions.sendDocument.request):
        produceLoadingState(draftState.requests.sendDocument);
        break;

      case getType(actions.sendDocument.failure):
        produceFailureState(draftState.requests.sendDocument, action.payload);
        break;

      case getType(actions.sendDocument.success):
        produceSuccessState(draftState.requests.sendDocument);
        break;

      default: // Immer will automatically return the state
    }
  });
