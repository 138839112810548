import * as t from 'io-ts';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

/**
 * Create a new codec (from io-ts) from an existing one  but with each property
 * changed to an array of string.
 * This is used to get a "normalized" codec, with each nested array of object
 * changed into an array of the object ids
 * @param codec The original codec
 * @param properties A list of properties to be transformed
 * @param [name] An optional name for the new codec
 */
export const normalizeCodec = <P extends t.Props, K extends [...(keyof P)[]]>(
  codec: t.TypeC<P>,
  properties: K,
  name?: string,
) => {
  //  [key in Exclude<keyof P, K[number]>]: P[key];

  type StringCodec = typeof t.string;
  const stringsArrayCodec = t.array(t.string);
  type StringsArrayCodec = typeof stringsArrayCodec;

  // Props that will remain as is
  const keptProps = omit(codec.props, properties) as Pick<
    P,
    Exclude<keyof P, K[number]>
  >;

  // Props that will be changed to (an array of) string(s)
  const newProps = properties.reduce(
    (acc, prop) => ({
      ...acc,
      [prop]:
        // eslint-disable-next-line no-underscore-dangle
        codec.props[prop] instanceof t.ArrayType ? t.array(t.string) : t.string,
    }),
    // Only way to type correctly the return type of a reduced value
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as {
      [k2 in K[number]]: P[k2] extends t.ArrayType<t.Any>
        ? StringsArrayCodec
        : StringCodec;
    },
  );
  const normalizedProps: typeof keptProps & typeof newProps = merge(
    keptProps,
    newProps,
  );
  return t.type(normalizedProps, name);
};

/*
  TODO: create a way to make t.Type<typeof partializeCodec(x)> usable (i.e. no
  union type), maybe by using function overloading
*/
/**
 * Create a new codec (from io-ts) from an existing one but with some properties
 * being marked as optional.
 * @param codec The original codec
 * @param properties A list of properties to be marked as optional
 * @param [name] An optional name for the new codec
 */
export const partializeCodec = <P extends t.Props, K extends keyof P>(
  codec: t.TypeC<P>,
  properties: K[],
  name?: string,
) => {
  const optionalProps = pick(codec.props, properties);
  const requiredProps = omit(codec.props, properties);
  // All properties are optional
  if (!Object.keys(requiredProps).length && Object.keys(optionalProps).length) {
    return t.partial(optionalProps, name);
  }
  // Some properties remains required
  if (Object.keys(requiredProps).length && Object.keys(optionalProps).length) {
    return t.intersection(
      [t.type(requiredProps), t.partial(optionalProps)],
      name,
    );
  }
  // The codec is returned as if
  return codec;
};

// From https://github.com/gcanti/io-ts/issues/216#issuecomment-471497998
/**
 * Codec type for io-ts codecs
 */
export class EnumType<A> extends t.Type<A> {
  public readonly _tag: 'EnumType' = 'EnumType';

  public enumObject: Record<string, unknown>;

  public constructor(e: Record<string, unknown>, name?: string) {
    super(
      name || 'enum',
      (u): u is A => Object.values(this.enumObject).some((v) => v === u),
      (u, c) => (this.is(u) ? t.success(u) : t.failure(u, c)),
      t.identity,
    );
    this.enumObject = e;
  }
}

export const createEnumType = <T>(e: Record<string, unknown>, name?: string) =>
  new EnumType<T>(e, name);

const responseWithStatus = t.type({
  Status: t.number,
});

export const isResponseWithStatus = responseWithStatus.is;
