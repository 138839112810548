import {
  EGetVipInitializationStatus,
  EPlayMonthlyBoxStatus,
  EPlayVipStatus,
  GetPlayerMonthlyBoxInfoStatus,
  GetPlayerVipRankInfoStatus,
  GetPlayerWheelInfoStatus,
  GetPrizeInfoStatus,
} from '@gaming1/g1-requests';

export const vipInitializeErrorMessages = {
  [EGetVipInitializationStatus.InternalError]: 'core:error.internalServer', // 0
  [EGetVipInitializationStatus.RoomNotFound]: 'core:error.invalidRoom', // 2
  [EGetVipInitializationStatus.PlayerNotFound]: 'core:error.invalidPlayer', // 3
  [EGetVipInitializationStatus.WrongRoomForPlayer]:
    'core:error.WrongRoomForPlayer', // 4
};

export const playVipWheelErrorMessages = {
  [EPlayVipStatus.InternalError]: 'core:error.internalServer', // 0
  [EPlayVipStatus.AlreadyPlayed]: 'core:error.alreadyParticipated', // 2
  [EPlayVipStatus.InvalidWheelId]: 'core:error.invalidWheelId', // 3
  [EPlayVipStatus.PlayerNoFound]: 'core:error.invalidPlayer', // 4
};

export const playMonthlyBoxErrorMessages = {
  [EPlayMonthlyBoxStatus.InternalError]: 'core:error.internalServer', // 0
  [EPlayMonthlyBoxStatus.AlreadyPlayed]: 'core:error.alreadyParticipated', // 2
  [EPlayMonthlyBoxStatus.NoMarketingAction]: 'loyalty:error.noMarketingAction', // 3
  [EPlayMonthlyBoxStatus.RoomNotFound]: 'core:error.invalidRoom', // 4
  [EPlayMonthlyBoxStatus.PlayerNotFound]: 'core:error.invalidPlayer', // 5
  [EPlayMonthlyBoxStatus.WrongRoomForPlayer]: 'core:error.wrongRoomForPlayer', // 6
};

export const getPlayerWheelInfoErrorMessages = {
  [GetPlayerWheelInfoStatus.InternalError]: 'core:error.internalServer', // 0
  [GetPlayerWheelInfoStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [GetPlayerWheelInfoStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
};

export const getPlayerMonthlyBoxInfoErrorMessages = {
  [GetPlayerMonthlyBoxInfoStatus.InternalError]: 'core:error.internalServer', // 0
  [GetPlayerMonthlyBoxInfoStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [GetPlayerMonthlyBoxInfoStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
};

export const getPlayerVipRankInfoErrorMessages = {
  [GetPlayerVipRankInfoStatus.InternalError]: 'core:error.internalServer', // 0
  [GetPlayerVipRankInfoStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [GetPlayerVipRankInfoStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
};

export const getScratchCardPrizeInfoErrorMessages = {
  [GetPrizeInfoStatus.InternalError]: 'core:error.internalServer', // 0
  [GetPrizeInfoStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
  [GetPrizeInfoStatus.InvalidRoom]: 'core:error.invalidRoom', // 3
};
