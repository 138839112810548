import flow from 'lodash/flow';
import identity from 'lodash/identity';
import { getLuminance, readableColor } from 'polished';
import {
  AnyStyledComponent,
  DefaultTheme,
  ThemeProps,
  css as webCss,
} from 'styled-components';

import {
  ELEVATION_SHADOW_VALUES,
  ElevationLevel,
  IS_REACT_NATIVE,
} from './constants';
import { ColorName } from './types';
import { FontType, FontWeight } from './types/theme';

const css = webCss;

// From polished
const STRICT_LUMINANCE_THRESHOLD_VALUE = 0.179;
/**
 * Luminance needed for a color to be considered "light", thus needing a dark
 * text color on top of it to be readable. The default value in polished is
 * 0.179 but it's not enough for our needs and with this value we end up with a
 * lot of dark text on darkish colors
 */
const LUMINANCE_THRESHOLD_VALUE = 0.275;

type PropsWithTheme = ThemeProps<DefaultTheme>;

type FontSizeIndex = 0 | 1 | 2 | 3 | 4 | 5; // keyof Theme['fontSizes']
type FontSizeNames = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'; // keyof FontSizesWithAliases
type OpacityIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7; // keyof Theme['opacity']
type OpacityNames =
  | 'transparent'
  | 'barelyVisible'
  | 'translucent'
  | 'semi'
  | 'seeThrough'
  | 'obscure'
  | 'opaque'; // keyof OpacityWithAliases
type ShadowIndex = 0 | 1 | 2 | 3 | 4; // keyof Theme['shadows']
type SpaceIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9; // keyof Theme['space']
type SpaceNames =
  | 'none'
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl'; // keyof SpaceWithAliases
type ZIndicesIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10; // keyof Theme['zIndices']
type ZIndicesNames =
  | 'behind'
  | 'fullscreen'
  | 'bottomnav'
  | 'popover'
  | 'helpWidget'
  | 'cookieMessage'
  | 'backdrop'
  | 'drawer'
  | 'modal'
  | 'notification'
  | 'devTool'; // keyof ZIndicesWithAliases
type ZIndiceIncrementLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback transform
 * @param {string} inputColor
 * @param {string} outputColor
 */

type ColorFunctionOptions = {
  /**
   * Defaults at 0
   */
  index?: number;
  /**
   * a transformator. Really convenient with `polished` curried color helpers
   */
  transform?: (color: string) => string;
};

// This rules throw a false positive when doing function overloading
/* eslint-disable import/export */

/**
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract a color from the theme
 *
 * ### Usage
 *
 * @example
 * // Basic usage:
 * const Component = styled.div`
 *   color: ${colors('mainBackground')};
 * `;
 *
 * @example
 * // Usage with array colors:
 * const Component = styled.div`
 *   color: ${colors('neutrals', { index: 1 })};
 * `;
 *
 * @example
 * // Usage with array colors:
 * import { lighten } from 'polished';
 * // ...
 * const Component = styled.div`
 *   color: ${colors('primary', { transform: lighten(0.1) })};
 * `;
 *
 * @param {ColorName} name the color name from a theme
 * @param {ColorFunctionOptions} [options]
 * @param {number} [options.index] the index of the color if it is an array
 * @param {transform} [options.transform] the transform function to apply to the
 *  color
 */
export const colors = (
  name: ColorName,
  { index = 0, transform = identity }: ColorFunctionOptions = {},
) =>
  flow(({ theme }: PropsWithTheme) => {
    const color = theme.colors[name];
    return typeof color === 'string' ? color : color[index];
  }, transform);

/**
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the spacing values from the theme
 *
 * @example
 * // Usage with index
 * const Component = styled.div`
 *   margin: ${spaces('xs')};
 * `;
 *
 * @example
 * // Usage with alias
 * const Component = styled.div`
 *   margin: ${spaces('xs')};
 * `;
 *
 * @param spacingLevel index (0-9)  or alias ('none', 'xxxs', 'xxs', 'xs', sm',
 *  'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl') of the space array in the theme
 */
export const spaces =
  (spacingLevel: SpaceIndex | SpaceNames) =>
  ({ theme: { space } }: PropsWithTheme) =>
    `${space[spacingLevel]}px`;

/**
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the shadow values from the theme
 *
 * @example
 * // Usage with index
 * const Component = styled.div`
 *   box-shadow: ${shadow(2)};
 * `;
 *
 * @param shadowLevel index (0-9) of the space array in the theme
 */
export const shadows =
  (shadowLevel: ShadowIndex) =>
  ({ theme }: PropsWithTheme) =>
    theme.shadows[shadowLevel];

/**
 *
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the font sizes values from the theme
 *
 *
 * @example
 * // Usage with index
 * const Component = styled.div`
 *   font-size: ${fontSizes(2)};
 * `;
 *
 * @example
 * // Usage with alias
 * const Component = styled.div`
 *   font-size: ${fontSizes('lg')};
 * `;
 *
 * @param fontSize index (0-5) or alias ('sm', 'md', 'lg', 'xl', 'xxl', 'xxxl')
 *  of the fontSizes array in the theme
 */
export const fontSizes =
  (fontSize: FontSizeIndex | FontSizeNames) =>
  ({ theme: { fontSizes: themeFontSizes } }: PropsWithTheme) =>
    `${themeFontSizes[fontSize]}px`;

/**
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the gutter size from the theme
 *
 * @example
 * // Usage
 * const Component = styled.div`
 *   padding: ${gutter};
 * `;
 */
export const gutter = ({ theme: { sizes } }: PropsWithTheme) =>
  `${sizes.gutter}px`;

/**
 *
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the opacity values from the theme
 *
 *
 * @example
 * // Usage with index
 * const Component = styled.div`
 *   opacity: ${opacity(2)};
 * `;
 *
 * @example
 * // Usage with alias
 * const Component = styled.div`
 *   opacity: ${opacity('translucent')};
 * `;
 *
 * @param opacityLevel index (0-7) or alias ('transparent', 'barelyVisible',
 *  'translucent', 'semi', 'seeThrough', 'obscure', 'opaque') of the opacity
 *  array in the theme
 */
export const opacity =
  (opacityLevel: OpacityIndex | OpacityNames) =>
  ({ theme: { opacity: opacityObject } }: PropsWithTheme) =>
    opacityObject[opacityLevel];

/**
 *
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the z-index values from the theme
 *
 *
 * @example
 * // Usage with index
 * const Component = styled.div`
 *   z-index: ${zIndex(4)};
 * `;
 *
 * @example
 * // Usage with alias
 * const Component = styled.div`
 *   z-index: ${zIndex('modal')};
 * `;
 *
 * @param zIndice index (0-10) or alias ('behind', 'fullscreen', 'backdrop',
 *  'drawer', 'modal', 'notification', 'devTool') of the zIndices array in
 *  the theme
 * @param [incrementLevel] optional number between 0 and 9 to increment the
 *  z-index number
 */
export const zIndex =
  (
    zIndice: ZIndicesIndex | ZIndicesNames,
    incrementLevel: ZIndiceIncrementLevel = 0,
  ) =>
  ({ theme: { zIndices } }: PropsWithTheme) =>
    zIndices[zIndice] + incrementLevel;

/**
 * Styled component helper made to improve DX and SC readability
 * It can be used to extract the shadow values from the theme on React Native
 *
 * @param level index (0-4) of the elevation level
 * @param shadowColor (iOS only) color of the shadow
 */
export const elevationShadow = (
  level: ElevationLevel,
  shadowColor = '#000000',
) => {
  if (!IS_REACT_NATIVE) {
    return null;
  }
  const {
    height,
    opacity: shadowOpacity,
    radius,
  } = ELEVATION_SHADOW_VALUES[level];
  // See https://github.com/styled-components/styled-components/issues/709#issuecomment-295275573
  return css`
    /* stylelint-disable */
    elevation: ${level};
    shadow-color: ${shadowColor};
    shadow-offset: /* width: */ 0px /* height: */ ${height}px;
    shadow-opacity: ${shadowOpacity};
    shadow-radius: ${radius};
    /* stylelint-enable */
  `;
};

/**
 * Elevate an element, applying those properties, depending on the elevation
 *   level:
 * - background-color
 * - box-shadow (if `noShadow` is not true)
 * - border (if `noBorder` is not true and the elevationBorderLevel match with
 * the desired elevation level)
 *
 * @param level elevation level (0-4)
 */
export const elevation =
  (
    level: ElevationLevel,
    options: { noBorder?: boolean; noShadow?: boolean } = {
      noBorder: false,
      noShadow: false,
    },
  ) =>
  /* keyof Theme['colors']['backgrounds'] */ ({
    theme,
  }: {
    theme: DefaultTheme;
  }) =>
    css`
      background-color: ${theme.colors.backgrounds[level]};
      /* Shadows for web */
      ${!options.noShadow &&
      !!theme.shadows[level] &&
      !IS_REACT_NATIVE &&
      css`
        box-shadow: ${theme.shadows[level]};
      `}
      /* Shadows for RN */
  ${IS_REACT_NATIVE &&
      !options.noShadow &&
      css`
        ${elevationShadow(level)}
      `}
  /* Border */
  ${!!theme.options.elevationBorderLevel &&
      level >= theme.options.elevationBorderLevel &&
      !options.noBorder &&
      css`
        border: ${theme.options.contentBorderWidth} solid
          ${theme.colors.contentBorder};
      `}
    `;

/**
 * Utility to get the style from a styled-component in the DOM
 * Should only be used in tests
 * @param styledComponent The styled component
 */
export const getStyledComponentStyles = (
  styledComponent: AnyStyledComponent,
) => {
  const componentClass = styledComponent.toString();
  const componentElement = document.querySelector(componentClass);
  return componentElement &&
    componentElement.ownerDocument &&
    componentElement.ownerDocument.defaultView
    ? componentElement.ownerDocument.defaultView.getComputedStyle(
        componentElement,
      )
    : null;
};

/**
 *
 * Styled component helper made to improve DX and SC readability
 * It can be used to change the font weight by changing both the font-family
 * and font-weight properties. This is because some fonts families have a
 * specific fonts for bolder/lighter weights (and the font-weight stays the same)
 * Other fonts keeps the same family but the font-weight must be changed.
 *
 * @example
 * // Usage with only weight
 * const Component = styled.p`
 *   ${fontWeight('bold')};
 * `;
 *
 * @example
 * // Usage with both weigth and type
 * const Component = styled.h3`
 *   ${fontWeight('light', 'title')};
 * `;
 *
 * @param weight the font weigth: 'regular', 'light' or 'bold'
 * @param type the type of text, in case the theme has a different font for
 * content or title texts: 'content' or 'title'. Default to 'content'.
 */
export const fontWeight =
  (weight: FontWeight, type: FontType = 'content') =>
  ({ theme: { fontFamilies, fontWeights } }: PropsWithTheme) =>
    css`
      ${!IS_REACT_NATIVE &&
      css`
        // Don't set font-family for RN
        font-family: '${fontFamilies[type][weight]}', sans-serif;
      `}
      font-weight: ${fontWeights[type][weight]};
    `;

/**
 * Determine if a color is dark or light, according to the W3C specs for
 * readability
 * @param color the color code
 */
export const isColorDark = (color: string, strict = true) => {
  if (color === 'transparent') {
    return false;
  }
  return (
    getLuminance(color) <
    (strict ? STRICT_LUMINANCE_THRESHOLD_VALUE : LUMINANCE_THRESHOLD_VALUE)
  );
};

/**
 * Returns a readable text color based on the background color name passed to it
 * @param {ColorName} backgroundColorName the name of the color from the theme
 * @param {ColorFunctionOptions} [options]
 * @param {number} [options.index] the index of the color if it is an array
 * @param {transform} [options.transform] the transform function to apply to the color
 * @param {boolean} isLuminanceThresholdEnabled enable a less strict call to readableColor
 */
export const textColorForBackground =
  (
    backgroundColorName: ColorName,
    { index = 0, transform = identity }: ColorFunctionOptions = {},
    isLuminanceThresholdEnabled = false,
  ) =>
  (propsWithTheme: PropsWithTheme) => {
    const backgroundColor = colors(backgroundColorName, { index, transform })(
      propsWithTheme,
    );

    // In contrast to the W3C specs that the readableColor function follows,
    // the UX team requested more tolerance for the colour white.
    const luminanceThresholdValue = isLuminanceThresholdEnabled
      ? LUMINANCE_THRESHOLD_VALUE
      : 0;

    return getLuminance(backgroundColor) > luminanceThresholdValue
      ? readableColor(
          backgroundColor,
          propsWithTheme.theme.colors.textDark,
          propsWithTheme.theme.colors.textLight,
        )
      : propsWithTheme.theme.colors.textLight;
  };

type GetReadableColorOptions = {
  /** The dark color code (will use the `theme.textDark` by default) */
  darkColor?: string;
  /** The index used for "array" colors such as `primary` or `backgrounds` */
  index?: number;
  /** The light color code (will use the `theme.textLight` by default) */
  lightColor?: string;
  /** Luminance needed for a color to be considered light. Defaults to 0.275 */
  luminanceThresholdValue?: number;
  /** A transformator which will be given the hexa color code as input */
  transform?: (color: string) => string;
};

/**
 * Get a readable text color for the given background color
 *
 * ### Common usage
 *
 * The following example sets the background as `primary` then uses this helper
 * to determine which color will be the most readable over this background.
 *
 * ts```
 * const Element = style.div`
 *  background-color: ${colors('primary')};
 *  color: ${getReadableColor('primary')};
 * `;
 * ```
 *
 * @param {string} backgroundColor the background color name from theme
 * @param {string} [options.darkColor] the dark color code (will use the
 *   textDark from the theme by default)
 * @param {number} [options.index] the index used for "array" colors such as
 *   `primary` or `backgrounds`
 * @param {string} [options.lightColor] the light color code (will use the
 *   textLight from the theme by default)
 * @param {number} [options.luminanceThresholdValue] luminance needed for a
 *   color to be considered light. Defaults to 0.275
 */
export const getReadableColor =
  (
    backgroundColor: ColorName,
    {
      darkColor,
      index = 0,
      lightColor,
      luminanceThresholdValue = LUMINANCE_THRESHOLD_VALUE,
      transform = identity,
    }: GetReadableColorOptions = {},
  ) =>
  ({ theme }: PropsWithTheme) => {
    const isLightBackground =
      getLuminance(colors(backgroundColor, { index, transform })({ theme })) >
      luminanceThresholdValue;
    const light = lightColor || theme.colors.textLight;
    const dark = darkColor || theme.colors.textDark;
    return isLightBackground ? dark : light;
  };
