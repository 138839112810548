import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { THROTTLE_TIME_IN_MS } from '@gaming1/g1-core';
import {
  getFavoriteAndRecentGameList,
  GetFavoriteAndRecentGameListRequestDTO,
  setFavoriteGameList,
  SetFavoriteGameListRequestDTO,
} from '@gaming1/g1-requests-gaming';
import {
  createFailurePayload,
  DEFAULT_SERVER_ERROR_STATUS,
  mapGuard,
} from '@gaming1/g1-utils';

import { logger } from '../../logger';
import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import {
  getFavoriteRecentGames as getFavoriteAndRecentGameListCodec,
  setFavoriteGameListResponse,
} from './codecs';
import {
  getFavoriteRecentGamesErrorMessages,
  setFavoriteGameListErrorMesssages,
} from './errorMessages';
import { formatFavoriteRecentGameItems } from './format';

export const favoriteRecentGamesEpic: GamingEpic = (
  actions$,
  _,
  { wsAdapter },
) =>
  actions$.pipe(
    filter(isActionOf(actions.fetchFavoriteAndRecentGames.request)),
    mergeMap(({ payload }) => {
      const params: GetFavoriteAndRecentGameListRequestDTO = {
        Count: payload.Count,
      };
      return wsAdapter.request(getFavoriteAndRecentGameList(params)).pipe(
        mapGuard(getFavoriteAndRecentGameListCodec),
        map(formatFavoriteRecentGameItems),
        map(actions.fetchFavoriteAndRecentGames.success),
        catchError((err) =>
          of(
            actions.fetchFavoriteAndRecentGames.failure(
              createFailurePayload(err, getFavoriteRecentGamesErrorMessages),
            ),
          ),
        ),
      );
    }),
  );

export const setFavoriteGameEpic: GamingEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.setFavoriteGame.request)),
    switchMap(({ payload }) => {
      if (payload.gameId === '') {
        logger.error('setFavoriteGameEpic: empty gameId in request action');
        return of(
          actions.setFavoriteGame.failure({
            errorMessage: 'core:error.invalidParameters',
            status: DEFAULT_SERVER_ERROR_STATUS,
          }),
        );
      }
      const request: SetFavoriteGameListRequestDTO = {
        List: [
          {
            GameId: payload.gameId,
            isFavorite: payload.isFavorite,
          },
        ],
      };
      return wsAdapter.request(setFavoriteGameList(request)).pipe(
        mapGuard(setFavoriteGameListResponse),
        map(() =>
          actions.setFavoriteGame.success({
            gameId: payload.gameId,
            isFavorite: payload.isFavorite,
          }),
        ),
        catchError((err) =>
          of(
            actions.setFavoriteGame.failure(
              createFailurePayload(err, setFavoriteGameListErrorMesssages),
            ),
          ),
        ),
      );
    }),
  );

/** Launch the request to set a new favorite game on click (throttled) */
export const clickOnSetFavoriteGameEpic: GamingEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.clickOnSetFavoriteGame)),
    throttleTime(THROTTLE_TIME_IN_MS),
    map(({ payload }) => actions.setFavoriteGame.request(payload)),
  );

/**
 * Refresh the list of the user favorite games after the user has added/removed
 * a favorite from its list
 */
export const refreshFavoriteGameListAfterChangeEpic: GamingEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.setFavoriteGame.success)),
    map(() => actions.fetchFavoriteAndRecentGames.request({})),
  );
