/* istanbul ignore file */
import { createRoute } from '@gaming1/g1-utils';

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!

/** Public user routes */
export const userPublicRoutes = {
  accountAuthentication: createRoute('/account/security/authentication'),
  accountSelfExclusion: createRoute(
    '/account/responsible-gaming/self-exclusion',
  ),
  accountDepositLimits: createRoute(
    '/account/responsible-gaming/deposit-limits',
  ),
  historyWithdrawal: createRoute('/profile/history/withdrawal'),
  accountSettings: createRoute('/account/settings'),
  history: createRoute('/history'),
  historyShop: createRoute('/profile/history/shop'),
  login: createRoute('/login'),
  profile: createRoute('/profile'),
  passwordRecovery: createRoute('/password-recovery'),
  registration: createRoute('/registration'),
  historyScratchcard: createRoute('/profile/history/scratchcard'),
};
