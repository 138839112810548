import formatDistance from 'date-fns/formatDistance';
import deDateLocale from 'date-fns/locale/de';
import enDateLocale from 'date-fns/locale/en-US';
import esDateLocale from 'date-fns/locale/es';
import frDateLocale from 'date-fns/locale/fr';
import nlDateLocale from 'date-fns/locale/nl';
import ptDateLocale from 'date-fns/locale/pt';
import srDateLocale from 'date-fns/locale/sr';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { isLocaleCode, LocaleCode, useConfig } from '@gaming1/g1-config';
import { formatMoney, formatNumber } from '@gaming1/g1-utils';

import { I18nContext, I18nContextValue } from './I18nContext';

/** Returns the i18n context values */
export const useI18n = (): I18nContextValue => useContext(I18nContext);

/**
 * Returns the Locale object from date-fns related to current locale code (from i18n)
 */
export const useDateLocale = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const currentLocale = isLocaleCode(language) ? language : ('en' as const);

  const locales: { [k in LocaleCode]: Locale } = {
    de: deDateLocale,
    en: enDateLocale,
    es: esDateLocale,
    fr: frDateLocale,
    nl: nlDateLocale,
    pt: ptDateLocale,
    sr: srDateLocale,
  };

  return locales[currentLocale];
};

/**
 * This hook will return a format function that uses the separators
 * from the configuration.
 */
export const useFormatNumber = () => {
  const { currentLocaleFullCode } = useI18n();

  return (value: number, fractionDigits: number, removeTrailingZeros = false) =>
    formatNumber({
      fractionDigits,
      locale: currentLocaleFullCode,
      removeTrailingZeros,
      value,
    });
};

/**
 * This hook will return a format function that uses the
 * currency, format, precision and separators from the configuration.
 *
 * ### Usage
 * ```ts
 * const formatMoney = useFormatMoney();
 *
 * // Outputs based on `currency == 'EUR'` and `locale == 'fr-BE'`
 * formatMoney(500); // Outputs 500,00 €
 * formatMoney(50000.1); // Outputs 50.000,10 €
 * formatMoney(500, true); // Outputs 500 €
 * formatMoney(50000.1, true); // Outputs 50.000,1 €
 * ```
 */
export const useFormatMoney = () => {
  const { currentLocaleFullCode, values } = useI18n();
  const config = useConfig();

  return formatMoney({
    currency: values.currency,
    fractionDigits: config.core.moneyPrecision,
    locale: currentLocaleFullCode,
  });
};

/** Convert a timestamp in seconds to an elapsed duration since now */
export const useConvertDateToElapsedTime = () => {
  const dateLocale = useDateLocale();
  return (timeInSeconds: number) =>
    formatDistance(new Date(timeInSeconds * 1000), new Date(), {
      addSuffix: true,
      locale: dateLocale,
    });
};
