import {
  notificationCodeLevelMapping,
  NotificationLevel,
  notificationStatusLevelMapping,
} from './level';
import { BettingSlipNotification, EFrontErrorType } from './types';

export const isHighlightStakeByError = (
  notification: BettingSlipNotification | undefined,
) => {
  if (!notification || !notification.Status) {
    return false;
  }
  switch (notification.Status) {
    case EFrontErrorType.NoStake:
    case EFrontErrorType.Max:
    case EFrontErrorType.Min:
    case EFrontErrorType.NotEnoughCredit:
    case EFrontErrorType.FirstDepositRequired:
      return true;
    default:
      return false;
  }
};

export const getNotificationLevelMapper = (
  notification: BettingSlipNotification,
): NotificationLevel => {
  if (notification.Status !== undefined) {
    return notificationStatusLevelMapping[notification.Status] || 'warning';
  }
  return notificationCodeLevelMapping[notification.Code] || 'warning';
};

export const getHasFrontNotificationError = (
  notification: BettingSlipNotification | null,
) => {
  if (!notification) {
    return false;
  }
  return notification.Status && notification.Status !== EFrontErrorType.None;
};
