import { useEffect, VFC } from 'react';
import { useLocation } from 'react-router-dom';

import { persistIn } from '@gaming1/g1-utils';

/**
 * Get BTAG param from URL and stores it in sessionStorage,
 */
export const BtagExtractor: VFC = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const btag = queryParams.get('btag');

    if (btag) {
      persistIn(sessionStorage, 'btag', btag);
    }
  }, [location]);

  return null;
};
