import styled from 'styled-components';

import { colors, spaces } from '@gaming1/g1-style';

type TextareaInputProps = {
  hasErrors?: boolean;
};

export const TextareaInput = styled.textarea<TextareaInputProps>`
  background-color: ${colors('inputBackground')};
  color: ${colors('text')};
  border-color: ${({ hasErrors }) =>
    colors(hasErrors ? 'danger' : 'inputBorder')};
  min-height: 4em;
  resize: vertical;
  &:focus {
    outline: none;
    border-color: ${colors('inputActive')};
    border-width: 2px;
  }
`;

export const TableData = styled.td`
  vertical-align: top;
`;

export const AppFieldset = styled.fieldset`
  border-color: ${colors('contentBorder')};
`;

export const AppLegend = styled.legend`
  padding: ${spaces('sm')};
`;
