import React, { BaseHTMLAttributes, FC, useContext } from 'react';

import { DrawerType, LayoutContext } from '../LayoutProvider';

import { DrawerContainer } from './styles';

export type DrawerProps = {
  /** which side of the screen should the drawer come from */
  appearFrom: 'left' | 'right' | 'bottom';
  /** The aria-label attr for screen readers */
  ariaLabel: string;
  /** The height of the drawer (with unit). Default '100%' */
  height?: string;
  /** The aria role (default to navigation) */
  role?: string;
  /** Optional test-id (default: 'drawer') */
  testId?: string;
  /** a unique name to identify which drawer is open in the LayoutContext */
  type: DrawerType;
  /** The width of the drawer (with unit). Default '100%' */
  width?: string;
} & BaseHTMLAttributes<HTMLDivElement>;

export const Drawer: FC<DrawerProps> = ({
  appearFrom,
  ariaLabel,
  children,
  height = '100%',
  role = 'navigation',
  testId = 'drawer',
  type,
  width = '100%',
}) => {
  const { visibleDrawer } = useContext(LayoutContext);

  return (
    <DrawerContainer
      appearFrom={appearFrom}
      aria-expanded={visibleDrawer === type ? 'true' : 'false'}
      aria-label={ariaLabel}
      data-testid={testId}
      height={height}
      role={role}
      visible={visibleDrawer === type}
      width={width}
    >
      {children}
    </DrawerContainer>
  );
};
