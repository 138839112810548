import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { ProfileInfoBase } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  getPlayerVipRankInfoRequestStateSelector,
  loyaltyActions,
  playerVipRankInfoSelector,
  playerVipRankProgressionSelector,
} from '@gaming1/g1-loyalty';
import { useRequestState } from '@gaming1/g1-store';
import { Box, CircularProgressBar, Icon } from '@gaming1/g1-ui';

import {
  HeaderSubTextNextStatus,
  HeaderSubTextPoints,
  ProfileWithRankingIconWrapper,
  SubTextStatus,
} from './styles';

export const ProfileRankInfo: VFC = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation('loyalty');
  const theme = useTheme();
  const playerVipRankProgression = useSelector(
    playerVipRankProgressionSelector,
  );
  const playerVipRankInfo = useSelector(playerVipRankInfoSelector);
  const { isNotAsked } = useRequestState(
    getPlayerVipRankInfoRequestStateSelector,
  );
  const isUserLoggedIn = useSelector(userLoggedInSelector);

  useEffect(() => {
    if (isUserLoggedIn && isNotAsked) {
      dispatch(loyaltyActions.getPlayerVipRankInfo.request());
    }
  }, [dispatch, isNotAsked, isUserLoggedIn]);

  let headerSubTextPoints = '';
  if (playerVipRankInfo?.Points) {
    headerSubTextPoints += `${playerVipRankInfo?.Points} `;
    if (playerVipRankInfo?.NextStatus) {
      headerSubTextPoints += `/ ${playerVipRankInfo?.NextStatus?.Threshold} `;
    }
    headerSubTextPoints += t('profile.vipPoints');
  }

  const remainingPoints =
    (playerVipRankInfo?.NextStatus?.Threshold || 0) -
    (playerVipRankInfo?.Points || 0);

  return (
    <ProfileInfoBase
      profileIcon={
        <Box ml="sm">
          <ProfileWithRankingIconWrapper>
            <CircularProgressBar progress={playerVipRankProgression / 100}>
              <Icon
                id="profile-user-rank"
                type="Shield"
                height={28}
                width={28}
                fill={theme.colors.text}
              />
            </CircularProgressBar>
          </ProfileWithRankingIconWrapper>
          <SubTextStatus data-testid="rank-name">
            {playerVipRankInfo?.CurrentStatus?.Name?.toUpperCase() ?? ''}
          </SubTextStatus>
        </Box>
      }
      subtext={
        <>
          <HeaderSubTextPoints>{headerSubTextPoints}</HeaderSubTextPoints>
          {playerVipRankInfo?.NextStatus && (
            <HeaderSubTextNextStatus>
              {t('profile.nextStatus', {
                remainingPoints,
                statusName: playerVipRankInfo?.NextStatus?.Name,
              })}
            </HeaderSubTextNextStatus>
          )}
        </>
      }
      firstName={user?.FirstName}
    />
  );
};
