import { generateAssetsContextValue } from '@gaming1/g1-core-web';
import { GamingAssetsContext } from '@gaming1/g1-gaming-web';

import jackpotGameTag from './gamecard/jackpot.svg';
import newGameTag from './gamecard/new.svg';
import topGameTag from './gamecard/top.svg';
import jackpotCoin1 from './jackpot/coin_01.png';
import jackpotCoin2 from './jackpot/coin_02.png';
import jackpotCoin3 from './jackpot/coin_03.png';
import jackpotCoin4 from './jackpot/coin_04.png';
import jackpotCoin5 from './jackpot/coin_05.png';
import jackpotCoin6 from './jackpot/coin_06.png';

export const gamingAssets = generateAssetsContextValue(GamingAssetsContext)({
  /* Game tags */
  jackpotGameTag,
  newGameTag,
  topGameTag,
  /* Jackpot coin animation */
  jackpotCoin1,
  jackpotCoin2,
  jackpotCoin3,
  jackpotCoin4,
  jackpotCoin5,
  jackpotCoin6,
});
