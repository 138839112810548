import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  coreActions,
  currentWebSocketLocaleSelector,
  UserField,
} from '@gaming1/g1-core';
import {
  activateAccount,
  getAllProvinces,
  getMunicipalities,
  getRegistrationFormInfo,
  getRegistrationInfo,
  registerPlayer,
  validateRegistrationFields,
} from '@gaming1/g1-requests';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import * as actions from '../../store/actions';
import { UserEpic } from '../../store/types';

import {
  activateAccountResponse,
  getMunicipalitiesResponse,
  getProvincesResponse,
  getRegistrationFormInfoResponse,
  getRegistrationInfoResponse,
  playerRegistrationSuccessResponse,
} from './codecs';
import {
  activateAccountErrorMessages,
  municipalitiesErrorMessages,
  provincesErrorMessages,
  registrationErrorMessages,
  registrationFormInfoErrorMessages,
  registrationInfoErrorMessages,
} from './errorMessages';
import {
  extractRegisrationValidationErrors,
  formatDepartmentsAndMunicipalities,
} from './format';

/** Get the registration form data from the backend  */
export const getRegistrationFormInfoEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRegistrationFormInfo.request)),
    switchMap(() =>
      wsAdapter.request(getRegistrationFormInfo({})).pipe(
        mapGuard(getRegistrationFormInfoResponse),
        map(actions.getRegistrationFormInfo.success),
        catchError((err) =>
          of(
            actions.getRegistrationFormInfo.failure(
              createFailurePayload(err, registrationFormInfoErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the provinces from the backend  */
export const getProvincesEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.getProvinces.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getAllProvinces(payload)).pipe(
        mapGuard(getProvincesResponse),
        map(actions.getProvinces.success),
        catchError((err) =>
          of(
            actions.getProvinces.failure(
              createFailurePayload(err, provincesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get the departments and the municipalities from the backend  */
export const getDepartmentsAndMunicipalitiesEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getDepartmentsAndMunicipalities.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(getMunicipalities(payload)).pipe(
        mapGuard(getMunicipalitiesResponse),
        map(formatDepartmentsAndMunicipalities),
        map(actions.getDepartmentsAndMunicipalities.success),
        catchError((err) =>
          of(
            actions.getDepartmentsAndMunicipalities.failure(
              createFailurePayload(err, municipalitiesErrorMessages),
            ),
          ),
        ),
      ),
    ),
  );

/** Get validations errors for a list of registration fields */
export const validateRegistrationFieldsEpic: UserEpic = (
  action$,
  _,
  { wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.validateRegistrationFields.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(validateRegistrationFields(payload)).pipe(
        mapGuard(playerRegistrationSuccessResponse),
        map(() => actions.validateRegistrationFields.success()),
        catchError((err) => {
          const fieldsErrorMessages = extractRegisrationValidationErrors(err);
          return of(
            actions.validateRegistrationFields.failure({
              ...createFailurePayload(err, registrationErrorMessages),
              fieldsErrorMessages,
            }),
          );
        }),
      ),
    ),
  );

/** Attempt to register the user */
export const registerUserEpic: UserEpic = (action$, _, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.registerUser.request)),
    switchMap(({ payload }) =>
      wsAdapter.request(registerPlayer(payload)).pipe(
        mapGuard(playerRegistrationSuccessResponse),
        map(() =>
          actions.registerUser.success({
            username: payload[UserField.username] || '',
            password: payload[UserField.password] || '',
            birthDate: payload[UserField.birthDate],
          }),
        ),
        catchError((err) => {
          const fieldsErrorMessages = extractRegisrationValidationErrors(err);
          return of(
            actions.registerUser.failure({
              ...createFailurePayload(err, registrationErrorMessages),
              fieldsErrorMessages,
            }),
          );
        }),
      ),
    ),
  );

/** After a successful registration, login immediately the user */
export const successFullRegistrationToLoginEpic: UserEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.registerUser.success)),
    map(({ payload }) =>
      coreActions.login.request({
        username: payload.username,
        password: payload.password,
        birthDate: payload.birthDate,
      }),
    ),
  );

/** Get registration info from the backend  */
export const getRegistrationInfoEpic: UserEpic = (
  action$,
  _,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRegistrationInfo.request)),
    withLatestFrom(config$),
    mergeMap(([action, config]) => {
      const params = {
        RoomDomainName: config.room.roomName,
        ...action.payload,
      };
      return wsAdapter.request(getRegistrationInfo(params)).pipe(
        mapGuard(getRegistrationInfoResponse),
        map(actions.getRegistrationInfo.success),
        catchError((err) =>
          of(
            actions.getRegistrationInfo.failure(
              createFailurePayload(err, registrationInfoErrorMessages),
            ),
          ),
        ),
      );
    }),
  );

/** Activate account */
export const activeAccountEpic: UserEpic = (action$, state$, { wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.activateAccount.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) =>
      wsAdapter
        .request(
          activateAccount({
            LanguageCode: currentWebSocketLocaleSelector(state),
            ...payload,
          }),
        )
        .pipe(
          mapGuard(activateAccountResponse),
          map(actions.activateAccount.success),
          catchError((err) =>
            of(
              actions.activateAccount.failure(
                createFailurePayload(err, activateAccountErrorMessages),
              ),
            ),
          ),
        ),
    ),
  );
