import { createSelector } from 'reselect';

import { EBettingSlipType } from '@gaming1/g1-requests-betting';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { calculateFinalSystemStake } from '../../../helpers/riskManagement';
import {
  RiskManagementRawData,
  SystemRiskManagementRawData,
} from '../../types/riskManagement';
import {
  bettingSlipItemsGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
} from '../common';
import {
  bettingSlipRankNbCombinationsGetterSelector,
  bettingSlipRanksGetterSelector,
  bettingSlipRankStakeInFloatGetterSelector,
} from '../elements/bettingslipSystem';
import { prepareItemGetterSelector } from '../prepare';

/**
 * Selector that will fetch the items contained in a specific bettingSlip (bettingSlipId) from the Sportbook as a SINGLE BS type.
 *
 * It'll get all the formatted items and return an object that will be used in the RM epic.
 *
 * These returned items need to be formatted as a GetRiskManagementReportRequest to be valid.
 *
 */
export const riskManagementPrepareSingleGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  prepareItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  (bettingSlipItems, preparedItem, bettingSlipItemStake) =>
    (bettingSlipId: BettingSlipIdentifierType): RiskManagementRawData => {
      const items = bettingSlipItems(bettingSlipId);

      // As we're on a single bettingSlipType, we have to get the stake through the bettingSlip item
      const stake = items ? bettingSlipItemStake(bettingSlipId) : undefined;

      const bettingSlipType = EBettingSlipType.Single;

      const preparedElements = items
        ? items.map((item) => preparedItem(bettingSlipId, item.id))
        : [];

      return {
        bettingSlipType,
        stake,
        preparedElements,
      };
    },
);

/**
 * Selector that will fetch the items contained in a specific bettingSlip (bettingSlipId) from the Sportbook as a COMBI BS type.
 *
 * It'll get all the formatted items and return an object that will be used in the RM epic.
 *
 * These returned items need to be formatted as a GetRiskManagementReportRequest to be valid.
 *
 */
export const riskManagementPrepareCombiGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  prepareItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  (bettingSlipItems, preparedItem, bettingSlipCombiStake) =>
    (bettingSlipId: BettingSlipIdentifierType): RiskManagementRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const stake = items ? bettingSlipCombiStake(bettingSlipId) : undefined;
      const bettingSlipType = EBettingSlipType.Combi;

      const preparedElements = items
        ? items.map((item) => preparedItem(bettingSlipId, item.id))
        : [];

      return {
        bettingSlipType,
        stake,
        preparedElements,
      };
    },
);

/**
 * RiskManagement selectors for the SYSTEM type
 */

/**
 * Returns an object with a rankId, its stake and its number of combinations
 */
export const bettingSlipSystemRankStakeGetterSelector = createSelector(
  bettingSlipRankStakeInFloatGetterSelector,
  bettingSlipRankNbCombinationsGetterSelector,
  (bettingSlipSystemStakeForRank, bettingSlipSystemNbCombinationsForRank) =>
    (bettingSlipId: BettingSlipIdentifierType, rankId: string): number => {
      const stake = bettingSlipSystemStakeForRank(bettingSlipId, rankId);
      const nbCombinations = bettingSlipSystemNbCombinationsForRank(
        bettingSlipId,
        rankId,
      );

      if (!stake || !nbCombinations) {
        return 0;
      }
      return Number((stake * nbCombinations).toPrecision(15));
    },
);

/**
 * Selector that will fetch the items contained in a specific bettingSlip (bettingSlipId) from the Sportbook as a SYSTEM BS type.
 *
 * It'll get all the formatted items and return an object that will be used in the RM epic.
 *
 * These returned items need to be formatted as a GetRiskManagementReportRequest to be valid.
 *
 */
export const riskManagementPrepareSystemGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  prepareItemGetterSelector,
  bettingSlipRanksGetterSelector,
  bettingSlipSystemRankStakeGetterSelector,
  (
      bettingSlipItems,
      preparedItem,
      bettingSlipSystemRanks,
      bettingSlipSystemRankStake,
    ) =>
    (bettingSlipId: BettingSlipIdentifierType): SystemRiskManagementRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const ranks = bettingSlipSystemRanks(bettingSlipId);

      const fullSystemStake = ranks
        ? calculateFinalSystemStake(
            ranks.map((rank) =>
              bettingSlipSystemRankStake(bettingSlipId, rank.id),
            ),
          )
        : 0;

      const preparedElements = items
        ? items.map((item) => preparedItem(bettingSlipId, item.id))
        : [];

      return {
        stake: fullSystemStake,
        preparedElements,
      };
    },
);
