import React, { FC, useContext } from 'react';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { useMediaBreakPoint } from '@gaming1/g1-ui';

import { useIsFullscreenLayout } from '../../../routing/hooks';
import { desktopLayoutBreakpoint } from '../../constants';
import { AppDownloadLinks } from '../AppDownloadLinks';
import { ComplianceLinks } from '../ComplianceLinks';

import { AppVersion } from './AppVersion';
import { FooterContext } from './FooterContext';
import { FooterSection } from './FooterSection';
import { Header } from './Header';
import { HelpButton } from './HelpButton';
import { ImageAndText } from './ImageAndText';
import { Links } from './Links';
import { SafeArea } from './SafeArea';
import { ScrollTopButton } from './ScrollTopButton';
import { SocialNetwork } from './SocialNetwork';
import { FooterBox, FooterContainer, FooterTitle } from './styles';

type FooterCompoundComponents = {
  /** Links to download iOS and Android App */
  AppDownloadLinks: typeof AppDownloadLinks;
  /** Native app version */
  AppVersion: typeof AppVersion;
  /** Box */
  Box: typeof FooterBox;
  /** Legal footer with legal notice and privacy settings opener */
  ComplianceLinks: typeof ComplianceLinks;
  /** Footer header, with logo, social links, and legal age */
  Header: typeof Header;
  /** Button that trigger Zendesk Widget  */
  HelpButton: typeof HelpButton;
  /** Image or Icon with text */
  ImageAndText: typeof ImageAndText;
  /** Links that can be displayed in row or columns, with separator or not */
  Links: typeof Links;
  /** Safe area */
  SafeArea: typeof SafeArea;
  /** Button to scroll to the top */
  ScrollTopButton: typeof ScrollTopButton;
  /** Section container to put elements in */
  Section: typeof FooterSection;
  /** Social network icons and links */
  SocialNetwork: typeof SocialNetwork;
  /** Title that can be added anywhere */
  Title: typeof FooterTitle;
};

/** Main footer of the app. Can be located in the drawer nav when on mobile
 * app (the location prop must be changed)
 */
export const Footer: FC & FooterCompoundComponents = ({ children }) => {
  const isInMobileApp = getWrapperEnv() === 'rn';
  const isDrawerNavAvailable = !useMediaBreakPoint({
    min: desktopLayoutBreakpoint,
  });
  const isFullscreenLayout = useIsFullscreenLayout();
  const { position } = useContext(FooterContext);
  const isInDrawerNav = position === 'drawerNav';
  /** If this is true, the footer is already renderd in the drawer nav, thus it
   * shouldn't be displayed at the bottom of the page */
  const isAlreadyShownInDrawerNav = isInMobileApp && isDrawerNavAvailable;
  const shouldBeShown = isInDrawerNav
    ? isInMobileApp
    : !isFullscreenLayout && !isAlreadyShownInDrawerNav;

  return shouldBeShown ? <FooterContainer>{children}</FooterContainer> : null;
};

Footer.AppDownloadLinks = AppDownloadLinks;
Footer.AppVersion = AppVersion;
Footer.Box = FooterBox;
Footer.ComplianceLinks = ComplianceLinks;
Footer.Header = Header;
Footer.HelpButton = HelpButton;
Footer.ImageAndText = ImageAndText;
Footer.Links = Links;
Footer.SafeArea = SafeArea;
Footer.ScrollTopButton = ScrollTopButton;
Footer.Section = FooterSection;
Footer.SocialNetwork = SocialNetwork;
Footer.Title = FooterTitle;
