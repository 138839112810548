import React, { VFC } from 'react';

import { Trans, useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';
import { Image } from '../Image';

type SearchNoResultProps = {
  /** The failure term to display when it did not provide any results */
  failureTerm: string;
};

/** Render a container with an image, a title, a description text and a "retry another search" text */
export const SearchNoResult: VFC<SearchNoResultProps> = ({ failureTerm }) => {
  const { t } = useTranslation('core');
  const getAssetPath = useGetCoreAssetPath();

  return (
    <Box
      data-testid="search-no-result"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="xxxl" mt={0}>
        {t('search.noResult.title')}
      </Text>
      <div>
        <p>
          <Trans
            i18nKey="search.noResult.description"
            ns="core"
            values={{ userSearchTerm: failureTerm }}
          />
        </p>
      </div>
      <Text mb={5}>{t('search.noResult.retry')}</Text>
      <Image
        url={getAssetPath('noResults')}
        id="search-no-result"
        alt={t('alt.noResult')}
        height="200px"
      />
    </Box>
  );
};
