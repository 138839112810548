import styled from 'styled-components';

export type RatioContainerDivProps = {
  className?: string;
  ratio: number;
};

export const RatioContainerDiv = styled.div<RatioContainerDivProps>`
  width: 100%;
  padding-top: ${(props) => 100 / props.ratio}%;
  position: relative;
`;

export const RatioContentDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
