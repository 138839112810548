import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { isNonNullable } from '@gaming1/g1-utils';

import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { freebetsConditionsListGettorSelector } from '../../../../freebets/selectors/common';
import {
  bettingSlipGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  freebetSelectedForBSGetterSelector,
} from '../common';
import { getFinalOddsOfBettingSlipItemGetterSelector } from '../elements/bettingslipItem';
import { bettingSlipFinalOddsGettorSelector } from '../odds';
import { bettingSlipFinalWinningsGettorSelector } from '../winnings';

import { isAvailableByRangeCondition } from './helpers';
/**
 * Get the freebetConditions slice of the store from the given bettingSlip
 */
export const getFreebetConditionsGetterSelector = createSelector(
  bettingSlipGetterSelector,
  (bettingSlip) => (bettingSlipId: BettingSlipIdentifierType) =>
    bettingSlip(bettingSlipId)?.freebetConditions,
);

export const isValidFreebetForBSGetterSelector = createSelector(
  freebetSelectedForBSGetterSelector,
  freebetsConditionsListGettorSelector,
  bettingSlipGetterSelector,
  bettingSlipFinalOddsGettorSelector,
  bettingSlipFinalWinningsGettorSelector,
  getFinalOddsOfBettingSlipItemGetterSelector,
  bettingSlipStakeInFloatGetterSelector,
  (
    freebetSelected,
    freebetContionsList,
    bettingSlip,
    bettingSlipFinalOdds,
    bettingSlipFinalWinnings,
    bettingSlipItemFinalOdds,
    bettingSlipStakeInFloat,
  ) =>
    memoize((bettingSlipId: BettingSlipIdentifierType) => {
      const freebet = freebetSelected(bettingSlipId);
      const conditions = freebetContionsList(freebet?.conditionId || '');

      const bs = bettingSlip(bettingSlipId);

      if (!freebet || !conditions) {
        return true;
      }
      return isAvailableByRangeCondition(
        conditions?.RangeConditions?.filter(isNonNullable),
        {
          items:
            bs?.items.map((item) => ({
              finalOdd: bettingSlipItemFinalOdds(bettingSlipId, item.id) || 0,
            })) || [],
          totalFinalOdd: bettingSlipFinalOdds(bettingSlipId),
          winnings: {
            final: bettingSlipFinalWinnings(bettingSlipId) || 0,
          },
          stake: bettingSlipStakeInFloat(bettingSlipId) || 0,
        },
        {
          freebetNetWinnings: true,
        },
      );
    }),
);
