import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { GetBettingHomeModuleOrderResponse } from './types';

/** Get home module order */
export const getHomeModuleOrder = createAsyncAction(
  'betting/get_home_module_order_request',
  'betting/get_home_module_order_success',
  'betting/get_home_module_order_failure',
)<void, GetBettingHomeModuleOrderResponse, FailurePayload>();
