/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       13:21:57 03-08-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  CancelWithdrawRequestDTO,
  CancelWithdrawResponseDTO,
  CreditCardWithdrawRequestDTO,
  CreditCardWithdrawResponseDTO,
  EWalletWithdrawRequestDTO,
  EWalletWithdrawResponseDTO,
  GetAnytimeCardResponseDTO,
  GetBicForIbanRequestDTO,
  GetBicForIbanResponseDTO,
  GetCancellableWithdrawsRequestDTO,
  GetCancellableWithdrawsResponseDTO,
  GetCreditCardWithdrawInfosRequestDTO,
  GetCreditCardWithdrawInfosResponseDTO,
  GetEBankWithdrawInfosRequestDTO,
  GetEBankWithdrawInfosResponseDTO,
  GetEWalletWithdrawInfosRequestDTO,
  GetEWalletWithdrawInfosResponseDTO,
  GetTransferWithdrawInfosRequestDTO,
  GetTransferWithdrawInfosResponseDTO,
  GetWithdrawHistoryRequestDTO,
  GetWithdrawHistoryResponseDTO,
  GetWithdrawInfoRequestDTO,
  GetWithdrawInfoResponseDTO,
  GetWithdrawLimitationRequestDTO,
  GetWithdrawLimitationResponseDTO,
  MakeAnytimeWithdrawRequestDTO,
  MakeAnytimeWithdrawResponseDTO,
  MakeEBankWithdrawRequestDTO,
  MakeEBankWithdrawResponseDTO,
  MakeInRoomWithdrawRequestDTO,
  MakeInRoomWithdrawResponseDTO,
  MakeMoviiRedWithdrawRequestDTO,
  MakeMoviiRedWithdrawResponseDTO,
  MakeTransferWithdrawRequestDTO,
  MakeTransferWithdrawResponseDTO,
  SetWithdrawLimitationRequestDTO,
  SetWithdrawLimitationResponseDTO,
} from '../entities';

export const getWithdrawInfo = (options: GetWithdrawInfoRequestDTO) =>
  createRequest<GetWithdrawInfoResponseDTO>(100, 'GetWithdrawInfo', options);

export const makeTransferWithdraw = (options: MakeTransferWithdrawRequestDTO) =>
  createRequest<MakeTransferWithdrawResponseDTO>(
    100,
    'MakeTransferWithdraw',
    options,
  );

export const makeInRoomWithdraw = (options: MakeInRoomWithdrawRequestDTO) =>
  createRequest<MakeInRoomWithdrawResponseDTO>(
    100,
    'MakeInRoomWithdraw',
    options,
  );

export const makeAnytimeWithdraw = (options: MakeAnytimeWithdrawRequestDTO) =>
  createRequest<MakeAnytimeWithdrawResponseDTO>(
    100,
    'MakeAnytimeWithdraw',
    options,
  );

export const makeEWalletWithdraw = (options: EWalletWithdrawRequestDTO) =>
  createRequest<EWalletWithdrawResponseDTO>(
    100,
    'MakeEWalletWithdraw',
    options,
  );

export const makeCreditCardWithdraw = (options: CreditCardWithdrawRequestDTO) =>
  createRequest<CreditCardWithdrawResponseDTO>(
    100,
    'MakeCreditCardWithdraw',
    options,
  );

export const makeMoviiRedWithdraw = (options: MakeMoviiRedWithdrawRequestDTO) =>
  createRequest<MakeMoviiRedWithdrawResponseDTO>(
    100,
    'MakeMoviiRedWithdraw',
    options,
  );

export const makeEBankWithdraw = (options: MakeEBankWithdrawRequestDTO) =>
  createRequest<MakeEBankWithdrawResponseDTO>(
    100,
    'MakeEBankWithdraw',
    options,
  );

export const getTransferWithdrawInfos = (
  options: GetTransferWithdrawInfosRequestDTO,
) =>
  createRequest<GetTransferWithdrawInfosResponseDTO>(
    100,
    'GetTransferWithdrawInfos',
    options,
  );

export const setWithdrawLimitation = (
  options: SetWithdrawLimitationRequestDTO,
) =>
  createRequest<SetWithdrawLimitationResponseDTO>(
    100,
    'SetWithdrawLimitation',
    options,
  );

export const getWithdrawLimitation = (
  options: GetWithdrawLimitationRequestDTO,
) =>
  createRequest<GetWithdrawLimitationResponseDTO>(
    100,
    'GetWithdrawLimitation',
    options,
  );

export const getBicForIban = (options: GetBicForIbanRequestDTO) =>
  createRequest<GetBicForIbanResponseDTO>(100, 'GetBicForIban', options);

export const getAnytimeCard = (options: GetWithdrawInfoRequestDTO) =>
  createRequest<GetAnytimeCardResponseDTO>(100, 'GetAnytimeCard', options);

export const getEWalletWithdrawInfos = (
  options: GetEWalletWithdrawInfosRequestDTO,
) =>
  createRequest<GetEWalletWithdrawInfosResponseDTO>(
    100,
    'GetEWalletWithdrawInfos',
    options,
  );

export const getCreditCardWithdrawInfos = (
  options: GetCreditCardWithdrawInfosRequestDTO,
) =>
  createRequest<GetCreditCardWithdrawInfosResponseDTO>(
    100,
    'GetCreditCardWithdrawInfos',
    options,
  );

export const getEBankInfosWithdraw = (
  options: GetEBankWithdrawInfosRequestDTO,
) =>
  createRequest<GetEBankWithdrawInfosResponseDTO>(
    100,
    'GetEBankInfosWithdraw',
    options,
  );

export const getCancellableWithdraws = (
  options: GetCancellableWithdrawsRequestDTO,
) =>
  createRequest<GetCancellableWithdrawsResponseDTO>(
    100,
    'GetCancellableWithdraws',
    options,
  );

export const getWithdrawtHistory = (options: GetWithdrawHistoryRequestDTO) =>
  createRequest<GetWithdrawHistoryResponseDTO>(
    100,
    'GetWithdrawtHistory',
    options,
  );

export const cancelWithdraw = (options: CancelWithdrawRequestDTO) =>
  createRequest<CancelWithdrawResponseDTO>(100, 'CancelWithdraw', options);
