import { Location } from 'history';
import isEqual from 'lodash/isEqual';
import {
  ContextType,
  FC,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { extractLocaleFromUrl, useI18n } from '@gaming1/g1-core';
import { usePrevious } from '@gaming1/g1-utils';

import { useDebugMode } from '../../../common/components/App/hooks';
import { useLocaleSwitch } from '../../../i18n/hooks';
import { coreRoutes } from '../../../routes';
import { useRoutePath } from '../../hooks';

import { HistoryContext } from './HistoryContext';

/**
 * Store the current/previous location, the last non-fullscreen location, and
 * the state of the fullscreen layout
 */
export const HistoryProvider: FC = memo(({ children }) => {
  const { currentLocale } = useI18n();
  const currentLocation = useLocation();
  const history = useHistory();
  const { switchLocale } = useLocaleSwitch();
  const isDebugModeEnabled = useDebugMode();

  /**
   * The location where the browser will go if the "back" button is used (if it
   * wasn't used previously). It should always be different than the
   * currentLocation since this version
   * https://github.com/remix-run/react-router/releases/tag/v5.2.1
   */
  const [previousLocation, setPreviousLocation] = useState<Location | null>(
    null,
  );

  /** Is the current render including a Fullscreen component ? */
  const [isFullscreenLayout, setIsFullscreenLayout] = useState(false);

  /** Is the current render including a bottom nav component in Fullscreen ? */
  const [isBottomNavVisibleInFullscreen, setIsBottomNavVisibleInFullscreen] =
    useState(false);

  /** Function handling the Fullscreen layout parameters */
  const setFullscreenLayout = (
    isFullScreen: boolean,
    isBottomNavVisible?: boolean,
  ) => {
    setIsFullscreenLayout(isFullScreen);
    setIsBottomNavVisibleInFullscreen(!!isBottomNavVisible);
  };

  /** The state of previous isFullscreenLayout associated with oldPreviousLocation  */
  const previousIsFullscreenLayout = usePrevious(isFullscreenLayout);

  const currentLocationRef = useRef(currentLocation);
  currentLocationRef.current = currentLocation;

  useEffect(() => {
    const unregister = history.listen((location, action) => {
      if (!isEqual(currentLocationRef.current, location)) {
        if (action !== 'REPLACE') {
          setPreviousLocation(currentLocationRef.current);
        }
      }
      if (action === 'POP') {
        const targetedLocale = extractLocaleFromUrl(location.pathname);
        if (
          targetedLocale &&
          currentLocale !== targetedLocale &&
          // If the translations are being debugged, no switch needed !
          (currentLocale as string) !== 'cimode'
        ) {
          switchLocale(targetedLocale, false);
        }
      }
    });
    return () => {
      unregister();
    };
  }, [currentLocale, history, isDebugModeEnabled, switchLocale]);
  const getRoutePath = useRoutePath(coreRoutes);
  const homePagePath = getRoutePath('home');

  const fallbackLocation = useMemo(
    () => ({
      hash: '',
      pathname: homePagePath,
      search: '',
      state: null,
    }),
    [homePagePath],
  );

  /**
   * The location where the "exit" button of a Fullscreen must redirect (i.e.
   * the latest non-Fullscreen location)
   */
  const [exitFullscreenLocation, setExitFullscreenLocation] =
    useState<Location | null>(null);

  useEffect(() => {
    if (
      !!previousLocation &&
      !isFullscreenLayout &&
      !previousIsFullscreenLayout
    ) {
      setExitFullscreenLocation(previousLocation);
    }
  }, [isFullscreenLayout, previousIsFullscreenLayout, previousLocation]);

  const historyContextValue: ContextType<typeof HistoryContext> = useMemo(
    () => ({
      exitFullscreenLocation: exitFullscreenLocation || fallbackLocation,
      isBottomNavVisibleInFullscreen,
      isFullscreenLayout,
      previousLocation,
      setFullscreenLayout,
    }),
    [
      exitFullscreenLocation,
      fallbackLocation,
      isBottomNavVisibleInFullscreen,
      isFullscreenLayout,
      previousLocation,
    ],
  );

  return (
    <HistoryContext.Provider value={historyContextValue}>
      {children}
    </HistoryContext.Provider>
  );
});
