import React, { ContextType, FC, useContext, useEffect } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { MediaBreakPointNames, mediaBreakPoints } from '@gaming1/g1-style';
import {
  Drawer,
  DrawerType,
  LayoutContext,
  useCloseDrawerOnNavigation,
} from '@gaming1/g1-ui';

import { desktopLayoutBreakpoint } from '../../constants';
import { FooterContext } from '../Footer/FooterContext';
import { Header } from '../Header';

import { ContentContainer } from './ContentContainer';
import { Links } from './Links';
import { LocaleList } from './LocaleList';
import { Menu } from './Menu';
import { DrawerNavContainer, DrawerNavTitle, FooterContainer } from './styles';

const widthByMedia: { [k in MediaBreakPointNames]: string } = {
  xs: '100%',
  sm: '80%',
  md: '60%',
  lg: '50%',
  xl: '50%',
  xxl: '50%',
};

type DrawerNavCompoundComponents = {
  ContentContainer: typeof ContentContainer;
  Footer: typeof FooterContainer;
  Menu: typeof Menu;
  Links: typeof Links;
  LocaleList: typeof LocaleList;
};

const breakpointNames = mediaBreakPoints.map(({ name }) => name);
/** List of breakpoint in which the DrawerNav might be visible */
const allowedBreakpoints = breakpointNames.slice(
  0,
  breakpointNames.indexOf(desktopLayoutBreakpoint),
);

const footerContextValue: ContextType<typeof FooterContext> = {
  position: 'drawerNav',
};

/** Drawer navigation displayed on small screen */
export const DrawerNav: FC & DrawerNavCompoundComponents = ({ children }) => {
  const { t } = useTranslation('core');
  useCloseDrawerOnNavigation(DrawerType.mobileMenu);

  const { media, visibleDrawer, hideDrawer } = useContext(LayoutContext);
  const isDrawerNavVisible = visibleDrawer === DrawerType.mobileMenu;

  /** Close the mobile menu when on desktop layout */
  useEffect(() => {
    if (!allowedBreakpoints.includes(media) && isDrawerNavVisible) {
      hideDrawer();
    }
  }, [media, isDrawerNavVisible, hideDrawer]);

  return (
    <Drawer
      appearFrom="left"
      type={DrawerType.mobileMenu}
      width={widthByMedia[media]}
      ariaLabel="Mobile menu"
      testId="drawernav"
    >
      <DrawerNavContainer>
        <FooterContext.Provider value={footerContextValue}>
          <Header
            onCloseButtonClick={hideDrawer}
            shouldDisplayAsMobile
            testId="drawernav-header"
          >
            <DrawerNavTitle>{t('drawerNav.title')}</DrawerNavTitle>
          </Header>
          {children}
        </FooterContext.Provider>
      </DrawerNavContainer>
    </Drawer>
  );
};

DrawerNav.ContentContainer = ContentContainer;
DrawerNav.Footer = FooterContainer;
DrawerNav.Links = Links;
DrawerNav.LocaleList = LocaleList;
DrawerNav.Menu = Menu;
