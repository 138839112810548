import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { actions as coreActions } from '@gaming1/g1-core';
import {
  EGameSort,
  EGameTechnology,
  getPaginatedGameList,
} from '@gaming1/g1-requests-gaming';
import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { gamesDataWithOnlyGamesResult } from '../../gameList/store/codecs';
import { GameRange } from '../../gameList/store/types';
import { GamingEpic } from '../../store/types';

import * as actions from './actions';
import { searchGamesResponse } from './codecs';
import {
  MIN_SEARCH_TERM_LENGTH,
  SEARCH_GAMES_DEFAULT_PAGE_NUMBER,
  SEARCH_GAMES_DEFAULT_PAGE_SIZE,
} from './constants';
import { formatGamesDataWithoutAttributes } from './format';
import { slugifySearchPayload } from './helpers';
import { searchGamesRequestsSelector } from './selectors';

export const searchGamesEpic: GamingEpic = (
  action$,
  _,
  { config$, wsAdapter },
) =>
  action$.pipe(
    filter(isActionOf(actions.searchGames.request)),
    withLatestFrom(config$),
    mergeMap(([action, config]) =>
      wsAdapter
        .request(
          getPaginatedGameList({
            GameSort: EGameSort.Popularity,
            GameTechnology: EGameTechnology.Unknown,
            HasRules: false,
            IncludeJackpotDetails: false,
            RoomDomainName: config.room.roomName,
            ...action.payload,
          }),
        )
        .pipe(
          mapGuard(searchGamesResponse),
          map(formatGamesDataWithoutAttributes),
          mapGuard(gamesDataWithOnlyGamesResult),
          map((data) =>
            actions.searchGames.success({ data, request: action.payload }),
          ),
          catchError((err) =>
            of(
              actions.searchGames.failure({
                data: createFailurePayload(err),
                request: action.payload,
              }),
            ),
          ),
        ),
    ),
  );

export const reactToCoreSearchEpic: GamingEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(coreActions.userSearch)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      request: {
        GameRange: GameRange.LOBBYALL,
        NameFilter: payload.searchTerm,
        PageNumber: SEARCH_GAMES_DEFAULT_PAGE_NUMBER,
        PageSize: SEARCH_GAMES_DEFAULT_PAGE_SIZE,
      },
      payload,
      state,
    })),
    map((data) => ({
      ...data,
      searchResultsAlreadyInTheStore: searchGamesRequestsSelector(data.state)[
        slugifySearchPayload(data.request)
      ],
    })),
    filter(
      ({ payload, searchResultsAlreadyInTheStore }) =>
        payload.searchTerm.length >= MIN_SEARCH_TERM_LENGTH &&
        !searchResultsAlreadyInTheStore,
    ),
    map(({ request }) => actions.searchGames.request(request)),
  );
