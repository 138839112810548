import React, { VFC } from 'react';
import { useTheme } from 'styled-components';

import {
  BettingHistoryTicketType,
  OptimalBetHistoryTicketInformationType,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Icon, Text } from '@gaming1/g1-ui';

import { OpacityContainer } from '../../../styles';
import { UpdatedOdds } from '../../HistoryCardParts/UpdatedOdds';
import {
  EXTRA_ICON_HEIGHT_IN_PX,
  EXTRA_ICON_WIDTH_IN_PX,
  ExtraText,
  HistoryExpandedGiftContainer,
} from '../styles';

type ExpandedExtraInfoOptimalBetProps = {
  optimalBet: OptimalBetHistoryTicketInformationType;
  history: BettingHistoryTicketType;
};

export const ExpandedExtraInfoOptimalBetV2: VFC<
  ExpandedExtraInfoOptimalBetProps
> = ({ optimalBet, history }) => {
  const { t } = useTranslation('betting');

  const { colors } = useTheme();

  const optimalBetIcon = (
    <Icon
      id="OptimalBet"
      type="OptimalBet"
      color={colors.bettingOptimalBet}
      width={EXTRA_ICON_WIDTH_IN_PX}
      height={EXTRA_ICON_HEIGHT_IN_PX}
    />
  );

  return optimalBet ? (
    <HistoryExpandedGiftContainer>
      {optimalBet.OptimalBetApplied ? (
        optimalBetIcon
      ) : (
        <OpacityContainer>{optimalBetIcon}</OpacityContainer>
      )}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <ExtraText>
          {t('optiodds.history.nrOfEvents', {
            count: optimalBet.NumberOfOutcomes,
          })}
        </ExtraText>
        {optimalBet.OptimalBetApplied ? (
          <UpdatedOdds
            baseOdds={history.BaseOdds}
            finalOdds={history.FinalOdds}
            updateType="odds"
            id="optiodds"
          />
        ) : (
          <Text mt={0} fontSize="md">
            {t('optiodds.history.notValid')}
          </Text>
        )}
      </Box>
    </HistoryExpandedGiftContainer>
  ) : null;
};
