import {
  EActivateAccountStatus,
  EFieldRegisterPlayerProperty,
  EGetAllProvincesStatus,
  EGetMunicipalitiesStatus,
  EGetRegistrationFormInfo,
  EPlayerRegistratioStatus,
  ERegistrationInfoStatus,
} from '@gaming1/g1-requests';

export const registrationFormInfoErrorMessages = {
  [EGetRegistrationFormInfo.InternalError]: 'core:error.internalServer', // 0
  [EGetRegistrationFormInfo.InvalidRoom]: 'core:error.invalidRoom', // 2
  [EGetRegistrationFormInfo.RegistrationForbiddenCountry]:
    'user:registration.message.forbidden', // 3
};

export const provincesErrorMessages = {
  [EGetAllProvincesStatus.InternalError]: 'core:error.internalServer', // 0
};

export const municipalitiesErrorMessages = {
  [EGetMunicipalitiesStatus.InternalError]: 'core:error.internalServer', // 0
};

export const registrationErrorMessages = {
  [EPlayerRegistratioStatus.InternalError]: 'core:error.internalServer', // 0
  [EPlayerRegistratioStatus.InvalidRoom]: 'core:error.invalidRoom', // 2
  [EPlayerRegistratioStatus.FieldError]:
    'user:registration.message.formInvalid', // 3
  [EPlayerRegistratioStatus.DuplicateAccount]:
    'user:registration.message.duplicateAccount', // 4
  [EPlayerRegistratioStatus.RegistrationBlocked]:
    'user:registration.message.forbidden', // 5
  [EPlayerRegistratioStatus.GovernmentIdentityUnknown]:
    'user:registration.message.identityUnknown', // 6
  // TODO: handle InvalidEventId, InvalidLimitations and InvalidAutomaticWidthdrawThreshold
};

export const registrationInfoErrorMessages = {
  [ERegistrationInfoStatus.InternalError]: 'core:error:internalServer', // 0
  [ERegistrationInfoStatus.InvalidRoom]: 'core:error:invalidRoom', // 2
};

export const registrationFieldErrorMessages = {
  [EFieldRegisterPlayerProperty.AlreadyUsed]: 'core:validation.alreadyUsed',
  [EFieldRegisterPlayerProperty.Invalid]: 'core:validation.invalidFormat',
  [EFieldRegisterPlayerProperty.Missing]: 'core:validation.required',
  [EFieldRegisterPlayerProperty.TooLong]: 'core:validation.tooLong',
  [EFieldRegisterPlayerProperty.TooShort]: 'core:validation.tooShort',
};

export const activateAccountErrorMessages = {
  [EActivateAccountStatus.InternalError]: 'core:error:internalServer', // 0
  [EActivateAccountStatus.InvalidActivationId]:
    'user:activateAccount.error.invalidActivationId', // 2
  [EActivateAccountStatus.AccountAlreadyActivated]:
    'user:activateAccount.error.accountAlreadyActivated', // 3
};
