import React, { forwardRef, InputHTMLAttributes, VFC } from 'react';

import { Icon } from '../Icon';
import { InputWrapper } from '../InputWrapper';
import { CommonInputProps } from '../types';

import {
  AppCheckboxSpan,
  AppToggleSpan,
  CheckboxIconSpan,
  CheckboxLabel,
  CheckboxLabelSpan,
  HiddenCheckboxInput,
  ToggleButtonSpan,
} from './styles';

export type CheckboxInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'value'
> &
  Omit<CommonInputProps, 'value'> & {
    /**
     * Optional callback called when the input is checked
     */
    onCheck?: () => void;
    /**
     * The type of checkbox UI. Either "default" or "toggle"
     */
    mode?: 'default' | 'toggle';

    /**
     * Position of the label
     */
    labelPosition?: 'top' | 'left' | 'right';

    /**
     * The label is the same color as the toggle
     */
    coloredLabel?: boolean;
    /**
     * If we need to remove the margin-top given by default by the InputWrapper
     */
    removeMarginTop?: boolean;
  };

type CheckBoxVisualProps = {
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

const CheckBoxDefault: VFC<CheckBoxVisualProps> = ({
  checked,
  disabled,
  readOnly,
}) => (
  <AppCheckboxSpan readOnly={readOnly} checked={checked} disabled={disabled}>
    <CheckboxIconSpan checked={checked}>
      <Icon id="checkbox-check" type="Check" />
    </CheckboxIconSpan>
  </AppCheckboxSpan>
);

const CheckboxToggle: VFC<CheckBoxVisualProps> = ({ checked, disabled }) => (
  <AppToggleSpan checked={checked} disabled={disabled}>
    <ToggleButtonSpan checked={checked} disabled={disabled} />
  </AppToggleSpan>
);

/**
 * Custom checkbox input
 */
export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      checked,
      disabled,
      readOnly,
      errors,
      info,
      label,
      name,
      onClick,
      onChange,
      onCheck,
      simple,
      coloredLabel = false,
      mode = 'default',
      labelPosition = 'right',
      testId = 'checkbox-input',
      wrapperProps,
      removeMarginTop = false,
      ...rest
    },
    ref,
  ) => {
    /* This prevent the error "You provided a `checked` prop to a form field
    without an `onChange` handler" from showing when either onClick or onCheck
    is provided */
    const dummyOnChange = onClick || onCheck ? () => null : undefined;
    const checkbox = (
      <CheckboxLabel
        shouldDisplayLabelAbove={!!label && labelPosition === 'top'}
        data-testid={`${testId}-labelwrapper`}
      >
        <HiddenCheckboxInput
          checked={checked}
          data-testid={testId}
          disabled={disabled}
          name={name}
          onChange={onChange || dummyOnChange}
          onClick={(event) => {
            if (onCheck && !checked) {
              onCheck();
            }
            if (onClick) {
              onClick(event);
            }
          }}
          ref={ref}
          type="checkbox"
          {...rest}
        />
        {!!label && labelPosition === 'left' && (
          <CheckboxLabelSpan
            checked={checked}
            disabled={disabled}
            pr="sm"
            coloredLabel={coloredLabel}
          >
            {label}
          </CheckboxLabelSpan>
        )}
        {!!label && labelPosition === 'top' && (
          <CheckboxLabelSpan
            checked={checked}
            disabled={disabled}
            pb="sm"
            coloredLabel={coloredLabel}
          >
            {label}
          </CheckboxLabelSpan>
        )}
        {mode === 'toggle' ? (
          <CheckboxToggle checked={checked} disabled={disabled} />
        ) : (
          <CheckBoxDefault
            readOnly={readOnly}
            checked={checked}
            disabled={disabled}
          />
        )}
        {!!label && labelPosition === 'right' && (
          <CheckboxLabelSpan
            coloredLabel={coloredLabel}
            checked={checked}
            disabled={disabled}
            pl="sm"
          >
            {label}
          </CheckboxLabelSpan>
        )}
      </CheckboxLabel>
    );

    if (simple) {
      return checkbox;
    }

    return (
      <InputWrapper
        errors={errors}
        info={info}
        testId={testId}
        mt={removeMarginTop ? 'none' : null}
        {...wrapperProps}
      >
        {checkbox}
      </InputWrapper>
    );
  },
);
