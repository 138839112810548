import format from 'date-fns/format';
import React, { FC } from 'react';

import {
  BettingHistoryTicketType,
  BettingSlipHistoryType,
  isOpenHistoryElementLive,
} from '@gaming1/g1-betting';
import { useConfig } from '@gaming1/g1-config';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box } from '@gaming1/g1-ui';

import { getCloseHistoryTicketStatus } from '../../helpers';
import { OpacityContainer, StateContainer } from '../../styles';

import { HistoryCardHeaderPromotionIcon } from './HistoryCardHeaderPromotionIcon';
import { ClickableContainer } from './styles';

type HistoryCardHeaderProps = {
  activeTab: BettingSlipHistoryType;
  date: string;
  historyTicket: BettingHistoryTicketType;
  onClick: () => void;
};

/**
 * This will render a clickable container having the status of the ticket,
 * an icon (could be different if it has a boostus, freebet, ...)
 * and it will display the created date (if open) or the close date (if closed).
 */
export const HistoryCardHeader: FC<HistoryCardHeaderProps> = ({
  activeTab,
  date,
  historyTicket,
  onClick,
}) => {
  const { i18n } = useConfig();
  const { t } = useTranslation('betting');

  const { Elements, Status } = historyTicket;

  const handleStatus = () => {
    if (activeTab === 'close') {
      return (
        <StateContainer status={Status} data-testid="history-card-close-status">
          {t(getCloseHistoryTicketStatus(historyTicket))}
        </StateContainer>
      );
    }

    // If the active tab is OPEN or the card is displayed in the success state of the bettingSlip
    return isOpenHistoryElementLive(Elements) ? (
      <StateContainer isLive>{t('historyElement.state.live')}</StateContainer>
    ) : (
      <StateContainer>{t('historyElement.state.prematch')}</StateContainer>
    );
  };

  return (
    <ClickableContainer
      onClick={onClick}
      flexDirection="row"
      justifyContent="space-between"
      data-testid="clickable-card-container"
    >
      <Box flexDirection="row" alignItems="center">
        {handleStatus()}
        <HistoryCardHeaderPromotionIcon historyTicket={historyTicket} />
      </Box>
      <OpacityContainer data-testid="history-ticket-date-container">
        {format(new Date(date), i18n.dateFormat)} -{' '}
        {format(new Date(date), i18n.timePartialFormat)}
      </OpacityContainer>
    </ClickableContainer>
  );
};
