export type GTMEventProps = {
  category: string;
  action: string;
  label?: string;
  value?: number | string;
};

// Allows additional props
export type ExtendedGTMEventProps = Record<string, string | number> &
  GTMEventProps;

export type RawGTMData = Record<string, string | number>;

export type GoogleAnalyticsEvent = {
  event: string;
} & Record<string, string | number>;

type GenericEvent = {
  event: string;
  eventProps: GTMEventProps;
};
type PageViewEvent = {
  event: 'pageview';
};
type GTMStartEvent = {
  event: 'gtm.js';
  'gtm.start': number;
};
type DataLayerDateInit = ['js', Date];
type DataLayerIdInit = ['config', string];
export type DataLayerItem =
  | DataLayerDateInit
  | DataLayerIdInit
  | GTMStartEvent
  | GenericEvent
  | GoogleAnalyticsEvent
  | PageViewEvent
  | RawGTMData;

type WindowWithDataLayer = Window & {
  dataLayer?: DataLayerItem[];
};

export const windowWithDataLayer = window as WindowWithDataLayer;
