import styled, { css } from 'styled-components';

import { colors, media, spaces } from '@gaming1/g1-style';
import { Box, TextInput } from '@gaming1/g1-ui';

export const SearchBarWrapper = styled(Box)<{ mt?: number }>`
  background-color: ${colors('tertiary')};
  border-radius: 18px;
  height: 36px;
  width: 100%;

  ${({ mt }) =>
    mt != null
      ? css`
          margin-top: ${mt}px;
        `
      : css`
          margin-top: ${spaces('xs')};
          ${media.md} {
            margin-top: ${spaces('xxl')};
          }
        `}
`;

export const SearchInput = styled(TextInput)`
  background-color: transparent;
  border-color: transparent;
  border-bottom: none;
  color: ${colors('backgrounds', { index: 2 })};
  outline: none;
  width: 100%;
  height: 100%;
  margin-left: ${spaces('xs')};
  margin-right: ${spaces('xs')};

  ::placeholder {
    color: ${colors('backgrounds', { index: 2 })};
    opacity: 0.7;
  }
`;

export const ClearIconWrapper = styled.div`
  display: flex;
  align-self: center;
`;
