import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';

import { Alignement } from '@gaming1/g1-cms';
import { elevation, spaces } from '@gaming1/g1-style';

import { getGridItemWidth } from '../../helpers';
import { PrismicWrapper } from '../styles';

export type PrismicGridWrapperProps = {
  /** items alignment */
  alignment: Alignement;
};

export const PrismicGridWrapper = styled(
  PrismicWrapper,
)<PrismicGridWrapperProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${({ alignment }) => {
    switch (alignment?.toLowerCase()) {
      case 'left':
        return css`
          justify-content: flex-start;
        `;
      case 'center':
        return css`
          justify-content: center;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
        `;
      case 'space around':
        return css`
          justify-content: space-around;
        `;
      default:
        return css`
          justify-content: space-between;
        `;
    }
  }}
`;

export type PrismicGridContainerProps = ThemeProps<DefaultTheme> & {
  itemsCount: number;
  width?: string | null;
  styleName: string | null;
  horizontalAlignment: string | null;
  verticalAlignment: string | null;
};

export const PrismicGridContainer = styled.div<PrismicGridContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ horizontalAlignment }) => {
    switch (horizontalAlignment?.toLowerCase()) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};
  align-items: ${({ verticalAlignment }) => {
    switch (verticalAlignment?.toLowerCase()) {
      case 'middle':
        return 'center';
      case 'bottom':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};

  margin: ${spaces('xs')} 0;
  width: ${(props) =>
    props.width ||
    getGridItemWidth(props.itemsCount, `${spaces('sm')(props)}`)};

  ${({ styleName }) =>
    styleName?.toLowerCase() === 'card'
      ? css`
          ${elevation(2)}
          padding: ${spaces('sm')};
        `
      : null}
`;
