import React, { FC } from 'react';

import { PromotionType } from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { NavigationLinkType, PictureSize } from '@gaming1/g1-requests-betting';
import { Box, Heading, Icon, Text, useMediaBreakPoint } from '@gaming1/g1-ui';

import { useCreateRouteFromFormattedRoute } from '../../../boostus/components/BoostusDetails/hooks';
import { ConditionsTable } from '../../../common/components/ConditionsTable';

import {
  Carousel,
  CarouselButton,
  CarouselSlideInfo,
  ModalContent,
  SlideSelector,
  SlideSelectorsContainer,
  StyledCollapsiblePanel,
  StyledImage,
  StyledLink,
  StyledPromoWrapper,
  StyledRules,
  StyledTitle,
} from './styles';

type PromotionDetailsContentProps = {
  promotion: PromotionType;
  currentSlide?: number;
  slidesCount?: number;
  goToPrev?: () => void;
  goToNext?: () => void;
  goToIndex?: (i: number) => void;
};

export const PromotionDetailsContent: FC<PromotionDetailsContentProps> = ({
  currentSlide,
  slidesCount,
  promotion,
  goToPrev,
  goToNext,
  goToIndex,
}) => {
  const { t } = useTranslation('betting');

  const route =
    useCreateRouteFromFormattedRoute(
      promotion?.NavigationData || null,
      promotion?.NavigationData?.LinkType,
    ) || '';

  const isMobileMedia = useMediaBreakPoint({ max: 'xs' });

  const promotionLargePictureUrl = promotion.Pictures?.find(
    (picture) => picture?.Size === PictureSize.Large,
  )?.Path;

  const promotionSmallPictureUrl = promotion.Pictures?.find(
    (picture) => picture?.Size === PictureSize.Small,
  )?.Path;

  const renderSelectors = () =>
    Array.from(Array(slidesCount).keys()).map((_, i) => (
      <SlideSelector
        key={`slideSelector${i + 1}`}
        isSelected={currentSlide === i}
        onClick={() => goToIndex && goToIndex(i)}
      />
    ));

  return (
    <ModalContent key={promotion.Id} data-testid={`promotion-${promotion.Id}`}>
      <StyledImage
        data-testid="promotion-picture"
        alt="promotion.img.alternativeText"
        src={
          isMobileMedia ? promotionSmallPictureUrl : promotionLargePictureUrl
        }
      />
      <StyledPromoWrapper>
        {slidesCount !== undefined &&
          slidesCount !== 1 &&
          currentSlide !== undefined && (
            <Carousel data-testid="carousel">
              <CarouselButton
                disabled={currentSlide === 0}
                onClick={goToPrev}
                data-testid="promotion-carousel-button-previous"
              >
                <Icon
                  id="promotion-carousel-button-previous"
                  type="AngleLeft"
                  width={25}
                  height={25}
                />
              </CarouselButton>
              <CarouselSlideInfo>
                <Text fontSize="md" fontWeight="bold" textAlign="center">
                  {t('promotions.detailsContent.currentSlide.slidesCount', {
                    currentSlide: currentSlide + 1,
                    slidesCount,
                  })}
                </Text>
                <SlideSelectorsContainer>
                  {renderSelectors()}
                </SlideSelectorsContainer>
              </CarouselSlideInfo>

              <CarouselButton
                disabled={currentSlide === slidesCount - 1}
                onClick={goToNext}
                data-testid="promotion-carousel-button-next"
              >
                <Icon
                  id="promotion-carousel-button-next"
                  type="AngleRight"
                  width={25}
                  height={25}
                />
              </CarouselButton>
            </Carousel>
          )}

        <StyledTitle data-testid="promotion-title">
          {promotion.Title}
        </StyledTitle>
        <StyledRules data-testid="promotion-rules">
          {promotion.Description}
        </StyledRules>
        <Box alignItems="center" mt="lg">
          {promotion?.NavigationData?.LinkType && (
            <StyledLink
              colorType="primary"
              to={route}
              data-testid="promotion-link"
            >
              {t(
                `promotion.detailsContent.navigateTo.${NavigationLinkType[
                  promotion?.NavigationData?.LinkType
                ].toLowerCase()}`,
              )}
            </StyledLink>
          )}
        </Box>
        <Heading level="h4" mb="sm">
          {t('promotion.detailsContent.heading.inDetails')}
        </Heading>
        <ConditionsTable
          groupConditions={promotion?.Conditions?.GroupConditions}
          rangeConditions={promotion?.Conditions?.RangeConditions}
          expirationDate={promotion.EndDate}
        />

        <StyledCollapsiblePanel
          data-testid="promotions-terms-and-conditions"
          expandedContent={t('promotions.termsAndConditions.expandedContent')}
          mainContent={t('promotions.termsAndConditions.mainContent')}
        />
      </StyledPromoWrapper>
    </ModalContent>
  );
};
