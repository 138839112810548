/* istanbul ignore file */

import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { coreActions, CoreActions } from '@gaming1/g1-core';

import { commonReducer } from '../common/store/reducer';
import { favoriteRecentGamesReducer } from '../favoriteRecentGames/store/reducer';
import { gameListReducer } from '../gameList/store/reducer';
import { jackpotReducer } from '../jackpot/store/reducer';
import { pendingGamesReducer } from '../pendingGames/store/reducer';
import { searchGamesReducer } from '../search/store/reducer';
import { getGameReducer } from '../startGame/store/reducer';
import { tournamentReducer } from '../tournament/store/reducer';
import { winningsReducer } from '../winnings/store/reducer';

import { GamingActions, GamingState } from './types';

const globalReducer: Reducer<GamingState, GamingActions | CoreActions> =
  combineReducers({
    common: commonReducer,
    favoriteRecentGames: favoriteRecentGamesReducer,
    gameList: gameListReducer,
    jackpot: jackpotReducer,
    pendingGames: pendingGamesReducer,
    search: searchGamesReducer,
    startGame: getGameReducer,
    tournament: tournamentReducer,
    winnings: winningsReducer,
  });

/**
 * The purpose of this root reducer is to reset the state when specific actions
 * are successfully made. See https://alligator.io/redux/reset-state-redux
 * for more information.
 */
export const gamingReducer: Reducer<GamingState, GamingActions> = (
  state: GamingState | undefined,
  action: GamingActions | CoreActions,
) =>
  globalReducer(
    getType(coreActions.loggedOutUser) === action.type &&
      action.payload === true
      ? undefined
      : state,
    action as GamingActions,
  );
