/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       13:06:51 10-03-2022                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import { GetDepositNextStepRequestDTO } from '../entities/GetDepositNextStepRequestDTO';
import { GetDepositNextStepResponseDTO } from '../entities/GetDepositNextStepResponseDTO';

export const getDepositNextStep = (options: GetDepositNextStepRequestDTO) =>
  createRequest<GetDepositNextStepResponseDTO>(
    100,
    'GetDepositNextStep',
    options,
  );
