import { createSelector } from 'reselect';

import { getBettingSlipTypeEnumValue } from '../../../../common/store/helpers';
import { BettingSlipIdentifierType } from '../../../../common/store/types';
import { OptimalBetRawData } from '../../types/optimalBet';
import {
  bettingSlipItemsGetterSelector,
  bettingSlipTypeGetterSelector,
} from '../common';
import { prepareItemGetterSelector } from '../prepare';

export const optimalBetPrepareGetterSelector = createSelector(
  bettingSlipItemsGetterSelector,
  prepareItemGetterSelector,
  bettingSlipTypeGetterSelector,
  (bettingSlipItems, preparedItem, bettingSlipType) =>
    (bettingSlipId: BettingSlipIdentifierType): OptimalBetRawData => {
      const items = bettingSlipItems(bettingSlipId);

      const preparedElements = items
        ? items.map((item) => preparedItem(bettingSlipId, item.id))
        : [];

      return {
        preparedElements,
        bettingSlipType: getBettingSlipTypeEnumValue(
          bettingSlipType(bettingSlipId),
        ),
      };
    },
);
