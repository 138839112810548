import styled from 'styled-components';

import { colors, fontSizes, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const OptimalBetMessageContainer = styled(Box)`
  border: 1px solid ${colors('backgrounds', { index: 4 })};
  margin: ${spaces('xxs')};
  padding: ${spaces('xs')};
  font-size: ${fontSizes('md')};
  border-radius: 4px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
