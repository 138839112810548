import { normalize, schema } from 'normalizr';

import {
  GetScoreBoardNormalizedType,
  GetScoreBoardResponseType,
  GetScoreBoardsNormalizedType,
  GetScoreBoardsResponseType,
  GetScoreBoardUpdatesType,
  ScoreBoardType,
} from './types';

const boards = new schema.Entity('scoreBoards', {}, { idAttribute: 'EventId' });
const scoreBoardItems = new schema.Array(boards);

/** Returns normalized data for the get score board response */
export const formatGetScoreBoard = (
  response: GetScoreBoardResponseType,
): GetScoreBoardNormalizedType => ({
  [response.ScoreBoard.EventId]: response.ScoreBoard,
});

/** Returns normalized data for the get score boards response */
export const formatGetScoreBoards = (
  response: GetScoreBoardsResponseType,
): GetScoreBoardsNormalizedType => {
  const normalizedData = normalize(response.ScoreBoards, scoreBoardItems);
  return normalizedData.entities as GetScoreBoardsNormalizedType;
};

/** Returns normalized data for the get score board updates */
export const formatGetScoreBoardUpdates = (
  response: GetScoreBoardUpdatesType,
): GetScoreBoardsNormalizedType => {
  const scoreBoards = response.Updates.reduce<ScoreBoardType[]>(
    (acc, update) =>
      update.ScoreBoardUpdate.ScoreBoard
        ? [...acc, update.ScoreBoardUpdate.ScoreBoard]
        : acc,
    [],
  );
  const normalizedData = normalize(scoreBoards, scoreBoardItems);
  return normalizedData.entities as GetScoreBoardsNormalizedType;
};
