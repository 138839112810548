import React, { useState, VFC } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { useRequestState } from '@gaming1/g1-store';
import {
  Either,
  generateInitialRequestState,
  RequestState,
} from '@gaming1/g1-utils';

import {
  RequestErrorMessage,
  RequestErrorMessageProps,
} from '../RequestErrorMessage';

type SelectorOrStateProps = Either<
  {
    /** redux selector returning a RequestState */
    selector: (state: any) => RequestState; // eslint-disable-line @typescript-eslint/no-explicit-any
    // No way around the any typing was found
  },
  {
    /** RequestState from the store */
    state: RequestState;
  }
>;

type RequestStateErrorMessageProps = RequestErrorMessageProps &
  SelectorOrStateProps;

const emptyRequestState = generateInitialRequestState();

/**
 * Wrapper of RequestErrorMessage that handles its visiblity and props when
 * given a RequestState selector
 */
export const RequestStateErrorMessage: VFC<RequestStateErrorMessageProps> = ({
  onRetryButtonClick,
  selector,
  state,
  ...rest
}) => {
  const { errorMessage, isLoading, lastErrorMessage } = useRequestState(
    selector ||
      state ||
      // Will never be used since either selector or state are required but
      // typescript doesn't seem to get that
      emptyRequestState,
  );
  const [wasRetried, setWasRetried] = useState(false);
  const { t } = useTranslation();
  const handleRetryButtonClick = onRetryButtonClick
    ? () => {
        setWasRetried(true);
        onRetryButtonClick();
      }
    : undefined;

  const errorMessageToShow =
    /* Use the previous error message while the retry is happening
    (errorMessage is undefined as soon as the request starts loading) */
    isLoading && wasRetried && lastErrorMessage
      ? lastErrorMessage
      : /* Use the current error message (if any) */
        errorMessage;
  if (errorMessageToShow) {
    return (
      <RequestErrorMessage
        onRetryButtonClick={handleRetryButtonClick}
        errorMessage={t(errorMessageToShow)}
        loading={isLoading}
        {...rest}
      />
    );
  }
  return null;
};
