import styled from 'styled-components';

type ResponsiveImgProps = {
  /** The `object-fit` css value */
  $objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
};

/**
 * An image that takes all its available space with optional `object-fit`
 * setting.
 *
 * ### Usage
 *
 * ```tsx
 * const simpleImage = (
 *   <ResponsiveImg
 *     alt="Placeholder 150"
 *     src="https://via.placeholder.com/150"
 *   />
 * );
 * const coverImage = (
 *   <ResponsiveImg
 *     alt="Placeholder 150"
 *     src="https://via.placeholder.com/150"
 *     $objectFit="cover"
 *   />
 * );
 * ```
 */
export const ResponsiveImg = styled.img<ResponsiveImgProps>`
  object-fit: ${({ $objectFit = 'contain' }) => $objectFit};
  max-width: 100%;
`;
