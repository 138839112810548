import React, { FC, memo, useContext } from 'react';

import {
  BettingSlipStringType,
  placeBettingSlipRequestStatusGetterSelector,
  useBettingGetterSelector,
  useBettingSlipCombiValidate,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';
import { RemoteData } from '@gaming1/g1-utils';

import { BettingSlipContext } from '../../BettingSlipContext';

import { BettingSlipValidationButton } from './styles';

type BettingSlipValidationCombiProps = {
  /** id to find ticket item */
  askValidation: (betType: BettingSlipStringType) => void;
};
/**
 * Display the ticket item footer
 *
 */
const BettingSlipValidationCombiComponent: FC<
  BettingSlipValidationCombiProps
> = ({ askValidation }) => {
  const { t } = useTranslation('betting');

  const { bettingSlipId } = useContext(BettingSlipContext);

  const placeBettingSlipStatus = useBettingGetterSelector({
    getterSelector: placeBettingSlipRequestStatusGetterSelector,
    args: [bettingSlipId],
  });

  const isLoading = placeBettingSlipStatus === RemoteData.Loading;

  const renderValidation = () => {
    askValidation('combi');
  };
  const isValid = useBettingSlipCombiValidate(bettingSlipId)();
  let testid = 'bettingslip-submit-button';
  testid += isLoading ? '-loading' : '';
  testid += !isValid ? '-disabled' : '';
  return (
    <BettingSlipValidationButton
      data-testid={testid}
      loading={isLoading}
      disabled={!isValid}
      type="submit"
      onClick={renderValidation}
    >
      {t('bettingslip.placebet.validation')}
    </BettingSlipValidationButton>
  );
};
export const BettingSlipValidationCombi = memo(
  BettingSlipValidationCombiComponent,
);
