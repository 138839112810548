import * as t from 'io-ts';

import { EBiggestWinningsStatus } from '@gaming1/g1-requests-gaming';

import { game } from '../../common/store/codecs';

const winning = t.type(
  {
    Game: game,
    Pseudo: t.string,
    WinningDate: t.string,
    Winnings: t.number,
  },
  'Winning',
);

export const getBiggestWinnings = t.type(
  {
    Status: t.literal(EBiggestWinningsStatus.Successful),
    PageNumber: t.number,
    PageSize: t.number,
    Total: t.number,
    List: t.array(t.union([winning, t.null])),
  },
  'BestWinnings',
);
