import * as t from 'io-ts';

import {
  EGameFeaturePlayerHistory,
  EGetGameFeatureHistory,
  ESponsorshipHistoryStatus,
  NewShopProductType,
  OrderStatus,
  ShopProductType,
  ShopResponseCode,
} from '@gaming1/g1-requests';
import { createEnumType } from '@gaming1/g1-utils';

/**
 * GAME FEATURE PLAYER
 */
export const freeSpin = t.intersection(
  [
    t.type({
      CreatedDate: t.string,
      UpdatedDate: t.string,
      ExpirationDate: t.string,
      StakeValue: t.number,
      NumberOfSpins: t.number,
      Status: createEnumType<EGameFeaturePlayerHistory>(
        EGameFeaturePlayerHistory,
        'EGameFeaturePlayerHistory',
      ),
      TotalWinBonus: t.number,
      TotalWinRealMoney: t.number,
    }),
    t.partial({
      LinkedGameName: t.string,
    }),
  ],
  'FreeSpin',
);

export const getGameFeatureHistoryResponse = t.type(
  {
    PageNumber: t.number,
    PageSize: t.number,
    Status: t.literal(EGetGameFeatureHistory.Successful),
    Total: t.number,
    HistoricGameFeature: t.array(freeSpin),
  },
  'GetGameFeatureHistoryResponse',
);

/**
 * SPONSORSHIP
 */
export const sponsorship = t.intersection(
  [
    t.type({
      CreatedDate: t.string,
      GodsonBonusProgression: t.number,
      Status: t.number, // TODO : Status should be a proper enum and not a number
      WasDeposited: t.boolean,
    }),
    t.partial({
      Godson: t.string,
      StatusDescription: t.string,
    }),
  ],
  'Sponsorship',
);

export const getSponsorshipHistoryResponse = t.type(
  {
    List: t.array(sponsorship),
    PageNumber: t.number,
    PageSize: t.number,
    Status: t.literal(ESponsorshipHistoryStatus.Successful),
    Total: t.number,
  },
  'GetSponsorshipHistoryResponse',
);

/**
 * SHOP
 */
export const shopOrderElement = t.intersection(
  [
    t.type({
      State: createEnumType<OrderStatus>(OrderStatus, 'OrderStatus'),
      ProductType: createEnumType<ShopProductType>(
        ShopProductType,
        'ProductType',
      ),
      NewShopProductType: createEnumType<NewShopProductType>(
        NewShopProductType,
        'NewShopProductType',
      ),
      Product: t.string,
      ProductCategory: t.number,
      IsExchanged: t.boolean,
    }),
    t.partial({
      Title: t.string,
      ProductCode: t.string,
      RedeemUrl: t.string,
      Tracking: t.string,
      InRoomCode: t.string,
      ExchangeDateTime: t.string,
      Thumb: t.string,
    }),
  ],
  'ShopOrderElement',
);

export const shopOrder = t.intersection(
  [
    t.type({
      Id: t.string,
      OrderDate: t.string,
      State: createEnumType<OrderStatus>(OrderStatus, 'OrderStatus'),
    }),
    t.partial({
      Items: t.array(shopOrderElement),
    }),
  ],
  'ShopOrder',
);

export const getShopHistoryResponse = t.intersection(
  [
    t.type({
      Status: t.literal(ShopResponseCode.Success),
      Total: t.number,
    }),
    t.partial({
      Orders: t.array(shopOrder),
      Message: t.string,
    }),
  ],
  'GetShopHistoryResponse',
);
export const scratchCardHistoryElementResponse = t.intersection(
  [
    t.type({
      Id: t.string,
      IsScratched: t.boolean,
      PurchaseDate: t.string,
      Amount: t.number,
    }),
    t.partial({
      PrizeId: t.string,
    }),
  ],
  'ScratchCardHistoryElementResponse',
);
export const scratchCardHistoryResponse = t.intersection(
  [
    t.type({
      PageNumber: t.number,
      PageSize: t.number,
      TotalElements: t.number,
    }),
    t.partial({
      Elements: t.array(scratchCardHistoryElementResponse),
    }),
  ],
  'ScratchCardHistoryResponse',
);
