/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       12:22:12 16-10-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  SendPhoneValidationCodeRequestDTO,
  SendPhoneValidationCodeResponseDTO,
  ValidatePhoneCodeRequestDTO,
  ValidatePhoneCodeResponseDTO,
} from '../entities';

export const sendPhoneValidationCode = (
  options: SendPhoneValidationCodeRequestDTO,
) =>
  createRequest<SendPhoneValidationCodeResponseDTO>(
    100,
    'SendPhoneValidationCode',
    options,
  );

export const validatePhoneCode = (options: ValidatePhoneCodeRequestDTO) =>
  createRequest<ValidatePhoneCodeResponseDTO>(
    100,
    'ValidatePhoneCode',
    options,
  );
