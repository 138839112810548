import { Selector } from 'react-redux';

import { useRequestState } from '@gaming1/g1-store';
import { RequestState } from '@gaming1/g1-utils';

/**
 * Given a RequestState or RequestState selector, returns a boolean informing if
 * a Request(State)ErrorMessage should be rendered.
 * It will be true if
 * - There request has failed and a an error message is available
 * - The request previously failed, has been sent again and is loading
 */
export const useShouldRenderRequestError = <State>(
  requestStateOrSelector: RequestState | Selector<State, RequestState>,
): boolean => {
  const { isLoading, wasFailed, lastErrorMessage, isFailing, errorMessage } =
    useRequestState(requestStateOrSelector);
  return (
    (isLoading && wasFailed && !!lastErrorMessage) ||
    (isFailing && !!errorMessage)
  );
};
