import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { Game } from '../../common/store/types';

import { Jackpot, JackpotTotals } from './types';

/** Get jackpot details */
export const getJackpotDetails = createAsyncAction(
  'gaming/get_jackpot_details_request',
  'gaming/get_jackpot_details_success',
  'gaming/get_jackpot_details_failure',
)<
  { GetLinkedGames: boolean; JackpotNames?: string[] },
  Jackpot[],
  FailurePayload
>();

/** Get jackpot games */
export const getJackpotGames = createAsyncAction(
  'gaming/get_jackpot_games_request',
  'gaming/get_jackpot_games_success',
  'gaming/get_jackpot_games_failure',
)<{ JackpotNames?: string[] }, Game[], FailurePayload>();

/** Get jackpot totals */
export const getJackpotTotals = createAsyncAction(
  'gaming/get_jackpot_totals_request',
  'gaming/get_jackpot_totals_success',
  'gaming/get_jackpot_totals_failure',
)<void, JackpotTotals, FailurePayload>();
