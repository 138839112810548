import { useTranslation } from '@gaming1/g1-i18n';

/** Returns all translations used in compact scoreboards */
export const useScoreBoardCompactTranslations = () => {
  const { t } = useTranslation('betting');

  return {
    games: t('score.boardCompact.games'),
    points: t('score.boardCompact.points'),
    score: t('score.boardCompact.score'),
    sets: t('score.boardCompact.sets'),
  };
};
