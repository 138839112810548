/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:19:38 26-11-2020                       */
/*                                                                 */
/* *************************************************************** */

export enum EGetExternalGameResourceResponseStatus {
  Success = 0,
  NotFound = 1,
  InternalServerError = 2,
}
export enum EGameTechnology {
  Unknown = 0,
  Flash = 1,
  HTML5 = 2,
  Unity = 3,
}
export enum EGameSort {
  None = 0,
  New = 1,
  Popularity = 2,
  MinStake = 3,
  MaxStake = 4,
}
export enum EGameListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  GameRangeNotFound = 3,
}
export enum EJackpotRetributionType {
  RealMoney = 0,
  Mystery = 1,
}
export enum ELobbyDisplayStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  GameRangeNotFound = 3,
}
export enum EDisplayElementType {
  Game = 0,
  VirtualBetting = 1,
  MarketingAction = 2,
}
export enum EGameCountForRangeStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGetGameStatus {
  InternalError = 0,
  Success = 1,
  DisabledGame = 2,
  GameNotFound = 3,
  BadGameId = 4,
}
export enum EGameDetailsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidGame = 2,
  InvalidRoom = 3,
}
export enum EJackpotType {
  None = 0,
  ProgressiveJackpot = 1,
  ExternalProgressiveJackpot = 2,
  PersonnalSpinQuestJackpot = 3,
}
/**
 * @deprecated Not used anymore, must be kept for old websites
 */
export enum EJackpotIdentifier {
  MysteryWheel = 10,
  Endemol = 20,
  RapidRush = 30,
  MysteryRush = 40,
  MegaSpinner = 50,
  StreakJackpot = 60,
  StreakJackpot10 = 61,
  CascadeJackpot = 70,
  BingoAccumulado = 80,
  MirrorGrid = 90,
  MysteryArena = 100,
  EGT = 110,
  MysteryDragon = 120,
  BigHit = 130,
  SummerDice = 140,
  Fakir = 150,
  QuarterMillion = 160,
  KarakForge_SQ = 170,
  EmakiAndTheSevens_SQ = 180,
  Faelorn_SQ = 182,
  MegaSpinnerSlot = 190,
  FiveRaiders = 200,
  Blueprint = 210,
  MysteryRace = 220,
  FakirDice = 230,
  RedTiger = 240,
  SuperBlueBerry = 250,
  CTech = 260,
}
export enum EGameHistoryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum ESetFavoriteGameListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidPlayer = 3,
}
export enum EGetFavoriteGameListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidPlayer = 3,
  NoFavoriteGames = 4,
}
export enum ERecentGameListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EGamesCategoriesStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGameCategoryDisplayType {
  GameRange = 0,
  DisplayElements = 1,
}
export enum EGameLaunchType {
  Demo = 0,
  Real = 1,
  Free = 2,
}
export enum ERegisterGameStatus {
  InternalError = 0,
  Successful = 1,
  NotAuthenticatedPlayer = 2,
  InvalidRoom = 3,
  InvalidGame = 4,
  RegistrationFailed = 5,
  LaunchFailed = 6,
  NotActivatedPlayer = 7,
  WaitingForIdentificationPlayer = 8,
  GameUnavailable = 9,
  ResumeGameFailed = 10,
}
export enum ERegisterGameType {
  Gaming1Internal = 0,
  Gaming1v2 = 1,
  EGT = 3,
  AirDice = 4,
  Amatic = 5,
  Apollo = 6,
  BetSoftGaming = 7,
  Blueprint = 8,
  BoomingGames = 9,
  CasinoTechnology = 10,
  EGaming = 11,
  Endorphina = 12,
  EvolutionGaming = 13,
  Ezugi = 14,
  Fazi = 15,
  GAA = 16,
  GreenTube = 18,
  IGT = 19,
  ISB = 20,
  Kajot = 21,
  Merkur = 22,
  MGA = 23,
  MicroGaming = 24,
  NetEnt = 25,
  Nyx = 26,
  Oryx = 27,
  PariPlay = 28,
  Playson = 29,
  Playtech = 30,
  PragmaticPlay = 31,
  ProgaIndia = 32,
  QuickSpin = 33,
  RedTiger = 34,
  RFranco = 35,
  Ribalix = 36,
  Spinomenal = 37,
  Thunderkick = 38,
  TomHorn = 39,
  Wazdan = 40,
  WorldMatch = 42,
  Yggdrasil = 43,
  Synot = 44,
  GameArt = 45,
  Mancala = 46,
  NoLimitCity = 47,
  PowerGaming = 48,
  Skywind = 49,
}
export enum ERegisterBonusGameStatus {
  InternalError = 0,
  Successful = 1,
  InvalidBonusId = 2,
  BonusCancelled = 3,
  BonusGameDenied = 4,
  NoBonusGameAvailable = 5,
  RegistrationFailed = 6,
  LaunchFailed = 7,
  NotAuthenticatedPlayer = 8,
}
export enum EResumeGameStatus {
  InternalError = 0,
  Successful = 1,
  InvalidId = 2,
  InvalidPlayer = 3,
  ResumeGameFailed = 4,
  LaunchFailed = 5,
  GameUnavailable = 6,
}
export enum EPendingGameStatus {
  InternalError = 0,
  Successful = 1,
  InvalidPlayer = 2,
}
export enum EBiggestWinningsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGameRTPStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ESpinRankingStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EStatus {
  InternalError = 0,
  Successful = 1,
}
export enum EVipRankingStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGetTournamentListType {
  Running = 1,
  Finished = 2,
}
export enum ETournamentMode {
  Online = 0,
  Offline = 1,
}
export enum EGetTournamentListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ETournamentState {
  Finished = 0,
  Scheduled = 1,
  Running = 2,
}
export enum ERunningTournamentStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum GetTournamentCountStatus {
  Success = 0,
  InternalError = 1,
}
export enum ETournamentSummaryStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
}
export enum ETournamentAction {
  None = 0,
  Register = 1,
  Start = 2,
  ResumeGame = 3,
  RebuyAddon = 4,
  ListVirtualRooms = 5,
}
export enum ETournamentDetailsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
}
export enum ETournamentRankingStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
}
export enum ETournamentPlayerListStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
}
export enum EPlayerInfoForTournamentRegistrationStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
}
export enum ERegisterTournamentStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
  RegistrationFailed = 4,
}
export enum ETournamentHelpStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidPage = 3,
}
export enum EStartTournamentStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
  StartFailed = 4,
  LaunchFailed = 5,
}
export enum EResumeTournamentStatus {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
  ResumeFailed = 4,
  LaunchFailed = 5,
}
export enum ETournamentStateV4 {
  Finished = 0,
  Scheduled = 1,
  Running = 2,
}
export enum EGetTournamentListV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ETournamentActionV4 {
  None = 0,
  Register = 1,
  Start = 2,
  Resume = 3,
  RebuyAddon = 4,
}
export enum ECostTypeV4 {
  Credit = 0,
  Coin = 1,
  Ticket = 2,
}
export enum EPrizeTypeV4 {
  Credit = 0,
  Bonus = 1,
  Gift = 2,
  Coin = 3,
  Ticket = 4,
}
export enum ETournamentModeV4 {
  Online = 0,
  Offline = 1,
}
export enum EGetTournamentV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidTournament = 3,
}
export enum ETournamentDetailsV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
}
export enum ETournamentRankingV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
}
export enum EPositionV4 {
  Top = 0,
  Center = 1,
  Bottom = 2,
}
export enum ETournamentPlayerRankingV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
  PlayerWithoutRanking = 4,
  PlayerWithoutRegistration = 5,
}
export enum ERegisterTournamentV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
  RegistrationFailed = 4,
}
export enum EStartTournamentV4Status {
  InternalError = 0,
  Successful = 1,
  InvalidTournament = 2,
  InvalidPlayer = 3,
  StartFailed = 4,
  LaunchFailed = 5,
}
export enum EJackpotInfoStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum ELinkedGamesResponseStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
  InvalidJackpotName = 3,
}
export enum EJackpotDetailsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGetDisplayElementsStatus {
  InternalError = 0,
  Successful = 1,
  InvalidRoom = 2,
}
export enum EGetPlayerGameFeaturesStatus {
  Unknown = 0,
  InternalError = 1,
  Successful = 2,
  GameNotFound = 3,
  PlayerNotAuthenticate = 4,
  PlayerNotFound = 5,
}
export enum EDeleteGameFeature {
  Unknown = 0,
  InternalError = 1,
  Successful = 2,
  GameFeatureNotFound = 3,
}
export enum EGetGameFeatureHistory {
  Unknown = 0,
  InternalError = 1,
  Successful = 2,
  PlayerNotAuthenticate = 3,
  PlayerNotFound = 4,
}
export enum EGameFeaturePlayerHistory {
  Created = 0,
  Validated = 1,
  Finished = 2,
  Canceled = 3,
}
export enum EGetRunningJackpotsStatus {
  InternalError = 0,
  Success = 1,
}
export enum ERunningJackpotState {
  Unknown = 0,
  Running = 1,
  Dropped = 2,
}
export enum ERunningJackpotType {
  Unknown = 0,
  Amount = 1,
  Hourly = 2,
  Daily = 3,
  Weekly = 4,
}
export enum EGetPlayerWonJackpotsStatus {
  InternalError = 0,
  Success = 1,
  NotAuthenticated = 2,
}
export enum EWonJackpotType {
  Unknown = 0,
  Amount = 1,
  Hourly = 2,
  Daily = 3,
  Weekly = 4,
}
