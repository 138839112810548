import React, { FC } from 'react';

import { useFormatMoney } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { FreeSpin } from '@gaming1/g1-loyalty';

import { FreeSpinsHistoryWinningsSpan } from '../styles';

export type FreeSpinsHistoryWinningsProps = {
  history: FreeSpin;
};

export const FreeSpinsHistoryWinnings: FC<FreeSpinsHistoryWinningsProps> = ({
  history,
}) => {
  const { t } = useTranslation('loyalty');
  const formatMoney = useFormatMoney();

  if (history.TotalWinBonus > 0) {
    return (
      <FreeSpinsHistoryWinningsSpan>
        {formatMoney(history.TotalWinBonus)} {t('history.freeSpins.bonus')}
      </FreeSpinsHistoryWinningsSpan>
    );
  }
  if (history.TotalWinRealMoney > 0) {
    return (
      <FreeSpinsHistoryWinningsSpan>
        {formatMoney(history.TotalWinRealMoney)}
      </FreeSpinsHistoryWinningsSpan>
    );
  }

  return (
    <FreeSpinsHistoryWinningsSpan winner={false}>
      {formatMoney(0)}
    </FreeSpinsHistoryWinningsSpan>
  );
};
