import {
  EDepositHistoryStatus,
  EWithdrawtHistoryStatus,
} from '@gaming1/g1-requests';

export const getDepositHistoryErrorMessages = {
  [EDepositHistoryStatus.InternalError]: 'core:error.internalServer', // 0
  [EDepositHistoryStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};

export const getWithdrawalHistoryErrorMessages = {
  [EWithdrawtHistoryStatus.InternalError]: 'core:error.internalServer', // 0
  [EWithdrawtHistoryStatus.InvalidPlayer]: 'core:error.invalidPlayer', // 2
};
