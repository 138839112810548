import {
  BettingSlipElementStatus,
  BettingSlipStatus,
  EventType,
} from '@gaming1/g1-requests-betting';

import {
  BettingHistoryElementType,
  BettingHistoryTicketType,
} from './store/types';

export const isWinningStatus = (data: BettingHistoryTicketType) =>
  data.Status === BettingSlipStatus.Won ||
  data.Status === BettingSlipStatus.Cashout;

export const isLostStatus = (data: BettingHistoryTicketType) =>
  data.Status === BettingSlipStatus.Lost;

export const isWaitingStatus = (data: BettingHistoryTicketType) =>
  data.Status === BettingSlipStatus.Open;

export const isNoneStatus = (data: BettingHistoryTicketType) =>
  data.Status === BettingSlipStatus.Unknown ||
  data.Status === BettingSlipStatus.Refund ||
  data.Status === BettingSlipStatus.Cancel;

/* Event status */
export const isEventPendingStatus = (data: BettingHistoryElementType) =>
  data.Status === BettingSlipElementStatus.Pending;
export const isEventLostStatus = (data: BettingHistoryElementType) =>
  data.Status === BettingSlipElementStatus.Lost;
export const isEventWinStatus = (data: BettingHistoryElementType) =>
  data.Status === BettingSlipElementStatus.Won;
export const isEventPushedStatus = (data: BettingHistoryElementType) =>
  data.Status === BettingSlipElementStatus.Pushed;

export const getLettersForSystemEvent = (
  list: BettingHistoryElementType[],
  targetElement: BettingHistoryElementType,
) => {
  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
  ];
  const index = list.findIndex(
    (item) => item.OutcomeId === targetElement.OutcomeId,
  );
  return letters[index];
};
export const getCombinationLabel = (
  list: BettingHistoryElementType[],
  targetElements: BettingHistoryElementType[],
) =>
  targetElements.reduce(
    (previousLabel, element) =>
      previousLabel + getLettersForSystemEvent(list, element),
    '',
  );

/**
 * Take an array of BettingHistoryElementType (used in the history feature) and check if any of these elements are live or not.
 * It creates an array and then filter it to know if any event is live.
 *
 * @param historyElements the elements that give details on every outcomes in the bet + if the event is live or not
 */
export const isOpenHistoryElementLive = (
  historyElements: (BettingHistoryElementType | null)[] | undefined,
) => {
  const historyElementsWithLive = historyElements?.filter(
    (element) =>
      element?.EventType === EventType.Live && isEventPendingStatus(element),
  );

  return historyElementsWithLive && historyElementsWithLive.length > 0;
};
