import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '@gaming1/g1-core';
import {
  generateFailureRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
} from '@gaming1/g1-utils';

import { GamingActions } from '../../store/types';

import * as gamingActions from './actions';
import { slugifySearchPayload } from './helpers';
import { SearchGamesState } from './types';

export const initialState: SearchGamesState = {
  requests: {
    searchGames: {},
  },
};

export const searchGamesReducer = (
  state: SearchGamesState = initialState,
  action: GamingActions | CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Search games  */
      case getType(gamingActions.searchGames.request): {
        const slug = slugifySearchPayload(action.payload);
        draftState.requests.searchGames[slug] = generateLoadingRequestState({
          games: [],
        });
        break;
      }

      case getType(gamingActions.searchGames.failure): {
        const slug = slugifySearchPayload(action.payload.request);
        draftState.requests.searchGames[slug] = generateFailureRequestState(
          action.payload.data,
          {
            games: [],
          },
        );
        break;
      }

      case getType(gamingActions.searchGames.success): {
        const slug = slugifySearchPayload(action.payload.request);
        draftState.requests.searchGames[slug] = generateSuccessRequestState(
          action.payload.data.result,
        );
        break;
      }

      default: // Immer will automatically return the state
    }
  });
