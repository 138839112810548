import React, { BaseHTMLAttributes, FC } from 'react';

import { RatioContainerDiv, RatioContentDiv } from './styles';

export type RatioContainerProps = {
  /** ClassName of the outer div */
  className?: string;
  ratio?: number;
};

/** Div that will respect the aspect ratio given to it */
export const RatioContainer: FC<
  RatioContainerProps & BaseHTMLAttributes<HTMLDivElement>
> = ({ children, className = '', ratio = 1, ...rest }) => (
  <RatioContainerDiv className={className} ratio={ratio || 1} {...rest}>
    <RatioContentDiv>{children}</RatioContentDiv>
  </RatioContainerDiv>
);
