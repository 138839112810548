import memoize from 'lodash/memoize';

import { generateInitialRequestState, RequestState } from '@gaming1/g1-utils';

import { ApplicationWithCmsState } from '../../store/types';

import { PromotionDocument, PromotionsPagination } from './types';

/** Check if the specified tag exists in the promotion groups */
export const promotionsGroupCheckSelector = memoize(
  (tag: string) =>
    (state: ApplicationWithCmsState): boolean =>
      !!state.cms.promotions.groups[tag],
);

/** Selects the RequestState for the specified tag */
export const promotionsGroupRequestStateSelector = memoize(
  (tag: string) =>
    (state: ApplicationWithCmsState): RequestState =>
      state.cms.promotions.groupsRequests[tag] || generateInitialRequestState(),
);

/** Selects the PromotionsPaginatedList for the specified tag */
export const promotionsGroupPaginationSelector = memoize(
  (tag: string) =>
    (state: ApplicationWithCmsState): PromotionsPagination =>
      state.cms.promotions.groups[tag]?.pagination || {
        promotions: [],
        totalPages: 0,
        totalItems: 0,
        pageNum: 0,
      },
);

/** Selects the promotions UIDs for the specified tag */
export const promotionsGroupUidsSelector = memoize(
  (tag: string) =>
    (state: ApplicationWithCmsState): string[] =>
      state.cms.promotions.groups[tag]?.promotionsUids || [],
);

/** Selects the promotions, accessible by their UID */
export const promotionDocumentsSelector = (
  state: ApplicationWithCmsState,
): Record<string, PromotionDocument> =>
  state.cms.promotions.promotionDocumentsByUid || {};

/** Selects the promotions, accessible by their UID */
export const promotionDocumentsByUidsSelector = memoize(
  (uids: string[]) =>
    (state: ApplicationWithCmsState): PromotionDocument[] =>
      (uids || []).map(
        (uid) => (state.cms.promotions.promotionDocumentsByUid || {})[uid],
      ),
);

/** Selects the RequestState for the promotions configuration request */
export const promotionsConfigurationRequestStateSelector = (
  state: ApplicationWithCmsState,
) => state.cms.promotions.configurationRequest;

/** Selects the promotions configuration */
export const promotionsConfigurationSelector = (
  state: ApplicationWithCmsState,
) => state.cms.promotions.configuration;

/** Selects the RequestState for the promotion request (used on promotion details page) */
export const promotionRequestStateSelector = (state: ApplicationWithCmsState) =>
  state.cms.promotions.promotionRequest;

/** Selects the promotion for the promotion details page */
export const promotionSelector = (state: ApplicationWithCmsState) =>
  state.cms.promotions.promotionDocument;
