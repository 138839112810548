import React, { Component, FC, ReactNode } from 'react';

import { useTranslation } from '@gaming1/g1-i18n';
import { getDeployEnv } from '@gaming1/g1-logger';
import { Button } from '@gaming1/g1-ui';

import { useGetCoreAssetPath } from '../../../assetsManagement/hooks';
import { logger } from '../../../logger';
import {
  RequestErrorMessageContainer,
  RequestErrorMessageImage,
  RequestErrorMessageText,
} from '../RequestErrorMessage/styles';

import { ErrorBoundaryContainer } from './styles';

const MISSING_ERROR = 'Error was swallowed during propagation.';
type ErrorBoundaryContentProps = {
  /** Error caught by componentDidCatch */
  error: Error;
  /** Click handler for the "retry" button */
  onClick: () => void;
};

const shouldShowError = ['development', 'local'].includes(getDeployEnv());

/**
 * Content to show when an UI error has been caught by ErrorBoundary
 */
const ErrorBoundaryContent: FC<ErrorBoundaryContentProps> = ({
  error,
  onClick,
}) => {
  const { t } = useTranslation('core');
  const getAssetPath = useGetCoreAssetPath();

  return (
    <ErrorBoundaryContainer>
      <RequestErrorMessageContainer
        data-testid="error-boundary-report"
        minWidth="80%"
        hasElevation
      >
        <RequestErrorMessageImage
          alt={t('alt.genericError')}
          id="generic-request-error"
          url={getAssetPath('genericError')}
        />
        <RequestErrorMessageText shouldHavePadding>
          {t('error.generic')}
        </RequestErrorMessageText>
        {shouldShowError && <pre>{error.toString()}</pre>}
        <Button
          my="xs"
          onClick={onClick}
          testId="error-boundary-button"
          type="tertiary"
        >
          {t('error.retryButton')}
        </Button>
      </RequestErrorMessageContainer>
    </ErrorBoundaryContainer>
  );
};

// From https://testingjavascript.com/lessons/egghead-test-componentdidcatch-handler-error-boundaries-with-react-testing-library
// And https://github.com/piotrwitek/react-redux-typescript-guide/blob/master/playground/src/hoc/with-error-boundary.tsx
// TODO: transform this class into a FC when a react hook will be created for componentDidCatch
/**
 * Component that implements the `componentDidCatch` method to catch runtime
 * errors and provide a "retry" button to rerender.
 */
export class ErrorBoundary extends Component<
  { children: ReactNode },
  { error: Error | undefined }
> {
  public constructor(props: never) {
    super(props);
    this.state = { error: undefined };
  }

  public componentDidCatch(error: Error | null, info: unknown) {
    const errorMessage = error || new Error(MISSING_ERROR);
    this.setState({ error: errorMessage });
    logger.error(info);
  }

  public tryAgain = () => this.setState({ error: undefined });

  public render() {
    const { children } = this.props;
    const { error } = this.state;
    return error === undefined ? (
      children
    ) : (
      <ErrorBoundaryContent error={error} onClick={this.tryAgain} />
    );
  }
}
