import React, { FC } from 'react';

import { FluidContentContainer } from '@gaming1/g1-ui';

import {
  FooterBackground,
  FooterBackgroundProps,
  FooterColsContainer,
  FooterImagesContainer,
  FooterRowContainer,
  FooterRowContainerProps,
  FooterWrapContainer,
} from './styles';

export type FooterSectionFactoryProps = {
  /** the section type */
  type: 'images' | 'columns' | 'row' | 'wrap';
};

const FooterSectionFactory: FC<FooterSectionFactoryProps> = ({
  type,
  ...rest
}) => {
  switch (type) {
    case 'images':
      return <FooterImagesContainer {...rest} />;
    case 'columns':
      return <FooterColsContainer {...rest} />;
    case 'row':
      return <FooterRowContainer {...rest} />;
    case 'wrap':
      return <FooterWrapContainer {...rest} />;
    default:
      return <div {...rest} />;
  }
};

export type FooterSectionProps = (
  | { type: 'images' }
  | { type: 'columns' }
  | ({ type: 'row' } & FooterRowContainerProps)
  | { type: 'wrap' }
) &
  FooterBackgroundProps;

export const FooterSection: FC<FooterSectionProps> = ({
  type,
  backgroundLevel,
  hasBorder,
  children,
  ...props
}) => (
  <FooterBackground backgroundLevel={backgroundLevel} hasBorder={hasBorder}>
    <FluidContentContainer>
      <FooterSectionFactory type={type} {...props}>
        {children}
      </FooterSectionFactory>
    </FluidContentContainer>
  </FooterBackground>
);
