import * as t from 'io-ts';

import { EChangePasswordStatus } from '@gaming1/g1-requests';

export const changePasswordResponse = t.type(
  {
    Status: t.literal(EChangePasswordStatus.Successful),
  },
  'ChangePasswordResponse',
);
