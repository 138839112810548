/* istanbul ignore file */
import { bettingPublicRoutes } from '@gaming1/g1-betting-api';
import { createParamRoute, createRoute } from '@gaming1/g1-utils';

const emptyParams = {
  sportId: '',
  regionId: '',
  leagueId: '',
  eventId: '',
  temporalFilter: '',
};

type Sport = { sportId: string };
type SportMatch = Sport & { temporalFilter: string };
type SportRegion = Sport & { regionId: string };
type SportRegionLeague = Sport & { regionId: string; leagueId: string };
type SportRegionLeagueEvent = SportRegionLeague & { eventId: string };

// has type RouteList (from @gaming1/g1-utils) but shouldn't be explicitly
// typed as it, otherwise this break the RouteParams mapped type!
export const bettingRoutes = {
  ...bettingPublicRoutes,
  bettingHome: createRoute('/betting/home'), // home route

  boostus: createRoute('/betting/gifts/booster'), // Gifts Routes
  dailyOffers: createRoute('/betting/gifts/best-odds'),
  superOdds: createRoute('/betting/gifts/super-odds'),
  promotions: createRoute('/betting/gifts/promotions'),

  sport: createParamRoute<Sport>('/betting/sport/:sportId', emptyParams), // Sports routes
  sportMatches: createParamRoute<SportMatch>(
    '/betting/sport/:sportId/matches/:temporalFilter?',
    emptyParams,
  ),
  sportLive: createParamRoute<SportRegion>(
    '/betting/sport/:sportId/live/:regionId?',
    emptyParams,
  ),
  sportAllLeagues: createParamRoute<Sport>(
    '/betting/sport/:sportId/all-leagues',
    emptyParams,
  ),
  region: createParamRoute<SportRegion>(
    '/betting/region/:sportId/:regionId',
    emptyParams,
  ),
  regionAllLeagues: createParamRoute<SportRegion>(
    '/betting/region/:sportId/:regionId/all-leagues',
    emptyParams,
  ),
  league: createParamRoute<SportRegionLeague>(
    '/betting/league/:sportId/:regionId/:leagueId',
    emptyParams,
  ),
  leagueCalendar: createParamRoute<SportRegionLeague>(
    '/betting/league/:sportId/:regionId/:leagueId/calendar',
    emptyParams,
  ),
  leagueCompetition: createParamRoute<SportRegionLeague>(
    '/betting/league/:sportId/:regionId/:leagueId/competition',
    emptyParams,
  ),
  leagueAllLeagues: createParamRoute<SportRegionLeague>(
    '/betting/league/:sportId/:regionId/:leagueId/all-leagues',
    emptyParams,
  ),
  eventPrematch: createParamRoute<SportRegionLeagueEvent>(
    '/betting/event/:sportId/:regionId/:leagueId/:eventId',
    emptyParams,
  ),

  liveListTop: createRoute('/betting/live'), // Live routes
  liveListAll: createRoute('/betting/live/all'),
  // liveListStream: createRoute('/betting/live/stream'),
  liveList: createParamRoute<SportRegion>(
    '/betting/live/:sportId/:regionId?',
    emptyParams,
  ),
  eventLive: createParamRoute<SportRegionLeagueEvent>(
    '/betting/live/event/:sportId/:regionId/:leagueId/:eventId',
    emptyParams,
  ),

  myFinishedBets: createRoute('/betting/my-bets/settled'), // Protected routes
  myOpenBets: createRoute('/betting/my-bets/active'),
  myUnlockedGifts: createRoute('/betting/my-gifts/available'),
  myLockedGifts: createRoute('/betting/my-gifts/to-unlock'),

  search: createRoute('/betting/search'), // Search
};
