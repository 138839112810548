import format from 'date-fns/format';
import { useEffect, useState } from 'react';

import { BetPipeDebugLog, betPipeDebugLogs$ } from '@gaming1/g1-betting';
import { Table } from '@gaming1/g1-ui';

export const BetPipeDebugging = () => {
  const [betPipeDebugLogs, setBetPipeDebugLogs] = useState<BetPipeDebugLog[]>(
    [],
  );

  useEffect(() => {
    const debugStoreSub = betPipeDebugLogs$.subscribe({
      next: (item) => {
        setBetPipeDebugLogs((items) => [...items, item]);
      },
    });

    return () => {
      debugStoreSub.unsubscribe();
    };
  }, []);

  return (
    <Table>
      <tr>
        <th>Time</th>
        <th>ID</th>
        <th>Type</th>
        <th>Steps</th>
      </tr>
      {betPipeDebugLogs.map(({ id, inputSteps, outputSteps, time, type }) => (
        <tr key={`${time}${id}`}>
          <td>{format(time, 'HH:mm:ss')}</td>
          <td>{id}</td>
          <td>{type}</td>
          <td>
            {inputSteps.join(', ')} =&gt; {outputSteps.join(', ')}
          </td>
        </tr>
      ))}
    </Table>
  );
};
