import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../../../betPipe/actions';
import { BettingActions } from '../../../store/types';
import { BettingSlipsState } from '../types/store';

import { getInitialRootBettingSlipState } from './helpers';

export const initialState: BettingSlipsState = getInitialRootBettingSlipState();

export const placeBetReducer = (state = initialState, action: BettingActions) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.placeBet.request): {
        const { payload } = action;
        produceLoadingState(
          draftState.bettingSlips[payload.bettingSlipId].requests.placeBet,
        );

        break;
      }

      case getType(actions.placeBet.success): {
        const { payload } = action;
        const {
          Notification,
          IsFirstBet,
          ElementNotifications,
          TicketSummaryId,
          Ticket,
        } = payload;

        const { placeBet } = draftState.bettingSlips[payload.bettingSlipId];
        if (placeBet) {
          placeBet.result = {
            Notification,
            IsFirstBet,
            ElementNotifications,
            TicketSummaryId: TicketSummaryId || Ticket?.Id,
          };
          produceSuccessState(
            draftState.bettingSlips[payload.bettingSlipId].requests.placeBet,
          );
        }

        break;
      }

      case getType(actions.placeBet.failure): {
        const { payload } = action;
        produceFailureState(
          draftState.bettingSlips[payload.bettingSlipId].requests.placeBet,
          payload,
        );

        const { placeBet } = draftState.bettingSlips[payload.bettingSlipId];
        if (placeBet) {
          placeBet.result = {
            ...payload,
          };
        }
        break;
      }

      case getType(actions.placeSystemBet.request): {
        const { payload } = action;
        produceLoadingState(
          draftState.bettingSlips[payload.bettingSlipId].requests.placeBet,
        );

        break;
      }

      case getType(actions.placeSystemBet.success): {
        const { payload } = action;
        const {
          RankNotifications,
          Notification,
          IsFirstBet,
          ElementNotifications,
          TicketSummaryId,
          Ticket,
        } = payload;
        produceSuccessState(
          draftState.bettingSlips[payload.bettingSlipId].requests.placeBet,
        );

        const { placeBet } = draftState.bettingSlips[payload.bettingSlipId];
        if (placeBet) {
          placeBet.result = {
            RankNotifications,
            Notification,
            IsFirstBet,
            ElementNotifications,
            TicketSummaryId: TicketSummaryId || Ticket?.Id,
          };
        }

        break;
      }

      case getType(actions.placeSystemBet.failure): {
        const { payload } = action;
        produceFailureState(
          draftState.bettingSlips[payload.bettingSlipId].requests.placeBet,
          payload,
        );

        const { placeBet } = draftState.bettingSlips[payload.bettingSlipId];
        if (placeBet) {
          placeBet.result = {
            ...payload,
          };
        }
        break;
      }

      default: // Immer will automatically return the state
    }
  });
