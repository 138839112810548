import React, { ContextType, FC, memo, useMemo } from 'react';

import { ajaxFetch, wsAdapter } from '@gaming1/g1-network';

import { NetworkContext } from './NetworkContext';

/** Provide network tools, useful for dependency injection */
export const NetworkProvider: FC = memo(({ children }) => {
  const networkContextValue: ContextType<typeof NetworkContext> = useMemo(
    () => ({
      wsAdapter,
      ajaxFetch,
    }),
    [],
  );
  return (
    <NetworkContext.Provider value={networkContextValue}>
      {children}
    </NetworkContext.Provider>
  );
});
