import styled, { css } from 'styled-components';

import { colors, elevation, spaces, zIndex } from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

import { BOTTOM_NAV_HEIGHT } from '../../../styles';

import { DebugPanelPosition } from './types';

const OPEN_PANEL_HEIGHT_IN_VH = 40;
const FULLSCREEN_PANEL_HEIGHT_IN_VH = 100;
const TABS_HEIGHT_IN_PX = 50;

export const DebugPanelWrapper = styled.div<{ position: DebugPanelPosition }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${OPEN_PANEL_HEIGHT_IN_VH}vh;
  ${elevation(1)};
  z-index: ${zIndex('devTool')};
  ${({ position }) =>
    position === 'fullscreen' &&
    css`
      height: ${FULLSCREEN_PANEL_HEIGHT_IN_VH}vh;
    `}
`;

export const DebugPanelContentWrapper = styled.div<{
  position: DebugPanelPosition;
}>`
  display: flex;
  flex-direction: column;
  height: ${OPEN_PANEL_HEIGHT_IN_VH}vh;
  ${({ position }) =>
    position === 'fullscreen' &&
    css`
      height: ${FULLSCREEN_PANEL_HEIGHT_IN_VH}vh;
      padding-bottom: ${spaces('md')};
    `}
`;

export const DebugPanelMinimizedWrapper = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  bottom: ${BOTTOM_NAV_HEIGHT * 2}px;
  ${elevation(1)};
  z-index: ${zIndex('devTool')};
`;

export const PositionButtonsWrapper = styled.div<{
  panelPosition: DebugPanelPosition;
}>`
  ${elevation(1, { noBorder: true, noShadow: true })};
  align-self: flex-end;
  padding: ${spaces('xxs')};
  svg path {
    fill: ${colors('text')};
  }
  ${({ panelPosition }) =>
    panelPosition === 'open' &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);
    `}
`;

export const PositionButton = styled(SimpleButton)`
  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${spaces('xxs')};
`;

export const Spacer = styled.div`
  height: ${OPEN_PANEL_HEIGHT_IN_VH}vh;
`;

export const TabContentContainer = styled.div`
  padding: ${spaces('sm')};
  overflow-y: auto;
  height: calc(100% - ${TABS_HEIGHT_IN_PX}px);
`;
