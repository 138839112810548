import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  actions,
  cashoutDebugLogs$,
  getCashoutInformationGetterSelector,
  getCashoutRequestStatusSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useFormatMoney, useIsDebugModeEnabled } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import {
  CashoutAvailability,
  NotificationCode,
} from '@gaming1/g1-requests-betting';
import { Box, Icon } from '@gaming1/g1-ui';
import { convertInputToAmount, RemoteData } from '@gaming1/g1-utils';

import { useManageNotificationOnRequestState } from '../../../hooks';
import {
  useCallCashoutInfoOnOutcomeAvailabilityUpdate,
  useCallCashoutInfoOnOutcomeOddUpdate,
  useCallCashoutInfoOnOutcomeProbabilityUpdate,
  useHandleUpdateOfCashoutInfoToStopCashoutProcess,
  useMoveOpenBetToCloseWhenCashoutIsPerformed,
  useSubscribeToOutcomeOfHistoryTicketElements,
} from '../../hooks';

import {
  ConfirmationOrCancelButton,
  ErrorMessage,
  MainCashoutButton,
} from './styles';

type CashoutProps = {
  /** The cashout betting slip ID */
  cashoutBettingSlipId: string;
  /** The history ID that the cashout is in */
  historyId: string;
  isDesktop?: boolean;
};

/**
 * Renders a Button that will give the possibility to the user to "Cashout" his bet.
 *
 * This is like a 3 steps thing :
 * 1 - We show the Button to the user with the amount of the Cashout
 * (so he knows that this bet could be "cashed out").
 *
 * 2 - If he clicks on it, we will ask him to confirm OR to cancel the process.
 *
 * 3 - If he confirms, then we proceed to the cashout, if he cancels then we stop the process and go back to step 1.
 */
export const CashoutSteps: FC<CashoutProps> = ({
  cashoutBettingSlipId,
  historyId,
  isDesktop,
}) => {
  const [isAskingConfirmation, setIsAskingConfirmation] =
    useState<boolean>(false);

  const { t } = useTranslation(['betting', 'core']);

  const { colors } = useTheme();

  const dispatch = useDispatch();

  const formatMoney = useFormatMoney();

  const isDebugModeEnabled = useIsDebugModeEnabled();
  const cashoutInformation = useBettingGetterSelector({
    getterSelector: getCashoutInformationGetterSelector,
    args: [cashoutBettingSlipId],
    equalityFn: isEqual,
  });
  const getCashoutRequestState = useBettingGetterSelector({
    getterSelector: getCashoutRequestStatusSelector,
    args: [cashoutBettingSlipId],
  });

  const isLoading = getCashoutRequestState === RemoteData.Loading;

  const isCashoutUnavailable =
    cashoutInformation?.CashoutAvailability !== CashoutAvailability.Available;

  const launchCashoutInfo = useRef(
    debounce(() => {
      if (isDebugModeEnabled) {
        cashoutDebugLogs$.next({
          time: new Date().getTime(),
          id: cashoutBettingSlipId,
          info: 'Ask for reflow',
        });
      }
      dispatch(
        actions.getCashoutInfo.request({
          BettingSlipId: cashoutBettingSlipId,
        }),
      );
    }, 100),
  ).current;

  useSubscribeToOutcomeOfHistoryTicketElements(historyId);

  useCallCashoutInfoOnOutcomeOddUpdate(
    historyId,
    cashoutBettingSlipId,
    launchCashoutInfo,
  );

  useCallCashoutInfoOnOutcomeProbabilityUpdate(
    historyId,
    cashoutBettingSlipId,
    launchCashoutInfo,
  );

  useCallCashoutInfoOnOutcomeAvailabilityUpdate(
    historyId,
    cashoutBettingSlipId,
    launchCashoutInfo,
  );

  useHandleUpdateOfCashoutInfoToStopCashoutProcess(
    setIsAskingConfirmation,
    cashoutBettingSlipId,
  );

  useMoveOpenBetToCloseWhenCashoutIsPerformed(cashoutBettingSlipId, historyId);

  /**
   * At first, when the user clicks on the cashout button, we will ask him a confirmation.
   */
  const askForConfirmation = () => {
    setIsAskingConfirmation(true);
  };

  const handleConfirmation = () => {
    dispatch(
      actions.cashout.request({
        BettingSlipId: cashoutBettingSlipId || '',
        Amount: cashoutInformation?.FullCashoutAmount ?? 0,
        // CashoutPercentage will be useful when we will do the partial cashout feature
      }),
    );
  };

  /**
   * If the user cancels the cashout, we have to go back to step one --> Propose the cashout to the user
   */
  const handleCancel = () => {
    setIsAskingConfirmation(false);
  };

  useManageNotificationOnRequestState(
    getCashoutRequestState,
    'cashout.success.message',
    'cashout.error.message',
  );

  useEffect(() => {
    if (getCashoutRequestState === RemoteData.Error) {
      handleCancel();
    }
  }, [getCashoutRequestState]);

  /**
   * This is the first step of the cashout : we display the possible
   * cashout to the user and he could click on it to start the process
   */

  const renderErrorMessage = () => {
    if (
      cashoutInformation?.Notification?.Code ===
      NotificationCode.Cashout_ValueBellowLimit
    ) {
      return t('history.cashout.error.minimAmount', {
        minimAmount: formatMoney(
          convertInputToAmount(
            cashoutInformation?.Notification?.NotificationParameters?.find(
              (param) => param?.Key === 'Cashout_Amount_Limit',
            )?.Value || '',
          ),
        ),
      });
    }
    return t('history.cashout.error.generic');
  };

  if (!isAskingConfirmation) {
    return (
      <>
        <MainCashoutButton
          disabled={isCashoutUnavailable}
          type="secondary"
          width={isDesktop ? '50%' : '100%'}
          px="sm"
          data-testid="first-step-cashout-button"
          onClick={askForConfirmation}
        >
          {isCashoutUnavailable ? (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                fill={colors.buttonSecondaryText}
                id="LockClosed"
                type="LockClosed"
                mr="xxs"
              />
              {t('history.ticket.cashout')}
            </Box>
          ) : (
            t('history.cashout.button', {
              cashoutValue: formatMoney(
                cashoutInformation?.FullCashoutAmount ?? 0,
              ),
            })
          )}
        </MainCashoutButton>
        {isCashoutUnavailable && (
          <ErrorMessage>{renderErrorMessage()}</ErrorMessage>
        )}
      </>
    );
  }

  return (
    <Box flexDirection="row" width="100%" mx="xs" justifyContent="center">
      <ConfirmationOrCancelButton
        data-testid="second-step-cashout-cancel"
        disabled={isLoading}
        type="secondary"
        onClick={handleCancel}
        mr="xs"
      >
        {t('core:button.cancel')}
      </ConfirmationOrCancelButton>
      <ConfirmationOrCancelButton
        data-testid="second-step-cashout-confirm"
        disabled={isCashoutUnavailable}
        loading={isLoading}
        type="primary"
        onClick={handleConfirmation}
      >
        {t('core:button.confirm')}
      </ConfirmationOrCancelButton>
    </Box>
  );
};
