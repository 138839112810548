import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { PrivacyConditionsState } from './types';

export const initialState: PrivacyConditionsState = {
  requests: {
    acceptPrivacyConditions: generateInitialRequestState(null),
    getPrivacyConditions: generateInitialRequestState(null),
    showPrivacyConditions: generateInitialRequestState(null),
  },
  path: null,
  show: false,
};

export const privacyConditionsReducer = (
  state: PrivacyConditionsState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /** Requests */
      case getType(actions.acceptPrivacyConditions.request):
        produceLoadingState(draftState.requests.acceptPrivacyConditions);
        break;
      case getType(actions.getPrivacyConditions.request):
        produceLoadingState(draftState.requests.getPrivacyConditions);
        break;
      case getType(actions.showPrivacyConditions.request):
        produceLoadingState(draftState.requests.showPrivacyConditions);
        draftState.show = false;
        break;

      /** Failures */
      case getType(actions.acceptPrivacyConditions.failure):
        produceFailureState(
          draftState.requests.acceptPrivacyConditions,
          action.payload,
        );
        break;
      case getType(actions.getPrivacyConditions.failure):
        produceFailureState(
          draftState.requests.getPrivacyConditions,
          action.payload,
        );
        break;
      case getType(actions.showPrivacyConditions.failure):
        produceFailureState(
          draftState.requests.showPrivacyConditions,
          action.payload,
        );
        break;

      /** Successes */
      case getType(actions.acceptPrivacyConditions.success):
        produceSuccessState(draftState.requests.acceptPrivacyConditions);
        break;
      case getType(actions.getPrivacyConditions.success):
        produceSuccessState(draftState.requests.getPrivacyConditions);
        draftState.path = action.payload.File ? action.payload.File : '';
        break;
      case getType(actions.showPrivacyConditions.success):
        produceSuccessState(draftState.requests.showPrivacyConditions);
        draftState.show = !action.payload.HasAccepted;
        break;

      default: // Immer will automatically return the state
    }
  });
