/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       11:07:55 11-03-2021                       */
/*                                                                 */
/* *************************************************************** */

export enum EResponseStatus {
  Successfull = 0,
  RequestParametersError = 1,
  Error = 2,
}
export enum EHomeModuleType {
  Unknown = 0,
  TopLive = 1,
  TopPrematch = 2,
  WidgetBanner = 3,
  RecommendedBet = 4,
}
export enum ESportType {
  Unknown = 0,
  Classic = 1,
  ESport = 10,
  Simulated = 20,
}
export enum EBettingSlipOddChangeSettings {
  None = 0,
  OnlyHigherOdds = 1,
  AllOdds = 2,
}
export enum EMarketGroupDisplayType {
  Unknown = 0,
  Standard = 10,
  Main = 20,
  SuperLive = 30,
}
export enum ShowcaseMarketDisplayType {
  Default = 0,
  OverUnder = 10,
}
export enum EventType {
  Unknown = 0,
  Prematch = 1,
  Live = 2,
  FutureLive = 3,
}
export enum EventDisplayType {
  Unknown = 0,
  European = 1,
  American = 2,
}
export enum TradingState {
  Open = 0,
  Suspended = 1,
  Closed = 2,
}
export enum ColumnReorder {
  Default = 0,
  ByColumn = 1,
  ByOutcome = 2,
}
export enum StreamingStatus {
  Available = 0,
  Ordered = 1,
  Streams = 2,
}
export enum StreamingType {
  Video = 0,
  LiveMatchTracker = 1,
}
export enum ScoreBoardSportType {
  Unknown = 0,
  DefaultWithPoint = 1,
  DefaultWithPeriod = 2,
  Football = 844,
  IceHockey = 846,
  Tennis = 848,
  Baseball = 856,
  FieldHockey = 860,
  AmericanFootball = 870,
  Darts = 882,
  Cricket = 894,
  Bowls = 3210,
}
export enum ScoreBoardPeriodType {
  Unknown = 0,
  PeriodBasedOnPoint = 1,
  PeriodBasedOnTime = 2,
}
export enum ScoreBoardState {
  Unknown = 0,
  Set1 = 1,
  Set2 = 2,
  Set3 = 3,
  Set4 = 4,
  Set5 = 5,
  Set6 = 6,
  Set7 = 7,
  Set8 = 8,
  Set9 = 9,
  Set10 = 10,
  Set11 = 11,
  Set12 = 12,
  Set13 = 13,
  Wait = 50,
  Timeout = 51,
  Penalty = 52,
  Finished = 53,
  Cancel = 54,
}
export enum ScoreBoardTeam {
  Unknown = 0,
  Home = 1,
  Away = 2,
}
export enum FilterType {
  Unknown = 0,
  Top = 1,
  Date = 2,
  Later = 3,
}
export enum EBettingSlipType {
  Unknown = 0,
  Single = 1,
  Combi = 2,
  System = 3,
}
export enum EOptimalBetRoundingMethod {
  MathematicRounding = 0,
  UpRounding = 1,
}
export enum OptimalBetElementStatus {
  Unknown = 0,
  Applicable = 1,
  NotApplicable = 2,
  NotAllowed = 3,
  OnError = 4,
}
export enum Provider {
  Unknown = 0,
  BetGenius = 10,
  SportRadar = 20,
  DonBest = 30,
  ManualBet = 1000,
}
export enum EGetBettingSlipPlayerPreferencesStatus {
  Ok = 0,
  InternalError = 1,
  InvalidPlayer = 2,
}
export enum NotificationLevel {
  Unknown = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Fatal = 4,
}
export enum NotificationCode {
  Unknown = 0,
  RiskManagement_Success = 1200,
  RiskManagement_InvalidBettingSlipType = 1400,
  RiskManagement_InternalError = 1500,
  RiskManagement_UnspecifiedError = 1600,
  RiskManagement_TooManyElements = 1601,
  RiskManagement_TooFewElements = 1602,
  RiskManagement_InvalidOutcomeCombination = 1603,
  RiskManagement_LiveSystemNotAllowed = 1604,
  RiskManagement_StakeTooHigh = 1605,
  RiskManagement_StakeTooLow = 1606,
  RiskManagement_TimingFreeze = 1607,
  RiskManagement_InvalidOutcomes = 1608,
  PlaceBet_Success = 2200,
  PlaceBet_InternalError = 2500,
  PlaceBet_UnspecifiedError = 2600,
  PlaceBet_InvalidBoostus = 2601,
  PlaceBet_InvalidSafeData = 2602,
  PlaceBet_FreebetNotFound = 2605,
  PlaceBet_NoFreebetGrantedBeforeMinElementStartDate = 2606,
  PlaceBet_InsufficientAmount = 2610,
  PlaceBet_InsufficientRealAmount = 2611,
  PlaceBet_AmountAboveLimit = 2612,
  PlaceBet_WageLimitExceeded = 2613,
  PlaceBet_LossLimitExceeded = 2614,
  PlaceBet_RunningGame = 2615,
  PlaceBet_PlayerCheckFailed = 2616,
  PlaceBet_CreditTransferError = 2617,
  PlaceBet_InvalidBettingSlipType = 2618,
  PlaceBet_InvalidPlayer = 2619,
  PlaceBet_InvalidElementsCount = 2620,
  PlaceBet_ProviderError = 2621,
  PlaceBet_PromoAttributionFailed = 2622,
  PlaceBet_InvalidElementToBetOnActually = 2623,
  PlaceBet_ValidationError = 2624,
  PlaceBet_PlayerIsNotAllowedDueToGeoLocation = 2625,
  PlaceBet_TimeSpentLimitReached = 2626,
  BettingHistory_Success = 3200,
  BettingHistorySystemDetails_Success = 3210,
  BettingHistory_InternalError = 3500,
  Freebet_Success = 4200,
  Freebet_InternalError = 4500,
  HasAvailableBoostus_Success = 5200,
  HasAvailableBoostus_InternalError = 5500,
  GetAvailableBoostus_Success = 6200,
  GetAvailableBoostus_InternalError = 6500,
  GetBoostusConditionForBettingSlip_Success = 7200,
  GetBoostusConditionForBettingSlip_InternalError = 7500,
  Cashout_Success = 8200,
  Cashout_ParametersError = 8400,
  Cashout_InternalError = 8500,
  Cashout_Undefined = 8501,
  Cashout_ErrorCashoutNotEnabled = 8502,
  Cashout_Error = 8600,
  Cashout_NotAvailableAThisTime = 8601,
  Cashout_AlreadyClosed = 8602,
  Cashout_AlreadyRebought = 8603,
  Cashout_ResidualStakeError = 8604,
  Cashout_InProgressError = 8605,
  Cashout_ValueBellowLimit = 8606,
  PlayerProfile_Success = 9200,
  PlayerProfile_InternalError = 9500,
  ManualBet_Success = 10200,
  ManualBet_InternalError = 10500,
  Promo_Success = 11200,
  Promo_InternalError = 11500,
  OptimalBet_Success = 12200,
  OptimalBet_InternalError = 12500,
  OptimalBet_NotEligible_MissingElement = 12601,
}
export enum KeyValueType {
  Unknown = 0,
  String = 1,
  Date = 2,
  Number = 3,
  Money = 4,
}
export enum BettingActivity {
  Unknown = 0,
  Sportbook = 1,
  Manual = 2,
  HorseRacing = 3,
}
export enum CreditType {
  Unknown = 0,
  Money = 1,
  Freebet = 2,
}
export enum BettingSlipStatus {
  Unknown = 0,
  Open = 1,
  Won = 2,
  Lost = 3,
  Refund = 4,
  Cancel = 5,
  Cashout = 6,
}
export enum BettingSlipElementStatus {
  Unknown = 0,
  Pending = 1,
  Won = 2,
  Lost = 3,
  Pushed = 4,
}
export enum ERoundingMethod {
  Unknown = 0,
  MathematicalRounding = 1,
  UpRounding = 2,
}
export enum ERoundingTarget {
  BettingSlipOddRounding = 0,
  BettingSlipElementOddRounding = 1,
  CurrencyRounding = 2,
  BoostusOddRounding = 3,
}
export enum RangeConditionType {
  Unknown = 0,
  ElementOdd = 1,
  Stake = 2,
  TicketOdd = 3,
  ElementCount = 4,
  BoostusMaxProfit = 5,
  FreebetNetWinnings = 6,
}
export enum GroupConditionType {
  Unknown = 0,
  Player = 1,
  Nationality = 2,
  League = 3,
  MarketType = 4,
  BettingSlipType = 5,
  BettingSlipEventType = 6,
  Sport = 7,
  Event = 8,
  AmountInType = 9,
}
export enum EHorizontalBannerResponseErrorStatus {
  None = 0,
  InternalError = 1,
}
export enum EBannerType {
  Blog = 0,
  Url = 1,
}
export enum ESliderScope {
  Any = 0,
  UnsecuredZone = 1,
  SecuredZone = 2,
  HasBet = 4,
  MakeDeposit = 8,
}
export enum ETargetType {
  EventPrematch = 0,
  EventLive = 1,
  AdminFavoriteLeague = 10,
  UserFavoriteLeague = 11,
  Blog = 100,
  OpenModal = 200,
  Url = 999,
}

export enum EventTypeFilter {
  Unknown = 0,
  All = 1,
  Prematch = 2,
  Live = 3,
}
export enum NavigationLinkType {
  None = 0,
  Sport = 1,
  League = 2,
  Event = 3,
}
export enum BettingSlipType {
  Unknown = 0,
  Single = 1,
  Combi = 2,
  System = 3,
}
export enum CashoutAvailability {
  Unknown = 0,
  Available = 1,
  Unavailable = 10,
  RegulationRulesUnchecked = 11,
}
export enum PlayerCashoutPolicy {
  Unknown = 0,
  Allowed = 1,
  NotAllowed = 2,
}
export enum ManualBetType {
  Unknown = 0,
  SuperOdds = 1,
  DailyOffer = 2,
}
export enum ManualBetPlayerStatus {
  Unknown = 0,
  NotConnected = 1,
  Participate = 2,
  AlreadyParticipate = 3,
  Eligible = 4,
  NotAllowedToParticipate = 5,
}

export enum AssociatedPromoType {
  Unknown = 0,
  Boostus = 1,
  DailyOffer = 2,
  SuperOdds = 3,
  Promotion = 4,
}

export enum PictureSize {
  Unknown = 0,
  Small = 1,
  Large = 2,
}

export enum PromotionState {
  Unknown = 0,
  Pending = 1,
  Validated = 2,
  NotValidated = 3,
}
export enum GiftType {
  Unknown = 0,
  RealMoney = 1,
  BonusMoney = 2,
  Freebet = 3,
}
