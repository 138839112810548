import {
  EGetBettingSlipPlayerPreferencesStatus,
  EResponseStatus,
} from '@gaming1/g1-requests-betting';

export const updateUserPreferencesErrorMessages = {
  [EResponseStatus.RequestParametersError]: 'core:error.requestParametersError', // 1
  [EResponseStatus.Error]: 'core:error.internalServer', // 2
};

export const getUserPreferencesErrorMessages = {
  [EResponseStatus.RequestParametersError]: 'core:error.requestParametersError', // 1
  [EResponseStatus.Error]: 'core:error.internalServer', // 2
};

export const getUserPreferencesResultErrorMessages = {
  [EGetBettingSlipPlayerPreferencesStatus.InternalError]:
    'core:error.internalServer', // 1
  [EGetBettingSlipPlayerPreferencesStatus.InvalidPlayer]:
    'core:error.invalidPlayer', // 2
};
