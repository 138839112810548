import * as t from 'io-ts';

import {
  EChagePlayerSettingsStatus,
  EGetPlayerSettingsStatus,
} from '@gaming1/g1-requests';

export const changePlayerSettingsResponse = t.type(
  {
    Status: t.literal(EChagePlayerSettingsStatus.Successful),
  },
  'ChangePlayerSettingsResponse',
);

export const getPlayerSettingsResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EGetPlayerSettingsStatus.Successful),
      ReceiveEmailNotification: t.boolean,
      ReceivePostNotification: t.boolean,
      ReceiveSMSNotification: t.boolean,
      ReceivePhoneNotification: t.boolean,
      RefuseBonus: t.boolean,
      AllowRemarketing: t.boolean,
    }),
    t.partial({ LanguageIsoCode: t.string }),
  ],
  'GetPlayerSettingsResponse',
);
