import styled, { css } from 'styled-components';

import { colors, fontWeight, spaces, zIndex } from '@gaming1/g1-style';
import { Box, MemoizedCollapsible } from '@gaming1/g1-ui';

import { OutcomeButton } from '../OutcomeButton';

const ROW_GAP_IN_PX = 2;
const COL_GAP_IN_PX = 2;
const OUTCOME_DETAILS_BUTTON_HEIGHT_IN_PX = '40px';

const colWidth = (gapInPx: number, itemCount: number) => css`
  flex-basis: calc(${100 / itemCount}% - ${gapInPx - gapInPx / itemCount}px);
  max-width: calc(${100 / itemCount}% - ${gapInPx - gapInPx / itemCount}px);
`;

export const IconContainer = styled(Box)`
  flex-direction: row;

  > :not(:last-child) {
    margin-right: ${spaces('xxs')};
  }
`;

export const StickyContainer = styled(Box)`
  background-color: ${colors('backgrounds', { index: 0 })};
  z-index: ${zIndex(6)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const MarketRow = styled.div`
  display: flex;
  gap: ${spaces('sm')};
`;

type MarketColProps = {
  colCount: number;
};
export const MarketCol = styled.div<MarketColProps>`
  width: ${({ colCount }) => 100 / colCount}%;
`;

export const MarketName = styled.div`
  padding: ${spaces('xs')};
`;

export const OutcomeDetailsButton = styled(OutcomeButton)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: ${OUTCOME_DETAILS_BUTTON_HEIGHT_IN_PX};
  padding: ${spaces('xs')};
`;

export const OutcomeName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OutcomeBaseEventDetails = styled(Box)`
  padding-left: ${spaces('xxxs')};
`;

export const CenteredTabBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const OutcomeOdd = styled.span`
  font-weight: ${fontWeight('bold')};
`;

export const OutcomeOddEventDetails = styled(OutcomeOdd)`
  padding-left: ${spaces('xs')};
  margin-left: auto;
`;

export const OutcomeCell = styled.div`
  & + & {
    padding-top: ${spaces('xxxs')};
  }
`;

type OutcomeRowProps = {
  colCount: number;
};

export const OutcomeRow = styled.div<OutcomeRowProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  row-gap: ${ROW_GAP_IN_PX}px;
  column-gap: ${COL_GAP_IN_PX}px;

  & > div {
    ${({ colCount }) => colWidth(COL_GAP_IN_PX, colCount)};
  }
`;

export const CollapsibleMarket = styled(MemoizedCollapsible)`
  background-color: unset;
  & + & {
    margin-top: ${spaces('sm')};
  }
`;

export const NoWhiteSpaceText = styled.span`
  white-space: nowrap;
`;

type LMTWrapperProps = { isXL: boolean };

export const BoardLMTWrapper = styled(Box)<LMTWrapperProps>`
  margin-bottom: ${spaces('sm')};
  flex-direction: ${({ isXL }) => (isXL ? 'row' : 'column')};

  ${({ isXL }) =>
    isXL &&
    css`
      margin-top: 6px;
    `}
`;

export const TabsLMTWrapper = styled(Box)<LMTWrapperProps>`
  background-color: ${colors('backgrounds', {
    index: 1,
  })};
  margin-top: ${spaces('xxxs')};

  ${({ isXL }) =>
    isXL &&
    css`
      margin-top: ${spaces('none')};
      margin-left: 6px;

      width: 270px;

      @media screen and (min-width: 1490px) {
        width: 304px;
      }
      @media screen and (min-width: 1590px) {
        width: 342px;
      }
      @media screen and (min-width: 1690px) {
        width: 374px;
      }
      @media screen and (min-width: 1790px) {
        width: 414px;
      }
    `}
`;

export const LMTWrapper = styled(Box)<LMTWrapperProps>`
  ${({ isXL }) =>
    isXL &&
    css`
      margin-left: 6px;

      width: 270px;
      min-height: 202px;

      & > div {
        background-color: black;
        height: 100%;
      }

      @media screen and (min-width: 1490px) {
        width: 304px;
        min-height: 222px;
      }
      @media screen and (min-width: 1590px) {
        width: 342px;
        min-height: 246px;
      }
      @media screen and (min-width: 1690px) {
        width: 374px;
        min-height: 266px;
      }
      @media screen and (min-width: 1790px) {
        width: 414px;
        min-height: 291px;
      }
    `}
`;

/** Prevents the LMT to be shrinked if the overflown
 * text is too large (white-space issue) */
export const ScoreBoardWrapper = styled(Box)`
  overflow: hidden;
  flex: 1;
`;
