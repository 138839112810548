/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       10:19:38 26-11-2020                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetPlayerInfoForTournamentRegistrationRequestDTO,
  GetPlayerInfoForTournamentRegistrationResponseDTO,
  GetRunningTournamentListRequestDTO,
  GetRunningTournamentListResponseDTO,
  GetTournamentCountRequestDTO,
  GetTournamentCountResponseDTO,
  GetTournamentDetailsRequestDTO,
  GetTournamentDetailsResponseDTO,
  GetTournamentDetailsV4RequestDTO,
  GetTournamentDetailsV4ResponseDTO,
  GetTournamentHelpRequestDTO,
  GetTournamentHelpResponseDTO,
  GetTournamentListRequestDTO,
  GetTournamentListResponseDTO,
  GetTournamentListV4RequestDTO,
  GetTournamentListV4ResponseDTO,
  GetTournamentPlayerListRequestDTO,
  GetTournamentPlayerListResponseDTO,
  GetTournamentPlayerRankingV4RequestDTO,
  GetTournamentPlayerRankingV4ResponseDTO,
  GetTournamentRankingRequestDTO,
  GetTournamentRankingResponseDTO,
  GetTournamentRankingV4RequestDTO,
  GetTournamentRankingV4ResponseDTO,
  GetTournamentSummaryRequestDTO,
  GetTournamentSummaryResponseDTO,
  GetTournamentV4RequestDTO,
  GetTournamentV4ResponseDTO,
  RegisterTournamentRequestDTO,
  RegisterTournamentResponseDTO,
  RegisterTournamentV4RequestDTO,
  RegisterTournamentV4ResponseDTO,
  ResumeTournamentRequestDTO,
  ResumeTournamentResponseDTO,
  StartTournamentRequestDTO,
  StartTournamentResponseDTO,
  StartTournamentV4RequestDTO,
  StartTournamentV4ResponseDTO,
} from '../entities';

export const getTournamentList = (options: GetTournamentListRequestDTO) =>
  createRequest<GetTournamentListResponseDTO>(
    105,
    'GetTournamentList',
    options,
  );

export const getRunningTournamentList = (
  options: GetRunningTournamentListRequestDTO,
) =>
  createRequest<GetRunningTournamentListResponseDTO>(
    105,
    'GetRunningTournamentList',
    options,
  );

export const getTournamentCount = (options: GetTournamentCountRequestDTO) =>
  createRequest<GetTournamentCountResponseDTO>(
    105,
    'GetTournamentCount',
    options,
  );

export const getTournamentSummary = (options: GetTournamentSummaryRequestDTO) =>
  createRequest<GetTournamentSummaryResponseDTO>(
    105,
    'GetTournamentSummary',
    options,
  );

export const getTournamentDetails = (options: GetTournamentDetailsRequestDTO) =>
  createRequest<GetTournamentDetailsResponseDTO>(
    105,
    'GetTournamentDetails',
    options,
  );

export const getTournamentRanking = (options: GetTournamentRankingRequestDTO) =>
  createRequest<GetTournamentRankingResponseDTO>(
    105,
    'GetTournamentRanking',
    options,
  );

export const getTournamentPlayerList = (
  options: GetTournamentPlayerListRequestDTO,
) =>
  createRequest<GetTournamentPlayerListResponseDTO>(
    105,
    'GetTournamentPlayerList',
    options,
  );

export const getPlayerInfoForTournamentRegistration = (
  options: GetPlayerInfoForTournamentRegistrationRequestDTO,
) =>
  createRequest<GetPlayerInfoForTournamentRegistrationResponseDTO>(
    105,
    'GetPlayerInfoForTournamentRegistration',
    options,
  );

export const registerTournament = (options: RegisterTournamentRequestDTO) =>
  createRequest<RegisterTournamentResponseDTO>(
    105,
    'RegisterTournament',
    options,
  );

export const getTournamentHelp = (options: GetTournamentHelpRequestDTO) =>
  createRequest<GetTournamentHelpResponseDTO>(
    105,
    'GetTournamentHelp',
    options,
  );

export const startTournament = (options: StartTournamentRequestDTO) =>
  createRequest<StartTournamentResponseDTO>(105, 'StartTournament', options);

export const resumeTournament = (options: ResumeTournamentRequestDTO) =>
  createRequest<ResumeTournamentResponseDTO>(105, 'ResumeTournament', options);

export const getTournamentList_V4 = (options: GetTournamentListV4RequestDTO) =>
  createRequest<GetTournamentListV4ResponseDTO>(
    105,
    'GetTournamentList_V4',
    options,
  );

export const getTournament_V4 = (options: GetTournamentV4RequestDTO) =>
  createRequest<GetTournamentV4ResponseDTO>(105, 'GetTournament_V4', options);

export const getTournamentDetails_V4 = (
  options: GetTournamentDetailsV4RequestDTO,
) =>
  createRequest<GetTournamentDetailsV4ResponseDTO>(
    105,
    'GetTournamentDetails_V4',
    options,
  );

export const getTournamentRanking_V4 = (
  options: GetTournamentRankingV4RequestDTO,
) =>
  createRequest<GetTournamentRankingV4ResponseDTO>(
    105,
    'GetTournamentRanking_V4',
    options,
  );

export const getTournamentPlayerRanking_V4 = (
  options: GetTournamentPlayerRankingV4RequestDTO,
) =>
  createRequest<GetTournamentPlayerRankingV4ResponseDTO>(
    105,
    'GetTournamentPlayerRanking_V4',
    options,
  );

export const registerTournament_V4 = (
  options: RegisterTournamentV4RequestDTO,
) =>
  createRequest<RegisterTournamentV4ResponseDTO>(
    105,
    'RegisterTournament_V4',
    options,
  );

export const startTournament_V4 = (options: StartTournamentV4RequestDTO) =>
  createRequest<StartTournamentV4ResponseDTO>(
    105,
    'StartTournament_V4',
    options,
  );
