// The lib can be used in both dom and non dom env
type GlobalWithConditionalWindow = NodeJS.Global & {
  window?: {
    document?: unknown;
    localStorage?: {
      getItem: (name: string) => string;
      setItem: (name: string, value: string) => void;
    };
    location?: {
      href: string;
    };
    navigator?: { product?: string };
    overwriteEnv?: { deployEnv: string };
    reactInterop?: unknown;
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  };
};
/**
 * Window object extract from "global" with every (possibly undefined)
 * properties it can have on both web and RN environments
 */
export const conditionalWindow = (global as GlobalWithConditionalWindow).window;
