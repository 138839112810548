import React, { FC, useEffect, useState } from 'react';
import { distinctUntilChanged } from 'rxjs/operators';

import { EWebSocketStatus, WebSocketAdapter } from '@gaming1/g1-network';
import { Button, Col, Heading, Row, TextInput } from '@gaming1/g1-ui';

import { SimpleSubmitButton, WsStatusDiv } from './styles';

const statusNames = {
  [EWebSocketStatus.connected]: 'connected',
  [EWebSocketStatus.connecting]: 'connecting',
  [EWebSocketStatus.disconnected]: 'disconnected',
  [EWebSocketStatus.ready]: 'ready',
};

type NetworkToolsProps = {
  /** An optional WebSocketAdapter instance (useful for DI) */
  webSocketAdapter: WebSocketAdapter;
};

export const NetworkTools: FC<NetworkToolsProps> = ({ webSocketAdapter }) => {
  const [wsState, setWsState] = useState(EWebSocketStatus.disconnected);
  const [wsDelay, setWsDelay] = useState(0);
  const [wsDelayInput, setWsDelayInput] = useState(0);

  useEffect(() => {
    const sub = webSocketAdapter.status$
      .pipe(distinctUntilChanged())
      .subscribe((status) => {
        setWsState(status);
      });
    return () => sub.unsubscribe();
  }, [webSocketAdapter]);

  return (
    <>
      <Heading level="h4">Web socket</Heading>
      <WsStatusDiv status={wsState}>
        Status: {statusNames[wsState] || wsState}
      </WsStatusDiv>
      <Button
        type="danger"
        mt="xs"
        onClick={
          wsState === EWebSocketStatus.disconnected
            ? webSocketAdapter.reconnect
            : webSocketAdapter.triggerDisconnection
        }
      >
        {wsState === EWebSocketStatus.disconnected ? 'Reconnect' : 'Disconnect'}
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          webSocketAdapter.setResponseDelay(wsDelayInput);
          setWsDelay(wsDelayInput);
        }}
      >
        <Row noGutter>
          <Col xs={10} noGutter>
            <TextInput
              aria-label="Responses delay"
              placeholder="Responses delay"
              type="number"
              simple
              value={wsDelayInput}
              onValueChange={(num) => {
                setWsDelayInput(Number(num));
              }}
              testId="devtoolmenu-delay-input"
            />
          </Col>

          <Col xs={2} noGutter justifyContent="flex-end">
            <SimpleSubmitButton
              data-testid="devtoolmenu-delay-submit"
              type="submit"
            >
              ok
            </SimpleSubmitButton>
          </Col>
        </Row>
      </form>
      <p>Current value: {wsDelay}</p>
    </>
  );
};
