import styled from 'styled-components';

import { Box, Button } from '@gaming1/g1-ui';

export const BettingSlipValidationContainer = styled(Box)`
  width: 100%;
`;

export const BettingSlipValidationButton = styled(Button)`
  width: 100%;
`;
