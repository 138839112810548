import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { colors, isColorDark, media, spaces } from '@gaming1/g1-style';
import { AppButtonLink, Box } from '@gaming1/g1-ui';

const INBOX_NOTIFICATION_POSITION_TOP = -4;
const INBOX_NOTIFICATION_POSITION_RIGHT = -4;

export const RoundIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spaces('sm')};
  height: 26px;
  width: 26px;
  border-radius: 50%;
  justify-content: center;
  position: relative;

  ${media.xl} {
    height: 40px;
    width: 40px;
  }
`;

export const IconProfileWrapper = styled(RoundIconWrapper)`
  background-color: ${({ theme }) =>
    isColorDark(theme.colors.topNavBackground)
      ? lighten(0.1)(theme.colors.topNavBackground)
      : darken(0.1)(theme.colors.topNavBackground)};
`;

export const NotificationTag = styled.div`
  align-self: flex-start;
  height: 9px;
  width: 9px;
  border: 1px solid ${colors('neutrals', { index: 5 })};
  border-radius: 50%;
  background-color: ${colors('primary')};
  position: absolute;
  top: 0;
  right: 0;

  ${media.xl} {
    height: 11px;
    width: 11px;
    top: 1px;
    right: 1px;
  }
`;

export const TopNavLinks = styled(AppButtonLink)`
  padding: ${spaces('xs')};
  ${media.lg} {
    height: 40px;
    padding: 0 ${spaces('sm')};
    display: flex;
    align-items: center;
  }
`;

export const InboxNotificationContainer = styled(Box)`
  position: absolute;
  top: ${INBOX_NOTIFICATION_POSITION_TOP}px;
  right: ${INBOX_NOTIFICATION_POSITION_RIGHT}px;
`;
