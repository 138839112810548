import React, { VFC } from 'react';
import { shallowEqual } from 'react-redux';

import {
  getScoreBoardTeamNamesSelector,
  getScoreBoardTotalScorePerTeamSelector,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useMediaBreakPoint } from '@gaming1/g1-ui';

import { SCOREBOARD_SCORE_SEPARATOR } from '../../constants';
import { ScoreBoardBackground as Background } from '../ScoreBoardBackground';
import { ScoreBoardPlayTime } from '../ScoreBoardPlayTime';
import { ScoreBoardTemplateProps } from '../ScoreBoardTemplateManager/types';

import {
  ScoreBoardFullTemplateColumn as TemplateColumn,
  ScoreBoardFullTemplateContentWrapper as TemplateContentWrapper,
  ScoreBoardFullTemplateItem as TemplateItem,
  ScoreBoardFullTemplateRow as TemplateRow,
  ScoreBoardFullTemplateWrapper as TemplateWrapper,
} from './styles';

/** Displays the full scoreboard with a point template */
export const ScoreBoardFullPointTemplate: VFC<ScoreBoardTemplateProps> = ({
  eventId,
  sportId,
}) => {
  const isDesktop = useMediaBreakPoint({ min: 'lg' });
  const {
    team1: name1,
    team2: name2,
    separator,
  } = useBettingGetterSelector({
    getterSelector: getScoreBoardTeamNamesSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });
  const { team1: score1, team2: score2 } = useBettingGetterSelector({
    getterSelector: getScoreBoardTotalScorePerTeamSelector,
    args: [eventId],
    equalityFn: shallowEqual,
  });

  return (
    <TemplateWrapper data-testid="scoreboard-full-point-template">
      <Background sportId={sportId}>
        <TemplateContentWrapper justifyContent="center">
          <TemplateColumn gap="md" width="100%">
            <TemplateRow justifyContent="center">
              <ScoreBoardPlayTime eventId={eventId} fontSize="md" />
            </TemplateRow>
            <TemplateRow justifyContent="center">
              <TemplateItem
                flex={1}
                fontSize={isDesktop ? 'xl' : 'md'}
                pr="md"
                textAlign="right"
                alignItems="flex-end"
              >
                {name1}
              </TemplateItem>
              <TemplateItem fontSize={isDesktop ? 'xxl' : 'xl'}>
                {score1}
              </TemplateItem>
              <TemplateItem fontSize={isDesktop ? 'xxl' : 'xl'}>
                {separator || SCOREBOARD_SCORE_SEPARATOR}
              </TemplateItem>
              <TemplateItem fontSize={isDesktop ? 'xxl' : 'xl'}>
                {score2}
              </TemplateItem>
              <TemplateItem
                flex={1}
                fontSize={isDesktop ? 'xl' : 'md'}
                pl="md"
                alignItems="start"
              >
                {name2}
              </TemplateItem>
            </TemplateRow>
          </TemplateColumn>
        </TemplateContentWrapper>
      </Background>
    </TemplateWrapper>
  );
};
