import React from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { LazyRoute } from '@gaming1/g1-core-web';

import { userRoutes } from './routes';

export const getUserRouter = (locale: LocaleCode) => [
  <LazyRoute
    exact
    componentName="LoginPage"
    importFactory={() => import('./login/pages/LoginPage')}
    key="login"
    path={`/${locale}${userRoutes.login.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="RegistrationPage"
    importFactory={() => import('./registration/pages/RegistrationPage')}
    key="registration"
    path={`/${locale}${userRoutes.registration.path}`}
    section="registration"
    type="protected"
    loggedOutOnly
  />,
  <LazyRoute
    exact
    componentName="RegistrationFormPage"
    importFactory={() => import('./registration/pages/RegistrationFormPage')}
    key="registrationForm"
    path={`/${locale}${userRoutes.registrationForm.path}`}
    section="registration"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="PasswordRecoveryPage"
    importFactory={() =>
      import('./passwordRecovery/pages/PasswordRecoveryPage')
    }
    key="passwordRecovery"
    path={`/${locale}${userRoutes.passwordRecovery.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    exact
    componentName="PasswordResetPage"
    importFactory={() => import('./passwordRecovery/pages/PasswordResetPage')}
    key="passwordReset"
    path={`/${locale}${userRoutes.passwordReset.path}`}
    section="default"
    type="protected"
    loggedOutOnly
  />,
  <LazyRoute
    componentName="ProfilePage"
    importFactory={() => import('./profile/pages/ProfilePage')}
    key="profile"
    path={`/${locale}${userRoutes.profile.path}`}
    section="profile"
    type="protected"
  />,
  <LazyRoute
    componentName="AccountRouter"
    importFactory={() => import('./account/AccountRouter')}
    key="account"
    path={`/${locale}${userRoutes.account.path}`}
    section="account"
    type="app"
  />,
  <LazyRoute
    componentName="AccountActivationPage"
    importFactory={() =>
      import('./accountActivation/pages/AccountActivationPage')
    }
    key="activation"
    path={`/${locale}${userRoutes.accountActivation.path}`}
    section="default"
    type="app"
  />,
  <LazyRoute
    componentName="ImpersonationPage"
    importFactory={() => import('./login/pages/ImpersonationPage')}
    key="impersonation"
    path={`/${locale}${userRoutes.impersonation.path}`}
    section="default"
    type="app"
  />,
];
