import * as t from 'io-ts';

export const shellParametersCodec = t.partial({
  MinimumMobileAppVersion: t.string,
  AvailableLanguages: t.array(t.string),
  // Those should always be there, but they are in the partial because we don't
  // use them for now
  IsWaitForMetaDataAckEnabled: t.boolean,
  IsCrawler: t.boolean,
  RoomDomainName: t.string,
  LanguageIsoCode: t.string,
  IsPacketCompressionEnabled: t.boolean,
  ServerAddress: t.string,
  AppRootUrl: t.string,
  UserAgent: t.string,
  IsBettingMaintenance: t.boolean,
  GeoLocatedCode: t.string,
  IsSubDomainEnabled: t.boolean,
});

export type ShellParameters = t.TypeOf<typeof shellParametersCodec>;
