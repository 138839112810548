import { createAsyncAction } from 'typesafe-actions';

import { GetBiggestWinningsRequestDTO } from '@gaming1/g1-requests-gaming';
import { FailurePayload } from '@gaming1/g1-utils';

import { GetBiggestWinnings } from './types';

export const fetchBiggestWinnings = createAsyncAction(
  'gaming/fetch_biggest_winnings_request',
  'gaming/fetch_biggest_winnings_success',
  'gaming/fetch_biggest_winnings_failure',
)<
  Pick<GetBiggestWinningsRequestDTO, 'Count' | 'PageNumber' | 'PageSize'>,
  GetBiggestWinnings & { request: GetBiggestWinningsRequestDTO },
  FailurePayload & { request: GetBiggestWinningsRequestDTO }
>();
