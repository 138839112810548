import styled, { css } from 'styled-components';

import { colors, fontSizes, fontWeight, spaces } from '@gaming1/g1-style';
import { Box, SimpleButton, Text } from '@gaming1/g1-ui';

const ICON_CONTAINER_WIDTH_IN_PX = 40;
const ICON_CONTAINER_HEIGHT_IN_PX = 40;
const ICON_CONTAINER_BORDER_RADIUS_IN_PX = 4;

type OffersAvailableContainerProps = {
  isOfferSelected?: boolean;
};

export const OffersAvailableContainer = styled(
  Box,
)<OffersAvailableContainerProps>`
  ${({ isOfferSelected }) =>
    isOfferSelected
      ? css`
          background-color: ${colors('deposit')};
        `
      : css`
          background-color: ${colors('backgrounds', { index: 1 })};
        `}

  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${spaces('sm')};
  border-radius: ${ICON_CONTAINER_BORDER_RADIUS_IN_PX}px;
`;
export const OffersAvailableErrorContainer = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${spaces('sm')};
  border-radius: ${ICON_CONTAINER_BORDER_RADIUS_IN_PX}px;
`;
const chooseOrDeleteOffer = css`
  ${fontWeight('bold')};
  font-size: ${fontSizes('md')};
  text-transform: uppercase;
  padding: 0 ${spaces('sm')};
`;

export const ChooseButton = styled(SimpleButton)`
  ${chooseOrDeleteOffer};
  color: ${colors('deposit')};
`;

export const RemoveSelectedOfferButton = styled(SimpleButton)`
  ${chooseOrDeleteOffer};
  color: ${colors('textDark')};
`;

export const IconContainer = styled(Box)<OffersAvailableContainerProps>`
  align-items: center;
  justify-content: center;
  background-color: ${colors('deposit')};
  height: ${ICON_CONTAINER_HEIGHT_IN_PX}px;
  width: ${ICON_CONTAINER_WIDTH_IN_PX}px;
  border-radius: ${ICON_CONTAINER_BORDER_RADIUS_IN_PX}px 0 0
    ${ICON_CONTAINER_BORDER_RADIUS_IN_PX}px;
  ${({ isOfferSelected }) =>
    isOfferSelected &&
    css`
      border-right: 1px solid ${colors('textDark')};
      border-radius: 4px 0 0 4px;
    `}
`;

export const NumberOfAvailableGiftsText = styled(Text)`
  ${fontWeight('bold')};
  margin-top: 0;
  margin-left: ${spaces('sm')};
  font-size: ${fontSizes('md')};
  color: ${colors('deposit')};
`;

export const SelectedOfferTitle = styled(Text)`
  ${fontWeight('bold')};
  margin-left: ${spaces('sm')};
  margin-top: 0;
  font-size: ${fontSizes('lg')};
  color: ${colors('textDark')};
`;
