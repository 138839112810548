/* *************************************************************** */
/*                                                                 */
/*              Generated source file - Do not modify              */
/*                                                                 */
/*                       07:27:44 07-07-2021                       */
/*                                                                 */
/* *************************************************************** */

import { createRequest } from '@gaming1/g1-network';

import {
  GetAvailableBoostusV4Request,
  GetAvailableBoostusV4Response,
  GetConditionsForBettingSlipRequest,
  GetConditionsForBettingSlipResponse,
  HasAvailableFeatureRequest,
  HasAvailableFeatureResponse,
} from '../entities';

export const hasAvailableBoostus = (options: HasAvailableFeatureRequest) =>
  createRequest<HasAvailableFeatureResponse>(
    202,
    'HasAvailableBoostus',
    options,
  );

export const getAvailableBoostusV4 = (options: GetAvailableBoostusV4Request) =>
  createRequest<GetAvailableBoostusV4Response>(
    202,
    'GetAvailableBoostusV4',
    options,
  );

export const getBoostusConditionsForBettingSlip = (
  options: GetConditionsForBettingSlipRequest,
) =>
  createRequest<GetConditionsForBettingSlipResponse>(
    202,
    'GetBoostusConditionsForBettingSlip',
    options,
  );
