import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import { Box, Icon } from '@gaming1/g1-ui';

import { oddUpdateAnimation } from '../../../styles';

export const PrevCurrentOddContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const OddContainer = styled(Box)`
  text-align: center;
  padding: ${spaces('xxs')};
  flex-direction: row;
`;

export const PrevOddContainer = styled.span`
  margin-right: ${spaces('xxs')};
  text-decoration: line-through;
  opacity: ${opacity('seeThrough')};
`;

export const FormattedOdds = styled.span`
  ${fontWeight('bold')};
  font-size: ${fontSizes('md')};
`;

export const IndicatorIcon = styled(Icon)`
  opacity: 0;

  &.increase,
  &.decrease {
    ${oddUpdateAnimation()};
  }

  &.increase {
    path {
      fill: ${colors('success')};
    }
  }

  &.decrease {
    path {
      fill: ${colors('danger')};
    }
  }
`;
