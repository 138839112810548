import { reduceReducers } from '@gaming1/g1-store';

import { LoyaltyActions } from '../../types';
import { HistoryState } from '../types';

import { freeSpinsHistoryReducer } from './freeSpins';
import { initialState } from './initialState';
import { scratchcardHistoryReducer } from './scratchcard';
import { shopHistoryReducer } from './shop';
import { sponsorshipHistoryReducer } from './sponsorship';

export const historyReducer = reduceReducers<HistoryState, LoyaltyActions>(
  initialState,
  freeSpinsHistoryReducer,
  scratchcardHistoryReducer,
  sponsorshipHistoryReducer,
  shopHistoryReducer,
);
