import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { BettingActions } from '../../store/types';

import * as actions from './actions';
import { BettingHomeState } from './types';

export const initialState: BettingHomeState = {
  homeModuleOrder: null,
  requests: {
    getBettingHomeModuleOrder: generateInitialRequestState(null),
  },
};

export const bettingHomeReducer = (
  state: BettingHomeState = initialState,
  action: BettingActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getHomeModuleOrder.request):
        produceLoadingState(draftState.requests.getBettingHomeModuleOrder);
        break;

      case getType(actions.getHomeModuleOrder.failure):
        produceFailureState(
          draftState.requests.getBettingHomeModuleOrder,
          action.payload,
        );
        break;

      case getType(actions.getHomeModuleOrder.success):
        produceSuccessState(draftState.requests.getBettingHomeModuleOrder);
        draftState.homeModuleOrder = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
