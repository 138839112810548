import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { colors, elevation, media, spaces, zIndex } from '@gaming1/g1-style';
import { SimpleButton } from '@gaming1/g1-ui';

import { FullScreenProps } from './types';

type FullScreenWrapperProps = Omit<FullScreenProps, 'onClose'>;

export const FullScreenWrapperAside = styled.aside.attrs({
  'aria-modal': true,
  role: 'dialog',
  tabIndex: -1,
})<FullScreenWrapperProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  ${elevation(0)};
  overflow-y: auto;
  overflow-x: hidden;
  color: ${colors('text')};
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
  z-index: ${zIndex('fullscreen')};

  /* Removes the outline created by the tabindex attr */
  &:focus {
    outline: none;
  }
`;

export const FullScreenWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  ${elevation(0)};
  transform: translateZ(0);
  position: relative;
  z-index: ${zIndex('fullscreen')};
  display: flex;
  flex-direction: column;

  /** The 'width: 100vw' trick is used to avoid the content "jumping" when the scroll bar appears */
  ${media.md} {
    width: 100vw;
  }
`;

export type FullScreenHeaderProps = {
  closeButtonOnly?: boolean;
  showLogoInHeader?: boolean;
};

const closeButtonCss = css`
  position: absolute;
  z-index: ${zIndex('fullscreen', 1)};
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: ${({ theme }) => theme.options.buttonsFontSize};

    path {
      fill: ${colors('text')};
    }
  }

  ${media.lg} {
    top: ${spaces('md')};
    right: ${spaces('lg')};
    width: 42px;
    height: 42px;

    border-radius: 50%;
    background-color: ${colors('backgroundLight', {
      transform: transparentize(0.3),
    })};

    svg path {
      fill: ${colors('textDark')};
    }
  }
`;

export const CloseButton = styled(SimpleButton)`
  ${closeButtonCss}
`;

export const CloseLink = styled(Link)`
  ${closeButtonCss}
`;

export const FullScreenContentDiv = styled.div`
  ${elevation(0)};
  display: flex;
  flex-direction: column;
  flex: 1;
  ${space}
`;
