import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { UserActions } from '../../store/types';

import * as actions from './actions';
import { PersonalDataState } from './types';

export const initialState: PersonalDataState = {
  residenceCountries: null,
  states: null,
  requests: {
    changePlayerProfile: generateInitialRequestState(null),
    residenceCountries: generateInitialRequestState(null),
    states: generateInitialRequestState(null),
  },
};

export const personalDataReducer = (
  state: PersonalDataState = initialState,
  action: UserActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      /**
       * Change user profile
       */
      case getType(actions.changeUserProfile.request):
        produceLoadingState(draftState.requests.changePlayerProfile);
        break;

      case getType(actions.changeUserProfile.failure):
        produceFailureState(
          draftState.requests.changePlayerProfile,
          action.payload,
        );
        break;

      case getType(actions.changeUserProfile.success):
        produceSuccessState(draftState.requests.changePlayerProfile);
        break;

      /**
       * Get residence countries
       */
      case getType(actions.getResidenceCountries.request):
        produceLoadingState(draftState.requests.residenceCountries);
        break;

      case getType(actions.getResidenceCountries.failure):
        produceFailureState(
          draftState.requests.residenceCountries,
          action.payload,
        );
        break;

      case getType(actions.getResidenceCountries.success):
        produceSuccessState(draftState.requests.residenceCountries);
        draftState.residenceCountries = action.payload.List;
        break;

      /**
       * Get states
       */
      case getType(actions.getStates.request):
        produceLoadingState(draftState.requests.states);
        break;

      case getType(actions.getStates.failure):
        produceFailureState(draftState.requests.states, action.payload);
        break;

      case getType(actions.getStates.success):
        produceSuccessState(draftState.requests.states);
        draftState.states = action.payload.States;
        break;

      default: // Immer will automatically return the state
    }
  });
