import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  getRegionsNavigationSelector,
  NavSportType,
  useBettingGetterSelector,
} from '@gaming1/g1-betting';
import { useTranslation } from '@gaming1/g1-i18n';

import { ColoredIcon } from '../../../common/components/ColoredIcon';
import { MenuItem } from '../../../common/components/MenuItem';
import { cleanParam } from '../../../helpers';
import { useBettingRoutePath } from '../../../hooks';
import { useRouteConfig } from '../../../routeurHooks';
import { getIconType } from '../../helpers';

import { RegionMenuItem } from './RegionMenuItem';
import { MenuIcon, RegionMenuItemContainer } from './styles';

type SportMenuItemProps = {
  isCollapsed?: boolean;
  item: NavSportType;
};

const SportMenuItemComponent: FC<SportMenuItemProps> = ({
  isCollapsed = false,
  item,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const routeConfig = useRouteConfig();

  const { activeSportId, regionId, leagueId, eventId } = useMemo(() => {
    const match = matchPath<{
      sportId?: string;
      regionId?: string;
      leagueId?: string;
      eventId?: string;
    }>(location.pathname, {
      path: Object.values(routeConfig).map((route) => route.path),
      exact: true,
    });
    if (!match) {
      return {
        params: {
          sportId: null,
          regionId: null,
          leagueId: null,
          eventId: null,
        },
      };
    }
    return {
      activeSportId: cleanParam(match.params.sportId?.split('/')[0]),
      regionId: cleanParam(match.params.regionId?.split('/')[0]),
      leagueId: cleanParam(match.params.leagueId?.split('/')[0]),
      eventId: cleanParam(match.params.eventId?.split('/')[0]),
    };
  }, [routeConfig, location.pathname]);

  const { t } = useTranslation('betting');
  const [isActive, setIsActive] = useState(true);

  const isExpanded = activeSportId === item.Id.toString() && isActive;
  const testId = `sportmenu-sport-toggle-${item.Id}`;
  const regions = useBettingGetterSelector({
    getterSelector: getRegionsNavigationSelector,
    args: [item.Id],
    equalityFn: shallowEqual,
  });

  useEffect(() => {
    if (activeSportId === item.Id.toString()) {
      setIsActive(true);
    }
  }, [activeSportId, item.Id]);

  const getBettingRoutePath = useBettingRoutePath();

  const content = useMemo(
    () => (
      <>
        <MenuItem
          key={`sport-menu-item-${item.Id}`}
          elevationLevel={2}
          mb="1px"
          flexDirection="column"
          data-testid={testId}
        >
          <MenuItem.Link
            onClick={(e) => {
              if (activeSportId === item.Id.toString()) {
                e.preventDefault();
              }
              setIsActive(!isActive);
            }}
            to={getBettingRoutePath('sportMatches', {
              sportId: String(item.Id),
              temporalFilter: '',
            })}
          >
            {!isCollapsed ? (
              <MenuItem.Content width="100%" padding={0}>
                <MenuItem.Left isActive={isExpanded}>
                  <MenuIcon
                    id="sport-menu-icon"
                    type={getIconType(item.Alias)}
                  />
                </MenuItem.Left>
                <MenuItem.Content>{item.Name}</MenuItem.Content>
                <MenuItem.Right>
                  <ColoredIcon
                    color={
                      isExpanded
                        ? theme.colors.neutrals[0]
                        : theme.colors.neutrals[3]
                    }
                    type={isExpanded ? 'AngleUp' : 'AngleDown'}
                    id={
                      isExpanded
                        ? 'sport-menu-item-active'
                        : 'sport-menu-item-not-active'
                    }
                  />
                </MenuItem.Right>
              </MenuItem.Content>
            ) : (
              <MenuItem.Content
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <MenuIcon id="sport-menu-icon" type={getIconType(item.Alias)} />
              </MenuItem.Content>
            )}
          </MenuItem.Link>
        </MenuItem>
        {isExpanded && !isCollapsed && (
          <>
            {regions.length > 1 && (
              <RegionMenuItemContainer
                key="sport-menu-all-sports"
                elevationLevel={2}
                hasNoShadow
                pl={6}
                width="100%"
                isActive={!regionId && !leagueId && !eventId}
              >
                <MenuItem.Link
                  to={getBettingRoutePath('sportMatches', {
                    sportId: String(item.Id),
                    temporalFilter: '',
                  })}
                >
                  <MenuItem.Content
                    fontWeight={isExpanded && regionId === null ? 'bold' : null}
                  >
                    {t('event.goToSport', {
                      sportName: item.Name,
                    })}
                  </MenuItem.Content>
                </MenuItem.Link>
              </RegionMenuItemContainer>
            )}
            {regions.map((region) => (
              <RegionMenuItem key={region.Id} item={region} />
            ))}
          </>
        )}
      </>
    ),
    [
      item,
      activeSportId,
      setIsActive,
      regions,
      isExpanded,
      regionId,
      leagueId,
      eventId,
      getBettingRoutePath,
      isActive,
      isCollapsed,
      t,
      testId,
      theme,
    ],
  );

  return content;
};

export const SportMenuItem = memo(SportMenuItemComponent);
