import {
  EGetGameStatus,
  ERegisterGameStatus,
} from '@gaming1/g1-requests-gaming';

export const gameErrorMessages = {
  [EGetGameStatus.InternalError]: 'core:error.internalServer',
  [EGetGameStatus.GameNotFound]: 'gaming:startGame.message.GameNotFound',
  [EGetGameStatus.DisabledGame]: 'gaming:startGame.message.DisabledGame',
  [EGetGameStatus.BadGameId]: 'gaming:startGame.message.GameNotFound',
};

export const registerGameErrorMessages = {
  [ERegisterGameStatus.GameUnavailable]: 'gameLauncher.splash.errors.generic',
  [ERegisterGameStatus.InternalError]: 'gameLauncher.splash.errors.generic',
  [ERegisterGameStatus.InvalidGame]: 'gameLauncher.splash.errors.generic',
  [ERegisterGameStatus.InvalidRoom]: 'gameLauncher.splash.errors.generic',
  [ERegisterGameStatus.LaunchFailed]: 'gameLauncher.splash.errors.generic',
  [ERegisterGameStatus.NotActivatedPlayer]: 'gameLauncher.splash.errors.player',
  [ERegisterGameStatus.NotAuthenticatedPlayer]:
    'gameLauncher.splash.errors.playerAuth',
  [ERegisterGameStatus.RegistrationFailed]:
    'gameLauncher.splash.errors.registrationFailed',
  [ERegisterGameStatus.ResumeGameFailed]: 'gameLauncher.splash.errors.generic',
  [ERegisterGameStatus.WaitingForIdentificationPlayer]:
    'gameLauncher.splash.errors.generic',
};
