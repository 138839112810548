import React, { memo, useContext, VFC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import {
  actions,
  boostusSelectedForBSGetterSelector,
  EFrontErrorType,
  freebetSelectedForBSGetterSelector,
  getNumberOfGiftsForBSGetterSelector,
  promotionSelectedForBSGetterSelector,
  useBettingGetterSelector,
  useBettingSlipAllowActions,
  useBettingSlipBoostusErrorType,
  useBettingSlipFreebetErrorType,
  useBettingSlipPromotionErrorType,
} from '@gaming1/g1-betting';
import {
  useFormatMoney,
  useFormatNumber,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Icon, IconType } from '@gaming1/g1-ui';

import { multiplyBy100 } from '../../../boostus/helpers';
import { BettingSlipContext } from '../../BettingSlipContext';
import { BettingSlipMessage } from '../BettingSlipMessage';
import { SubDrawerContext } from '../SubDrawer/SubDrawerContext';

import {
  ChooseButton,
  IconContainer,
  NumberOfAvailableGiftsText,
  OffersAvailableContainer,
  OffersAvailableErrorContainer,
  RemoveSelectedOfferButton,
  SelectedOfferTitle,
} from './styles';
import { OfferType } from './types';

/** Render a bar to show above the BettingSlip "footer" to display how many offers are available to the user to use.
 *  This bar has a ctx to open a drawer with the offers available.
 */

type OfferInfo = {
  type: OfferType;
  icon: IconType;
  title: JSX.Element;
  cta: () => void;
  action: JSX.Element;
  error: JSX.Element | undefined;
};

export const OffersAvailableComponent: VFC = () => {
  const { t } = useTranslation('betting');

  const { bettingSlipId, type } = useContext(BettingSlipContext);
  const allowBSActions = useBettingSlipAllowActions(bettingSlipId);

  const { openOffersAvailableDrawer } = useContext(SubDrawerContext);
  const { colors } = useContext(ThemeContext);

  const formatMoney = useFormatMoney();

  const selectedFreebet = useBettingGetterSelector({
    getterSelector: freebetSelectedForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const selectedBoostus = useBettingGetterSelector({
    getterSelector: boostusSelectedForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const selectedPromotion = useBettingGetterSelector({
    getterSelector: promotionSelectedForBSGetterSelector,
    args: [bettingSlipId],
    equalityFn: shallowEqual,
  });

  const boostusError = useBettingSlipBoostusErrorType(bettingSlipId)();
  const freebetError = useBettingSlipFreebetErrorType(bettingSlipId)();
  const promotionError = useBettingSlipPromotionErrorType(bettingSlipId)();

  const formatNumber = useFormatNumber();
  const boostusValue =
    selectedBoostus &&
    formatNumber(multiplyBy100(selectedBoostus.boostValue), 0);

  const isUserLoggedIn = useSelector(userLoggedInSelector);

  const nbGifts = useBettingGetterSelector({
    getterSelector: getNumberOfGiftsForBSGetterSelector,
    args: [bettingSlipId],
  });

  if (!isUserLoggedIn) {
    return null;
  }
  if (nbGifts === 0) {
    return null;
  }
  if (type === 'system') {
    return null;
  }

  const deleteSelectedFreebet = () => {
    allowBSActions(actions.removeFreebetToBS({ bettingSlipId }));
  };

  const deleteSelectedBoostus = () => {
    allowBSActions(actions.removeBoostusToBS({ bettingSlipId }));
  };
  const deleteSelectedPromotion = () => {
    allowBSActions(actions.removePromotionToBS({ bettingSlipId }));
  };

  const updateStake = (newStake: string) => {
    allowBSActions(
      actions.updateStake({
        stake: newStake,
        bettingSlipId,
      }),
    );
  };

  const callToActionsFunc = {
    updateStake,
  };

  let offerInfo: OfferInfo = {
    type: 'none',
    action: (
      <ChooseButton data-testid="bettingslip-offers-available-choose-button">
        {t('bettingslip.offersAvailable.button.text')}
      </ChooseButton>
    ),
    cta: openOffersAvailableDrawer,
    error: undefined,
    icon: 'Gift',
    title: (
      <NumberOfAvailableGiftsText
        data-testid={`bettingslip-offers-available-${nbGifts}`}
      >
        {t('bettingslip.offersAvailable.description', {
          count: nbGifts,
        })}
      </NumberOfAvailableGiftsText>
    ),
  };

  if (selectedFreebet) {
    offerInfo = {
      type: 'freebet',
      action: (
        <RemoveSelectedOfferButton data-testid="bettingslip-selected-freebet-offer-delete-button">
          {t('bettingslip.offersSelected.button.text')}
        </RemoveSelectedOfferButton>
      ),
      cta: deleteSelectedFreebet,
      error:
        freebetError &&
        freebetError.Status === EFrontErrorType.InvalidFreebet ? (
          <BettingSlipMessage
            testId="bettingslip-freebet-error"
            notification={freebetError}
          />
        ) : undefined,
      icon: 'FreebetV2',
      title: (
        <SelectedOfferTitle
          type="freebet"
          data-testid={`bettingslip-selected-freebet-offer-value-${selectedFreebet.amount}`}
        >
          {t('bettingslip.offersSelected.freebet', {
            amount: formatMoney(selectedFreebet.amount),
          })}
        </SelectedOfferTitle>
      ),
    };
  }

  if (selectedBoostus) {
    offerInfo = {
      type: 'boostus',
      action: (
        <RemoveSelectedOfferButton data-testid="bettingslip-selected-boostus-offer-delete-button">
          {t('bettingslip.offersSelected.button.text')}
        </RemoveSelectedOfferButton>
      ),
      cta: deleteSelectedBoostus,
      error:
        boostusError && boostusError.Status !== EFrontErrorType.None ? (
          <BettingSlipMessage
            testId="bettingslip-boostus-error"
            notification={boostusError}
            callToActionsFunc={callToActionsFunc}
          />
        ) : undefined,
      icon: 'Boostus',
      title: (
        <SelectedOfferTitle
          type="boostus"
          data-testid={`bettingslip-selected-boostus-offer-value-${selectedBoostus.boostValue}`}
        >
          {t('bettingslip.offersSelected.boostus', {
            percentage: boostusValue,
          })}
        </SelectedOfferTitle>
      ),
    };
  }

  if (selectedPromotion) {
    offerInfo = {
      type: 'promotion',
      action: (
        <RemoveSelectedOfferButton data-testid="bettingslip-selected-promotion-offer-delete-button">
          {t('bettingslip.offersSelected.button.text')}
        </RemoveSelectedOfferButton>
      ),
      cta: deleteSelectedPromotion,
      error:
        promotionError && promotionError.Status !== EFrontErrorType.None ? (
          <BettingSlipMessage
            testId="bettingslip-promotion-error"
            notification={promotionError}
            callToActionsFunc={callToActionsFunc}
          />
        ) : undefined,
      icon: 'BettingPromotion',
      title: (
        <SelectedOfferTitle
          type="promotion"
          data-testid="bettingslip-selected-promotion-offer"
        >
          {selectedPromotion.title}
        </SelectedOfferTitle>
      ),
    };
  }

  const isOfferSelected = Boolean(
    selectedFreebet || selectedBoostus || selectedPromotion,
  );

  return (
    <>
      <OffersAvailableContainer
        data-testid="bettingslip-offers-available-container"
        onClick={offerInfo.cta}
      >
        <Box flexDirection="row" alignItems="center" width="100%">
          <IconContainer
            isOfferSelected={isOfferSelected}
            type={offerInfo.type}
          >
            <Icon
              id={offerInfo.icon}
              type={offerInfo.icon}
              fill={isOfferSelected ? '' : colors.textDark}
              width="18px"
              height="18px"
            />
          </IconContainer>

          {offerInfo.title}
        </Box>
        {offerInfo.action}
      </OffersAvailableContainer>
      {offerInfo.error && (
        <OffersAvailableErrorContainer data-testid="bettingslip-offers-available-error-container">
          {offerInfo.error}
        </OffersAvailableErrorContainer>
      )}
    </>
  );
};

export const OffersAvailable = memo(OffersAvailableComponent);
