import { of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { createFailurePayload, mapGuard } from '@gaming1/g1-utils';

import { CoreEpic } from '../types';

import * as actions from './actions';
import { shellParametersCodec } from './codecs';
/**
 * When the app launch, init the websocket connection
 */
export const appInitEpic: CoreEpic = (action$, _, { config$, wsAdapter }) =>
  action$.pipe(
    filter(isActionOf(actions.initApp)),
    withLatestFrom(config$),
    tap(([action, config]) => {
      const { locale, wsAdapterOptions } = action.payload;
      wsAdapter.init({
        address: config.network.wsUrl,
        compression: config.network.wsCompression,
        locale,
        room: config.room.roomName,
        ...wsAdapterOptions,
      });
    }),
    ignoreElements(),
  );

/**
 * When the user is exiting the application, let the websocket know
 */
export const appExitEpic: CoreEpic = (actions$, _, { wsAdapter }) =>
  actions$.pipe(
    filter(isActionOf(actions.exitApp)),
    tap(wsAdapter.prepareDisconnection),
    ignoreElements(),
  );

/** Fetch the shell parameters from the ajax api */
export const getShellParametersEpic: CoreEpic = (
  actions$,
  _,
  { ajaxFetch, config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.getShellParams.request)),
    withLatestFrom(config$),
    mergeMap(([, config]) =>
      ajaxFetch(`${config.network.apiUrl}/Ajax/GetShellParameters.ashx`).pipe(
        mapGuard(shellParametersCodec),
        map(actions.getShellParams.success),
        catchError((err) =>
          of(actions.getShellParams.failure(createFailurePayload(err))),
        ),
      ),
    ),
  );

export const appInitToGetShellParamsEpic: CoreEpic = (
  actions$,
  _,
  { config$ },
) =>
  actions$.pipe(
    filter(isActionOf(actions.initApp)),
    pluck('payload'),
    withLatestFrom(config$),
    // only fetch if the app is running inside a mobile app or if the available languages come from the backend
    filter(
      ([payload, config]) =>
        payload.isInMobileApp || config.i18n.shouldBeRestrictedByShellParams,
    ),
    mapTo(actions.getShellParams.request()),
  );
