import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeight,
  media,
  opacity,
  spaces,
} from '@gaming1/g1-style';
import {
  AppButtonLink,
  Box,
  CollapsiblePanel,
  SimpleButton,
  Text,
} from '@gaming1/g1-ui';

const SLIDE_SELECTOR_SIZE_IN_PX = 8;
const CAROUSEL_SLIDE_INFO_MARGININ_FOR_DESKTOP_IN_PX = 85;
const CAROUSEL_SLIDE_INFO_MARGININ_FOR_MOBILE_IN_PX = 55;
const CARD_HEADING_CUSTOM_LINE_HEIGHT_IN_PX = 32;
const IMAGE_HEIGHT_FOR_DESKTOP_IN_PX = 200;
const IMAGE_HEIGHT_FOR_MOBILE_IN_PX = 180;

export const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  margin-top: ${spaces('lg')};
  white-space: pre-line;
`;

export const ModalContent = styled.div`
  background-color: ${colors('backgrounds', { index: 0 })};
`;

export const StyledImage = styled.img`
  width: 100%;
  height: ${IMAGE_HEIGHT_FOR_MOBILE_IN_PX}px;

  ${media.lg} {
    height: ${IMAGE_HEIGHT_FOR_DESKTOP_IN_PX}px;
  }
`;

export const StyledPromoWrapper = styled.div`
  padding: ${spaces('sm')} ${spaces('md')};
`;

export const StyledRules = styled(Text)`
  font-size: ${spaces('sm')};
  height: 100%;
  margin-bottom: ${spaces('sm')};
  margin-top: ${spaces('xs')};
`;

export const StyledTitle = styled(Text)`
  line-height: ${CARD_HEADING_CUSTOM_LINE_HEIGHT_IN_PX}px;
  color: ${colors('primary')};
  font-size: ${fontSizes('xl')};
  font-weight: ${fontWeight('bold')};
  margin-top: 0;
`;

export const StyledLink = styled(AppButtonLink)`
  padding: ${spaces('sm')} ${spaces('xxxl')};
`;

export const Carousel = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type CarouselButtonProps = {
  disabled: boolean;
};

export const CarouselButton = styled(SimpleButton)<CarouselButtonProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${opacity('translucent')};
    `}
`;

export const SlideSelectorsContainer = styled.div`
  display: flex;
  margin: ${spaces('xxxs')};
`;

type SlideSelectorProps = {
  isSelected: boolean;
};

export const SlideSelector = styled.button.attrs({
  'data-testid': 'carousel-selector',
})<SlideSelectorProps>`
  width: ${SLIDE_SELECTOR_SIZE_IN_PX}px;
  height: ${SLIDE_SELECTOR_SIZE_IN_PX}px;
  border-radius: 50%;
  margin: ${spaces('xxxs')};
  padding: ${spaces('none')};
  background-color: ${colors('scrollNavButtonBackground')};
  border: none;
  -webkit-tap-highlight-color: transparent;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${colors('primary')};
    `}

  &:enabled {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const CarouselSlideInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 ${CAROUSEL_SLIDE_INFO_MARGININ_FOR_MOBILE_IN_PX}px;

  p {
    margin-top: 0;
  }

  ${media.sm} {
    margin: 0 ${CAROUSEL_SLIDE_INFO_MARGININ_FOR_DESKTOP_IN_PX}px;
  }
`;
