import * as t from 'io-ts';

import {
  EGetRunningJackpotsStatus,
  ERunningJackpotState,
  ERunningJackpotType,
} from '@gaming1/g1-requests-gaming';
import { createEnumType } from '@gaming1/g1-utils';

export const platformJackpot = t.intersection(
  [
    t.type({
      Id: t.string,
      Name: t.string,
      Amount: t.number,
      State: createEnumType<ERunningJackpotState>(ERunningJackpotState),
      Type: createEnumType<ERunningJackpotType>(ERunningJackpotType),
    }),
    t.partial({
      MaxAmount: t.number,
      RemainingSeconds: t.number,
    }),
  ],
  'PlatformJackpot',
);

export const getPlatformJackpotsResponse = t.type(
  {
    Status: t.literal(EGetRunningJackpotsStatus.Success),
    RunningJackpots: t.array(platformJackpot),
  },
  'GetPlatformJackpotResponse',
);
