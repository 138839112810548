import styled from 'styled-components';

import { colors, media, spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

import { PrismicWrapper } from '../styles';

const COUNTER_SIZE = 30;

export const PrismicStepsWrapper = styled(PrismicWrapper)`
  margin-left: auto;
  margin-right: auto;

  ${media.lg} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const PrismicStepsContainer = styled(Box)`
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 ${spaces('sm')};

  ${media.lg} {
    flex-direction: row;
  }
`;

export const PrismicStepCounter = styled.span`
  background-color: ${colors('secondary')};
  border-radius: 50%;
  text-align: center;
  width: ${COUNTER_SIZE}px;
  height: ${COUNTER_SIZE}px;
  line-height: ${COUNTER_SIZE}px;
  display: block;
  margin-right: ${spaces('md')};
  flex-shrink: 0;

  ${media.lg} {
    margin: auto;
    margin-bottom: ${spaces('sm')};
  }
`;

export const PrismicStepDiv = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: left;

  ${media.lg} {
    display: block;
    text-align: center;
  }

  &:not(:last-of-type) {
    &:after {
      ${media.lg} {
        content: '';
        background-color: ${colors('neutrals', { index: 3 })};
        display: block;
        margin: auto;
        width: calc(100% - (${COUNTER_SIZE}px * 2));
        height: 2px;
        position: absolute;
        top: calc(${COUNTER_SIZE}px * 0.5);
        bottom: auto;
        left: calc(${COUNTER_SIZE}px + 50%);
      }
    }
  }
`;

export const PrismicStepsSeparator = styled.div`
  background-color: ${colors('neutrals', { index: 3 })};
  width: 2px;
  height: 25px;
  margin-left: calc(${COUNTER_SIZE}px * 0.5);
  margin-top: ${spaces('xxxs')};
  margin-bottom: ${spaces('xxxs')};
`;
