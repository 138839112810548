import * as t from 'io-ts';

import {
  EPlayerAcceptPrivacyConditionsResponseStatus,
  EPrivacyConditionsResponseStatus,
  EShowPrivacyConditions,
} from '@gaming1/g1-requests';

export const acceptPrivacyConditionsResponse = t.intersection(
  [
    t.type({
      Status: t.literal(
        EPlayerAcceptPrivacyConditionsResponseStatus.Successful,
      ),
    }),
    t.partial({
      // Message: t.string
    }),
  ],
  'AcceptPrivacyConditionsRequest',
);

export const getPrivacyConditionsResponse = t.intersection(
  [
    t.type({
      Status: t.literal(EPrivacyConditionsResponseStatus.Successful),
    }),
    t.partial({
      File: t.string,
      // Message: t.string
    }),
  ],
  'PrivacyConditionsResponse',
);

export const showPrivacyConditionsResponse = t.intersection(
  [
    t.type({
      HasAccepted: t.boolean,
      Status: t.literal(EShowPrivacyConditions.Successful),
    }),
    t.partial({
      // Message: t.string
    }),
  ],
  'ShowPrivacyConditionsResponse',
);
