/**
 * This enum lists all available messages between the web and the rn apps.
 * The tunnel is multi-directional.
 * TODO: split enum in two separate ones to help document the intended use and
 * direction of each message. Make sure to use PascalCase when doing so!
 */
export enum interopMessages {
  blacklistedRoutes = 'blacklistedRoutes',
  changeConnectionType = 'changeConnectionType',
  exitFullscreenLocation = 'exitFullscreenLocation',
  geocomplyStatus = 'geocomplyStatus',
  geolocationRequest = 'geolocationRequest',
  localeChanged = 'localeChanged',
  locationChanged = 'locationChanged',
  navigate = 'navigate',
  navigationDrawerStateChange = 'navigationDrawerStateChange',
  networkRequest = 'networkRequest',
  networkResponse = 'networkResponse',
  openAppSettings = 'openAppSettings',
  openBrowser = 'openBrowser',
  openExternalLinkView = 'openExternalLinkView',
  reduxActionDispatched = 'reduxActionDispatched',
  switchConfig = 'switchConfig',
  trackingEvent = 'trackingEvent',
}

/**
 * This enum list all available analytics/tracking messages types
 */
export enum TrackingEventKind {
  RawData = 'RawData',
  Event = 'Event',
}
