import { LocaleCode } from '@gaming1/g1-config';
import {
  EBettingSlipType,
  EventDisplayType,
} from '@gaming1/g1-requests-betting';

import { BettingSlipStringType, EventState } from './types';

type BettingMulticastType =
  | 'allScoreboards'
  | 'allSports'
  | 'countAllLive'
  | 'countSportLive'
  | 'event'
  | 'lastMinutes'
  | 'league'
  | 'lives'
  | 'liveSport'
  | 'outcome'
  | 'prematchSport'
  | 'scoreboard'
  | 'sport'
  | 'region'
  | 'topEvent';

type MulticastGeneratorValues = {
  allScoreboards: {
    locale: LocaleCode;
  };
  allSports: {
    locale: LocaleCode;
    showcaseMarkets?: boolean;
    type: EventState;
  };
  countAllLive: null;
  countSportLive: null;
  lives: { locale: LocaleCode };
  event: {
    eventId: number;
    locale: LocaleCode;
  };
  lastMinutes: {
    locale: LocaleCode;
    showcaseMarkets?: boolean;
    sportId: number;
  };
  league: {
    leagueId: number;
    locale: LocaleCode;
  };
  outcome: {
    locale: LocaleCode;
    outcomeId: number;
  };
  prematchSport: {
    sportId: number;
    locale: LocaleCode;
  };
  liveSport: {
    sportId: number;
    locale: LocaleCode;
  };
  scoreboard: {
    eventId: number;
    locale: LocaleCode;
  };
  sport: {
    filterId: number;
    locale: LocaleCode;
    showcaseMarkets?: boolean;
    sportId: number;
    type: EventState;
  };
  region: {
    locale: LocaleCode;
    sportId: number;
    regionId: number;
  };
  topEvent: {
    locale: LocaleCode;
    type: EventState;
  };
};

/**
 * Generate a multi cast group name used to subscribe to betting push updates
 * @param multicastType the type of updates
 * @param values the values to provide to construct the name (if any)
 */
export const generateBettingMulticastGroupName = <
  Type extends BettingMulticastType,
>(
  multicastType: Type,
  values: MulticastGeneratorValues[Type],
) => {
  switch (multicastType) {
    case 'allScoreboards': {
      const { locale } = values as MulticastGeneratorValues['allScoreboards'];
      return `v4_scoreboard_lives_${locale}`;
    }
    case 'allSports': {
      const { locale, showcaseMarkets, type } =
        values as MulticastGeneratorValues['allSports'];
      return `${showcaseMarkets ? 'scm_' : ''}sp_all_${type}_${locale}`;
    }
    case 'countAllLive': {
      return 'count_all_live_00';
    }
    case 'countSportLive': {
      return 'count_sport_live';
    }
    case 'lives': {
      const { locale } = values as MulticastGeneratorValues['lives'];
      return `v4_lives_${locale}`;
    }
    case 'event': {
      const { eventId, locale } = values as MulticastGeneratorValues['event'];
      return `v4_event_${eventId}_${locale}`;
    }
    case 'lastMinutes': {
      const { locale, showcaseMarkets, sportId } =
        values as MulticastGeneratorValues['lastMinutes'];
      return `${showcaseMarkets ? 'scm_' : ''}lm_${sportId}_${locale}`;
    }
    case 'prematchSport': {
      const { sportId, locale } =
        values as MulticastGeneratorValues['prematchSport'];
      return `v4_prematch_sport_${sportId}_${locale}`;
    }
    case 'liveSport': {
      const { sportId, locale } =
        values as MulticastGeneratorValues['liveSport'];
      return `v4_live_sport_${sportId}_${locale}`;
    }
    case 'league': {
      const { leagueId, locale } = values as MulticastGeneratorValues['league'];
      return `v4_league_${leagueId}_${locale}`;
    }
    case 'region': {
      const { locale, sportId, regionId } =
        values as MulticastGeneratorValues['region'];
      return `v4_region_${sportId}_${regionId}_${locale}`;
    }
    case 'outcome': {
      const { locale, outcomeId } =
        values as MulticastGeneratorValues['outcome'];
      return `v4_outcome_${outcomeId}_${locale}`;
    }
    case 'scoreboard': {
      const { eventId, locale } =
        values as MulticastGeneratorValues['scoreboard'];
      return `v4_scoreboard_${eventId}_${locale}`;
    }
    case 'sport': {
      const { filterId, locale, showcaseMarkets, sportId, type } =
        values as MulticastGeneratorValues['sport'];
      return `${
        showcaseMarkets ? 'scm_' : ''
      }sp_${type}_${sportId}_${filterId}_${locale}`;
    }
    case 'topEvent': {
      const { locale, type } = values as MulticastGeneratorValues['topEvent'];
      return `v4_topevent_${type}_${locale}`;
    }
    default:
      // Shouldn't happen since the union types is finite
      return '';
  }
};

/**
 * Gets the values of the teams based on the event type
 * (home and away reversed, separator ...).
 */
export const getEventTeamsValues = <T>(
  home: T,
  away: T,
  type: EventDisplayType = EventDisplayType.Unknown,
) => {
  const isAmerican = type === EventDisplayType.American;

  const team1 = isAmerican ? away : home;
  const team2 = isAmerican ? home : away;
  const separator = isAmerican && team1 && team2 ? '@' : '';

  return { team1, team2, separator };
};

/**
 * Gets the event name based on the event type
 * (home and away reversed, separator ...).
 */
export const getEventName = <T>(
  home: T,
  away: T,
  type: EventDisplayType = EventDisplayType.Unknown,
) => {
  const { team1, team2, separator } = getEventTeamsValues(home, away, type);

  return `${team1} ${separator || '-'} ${team2}`;
};

/** Gets a EBettingSlipType using a BettingSlipStringType */
export const getBettingSlipTypeEnumValue = (
  type: BettingSlipStringType,
): EBettingSlipType => {
  switch (type) {
    case 'single':
      return EBettingSlipType.Single;
    case 'combi':
      return EBettingSlipType.Combi;
    case 'system':
      return EBettingSlipType.System;
    default:
      // Should not happen
      return EBettingSlipType.Unknown;
  }
};

/** Converts a BettingSlipStringType into a EBettingSlipType */
export const convertWithBettingSlipString = (
  item: BettingSlipStringType | undefined,
): EBettingSlipType => {
  if (!item) {
    return EBettingSlipType.Combi;
  }
  if (item === 'single') {
    return EBettingSlipType.Single;
  }
  if (item === 'system') {
    return EBettingSlipType.System;
  }
  return EBettingSlipType.Combi;
};
