import React, { FC } from 'react';

import { MenuGroups } from '@gaming1/g1-core-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Icon } from '@gaming1/g1-ui';

import { BettingSlipButtonContainer, BettingSlipLinkContainer } from './styles';

export type BettingSlipLinkProps = {
  /** List of menu entries to display */
  menu: MenuGroups;
};

/** Render a list of links for the mobile bettingSlip */
export const BettingSlipMobileLinks: FC<BettingSlipLinkProps> = ({ menu }) => {
  const { t } = useTranslation('betting');
  return (
    <div data-testid="bettingSlip-ticket-links-container">
      {menu.map((group) => (
        <Box key={menu.indexOf(group)}>
          {group
            .filter((item) => item.hidden !== true)
            .map(({ testId, label, icon, ...props }) => {
              const content = (
                <Box
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                  mx="sm"
                >
                  {!!icon && typeof icon === 'string' ? (
                    <Icon
                      type={icon}
                      id={`betting-slip-link-${icon.toLowerCase()}`}
                      height="16px"
                    />
                  ) : (
                    icon
                  )}
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    ml="sm"
                  >
                    {t(label)}
                    <Icon
                      type="AngleRight"
                      id="bettingSlip-link-right"
                      rotate="90"
                    />
                  </Box>
                </Box>
              );

              return 'onClick' in props ? (
                <BettingSlipButtonContainer
                  onClick={props.onClick}
                  key={`${testId}${label}`}
                  data-testid={testId}
                >
                  {content}
                </BettingSlipButtonContainer>
              ) : (
                <BettingSlipLinkContainer
                  to={props.path}
                  key={`${testId}${label}`}
                  data-testid={testId}
                >
                  {content}
                </BettingSlipLinkContainer>
              );
            })}
        </Box>
      ))}
    </div>
  );
};
