import * as t from 'io-ts';

export const geoComplyLicenseKeyResponseCodec = t.type(
  {
    License: t.string,
  },
  'GeoComplyLicenseKeyResponse',
);

export const isGeoComplyLocationValidResponseCodec = t.type(
  {
    IsValid: t.boolean,
    ServerTime: t.string,
    ShouldGeoLocateBeforeTime: t.string,
    ShouldGeoLocateInSeconds: t.number,
  },
  'IsGeoComplyLocationValidResponse',
);

export const geolocationTroubleshooterCodec = t.intersection(
  [
    t.type({
      ShowTryAgain: t.boolean,
    }),
    t.partial({
      Message: t.string,
      Rule: t.string,
      OptInLink: t.string,
      HelpLink: t.string,
    }),
  ],
  'GeolocationTroubleshooter',
);

export const checkGeoLocationResponseCodec = t.intersection(
  [
    t.partial({
      Troubleshooters: t.array(geolocationTroubleshooterCodec),
    }),
    isGeoComplyLocationValidResponseCodec,
  ],
  'CheckGeoLocationResponse',
);

export type GeoComplyLicenseKeyResponse = t.TypeOf<
  typeof geoComplyLicenseKeyResponseCodec
>;
export type IsGeoComplyLocationValidResponse = t.TypeOf<
  typeof isGeoComplyLocationValidResponseCodec
>;
export type CheckGeoLocationResponse = t.TypeOf<
  typeof checkGeoLocationResponseCodec
>;
