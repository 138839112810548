import { createAsyncAction } from 'typesafe-actions';

import {
  GetAllProvincesRequestDTO,
  GetMunicipalitiesRequestDTO,
  GetRegistrationInfoRequestDTO,
  PlayerRegistrationRequestDTO,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  ActivateAccountResponse,
  GetDepartmentsAndMunicipalitiesResponse,
  GetProvincesResponse,
  GetRegistrationFormInfoResponse,
  GetRegistrationInfoResponse,
  RegistrationFailurePayload,
  RegistrationSuccessPayload,
} from './types';

/** Get information about the registration form */
export const getRegistrationFormInfo = createAsyncAction(
  'user/get_registration_form_info_request',
  'user/get_registration_form_info_success',
  'user/get_registration_form_info_failure',
)<void, GetRegistrationFormInfoResponse, FailurePayload>();

/** Get all provinces */
export const getProvinces = createAsyncAction(
  'user/get_provinces_request',
  'user/get_provinces_success',
  'user/get_provinces_failure',
)<GetAllProvincesRequestDTO, GetProvincesResponse, FailurePayload>();

/** Get all departments and municipalities */
export const getDepartmentsAndMunicipalities = createAsyncAction(
  'user/get_departments_municipalities_request',
  'user/get_departments_municipalities_success',
  'user/get_departments_municipalities_failure',
)<
  GetMunicipalitiesRequestDTO,
  GetDepartmentsAndMunicipalitiesResponse,
  FailurePayload
>();

/** Validate some fields from the registration form */
export const validateRegistrationFields = createAsyncAction(
  'user/validate_registration_fields_request',
  'user/validate_registration_fields_success',
  'user/validate_registration_fields_failure',
)<PlayerRegistrationRequestDTO, void, RegistrationFailurePayload>();

/** Register the user */
export const registerUser = createAsyncAction(
  'user/register_user_request',
  'user/register_user_success',
  'user/register_user_failure',
)<
  PlayerRegistrationRequestDTO,
  RegistrationSuccessPayload,
  RegistrationFailurePayload
>();

/** Get registration info */
export const getRegistrationInfo = createAsyncAction(
  'user/get_registration_info_request',
  'user/get_registration_info_success',
  'user/get_registration_info_failure',
)<GetRegistrationInfoRequestDTO, GetRegistrationInfoResponse, FailurePayload>();

/** Active account */
export const activateAccount = createAsyncAction(
  'user/activate_account_request',
  'user/activate_account_success',
  'user/activate_account_failure',
)<{ ActivationId: string }, ActivateAccountResponse, FailurePayload>();
