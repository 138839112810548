import { ApplicationWithUserState } from '../../store/types';

/** Accept Legal Modal */
export const acceptLegalModalSelector = (state: ApplicationWithUserState) =>
  state.user.legalModal.lastAcceptanceDate;

export const acceptLegalModalRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.legalModal.requests.acceptLegalModal;

/** Show Legal Modal */
export const shouldShowLegalModalSelector = (state: ApplicationWithUserState) =>
  state.user.legalModal.show;

export const showLegalModalRequestStateSelector = (
  state: ApplicationWithUserState,
) => state.user.legalModal.requests.showLegalModal;
